import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
// import { InstagramEmbed } from "react-social-media-embed";
import { isMobileDevice } from "../../../../utils/AppUtils";
// import Styles from "../../../../Styles.module.css";

function Feed(props) {
  const { id, caption, media_type, media_url, permalink } = props.feed;

  // let locationParam = permalink.split("/"); // read URL and split it on "/"
  // let ig_shortcode = locationParam[locationParam.length - 2];
  // let ig_shortcode = "CQBc5wZjbA7";
  // const ig_shortcode=permalink.split
  let post;

  function enlargeImg() {
    document.getElementById(id).requestFullscreen();
  }

  switch (media_type) {
    case "VIDEO":
      post = (
        <video
          width={"100%"}
          height={isMobileDevice() ? 354 : "100%"}
          src={media_url}
          type="video/mp4"
          controls
          playsinline
        ></video>
      );
      break;
    case "CAROUSEL_ALBUM":
      post = (
        <img
          width={"100%"}
          height={isMobileDevice() ? 354 : "100%"}
          id={id}
          src={media_url}
          alt={caption}
          onClick={() => enlargeImg()}
        />
      );
      break;
    default:
      post = (
        // <React.Fragment>
        <img
          width={"100%"}
          height={isMobileDevice() ? 354 : "100%"}
          // width={isMobileDevice() ? "100%" : 200}
          // height={isMobileDevice() ? 354 : "auto"}
          id={id}
          src={media_url}
          alt={caption}
          onClick={() => {
            !isMobileDevice() && enlargeImg();
          }}
        />
      );
  }

  return (
    // <div
    //   style={{
    //     border: "1px solid #868da6",
    //     maxWidth: isMobileDevice() ? "100%" : 200,
    //     marginRight: 8,
    //     marginLeft: isMobileDevice() && 12,
    //     marginBottom: isMobileDevice() && 16,
    //     height: isMobileDevice() ? 354 : 200,
    //   }}
    //   id="temp-container"
    // >
    <Grid item xs={12} md={3}>
      <div
        style={{
          border: "1px solid #868da6",
          maxWidth: "100%",
          marginRight: 8,
          marginLeft: isMobileDevice() && 12,
          marginBottom: 16,
          height: isMobileDevice() ? 354 : 200,
        }}
        id="temp-container"
      >
        {post}
      </div>
    </Grid>

    // </div>
  );
}

// function InstagramFeeds({ instaData }) {
//   return (
//     <div
//       style={{
//         display: !isMobileDevice() && "flex",
//         overflow: "auto",
//         maxWidth: 932,
//       }}
//       className="hideScroll"
//     >
//       {instaData && instaData.length
//         ? instaData.map((feed) => <Feed key={feed.id} feed={feed} />)
//         : null}
//     </div>
//   );
// }

export default function SocialMediaFeed({ instaPosts }) {
  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v9.0&appId=souqh&autoLogAppEvents=1";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  let instaData = [
    {
      id: "17949230585188442",
      media_url:
        "https://scontent.cdninstagram.com/v/t51.29350-15/307459000_396244526015208_1644282458567773467_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=QERmXPiGfXAAX89EWoW&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT-86WzEPovjSNqdN9uTZZ7Gfy6DTUcDIEBlZEL-0EkwfQ&oe=63311A28",
      media_type: "IMAGE",
      permalink: "https://www.instagram.com/p/CiuJ7mYqZyk/",
      timestamp: "2022-09-20T08:04:43+0000",
      username: "souqh_testing",
    },
    {
      id: "17934994067426097",
      caption: "test",
      media_url:
        "https://scontent.cdninstagram.com/v/t51.29350-15/307821451_657432462469798_2064420217179286637_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=KkulyMYKpbMAX-vo8A0&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT8OGuSImMZZoB4NmOxgIbsmVAAu6SX2gyJB063MeODeGA&oe=63309912",
      media_type: "IMAGE",
      permalink: "https://www.instagram.com/p/CiuA1SvqUUB/",
      timestamp: "2022-09-20T06:45:13+0000",
      username: "souqh_testing",
    },
    {
      id: "17949230585188442",
      media_url:
        "https://scontent.cdninstagram.com/v/t51.29350-15/307459000_396244526015208_1644282458567773467_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=QERmXPiGfXAAX89EWoW&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT-86WzEPovjSNqdN9uTZZ7Gfy6DTUcDIEBlZEL-0EkwfQ&oe=63311A28",
      media_type: "IMAGE",
      permalink: "https://www.instagram.com/p/CiuJ7mYqZyk/",
      timestamp: "2022-09-20T08:04:43+0000",
      username: "souqh_testing",
    },
    {
      id: "17934994067426097",
      caption: "test",
      media_url:
        "https://scontent.cdninstagram.com/v/t51.29350-15/307821451_657432462469798_2064420217179286637_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=KkulyMYKpbMAX-vo8A0&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT8OGuSImMZZoB4NmOxgIbsmVAAu6SX2gyJB063MeODeGA&oe=63309912",
      media_type: "IMAGE",
      permalink: "https://www.instagram.com/p/CiuA1SvqUUB/",
      timestamp: "2022-09-20T06:45:13+0000",
      username: "souqh_testing",
    },
    {
      id: "17949230585188442",
      media_url:
        "https://scontent.cdninstagram.com/v/t51.29350-15/307459000_396244526015208_1644282458567773467_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=QERmXPiGfXAAX89EWoW&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT-86WzEPovjSNqdN9uTZZ7Gfy6DTUcDIEBlZEL-0EkwfQ&oe=63311A28",
      media_type: "IMAGE",
      permalink: "https://www.instagram.com/p/CiuJ7mYqZyk/",
      timestamp: "2022-09-20T08:04:43+0000",
      username: "souqh_testing",
    },
    {
      id: "17934994067426097",
      caption: "test",
      media_url:
        "https://scontent.cdninstagram.com/v/t51.29350-15/307821451_657432462469798_2064420217179286637_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=KkulyMYKpbMAX-vo8A0&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT8OGuSImMZZoB4NmOxgIbsmVAAu6SX2gyJB063MeODeGA&oe=63309912",
      media_type: "IMAGE",
      permalink: "https://www.instagram.com/p/CiuA1SvqUUB/",
      timestamp: "2022-09-20T06:45:13+0000",
      username: "souqh_testing",
    },

    {
      id: "17951991074147833",
      media_url:
        "https://scontent.cdninstagram.com/v/t51.29350-15/308314559_1132741484321245_146785622439098811_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=yyvQO_VPlOMAX9soZPp&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT-wkON7uWa6T4IvMoIMGiuLrtxawuVpUs6iYl6Lhg2IYw&oe=6331E5DB",
      media_type: "IMAGE",
      permalink: "https://www.instagram.com/p/Ciz0-LiK511/",
      timestamp: "2022-09-22T12:57:01+0000",
      username: "souqh_testing",
    },
    {
      id: "17949230585188442",
      media_url:
        "https://scontent.cdninstagram.com/v/t51.29350-15/307459000_396244526015208_1644282458567773467_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=QERmXPiGfXAAX89EWoW&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT-86WzEPovjSNqdN9uTZZ7Gfy6DTUcDIEBlZEL-0EkwfQ&oe=63311A28",
      media_type: "IMAGE",
      permalink: "https://www.instagram.com/p/CiuJ7mYqZyk/",
      timestamp: "2022-09-20T08:04:43+0000",
      username: "souqh_testing",
    },
    {
      id: "17934994067426097",
      caption: "test",
      media_url:
        "https://scontent.cdninstagram.com/v/t51.29350-15/307821451_657432462469798_2064420217179286637_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=KkulyMYKpbMAX-vo8A0&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT8OGuSImMZZoB4NmOxgIbsmVAAu6SX2gyJB063MeODeGA&oe=63309912",
      media_type: "IMAGE",
      permalink: "https://www.instagram.com/p/CiuA1SvqUUB/",
      timestamp: "2022-09-20T06:45:13+0000",
      username: "souqh_testing",
    },
  ];
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        padding: "12px 0px",
        alignItems: "flex-start",
        marginBottom: 16,
      }}
    >
      {/* <Grid item xs={12}>
        <div
          class="fb-page"
          data-tabs="timeline"
          data-href="https://www.facebook.com/ExcellarateTech"
          // data-href="https://www.facebook.com/20531316728/"
          // data-href="https://www.facebook.com/facebook"
          data-width="500"
          data-adapt-container-width="true"
          data-hide-cover="false"
        >
          <blockquote
            cite="https://www.facebook.com/ExcellarateTech"
            // cite="https://www.facebook.com/facebook"
            class="fb-xfbml-parse-ignore"
          >
            <a
              href="https://www.facebook.com/ExcellarateTech"
              // href="https://www.facebook.com/facebook"
            >
              Facebook
            </a>
          </blockquote>
        </div>
      </Grid> */}

      <Grid container item xs={12}>
        {/* <InstagramFeeds instaData={instaPosts} />
         */}
        {instaPosts && instaPosts.length
          ? instaPosts.map((feed) => (
              <Feed key={feed.id} feed={feed} instaPosts={instaPosts} />
            ))
          : null}
      </Grid>
    </Grid>
  );
}
