import { useDispatch } from "react-redux";
import { useAuthUser } from "../useLogin";
import { useState } from "react";
import { useApiClient } from "../useApiClient";

export const useConnectedAccount = () => {
  const dispatch = useDispatch();
  const userInfo = useAuthUser();
  const { apiClient } = useApiClient();

  const [connectedAccountDetails, setConnectedAccountDetails] = useState(null);
  const [acceptPayments, setAcceptPayments] = useState(false);
  const [stripeConfigLink, setStripeConfigLink] = useState("");

  //   const getEnableEcommerceDetails = () => {
  //     dispatch({ type: "SET_LOADING", value: true });
  //     return apiClient
  //       .get(`/payment/user/ecommerce/${userInfo.userId}`)
  //       .then(function (response) {
  //         if (response.data.status === 200) {
  //           setIsEnableEcommerce(response.data.result);
  //         }
  //       })
  //       .catch(function (error) {
  //         console.error(error);
  //       })
  //       .then(function () {
  //         // always executed
  //         dispatch({ type: "SET_LOADING", value: false });
  //       });
  //   };

  const getConnectedAccountDetails = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/payment/connectedAcc/${userInfo.userId}`)
      .then(function (response) {
        if (response.data.status === 200) {
          setConnectedAccountDetails(response.data.result);
          setAcceptPayments(response.data.result.enableEcommerce);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const updateConnectedAccountDetails = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .put(
        `/payment/update/connected-account/${userInfo.userId}/${acceptPayments}`
      )
      .then(function (response) {
        if (response.data.status === 200) {
          window.open(response.data.message, "_blank");
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const addConnectedAccountDetails = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post(
        `/payment/add/connected-account/${userInfo.userId}/${acceptPayments}`
      )
      .then(function (response) {
        if (response.data.status === 200) {
          window.open(response.data.message, "_blank");
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const updateStatusOfInvoice = (invoiceId, status) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post(`home-buyer/invoice/${invoiceId}/${status}`)
      .then(function (response) {})
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getExpressAccountUrl = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/payment/connectedAcc/url/${userInfo.userId}`)
      .then(function (response) {
        if (response.data.status === 200) {
          window.open(response.data.result, "_blank");
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getStripeAccountUrl = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/payment/stripe-account/url/${userInfo.userId}`)
      .then(function (response) {
        if (response.data.status === 200) {
          window.open(response.data.result, "_blank");
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    // getEnableEcommerceDetails,
    getConnectedAccountDetails,
    connectedAccountDetails,
    setAcceptPayments,
    acceptPayments,
    addConnectedAccountDetails,
    updateConnectedAccountDetails,
    stripeConfigLink,
    updateStatusOfInvoice,
    getExpressAccountUrl,
    getStripeAccountUrl,
  };
};
