import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import React from "react";
import Styles from "../../Styles.module.css";

export default function ComingSoonDialog({
  openComingSoonDialog,
  setOpenComingSoonDialog,
}) {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={() => setOpenComingSoonDialog(false)}
      aria-labelledby="simple-dialog-title"
      open={openComingSoonDialog}
    >
      <DialogContent className={Styles.navyBlueColor}>
        Coming Soon
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => setOpenComingSoonDialog(false)}
          className={Styles.navyBlueColorForce}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
