import { useState, useEffect } from "react";

export const useSideDrawer = () => {
  const [activeSection, setActiveSection] = useState("dashboard");

  return {
    activeSection,
    setActiveSection,
  };
};
