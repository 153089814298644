import { useAuthUser } from "../../useLogin";
import { useDispatch } from "react-redux";
import { useApiClient } from "../../useApiClient";
import { SET_INACTIVE_CLIENT_DATA } from "../../../souqh-redux/reducers/actionConstants";

export const useInactiveClientInfo = (pageSize = 24) => {
  const { apiClient } = useApiClient();
  const userInfo = useAuthUser();
  const dispatch = useDispatch();

  const getInactiveClientData = (page = 0) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(
        `/service-provider/client/service-request/in-active/${userInfo.userId}?page=${page}&size=${pageSize}`
      )
      .then(function (response) {
        if (response.data.response && response.data.response.length) {
          const data = processResponse(response.data);
          dispatch({
            type: SET_INACTIVE_CLIENT_DATA,
            payload: data,
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const processResponse = (data) => {
    data.response = data.response.length
      ? data.response.map((res) => {
          return { ...res.hbDetails, ...res };
        })
      : [];
    return data;
  };

  return {
    getInactiveClientData,
  };
};
