import { useDispatch } from "react-redux";
import { useAuthUser } from "../useLogin";
import { useState } from "react";
import { MY_TEMPLATES } from "../../web/src/components/ServiceProvider/serviceProviderConstants";
import { useApiClient } from "../useApiClient";
import { cloneDeep } from "lodash";

export const useMoveDocument = (
  documentId,
  sourceCategoryId,
  getTabWiseCategoriesList,
  documentTabName,
  documentItem
) => {
  const { apiClient } = useApiClient();
  const userInfo = useAuthUser();
  const [errorMessage, setErrorMessage] = useState("");

  const [destinationCategoryId, setDestinationCategoryId] = useState("");
  const [destinationDocumentTab, setDestinationDocumentTab] = useState("");
  const moveData = {
    destinationCategoryId,
    destinationDocumentTab,
    documentId,
    fileName: documentItem ? documentItem.fileName : null,
    sourceCategoryId,
    userId: userInfo.userId,
  };
  const dispatch = useDispatch();

  const moveHBDocument = (onResult) => {
    if (documentItem) {
      if (destinationDocumentTab === MY_TEMPLATES) {
        if (!documentItem.signNowDocumentId || !documentItem.isTemplate) {
          dispatch({
            type: "SET_SERVER_ERROR",
            error: {
              message: "You cannot move another document to my templates",
            },
          });
          return;
        }
      }
      if (destinationDocumentTab !== MY_TEMPLATES) {
        if (documentItem.isTemplate) {
          dispatch({
            type: "SET_SERVER_ERROR",
            error: {
              message: "You cannot move template document to another tab",
            },
          });
          return;
        }
      }
    }
    const copiedData = cloneDeep(moveData);

    let url = "/documents/move";
    if (documentItem.signNowDocumentId) {
      url = "/documents/signnow/move";
      copiedData.fileName = null;
    }
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post(url, copiedData)
      .then((res) => {
        if (res.data.status === 200) {
          getTabWiseCategoriesList &&
            documentTabName &&
            getTabWiseCategoriesList(documentTabName);
        } else if (res.data.status === 400) {
          // setErrorMessage(res.data.message);
          dispatch({
            type: "SET_SERVER_ERROR",
            error: { message: res.data.message },
          });
        }
        onResult && onResult(res);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const moveRefDocument = () => {};

  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    setDestinationCategoryId("");
    setDestinationDocumentTab("");
  };
  return {
    moveHBDocument,
    setDestinationCategoryId,
    setDestinationDocumentTab,
    destinationDocumentTab,
    destinationCategoryId,
    handleClose,
  };
};
