import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {
  Checkbox,
  Collapse,
  Link,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import clsx from "clsx";
import Styles from "../../../Styles.module.css";
import RowMenuOptions from "./RowMenuOptions";
import DocQuickActions from "./DocQuickActions";
import DocumentStatus from "../../common/DocumentStatus";
import SPClientCell from "./SPClientCell";
import {
  covertTimeStampToDateTime,
  getComparator,
  isMobileDevice,
  isTabletDevice,
  stableSort,
} from "../../../utils/AppUtils";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { useDispatch } from "react-redux";
import { SP_SIGN_NOW_ACCOUNT_EXIST } from "../serviceProviderConstants";

const useStyles = makeStyles((theme) => ({
  paddingRight10: {
    paddingRight: 10,
  },
  tableHeading: {
    fontWeight: "bold",
  },
  cursorPointer: {
    cursor: "pointer",
  },
}));

export default function DocTableCell(props) {
  const classes = useStyles();

  const {
    categoryId,
    documents,
    order,
    orderBy,
    downLoadDocument,
    setOpenDeleteDocumentDialog,
    setOpenVoidDocumentDialog,
    setOpenMoveDocumentDialog,
    setOpenArchiveDocumentDialog,
    setOpenRenameDocumentDialog,
    getSharedWithTooltip,
    setOpenShareDocumentDialog,
    handleCheckboxChange,
    downloadMultipleDocument,
    selectedData,
    setSelectedData,
    setSelectedRow,
    selelctedRow,
    setOpenDocumentHistoryDialog,
    setCurrentDocument,
    handleInnerClose,
    openInnerMenu,
    duplicateDocument,
    handleInnerListKeyDown,
    setDocumentItem,
    setOpenDocumetDialog,
    getUploadedDocuments,
    setOpenSharedWithPopup,
    innerAnchorRef,
    setInnerAnchorRef,
    handleInnerToggle,
    open,
    isMyTemplatesTab,
    setTemplateForUse,
    setOpenEditTemplate,
    setOpenSignDocumentDialog,
    setSignDocumentItem,
    setOpenSignedWithPopup,
    documentTabName,
    setDocumentDataForEdit,
  } = props;
  const userInfo = useAuthUser();
  const dispatch = useDispatch();
  const quickActions = (row) => {
    return (
      <DocQuickActions
        row={row}
        selelctedRow={selelctedRow}
        categoryId={categoryId}
        setSelectedData={setSelectedData}
        setOpenShareDocumentDialog={setOpenShareDocumentDialog}
        downloadMultipleDocument={downloadMultipleDocument}
        downLoadDocument={downLoadDocument}
        selectedData={selectedData}
        documentTabName={documentTabName}
        setDocumentDataForEdit={setDocumentDataForEdit}
      />
    );
  };

  const rowOptions = (row) => {
    return (
      <RowMenuOptions
        row={row}
        selelctedRow={selelctedRow}
        categoryId={categoryId}
        setOpenDeleteDocumentDialog={setOpenDeleteDocumentDialog}
        setOpenVoidDocumentDialog={setOpenVoidDocumentDialog}
        setOpenMoveDocumentDialog={setOpenMoveDocumentDialog}
        setOpenArchiveDocumentDialog={setOpenArchiveDocumentDialog}
        setOpenRenameDocumentDialog={setOpenRenameDocumentDialog}
        setOpenDocumentHistoryDialog={setOpenDocumentHistoryDialog}
        setCurrentDocument={setCurrentDocument}
        handleInnerClose={handleInnerClose}
        openInnerMenu={openInnerMenu}
        handleInnerListKeyDown={handleInnerListKeyDown}
        duplicateDocument={duplicateDocument}
        isMyTemplatesTab={isMyTemplatesTab}
        downloadMultipleDocument={downloadMultipleDocument}
        downLoadDocument={downLoadDocument}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        setOpenShareDocumentDialog={setOpenShareDocumentDialog}
        getUploadedDocuments={getUploadedDocuments}
        setOpenDocumetDialog={setOpenDocumetDialog}
        setDocumentItem={setDocumentItem}
        setOpenEditTemplate={setOpenEditTemplate}
        setSignDocumentItem={setSignDocumentItem}
        setOpenSignDocumentDialog={setOpenSignDocumentDialog}
      />
    );
  };

  return (
    <TableCell style={{ padding: 0 }} colSpan={6}>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Table
          size="small"
          aria-label="purchases"
          style={{ tableLayout: "fixed", width: "100%" }}
        >
          <TableBody>
            {stableSort(documents, getComparator(order, orderBy)).map(
              (row, index) => {
                const isLinkEnabled = row.signNowDocumentId
                  ? row.enableUrl
                    ? true
                    : false
                  : true;
                return (
                  <TableRow key={row.docId}>
                    <TableCell
                      align="left"
                      style={{
                        width: isMyTemplatesTab
                          ? isTabletDevice() || isMobileDevice()
                            ? "15em"
                            : "30%"
                          : isTabletDevice() || isMobileDevice()
                          ? "13em"
                          : "25%",
                        overflow: "hidden",
                        paddingLeft: 7,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Checkbox
                          checked={row.selected || false}
                          onChange={(event) => {
                            handleCheckboxChange(
                              event.target.checked,
                              row.docId
                            );
                          }}
                          color={Styles.navyBlueColorForce}
                        />
                        <Link
                          color="inherit"
                          style={{
                            wordBreak: "break-all",
                            fontSize: 14,
                            opacity: isLinkEnabled ? 1 : 0.6,
                            cursor: isLinkEnabled ? "pointer" : "default",
                            textDecoration: isLinkEnabled
                              ? "underline"
                              : "none",
                          }}
                          onClick={() => {
                            if (userInfo.signNowAccountCreated) {
                              if (!isLinkEnabled) {
                                return;
                              }
                              if (
                                (row.enableUrl && row.signNowDocumentId) ||
                                (row.signNowDocumentId &&
                                  row.docStatus === "Completed") ||
                                (row.signNowDocumentId && row.isTemplate)
                              ) {
                                setSignDocumentItem(row);
                                setOpenSignDocumentDialog(true);
                              } else {
                                setDocumentItem(getUploadedDocuments(row));
                                setOpenDocumetDialog(true);
                              }
                            } else {
                              dispatch({
                                type: "SET_SERVER_ERROR",
                                error: {
                                  message: SP_SIGN_NOW_ACCOUNT_EXIST,
                                  title: "Existing SignNow Account Error",
                                },
                              });
                            }
                          }}
                          className={Styles.navyBlueColorForce}
                          title={row.docDescription}
                        >
                          {row.docName}
                        </Link>
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        width: isMyTemplatesTab
                          ? isTabletDevice() || isMobileDevice()
                            ? "1em"
                            : "2%"
                          : isTabletDevice() || isMobileDevice()
                          ? "11em"
                          : "23%",
                      }}
                    >
                      <Typography style={{ fontSize: 14 }}>
                        {row.signNowDocumentId &&
                        row.signedWith &&
                        row.signedWith.length ? (
                          <SPClientCell
                            row={row}
                            setOpenSharedWithPopup={setOpenSharedWithPopup}
                            getSharedWithTooltip={getSharedWithTooltip}
                            setOpenSignedWithPopup={setOpenSignedWithPopup}
                          />
                        ) : isMyTemplatesTab ? null : (
                          <Typography
                            variant="body1"
                            style={{ width: "95%", marginBottom: 12 }}
                            title={row.sentBy}
                            className={clsx(
                              Styles.sqEllipsisOneLine,
                              Styles.navyBlueColor
                            )}
                          >
                            {row.sentBy}
                          </Typography>
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        width: isMyTemplatesTab
                          ? isTabletDevice() || isMobileDevice()
                            ? "1em"
                            : "2%"
                          : isTabletDevice() || isMobileDevice()
                          ? "12em"
                          : "15%",
                      }}
                    >
                      {isMyTemplatesTab ? null : (
                        <DocumentStatus docStatus={row.docStatus} />
                      )}
                    </TableCell>
                    <TableCell
                      align={isMyTemplatesTab ? "left" : "left"}
                      style={{
                        width: isMyTemplatesTab
                          ? isTabletDevice() || isMobileDevice()
                            ? "15em"
                            : "36%"
                          : isTabletDevice() || isMobileDevice()
                          ? "15em"
                          : "18%",
                      }}
                    >
                      <Tooltip
                        title={covertTimeStampToDateTime(row.lastChange)}
                      >
                        <Typography
                          className={Styles.navyBlueColorForce}
                          style={{ fontSize: 14 }}
                        >
                          {covertTimeStampToDateTime(row.lastChange)}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      align={isMyTemplatesTab ? "left" : "right"}
                      style={{
                        width: isMyTemplatesTab
                          ? isTabletDevice() || isMobileDevice()
                            ? "15em"
                            : "25%"
                          : isTabletDevice() || isMobileDevice()
                          ? "11em"
                          : "12%",
                      }}
                    >
                      {isMyTemplatesTab ? (
                        <Button
                          variant="outlined"
                          style={{
                            textTransform: "capitalize",
                            padding: "2px 16px",
                            marginRight: 12,
                          }}
                          className={clsx(Styles.navyBlueColorForce)}
                          onClick={() => {
                            if (userInfo.signNowAccountCreated) {
                              setTemplateForUse(row);
                            } else {
                              dispatch({
                                type: "SET_SERVER_ERROR",
                                error: {
                                  message: SP_SIGN_NOW_ACCOUNT_EXIST,
                                  title: "Existing SignNow Account Error",
                                },
                              });
                            }
                          }}
                        >
                          Invite to sign
                        </Button>
                      ) : (
                        quickActions(row)
                      )}
                    </TableCell>
                    <TableCell align="right" style={{ width: "3%" }}>
                      <MoreHorizIcon
                        ref={innerAnchorRef}
                        onClick={(event) => {
                          // handleCheckboxChange(true, row.docId);
                          setSelectedRow(row);
                          setInnerAnchorRef(event.currentTarget);
                          handleInnerToggle();
                        }}
                        className={clsx(
                          classes.cursorPointer,
                          Styles.navyBlueColorForce
                        )}
                      />
                      <Popper
                        open={openInnerMenu}
                        anchorEl={innerAnchorRef}
                        placement="bottom-end"
                      >
                        {rowOptions(row)}
                      </Popper>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </Collapse>
    </TableCell>
  );
}
