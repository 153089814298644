import React from "react";
import { Grid, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import StorefrontSocialLinks from "./StorefrontSocialLinks";
import StorefrontIcons from "./StorefrontIcons";
import { useTheme } from "souqh-react-redux-hooks/serviceProvider/storefront/useTheme";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import Styles from "../../../../Styles.module.css";
import {
  ADVANCED,
  MARKETPLACE,
  PREMIUM,
} from "../../../ServiceProvider/serviceProviderConstants";
import WebAddress from "../../../SPSetup/StoreFront/WebAddress";
import { isTabletDevice } from "../../../../utils/AppUtils";

const useStyles = makeStyles((theme) => ({
  displayPicture: {
    height: 164,
    width: 164,
    borderRadius: 8,
    position: "absolute",
    top: -20,
    left: 12,
    boxShadow: "3px 3px 10px #00000029",
  },
  ratingStyle: {
    border: "1px solid",
    borderRadius: 8,
    padding: "4px 8px",
  },
}));

function StorefrontPageHeader(props) {
  const user = useAuthUser();
  const { primaryColor, secondaryColor } = useTheme();
  const classes = useStyles();
  const {
    primarySubscriptionPlanName,
    storeFrontDetails,
    businessTypes,
    businessName,
    addresses,
    SFName,
    showLimitedView,
    email,
    reviewStatistics,
    storeFrontRouteName,
    storeFrontId,
    setUserEngagementData,
    increaseLeadCount,
    contactNumber,
    calSyncAccessToken,
    pageRoute,
    storeFrontName,
  } = props;
  const matches = useMediaQuery("(min-width:1620px)");

  if (!storeFrontDetails) {
    return null;
  }
  return (
    <Grid item container xs={12}>
      <Grid
        item
        xs={2}
        md={3}
        lg={2}
        style={{
          position: "relative",
        }}
      >
        <img
          src={
            storeFrontDetails.displayPicture
              ? storeFrontDetails.displayPicture
              : businessTypes.length
              ? `/images/Explore-Category/${businessTypes[0].logoUrl}`
              : "" // default image if user has not uploaded displayPicture according to its business Type
          }
          className={classes.displayPicture}
        />
      </Grid>
      <Grid
        item
        xs={8}
        md={6}
        lg={8}
        style={
          matches
            ? { padding: "24px 16px 8px 24px" }
            : { padding: "24px 16px 8px 36px" }
        }
      >
        <Typography variant="h6" style={{ color: primaryColor }}>
          {SFName}
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ color: secondaryColor }}
          className={Styles.sqEllipsis}
        >
          {businessTypes && businessTypes.length
            ? businessTypes[0].name === "Other"
              ? businessTypes[0].otherBusinessName
              : businessTypes[0].name
            : ""}
        </Typography>
        <Typography
          variant="body1"
          style={{ color: secondaryColor }}
          className={Styles.sqEllipsis}
        >
          {businessName}
        </Typography>
        <Typography
          variant="body1"
          style={{ color: secondaryColor }}
          className={Styles.sqEllipsis}
        >
          {storeFrontDetails.competeText}
        </Typography>
        <Grid container item xs={12} alignContent="center">
          {storeFrontDetails.webPresence.companyWebsite && (
            <WebAddress
              style={{
                height: 25,
                width: 25,
                margin: "4px 8px",
                cursor: "pointer",
              }}
              id={"web-address"}
              onClick={(e) => {
                e.preventDefault();
                let compWebsite = storeFrontDetails.webPresence.companyWebsite;
                let url =
                  compWebsite.indexOf("//") < 0
                    ? "http://" + compWebsite
                    : compWebsite; //check if link is in valid format or not
                window.open(url, "_blank");
                // window.open(
                //   storeFrontDetails.webPresence.companyWebsite,
                //   "_blank"
                // );
                setUserEngagementData(storeFrontId, "Web_address");
              }}
            ></WebAddress>
          )}
          <StorefrontSocialLinks
            socialLinks={storeFrontDetails.webPresence.socialLinks}
            setUserEngagementData={setUserEngagementData}
            storeFrontId={storeFrontId}
          />
        </Grid>
      </Grid>
      <Grid item xs={2} md={3} lg={2} style={{ padding: "24px 0px 8px 4px" }}>
        <Grid item>
          <StorefrontIcons
            addresses={addresses}
            showLimitedView={showLimitedView}
            email={email}
            user={user}
            storeFrontId={storeFrontId}
            storeFrontRouteName={storeFrontRouteName}
            setUserEngagementData={setUserEngagementData}
            contactNumber={contactNumber}
            increaseLeadCount={increaseLeadCount}
            calSyncAccessToken={calSyncAccessToken}
            pageRoute={pageRoute}
            storeFrontName={storeFrontName}
          />
        </Grid>
        {reviewStatistics && reviewStatistics.avgStarRatings && (
          <Grid item container style={{ padding: "4px 0px" }}>
            <Grid
              container
              item
              xs={6}
              className={classes.ratingStyle}
              style={{
                borderColor: primaryColor,
              }}
              justifyContent="center"
              alignContent="center"
            >
              <Typography variant="h6" style={{ color: secondaryColor }}>
                {(reviewStatistics &&
                  reviewStatistics.avgStarRatings &&
                  reviewStatistics.avgStarRatings.toFixed(1)) ||
                  "NA"}
                &nbsp;★
              </Typography>
            </Grid>
            {primarySubscriptionPlanName !== MARKETPLACE && (
              <Grid
                container
                item
                xs={6}
                justifyContent="center"
                alignContent="center"
              >
                <img
                  src={
                    primarySubscriptionPlanName === PREMIUM || primarySubscriptionPlanName === ADVANCED
                      ? "/images/blue_badge.svg"
                      : "/images/teal_badge.svg"
                  }
                ></img>
              </Grid>
            )}
          </Grid>
        )}
        {!isTabletDevice() &&
          reviewStatistics &&
          reviewStatistics.avgStarRatings && (
            <Grid item>
              <Typography variant="body1" style={{ color: secondaryColor }}>
                {(reviewStatistics && reviewStatistics.totalReviewComments) ||
                  0}
                &nbsp;Reviews
              </Typography>
            </Grid>
          )}
      </Grid>
    </Grid>
  );
}
export default StorefrontPageHeader;
