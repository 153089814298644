import { useCallback, useState, useEffect } from "react";
import { useValidations } from "../../useValidations";
import { passwordValidationMeta } from "../../validationMetas";
const validationMeta = [passwordValidationMeta];
import { useDispatch } from "react-redux";
import { useApiClient } from "../../useApiClient";
import { useAuthUser } from "../../useLogin";
import { color_success } from "../../toasterColors";

export const useChangePassword = () => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();
  const user = useAuthUser();

  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");

  const [allowSubmit, setAllowSubmit] = useState(false);

  const getFormValues = useCallback(() => {
    return { password };
  }, [password]);
  const getServerKeysMap = useCallback(() => {
    return {};
  }, []);

  const { validations, addValidations, clearAllValidations } = useValidations(
    validationMeta,
    getFormValues,
    getServerKeysMap
  );
  useEffect(() => {
    let validForm = !!password && !Object.keys(validations).length;
    setAllowSubmit(validForm);
  }, [password, validations]);

  const changePassword = (onSuccess, onFailure) => {
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .put("/user/password/reset", {
        password: password,
        userId: user.userId,
      })
      .then((res) => {
        if (res && res.data.status === 200) {
          dispatch({
            type: "SET_TOASTER",
            payload: {
              message: `Password changed successfully!`,
              bgColor: color_success,
              open: true,
            },
          });
        }
        onSuccess && onSuccess(res);
      })
      .catch((error) => {
        console.error(error);
        onFailure && onFailure(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };
  return {
    password,
    setPassword,
    validations,
    allowSubmit,
    changePassword,
    addValidations,
    confirmPassword,
    setconfirmPassword,
  };
};
