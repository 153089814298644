import { useSelector } from "react-redux";

import {
  SET_PRIMARY_COLOR,
  SET_SECONDARY_COLOR,
} from "../../../souqh-redux/reducers/actionConstants";
import { useActions } from "../../useActions";

const actionCreators = {
  setPrimaryColor: (payload) => ({
    type: SET_PRIMARY_COLOR,
    payload,
  }),
  setSecondaryColor: (payload) => ({
    type: SET_SECONDARY_COLOR,
    payload,
  }),
};

export const useTheme = () => {
  const primaryColor = useSelector((state) => state.storefront.primaryColor);
  const secondaryColor = useSelector(
    (state) => state.storefront.secondaryColor
  );
  const actions = useActions(actionCreators);

  return { primaryColor, secondaryColor, actions };
};
