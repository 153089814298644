import { MenuItem, Paper, MenuList } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { color_error } from "souqh-react-redux-hooks/toasterColors";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";

import Styles from "../../../Styles.module.css";
import { SP_SIGN_NOW_ACCOUNT_EXIST } from "../serviceProviderConstants";

export default function RowMenuOptions(props) {
  const {
    row,
    selelctedRow,
    categoryId,
    setOpenDeleteDocumentDialog,
    setOpenVoidDocumentDialog,
    setOpenMoveDocumentDialog,
    setOpenRenameDocumentDialog,
    setOpenDocumentHistoryDialog,
    setCurrentDocument,
    handleInnerClose,
    openInnerMenu,
    handleInnerListKeyDown,
    duplicateDocument,
    isMyTemplatesTab,
    downloadMultipleDocument,
    downLoadDocument,
    selectedData,
    setOpenSignDocumentDialog,
    setSignDocumentItem,
  } = props;
  const userInfo = useAuthUser();
  const dispatch = useDispatch();
  const allowDelete = row.signNowDocumentId
    ? row.allowVoid
      ? true
      : false
    : true;

  return (
    <Paper>
      <MenuList
        autoFocusItem={openInnerMenu}
        id="menu-list-grow"
        onKeyDown={handleInnerListKeyDown}
        className={Styles.navyBlueColor}
      >
        {row.isTemplate && row.signNowDocumentId ? (
          <MenuItem
            onClick={() => {
              if (row.isTemplate) {
                setSignDocumentItem(selelctedRow);
                setOpenSignDocumentDialog(true);
              }
              handleInnerClose();
            }}
          >
            Edit
          </MenuItem>
        ) : null}

        {isMyTemplatesTab && row.signNowDocumentId ? (
          <MenuItem
            onClick={() => {
              if (selectedData.length > 1) {
                downloadMultipleDocument();
              } else {
                downLoadDocument(categoryId, selelctedRow);
              }
              handleInnerClose();
            }}
          >
            Download
          </MenuItem>
        ) : null}

        {allowDelete ? (
          <MenuItem
            onClick={() => {
              if (userInfo.signNowAccountCreated) {
                setOpenRenameDocumentDialog({
                  open: true,
                  documentId: selelctedRow.docId,
                  categoryId,
                  documentName: selelctedRow.docName,
                  docData: selelctedRow,
                });
                handleInnerClose();
              } else {
                dispatch({
                  type: "SET_SERVER_ERROR",
                  error: {
                    message: SP_SIGN_NOW_ACCOUNT_EXIST,
                    title: "Existing SignNow Account Error",
                  },
                });
              }
            }}
          >
            Rename
          </MenuItem>
        ) : null}

        <MenuItem
          onClick={() => {
            if (userInfo.signNowAccountCreated) {
              duplicateDocument(categoryId, selelctedRow);
              handleInnerClose();
            } else {
              dispatch({
                type: "SET_SERVER_ERROR",
                error: {
                  message: SP_SIGN_NOW_ACCOUNT_EXIST,
                  title: "Existing SignNow Account Error",
                },
              });
            }
          }}
        >
          Duplicate
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenMoveDocumentDialog({
              open: true,
              documentName: selelctedRow.docName,
              documentId: selelctedRow.docId,
              sourceCategoryId: categoryId,
              docData: selelctedRow,
            });
            handleInnerClose();
          }}
        >
          Move
        </MenuItem>
        {row.allowVoid &&
        row.signNowDocumentId &&
        !row.isTemplate &&
        row.docStatus !== "Completed" ? (
          <MenuItem
            disabled={!row.signNowDocumentId || row.isTemplate}
            onClick={() => {
              if (userInfo.signNowAccountCreated) {
                setOpenVoidDocumentDialog({
                  open: true,
                  documentName: selelctedRow.docName,
                  docDetails: selelctedRow,
                });
                handleInnerClose();
              } else {
                dispatch({
                  type: "SET_SERVER_ERROR",
                  error: {
                    message: SP_SIGN_NOW_ACCOUNT_EXIST,
                    title: "Existing SignNow Account Error",
                  },
                });
              }
            }}
          >
            Void
          </MenuItem>
        ) : null}

        {allowDelete ? (
          <MenuItem
            style={{
              opacity: selelctedRow.docStatus === "waiting for sign" ? 0.5 : 1,
              cursor:
                selelctedRow.docStatus === "waiting for sign"
                  ? "default"
                  : "pointer",
            }}
            onClick={() => {
              if (selelctedRow.docStatus === "waiting for sign") {
                return;
              }
              if (selelctedRow.documentDelete) {
                setOpenDeleteDocumentDialog({
                  open: true,
                  docDetails: selelctedRow,
                  categoryId,
                });
                handleInnerClose();
              } else {
                dispatch({
                  type: "SET_TOASTER",
                  payload: {
                    message: `Cannot delete document`,
                    bgColor: color_error,
                    open: true,
                  },
                });
                handleInnerClose();
              }
            }}
          >
            Delete
          </MenuItem>
        ) : null}

        {row.signNowDocumentId && !row.isTemplate ? (
          <MenuItem
            disabled={!row.signNowDocumentId || row.isTemplate}
            onClick={() => {
              if (userInfo.signNowAccountCreated) {
                handleInnerClose();
                setCurrentDocument && setCurrentDocument(selelctedRow);
                setOpenDocumentHistoryDialog &&
                  setOpenDocumentHistoryDialog(true);
              } else {
                dispatch({
                  type: "SET_SERVER_ERROR",
                  error: {
                    message: SP_SIGN_NOW_ACCOUNT_EXIST,
                    title: "Existing SignNow Account Error",
                  },
                });
              }
            }}
          >
            History
          </MenuItem>
        ) : null}
      </MenuList>
    </Paper>
  );
}
