import { Grid, Typography, Paper, Avatar, makeStyles } from "@material-ui/core";
import React from "react";
import Styles from "../../../Styles.module.css";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import clsx from "clsx";
import ContactNumber from "../../HomeBuyer/SPManagement/ContactNumber";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";

const useStyles = makeStyles((theme) => ({
  paddingLeft15: {
    paddingLeft: 15,
  },
  italicFont: {
    fontStyle: "italic",
  },
}));

export default function PersonalDetailsTab(props) {
  const { coApplicant, currentAddress, primaryApplicant, coApplicantSupport } =
    props;
  const classes = useStyles();

  const { streetName, streetNumber, province, county, postalCode } =
    currentAddress;

  return (
    <Paper elevation={0} style={{ padding: 12, height: 479, marginTop: 16 }}>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <Grid item xs={12}>
            <Typography variant="h6" className={Styles.navyBlueColor}>
              Primary Applicant
            </Typography>
          </Grid>
          <Grid container item xs={12} style={{ marginTop: 20 }}>
            <Grid item xs={5} md={4}>
              <Typography
                variant="body1"
                className={Styles.navyBlueColor}
                style={{ fontWeight: 500 }}
              >
                Full Name
              </Typography>
            </Grid>
            <Grid item xs={7} md={8} className={classes.paddingLeft15}>
              <Typography
                variant="body1"
                className={clsx(classes.italicFont, Styles.navyBlueColor)}
              >
                {primaryApplicant && primaryApplicant.fullName}
              </Typography>
            </Grid>

            <Grid item xs={5} md={4} style={{ marginTop: 8 }}>
              <Typography
                variant="body1"
                className={Styles.navyBlueColor}
                style={{ fontWeight: 500 }}
              >
                Email Address
              </Typography>
            </Grid>
            <Grid
              item
              xs={7}
              md={8}
              className={classes.paddingLeft15}
              style={{ marginTop: 8 }}
            >
              <Typography
                variant="body1"
                className={clsx(
                  classes.italicFont,
                  Styles.navyBlueColor,
                  Styles.sqEllipsis
                )}
              >
                {primaryApplicant && primaryApplicant.email}
              </Typography>
            </Grid>

            <Grid item xs={5} md={4} style={{ marginTop: 10 }}>
              <Typography
                variant="body1"
                className={Styles.navyBlueColor}
                style={{ fontWeight: 500 }}
              >
                Phone
              </Typography>
            </Grid>
            <Grid
              item
              xs={7}
              md={8}
              className={classes.paddingLeft15}
              style={{ marginTop: 10 }}
            >
              <Typography
                variant="body1"
                className={clsx(classes.italicFont, Styles.navyBlueColor)}
              >
                {primaryApplicant && (
                  <ContactNumber contactNumber={primaryApplicant.phoneNumber} />
                )}
              </Typography>
            </Grid>

            <Grid item xs={5} md={4} style={{ marginTop: 10 }}>
              <Typography
                variant="body1"
                className={Styles.navyBlueColor}
                style={{ fontWeight: 500 }}
              >
                Address
              </Typography>
            </Grid>
            <Grid
              item
              xs={7}
              md={8}
              className={classes.paddingLeft15}
              style={{ marginTop: 10 }}
            >
              {currentAddress ? (
                <Typography
                  variant="body1"
                  className={clsx(
                    classes.italicFont,
                    Styles.navyBlueColor,
                    Styles.sqEllipsisTwoLines
                  )}
                >
                  {(streetNumber && `${streetNumber},`) || ""}
                  {(streetName && `${streetName},`) || ""}
                  {(province && `${province.name},`) || ""}
                  {(currentAddress.city && `${currentAddress.city.name},`) ||
                    ""}
                  {(postalCode && `${postalCode},`) || ""}
                  {(county && `${county}`) || ""}
                </Typography>
              ) : (
                "null"
              )}
            </Grid>
          </Grid>
        </Grid>

        {coApplicantSupport ? (
          <Grid item xs={12} lg={6}>
            <Grid item xs={12}>
              <Typography variant="h6" className={Styles.navyBlueColor}>
                Co - Applicant
              </Typography>
            </Grid>
            <Grid container item xs={12} style={{ marginTop: 20 }}>
              {!isMobileDevice() && !isTabletDevice() && (
                <Grid
                  item
                  xs={2}
                  style={{
                    border: "1px solid #0E1C4E1F",
                    height: 80,
                    width: 80,
                  }}
                >
                  <Avatar style={{ marginTop: "25%", marginLeft: "23%" }}>
                    <PersonOutlineIcon style={{ height: 41, width: 41 }} />
                  </Avatar>
                </Grid>
              )}
              <Grid
                container
                item
                xs={12}
                md={10}
                alignContent="flex-start"
                style={{
                  paddingLeft: !isMobileDevice() && !isTabletDevice() && 20,
                }}
              >
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    className={Styles.navyBlueColor}
                    style={{ fontWeight: 500 }}
                  >
                    Full Name
                  </Typography>
                </Grid>
                <Grid item xs={8} className={classes.paddingLeft15}>
                  <Typography
                    variant="body1"
                    className={clsx(
                      classes.italicFont,
                      Styles.navyBlueColor,
                      Styles.sqEllipsis
                    )}
                  >
                    {coApplicant && coApplicant.fullName}
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    className={Styles.navyBlueColor}
                    style={{ fontWeight: 500, marginTop: 8 }}
                  >
                    Email Address
                  </Typography>
                </Grid>
                <Grid item xs={8} className={classes.paddingLeft15}>
                  <Typography
                    variant="body1"
                    className={clsx(
                      classes.italicFont,
                      Styles.navyBlueColor,
                      Styles.sqEllipsis
                    )}
                    style={{ marginTop: 8 }}
                  >
                    {coApplicant && coApplicant.email}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );
}
