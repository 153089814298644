import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import {
  IMAGE_FILE_FORMATS,
  OFFICE_FILE_FORMATS,
} from "../HBRegistration/HomeBuyerConstants";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function DocumentPreviewRenderer({
  documentItem,
  iframeLoaded,
  eleRef,
}) {
  const classes = useStyles();

  if (!documentItem) {
    return null;
  }
  const documentType = (documentItem.type || "").toLowerCase();
  let viewerItem = null;

  if (documentItem && documentItem.signNowDocumentId) {
    viewerItem = <Typography>Cannot show preview of this document</Typography>;
  } else {
    if (IMAGE_FILE_FORMATS.includes(documentType)) {
      viewerItem = (
        <img
          src={decodeURIComponent(documentItem.path)}
          alt={documentItem.name}
          style={{
            width: "50%",
            height: window.innerHeight - 240,
            border: "1px solid rgba(0, 0,0,0.12)",
          }}
        />
      );
    } else if (OFFICE_FILE_FORMATS.includes(documentType)) {
      const url =
        "https://view.officeapps.live.com/op/embed.aspx?src=" +
        documentItem.path;
      viewerItem = (
        <iframe
          src={url}
          title="preview"
          width="90%"
          height={window.innerHeight - 240}
          frameborder="0"
        >
          This is an embedded{" "}
          <a target="_blank" href="http://office.com">
            Microsoft Office
          </a>{" "}
          document, powered by{" "}
          <a target="_blank" href="http://office.com/webapps">
            Office Online
          </a>
          .
        </iframe>
      );
    } else if (documentType === "pdf") {
      viewerItem = (
        <embed
          onLoad={iframeLoaded}
          onError={iframeLoaded}
          src={decodeURIComponent(documentItem.path)}
          width="90%"
          height={window.innerHeight - 240}
          ref={eleRef}
          type="application/pdf"
        ></embed>
      );
    } else {
      viewerItem = (
        <iframe
          onLoad={iframeLoaded}
          onError={iframeLoaded}
          title="preview"
          src={
            "https://docs.google.com/gview?url=" +
            documentItem.path +
            "&embedded=true&time=0"
          }
          width="90%"
          height={window.innerHeight - 240}
          ref={eleRef}
        ></iframe>
      );
    }
  }

  return viewerItem;
}
