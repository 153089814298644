import { Grid, Typography } from "@material-ui/core";
import React from "react";

export default function CardDetails({ paymentCardDetails, page }) {
  return (
    <Grid
      item
      style={{
        backgroundColor: "#ffffff",
        borderRadius: 8,
        padding: 20,
        border: page && page === "registration" && "1px solid #17174c1f",
      }}
    >
      <Grid container>
        <Grid item style={{ marginRight: 20 }}>
          <Typography variant="subtitle1">
            {paymentCardDetails?.brand}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            *****{paymentCardDetails?.lastFour}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item style={{ marginRight: 10 }}>
          <Typography variant="body1">Expires </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            {paymentCardDetails?.expMonth}/{paymentCardDetails?.expYear}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
