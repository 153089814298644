import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Divider,
  Avatar,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";

const styles = makeStyles((theme) => ({
  dialogPaper: {
    height: 602,
    width: 606,
  },
}));

export default function SignedWithDialog({
  openSignedWithPopup,
  setOpenSignedWithPopup,
  signedWithList,
}) {
  const classes = styles();
  return (
    <Dialog
      fullWidth
      open={openSignedWithPopup}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Signed With</Typography>
      </DialogTitle>
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        style={{ width: "100%" }}
      />
      <DialogContent>
        {signedWithList && signedWithList.length
          ? signedWithList.map((item, index) => {
              return (
                <Grid container key={index} style={{ marginBottom: 24 }}>
                  <Grid container item xs={1} alignContent="center">
                    <Avatar
                      src={"/broken-image.jpg"}
                      style={{ width: 30, height: 30 }}
                    />
                  </Grid>
                  <Grid container item xs={9} alignContent="center">
                    <Typography
                      variant="body1"
                      className={Styles.navyBlueColor}
                    >
                      {item.email}
                    </Typography>
                  </Grid>
                  <Grid container item xs={2} alignContent="center">
                    <Typography
                      variant="body1"
                      className={Styles.navyBlueColor}
                    >
                      {item.status}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })
          : null}
      </DialogContent>
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        style={{ width: "100%" }}
      />
      <DialogActions>
        <Button
          variant="outlined"
          className={Styles.navyBlueColorForce}
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            setOpenSignedWithPopup(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
