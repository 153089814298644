import { Grid } from "@material-ui/core";
import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Lawyers from "./Lawyers";
import MortgageBroker from "./MortgageBroker";
import Realtor from "./Realtor";

export default function AppsRightPanel() {
  let { path } = useRouteMatch();

  return (
    <Grid
      item
      xs={12}
      style={{
        height: "100%",
        overflow: "hidden",
        paddingTop: 32,
        marginRight: 60,
      }}
    >
      <Switch>
        <Route exact path={`${path}/realtor`}>
          <Realtor />
        </Route>
        <Route exact path={`${path}`}>
          <Realtor />
        </Route>
        <Route exact path={`${path}/mortgagebroker`}>
          <MortgageBroker />
        </Route>
        <Route exact path={`${path}/lawyers`}>
          <Lawyers />
        </Route>
        <Route exact path={`${path}/movers`}>
          Coming Soon...
        </Route>
        <Route exact path={`${path}/propertyinspectors`}>
          Coming Soon...
        </Route>
        <Route exact path={`${path}/generacontractors`}>
          Coming Soon...
        </Route>
      </Switch>
    </Grid>
  );
}
