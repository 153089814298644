import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Popover,
  Grid,
  TableSortLabel,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";
import { useClientDocuments } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useClientDocuments";
import ClientDocumentsRow from "./ClientDocumentsRow";
import { useSQQuery } from "souqh-react-redux-hooks/common/useSQQuery";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";

export default function DocumentsTab({ userId }) {
  const { query } = useSQQuery();

  const [anchorEl, setAnchorEl] = useState(null);
  const openPopover = Boolean(anchorEl);

  const [selectedDocId, setSelectedDocId] = useState(null);
  const [selectedSignNowDocId, setSelectedSignNowDocId] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("docName");

  let serviceRequestId = query.get("serviceRequestId");

  const { getClientDocuments, clientDocuments, requestDocumentAccess } =
    useClientDocuments(userId, selectedDocId, serviceRequestId);

  const handleClick = (event, docId, signNowDocumentId) => {
    setAnchorEl(event.currentTarget);
    setSelectedDocId(docId);
    setSelectedSignNowDocId(signNowDocumentId);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getClientDocuments();
  }, []);

  const DOCUMENTS_TABLE_HEADERS = [
    {
      id: "docName",
      label: "Document Name",
      width: isTabletDevice() || isMobileDevice() ? "18em" : "24%",
    },
    {
      id: "docDescription",
      label: "Description",
      width: isTabletDevice() || isMobileDevice() ? "18em" : "37%",
    },
    {
      id: "docStatus",
      label: "Status",
      width: isTabletDevice() || isMobileDevice() ? "9em" : "17%",
    },
    {
      id: "lastChange",
      label: "Last Changed",
      width: isTabletDevice() || isMobileDevice() ? "10em" : "16%",
    },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function EnhancedTableHead({ order, orderBy, onRequestSort, tab }) {
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <>
        {DOCUMENTS_TABLE_HEADERS.map((headCell) => (
          <TableCell
            className={clsx(Styles.navyBlueColorForce, Styles.font14)}
            key={headCell.id}
            align={"left"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={
              isTabletDevice() || isMobileDevice()
                ? { minWidth: headCell.width }
                : {
                    width: headCell.width,
                    paddingRight: headCell.id === "lastChange" && 0,
                  }
            }
          >
            <TableSortLabel
              active={
                headCell.id === "docStatus" || headCell.id === "docDescription"
                  ? false
                  : orderBy === headCell.id
              }
              disabled={
                headCell.id === "docStatus" || headCell.id === "docDescription"
              }
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              style={{ fontSize: 12, fontWeight: 600 }}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </>
    );
  }

  return (
    <Grid container>
      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Grid style={{ padding: "10px 20px" }}>
          <Typography
            style={{ marginTop: 10, cursor: "pointer" }}
            className={Styles.navyBlueColor}
            variant="body1"
            onClick={() => {
              requestDocumentAccess(selectedSignNowDocId, (response) => {
                if (response && response.data.status === 200) {
                  handleClose();
                }
              });
            }}
          >
            Request Access
          </Typography>
        </Grid>
      </Popover>
      <TableContainer
        component={Paper}
        elevation={0}
        style={{ marginBottom: isMobileDevice() ? 80 : 40 }}
      >
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={DOCUMENTS_TABLE_HEADERS}
              />
              <TableCell
                className={Styles.navyBlueColorForce}
                style={{
                  width: "1%",
                  minWidth: (isTabletDevice() || isMobileDevice()) && "2em",
                  fontWeight: 600,
                  paddingRight: 8,
                  textAlign: "center",
                }}
              >
                <MoreHorizIcon />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientDocuments && clientDocuments.length
              ? clientDocuments.map((data, index) => (
                  <ClientDocumentsRow
                    key={index}
                    data={data}
                    handleClick={handleClick}
                    order={order}
                    orderBy={orderBy}
                  />
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
