import { cloneDeep, isEmpty } from "lodash";
import {
  SAVE_HB_SINGUP_INFO,
  SAVE_HB_SINGUP_USERINFO,
  SET_EXPLORE_SERVICES,
  SET_EXPLORE_CITIES,
  SET_FEATURED_STORE_FRONTS,
  SET_SEARCHED_STORE_FRONTS,
  SET_CONTACTS,
  SET_ACTIVE_SERVICE_PROVIDERS,
  SET_SELECTED_CONTACTS,
  SET_SELECTED_ACTIVE_SP,
  ADD_DOCUMENTS,
  SET_REFERENCE_DOCUMENT,
  SET_DOCUMENT_CATEGORY,
  DELETE_DOCUMENT_WHILE_UPLOAD,
  CLEAR_UPLOAD_FILE_DATA,
  UPDATE_UPLOADED_DOCS,
  DELETE_CONTACTS,
  EXPLORE_PAGE_FILTER,
  SET_SEARCHED_CITY,
  SET_SEARCHED_SERVICE,
  ADD_SERVICE_REQUEST_DCOUMENTS,
  DELETE_SERVICE_REQUEST_DCOUMENT,
  CLEAR_EXPLORE_SEARCH_RESULT,
  SET_FEATURED_STORE_FRONTS_FOR_WEBSITE,
  SET_FEATURED_STORE_FRONTS_ADD_ON,
  ADD_BROADCAST_SERVICE_REQUEST_DOCUMENTS,
  DELETE_BROADCAST_SERVICE_REQUEST_DOCUMENT,
} from "./actionConstants";

const initialState = {
  singupInfo: null,
  singUpUserDetails: null,
  userInfo: null,
  services: [],
  cities: [],
  featuredStoreFronts: [],
  searchedStoreFronts: {
    response: [],
    totalPages: 0,
    totalItems: 0,
  },
  categories: [],
  contacts: [],
  selectedContacts: [],
  activeServiceProviders: [],
  selectedActiveSPs: [],
  documentList: [],
  duplicateFileName: false,
  allReferenceDocuments: false,
  isFilterActive: false,
  searchedService: null,
  searchedCity: null,
  serviceRequestDocuments: [],
  featuredStoreFrontsForWebsite: [],
  featuredStoreFrontsAddon: [],
  broadcastServiceRequestDocuments: [],
};

const homeBuyer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SAVE_HB_SINGUP_INFO:
      newState = cloneDeep(state);
      newState.singupInfo = action.payload;
      return newState;
    case SAVE_HB_SINGUP_USERINFO:
      newState = cloneDeep(state);
      newState.singupInfo = action.payload;
      return newState;
    case SET_EXPLORE_SERVICES:
      newState = cloneDeep(state);
      newState.services = action.payload.services;
      return newState;
    case SET_EXPLORE_CITIES:
      newState = cloneDeep(state);
      newState.cities = action.payload.cities;
      return newState;
    case SET_FEATURED_STORE_FRONTS:
      newState = cloneDeep(state);
      newState.featuredStoreFronts = action.payload.featuredStoreFronts;
      return newState;
    case SET_SEARCHED_STORE_FRONTS:
      newState = cloneDeep(state);
      if (action.payload.searchedStoreFronts.page) {
        newState.searchedStoreFronts.response =
          newState.searchedStoreFronts.response.concat(
            action.payload.searchedStoreFronts.response
          );
      } else {
        newState.searchedStoreFronts.response =
          action.payload.searchedStoreFronts.response;
      }

      newState.searchedStoreFronts.totalItems =
        action.payload.searchedStoreFronts.totalItems;
      newState.searchedStoreFronts.totalPages =
        action.payload.searchedStoreFronts.totalPages;
      return newState;
    case SET_CONTACTS:
      newState = cloneDeep(state);
      newState.contacts = action.payload.contacts;
      return newState;
    case SET_SELECTED_CONTACTS:
      newState = cloneDeep(state);
      newState.selectedContacts = action.payload;
      return newState;
    case SET_ACTIVE_SERVICE_PROVIDERS:
      newState = cloneDeep(state);
      newState.activeServiceProviders = action.payload.activeServiceProviders;
      return newState;
    case SET_SELECTED_ACTIVE_SP:
      newState = cloneDeep(state);
      newState.selectedActiveSPs = action.payload;
      return newState;
    case ADD_DOCUMENTS:
      newState = cloneDeep(state);
      if (!action.payload.isDuplicate) {
        newState.documentList = [
          ...newState.documentList,
          ...action.payload.files,
        ];
        newState.duplicateFileName = false;
      } else {
        newState.duplicateFileName = true;
      }
      return newState;
    case SET_REFERENCE_DOCUMENT:
      newState = cloneDeep(state);
      newState.documentList[action.payload.index].isRef =
        action.payload.checked;
      const allRef = newState.documentList.every((doc) => doc.isRef); // check if all the documents are marked as reference
      newState.allReferenceDocuments = allRef;
      return newState;
    case SET_DOCUMENT_CATEGORY:
      newState = cloneDeep(state);
      newState.documentList[action.payload.index].categoryId =
        action.payload.categoryId;
      return newState;
    case DELETE_DOCUMENT_WHILE_UPLOAD:
      newState = cloneDeep(state);
      newState.documentList.splice(action.payload.index, 1);
      return newState;
    case CLEAR_UPLOAD_FILE_DATA:
      newState = cloneDeep(state);
      newState.documentList = [];
      return newState;
    case UPDATE_UPLOADED_DOCS:
      newState = cloneDeep(state);

      //FIX ME: saili pls modify the logic below as needed
      for (var arr in newState.categories) {
        for (var filter in action.payload.category) {
          if (
            newState.categories[arr].id === action.payload.category[filter].id
          ) {
            console.log("TTTT", newState.categories[arr].documents);
            let abcd = newState.categories[arr].documents;
            abcd.push(action.payload.category[arr].documents);
          }
        }
      }
      return newState;
    case DELETE_CONTACTS:
      newState = cloneDeep(state);
      newState.selectedContacts.splice(action.payload.index, 1);
      return newState;
    case EXPLORE_PAGE_FILTER:
      newState = cloneDeep(state);
      newState.isFilterActive = action.payload;
      return newState;
    case SET_SEARCHED_CITY:
      newState = cloneDeep(state);
      newState.searchedCity = action.payload;
      return newState;
    case SET_SEARCHED_SERVICE:
      newState = cloneDeep(state);
      newState.searchedService = action.payload;
      return newState;
    case ADD_SERVICE_REQUEST_DCOUMENTS:
      newState = cloneDeep(state);
      const files = action.payload;
      if (!isEmpty(files)) {
        Object.keys(files).map((key) => {
          newState.serviceRequestDocuments.push(files[key]);
        });
      } else {
        newState.serviceRequestDocuments = [];
      }
      return newState;
    case DELETE_SERVICE_REQUEST_DCOUMENT:
      newState = cloneDeep(state);
      newState.serviceRequestDocuments.splice(action.payload, 1);
      return newState;

    case CLEAR_EXPLORE_SEARCH_RESULT:
      newState = cloneDeep(state);
      newState.featuredStoreFronts = initialState.featuredStoreFronts;
      newState.searchedStoreFronts = initialState.searchedStoreFronts;
      newState.searchedService = initialState.searchedService;
      newState.searchedCity = initialState.searchedCity;
      return newState;
    case SET_FEATURED_STORE_FRONTS_FOR_WEBSITE:
      newState = cloneDeep(state);
      newState.featuredStoreFrontsForWebsite =
        action.payload.featuredStoreFrontsForWebsite;
      return newState;
    case SET_FEATURED_STORE_FRONTS_ADD_ON:
      newState = cloneDeep(state);
      newState.featuredStoreFrontsAddon =
        action.payload.featuredStoreFrontsAddon;
      return newState;
    case ADD_BROADCAST_SERVICE_REQUEST_DOCUMENTS:
      newState = cloneDeep(state);
      const broadcastServiceFiles = action.payload;
      if (!isEmpty(broadcastServiceFiles)) {
        Object.keys(broadcastServiceFiles).map((key) => {
          newState.broadcastServiceRequestDocuments.push(
            broadcastServiceFiles[key]
          );
        });
      } else {
        newState.broadcastServiceRequestDocuments = [];
      }
      return newState;
    case DELETE_BROADCAST_SERVICE_REQUEST_DOCUMENT:
      newState = cloneDeep(state);
      newState.broadcastServiceRequestDocuments.splice(action.payload, 1);
      return newState;

    default:
      return state;
  }
};

export default homeBuyer;
