import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import Styles from "../../../Styles.module.css";
import AddIcon from "@material-ui/icons/Add";
import OfflineBoltOutlinedIcon from "@material-ui/icons/OfflineBoltOutlined";
import SmallCalendarWidget from "./SmallCalendarWidget";
import {
  MEETING_CREATE_MODE,
  MEETING_READ_MODE,
  SERVICE_PROVIDER_USER,
} from "../../ServiceProvider/serviceProviderConstants";
import { useAudioVideoCalling } from "souqh-react-redux-hooks/messaging/useAudioVideoCalling";
import { isMobileDevice, isPlusPlan, isProPlan } from "../../../utils/AppUtils";
import UpgradePlanIconSection from "../UpgradePlanIconSection";
import SyncCalendarDialog from "./SyncCalendarDialog";
import EditIcon from "@material-ui/icons/Edit";
import DateRangeIcon from "@material-ui/icons/DateRange";
import CircleIcon from "@mui/icons-material/Circle";
import { useCalendarSync } from "souqh-react-redux-hooks/calendarSync/useCalendarSync";
import WarningIcon from "@mui/icons-material/Warning";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    color: "#0E1C4E",
    textTransform: "capitalize",
  },
  arrowIcon: {
    width: 24,
    height: 24,
    cursor: "pointer",
    fill: "#ffffff",
  },
}));

export default function CalendarLeftPanel({
  currentCalendarDate,
  setCurrentCalendarDate,
  calendarEvents,
  setOpenCreateMeetingDialog,
  createInstantMeeting,
  setMeetingMode,
  setOpenInstantMeetingDailog,
  setMeetingLink,
  setModeratorMeetingLink,
  userInfo,
  accountDetails,
  setMainCalendarView,
}) {
  const classes = useStyles();
  const { getInstantMeetingLink } = useAudioVideoCalling();
  // const {
  //   getAccountDetails,
  //   // accountDetails,
  //   microsoftAuthorizeEmailApi,
  //   detectProvider,
  //   getSchedulingPages,
  // } = useCalendarSync();

  const [openSyncCalendarDialog, setOpenSyncCalendarDialog] = useState(false);

  // useEffect(() => {
  //   getAccountDetails();
  // }, []);

  const Nylas = require("nylas");

  Nylas.config({
    clientId: "aimf9hv1m0o0e68v97l1wofm4",
    clientSecret: "615ilxgkv5es6a55v0it48mkw",
  });

  const envName = process.env.REACT_APP_ENV_NAME;
  const redirectLink =
    envName === "PROD"
      ? "app.souqh.ca"
      : envName === "QA"
      ? "demo.souqh.co"
      : "development.souqh.co";

  const authorizeEmail = () => {
    const options = {
      redirectURI: `https://${redirectLink}/nylasAuth`,
      // loginHint: "kirthin777@gmail.com",
      responseType: "code",
      scopes: ["calendar", "email"],
      // provider: "gmail",
    };

    const authUrl = Nylas.urlForAuthentication(options);
    window.open(authUrl, "_self");
  };

  return (
    <Grid
      item
      xs={12}
      className={classes.root}
      style={{
        height: "100%",
        overflow: "hidden",
        padding: isMobileDevice() ? "18px 0px 16px 0px" : "48px 0px 16px 0px",
      }}
    >
      <SyncCalendarDialog
        openSyncCalendarDialog={openSyncCalendarDialog}
        setOpenSyncCalendarDialog={setOpenSyncCalendarDialog}
      />
      {!isMobileDevice() && (
        <Grid item xs={12} style={{ padding: "0px 20px" }}>
          <Typography
            variant="subtitle1"
            style={{ width: "95%", marginBottom: 12 }}
            className={clsx(Styles.sqEllipsis, Styles.navyBlueColor)}
          >
            Calendar
          </Typography>
        </Grid>
      )}
      {!isMobileDevice() && (
        <Grid item xs={12} style={{ marginBottom: 24 }}>
          <Divider />
        </Grid>
      )}
      <Grid item xs={12} style={{ marginBottom: 24, padding: "0px 20px" }}>
        <div style={{ position: "relative" }}>
          <Button
            // disabled={localStorage.getItem("instantMeetingDetails")}
            disabled={isProPlan(userInfo)}
            variant="outlined"
            startIcon={<OfflineBoltOutlinedIcon />}
            className={classes.button}
            style={{ marginBottom: 16, width: "100%" }}
            title="Coming soon"
            onClick={() => {
              if (!isProPlan(userInfo)) {
                if (localStorage.getItem("instantMeetingDetails")) {
                  setOpenInstantMeetingDailog(true);
                  let meetingDetails = JSON.parse(
                    localStorage.getItem("instantMeetingDetails")
                  );
                  setMeetingLink(meetingDetails.participantURL);
                  setModeratorMeetingLink(meetingDetails.moderatorURL);
                } else {
                  getInstantMeetingLink((res) => {
                    if (res.data.status === 200) {
                      setMeetingLink(res.data.result.participantURL);
                      setModeratorMeetingLink(res.data.result.moderatorURL);
                      setOpenInstantMeetingDailog(true);
                      localStorage.setItem(
                        "instantMeetingDetails",
                        JSON.stringify(res.data.result)
                      );
                      // Open link in new window
                      window.open(res.data.result.moderatorURL, "_blank");
                    }
                  });
                }
              }
            }}
          >
            Instant Meeting
          </Button>
          {isProPlan(userInfo) && (
            <UpgradePlanIconSection
              iconBtnStyle={{
                position: "absolute",
                fontSize: 16,
                left: 250,
                top: -12,
                fill: "#fa7e61",
              }}
              iconStyle={{
                position: "absolute",
                fontSize: 16,
                top: 4,
                fill: "#fa7e61",
              }}
            />
          )}
        </div>
        <div style={{ position: "relative" }}>
          <Button
            disabled={isProPlan(userInfo)}
            variant="outlined"
            startIcon={<AddIcon />}
            className={classes.button}
            style={{ width: "100%" }}
            onClick={(e) => {
              if (!isProPlan(userInfo)) {
                setMeetingMode(MEETING_CREATE_MODE);
                setOpenCreateMeetingDialog(true);
              }
            }}
          >
            Create Meeting
          </Button>
          {isProPlan(userInfo) && (
            <UpgradePlanIconSection
              iconBtnStyle={{
                position: "absolute",
                fontSize: 16,
                left: 250,
                top: -12,
                fill: "#fa7e61",
              }}
              iconStyle={{
                position: "absolute",
                fontSize: 16,
                top: 4,
                fill: "#fa7e61",
              }}
            />
          )}
        </div>
      </Grid>
      <Grid
        item
        container
        xs={12}
        style={{ padding: !isMobileDevice() && "0px 20px" }}
      >
        <SmallCalendarWidget
          eventsData={calendarEvents}
          width={"100%"}
          height={260}
          currentCalendarDate={currentCalendarDate}
          setCurrentCalendarDate={setCurrentCalendarDate}
          showToolbar={true}
          setMainCalendarView={setMainCalendarView}
        />
      </Grid>
      {/* {!accountDetails?.accountId &&
      userInfo.userType === SERVICE_PROVIDER_USER ? (
        <Grid
          item
          container
          xs={12}
          style={{ padding: !isMobileDevice() && "0px 20px", marginTop: 16 }}
          justifyContent="center"
        >
          <Button
            className={clsx(Styles.navyBlueColorForce, Styles.primaryBtnAccent)}
            style={{ textTransform: "capitalize", padding: "6px 32px" }}
            // onClick={() => setOpenSyncCalendarDialog(true)}
            onClick={() => authorizeEmail()}
            // onClick={() => detectProvider()}
            disabled={isProPlan(userInfo) || isPlusPlan(userInfo)}
          >
            Sync Calendar
          </Button>
          {(isProPlan(userInfo) || isPlusPlan(userInfo)) && (
            <UpgradePlanIconSection
              iconBtnStyle={{
                fontSize: 16,
                left: -14,
                top: -10,
                fill: "#fa7e61",
                // position: "absolute",
              }}
              iconStyle={{
                position: "absolute",
                fontSize: 16,
                top: 4,
                fill: "#fa7e61",
              }}
            />
          )}
          <Tooltip title="Sync your personal or work calendar here">
            <InfoOutlinedIcon
              style={{
                marginTop: 8,
                marginLeft:
                  isProPlan(userInfo) || isPlusPlan(userInfo) ? -8 : 12,
              }}
              className={Styles.navyBlueColor}
              fontSize="small"
            />
          </Tooltip>
        </Grid>
      ) : (
        accountDetails?.accountId && (
          <Grid
            item
            container
            xs={12}
            style={{ padding: !isMobileDevice() && "0px 20px", marginTop: 16 }}
            // justifyContent="center"
          >
            <Typography variant="subtitle1" className={Styles.navyBlueColor}>
              Linked Calendar
            </Typography>
            <Grid container item xs={12}>
              <Grid item xs={9}>
                <Tooltip title={accountDetails?.email}>
                  <Typography
                    className={clsx(Styles.navyBlueColor, Styles.sqEllipsis)}
                    style={{ fontSize: 14, maxWidth: "90%" }}
                  >
                    {accountDetails && accountDetails.email}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item xs={1}>
                {accountDetails && accountDetails.syncState === "running" ? (
                  <CircleIcon style={{ fontSize: 16, color: "#107E3E" }} />
                ) : (
                  <WarningIcon
                    style={{ fontSize: 16, color: "#e65100" }}
                    // className={Styles.sqErrorColor}
                  />
                )}
              </Grid>
              <Grid item xs={1}>
                <EditIcon
                  style={{
                    fontSize: 16,
                    cursor:
                      accountDetails && accountDetails.syncState === "running"
                        ? "default"
                        : "pointer",
                    color:
                      accountDetails &&
                      accountDetails.syncState === "running" &&
                      "#868da6",
                  }}
                  className={Styles.navyBlueColor}
                  onClick={() => {
                    if (
                      accountDetails &&
                      accountDetails.syncState !== "running"
                    ) {
                      authorizeEmail();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <DateRangeIcon
                  style={{ fontSize: 16, cursor: "pointer" }}
                  className={Styles.navyBlueColorForce}
                  onClick={async () => {
                    // eslint-disable-next-line no-undef
                    nylas.scheduler.show({
                      auth: {
                        // Account <ACCESS_TOKEN> with active calendar scope
                        accessToken:
                          accountDetails && accountDetails.accessToken,
                      },
                      style: {
                        // Style the Schedule Editor
                        tintColor: "#32325d",
                        backgroundColor: "white",
                      },
                      defaults: {
                        appearance: {
                          logo: "https://souqh-images.s3.ca-central-1.amazonaws.com/souqh_logo_withName.png",
                        },
                      },
                      // defaults: {
                      //   event: {
                      //     title: "30-min Coffee Meeting",
                      //     duration: 30,
                      //   },
                      // },
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        )
      )} */}
    </Grid>
  );
}
