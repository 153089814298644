import { useState, useEffect, useRef } from "react";

function useFeaturedStoreFront(featuredSFs, numberOfCards, open = true) {
  const [shuffledItems, setShuffledItems] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  let timer;

  const cardIterator = useRef(0);

  useEffect(() => {
    if (timer) clearTimeout(timer);
    cardIterator.current = activeIndex * numberOfCards;
    if(featuredSFs?.length) getNextElements(featuredSFs);
    else setShuffledItems([])
  }, [activeIndex, JSON.stringify(featuredSFs)]);

  useEffect(() => {
    if (featuredSFs?.length && open) {
      timer = setTimeout(
        () =>
          setActiveIndex((prev) =>
            prev >= Math.ceil(featuredSFs.length / numberOfCards) - 1
              ? 0
              : prev + 1
          ),
        10000
      );
    } else {
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
  });

  function getNextElements(storeFronts) {
    if (storeFronts?.length) {
      const finalIndex = cardIterator.current + numberOfCards;
      let randomItems = storeFronts.slice(cardIterator.current, finalIndex);
      setShuffledItems(randomItems);
    }
  }

  return { shuffledItems, activeIndex, setActiveIndex };
}

export default useFeaturedStoreFront;
