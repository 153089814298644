import React from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Grid } from "@material-ui/core";

export default function CustomCalendarToolBar(props) {
  const navigate = (action) => {
    props.onNavigate(action);
  };

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        padding: "8px 16px",
        backgroundColor: "#F5F6F8",
        color: "#0e1c4e",
        borderRadius: 8,
        alignItems: "center",
        marginBottom: 12,
        flexBasis: "unset",
      }}
    >
      <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
        <ArrowBackIosIcon
          style={{
            width: 20,
            height: 20,
            cursor: "pointer",
            color: "#0e1c4e",
          }}
          onClick={() => {
            navigate("PREV");
          }}
        />
      </Grid>
      <Grid
        item
        xs={10}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#0e1c4e",
          fontWeight: 600,
        }}
      >
        {props.label}
      </Grid>
      <Grid
        item
        xs={1}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <ArrowForwardIosIcon
          style={{
            width: 20,
            height: 20,
            cursor: "pointer",
            color: "#0e1c4e",
          }}
          onClick={() => {
            navigate("NEXT");
          }}
        />
      </Grid>
    </Grid>
  );
}
