import {
  Divider,
  Grid,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import React, { useEffect } from "react";
import Styles from "../../../Styles.module.css";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";
import { useTrafficAnalytics } from "souqh-react-redux-hooks/serviceProvider/analytics/useTrafficAnalytics";

import {
  ResponsiveContainer,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  AreaChart,
  BarChart,
  Bar,
  Cell,
} from "recharts";
import NoDataAvailable from "../../common/NoDataAvailable";

export default function TrafficAnalytics({ days }) {
  const {
    getTrafficAnalyticsData,
    trafficAnalyticsData,
    finalTimeTrafficData,
    getTrafficByLocationData,
    finalLocationTrafficData,
    trafficByLocationPreference,
    setTrafficByLocationPreference,
  } = useTrafficAnalytics(days);

  useEffect(() => {
    getTrafficAnalyticsData();
    getTrafficByLocationData();
  }, [days]);

  const tickFormatter = (value, index) => {
    const limit = 7;
    if (value.length <= limit) return value;
    return `${value.substring(0, limit)}...`;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="tooltip" style={{ backgroundColor: "white" }}>
          <p className="tooltipLabel">{`${payload[0].payload?.xValue}`}</p>
          <p className="tooltipDesc">{`${payload[0]?.payload?.value}`}</p>
        </div>
      );
    }

    return null;
  };

  const handleChange = (event) => {
    setTrafficByLocationPreference(event.target.value);
  };

  return (
    <Grid
      container
      spacing={3}
      style={{
        marginTop: 12,
        paddingRight: !isMobileDevice() && !isTabletDevice() && 20,
      }}
    >
      <Grid item xs={12} md={4}>
        <Paper
          elevation={0}
          style={{
            border:
              (isMobileDevice() || isTabletDevice()) && "1px solid #0000001F",
            height: 200,
          }}
        >
          <Grid item xs={12} style={{ padding: "16px 0px 8px 0px" }}>
            <Typography
              align="center"
              className={Styles.navyBlueColor}
              style={{ fontWeight: 600 }}
            >
              Total Storefront Visits
            </Typography>
          </Grid>
          <Divider />
          <Grid item xs={12} style={{ padding: "24px 0px 48px 0px" }}>
            <Typography
              variant="h1"
              align="center"
              className={Styles.navyBlueColor}
            >
              {(trafficAnalyticsData &&
                trafficAnalyticsData.totalStorefrontVisits) ||
                0}
            </Typography>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper
          elevation={0}
          style={{
            border:
              (isMobileDevice() || isTabletDevice()) && "1px solid #0000001F",
            height: 200,
          }}
        >
          <Grid item xs={12} style={{ padding: "16px 0px 8px 0px" }}>
            <Typography
              align="center"
              className={Styles.navyBlueColor}
              style={{ fontWeight: 600 }}
            >
              User Engagement
            </Typography>
          </Grid>
          <Divider />
          <Grid
            item
            xs={12}
            style={{ padding: "24px 0px 48px 0px", textAlign: "center" }}
          >
            <Typography
              variant="h1"
              align="center"
              className={Styles.navyBlueColor}
            >
              {(trafficAnalyticsData && trafficAnalyticsData.userEngagements) ||
                0}
            </Typography>
            <Typography
              variant="body1"
              align="center"
              className={Styles.navyBlueColor}
            >
              Clicks
            </Typography>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper
          elevation={0}
          style={{
            border:
              (isMobileDevice() || isTabletDevice()) && "1px solid #0000001F",
            height: 200,
          }}
        >
          <Grid item xs={12} style={{ padding: "16px 0px 8px 0px" }}>
            <Typography
              align="center"
              className={Styles.navyBlueColor}
              style={{ fontWeight: 600 }}
            >
              Average Response Time
            </Typography>
          </Grid>
          <Divider />
          <Grid
            item
            xs={12}
            style={{ padding: "24px 0px 48px 0px", textAlign: "center" }}
          >
            <Typography
              variant="h1"
              align="center"
              className={Styles.navyBlueColor}
            >
              {(trafficAnalyticsData &&
                trafficAnalyticsData.averageResponseTime) ||
                0}
            </Typography>
            <Typography
              variant="body1"
              align="center"
              className={Styles.navyBlueColor}
            >
              Days
            </Typography>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            border:
              (isMobileDevice() || isTabletDevice()) && "1px solid #0000001F",
          }}
        >
          <Grid item xs={12} style={{ padding: "16px 0px 8px 0px" }}>
            <Typography
              align="center"
              className={Styles.navyBlueColor}
              style={{ fontWeight: 600 }}
            >
              Traffic Over Time
            </Typography>
          </Grid>
          <Divider />
          <Grid
            item
            xs={12}
            style={{
              padding: "24px 0px 48px 0px",
              textAlign: "center",
              height: "18.5rem",
            }}
          >
            {finalTimeTrafficData.every((item) => item.value === 0) ? (
              <NoDataAvailable isSFAnalyticsPage={true} />
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  data={finalTimeTrafficData}
                  margin={{ top: 5, right: 20, bottom: 20, left: 0 }}
                  dataKey="value"
                  nameKey="xValue"
                >
                  <Line
                    type="monotone"
                    dataKey="value"
                    stroke="#8884d8"
                    dot={true}
                  />
                  <XAxis
                    dataKey="year"
                    textAnchor="end"
                    sclaeToFit="true"
                    verticalAnchor="start"
                    interval={0}
                    angle="-30"
                    style={{ fontSize: isMobileDevice() && 12 }}
                  />
                  <YAxis allowDecimals={false} />
                  <Tooltip content={<CustomTooltip />} />
                  <Area
                    type="monotone"
                    dataKey="value"
                    stroke="#8884d8"
                    fill="#C9CFF3"
                  />
                </AreaChart>
              </ResponsiveContainer>
            )}
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            border:
              (isMobileDevice() || isTabletDevice()) && "1px solid #0000001F",
          }}
        >
          <Grid
            container
            item
            xs={12}
            style={{
              padding: isMobileDevice()
                ? "16px 8px 8px 8px"
                : "16px 0px 8px 0px",
            }}
          >
            <Grid
              container
              item
              xs={8}
              md={9}
              justifyContent={isMobileDevice() ? "flex-start" : "flex-end"}
              alignItems="center"
              style={{
                paddingRight: !isMobileDevice() && "18%",
                paddingLeft: isMobileDevice() && "2%",
              }}
            >
              <Typography
                align="center"
                className={Styles.navyBlueColor}
                style={{ fontWeight: 600 }}
              >
                Traffic By Location
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={4}
              md={3}
              justifyContent={"flex-end"}
              alignItems="center"
              style={{ paddingRight: !isMobileDevice() && 16 }}
            >
              <FormControl>
                <Select
                  value={trafficByLocationPreference}
                  onChange={handleChange}
                  displayEmpty
                >
                  <MenuItem value={"City"}>City</MenuItem>
                  <MenuItem value={"Province"}>Province</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Divider />
          <Grid
            item
            xs={12}
            style={{
              padding: !isMobileDevice() && "24px 0px 48px 0px",
              textAlign: "center",
              height: !isMobileDevice() && "25rem",
            }}
          >
            {!finalLocationTrafficData.length ? (
              <NoDataAvailable isSFAnalyticsPage={true} />
            ) : isMobileDevice() ? (
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ fontWeight: "bold" }}
                        className={Styles.navyBlueColorForce}
                      >
                        LOCATION
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ fontWeight: "bold" }}
                        className={Styles.navyBlueColorForce}
                      >
                        USERS
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {finalLocationTrafficData.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell
                          component="th"
                          scope="row"
                          className={Styles.navyBlueColorForce}
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={Styles.navyBlueColorForce}
                        >
                          {row.value}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={finalLocationTrafficData}
                  layout="horizontal"
                  margin={{
                    top: 15,
                    right: !isMobileDevice() && 20,
                    left: isMobileDevice() ? -30 : 30,
                    bottom: 40,
                  }}
                >
                  <Tooltip
                    cursor={{ fill: "transparent" }}
                    content={<CustomTooltip />}
                  />
                  <XAxis
                    yAxisId={0}
                    dataKey="name"
                    interval={0}
                    type="category"
                    tickLine={false}
                    tickFormatter={tickFormatter}
                    angle="-50"
                    dy={20}
                    style={{ fontSize: isMobileDevice() && 12 }}
                  />
                  <YAxis type="number" interval={0} />

                  <Bar dataKey="value" minPointSize={2} barSize={32}>
                    {finalLocationTrafficData.map((d, idx) => {
                      return <Cell fill="#C9CFF3" />;
                    })}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
