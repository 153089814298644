import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";

export const PENDING_ACTION_HEADERS = [
  {
    id: "id",
    label: "#",
    width: "1%",
  },
  {
    id: "requestedDate",
    label: "Requested Date",
    width: isMobileDevice() || isTabletDevice() ? "9em" : "8%",
  },
  // {
  //   id: "dueDate",
  //   label: "Due Date",
  //   width: isMobileDevice() || isTabletDevice() ? "9em" : "8%",
  // },
  {
    id: "clientName",
    label: "Client",
    width: isMobileDevice() || isTabletDevice() ? "10em" : "13%",
  },
  {
    id: "eventDescription",
    label: "Event Description",
    width: isMobileDevice() || isTabletDevice() ? "15em" : "23%",
  },
  {
    id: "",
    label: "",
    width: "1%",
  },
];

export const UPCOMING_EVENTS_HEADERS = [
  {
    id: "id",
    label: "#",
    width: "2%",
  },
  {
    id: "startDateTime",
    label: "Date and Time",
    width: isMobileDevice() || isTabletDevice() ? "16em" : "25%",
  },
  {
    id: "attendees",
    label: "Attendees",
    width: isMobileDevice() || isTabletDevice() ? "14em" : "30%",
  },
  {
    id: "title",
    label: "Title",
    width: isMobileDevice() || isTabletDevice() ? "13em" : "35%",
  },
  {
    id: "",
    label: "",
    width: "5%",
  },
];

export const MOVING_CHECKLIST_HEADERS = [
  {
    id: "id",
    label: "#",
    width: "1%",
  },
  {
    id: "eventDescription",
    label: "Event Description",
    width: isMobileDevice() || isTabletDevice() ? "15em" : "23%",
  },
  {
    id: "",
    label: "",
    width: "1%",
  },
];

