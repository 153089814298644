import React, { useEffect } from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import {
  Typography,
  IconButton,
  Dialog,
  Grid,
  makeStyles,
  Button,
} from "@material-ui/core/";
import Styles from "../../../Styles.module.css";
import { useSignDocument } from "souqh-react-redux-hooks/serviceProvider/documents/useSignDocument";
import GetAppIcon from "@material-ui/icons/GetApp";
import clsx from "clsx";
import { useSQQuery } from "souqh-react-redux-hooks/common/useSQQuery";

const styles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    padding: 0,
  },
  dialogPaper: {
    height: window.innerHeight,
    borderRadius: 0,
  },
  diaogFooter: {
    padding: "12px 36px",
  },
  dialogRoot: {
    padding: 0,
  },
  selectedIcon: {
    fontWeight: "bold",
    backgroundColor: "#FA7E61",
  },
  nonSelectedIcon: {
    backgroundColor: "white",
  },
  default: {
    marginLeft: 7,
    padding: "1px 12px 1px 12px",
    borderRadius: "4px",
    fontWeight: "bold",
  },
  selectedText: {
    color: "#FA7E61",
  },
}));

const DialogTitle = (props) => {
  const { children, classes, closeCurrentDialog, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="subtitle1">{children}</Typography>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={() => {
          closeCurrentDialog();
        }}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
};

export default function SignDocumentDialog({
  openSignDocumentDialog,
  setOpenSignDocumentDialog,
  updateIntercomStatus,
  getTabWiseCategoriesList,
  setDocumentTabName,
  signDocumentItem,
  setSignDocumentItem,
  documentTabName,
  reloadPendingActionData,
}) {
  const classes = styles();
  const { query } = useSQQuery();

  let docStatusLabel = "View Document - ";
  const {
    closeAndFinishSignDialog,
    eventType,
    processListener,
    connectToSocket,
    loadSignnowUrl,
    urlForSign,
    setUrlForSign,
    downloadSignedDocument,
  } = useSignDocument(reloadPendingActionData);
  const _documentId = query.get("documentId");
  const _userId = query.get("userId");

  useEffect(() => {
    updateIntercomStatus && updateIntercomStatus(openSignDocumentDialog);
    if (openSignDocumentDialog) {
      if (signDocumentItem) {
        loadSignnowUrl(signDocumentItem.signNowDocumentId);
        connectToSocket();
      } else {
        if (_userId && _documentId) {
          loadSignnowUrl(_documentId, _userId);
          connectToSocket();
        }
      }
    } else {
      setSignDocumentItem && setSignDocumentItem(null);
      setUrlForSign(null);
    }
  }, [openSignDocumentDialog]);

  useEffect(() => {
    if (eventType) {
      processListener(signDocumentItem, closeCurrentDialog);
    }
  }, [eventType]);

  const closeCurrentDialog = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    setOpenSignDocumentDialog(false);
    setSignDocumentItem(null);
    closeAndFinishSignDialog();
    if ((docStatusLabel || "").indexOf("View Document") > -1) {
      // To do
    } else {
      getTabWiseCategoriesList && getTabWiseCategoriesList(documentTabName);
    }
  };

  if (signDocumentItem) {
    if (signDocumentItem.isTemplate) {
      docStatusLabel = "View/Edit Template - ";
    } else {
      const status = (signDocumentItem.docStatus || "").trim().toLowerCase();
      if (
        status === "signed" ||
        status === "completed" ||
        status === "waiting for other"
      ) {
        docStatusLabel = "View Document - ";
      } else if (status === "waiting for sign") {
        docStatusLabel = "Sign Document - ";
      }
      if (urlForSign && urlForSign.documentUrl) {
        docStatusLabel = "View Document - ";
      }
    }
  }
  return (
    <Dialog
      fullScreen
      onClose={closeCurrentDialog}
      aria-labelledby="customized-dialog-title"
      open={openSignDocumentDialog}
      maxWidth="lg"
      classes={{ paper: classes.dialogPaper, root: classes.dialogRoot }}
    >
      <DialogTitle
        classes={classes}
        id="customized-dialog-title"
        className={Styles.navyBlueColor}
        closeCurrentDialog={closeCurrentDialog}
      >
        {docStatusLabel}
        <span style={{ fontWeight: 400 }}>
          {signDocumentItem ? signDocumentItem.docName : ""}
        </span>
      </DialogTitle>
      <MuiDialogContent dividers className={classes.dialogContent}>
        <Grid container style={{ height: "100%" }}>
          <Grid item xs={12} style={{ height: "calc(100% - 0px)" }}>
            <Grid style={{ padding: "0px", width: "99%", height: "99%" }}>
              {(signDocumentItem || query.get("documentId")) && urlForSign ? (
                urlForSign.iframeUrl ? (
                  <iframe src={urlForSign.iframeUrl} width="100%" height="100%">
                    iframe is not supported
                  </iframe>
                ) : (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: 16,
                    }}
                  >
                    <img
                      src={urlForSign.documentUrl}
                      style={{ width: "100%", height: "auto" }}
                    />
                  </Grid>
                )
              ) : (
                "Loading..."
              )}
            </Grid>
          </Grid>
        </Grid>
      </MuiDialogContent>
      <MuiDialogActions className={classes.diaogFooter}>
        <Button
          variant="outlined"
          startIcon={<GetAppIcon style={{ fill: "#0e1c4e" }} />}
          style={{ textTransform: "capitalize", padding: "6px 20px" }}
          className={clsx(Styles.navyBlueColorForce)}
          onClick={() => {
            if (_userId && _documentId && !signDocumentItem) {
              downloadSignedDocument(_documentId);
            } else {
              downloadSignedDocument(signDocumentItem.signNowDocumentId);
            }
          }}
        >
          Download full document
        </Button>

        <Button
          variant="outlined"
          style={{
            textTransform: "capitalize",
            padding: "6px 32px",
          }}
          className={clsx(Styles.navyBlueColorForce, Styles.primaryBtnAccent)}
          onClick={() => {
            closeCurrentDialog();
          }}
        >
          Close
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
}
