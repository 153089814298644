import { cloneDeep } from "lodash";
import { SET_ALL_USERS, RESET_ALL_USERS_DATA } from "./actionConstants";

const initialState = {
  isLoadedAllUserData: false,
  loadingUsersData: false,
  allSouqhUsers: [],
};

const allUsers = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_ALL_USERS:
      newState = cloneDeep(state);
      newState.isLoadedAllUserData = action.payload.isLoadedAllUserData;
      newState.loadingUsersData = action.payload.loadingUsersData;
      newState.allSouqhUsers = action.payload.data;
      return newState;
    case RESET_ALL_USERS_DATA:
      newState = cloneDeep(state);
      newState.newState = {
        isLoadedAllUserData: false,
        loadingUsersData: false,
        allSouqhUsers: [],
      };
      return newState;
    case RESET_ALL_USERS_DATA:
      newState = cloneDeep(state);
      newState.loadingUsersData = action.payload;
      return newState;
    default:
      return state;
  }
};

export default allUsers;
