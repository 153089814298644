import React, { useState } from "react";
import StarsIcon from "@mui/icons-material/Stars";
import { IconButton } from "@material-ui/core";
import UpgradePlanDialog from "./UpgradePlanDialog";

export default function UpgradePlanIconSection({ iconBtnStyle, iconStyle }) {
  const [openUpgradePlanDialog, setOpenUpgradePlanDialog] = useState(false);

  return (
    <React.Fragment>
      <UpgradePlanDialog
        openUpgradePlanDialog={openUpgradePlanDialog}
        setOpenUpgradePlanDialog={setOpenUpgradePlanDialog}
      />
      <IconButton
        onClick={(event) => {
          event && event.stopPropagation();
          setOpenUpgradePlanDialog(true);
        }}
        style={iconBtnStyle}
      >
        <StarsIcon style={iconStyle} />
      </IconButton>
    </React.Fragment>
  );
}
