import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Styles from "../../../Styles.module.css";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { useExplore } from "souqh-react-redux-hooks/homeBuyer/useExplore";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CancelIcon from "@material-ui/icons/Cancel";
import { useHistory } from "react-router-dom";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useBroadcastServiceRequest } from "souqh-react-redux-hooks/homeBuyer/useBroadcastServiceRequest";
import MaskedMobileInput from "../../MaskedMobileInput";
import EmailCheck from "../../EmailCheck";
import BroadcastConfirmationDialog from "./BroadcastConfirmationDialog";

const useStyles = makeStyles((theme) => ({
  inputs: {
    marginTop: 0,
    marginBottom: 0,
  },

  textInput: {
    "& .MuiInput-underline:before": {
      // borderBottom: "none",
    },
  },
  marginTop20: {
    marginTop: 20,
  },
}));

export default function RentalInquiryFormDialog({
  openRentalInquiryFormDialog,
  setOpenRentalInquiryFormDialog,
  userInfo,
  defaultSearchedCity,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [residingInCanadaValue, setResidingInCanadaValue] = useState(false);
  const [searchedCity, setSearchedCity] = useState(null);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [haveLocalGuarantorValue, setHaveLocalGuarantorValue] = useState(false);
  const [guarantorName, setGuarantorName] = useState("");
  const [guarantorAddress, setGuarantorAddress] = useState("");
  const [guarantorPhoneNumber, setGuarantorPhoneNumber] = useState("");
  const [guarantorEmail, setGuarantorEmail] = useState("");
  const [guarantorOwnHomeValue, setGuarantorOwnHomeValue] = useState(false);
  const [securityDepositMonthsValue, setSecurityDepositMonthsValue] =
    useState("");
  const [languageRestrictionValue, setLanguageRestrictionValue] =
    useState(false);
  const [currentlyEmployedValue, setCurrentlyEmployedValue] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [position, setPosition] = useState("");
  const [salaryRangeValue, setSalaryRangeValue] = useState("");
  const [provideCompanyLetterValue, setProvideCompanyLetterValue] =
    useState(false);
  const [rentalBudgetValue, setRentalBudgetValue] = useState("");
  const [preferredLocationCity, setPreferredLocationCity] = useState(null);
  const [timelineOption, setTimelineOption] = useState(null);
  const [typeOfRentalHomeValue, setTypeOfRentalHomeValue] = useState("");
  const [bedroomsNeededValue, setBedroomsNeededValue] = useState("");
  const [bathroomsNeededValue, setBathroomsNeededValue] = useState("");
  const [creditReportAvailableValue, setCreditReportAvailableValue] =
    useState(false);
  const [shareBankStatementsValue, setShareBankStatementsValue] =
    useState(false);
  const [originCountry, setOriginCountry] = useState("");
  const [filedBankruptcyValue, setFiledBankruptcyValue] = useState(false);
  const [evictedValue, setEvictedValue] = useState(false);
  const [comments, setComments] = useState("");

  const [openBroadcastConfirmationDialog, setOpenBroadcastConfirmationDialog] =
    useState(false);
  const { cities, getCitiesList, services, getServicesList } = useExplore();

  const {
    createBroadcastService,
    actions: { addFiles, deleteFile },
    broadcastServiceRequestDocuments,
    validations,
    phoneNumber,
    setPhoneNumber,
    email,
    setEmail,
    name,
    setName,
    getMatchingServiceProviderDetails,
    matchingSPCount,
    matchingSPEmails,
    setMatchingSPEmails,
  } = useBroadcastServiceRequest();

  useEffect(() => {
    // getServicesList();
    getCitiesList();
  }, []);

  useEffect(() => {
    if (defaultSearchedCity) {
      setPreferredLocationCity(defaultSearchedCity);
    }
  }, [defaultSearchedCity]);

  const clearData = () => {
    setResidingInCanadaValue(false);
    setSearchedCity(null);
    setSelectedIDs([]);
    setHaveLocalGuarantorValue(false);
    setGuarantorOwnHomeValue(false);
    setSecurityDepositMonthsValue("");
    setLanguageRestrictionValue(false);
    setCurrentlyEmployedValue(false);
    setSalaryRangeValue("");
    setProvideCompanyLetterValue(false);
    setRentalBudgetValue("");
    setPreferredLocationCity(null);
    setTypeOfRentalHomeValue("");
    setBedroomsNeededValue("");
    setBathroomsNeededValue("");
    setCreditReportAvailableValue(false);
    setShareBankStatementsValue(false);
    setOriginCountry("");
    setFiledBankruptcyValue(false);
    setEvictedValue(false);
    setComments("");
    setTimelineOption(null);
    setGuarantorName("");
    setGuarantorAddress("");
    setGuarantorPhoneNumber("");
    setGuarantorEmail("");
    setPhoneNumber("");
    setEmail("");
    setName("");
    setCompanyName("");
    setPosition("");
  };

  let filteredCities = cities.filter(
    (item) => item.cityDTO[0].name !== "All Cities"
  );

  const handleChange = (event) => {
    setResidingInCanadaValue(str2bool(event.target.value));
  };

  let IDDocs = [
    "Drivers License",
    "PR Card",
    "SIN Card",
    "Work Permit",
    "Proof of Visa",
  ];

  let securityDepositOptions = [
    "first/last month only",
    "3 months",
    "6 months",
    "12 months",
  ];

  let salaryRangeOptions = ["$25K-$50K", "$50K-75K", "$75K-100K", "$100K+"];

  let rentalBudgetOptions = [
    "$1K-1.5K",
    "$1.5K-2K",
    "$2K-$2.5K",
    "$2.5K-$3K",
    "$3K-$3.5K",
    "$3.5K-4K",
    "$4K+",
  ];

  let roomOptions = ["1", "2", "3", "4", "4+"];

  let typeOfRentalHomeOptions = [
    "Apartment",
    "Town Home",
    "Semi-detached",
    "Detached",
  ];

  const timelineOptions = [
    { name: "As Soon As Possible", value: "AS_SOON_AS_POSSIBLE" },
    { name: "2-4 weeks", value: "TWO_TO_FOUR_WEEKS" },
    { name: "4-6 weeks", value: "FOUR_TO_EIGHT_WEEKS" },
    { name: "6-8 weeks", value: "FOUR_TO_EIGHT_WEEKS" },
    { name: "8+ weeks", value: "EIGHT_WEEKS_PLUS" },
  ];

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  let rentalForNewComersDetails = {
    canadaResident: residingInCanadaValue,
    currentCityId: searchedCity?.cityDTO[0].id,
    identificationDocuments: selectedIDs,
    budget: rentalBudgetValue,
    typeOfRentalHome: typeOfRentalHomeValue,
    numberOfBedrooms: bedroomsNeededValue,
    numberOfBathrooms: bathroomsNeededValue,
    guarantorAvailable: haveLocalGuarantorValue,
    guarantorName: name,
    guarantorAddress: guarantorAddress,
    guarantorPhoneNumber: phoneNumber,
    guarantorEmail: email,
    guarantorOwnHome: guarantorOwnHomeValue,
    securityDepositDetail: securityDepositMonthsValue,
    laguageRestriction: languageRestrictionValue,
    employed: currentlyEmployedValue,
    companyName: companyName,
    position: position,
    salaryRange: salaryRangeValue,
    companyLetterAvailable: provideCompanyLetterValue,
    creditReportAvailable: creditReportAvailableValue,
    bankStatementsSharable: shareBankStatementsValue,
    countryOfOrigin: originCountry,
    filedBankruptcyInCanada: filedBankruptcyValue,
    everBeenEvicted: evictedValue,
  };

  console.log(rentalForNewComersDetails);

  const str2bool = (value) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  };

  let defaultCategory = {
    id: 1,
    name: "Real Estate Broker / Agent",
    shortName: "Realtor",
    logoUrl: "Realtor.svg",
    isBusinessType: true,
    sequenceNumber: 1,
    storefrontPathName: "real-estate-agent",
    noOfServiceProviders: 333,
  }; // searchedCategory defaulted to Real Estate Broker / Agent

  return (
    <>
      {openBroadcastConfirmationDialog && (
        <BroadcastConfirmationDialog
          openBroadcastConfirmationDialog={openBroadcastConfirmationDialog}
          setOpenBroadcastConfirmationDialog={
            setOpenBroadcastConfirmationDialog
          }
          createBroadcastService={createBroadcastService}
          searchedCategory={defaultCategory}
          searchedCity={preferredLocationCity}
          timelineOption={timelineOption}
          description={comments}
          clearData={clearData}
          getMatchingServiceProviderDetails={getMatchingServiceProviderDetails}
          matchingSPCount={matchingSPCount}
          setOpenRentalInquiryFormDialog={setOpenRentalInquiryFormDialog}
          rentalForNewComersDetails={rentalForNewComersDetails}
          matchingSPEmails={matchingSPEmails}
          setMatchingSPEmails={setMatchingSPEmails}
          broadcastServiceRequestDocuments={broadcastServiceRequestDocuments}
        />
      )}
      <Dialog fullWidth open={openRentalInquiryFormDialog} maxWidth={"sm"}>
        <DialogTitle>
          <Grid item container xs={12}>
            {userInfo && (
              <Grid item xs={11}>
                <Typography
                  variant="subtitle1"
                  className={Styles.navyBlueColorForce}
                >
                  Rental Inquiry Form
                </Typography>
              </Grid>
            )}
            <Grid
              item
              xs={userInfo ? 1 : 12}
              container
              style={{ justifyContent: "flex-end" }}
            >
              <Button
                style={{ padding: 0, minWidth: 0 }}
                onClick={() => {
                  //     setUserEngagementData &&
                  //       setUserEngagementData(
                  //         storeFrontId,
                  //         "Service_Request_Cancelled"
                  //       );
                  //     setOpenserviceRequestDialog({ open: false, service: null });
                  //     if (nonloggedInUser) {
                  //       localStorage.removeItem("nonloggedInUser");
                  //       history.push("/app/homebuyer/explore");
                  //     }
                  //     setServiceSelected(null);
                  //     clearData();
                  setOpenRentalInquiryFormDialog(false);
                  clearData();
                }}
              >
                <CloseIcon className={Styles.navyBlueColorForce} />
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>

        {userInfo && <Divider />}
        <DialogContent
          className={Styles.navyBlueColorForce}
          // style={{ minHeight: userInfo ? 350 : 70 }}
        >
          {userInfo ? (
            <>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  className={Styles.navyBlueColorForce}
                >
                  Personal Details:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={Styles.navyBlueColorForce}>
                  Are you Currently residing in Canada
                </Typography>
              </Grid>
              <RadioGroup
                style={{
                  alignItems: "center",
                  flexDirection: "row",
                  marginLeft: 24,
                }}
                value={residingInCanadaValue}
                onChange={handleChange}
              >
                <FormControlLabel
                  value={true}
                  control={
                    <Radio
                      checked={residingInCanadaValue}
                      color={Styles.navyBlueColor}
                      style={{ marginLeft: -24 }}
                    />
                  }
                  label="Yes"
                  className={Styles.navyBlueColor}
                />
                <FormControlLabel
                  value={false}
                  control={
                    <Radio
                      checked={!residingInCanadaValue}
                      color={Styles.navyBlueColor}
                    />
                  }
                  label="No"
                  className={Styles.navyBlueColor}
                />
              </RadioGroup>
              {residingInCanadaValue && (
                <Autocomplete
                  options={filteredCities || []}
                  value={searchedCity}
                  // getOptionLabel={(option) => option.cityDTO[0].name || ""}
                  getOptionLabel={(option) => {
                    return `${option.cityDTO[0].shortName} (${option.name})`;
                  }}
                  id="city"
                  debug
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      className={clsx(classes.inputs, classes.textInput)}
                      placeholder="Which city?"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, value) => {
                    setSearchedCity(value);
                  }}
                  getOptionSelected={(option, value) => {
                    return option?.cityDTO[0]?.name === value?.cityDTO[0].name;
                  }}
                  style={{ width: "410px" }}
                  className={classes.marginTop20}
                />
              )}
              <Grid item container xs={12}>
                <Typography
                  className={Styles.navyBlueColor}
                  style={{
                    marginTop: 10,
                  }}
                >
                  Which of the following Identification Documents do you
                  currently have
                </Typography>
              </Grid>
              <Autocomplete
                multiple
                required
                id="tags-standard"
                options={IDDocs || []}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                getOptionSelected={(option, value) => {
                  return option === value;
                }}
                limitTags={3}
                value={selectedIDs}
                // inputValue={inputValueCities}
                // onInputChange={(event, newInputValue) => {
                //   setInputValueCities(newInputValue);
                // }}
                onChange={(e, v) => {
                  setSelectedIDs(v);
                }}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      color={Styles.navyBlueColorForce}
                    />
                    <span>{option}</span>
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select IDs"
                  />
                )}
              />

              <Grid
                item
                xs={12}
                style={{
                  marginTop: 20,
                }}
              >
                <Typography
                  variant="subtitle1"
                  className={Styles.navyBlueColorForce}
                >
                  Rental Details:
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  marginTop: 10,
                }}
              >
                <Typography className={Styles.navyBlueColorForce}>
                  What is your budget for the rental?
                </Typography>
              </Grid>
              <Autocomplete
                fullWidth
                value={rentalBudgetValue}
                onChange={(e, value) => {
                  setRentalBudgetValue(value);
                }}
                id={"rental-budget"}
                options={rentalBudgetOptions}
                getOptionLabel={(option) => option}
                getOptionSelected={(option, value) => {
                  return option === value;
                }}
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} label="Select" />
                )}
                required
              />

              <Grid
                item
                xs={12}
                style={{
                  marginTop: 10,
                }}
              >
                <Typography className={Styles.navyBlueColorForce}>
                  What is your preferred location?
                </Typography>
              </Grid>
              <Autocomplete
                options={filteredCities || []}
                value={preferredLocationCity}
                // getOptionLabel={(option) => option.cityDTO[0].name || ""}
                getOptionLabel={(option) => {
                  return `${option.cityDTO[0].shortName} (${option.name})`;
                }}
                id="city"
                debug
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    className={clsx(classes.inputs, classes.textInput)}
                    placeholder="Which city?"
                    variant="outlined"
                  />
                )}
                onChange={(event, value) => {
                  setPreferredLocationCity(value);
                }}
                getOptionSelected={(option, value) => {
                  return option?.cityDTO[0]?.name === value?.cityDTO[0].name;
                }}
                style={{ width: "410px" }}
                // className={classes.marginTop20}
                required
              />
              <Grid
                item
                xs={12}
                style={{
                  marginTop: 10,
                }}
              >
                <Typography className={Styles.navyBlueColorForce}>
                  When are you looking to get into a rental home?
                </Typography>
              </Grid>
              <Autocomplete
                fullWidth
                value={timelineOption}
                onChange={(e, value) => {
                  setTimelineOption(value);
                }}
                id={"rental-budget"}
                options={timelineOptions || []}
                getOptionLabel={(option) => option.name || ""}
                getOptionSelected={(option, value) => {
                  return option === value;
                }}
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} label="Select" />
                )}
                required
              />
              <Grid
                item
                xs={12}
                style={{
                  marginTop: 10,
                }}
              >
                <Typography className={Styles.navyBlueColorForce}>
                  What type of rental home are you looking for?
                </Typography>
              </Grid>
              <Autocomplete
                fullWidth
                value={typeOfRentalHomeValue}
                onChange={(e, value) => {
                  setTypeOfRentalHomeValue(value);
                }}
                id={"type-of-rental-home"}
                options={typeOfRentalHomeOptions}
                getOptionLabel={(option) => option}
                getOptionSelected={(option, value) => {
                  return option === value;
                }}
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} label="Select" />
                )}
                required
              />
              <Grid
                item
                xs={12}
                style={{
                  marginTop: 10,
                }}
              >
                <Typography className={Styles.navyBlueColorForce}>
                  How many bedrooms do you need?
                </Typography>
              </Grid>
              <Autocomplete
                fullWidth
                value={bedroomsNeededValue}
                onChange={(e, value) => {
                  setBedroomsNeededValue(value);
                }}
                id={"bedrooms-needed"}
                options={roomOptions}
                getOptionLabel={(option) => option}
                getOptionSelected={(option, value) => {
                  return option === value;
                }}
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} label="Select" />
                )}
                required
              />
              <Grid
                item
                xs={12}
                style={{
                  marginTop: 10,
                }}
              >
                <Typography className={Styles.navyBlueColorForce}>
                  How many bathrooms do you need?
                </Typography>
              </Grid>
              <Autocomplete
                fullWidth
                value={bathroomsNeededValue}
                onChange={(e, value) => {
                  setBathroomsNeededValue(value);
                }}
                id={"bathrooms-needed"}
                options={roomOptions}
                getOptionLabel={(option) => option}
                getOptionSelected={(option, value) => {
                  return option === value;
                }}
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} label="Select" />
                )}
                required
              />
              <Grid
                item
                xs={12}
                style={{
                  marginTop: 10,
                }}
              >
                <Typography className={Styles.navyBlueColorForce}>
                  Do you have a local guarantor
                </Typography>
              </Grid>
              <RadioGroup
                style={{
                  alignItems: "center",
                  flexDirection: "row",
                  marginLeft: 24,
                }}
                value={haveLocalGuarantorValue}
                onChange={(e) =>
                  setHaveLocalGuarantorValue(str2bool(e.target.value))
                }
              >
                <FormControlLabel
                  value={true}
                  control={
                    <Radio
                      checked={haveLocalGuarantorValue}
                      color={Styles.navyBlueColor}
                      style={{ marginLeft: -24 }}
                    />
                  }
                  label="Yes"
                  className={Styles.navyBlueColor}
                />
                <FormControlLabel
                  value={false}
                  control={
                    <Radio
                      checked={!haveLocalGuarantorValue}
                      color={Styles.navyBlueColor}
                    />
                  }
                  label="No"
                  className={Styles.navyBlueColor}
                />
              </RadioGroup>
              {haveLocalGuarantorValue && (
                <Grid item container style={{ padding: "10px 10px 20px 10px" }}>
                  <TextField
                    error={!!validations.name}
                    helperText={validations.name}
                    label="Guarantor Name"
                    id="fullName"
                    name="fullName"
                    fullWidth
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    variant="outlined"
                    required
                    inputProps={{
                      maxLength: 256,
                      className: Styles.navyBlueColor,
                    }}
                  />
                  <TextField
                    // error={!!validations.licenseNumber}
                    // helperText={validations.licenseNumber}
                    label="Guarantor Address"
                    fullWidth
                    value={guarantorAddress}
                    onChange={(e) => {
                      setGuarantorAddress(e.target.value);
                    }}
                    variant="outlined"
                    required
                    inputProps={{
                      maxLength: 500,
                      className: Styles.navyBlueColor,
                    }}
                    style={{
                      marginTop: 10,
                    }}
                  />
                  {/* <TextField
                // error={!!validations.licenseNumber}
                // helperText={validations.licenseNumber}
                error={!!validations.phoneNumber}
                helperText={
                  validations.phoneNumber && validations.phoneNumber.join(" ")
                }
                id="phoneNumber"
                label="Guarantor Phone number"
                name="phoneNumber"
                fullWidth
                value={guarantorPhoneNumber}
                onChange={(e) => {
                  setGuarantorPhoneNumber(e.target.value);
                }}
                variant="outlined"
                required
                inputProps={{
                  maxLength: 10,
                  className: Styles.navyBlueColor,
                }}
                style={{
                  marginTop: 10,
                }}
              /> */}

                  <MaskedMobileInput
                    value={phoneNumber}
                    error={!!validations.phoneNumber}
                    helperText={
                      validations.phoneNumber &&
                      validations.phoneNumber.join(" ")
                    }
                    id="phoneNumber"
                    label="Guarantor Phone Number"
                    name="phoneNumber"
                    variant="outlined"
                    required
                    type="tel"
                    fullWidth
                    className={classes.textField}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value.replaceAll("-", ""));
                    }}
                    style={{
                      marginTop: 10,
                    }}
                  />
                  <TextField
                    error={!!validations.email}
                    helperText={
                      validations.email && (
                        <EmailCheck
                          validations={validations.email}
                        ></EmailCheck>
                      )
                    }
                    label="Guarantor Email address"
                    fullWidth
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    variant="outlined"
                    required
                    inputProps={{
                      maxLength: 256,
                      className: Styles.navyBlueColor,
                    }}
                    style={{
                      marginTop: 10,
                    }}
                  />
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <Typography className={Styles.navyBlueColorForce}>
                      Does the guarantor own their home?
                    </Typography>
                  </Grid>
                  <RadioGroup
                    style={{
                      alignItems: "center",
                      flexDirection: "row",
                      marginLeft: 24,
                    }}
                    value={guarantorOwnHomeValue}
                    onChange={(e) =>
                      setGuarantorOwnHomeValue(str2bool(e.target.value))
                    }
                  >
                    <FormControlLabel
                      value={true}
                      control={
                        <Radio
                          checked={guarantorOwnHomeValue}
                          color={Styles.navyBlueColor}
                          style={{ marginLeft: -24 }}
                        />
                      }
                      label="Yes"
                      className={Styles.navyBlueColor}
                    />
                    <FormControlLabel
                      value={false}
                      control={
                        <Radio
                          checked={!guarantorOwnHomeValue}
                          color={Styles.navyBlueColor}
                        />
                      }
                      label="No"
                      className={Styles.navyBlueColor}
                    />
                  </RadioGroup>
                  {!guarantorOwnHomeValue && (
                    <Grid container>
                      <Typography
                        className={Styles.navyBlueColor}
                        style={{
                          marginTop: 10,
                        }}
                      >
                        How many months of security deposit can you provide
                      </Typography>
                      <Autocomplete
                        fullWidth
                        value={securityDepositMonthsValue}
                        onChange={(e, value) => {
                          setSecurityDepositMonthsValue(value);
                        }}
                        id={"billing-address-from-office-addresses"}
                        options={securityDepositOptions}
                        getOptionLabel={(option) => option}
                        getOptionSelected={(option, value) => {
                          return option === value;
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            {...params}
                            label="Select Months"
                          />
                        )}
                        required
                      />
                    </Grid>
                  )}
                </Grid>
              )}
              <Grid
                item
                xs={12}
                style={{
                  marginTop: 10,
                }}
              >
                <Typography className={Styles.navyBlueColorForce}>
                  Do you have language restrictions to communicate with a
                  realtor
                </Typography>
              </Grid>
              <RadioGroup
                style={{
                  alignItems: "center",
                  flexDirection: "row",
                  marginLeft: 24,
                }}
                value={languageRestrictionValue}
                onChange={(e) =>
                  setLanguageRestrictionValue(str2bool(e.target.value))
                }
              >
                <FormControlLabel
                  value={true}
                  control={
                    <Radio
                      checked={languageRestrictionValue}
                      color={Styles.navyBlueColor}
                      style={{ marginLeft: -24 }}
                    />
                  }
                  label="Yes"
                  className={Styles.navyBlueColor}
                />
                <FormControlLabel
                  value={false}
                  control={
                    <Radio
                      checked={!languageRestrictionValue}
                      color={Styles.navyBlueColor}
                    />
                  }
                  label="No"
                  className={Styles.navyBlueColor}
                />
              </RadioGroup>

              <Grid
                item
                xs={12}
                style={{
                  marginTop: 20,
                }}
              >
                <Typography
                  variant="subtitle1"
                  className={Styles.navyBlueColorForce}
                >
                  Employment Details:
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  marginTop: 10,
                }}
              >
                <Typography className={Styles.navyBlueColorForce}>
                  Are you currently employed?
                </Typography>
              </Grid>
              <RadioGroup
                style={{
                  alignItems: "center",
                  flexDirection: "row",
                  marginLeft: 24,
                }}
                value={currentlyEmployedValue}
                onChange={(e) =>
                  setCurrentlyEmployedValue(str2bool(e.target.value))
                }
              >
                <FormControlLabel
                  value={true}
                  control={
                    <Radio
                      checked={currentlyEmployedValue}
                      color={Styles.navyBlueColor}
                      style={{ marginLeft: -24 }}
                    />
                  }
                  label="Yes"
                  className={Styles.navyBlueColor}
                />
                <FormControlLabel
                  value={false}
                  control={
                    <Radio
                      checked={!currentlyEmployedValue}
                      color={Styles.navyBlueColor}
                    />
                  }
                  label="No"
                  className={Styles.navyBlueColor}
                />
              </RadioGroup>
              {currentlyEmployedValue && (
                <Grid item container style={{ padding: "10px 10px 20px 10px" }}>
                  <TextField
                    // error={!!validations.licenseName}
                    // helperText={validations.licenseName}
                    label="Company Name"
                    fullWidth
                    value={companyName}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                    }}
                    variant="outlined"
                    required
                    inputProps={{
                      maxLength: 256,
                      className: Styles.navyBlueColor,
                    }}
                  />
                  <TextField
                    // error={!!validations.licenseNumber}
                    // helperText={validations.licenseNumber}
                    label="Position"
                    fullWidth
                    value={position}
                    onChange={(e) => {
                      setPosition(e.target.value);
                    }}
                    variant="outlined"
                    required
                    inputProps={{
                      maxLength: 100,
                      className: Styles.navyBlueColor,
                    }}
                    style={{
                      marginTop: 10,
                    }}
                  />
                  <Autocomplete
                    fullWidth
                    value={salaryRangeValue}
                    onChange={(e, value) => {
                      setSalaryRangeValue(value);
                    }}
                    id={"salary-range"}
                    options={salaryRangeOptions}
                    getOptionLabel={(option) => option}
                    getOptionSelected={(option, value) => {
                      return option === value;
                    }}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        {...params}
                        label="Salary Range"
                      />
                    )}
                    required
                    style={{
                      marginTop: 10,
                    }}
                  />
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <Typography className={Styles.navyBlueColorForce}>
                      Are you able to provide a letter from your company?
                    </Typography>
                  </Grid>
                  <RadioGroup
                    style={{
                      alignItems: "center",
                      flexDirection: "row",
                      marginLeft: 24,
                    }}
                    value={provideCompanyLetterValue}
                    onChange={(e) =>
                      setProvideCompanyLetterValue(str2bool(e.target.value))
                    }
                  >
                    <FormControlLabel
                      value={true}
                      control={
                        <Radio
                          checked={provideCompanyLetterValue}
                          color={Styles.navyBlueColor}
                          style={{ marginLeft: -24 }}
                        />
                      }
                      label="Yes"
                      className={Styles.navyBlueColor}
                    />
                    <FormControlLabel
                      value={false}
                      control={
                        <Radio
                          checked={!provideCompanyLetterValue}
                          color={Styles.navyBlueColor}
                        />
                      }
                      label="No"
                      className={Styles.navyBlueColor}
                    />
                  </RadioGroup>
                </Grid>
              )}

              <Grid
                item
                xs={12}
                style={{
                  marginTop: 20,
                }}
              >
                <Typography
                  variant="subtitle1"
                  className={Styles.navyBlueColorForce}
                >
                  Financial Details:
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  marginTop: 10,
                }}
              >
                <Typography className={Styles.navyBlueColorForce}>
                  Do you have a credit report available to share?
                </Typography>
              </Grid>
              <RadioGroup
                style={{
                  alignItems: "center",
                  flexDirection: "row",
                  marginLeft: 24,
                }}
                value={creditReportAvailableValue}
                onChange={(e) =>
                  setCreditReportAvailableValue(str2bool(e.target.value))
                }
              >
                <FormControlLabel
                  value={true}
                  control={
                    <Radio
                      color={Styles.navyBlueColor}
                      style={{ marginLeft: -24 }}
                    />
                  }
                  label="Yes"
                  className={Styles.navyBlueColor}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color={Styles.navyBlueColor} />}
                  label="No"
                  className={Styles.navyBlueColor}
                />
              </RadioGroup>

              <Grid
                item
                xs={12}
                style={{
                  marginTop: 10,
                }}
              >
                <Typography className={Styles.navyBlueColorForce}>
                  Can you share your bank statements with the realtor/landlord?
                </Typography>
              </Grid>
              <RadioGroup
                style={{
                  alignItems: "center",
                  flexDirection: "row",
                  marginLeft: 24,
                }}
                value={shareBankStatementsValue}
                onChange={(e) =>
                  setShareBankStatementsValue(str2bool(e.target.value))
                }
              >
                <FormControlLabel
                  value={true}
                  control={
                    <Radio
                      color={Styles.navyBlueColor}
                      style={{ marginLeft: -24 }}
                    />
                  }
                  label="Yes"
                  className={Styles.navyBlueColor}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color={Styles.navyBlueColor} />}
                  label="No"
                  className={Styles.navyBlueColor}
                />
              </RadioGroup>

              <Grid
                item
                xs={12}
                style={{
                  marginTop: 10,
                }}
              >
                <Typography className={Styles.navyBlueColorForce}>
                  {/* Do they own a home back home? */}
                  Country of origin?
                </Typography>
              </Grid>
              <TextField
                value={originCountry}
                required
                variant="outlined"
                fullWidth
                inputProps={{
                  className: Styles.navyBlueColor,
                  maxLength: 100,
                }}
                onChange={(event) => {
                  setOriginCountry(event.target.value.trim());
                }}
                label="Enter Country Name"
              />

              <Grid
                item
                xs={12}
                style={{
                  marginTop: 10,
                }}
              >
                <Typography className={Styles.navyBlueColorForce}>
                  Have you ever filed bankruptcy in Canada?
                </Typography>
              </Grid>
              <RadioGroup
                style={{
                  alignItems: "center",
                  flexDirection: "row",
                  marginLeft: 24,
                }}
                value={filedBankruptcyValue}
                onChange={(e) =>
                  setFiledBankruptcyValue(str2bool(e.target.value))
                }
              >
                <FormControlLabel
                  value={true}
                  control={
                    <Radio
                      color={Styles.navyBlueColor}
                      style={{ marginLeft: -24 }}
                    />
                  }
                  label="Yes"
                  className={Styles.navyBlueColor}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color={Styles.navyBlueColor} />}
                  label="No"
                  className={Styles.navyBlueColor}
                />
              </RadioGroup>

              <Grid
                item
                xs={12}
                style={{
                  marginTop: 10,
                }}
              >
                <Typography className={Styles.navyBlueColorForce}>
                  Have you ever been evicted?
                </Typography>
              </Grid>
              <RadioGroup
                style={{
                  alignItems: "center",
                  flexDirection: "row",
                  marginLeft: 24,
                }}
                value={evictedValue}
                onChange={(e) => setEvictedValue(str2bool(e.target.value))}
              >
                <FormControlLabel
                  value={true}
                  control={
                    <Radio
                      color={Styles.navyBlueColor}
                      style={{ marginLeft: -24 }}
                    />
                  }
                  label="Yes"
                  className={Styles.navyBlueColor}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color={Styles.navyBlueColor} />}
                  label="No"
                  className={Styles.navyBlueColor}
                />
              </RadioGroup>
              <Grid
                item
                xs={12}
                style={{
                  marginTop: 10,
                }}
              >
                <Typography className={Styles.navyBlueColorForce}>
                  Comments
                </Typography>
              </Grid>
              <TextField
                multiline
                placeholder="If you have any other comments, requirements or would like to provide additional information, please add it here."
                required
                minRows={4}
                maxRows={9}
                variant="outlined"
                fullWidth
                inputProps={{
                  className: Styles.navyBlueColor,
                  maxLength: 500,
                }}
                onChange={(event) => {
                  setComments(event.target.value.trim());
                }}
              />
            </>
          ) : (
            <>
              <Grid container className={classes.marginTop10}>
                <Grid item>
                  <Typography className={classes.font14}>
                    Please log in to interact with service providers. Don't have
                    an account? Sign up for your free Souqh account today -
                    should take less than a minute
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          {userInfo ? (
            <Button
              disabled={
                !selectedIDs.length ||
                !rentalBudgetValue ||
                !preferredLocationCity ||
                !timelineOption ||
                !typeOfRentalHomeValue ||
                !bathroomsNeededValue ||
                !bedroomsNeededValue ||
                !originCountry
              }
              style={{
                textTransform: "capitalize",
                marginRight: 10,
                cursor: "pointer",
              }}
              // onClick={() => {
              //   createBroadcastService(
              //     {
              //       id: 1,
              //       name: "Real Estate Broker / Agent",
              //       shortName: "Realtor",
              //       logoUrl: "Realtor.svg",
              //       isBusinessType: true,
              //       sequenceNumber: 1,
              //       storefrontPathName: "real-estate-agent",
              //       noOfServiceProviders: 333,
              //     }, // searchedCategory defaulted to Real Estate Broker / Agent
              //     preferredLocationCity,
              //     timelineOption,
              //     comments,
              //     rentalForNewComersDetails,
              //     (res) => {
              //       if (res && res.data.status === 200) {
              //         setOpenRentalInquiryFormDialog(false);
              //         clearData();
              //       }
              //     }
              //   );
              // }}
              onClick={() => {
                getMatchingServiceProviderDetails(
                  defaultCategory,
                  preferredLocationCity,
                  (res) => {
                    if (res.data.status === 200) {
                      setOpenBroadcastConfirmationDialog(true);
                    }
                  }
                );
              }}
              className={clsx(
                Styles.navyBlueColorForce,
                Styles.primaryBtnAccent
              )}
            >
              Submit
            </Button>
          ) : (
            <>
              <Button
                className={Styles.navyBlueColorForce}
                style={{ textTransform: "capitalize", cursor: "pointer" }}
                onClick={() => {
                  history.push("/prepare-for-canada/signup");
                }}
              >
                Sign up for free
              </Button>
              <Button
                style={{
                  textTransform: "capitalize",
                  marginRight: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  history.push("/prepare-for-canada/login");
                }}
                className={clsx(
                  Styles.navyBlueColorForce,
                  Styles.primaryBtnAccent
                )}
              >
                Login
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
