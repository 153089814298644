import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Card,
  TextField,
  makeStyles,
  Paper,
  Divider,
  Avatar,
  Chip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import clsx from "clsx";
import Styles from "../../../Styles.module.css";
import { REVIEW_TYPES } from "./clientManagementConstants";
import SearchIcon from "@material-ui/icons/Search";
import FlagIcon from "@material-ui/icons/Flag";
import { useStorefrontClientReviews } from "souqh-react-redux-hooks/common/useStorefrontClientReviews";
import Pagination from "@material-ui/lab/Pagination";
import ShowMoreText from "react-show-more-text";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import InactiveReviewDialog from "./InactiveReviewDialog";
import * as moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSQQuery } from "souqh-react-redux-hooks/common/useSQQuery";
import { useHistory } from "react-router";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";
import { useRoleManagement } from "souqh-react-redux-hooks/common/useRoleManagement";

const useStyles = makeStyles((theme) => ({
  textInput: {
    "& .MuiInput-underline:before, & .MuiInput-underline:hover:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after, & .MuiInput-underline:hover:after": {
      borderBottom: "none",
    },
  },
}));

export default function DetailReviewsPage({
  isViewStorefrontPage,
  storefrontReviews,
  servicesList,
  previewStorefrontId,
  publicStorefrontId,
  setUserEngagementData,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const user = useAuthUser();
  const { query } = useSQQuery();
  const history = useHistory();
  const { isTeamMember } = useRoleManagement();

  const storeFrontId = isViewStorefrontPage
    ? query.get("storeFrontId") || previewStorefrontId
      ? publicStorefrontId
      : publicStorefrontId
    : user.storefrontId;

  //sp-view is for private view (SP side) and without sp-view is for view SF (public page)
  const url = isViewStorefrontPage
    ? "storefront/service-review/all"
    : "storefront/service-review/sp-view/all";

  const reviewStatsUrl = isViewStorefrontPage
    ? "storefront/service-review/statistics"
    : "storefront/service-review/sp-view/statistics";

  const searchUrl = isViewStorefrontPage
    ? "/storefront/service-review/search"
    : "/storefront/service-review/sp-view/search";

  const {
    handlePageChange,
    reviewsList,
    totalReviews,
    getReviewStatistics,
    reviewStatistics,
    searchedService,
    setSearchedService,
    searchReviews,
    storefrontServicesList,
    getStorefrontServices,
  } = useStorefrontClientReviews(25, url, storeFrontId); // passed page size

  const [openInactiveReviewDialog, setOpenInactiveReviewDialog] = useState({
    open: false,
    reviewId: "",
  });
  const [avgStarRatings, setAvgStarRatings] = useState(null);

  useEffect(() => {
    window.scroll(0, 0); //scroll to top of page on load
    if (!isViewStorefrontPage) {
      getReviewStatistics(reviewStatsUrl, storeFrontId);
    }
  }, []);

  useEffect(() => {
    !isViewStorefrontPage && getStorefrontServices();
  }, []);

  useEffect(() => {
    if (reviewStatistics && reviewStatistics.avgStarRatings) {
      setAvgStarRatings(reviewStatistics.avgStarRatings.toFixed(1));
    }
  }, [reviewStatistics]);

  const thirtyDaysStats = reviewStatistics.thirtyDaysStatsMap || {};
  const ninetyDaysStats = reviewStatistics.ninetyDaysStatsMap || {};
  const twelveMonthsStats = reviewStatistics.tweleveMonthsStatsMap || {};
  const lifetimeStats = reviewStatistics.lifetimeStatsMap || {};

  const getFormattedDate = (dateValue) => {
    const dateObj = new Date(dateValue);
    const date = moment(dateObj);
    const dateString = date.format("MMM DD, yyyy");
    return dateString;
  };

  return (
    <Grid container item xs={12}>
      <InactiveReviewDialog
        openInactiveReviewDialog={openInactiveReviewDialog.open}
        setOpenInactiveReviewDialog={setOpenInactiveReviewDialog}
        reviewId={openInactiveReviewDialog.reviewId}
      />
      {!isMobileDevice() && (
        <Grid item xs={12}>
          <Typography variant="h4" className={Styles.navyBlueColor}>
            Reviews
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Card
          elevation={0}
          style={{
            padding: "10px 0px 20px 10px",
            marginTop: 20,
            backgroundColor: isViewStorefrontPage ? "#0E1C4E0A" : "#FFFFFFF",
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              md={3}
              container
              alignItems="center"
              direction="column"
            >
              <Typography className={Styles.navyBlueColor} variant="h2">
                {reviewStatistics &&
                  reviewStatistics.avgStarRatings &&
                  reviewStatistics.avgStarRatings.toFixed(1)}
              </Typography>
              <Rating
                name="rating"
                value={avgStarRatings}
                precision={0.1}
                className={Styles.navyBlueColorForce}
                readOnly
                style={{ marginTop: 20 }}
              />
              <Typography
                style={{ marginTop: 10 }}
                className={clsx(Styles.navyBlueColor, Styles.font14)}
              >
                {reviewStatistics && reviewStatistics.totalStarRatings} Ratings
                | {reviewStatistics && reviewStatistics.totalReviewComments}{" "}
                Reviews
              </Typography>
            </Grid>
            {!isMobileDevice() && (
              <Grid item container xs={9}>
                <Grid item xs={3}>
                  <Typography
                    className={Styles.navyBlueColor}
                    variant="subtitle1"
                  >
                    30 Days
                  </Typography>
                  <Grid container style={{ marginTop: 10 }}>
                    {Object.keys(thirtyDaysStats).map((keyName, keyIndex) => (
                      <Grid container key={keyIndex} style={{ marginTop: 5 }}>
                        <Grid item xs={6}>
                          <Typography
                            variant="body2"
                            style={{ color: `${REVIEW_TYPES[keyName]}` }}
                          >
                            {keyName}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            style={{ color: `${REVIEW_TYPES[keyName]}` }}
                            variant="subtitle2"
                          >
                            {thirtyDaysStats[keyName]}
                            {keyName !== "Count" && "%"}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    className={Styles.navyBlueColor}
                    variant="subtitle1"
                  >
                    90 Days
                  </Typography>
                  <Grid container style={{ marginTop: 10 }}>
                    {Object.keys(ninetyDaysStats).map((keyName, keyIndex) => (
                      <Grid container key={keyIndex} style={{ marginTop: 5 }}>
                        <Grid item xs={6}>
                          <Typography
                            variant="body2"
                            style={{ color: `${REVIEW_TYPES[keyName]}` }}
                          >
                            {keyName}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            style={{ color: `${REVIEW_TYPES[keyName]}` }}
                            variant="subtitle2"
                          >
                            {ninetyDaysStats[keyName]}
                            {keyName !== "Count" && "%"}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    className={Styles.navyBlueColor}
                    variant="subtitle1"
                  >
                    12 Months
                  </Typography>
                  <Grid container style={{ marginTop: 10 }}>
                    {Object.keys(twelveMonthsStats).map((keyName, keyIndex) => (
                      <Grid container key={keyIndex} style={{ marginTop: 5 }}>
                        <Grid item xs={6}>
                          <Typography
                            variant="body2"
                            style={{ color: `${REVIEW_TYPES[keyName]}` }}
                          >
                            {keyName}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            style={{ color: `${REVIEW_TYPES[keyName]}` }}
                            variant="subtitle2"
                          >
                            {twelveMonthsStats[keyName]}
                            {keyName !== "Count" && "%"}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    className={Styles.navyBlueColor}
                    variant="subtitle1"
                  >
                    Lifetime
                  </Typography>
                  <Grid container style={{ marginTop: 10 }}>
                    {Object.keys(lifetimeStats).map((keyName, keyIndex) => (
                      <Grid container key={keyIndex} style={{ marginTop: 5 }}>
                        <Grid item xs={6}>
                          <Typography
                            variant="body2"
                            style={{ color: `${REVIEW_TYPES[keyName]}` }}
                          >
                            {keyName}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            style={{ color: `${REVIEW_TYPES[keyName]}` }}
                            variant="subtitle2"
                          >
                            {lifetimeStats[keyName]}
                            {keyName !== "Count" && "%"}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {isViewStorefrontPage && (
              <Grid container item xs={12}>
                <Divider
                  orientation="horizontal"
                  variant="fullWidth"
                  style={{ width: "100%", margin: "20px 0px 5px 0px" }}
                />
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 600 }}
                    className={Styles.navyBlueColor}
                  >
                    External Reviews
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 8 }}>
                  {storefrontReviews &&
                  storefrontReviews.length &&
                  storefrontReviews[0].title &&
                  storefrontReviews[0].link ? (
                    storefrontReviews.map((item, index) =>
                      item.link && item.title ? (
                        <Chip
                          key={index}
                          variant="outlined"
                          storefrontReviews
                          label={item.title}
                          className={Styles.navyBlueColor}
                          classes={{ label: Styles.sqEllipsis }}
                          onClick={() => {
                            window.open(item.link, "_blank");
                            setUserEngagementData &&
                              setUserEngagementData(
                                storeFrontId,
                                "External_reviews"
                              );
                          }}
                          style={{ marginRight: 10 }}
                        />
                      ) : null
                    )
                  ) : (
                    <Typography className={Styles.navyBlueColor}>
                      No data available
                    </Typography>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={5}
        style={{
          margin: isMobileDevice() ? 16 : "20px 0",
        }}
      >
        <Paper
          elevation={0}
          style={{
            width: "100%",
            fontStyle: "italic",
          }}
          className={Styles.navyBlueColorForce}
        >
          <Grid container item xs={12}>
            <Autocomplete
              fullWidth
              value={searchedService || null}
              options={
                (isViewStorefrontPage
                  ? servicesList
                  : storefrontServicesList) || []
              }
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => {
                return option.name === value;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  Search
                  label="Search your reviews here"
                  required
                  fullWidth
                  variant="outlined"
                  className={classes.textInput}
                />
              )}
              required
              onChange={(event, value) => {
                setSearchedService(value);
                searchReviews(searchUrl, value);
                setUserEngagementData &&
                  setUserEngagementData(storeFrontId, "Search_reviews");
              }}
            />
          </Grid>
        </Paper>
      </Grid>
      {isViewStorefrontPage && (
        <Divider
          orientation="horizontal"
          variant="fullWidth"
          style={{ width: "100%", marginBottom: 20 }}
        />
      )}

      <Grid item xs={12} style={{ paddingBottom: isTabletDevice() && 48 }}>
        {reviewsList && reviewsList.length ? (
          <Paper elevation={0}>
            <Grid container>
              {reviewsList.map((data, index) => (
                <Grid
                  key={index}
                  container
                  spacing={2}
                  style={{ padding: "16px 24px" }}
                >
                  <Grid container item xs={11}>
                    <Grid item>
                      <Avatar
                        src={"/broken-image.jpg"}
                        style={{ width: 32, height: 32 }}
                      >
                        {(data.userName || "").charAt(0)}
                      </Avatar>
                    </Grid>
                    <Grid item style={{ marginLeft: 16 }}>
                      {isViewStorefrontPage ? (
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 600 }}
                          className={Styles.navyBlueColor}
                        >
                          {data.userName}
                        </Typography>
                      ) : (
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 600, cursor: "pointer" }}
                          className={Styles.navyBlueColor}
                          onClick={() => {
                            history.push(
                              `/serviceprovider/clients/details/${data.userId}`
                            );
                          }}
                        >
                          {data.userName}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item style={{ marginLeft: 16 }}>
                      <Typography style={{ fontSize: 14, color: "#868DA6" }}>
                        {getFormattedDate(data.reviewedAt)}
                      </Typography>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={5} md={3} lg={2}>
                        <Rating
                          name="rating"
                          value={data.starRating}
                          className={Styles.navyBlueColorForce}
                          readOnly
                        />
                      </Grid>
                      <Grid item xs={7} md={9} lg={10}>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 600 }}
                          className={clsx(
                            Styles.navyBlueColor,
                            Styles.sqEllipsis
                          )}
                        >
                          {data.serviceName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} className="review-comments">
                      <Typography
                        variant="body1"
                        className={Styles.navyBlueColor}
                      >
                        <ShowMoreText
                          lines={2}
                          more={
                            <Grid
                              container
                              item
                              xs={12}
                              justifyContent="flex-end"
                              style={{ marginLeft: 30 }}
                            >
                              <Typography
                                variant="body1"
                                className={Styles.primaryTxtAccent}
                                style={{
                                  fontSize: 12,
                                  textDecoration: "none",
                                }}
                              >
                                See More
                              </Typography>
                            </Grid>
                          }
                          less={
                            <Grid
                              container
                              item
                              xs={12}
                              justifyContent="flex-end"
                            >
                              <Typography
                                variant="body1"
                                className={Styles.primaryTxtAccent}
                                style={{ fontSize: 12 }}
                              >
                                See Less
                              </Typography>
                            </Grid>
                          }
                          className="content-css"
                          anchorClass="review-comments-anchor"
                          expanded={false}
                          width={xlUp ? 1100 : 1000}
                        >
                          {data.reviewComments}
                        </ShowMoreText>
                      </Typography>
                    </Grid>
                  </Grid>
                  {!isViewStorefrontPage && !isTeamMember() && (
                    <Grid container item xs={1} justifyContent="flex-end">
                      <FlagIcon
                        className={Styles.navyBlueColor}
                        style={{
                          cursor: !data.flagged && "pointer",
                          color: !data.flagged && "#0E1C4E1F",
                        }}
                        onClick={() => {
                          setOpenInactiveReviewDialog({
                            open: !data.flagged ? true : false,
                            reviewId: data.id,
                          });
                        }}
                      />
                    </Grid>
                  )}
                  {index !== reviewsList.length - 1 ? (
                    <Divider
                      orientation="horizontal"
                      variant="fullWidth"
                      style={{ width: "100%" }}
                    />
                  ) : null}
                </Grid>
              ))}
            </Grid>
          </Paper>
        ) : (
          <Typography>No reviews found</Typography>
        )}
        {reviewsList && reviewsList.length ? (
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            style={{ margin: "16px 0" }}
          >
            <Pagination
              count={totalReviews && totalReviews.totalPages}
              shape="rounded"
              variant="outlined"
              onChange={handlePageChange}
            />
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
}
