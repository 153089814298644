import {
  Divider,
  Grid,
  Button,
  makeStyles,
  Paper,
  Container,
  Typography,
  Link,
  Chip,
  Snackbar,
} from "@material-ui/core";

import React, { useEffect, useState } from "react";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";
import SetupFooter from "../../SPSetup/SetupFooter";
import { useDocumentList } from "souqh-react-redux-hooks/homeBuyer/hbProfile/useDocumentList";
import { useHistory } from "react-router";
import Alert from "@material-ui/lab/Alert";
import DeleteDocumentDialog from "../../DocumentManagementDialogs/DeleteDocumentDialog";
import DocumentPreviewDialog from "../../HomeBuyer/Explore/StorefrontPage/DocumentPreviewDialog";
import { getUploadedDocuments } from "../../../utils/AppUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  padding: {
    padding: 10,
  },
  marginTop: {
    marginTop: 20,
  },
  buttons: {
    padding: "2px 15px",
    textTransform: "capitalize",
    borderRadius: 4,
    fontSize: 16,
  },
  imgUpload: {
    margin: 5,
  },
}));
const DocumentCheckList = () => {
  const classes = useStyles();
  const {
    handleOnChange,
    documentList,
    saveDocumentList,
    getDocumentsData,
    maxFileSize,
    openDeleteDocumentDialog,
    setOpenDeleteDocumentDialog,
    actions: { setMaxFileSize, deleteDoc },
  } = useDocumentList();
  let history = useHistory();
  const [openDocumetDialog, setOpenDocumetDialog] = useState(false);
  const [documentItem, setDocumentItem] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [deleteDocData, setDeleteDocData] = useState(null);
  const [docCategoryId, setDocCategoryId] = useState(null);
  const [docNameForDownload, setDocNameForDownload] = useState(null);

  const {
    DRIVERS_LICENSE,
    OTHER_GOVERNMENT_ISSUED_PHOTO_ID,
    LETTER_OF_EMPLOYMENT,
    PAY_SLIPS,
    T4_FORM,
    NOTICE_OF_ASSESSMENT_FROM_TAX_RETURN,
    OFFICIAL_BANK_STATEMENT_RRSP,
    SELF_EMPLOYED_CORPORATE_TAX,
  } = documentList;
  useEffect(() => {
    window.scroll(0, 0);
    getDocumentsData();
  }, []);

  useEffect(() => {
    if (openDeleteDocumentDialog.isDeleteSuccessful) {
      deleteDoc(deleteDocData);
      setOpenDeleteDocumentDialog(false);
    }
  }, [openDeleteDocumentDialog]);

  return (
    <>
      <DeleteDocumentDialog
        openDeleteDocumentDialog={openDeleteDocumentDialog.open}
        setOpenDeleteDocumentDialog={setOpenDeleteDocumentDialog}
        docDetails={openDeleteDocumentDialog.docDetails}
        categoryId={openDeleteDocumentDialog.categoryId}
        documentTabName={"Reference Documents"}
      />
      <DocumentPreviewDialog
        openDocumetDialog={openDocumetDialog}
        setOpenDocumetDialog={setOpenDocumetDialog}
        documentItem={documentItem}
        setDocumentItem={setDocumentItem}
        downloadUrl={downloadUrl}
        source="documentCheckList"
        docCategoryId={docCategoryId}
        docNameForDownload={docNameForDownload}
      />
      <Grid container className={clsx(classes.root, classes.marginTop)}>
        <Container maxWidth="md" style={{ paddingBottom: 70 }}>
          <Grid
            style={{ marginBottom: 25, marginTop: 30 }}
            container
            item
            xs={9}
          >
            <Typography variant="h4" className={Styles.navyBlueColor}>
              Document CheckList
            </Typography>
            <Typography className={clsx(Styles.navyBlueColor, Styles.font14)}>
              Add common documents for convenience of sharing with service
              providers when requested. All documents uploaded are protected by
              our{" "}
              <Link
                href="/privacypolicy"
                underline="always"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#0e1c4e" }}
              >
                privacy policy
              </Link>{" "}
              .
            </Typography>
            <Typography
              className={clsx(Styles.navyBlueColor, Styles.font14)}
              style={{ marginTop: 10 }}
            >
              Documents uploaded here will also be available in your Documents
              portal under the Reference Documents section to view or delete.
              Additional documents can be uploaded through the Documents portal
              anytime
            </Typography>
          </Grid>
          {maxFileSize && (
            <Snackbar
              open={maxFileSize}
              autoHideDuration={4000}
              onClose={() => {
                setMaxFileSize(false);
              }}
            >
              <Alert variant="filled" severity="error">
                Max size limit for each document is 15 MB
              </Alert>
            </Snackbar>
          )}
          <Paper elevation={0} style={{ padding: "16px 10px" }}>
            <Grid container>
              <Grid item container xs={12} alignItems="center">
                <Grid item xs={9}>
                  <Typography variant="body1" className={Styles.navyBlueColor}>
                    Driver’s License
                  </Typography>
                  <Typography
                    style={{ fontSize: 10, fontStyle: "italic" }}
                    className={Styles.navyBlueColor}
                  >
                    Provincial Drivers License
                  </Typography>
                  {DRIVERS_LICENSE && DRIVERS_LICENSE.length ? (
                    <Grid item container xs={12}>
                      {DRIVERS_LICENSE.map((dL, index) => (
                        <Chip
                          key={index}
                          style={{ marginTop: 10, marginLeft: 5 }}
                          label={dL.fileName || dL.name}
                          onDelete={() => {
                            if (dL instanceof File) {
                              deleteDoc({
                                field: "DRIVERS_LICENSE",
                                index: index,
                              });
                            } else {
                              setDeleteDocData({
                                field: "DRIVERS_LICENSE",
                                index: index,
                              });
                              setOpenDeleteDocumentDialog({
                                open: true,
                                docDetails: dL,
                                categoryId: dL.categoryId,
                              });
                            }
                          }}
                          onClick={() => {
                            setDocumentItem(getUploadedDocuments(dL));
                            setDownloadUrl(dL.docUrl);
                            setDocCategoryId(dL.categoryId);
                            setDocNameForDownload(dL.fileName);
                            setOpenDocumetDialog(true);
                          }}
                        />
                      ))}
                    </Grid>
                  ) : null}
                </Grid>
                <Grid item xs={3}>
                  <input
                    accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .png, .jpeg, .bmp, .gif, .CSV"
                    style={{ display: "none" }}
                    id="dlFileBtn"
                    type="file"
                    onChange={(event) => {
                      handleOnChange({
                        event,
                        field: "DRIVERS_LICENSE",
                        limit: 1,
                      });
                    }}
                    multiple
                  />
                  <label htmlFor="dlFileBtn" style={{ marginBottom: 16 }}>
                    <Button
                      variant="outlined"
                      color="default"
                      component="span"
                      startIcon={
                        <img src="/images/Icon material-file-upload.png" />
                      }
                      style={{ textTransform: "capitalize" }}
                      className={clsx(classes.root, classes.buttons)}
                    >
                      Upload
                    </Button>
                  </label>
                </Grid>
              </Grid>
            </Grid>
            <Divider
              style={{ width: "100%", marginTop: 16, marginBottom: 16 }}
            />
            <Grid container>
              <Grid item container xs={12} alignItems="center">
                <Grid item xs={9}>
                  <Typography variant="body1" className={Styles.navyBlueColor}>
                    Other Government Issued Photo ID
                  </Typography>
                  <Typography
                    style={{ fontSize: 10, fontStyle: "italic" }}
                    className={Styles.navyBlueColor}
                  >
                    Government Issued ID
                  </Typography>
                  {OTHER_GOVERNMENT_ISSUED_PHOTO_ID &&
                  OTHER_GOVERNMENT_ISSUED_PHOTO_ID.length ? (
                    <Grid item container xs={12}>
                      {OTHER_GOVERNMENT_ISSUED_PHOTO_ID.map((other, index) => (
                        <Chip
                          key={index}
                          style={{ marginTop: 10, marginLeft: 5 }}
                          label={other.fileName || other.name}
                          onDelete={() => {
                            if (other instanceof File) {
                              deleteDoc({
                                field: "OTHER_GOVERNMENT_ISSUED_PHOTO_ID",
                                index: index,
                              });
                            } else {
                              setDeleteDocData({
                                field: "OTHER_GOVERNMENT_ISSUED_PHOTO_ID",
                                index: index,
                              });
                              setOpenDeleteDocumentDialog({
                                open: true,
                                docDetails: other,
                                categoryId: other.categoryId,
                              });
                            }
                          }}
                          onClick={() => {
                            setDocumentItem(getUploadedDocuments(other));
                            setDownloadUrl(other.docUrl);
                            setDocCategoryId(other.categoryId);
                            setDocNameForDownload(other.fileName);
                            setOpenDocumetDialog(true);
                          }}
                        />
                      ))}
                    </Grid>
                  ) : null}
                </Grid>
                <Grid item xs={3}>
                  <input
                    accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .png, .jpeg, .bmp, .gif, .CSV"
                    style={{ display: "none" }}
                    id="OTHER_GOVERNMENT_ISSUED_PHOTO_IDFileBtn"
                    type="file"
                    onChange={(event) => {
                      handleOnChange({
                        event,
                        field: "OTHER_GOVERNMENT_ISSUED_PHOTO_ID",
                        limit: 1,
                      });
                    }}
                    multiple
                  />
                  <label
                    htmlFor="OTHER_GOVERNMENT_ISSUED_PHOTO_IDFileBtn"
                    style={{ marginBottom: 16 }}
                  >
                    <Button
                      variant="outlined"
                      color="default"
                      component="span"
                      startIcon={
                        <img src="/images/Icon material-file-upload.png" />
                      }
                      style={{ textTransform: "capitalize" }}
                      className={clsx(classes.root, classes.buttons)}
                    >
                      Upload
                    </Button>
                  </label>
                </Grid>
              </Grid>
            </Grid>
            <Divider
              style={{ width: "100%", marginTop: 16, marginBottom: 16 }}
            />
            <Grid container>
              <Grid item container xs={12} alignItems="center">
                <Grid item xs={9}>
                  <Typography variant="body1" className={Styles.navyBlueColor}>
                    Letter of Employment
                  </Typography>
                  <Typography
                    style={{ fontSize: 10, fontStyle: "italic" }}
                    className={Styles.navyBlueColor}
                  >
                    Your current company
                  </Typography>
                  {LETTER_OF_EMPLOYMENT && LETTER_OF_EMPLOYMENT.length ? (
                    <Grid item container xs={12}>
                      {LETTER_OF_EMPLOYMENT.map((letter, index) => (
                        <Chip
                          key={index}
                          style={{ marginTop: 10, marginLeft: 5 }}
                          label={letter.fileName || letter.name}
                          onDelete={() => {
                            if (letter instanceof File) {
                              deleteDoc({
                                field: "LETTER_OF_EMPLOYMENT",
                                index: index,
                              });
                            } else {
                              setDeleteDocData({
                                field: "LETTER_OF_EMPLOYMENT",
                                index: index,
                              });
                              setOpenDeleteDocumentDialog({
                                open: true,
                                docDetails: letter,
                                categoryId: letter.categoryId,
                              });
                            }
                          }}
                          onClick={() => {
                            setDocumentItem(getUploadedDocuments(letter));
                            setDownloadUrl(letter.docUrl);
                            setDocCategoryId(letter.categoryId);
                            setDocNameForDownload(letter.fileName);
                            setOpenDocumetDialog(true);
                          }}
                        />
                      ))}
                    </Grid>
                  ) : null}
                </Grid>
                <Grid item xs={3}>
                  <input
                    accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .png, .jpeg, .bmp, .gif, .CSV"
                    style={{ display: "none" }}
                    id="letterOfEmpFileBtn"
                    type="file"
                    onChange={(event) => {
                      handleOnChange({
                        event,
                        field: "LETTER_OF_EMPLOYMENT",
                        limit: 1,
                      });
                    }}
                    multiple
                  />
                  <label
                    htmlFor="letterOfEmpFileBtn"
                    style={{ marginBottom: 16 }}
                  >
                    <Button
                      variant="outlined"
                      color="default"
                      component="span"
                      startIcon={
                        <img src="/images/Icon material-file-upload.png" />
                      }
                      style={{ textTransform: "capitalize" }}
                      className={clsx(classes.root, classes.buttons)}
                    >
                      Upload
                    </Button>
                  </label>
                </Grid>
              </Grid>
            </Grid>
            <Divider
              style={{ width: "100%", marginTop: 16, marginBottom: 16 }}
            />

            <Grid item container xs={12}>
              <Grid item container xs={12} alignItems="center">
                <Grid item xs={9}>
                  <Typography variant="body1" className={Styles.navyBlueColor}>
                    Pay Slips
                  </Typography>
                  <Typography
                    style={{ fontSize: 10, fontStyle: "italic" }}
                    className={Styles.navyBlueColor}
                  >
                    Minimum 2 of your most recent pay slips
                  </Typography>
                  {PAY_SLIPS && PAY_SLIPS.length ? (
                    <Grid item container xs={12}>
                      {PAY_SLIPS.map((paySlip, index) => (
                        <Chip
                          style={{ marginTop: 10, marginLeft: 5 }}
                          key={index}
                          label={paySlip.fileName || paySlip.name}
                          onDelete={() => {
                            if (paySlip instanceof File) {
                              deleteDoc({
                                field: "PAY_SLIPS",
                                index: index,
                              });
                            } else {
                              setDeleteDocData({
                                field: "PAY_SLIPS",
                                index: index,
                              });
                              setOpenDeleteDocumentDialog({
                                open: true,
                                docDetails: paySlip,
                                categoryId: paySlip.categoryId,
                              });
                            }
                          }}
                          onClick={() => {
                            setDocumentItem(getUploadedDocuments(paySlip));
                            setDownloadUrl(paySlip.docUrl);
                            setDocCategoryId(paySlip.categoryId);
                            setDocNameForDownload(paySlip.fileName);
                            setOpenDocumetDialog(true);
                          }}
                        />
                      ))}
                    </Grid>
                  ) : null}
                </Grid>
                <Grid item xs={3}>
                  <input
                    accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .png, .jpeg, .bmp, .gif, .CSV"
                    style={{ display: "none" }}
                    id="payslipFileBtn"
                    multiple
                    type="file"
                    onChange={(event) => {
                      handleOnChange({ event, field: "PAY_SLIPS", limit: 2 });
                    }}
                  />
                  <label htmlFor="payslipFileBtn" style={{ marginBottom: 16 }}>
                    <Button
                      variant="outlined"
                      color="default"
                      component="span"
                      startIcon={
                        <img src="/images/Icon material-file-upload.png" />
                      }
                      style={{ textTransform: "capitalize" }}
                      className={clsx(classes.root, classes.buttons)}
                    >
                      Upload
                    </Button>
                  </label>
                </Grid>
              </Grid>
            </Grid>
            <Divider
              style={{ width: "100%", marginTop: 16, marginBottom: 16 }}
            />
            <Grid item container xs={12}>
              <Grid item container xs={12} alignItems="center">
                <Grid item xs={9}>
                  <Typography variant="body1" className={Styles.navyBlueColor}>
                    T4 Form
                  </Typography>
                  <Typography
                    style={{ fontSize: 10, fontStyle: "italic" }}
                    className={Styles.navyBlueColor}
                  >
                    If you are an employee (Last 2 years)
                  </Typography>
                  {T4_FORM && T4_FORM.length ? (
                    <Grid item container xs={12}>
                      {T4_FORM.map((tForm, index) => (
                        <Chip
                          key={index}
                          style={{ marginTop: 10, marginLeft: 5 }}
                          label={tForm.fileName || tForm.name}
                          onDelete={() => {
                            if (tForm instanceof File) {
                              deleteDoc({
                                field: "T4_FORM",
                                index: index,
                              });
                            } else {
                              setDeleteDocData({
                                field: "T4_FORM",
                                index: index,
                              });
                              setOpenDeleteDocumentDialog({
                                open: true,
                                docDetails: tForm,
                                categoryId: tForm.categoryId,
                              });
                            }
                          }}
                          onClick={() => {
                            setDocumentItem(getUploadedDocuments(tForm));
                            setDownloadUrl(tForm.docUrl);
                            setDocCategoryId(tForm.categoryId);
                            setDocNameForDownload(tForm.fileName);
                            setOpenDocumetDialog(true);
                          }}
                        />
                      ))}
                    </Grid>
                  ) : null}
                </Grid>
                <Grid item xs={3}>
                  <input
                    accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .png, .jpeg, .bmp, .gif, .CSV"
                    style={{ display: "none" }}
                    id="t4FileBtn"
                    type="file"
                    onChange={(event) => {
                      handleOnChange({ event, field: "T4_FORM", limit: 1 });
                    }}
                    multiple
                  />
                  <label htmlFor="t4FileBtn" style={{ marginBottom: 16 }}>
                    <Button
                      variant="outlined"
                      color="default"
                      component="span"
                      startIcon={
                        <img src="/images/Icon material-file-upload.png" />
                      }
                      style={{ textTransform: "capitalize" }}
                      className={clsx(classes.root, classes.buttons)}
                    >
                      Upload
                    </Button>
                  </label>
                </Grid>
              </Grid>
            </Grid>
            <Divider
              style={{ width: "100%", marginTop: 16, marginBottom: 16 }}
            />
            <Grid item container xs={12}>
              <Grid item container xs={12} alignItems="center">
                <Grid item xs={9}>
                  <Typography variant="body1" className={Styles.navyBlueColor}>
                    Notice of Assessment From Tax Return
                  </Typography>
                  <Typography
                    style={{ fontSize: 10, fontStyle: "italic" }}
                    className={Styles.navyBlueColor}
                  >
                    2 of the most recent
                  </Typography>
                  {NOTICE_OF_ASSESSMENT_FROM_TAX_RETURN &&
                  NOTICE_OF_ASSESSMENT_FROM_TAX_RETURN.length ? (
                    <Grid item container xs={12}>
                      {NOTICE_OF_ASSESSMENT_FROM_TAX_RETURN.map(
                        (notice, index) => (
                          <Chip
                            style={{ marginTop: 10, marginLeft: 5 }}
                            key={index}
                            label={notice.fileName || notice.name}
                            onDelete={() => {
                              if (notice instanceof File) {
                                deleteDoc({
                                  field: "NOTICE_OF_ASSESSMENT_FROM_TAX_RETURN",
                                  index: index,
                                });
                              } else {
                                setDeleteDocData({
                                  field: "NOTICE_OF_ASSESSMENT_FROM_TAX_RETURN",
                                  index: index,
                                });
                                setOpenDeleteDocumentDialog({
                                  open: true,
                                  docDetails: notice,
                                  categoryId: notice.categoryId,
                                });
                              }
                            }}
                            onClick={() => {
                              setDocumentItem(getUploadedDocuments(notice));
                              setDownloadUrl(notice.docUrl);
                              setDocCategoryId(notice.categoryId);
                              setDocNameForDownload(notice.fileName);
                              setOpenDocumetDialog(true);
                            }}
                          />
                        )
                      )}
                    </Grid>
                  ) : null}
                </Grid>
                <Grid item xs={3}>
                  <input
                    accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .png, .jpeg, .bmp, .gif, .CSV"
                    style={{ display: "none" }}
                    id="noticeFileBtn"
                    type="file"
                    multiple
                    onChange={(event) => {
                      handleOnChange({
                        event,
                        field: "NOTICE_OF_ASSESSMENT_FROM_TAX_RETURN",
                        limit: 2,
                      });
                    }}
                  />
                  <label htmlFor="noticeFileBtn" style={{ marginBottom: 16 }}>
                    <Button
                      variant="outlined"
                      color="default"
                      component="span"
                      startIcon={
                        <img src="/images/Icon material-file-upload.png" />
                      }
                      style={{ textTransform: "capitalize" }}
                      className={clsx(classes.root, classes.buttons)}
                    >
                      Upload
                    </Button>
                  </label>
                </Grid>
              </Grid>
            </Grid>
            <Divider
              style={{ width: "100%", marginTop: 16, marginBottom: 16 }}
            />
            <Grid item container xs={12}>
              <Grid item container xs={12} alignItems="center">
                <Grid item xs={9}>
                  <Typography variant="body1" className={Styles.navyBlueColor}>
                    Official Bank Statements/RRSP Statements
                  </Typography>
                  <Typography
                    style={{ fontSize: 10, fontStyle: "italic" }}
                    className={Styles.navyBlueColor}
                  >
                    Last 3 months statement to confirm available down payment
                  </Typography>
                  {OFFICIAL_BANK_STATEMENT_RRSP &&
                  OFFICIAL_BANK_STATEMENT_RRSP.length ? (
                    <Grid item container xs={12}>
                      {OFFICIAL_BANK_STATEMENT_RRSP.map((statement, index) => (
                        <Chip
                          style={{ marginTop: 10, marginLeft: 5 }}
                          key={index}
                          label={statement.fileName || statement.name}
                          onDelete={() => {
                            if (statement instanceof File) {
                              deleteDoc({
                                field: "OFFICIAL_BANK_STATEMENT_RRSP",
                                index: index,
                              });
                            } else {
                              setDeleteDocData({
                                field: "OFFICIAL_BANK_STATEMENT_RRSP",
                                index: index,
                              });
                              setOpenDeleteDocumentDialog({
                                open: true,
                                docDetails: statement,
                                categoryId: statement.categoryId,
                              });
                            }
                          }}
                          onClick={() => {
                            setDocumentItem(getUploadedDocuments(statement));
                            setDownloadUrl(statement.docUrl);
                            setDocCategoryId(statement.categoryId);
                            setDocNameForDownload(statement.fileName);
                            setOpenDocumetDialog(true);
                          }}
                        />
                      ))}
                    </Grid>
                  ) : null}
                </Grid>
                <Grid item xs={3}>
                  <input
                    accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .png, .jpeg, .bmp, .gif, .CSV"
                    style={{ display: "none" }}
                    id="bankFileBtn"
                    multiple
                    type="file"
                    onChange={(event) => {
                      handleOnChange({
                        event,
                        field: "OFFICIAL_BANK_STATEMENT_RRSP",
                        limit: 3,
                      });
                    }}
                  />
                  <label htmlFor="bankFileBtn" style={{ marginBottom: 16 }}>
                    <Button
                      variant="outlined"
                      color="default"
                      component="span"
                      startIcon={
                        <img src="/images/Icon material-file-upload.png" />
                      }
                      style={{ textTransform: "capitalize" }}
                      className={clsx(classes.root, classes.buttons)}
                    >
                      Upload
                    </Button>
                  </label>
                </Grid>
              </Grid>
            </Grid>
            <Divider
              style={{ width: "100%", marginTop: 16, marginBottom: 16 }}
            />
            <Grid item container xs={12}>
              <Grid item container xs={12} alignItems="center">
                <Grid item xs={9}>
                  <Typography variant="body1" className={Styles.navyBlueColor}>
                    Self-employed Corporate Tax Return (If applicable)
                  </Typography>
                  <Typography
                    style={{ fontSize: 10, fontStyle: "italic" }}
                    className={Styles.navyBlueColor}
                  >
                    If you are self-employed, please upload your corporate tax
                    returns
                  </Typography>
                  {SELF_EMPLOYED_CORPORATE_TAX &&
                  SELF_EMPLOYED_CORPORATE_TAX.length ? (
                    <Grid item container xs={12}>
                      {SELF_EMPLOYED_CORPORATE_TAX.map((statement, index) => (
                        <Chip
                          style={{ marginTop: 10, marginLeft: 5 }}
                          key={index}
                          label={statement.fileName || statement.name}
                          onDelete={() => {
                            if (statement instanceof File) {
                              deleteDoc({
                                field: "SELF_EMPLOYED_CORPORATE_TAX",
                                index: index,
                              });
                            } else {
                              setDeleteDocData({
                                field: "SELF_EMPLOYED_CORPORATE_TAX",
                                index: index,
                              });
                              setOpenDeleteDocumentDialog({
                                open: true,
                                docDetails: statement,
                                categoryId: statement.categoryId,
                              });
                            }
                          }}
                          onClick={() => {
                            setDocumentItem(getUploadedDocuments(statement));
                            setDownloadUrl(statement.docUrl);
                            setDocCategoryId(statement.categoryId);
                            setDocNameForDownload(statement.fileName);
                            setOpenDocumetDialog(true);
                          }}
                        />
                      ))}
                    </Grid>
                  ) : null}
                </Grid>
                <Grid item xs={3}>
                  <input
                    accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .png, .jpeg, .bmp, .gif, .CSV"
                    style={{ display: "none" }}
                    id="selfEmployedFileBtn"
                    multiple
                    type="file"
                    onChange={(event) => {
                      handleOnChange({
                        event,
                        field: "SELF_EMPLOYED_CORPORATE_TAX",
                        limit: 3,
                      });
                    }}
                  />
                  <label
                    htmlFor="selfEmployedFileBtn"
                    style={{ marginBottom: 16 }}
                  >
                    <Button
                      variant="outlined"
                      color="default"
                      component="span"
                      startIcon={
                        <img src="/images/Icon material-file-upload.png" />
                      }
                      style={{ textTransform: "capitalize" }}
                      className={clsx(classes.root, classes.buttons)}
                    >
                      Upload
                    </Button>
                  </label>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Container>
        <SetupFooter isSaveAsDraft={false} page="demographic">
          <Button
            variant="contained"
            onClick={() => {
              saveDocumentList((res) => {
                if (res && res.status === 200) {
                  history.push("/homebuyer/dashboard");
                }
              });
            }}
            className={clsx(Styles.primaryBtnAccent, classes.buttons)}
          >
            Submit
          </Button>
        </SetupFooter>
      </Grid>
    </>
  );
};
export default DocumentCheckList;
