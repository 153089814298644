import { useSelector } from "react-redux";
import { useCallback, useState, useEffect } from "react";
import { useValidations } from "../../useValidations";

import {
  ADD_REVIEW,
  REMOVE_REVIEW,
  UPDATE_REVIEW,
  SET_REVIEW_FIELD,
  SET_EXTERNAL_REVIEW_SUPPORT,
} from "../../../souqh-redux/reducers/actionConstants";
import { useActions } from "../../useActions";

const actionCreators = {
  addReview: (payload) => ({
    type: ADD_REVIEW,
    payload,
  }),
  removeReview: (payload) => ({
    type: REMOVE_REVIEW,
    payload,
  }),
  updateReview: (payload) => ({
    type: UPDATE_REVIEW,
    payload,
  }),
  setReview: (payload) => ({
    type: SET_REVIEW_FIELD,
    payload,
  }),
  setExternalReviewSupport: (payload) => ({
    type: SET_EXTERNAL_REVIEW_SUPPORT,
    payload,
  }),
};

export const useReviews = () => {
  const reviews = useSelector((state) => state.storefront.reviews);
  const externalReviewSupport = useSelector(
    (state) => state.storefront.externalReviewSupport
  );
  const actions = useActions(actionCreators);
  const [reviewsMaxLimit, setReviewsMaxLimit] = useState();
  const [allowAction, setAllowAction] = useState(false);

  useEffect(() => {
    let validData;
    if (reviews.length) {
      validData = reviews.some((review) => {
        return !(!!review.title.trim() && !!review.link.trim());
      });
      setAllowAction(!validData);
    } else {
      setAllowAction(true);
    }
  }, [reviews]);
  return {
    reviews,
    actions,
    reviewsMaxLimit,
    setReviewsMaxLimit,
    allowAction,
    externalReviewSupport,
  };
};

const validationMeta = [
  {
    name: "link",
    patterns: [
      {
        match: (val) => /(.*?)/i.test(val),
        msg: "Please enter a valid link.",
      },
    ],
  },
];
export const useReview = (index) => {
  const review = useSelector((state) => state.storefront.reviews[index]);
  const actions = useActions(actionCreators);
  const { link } = useSelector((state) => state.storefront.reviews[index]);
  const getFormValues = useCallback(() => {
    return { link };
  }, [link]);

  const getServerKeysMap = useCallback(() => {
    return {};
  }, []);
  const { validations, addValidations, clearAllValidations } = useValidations(
    validationMeta,
    getFormValues,
    getServerKeysMap
  );

  return { review, actions, validations };
};
