import { Grid, Typography, Switch, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import PaymentMethod from "./PaymentMethod";
import Styles from "../../../Styles.module.css";
import { useCustomerPayments } from "souqh-react-redux-hooks/serviceProvider/storefront/useCustomerPayments";
import ConfirmationDialog from "../../ConfirmationDialog";

function CustomerPayments() {
  const { primaryColor } = useTheme();
  const {
    paymentsVisible,
    actions: { setCustomerPayments },
  } = useCustomerPayments();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [paymentsVisibility, setPaymentsVisibility] = useState(false);
  return (
    <Grid container item xs={12} style={{ marginBottom: 200 }}>
      <ConfirmationDialog
        open={openConfirmationDialog}
        handleYes={() => {
          setCustomerPayments(paymentsVisibility);
          setOpenConfirmationDialog(false);
        }}
        handleNo={() => {
          setOpenConfirmationDialog(false);
        }}
        sectionTitle={"Are you sure you want to disable Payments feature?"}
      />
      <Grid container id="customer_payment" style={{ marginTop: "2rem" }}>
        <Grid
          item
          container
          justifyContent="space-between"
          style={{ marginTop: 60, marginBottom: 8 }}
        >
          <Typography variant="h4" className={Styles.navyBlueColor}>
            Client Payment Options
          </Typography>
          <Switch
            className={"sqGraySwitch"}
            checked={paymentsVisible}
            onChange={(event) => {
              if (!event.target.checked) {
                setOpenConfirmationDialog(true);
                setPaymentsVisibility(event.target.checked);
              } else {
                setCustomerPayments(event.target.checked);
              }
            }}
          ></Switch>
        </Grid>
        <Typography variant="body1" className={Styles.navyBlueColor}>
          Display the methods of payment that you accept from your clients to
          receive payments. Actual payment details will only be visible when you
          allow to share this information with clients
        </Typography>
        {paymentsVisible && <PaymentMethod></PaymentMethod>}
      </Grid>
    </Grid>
  );
}

export default CustomerPayments;
