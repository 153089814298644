import {
  Toolbar,
  makeStyles,
  AppBar,
  Tabs,
  Tab,
  SwipeableDrawer,
  Badge,
} from "@material-ui/core";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSideDrawer } from "souqh-react-redux-hooks/homeBuyer/useSideDrawer";
import clsx from "clsx";
import { useLocation } from "react-router";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PersonIcon from "@material-ui/icons/Person";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import MenuIcon from "@material-ui/icons/Menu";
import { HOME_BUYER_USER } from "../../components/ServiceProvider/serviceProviderConstants";
import Styles from "../../Styles.module.css";
import MobileDrawer from "../MobileDrawer";
import {
  isMobileDevice,
  isOnboardingInComplete,
  isTabletDevice,
} from "../../utils/AppUtils";
import SearchIcon from "@material-ui/icons/Search";
import NotificationContent from "../../components/Notifications/NotificationContent";
import { useNotifications } from "souqh-react-redux-hooks/notifications/useNotifications";
import { useUpcomingEeventsPendingActions } from "souqh-react-redux-hooks/serviceProvider/upcomingEeventsPendingActions/useUpcomingEeventsPendingActions";
import SPUploadWizard from "../../components/common/UploadWizard/SPUploadWizard";
import DocumentAccessRequestDialog from "../../components/common/UpcomingEeventsPendingActions/DocumentAccessRequestDialog";
import ViewOtherActionDialog from "../../components/common/UpcomingEeventsPendingActions/ViewOtherActionDialog";
import ProfileAccessRequestDialog from "../../components/common/UpcomingEeventsPendingActions/ProfileAccessRequestDialog";
import ViewInvoiceDialog from "../../components/common/UpcomingEeventsPendingActions/ViewInvoiceDialog";
import AddReviewDialog from "../../components/HomeBuyer/SPManagement/AddReviewDialog";
import ViewDocumentDialog from "../../components/DocumentManagementDialogs/ViewDocumentDialog";
import SignDocumentDialog from "../../components/ServiceProvider/UploadDocuments/SignDocumentDialog";
import MessageIcon from "@material-ui/icons/Message";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  icon: {
    padding: "0 12px",
    width: "1.5em !important",
    height: "1.5em !important",
  },
  selectedIcon: {
    color: "#FA7E61",
  },
  appBar: {
    zIndex: 1300,
  },
  modal: {
    zIndex: "1400 !important",
  },
  paper: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    // minHeight: "50%",
  },
  customBadge: {
    backgroundColor: "#FA7E61",
    top: 12,
    right: isMobileDevice() ? 22 : 50,
  },
}));
export default function MobileBottomNavigation() {
  const classes = useStyles();
  const history = useHistory();
  const { activeSection, setActiveSection } = useSideDrawer();
  let location = useLocation();
  let locationParam = location.pathname.split("/"); // read URL and split it on "/"
  let activeSectionFromURL = locationParam[locationParam.length - 1]; // take last section from URL
  const user = useAuthUser();

  const [mobileDrawer, setMobileDrawer] = useState(false);
  const [notificationMobileDrawer, setNotificationMobileDrawer] =
    useState(false);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  const spnotificationRef = useRef();

  const {
    getNotifications,
    handelScroll,
    scroller,
    todayNotifications,
    olderNotifications,
    getDescription,
    getAvatarUrl,
    handleNotification,
    showOnlyUnreadNotifications,
    markAllNotificationsRead,
    actions: { setShowOnlyUnreadNotifications },
    openDocumentAccessRequestDialog,
    setOpenDocumentAccessRequestDialog,
    requestedDocAccessName,
    documentAccessRequestorSP,
    setOpenUploadWizard,
    openUploadWizard,
    docAccessRequestedData,
    openDocumetDialog,
    setOpenDocumetDialog,
    documentItem,
    setDocumentItem,
    openSignDocumentDialog,
    setOpenSignDocumentDialog,
    signDocumentItem,
    setSignDocumentItem,
    openViewInvoiceDialog,
    setOpenViewInvoiceDialog,
    viewInvoiceDialogData,
    userInfo,
    openAddReviewDialog,
    setOpenAddReviewDialog,
    openProfileAccessRequestDialog,
    setOpenProfileAccessRequestDialog,
    profileAccessData,
    acceptRejectProfileAccessRequest,
    openOtherActionDialog,
    setOpenOtherActionDialog,
    otherActionData,
    deleteNotification,
  } = useNotifications();

  const { acceptDocumentAccessRequest } = useUpcomingEeventsPendingActions();
  const unreadChannels = useSelector((state) => state.messaging.unreadChannels);
  const { notificationsList } = useSelector((state) => state.notifications);

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("showNotification")) &&
      notificationsList.response &&
      notificationsList.response.length
    ) {
      setNotificationsAnchorEl(spnotificationRef.current);
    }
  }, [notificationsList]);

  const unreadNotifications = notificationsList.response.filter(
    (el) => el.isRead === false
  );

  useEffect(() => {
    getNotifications(0);
  }, []);

  useEffect(() => {
    activeSection !== "menu" && setMobileDrawer(false);
  }, [activeSection]);

  useEffect(() => {
    if (activeSectionFromURL === "serviceprovider") {
      setActiveSection("dashboard");
    } else {
      setActiveSection(activeSectionFromURL);
    }
  }, [location]);

  const toggleMobileDrawer = () => {
    setMobileDrawer(!mobileDrawer);
  };

  const handleNotificationsClose = () => {
    setNotificationMobileDrawer(false);
    if (localStorage.getItem("showNotification")) {
      localStorage.setItem("showNotification", false);
    }
  };

  const noNotifications =
    (todayNotifications && todayNotifications.length) ||
    (olderNotifications && olderNotifications.length);

  return (
    <React.Fragment>
      <SPUploadWizard
        openUploadWizard={openUploadWizard}
        setOpenUploadWizard={setOpenUploadWizard}
      />
      <DocumentAccessRequestDialog
        openDocumentAccessRequestDialog={openDocumentAccessRequestDialog}
        setOpenDocumentAccessRequestDialog={setOpenDocumentAccessRequestDialog}
        requestedDocAccessName={requestedDocAccessName}
        documentAccessRequestorSP={documentAccessRequestorSP}
        acceptDocumentAccessRequest={acceptDocumentAccessRequest}
        docAccessRequestedData={docAccessRequestedData}
      />
      <ViewOtherActionDialog
        openOtherActionDialog={openOtherActionDialog}
        setOpenOtherActionDialog={setOpenOtherActionDialog}
        otherActionData={otherActionData}
        source="notifications"
      />
      <ProfileAccessRequestDialog
        openProfileAccessRequestDialog={openProfileAccessRequestDialog}
        setOpenProfileAccessRequestDialog={setOpenProfileAccessRequestDialog}
        profileAccessData={profileAccessData}
        acceptRejectProfileAccessRequest={acceptRejectProfileAccessRequest}
      />
      <ViewInvoiceDialog
        openViewInvoiceDialog={openViewInvoiceDialog}
        setOpenViewInvoiceDialog={setOpenViewInvoiceDialog}
        viewInvoiceDialogData={viewInvoiceDialogData}
        userInfo={userInfo}
      />
      <AddReviewDialog
        openAddReviewDialog={openAddReviewDialog}
        setOpenAddReviewDialog={setOpenAddReviewDialog}
      />
      <ViewDocumentDialog
        openDocumetDialog={openDocumetDialog}
        setOpenDocumetDialog={setOpenDocumetDialog}
        documentItem={documentItem}
        setDocumentItem={setDocumentItem}
      />
      <SignDocumentDialog
        openSignDocumentDialog={openSignDocumentDialog}
        setOpenSignDocumentDialog={setOpenSignDocumentDialog}
        signDocumentItem={signDocumentItem}
        setSignDocumentItem={setSignDocumentItem}
      />
      <SwipeableDrawer
        classes={{ modal: classes.modal, paper: classes.paper }}
        anchor="bottom"
        open={notificationMobileDrawer}
        onClose={() => {
          setNotificationMobileDrawer(false);
          setShowOnlyUnreadNotifications(false);
        }}
        onOpen={() => {
          setNotificationMobileDrawer(true);
        }}
      >
        <NotificationContent
          handleNotificationsClose={handleNotificationsClose}
          noNotifications={noNotifications}
          handelScroll={handelScroll}
          scroller={scroller}
          todayNotifications={todayNotifications}
          olderNotifications={olderNotifications}
          getDescription={getDescription}
          getAvatarUrl={getAvatarUrl}
          handleNotification={handleNotification}
          setShowOnlyUnreadNotifications={setShowOnlyUnreadNotifications}
          showOnlyUnreadNotifications={showOnlyUnreadNotifications}
          markAllNotificationsRead={markAllNotificationsRead}
          deleteNotification={deleteNotification}
        />
      </SwipeableDrawer>
      <MobileDrawer
        mobileDrawer={mobileDrawer}
        toggleMobileDrawer={toggleMobileDrawer}
        setNotificationMobileDrawer={setNotificationMobileDrawer}
        unreadNotifications={unreadNotifications}
      />
      <AppBar
        // position="fixed"
        className={classes.appBar}
        style={{
          top: "auto",
          bottom: 0,
          backgroundColor: "#0e1c4e",
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          position: "fixed",
        }}
      >
        <Toolbar disableGutters>
          <Tabs
            variant="fullWidth"
            textColor="primary"
            style={{ width: "100%" }}
            classes={{ indicator: Styles.indicator }}
          >
            <Tab
              label="Home"
              icon={<DashboardIcon />}
              style={{
                color: "white",
                textTransform: "capitalize",
                minWidth: isTabletDevice() && 0,
                maxWidth: "20%",
                padding: "6px 8px",
              }}
              className={clsx({
                [Styles.primaryTxtAccentForce]: activeSection === "dashboard",
              })}
              onClick={() => {
                if (user.userType === HOME_BUYER_USER) {
                  history.push(`/homebuyer/dashboard`);
                } else {
                  history.push(`/serviceprovider/dashboard`);
                }
                setActiveSection("dashboard");
              }}
            />
            {user.userType === HOME_BUYER_USER && (
              <Tab
                label="Search"
                icon={<SearchIcon />}
                style={{
                  color: "white",
                  textTransform: "capitalize",
                  minWidth: isTabletDevice() && 0,
                  maxWidth: "20%",
                  padding: "6px 8px",
                }}
                className={clsx({
                  [Styles.primaryTxtAccentForce]: activeSection === "explore",
                })}
                onClick={() => {
                  history.push(`/homebuyer/explore`);
                  setActiveSection("explore");
                }}
              />
            )}

            {user.userType === HOME_BUYER_USER ? (
              <Tab
                label="Services"
                icon={<PersonIcon />}
                style={{
                  color: "white",
                  textTransform: "capitalize",
                  minWidth: isTabletDevice() && 0,
                  maxWidth: "20%",
                  padding: "6px 8px",
                }}
                className={clsx({
                  [Styles.primaryTxtAccentForce]:
                    activeSection === "serviceproviders",
                })}
                onClick={() => {
                  history.push(`/homebuyer/serviceproviders`);
                  setActiveSection("serviceproviders");
                }}
              />
            ) : (
              <Tab
                disabled={isOnboardingInComplete(user)}
                label="Clients"
                icon={<PersonIcon />}
                style={{
                  color: "white",
                  textTransform: "capitalize",
                  minWidth: isTabletDevice() && 0,
                  maxWidth: "20%",
                }}
                className={clsx({
                  [Styles.primaryTxtAccentForce]: activeSection === "clients",
                })}
                onClick={() => {
                  history.push(`/serviceprovider/clients`);
                  setActiveSection("customer");
                }}
              />
            )}
            {(user.userType !== HOME_BUYER_USER ||
              (user.userType === HOME_BUYER_USER && isTabletDevice())) && (
              <Tab
                disabled={
                  user.userType !== HOME_BUYER_USER &&
                  isOnboardingInComplete(user)
                }
                label="Docs"
                icon={<InsertDriveFileIcon />}
                style={{
                  color: "white",
                  textTransform: "capitalize",
                  minWidth: isTabletDevice() && 0,
                  maxWidth: "20%",
                }}
                className={clsx({
                  [Styles.primaryTxtAccentForce]: activeSection === "documents",
                })}
                onClick={() => {
                  if (user.userType === HOME_BUYER_USER) {
                    history.push(`/homebuyer/documents`);
                  } else {
                    history.push(`/serviceprovider/documents`);
                  }
                  setActiveSection("documents");
                }}
              />
            )}
            {isTabletDevice() && user.userType !== HOME_BUYER_USER && (
              <Tab
                label="Marketplace"
                icon={<SearchIcon />}
                style={{
                  color: "white",
                  textTransform: "capitalize",
                  minWidth: isTabletDevice() && 0,
                  maxWidth: "20%",
                }}
                className={clsx({
                  [Styles.primaryTxtAccentForce]: activeSection === "explore",
                })}
                onClick={() => {
                  history.push(`/serviceprovider/explore`);
                  setActiveSection("explore");
                }}
              />
            )}
            <Badge
              variant="dot"
              classes={{ badge: classes.customBadge }}
              invisible={unreadChannels && unreadChannels.length ? false : true}
              style={{ minWidth: "20%" }}
            >
              <Tab
                label="Messages"
                icon={<MessageIcon />}
                style={{
                  textTransform: "capitalize",
                  minWidth: isTabletDevice() && "100%",
                  opacity: 1,
                  padding: "6px 8px",
                }}
                className={clsx({
                  [Styles.primaryTxtAccentForce]: activeSection === "messaging",
                })}
                onClick={() => {
                  setActiveSection("messaging");
                  if (user.userType !== HOME_BUYER_USER) {
                    history.push(`/serviceprovider/messaging`);
                  } else {
                    history.push(`/homebuyer/messaging`);
                  }
                }}
              ></Tab>
            </Badge>
            <Badge
              variant="dot"
              classes={{ badge: classes.customBadge }}
              invisible={unreadNotifications.length ? false : true}
              style={{ minWidth: "20%" }}
            >
              <Tab
                label="More"
                icon={<MenuIcon />}
                style={{
                  textTransform: "capitalize",
                  minWidth: isTabletDevice() && "100%",
                  opacity: 1,
                  padding: "6px 8px",
                }}
                className={clsx({
                  [Styles.primaryTxtAccentForce]: activeSection === "menu",
                })}
                onClick={() => {
                  setActiveSection("menu");
                  toggleMobileDrawer();
                }}
              ></Tab>
            </Badge>
          </Tabs>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
