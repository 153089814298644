import {
  Button,
  ButtonBase,
  Grid,
  Switch,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import Styles from "../../Styles.module.css";
import EditIcon from "@material-ui/icons/Edit";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import * as moment from "moment";
import { BILLING_CYCLE } from "../ServiceProvider/serviceProviderConstants";

export default function NonEditableTeamMemberRow({
  data,
  editTeamMember,
  setOpenRemoveMemberDialog,
  tab,
  resendInviteMail,
  setSelectedUser,
}) {
  const billingCycle = BILLING_CYCLE.find(
    (billing) => billing.value === data.paymentCycle
  );

  return (
    <TableRow>
      <TableCell className={Styles.navyBlueColorForce} style={{ width: "20%" }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography style={{ fontWeight: 600 }}>{data.fullName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Tooltip title={data.email}>
              <Typography className={Styles.sqEllipsisOneLine}>
                {data.email}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell className={Styles.navyBlueColorForce}>
        <Typography>{data && data.roles && data.roles[0]}</Typography>
      </TableCell>
      <TableCell className={Styles.navyBlueColorForce}>
        <Typography>
          {data && data.subscriptionPlan && data.subscriptionPlan.planName}
        </Typography>
      </TableCell>
      <TableCell className={Styles.navyBlueColorForce}>
        <Typography>{billingCycle?.label || ""}</Typography>
      </TableCell>
      <TableCell className={Styles.navyBlueColorForce}>
        {tab !== "pendingMembers" ? (
          <Switch
            size="small"
            className={"sqGraySwitch"}
            checked={data.enable2Factor}
            onChange={(event) => {}}
          />
        ) : null}
      </TableCell>
      <TableCell className={Styles.navyBlueColorForce}>
        <Typography>
          {data.lastLogin
            ? moment(data.lastLogin).format("MMM DD, YYYY - hh:mm A")
            : null}
        </Typography>
      </TableCell>
      <TableCell style={{ paddingLeft: 0 }}>
        {tab !== "pendingMembers" && tab !== "individualSF" && (
          <Grid container>
            <Grid item xs={6}>
              <ButtonBase>
                <EditIcon
                  className={Styles.navyBlueColor}
                  // // onClick={() => editTeamMember({ id: data.id, edit: true })}
                  onClick={() => editTeamMember(data.userId)}
                />
              </ButtonBase>
            </Grid>
            <Grid item xs={6}>
              <ButtonBase>
                <CancelOutlinedIcon
                  // style={{ color: tab === "individualSF" && "grey" }}
                  className={Styles.navyBlueColor}
                  onClick={() => {
                    setSelectedUser(data);
                    setOpenRemoveMemberDialog(true);
                  }}
                />
              </ButtonBase>
            </Grid>
          </Grid>
        )}
        {tab === "pendingMembers" && (
          <Button
            variant="outlined"
            style={{ textTransform: "capitalize" }}
            onClick={() => resendInviteMail(data.inviteId)}
          >
            Resend email
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
}
