import { useState } from "react";
import { useDispatch } from "react-redux";
import { useApiClient } from "../../useApiClient";
import { SET_SP_LABEL } from "../../../souqh-redux/reducers/actionConstants";

export const useAddLabelDailog = (serviceRequestId) => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();

  const [labelText, setLabelText] = useState("");

  const addLabel = (onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .put(
        `/home-buyer/sp/service-request/label/${serviceRequestId}`,
        labelText
      )
      .then((res) => {
        onResult && onResult(res);
        dispatch({
          type: SET_SP_LABEL,
          payload: {
            serviceRequestId: serviceRequestId,
            label: res.data.result,
          },
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    labelText,
    setLabelText,
    addLabel,
  };
};
