import { Grid } from "@material-ui/core";
import React from "react";
import MessageUserActionsPanel from "./MessageUserActionsPanel";
import MessagesChatPanel from "./MessagesChatPanel";
import { isMobileDevice } from "../../utils/AppUtils";

export default function MessageRightPanel({
  selectedChannelData,
  souqhUserInfo,
  setOpenCreateNewGroupChat,
  currentChannelMessages,
  messageTextBoxRef,
  messagePanelRef,
  messageTextBox,
  setMessageTextBox,
  sendMessage,
  onContentScroll,
  processMessageAttachment,
  messageAttachment,
  removeAttachment,
  sendingMessage,
  downloadAttachment,
  showEmojiPicker,
  setShowEmojiPicker,
  selectedChannelMembersList,
  setOpenGroupMembersDialog,
}) {
  return (
    <Grid item xs={12} style={{ height: "100%", paddingTop: 32 }}>
      {selectedChannelData || isMobileDevice() ? (
        <MessagesChatPanel
          selectedChannelData={selectedChannelData}
          souqhUserInfo={souqhUserInfo}
          currentChannelMessages={currentChannelMessages}
          messageTextBoxRef={messageTextBoxRef}
          messagePanelRef={messagePanelRef}
          messageTextBox={messageTextBox}
          setMessageTextBox={setMessageTextBox}
          sendMessage={sendMessage}
          onContentScroll={onContentScroll}
          processMessageAttachment={processMessageAttachment}
          messageAttachment={messageAttachment}
          removeAttachment={removeAttachment}
          sendingMessage={sendingMessage}
          downloadAttachment={downloadAttachment}
          showEmojiPicker={showEmojiPicker}
          setShowEmojiPicker={setShowEmojiPicker}
          selectedChannelMembersList={selectedChannelMembersList}
          setOpenGroupMembersDialog={setOpenGroupMembersDialog}
        />
      ) : (
        <MessageUserActionsPanel
          souqhUserInfo={souqhUserInfo}
          setOpenCreateNewGroupChat={setOpenCreateNewGroupChat}
        />
      )}
    </Grid>
  );
}
