import { Button, Grid, Switch, Typography } from "@material-ui/core";
import React, { useEffect } from "react";

import Styles from "../../Styles.module.css";
import { useConnectedAccount } from "souqh-react-redux-hooks/common/useConnectedAccount";

export default function ECommerceSettings() {
  const {
    connectedAccountDetails,
    getConnectedAccountDetails,
    setAcceptPayments,
    acceptPayments,
    addConnectedAccountDetails,
    updateConnectedAccountDetails,
    stripeConfigLink,
  } = useConnectedAccount();

  useEffect(() => {
    getConnectedAccountDetails();
  }, []);

  return (
    <Grid
      item
      xs={12}
      style={{
        height: "100%",
        overflow: "hidden",
        paddingTop: 32,
        padding: 10,
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h4" className={Styles.navyBlueColor}>
          eCommerce
        </Typography>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 20 }}>
        <Grid container item xs={11} alignContent="center">
          <Typography variant="body1" className={Styles.navyBlueColor}>
            Do you wish to accept payments from clients via Souqh?
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Switch
            style={{ paddingBottom: 10 }}
            className={"sqGraySwitch"}
            checked={acceptPayments}
            onChange={(e) => {
              setAcceptPayments(e.target.checked);
            }}
          ></Switch>
        </Grid>
      </Grid>
      {acceptPayments ? (
        <React.Fragment>
          <Grid
            item
            xs={12}
            style={{ backgroundColor: "#f5f6f8", padding: 20, marginTop: 10 }}
          >
            <Grid
              container
              item
              justifyContent="center"
              style={{ marginTop: 20 }}
            >
              <Typography variant="body1" className={Styles.navyBlueColor}>
                Please click on link below to complete Stripe configuration.
              </Typography>
            </Grid>
            <Grid
              container
              item
              justifyContent="center"
              style={{ marginTop: 20 }}
            >
              {connectedAccountDetails.stripeConnectId ? (
                <Button
                  style={{ marginTop: 20 }}
                  variant="contained"
                  className={Styles.primaryBtnAccent}
                  style={{ textTransform: "capitalize" }}
                  onClick={() => {
                    updateConnectedAccountDetails();
                  }}
                >
                  Update Stripe Connected Configuration
                </Button>
              ) : (
                <Button
                  style={{ marginTop: 20 }}
                  variant="contained"
                  className={Styles.primaryBtnAccent}
                  style={{ textTransform: "capitalize" }}
                  onClick={() => {
                    addConnectedAccountDetails();
                  }}
                >
                  Configure Stripe Connected Account
                </Button>
              )}
            </Grid>
            <Grid
              container
              item
              justifyContent="center"
              style={{ marginTop: 20 }}
            >
              <Typography style={{ fontSize: 12 }} className={Styles.grayText}>
                <a
                  href={stripeConfigLink ? stripeConfigLink : null}
                  target="_blank"
                >
                  {stripeConfigLink ? stripeConfigLink : null}
                </a>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 20 }}>
            <Typography style={{ fontSize: 12 }} className={Styles.grayText}>
              Stripe charges -{" "}
              <a
                href="https://stripe.com/en-gb-ca/connect/pricing"
                target="_blank"
              >
                https://stripe.com/en-gb-ca/connect/pricing
              </a>
            </Typography>
            <Typography style={{ fontSize: 12 }} className={Styles.grayText}>
              Stripe help center -{" "}
              <a
                href="https://support.stripe.com/contact/login"
                target="_blank"
              >
                https://support.stripe.com/contact/login
              </a>
            </Typography>
          </Grid>
        </React.Fragment>
      ) : null}
    </Grid>
  );
}
