import { Divider, Grid, Typography } from "@material-ui/core";
import React from "react";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";
import Styles from "../../../Styles.module.css";
import moment from "moment";

export default function ForecastedResult({ section, campaignData }) {
  return (
    <Grid
      container
      item
      xs={12}
      lg={4}
      style={{
        backgroundColor: "#0E1C4E0A",
        height: isMobileDevice() || isTabletDevice() ? "30%" : "100%",
        overflow: "hidden",
        position: "sticky",
        width: "inherit",
        top: !isMobileDevice() && !isTabletDevice() && 0,
        right: 0,
        bottom: isMobileDevice() && 0,
        marginTop: section === 1 && 20,
      }}
    >
      <div
        style={{
          backgroundColor: "#0E1C4E0A",
          height: isMobileDevice()
            ? 250
            : isTabletDevice()
            ? 350
            : window.innerHeight - 202,
          width: "100%",
          paddingBottom: isMobileDevice() && "0.75rem",
        }}
      >
        <Typography
          variant="subtitle1"
          className={Styles.navyBlueColor}
          style={{
            margin: "10px 20px",
          }}
        >
          Forecasted Result
        </Typography>
        <Typography style={{ color: "#868DA6", margin: "20px 20px 0px 20px" }}>
          Potential Souqh Customers Reached
        </Typography>
        <Typography
          variant="subtitle1"
          className={Styles.navyBlueColor}
          style={{ margin: "0 20px" }}
        >
          {(campaignData && campaignData.forcastedResult) || 0}
        </Typography>
        <Divider style={{ margin: "10px 16px 28px 20px" }} />
        {section === 3 && (
          <React.Fragment>
            <Typography
              style={{ color: "#868DA6", margin: "20px 20px 0px 20px" }}
            >
              Total Cost
            </Typography>
            <Typography
              variant="subtitle1"
              className={Styles.navyBlueColor}
              style={{ margin: "0 20px" }}
            >
              ${campaignData.totalCost}
            </Typography>
            {campaignData.name && campaignData.campaignStartDate && (
              <Typography
                className={Styles.navyBlueColor}
                style={{ margin: "0 20px", fontSize: 10 }}
              >
                {`*Plus taxes. Will be charged from your credit card on `}
                {moment(new Date(campaignData.campaignStartDate)).format(
                  "MMM DD, YYYY"
                )}
              </Typography>
            )}
          </React.Fragment>
        )}
      </div>
    </Grid>
  );
}
