import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useHistory } from "react-router-dom";
import { useSetupDrawer } from "souqh-react-redux-hooks/useSetupDrawer";
import clsx from "clsx";
import { useSubscriptionPlan } from "souqh-react-redux-hooks/serviceProvider/plans/useSubscriptionPlan";
import { Link } from "react-scroll";
import { useLocation } from "react-router";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { APPROVED } from "../ServiceProvider/serviceProviderConstants";
import { isMarketplacePlan } from "../../utils/AppUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    color: "white",
    backgroundColor: "#17174c",
  },
  selected: {
    color: "#FA7E61",
  },
  selectedSubItemText: {
    color: "#17174c",
    backgroundColor: "#FA7E61 !important",
    borderRadius: 8,
  },
  selectedSvg: {
    fontSize: "2.5rem",
  },
  nonSelectedSvg: {
    fontSize: "2rem",
  },
  whiteText: {
    color: "#17174c",
    backgroundColor: "white",
    minWidth: 30,
    marginRight: "12px",
    borderRadius: 4,
    fontWeight: "bold",
    fontSize: "1rem",
  },
  selectedIcon: {
    fontSize: "1rem",
    color: "#17174c",
    fontWeight: "bold",
    backgroundColor: "#FA7E61",
    minWidth: 30,
    marginRight: "12px",
    borderRadius: 4,
  },
  selectedSubItemStyle: {
    borderLeft: "4px solid #0e1c4e !important",
    paddingLeft: 8,
    marginLeft: -5,
    height: "1rem !important",
    paddingTop: "-1 !important",
    display: "inline-block",
    marginBottom: -2,
  },
}));

export default function SetupDrawer() {
  const mapping = {
    profile: 1,
    storefront: 2,
    subscription: 3,
    review: 4,
  };
  const history = useHistory();
  const classes = useStyles();
  const { subscriptionPlans } = useSubscriptionPlan();
  let location = useLocation();
  let locationParam = location.pathname.split("/"); // read URL and split it on "/"
  let activeSectionFromURL = locationParam[locationParam.length - 1]; // take last section from URL
  const userInfo = useAuthUser();

  const {
    activeSection,
    selectedItem,
    selectedSubItem,
    setActiveSection,
    setSelectedItem,
    setSelectedSubItem,
    spProfileStatus,
    actions: { showInvoiceComponent },
  } = useSetupDrawer();

  useEffect(() => {
    // On load of page set the active section on Drawer according to url.
    if (mapping[activeSectionFromURL]) {
      setActiveSection(activeSectionFromURL);
      setSelectedItem(mapping[activeSectionFromURL]);
    }
  }, [location]);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          style={{ display: "flex" }}
        >
          <img
            style={{ height: "200px", width: "200px" }}
            src="/images/souqh_logo_withName_white.svg"
          ></img>
        </ListSubheader>
      }
      className={classes.root}
    >
      <ListItem
        button
        selected={selectedItem === 1}
        onClick={() => {
          history.push("/setup/profile");
          setActiveSection(activeSection === "profile" ? "" : "profile");
          setSelectedItem(1);
        }}
        className={clsx(classes.root, {
          [classes.selected]: selectedItem === 1,
        })}
      >
        <ListItemIcon
          className={clsx(classes.whiteText, {
            [classes.selectedIcon]: selectedItem === 1,
          })}
        >
          <span style={{ marginLeft: 11 }}>1</span>
        </ListItemIcon>
        <ListItemText
          primary="Profile Setup"
          primaryTypographyProps={{ variant: "subtitle1" }}
        />
        {activeSection === "profile" ? (
          <ExpandLess
            className={clsx({
              [classes.selectedSvg]: selectedItem === 1,
              [classes.nonSelectedSvg]: selectedItem !== 1,
            })}
          />
        ) : (
          <ExpandMore
            className={clsx({
              [classes.selectedSvg]: selectedItem === 1,
              [classes.nonSelectedSvg]: selectedItem !== 1,
            })}
          />
        )}
      </ListItem>
      <Collapse in={activeSection === "profile"} timeout="auto" unmountOnExit>
        <List component="div" style={{ paddingLeft: 60, paddingRight: 18 }}>
          <Link to="about_business" spy={true} smooth={true}>
            <ListItem
              selected={selectedSubItem === 1}
              onClick={() => {
                setSelectedSubItem(1);
              }}
              button
              className={clsx(classes.root, {
                [classes.selectedSubItemText]: selectedSubItem === 1,
              })}
              style={{ paddingTop: 0, paddingBottom: 0, marginBottom: 4 }}
            >
              <ListItemText
                primaryTypographyProps={
                  selectedSubItem === 1 && { variant: "subtitle1" }
                }
              >
                <span
                  className={clsx({
                    [classes.selectedSubItemStyle]: selectedSubItem === 1,
                  })}
                />
                Tell us about your business
              </ListItemText>
            </ListItem>
          </Link>
          <Link to="office_address" spy={true} smooth={true}>
            <ListItem
              selected={selectedSubItem === 2}
              onClick={() => {
                setSelectedSubItem(2);
              }}
              button
              className={clsx(classes.root, {
                [classes.selectedSubItemText]: selectedSubItem === 2,
              })}
              style={{ paddingTop: 0, paddingBottom: 0, marginBottom: 4 }}
            >
              <ListItemText
                primaryTypographyProps={
                  selectedSubItem === 2 && { variant: "subtitle1" }
                }
              >
                <span
                  className={clsx({
                    [classes.selectedSubItemStyle]: selectedSubItem === 2,
                  })}
                />
                Office Address
              </ListItemText>
            </ListItem>
          </Link>
          <Link to="your_team" spy={true} smooth={true}>
            <ListItem
              selected={selectedSubItem === 3}
              onClick={() => {
                setSelectedSubItem(3);
              }}
              button
              className={clsx(classes.root, {
                [classes.selectedSubItemText]: selectedSubItem === 3,
              })}
              style={{ paddingTop: 0, paddingBottom: 0, marginBottom: 4 }}
            >
              <ListItemText
                primaryTypographyProps={
                  selectedSubItem === 3 && { variant: "subtitle1" }
                }
              >
                <span
                  className={clsx({
                    [classes.selectedSubItemStyle]: selectedSubItem === 3,
                  })}
                />
                Your Team
              </ListItemText>
            </ListItem>
          </Link>
        </List>
      </Collapse>
      <ListItem
        disabled={spProfileStatus ? !spProfileStatus.profileSetupStatus : true}
        button
        selected={selectedItem === 2}
        onClick={() => {
          history.push("/setup/storefront");
          setActiveSection(activeSection === "storefront" ? "" : "storefront");
          setSelectedItem(2);
        }}
        className={clsx(classes.root, {
          [classes.selected]: selectedItem === 2,
        })}
      >
        <ListItemIcon
          className={clsx(classes.whiteText, {
            [classes.selectedIcon]: selectedItem === 2,
          })}
        >
          <span style={{ marginLeft: 11 }}>2</span>
        </ListItemIcon>
        <ListItemText
          primary="Create Storefront"
          primaryTypographyProps={{ variant: "subtitle1" }}
        />
        {activeSection === "storefront" ? (
          <ExpandLess
            className={clsx({
              [classes.selectedSvg]: selectedItem === 2,
              [classes.nonSelectedSvg]: selectedItem !== 2,
            })}
          />
        ) : (
          <ExpandMore
            className={clsx({
              [classes.selectedSvg]: selectedItem === 2,
              [classes.nonSelectedSvg]: selectedItem !== 2,
            })}
          />
        )}
      </ListItem>
      <Collapse
        in={activeSection === "storefront"}
        timeout="auto"
        unmountOnExit
      >
        <List
          component="div"
          disablePadding
          style={{ paddingLeft: 60, paddingRight: 18 }}
        >
          <Link to="brand_theme" spy={true} smooth={true}>
            <ListItem
              selected={selectedSubItem === 1}
              onClick={() => {
                setSelectedSubItem(1);
              }}
              button
              className={clsx(classes.root, {
                [classes.selectedSubItemText]: selectedSubItem === 1,
              })}
              style={{ paddingTop: 0, paddingBottom: 0, marginBottom: 4 }}
            >
              <ListItemText
                primaryTypographyProps={
                  selectedSubItem === 1 && { variant: "subtitle1" }
                }
              >
                <span
                  className={clsx({
                    [classes.selectedSubItemStyle]: selectedSubItem === 1,
                  })}
                />
                Brand and Theme
              </ListItemText>
            </ListItem>
          </Link>
          <Link to="service_details" spy={true} smooth={true}>
            <ListItem
              selected={selectedSubItem === 2}
              onClick={() => {
                setSelectedSubItem(2);
              }}
              button
              className={clsx(classes.root, {
                [classes.selectedSubItemText]: selectedSubItem === 2,
              })}
              style={{ paddingTop: 0, paddingBottom: 0, marginBottom: 4 }}
            >
              <ListItemText
                primaryTypographyProps={
                  selectedSubItem === 2 && { variant: "subtitle1" }
                }
              >
                <span
                  className={clsx({
                    [classes.selectedSubItemStyle]: selectedSubItem === 2,
                  })}
                />
                Service Details
              </ListItemText>
            </ListItem>
          </Link>
          <Link to="customer_payment" spy={true} smooth={true}>
            <ListItem
              selected={selectedSubItem === 3}
              onClick={() => {
                setSelectedSubItem(3);
              }}
              button
              className={clsx(classes.root, {
                [classes.selectedSubItemText]: selectedSubItem === 3,
              })}
              style={{ paddingTop: 0, paddingBottom: 0, marginBottom: 4 }}
            >
              <ListItemText
                primaryTypographyProps={
                  selectedSubItem === 3 && { variant: "subtitle1" }
                }
              >
                <span
                  className={clsx({
                    [classes.selectedSubItemStyle]: selectedSubItem === 3,
                  })}
                />
                Client Payment
              </ListItemText>
            </ListItem>
          </Link>
        </List>
      </Collapse>
      {userInfo.spApprovalStatus !== APPROVED || isMarketplacePlan(userInfo) ? (
        <ListItem
          disabled={
            spProfileStatus ? !spProfileStatus.profileSetupStatus : true
          }
          button
          selected={selectedItem === 3}
          onClick={() => {
            history.push("/setup/subscription");
            setActiveSection(
              activeSection === "subscription" ? "" : "subscription"
            );
            setSelectedItem(3);
            showInvoiceComponent(false);
          }}
          className={clsx(classes.root, {
            [classes.selected]: selectedItem === 3,
          })}
          style={{ paddingTop: 2, paddingBottom: 2, marginBottom: 4 }}
        >
          <ListItemIcon
            className={clsx(classes.whiteText, {
              [classes.selectedIcon]: selectedItem === 3,
            })}
          >
            <span style={{ marginLeft: 11 }}>3</span>
          </ListItemIcon>
          <ListItemText
            primary="Subscription Plan"
            primaryTypographyProps={{ variant: "subtitle1" }}
          />
          {activeSection === "subscription" ? (
            <ExpandLess
              className={clsx({
                [classes.selectedSvg]: selectedItem === 3,
                [classes.nonSelectedSvg]: selectedItem !== 3,
              })}
            />
          ) : (
            <ExpandMore
              className={clsx({
                [classes.selectedSvg]: selectedItem === 3,
                [classes.nonSelectedSvg]: selectedItem !== 3,
              })}
            />
          )}
        </ListItem>
      ) : null}
      <Collapse
        in={activeSection === "subscription"}
        timeout="auto"
        unmountOnExit
      >
        <List
          component="div"
          disablePadding
          style={{ paddingLeft: 60, paddingRight: 18 }}
        >
          <Link to="plan" spy={true} smooth={true}>
            <ListItem
              selected={selectedSubItem === 1}
              onClick={() => {
                showInvoiceComponent(false);
                setSelectedSubItem(1);
              }}
              button
              className={clsx(classes.root, {
                [classes.selectedSubItemText]: selectedSubItem === 1,
              })}
              style={{ paddingTop: 0, paddingBottom: 0, marginBottom: 4 }}
            >
              <ListItemText
                primaryTypographyProps={
                  selectedSubItem === 1 && { variant: "subtitle1" }
                }
              >
                <span
                  className={clsx({
                    [classes.selectedSubItemStyle]: selectedSubItem === 1,
                  })}
                />
                {subscriptionPlans.case === 2
                  ? "Select Team Plan"
                  : "Select your payment plan"}
              </ListItemText>
            </ListItem>
          </Link>
          <Link to="invoice" spy={true} smooth={true}>
            <ListItem
              disabled={
                spProfileStatus ? !spProfileStatus.isPaymentPlanSelected : true
              }
              selected={selectedSubItem === 2}
              onClick={() => {
                showInvoiceComponent(true);
                setSelectedSubItem(2);
              }}
              button
              className={clsx(classes.root, {
                [classes.selectedSubItemText]: selectedSubItem === 2,
              })}
              style={{ paddingTop: 0, paddingBottom: 0, marginBottom: 4 }}
            >
              <ListItemText
                primaryTypographyProps={
                  selectedSubItem === 2 && { variant: "subtitle1" }
                }
              >
                <span
                  className={clsx({
                    [classes.selectedSubItemStyle]: selectedSubItem === 2,
                  })}
                />
                Payment and Invoice
              </ListItemText>
            </ListItem>
          </Link>
        </List>
      </Collapse>

      <ListItem
        disabled={
          spProfileStatus &&
          spProfileStatus.isPaymentCardAdded &&
          spProfileStatus.profileSetupStatus
            ? false
            : true
        }
        button
        selected={selectedItem === 4}
        onClick={() => {
          history.push("/setup/review");
          setActiveSection(activeSection === "review" ? "" : "review");
          setSelectedItem(4);
        }}
        className={clsx(classes.root, {
          [classes.selected]: selectedItem === 4,
        })}
        style={{ paddingTop: 0, paddingBottom: 0, marginBottom: 4 }}
      >
        <ListItemIcon
          className={clsx(classes.whiteText, {
            [classes.selectedIcon]: selectedItem === 4,
          })}
        >
          <span style={{ marginLeft: 11 }}>4</span>
        </ListItemIcon>
        <ListItemText
          primary="Review & Submit"
          primaryTypographyProps={{ variant: "subtitle1" }}
        />
        {/* {activeSection === "review" ? (
          <ExpandLess
            className={clsx({
              [classes.selectedSvg]: selectedItem === 4,
              [classes.nonSelectedSvg]: selectedItem !== 4,
            })}
          />
        ) : (
          <ExpandMore
            className={clsx({
              [classes.selectedSvg]: selectedItem === 4,
              [classes.nonSelectedSvg]: selectedItem !== 4,
            })}
          />
        )} */}
      </ListItem>
      {/* <Collapse in={activeSection === "review"} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          style={{ paddingLeft: 60, paddingRight: 18 }}
        >
          <ListItem
            selected={selectedSubItem === 1}
            onClick={() => {
              setSelectedSubItem(1);
            }}
            button
            className={clsx(classes.root, {
              [classes.selectedSubItemText]: selectedSubItem === 1,
            })}
            style={{ paddingTop: 2, paddingBottom: 2, marginBottom: 4 }}
          >
            <ListItemText
              primary="Lets get everything right!"
              primaryTypographyProps={
                selectedSubItem === 1 && { variant: "subtitle1" }
              }
            />
          </ListItem>
        </List>
      </Collapse> */}
    </List>
  );
}
