import {
  SET_CO_APPLICANT,
  SAVE_CO_APPLICANT,
  SAVE_CURRENT_ADDRESS,
  UPDATE_CURRENT_ADDRESS,
  EDIT_CURRENT_ADDRESS,
  RESET_CURRENT_ADDRESS,
  UPDATE_PROFILE_PHOTO,
  UPDATE_PRIMARY_APPLICANT,
  SET_HB_PERSONAL_INFO,
} from "souqh-redux/reducers/actionConstants";
import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useActions } from "../../useActions";
import { useProvinces } from "../../useProvinces";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import {
  emailValidationMeta,
  mobileNumberValidationMeta,
} from "../../validationMetas";
import { useValidations } from "../../useValidations";
import BlobManager from "../../../web/src/utils/BlobManager";
import { MARITAL_STATUS } from "../../../web/src/components/HBRegistration/HomeBuyerConstants";
import { useApiClient } from "../../useApiClient";

const addToBlobManager = (url, name) => {
  return fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      return BlobManager.addFromBlob(blob, name, "image/png", "png");
    });
};

const generateBlobs = async (data) => {
  // banner
  if (data.profilePhoto) {
    const { fileName } = await addToBlobManager(
      data.profilePhoto,
      "hb-profile"
    );
    data.profilePhoto = fileName;
  }
};
const capitalize = (status) => {
  return status && status.toUpperCase();
};
// Save personal info
export const useSavePersonalInfo = (user) => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();
  const {
    profilePhoto,
    coApplicantSupport,
    coApplicant,
    currentAddress,
    primaryApplicant,
  } = useSelector((state) => state.hbSetup);

  // Form POST API data
  let hbCurrentAddress;
  let coApplicantData;
  if (currentAddress) {
    hbCurrentAddress = {
      cityId: currentAddress.city && currentAddress.city.id,
      county: currentAddress.county,
      id: currentAddress.id || null,
      otherCity: currentAddress.otherCity || null,
      postalCode: currentAddress.postalCode,
      provinceId: currentAddress.province && currentAddress.province.id,
      streetName: currentAddress.streetName,
      streetNumber: currentAddress.streetNumber,
    };
  }
  if (coApplicantSupport) {
    coApplicantData = {
      ...coApplicant,
      id: coApplicant.id,
    };
  }
  const formData = new FormData();
  const profileData = {
    avatarUrl: profilePhoto || null,
    coApplicant: coApplicantData || null,
    coApplicantAvailable: coApplicantSupport,
    hbCurrentAddress,
    primaryApplicant: {
      contactNumber: primaryApplicant.phoneNumber,
      maritalStatus:
        primaryApplicant.maritalStatus && primaryApplicant.maritalStatus.value,
    },
  };
  const imageBlob = BlobManager.getByName(profilePhoto);
  imageBlob
    ? formData.append("profilePhoto", imageBlob.file, imageBlob.fileName)
    : formData.append("profilePhoto", null);
  formData.append(
    "personalDetails",
    new Blob([JSON.stringify(profileData)], {
      type: "application/json",
    })
  );

  const savePersonalInfo = (onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .put(`/home-buyer/profile/personal/${user.userId}`, formData)
      .then((res) => {
        onResult && onResult(res);
        // Set avatarUrl if initially profile photo is not there
        if (res.data && res.data.result && res.data.result.avatarUrl) {
          dispatch({
            type: "SAVE_USER",
            payload: { avatarUrl: res.data.result.avatarUrl },
          });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };
  return { savePersonalInfo };
};
const actionCreators = {
  setCoApplicant: (payload) => ({ type: SET_CO_APPLICANT, payload }),
  saveCoApplicant: (payload) => ({ type: SAVE_CO_APPLICANT, payload }),
  updateCurrentAddress: (payload) => ({
    type: UPDATE_CURRENT_ADDRESS,
    payload,
  }),
  saveCurrentAddress: () => ({ type: SAVE_CURRENT_ADDRESS }),
  clickEdit: () => ({ type: EDIT_CURRENT_ADDRESS }),
  resetCurrentAddress: () => ({ type: RESET_CURRENT_ADDRESS }),
  updateProfilePhoto: (payload) => ({ type: UPDATE_PROFILE_PHOTO, payload }),
  updatePrimaryApplicant: (payload) => ({
    type: UPDATE_PRIMARY_APPLICANT,
    payload,
  }),
};
const validationMeta = [emailValidationMeta, mobileNumberValidationMeta];

export const usePersonalOverview = (fetchBlob) => {
  const { apiClient } = useApiClient();
  const user = useAuthUser();
  const dispatch = useDispatch();
  const [allowSaveAndContinue, setAllowSaveAndContinue] = useState(false);
  const [validCoApplicantData, setValidCoApplicantData] = useState(false);
  const [allowReset, setAllowReset] = useState(false);
  const [clientProfileImage, setClientProfileImage] = useState(false);
  const [clientFirstName, setClientFirstName] = useState(false);
  const [onSouqhSince, setonSouqhSince] = useState("");
  const [activeSps, setActiveSps] = useState([]);
  const { provinces } = useProvinces(1); // FIXME get country id from logged in user
  const {
    coApplicantSupport,
    coApplicant,
    currentAddress,
    primaryApplicant,
    profilePhoto,
  } = useSelector((state) => state.hbSetup);
  const actions = useActions(actionCreators);

  const { email, fullName } = useSelector((state) => state.hbSetup.coApplicant);
  const { phoneNumber } = useSelector(
    (state) => state.hbSetup.primaryApplicant
  );
  const getFormValues = useCallback(() => {
    return { email, phoneNumber };
  }, [email, phoneNumber]);
  const getServerKeysMap = useCallback(() => {
    return {
      email: "email",
      contactNumber: "phoneNumber",
    };
  }, []);

  const { validations, addValidations, clearAllValidations } = useValidations(
    validationMeta,
    getFormValues,
    getServerKeysMap
  );
  useEffect(() => {
    let validData =
      currentAddress &&
      currentAddress.streetNumber &&
      !!currentAddress.streetNumber.trim() &&
      !!currentAddress.city &&
      currentAddress.province &&
      currentAddress.postalCode &&
      !!currentAddress.postalCode.trim() &&
      primaryApplicant &&
      !!primaryApplicant.phoneNumber &&
      !!primaryApplicant.maritalStatus &&
      !Object.keys(validations).length;

    setAllowSaveAndContinue(validData);
    validData =
      currentAddress &&
      Object.keys(currentAddress).some((key) => currentAddress[key]);
    setAllowReset(validData);
  }, [currentAddress, primaryApplicant, validations]);

  useEffect(() => {
    if (!coApplicantSupport) {
      actions.saveCoApplicant({ email: "", fullName: "" });
      setValidCoApplicantData(true);
    } else if (coApplicantSupport) {
      let validData =
        coApplicant &&
        !!fullName &&
        !!email &&
        !Object.keys(validations).length;
      setValidCoApplicantData(validData);
    }
  }, [coApplicantSupport, fullName, email, validations]);

  const getPersonalData = (userId) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/home-buyer/profile/personal/${userId}`)
      .then(async (response) => {
        const data = mapKeysToReducer(response.data.result);
        setClientProfileImage(response.data.result.avatarUrl);
        setClientFirstName(response.data.result.primaryApplicant.firstName);
        setonSouqhSince(response.data.result.onSouqhSince);
        setActiveSps(response.data.result.activeServiceProviders);
        if (fetchBlob) {
          try {
            await generateBlobs(data);
          } catch (e) {
            console.error(e);
          }
        }
        dispatch({
          type: SET_HB_PERSONAL_INFO,
          payload: data,
        });
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };
  const mapKeysToReducer = (data) => {
    let currentAddress;
    if (data.hbCurrentAddress) {
      currentAddress = {
        id: data.hbCurrentAddress.id,
        editMode: data.hbCurrentAddress.id ? true : false,
        streetName: data.hbCurrentAddress.streetName,
        streetNumber: data.hbCurrentAddress.streetNumber,
        province: data.hbCurrentAddress.province,
        county: data.hbCurrentAddress.county || "Canada",
        postalCode: data.hbCurrentAddress.postalCode,
        city: data.hbCurrentAddress.city,
      };
    }
    return {
      profilePhoto: data.avatarUrl,
      coApplicantSupport: data.coApplicantAvailable || false,
      coApplicant: {
        ...data.coApplicant,
        id: data.coApplicant && data.coApplicant.id,
      },
      primaryApplicant: {
        fullName: `${data.primaryApplicant.firstName} ${data.primaryApplicant.lastName}`,
        email: data.primaryApplicant.email,
        phoneNumber: data.primaryApplicant.contactNumber,
        maritalStatus:
          data.primaryApplicant &&
          MARITAL_STATUS.filter(
            (mData) => mData.value === data.primaryApplicant.maritalStatus
          )[0],
      },
      currentAddress: {
        ...currentAddress,
        county: (currentAddress && currentAddress.county) || "Canada",
      },
    };
  };

  return {
    user,
    coApplicantSupport,
    primaryApplicant,
    actions,
    coApplicant,
    provinces,
    currentAddress,
    allowReset,
    validations,
    profilePhoto,
    allowSaveAndContinue,
    getPersonalData,
    clientProfileImage,
    clientFirstName,
    onSouqhSince,
    activeSps,
    addValidations,
    validCoApplicantData,
  };
};
