import React from "react";

import {
  Typography,
  IconButton,
  Button,
  Dialog,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  makeStyles,
} from "@material-ui/core/";
import clsx from "clsx";
import Styles from "../../../Styles.module.css";

const styles = makeStyles((theme) => ({
  selectedIcon: {
    fontWeight: "bold",
    backgroundColor: "#FA7E61",
  },
  nonSelectedIcon: {
    backgroundColor: "white",
  },
  default: {
    marginLeft: 7,
    padding: "1px 12px 1px 12px",
    borderRadius: "4px",
    fontWeight: "bold",
  },
  selectedText: {
    color: "#FA7E61",
  },
  defaultText: {
    color: "white",
  },
}));

export default function EsignStepsHeader({ activeEsignSection }) {
  const classes = styles();

  return (
    <Grid
      item
      xs={12}
      className={Styles.primaryBackground}
      style={{ height: 60, display: "flex", alignItems: "center" }}
    >
      <List style={{ width: "100%" }}>
        <Grid container style={{ padding: "2px 84px" }}>
          <Grid item xs={3}>
            <ListItem
              button
              className={clsx({
                [classes.selectedText]: activeEsignSection === 1,
                [classes.defaultText]: activeEsignSection !== 1,
              })}
              style={{ cursor: "default" }}
            >
              <ListItemIcon>
                <span
                  className={clsx(classes.default, Styles.navyBlueColor, {
                    [classes.selectedIcon]: activeEsignSection === 1,
                    [classes.nonSelectedIcon]: activeEsignSection !== 1,
                  })}
                >
                  1
                </span>
              </ListItemIcon>
              <ListItemText
                primary="Select & Upload"
                primaryTypographyProps={{ variant: "inherit" }}
              />
            </ListItem>
          </Grid>
          <Grid item xs={3}>
            <ListItem
              button
              className={clsx({
                [classes.selectedText]: activeEsignSection === 2,
                [classes.defaultText]: activeEsignSection !== 2,
              })}
              style={{ cursor: "default" }}
            >
              <ListItemIcon>
                <span
                  className={clsx(classes.default, Styles.navyBlueColor, {
                    [classes.selectedIcon]: activeEsignSection === 2,
                    [classes.nonSelectedIcon]: activeEsignSection !== 2,
                  })}
                >
                  2
                </span>
              </ListItemIcon>
              <ListItemText
                primary="Tag Document"
                primaryTypographyProps={{ variant: "inherit" }}
              />
            </ListItem>
          </Grid>
          <Grid item xs={3}>
            <ListItem
              button
              className={clsx({
                [classes.selectedText]: activeEsignSection === 3,
                [classes.defaultText]: activeEsignSection !== 3,
              })}
              style={{ cursor: "default" }}
            >
              <ListItemIcon>
                <span
                  className={clsx(classes.default, Styles.navyBlueColor, {
                    [classes.selectedIcon]: activeEsignSection === 3,
                    [classes.nonSelectedIcon]: activeEsignSection !== 3,
                  })}
                >
                  3
                </span>
              </ListItemIcon>
              <ListItemText
                primary="Document Summary"
                primaryTypographyProps={{ variant: "inherit" }}
              />
            </ListItem>
          </Grid>
          <Grid item xs={3}>
            <ListItem
              button
              className={clsx({
                [classes.selectedText]: activeEsignSection === 4,
                [classes.defaultText]: activeEsignSection !== 4,
              })}
              style={{ cursor: "default" }}
            >
              <ListItemIcon>
                <span
                  className={clsx(classes.default, Styles.navyBlueColor, {
                    [classes.selectedIcon]: activeEsignSection === 4,
                    [classes.nonSelectedIcon]: activeEsignSection !== 4,
                  })}
                >
                  4
                </span>
              </ListItemIcon>
              <ListItemText
                primary="Invite &#38; Send"
                primaryTypographyProps={{ variant: "inherit" }}
              />
            </ListItem>
          </Grid>
        </Grid>
      </List>
    </Grid>
  );
}
