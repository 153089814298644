import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  makeStyles,
  Avatar,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import ContactNumber from "../../HomeBuyer/SPManagement/ContactNumber";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 466,
    minWidth: 800,
  },
}));

function RecommendedSPDialog({
  openRecommendedSPDialog,
  setOpenRecommendedSPDialog,
  recommendedSpData,
}) {
  const classes = useStyles();
  const storeFrontContent =
    recommendedSpData && JSON.parse(recommendedSpData.storeFrontContent);

  return (
    <Dialog
      fullWidth
      open={openRecommendedSPDialog}
      onClose={() => {
        setOpenRecommendedSPDialog(false);
      }}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle
        className={Styles.navyBlueColor}
        style={{ padding: "12px 24px" }}
      >
        <Typography variant="subtitle1">
          Recommended Service Provider
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ marginTop: 8 }}>
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <Avatar
              style={{ height: 56, width: 56, borderRadius: 4 }}
              variant="square"
              src={storeFrontContent && storeFrontContent.displayPicture}
            >
              {(
                (storeFrontContent && storeFrontContent.storefrontName) ||
                ""
              ).charAt(0)}
            </Avatar>
          </Grid>
          <Grid container item xs={11} style={{ paddingLeft: 10 }}>
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <Typography
                  className={Styles.navyBlueColor}
                  variant="body1"
                  style={{ fontWeight: 500 }}
                >
                  {storeFrontContent && storeFrontContent.storefrontName}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={1} style={{ marginRight: -20 }}>
                <EmailIcon className={Styles.navyBlueColor} />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body1" className={Styles.navyBlueColor}>
                  {(recommendedSpData && recommendedSpData.email) || "-"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={1} style={{ marginRight: -20 }}>
                <CallIcon className={Styles.navyBlueColor} />
              </Grid>
              <Grid item xs={11}>
                <ContactNumber
                  contactNumber={
                    recommendedSpData && recommendedSpData.contactNo
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Divider
            orientation="horizontal"
            variant="fullWidth"
            style={{ width: "100%", margin: "16px 0" }}
          />
          <Grid item xs={12}>
            <Typography className={Styles.navyBlueColor} variant="subtitle1">
              {(storeFrontContent && storeFrontContent.competeText) || "-"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={Styles.navyBlueColor}>
              {(storeFrontContent && storeFrontContent.companyOverview) || "-"}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions style={{ padding: "16px 24px" }}>
        <Button
          variant="outlined"
          style={{
            padding: "6px 16px",
            textTransform: "capitalize",
          }}
          className={Styles.navyBlueColorForce}
          onClick={() => {
            setOpenRecommendedSPDialog(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={recommendedSpData && !recommendedSpData.storeFrontUrl}
          variant="contained"
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize", padding: "6px 32px" }}
          onClick={() => {
            setOpenRecommendedSPDialog(false);
            recommendedSpData.storeFrontUrl &&
              window.open(recommendedSpData.storeFrontUrl, "_blank");
          }}
        >
          Visit Storefront
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RecommendedSPDialog;
