import { useSelector, useDispatch } from "react-redux";
import { useAuthUser } from "../useLogin";
import {
  SET_STOREFRONT_DATA,
  INITIAL_STATE_STORE,
} from "souqh-redux/reducers/actionConstants";
import { useApiClient } from "../useApiClient";
import {
  ADVANCED,
  PREMIUM,
  SERVICE_PROVIDER_USER,
  TEAM_MEMBER_USER,
} from "../../web/src/components/ServiceProvider/serviceProviderConstants";
import { useState } from "react";

export const useStorefrontReview = () => {
  const { apiClient } = useApiClient();
  const user = useAuthUser();
  const dispatch = useDispatch();

  const [fullSFDetails, setFullSFDetails] = useState(null);

  const storeFrontDetails = useSelector((state) => state.storefront);

  const getStorefrontDetails = () => {
    if (user.serviceProviderId || user.parentServiceProviderId) {
      let url =
        user.userType === SERVICE_PROVIDER_USER ||
        (user.userType === TEAM_MEMBER_USER &&
          (user.subscriptionPlanName === PREMIUM || user.subscriptionPlanName === ADVANCED))
          ? `/service-provider/storefront?serviceProviderId=${user.serviceProviderId}`
          : `/service-provider/storefront?serviceProviderId=${user.parentServiceProviderId}`;
      dispatch({ type: "SET_LOADING", value: true });
      return apiClient
        .get(url)
        .then(function (response) {
          if (
            response &&
            response.data &&
            response.data.result &&
            response.data.result.length &&
            response.data.result[0].storeFrontId
          ) {
            const { storeFrontContent, services } = response.data.result[0];
            let rStoreFront = JSON.parse(storeFrontContent);
            rStoreFront.services = services;
            rStoreFront.storeFrontId = response.data.result[0].storeFrontId;
            rStoreFront.storeFrontRouteName =
              response.data.result[0].storeFrontRouteName;
            setFullSFDetails(response.data.result[0]);
            if (rStoreFront) {
              dispatch({
                type: SET_STOREFRONT_DATA,
                payload: { storeFront: rStoreFront },
              });
            } else {
              dispatch({
                type: INITIAL_STATE_STORE,
              });
            }
            const existing = JSON.parse(localStorage.getItem("userInfo"));
            if (!existing.spProfilePicture) {
              existing.spProfilePicture = rStoreFront.displayPicture;
              localStorage.setItem("userInfo", JSON.stringify(existing));
            }
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  return {
    storeFrontDetails,
    getStorefrontDetails,
    fullSFDetails,
  };
};
