import React, { useState, useEffect } from "react";
import { useBillingAddress } from "souqh-react-redux-hooks/serviceProvider/useSetUp";
import { find } from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
  Paper,
  makeStyles,
  Divider,
} from "@material-ui/core";
import Address from "./Address";
import Styles from "../../../Styles.module.css";
import { useTheme } from "souqh-react-redux-hooks/serviceProvider/storefront/useTheme";

const useStyles = makeStyles((theme) => ({
  paddingTop: {
    paddingTop: 10,
  },
  paddingLeft: {
    paddingLeft: 10,
    paddingRight: 16,
  },
  innerContainer: {
    paddingleft: 23,
    paddingRight: 26,
    marginTop: 10,
  },
}));

function BillingAddress() {
  const classes = useStyles();
  const {
    officeAddresses,
    billingAddress,
    billingAddressSameAsOA,
    provinces,
    cities,
    actions: { setBillingSameASOA, saveAddressField },
  } = useBillingAddress();

  const [changedManually, setChangeManullay] = useState(false);
  const { primaryColor } = useTheme();

  useEffect(() => {
    const pa = find(officeAddresses, (a) => a.isPrimary);
    if (!changedManually && pa !== billingAddress) {
      setBillingSameASOA({ check: true, title: pa.title });
    }
  }, [officeAddresses, changedManually]);

  return (
    <Paper style={{ paddingTop: 20 }} elevation={0}>
      <Grid container>
        <Typography
          variant="subtitle1"
          className={Styles.navyBlueColor}
          style={{ paddingLeft: 32 }}
        >
          Billing Address
        </Typography>
        <Divider
          orientation="horizontal"
          variant="fullWidth"
          style={{ width: "100%", marginTop: 5 }}
        />
        <Grid container alignItems="center" className={classes.innerContainer}>
          <Grid item xs={6}>
            <FormControlLabel
              value={billingAddressSameAsOA}
              control={
                <Switch
                  className={"sqGraySwitch"}
                  checked={billingAddressSameAsOA}
                  onChange={(e) => {
                    setChangeManullay(!e.target.checked);
                    setBillingSameASOA({ check: e.target.checked });
                  }}
                />
              }
              label={
                <Typography
                  variant="body1"
                  className={Styles.navyBlueColor}
                  style={{ paddingLeft: 16 }}
                >
                  Same as Office address
                </Typography>
              }
              labelPlacement="start"
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container item className={classes.paddingLeft}>
              <Autocomplete
                disabled={!billingAddressSameAsOA}
                fullWidth
                value={billingAddressSameAsOA ? billingAddress : null}
                onChange={(e, value) => {
                  setChangeManullay(true);
                  if (value) {
                    setBillingSameASOA({ check: true, title: value.title });
                  }
                }}
                id={"billing-address-from-office-addresses"}
                options={officeAddresses}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    {...params}
                    label="Choose Office Address"
                  />
                )}
                required
              />
            </Grid>
          </Grid>
        </Grid>

        <Address
          disabled={billingAddressSameAsOA}
          {...billingAddress}
          index="billing-address"
          provinces={provinces}
          cities={cities}
          saveAddressField={saveAddressField}
        ></Address>
      </Grid>
    </Paper>
  );
}

export default BillingAddress;
