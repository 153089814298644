import { useEffect, useState } from "react";

export const localeMap = {
  enUS: "enUS",
  hiIN: "hiIN",
  hi: "hiIN",
  fr: "frFR",
  frFR: "frFR",
  frCA: "frFR",
  frCH: "frFR",
};

export const loadLocale = (locale) => {
  return import("./" + (localeMap[locale] || localeMap.enUS));
};

export const useLocale = (inLocale = "en-US") => {
  inLocale = inLocale.replaceAll("-", "");
  const [locale, setLocale] = useState(null);
  useEffect(() => {
    const load = async () => {
      const {
        default: { souqh },
      } = await loadLocale(inLocale);
      setLocale(souqh);
    };

    if (!locale) {
      load();
    }
  }, [locale]);

  return { locale };
};
