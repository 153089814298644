import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Styles from "../../../../Styles.module.css";
import RoomIcon from "@material-ui/icons/Room";

const useStyles = makeStyles((theme) => ({
  font14: {
    fontSize: 14,
  },
  font14Bold: {
    fontSize: 14,
    fontWeight: 700,
  },
  marginTop20: {
    marginTop: 20,
  },
  marginTop10: {
    marginTop: 10,
  },
}));

export default function AddressDialog({
  openAddressDialog,
  setOpenAddressDialog,
  addresses,
}) {
  const classes = useStyles();
  return (
    <Dialog fullWidth open={openAddressDialog} maxWidth={"sm"}>
      <DialogTitle>
        <Typography variant="subtitle1" className={Styles.navyBlueColorForce}>
          Address
        </Typography>
      </DialogTitle>
      <DialogContent
        className={Styles.navyBlueColorForce}
        style={{ minHeight: 350 }}
      >
        {addresses && addresses.length
          ? addresses.map((address, index) =>
              address.primaryAddress ||
              !address.billing ||
              addresses.length === 1 ? (
                <Grid item container xs={12} key={index} direction="column">
                  <Divider className={classes.marginTop10} />
                  <Grid item container xs={12} className={classes.marginTop20}>
                    <Grid item xs={12} container alignItems="center">
                      <RoomIcon
                        style={{ width: 16, height: 16, marginRight: 10 }}
                      />
                      <Typography variant="subtitle1">
                        {address.addressNickname}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      container
                      direction="column"
                      style={{ marginLeft: 26 }}
                    >
                      <Typography variant="body1">
                        {address.streetNumber}, {address.streetName}
                      </Typography>
                      <Typography variant="body1">
                        {address.city && address.city.name},{" "}
                        {address.province && address.province.name},{" "}
                      </Typography>
                      <Typography variant="body1">
                        {address.county && address.county}, {address.postalCode}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null
            )
          : null}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          className={Styles.navyBlueColorForce}
          style={{ textTransform: "capitalize", cursor: "pointer" }}
          onClick={() => {
            setOpenAddressDialog(false);
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
