import { v4 as uuidv4 } from "uuid";
/**
 * Util module to manage blobs
 *
 * Useful to hold different blobs.
 *
 * orientation:
 * Blobs wont be stored in redux. At Redux we will only save blob urls.
 * This manager helps to hold blobs so that at the time of api interaction we can get blobs from
 * here and use for upload purpose.
 *
 * Mostly, for use cases where we have artefact uploads involved.
 * e.g. image cropper
 *
 * File are also blobs so those can use this module for upload purpose
 *
 * For any artefact
 * 1. if artefact is used in client app like images then we should construct blob url
 * 2. else, the blobs can be just held here for upload purpose
 */

/**
 * Construct to hold blobs
 */
const blobs = {};

/**
 * Adds blob entry and returns newly added entry
 * @param {*} blob The blob
 * @param {*} name The file name to be assigned
 * @param {*} type The content type
 * @returns
 */
const addFromBlob = (blob, name, type, extension) => {
  const newUUID = uuidv4();
  const blobURL = URL.createObjectURL(blob);
  const fileName = name + newUUID + "." + extension;
  const file = new File([blob], name + newUUID + "." + extension, { type });
  const entry = { url: blobURL, file, fileName };
  blobs[fileName] = entry;

  return entry;
};

/**
 * Get entry given file name
 * @param {*} fileName
 * @returns
 */
const getByName = (fileName) => blobs[fileName];

/**
 * Get blob url of an entry given filename
 * @param {*} fileName The file name
 * @returns blob url
 */
const getBlobURLByFileName = (fileName) => {
  return blobs[fileName] && blobs[fileName].url;
};
const getByID = () => {};

const clearBlob = () => {
  Object.getOwnPropertyNames(blobs).forEach((prop) => {
    delete blobs[prop];
  });
};

export default {
  addFromBlob,
  getByName,
  getBlobURLByFileName,
  getByID,
  clearBlob,
};
