import React, { useEffect } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import HBDashboard from "./HBDashboard";
import Documents from "./Documents";
import Explore from "./Explore/Explore";
import StorefrontPage from "./Explore/StorefrontPage/StorefrontPage";
import ReferralManagement from "../ReferralManagement/ReferralManagement";
import LeftNavBar from "./LeftNavBar";
import SPManagement from "./SPManagement";
import ViewWrapper from "../common/ViewWrapper";
import Messaging from "../Messaging/Messaging";
import SQCalender from "../common/SQCalender/SQCalender";
import UpcomingEeventsPendingActions from "../common/UpcomingEeventsPendingActions";
import Settings from "../Settings/Settings";
import { useRouteCheck } from "souqh-react-redux-hooks/common/useRouteCheck";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { isMobileDevice, isTabletDevice } from "../../utils/AppUtils";
import MobileStorefrontPage from "../../mobileComponents/HomeBuyer/StorfrontDetails/MobileStorefrontPage";
import FeedbackPage from "../FeedbackPage";
import SPDetails from "./SPDetails";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    backgroundColor: "#F5F5F8",
    minHeight: window.innerHeight,
  },
}));
const HomeBuyer = () => {
  let { path } = useRouteMatch();
  const classes = useStyles();
  const userInfo = useAuthUser();
  const { checkHBRoute } = useRouteCheck();

  useEffect(() => {
    checkHBRoute();
  }, [userInfo]);

  if (!userInfo) {
    return null;
  }
  return (
    <div className={classes.root}>
      <LeftNavBar></LeftNavBar>
      <main className={classes.content}>
        <div style={{ width: "calc(100%)" }}>
          <Grid
            item
            container
            xs={12}
            style={{
              alignItems: "flex-start",
              justifyContent: "center",
              maxWidth: window.screen.width,
            }}
          >
            <Switch>
              <Route exact path={`${path}/dashboard`}>
                <ViewWrapper>
                  <HBDashboard />
                </ViewWrapper>
              </Route>
              <Route exact path={`${path}`}>
                <ViewWrapper>
                  <HBDashboard />
                </ViewWrapper>
              </Route>
              <Route exact path={`${path}/calendar`}>
                <SQCalender />
              </Route>
              <Route exact path={`${path}/serviceproviders`}>
                <ViewWrapper showWhiteBackground={true}>
                  <SPManagement />
                </ViewWrapper>
              </Route>
              <Route
                exact
                path={`${path}/serviceproviders/details/:serviceProviderId`}
              >
                <ViewWrapper
                  showWhiteBackgroundForTabletAndMobile={
                    isTabletDevice() || isMobileDevice() ? true : false
                  }
                  spDetails={true}
                >
                  <SPDetails />
                </ViewWrapper>
              </Route>
              <Route exact path={`${path}/documents`}>
                <ViewWrapper
                  showWhiteBackgroundForTabletAndMobile={
                    isTabletDevice() || isMobileDevice() ? true : false
                  }
                >
                  <Documents />
                </ViewWrapper>
              </Route>
              <Route
                exact
                path={`${path}/explore/:appSearchedService?/:appSearchedCityId?`}
              >
                {!isMobileDevice() && !isTabletDevice() ? (
                  <ViewWrapper>
                    <Explore />
                  </ViewWrapper>
                ) : (
                  <Explore />
                )}
              </Route>
              <Route exact path={`${path}/storefrontview/:businessName`}>
                {isMobileDevice() ? (
                  <MobileStorefrontPage />
                ) : isTabletDevice() ? (
                  <StorefrontPage />
                ) : (
                  <ViewWrapper>
                    <StorefrontPage />
                  </ViewWrapper>
                )}
              </Route>
              <Route exact path={`${path}/referralmanagement`}>
                <ViewWrapper>
                  <ReferralManagement />
                </ViewWrapper>
              </Route>
              <Route exact path={`${path}/events`}>
                <ViewWrapper>
                  <UpcomingEeventsPendingActions />
                </ViewWrapper>
              </Route>
              <Route exact path={`${path}/messaging`}>
                <Messaging />
              </Route>
              <Route path={`${path}/settings`}>
                <Settings />
              </Route>
              <Route path={`${path}/feedback`}>
                <FeedbackPage />
              </Route>
            </Switch>
          </Grid>
        </div>
      </main>
    </div>
  );
};
export default HomeBuyer;
