import { cloneDeep } from "lodash";
import {
  SET_INVOICE_DATA,
  SET_SUBSCRIPTION_PLAN_DETAILS,
} from "./actionConstants";

const initialState = {
  invoiceData: null,
  planDetails: null,
};

const paymentAndInvoice = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_INVOICE_DATA:
      newState = cloneDeep(state);
      newState.invoiceData = action.payload.invoiceData;
      return newState;
    case SET_SUBSCRIPTION_PLAN_DETAILS:
      newState = cloneDeep(state);
      newState.planDetails = action.payload.planDetails;
      return newState;
    default:
      return state;
  }
};

export default paymentAndInvoice;
