import { SET_PAYMENT_HISTORY } from "./actionConstants";
import { cloneDeep } from "lodash";

const initialState = {
  payments: [],
};

const paymentHistory = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAYMENT_HISTORY:
      const newState = cloneDeep(state);
      newState.payments = action.payload;
      return newState;
    default:
      return state;
  }
};

export default paymentHistory;
