import {
  Avatar,
  Button,
  Grid,
  makeStyles,
  Radio,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import Styles from "../../../Styles.module.css";
import AddIcon from "@material-ui/icons/Add";
import clsx from "clsx";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";

const styles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined": {
      top: -8,
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px",
    },
  },
}));

export default function InviteLeftPanel({
  searchText,
  setSearchText,
  selectedContact,
  setSelectedContact,
  filteredContacts,
  filterContacts,
  contacts,
}) {
  const classes = styles();

  const handleChange = (contact) => {
    setSelectedContact(contact);
  };

  useEffect(() => {
    filterContacts();
  }, [searchText]);

  const allContacts = filteredContacts.map((item, index) => {
    return (
      <Grid
        item
        container
        xs={12}
        alignItems="center"
        style={{ padding: "4px 0px" }}
        key={index}
      >
        <Grid item xs={1}>
          <Radio
            checked={
              selectedContact
                ? selectedContact.primaryApplicantEmail ===
                  item.primaryApplicantEmail
                : false
            }
            onChange={(e) => {
              handleChange(item);
            }}
            value={item.primaryApplicantEmail}
            name="radio-button-demo"
            className={"sqBlueRadio"}
          />
        </Grid>
        <Grid item xs={11} style={{ padding: "0px 0px 0px 20px" }}>
          <Typography
            variant="body1"
            title={item.name}
            style={{ width: "95%" }}
            className={clsx(Styles.navyBlueColor, Styles.sqEllipsis)}
          >
            {item.name}
          </Typography>
        </Grid>
      </Grid>
    );
  });

  return (
    <Grid
      item
      container
      xs={12}
      style={{ height: "100%" }}
      className={classes.root}
    >
      <Grid
        item
        container
        xs={12}
        alignItems="center"
        style={{ marginTop: "12px" }}
      >
        <Typography variant="subtitle1" className={Styles.navyBlueColor}>
          Select Signer
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ margin: "8px 0px" }}>
        {contacts && contacts.length ? (
          <TextField
            fullWidth
            placeholder="Search for contact name or email address"
            variant="outlined"
            value={searchText}
            style={{ backgroundColor: "#ffffff" }}
            onInput={(e) => {
              setSearchText(e.target.value);
            }}
          />
        ) : null}
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          height: "calc(100% - 100px)",
          overflow: "auto",
          marginLeft: -12,
        }}
      >
        {allContacts && allContacts.length ? (
          allContacts
        ) : (
          <Typography
            variant="h6"
            style={{
              color: "0e1c4e",
              opacity: 0.5,
              fontStyle: "italic",
              fontWeight: 300,
              textAlign: "center",
              paddingTop: 40,
            }}
          >
            No signers available
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}
