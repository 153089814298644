import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useAddLabelDailog } from "souqh-react-redux-hooks/homeBuyer/spManagement/useAddLabelDailog";
import Styles from "../../../Styles.module.css";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 400,
  },
}));

export default function AddLabelDailog({
  openAddLabelDialog,
  setOpenAddLabelDialog,
  serviceRequestId,
  label,
}) {
  const classes = useStyles();

  const { labelText, setLabelText, addLabel } =
    useAddLabelDailog(serviceRequestId);

  useEffect(() => {
    if (label && openAddLabelDialog) {
      setLabelText(label);
    }
  }, [openAddLabelDialog]);
  return (
    <Dialog
      fullWidth
      disableBackdropClick
      open={openAddLabelDialog}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Add Label</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent
        className={Styles.navyBlueColor}
        // style={{ marginBottom: 80 }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1">
              Adding labels will help you identify and keep track of different
              service providers that you engage with. Use this like a tag.
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 16 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="For Example: xyz, abc"
              value={labelText}
              onChange={(e) => {
                setLabelText(e.target.value);
              }}
              inputProps={{
                maxLength: 20,
              }}
            ></TextField>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-end"
            style={{ marginTop: 6 }}
          >
            <Typography variant="body2" style={{ color: "#17174C99" }}>
              {labelText.length}/20
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions style={{ padding: "16px 25px" }}>
        <Button
          variant="outlined"
          style={{
            padding: "6px 16px",
            marginRight: 12,
            textTransform: "capitalize",
          }}
          className={Styles.navyBlueColorForce}
          onClick={() => {
            setOpenAddLabelDialog(false);
            setLabelText("");
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!labelText}
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize", padding: "6px 32px" }}
          onClick={() => {
            addLabel((res) => {
              if (res.data.status === 200) {
                setOpenAddLabelDialog(false);
                setLabelText("");
              }
            });
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
