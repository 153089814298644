import {
  Button,
  Grid,
  makeStyles,
  Typography,
  Paper,
  Divider,
} from "@material-ui/core";
import React from "react";
import Address from "./Address";
import { useOfficeAddress } from "souqh-react-redux-hooks/serviceProvider/useSetUp";
import AddIcon from "@material-ui/icons/Add";
import Styles from "../../../Styles.module.css";

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: 20,
  },
}));

function OfficeAddress() {
  const {
    addresses,
    provinces,
    cities,
    maxAdditionalAddresses,
    allowAction,
    actions: { saveAddressField, setPrimary, deleteAddress, addAddress },
  } = useOfficeAddress();
  const classes = useStyles();

  return (
    <Grid container item id="office_address">
      <Typography
        variant="h4"
        className={Styles.navyBlueColor}
        style={{ marginTop: 48, marginBottom: 28 }}
      >
        Office Address
      </Typography>
      <Grid item>
        <Paper elevation={0}>
          {addresses.map((a, index) => {
            return (
              <div key={index}>
                {index === 0 && (
                  <Typography
                    variant="subtitle1"
                    style={{ padding: "8px 32px" }}
                    className={Styles.navyBlueColor}
                  >
                    Please enter your address details
                  </Typography>
                )}
                <Divider
                  orientation="horizontal"
                  variant="fullWidth"
                  style={{ width: "100%", marginTop: 5 }}
                />
                <Address
                  {...a}
                  index={index}
                  provinces={provinces}
                  cities={cities}
                  saveAddressField={saveAddressField}
                  setPrimary={setPrimary}
                  deleteAddress={deleteAddress}
                ></Address>
                {index === 0 && (
                  <Typography
                    variant="body2"
                    style={{ color: "#0E1C4E", marginTop: -12 }}
                  >
                    *Minimum 1 address required
                  </Typography>
                )}
              </div>
            );
          })}
          <Divider />
          <Grid
            container
            item
            justifyContent="flex-end"
            style={{
              marginTop: 10,
              paddingBottom: 20,
              marginBottom: 20,
              paddingRight: 32,
            }}
          >
            <Button
              disabled={
                addresses.length >= 1 + maxAdditionalAddresses || !allowAction
              }
              variant="outlined"
              style={{ color: "#0E1C4E", textTransform: "capitalize" }}
              startIcon={<AddIcon />}
              onClick={() => {
                addAddress();
              }}
            >
              Add New Address
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default OfficeAddress;
