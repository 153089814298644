import { createTheme } from "@material-ui/core/styles";
import { localeMap } from "souqh-react-redux-hooks/locale";

export const getSouqhtheme = async (souqhLocale, inLocale = "en-US") => {
  inLocale = inLocale.replaceAll("-", "");

  const localeSymbol = localeMap[inLocale];

  //load material locale
  const materialLocale = await import("@material-ui/core/locale");
  const targetML = materialLocale[localeSymbol] || {};
  targetML.locale = souqhLocale;

  const SouqhTheme = createTheme(
    {
      typography: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        h1: {
          fontWeight: 700,
          fontSize: "4.5rem", // 72px
          letterSpacing: "0px",
        },
        h2: {
          fontWeight: 700,
          fontSize: "3rem", // 48px
          letterSpacing: "0px",
        },
        h3: {
          fontWeight: 700,
          fontSize: "2.25rem", // 36px
          letterSpacing: "0px",
        },
        h4: {
          fontWeight: 700,
          fontSize: "2rem", // 32px
          letterSpacing: "0px",
        },
        h5: {
          fontWeight: 700,
          fontSize: "1.75rem", // 28px
          letterSpacing: "0px",
        },
        h6: {
          fontWeight: 700,
          fontSize: "1.5rem", // 24px
          letterSpacing: "0px",
        },
        subtitle1: {
          fontWeight: 700,
          fontSize: "1rem",
          letterSpacing: "0px",
        },
        subtitle2: {
          fontWeight: 700,
          fontSize: "0.75rem", // 12px
          letterSpacing: "0px",
        },
        body1: {
          fontSize: "1rem",
          letterSpacing: "0px",
        },
        body2: {
          fontSize: "0.75rem", // 12px
          letterSpacing: "0px",
        },
      },
      palette: {
        // primary: {
        //   light: "",
        //   main: "#17174c",
        //   dark: "#FF8D8E",
        // },
        text: {
          secondary: "#17174C",
        },
      },
      overrides: {
        // MuiButton: {
        //   root: {
        //     textTransform: "capitalize",
        //     border: "1px solid #17174C1F",
        //   },
        //   text: {
        //     padding: "6px 16px",
        //   },
        //   label: {
        //     color: "#17174C",
        //   },
        //   disabled: {
        //     color: "#17174C77",
        //     opacity: 0.5,
        //   },
        // },
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 768,
          lg: 1280,
          xl: 1680,
        },
      },
    },
    targetML
  );
  return SouqhTheme;
};
