import {
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  makeStyles,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Checkbox,
  TableCell,
  TableBody,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { usePreProfileData } from "souqh-react-redux-hooks/serviceProvider/useSetUp";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import EditIcon from "@material-ui/icons/Edit";
import StoreIcon from "@material-ui/icons/Store";
import StarsIcon from "@material-ui/icons/Stars";
import OfficeAddressReview from "./OfficeAddressReview";
import LocationServedReview from "./LocationServedReview";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import Styles from "../../../Styles.module.css";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    color: "#0e1c4e",
  },
  paperPadding: {
    padding: "0px 16px",
  },
  headerPadding: {
    padding: "16px 16px 0px 16px",
  },
  marginTop: {
    marginTop: 20,
  },
  marginTop10: {
    marginTop: 10,
  },
  marginRight: {
    marginRight: 10,
  },
  margins: {
    marginRight: 10,
    marginTop: 10,
  },
  italicFont: {
    fontStyle: "italic",
  },
  textColor: {
    color: "#0e1c4e",
  },
  cursorPointer: {
    cursor: "pointer",
  },
}));

export default function ProfileReview() {
  const classes = useStyles();
  let history = useHistory();

  const {
    getProfileData,
    areaOfSpecializations,
    businessTypeSubCategories,
    languageSupport,
    addresses,
    team,
    preferredSPs,
  } = usePreProfileData();
  const user = useAuthUser();
  const [currentTeamMembers, setCurrentTeamMembers] = useState([]);
  const [billingAdd, setBillingAdd] = useState([]);

  useEffect(() => {
    if (team.additionalUsers.length) {
      setCurrentTeamMembers(team.additionalUsers);
    }
  }, [team]);

  useEffect(() => {
    if (addresses.length) {
      const billingAdd = addresses.filter((address) => address.billing);
      setBillingAdd(billingAdd);
    }
  }, [addresses]);

  useEffect(() => {
    getProfileData(() => {});
  }, []);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="space-between"
          className={classes.headerPadding}
        >
          <Grid item>
            <Typography variant="h4">Profile Setup</Typography>
          </Grid>
          <Grid item>
            <Grid container className={classes.cursorPointer}>
              <Button
                style={{ color: "#F86C6B", textTransform: "capitalize" }}
                onClick={() => {
                  history.push("/setup/profile");
                }}
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 20, marginBottom: 16 }} />
      </Grid>
      <Grid
        item
        xs={12}
        className={clsx(classes.marginTop, classes.paperPadding)}
      >
        <Typography variant="h6">Tell us about your business</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        className={clsx(classes.marginTop, classes.paperPadding)}
      >
        <Typography variant="subtitle1">Business Name</Typography>
        <Typography variant="body1">{user.businessName}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        className={clsx(classes.marginTop, classes.paperPadding)}
      >
        <Typography variant="subtitle1">Business Type</Typography>
        <Typography variant="body1">{user.businessTypes[0].name} : </Typography>
        {user.businessTypes[0].name === "Other" ? (
          <Typography variant="body1">
            {user.businessTypes[0].otherBusinessName}
          </Typography>
        ) : businessTypeSubCategories.length ? (
          businessTypeSubCategories.map((subType) => (
            <Chip
              key={subType.id}
              label={subType.name}
              variant="outlined"
              className={clsx(classes.margins)}
            />
          ))
        ) : (
          <Typography>No data available</Typography>
        )}
      </Grid>

      {/* Areas of specialisation */}
      <Grid
        item
        xs={12}
        className={clsx(classes.marginTop, classes.paperPadding)}
      >
        <Typography variant="subtitle1">Areas of specialization</Typography>
        {areaOfSpecializations.length ? (
          <Grid item>
            {areaOfSpecializations.map((areaOfSpec) => (
              <Chip
                key={areaOfSpec.id}
                label={areaOfSpec.name}
                variant="outlined"
                className={classes.marginRight}
              />
            ))}
          </Grid>
        ) : (
          <Typography>No data available</Typography>
        )}
      </Grid>

      {/* Languages you speak */}
      {languageSupport.length && (
        <Grid
          item
          xs={12}
          className={clsx(classes.marginTop, classes.paperPadding)}
        >
          <Typography variant="subtitle1">Languages you speak</Typography>
          <Grid>
            {languageSupport.map((lang) => (
              <Chip
                key={lang.id}
                label={lang.name}
                variant="outlined"
                className={classes.marginRight}
              />
            ))}
          </Grid>
        </Grid>
      )}
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        style={{ width: "100%", marginTop: 16 }}
      />
      {/* Office Address */}
      <OfficeAddressReview></OfficeAddressReview>
      {/* Billing Address */}
      {billingAdd.length ? (
        <Grid
          item
          xs={12}
          className={clsx(classes.marginTop10, classes.paperPadding)}
        >
          <Typography variant="subtitle1">Billing Address</Typography>
          <Grid container>
            <Grid item md={4}>
              <Grid item md={11}>
                <Card variant="outlined" className={classes.textColor}>
                  <CardContent>
                    <Grid container>
                      {billingAdd[0].isPrimary && (
                        <StarsIcon className={classes.marginRight} />
                      )}
                      <Typography variant="subtitle1">
                        {billingAdd[0].title}
                      </Typography>
                    </Grid>
                    <Typography variant="body1">
                      {billingAdd[0].streetNumber}, {billingAdd[0].streetName}
                    </Typography>
                    <Typography variant="body1">
                      {billingAdd[0].city.name}, {billingAdd[0].province.name},{" "}
                      {billingAdd[0].county}, {billingAdd[0].postalCode}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      {/* Locations Served */}
      <LocationServedReview></LocationServedReview>
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        style={{ width: "100%", marginTop: 16 }}
      />
      {/* Your Team */}
      <Grid
        item
        xs={12}
        className={clsx(classes.marginTop, classes.paperPadding)}
      >
        <Typography variant="h6">Your Team</Typography>
      </Grid>
      {Object.keys(user).length > 0 ? (
        <Grid
          item
          xs={12}
          className={clsx(classes.marginTop, classes.paperPadding)}
        >
          <Typography variant="subtitle1">Primary Contact</Typography>
          <Grid container spacing={1}>
            <Grid item md={5}>
              <Grid item md={12}>
                <Card variant="outlined" className={classes.textColor}>
                  <CardContent>
                    <Typography variant="subtitle1">
                      {user.firstName || ""} {user.lastName || ""}
                    </Typography>
                    <Typography variant="body1" style={{ fontStyle: "italic" }}>
                      {user.email || ""}
                    </Typography>
                    {user.contactNumber ? (
                      <Typography
                        variant="body1"
                        className={Styles.navyBlueColor}
                      >
                        {`(${user.contactNumber.substring(
                          0,
                          3
                        )})-${user.contactNumber.substring(
                          3,
                          6
                        )}-${user.contactNumber.substring(
                          6,
                          user.contactNumber.length
                        )}`}
                      </Typography>
                    ) : null}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      {/* Licensed Practitioner Section */}
      <Grid
        item
        xs={12}
        className={classes.marginTop}
        style={{ paddingLeft: 22 }}
      >
        <Typography variant="subtitle1">Licensed Practitioner</Typography>
      </Grid>
      {team.licensedPractitioner ? (
        <Grid container style={{ paddingLeft: 22 }}>
          <Grid item md={4}>
            <Grid item md={11}>
              <Card variant="outlined" className={classes.textColor}>
                <CardContent>
                  <Typography variant="body1">{team.licenseName}</Typography>
                  <Typography variant="body1">{team.licenseNumber}</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Typography style={{ paddingLeft: 22 }}>No data available</Typography>
      )}

      {/* Current Team  Section */}
      <Grid
        item
        xs={12}
        className={clsx(classes.marginTop, classes.paperPadding)}
      >
        <Typography variant="subtitle1">Current Team</Typography>
        <Grid container style={{ padding: 10 }}>
          <TableContainer>
            {currentTeamMembers.length ? (
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Full Name</TableCell>
                    <TableCell>Email Address</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentTeamMembers.map((a, index) => (
                    <TableRow key={index}>
                      <TableCell>{a.title}</TableCell>
                      <TableCell>{a.fullName}</TableCell>
                      <TableCell>{a.email}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Typography>No data available</Typography>
            )}
          </TableContainer>
        </Grid>
      </Grid>
      {/* preferred SP  Section */}
      <Grid
        item
        xs={12}
        className={clsx(classes.marginTop, classes.paperPadding)}
      >
        <Typography variant="subtitle1">Preferred Service Providers</Typography>
        <Grid container style={{ padding: 10 }}>
          <TableContainer>
            {preferredSPs.length ? (
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Full Name</TableCell>
                    <TableCell>Email Address</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {preferredSPs.map((preferredSP, index) => (
                    <TableRow key={index}>
                      <TableCell>{preferredSP.businessTypeName}</TableCell>
                      <TableCell>{preferredSP.spFullName}</TableCell>
                      <TableCell>{preferredSP.spEmail}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Typography>No data available</Typography>
            )}
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
}
