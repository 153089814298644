import { useState, useCallback, useEffect } from "react";
import { emailValidationMeta } from "../validationMetas";
import { useValidations } from "../useValidations";
import { useActions } from "../useActions";
import {
  SAVE_NEW_SP,
  ADD_NEW_SP,
  CLEAR_NEW_SP_DATA,
} from "../../souqh-redux/reducers/actionConstants";
import { useSelector } from "react-redux";

const validationMeta = [emailValidationMeta];

const actionCreators = {
  saveNewSP: (payload) => ({
    type: SAVE_NEW_SP,
    payload,
  }),
  addNewSP: (payload) => ({
    type: ADD_NEW_SP,
    payload,
  }),
  clearData: (payload) => ({
    type: CLEAR_NEW_SP_DATA,
    payload,
  }),
};

export const useAddNewSP = () => {
  const actions = useActions(actionCreators);
  const { email } = useSelector((state) => state.referralManagement.newSP);
  const { newSP } = useSelector((state) => state.referralManagement);
  const [allowAction, setAllowAction] = useState(false);

  const getFormValues = useCallback(() => {
    return { email };
  }, [email]);

  const getServerKeysMap = useCallback(() => {
    return {
      email: "email",
    };
  }, []);

  const { validations, addValidations, clearAllValidations } = useValidations(
    validationMeta,
    getFormValues,
    getServerKeysMap
  );

  useEffect(() => {
    let validation =
      !!newSP.email &&
      !!newSP.fullName &&
      !!newSP.serviceProviderType &&
      !Object.keys(validations).length;
    setAllowAction(validation);
  }, [newSP, validations]);

  return {
    validations,
    newSP,
    allowAction,
    actions,
  };
};
