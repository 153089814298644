import {
  Button,
  Checkbox,
  Chip,
  Grid,
  Paper,
  TextField,
  Typography,
  makeStyles,
  Divider,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState, useEffect } from "react";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useLocationServed } from "souqh-react-redux-hooks/useLocationServed";
import AddIcon from "@material-ui/icons/Add";
import Styles from "../../../Styles.module.css";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: 8,
    marginTop: 20,
    width: "100%",
  },
}));

function LocationServed({ page = "" }) {
  const classes = useStyles();
  const {
    provinces,
    selectedCities,
    locationsServed,
    displayCities,
    setSelectedProvince,
    setSelectedCities,
    setDisplayCities,
    addLocation,
    selectedProvince,
    actions: { deleteCity },
  } = useLocationServed();

  const [inputValue, setInputValue] = useState("");
  const [inputValueCities, setInputValueCities] = useState("");

  useEffect(() => {
    if (selectedCities.length) {
      addLocation();
    }
  }, [selectedCities]);

  return (
    <React.Fragment>
      <Paper className={classes.paper} elevation={0}>
        <Grid container justifyContent="space-between">
          <Grid item container xs={12}>
            <Typography
              variant="subtitle1"
              className={Styles.navyBlueColor}
              style={{
                paddingLeft: page !== "marketingCampaign" && 32,
                paddingTop: 10,
              }}
            >
              {page === "marketingCampaign"
                ? "Region*"
                : "Please select the key locations you serve"}
            </Typography>
          </Grid>
          <Divider
            orientation="horizontal"
            variant="fullWidth"
            style={{
              width: "100%",
              marginTop: 5,
              marginBottom: 10,
            }}
          />
          <Grid
            item
            container
            style={{
              padding:
                page === "marketingCampaign"
                  ? "8px 0 10px 0"
                  : "8px 26px 10px 23px",
            }}
            xs={12}
            spacing={page === "marketingCampaign" && 1}
            justifyContent={page === "marketingCampaign" && "space-around"}
          >
            <Grid item xs={6}>
              <Grid item xs={page === "marketingCampaign" ? 12 : 11}>
                {provinces.length ? (
                  <Autocomplete
                    id="provinces"
                    options={provinces || []}
                    value={selectedProvince}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Province"
                        variant="outlined"
                      />
                    )}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    onChange={(e, v) => {
                      setSelectedProvince(v);
                    }}
                  />
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={page === "marketingCampaign" ? 12 : 11}>
                <Autocomplete
                  multiple
                  required
                  id="tags-standard"
                  options={displayCities || []}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => {
                    return option.name === value.name;
                  }}
                  limitTags={3}
                  value={selectedCities}
                  inputValue={inputValueCities}
                  onInputChange={(event, newInputValue) => {
                    setInputValueCities(newInputValue);
                  }}
                  onChange={(e, v) => {
                    setSelectedCities(v);
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color={Styles.navyBlueColorForce}
                      />
                      <span>{option.name}</span>
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Cities"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Divider
              orientation="horizontal"
              variant="fullWidth"
              style={{
                width: "100%",
                marginTop: 36,
              }}
            />
            <Grid
              item
              container
              justifyContent="flex-end"
              style={{ margin: "16px 0px 12px 0px", paddingRight: 17 }}
            >
              <Button
                disabled={!selectedCities.length}
                variant="outlined"
                style={{ color: "#0E1C4E" }}
                onClick={() => {
                  setInputValue("");
                  setInputValueCities("");
                  // Reset autocomplete for provinces and cities after add.
                  setSelectedCities([]);
                  setDisplayCities([]);
                  setSelectedProvince(null);
                }}
                startIcon={<AddIcon />}
              >
                Add another province
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      {locationsServed.length ? (
        <Paper style={{ padding: "8px 0px 12px 8px" }} elevation={0}>
          {locationsServed.map((location, index) => (
            <Grid
              key={index}
              container
              style={{ padding: "10px 26px 10px 23px" }}
            >
              <Grid item container>
                <Typography variant="body1" className={Styles.navyBlueColor}>
                  {location.province.name}
                </Typography>
              </Grid>
              <Grid item container style={{ marginLeft: -4 }}>
                {location.cities.map((city) => (
                  <Chip
                    key={city.id}
                    label={city.name}
                    onDelete={() => deleteCity({ city, index })}
                    style={{ marginLeft: 4, marginTop: 10 }}
                    variant="outlined"
                    className={Styles.navyBlueColorForce}
                  />
                ))}
              </Grid>
            </Grid>
          ))}
        </Paper>
      ) : null}
    </React.Fragment>
  );
}

export default LocationServed;
