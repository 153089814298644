import { useActions } from "../useActions";
import {
  SAVE_RECOMMEND_SP_DATA,
  DELETE_NEW_SP,
  SET_SERVICE_PROVIDER_LIST,
} from "../../souqh-redux/reducers/actionConstants";
import { useSelector, useDispatch } from "react-redux";
import { useApiClient } from "../useApiClient";

const recommendSPActions = {
  saveRecommendSPData: (payload) => ({
    type: SAVE_RECOMMEND_SP_DATA,
    payload,
  }),
  deleteNewSP: (index) => ({
    type: DELETE_NEW_SP,
    index,
  }),
};

export const useRecommendSP = () => {
  const { apiClient } = useApiClient();

  const actions = useActions(recommendSPActions);
  const dispatch = useDispatch();

  const { recommendSP } = useSelector((state) => state.referralManagement);
  const { serviceProviderList } = useSelector(
    (state) => state.referralManagement
  );

  //Get Sp list
  const getSPList = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/service-provider/recommend`)
      .then(function (response) {
        if (response.data.result.length) {
          dispatch({
            type: SET_SERVICE_PROVIDER_LIST,
            serviceProviderList: response.data.result,
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    recommendSP,
    serviceProviderList,
    actions,
    getSPList,
  };
};
