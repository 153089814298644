import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import clsx from "clsx";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";
import CallIcon from "@material-ui/icons/Call";
import CircleIcon from '@mui/icons-material/Circle';
import { ListItemButton } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 800,
    minWidth: !isTabletDevice() && !isMobileDevice() && 800,
  },
}));

function MovingChecklistAddressPopup({
  openMovingChecklistAddressPopup,
  setOpenMovingChecklistAddressPopup,
}) {
  const classes = useStyles();

  const [movingFromCityWebsite, setMovingFromCityWebsite] = useState(null)
  const [movingToCityWebsite, setMovingToCityWebsite] = useState(null)
  const [movingToCityTVWebsite, setMovingToCityTVWebsite] = useState(null)

  const timelineOptions = [
    { name: "Urgent", value: "URGENT" },
    { name: "As Soon As Possible", value: "AS_SOON_AS_POSSIBLE" },
    { name: "1-2 weeks", value: "ONE_TO_TWO_WEEKS" },
    { name: "2-4 weeks", value: "TWO_TO_FOUR_WEEKS" },
    { name: "4-8 weeks", value: "FOUR_TO_EIGHT_WEEKS" },
    { name: "8 weeks plus", value: "EIGHT_WEEKS_PLUS" },
  ];

  return (
    <Dialog
      fullWidth
      open={openMovingChecklistAddressPopup && openMovingChecklistAddressPopup.open}
      onClose={() => {
        setOpenMovingChecklistAddressPopup({ open: false, data: {} });
      }}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Grid container item>
          <Grid item xs={10}>
            <Typography variant="subtitle1">{openMovingChecklistAddressPopup.data?.[0]?.title}</Typography>
          </Grid>
          <Grid container item xs={2} justifyContent="flex-end">
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpenMovingChecklistAddressPopup({ open: false, data: {} });
                setMovingFromCityWebsite(null);
                setMovingToCityWebsite(null);
                setMovingToCityTVWebsite(null);
              }}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ marginTop: 8 }}>
        {openMovingChecklistAddressPopup.data && openMovingChecklistAddressPopup.data.length && openMovingChecklistAddressPopup.data.map((item, index) => (<Grid>
          {item.subTitle && <Grid item xs={10}>
            <Typography variant="subtitle1" className={Styles.navyBlueColor}
              style={{ marginBottom: 36 }}>{item.subTitle}</Typography>
          </Grid>}
          {item.subTitle !== "TV" && (<Grid container>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              className={Styles.navyBlueColor}
              style={{ marginBottom: 36 }}
              >Moving from {item.movingFromAddress}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              className={Styles.navyBlueColor}
            // style={{ marginBottom: 36 }}
              >To close your account, contact your {item.subTitle === "TV" ? "TV" : item.subTitle === "Internet" && "internet"} service provider
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
                options={item.currentCiyDropdownValues || []}
              value={movingFromCityWebsite}
                getOptionLabel={(option) => option.company || ""}
              id="timeline"
              debug
              renderInput={(params) => (
                // <Tooltip title="How soon do you want the service to be completed?">
                <TextField
                  {...params}
                  margin="normal"
                  className={clsx(classes.inputs, classes.textInput)}
                  placeholder={item.textFieldPlaceholder}
                  variant="outlined"
                />
                // </Tooltip>
              )}
              onChange={(event, value) => {
                setMovingFromCityWebsite(value);
              }}
              getOptionSelected={(option, value) => {
                return option === value;
              }}
              style={{ width: !isMobileDevice() && "410px" }}
              className={classes.marginTop20}
            />
          </Grid>
          {movingFromCityWebsite && (<Grid item xs={12} style={{ marginTop: 16 }}>
            <Link
              underline="always"
              variant="body1"
              className={Styles.primaryTxtAccentForce}
              style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(movingFromCityWebsite.link, "_blank")
                }}

            >Website
            </Link>
              {movingFromCityWebsite && movingFromCityWebsite.phoneNumber && <CallIcon
              style={{
                fill: Styles.navyBlueColor,
                cursor: "pointer",
                marginBottom: -8,
                marginLeft: 16
              }}
              />}
              {movingFromCityWebsite && movingFromCityWebsite.phoneNumber && <Link
              underline="always"
              variant="body1"
              className={Styles.navyBlueColorForce}
              style={{ cursor: "pointer" }}
                onClick={() => {
                  if (isMobileDevice()) {
                    window.open(
                      `tel:${movingFromCityWebsite.phoneNumber}`,
                      "_self"
                    );
                  }
                }}

              >{movingFromCityWebsite?.phoneNumber}
              </Link>}
          </Grid>)}
          </Grid>)}
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              className={Styles.navyBlueColor}
                style={{ marginBottom: 36, marginTop: item.subTitle !== "TV" && 36 }}
              >Moving to {item.movingToAddress}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              className={Styles.navyBlueColor}
            // style={{ marginBottom: 36 }}
              >To open your account, contact your {item.subTitle === "TV" ? "TV" : item.subTitle === "Internet" && "internet"} service provider
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
                options={item.newCiyDropdownValues || []}
                value={item.subTitle !== "TV" ? movingToCityWebsite : movingToCityTVWebsite}
                getOptionLabel={(option) => option.company || ""}
              id="timeline"
              debug
              renderInput={(params) => (
                // <Tooltip title="How soon do you want the service to be completed?">
                <TextField
                  {...params}
                  margin="normal"
                  className={clsx(classes.inputs, classes.textInput)}
                  placeholder={item.textFieldPlaceholder}
                  variant="outlined"
                />
                // </Tooltip>
              )}
              onChange={(event, value) => {
                item.subTitle !== "TV" ? setMovingToCityWebsite(value) : setMovingToCityTVWebsite(value)
              }}
              getOptionSelected={(option, value) => {
                return option === value;
              }}
              style={{ width: !isMobileDevice() && "410px" }}
              className={classes.marginTop20}
            />
          </Grid>
            {((movingToCityWebsite && item.subTitle !== "TV") || (movingToCityTVWebsite && item.subTitle === "TV")) && (<Grid item xs={12} style={{ marginTop: 16 }}>
            <Link
              underline="always"
              variant="body1"
              className={Styles.primaryTxtAccentForce}
              style={{ cursor: "pointer" }}
                onClick={() => {
                  item.subTitle !== "TV" ? window.open(movingToCityWebsite.link, "_blank") : window.open(movingToCityTVWebsite.link, "_blank")
                }}

            >Website
            </Link>
              {movingToCityWebsite && movingToCityWebsite.phoneNumber && <CallIcon
              style={{
                fill: Styles.navyBlueColor,
                cursor: "pointer",
                marginBottom: -8,
                marginLeft: 16
              }}
              />}
              {((movingToCityWebsite && movingToCityWebsite.phoneNumber) || (movingToCityTVWebsite && movingToCityTVWebsite.phoneNumber)) && <Link
              underline="always"
              variant="body1"
              className={Styles.navyBlueColorForce}
              style={{ cursor: "pointer" }}
                onClick={() => {
                  if (isMobileDevice()) {
                    if (movingToCityWebsite) {
                      window.open(
                        `tel:${movingToCityWebsite.phoneNumber}`,
                        "_self"
                      );
                    } else if (movingToCityTVWebsite) {
                      window.open(
                        `tel:${movingToCityTVWebsite.phoneNumber}`,
                        "_self"
                      );
                    }
                  }
                }}

              >{movingToCityWebsite ? movingToCityWebsite.phoneNumber : movingToCityTVWebsite && movingToCityTVWebsite.phoneNumber}
              </Link>}
          </Grid>)}
        </Grid>

          {openMovingChecklistAddressPopup.data.length > 1 && index !== openMovingChecklistAddressPopup.data.length - 1 && (<Divider style={{ marginTop: 20, marginBottom: 20 }} />)}

        {/* <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="nested-list-subheader"

        >
          <ListItemButton>
            <ListItemIcon>
              <CircleIcon />
            </ListItemIcon>
            <ListItemText primary="Sent mail" />
          </ListItemButton>


          <ListItemButton>
            <ListItemIcon>
              <CircleIcon />
            </ListItemIcon>
            <ListItemText primary="Drafts" />
          </ListItemButton>

          <ListItemButton>
            <ListItemIcon>
              <CircleIcon />
            </ListItemIcon>
            <ListItemText primary="Inbox" />

          </ListItemButton>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>
              <ListItemText primary="Starred" />
            </ListItemButton>
          </List>


        </List> */}
        </Grid>))}
      </DialogContent>
    </Dialog>
  );
}

export default MovingChecklistAddressPopup;
