import { useState, useEffect } from "react";
import {
  SET_DOCUMENT_TAB,
  SET_DOCUMENT_TAB_NAME,
  SET_CATEGORIES,
  SET_DOCUMENTS_TABLE_DATA,
} from "../../souqh-redux/reducers/actionConstants";
import { useActions } from "../useActions";
import { useSelector, useDispatch } from "react-redux";
import { findIndex, cloneDeep } from "lodash";
import { useAuthUser } from "../useLogin";
import { useSQIntercom } from "../intercom/useSQIntercom";
import { useApiClient } from "../useApiClient";

const actionCreator = {
  setDocumentTab: (payload) => ({ type: SET_DOCUMENT_TAB, payload }),
  setDocumentTabName: (payload) => ({ type: SET_DOCUMENT_TAB_NAME, payload }),
  setDocumentsTableData: (documentsTableData) => ({
    type: SET_DOCUMENTS_TABLE_DATA,
    payload: { documentsTableData },
  }),
};

export const useDocuments = () => {
  const { apiClient } = useApiClient();

  const dispatch = useDispatch();
  const actions = useActions(actionCreator);
  const { updateIntercom, showIntercom, hideIntercom } = useSQIntercom();

  const getSharedWithTooltip = (sharedWithArray) => {
    const filteredSharedWithArray = sharedWithArray.slice(0, 1);
    const remainingArray = sharedWithArray.filter(
      (el) => !filteredSharedWithArray.includes(el)
    );
    return remainingArray.join();
  };

  const [openDeleteCategoryDialog, setOpenDeleteCategoryDialog] =
    useState(false);
  const [openAddCategoryDialog, setOpenAddCategoryDialog] = useState(false);
  const [openRenameCategoryDialog, setOpenRenameCategoryDialog] =
    useState(false);

  const [openShareDocumentDialog, setOpenShareDocumentDialog] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("docName");
  const documentsTableData = useSelector(
    (state) => state.documentsData.documentsTableData
  );
  const [selectedData, setSelectedData] = useState([]);
  const [selectedDataForDownload, setSelectedDataForDownload] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [actionRequiredCount, setActionRequiredCount] = useState(0);
  const [waitingForOtherCount, setWaitingForOtherCount] = useState(0);
  const [expiringSoonCount, setExpiringSoonCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [archivedCount, setArchivedCount] = useState(0);
  const [referenceDocumentsCount, setReferenceDocumentsCount] = useState(0);

  const categories = useSelector((state) => state.documents.categories);
  const userInfo = useAuthUser();

  useEffect(() => {
    actions.setDocumentsTableData(categories);
  }, [categories]);

  const [openDeleteDocumentDialog, setOpenDeleteDocumentDialog] = useState({
    open: false,
    docDetails: {},
    categoryId: "",
  });

  const [openArchiveDocumentDialog, setOpenArchiveDocumentDialog] = useState({
    open: false,
    docDetails: {},
  });

  const [openMoveDocumentDialog, setOpenMoveDocumentDialog] = useState({
    open: false,
    documentName: "",
    documentId: "",
    sourceCategoryId: "",
  });

  const [openRenameDocumentDialog, setOpenRenameDocumentDialog] = useState({
    open: false,
    documentId: "",
    categoryId: "",
    documentName: "",
  });
  const documentList = useSelector(
    (state) => state.uploadDocument.documentList
  );
  const documentTab = useSelector((state) => state.documents.documentTab);
  const documentTabName = useSelector(
    (state) => state.documents.documentTabName
  );

  const updateIntercomStatus = (flag) => {
    if (flag) {
      hideIntercom();
      updateIntercom({
        hideDefaultLauncher: true,
      });
    } else {
      // showIntercom();
      updateIntercom({
        hideDefaultLauncher: false,
      });
    }
  };
  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    setOpenMenu(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenMenu(false);
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const searchVal = (val) => {
    setSearchText(val);
    requestSearch(val);
  };

  const requestSearch = (searchedVal) => {
    setSearchText(searchedVal);
    let filteredRows = [];
    categories.map((category) => {
      category.documents.filter((row) => {
        if (
          row.docName.toLowerCase().includes(searchedVal.toLowerCase()) ||
          row.docStatus.toLowerCase().includes(searchedVal.toLowerCase()) ||
          row.sharedWith
            .toString()
            .toLowerCase()
            .includes(searchedVal.toLowerCase())
        ) {
          const index = findIndex(
            filteredRows,
            (filteredRow) => filteredRow.name === category.name
          );
          if (index >= 0) {
            filteredRows[index].documents.push(row);
          } else {
            filteredRows.push({
              id: category.id,
              isDefault: category.isDefault,
              name: category.name,
              documents: [row],
            });
          }
        }
      });
    });
    searchedVal
      ? actions.setDocumentsTableData(filteredRows)
      : actions.setDocumentsTableData(categories);
  };

  const handleCheckboxChange = (checked, docId) => {
    const tempList = cloneDeep(documentsTableData);
    tempList.forEach((item) => {
      item.documents.forEach((el) => {
        if (el.docId === docId) {
          el.selected = checked;
        }
      });
    });
    actions.setDocumentsTableData(tempList);
  };

  const deselectDocfromSelection = (docId) => {
    const tempList = cloneDeep(documentsTableData);
    let findItem = null;
    tempList.forEach((item) => {
      !findItem
        ? (findItem = item.documents.find((el) => el.docId === docId))
        : null;
    });
    if (findItem) {
      findItem.selected = false;
      actions.setDocumentsTableData(tempList);
    }
  };

  const extractSelectedData = (temp, dataForDownload) => {
    documentsTableData &&
      documentsTableData.length &&
      documentsTableData.forEach((item) => {
        item.documents.forEach((el) => {
          if (el.selected) {
            temp.push(el);
            dataForDownload.push({
              categoryId: item.id,
              categoryName: item.name,
              documentName: el.docName,
              fileName: el.fileName,
              isSignedDocument: !!el.signNowDocumentId,
            });
          }
        });
      });
  };

  const getTabWiseCategoriesList = (documentTabName) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/documents/user/${userInfo.userId}/${documentTabName}`)
      .then(function (response) {
        dispatch({
          type: SET_CATEGORIES,
          payload: { categories: response.data.result.documentList },
        });
        setActionRequiredCount(response.data.result.actionRequiredCount);
        setWaitingForOtherCount(response.data.result.waitingForOtherCount);
        setExpiringSoonCount(response.data.result.expiringSoonCount);
        setCompletedCount(response.data.result.completedCount);
        setArchivedCount(response.data.result.archivedCount);
        setReferenceDocumentsCount(
          response.data.result.referenceDocumentsCount
        );
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    documentList,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    handleRequestSort,
    actions,
    documentTab,
    openDeleteCategoryDialog,
    setOpenDeleteCategoryDialog,
    openAddCategoryDialog,
    setOpenAddCategoryDialog,
    openRenameCategoryDialog,
    setOpenRenameCategoryDialog,
    openMenu,
    handleToggle,
    handleClose,
    handleListKeyDown,
    getSharedWithTooltip,
    openDeleteDocumentDialog,
    setOpenDeleteDocumentDialog,
    openMoveDocumentDialog,
    setOpenMoveDocumentDialog,
    openArchiveDocumentDialog,
    setOpenArchiveDocumentDialog,
    openRenameDocumentDialog,
    setOpenRenameDocumentDialog,
    searchVal,
    documentsTableData,
    categories,
    openShareDocumentDialog,
    setOpenShareDocumentDialog,
    handleCheckboxChange,
    selectedData,
    setSelectedData,
    selectedDataForDownload,
    setSelectedDataForDownload,
    extractSelectedData,
    deselectDocfromSelection,
    updateIntercomStatus,
    documentTabName,
    getTabWiseCategoriesList,
    actionRequiredCount,
    waitingForOtherCount,
    expiringSoonCount,
    completedCount,
    archivedCount,
    referenceDocumentsCount,
    setSearchText,
    searchText,
    userInfo,
  };
};
