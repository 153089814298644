import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Styles from "../Styles.module.css";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 250,
  },
}));

function ConfirmationDialog({
  open,
  sectionTitle,
  handleNo,
  handleYes,
  title,
  handleNoTitle,
  handleYesTitle,
}) {
  const classes = useStyles();
  return (
    <Dialog
      fullWidth
      open={open}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">{title || "Delete"}</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>{sectionTitle}</DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleNo}
          className={Styles.navyBlueColorForce}
          style={{ textTransform: "capitalize" }}
        >
          {handleNoTitle || "No"}
        </Button>
        <Button
          variant="contained"
          onClick={handleYes}
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize" }}
        >
          {handleYesTitle || "Yes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
