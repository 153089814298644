import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Link,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import CloseIcon from "@material-ui/icons/Close";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 800,
    minWidth: !isTabletDevice() && !isMobileDevice() && 800,
  },
}));

function MovingChecklistPopup({
  openMovingChecklistPopup,
  setOpenMovingChecklistPopup,
}) {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      open={openMovingChecklistPopup && openMovingChecklistPopup.open}
      onClose={() => {
        setOpenMovingChecklistPopup({ open: false, data: {} });
      }}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Grid container item>
          <Grid item xs={10}>
            <Typography variant="subtitle1">{openMovingChecklistPopup.data.title}</Typography>
          </Grid>
          <Grid container item xs={2} justifyContent="flex-end">
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpenMovingChecklistPopup({ open: false, data: {} });
              }}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ marginTop: 8 }}>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            className={Styles.navyBlueColor}
            style={{ marginBottom: 36 }}
          >{openMovingChecklistPopup.data.subHeader}
          </Typography>
        </Grid>
        {openMovingChecklistPopup.data.subCategories && openMovingChecklistPopup.data.subCategories.length && openMovingChecklistPopup.data.subCategories.map((category) => (
          <Grid item xs={12} style={{ marginBottom: 16 }}>
            <Link
              underline="always"
              variant="body1"
              className={Styles.primaryTxtAccentForce}
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(category.link, "_blank")
              }}

            >{category.eventDescription}
            </Link>
          </Grid>
        )
        )}
      </DialogContent>
    </Dialog>
  );
}

export default MovingChecklistPopup;
