import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Divider,
  makeStyles,
  Typography,
  Switch,
  SnackbarContent,
  Snackbar,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  useCoApplicant,
  useInviteClientsDialog,
  usePrimaryApplicant,
} from "souqh-react-redux-hooks/common/useInviteClientsDialog";
import MaskedMobileInput from "../MaskedMobileInput";
import RecommendedSP from "./RecommendedSP";
import { useRecommendSP } from "souqh-react-redux-hooks/common/useRecommendSP";
import InviteCheck from "../InviteCheck";
import ConfirmationDailog from "./ConfirmationDailog";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useStorefrontReview } from "souqh-react-redux-hooks/review/useStorefrontReview";
import { isMobileDevice, isTabletDevice } from "../../utils/AppUtils";
import {
  BUSINESS_TYPE_OTHER,
  SERVICE_PROVIDER_USER,
  TEAM_MEMBER_USER,
} from "../ServiceProvider/serviceProviderConstants";
import MuiAlert from "@material-ui/lab/Alert";
import { useRecommmendSpToClient } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useRecommendSPToClient";
import CurrentAndNewAddress from "./CureentAndNewAddress";
import { useCurrentAndNewAddress } from "souqh-react-redux-hooks/serviceProvider/useSetUp";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 574,
    minWidth: isTabletDevice() ? 700 : !isMobileDevice() && 1140,
  },
  grids: {
    marginBottom: 16,
  },
  marginTop20: {
    marginTop: 20,
  },
  semiBold16: {
    fontSize: "1rem",
    fontWeight: 600,
  },
}));

function InviteClientsDialog({
  openInviteClientsDialog,
  setOpenInviteClientsDialog,
  srcPage = "",
  getActiveClientData,
}) {
  const classes = useStyles();
  const {
    showCoApplicantdetails,
    showRecommendedSPDetails,
    addSPSucessMsg,
    setAddSPSucessMsg,
    sentInviteToClients,
    selectedService,
    setSelectedService,
    getDocumentChecklist,
    documentChecklist,
    selectedDoc,
    setSelectedDoc,
    getUserTemplatelist,
    templateList,
    selectedTemplate,
    setSelectedTemplate,
    otherDoc,
    setOtherDoc,
    userInfo,
    actions: {
      clearData,
      setShowCoApplicantdetails,
      setshowRecommendedSPDetails,
    },
  } = useInviteClientsDialog(srcPage);

  const {
    primaryApplicant,
    validations: primaryApplicantValidations,
    allowAction: primaryApplicantAllowAction,
    addValidations,
    actions: { savePrimaryApplicantData },
  } = usePrimaryApplicant();

  const {
    coApplicant,
    validations: coApplicantValidations,
    allowAction: coApplicantAllowAction,
    actions: { saveCoApplicantData },
  } = useCoApplicant();

  const {
    recommendSP,
    actions: { saveRecommendSPData, deleteNewSP },
    serviceProviderList,
    getSPList,
  } = useRecommendSP();
  const { addLeadsForRecommendedSPs } = useRecommmendSpToClient();

  const {
    currentAndNewAddress,
    allowAction : currentAndNewAddressAllowAction,
    provinces,
    cities,
    actions: { saveAddressField, resetCurrentAndNewAddress },
  } = useCurrentAndNewAddress();

  const [openConfirmationDailog, setOpenConfirmationDailog] = useState(false);
  const [error, setError] = useState({ open: false, message: null });
  const { getStorefrontDetails } = useStorefrontReview();

  useEffect(() => {
    if (srcPage === "clientManagement" || srcPage === "serviceProvider") {
      getStorefrontDetails();
    }
    getDocumentChecklist();
    getUserTemplatelist();
  }, []);
  const { services } = useSelector((state) => state.storefront);

  const cleanUpUI = () => {
    setOpenInviteClientsDialog(false);
    setShowCoApplicantdetails(false);
    setshowRecommendedSPDetails(false);
    setSelectedService(null);
    setSelectedDoc([]);
    setSelectedTemplate([]);
    clearData();
  };
  return (
    <>
      <ConfirmationDailog
        openConfirmationDailog={openConfirmationDailog}
        setOpenConfirmationDailog={setOpenConfirmationDailog}
        setOpenInviteClientsDialog={setOpenInviteClientsDialog}
        setShowCoApplicantdetails={setShowCoApplicantdetails}
        setshowRecommendedSPDetails={setshowRecommendedSPDetails}
        clearData={clearData}
        resetCurrentAndNewAddress={resetCurrentAndNewAddress}
        type="inviteClient"
        setSelectedService={setSelectedService}
        setSelectedDoc={setSelectedDoc}
        setSelectedTemplate={setSelectedTemplate}
      />
      <Dialog
        // fullWidth
        open={openInviteClientsDialog}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        {addSPSucessMsg && (
          <Grid container justifyContent="center">
            <SnackbarContent
              style={{ maxWidth: 200, position: "absolute", marginTop: 10 }}
              message={"Service Provider has been added successfully."}
            />
          </Grid>
        )}
        <DialogTitle className={Styles.navyBlueColor}>
          <Typography variant="subtitle1">Invite New Clients</Typography>
        </DialogTitle>
        <Divider
          orientation="horizontal"
          variant="fullWidth"
          style={{ width: "100%" }}
        />
        <DialogContent>
          <DialogContentText>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Grid item xs={12} md={11}>
                  <Grid item xs={12} className={classes.grids}>
                    <Typography className={classes.semiBold16}>
                      Primary Applicant Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.grids}>
                    <TextField
                      required
                      id="fullName"
                      placeholder="Full Name"
                      label="Full Name"
                      name="fullName"
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        maxLength: 256,
                      }}
                      onChange={(event) => {
                        savePrimaryApplicantData({
                          fullName: event.target.value,
                        });
                      }}
                      autoComplete="full-name"
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.grids}>
                    <TextField
                      required
                      error={!!primaryApplicantValidations.email}
                      helperText={
                        primaryApplicantValidations.email && (
                          <InviteCheck
                            validations={primaryApplicantValidations}
                          />
                        )
                      }
                      id="emailAddress"
                      label="Email Address"
                      placeholder="Email Address"
                      name="emailAddress"
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        savePrimaryApplicantData({ email: event.target.value });
                      }}
                      autoComplete="email-address"
                      value={primaryApplicant.email}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.grids}>
                    <MaskedMobileInput
                      value={primaryApplicant.phoneNumber}
                      error={!!primaryApplicantValidations.phoneNumber}
                      helperText={
                        primaryApplicantValidations.phoneNumber &&
                        primaryApplicantValidations.phoneNumber.join(" ")
                      }
                      id="phoneNumber"
                      label="Phone Number"
                      name="phoneNumber"
                      variant="outlined"
                      type="tel"
                      fullWidth
                      onChange={(e) => {
                        savePrimaryApplicantData({
                          phoneNumber: e.target.value.replaceAll("-", ""),
                        });
                      }}
                    ></MaskedMobileInput>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container item xs={12} className={classes.grids}>
                  <Grid item xs={12} className={classes.grids}>
                    <Typography className={classes.semiBold16}>
                      Service Details
                    </Typography>
                  </Grid>
                  {srcPage === "clientManagement" ||
                  srcPage === "serviceProvider" ? (
                    <Grid item xs={12} className={classes.grids}>
                      <Autocomplete
                        fullWidth
                        noOptionsText={"No options"}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label="Select Service"
                            variant="outlined"
                            autoComplete="new-password"
                          />
                        )}
                        value={selectedService || ""}
                        options={services || []}
                        getOptionLabel={(option) => option.name || ""}
                        getOptionSelected={(option, value) => {
                          return option.name === value.name;
                        }}
                        onChange={(event, value) => {
                          setSelectedService(value);
                        }}
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12} className={classes.grids}>
                    <Autocomplete
                      // hidden
                      fullWidth
                      multiple
                      noOptionsText={"No options"}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Request Documents"
                          variant="outlined"
                          autoComplete="new-password"
                        />
                      )}
                      value={selectedDoc || []}
                      options={documentChecklist || []}
                      getOptionLabel={(option) => option || ""}
                      getOptionSelected={(option, value) => {
                        return option === value;
                      }}
                      onChange={(event, value) => {
                        setSelectedDoc(value);
                      }}
                    />
                  </Grid>

                  {selectedDoc.includes(BUSINESS_TYPE_OTHER) && (
                    <Grid item xs={12} className={classes.grids}>
                      <TextField
                        id="otherDoc"
                        placeholder="Document Name"
                        label="Document Name"
                        name="otherDoc"
                        variant="outlined"
                        fullWidth
                        inputProps={{
                          maxLength: 256,
                        }}
                        onChange={(event) => {
                          setOtherDoc(event.target.value);
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} className={classes.grids}>
                    <Autocomplete
                      // hidden
                      fullWidth
                      multiple={true}
                      noOptionsText={"No options"}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select template(s) to sign"
                          variant="outlined"
                          autoComplete="new-password"
                        />
                      )}
                      value={selectedTemplate || []}
                      options={templateList || []}
                      getOptionLabel={(option) => option.name || ""}
                      getOptionSelected={(option, value) => {
                        return option.name === value.name;
                      }}
                      onChange={(event, value) => {
                        setSelectedTemplate(value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider
              orientation="horizontal"
              variant="fullWidth"
              style={{ width: "100%", margin: "16px 0" }}
            />

            {userInfo.userType === SERVICE_PROVIDER_USER ? (
              <>
                <CurrentAndNewAddress
                  {...currentAndNewAddress?.currentAddress}
                  titleLabel="Current Address"
                  provinces={provinces}
                  cities={cities}
                  saveAddressField={saveAddressField}
                />

                <Divider
                  orientation="horizontal"
                  variant="fullWidth"
                  style={{ width: "100%", margin: "16px 0" }}
                />

                <CurrentAndNewAddress
                  {...currentAndNewAddress?.newAddress}
                  titleLabel="New Home Address"
                  provinces={provinces}
                  cities={cities}
                  saveAddressField={saveAddressField}
                />

                <Divider
                  orientation="horizontal"
                  variant="fullWidth"
                  style={{ width: "100%", margin: "16px 0" }}
                />
              </>
            ) : null}

            <Grid item xs={12}>
              <Grid container item xs={12} md={6} className={classes.grids}>
                <Grid item xs={10}>
                  <Typography className={classes.semiBold16}>
                    Add Co-applicant
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Switch
                    size="small"
                    className={"sqGraySwitch"}
                    checked={showCoApplicantdetails}
                    onChange={(event) => {
                      setShowCoApplicantdetails(event.target.checked);
                    }}
                  />
                </Grid>
              </Grid>
              {showCoApplicantdetails && (
                <Grid item xs={12} md={11}>
                  <Grid item xs={12} md={6} className={classes.grids}>
                    <TextField
                      id="coApplicantFullName"
                      placeholder="Full Name"
                      label="Full Name"
                      name="coApplicantFullName"
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        maxLength: 256,
                      }}
                      value={coApplicant.fullName}
                      onChange={(event) => {
                        saveCoApplicantData({
                          fullName: event.target.value,
                        });
                      }}
                      autoComplete="full-name"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.grids}>
                    <TextField
                      error={!!coApplicantValidations.email}
                      helperText={coApplicantValidations.email}
                      id="coApplicantEmailAddress"
                      placeholder="Email Address"
                      label="Email Address"
                      name="coApplicantEmailAddress"
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        saveCoApplicantData({ email: event.target.value });
                      }}
                      value={coApplicant.email}
                      autoComplete="email-address"
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Divider
              orientation="horizontal"
              variant="fullWidth"
              style={{ width: "100%", margin: "16px 0" }}
            />
            <RecommendedSP
              showRecommendedSPDetails={showRecommendedSPDetails}
              setshowRecommendedSPDetails={setshowRecommendedSPDetails}
              recommendSP={recommendSP}
              saveRecommendSPData={saveRecommendSPData}
              serviceProviderList={serviceProviderList}
              setAddSPSucessMsg={setAddSPSucessMsg}
              newAddedSP={recommendSP.newAddedSP}
              deleteNewSP={deleteNewSP}
              getSPList={getSPList}
            />
          </DialogContentText>
        </DialogContent>
        <Divider
          orientation="horizontal"
          variant="fullWidth"
          style={{ width: "100%" }}
        />
        <DialogActions style={{ paddingRight: 38 }}>
          <Button
            variant="outlined"
            className={Styles.navyBlueColorForce}
            style={{ textTransform: "capitalize", marginRight: 10 }}
            onClick={() => {
              if (
                primaryApplicant.fullName ||
                primaryApplicant.email ||
                primaryApplicant.phoneNumber ||
                showCoApplicantdetails ||
                showRecommendedSPDetails ||
                selectedDoc.length
              ) {
                setOpenConfirmationDailog(true);
              } else {
                setOpenInviteClientsDialog(false);
                setShowCoApplicantdetails(false);
                setshowRecommendedSPDetails(false);
                setSelectedService(null);
                setSelectedDoc([]);
                setSelectedTemplate([]);
                clearData();
                resetCurrentAndNewAddress();
              }
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={
              !primaryApplicantAllowAction ||
              !coApplicantAllowAction ||
              !currentAndNewAddressAllowAction ||
              ((srcPage === "clientManagement" ||
                srcPage === "serviceProvider") &&
                !selectedService)
            }
            className={Styles.primaryBtnAccent}
            style={{ textTransform: "capitalize", width: 141 }}
            onClick={() => {
              sentInviteToClients((res) => {
                if (res.data.status === 200) {
                  if (
                    srcPage === "clientManagement" ||
                    srcPage === "serviceProvider"
                  ) {
                    getActiveClientData && getActiveClientData();
                  }

                  // Increase lead count for recommended SPs
                  const recommendOtherSPIds = [];
                  if (recommendSP.selectedPreferredSP.length) {
                    recommendSP.selectedPreferredSP.forEach((data) =>
                      recommendOtherSPIds.push(data.serviceProviderId)
                    );
                    addLeadsForRecommendedSPs(
                      recommendOtherSPIds,
                      (response) => {
                        if (response && response.status === 200) {
                          cleanUpUI();
                        }
                      }
                    );
                  } else {
                    cleanUpUI();
                  }
                } else if (res.data.status === 400) {
                  addValidations(res.data.result);
                } else if (res.data.status === 404) {
                  addValidations({ email: res.data.message });
                } else if (res.data.status === 412) {
                  setError({ open: true, message: res.data.result });
                }
              });
            }}
          >
            Send Invite
          </Button>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={error.open}
            onClose={() => {
              setError({ open: false });
            }}
          >
            <Alert severity="error">{error.message}</Alert>
          </Snackbar>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default InviteClientsDialog;
