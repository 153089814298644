import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 400,
  },
}));

function ProfileAccessRequestDialog({
  openProfileAccessRequestDialog,
  setOpenProfileAccessRequestDialog,
  profileAccessData,
  acceptRejectProfileAccessRequest,
}) {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      open={openProfileAccessRequestDialog}
      onClose={() => {
        setOpenProfileAccessRequestDialog(false);
      }}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Grid container item>
          <Grid item xs={10}>
            <Typography variant="subtitle1">Profile Access Request</Typography>
          </Grid>
          <Grid container item xs={2} justifyContent="flex-end">
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpenProfileAccessRequestDialog(false);
              }}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ marginTop: 8 }}>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            className={Styles.navyBlueColor}
            style={{ fontWeight: 600 }}
          >
            Service Provider&nbsp;
            {profileAccessData && profileAccessData.requestorSPDetails.email} is
            requesting access to view your profile
          </Typography>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions style={{ padding: "16px 36px" }}>
        <Button
          variant="outlined"
          style={{
            padding: "6px 16px",
            marginRight: 12,
            textTransform: "capitalize",
          }}
          className={Styles.navyBlueColorForce}
          onClick={() => {
            acceptRejectProfileAccessRequest(
              false, //isApproved value false
              profileAccessData.serviceRequestId,
              (response) => {
                if (response && response.data.status === 200) {
                  setOpenProfileAccessRequestDialog(false);
                }
              }
            );
          }}
        >
          Reject
        </Button>
        <Button
          variant="contained"
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize", padding: "6px 32px" }}
          onClick={() => {
            acceptRejectProfileAccessRequest(
              true, //isApproved value true
              profileAccessData.serviceRequestId,
              (response) => {
                if (response && response.data.status === 200) {
                  setOpenProfileAccessRequestDialog(false);
                }
              }
            );
          }}
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ProfileAccessRequestDialog;
