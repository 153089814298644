import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import Styles from "../../Styles.module.css";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";

export default function RentalDetailsPopup({
  openRentalDetailsPopup,
  setOpenRentalDetailsPopup,
  userInfo,
  data,
}) {
  // let filteredCity =
  //   cities &&
  //   cities.length &&
  //   cities.filter(
  //     (item) =>
  //       item.cityDTO[0].id ===
  //       data?.broadCastServiceRequestDto?.rentalForNewComersDetails
  //         ?.currentCityId
  //   );

  const timelineOptions = [
    { name: "Urgent", value: "URGENT" },
    { name: "As Soon As Possible", value: "AS_SOON_AS_POSSIBLE" },
    { name: "1-2 weeks", value: "ONE_TO_TWO_WEEKS" },
    { name: "2-4 weeks", value: "TWO_TO_FOUR_WEEKS" },
    { name: "4-8 weeks", value: "FOUR_TO_EIGHT_WEEKS" },
    { name: "8 weeks plus", value: "EIGHT_WEEKS_PLUS" },
  ];

  let formattedTimeline =
    data.broadCastServiceRequestDto &&
    timelineOptions.filter(
      (item) => item.value === data.broadCastServiceRequestDto.timeLine
    )[0];

  return (
    <Dialog fullWidth open={openRentalDetailsPopup} maxWidth={"sm"}>
      <DialogTitle>
        <Grid item container xs={12}>
          <Grid item xs={11}>
            <Typography
              variant="subtitle1"
              className={Styles.navyBlueColorForce}
            >
              Rental Details
            </Typography>
          </Grid>
          <Grid item xs={1} container style={{ justifyContent: "flex-end" }}>
            <Button
              style={{ padding: 0, minWidth: 0 }}
              onClick={() => {
                //     setUserEngagementData &&
                //       setUserEngagementData(
                //         storeFrontId,
                //         "Service_Request_Cancelled"
                //       );
                //     setOpenserviceRequestDialog({ open: false, service: null });
                //     if (nonloggedInUser) {
                //       localStorage.removeItem("nonloggedInUser");
                //       history.push("/app/homebuyer/explore");
                //     }
                //     setServiceSelected(null);
                //     clearData();
                setOpenRentalDetailsPopup(false);
              }}
            >
              <CloseIcon className={Styles.navyBlueColorForce} />
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent
        className={Styles.navyBlueColorForce}
        // style={{ minHeight: userInfo ? 350 : 70 }}
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={Styles.navyBlueColorForce}>
            Personal Details:
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Grid item>
            <Typography className={Styles.navyBlueColorForce}>
              Currently residing in Canada:
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: 8 }}>
            <Typography className={Styles.primaryTxtAccentForce}>
              {data.broadCastServiceRequestDto?.rentalForNewComersDetails
                ?.canadaResident
                ? "Yes"
                : "No"}
            </Typography>
          </Grid>
        </Grid>
        {data.broadCastServiceRequestDto?.rentalForNewComersDetails
          ?.canadaResident && (
          <Grid container item xs={12}>
            <Grid item>
              <Typography className={Styles.navyBlueColorForce}>
                City:
              </Typography>
            </Grid>
            <Grid item style={{ marginLeft: 8 }}>
              <Typography className={Styles.primaryTxtAccentForce}>
                {
                  data?.broadCastServiceRequestDto?.rentalForNewComersDetails
                    ?.currentCityName
                }
              </Typography>
            </Grid>
          </Grid>
        )}
        {data?.broadCastServiceRequestDto?.rentalForNewComersDetails
          ?.identificationDocuments &&
        data?.broadCastServiceRequestDto?.rentalForNewComersDetails
          ?.identificationDocuments.length ? (
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Typography
                className={Styles.navyBlueColorForce}
                // style={{ display: "flex" }}
                display="inline"
                component="span"
              >
                Identification Documents Added:
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                style={{ display: "inline" }}
                className={Styles.primaryTxtAccentForce}
              >
                {data.broadCastServiceRequestDto.rentalForNewComersDetails.identificationDocuments.join(
                  ", "
                )}
              </Typography>
            </Grid>
          </Grid>
        ) : null}

        <Grid
          item
          xs={12}
          style={{
            marginTop: 20,
          }}
        >
          <Typography variant="subtitle1" className={Styles.navyBlueColorForce}>
            Rental Details:
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Grid item>
            <Typography className={Styles.navyBlueColorForce}>
              Budget for the rental:
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: 8 }}>
            <Typography className={Styles.primaryTxtAccentForce}>
              {
                data.broadCastServiceRequestDto?.rentalForNewComersDetails
                  ?.budget
              }
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item>
            <Typography className={Styles.navyBlueColorForce}>
              Preferred location:
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: 8 }}>
            <Typography className={Styles.primaryTxtAccentForce}>
              {data.broadCastServiceRequestDto?.city}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item>
            <Typography className={Styles.navyBlueColorForce}>
              When are you looking to get into a rental home?
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: 8 }}>
            <Typography className={Styles.primaryTxtAccentForce}>
              {formattedTimeline?.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item>
            <Typography className={Styles.navyBlueColorForce}>
              Type of rental home looking for:
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: 8 }}>
            <Typography className={Styles.primaryTxtAccentForce}>
              {
                data.broadCastServiceRequestDto?.rentalForNewComersDetails
                  ?.typeOfRentalHome
              }
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item>
            <Typography className={Styles.navyBlueColorForce}>
              Bedrooms needed:
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: 8 }}>
            <Typography className={Styles.primaryTxtAccentForce}>
              {
                data.broadCastServiceRequestDto?.rentalForNewComersDetails
                  ?.numberOfBedrooms
              }
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item>
            <Typography className={Styles.navyBlueColorForce}>
              Bathrooms needed:
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: 8 }}>
            <Typography className={Styles.primaryTxtAccentForce}>
              {
                data.broadCastServiceRequestDto?.rentalForNewComersDetails
                  ?.numberOfBathrooms
              }
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item>
            <Typography className={Styles.navyBlueColorForce}>
              Have a local guarantor:
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: 8 }}>
            <Typography className={Styles.primaryTxtAccentForce}>
              {data.broadCastServiceRequestDto?.rentalForNewComersDetails
                ?.guarantorAvailable
                ? "Yes"
                : "No"}
            </Typography>
          </Grid>
        </Grid>
        {data.broadCastServiceRequestDto?.rentalForNewComersDetails
          ?.guarantorAvailable && (
          <Grid container item xs={12}>
            <Grid item>
              <Typography className={Styles.navyBlueColorForce}>
                Guarantor Name:
              </Typography>
            </Grid>
            <Grid item style={{ marginLeft: 8 }}>
              <Typography className={Styles.primaryTxtAccentForce}>
                {
                  data.broadCastServiceRequestDto?.rentalForNewComersDetails
                    ?.guarantorName
                }
              </Typography>
            </Grid>
          </Grid>
        )}
        {data.broadCastServiceRequestDto?.rentalForNewComersDetails
          ?.guarantorAvailable && (
          <Grid container item xs={12}>
            <Grid item>
              <Typography className={Styles.navyBlueColorForce}>
                Guarantor Address:
              </Typography>
            </Grid>
            <Grid item style={{ marginLeft: 8 }}>
              <Typography className={Styles.primaryTxtAccentForce}>
                {
                  data.broadCastServiceRequestDto?.rentalForNewComersDetails
                    ?.guarantorAddress
                }
              </Typography>
            </Grid>
          </Grid>
        )}
        {data.broadCastServiceRequestDto?.rentalForNewComersDetails
          ?.guarantorAvailable && (
          <Grid container item xs={12}>
            <Grid item>
              <Typography className={Styles.navyBlueColorForce}>
                Guarantor Phone number:
              </Typography>
            </Grid>
            <Grid item style={{ marginLeft: 8 }}>
              <Typography className={Styles.primaryTxtAccentForce}>
                {
                  data.broadCastServiceRequestDto?.rentalForNewComersDetails
                    ?.guarantorPhoneNumber
                }
              </Typography>
            </Grid>
          </Grid>
        )}
        {data.broadCastServiceRequestDto?.rentalForNewComersDetails
          ?.guarantorAvailable && (
          <Grid container item xs={12}>
            <Grid item>
              <Typography className={Styles.navyBlueColorForce}>
                Guarantor Email address:
              </Typography>
            </Grid>
            <Grid item style={{ marginLeft: 8 }}>
              <Typography className={Styles.primaryTxtAccentForce}>
                {
                  data.broadCastServiceRequestDto?.rentalForNewComersDetails
                    ?.guarantorEmail
                }
              </Typography>
            </Grid>
          </Grid>
        )}
        {data.broadCastServiceRequestDto?.rentalForNewComersDetails
          ?.guarantorAvailable && (
          <Grid container item xs={12}>
            <Grid item>
              <Typography className={Styles.navyBlueColorForce}>
                Does the guarantor own their home?
              </Typography>
            </Grid>
            <Grid item style={{ marginLeft: 8 }}>
              <Typography className={Styles.primaryTxtAccentForce}>
                {data.broadCastServiceRequestDto?.rentalForNewComersDetails
                  ?.guarantorOwnHome
                  ? "Yes"
                  : "No"}
              </Typography>
            </Grid>
          </Grid>
        )}
        {data.broadCastServiceRequestDto?.rentalForNewComersDetails
          ?.guarantorAvailable &&
          !data.broadCastServiceRequestDto?.rentalForNewComersDetails
            ?.guarantorOwnHome && (
            <Grid container item xs={12}>
              <Grid item>
                <Typography className={Styles.navyBlueColorForce}>
                  How many months of security deposit can you provide?
                </Typography>
              </Grid>
              <Grid item style={{ marginLeft: 8 }}>
                <Typography className={Styles.primaryTxtAccentForce}>
                  {
                    data.broadCastServiceRequestDto?.rentalForNewComersDetails
                      ?.securityDepositDetail
                  }
                </Typography>
              </Grid>
            </Grid>
          )}
        <Grid container item xs={12}>
          <Grid item>
            <Typography className={Styles.navyBlueColorForce}>
              Have language restrictions to communicate with a realtor?
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: 8 }}>
            <Typography className={Styles.primaryTxtAccentForce}>
              {data.broadCastServiceRequestDto?.rentalForNewComersDetails
                ?.laguageRestriction
                ? "Yes"
                : "No"}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            marginTop: 20,
          }}
        >
          <Typography variant="subtitle1" className={Styles.navyBlueColorForce}>
            Employment Details:
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Grid item>
            <Typography className={Styles.navyBlueColorForce}>
              Currently employed:
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: 8 }}>
            <Typography className={Styles.primaryTxtAccentForce}>
              {data.broadCastServiceRequestDto?.rentalForNewComersDetails
                ?.employed
                ? "Yes"
                : "No"}
            </Typography>
          </Grid>
        </Grid>
        {data.broadCastServiceRequestDto?.rentalForNewComersDetails
          ?.employed && (
          <Grid container item xs={12}>
            <Grid item>
              <Typography className={Styles.navyBlueColorForce}>
                Company Name:
              </Typography>
            </Grid>
            <Grid item style={{ marginLeft: 8 }}>
              <Typography className={Styles.primaryTxtAccentForce}>
                {
                  data.broadCastServiceRequestDto?.rentalForNewComersDetails
                    ?.companyName
                }
              </Typography>
            </Grid>
          </Grid>
        )}
        {data.broadCastServiceRequestDto?.rentalForNewComersDetails
          ?.employed && (
          <Grid container item xs={12}>
            <Grid item>
              <Typography className={Styles.navyBlueColorForce}>
                Position:
              </Typography>
            </Grid>
            <Grid item style={{ marginLeft: 8 }}>
              <Typography className={Styles.primaryTxtAccentForce}>
                {
                  data.broadCastServiceRequestDto?.rentalForNewComersDetails
                    ?.position
                }
              </Typography>
            </Grid>
          </Grid>
        )}
        {data.broadCastServiceRequestDto?.rentalForNewComersDetails
          ?.employed && (
          <Grid container item xs={12}>
            <Grid item>
              <Typography className={Styles.navyBlueColorForce}>
                Salary Range:
              </Typography>
            </Grid>
            <Grid item style={{ marginLeft: 8 }}>
              <Typography className={Styles.primaryTxtAccentForce}>
                {
                  data.broadCastServiceRequestDto?.rentalForNewComersDetails
                    ?.salaryRange
                }
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid container item xs={12}>
          <Grid item>
            <Typography className={Styles.navyBlueColorForce}>
              Are you able to provide a letter from your company?
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: 8 }}>
            <Typography className={Styles.primaryTxtAccentForce}>
              {data.broadCastServiceRequestDto?.rentalForNewComersDetails
                ?.companyLetterAvailable
                ? "Yes"
                : "No"}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            marginTop: 20,
          }}
        >
          <Typography variant="subtitle1" className={Styles.navyBlueColorForce}>
            Financial Details:
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Grid item>
            <Typography className={Styles.navyBlueColorForce}>
              Do you have a credit report available to share?
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: 8 }}>
            <Typography className={Styles.primaryTxtAccentForce}>
              {data.broadCastServiceRequestDto?.rentalForNewComersDetails
                ?.creditReportAvailable
                ? "Yes"
                : "No"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item>
            <Typography className={Styles.navyBlueColorForce}>
              Can you share your bank statements with the realtor/landlord?
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: 8 }}>
            <Typography className={Styles.primaryTxtAccentForce}>
              {data.broadCastServiceRequestDto?.rentalForNewComersDetails
                ?.bankStatementsSharable
                ? "Yes"
                : "No"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item>
            <Typography className={Styles.navyBlueColorForce}>
              Country of origin:
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: 8 }}>
            <Typography className={Styles.primaryTxtAccentForce}>
              {
                data.broadCastServiceRequestDto?.rentalForNewComersDetails
                  ?.countryOfOrigin
              }
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item>
            <Typography className={Styles.navyBlueColorForce}>
              Have you ever filed bankruptcy in Canada?
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: 8 }}>
            <Typography className={Styles.primaryTxtAccentForce}>
              {data.broadCastServiceRequestDto?.rentalForNewComersDetails
                ?.filedBankruptcyInCanada
                ? "Yes"
                : "No"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={{ marginBottom: 16 }}>
          <Grid item>
            <Typography className={Styles.navyBlueColorForce}>
              Have you ever been evicted?
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: 8 }}>
            <Typography className={Styles.primaryTxtAccentForce}>
              {data.broadCastServiceRequestDto?.rentalForNewComersDetails
                ?.everBeenEvicted
                ? "Yes"
                : "No"}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
