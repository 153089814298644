import { SET_SP_REFERRAL_LIST } from "../../souqh-redux/reducers/actionConstants";
import { useSelector, useDispatch } from "react-redux";
import { useAuthUser } from "../useLogin";
import { useActions } from "../useActions";
import { useState, useEffect } from "react";
import { createSelector } from "reselect";
import { useApiClient } from "../useApiClient";

const actionCreators = {
  setSpReferralList: (payload) => ({ type: SET_SP_REFERRAL_LIST, payload }),
};

const getReferralData = (user, apiClient) => (dispatch) => {
  dispatch({ type: "SET_LOADING", value: true });
  return apiClient
    .get(`/referral/invite/service-providers?userId=${user.userId}`)
    .then((response) => {
      if (response && response.data.result && response.data.result.length) {
        dispatch({ type: SET_SP_REFERRAL_LIST, payload: response.data.result });
      }
    })
    .catch(function (error) {
      console.error(error);
    })
    .then(function () {
      dispatch({ type: "SET_LOADING", value: false });
    });
};

const getReferralList = createSelector(
  (state) => state.referralManagement.spReferralList,
  (spReferralList) => {
    if (!spReferralList.length) return [];
    return spReferralList.map((v) => ({
      id: v.id,
      userId: v.userId,
      businessType: v.businessType,
      fullName: v.fullName,
      email: v.email,
      referralInviteStatus: v.referralInviteStatus,
      inviteDate: v.inviteDate,
      acceptedDate: v.acceptedDate,
      completionDate: v.completionDate,
      businessTypeName: v.businessType.name,
      preferred: v.preferred
    }));
  }
);

export const useReferralManagement = (isGetReferralData) => {
  const { apiClient } = useApiClient();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("fullName");

  const [searchText, setSearchText] = useState("");
  const [referralTableData, setReferralTableData] = useState([]);

  const spReferralList = useSelector(getReferralList);

  const dispatch = useDispatch();
  const user = useAuthUser();
  const actions = useActions(actionCreators);

  useEffect(() => {
    if (!spReferralList.length && isGetReferralData) {
      dispatch(getReferralData(user, apiClient));
    }
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    if (spReferralList.length) {
      setReferralTableData(spReferralList);
    }
  }, [spReferralList]);

  const searchVal = (val) => {
    setSearchText(val);
    requestSearch(val);
  };

  const requestSearch = (searchedVal) => {
    setSearchText(searchedVal);
    let filteredRows = [];
    spReferralList &&
      spReferralList.length &&
      spReferralList.map((row) => {
        if (
          row.businessType.name
            .toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row.fullName.toLowerCase().includes(searchedVal.toLowerCase()) ||
          row.referralInviteStatus
            .toString()
            .toLowerCase()
            .includes(searchedVal.toLowerCase())
        ) {
          filteredRows.push(row);
        }
      });
    setReferralTableData(filteredRows);
  };

  const setPreferredCheckedValue = (checkedValue, email, onResult) => {
    let url = `/preferred-partner/${email}/status/${checkedValue}`;

    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .put(url)
      .then(function (response) {
        if (response.status === 200) {
          let newList = [...referralTableData]
          for (i = 0; i < newList.length; i++) {
            if (newList[i].email === email) {
              newList[i].preferred = checkedValue
            }
          }
          setReferralTableData(newList)
          onResult && onResult(response)
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };



  return {
    getReferralData,
    spReferralList,
    actions,
    order,
    orderBy,
    handleRequestSort,
    searchVal,
    referralTableData,
    setPreferredCheckedValue
  };
};
