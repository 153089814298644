import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import * as moment from "moment";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { HOME_BUYER_USER } from "../../ServiceProvider/serviceProviderConstants";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 466,
    minWidth: 800,
  },
}));

function ViewOtherActionDialog({
  openOtherActionDialog,
  setOpenOtherActionDialog,
  otherActionData,
  source,
}) {
  const classes = useStyles();
  const userInfo = useAuthUser();

  let requestorEmail;

  if (source === "notifications") {
    requestorEmail = otherActionData && otherActionData.fromEmail;
  } else {
    requestorEmail =
      otherActionData &&
      otherActionData.requestorSPDetails &&
      otherActionData.requestorSPDetails.email;
  }
  // }
  let actionName = "";
  if (source === "notifications") {
    actionName = otherActionData && otherActionData.actionName;
  } else {
    actionName =
      (otherActionData &&
        otherActionData.eventDescription &&
        otherActionData.eventDescription.split("-")[0]) ||
      "-";
  }

  return (
    <Dialog
      fullWidth
      open={openOtherActionDialog}
      onClose={() => {
        setOpenOtherActionDialog(false);
      }}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle
        className={Styles.navyBlueColor}
        style={{ padding: "12px 24px" }}
      >
        <Typography variant="subtitle1">Other Action</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ marginTop: 8 }}>
        <Grid container item xs={12}>
          <Grid item xs={3}>
            <Typography
              variant="body1"
              className={Styles.navyBlueColor}
              style={{ fontWeight: 600 }}
            >
              From
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography
              variant="body1"
              className={Styles.navyBlueColor}
              style={{ fontWeight: 600 }}
            >
              {requestorEmail}
            </Typography>
          </Grid>
          <Divider
            orientation="horizontal"
            variant="fullWidth"
            style={{ width: "100%", margin: "16px 0" }}
          />
          <Grid item xs={3}>
            <Typography
              variant="body1"
              className={Styles.navyBlueColor}
              style={{ fontWeight: 600 }}
            >
              Action
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="body1" className={Styles.navyBlueColor}>
              {actionName}
            </Typography>
          </Grid>
          <Divider
            orientation="horizontal"
            variant="fullWidth"
            style={{ width: "100%", margin: "16px 0" }}
          />
          <Grid item xs={3}>
            <Typography
              variant="body1"
              className={Styles.navyBlueColor}
              style={{ fontWeight: 600 }}
            >
              Comment
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="body1" className={Styles.navyBlueColor}>
              {otherActionData && otherActionData.tagComments}
            </Typography>
          </Grid>
          <Divider
            orientation="horizontal"
            variant="fullWidth"
            style={{ width: "100%", margin: "16px 0" }}
          />
          <Grid item xs={3}>
            <Typography
              variant="body1"
              className={Styles.navyBlueColor}
              style={{ fontWeight: 600 }}
            >
              Deadline
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="body1" className={Styles.navyBlueColor}>
              {otherActionData && otherActionData.dueDate
                ? moment(otherActionData.dueDate).format("MMM DD, YYYY")
                : "-"}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions style={{ padding: "16px 24px" }}>
        <Button
          variant="outlined"
          style={{
            padding: "6px 16px",
            textTransform: "capitalize",
          }}
          className={Styles.navyBlueColorForce}
          onClick={() => {
            setOpenOtherActionDialog(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ViewOtherActionDialog;
