import { useState, useRef } from "react";
import {
  IMAGE_FILE_FORMATS,
  OFFICE_FILE_FORMATS,
} from "../../web/src/components/HBRegistration/HomeBuyerConstants";

export const useDocumentPreview = () => {
  const [frameTimeoutId, setFrameTimeoutId] = useState(null);
  const [counter, setCounter] = useState(0);
  const eleRef = useRef(null);
  const [fileNotLoaded, setFileNotLoaded] = useState(false);
  const setRefreshTimer = (documentItem) => {
    if (documentItem) {
      const documentType = (documentItem.type || "").toLowerCase();
      if (
        !(
          IMAGE_FILE_FORMATS.includes(documentType) ||
          OFFICE_FILE_FORMATS.includes(documentType) ||
          documentType === "pdf"
        )
      ) {
        const tempId = setInterval(updateIframeSrc, 3000);
        setFrameTimeoutId(tempId);
      }
    }
  };
  const updateIframeSrc = () => {
    setCounter(counter + 1);
    if (eleRef && eleRef.current && counter < 5) {
      eleRef.current.src = eleRef.current.src;
    }
    if (counter > 5) {
      iframeLoaded();
      setFileNotLoaded(true);
      clearInterval(frameTimeoutId);
    }
  };

  const iframeLoaded = () => {
    clearInterval(frameTimeoutId);
    setFileNotLoaded(false);
  };

  const clearAll = (setDocumentItem, setOpenDocumetDialog) => {
    setOpenDocumetDialog(false);
    setDocumentItem(null);
    iframeLoaded();
  };

  return {
    counter,
    setCounter,
    frameTimeoutId,
    setFrameTimeoutId,
    eleRef,
    fileNotLoaded,
    setFileNotLoaded,
    setRefreshTimer,
    clearAll,
    iframeLoaded,
    updateIframeSrc,
  };
};
