import {
  HOME_BUYER_USER,
  SERVICE_PROVIDER_USER,
  TEAM_MEMBER_USER,
} from "../../web/src/components/ServiceProvider/serviceProviderConstants";
import { useAuthUser } from "../useLogin";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { color_error } from "../toasterColors";

export const useRouteCheck = () => {
  const userInfo = useAuthUser();
  let history = useHistory();
  const dispatch = useDispatch();

  const checkSPRoute = () => {
    if (userInfo) {
      if (
        userInfo.userType === SERVICE_PROVIDER_USER ||
        userInfo.userType == TEAM_MEMBER_USER
      ) {
        // do noting
      } else {
        history.push("/homebuyer/dashboard");
        dispatch({
          type: "SET_TOASTER",
          payload: {
            message: `You do not have access to this page`,
            bgColor: color_error,
            open: true,
            severity: "error",
          },
        });
      }
    }
  };

  const checkHBRoute = () => {
    if (userInfo) {
      if (userInfo.userType !== HOME_BUYER_USER) {
        history.push("/serviceprovider/dashboard");
        dispatch({
          type: "SET_TOASTER",
          payload: {
            message: `You do not have access to this page`,
            bgColor: color_error,
            open: true,
            severity: "error",
          },
        });
      }
    }
  };

  return {
    checkSPRoute,
    checkHBRoute,
  };
};
