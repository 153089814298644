import { useState } from "react";
import { useAuthUser } from "../../useLogin";
import { useDispatch, useSelector } from "react-redux";
import { useApiClient } from "../../useApiClient";

import {
  SET_TAG_MEMBERS,
  EDIT_TEAM_MEMBER,
  SET_ALL_USERS,
  SET_LOADING_ALL_USERS_DATA,
} from "souqh-redux/reducers/actionConstants";
import { useActions } from "../../useActions";
import {
  PREMIUM,
  SERVICE_PROVIDER_USER,
  TEAM_MEMBER_USER,
  PA_TAG_TEAM_MEMBER,
  PA_TAG_SOMEONE,
  ADVANCED,
} from "../../../web/src/components/ServiceProvider/serviceProviderConstants";

const actionCreators = {
  setTagMembers: (payload) => ({
    type: SET_TAG_MEMBERS,
    payload,
  }),
  editTeamMember: (payload) => ({
    type: EDIT_TEAM_MEMBER,
    payload,
  }),
};

export const useTagSomeone = (serviceRequestId = null, page) => {
  const actions = useActions(actionCreators);
  const [selectedMember, setSelectedMember] = useState(null);
  const [tagComments, setTagComments] = useState("");
  const [dueDate, setDueDate] = useState(null);
  const { apiClient } = useApiClient();
  const { allSouqhUsers, isLoadedAllUserData, loadingUsersData } = useSelector(
    (state) => state.allUsers
  );
  const dispatch = useDispatch();
  const userInfo = useAuthUser();

  const { tagMembers } = useSelector((state) => state.clientManagement);

  const getTagMembers = () => {
    let url =
      userInfo.userType === SERVICE_PROVIDER_USER ||
      (userInfo.userType === TEAM_MEMBER_USER &&
        (userInfo.subscriptionPlanName === PREMIUM || userInfo.subscriptionPlanName === ADVANCED))
        ? `/service-provider/team-member/${userInfo.serviceProviderId}`
        : `/service-provider/team-member/${userInfo.parentServiceProviderId}`;
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .get(url, {
        timeout: 30000,
      })
      .then((response) => {
        if (response.data.status === 200) {
          dispatch({ type: "SET_LOADING", value: false });
          actions.setTagMembers(response.data.result);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };
  const getAllSouqhMembers = () => {
    if (loadingUsersData || isLoadedAllUserData) {
      return;
    }
    dispatch({
      type: SET_LOADING_ALL_USERS_DATA,
      payload: {
        loadingUsersData: false,
      },
    });
    apiClient
      .get(`/user/all-users`)
      .then((response) => {
        if (response.data.status === 200) {
          dispatch({
            type: SET_ALL_USERS,
            payload: {
              data: response.data.result,
              isLoadedAllUserData: true,
              loadingUsersData: false,
            },
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  let data;

  if (userInfo) {
    data = {
      dueDate: dueDate ? dueDate.toISOString() : null,
      requestorUserId: userInfo.userId,
      serviceRequestId: serviceRequestId,
      tagComments: tagComments,
      taggedMemberUserId: selectedMember && selectedMember.userId,
      tagActionType: page === "clientHub" ? PA_TAG_SOMEONE : PA_TAG_TEAM_MEMBER,
    };
  }
  const tagMember = (onSuccess) => {
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .post(`/pending-actions/tag-member`, data)
      .then((response) => {
        if (response.data.status === 200) {
          dispatch({ type: "SET_LOADING", value: false });
          onSuccess(response);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };
  const clearData = () => {
    setSelectedMember(null);
    setTagComments("");
    setDueDate(null);
  };
  return {
    selectedMember,
    setSelectedMember,
    tagMembers,
    tagComments,
    dueDate,
    setTagComments,
    setDueDate,
    getTagMembers,
    tagMember,
    clearData,
    actions,
    getAllSouqhMembers,
    allSouqhUsers,
  };
};
