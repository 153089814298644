import React, { useCallback } from "react";
import {
  Typography,
  Button,
  Grid,
  makeStyles,
  ButtonBase,
  FormControl,
  TextField,
  Divider,
  Tooltip,
} from "@material-ui/core/";
import AddIcon from "@material-ui/icons/Add";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import Styles from "../../../../Styles.module.css";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined": {
      top: -8,
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px",
    },
    "& .file-drop": {
      height: "100%",
    },
  },
  tableHeader: {
    color: "#0E1C4E",
    fontSize: "1rem",
    fontWeight: "bold",
  },
  tableContent: {
    color: "#0E1C4E",
    fontSize: "1rem",
    width: "98%",
  },
  button: {
    color: "#0E1C4E",
    textTransform: "capitalize",
  },
  formControl: {
    minWidth: 120,
    width: "80%",
  },
}));

export default function AddTemplateStep1({
  onUploadTemplateFileChange,
  processTemplateUploadedFiles,
  templateDocumentList,
  templateDescription,
  setTemplateDescription,
  templateName,
  setTemplateName,
  hasUpdatedName,
  setHasUpdatedName,
  hasUpdatedDescription,
  setHasUpdatedDescription,
  deleteDocument,
}) {
  const classes = useStyles();

  const upperSection = (
    <Grid item container xs={12}>
      <Grid item container xs={12} style={{ padding: "0px 0px" }}>
        <Grid item container xs={4} className={classes.tableHeader}>
          <Typography variant="subtitle1" className={Styles.navyBlueColor}>
            Template Name
          </Typography>
        </Grid>
        <Grid item container xs={8} className={classes.tableHeader}>
          <Typography variant="subtitle1" className={Styles.navyBlueColor}>
            Description
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} style={{ padding: "8px 0px" }}>
        <Grid item container xs={4} className={classes.tableHeader}>
          <FormControl className={classes.formControl}>
            <TextField
              error={!!(!templateName && hasUpdatedName)}
              fullWidth
              placeholder={"Please add template name*"}
              variant="outlined"
              value={templateName}
              style={{ backgroundColor: "#ffffff" }}
              required
              onChange={(e) => {
                setTemplateName(e.target.value);
                setHasUpdatedName(true);
              }}
              helperText={
                !templateName && hasUpdatedName
                  ? "Please enter template name."
                  : ""
              }
              inputProps={{
                maxLength: 100,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item container xs={8} className={classes.tableHeader}>
          <FormControl className={classes.formControl}>
            <TextField
              error={!!(!templateDescription && hasUpdatedDescription)}
              fullWidth
              placeholder={"Please add a brief template description*"}
              variant="outlined"
              value={templateDescription}
              style={{ backgroundColor: "#ffffff" }}
              required
              onChange={(e) => {
                setTemplateDescription(e.target.value);
                setHasUpdatedDescription(true);
              }}
              helperText={
                !templateDescription && hasUpdatedDescription
                  ? "Please enter template description."
                  : ""
              }
              inputProps={{
                maxLength: 500,
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );

  const tableHeading = (
    <Grid
      item
      container
      xs={12}
      style={{ alignItems: "center", marginBottom: 8 }}
    >
      <Grid item xs={9}>
        <Typography variant="subtitle1" className={classes.tableContent}>
          Documents Added
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={3}
        style={{ justifyContent: "flex-end", paddingRight: 32 }}
      >
        <input
          style={{ display: "none" }}
          id="contained-button-file-upload-esignStep"
          multiple
          type="file"
          onChange={onUploadTemplateFileChange}
        />
        <label htmlFor="contained-button-file-upload-esignStep">
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            className={classes.button}
            component="span"
          >
            Add
          </Button>
        </label>
      </Grid>
    </Grid>
  );

  const cellContent = templateDocumentList.length
    ? templateDocumentList.map((fileData, index) => {
        return (
          <Grid item container xs={12} key={index}>
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              style={{ padding: "4px 0px" }}
            >
              <Grid item container xs={10} className={classes.tableContent}>
                <Tooltip title={fileData.file.name}>
                  <Typography
                    variant="body1"
                    style={{ width: "95%" }}
                    className={clsx(Styles.sqEllipsis, Styles.navyBlueColor)}
                  >
                    {fileData.file.name}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid
                item
                container
                xs={2}
                className={classes.tableContent}
                style={{ justifyContent: "flex-end", paddingRight: 64 }}
              >
                <ButtonBase>
                  <CancelOutlinedIcon
                    style={{ marginTop: 10 }}
                    onClick={() => {
                      deleteDocument(index);
                    }}
                  />
                </ButtonBase>
              </Grid>
            </Grid>
          </Grid>
        );
      })
    : null;

  const onDrop = (acceptedFiles) => {
    processTemplateUploadedFiles(acceptedFiles, null);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Grid
      container
      style={{ padding: "12px 20px", height: "100%" }}
      className={classes.root}
    >
      {upperSection}
      <Grid item xs={12} style={{ margin: "16px 0px 20px 0px" }}>
        <Divider />
      </Grid>
      {tableHeading}
      <Grid
        item
        xs={12}
        style={{ height: "calc(100% - 160px)", overflow: "auto" }}
      >
        <div
          style={{ height: "100%" }}
          {...getRootProps()}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <input {...getInputProps()} />
          <Grid item container xs={12}>
            {cellContent}
            {!templateDocumentList.length ? (
              <Grid item xs={12} style={{ margin: "12px 0px" }}>
                <Typography
                  variant="body1"
                  style={{
                    margin: "64px 0px",
                    textAlign: "center",
                    opacity: 0.5,
                    fontStyle: "italic",
                    fontWeight: 300,
                  }}
                  className={Styles.navyBlueColor}
                >
                  No document added, Please click on add button
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}
