import { useAuthUser } from "../useLogin";

export const useRoleManagement = () => {
  let userInfo = useAuthUser();

  const isTeamMember = () => {
    // checks if Team Member  has team-member role.
    if (userInfo.roles && userInfo.roles.length) {
      return userInfo.roles.includes("team-member");
    }
  };

  const isAdmin = () => {
    // checks if Team Member  has service-provider role.
    if (userInfo.roles && userInfo.roles.length) {
      return userInfo.roles.includes("service-provider");
    }
  };

  const getAdminNames = () => {
    let adminNamesArray = [];
    if (userInfo.adminUsers && userInfo.adminUsers.length) {
      adminNamesArray = userInfo.adminUsers.map((user) => user.fullName);
    }
    return adminNamesArray.toString();
  };

  return {
    isTeamMember,
    isAdmin,
    getAdminNames,
  };
};
