import { cloneDeep } from "lodash";
import {
  ADD_UPLOAD_DOCUMENTS,
  DELETE_UPLOAD_DOCUMENT_WHILE_UPLOAD,
  UPDATE_UPLOAD_DOCUMENT_DESCRIPTION,
  CLEAN_UPLOAD_FILE_DATA,
  SET_UPLOAD_SELECTED_CONTACTS,
  SET_UPLOAD_ACTIVE_CUSTOMERS,
  SET_UPLOAD_CONTACTS,
  SET_UPLOAD_CATEGORIES,
  SET_OPEN_UPLOAD_WIZARD,
} from "./actionConstants";

const initialState = {
  documentList: [],
  categories: [],
  duplicateFileName: false,
  selectedContacts: [],
  activeCustomers: [],
  selectedActiveSPs: [],
  contacts: [],
  openUploadWizard: false,
};
const uploadDocument = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case ADD_UPLOAD_DOCUMENTS:
      newState = cloneDeep(state);
      if (!action.payload.isDuplicate) {
        newState.documentList = [
          ...newState.documentList,
          ...action.payload.files,
        ];
        newState.duplicateFileName = false;
      } else {
        newState.duplicateFileName = true;
      }
      return newState;
    case UPDATE_UPLOAD_DOCUMENT_DESCRIPTION:
      newState = cloneDeep(state);
      const itemTobeModified = newState.documentList[action.payload.index];
      if (itemTobeModified) {
        newState.documentList[action.payload.index].description =
          action.payload.value;
        newState.documentList[
          action.payload.index
        ].hasUpdatedDescription = true;
      }
      return newState;
    case DELETE_UPLOAD_DOCUMENT_WHILE_UPLOAD:
      newState = cloneDeep(state);
      newState.documentList.splice(action.payload.index, 1);
      return newState;
    case CLEAN_UPLOAD_FILE_DATA:
      newState = cloneDeep(state);
      newState.documentList = [];
      newState.activeCustomers = [];
      newState.selectedActiveSPs = [];
      newState.contacts = [];
      newState.selectedContacts = [];
      newState.duplicateFileName = false;
      newState.openUploadWizard = false;
      return newState;
    case SET_UPLOAD_SELECTED_CONTACTS:
      newState = cloneDeep(state);
      newState.selectedContacts = action.payload;
      return newState;
    case SET_UPLOAD_CONTACTS:
      newState = cloneDeep(state);
      newState.contacts = action.payload;
      return newState;
    case SET_UPLOAD_ACTIVE_CUSTOMERS:
      newState = cloneDeep(state);
      newState.activeCustomers = action.payload.activeCustomers;
      return newState;
    case SET_UPLOAD_CATEGORIES:
      newState = cloneDeep(state);
      newState.categories = action.payload.categories;
      return newState;
    case SET_OPEN_UPLOAD_WIZARD:
      newState = cloneDeep(state);
      newState.openUploadWizard = action.payload.openUploadWizard;
      return newState;
    default:
      return state;
  }
};

export default uploadDocument;
