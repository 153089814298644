import {
  SET_INVOICE_DATA,
  SET_PROFILE_STATUS,
} from "../../../souqh-redux/reducers/actionConstants";
import { useAuthUser } from "../../useLogin";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useCallback } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { useApiClient } from "../../useApiClient";
import { useValidations } from "../../useValidations";

const validationMeta = [
  {
    name: "cardHolderName",
    patterns: [
      {
        match: (val) => /^$|^((?:[A-Za-z]+ ?){1,2})*[A-Za-z]$/i.test(val),
        msg: "Entered name has invalid characters. Please enter a valid name.",
      },
    ],
  },
];

export const usePaymentAndInvoice = (page) => {
  const { apiClient } = useApiClient();
  const user = useAuthUser();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [showCardDetails, setShowCardDetails] = useState(false);
  const [paymentCardDetails, setPaymentCardDetails] = useState(null);
  const [cardHolderName, setCardHolderName] = useState("");
  const [allowSubmit, setAllowSubmit] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const invoiceData = useSelector(
    (state) => state.paymentAndInvoice.invoiceData
  );
  const stripeCustomerId = useSelector(
    (state) => state.spSetup.stripeCustomerId
  );

  const team = useSelector((state) => state.spSetup.team);

  useEffect(() => {
    if (invoiceData && invoiceData.spProfileSetupStatus.isPaymentCardAdded) {
      setShowCardDetails(true);
      setPaymentCardDetails(invoiceData.paymentCardDetails[0]);
    } else {
      setShowCardDetails(false);
      setPaymentCardDetails(null);
    }
  }, [invoiceData]);

  const getFormValues = useCallback(() => {
    return { cardHolderName };
  }, [cardHolderName]);
  const getServerKeysMap = useCallback(() => {
    return {};
  }, []);

  const { validations, addValidations, clearAllValidations } = useValidations(
    validationMeta,
    getFormValues,
    getServerKeysMap
  );
  useEffect(() => {
    let validForm =
      cardHolderName &&
      !!cardHolderName.trim() &&
      !Object.keys(validations).length;
    setAllowSubmit(validForm);
  }, [cardHolderName, validations]);

  const getInvoiceData = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/service-provider/subscription/invoice/${user.serviceProviderId}`)
      .then(function (response) {
        if (response.data.status === 200) {
          dispatch({
            type: SET_INVOICE_DATA,
            payload: { invoiceData: response.data.result },
          });
        } else if (response.data.status === 451) {
          dispatch({
            type: SET_INVOICE_DATA,
            payload: { invoiceData: response.data },
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const handleSubmit = async (event, onResult) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardNumberElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      // Show error to your customer.
      setErrorMessage(result.error.message);
    } else {
      if (page && page === "registration") {
        setErrorMessage("");
        onResult && onResult(result);
      } else {
        let data = {
          serviceProviderId: user.serviceProviderId,
          stripeCustomerId: stripeCustomerId,
          tokenId: result.token.id,
        };
        // Send the token to your server.
        submitPaymentDetails(data, onResult);
      }
    }
  };

  const submitPaymentDetails = (data, onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .post("/payment/user", data)
      .then((res) => {
        if (res.data.status === 200) {
          setErrorMessage("");
          dispatch({
            type: SET_PROFILE_STATUS,
            payload: {
              data: {
                key: "isPaymentCardAdded",
                value: true,
              },
            },
          });
          dispatch({ type: "SET_TOASTER", value: true });
          onResult && onResult(res);
        } else if (res.data.status === 400) {
          setErrorMessage(res.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    getInvoiceData,
    invoiceData,
    handleSubmit,
    errorMessage,
    showCardDetails,
    paymentCardDetails,
    team,
    cardHolderName,
    setCardHolderName,
    allowSubmit,
    validations,
  };
};
