import {
  Button,
  Divider,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  SwipeableDrawer,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TrafficAnalytics from "./TrafficAnalytics";
import SalesAnalytics from "./SalesAnalytics";
import PeopleAnalytics from "./PeopleAnalytics";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  marginTop20: {
    marginTop: 20,
  },
  marginTop24: {
    marginTop: 24,
  },
  tabs: {
    minWidth: "16%",
    fontWeight: "bold",
    textTransform: "capitalize",
    opacity: "100%",
    fontSize: 14,
  },
  modal: {
    zIndex: "1400 !important",
  },
  paper: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
}));

const TAB_NAMES = ["Traffic", "Sales", "People"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export default function StorefrontAnalytics() {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [tabDrawer, setTabDrawer] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Traffic");
  const [days, setDays] = useState("ALL_DAYS");
  const [clients, setClients] = useState("ALL_CLIENTS");

  const handleClientsChange = (event) => {
    setClients(event.target.value);
  };
  const handleChange = (event) => {
    setDays(event.target.value);
  };

  const moreActionsList = () => (
    <div
      className={classes.fullList}
      role="presentation"
      onClick={() => {
        setTabDrawer(false);
      }}
      onKeyDown={() => {
        setTabDrawer(false);
      }}
    >
      <Grid container item xs={12} style={{ padding: 16 }}>
        <Grid item xs={10}>
          <Typography variant="subtitle1" className={Styles.navyBlueColorForce}>
            More Information
          </Typography>
        </Grid>
        <Grid container item xs={2} justifyContent="flex-end">
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              setTabDrawer(false);
            }}
          />
        </Grid>
      </Grid>
      <Divider />
      <List>
        {TAB_NAMES.map((tab, index) => (
          <ListItem
            key={index}
            onClick={() => {
              setSelectedTab(tab);
              setActiveTab(index);
            }}
          >
            <ListItemText
              primary={tab}
              className={Styles.navyBlueColorForce}
              style={{ fontSize: 14 }}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const daysFilter = () => {
    return (
      <Grid
        container
        item
        xs={12}
        md={2}
        alignItems="center"
        justifyContent="flex-end"
        style={{ paddingRight: 16, cursor: "pointer" }}
      >
        <FormControl>
          <Select value={days} onChange={handleChange} displayEmpty>
            <MenuItem value={"ALL_DAYS"}>All Days</MenuItem>
            <MenuItem value={"THIRTY_DAYS"}>Last 30 Days</MenuItem>
            <MenuItem value={"NINETY_DAYS"}>Last 90 Days</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    );
  };
  return (
    <Grid container>
      <SwipeableDrawer
        classes={{ modal: classes.modal, paper: classes.paper }}
        anchor="bottom"
        open={tabDrawer}
        onClose={() => {
          setTabDrawer(false);
        }}
        onOpen={() => {
          setTabDrawer(true);
        }}
      >
        {tabDrawer && moreActionsList()}
      </SwipeableDrawer>
      <Grid item xs={12} style={{ marginTop: 8 }}>
        {isMobileDevice() ? (
          <React.Fragment>
            {daysFilter()}
            <Button
              fullWidth
              variant="outlined"
              className={Styles.navyBlueColorForce}
              style={{
                textTransform: "capitalize",
                marginTop: 12,
                padding: 12,
              }}
              onClick={() => setTabDrawer(true)}
              endIcon={<ExpandMoreIcon />}
            >
              {selectedTab}
            </Button>
          </React.Fragment>
        ) : (
          <Paper
            elevation={0}
            style={{
              marginRight: !isMobileDevice() && !isTabletDevice() && 20,
            }}
          >
            <Grid container item xs={12}>
              <Grid item xs={activeTab === 2 ? 8 : 10}>
                <Tabs
                  value={activeTab}
                  aria-label="storefront-management-tabs"
                  classes={{ indicator: Styles.indicator }}
                >
                  {TAB_NAMES.map((tab, index) => (
                    <Tab
                      key={index}
                      label={`${tab}`}
                      className={clsx(classes.tabs, {
                        [Styles.primaryTxtAccentForce]: index === activeTab,
                        [Styles.navyBlueColorForce]: index !== activeTab,
                      })}
                      onClick={() => {
                        setActiveTab(index);
                        setSelectedTab(tab);
                      }}
                    />
                  ))}
                </Tabs>
              </Grid>
              {activeTab === 2 && (
                <Grid
                  container
                  item
                  xs={2}
                  alignItems="center"
                  justifyContent="flex-end"
                  style={{ cursor: "pointer" }}
                >
                  <FormControl>
                    <Select
                      value={clients}
                      onChange={handleClientsChange}
                      displayEmpty
                    >
                      <MenuItem value={"ALL_CLIENTS"}>All Clients</MenuItem>
                      <MenuItem value={"MY_CLIENTS"}>My Clients</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {daysFilter()}
            </Grid>
            {isTabletDevice() && <Divider />}
          </Paper>
        )}
      </Grid>
      <Grid item xs={12}>
        <TabPanel
          value={activeTab}
          index={0}
          style={{
            paddingBottom: (isMobileDevice() || isTabletDevice()) && 100,
          }}
        >
          <TrafficAnalytics days={days} />
        </TabPanel>

        <TabPanel
          value={activeTab}
          index={1}
          style={{
            paddingBottom: (isMobileDevice() || isTabletDevice()) && 100,
          }}
        >
          <SalesAnalytics days={days} />
        </TabPanel>

        <TabPanel
          value={activeTab}
          index={2}
          style={{
            paddingBottom: (isMobileDevice() || isTabletDevice()) && 100,
          }}
        >
          <PeopleAnalytics clients={clients} days={days} />
        </TabPanel>
      </Grid>
    </Grid>
  );
}
