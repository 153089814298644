import {
  Divider,
  Grid,
  Button,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  Checkbox,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { filter } from "lodash";
import ConfirmationDialog from "../../ConfirmationDialog";
import * as moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "20px",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    textTransform: "capitalize",
  },
}));
export default function CurrentAddress({
  newHomeAddress,
  provinces,
  address,
  saveAddress,
  updateAddress,
  resetAddress,
  clickEdit,
  allowReset,
  propertyTypes,
}) {
  const classes = useStyles();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  return (
    <>
      <Grid item className={classes.root} id="current_address">
        <ConfirmationDialog
          open={openConfirmationDialog}
          handleYes={() => {
            resetAddress();
            setOpenConfirmationDialog(false);
          }}
          handleNo={() => {
            setOpenConfirmationDialog(false);
          }}
          sectionTitle={"Do you want to reset your Current Address?"}
        />
        {address && address.editMode ? (
          <Grid container item xs={12}>
            <Grid container item xs={12} justifyContent="flex-end">
              <IconButton
                color="default"
                aria-label="Edit Address"
                component="span"
                onClick={() => {
                  clickEdit();
                }}
              >
                <EditIcon />
              </IconButton>
            </Grid>
            <Grid
              container
              item
              xs={12}
              direction="column"
              style={{ paddingLeft: 20 }}
            >
              <Typography>
                {address.streetNumber} {address.streetName}
              </Typography>
              <Typography>
                {address.province && address.province.name}{" "}
                {address.city.name === "Other"
                  ? address.otherCity
                  : address.city.name}
              </Typography>
              <Typography>
                {address.county} {address.postalCode}
              </Typography>
              {address && address.propertyType ? (
                <Grid container alignItems="center">
                  <Typography variant="subtitle1" style={{ marginRight: 10 }}>
                    Property Type:
                  </Typography>
                  <Typography variant="body1">
                    {address &&
                      address.propertyType &&
                      address.propertyType.name}
                  </Typography>
                </Grid>
              ) : null}
              {address && address.closingDate ? (
                <Grid container alignItems="center">
                  <Typography variant="subtitle1" style={{ marginRight: 10 }}>
                    Closing Date:
                  </Typography>
                  <Typography variant="body1">
                    {moment(address.closingDate).format("MM/DD/YYYY")}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid container item xs={12} style={{ padding: "10px 0px" }}>
              <Grid
                container
                item
                xs={12}
                spacing={2}
                style={{ padding: "0px 10px" }}
              >
                <Grid item xs={6}>
                  <TextField
                    autoComplete="new-password"
                    variant="outlined"
                    fullWidth
                    aria-label="Stree number Input"
                    label="Address Line 1"
                    required
                    value={address && address.streetNumber}
                    inputProps={{
                      maxLength: 100,
                    }}
                    onChange={(event) => {
                      updateAddress({
                        streetNumber: event.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    autoComplete="new-password"
                    variant="outlined"
                    fullWidth
                    aria-label="Street Name Input"
                    label="Address Line 2"
                    value={address && address.streetName}
                    inputProps={{
                      maxLength: 100,
                    }}
                    onChange={(event) => {
                      updateAddress({ streetName: event.target.value });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                spacing={2}
                style={{ padding: "0px 10px" }}
              >
                <Grid item xs={6}>
                  <Autocomplete
                    fullWidth
                    value={(address && address.province) || null}
                    options={provinces || []}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => {
                      return option.name === value.name;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="State/Province"
                        required
                        variant="outlined"
                        autoComplete="new-password"
                      />
                    )}
                    required
                    onChange={(event, value) => {
                      updateAddress({ province: value });
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    fullWidth
                    noOptionsText={"Please select province first"}
                    value={(address && address.city) || null}
                    options={
                      address && address.province && address.province.cityDTO
                        ? filter(
                            // Replace All cities by Other.
                            address.province.cityDTO,
                            (city) =>
                              (city.name =
                                city.name === "All Cities"
                                  ? "Other"
                                  : city.name)
                          )
                        : []
                    }
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => {
                      return option.name === value.name;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="City"
                        required
                        variant="outlined"
                        autoComplete="new-password"
                      />
                    )}
                    required
                    onChange={(event, value) => {
                      updateAddress({ city: value });
                    }}
                  />
                </Grid>
              </Grid>
              {address && address.city && address.city.id === 1739 && (
                <Grid
                  container
                  item
                  xs={12}
                  spacing={2}
                  style={{ padding: "0px 10px" }}
                >
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={address && address.otherCity}
                      aria-label="Other City"
                      label="Other City"
                      autoComplete="new-password"
                      inputProps={{
                        maxLength: 256,
                      }}
                      onChange={(event) => {
                        updateAddress({ otherCity: event.target.value });
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid
                container
                item
                xs={12}
                spacing={2}
                style={{ padding: "0px 10px" }}
              >
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    aria-label="Country Input"
                    label="Country"
                    value={address && address.county}
                    inputProps={{
                      maxLength: 256,
                    }}
                    autoComplete="new-password"
                    onChange={(event) => {
                      updateAddress({ county: event.target.value });
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    required
                    aria-label="Postal Code Input"
                    label="Postal Code"
                    value={address && address.postalCode}
                    inputProps={{
                      maxLength: 15,
                    }}
                    autoComplete="new-password"
                    onChange={(event) => {
                      updateAddress({ postalCode: event.target.value });
                    }}
                  />
                </Grid>
              </Grid>
              {newHomeAddress && (
                <>
                  <Divider
                    orientation="horizontal"
                    variant="fullWidth"
                    style={{ width: "100%", marginTop: 10, marginBottom: 10 }}
                  />
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    style={{ marginTop: 5, padding: "0px 10px" }}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      style={{ paddingLeft: 16 }}
                    ></Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        options={propertyTypes || []}
                        value={address && address.propertyType}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Property Type"
                            required
                            variant="outlined"
                            autoComplete="new-password"
                          />
                        )}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => {
                          return option.name === value.name;
                        }}
                        onChange={(event, value) => {
                          updateAddress({ propertyType: value });
                        }}
                      />
                    </Grid>
                    {address &&
                      address.propertyType &&
                      address.propertyType.name === "Other" && (
                        <Grid item xs={6}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            value={address && address.otherPropertyType}
                            aria-label="Other Property Type"
                            label="Other Property Type"
                            inputProps={{
                              maxLength: 256,
                            }}
                            autoComplete="new-password"
                            onChange={(event) => {
                              updateAddress({
                                otherPropertyType: event.target.value,
                              });
                            }}
                          />
                        </Grid>
                      )}
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    direction="column"
                    style={{ marginTop: 5, padding: "0px 10px" }}
                  >
                    <Grid item xs={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          disableToolbar
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          id="date-picker-inline"
                          label="Closing Date"
                          value={(address && address.closingDate) || null}
                          autoOk={true}
                          onChange={(event) => {
                            updateAddress({
                              closingDate: event,
                            });
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          minDate={new Date()}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid container item xs={6} alignItems="center">
                      <Checkbox
                        color={Styles.navyBlueColorForce}
                        checked={address && address.firstTimeHomeBuyer}
                        onChange={(event) => {
                          updateAddress({
                            firstTimeHomeBuyer: event.target.checked,
                          });
                        }}
                      />
                      <Typography
                        variant="subtitle1"
                        className={Styles.navyBlueColor}
                      >
                        First-time Home Buyer
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid
                item
                container
                xs={12}
                justifyContent="flex-end"
                spacing={1}
                style={{ marginTop: 10, padding: "0px 10px" }}
              >
                <Grid item>
                  <Button
                    disabled={!allowReset}
                    variant="outlined"
                    className={clsx(Styles.navyBlueColorForce, classes.button)}
                    onClick={() => {
                      setOpenConfirmationDialog(true);
                    }}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
