import { useDispatch } from "react-redux";
import { RENAME_CATEGORY } from "../../souqh-redux/reducers/actionConstants";
import { useState, useEffect } from "react";
import { useApiClient } from "../useApiClient";
import { color_success } from "../toasterColors";

export const useRenameCategory = (categories) => {
  const { apiClient } = useApiClient();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [renamedCategory, setRenamedCategory] = useState("");
  const [validation, setValidation] = useState(null);

  const [customCategories, setCustomCategories] = useState([]);
  useEffect(() => {
    if (categories && categories.length) {
      setCustomCategories(categories.filter((category) => !category.isDefault));
    }
  }, [categories]);
  const oldCategory =
    customCategories.find((ele) => ele.id === selectedCategory) &&
    customCategories.find((ele) => ele.id === selectedCategory).name;

  const dispatch = useDispatch();
  const renameExistingCategory = (onResult) => {
    if (!validation) {
      dispatch({ type: "SET_LOADING", value: true });
      return apiClient
        .put("/category", {
          categoryId: selectedCategory,
          name: renamedCategory,
        })
        .then((res) => {
          dispatch({
            type: RENAME_CATEGORY,
            payload: res.data.result,
          });
          dispatch({
            type: "SET_TOASTER",
            payload: {
              message: `You have renamed category -${oldCategory} from ${oldCategory} to ${renamedCategory}`,
              bgColor: color_success,
              open: true,
            },
          });
          onResult && onResult(res);
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  let duplicate;
  const checkDuplicate = (event) => {
    duplicate = categories.find(
      (e) => e.name.toUpperCase() === event.target.value.toUpperCase().trim()
    );
    if (duplicate && event.target.value) {
      setValidation(true);
    } else {
      setValidation(null);
    }
  };

  return {
    renameExistingCategory,
    renamedCategory,
    setRenamedCategory,
    checkDuplicate,
    validation,
    setSelectedCategory,
    customCategories,
    selectedCategory,
  };
};
