import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";
import SearchIcon from "@material-ui/icons/Search";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import clsx from "clsx";
import { cloneDeep } from "lodash-es";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { isMobileDevice, isTabletDevice } from "../../utils/AppUtils";

const styles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined": {
      top: -8,
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px",
    },
    "& .multline-txt .MuiOutlinedInput-input": {
      padding: "0px",
    },
  },
  dialogPaper: {
    height:
      isTabletDevice() || isMobileDevice()
        ? window.innerHeight - 200
        : window.innerHeight,
    borderRadius: 0,
  },
}));
export default function BrodcastMessageDialog({
  openBroadCastMessageDialog,
  setOpenBroadCastMessageDialog,
  channelList,
  onSendBroadcastMessage,
}) {
  const classes = styles();

  const [searchText, setSearchText] = useState("");
  const [messageText, setMessageText] = useState("");
  const [userListToBroadCast, setUserListToBroadCast] = useState([]);
  useEffect(() => {
    if (openBroadCastMessageDialog) {
      setUserListToBroadCast(
        cloneDeep(channelList).filter((item) => item.Username)
      );
    } else {
      setUserListToBroadCast([]);
    }
  }, [openBroadCastMessageDialog]);

  const handleDialogClose = () => {
    setSearchText("");
    setMessageText("");
    setOpenBroadCastMessageDialog(false);
  };

  const handleSelectionChange = (value, item) => {
    const tempList = cloneDeep(userListToBroadCast);
    const findItem = tempList.find(
      (elem) => elem.Username && elem.Username === item.Username
    );
    if (findItem) {
      findItem.selected = value;
    }
    setUserListToBroadCast(tempList);
  };
  const txt = (searchText || "").toLowerCase().trim();
  const allUsersList = userListToBroadCast
    ? userListToBroadCast
        .filter((elem) => (elem.fullName || "").toLowerCase().indexOf(txt) >= 0)
        .map((item, index) => {
          return (
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              style={{ padding: "4px 0px" }}
              key={index}
            >
              <Grid item xs={1}>
                <Checkbox
                  checked={item.selected || false}
                  onChange={(event) => {
                    handleSelectionChange(event.target.checked, item);
                  }}
                  size="small"
                  value={item.selected}
                  name="radio-button-demo"
                  className={"sqBlueRadio"}
                  color={Styles.navyBlueColorForce}
                />
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  padding: "0px 12px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingLeft: isMobileDevice() && 24,
                }}
              >
                {item.profilePhoto ? (
                  <Avatar
                    src={item.profilePhoto}
                    style={{ width: 32, height: 32, borderRadius: 4 }}
                  />
                ) : (
                  <AccountBoxIcon
                    style={{ fontSize: 40, fill: "#0e1c4e", opacity: 0.3 }}
                  />
                )}
              </Grid>
              <Grid item xs={9}>
                <Typography
                  variant="body1"
                  title={item.fullName}
                  style={{ width: "95%", paddingLeft: isMobileDevice() && 10 }}
                  className={clsx(Styles.navyBlueColor, Styles.sqEllipsis)}
                >
                  {item.fullName || item.Username}
                </Typography>
              </Grid>
            </Grid>
          );
        })
    : null;

  const getSelectedUsers = () => {
    return userListToBroadCast.filter((item) => item.selected);
  };

  return (
    <Dialog
      fullWidth
      disableBackdropClick
      maxWidth={"xs"}
      open={openBroadCastMessageDialog}
      onClose={() => {
        handleDialogClose();
      }}
      classes={{ paper: classes.dialogPaper, root: classes.root }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Broadcast Message</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ paddingTop: 8, overflow: "hidden" }}>
        <Grid
          item
          xs={12}
          style={{
            marginBottom: 20,
            display: "flex",
            alignItems: "center",
            fontSize: 12,
            color: "rgb(80, 101, 217)",
          }}
        >
          <InfoOutlinedIcon style={{ fontSize: 14, marginRight: 4 }} />
          <span>Broadcasting a message may take more time to deliver.</span>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 16 }}>
          <TextField
            fullWidth
            placeholder="Write message to broadcast"
            variant="outlined"
            multiline={true}
            rows={3}
            value={messageText}
            className="multline-txt"
            onChange={(e) => {
              setMessageText(e.target.value);
            }}
            inputProps={{
              maxLength: 500,
            }}
          />
        </Grid>
        <Typography
          variant="subtitle1"
          className={Styles.navyBlueColor}
          style={{ marginBottom: 8 }}
        >
          Select contacts to broadcast message
        </Typography>
        <Grid item xs={12} style={{ position: "relative", marginBottom: 8 }}>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Search Members"
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
            }}
          />
          <SearchIcon
            style={{
              marginTop: 10,
              paddingRight: 10,
              width: 24,
              height: 24,
              position: "absolute",
              right: 0,
              fill: "#0e1c4e",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ height: "calc(100% - 220px)", overflow: "auto" }}
        >
          {allUsersList}
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: "16px 24px" }}>
        <Button
          variant="outlined"
          style={{
            padding: "6px 16px",
            marginRight: 12,
            textTransform: "capitalize",
          }}
          className={Styles.navyBlueColorForce}
          onClick={() => {
            handleDialogClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={
            !(messageText || "").trim() || getSelectedUsers().length < 1
          }
          onClick={() => {
            if (getSelectedUsers().length > 1) {
              onSendBroadcastMessage(
                (messageText || "").trim(),
                getSelectedUsers(),
                () => {
                  handleDialogClose();
                }
              );
            }
          }}
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize", padding: "6px 32px" }}
        >
          Broadcast
        </Button>
      </DialogActions>
    </Dialog>
  );
}
