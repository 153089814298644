import {
  Avatar,
  Button,
  ButtonBase,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";
import ShareWithSPSection from "./ShareWithSPSection";
import SignOrderRow from "./SignOrderRow";
import { useAddTemplate } from "souqh-react-redux-hooks/serviceProvider/documents/useAddTemplate";

const styles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined": {
      top: -6,
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px",
    },
  },
}));
export default function SignOrderMainPanel({
  setAddContactDialog,
  associactedServiceProviders,
  sharedWithSP,
  setSharedWithSP,
  signOrders,
  signOrdersData,
  updateSignOrderChange,
  updateEmailTouchedState,
  updateEmailValue,
  selectedContact,
  SPContacts,
  handleAssocSPSelect,
  primaryApplicantList,
}) {
  const classes = styles();

  return (
    <Grid
      item
      xs={12}
      className={classes.root}
      style={{ padding: "24px 0px 10px 20px ", height: "100%" }}
    >
      <Grid item container xs={12} alignItems="center">
        <Typography variant="subtitle1" className={clsx(Styles.navyBlueColor)}>
          Sign Order
        </Typography>
      </Grid>
      <Divider style={{ width: "100%", margin: "10px 0px" }} />

      <Grid
        item
        xs={12}
        style={{
          height: "calc(100% - 210px)",
          overflow: "auto",
        }}
      >
        {signOrders.length && signOrdersData.length
          ? signOrdersData.map((signData, index) => {
              return (
                <SignOrderRow
                  signOrders={signOrders}
                  signData={signData}
                  index={index}
                  key={index}
                  updateSignOrderChange={updateSignOrderChange}
                  updateEmailTouchedState={updateEmailTouchedState}
                  updateEmailValue={updateEmailValue}
                  SPContacts={SPContacts}
                  primaryApplicantList={primaryApplicantList}
                />
              );
            })
          : null}
      </Grid>

      <Grid item xs={12}>
        {selectedContact ? (
          <Divider style={{ width: "100%", marginTop: 10, marginBottom: 10 }} />
        ) : null}
      </Grid>

      {selectedContact ? (
        <Grid item xs={12} style={{ height: "auto" }}>
          <ShareWithSPSection
            sharedWithSP={sharedWithSP}
            setSharedWithSP={setSharedWithSP}
            setAddContactDialog={setAddContactDialog}
            associactedServiceProviders={associactedServiceProviders}
            handleAssocSPSelect={handleAssocSPSelect}
          ></ShareWithSPSection>
        </Grid>
      ) : null}
    </Grid>
  );
}
