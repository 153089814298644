import React from "react";
import { Grid, Chip, Avatar } from "@material-ui/core";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import {
  ADVANCED,
  HOME_BUYER_USER,
  PREMIUM,
  TEAM_MEMBER_USER,
} from "./ServiceProvider/serviceProviderConstants";
import { isTabletDevice } from "../utils/AppUtils";
import { useSelector } from "react-redux";

export default function DashboardChip({ xlUp }) {
  const user = useAuthUser();
  let name =
    user.userType === TEAM_MEMBER_USER && user.subscriptionPlanName !== PREMIUM && user.subscriptionPlanName !== ADVANCED
      ? user.fullName.split(" ")[0]
      : user.firstName;
  let storeFrontDetails = useSelector((state) => state.storefront);

  return (
    <Grid
      container
      item
      xs={12}
      style={{ marginBottom: isTabletDevice() ? 72 : 20 }}
    >
      <Chip
        style={{
          backgroundColor: "white",
          color: "#17174C",
          fontSize: xlUp ? 24 : 20,
          fontWeight: "bold",
          marginBottom: 10,
        }}
        label={`Welcome Back, ${name}`}
        avatar={
          user.userType === HOME_BUYER_USER ? (
            <Avatar src={user.avatarUrl || "/broken-image.jpg"} />
          ) : (
            <Avatar
              src={storeFrontDetails.displayPicture || "/broken-image.jpg"}
              style={{ width: 25, height: 25 }}
            />
          )
        }
      />
    </Grid>
  );
}
