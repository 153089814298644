import { Grid, Typography, Chip } from "@material-ui/core";
import React, { useState } from "react";
import Styles from "../../../Styles.module.css";
import { cloneDeep } from "lodash";
import SeeMoreDoc from "./SeeMoreDoc";
import { isMarketplacePlan } from "../../../utils/AppUtils";
export default function Attachments({
  attachments,
  setDocumentItem,
  getUploadedDocuments,
  setOpenDocumetDialog,
  limit,
  isDashboardWidget,
  setDownloadUrl,
  setDocCategoryId,
  setDocNameForDownload,
  setDocUserId,
  userInfo,
  setOpenPlanUpgradeMsgDialog,
}) {
  const [openSeeMoreDocPopup, setOpenSeeMoreDocPopup] = useState(false);
  let originalList = cloneDeep(attachments);
  const temp =
    originalList.length > limit ? originalList.splice(limit) : originalList;
  return (
    <>
      <SeeMoreDoc
        docList={temp}
        openSeeMoreDocPopup={openSeeMoreDocPopup}
        setOpenSeeMoreDocPopup={setOpenSeeMoreDocPopup}
        setDocumentItem={setDocumentItem}
        getUploadedDocuments={getUploadedDocuments}
        setOpenDocumetDialog={setOpenDocumetDialog}
        setDownloadUrl={setDownloadUrl}
        setDocCategoryId={setDocCategoryId}
        setDocNameForDownload={setDocNameForDownload}
        setDocUserId={setDocUserId}
      />
      <Grid container>
        {originalList.map((doc, index) => (
          <Chip
            item
            key={index}
            style={{
              fontSize: isDashboardWidget ? 11 : 14,
              marginTop: 10,
              marginRight: 5,
              textOverflow: "ellipsis",
              marginLeft: 5,
              // width: "90%", //To add elipsis to chip
            }}
            label={doc.documentName}
            onClick={() => {
              if (userInfo && isMarketplacePlan(userInfo)) {
                setOpenPlanUpgradeMsgDialog &&
                  setOpenPlanUpgradeMsgDialog(true);
              } else {
                setDocumentItem(getUploadedDocuments(doc));
                setDownloadUrl(doc.documentUrl);
                setDocCategoryId && setDocCategoryId(doc.categoryId);
                setDocNameForDownload && setDocNameForDownload(doc.fileName);
                setOpenDocumetDialog(true);
                setDocUserId && setDocUserId(doc.userId);
              }
            }}
          />
        ))}
        {attachments.length > limit ? (
          <Typography
            className={Styles.navyBlueColor}
            variant="body1"
            style={{
              marginTop: 15,
              cursor: "pointer",
              fontSize: isDashboardWidget ? 11 : 14,
            }}
            onClick={() => {
              setOpenSeeMoreDocPopup(true);
            }}
          >
            +{temp.length}
          </Typography>
        ) : null}
      </Grid>
    </>
  );
}
