import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 150,
  },
}));

function ServiceRequestFulfilledDialog({
  openServiceRequestFulfilledDialog,
  setOpenServiceRequestFulfilledDialog,
}) {
  const classes = useStyles();
  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={openServiceRequestFulfilledDialog}
      onClose={() => {
        setOpenServiceRequestFulfilledDialog(false);
      }}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogContent>
        <Grid item xs={12} style={{ paddingBottom: 16 }}>
          <Typography variant="body1" className={Styles.navyBlueColor}>
            Service Request Already Fulfilled
          </Typography>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          className={Styles.navyBlueColorForce}
          style={{ textTransform: "capitalize", marginRight: 8 }}
          onClick={() => {
            setOpenServiceRequestFulfilledDialog(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ServiceRequestFulfilledDialog;
