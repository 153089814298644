import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";
import SPDetailsDocumentsRow from "./SPDetailsDocumentsRow";
import RenameDocumentDialog from "../../DocumentManagementDialogs/RenameDocumentDialog";
import DeleteDocumentDialog from "../../DocumentManagementDialogs/DeleteDocumentDialog";
import MoveDocumentDialog from "../../DocumentManagementDialogs/MoveDocumentDialog";
import { useDocuments } from "souqh-react-redux-hooks/homeBuyer/useDocuments";
import { ACTION_REQUIRED } from "../homeBuyerConstants";

export default function SPDetailsDocuments({
  individualSPDocuments,
  refreshSPDocuments,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const openPopover = Boolean(anchorEl);

  const { categories, getTabWiseCategoriesList } = useDocuments();

  useEffect(() => {
    getTabWiseCategoriesList(ACTION_REQUIRED);
  }, []);

  const [openDeleteDocumentDialog, setOpenDeleteDocumentDialog] = useState({
    open: false,
    docDetails: {},
    categoryId: "",
  });

  const [openRenameDocumentDialog, setOpenRenameDocumentDialog] = useState({
    open: false,
    documentId: "",
    categoryId: "",
    documentName: "",
  });

  const [openMoveDocumentDialog, setOpenMoveDocumentDialog] = useState({
    open: false,
    documentName: "",
    documentId: "",
    sourceCategoryId: "",
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event, docId, signNowDocumentId) => {
    setAnchorEl(event.currentTarget);
    // setSelectedDocId(docId);
    // setSelectedSignNowDocId(signNowDocumentId);
  };

  return (
    <Grid container>
      <RenameDocumentDialog
        openRenameDocumentDialog={openRenameDocumentDialog.open}
        setOpenRenameDocumentDialog={setOpenRenameDocumentDialog}
        documentId={openRenameDocumentDialog.documentId}
        categoryId={openRenameDocumentDialog.categoryId}
        documentItem={openRenameDocumentDialog.docData}
        getTabWiseCategoriesList={null}
        documentTabName={null}
        documentName={openRenameDocumentDialog.documentName}
        refreshSPDocuments={refreshSPDocuments}
      />
      <DeleteDocumentDialog
        openDeleteDocumentDialog={openDeleteDocumentDialog.open}
        setOpenDeleteDocumentDialog={setOpenDeleteDocumentDialog}
        docDetails={openDeleteDocumentDialog.docDetails}
        categoryId={openDeleteDocumentDialog.categoryId}
        documentTabName={null}
        getTabWiseCategoriesList={null}
        refreshSPDocuments={refreshSPDocuments}
      />
      <MoveDocumentDialog
        openMoveDocumentDialog={openMoveDocumentDialog.open}
        setOpenMoveDocumentDialog={setOpenMoveDocumentDialog}
        documentName={openMoveDocumentDialog.documentName}
        documentItem={openMoveDocumentDialog.docData}
        categories={categories}
        documentId={openMoveDocumentDialog.documentId}
        sourceCategoryId={openMoveDocumentDialog.sourceCategoryId}
        getTabWiseCategoriesList={null}
        documentTabName={null}
      />
      <TableContainer
        component={Paper}
        elevation={0}
        style={{ marginBottom: isMobileDevice() ? 80 : 40 }}
      >
        {individualSPDocuments &&
        individualSPDocuments.documentList &&
        individualSPDocuments.documentList.length ? (
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    width: "22%",
                    minWidth: (isTabletDevice() || isMobileDevice()) && "15em",
                    fontWeight: 600,
                  }}
                  className={clsx(Styles.navyBlueColorForce, Styles.font14)}
                >
                  Document Name
                </TableCell>
                <TableCell
                  style={{
                    width: "36%",
                    minWidth: (isTabletDevice() || isMobileDevice()) && "13em",
                    fontWeight: 600,
                  }}
                  className={clsx(Styles.navyBlueColorForce, Styles.font14)}
                >
                  Description
                </TableCell>
                <TableCell
                  style={{
                    width: "16%",
                    minWidth: (isTabletDevice() || isMobileDevice()) && "9em",
                    fontWeight: 600,
                  }}
                  className={clsx(Styles.navyBlueColorForce, Styles.font14)}
                >
                  Status
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    width: "15%",
                    minWidth: (isTabletDevice() || isMobileDevice()) && "12em",
                    fontWeight: 600,
                  }}
                  className={clsx(Styles.navyBlueColorForce, Styles.font14)}
                >
                  Last Changed
                </TableCell>
                <TableCell
                  align="right"
                  className={Styles.navyBlueColorForce}
                  style={{
                    width: "1%",
                    minWidth: (isTabletDevice() || isMobileDevice()) && "2em",
                    fontWeight: 600,
                  }}
                >
                  {/* <MoreHorizIcon /> */}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {individualSPDocuments &&
              individualSPDocuments.documentList &&
              individualSPDocuments.documentList.length
                ? individualSPDocuments.documentList.map((data, index) => (
                    <SPDetailsDocumentsRow
                      key={index}
                      data={data}
                      handleClick={handleClick}
                      setSelectedRow={setSelectedRow}
                      categoryId={data.id}
                      selectedRow={selectedRow}
                      setOpenRenameDocumentDialog={setOpenRenameDocumentDialog}
                      openPopover={openPopover}
                      anchorEl={anchorEl}
                      handleClose={handleClose}
                      setOpenDeleteDocumentDialog={setOpenDeleteDocumentDialog}
                      selectedCategoryId={selectedCategoryId}
                      setSelectedCategoryId={setSelectedCategoryId}
                      setOpenMoveDocumentDialog={setOpenMoveDocumentDialog}
                    />
                  ))
                : null}
            </TableBody>
          </Table>
        ) : (
          <Typography
            className={Styles.navyBlueColor}
            style={{ fontWeight: "500", textAlign: "center" }}
          >
            No documents available
          </Typography>
        )}
      </TableContainer>
    </Grid>
  );
}
