let messageUpdateCallbacks = [];
let messagingSession = null;
const getMessageUpdateCallbacks = () => {
  return messageUpdateCallbacks;
};

const publishMessageUpdate = (message) => {
  let i = 0;
  for (i = 0; i < messageUpdateCallbacks.length; i += 1) {
    const callback = messageUpdateCallbacks[i];
    callback && callback(message);
  }
};

const messageObserver = {
  messagingSessionDidStart: () => {},
  messagingSessionDidStartConnecting: (reconnecting) => {},
  messagingSessionDidStop: (event) => {},
  messagingSessionDidReceiveMessage: (message) => {
    publishMessageUpdate(message);
  },
};

const addToUpdateCallBack = (callback) => {
  if (callback) {
    messageUpdateCallbacks.push(callback);
  }
};

const removeFromUpdateCallBack = (callback) => {
  if (callback) {
    const index = messageUpdateCallbacks.indexOf(callback);
    if (index !== -1) {
      messageUpdateCallbacks.splice(index, 1);
    }
  }
};

const resetMessageUpdateCallbacks = () => {
  messageUpdateCallbacks = [];
};

export default {
  getMessageUpdateCallbacks,
  addToUpdateCallBack,
  removeFromUpdateCallBack,
  resetMessageUpdateCallbacks,
  messageUpdateCallbacks,
  messageObserver,
};
