import { MenuItem, Paper, MenuList } from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  color_error,
  color_success,
} from "souqh-react-redux-hooks/toasterColors";
import Styles from "../../Styles.module.css";

export default function HBRowMenuOptions(props) {
  const {
    row,
    selelctedRow,
    categoryId,
    setOpenDeleteDocumentDialog,
    setOpenMoveDocumentDialog,
    setOpenRenameDocumentDialog,
    handleInnerClose,
    openInnerMenu,
    handleInnerListKeyDown,
    duplicateDocument,
  } = props;

  const allowDelete = row.signNowDocumentId
    ? row.allowVoid
      ? true
      : false
    : true;

  const dispatch = useDispatch();
  return (
    <Paper>
      <MenuList
        autoFocusItem={openInnerMenu}
        id="menu-list-grow"
        onKeyDown={handleInnerListKeyDown && handleInnerListKeyDown}
        className={Styles.navyBlueColor}
      >
        {allowDelete ? (
          <MenuItem
            onClick={() => {
              setOpenRenameDocumentDialog({
                open: true,
                documentId: selelctedRow.docId,
                categoryId,
                documentName: selelctedRow.docName,
                docData: selelctedRow,
              });
              handleInnerClose();
            }}
          >
            Rename
          </MenuItem>
        ) : null}

        {allowDelete ? (
          <MenuItem
            onClick={() => {
              // duplicateDocument(categoryId, selelctedRow);
              duplicateDocument(categoryId, selelctedRow, (res) => {
                if (res.data.status === 200 || res.data.status === 409) {
                  dispatch({
                    type: "SET_TOASTER",
                    payload: {
                      message: res.data.message,
                      bgColor:
                        res.data.status === 200 ? color_success : color_error,
                      open: true,
                    },
                  });
                }
              });
              handleInnerClose();
            }}
          >
            Duplicate
          </MenuItem>
        ) : null}

        <MenuItem
          onClick={() => {
            setOpenMoveDocumentDialog({
              open: true,
              documentName: selelctedRow.docName,
              documentId: selelctedRow.docId,
              sourceCategoryId: categoryId,
              docData: selelctedRow,
            });
            handleInnerClose();
          }}
        >
          Move
        </MenuItem>

        {allowDelete ? (
          <MenuItem
            style={{
              opacity: selelctedRow.docStatus === "waiting for sign" ? 0.5 : 1,
              cursor:
                selelctedRow.docStatus === "waiting for sign"
                  ? "default"
                  : "pointer",
            }}
            onClick={() => {
              if (selelctedRow.docStatus === "waiting for sign") {
                return;
              }
              if (selelctedRow.documentDelete) {
                setOpenDeleteDocumentDialog({
                  open: true,
                  docDetails: selelctedRow,
                  categoryId,
                });
                handleInnerClose();
              } else {
                dispatch({
                  type: "SET_TOASTER",
                  payload: {
                    message: `Cannot delete document`,
                    bgColor: color_error,
                    open: true,
                  },
                });
                handleInnerClose();
              }
            }}
          >
            Delete
          </MenuItem>
        ) : null}
      </MenuList>
    </Paper>
  );
}
