import {
  Grid,
  Typography,
  Divider,
  makeStyles,
  Container,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItem,
  List,
  ListItemText,
  Checkbox,
} from "@material-ui/core";
import React, { useEffect } from "react";
import Styles from "../../../Styles.module.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";
import ForecastedResult from "./ForecastedResult";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import LocationServed from "../../SPSetup/Profile/LocationServed";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { cloneDeep } from "lodash";

const useStyles = makeStyles((theme) => ({}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

let timer;

function debounce(func, timeout = 300) {
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

function NewCampaignStep1({
  section,
  campaignData,
  setStep1Data,
  dropDownValues,
  setDetectForcastedResult,
  displayMarketingData,
  validations,
  checkMarketingCampaignNameExists,
  nameAlreadyExist,
}) {
  const classes = useStyles();

  const handleChange = (value) => {
    const tempList = dropDownValues.userTypes;
    tempList.forEach((item) => {
      if (item.value === value) {
        item.selected = !item.selected;
      }
    });
    setStep1Data({
      field: "campaignUserType",
      value: tempList.filter((ele) => ele.selected),
    });
    setDetectForcastedResult((state) => state + 1);
  };

  const handleStageChange = (value) => {
    const tempList = dropDownValues.journeyStatusList;
    tempList.forEach((item) => {
      if (item.value === value) {
        item.selected = !item.selected;
      }
    });
    setStep1Data({
      field: "campaignStage",
      value: tempList.filter((ele) => ele.selected),
    });
    setDetectForcastedResult((state) => state + 1);
  };

  return (
    <React.Fragment>
      <Grid
        container
        item
        xs={12}
        className="hideScroll"
        style={{
          marginTop: !isMobileDevice() && !isTabletDevice() && 40,
          minHeight: (isMobileDevice() || isTabletDevice()) && "40rem",
          overflow: (isMobileDevice() || isTabletDevice()) && "hidden",
        }}
      >
        <Grid
          container
          item
          xs={12}
          lg={8}
          style={{
            height: (isMobileDevice() || isTabletDevice()) && 430,
            overflow: (isMobileDevice() || isTabletDevice()) && "scroll",
          }}
        >
          <Container maxWidth="md">
            <Grid container item xs={12}>
              <Grid
                item
                xs={12}
                style={{
                  marginTop: !isMobileDevice() && !isTabletDevice() && 20,
                }}
              >
                <Typography
                  variant="subtitle1"
                  className={Styles.navyBlueColor}
                  style={{ marginTop: 16 }}
                >
                  Campaign Name*
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={nameAlreadyExist || !!validations.name}
                  helperText={validations.name}
                  value={campaignData && campaignData.name}
                  id="campaignName"
                  placeholder="Type in your campaign name"
                  name="campaignName"
                  variant="outlined"
                  fullWidth
                  required
                  onChange={(e) => {
                    debounce(checkMarketingCampaignNameExists)(e.target.value);
                    setStep1Data({
                      field: "name",
                      value: campaignData.name.length
                        ? e.target.value
                        : e.target.value.trim(),
                    });
                  }}
                  onBlur={(e) => {
                    setStep1Data({
                      field: "name",
                      value: e.target.value.trim(),
                    });
                  }}
                  inputProps={{
                    maxLength: 500,
                  }}
                />
              </Grid>
              {nameAlreadyExist && (
                <Grid item xs={12}>
                  <Typography variant="body2" style={{ color: "#f44336" }}>
                    Campaign already exist. Please enter a different campaign
                    name
                  </Typography>
                </Grid>
              )}
              <LocationServed page="marketingCampaign"></LocationServed>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon className={Styles.navyBlueColor} />
                    }
                    style={{ marginTop: 16 }}
                  >
                    <Typography
                      variant="subtitle1"
                      className={Styles.navyBlueColor}
                    >
                      Optional Parameters
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container xs={12} item>
                      <Grid container xs={12} item>
                        <Grid item xs={12} md={6}>
                          <Typography
                            variant="subtitle1"
                            className={Styles.navyBlueColor}
                          >
                            Select User Type
                          </Typography>
                          <Grid container item xs={12}>
                            <List>
                              {dropDownValues &&
                                dropDownValues.userTypes &&
                                dropDownValues.userTypes.length &&
                                dropDownValues.userTypes.map(
                                  (userType, index) => (
                                    <ListItem
                                      key={index}
                                      style={{
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                      }}
                                    >
                                      <Checkbox
                                        checked={userType.selected || false}
                                        value={userType.value}
                                        onChange={(event) => {
                                          handleChange(event.target.value);
                                        }}
                                        color={Styles.navyBlueColor}
                                        style={{ paddingLeft: 0 }}
                                      />
                                      <ListItemText
                                        className={Styles.navyBlueColor}
                                      >
                                        {userType.name}
                                      </ListItemText>
                                    </ListItem>
                                  )
                                )}
                            </List>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography
                            variant="subtitle1"
                            className={Styles.navyBlueColor}
                          >
                            Select Stage
                          </Typography>
                          <Grid container item xs={12}>
                            <List>
                              {dropDownValues &&
                                dropDownValues.journeyStatusList &&
                                dropDownValues.journeyStatusList.length &&
                                dropDownValues.journeyStatusList.map(
                                  (stage, index) => (
                                    <ListItem
                                      key={index}
                                      style={{
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                      }}
                                    >
                                      <Checkbox
                                        checked={stage.selected || false}
                                        value={stage.value}
                                        onChange={(event) => {
                                          handleStageChange(event.target.value);
                                        }}
                                        color={Styles.navyBlueColor}
                                        style={{ paddingLeft: 0 }}
                                      />
                                      <ListItemText
                                        className={Styles.navyBlueColor}
                                      >
                                        {stage.value}
                                      </ListItemText>
                                    </ListItem>
                                  )
                                )}
                            </List>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container item xs={12} style={{ marginTop: 32 }}>
                        <Grid item xs={12}>
                          <Typography
                            style={{ fontSize: "0.625rem", fontWeight: 700 }}
                            className={Styles.navyBlueColor}
                          >
                            DEMOGRAPHIC
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider style={{ margin: "10px 16px 10px 0px" }} />
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Autocomplete
                              value={
                                (campaignData && campaignData.ageRange) || []
                              }
                              options={
                                (dropDownValues &&
                                  dropDownValues.ageRangeList) ||
                                []
                              }
                              getOptionLabel={(option) => option.value}
                              getOptionSelected={(option, value) => {
                                return option.value === value.value;
                              }}
                              onChange={(event, value) => {
                                setStep1Data({
                                  field: "ageRange",
                                  value: value,
                                });
                                setDetectForcastedResult((state) => state + 1);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Age Range"
                                  variant="outlined"
                                  autoComplete="new-password"
                                />
                              )}
                              multiple
                              limitTags={2}
                              disableCloseOnSelect
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                    color={Styles.navyBlueColorForce}
                                  />
                                  {option.value}
                                </React.Fragment>
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Autocomplete
                              value={
                                (campaignData && campaignData.maritalStatus) ||
                                []
                              }
                              options={
                                (dropDownValues &&
                                  dropDownValues.maritalStatusList) ||
                                []
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Marital Status"
                                  variant="outlined"
                                  autoComplete="new-password"
                                />
                              )}
                              getOptionLabel={(option) => option.name}
                              getOptionSelected={(option, value) => {
                                return option.value === value.value;
                              }}
                              onChange={(event, value) => {
                                setStep1Data({
                                  field: "maritalStatus",
                                  value: value,
                                });
                                setDetectForcastedResult((state) => state + 1);
                              }}
                              multiple
                              limitTags={2}
                              disableCloseOnSelect
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                    color={Styles.navyBlueColorForce}
                                  />
                                  {option.name}
                                </React.Fragment>
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12} style={{ marginTop: 40 }}>
                        <Grid item xs={12}>
                          <Typography
                            style={{ fontSize: "0.625rem", fontWeight: 700 }}
                            className={Styles.navyBlueColor}
                          >
                            FINANCIAL
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider
                            className={classes.divider}
                            style={{
                              margin: isMobileDevice()
                                ? "10px 0px"
                                : "10px 16px 10px 0px",
                            }}
                          />
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Autocomplete
                              value={
                                (campaignData &&
                                  campaignData.employmentStatus) ||
                                []
                              }
                              options={
                                (dropDownValues &&
                                  dropDownValues.employmentStatusList) ||
                                []
                              }
                              onChange={(event, value) => {
                                setStep1Data({
                                  field: "employmentStatus",
                                  value: value,
                                });
                                setDetectForcastedResult((state) => state + 1);
                              }}
                              getOptionLabel={(option) => option.name}
                              getOptionSelected={(option, value) => {
                                return option.value === value.value;
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Employment"
                                  variant="outlined"
                                  autoComplete="new-password"
                                />
                              )}
                              multiple
                              limitTags={2}
                              disableCloseOnSelect
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                    color={Styles.navyBlueColorForce}
                                  />
                                  {option.name}
                                </React.Fragment>
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Autocomplete
                              value={
                                (campaignData &&
                                  campaignData.houseHoldIncomeRange) ||
                                []
                              }
                              options={
                                (dropDownValues &&
                                  dropDownValues.householdIncomeRangeList) ||
                                []
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Household Income Range"
                                  variant="outlined"
                                  autoComplete="new-password"
                                />
                              )}
                              onChange={(event, value) => {
                                setStep1Data({
                                  field: "houseHoldIncomeRange",
                                  value: value,
                                });
                                setDetectForcastedResult((state) => state + 1);
                              }}
                              multiple
                              limitTags={2}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.value}
                              getOptionSelected={(option, value) => {
                                return option.value === value.value;
                              }}
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                    color={Styles.navyBlueColorForce}
                                  />
                                  {option.value}
                                </React.Fragment>
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12} style={{ marginTop: 40 }}>
                        <Grid
                          item
                          container
                          xs={12}
                          spacing={2}
                          style={{ marginBottom: 30 }}
                        >
                          <Grid item xs={12} md={6}>
                            <Autocomplete
                              value={
                                (campaignData && campaignData.propertyType) ||
                                []
                              }
                              options={
                                (dropDownValues &&
                                  dropDownValues.propertyTypeList) ||
                                []
                              }
                              onChange={(event, value) => {
                                setStep1Data({
                                  field: "propertyType",
                                  value: value,
                                });
                                setDetectForcastedResult((state) => state + 1);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Property Type"
                                  variant="outlined"
                                  autoComplete="new-password"
                                />
                              )}
                              multiple
                              limitTags={2}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.value}
                              getOptionSelected={(option, value) => {
                                return option.value === value.value;
                              }}
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                    color={Styles.navyBlueColorForce}
                                  />
                                  {option.value}
                                </React.Fragment>
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Autocomplete
                              value={
                                (campaignData && campaignData.industry) || []
                              }
                              options={
                                (dropDownValues &&
                                  dropDownValues.industryList) ||
                                []
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Industry "
                                  variant="outlined"
                                  autoComplete="new-password"
                                />
                              )}
                              onChange={(event, value) => {
                                setStep1Data({
                                  field: "industry",
                                  value: value,
                                });
                                setDetectForcastedResult((state) => state + 1);
                              }}
                              multiple
                              limitTags={2}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.name}
                              getOptionSelected={(option, value) => {
                                return option.name === value.name;
                              }}
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                    color={Styles.navyBlueColorForce}
                                  />
                                  {option.name}
                                </React.Fragment>
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <ForecastedResult section={section} campaignData={campaignData} />
      </Grid>
    </React.Fragment>
  );
}

export default NewCampaignStep1;
