import {
  Divider,
  Grid,
  makeStyles,
  Typography,
  Button,
  Chip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";
import React, { useEffect } from "react";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { SocialIcon } from "react-social-icons";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { isEmpty } from "lodash";
import { useStorefrontReview } from "souqh-react-redux-hooks/review/useStorefrontReview";
import Styles from "../../../Styles.module.css";
import { useService } from "souqh-react-redux-hooks/serviceProvider/storefront/useServices";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    padding: "16px 22px",
    color: "#0e1c4e",
  },
  marginTop: {
    marginTop: 20,
  },
  marginRight: {
    marginRight: 10,
  },
  marginLeft: {
    marginLeft: 10,
  },
  cursorPointer: {
    cursor: "pointer",
  },
  marginBottom: {
    marginBottom: 10,
  },
  webPresenceBox: {
    border: "1px solid #17174C1F",
    borderRadius: 4,
    marginBottom: 4,
  },
  heading: {
    fontWeight: 600,
  },
  dataText: {
    fontStyle: "italic",
  },
}));

export default function StoreFrontReview() {
  const classes = useStyles();
  let history = useHistory();
  const { getStorefrontDetails, storeFrontDetails } = useStorefrontReview();
  const { getFormattedFileName } = useService();
  const {
    creditCardInfo,
    paypalInfo,
    wireTransferInfo,
    eTransferInfo,
    chequeInfo,
    otherInfo,
  } = storeFrontDetails.customerPayments;
  useEffect(() => {
    getStorefrontDetails();
  }, []);
  const isPaymentVisible =
    (storeFrontDetails.paymentsVisible &&
      !Object.keys(creditCardInfo.config).length === 0) ||
    !Object.keys(paypalInfo.config).length === 0 ||
    !Object.keys(wireTransferInfo.config).some(
      (key) => wireTransferInfo.config[key] === ""
    ) ||
    !Object.keys(eTransferInfo.config).some(
      (key) => eTransferInfo.config[key] === ""
    ) ||
    !Object.keys(chequeInfo.config).some(
      (key) => chequeInfo.config[key] === ""
    ) ||
    (otherInfo.config.length &&
      !Object.keys(otherInfo.config[0]).some(
        (key) => otherInfo.config[0][key] === ""
      ));
  return (
    <Grid container className={`${classes.root} ${classes.marginTop}`}>
      <Grid item xs={12} className={classes.marginTop}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h4">Create Storefront</Typography>
          </Grid>
          <Grid item>
            <Grid container className={classes.cursorPointer}>
              <Button
                style={{ color: "#f38282", textTransform: "capitalize" }}
                onClick={() => {
                  history.push("/setup/storefront");
                }}
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Divider className={classes.marginTop} />
      </Grid>
      <Grid item xs={12} className={classes.marginTop}>
        <Typography variant="h6">Brand and Theme</Typography>
      </Grid>
      <Grid item xs={12} className={classes.marginTop}>
        <Typography variant="subtitle1">Storefront Name</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          {storeFrontDetails.storefrontName}
        </Typography>
      </Grid>
      {storeFrontDetails.displayPicture && (
        <>
          <Grid item xs={12} className={classes.marginTop}>
            <Typography variant="subtitle1">Display Picture</Typography>
          </Grid>
          <Grid
            item
            style={{ width: 154, height: 154 }}
            className={classes.webPresenceBox}
          >
            <img
              alt="/images/logo.svg"
              style={{ width: 154, height: 154 }}
              src={storeFrontDetails.displayPicture}
            />
          </Grid>
        </>
      )}
      {storeFrontDetails.bannerPicture && (
        <>
          <Grid item xs={12} className={classes.marginTop}>
            <Typography variant="subtitle1">Banner</Typography>
          </Grid>
          <Grid
            item
            style={{ width: 433, height: 154 }}
            className={classes.webPresenceBox}
          >
            <img
              alt="/images/logo.svg"
              style={{ width: 433, height: 154 }}
              src={storeFrontDetails.bannerPicture}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} className={classes.marginTop}>
        <Typography variant="subtitle1">Colour Theme</Typography>
      </Grid>
      <Grid
        container
        item
        xs={5}
        className={clsx(classes.webPresenceBox, classes.marginRight)}
        alignItems="center"
      >
        <Grid
          item
          sm={3}
          style={{
            padding: 16,
            borderRadius: 4,
          }}
        >
          <Grid
            item
            style={{
              padding: 16,
              borderRadius: 4,
              backgroundColor: storeFrontDetails.primaryColor,
              height: 48,
              width: 48,
            }}
          ></Grid>
        </Grid>
        <Grid item sm={9} style={{ paddingLeft: 16 }}>
          <Typography variant="body1" className={classes.heading}>
            Primary Colour
          </Typography>
          <Typography variant="body1">
            Hex: {storeFrontDetails.primaryColor}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={5}
        className={clsx(classes.webPresenceBox, classes.marginRight)}
        alignItems="center"
      >
        <Grid
          item
          sm={3}
          style={{
            padding: 16,
            borderRadius: 4,
          }}
        >
          <Grid
            item
            style={{
              padding: 16,
              borderRadius: 4,
              backgroundColor: storeFrontDetails.secondaryColor,
              height: 48,
              width: 48,
            }}
          ></Grid>
        </Grid>
        <Grid item sm={9} style={{ paddingLeft: 16 }}>
          <Typography variant="body1" className={classes.heading}>
            Secondary Colour
          </Typography>
          <Typography variant="body1">
            Hex: {storeFrontDetails.secondaryColor}
          </Typography>
        </Grid>
      </Grid>
      {/* competeText */}
      {storeFrontDetails.competeText ? (
        <>
          <Grid item xs={12} className={classes.marginTop}>
            <Typography variant="subtitle1">
              What separates you from the competition?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {storeFrontDetails.competeText}
            </Typography>
          </Grid>
        </>
      ) : null}
      {/* companyOverview */}
      {storeFrontDetails.companyOverview ? (
        <>
          <Grid item xs={12} className={classes.marginTop}>
            <Typography variant="subtitle1">
              Company overview on storefront
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {storeFrontDetails.companyOverview}
            </Typography>
          </Grid>
        </>
      ) : null}
      {/* Link your web presence */}
      {storeFrontDetails.webPresence &&
      storeFrontDetails.webPresence.socialLinks.length ? (
        <Grid item xs={7} className={classes.marginTop}>
          <Typography variant="subtitle1">Link your web presence</Typography>
          <Grid container className={classes.webPresenceBox}>
            <Grid container style={{ padding: "10px 20px 20px 20px" }}>
              {storeFrontDetails.webPresence.socialLinks.map((socialLink) => (
                <Grid container style={{ marginTop: 20 }}>
                  <Grid item xs={1}>
                    <SocialIcon
                      style={{ width: 20, height: 20 }}
                      url={socialLink}
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <Grid
                      item
                      container
                      direction="column"
                      style={{ marginLeft: 10, wordBreak: "break-all" }}
                    >
                      <Typography style={{ fontSize: 14, fontStyle: "italic" }}>
                        {socialLink}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {/* Service Details */}
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        style={{ width: "100%", marginTop: 20, marginBottom: 10 }}
      />
      {storeFrontDetails.services && storeFrontDetails.services.length ? (
        <>
          <Grid item xs={12} className={classes.marginTop}>
            <Typography variant="h6">Service Details</Typography>
          </Grid>
          <Grid item xs={12} className={classes.marginTop}>
            <Typography variant="subtitle1">Service</Typography>
          </Grid>
          {storeFrontDetails.services.map((service, index) => (
            <Grid
              container
              className={classes.webPresenceBox}
              style={{ marginBottom: 16 }}
            >
              <Grid
                container
                style={{ padding: "10px 20px 20px 20px" }}
                key={index}
              >
                <Grid item container xs={2} justifyContent="center">
                  <img
                    src={service.imageUrl || "/images/logo.svg"} // if image not present show placeholder logo
                    style={{ width: 81, height: 106 }}
                    placeholderImage="/images/banner.svg"
                  />
                </Grid>
                <Grid
                  container
                  xs={8}
                  item
                  className={`${classes.marginBottom} ${classes.marginLeft}`}
                >
                  <Grid item xs={12}>
                    <Typography variant="body1" className={classes.heading}>
                      {service.name}
                    </Typography>
                    <Typography variant="body1">
                      {service.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 10 }}>
                    <Typography variant="body1">{service.label}</Typography>
                    <Typography variant="body1">{service.link}</Typography>
                  </Grid>
                </Grid>
                <Divider
                  orientation="horizontal"
                  variant="fullWidth"
                  style={{ width: "100%", marginTop: 10, marginBottom: 16 }}
                />
                <Grid item xs={12} style={{ marginLeft: -10 }}>
                  {service.uploadDocUrl ? (
                    <Chip
                      label={getFormattedFileName(service.uploadDocUrl)}
                      variant="outlined"
                      style={{ marginLeft: 10 }}
                      icon={<InsertDriveFileIcon />}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </>
      ) : null}

      <Grid item xs={12} className={classes.marginTop}>
        <Typography variant="subtitle1">Upload Marketing Brochure</Typography>
      </Grid>
      {storeFrontDetails.marketingRelatedDocs &&
      storeFrontDetails.marketingRelatedDocs.length ? (
        <Grid item xs={12} style={{ padding: 10 }}>
          <TableContainer component={Paper} style={{ marginBottom: 20 }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>File Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {storeFrontDetails.marketingRelatedDocs.map(
                  (document, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {document.split("/").pop()}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : (
        <Grid item xs={12} style={{ padding: 10 }}>
          <Typography>No data available</Typography>
        </Grid>
      )}

      {storeFrontDetails.reviews &&
      storeFrontDetails.reviews.length &&
      !Object.keys(storeFrontDetails.reviews[0]).some(
        (key) => storeFrontDetails.reviews[0][key] === ""
      ) ? (
        <>
          <Grid item xs={12} className={classes.marginTop}>
            <Typography variant="subtitle1">Existing Reviews</Typography>
          </Grid>
          {storeFrontDetails.reviews.map(
            (review, index) =>
              review.title && (
                <Grid
                  container
                  className={`${classes.webPresenceBox} ${classes.marginBottom}`}
                >
                  <Grid container style={{ padding: "10px 20px 20px 20px" }}>
                    <Grid item xs={12} key={index}>
                      <Typography variant="body1" className={classes.heading}>
                        {review.title}
                      </Typography>
                      <Typography variant="body1">{review.link}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )
          )}
        </>
      ) : null}
      <Divider className={classes.marginTop} />
      {isPaymentVisible ? (
        <>
          <Grid item xs={12} className={classes.marginTop}>
            <Typography variant="h6">Customer Payment</Typography>
          </Grid>
          <Grid item xs={12} className={classes.marginTop}>
            <Typography variant="subtitle1">Payment Method</Typography>
          </Grid>
        </>
      ) : null}

      {creditCardInfo &&
      creditCardInfo.config &&
      Object.keys(creditCardInfo.config).length ? (
        <Grid
          container
          className={`${classes.webPresenceBox} ${classes.marginBottom}`}
        >
          <Grid container style={{ padding: "10px 20px 20px 20px" }}>
            <Typography variant="body1" className={classes.heading}>
              Credit/Debit Card
            </Typography>
            {creditCardInfo.config &&
              creditCardInfo.config.length &&
              creditCardInfo.config.map((creditCard) => (
                <Grid item container className={classes.marginLeft}>
                  <Typography variant="body1">{creditCard.name}</Typography>
                </Grid>
              ))}
          </Grid>
        </Grid>
      ) : null}
      {paypalInfo &&
      paypalInfo.config &&
      Object.keys(paypalInfo.config).length ? (
        <Grid
          container
          className={`${classes.webPresenceBox} ${classes.marginBottom}`}
        >
          <Grid container style={{ padding: "10px 20px 20px 20px" }}>
            <Typography variant="body1" className={classes.heading}>
              Paypal
            </Typography>
            {paypalInfo.config.email && (
              <Grid item container className={classes.marginLeft}>
                <Typography variant="body1">
                  {paypalInfo.config.email}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : null}
      {wireTransferInfo &&
      wireTransferInfo.config &&
      !Object.keys(wireTransferInfo.config).some(
        (key) => wireTransferInfo.config[key] === ""
      ) ? (
        <Grid
          container
          className={`${classes.webPresenceBox} ${classes.marginBottom}`}
        >
          <Grid container style={{ padding: "10px 20px 20px 20px" }}>
            <Typography variant="body1" className={classes.heading}>
              Wire Transfer
            </Typography>
            {wireTransferInfo.config && (
              <>
                <Grid item container className={classes.marginLeft}>
                  <Typography variant="body1">Payable to:</Typography>
                  <Typography variant="body1" style={{ marginLeft: 10 }}>
                    {wireTransferInfo.config.payableTo}
                  </Typography>
                </Grid>
                <Grid item container className={classes.marginLeft}>
                  <Typography variant="body1">Firm Address:</Typography>
                  <Typography variant="body1" style={{ marginLeft: 10 }}>
                    {wireTransferInfo.config.firmAddress}
                  </Typography>
                </Grid>
                <Grid item container className={classes.marginLeft}>
                  <Typography variant="body1">Institution:</Typography>
                  <Typography variant="body1" style={{ marginLeft: 10 }}>
                    {wireTransferInfo.config.institution}
                  </Typography>
                </Grid>
                <Grid item container className={classes.marginLeft}>
                  <Typography variant="body1">Institution Number:</Typography>
                  <Typography variant="body1" style={{ marginLeft: 10 }}>
                    {wireTransferInfo.config.institutionNumber}
                  </Typography>
                </Grid>
                <Grid item container className={classes.marginLeft}>
                  <Typography variant="body1">Branch Number:</Typography>
                  <Typography variant="body1" style={{ marginLeft: 10 }}>
                    {wireTransferInfo.config.branchNumber}
                  </Typography>
                </Grid>
                <Grid item container className={classes.marginLeft}>
                  <Typography variant="body1">Swift Code:</Typography>
                  <Typography variant="body1" style={{ marginLeft: 10 }}>
                    {wireTransferInfo.config.swiftCode}
                  </Typography>
                </Grid>
                <Grid item container className={classes.marginLeft}>
                  <Typography variant="body1">
                    Canadian Clearing Code:
                  </Typography>
                  <Typography variant="body1" style={{ marginLeft: 10 }}>
                    {wireTransferInfo.config.canadianClearingCode}
                  </Typography>
                </Grid>
                <Grid item container className={classes.marginLeft}>
                  <Typography variant="body1">Account Number:</Typography>
                  <Typography variant="body1" style={{ marginLeft: 10 }}>
                    {wireTransferInfo.config.accountNumber}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      ) : null}
      {eTransferInfo &&
      eTransferInfo.config &&
      !Object.keys(eTransferInfo.config).some(
        (key) => eTransferInfo.config[key] === ""
      ) ? (
        <Grid
          container
          className={`${classes.webPresenceBox} ${classes.marginBottom}`}
        >
          <Grid container style={{ padding: "10px 20px 20px 20px" }}>
            <Typography variant="body1" className={classes.heading}>
              E-Transfer
            </Typography>
            {eTransferInfo.config && (
              <>
                <Grid item container className={classes.marginLeft}>
                  <Typography variant="body1">Email:</Typography>
                  <Typography variant="body1" style={{ marginLeft: 10 }}>
                    {eTransferInfo.config.email}
                  </Typography>
                </Grid>
                <Grid item container className={classes.marginLeft}>
                  <Typography variant="body1">Preferred Password:</Typography>
                  <Typography variant="body1" style={{ marginLeft: 10 }}>
                    {eTransferInfo.config.preferredPassword}
                  </Typography>
                </Grid>
                <Grid item container className={classes.marginLeft}>
                  <Typography variant="body1">Max Amount: </Typography>
                  <Typography variant="body1" style={{ marginLeft: 10 }}>
                    ${eTransferInfo.config.maxAmount}
                    {/* currency hardcoded to $ for now */}
                  </Typography>
                </Grid>
                <Grid item container className={classes.marginLeft}>
                  <Typography variant="body1">Mobile Number:</Typography>
                  <Typography variant="body1" style={{ marginLeft: 10 }}>
                    {eTransferInfo.config.mobileNumber}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      ) : null}
      {chequeInfo &&
      chequeInfo.config &&
      !Object.keys(chequeInfo.config).some(
        (key) => chequeInfo.config[key] === ""
      ) ? (
        <Grid
          container
          className={`${classes.webPresenceBox} ${classes.marginBottom}`}
        >
          <Grid container style={{ padding: "10px 20px 20px 20px" }}>
            <Typography variant="body1" className={classes.heading}>
              Cheque
            </Typography>
            {chequeInfo.config && (
              <>
                <Grid item container className={classes.marginLeft}>
                  <Typography variant="body1">Name:</Typography>
                  <Typography variant="body1" style={{ marginLeft: 10 }}>
                    {chequeInfo.config.name}
                  </Typography>
                </Grid>
                <Grid item container className={classes.marginLeft}>
                  <Typography variant="body1">Address:</Typography>
                  <Typography variant="body1" style={{ marginLeft: 10 }}>
                    {chequeInfo.config.address}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      ) : null}
      {otherInfo &&
      otherInfo.config.length &&
      !Object.keys(otherInfo.config[0]).some(
        (key) => otherInfo.config[0][key] === ""
      ) ? (
        <Grid
          container
          className={`${classes.webPresenceBox} ${classes.marginBottom}`}
        >
          <Grid container style={{ padding: "10px 20px 20px 20px" }}>
            <Typography variant="body1" className={classes.heading}>
              Other Payment Methods
            </Typography>
            {otherInfo.config &&
              otherInfo.config.map((otherPayment) => (
                <Grid item container direction="column">
                  <Grid item container>
                    <Typography variant="body1">Payment Method:</Typography>
                    <Typography variant="body1" style={{ marginLeft: 10 }}>
                      {otherPayment && otherPayment.methodName}
                    </Typography>
                  </Grid>
                  <Grid item container>
                    <Typography variant="body1">Details:</Typography>
                    <Typography variant="body1" style={{ marginLeft: 10 }}>
                      {otherPayment && otherPayment.methodDetails}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
}
