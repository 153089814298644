import React from "react";
import { map } from "lodash";

function EmailCheck({ validations, signUrl = "/" }) {
  if (!validations) {
    return null;
  }

  const result = map(validations, (e) => {
    if (/sign-in/i.test(e)) {
      return e.replaceAll("sign-in", `<a href="${signUrl}">sign-in</a>`);
    }
    return e;
  });

  return <div dangerouslySetInnerHTML={{ __html: result.join(" ") }}></div>;
}

export default EmailCheck;
