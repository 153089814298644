import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useMessaging } from "souqh-react-redux-hooks/messaging/useMessaging";
import BrodcastMessageDialog from "./BrodcastMessageDialog";
import CreateNewGroupChatDialog from "./CreateNewGroupChatDialog";
import MessageLeftPanel from "./MessageLeftPanel";
import MessageRightPanel from "./MessageRightPanel";
import GroupMembersDialog from "./GroupMembersDialog";
import { useSQQuery } from "souqh-react-redux-hooks/common/useSQQuery";
import { isMobileDevice, isTabletDevice } from "../../utils/AppUtils";

export default function Messaging() {
  let location = useLocation();
  const { query } = useSQQuery();

  const {
    actions: { setCurrentUIPath },
    searchUserText,
    setSearchUserText,
    channelList,
    openCreateNewGroupChat,
    setOpenCreateNewGroupChat,
    openBroadCastMessageDialog,
    setOpenBroadCastMessageDialog,
    souqhUserInfo,
    selectedChannelData,
    currentChannelMessages,
    sessionStarted,
    loadChannelsData,
    leftPanelRef,
    itemRefs,
    messageTextBoxRef,
    messagePanelRef,
    messageTextBox,
    setMessageTextBox,
    sendMessage,
    onContentScroll,
    processMessageAttachment,
    messageAttachment,
    removeAttachment,
    onChannelClick,
    subscribeToMessageUpdate,
    messagesProcessor,
    socketProcessor,
    clearMessageDataOnDestroy,
    socketMessage,
    sendingMessage,
    loadedChannelData,
    showLoader,
    downloadAttachment,
    setUnreadChannlesData,
    createNewUserGroup,
    broadcastMessage,
    onSendBroadcastMessage,
    updateListAfterBroadcast,
    showEmojiPicker,
    setShowEmojiPicker,
    selectedChannelMembersList,
    openGroupMembersDialog,
    setOpenGroupMembersDialog,
    openChannelManually,
    setOpenChannelManually,
    setQueryEmailAddress,
    openChannelForMessage,
    addedWaitForUnread,
    processUnreadWait,
    waitedUnreadData,
    setWaitedUnreadData,
    hasMessageContent,
    setHasMessageContent,
    getMessageContentFromLocalStorage,
  } = useMessaging(false, location);

  useEffect(() => {
    if (sessionStarted) {
      subscribeToMessageUpdate(socketProcessor);
      loadChannelsData(true);
    }
  }, [sessionStarted]);

  useEffect(() => {
    setQueryEmailAddress(query.get("sendMessage"));
    setHasMessageContent(query.get("hasMessageContent"));
    setCurrentUIPath(location.pathname);
    if (!loadedChannelData) {
      showLoader();
    }
    return () => {
      clearMessageDataOnDestroy();
      setCurrentUIPath("");
    };
  }, []);

  useEffect(() => {
    if (socketMessage) {
      messagesProcessor();
    }
  }, [socketMessage]);

  useEffect(() => {
    if (loadedChannelData) {
      setUnreadChannlesData();
      openChannelForMessage();
    }
  }, [loadedChannelData]);

  useEffect(() => {
    if (broadcastMessage) {
      updateListAfterBroadcast();
    }
  }, [broadcastMessage]);

  useEffect(() => {
    if (openChannelManually) {
      const messageContent = getMessageContentFromLocalStorage();
      onChannelClick(openChannelManually, messageContent);
      setOpenChannelManually(null);
    }
  }, [openChannelManually]);

  useEffect(() => {
    if (waitedUnreadData && addedWaitForUnread) {
      processUnreadWait();
    }
  }, [waitedUnreadData, addedWaitForUnread]);

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        // height: window.innerHeight,
        height: isMobileDevice() ? 570 : window.innerHeight,
        marginLeft: isMobileDevice() ? 0 : isTabletDevice() ? 20 : 80,
        opacity: sessionStarted ? 1 : 0.5,
        pointerEvents: sessionStarted ? "all" : "none",
      }}
    >
      {/* <CreateNewGroupChatDialog
        openCreateNewGroupChat={openCreateNewGroupChat}
        setOpenCreateNewGroupChat={setOpenCreateNewGroupChat}
        channelList={channelList}
        createNewUserGroup={createNewUserGroup}
      /> */}
      <BrodcastMessageDialog
        openBroadCastMessageDialog={openBroadCastMessageDialog}
        setOpenBroadCastMessageDialog={setOpenBroadCastMessageDialog}
        channelList={channelList}
        onSendBroadcastMessage={onSendBroadcastMessage}
      />
      <GroupMembersDialog
        openGroupMembersDialog={openGroupMembersDialog}
        setOpenGroupMembersDialog={setOpenGroupMembersDialog}
        selectedChannelMembersList={selectedChannelMembersList}
        souqhUserInfo={souqhUserInfo}
      />

      {isMobileDevice() ? (
        <Grid item container xs={12} style={{ height: "100%" }}>
          {!selectedChannelData && isMobileDevice() ? (
            <Grid
              item
              style={{
                width: isMobileDevice() ? "100%" : 350,
                height: "100%",
                backgroundColor: "#ffffff",
                overflow: "hidden",
              }}
            >
              <MessageLeftPanel
                searchUserText={searchUserText}
                setSearchUserText={setSearchUserText}
                channelList={channelList}
                setOpenCreateNewGroupChat={setOpenCreateNewGroupChat}
                setOpenBroadCastMessageDialog={setOpenBroadCastMessageDialog}
                selectedChannelData={selectedChannelData}
                leftPanelRef={leftPanelRef}
                itemRefs={itemRefs}
                onChannelClick={onChannelClick}
              />
            </Grid>
          ) : (
            <Grid
              item
              style={{
                width: isMobileDevice() ? "100%" : "calc(100% - 350px)",
                height: "100%",
                backgroundColor: "transparent",
              }}
            >
              <MessageRightPanel
                selectedChannelData={selectedChannelData}
                souqhUserInfo={souqhUserInfo}
                setOpenCreateNewGroupChat={setOpenCreateNewGroupChat}
                currentChannelMessages={currentChannelMessages}
                messageTextBoxRef={messageTextBoxRef}
                messagePanelRef={messagePanelRef}
                messageTextBox={messageTextBox}
                setMessageTextBox={setMessageTextBox}
                sendMessage={sendMessage}
                onContentScroll={onContentScroll}
                processMessageAttachment={processMessageAttachment}
                messageAttachment={messageAttachment}
                removeAttachment={removeAttachment}
                sendingMessage={sendingMessage}
                downloadAttachment={downloadAttachment}
                showEmojiPicker={showEmojiPicker}
                setShowEmojiPicker={setShowEmojiPicker}
                selectedChannelMembersList={selectedChannelMembersList}
                setOpenGroupMembersDialog={setOpenGroupMembersDialog}
              />
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid item container xs={12} style={{ height: "100%" }}>
          <Grid
            item
            style={{
              width: isMobileDevice() ? "100%" : isTabletDevice() ? 320 : 350,
              height: "100%",
              backgroundColor: "#ffffff",
              overflow: "hidden",
            }}
          >
            <MessageLeftPanel
              searchUserText={searchUserText}
              setSearchUserText={setSearchUserText}
              channelList={channelList}
              setOpenCreateNewGroupChat={setOpenCreateNewGroupChat}
              setOpenBroadCastMessageDialog={setOpenBroadCastMessageDialog}
              selectedChannelData={selectedChannelData}
              leftPanelRef={leftPanelRef}
              itemRefs={itemRefs}
              onChannelClick={onChannelClick}
            />
          </Grid>
          <Grid
            item
            style={{
              width: isMobileDevice() ? "100%" : "calc(100% - 350px)",
              height: "100%",
              backgroundColor: "transparent",
            }}
          >
            <MessageRightPanel
              selectedChannelData={selectedChannelData}
              souqhUserInfo={souqhUserInfo}
              setOpenCreateNewGroupChat={setOpenCreateNewGroupChat}
              currentChannelMessages={currentChannelMessages}
              messageTextBoxRef={messageTextBoxRef}
              messagePanelRef={messagePanelRef}
              messageTextBox={messageTextBox}
              setMessageTextBox={setMessageTextBox}
              sendMessage={sendMessage}
              onContentScroll={onContentScroll}
              processMessageAttachment={processMessageAttachment}
              messageAttachment={messageAttachment}
              removeAttachment={removeAttachment}
              sendingMessage={sendingMessage}
              downloadAttachment={downloadAttachment}
              showEmojiPicker={showEmojiPicker}
              setShowEmojiPicker={setShowEmojiPicker}
              selectedChannelMembersList={selectedChannelMembersList}
              setOpenGroupMembersDialog={setOpenGroupMembersDialog}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
