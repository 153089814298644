import {
  Checkbox,
  Collapse,
  Link,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import Styles from "../../Styles.module.css";
import DocumentStatus from "../common/DocumentStatus";
import DocQuickActions from "../ServiceProvider/UploadDocuments/DocQuickActions";
import HBRowMenuOptions from "./HBRowMenuOptions";
import SPClientCell from "../ServiceProvider/UploadDocuments/SPClientCell";
import {
  covertTimeStampToDateTime,
  getComparator,
  isMobileDevice,
  isTabletDevice,
  stableSort,
} from "../../utils/AppUtils";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { useDispatch } from "react-redux";
import { HB_SIGN_NOW_ACCOUNT_EXIST } from "../ServiceProvider/serviceProviderConstants";

export default function HBDocTableCell(props) {
  const {
    classes,
    categoryId,
    documents,
    order,
    orderBy,
    downLoadDocument,
    setOpenDeleteDocumentDialog,
    setOpenMoveDocumentDialog,
    setOpenArchiveDocumentDialog,
    setOpenRenameDocumentDialog,
    getSharedWithTooltip,
    setOpenShareDocumentDialog,
    handleCheckboxChange,
    downloadMultipleDocument,
    selectedData,
    setSelectedData,
    setSelectedRow,
    selelctedRow,
    handleInnerClose,
    openInnerMenu,
    duplicateDocument,
    handleInnerListKeyDown,
    setDocumentItem,
    setOpenDocumetDialog,
    getUploadedDocuments,
    setOpenSharedWithPopup,
    innerAnchorRef,
    setInnerAnchorRef,
    handleInnerToggle,
    open,
    setOpenSignDocumentDialog,
    setSignDocumentItem,
    documentTabName,
  } = props;

  const userInfo = useAuthUser();
  const dispatch = useDispatch();

  const quickActions = (row) => {
    return (
      <DocQuickActions
        row={row}
        selelctedRow={selelctedRow}
        categoryId={categoryId}
        setSelectedData={setSelectedData}
        setOpenShareDocumentDialog={setOpenShareDocumentDialog}
        downloadMultipleDocument={downloadMultipleDocument}
        downLoadDocument={downLoadDocument}
        selectedData={selectedData}
        documentTabName={documentTabName}
      />
    );
  };

  const rowOptions = (row) => {
    return (
      <HBRowMenuOptions
        row={row}
        selelctedRow={selelctedRow}
        categoryId={categoryId}
        setOpenDeleteDocumentDialog={setOpenDeleteDocumentDialog}
        setOpenMoveDocumentDialog={setOpenMoveDocumentDialog}
        setOpenRenameDocumentDialog={setOpenRenameDocumentDialog}
        handleInnerClose={handleInnerClose}
        openInnerMenu={openInnerMenu}
        handleInnerListKeyDown={handleInnerListKeyDown}
        duplicateDocument={duplicateDocument}
      />
    );
  };

  return (
    <TableCell style={{ padding: 0 }} colSpan={6}>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Table
          size="small"
          aria-label="purchases"
          style={{ tableLayout: "fixed", width: "100%" }}
        >
          <TableBody>
            {stableSort(documents, getComparator(order, orderBy)).map(
              (row, index) => {
                const isLinkEnabled = row.signNowDocumentId
                  ? row.enableUrl
                    ? true
                    : false
                  : true;
                return (
                  <TableRow key={index}>
                    <TableCell
                      align="left"
                      style={{
                        width:
                          isTabletDevice() || isMobileDevice() ? "14em" : "25%",
                        overflow: "hidden",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Checkbox
                          checked={row.selected || false}
                          onChange={(event) => {
                            handleCheckboxChange(
                              event.target.checked,
                              row.docId
                            );
                          }}
                          color={Styles.navyBlueColorForce}
                        />
                        <Link
                          color="inherit"
                          style={{
                            wordBreak: "break-all",
                            fontSize: 14,
                            opacity: isLinkEnabled ? 1 : 0.6,
                            cursor: isLinkEnabled ? "pointer" : "default",
                            textDecoration: isLinkEnabled
                              ? "underline"
                              : "none",
                          }}
                          onClick={() => {
                            if (userInfo.signNowAccountCreated) {
                              if (!isLinkEnabled) {
                                return;
                              }
                              if (row.enableUrl && row.signNowDocumentId) {
                                setSignDocumentItem(row);
                                setOpenSignDocumentDialog(true);
                              } else {
                                setDocumentItem(getUploadedDocuments(row));
                                setOpenDocumetDialog(true);
                              }
                            } else {
                              dispatch({
                                type: "SET_SERVER_ERROR",
                                error: {
                                  message: HB_SIGN_NOW_ACCOUNT_EXIST,
                                },
                              });
                            }
                          }}
                          className={Styles.navyBlueColorForce}
                          title={row.docDescription}
                        >
                          {row.docName}
                        </Link>
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        paddingRight: 0,
                        width:
                          isTabletDevice() || isMobileDevice() ? "15em" : "23%",
                      }}
                    >
                      <Typography style={{ fontSize: 14 }}>
                        {row.signNowDocumentId ? (
                          <Typography
                            variant="body1"
                            style={{ width: "95%", marginBottom: 12 }}
                            title={row.sentBy}
                            className={clsx(
                              Styles.sqEllipsis,
                              Styles.navyBlueColor
                            )}
                          >
                            {row.sentBy}
                          </Typography>
                        ) : (
                          <SPClientCell
                            row={row}
                            setOpenSharedWithPopup={setOpenSharedWithPopup}
                            getSharedWithTooltip={getSharedWithTooltip}
                          />
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        width:
                          isTabletDevice() || isMobileDevice() ? "11em" : "15%",
                      }}
                    >
                      <DocumentStatus docStatus={row.docStatus} />
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        width:
                          isTabletDevice() || isMobileDevice() ? "13em" : "18%",
                      }}
                    >
                      <Tooltip
                        title={covertTimeStampToDateTime(row.lastChange)}
                      >
                        <Typography
                          className={Styles.navyBlueColorForce}
                          style={{ fontSize: 14 }}
                        >
                          {covertTimeStampToDateTime(row.lastChange)}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        width:
                          isTabletDevice() || isMobileDevice() ? "8em" : "12%",
                      }}
                    >
                      {quickActions(row)}
                    </TableCell>
                    <TableCell align="right" style={{ width: "3%" }}>
                      <MoreHorizIcon
                        ref={innerAnchorRef}
                        onClick={(event) => {
                          // handleCheckboxChange(true, row.docId);
                          setSelectedRow(row);
                          setInnerAnchorRef(event.currentTarget);
                          handleInnerToggle();
                        }}
                        className={clsx(
                          classes.cursorPointer,
                          Styles.navyBlueColorForce
                        )}
                      />
                      <Popper
                        open={openInnerMenu}
                        anchorEl={innerAnchorRef}
                        placement="bottom-end"
                      >
                        {rowOptions(row)}
                      </Popper>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </Collapse>
    </TableCell>
  );
}
