import React, { useState } from "react";
import {
  Avatar,
  Card,
  Grid,
  Typography,
  CardContent,
  Divider,
  ButtonBase,
  CardActions,
  Button,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Styles from "../../../Styles.module.css";
import { useHistory } from "react-router";
import { useRouteMatch, Link } from "react-router-dom";
import clsx from "clsx";
import MoreOptionsOnSPInfo from "./MoreOptionsOnSPInfo";
import AddIcon from "@material-ui/icons/Add";
import AddLabelDailog from "./AddLabelDailog";
import EditIcon from "@material-ui/icons/Edit";
import { useUpcomingEeventsPendingActions } from "souqh-react-redux-hooks/serviceProvider/upcomingEeventsPendingActions/useUpcomingEeventsPendingActions";
import RecommendedSPDialog from "../../common/UpcomingEeventsPendingActions/RecommendedSPDialog.";
import RequestedDocumentDialog from "../../common/UpcomingEeventsPendingActions/RequestedDocumentDialog";
import DocumentAccessRequestDialog from "../../common/UpcomingEeventsPendingActions/DocumentAccessRequestDialog";
import ViewInvoiceDialog from "../../common/UpcomingEeventsPendingActions/ViewInvoiceDialog";
import ViewOtherActionDialog from "../../common/UpcomingEeventsPendingActions/ViewOtherActionDialog";
import SelectHomeBuyingJourneyDialog from "../../common/UpcomingEeventsPendingActions/SelectHomeBuyingJourneyDialog";
import SignDocumentDialog from "../../ServiceProvider/UploadDocuments/SignDocumentDialog";
import ViewTagDialog from "../../common/UpcomingEeventsPendingActions/ViewTagDialog";
import { isMobileDevice } from "../../../utils/AppUtils";

export default function ActiveSPInfoCard({
  data,
  storeFrontContent,
  storefrontDetails,
  getSpManagementData,
}) {
  const history = useHistory();
  let { path } = useRouteMatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openAddLabelDialog, setOpenAddLabelDialog] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  const showMoreInfoCondition = isMobileDevice() && showMoreInfo;

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const {
    handlePendingAction,
    openHomeBuyingJourneyDialog,
    setOpenHomeBuyingJourneyDialog,
    openViewInvoiceDialog,
    setOpenViewInvoiceDialog,
    viewInvoiceDialogData,
    openSignDocumentDialog,
    setOpenSignDocumentDialog,
    signDocumentItem,
    setSignDocumentItem,
    reloadPendingActionData,
    deletePendingAction,
    setOpenViewTagDialog,
    openViewTagDialog,
    viewTagDialogData,
    openRecommendedSPDialog,
    setOpenRecommendedSPDialog,
    recommendedSpData,
    openRequestedDocumentDialog,
    setOpenRequestedDocumentDialog,
    requestedDocumentData,
    openDocumentAccessRequestDialog,
    setOpenDocumentAccessRequestDialog,
    requestedDocAccessName,
    documentAccessRequestorSP,
    acceptDocumentAccessRequest,
    openOtherActionDialog,
    setOpenOtherActionDialog,
    otherActionData,
    userInfo,
    docAccessRequestedData,
  } = useUpcomingEeventsPendingActions();

  return (
    <React.Fragment>
      <AddLabelDailog
        openAddLabelDialog={openAddLabelDialog}
        setOpenAddLabelDialog={setOpenAddLabelDialog}
        serviceRequestId={data.serviceRequestId}
        label={data.label}
      />
      <RecommendedSPDialog
        openRecommendedSPDialog={openRecommendedSPDialog}
        setOpenRecommendedSPDialog={setOpenRecommendedSPDialog}
        recommendedSpData={recommendedSpData}
      />
      <RequestedDocumentDialog
        openRequestedDocumentDialog={openRequestedDocumentDialog}
        setOpenRequestedDocumentDialog={setOpenRequestedDocumentDialog}
        requestedDocumentData={requestedDocumentData}
        deletePendingAction={deletePendingAction}
      />
      <DocumentAccessRequestDialog
        openDocumentAccessRequestDialog={openDocumentAccessRequestDialog}
        setOpenDocumentAccessRequestDialog={setOpenDocumentAccessRequestDialog}
        requestedDocAccessName={requestedDocAccessName}
        documentAccessRequestorSP={documentAccessRequestorSP}
        acceptDocumentAccessRequest={acceptDocumentAccessRequest}
        docAccessRequestedData={docAccessRequestedData}
      />
      <ViewInvoiceDialog
        openViewInvoiceDialog={openViewInvoiceDialog}
        setOpenViewInvoiceDialog={setOpenViewInvoiceDialog}
        viewInvoiceDialogData={viewInvoiceDialogData}
        userInfo={userInfo}
      />
      <ViewOtherActionDialog
        openOtherActionDialog={openOtherActionDialog}
        setOpenOtherActionDialog={setOpenOtherActionDialog}
        otherActionData={otherActionData}
      />
      <SelectHomeBuyingJourneyDialog
        openHomeBuyingJourneyDialog={openHomeBuyingJourneyDialog}
        setOpenHomeBuyingJourneyDialog={setOpenHomeBuyingJourneyDialog}
      />
      <SignDocumentDialog
        openSignDocumentDialog={openSignDocumentDialog}
        setOpenSignDocumentDialog={setOpenSignDocumentDialog}
        signDocumentItem={signDocumentItem}
        setSignDocumentItem={setSignDocumentItem}
        reloadPendingActionData={reloadPendingActionData}
      />
      <ViewTagDialog
        openViewTagDialog={openViewTagDialog}
        setOpenViewTagDialog={setOpenViewTagDialog}
        viewTagDialogData={viewTagDialogData}
      />
      <Card
        elevation={0}
        style={{ border: !isMobileDevice() && "1px solid #0000001F" }}
      >
        <MoreOptionsOnSPInfo
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          serviceRequestId={data.serviceRequestId}
          storefrontId={storefrontDetails.storefrontId}
          displayPicture={storeFrontContent.displayPicture}
          storefrontName={storeFrontContent.storefrontName}
          email={storefrontDetails.email}
          contactNumber={storefrontDetails.contactNumber}
          serviceName={data.serviceName}
          showAddReviewOption={true}
          showMoveToCompletedOption={true}
          showRemoveAndArchiveOption={true}
          showRecommendToOthersOption={true}
          showSendMessageOption={true}
          getSpManagementData={getSpManagementData}
          actionType="ARCHIVE_ACTIVE_REQUEST"
        />
        <CardContent style={{ padding: "16px 0px" }}>
          {/* <Link
            style={{ textDecoration: "none", cursor: "pointer" }}
            to={`/homebuyer/serviceproviders/details/${data.storefrontDetails.serviceProviderId}`}
          > */}
          <Grid
            container
            style={{
              // padding: !isMobileDevice() && "0px 16px",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push({
                pathname: `/homebuyer/serviceproviders/details/${data.storefrontDetails.serviceProviderId}`,
                state: {
                  email: storefrontDetails.email,
                },
              });
            }}
          >
            <Grid
              container
              style={{ padding: !isMobileDevice() && "0px 16px" }}
            >
              <Grid item xs={3}>
                <Avatar
                  style={{ height: 76, width: 76, borderRadius: 4 }}
                  variant="square"
                  src={storeFrontContent.displayPicture}
                >
                  {(storefrontDetails.storeFrontName || "").charAt(0)}
                </Avatar>
              </Grid>
              <Grid container item xs={8} style={{ paddingLeft: 4 }}>
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <Typography
                      className={clsx(Styles.navyBlueColor, Styles.sqEllipsis)}
                      variant="body1"
                      style={{ fontWeight: 500 }}
                    >
                      {storefrontDetails.storeFrontName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Typography
                    className={clsx(Styles.navyBlueColor, Styles.font14)}
                  >
                    {storefrontDetails.businessTypes[0].name}
                  </Typography>
                </Grid>
                <Grid container item xs={12}>
                  <Typography
                    className={clsx(Styles.navyBlueColor, Styles.font14)}
                  >
                    {data.serviceName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <ButtonBase>
                  <MoreHorizIcon
                    className={Styles.navyBlueColor}
                    onClick={handleClick}
                  />
                </ButtonBase>
              </Grid>
            </Grid>
            {/* </Link> */}
          </Grid>
          {(!isMobileDevice() || showMoreInfoCondition) && (
            <React.Fragment>
              <Divider style={{ margin: "16px 0" }} />
              <Grid
                container
                item
                xs={12}
                style={{ paddingLeft: 16, paddingRight: 16 }}
              >
                <Grid item xs={8}>
                  <Typography
                    style={{ color: "#868DA6" }}
                    className={Styles.font14}
                  >
                    {data.label || "Label this service provider"}
                  </Typography>
                </Grid>
                {data.label ? (
                  <Grid
                    container
                    item
                    xs={4}
                    justifyContent="flex-end"
                    alignItems="center"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setOpenAddLabelDialog(true);
                    }}
                  >
                    <EditIcon
                      style={{ fontSize: "1rem" }}
                      className={Styles.navyBlueColor}
                    />
                    <Typography
                      style={{ paddingLeft: 4 }}
                      className={clsx(Styles.navyBlueColor, Styles.font14)}
                    >
                      Edit Label
                    </Typography>
                  </Grid>
                ) : (
                  <Grid
                    container
                    item
                    xs={4}
                    justifyContent="flex-end"
                    alignItems="center"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setOpenAddLabelDialog(true);
                    }}
                  >
                    <AddIcon
                      style={{ fontSize: "1rem" }}
                      className={Styles.navyBlueColor}
                    />
                    <Typography
                      style={{ paddingLeft: 4 }}
                      className={clsx(Styles.navyBlueColor, Styles.font14)}
                    >
                      Add Label
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Divider style={{ margin: "16px 0" }} />
              <Grid container style={{ padding: "0px 16px", height: "8em" }}>
                <Grid item xs={12}>
                  <Typography
                    className={Styles.navyBlueColor}
                    variant="body1"
                    style={{ fontWeight: 500 }}
                  >
                    Pending Actions
                  </Typography>
                </Grid>
                {data.hbPendingActions && data.hbPendingActions.length ? (
                  data.hbPendingActions.slice(0, 5).map((item) => (
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={6}
                        className={Styles.sqEllipsis}
                        title={item.eventDescription}
                      >
                        <Link
                          underline="always"
                          className={Styles.primaryTxtAccentForce}
                          variant="body1"
                          onClick={() => {
                            handlePendingAction(item);
                          }}
                        >
                          {item.eventDescription}
                        </Link>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          style={{ color: "#17174C59" }}
                        >
                          Due: {item.dueDate || "--"}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <Typography className={Styles.navyBlueColor}>
                    No Pending Actions
                  </Typography>
                )}
              </Grid>
            </React.Fragment>
          )}
        </CardContent>
        {(!isMobileDevice() || showMoreInfoCondition) && (
          <React.Fragment>
            <Divider style={{ margin: "16px 0px 0px 0px" }} />
            <CardActions disableSpacing>
              <Grid container item xs={12} justifyContent="flex-end">
                <ButtonBase
                  onClick={() => {
                    //   history.push(`${path}/reviews`);
                  }}
                >
                  <Typography
                    style={{ paddingRight: 10 }}
                    className={clsx(Styles.navyBlueColor, Styles.font14)}
                  >
                    View All
                  </Typography>
                </ButtonBase>
              </Grid>
            </CardActions>
          </React.Fragment>
        )}

        {isMobileDevice() && (
          <Grid container item xs={12} justifyContent="flex-end">
            <Button
              variant="text"
              className={Styles.primaryTxtAccentForce}
              style={{
                textTransform: "capitalize",
                marginTop: showMoreInfo ? -8 : -28,
              }}
              onClick={(event) => {
                event.stopPropagation();
                setShowMoreInfo(!showMoreInfo);
              }}
            >
              {showMoreInfo ? "Show less" : "Show more"}
            </Button>
          </Grid>
        )}
      </Card>
    </React.Fragment>
  );
}
