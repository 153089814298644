import { Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import Styles from "../../../../Styles.module.css";
import InviteLeftPanel from "../InviteLeftPanel";
import SignOrderMainPanel from "../SignOrderMainPanel";

const styles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined": {
      top: -8,
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px",
    },
  },
}));

export default function UploadAndUseTemplate({
  associactedServiceProviders,
  setAddContactDialog,
  searchText,
  setSearchText,
  selectedContact,
  setSelectedContact,
  filteredContacts,
  filterContacts,
  setAssociactedServiceProviders,
  sharedWithSP,
  setSharedWithSP,
  signOrders,
  signOrdersData,
  updateSignOrderChange,
  updateEmailTouchedState,
  updateEmailValue,
  updatePrimaryAndSecondaryApplicant,
  contacts,
  SPContacts,
  handleAssocSPSelect,
  primaryApplicantList,
}) {
  const classes = styles();

  useEffect(() => {
    filterContacts();
  }, [searchText]);

  useEffect(() => {
    if (selectedContact) {
      updatePrimaryAndSecondaryApplicant();
      setAssociactedServiceProviders(
        selectedContact.associatedServiceProviderDTOS || []
      );
    } else {
      setAssociactedServiceProviders([]);
    }
    setSharedWithSP(false);
  }, [selectedContact]);

  return (
    <Grid
      item
      container
      xs={12}
      style={{ height: "100%" }}
      className={classes.root}
    >
      <Grid
        item
        xs={3}
        className={Styles.sqGrayBackgroundColor}
        style={{ padding: "12px 20px", height: "100%" }}
      >
        <InviteLeftPanel
          searchText={searchText}
          setSearchText={setSearchText}
          selectedContact={selectedContact}
          setSelectedContact={setSelectedContact}
          filteredContacts={filteredContacts}
          filterContacts={filterContacts}
          contacts={contacts}
        ></InviteLeftPanel>
      </Grid>
      <Grid item xs={9} style={{ height: "100%", overflow: "auto" }}>
        {signOrdersData && signOrdersData.length ? (
          <SignOrderMainPanel
            setAddContactDialog={setAddContactDialog}
            selectedContact={selectedContact}
            associactedServiceProviders={associactedServiceProviders}
            sharedWithSP={sharedWithSP}
            setSharedWithSP={setSharedWithSP}
            signOrders={signOrders}
            signOrdersData={signOrdersData}
            updateSignOrderChange={updateSignOrderChange}
            updateEmailTouchedState={updateEmailTouchedState}
            updateEmailValue={updateEmailValue}
            SPContacts={SPContacts}
            handleAssocSPSelect={handleAssocSPSelect}
            primaryApplicantList={primaryApplicantList}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}
