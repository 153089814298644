import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuthUser } from "../useLogin";
import {
  SET_STOREFRONT_DATA,
  SET_STOREFRONT_ID,
} from "souqh-redux/reducers/actionConstants";
import { useLocation } from "react-router";
import { useApiClient } from "../useApiClient";

export const useStorefrontPage = () => {
  const { apiClient } = useApiClient();
  const user = useAuthUser();
  const dispatch = useDispatch();
  const [areaOfSpecializations, setAreaOfSpecializations] = useState([]);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [languagesYouSpeak, setLanguagesYouSpeak] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [businessName, setBusinessName] = useState("");
  const [ownerEmail, setOwnerEmail] = useState("");
  const [storeFrontName, setStoreFrontName] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [primarySubscriptionPlanName, setPrimarySubscriptionPlanName] =
    useState("");
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  let storeFrontDetails = useSelector((state) => state.storefront);
  const [openDocumetDialog, setOpenDocumetDialog] = useState(false);
  const [documentItem, setDocumentItem] = useState(null);
  const [openPage, setOpenPage] = useState(false);
  const [yearsInBusiness, setYearsInBusiness] = useState("");
  const [numberOfEmployees, setNumberOfEmployees] = useState("");
  const [showLimitedView, setShowLimitedView] = useState(false);
  const [addresses, setAddresses] = useState(null);
  const [serviceProviderId, setServiceProviderId] = useState(null);
  const [publicStorefrontId, setPublicStorefrontId] = useState(null);
  const [calSyncAccessToken, setCalSyncAccessToken] = useState(null);
  const [instaPosts, setInstaPosts] = useState([]);
  const [instagramToken, setInstagramToken] = useState(null);
  const [preferredPartnerSFDetails, setPreferredPartnerSFDetails] = useState(null);
  const pageLocation = useLocation();

  const getUploadedDocuments = (storeFrontDetails) => {
    if (!storeFrontDetails) {
      return [];
    }
    let tempSet = [];
    if (storeFrontDetails.marketingRelatedDocs.length) {
      tempSet = tempSet.concat(storeFrontDetails.marketingRelatedDocs);
    }
    if (storeFrontDetails.services.length) {
      storeFrontDetails.services.map((el) => {
        const uploadedDocs = el.uploadDocUrl;
        if (uploadedDocs) {
          tempSet.push(uploadedDocs);
        }
      });
    }
    const documentSet = [];
    tempSet.map((el) => {
      const fileName = decodeURIComponent(el.replace(/^.*[\\\/]/, "")) || "";
      documentSet.push({
        name: fileName,
        path: el,
        type: fileName.split(".").pop(),
      });
    });
    return documentSet;
  };

  const getStorefrontDetailsByName = (businessName) => {
    window.scrollTo(0, 200);
    // To Do change below url with new api
    if (pageLocation.pathname.indexOf("homebuyer/storefrontview") > -1) {
      setOpenPage(false);
    } else {
      setOpenPage(true);
    }
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/service-provider/storefront/name/${businessName}`)
      .then(function (response) {
        if (response && response.data && response.data.result) {
          processResponse(response);
        } else {
          setNoDataAvailable(true);
        }
      })
      .catch(function (error) {
        setNoDataAvailable(true);
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getInstaPosts = () => {
    if (instagramToken) {
      return apiClient
        .get(
          // "https://graph.instagram.com/me/media?fields=id,caption,media_url&access_token=936619743392459|3cdb3f896252a1db29679cb4554db266"
          // "https://graph.instagram.com/me/media?fields=id,caption,media_url,media_type,permalink,thumbnail_url,timestamp,username&access_token=IGQVJWQ3pPUkZAJWkNLWXUwTGFXazJSRkJkSkxmbnY5R2J4T1lOVWlOSEN6S3FIRi1oVmlDQXh0N19QaVNheE5vS2ZAEYUpZAanUwZAGhmQTRncDMyc2swS0ZAnZA0RSSXlVcHBDUUZABZAWxQQW1UZAE5GVmJqegZDZD"
          `https://graph.instagram.com/me/media?fields=id,caption,media_url,media_type,permalink,thumbnail_url,timestamp,username&access_token=${instagramToken}&limit=5000`
          // "https://graph.facebook.com/107080722166133/tags?fields=id,caption,media_url,media_type,permalink,thumbnail_url,timestamp,username&access_token=EAAIxD3XAEHgBAFitVCxXXElZAGaMYlRCbRwNP4Q2HmZBZCj1uI4ZBONYWwLkjZBXthBqZANnmt1JPTZBZB9KzE9392cm1DBmKYyyiSiyrLCfpIT9vZCDEHK8T1sZCOhHynr5w5pjtO0jsBn3ED0LLW4A3QQt3piP5MSxU3Jo7K2wXoOZC5JxngNQC3ZBadfmyrFhPANigUsRpiLQBASIyimS9S1M2QdPACuqj0fZCRQ0MidoYy8ZAATCAZB5E5P"
        )
        .then(function (response) {
          setInstaPosts(response.data.data);
          // if (response && response.data && response.data.result) {

          // } else {
          //   setNoDataAvailable(true);
          // }
        })
        .catch(function (error) {
          // setNoDataAvailable(true);
          setInstaPosts([])
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const getFBPosts = () => {
    return apiClient
      .get(
        "http://graph.facebook.com/me/wall?access_token=EAAIxD3XAEHgBAFitVCxXXElZAGaMYlRCbRwNP4Q2HmZBZCj1uI4ZBONYWwLkjZBXthBqZANnmt1JPTZBZB9KzE9392cm1DBmKYyyiSiyrLCfpIT9vZCDEHK8T1sZCOhHynr5w5pjtO0jsBn3ED0LLW4A3QQt3piP5MSxU3Jo7K2wXoOZC5JxngNQC3ZBadfmyrFhPANigUsRpiLQBASIyimS9S1M2QdPACuqj0fZCRQ0MidoYy8ZAATCAZB5E5P"
      )
      .then(function (response) {
        console.log("getFBPosts", response);
        // if (response && response.data && response.data.result) {

        // } else {
        //   setNoDataAvailable(true);
        // }
      })
      .catch(function (error) {
        setNoDataAvailable(true);
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getStorefrontDetails = (previewStorefrontId) => {
    window.scrollTo(0, 200);
    if (pageLocation.pathname.indexOf("homebuyer/storefrontview") > -1) {
      setOpenPage(false);
    } else {
      setOpenPage(true);
    }
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/service-provider/storefront/${previewStorefrontId}`)
      .then(function (response) {
        if (response && response.data && response.data.result) {
          processResponse(response);
        } else {
          setNoDataAvailable(true);
        }
      })
      .catch(function (error) {
        setNoDataAvailable(true);
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const processResponse = (response) => {
    const { storeFrontContent, services } = response.data.result.storefront;
    let rStoreFront = JSON.parse(storeFrontContent);
    rStoreFront.services = services;
    rStoreFront.contactNumber = response.data.result.contactNumber;
    rStoreFront.storeFrontRouteName =
      response.data.result.storefront.storeFrontRouteName;
    setAreaOfSpecializations(response.data.result.areaOfSpecializations);
    setBusinessTypes(response.data.result.businessTypes);
    setLanguagesYouSpeak(response.data.result.languagesYouSpeak);
    setTeamMembers(response.data.result.teamMembers);
    setBusinessName(response.data.result.businessName);
    setOwnerEmail(response.data.result.email);
    setStoreFrontName(response.data.result.storefront.storeFrontName);
    setPrimarySubscriptionPlanName(
      response.data.result.primarySubscriptionPlanName
    );
    setNumberOfEmployees(response.data.result.numberOfEmployees);
    setYearsInBusiness(response.data.result.yearsInBusiness);
    setShowLimitedView(response.data.result.showLimitedView);
    setPublicStorefrontId(response.data.result.storefront.id);
    const addresses = response.data.result.address;
    let tempAddress = addresses.splice(
      addresses.findIndex((item) => item.primaryAddress),
      1
    );
    addresses.unshift(tempAddress[0]);
    setAddresses(response.data.result.address);
    setServiceProviderId(response.data.result.serviceProviderId);
    setNoDataAvailable(false);
    setCalSyncAccessToken(response.data.result.calSyncAccessToken);
    setInstagramToken(response.data.result.instagramToken);
    setPreferredPartnerSFDetails(response.data.result.preferredPartnerStorefrontDetails)
    dispatch({
      type: SET_STOREFRONT_DATA,
      payload: { storeFront: rStoreFront },
    });
    dispatch({
      type: SET_STOREFRONT_ID,
      payload: { storeFrontId: response.data.result.storefront.storeFrontId },
    });
  };

  return {
    openPage,
    documentItem,
    setDocumentItem,
    openDocumetDialog,
    setOpenDocumetDialog,
    noDataAvailable,
    activeTab,
    setActiveTab,
    businessName,
    areaOfSpecializations,
    languagesYouSpeak,
    teamMembers,
    businessTypes,
    primarySubscriptionPlanName,
    storeFrontDetails,
    getStorefrontDetails,
    getUploadedDocuments,
    yearsInBusiness,
    numberOfEmployees,
    getStorefrontDetailsByName,
    storeFrontName,
    showLimitedView,
    addresses,
    serviceProviderId,
    setServiceProviderId,
    user,
    ownerEmail,
    publicStorefrontId,
    calSyncAccessToken,
    getInstaPosts,
    getFBPosts,
    instaPosts,
    instagramToken,
    preferredPartnerSFDetails
  };
};
