import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Divider,
  Avatar,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";

const styles = makeStyles((theme) => ({
  dialogPaper: {
    height: 602,
    width: 606,
  },
}));

function SharedWithPopup({
  openSharedWithPopup,
  setOpenSharedWithPopup,
  sharedWithList,
  title,
}) {
  const classes = styles();
  return (
    <Dialog
      fullWidth
      open={openSharedWithPopup}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">{title || "Shared With"}</Typography>
      </DialogTitle>
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        style={{ width: "100%" }}
      />
      <DialogContent>
        {sharedWithList && sharedWithList.length
          ? sharedWithList.map((value, index) => (
              <Grid container key={index} style={{ marginBottom: 24 }}>
                <Grid container item xs={1} alignContent="center">
                  <Avatar
                    src={"/broken-image.jpg"}
                    style={{ width: 30, height: 30 }}
                  />
                </Grid>
                <Grid container item xs={11} alignContent="center">
                  <Typography variant="body1" className={Styles.navyBlueColor}>
                    {value}
                  </Typography>
                </Grid>
              </Grid>
            ))
          : null}
      </DialogContent>
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        style={{ width: "100%" }}
      />
      <DialogActions>
        <Button
          variant="outlined"
          className={Styles.navyBlueColorForce}
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            setOpenSharedWithPopup(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SharedWithPopup;
