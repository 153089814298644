import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DROP_REQUEST,
  SET_LEADS_CLIENT_DATA,
} from "souqh-redux/reducers/actionConstants";
import { useApiClient } from "../../useApiClient";
import { useAuthUser } from "../../useLogin";
import { color_success, color_info } from "../../toasterColors";
import { useActions } from "../../useActions";

const actionCreators = {
  clearLeadsData: (payload) => ({
    type: SET_LEADS_CLIENT_DATA,
    payload: { response: [] },
  }),
};

export const useLeadsClientInfoCard = (pageSize = 24) => {
  const dispatch = useDispatch();
  const { apiClient } = useApiClient();
  const [archiveInActiveReason, setArchiveInActiveReason] = useState("");
  const userInfo = useAuthUser();
  const actions = useActions(actionCreators);

  const { leads } = useSelector((state) => state.clientManagement);

  const accpetServiceRequest = (serviceRequestId, onResult) => {
    const clientName = leads.response.find(
      (ele) => ele.serviceRequestId === serviceRequestId
    ).name;
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .put(
        `/service-provider/client/service-request/accept/${serviceRequestId}`
      )
      .then(function (response) {
        if (response.data.status === 200) {
          dispatch({
            type: DROP_REQUEST,
            payload: { serviceRequestId, tabName: "leads" },
          });
          dispatch({
            type: "SET_TOASTER",
            payload: {
              message: `Lead Accepted - ${clientName}`,
              bgColor: color_success,
              open: true,
            },
          });
          onResult && onResult(response);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const declineServiceRequest = (
    serviceRequestId,
    message,
    actionType,
    onResult
  ) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .put(`/home-buyer/sp/service-request/archive/${serviceRequestId}`, {
        archiveInActiveReason,
        actionType,
      })
      .then(function (response) {
        if (response.data.status === 200) {
          onResult && onResult(response);
          dispatch({
            type: DROP_REQUEST,
            payload: { serviceRequestId, tabName: "leads" },
          });
          dispatch({
            type: "SET_TOASTER",
            payload: {
              message,
              bgColor: color_info,
              open: true,
            },
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getLeadsClientData = (page = 0) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(
        `/service-provider/client/service-request/leads/${userInfo.userId}?page=${page}&size=${pageSize}`
      )
      .then(function (response) {
        if (response.data.response && response.data.response.length) {
          const data = processResponse(response.data);
          dispatch({
            type: SET_LEADS_CLIENT_DATA,
            payload: data,
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const processResponse = (data) => {
    data.response = data.response.length
      ? data.response.map((res) => {
          return { ...res.hbDetails, ...res };
        })
      : [];
    return data;
  };

  return {
    accpetServiceRequest,
    declineServiceRequest,
    archiveInActiveReason,
    setArchiveInActiveReason,
    getLeadsClientData,
    actions,
    leads,
  };
};
