import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";
import { DialogActions } from "@mui/material";
import { useLogout } from "souqh-react-redux-hooks/useLogin";
import { useHistory } from "react-router-dom";

function LogoutMsgDialog({
  openLogoutMsgDialog,
  setOpenLogoutMsgDialog,
  isInvoicePage,
}) {
  const { logout } = useLogout();
  let history = useHistory();

  return (
    <Dialog
      fullWidth
      disableBackdropClick
      open={openLogoutMsgDialog}
      onClose={() => {
        setOpenLogoutMsgDialog(false);
      }}
    >
      <DialogContent>
        <Grid item xs={12} style={{ paddingBottom: 16 }}>
          <Typography variant="body1" className={Styles.navyBlueColor}>
            You have changed your current plan. Please re-login again to view
            the features as per your plan
          </Typography>
        </Grid>
      </DialogContent>
      {isInvoicePage && (
        <DialogActions>
          <Button
            variant="contained"
            onClick={() =>
              logout((res) => {
                if (res.data.status === 200) {
                  history.push("/login");
                }
              })
            }
            className={Styles.primaryBtnAccent}
            style={{ textTransform: "capitalize" }}
          >
            Logout
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default LogoutMsgDialog;
