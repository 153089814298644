import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";

const styles = makeStyles((theme) => ({
  selectedIcon: {
    fontWeight: "bold",
    backgroundColor: "#FA7E61",
  },
  nonSelectedIcon: {
    backgroundColor: "white",
  },
  default: {
    marginLeft: 7,
    padding: "1px 12px 1px 12px",
    borderRadius: "4px",
    fontWeight: "bold",
  },
  selectedText: {
    color: "#FA7E61 !important",
  },
  defaultText: {
    color: "white !important",
  },
}));

export default function NewCampaignDialogHeader({ activeSection }) {
  const classes = styles();
  return (
    <Grid
      item
      xs={12}
      className={Styles.primaryBackground}
      style={{
        height: 60,
        display: "flex",
        alignItems: "center",
        position: "fixed",
        right: 16,
        left: 16,
        zIndex: 1500,
      }}
    >
      <List style={{ width: "100%" }}>
        <Grid container style={{ padding: "2px 14%" }}>
          <Grid item xs={4}>
            <ListItem
              button
              className={clsx({
                [classes.selectedText]: activeSection >= 1,
              })}
              style={{ cursor: "default" }}
            >
              <ListItemIcon>
                <span
                  className={clsx(classes.default, Styles.navyBlueColor, {
                    [classes.selectedIcon]: activeSection >= 1,
                  })}
                >
                  1
                </span>
              </ListItemIcon>
              <ListItemText
                primary="Set Target Audience"
                primaryTypographyProps={{ variant: "inherit" }}
              />
            </ListItem>
          </Grid>
          <Grid item xs={4}>
            <ListItem
              button
              className={clsx({
                [classes.selectedText]: activeSection >= 2,
                [classes.defaultText]: activeSection < 2,
              })}
              style={{ cursor: "default" }}
            >
              <ListItemIcon>
                <span
                  className={clsx(classes.default, Styles.navyBlueColor, {
                    [classes.selectedIcon]: activeSection >= 2,
                    [classes.nonSelectedIcon]: activeSection < 2,
                  })}
                >
                  2
                </span>
              </ListItemIcon>
              <ListItemText
                primary="Define Budget & Schedule"
                primaryTypographyProps={{ variant: "inherit" }}
              />
            </ListItem>
          </Grid>
          <Grid item xs={4}>
            <ListItem
              button
              className={clsx({
                [classes.selectedText]: activeSection === 3,
                [classes.defaultText]: activeSection !== 3,
              })}
              style={{ cursor: "default" }}
            >
              <ListItemIcon>
                <span
                  className={clsx(classes.default, Styles.navyBlueColor, {
                    [classes.selectedIcon]: activeSection === 3,
                    [classes.nonSelectedIcon]: activeSection !== 3,
                  })}
                >
                  3
                </span>
              </ListItemIcon>
              <ListItemText
                primary="Review Campaign"
                primaryTypographyProps={{ variant: "inherit" }}
              />
            </ListItem>
          </Grid>
        </Grid>
      </List>
    </Grid>
  );
}
