import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useApiClient } from "../useApiClient";
import { color_success } from "../toasterColors";

export const useTeamManagement = () => {
  const dispatch = useDispatch();
  const { apiClient } = useApiClient();

  const [searchTeamMemberText, setSearchTeamMemberText] = useState("");

  // for non premium members
  const [displayTeamMembersData, setDisplayTeamMembersData] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  // for premium members
  const [displayPremiumMembersData, setDisplayPremiumMembersData] = useState(
    []
  );
  const [originalPremiumMembersData, setOriginalPremiumMembersData] = useState(
    []
  );

  // for pending memmbers
  const [displayPendingMembersData, setDisplayPendingMembersData] = useState(
    []
  );
  const [originalPendingMembersData, setOriginalPendingMembersData] = useState(
    []
  );

  const [searchedRole, setSearchedRole] = useState("");

  const { nonPremiumTeamMembersList, pendingInvites, premiumTeamMembersList } =
    useSelector((state) => state.clientManagement.tagMembers);

  useEffect(() => {
    setSearchTeamMemberText("");
    setDisplayTeamMembersData(nonPremiumTeamMembersList);
    setOriginalData(nonPremiumTeamMembersList);
    setDisplayPremiumMembersData(premiumTeamMembersList);
    setOriginalPremiumMembersData(premiumTeamMembersList);
    setDisplayPendingMembersData(pendingInvites);
    setOriginalPendingMembersData(pendingInvites);
  }, [nonPremiumTeamMembersList, premiumTeamMembersList, pendingInvites]);

  useEffect(() => {
    if (!searchedRole) {
      setDisplayTeamMembersData(nonPremiumTeamMembersList);
      setDisplayPremiumMembersData(premiumTeamMembersList);
      setDisplayPendingMembersData(pendingInvites);
    }
  }, [searchedRole]);

  const requestTeamMembersSearch = (searchedVal) => {
    setSearchTeamMemberText(searchedVal);
    let filteredRows = [];
    originalData &&
      originalData.length &&
      originalData.map((row) => {
        if (
          row.fullName.toLowerCase().includes(searchedVal.toLowerCase()) ||
          row.email.toLowerCase().includes(searchedVal.toLowerCase())
        ) {
          filteredRows.push(row);
        }
      });
    setDisplayTeamMembersData(filteredRows);

    let filteredPremiumRows = [];
    originalPremiumMembersData &&
      originalPremiumMembersData.length &&
      originalPremiumMembersData.map((row) => {
        if (
          row.fullName.toLowerCase().includes(searchedVal.toLowerCase()) ||
          row.email.toLowerCase().includes(searchedVal.toLowerCase())
        ) {
          filteredPremiumRows.push(row);
        }
      });
    setDisplayPremiumMembersData(filteredPremiumRows);

    let filteredPendingRows = [];
    originalPendingMembersData &&
      originalPendingMembersData.length &&
      originalPendingMembersData.map((row) => {
        if (
          row.fullName.toLowerCase().includes(searchedVal.toLowerCase()) ||
          row.email.toLowerCase().includes(searchedVal.toLowerCase())
        ) {
          filteredPendingRows.push(row);
        }
      });
    setDisplayPendingMembersData(filteredPendingRows);
  };

  const requestTeamMembersSearchByRole = (searchedVal) => {
    let temp = searchedVal && searchedVal.split(" ").join("-").toLowerCase();
    if (searchedVal && searchedVal === "Admin") {
      temp = "service-provider";
    }
    let filteredRows = [];
    originalData &&
      originalData.length &&
      originalData.map((row) => {
        if (row.roles.includes(temp)) {
          filteredRows.push(row);
        }
      });
    setDisplayTeamMembersData(filteredRows);

    let filteredPremiumRows = [];
    originalPremiumMembersData &&
      originalPremiumMembersData.length &&
      originalPremiumMembersData.map((row) => {
        if (row.roles.includes(temp)) {
          filteredPremiumRows.push(row);
        }
      });
    setDisplayPremiumMembersData(filteredPremiumRows);

    let filteredPendingRows = [];
    originalPendingMembersData &&
      originalPendingMembersData.length &&
      originalPendingMembersData.map((row) => {
        if (row.roles.includes(temp)) {
          filteredPendingRows.push(row);
        }
      });
    setDisplayPendingMembersData(filteredPendingRows);
  };

  const resendInviteMail = (inviteId) => {
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .put(`/service-provider/team-member/resend/invite-email/${inviteId}`)
      .then((res) => {
        if (res && res.data.status === 200) {
          console.log(res);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const removeTeamMember = (userId, isPremium, onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .delete(`/service-provider/team-member/remove-tm/${userId}`, {
        data: {
          isPremium,
        },
      })
      .then((res) => {
        if (res && res.data.status === 200) {
          onResult && onResult(res);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const removePremiumMember = (token, flag, user, onResult) => {
    if (user) {
      dispatch({ type: "SET_LOADING", value: true });
      apiClient
        .delete(
          `/service-provider/team-member/remove-tm/confirm/${token}/${flag}`,
          {
            headers: {
              Authorization: user.access_token,
            },
          }
        )
        .then((res) => {
          if (res && res.data.status === 200) {
            dispatch({
              type: "SET_TOASTER",
              payload: {
                message: res.data.message,
                bgColor: color_success,
                open: true,
              },
            });
          }
          onResult && onResult(res);
        })
        .catch((error) => {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const updateTeamMember = (data, onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .put(`/service-provider/team-member/edit-tm`, data)
      .then((res) => {
        if (res && res.data.status === 200) {
          onResult && onResult(res);
          dispatch({
            type: "SET_TOASTER",
            payload: {
              message: res.data.message,
              bgColor: color_success,
              open: true,
            },
          });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    displayTeamMembersData,
    requestTeamMembersSearch,
    searchedRole,
    setSearchedRole,
    searchTeamMemberText,
    nonPremiumTeamMembersList,
    pendingInvites,
    premiumTeamMembersList,
    displayPremiumMembersData,
    displayPendingMembersData,
    resendInviteMail,
    requestTeamMembersSearchByRole,
    removeTeamMember,
    removePremiumMember,
    updateTeamMember,
  };
};
