import { useSelector, useDispatch } from "react-redux";
import { useApiClient } from "../../useApiClient";
import { SET_CLIENT_HISTORY } from "../../../souqh-redux/reducers/actionConstants";
import {
  covertTimeStampToDate,
  getDateFromTimestamp,
  getTimeFromTimestamp,
} from "../../../web/src/utils/AppUtils";

const PAGE_SIZE = 10;
export const useClientsHubTab = (userId, serviceRequestId) => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();

  const { clientHistory } = useSelector((state) => state.clientHub);

  const getClientHistory = (page = 0, pageSize = PAGE_SIZE) => {
    if (userId && serviceRequestId) {
      dispatch({ type: "SET_LOADING", value: true });
      return apiClient
        .get(
          `/client/history?page=${page}&serviceRequestId=${serviceRequestId}&size=${pageSize}&userId=${userId}`
        )
        .then(function (response) {
          if (response.data.response && response.data.response.length) {
            dispatch({
              type: SET_CLIENT_HISTORY,
              payload: response.data,
            });
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const handlePageChange = (event, page) => {
    getClientHistory(page - 1);
  };

  const getDescription = (description, dueDate, eventFromDateTime) => {
    let replacedDescription;
    if (description && description.includes("due_date")) {
      replacedDescription = description.replace(
        "due_date",
        covertTimeStampToDate(dueDate)
      );
    } else {
      replacedDescription =
        description &&
        description.replace(
          "date_value",
          getDateFromTimestamp(eventFromDateTime)
        );
      replacedDescription =
        replacedDescription &&
        replacedDescription.replace(
          "time_value",
          getTimeFromTimestamp(eventFromDateTime)
        );
    }
    return replacedDescription;
  };

  return {
    getClientHistory,
    clientHistory,
    getDescription,
    handlePageChange,
  };
};
