import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Grid,
  Button,
  Typography,
  TextField,
  Paper,
  Tabs,
  Tab,
  IconButton,
  InputAdornment,
  SwipeableDrawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  Link,
  Tooltip,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import { TAB_NAMES } from "./clientManagementConstants";
import clsx from "clsx";
import { useClientManagement } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useClientManagement";
import ClientInfoGridView from "./ClientInfoGridView";
import InviteClientsDialog from "../../ReferralManagement/InviteClientsDialog";
import ClientInfoTableView from "./ClientInfoTableView";
import GridOnIcon from "@material-ui/icons/GridOn";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import SearchIcon from "@material-ui/icons/Search";
import { useSQQuery } from "souqh-react-redux-hooks/common/useSQQuery";
import { useHistory } from "react-router-dom";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreTrafficDialog from "../Dashboard/MoreTrafficDialog";
import AddIcon from "@material-ui/icons/Add";
import { useTagSomeone } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useTagSomeone";
import NoDataAvailable from "../../common/NoDataAvailable";
import SearchNotFound from "../../common/SearchNotFound";

const useStyles = makeStyles((theme) => ({
  marginTop20: {
    marginTop: 20,
  },
  tabs: {
    minWidth: "16%",
    fontWeight: "bold",
    textTransform: "capitalize",
    opacity: "100%",
    fontSize: 14,
  },
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#0e1c4e",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0e1c4e",
      },
    },
  },
  modal: {
    zIndex: "1400 !important",
  },
  paper: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
}));

export default function ClientManagement() {
  const classes = useStyles();
  const {
    openInviteClientsDialog,
    setOpenInviteClientsDialog,
    searchText,
    requestClientSearch,
    displayClientsData,
    clientsTab,
    clientMgmtData,
    getClientManagementData,
    actions: { setClientsTab },
    handlePageChange,
    getDataOnTabChange,
    getActiveClientData,
    storeFrontRouteName,
    storeFrontId,
  } = useClientManagement();

  const [tableView, setTableView] = useState(false);
  const [tabDrawer, setTabDrawer] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Active");
  const [openMoreTrafficDialog, setopenMoreTrafficDialog] = useState(false);

  const { query } = useSQQuery();
  const history = useHistory();
  const { getTagMembers } = useTagSomeone();

  useEffect(() => {
    let unlisten = history.listen((location, action) => {
      let tab = location.search.split("=");
      setClientsTab(parseInt(tab[tab.length - 1]));
    });
    const tabIndex = query.get("tabIndex");
    if (tabIndex) {
      if (
        tabIndex === "0" ||
        tabIndex === "1" ||
        tabIndex === "2" ||
        tabIndex === "3"
      ) {
        setClientsTab(parseInt(tabIndex));
      } else {
        setClientsTab(0);
      }
    } else {
      setClientsTab(0);
    }

    getClientManagementData();
    getTagMembers();

    window.scrollTo(0, 0); // scroll at top of page on load
    return () => {
      setClientsTab(null);
      unlisten();
    };
  }, []);

  useEffect(() => {
    getDataOnTabChange();
  }, [clientsTab]);

  const moreActionsList = () => (
    <div
      className={classes.fullList}
      role="presentation"
      onClick={() => {
        setTabDrawer(false);
      }}
      onKeyDown={() => {
        setTabDrawer(false);
      }}
    >
      <Grid container item xs={12} style={{ padding: 16 }}>
        <Grid item xs={10}>
          <Typography variant="subtitle1" className={Styles.navyBlueColorForce}>
            More Information
          </Typography>
        </Grid>
        <Grid container item xs={2} justifyContent="flex-end">
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              setTabDrawer(false);
            }}
          />
        </Grid>
      </Grid>
      <Divider />
      <List>
        {TAB_NAMES.map((text, index) => (
          <ListItem
            key={index}
            onClick={() => {
              setSelectedTab(text.value);
              setClientsTab(index);
            }}
          >
            <ListItemText
              primary={text.value}
              className={Styles.navyBlueColorForce}
              style={{ fontSize: 14 }}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const getNoResultInfo = () => {
    if (clientsTab === 0) {
      return (
        <Typography style={{ color: "#868DA6" }}>
          Looks like you are not working with any active clients. Click{" "}
          <Link
            style={{ cursor: "pointer" }}
            onClick={() => setOpenInviteClientsDialog(true)}
          >
            here
          </Link>{" "}
          to invite your clients to join you on Souqh
        </Typography>
      );
    } else {
      return (
        <Typography style={{ color: "#868DA6" }}>
          Looks like you do not have any leads at this time. Click{" "}
          <Link
            style={{ cursor: "pointer" }}
            onClick={() => setopenMoreTrafficDialog(true)}
          >
            here
          </Link>{" "}
          to drive more traffic by sharing your storefront
        </Typography>
      );
    }
  };

  return (
    <Grid container>
      <SwipeableDrawer
        classes={{ modal: classes.modal, paper: classes.paper }}
        anchor="bottom"
        open={tabDrawer}
        onClose={() => {
          setTabDrawer(false);
        }}
        onOpen={() => {
          setTabDrawer(true);
        }}
      >
        {tabDrawer && moreActionsList()}
      </SwipeableDrawer>
      <MoreTrafficDialog
        open={openMoreTrafficDialog}
        setopenMoreTrafficDialog={setopenMoreTrafficDialog}
        storeFrontId={storeFrontId}
        storeFrontRouteName={storeFrontRouteName}
      />
      <InviteClientsDialog
        openInviteClientsDialog={openInviteClientsDialog}
        setOpenInviteClientsDialog={setOpenInviteClientsDialog}
        srcPage="clientManagement"
        getActiveClientData={getActiveClientData}
      />
      <Grid container item xs={12}>
        <Grid item xs={9} lg={12}>
          <Typography
            variant={isMobileDevice() ? "h6" : "h4"}
            className={Styles.navyBlueColor}
          >
            Clients
          </Typography>
        </Grid>
        {isMobileDevice() && (
          <Grid container item xs={3} justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={() => setOpenInviteClientsDialog(true)}
              className={Styles.primaryBtnAccent}
            >
              <AddIcon style={{ height: 30, width: 30 }} />
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} className={classes.marginTop20}>
        <Grid item xs={12} md={10} lg={8}>
          <TextField
            value={searchText}
            variant="outlined"
            fullWidth
            placeholder="Search your client here by name, email address or phone number"
            style={{ backgroundColor: "#FFFFFF" }}
            onChange={(event) => {
              requestClientSearch(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon className={Styles.navyBlueColor} />
                </InputAdornment>
              ),
            }}
            classes={{ root: classes.root }}
          ></TextField>
        </Grid>
        {!isMobileDevice() && (
          <Grid
            container
            item
            xs={12}
            md={2}
            lg={4}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              variant="outlined"
              onClick={() => setOpenInviteClientsDialog(true)}
              className={Styles.primaryBtnAccent}
              style={{ textTransform: "capitalize", height: 40 }}
            >
              Add Client
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} style={{ marginTop: 8 }}>
        {isMobileDevice() ? (
          <Grid item container xs={12}>
            <Button
              fullWidth
              variant="outlined"
              className={Styles.navyBlueColorForce}
              style={{
                textTransform: "capitalize",
                marginTop: 12,
                padding: 12,
              }}
              onClick={() => setTabDrawer(true)}
              endIcon={<ExpandMoreIcon />}
            >
              {selectedTab}
            </Button>
          </Grid>
        ) : (
          <Paper elevation={0}>
            <Grid container item xs={12}>
              <Grid item xs={11} md={10}>
                <Tabs
                  value={clientsTab}
                  // onChange={(event, newValue) => {
                  //   setDocumentTab(newValue);
                  // }}
                  aria-label="client-management-tabs"
                  classes={{ indicator: Styles.indicator }}
                >
                  {TAB_NAMES.map((tab, index) => (
                    <Tab
                      key={index}
                      label={`${tab.value} (${clientMgmtData[tab.key].count})`}
                      className={clsx(classes.tabs, {
                        [Styles.primaryTxtAccentForce]: index === clientsTab,
                        [Styles.navyBlueColorForce]: index !== clientsTab,
                      })}
                      onClick={() => setClientsTab(index)}
                    />
                  ))}
                </Tabs>
              </Grid>

              <Grid
                item
                xs={1}
                md={2}
                container
                justifyContent="space-around"
                alignItems="center"
              >
                <Tooltip title="Coming Soon">
                  <img src="/images/filter.svg" />
                </Tooltip>
                <IconButton onClick={() => setTableView(!tableView)}>
                  {tableView ? (
                    <GridOnIcon color="#0e1c4e" />
                  ) : (
                    <FormatListBulletedIcon style={{ color: "#0e1c4e" }} />
                  )}
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Grid>
      {displayClientsData &&
      displayClientsData.response &&
      displayClientsData.response.length ? (
        <Grid
          item
          xs={12}
          className={classes.marginTop20}
          style={{ marginBottom: (isMobileDevice() || isTabletDevice()) && 92 }}
        >
          {tableView ? (
            <ClientInfoTableView
              displayClientsData={displayClientsData}
              tabValue={clientsTab}
              handlePageChange={handlePageChange}
              getClientManagementData={getClientManagementData}
            />
          ) : (
            <ClientInfoGridView
              displayClientsData={displayClientsData}
              tabValue={clientsTab}
              handlePageChange={handlePageChange}
              getClientManagementData={getClientManagementData}
            />
          )}
        </Grid>
      ) : searchText ? (
        <SearchNotFound functionality="clients" />
      ) : (clientsTab === 0 || clientsTab === 3) && !searchText ? (
        <NoDataAvailable isUserMgmtPage={true}>
          {getNoResultInfo()}
        </NoDataAvailable>
      ) : null}
    </Grid>
  );
}
