import {
  Grid,
  Tabs,
  Typography,
  Tab,
  makeStyles,
  TextField,
  Chip,
} from "@material-ui/core";
import React, { useEffect } from "react";
import Styles from "../../../Styles.module.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import ActiveCustomersTab from "./ActiveCustomersTab";
import { cloneDeep } from "lodash";
import { isMobileDevice } from "../../../utils/AppUtils";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";

const useStyles = makeStyles((theme) => ({
  tabs: {
    minWidth: "16%",
    fontSize: "1rem",
    fontWeight: "bold",
    textTransform: "capitalize",
    opacity: "100%",
  },
  tableContent: {
    fontSize: "1rem",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}
export default function SPSelectContactsToUpload({
  userType,
  searchText,
  setSearchText,
  tabs,
  activeTab,
  setActiveTab,
  contacts,
  setCustomContacts,
  customContacts,
  addItem,
  handleCustomerSelect,
  selectedActiveCustomers,
  selectedContacts,
  setSelectedContacts,
  selectAllCustomers,
  setSelectAllCustomers,
  getFilteredCustomers,
  selectDeselectAllCustomers,
  activeCustomers,
  hasLoadedList,
  setActiveCustomers,
  refreshSelectedActiveCustomers,
  removeFromOtherContacts,
}) {
  const classes = useStyles();

  useEffect(() => {
    if (hasLoadedList) {
      const tempList = cloneDeep(activeCustomers);
      if (selectAllCustomers) {
        tempList.forEach((item) => {
          item.selected = true;
        });
      } else {
        tempList.forEach((item) => {
          item.selected = false;
        });
      }
      setActiveCustomers(tempList);
    }
  }, [selectAllCustomers]);
  useEffect(() => {
    refreshSelectedActiveCustomers();
  }, [activeCustomers]);

  return (
    <Grid
      item
      container
      xs={12}
      style={{ padding: !isMobileDevice() && "0px 16px", height: "100%" }}
    >
      <Grid item xs={12}>
        <Typography
          varinat="body1"
          style={{ margin: "12px 0px" }}
          className={clsx(classes.tableContent, Styles.navyBlueColor)}
        >
          Select contacts to share the documents with
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => {
            setActiveTab(newValue);
          }}
          classes={{ indicator: Styles.indicator }}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab}
              className={clsx(classes.tabs, {
                [Styles.primaryTxtAccentForce]: index === activeTab,
                [Styles.navyBlueColorForce]: index !== activeTab,
              })}
            />
          ))}
        </Tabs>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          height: "calc(100% - 120px)",
          overflow:
            !selectedContacts.length && activeTab === 1 ? "hidden" : "auto",
        }}
      >
        <TabPanel value={activeTab} index={0}>
          <ActiveCustomersTab
            userType={userType}
            searchText={searchText}
            setSearchText={setSearchText}
            handleCustomerSelect={handleCustomerSelect}
            selectedActiveCustomers={selectedActiveCustomers}
            selectAllCustomers={selectAllCustomers}
            setSelectAllCustomers={setSelectAllCustomers}
            getFilteredCustomers={getFilteredCustomers}
            selectDeselectAllCustomers={selectDeselectAllCustomers}
            activeCustomers={activeCustomers}
          />
        </TabPanel>

        <TabPanel value={activeTab} index={1}>
          <Grid item xs={12} md={11} style={{ margin: "12px 0px" }}>
            <TextField
              fullWidth
              value={customContacts}
              variant="outlined"
              label={
                "Please enter email address of your contact and press enter"
              }
              onChange={(event) => {
                setCustomContacts(event.target.value);
              }}
              onKeyPress={(event) => addItem(event, customContacts)}
            />
          </Grid>
          <Grid item xs={12}>
            {selectedContacts && selectedContacts.length
              ? selectedContacts.map((item, index) => {
                  return (
                    <Chip
                      key={index}
                      icon={<PermIdentityIcon />}
                      variant="outlined"
                      label={item.email}
                      className={Styles.navyBlueColorForce}
                      onDelete={() => {
                        removeFromOtherContacts(item);
                      }}
                      style={{
                        marginRight: 8,
                        marginBottom: 4,
                        fontWeight: 600,
                      }}
                    />
                  );
                })
              : null}
          </Grid>
        </TabPanel>
      </Grid>
    </Grid>
  );
}
