import { useDispatch } from "react-redux";
import { useApiClient } from "../../useApiClient";
import { useAuthUser } from "../../useLogin";

export const useRequestProfileUpdate = (toUserId) => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();
  const userInfo = useAuthUser();

  const requestCompleteProfile = (serviceRequestId, onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .post("/pending-actions/request/complete-profile", {
        fromUserId: userInfo.userId,
        toUserId,
        serviceRequestId: serviceRequestId,
      })
      .then((res) => {
        onResult && onResult(res);
      })
      .catch((error) => {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    requestCompleteProfile,
  };
};
