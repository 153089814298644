import {
  Grid,
  makeStyles,
  Button,
  Container,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import React, { useEffect } from "react";
import ProfileReview from "./ProfileReview";
import { useHistory } from "react-router-dom";
import SetupFooter from "../SetupFooter";
import StoreFrontReview from "./StoreFrontReview";
import PaymentPlanReview from "./PaymentPlanReview";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";
import { useSpSetupReview } from "souqh-react-redux-hooks/review/useSpSetupReview";
import { APPROVED } from "../../ServiceProvider/serviceProviderConstants";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    paddingBottom: 70,
  },
  buttons: {
    textTransform: "capitalize",
    color: "#0E1C4E",
    backgroundColor: "#FA7E61",
    padding: "0px 30px",
  },
}));

function Review() {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { requestToAdmin } = useSpSetupReview();
  const userInfo = useAuthUser();

  const { selectedUserPlan } = useSelector((state) => state.subscriptionPlan);

  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  useEffect(() => {
    window.scrollTo(0, 0); // scroll at top of page on load
  }, []);

  return (
    <Grid container style={{ marginTop: 20 }}>
      <Container style={{ paddingBottom: 80 }} maxWidth={xlUp ? "lg" : "md"}>
        <Grid style={{ marginBottom: 48, marginTop: 84 }}>
          <Typography
            variant="h4"
            className={Styles.navyBlueColor}
            style={{ marginBottom: 8 }}
          >
            Please review the details below
          </Typography>
          <Typography variant="body1" className={Styles.navyBlueColor}>
            Make sure that you have entered the correct details
          </Typography>
        </Grid>
        <Grid container item className={classes.root}>
          <ProfileReview></ProfileReview>
          <StoreFrontReview />
          <PaymentPlanReview />
        </Grid>
      </Container>
      <SetupFooter
        review={true}
        setupPage="subscription"
        isMarketplaceplan={selectedUserPlan === 5 ? true : false}
      >
        {userInfo.spApprovalStatus !== APPROVED ? (
          <Button
            variant="contained"
            className={clsx(classes.buttons, Styles.primaryBtnAccent)}
            onClick={() => {
              requestToAdmin(() => {
                history.push("/setup-success");
              });
            }}
          >
            Submit
          </Button>
        ) : (
          <Button
            variant="contained"
            className={clsx(classes.buttons, Styles.primaryBtnAccent)}
            onClick={() => {
              history.push("/serviceprovider/dashboard");
            }}
          >
            Save
          </Button>
        )}
      </SetupFooter>
    </Grid>
  );
}

export default Review;
