export const passwordValidationMeta = {
  name: "password",
  patterns: [
    {
      match: (val) => /[^$]{8,}/.test(val),
      msg: "Minimum 8 Characters",
    },
    {
      match: (val) => /[a-z].*[A-Z]|[A-Z].*[a-z]/.test(val),
      msg: "1 UpperCase and 1 LowerCase",
    },
    {
      match: (val) => /[0-9]/.test(val),
      msg: "1 Number",
    },
    {
      match: (val) =>
        /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(val) &&
        !/[ <>]/.test(val),
      msg: "1 Special Character (no use of < & > or space)",
    },
  ],
};

export const emailValidationMeta = {
  name: "email",
  patterns: [
    {
      match: (val) =>
        /(^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)|(^$)/i.test(
          val
        ),
      msg: "Please enter a valid email.",
    },
  ],
};

export const TMFullnameValidationMeta = {
  name: "name",
  patterns: [
    {
      match: (val) => /^$|^(\w+\s)*\w+$/i.test(val),
      msg: "Please enter a valid fullname (only single space allowed)",
    },
  ],
};

export const mobileNumberValidationMeta = {
  name: "phoneNumber",
  patterns: [
    {
      match: (val) => /^(\s*|\d+)$/i.test(val),
      msg: "Please enter a valid mobile number.",
    },
  ],
};

export const buinessYrsAndEmployeeMeta = [
  {
    name: "yearsInBusiness",
    patterns: [
      {
        match: (val) => /^\d{1,3}(\.([0-9]|1[01]))*$/i.test(val),
        msg: "Please enter a valid number.",
      },
    ],
  },
  {
    name: "numberOfEmployees",
    patterns: [
      {
        match: (val) => /^[1-9]\d*$/i.test(val),
        msg: "Please enter a valid number.",
      },
    ],
  },
];
