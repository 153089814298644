import {
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Collapse,
  FormControlLabel,
  Checkbox,
  Link,
  ButtonBase,
} from "@material-ui/core";
import React, { useState } from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import DocumentStatus from "../../common/DocumentStatus";
import ViewDocumentDialog from "../../DocumentManagementDialogs/ViewDocumentDialog";
import SignDocumentDialog from "../UploadDocuments/SignDocumentDialog";
import {
  covertTimeStampToDate,
  isTabletDevice,
  isMobileDevice,
  stableSort,
  getComparator,
} from "../../../utils/AppUtils";

export default function ClientDocumentsRow({
  data,
  handleClick,
  order,
  orderBy,
}) {
  const [open, setOpen] = useState(false);
  const [openDocumetDialog, setOpenDocumetDialog] = useState(false);
  const [documentItem, setDocumentItem] = useState(null);
  const [openSignDocumentDialog, setOpenSignDocumentDialog] = useState(false);
  const [signDocumentItem, setSignDocumentItem] = useState(null);

  const getUploadedDocuments = (docDetails) => {
    if (!docDetails) {
      return [];
    }

    const fileName =
      decodeURIComponent(docDetails.docName.replace(/^.*[\\\/]/, "")) || "";

    let docObj = {
      name: fileName,
      path: docDetails.documentPreviewUrl,
      type: fileName.split(".").pop(),
      isReferenceDocument: docDetails.isReferenceDocument,
      lastChange: docDetails.lastChange,
      documentId: docDetails.docId,
      signNowDocumentId: docDetails.signNowDocumentId,
    };
    return docObj;
  };

  return (
    <React.Fragment>
      <ViewDocumentDialog
        openDocumetDialog={openDocumetDialog}
        setOpenDocumetDialog={setOpenDocumetDialog}
        documentItem={documentItem}
        setDocumentItem={setDocumentItem}
      ></ViewDocumentDialog>
      <SignDocumentDialog
        openSignDocumentDialog={openSignDocumentDialog}
        setOpenSignDocumentDialog={setOpenSignDocumentDialog}
        signDocumentItem={signDocumentItem}
        setSignDocumentItem={setSignDocumentItem}
      />
      <TableRow onClick={() => setOpen(!open)}>
        <TableCell
          className={clsx(Styles.navyBlueColorForce, Styles.font14)}
          style={{ fontWeight: "bold" }}
        >
          {data.name} ({data.documents && data.documents.length})
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            className={Styles.navyBlueColor}
          >
            {open ? (
              <KeyboardArrowUpIcon className={Styles.navyBlueColor} />
            ) : (
              <KeyboardArrowDownIcon className={Styles.navyBlueColor} />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" style={{ tableLayout: "fixed", width: "100%" }}>
              <TableBody>
                {data.documents && data.documents.length
                  ? stableSort(
                      data.documents,
                      getComparator(order, orderBy)
                    ).map((row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            style={{
                              width:
                                isTabletDevice() || isMobileDevice()
                                  ? "18em"
                                  : "21%",
                              overflow: "hidden",
                              paddingRight: 0,
                            }}
                          >
                            {/* <FormControlLabel
                            control={<Checkbox />}
                            label={ */}
                            {row.enableDoc ? (
                              <Link
                                underline="always"
                                color="inherit"
                                style={{
                                  wordBreak: "break-all",
                                  fontSize: 16,
                                  cursor: "pointer",
                                }}
                                className={Styles.navyBlueColorForce}
                                onClick={() => {
                                  if (row.enableUrl && row.signNowDocumentId) {
                                    setSignDocumentItem(row);
                                    setOpenSignDocumentDialog(true);
                                  } else {
                                    setDocumentItem(getUploadedDocuments(row));
                                    setOpenDocumetDialog(true);
                                  }
                                }}
                              >
                                {row.docName}
                              </Link>
                            ) : (
                              <Typography style={{ color: "#868DA6" }}>
                                {row.docName}
                              </Typography>
                            )}
                            {/* }
                          /> */}
                          </TableCell>
                          <TableCell
                            style={{
                              width:
                                isTabletDevice() || isMobileDevice()
                                  ? "18em"
                                  : "30%",
                              paddingRight: 6,
                            }}
                          >
                            <Typography
                              variant="body1"
                              className={clsx(
                                Styles.sqEllipsis,
                                Styles.navyBlueColor
                              )}
                            >
                              {row.docDescription}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              width:
                                isTabletDevice() || isMobileDevice()
                                  ? "9em"
                                  : "16%",
                            }}
                          >
                            <DocumentStatus docStatus={row.docStatus} />
                          </TableCell>
                          <TableCell
                            style={{
                              width:
                                isTabletDevice() || isMobileDevice()
                                  ? "10em"
                                  : "12%",
                            }}
                          >
                            <Typography
                              variant="body1"
                              className={Styles.navyBlueColor}
                            >
                              {covertTimeStampToDate(row.lastChange)}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              width:
                                isTabletDevice() || isMobileDevice()
                                  ? "2em"
                                  : "3%",
                            }}
                          >
                            {!row.enableDoc ? (
                              <ButtonBase>
                                <MoreHorizIcon
                                  className={Styles.navyBlueColor}
                                  onClick={(event) => {
                                    handleClick(
                                      event,
                                      row.docId,
                                      row.signNowDocumentId
                                    );
                                  }}
                                />
                              </ButtonBase>
                            ) : (
                              <MoreHorizIcon
                                style={{
                                  color: "#868DA6",
                                }}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
