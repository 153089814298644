import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  useTheme,
  useMediaQuery,
  Typography,
  Drawer,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DashboardCard from "../../DashboardCard";
import DashboardChip from "../../DashboardChip";
import DocumentUpload from "./DocumentUpload";
import clsx from "clsx";
import Styles from "../../../Styles.module.css";
import { useDocuments } from "souqh-react-redux-hooks/serviceProvider/documents/useDocuments";
import SPeSignWizard from "../UploadDocuments/SPeSignWizard";
import SPUploadWizard from "../../common/UploadWizard/SPUploadWizard";
import InviteSPDialog from "../../ReferralManagement/InviteSPdialog";
import InviteClientsDialog from "../../ReferralManagement/InviteClientsDialog";
import { useSPUploadWizard } from "souqh-react-redux-hooks/serviceProvider/documents/useSPUploadWizard";
import { useSPeSignWizard } from "souqh-react-redux-hooks/serviceProvider/documents/useSPeSignWizard";
import AddTemplate from "../UploadDocuments/AddTemplate/AddTemplate";
import { useAddTemplate } from "souqh-react-redux-hooks/serviceProvider/documents/useAddTemplate";
import MoreTrafficDialog from "./MoreTrafficDialog";
import { useHistory } from "react-router-dom";
import ActiveClientsWidget from "./ActiveClientsWidget";
import LeadsWidget from "./LeadsWidget";
import UpcomingEventsWidget from "../../common/UpcomingEventsWidget";
import PendingActionsWidget from "../../common/PendingActionsWidget";
import ClientReviewsWidget from "./ClientReviewsWidget";
import StoreFrontWidget from "./StoreFrontWidget";
import { useUpcomingEeventsPendingActions } from "souqh-react-redux-hooks/serviceProvider/upcomingEeventsPendingActions/useUpcomingEeventsPendingActions";
import HelpCenterWidget from "../../common/HelpCenterWidget";
import { useSQCalendar } from "souqh-react-redux-hooks/common/useSQCalendar";
import SmallCalendarWidget from "../../common/SQCalender/SmallCalendarWidget";
import CreateMeetingDialog from "../../common/SQCalender/CreateMeetingDialog";
import { MEETING_CREATE_MODE } from "../serviceProviderConstants";
import { useUpcomingEvents } from "souqh-react-redux-hooks/serviceProvider/upcomingEeventsPendingActions/useUpcomingEvents";
import ShareIcon from "@material-ui/icons/Share";
import {
  isMobileDevice,
  isOnboardingInComplete,
  isProPlan,
  isSpApprovalPending,
  isTabletDevice,
  isMarketplacePlan,
  getMetadataInfo,
} from "../../../utils/AppUtils";
import SPKeyStatisticsWidget from "./SPKeyStatisticsWidget";
import { useStorefrontReview } from "souqh-react-redux-hooks/review/useStorefrontReview";
import { useDispatch, useSelector } from "react-redux";
import CompleteOnboardingDailog from "../../common/CompleteOnboardingDailog";
import MarketingCampaignsWidget from "./MarketingCampaignsWidget";
import NewCampaignDialog from "../MarketingCampaign/NewCampaignDialog";
import NewCampaignStep1 from "../MarketingCampaign/NewCampaignStep1";
import NewCampaignStep2 from "../MarketingCampaign/NewCampaignStep2";
import NewCampaignStep3 from "../MarketingCampaign/NewCampaignStep3";
import NewCampaignDialogFooter from "../MarketingCampaign/NewCampaignDialogFooter";
import CloseIcon from "@material-ui/icons/Close";
import { useMarketingCampaign } from "souqh-react-redux-hooks/serviceProvider/marketingCampaign/useMarketingCampaign";
import ConfirmationDialog from "../../ConfirmationDialog";
import PlanUpgradeMsgDialog from "../../common/PlanUpgradeMsgDialog";
import {
  SET_HAVE_PROMO_CODE,
  SET_PROMO_CODE,
} from "souqh-redux/reducers/actionConstants";
import { useCalendarSync } from "souqh-react-redux-hooks/calendarSync/useCalendarSync";
import * as moment from "moment";

const useStyles = makeStyles((theme) => ({
  grid: {
    marginBottom: 20,
  },
  cardBackgroundImg: {
    backgroundImage: "url('/images/teal_bg_referral.png')",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: 430,
  },
  buttons: {
    textTransform: "capitalize",
    fontWeight: "bold",
    fontSize: "1rem",
  },
  buttonGrids: {
    textAlign: "center",
    borderRadius: 8,
    backgroundColor: "white",
  },
  imageStyle: {
    height: 272,
    width: "100%",
  },
  buttonBase: {
    height: "100%",
    display: "block",
  },
  buttonBaseBorder: {
    borderRight: "1px solid #0E1C4E0A",
  },
  innerGrids: {
    textAlign: "center",
    marginTop: 18,
  },
  uploadBtn: {
    borderRadius: 6,
    textTransform: "capitalize",
  },
  paper: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    maxHeight: "90%",
    minHeight: "90%",
  },
}));

function StorefrontWidgetSections({
  history,
  classes,
  setopenMoreTrafficDialog,
  services,
  displayPicture,
  storefrontName,
  userInfo,
  openPlanUpgradeMsgDialog,
  setOpenPlanUpgradeMsgDialog,
}) {
  return (
    <Grid item xs={12} md={6} lg={3} className={classes.grid}>
      <DashboardCard
        title="Storefront"
        redirectTo={() => {
          if (isMarketplacePlan(userInfo)) {
            setOpenPlanUpgradeMsgDialog(true);
          } else {
            history.push("/serviceprovider/storefront");
          }
        }}
      >
        <StoreFrontWidget
          setopenMoreTrafficDialog={setopenMoreTrafficDialog}
          services={services}
          displayPicture={displayPicture}
          storefrontName={storefrontName}
          userInfo={userInfo}
          openPlanUpgradeMsgDialog={openPlanUpgradeMsgDialog}
          setOpenPlanUpgradeMsgDialog={setOpenPlanUpgradeMsgDialog}
        />
      </DashboardCard>
    </Grid>
  );
}

function HelpCenterWidgetsection({
  openPlanUpgradeMsgDialog,
  setOpenPlanUpgradeMsgDialog,
  userInfo,
}) {
  return (
    <Grid item xs={12} md={6} lg={3} style={{ marginBottom: 80 }}>
      <DashboardCard title="Get Help">
        <HelpCenterWidget
          openPlanUpgradeMsgDialog={openPlanUpgradeMsgDialog}
          setOpenPlanUpgradeMsgDialog={setOpenPlanUpgradeMsgDialog}
          userInfo={userInfo}
        />
      </DashboardCard>
    </Grid>
  );
}

export default function SPDashboard() {
  const classes = useStyles();
  const theme = useTheme();
  let history = useHistory();
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const dispatch = useDispatch();

  const [openInviteSPDialog, setOpenInviteSPDialog] = useState(false);
  const [openInviteClientsDialog, setOpenInviteClientsDialog] = useState(false);
  const [openNewCampaignDialog, setOpenNewCampaignDialog] = useState({
    open: false,
    data: null,
  });
  const [tabDrawer, setTabDrawer] = useState(false);
  const [activeSection, setActiveSection] = useState(1);
  const [openCloseConfirmationDialog, setOpenCloseConfirmationDialog] =
    useState(false);

  const { updateIntercomStatus, getShowDialogFlag } = useDocuments();

  const {
    onUploadFileChange,
    openUploadWizard,
    actions: { setOpenUploadWizard, cleanUploadFileDataOnClose },
  } = useSPUploadWizard();

  const {
    onUploadTemplateFileChange,
    openAddTemplate,
    actions: { setOpenAddTemplate },
    closeAndFinishAddTemplate,
  } = useAddTemplate();

  const {
    processEsignUploadedFiles,
    onUploadEsignFileChange,
    openEsignWizard,
    actions: { setOpenEsignWizard },
    closeAndFinishEsign,
  } = useSPeSignWizard();

  const [openMoreTrafficDialog, setopenMoreTrafficDialog] = useState(false);

  const {
    actions: { setDocumentTab },
  } = useDocuments();

  const {
    actions: { setEventsTab },
    getAllPendingActions,
    pendingActionsList,
    handlePendingAction,
    openAddMemberDialog,
    setOpenAddMemberDialog,
    openInviteSPDialogForPendingAction,
    setOpenInviteSPDialogForPendingAction,
    openViewTagDialog,
    setOpenViewTagDialog,
    viewTagDialogData,
    openSignDocumentDialog,
    setOpenSignDocumentDialog,
    signDocumentItem,
    setSignDocumentItem,
    setEventIndex,
    reloadPendingActionData,
    deletePendingAction,
    openDocumetDialog,
    setOpenDocumetDialog,
    documentItem,
    setDocumentItem,
    downloadUrl,
    docCategoryId,
    docNameForDownload,
    openRecommendedSPDialog,
    setOpenRecommendedSPDialog,
    recommendedSpData,
    openDocumentAccessRequestDialog,
    setOpenDocumentAccessRequestDialog,
    requestedDocAccessName,
    documentAccessRequestorSP,
    acceptDocumentAccessRequest,
    docAccessRequestedData,
    openRequestedDocumentDialog,
    setOpenRequestedDocumentDialog,
    requestedDocumentData,
    openAddReviewDialog,
    setOpenAddReviewDialog,
    openHomeBuyingJourneyDialog,
    setOpenHomeBuyingJourneyDialog,
    openViewInvoiceDialog,
    setOpenViewInvoiceDialog,
    viewInvoiceDialogData,
  } = useUpcomingEeventsPendingActions();

  const {
    upcomingEventsList,
    getUpcomingCalendarEvents,
    getAttendeesTooltip,
    handleEventDialogClose,
    cancelEvent,
    eventSelected,
    setEventSelected,
    clearUpcomingEventsDataDestroy,
  } = useUpcomingEvents();

  const {
    actions: {
      setOpenCreateMeetingDialog,
      setMeetingMode,
      setOpenConfirmCanelMeetingDialog,
    },
    currentCalendarDate,
    setCurrentCalendarDate,
    calendarEvents,
    openCreateMeetingDialog,
    userInfo,
    meetingMode,
    selectedCalendarEvent,
    cancelMeeting,
    title,
    setTitle,
    meetingDate,
    setMeetingDate,
    meetingLink,
    meetingPhoneNumber,
    meetingLocation,
    setMeetingLocation,
    meetingAttachments,
    description,
    setDescription,
    uploadRef,
    textAreaRef,
    contactInputRef,
    handleDialogClose,
    processAttachment,
    removeAttachment,
    copyToClipboard,
    meetingStartTime,
    setMeetingStartTime,
    meetingEndTime,
    setMeetingEndTime,
    addCustomItem,
    selectedContacts,
    customContact,
    setCustomContact,
    allContacts,
    removeSelectedContact,
    addItemOnSelect,
    getAllContacts,
    invalidTimes,
    setInvalidTimes,
    setMeetingData,
    removeMeetingLink,
    addMeetingLink,
    setAddMeetingLink,
    setupEmptyData,
    saveMeeting,
    validateTimeSlot,
    isFullDayEvent,
    setIsFullDayEvent,
    getCalendarEvents,
    eventUserId,
    hasFilledMandatoryData,
    updateMeetingStatus,
    downlodAttachment,
    clearCalendarDataDestroy,
    openConfirmCanelMeetingDialog,
    filteredContacts,
    downloadCalendarAttachment,
    getCalendarEventDetails,
    getAllCalendarEvents,
    setMainCalendarView,
    mainCalendarView,
    setStartDateTimestamp,
    setEndDateTimestamp,
    startDateTimestamp,
    endDateTimestamp,
  } = useSQCalendar({
    calendarListUpdater: true,
    upcomingEventsUpdater: getUpcomingCalendarEvents,
  });

  const {
    connectToSocket,
    disconnectFromSocket,
    eventType,
    getAccountDetails,
    accountDetails,
  } = useCalendarSync();

  const { getStorefrontDetails, fullSFDetails } = useStorefrontReview();

  useEffect(() => {
    window.scrollTo(0, 0);
    setOpenCreateMeetingDialog(false);
    getShowDialogFlag();
    // getCalendarEvents();
    getAllCalendarEvents();
    getUpcomingCalendarEvents(0);
    getAllContacts();
    getStorefrontDetails();
    getAccountDetails();
    return () => {
      closeAndFinishAddTemplate();
      closeAndFinishEsign();
      cleanUploadFileDataOnClose();
      disconnectFromSocket();
    };
  }, []);

  useEffect(() => {
    connectToSocket();
  }, [accountDetails]);

  useEffect(() => {
    if (!userInfo) {
      clearUpcomingEventsDataDestroy();
      clearCalendarDataDestroy();
    }
  }, [userInfo]);

  useEffect(() => {
    getUpcomingCalendarEvents(0);
  }, [eventType]);

  useEffect(() => {
    setStartAndEndDates();
  }, [currentCalendarDate, mainCalendarView]);

  useEffect(() => {
    getAllCalendarEvents(true);
  }, [startDateTimestamp, endDateTimestamp, eventType]);

  const setStartAndEndDates = () => {
    // let currentDate = moment();
    let weekStart = moment(currentCalendarDate).clone().startOf("week");
    let weekEnd = moment(currentCalendarDate).clone().endOf("week");

    let monthStart = moment(currentCalendarDate).clone().startOf("month");
    let monthEnd = moment(currentCalendarDate).clone().endOf("month");
    let startDate = mainCalendarView === "month" ? monthStart : weekStart;
    const endDate = mainCalendarView === "month" ? monthEnd : weekEnd;
    const startDateTS = moment(startDate).unix();
    const endDateTS = moment(endDate).unix();
    setStartDateTimestamp(startDateTS);
    setEndDateTimestamp(endDateTS);
    setTimeout(3000);
  };

  const {
    services,
    displayPicture,
    storefrontName,
    storeFrontRouteName,
    storeFrontId,
  } = useSelector((state) => state.storefront);

  const [openDialog, setOpenDailog] = useState(
    isOnboardingInComplete(userInfo) && !isSpApprovalPending(userInfo)
  );

  const {
    campaignData,
    enableStep1,
    enableStep2,
    timeList,
    locationsServed,
    createMarketingCampaign,
    setDetectForcastedResult,
    displayMarketingData,
    duplicateCampaignName,
    setDuplicateCampaignName,
    dropDownValues,
    validations,
    getMarketingCampaignData,
    updateMarketingCampaign,
    locationServedActions: { clearLocationServed },
    actions: { setStep1Data, clearData, resetCampaignStartDate },
    checkMarketingCampaignNameExists,
    nameAlreadyExist,
    setEnableStep2,
    getMarketingCampaignInvoiceData,
    setNameAlreadyExist,
  } = useMarketingCampaign();

  const moreActionsList = () => (
    <div className={classes.fullList} role="presentation">
      <Grid
        container
        item
        xs={12}
        style={{
          padding: 16,
          position: "sticky",
          height: 60,
          top: 0,
          zIndex: 1500,
          overflowY: "hidden",
          borderBottom: "1px solid #0E1C4E1F",
          backgroundColor: "white",
        }}
      >
        <Grid item xs={10}>
          <Typography variant="subtitle1" className={Styles.navyBlueColorForce}>
            New Campaign ({activeSection}/3)
          </Typography>
        </Grid>
        <Grid container item xs={2} justifyContent="flex-end">
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              setActiveSection(1);
              setTabDrawer(false);
            }}
          />
        </Grid>
      </Grid>
      {activeSection === 1 && (
        <NewCampaignStep1
          section={1}
          campaignData={campaignData}
          setStep1Data={setStep1Data}
          dropDownValues={dropDownValues}
          setDetectForcastedResult={setDetectForcastedResult}
          displayMarketingData={displayMarketingData}
          duplicateCampaignName={duplicateCampaignName}
          setDuplicateCampaignName={setDuplicateCampaignName}
          validations={validations}
          checkMarketingCampaignNameExists={checkMarketingCampaignNameExists}
          nameAlreadyExist={nameAlreadyExist}
        />
      )}
      {activeSection === 2 && (
        <NewCampaignStep2
          section={2}
          campaignData={campaignData}
          setStep1Data={setStep1Data}
          timeList={timeList}
          resetCampaignStartDate={resetCampaignStartDate}
          dropDownValues={dropDownValues}
        />
      )}
      {activeSection === 3 && (
        <NewCampaignStep3
          section={3}
          campaignData={campaignData}
          locationsServed={locationsServed}
        />
      )}
      <NewCampaignDialogFooter
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        enableStep1={enableStep1}
        enableStep2={enableStep2}
        createMarketingCampaign={createMarketingCampaign}
        setOpenNewCampaignDialog={setOpenNewCampaignDialog}
        campaignId={campaignData.id}
        updateMarketingCampaign={updateMarketingCampaign}
        getMarketingCampaignInvoiceData={getMarketingCampaignInvoiceData}
        handleClose={handleClose}
        duplicateCampaignName={duplicateCampaignName}
      />
    </div>
  );

  const handleNewCampaignDialog = () => {
    if (!isOnboardingInComplete(userInfo)) {
      if (isMarketplacePlan(userInfo)) {
        setOpenPlanUpgradeMsgDialog(true);
      } else {
        isMobileDevice() || isTabletDevice()
          ? setTabDrawer(true)
          : setOpenNewCampaignDialog({ open: true });
      }
    }
  };

  const [openPlanUpgradeMsgDialog, setOpenPlanUpgradeMsgDialog] = useState(
    isMarketplacePlan(userInfo)
  );

  const [selelctedRow, setSelectedRow] = useState(null);

  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    clearLocationServed && clearLocationServed();
    setActiveSection(1);
    clearData && clearData();
    setOpenNewCampaignDialog({ open: false });
    setTabDrawer(false);
    setSelectedRow && setSelectedRow(null);
    setNameAlreadyExist(false);
    dispatch({
      type: SET_PROMO_CODE,
      payload: "",
    });
    dispatch({
      type: SET_HAVE_PROMO_CODE,
      payload: false,
    });
    dispatch({
      type: "SET_VERFIY_PROMOCODE",
      payload: "",
    });
  };

  return (
    <>
      <PlanUpgradeMsgDialog
        openPlanUpgradeMsgDialog={openPlanUpgradeMsgDialog}
        setOpenPlanUpgradeMsgDialog={setOpenPlanUpgradeMsgDialog}
      />
      <CompleteOnboardingDailog
        openDialog={openDialog}
        setOpenDailog={setOpenDailog}
      />
      <Drawer
        classes={{ paper: classes.paper }}
        anchor="bottom"
        open={tabDrawer}
        onClose={() => {
          setTabDrawer(false);
        }}
        onOpen={() => {
          setTabDrawer(true);
        }}
      >
        {tabDrawer && moreActionsList()}
      </Drawer>
      <ConfirmationDialog
        open={openCloseConfirmationDialog}
        handleYes={() => {
          if (openCloseConfirmationDialog) {
            setOpenCloseConfirmationDialog(false);
            setOpenNewCampaignDialog(false);
            // handleClose();
            setTabDrawer(false);
          }
        }}
        handleNo={() => {
          if (openCloseConfirmationDialog) {
            setOpenCloseConfirmationDialog(false);
            isMobileDevice() && setTabDrawer(true);
          }
        }}
        sectionTitle={"All the entered data will be lost. Are you sure?"}
        title={openCloseConfirmationDialog && "Confirmation"}
      />
      <NewCampaignDialog
        openNewCampaignDialog={openNewCampaignDialog}
        setOpenNewCampaignDialog={setOpenNewCampaignDialog}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        campaignData={campaignData}
        enableStep1={enableStep1}
        enableStep2={enableStep2}
        setStep1Data={setStep1Data}
        clearData={clearData}
        clearLocationServed={clearLocationServed}
        timeList={timeList}
        resetCampaignStartDate={resetCampaignStartDate}
        locationsServed={locationsServed}
        createMarketingCampaign={createMarketingCampaign}
        setDetectForcastedResult={setDetectForcastedResult}
        displayMarketingData={displayMarketingData}
        duplicateCampaignName={duplicateCampaignName}
        setDuplicateCampaignName={setDuplicateCampaignName}
        dropDownValues={dropDownValues}
        validations={validations}
        getMarketingCampaignData={getMarketingCampaignData}
        updateMarketingCampaign={updateMarketingCampaign}
        setOpenCloseConfirmationDialog={setOpenCloseConfirmationDialog}
        handleClose={handleClose}
        setEnableStep2={setEnableStep2}
        selelctedRow={selelctedRow}
        getMarketingCampaignInvoiceData={getMarketingCampaignInvoiceData}
        checkMarketingCampaignNameExists={checkMarketingCampaignNameExists}
        nameAlreadyExist={nameAlreadyExist}
      />
      <MoreTrafficDialog
        open={openMoreTrafficDialog}
        setopenMoreTrafficDialog={setopenMoreTrafficDialog}
        storeFrontRouteName={storeFrontRouteName}
        storeFrontId={storeFrontId}
        data={fullSFDetails && getMetadataInfo(fullSFDetails)}
      />
      <InviteSPDialog
        openInviteSPDialog={openInviteSPDialog}
        setOpenInviteSPDialog={setOpenInviteSPDialog}
      />
      <InviteClientsDialog
        openInviteClientsDialog={openInviteClientsDialog}
        setOpenInviteClientsDialog={setOpenInviteClientsDialog}
        srcPage="serviceProvider"
      />
      <AddTemplate
        openAddTemplate={openAddTemplate}
        setOpenAddTemplate={setOpenAddTemplate}
        updateIntercomStatus={updateIntercomStatus}
      ></AddTemplate>
      <SPUploadWizard
        openUploadWizard={openUploadWizard}
        setOpenUploadWizard={setOpenUploadWizard}
        updateIntercomStatus={updateIntercomStatus}
      />
      <SPeSignWizard
        openEsignWizard={openEsignWizard}
        setOpenEsignWizard={setOpenEsignWizard}
        updateIntercomStatus={updateIntercomStatus}
      />
      <CreateMeetingDialog
        openCreateMeetingDialog={openCreateMeetingDialog}
        setOpenCreateMeetingDialog={setOpenCreateMeetingDialog}
        meetingMode={meetingMode}
        setMeetingMode={setMeetingMode}
        openConfirmCanelMeetingDialog={openConfirmCanelMeetingDialog}
        setOpenConfirmCanelMeetingDialog={setOpenConfirmCanelMeetingDialog}
        cancelMeeting={cancelMeeting}
        selectedCalendarEvent={selectedCalendarEvent}
        title={title}
        setTitle={setTitle}
        meetingDate={meetingDate}
        setMeetingDate={setMeetingDate}
        meetingLink={meetingLink}
        meetingPhoneNumber={meetingPhoneNumber}
        meetingLocation={meetingLocation}
        setMeetingLocation={setMeetingLocation}
        meetingAttachments={meetingAttachments}
        description={description}
        setDescription={setDescription}
        uploadRef={uploadRef}
        textAreaRef={textAreaRef}
        contactInputRef={contactInputRef}
        meetingStartTime={meetingStartTime}
        setMeetingStartTime={setMeetingStartTime}
        meetingEndTime={meetingEndTime}
        setMeetingEndTime={setMeetingEndTime}
        handleDialogClose={handleDialogClose}
        processAttachment={processAttachment}
        removeAttachment={removeAttachment}
        copyToClipboard={copyToClipboard}
        addCustomItem={addCustomItem}
        selectedContacts={selectedContacts}
        customContact={customContact}
        setCustomContact={setCustomContact}
        allContacts={allContacts}
        removeSelectedContact={removeSelectedContact}
        addItemOnSelect={addItemOnSelect}
        invalidTimes={invalidTimes}
        setInvalidTimes={setInvalidTimes}
        setMeetingData={setMeetingData}
        removeMeetingLink={removeMeetingLink}
        addMeetingLink={addMeetingLink}
        setAddMeetingLink={setAddMeetingLink}
        setupEmptyData={setupEmptyData}
        saveMeeting={saveMeeting}
        validateTimeSlot={validateTimeSlot}
        isFullDayEvent={isFullDayEvent}
        setIsFullDayEvent={setIsFullDayEvent}
        eventUserId={eventUserId}
        userInfo={userInfo}
        hasFilledMandatoryData={hasFilledMandatoryData}
        updateMeetingStatus={updateMeetingStatus}
        downlodAttachment={downlodAttachment}
        filteredContacts={filteredContacts}
        downloadCalendarAttachment={downloadCalendarAttachment}
      />
      <Grid container item xs={12} md={6} lg={9}>
        <Grid item xs={12} md={9}>
          <DashboardChip xlUp={xlUp} />
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={3}
          justifyContent={isMobileDevice() ? "center" : "flex-end"}
        >
          {!isSpApprovalPending(userInfo) &&
          isOnboardingInComplete(userInfo) ? (
            <Button
              variant="contained"
              onClick={() => {
                if (isMobileDevice()) {
                  setOpenDailog(true);
                } else {
                  history.push("/setup");
                }
              }}
              className={Styles.primaryBtnAccent}
              style={{
                textTransform: "capitalize",
                marginBottom: isMobileDevice() ? 10 : 0,
                marginRight: 20,
                height: 40,
              }}
            >
              Complete Onboarding
            </Button>
          ) : null}
        </Grid>
      </Grid>
      {/* <Grid container item xs={12}> */}
      <Grid item xs={12} lg={9} className={classes.grid}>
        <Card
          raised
          style={{
            marginRight: !isMobileDevice() && 20,
            borderRadius: 8,
            height: "100%",
          }}
        >
          <CardHeader
            style={{
              color: "white",
              fontWeight: 700,
              fontSize: xlUp ? "1rem" : "0.875rem", // 14 px
            }}
            className={Styles.primaryBackground}
            title="Key Statistics"
            titleTypographyProps={{ variant: "" }}
          />
          <CardContent>
            <SPKeyStatisticsWidget
              xlUp={xlUp}
              openPlanUpgradeMsgDialog={openPlanUpgradeMsgDialog}
              setOpenPlanUpgradeMsgDialog={setOpenPlanUpgradeMsgDialog}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} lg={3} className={classes.grid}>
        <Card
          raised
          style={{
            borderRadius: 8,
            marginRight: !isMobileDevice() && 20,
            height: "100%",
          }}
          className={classes.cardBackgroundImg}
        >
          <CardContent>
            <Grid container>
              <Grid container item xs={1} alignContent="center">
                <ShareIcon
                  style={{ fontSize: 16, color: "white", cursor: "text" }}
                />
              </Grid>
              <Grid item xs={11} style={{ paddingLeft: 5 }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  Make a Referral
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant={isMobileDevice() ? "h6" : xlUp ? "h2" : "h3"}
                style={{ marginTop: xlUp ? 25 : 10, color: "white" }}
              >
                Earn $50 Credit
              </Typography>
            </Grid>
            {/* </Grid> */}
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{
                  marginTop: xlUp ? 40 : 20,
                  color: "white",
                  lineHeight: 1.25,
                }}
              >
                Get the most out of Souqh by inviting your network and earn
                bonus credits while you're at it!
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                marginTop: xlUp ? 75 : 20,
              }}
              className={classes.buttonGrids}
            >
              <Button
                fullWidth
                disabled={isOnboardingInComplete(userInfo)}
                className={clsx(Styles.navyBlueColorForce, classes.buttons)}
                onClick={() => {
                  if (isMarketplacePlan(userInfo)) {
                    setOpenPlanUpgradeMsgDialog(true);
                  } else {
                    setOpenInviteSPDialog(true);
                  }
                }}
              >
                Invite Service Providers
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                marginTop: 10,
              }}
              className={classes.buttonGrids}
            >
              <Button
                fullWidth
                disabled={isOnboardingInComplete(userInfo)}
                className={clsx(Styles.navyBlueColorForce, classes.buttons)}
                onClick={() => {
                  if (isMarketplacePlan(userInfo)) {
                    setOpenPlanUpgradeMsgDialog(true);
                  } else {
                    setOpenInviteClientsDialog(true);
                  }
                }}
              >
                Invite Clients
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {/* </Grid> */}
      {/* <Grid container item xs={12}> */}
      {/* <Grid container item xs={12}> */}
      <Grid item xs={12} md={6} lg={3} className={classes.grid}>
        <DashboardCard
          title="Calendar"
          redirectTo={(event) => {
            event && event.stopPropagation();
            if (isMarketplacePlan(userInfo)) {
              setOpenPlanUpgradeMsgDialog(true);
            } else {
              history.push(`/serviceprovider/calendar`);
            }
          }}
          onAddClick={(e) => {
            if (isMarketplacePlan(userInfo)) {
              setOpenPlanUpgradeMsgDialog(true);
            } else {
              if (!isProPlan(userInfo)) {
                setMeetingMode(MEETING_CREATE_MODE);
                setOpenCreateMeetingDialog(true);
              }
            }
          }}
        >
          <Grid item xs={12} style={{ height: "100%", width: "100%" }}>
            <SmallCalendarWidget
              eventsData={calendarEvents}
              width={"100%"}
              height={"95%"}
              currentCalendarDate={currentCalendarDate}
              setCurrentCalendarDate={setCurrentCalendarDate}
              showToolbar={true}
              navigateOnDateClick={true}
              setMainCalendarView={setMainCalendarView}
            />
          </Grid>
        </DashboardCard>
      </Grid>
      <Grid item xs={12} md={6} lg={3} className={classes.grid}>
        <DashboardCard
          title="Upcoming Events"
          redirectTo={(event) => {
            event && event.stopPropagation();
            if (isMarketplacePlan(userInfo)) {
              setOpenPlanUpgradeMsgDialog(true);
            } else {
              history.push("/serviceprovider/events");
              setEventsTab(0);
            }
          }}
          onAddClick={(e) => {
            if (isMarketplacePlan(userInfo)) {
              setOpenPlanUpgradeMsgDialog(true);
            } else {
              if (!isProPlan(userInfo)) {
                setMeetingMode(MEETING_CREATE_MODE);
                setOpenCreateMeetingDialog(true);
              }
            }
          }}
        >
          <UpcomingEventsWidget
            xlUp={xlUp}
            upcomingEventsList={upcomingEventsList}
            getAttendeesTooltip={getAttendeesTooltip}
            cancelEvent={cancelEvent}
            setEventSelected={setEventSelected}
            getCalendarEventDetails={getCalendarEventDetails}
            setMeetingData={setMeetingData}
            setMeetingMode={setMeetingMode}
            setOpenCreateMeetingDialog={setOpenCreateMeetingDialog}
            openPlanUpgradeMsgDialog={openPlanUpgradeMsgDialog}
            setOpenPlanUpgradeMsgDialog={setOpenPlanUpgradeMsgDialog}
          />
        </DashboardCard>
      </Grid>
      <Grid item xs={12} md={6} lg={3} className={classes.grid}>
        <DashboardCard
          title="Pending Actions"
          redirectTo={() => {
            if (isMarketplacePlan(userInfo)) {
              setOpenPlanUpgradeMsgDialog(true);
            } else {
              history.push("/serviceprovider/events?tabIndex=1");
            }
          }}
        >
          <PendingActionsWidget
            xlUp={xlUp}
            setEventsTab={setEventsTab}
            getAllPendingActions={getAllPendingActions}
            pendingActionsList={pendingActionsList}
            userInfo={userInfo}
            handlePendingAction={handlePendingAction}
            openAddMemberDialog={openAddMemberDialog}
            setOpenAddMemberDialog={setOpenAddMemberDialog}
            openInviteSPDialogForPendingAction={
              openInviteSPDialogForPendingAction
            }
            setOpenInviteSPDialogForPendingAction={
              setOpenInviteSPDialogForPendingAction
            }
            openAddTemplate={openAddTemplate}
            setOpenAddTemplate={setOpenAddTemplate}
            onUploadTemplateFileChange={onUploadTemplateFileChange}
            updateIntercomStatus={updateIntercomStatus}
            openViewTagDialog={openViewTagDialog}
            setOpenViewTagDialog={setOpenViewTagDialog}
            viewTagDialogData={viewTagDialogData}
            openSignDocumentDialog={openSignDocumentDialog}
            setOpenSignDocumentDialog={setOpenSignDocumentDialog}
            signDocumentItem={signDocumentItem}
            setSignDocumentItem={setSignDocumentItem}
            setEventIndex={setEventIndex}
            reloadPendingActionData={reloadPendingActionData}
            deletePendingAction={deletePendingAction}
            openDocumetDialog={openDocumetDialog}
            setOpenDocumetDialog={setOpenDocumetDialog}
            documentItem={documentItem}
            setDocumentItem={setDocumentItem}
            downloadUrl={downloadUrl}
            docCategoryId={docCategoryId}
            docNameForDownload={docNameForDownload}
            openPlanUpgradeMsgDialog={openPlanUpgradeMsgDialog}
            setOpenPlanUpgradeMsgDialog={setOpenPlanUpgradeMsgDialog}
            openRecommendedSPDialog={openRecommendedSPDialog}
            setOpenRecommendedSPDialog={setOpenRecommendedSPDialog}
            recommendedSpData={recommendedSpData}
            openDocumentAccessRequestDialog={openDocumentAccessRequestDialog}
            setOpenDocumentAccessRequestDialog={
              setOpenDocumentAccessRequestDialog
            }
            requestedDocAccessName={requestedDocAccessName}
            documentAccessRequestorSP={documentAccessRequestorSP}
            acceptDocumentAccessRequest={acceptDocumentAccessRequest}
            docAccessRequestedData={docAccessRequestedData}
            openRequestedDocumentDialog={openRequestedDocumentDialog}
            setOpenRequestedDocumentDialog={setOpenRequestedDocumentDialog}
            requestedDocumentData={requestedDocumentData}
            openAddReviewDialog={openAddReviewDialog}
            setOpenAddReviewDialog={setOpenAddReviewDialog}
            openHomeBuyingJourneyDialog={openHomeBuyingJourneyDialog}
            setOpenHomeBuyingJourneyDialog={setOpenHomeBuyingJourneyDialog}
            openViewInvoiceDialog={openViewInvoiceDialog}
            setOpenViewInvoiceDialog={setOpenViewInvoiceDialog}
            viewInvoiceDialogData={viewInvoiceDialogData}
          />
        </DashboardCard>
      </Grid>
      <Grid item xs={12} md={6} lg={3} className={classes.grid}>
        <DashboardCard
          title="Document Upload"
          redirectTo={() => {
            if (isMarketplacePlan(userInfo)) {
              setOpenPlanUpgradeMsgDialog(true);
            } else {
              history.push("/serviceprovider/documents");
              setDocumentTab(0);
            }
          }}
        >
          <DocumentUpload
            processEsignUploadedFiles={processEsignUploadedFiles}
            onUploadEsignFileChange={onUploadEsignFileChange}
            onUploadTemplateFileChange={onUploadTemplateFileChange}
            openPlanUpgradeMsgDialog={openPlanUpgradeMsgDialog}
            setOpenPlanUpgradeMsgDialog={setOpenPlanUpgradeMsgDialog}
          />
        </DashboardCard>
      </Grid>
      {/* </Grid> */}
      {/* <Grid container item xs={12}> */}
      <Grid item xs={12} lg={9} className={classes.grid}>
        <DashboardCard
          title="Active Clients"
          redirectTo={() => {
            if (isMarketplacePlan(userInfo)) {
              setOpenPlanUpgradeMsgDialog(true);
            } else {
              history.push("/serviceprovider/clients?tabIndex=0");
            }
          }}
          onAddClick={(e) => {
            if (isMarketplacePlan(userInfo)) {
              setOpenPlanUpgradeMsgDialog(true);
            } else {
              setOpenInviteClientsDialog(true);
            }
          }}
        >
          <ActiveClientsWidget
            openPlanUpgradeMsgDialog={openPlanUpgradeMsgDialog}
            setOpenPlanUpgradeMsgDialog={setOpenPlanUpgradeMsgDialog}
          />
        </DashboardCard>
      </Grid>
      {!isTabletDevice() && (
        <StorefrontWidgetSections
          history={history}
          classes={classes}
          setopenMoreTrafficDialog={setopenMoreTrafficDialog}
          services={services}
          displayPicture={displayPicture}
          storefrontName={storefrontName}
          userInfo={userInfo}
          openPlanUpgradeMsgDialog={openPlanUpgradeMsgDialog}
          setOpenPlanUpgradeMsgDialog={setOpenPlanUpgradeMsgDialog}
        />
      )}
      {/* </Grid> */}
      {/* <Grid container item xs={12}> */}
      <Grid item xs={12} lg={9} className={classes.grid}>
        <DashboardCard
          title="Leads"
          redirectTo={() => {
            if (isMarketplacePlan(userInfo)) {
              setOpenPlanUpgradeMsgDialog(true);
            } else {
              history.push("/serviceprovider/clients?tabIndex=3");
            }
          }}
        >
          <LeadsWidget
            storeFrontRouteName={storeFrontRouteName}
            storeFrontId={storeFrontId}
            openPlanUpgradeMsgDialog={openPlanUpgradeMsgDialog}
            setOpenPlanUpgradeMsgDialog={setOpenPlanUpgradeMsgDialog}
            storeFrontDetails={fullSFDetails}
          />
        </DashboardCard>
      </Grid>
      {isTabletDevice() && (
        <StorefrontWidgetSections
          history={history}
          classes={classes}
          setopenMoreTrafficDialog={setopenMoreTrafficDialog}
          services={services}
          displayPicture={displayPicture}
          storefrontName={storefrontName}
          userInfo={userInfo}
          openPlanUpgradeMsgDialog={openPlanUpgradeMsgDialog}
          setOpenPlanUpgradeMsgDialog={setOpenPlanUpgradeMsgDialog}
        />
      )}
      <Grid item xs={12} md={6} lg={3} className={classes.grid}>
        <DashboardCard
          title="Client Reviews"
          redirectTo={() => {
            if (isMarketplacePlan(userInfo)) {
              setOpenPlanUpgradeMsgDialog(true);
            } else {
              history.push("/serviceprovider/clients/reviews");
            }
          }}
        >
          <ClientReviewsWidget
            xlUp={xlUp}
            openPlanUpgradeMsgDialog={openPlanUpgradeMsgDialog}
            setOpenPlanUpgradeMsgDialog={setOpenPlanUpgradeMsgDialog}
            storeFrontDetails={fullSFDetails}
          />
        </DashboardCard>
      </Grid>
      {/* </Grid> */}
      {/* <Grid container item xs={12} className={classes.grid}> */}
      <Grid item xs={12} lg={9} className={classes.grid}>
        <DashboardCard
          title="Marketing Campaigns"
          redirectTo={() => {
            if (isMarketplacePlan(userInfo)) {
              setOpenPlanUpgradeMsgDialog(true);
            } else {
              history.push("/serviceprovider/marketing");
            }
          }}
          onAddClick={handleNewCampaignDialog}
        >
          {/* <img src="/images/Mask Group 53.png" className={classes.imageStyle} /> */}
          <MarketingCampaignsWidget
            setNewCampaignDialog={handleNewCampaignDialog}
            openPlanUpgradeMsgDialog={openPlanUpgradeMsgDialog}
            setOpenPlanUpgradeMsgDialog={setOpenPlanUpgradeMsgDialog}
            userInfo={userInfo}
          />
        </DashboardCard>
      </Grid>
      {isTabletDevice() ? (
        <Grid
          container
          item
          xs={12}
          className={classes.grid}
          justifyContent="center"
        >
          <HelpCenterWidgetsection
            openPlanUpgradeMsgDialog={openPlanUpgradeMsgDialog}
            setOpenPlanUpgradeMsgDialog={setOpenPlanUpgradeMsgDialog}
            userInfo={userInfo}
          />
        </Grid>
      ) : (
        <HelpCenterWidgetsection
          openPlanUpgradeMsgDialog={openPlanUpgradeMsgDialog}
          setOpenPlanUpgradeMsgDialog={setOpenPlanUpgradeMsgDialog}
          userInfo={userInfo}
        />
      )}
      {/* </Grid> */}
      {/* </Grid> */}
    </>
  );
}
