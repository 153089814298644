import {
  SET_STOREFRONT_DATA,
  SET_NUM_EMPLOYEES,
  SET_NUM_YRS_BUSINESS,
  SET_STORE_FRONT_ROUTE_NAME,
  INITIAL_STATE_STORE,
  SET_INSTAGRAM_TOKEN,
} from "souqh-redux/reducers/actionConstants";
import BlobManager from "../../../web/src/utils/BlobManager";
import { useActions } from "../../useActions";
import { forEach } from "lodash";
import {
  ADVANCED,
  PREMIUM,
  SERVICE_PROVIDER_USER,
  TEAM_MEMBER_USER,
} from "../../../web/src/components/ServiceProvider/serviceProviderConstants";
import { useState } from "react";

const addToBlobManager = (url, name) => {
  return fetch(url + `?${Date.now()}`)
    .then((res) => res.blob())
    .then((blob) => {
      return BlobManager.addFromBlob(blob, name, "image/png", "png");
    });
};

export const generateBlobs = async (rStoreFront) => {
  // banner
  if (rStoreFront.bannerPicture) {
    const { fileName: bannerFileNmae } = await addToBlobManager(
      rStoreFront.bannerPicture,
      "banner"
    );
    rStoreFront.bannerPicture = bannerFileNmae;
  }

  // logo
  if (rStoreFront.displayPicture) {
    const { fileName: logoFileName } = await addToBlobManager(
      rStoreFront.displayPicture,
      "logo"
    );
    rStoreFront.displayPicture = logoFileName;
  }

  if (rStoreFront.services && rStoreFront.services.length) {
    const tasks = rStoreFront.services.map(async (v) => {
      if (v.imageUrl) {
        const { fileName: serviceImage } = await addToBlobManager(
          v.imageUrl,
          "service-"
        );
        v.imageUrl = serviceImage;
      }
    });
    await Promise.all(tasks);
  }
};

const actionCreators = {
  fetch: (apiClient, onResult) => (dispatch, getState) => {
    const {
      storefront,
      auth: {
        userDetails: {
          serviceProviderId,
          userType,
          parentServiceProviderId,
          subscriptionPlanName,
        },
      },
    } = getState();

    let url =
      userType === SERVICE_PROVIDER_USER ||
        (userType === TEAM_MEMBER_USER && (subscriptionPlanName === PREMIUM || subscriptionPlanName === ADVANCED))
        ? `/service-provider/storefront?serviceProviderId=${serviceProviderId}`
        : `/service-provider/storefront?serviceProviderId=${parentServiceProviderId}`;

    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(url)
      .then(async (response) => {
        if (
          response &&
          response.data &&
          response.data.result &&
          response.data.result.length
        ) {
          // until further resolution, always get 0th element from result.
          // while saving back, we are always updating the storefront record with id 1.
          const {
            storeFrontContent,
            id, // this is sp id and not store front id
            numberOfEmployees,
            yearsInBusiness,
            storeFrontRouteName,
            storeFrontId,
            services,
            instagramToken,
          } = response.data.result[0];

          let rStoreFront = null;
          if (storeFrontId) {
            rStoreFront = JSON.parse(storeFrontContent);
            rStoreFront.services = services;
            try {
              await generateBlobs(rStoreFront);
            } catch (e) {
              console.error(e);
            }
            if (!rStoreFront.storeFrontId) {
              rStoreFront.storeFrontId = storeFrontId;
            }
          }

          //   if (rStoreFront.bannerPicture) {
          //     await addToBlobManager(rStoreFront.bannerPicture, "banner");
          //     rStoreFront.bannerPicture = "banner.png";
          //   }

          if (storeFrontId) {
            dispatch({
              type: SET_STOREFRONT_DATA,
              payload: { storeFront: rStoreFront },
            });
            dispatch({
              type: SET_STORE_FRONT_ROUTE_NAME,
              payload: storeFrontRouteName,
            });
            onResult && onResult(response);
          }

          dispatch({
            type: SET_INSTAGRAM_TOKEN,
            payload: instagramToken,
          });

          dispatch({
            type: SET_NUM_EMPLOYEES,
            payload: numberOfEmployees,
          });
          dispatch({
            type: SET_NUM_YRS_BUSINESS,
            payload: yearsInBusiness,
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  },
};
export const useFetch = () => {
  const actions = useActions(actionCreators);
  return { actions };
};
