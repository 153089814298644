import {
  Button,
  ButtonBase,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import Styles from "../../../Styles.module.css";
import {
  covertTimeStampToDate,
  isMobileDevice,
  isTabletDevice,
} from "../../../utils/AppUtils";
import StoreIcon from "@mui/icons-material/Store";
import SPDetailsDocuments from "./SPDetailsDocuments";
import { useSPUploadWizard } from "souqh-react-redux-hooks/serviceProvider/documents/useSPUploadWizard";
import SPUploadWizard from "../../common/UploadWizard/SPUploadWizard";
import { useDocuments } from "souqh-react-redux-hooks/homeBuyer/useDocuments";
import { useParams } from "react-router-dom";
import { useHomeOwnershipTimeline } from "souqh-react-redux-hooks/common/useHomeOwnershipTimeline";
import IndividualSPTimeline from "../HomeBuyingJourney/IndividualSPTimeline";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { useNavigateToMessage } from "souqh-react-redux-hooks/common/useNavigateToMessage";
import { useHistory } from "react-router";

export default function SPDetails() {
  const userInfo = useAuthUser();
  const history = useHistory();
  const { navigateMessage } = useNavigateToMessage();

  const email = history.location.state && history.location.state?.email;

  const {
    onUploadFileChange,
    openUploadWizard,
    actions: { setOpenUploadWizard },
  } = useSPUploadWizard();

  const {
    updateIntercomStatus,
    getTabWiseCategoriesList,
    categories,
    actions: { setDocumentTab },
  } = useDocuments();

  const {
    getIndividualSPsDocuments,
    individualSPDocuments,
    getIndividualSPsTimelineData,
    individualSPTimelineData,
  } = useHomeOwnershipTimeline();

  let { serviceProviderId } = useParams();

  useEffect(() => {
    getIndividualSPsDocuments(serviceProviderId);
    getIndividualSPsTimelineData(serviceProviderId, userInfo.userId);
  }, []);

  const refreshSPDocuments = () => {
    getIndividualSPsDocuments(serviceProviderId);
  };

  const storeFrontContent =
    individualSPDocuments &&
    individualSPDocuments.storeFrontContent &&
    JSON.parse(individualSPDocuments.storeFrontContent);

  return (
    <Grid container style={{ padding: isTabletDevice() && "0px 20px" }}>
      <SPUploadWizard
        openUploadWizard={openUploadWizard}
        setOpenUploadWizard={setOpenUploadWizard}
        categories={categories}
        updateIntercomStatus={updateIntercomStatus}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
        setDocumentTab={setDocumentTab}
        refreshSPDocuments={refreshSPDocuments}
      />
      {!isMobileDevice() && (
        <Grid item xs={12} style={{ padding: isMobileDevice() && "0px 20px" }}>
          <ButtonBase
            onClick={() => {
              history.push("/homebuyer/serviceproviders");
            }}
          >
            <Typography variant="body1" className={Styles.navyBlueColor}>
              Service Providers/
              {storeFrontContent && storeFrontContent.storefrontName}
            </Typography>
          </ButtonBase>
        </Grid>
      )}
      <Grid container item xs={12} md={12}>
        {!isMobileDevice() && (
          <React.Fragment>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" className={Styles.navyBlueColor}>
                {storeFrontContent && storeFrontContent.storefrontName}
              </Typography>
            </Grid>

            <Grid item container xs={6} justifyContent="flex-end">
              <Button
                fullWidth={isTabletDevice() ? true : false}
                style={{
                  textTransform: "none",
                  paddingLeft: isTabletDevice() ? 32 : 24,
                  paddingRight: 24,
                  marginRight: 16,
                }}
                className={Styles.primaryBtnAccent}
                onClick={(event) => {
                  navigateMessage(event, email);
                }}
              >
                Send Message
              </Button>
              <input
                style={{ display: "none" }}
                id="contained-button-file"
                multiple
                type="file"
                onChange={onUploadFileChange}
              />
              <label htmlFor="contained-button-file">
                <Button
                  fullWidth={isTabletDevice() ? true : false}
                  variant="outlined"
                  style={{
                    textTransform: "none",
                    paddingLeft: isTabletDevice() ? 32 : 24,
                    paddingRight: 24,
                    paddingTop: 7,
                    paddingBottom: 7,
                  }}
                  className={Styles.primaryBtnAccent}
                  component="span"
                >
                  Upload Documents
                </Button>
              </label>
            </Grid>
          </React.Fragment>
        )}
      </Grid>

      <Grid
        container
        item
        xs={12}
        style={{ marginTop: !isMobileDevice() && 20 }}
      >
        <Grid
          container={isMobileDevice() || isTabletDevice() ? true : false}
          item
          xs={12}
          lg={2}
          justifyContent={
            (isMobileDevice() || isTabletDevice()) && "space-between"
          }
          style={{ padding: isMobileDevice() && "0px 20px" }}
        >
          <Grid item xs={3} md={1}>
            <img
              src={
                (storeFrontContent && storeFrontContent.displayPicture) ||
                "/images/logo.svg"
              }
              alt="logo"
              title="logo"
              style={{
                height: isMobileDevice() || isTabletDevice() ? 80 : 170,
                width: isMobileDevice() || isTabletDevice() ? 80 : 170,
              }}
            />
          </Grid>
          <Grid
            item
            xs={8}
            md={10}
            lg={12}
            container={isMobileDevice() || isTabletDevice() ? true : false}
          >
            <Grid item xs={12}>
              <Typography variant="subtitle1" className={Styles.navyBlueColor}>
                {storeFrontContent && storeFrontContent.storefrontName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={Styles.navyBlueColor} variant="body1">
                On Souqh since
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={Styles.navyBlueColor} variant="body1">
                {covertTimeStampToDate(individualSPDocuments.onSouqhSince)}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ marginTop: 24 }}>
            <Button
              variant="outlined"
              startIcon={<StoreIcon />}
              style={{ textTransform: "capitalize" }}
              className={Styles.navyBlueColorForce}
              fullWidth={isMobileDevice() ? true : false}
              onClick={() => {
                let url =
                  window.location.origin +
                  `/storefront/${storeFrontContent.storeFrontRouteName}`;
                window.open(url, "_blank");
              }}
            >
              Visit storefront
            </Button>
          </Grid>
        </Grid>
        {isMobileDevice() && (
          <Divider
            orientation="horizontal"
            variant="fullWidth"
            style={{ width: "100%", margin: "16px 0" }}
          />
        )}
        <Grid item xs={12} lg={10} md={12}>
          <SPDetailsDocuments
            individualSPDocuments={individualSPDocuments}
            refreshSPDocuments={refreshSPDocuments}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        style={{
          margin: isMobileDevice() ? "0px 0px 80px 0px" : "20px 0px 40px 0px",
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h4" className={Styles.navyBlueColor}>
            Interaction History
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <IndividualSPTimeline
            individualSPTimelineData={individualSPTimelineData}
            isSPDetailsPage={true}
            email={email}
            navigateMessage={navigateMessage}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
