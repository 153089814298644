import { Grid, makeStyles, Typography, Avatar, Link } from "@material-ui/core";
import React, { useState } from "react";
import clsx from "clsx";
import Styles from "../../../Styles.module.css";
import GroupRoundedIcon from "@material-ui/icons/GroupRounded";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import SharedWithPopup from "../../DocumentManagementDialogs/SharedWithPopup";

const useStyles = makeStyles((theme) => ({
  paddingRight10: {
    paddingRight: 10,
  },
  tableHeading: {
    fontWeight: "bold",
  },
  cursorPointer: {
    cursor: "pointer",
  },
}));

export default function AttendeesCell({ row, getAttendeesTooltip }) {
  const [openSharedWithPopup, setOpenSharedWithPopup] = useState(false);
  const classes = useStyles();
  if (row.attendees.length) {
    const firstUser = row.attendees[0] || {};
    const usersToolTip = getAttendeesTooltip(row.attendees);
    return (
      <React.Fragment>
        <SharedWithPopup
          openSharedWithPopup={openSharedWithPopup}
          setOpenSharedWithPopup={setOpenSharedWithPopup}
          sharedWithList={usersToolTip}
          title="Attendees"
        />
        <Grid item xs={12}>
          <Typography
            variant="body1"
            title={firstUser.fullName || firstUser.email}
            className={clsx(Styles.sqEllipsis, Styles.navyBlueColor)}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Avatar style={{ width: 32, height: 32 }}>
              {row.attendees.length > 1 ? (
                <GroupRoundedIcon />
              ) : (
                <PersonRoundedIcon />
              )}
            </Avatar>
            <div style={{ marginLeft: 6 }} className={Styles.sqEllipsis}>
              {firstUser.fullName || firstUser.email}
            </div>
          </Typography>
          {usersToolTip.length ? (
            <Link
              className={clsx(classes.cursorPointer, Styles.navyBlueColorForce)}
              style={{ paddingLeft: 32 }}
              underline="always"
              onClick={() => {
                setOpenSharedWithPopup(true);
              }}
            >{`+${row.attendees.length - 1} more`}</Link>
          ) : null}
        </Grid>
      </React.Fragment>
    );
  }
  return null;
}
