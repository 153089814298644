import { useState } from "react";
import { useAuthUser } from "../../useLogin";
import { useApiClient } from "../../useApiClient";
import { useDispatch } from "react-redux";
import { cloneDeep } from "lodash";
import * as moment from "moment";
import { useSQCalendar } from "../../common/useSQCalendar";

export const useUpcomingEvents = () => {
  const dispatch = useDispatch();
  const { apiClient } = useApiClient();
  const userInfo = useAuthUser();
  const [eventsOrder, setEventsOrder] = useState("asc");
  const [eventsOrderBy, setEventsOrderBy] = useState("startDateTime");
  const [upcomingEventsList, setUpcomingEventsList] = useState([]);
  const {
    actions: { setOpenConfirmCanelMeetingDialog },
  } = useSQCalendar({
    calendarListUpdater: null,
    upcomingEventsUpdater: null,
  });

  const [eventSelected, setEventSelected] = useState(null);
  const handleEventsRequestSort = (event, property) => {
    const isAsc = eventsOrderBy === property && eventsOrder === "asc";
    setEventsOrder(isAsc ? "desc" : "asc");
    setEventsOrderBy(property);
  };

  const getAttendeesTooltip = (attendees) => {
    const fileterdList = attendees.slice(0, 1);
    const remainingList = attendees.filter((el) => !fileterdList.includes(el));
    return remainingList.map((item) => item.fullName || item.email);
  };

  const getFileretedUpcomingEvents = (eventsData, value) => {
    if (!eventsData) {
      return [];
    }
    const searchText = (value || "").trim().toLowerCase();
    return eventsData.filter((row) => {
      return (
        (row.title || "").toLowerCase().includes(searchText) ||
        (row.displayFromDate || "").toLowerCase().includes(searchText) ||
        row.attendees.some(
          (item) =>
            (item.email || "").toLowerCase().includes(searchText) ||
            (item.fullName || "").toLowerCase().includes(searchText)
        )
      );
    });
  };

  const getUpcomingCalendarEvents = (allEvents) => {
    const body = {
      // fromDate: new Date().toGMTString(),
      startAfterTime: Math.floor(Date.now() / 1000),
      numberOfRecords: allEvents || 0,
      userId: userInfo.userId,
    };
    apiClient
      .post("/calendar/event/upcoming", body)
      .then((response) => {
        if (response.data.status === 200) {
          const parsedData = response.data.result || [];
          parsedData.forEach((item) => {
            item.displayFromDate = item.isFullDayEvent
              ? moment(item.eventDate).format("MMM DD, YYYY - hh:mm A")
              : item.startDateTime
              ? moment(item.startDateTime).format("MMM DD, YYYY - hh:mm A")
              : "N/A";
          });
          setUpcomingEventsList(parsedData);
        } else {
          dispatch({
            type: "SET_SERVER_ERROR",
            error: { message: "Something went wrong" },
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const cancelEvent = (callback) => {
    if (eventSelected && (eventSelected.id || eventSelected.nylasEventId)) {
      // const url = `/calendar/event/${eventSelected.id}/user/${userInfo.userId}`;
      const url = `/calendar/event/user/${userInfo.userId}`;
      dispatch({ type: "SET_LOADING", value: true });
      apiClient
        .delete(url, {
          params: {
            appEventId: eventSelected.id,
            nylasEventId: eventSelected.nylasEventId,
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            const tempList = cloneDeep(upcomingEventsList);
            let index;
            if (eventSelected.id) {
              index = tempList.findIndex(
                (item) => item.id === eventSelected.id
              );
            } else if (eventSelected.nylasEventId) {
              index = tempList.findIndex(
                (item) => item.id === eventSelected.nylasEventId
              );
            }
            tempList.splice(index, 1);
            setUpcomingEventsList(tempList);
            callback && callback();
          } else {
            dispatch({
              type: "SET_SERVER_ERROR",
              error: { message: "Something went wrong" },
            });
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function (error) {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const handleEventDialogClose = () => {
    setOpenConfirmCanelMeetingDialog(false);
  };

  const clearUpcomingEventsDataDestroy = () => {
    setUpcomingEventsList([]);
    setOpenConfirmCanelMeetingDialog(false);
    setEventSelected(null);
    setEventsOrderBy("startDateTime");
    setEventsOrder("desc");
  };

  return {
    handleEventsRequestSort,
    eventsOrder,
    setEventsOrder,
    eventsOrderBy,
    setEventsOrderBy,
    upcomingEventsList,
    setUpcomingEventsList,
    getUpcomingCalendarEvents,
    getAttendeesTooltip,
    userInfo,
    cancelEvent,
    handleEventDialogClose,
    eventSelected,
    setEventSelected,
    clearUpcomingEventsDataDestroy,
    getFileretedUpcomingEvents,
  };
};
