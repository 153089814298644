import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useApiClient } from "../../useApiClient";
import { useAuthUser } from "../../useLogin";
import { color_error } from "../../toasterColors";
import { emailValidationMeta } from "../../validationMetas";
import { useValidations } from "../../useValidations";

const validationMeta = [emailValidationMeta];

export const useDeactivateAccount = () => {
  const user = useAuthUser();
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");
  const [reasonValue, setReasonValue] = useState(null);
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();
  const [allowSubmit, setAllowSubmit] = useState(false);

  const getFormValues = useCallback(() => {
    return { email };
  }, [email]);
  const getServerKeysMap = useCallback(() => {
    return {
      email: "email",
    };
  }, []);

  const { validations, addValidations, clearAllValidations } = useValidations(
    validationMeta,
    getFormValues,
    getServerKeysMap
  );

  useEffect(() => {
    let validForm = !!email && !!reason && !Object.keys(validations).length;
    setAllowSubmit(validForm);
  }, [email, reason, validations]);

  const deactivateAccount = (onResult) => {
    let request = { deactivatedByName: email, reason: reason };
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .put(`/service-provider/deactivate-client/${user.userId}`, request)
      .then((res) => {
        onResult && onResult(res);
      })
      .catch((error) => {
        // dispatch({
        //   type: "SET_TOASTER",
        //   payload: {
        //     message: `Couldn't update data`,
        //     bgColor: color_error,
        //     open: true,
        //   },
        // });
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const clearData = () => {
    setEmail("");
    setReason("");
  };
  return {
    deactivateAccount,
    email,
    setEmail,
    reason,
    setReason,
    reasonValue,
    setReasonValue,
    clearData,
    allowSubmit,
    validations,
  };
};
