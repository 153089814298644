import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SET_INVOICE_DATA } from "../../souqh-redux/reducers/actionConstants";
import { useApiClient } from "../useApiClient";
import { useValidations } from "../useValidations";
import {
  emailValidationMeta,
  passwordValidationMeta,
  mobileNumberValidationMeta,
} from "../validationMetas";
import {
  useSavePlanData,
  useSubscriptionPlan,
} from "./plans/useSubscriptionPlan";

const validationMeta = [
  {
    name: "businessName",
    patterns: [
      {
        match: (val) => /^[A-Za-z0-9 ,$&:|*^"';#@./!+-_]*$/i.test(val),
        msg: "Entered business name has invalid characters. Please enter a valid business name.",
      },
    ],
  },
  {
    name: "firstname",
    patterns: [
      {
        match: (val) => /^[a-z0-9]*$/i.test(val),
        msg: "Entered name has invalid characters. Please enter a valid name.",
      },
    ],
  },
  {
    name: "lastname",
    patterns: [
      {
        match: (val) => /^[a-z0-9]*$/i.test(val),
        msg: "Entered name has invalid characters. Please enter a valid name.",
      },
    ],
  },
  emailValidationMeta,
  passwordValidationMeta,
  mobileNumberValidationMeta,
];

export const useRegistration = () => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedProvinces, setSelectedProvinces] = useState([]);
  const [selectedBisunessType, setSelectedBusinessType] = useState([]);
  const [otherBusinessTypeName, setOtherBusinessTypeName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setpassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [enable2Factor, setEnable2Factor] = useState(false);
  const [productUpdateAlert, setProductUpdateAlert] = useState(false);
  const [termsPrivacyPolicy, setTermsPrivacyPolicy] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [registrationCardDetails, setRegistrationCardDetails] = useState(null);

  const [stripeTokenId, setStripeTokenId] = useState("");

  const promoCode = useSelector((state) => state.subscriptionPlan.promoCode);

  const {
    actions: { setPlanDuration },
    planDuration,
    defaultSelectedCard,
    setDefaultSelectedCard,
    selectedUserPlan,

    plans,
  } = useSubscriptionPlan("registration"); //page ==registration

  const {
    savePlanData,
    actions: { setSelectedUserPlan },
  } = useSavePlanData();

  const getFormValues = useCallback(() => {
    return { businessName, firstname, lastname, email, phoneNumber, password };
  }, [businessName, firstname, lastname, email, phoneNumber, password]);
  const getServerKeysMap = useCallback(() => {
    return {
      contactNumber: "phoneNumber",
      firstName: "firstname",
      lastName: "lastname",
    };
  }, []);

  const { validations, addValidations, clearAllValidations } = useValidations(
    validationMeta,
    getFormValues,
    getServerKeysMap
  );
  useEffect(() => {
    let validForm =
      !!businessName &&
      !!firstname &&
      !!lastname &&
      !!email &&
      !!phoneNumber &&
      !!password &&
      termsPrivacyPolicy &&
      !Object.keys(validations).length;
    setAllowSubmit(validForm);
  }, [
    businessName,
    firstname,
    lastname,
    email,
    password,
    termsPrivacyPolicy,
    validations,
  ]);

  const submitProspect = (onSuccess, onFailure) => {
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .post("/service-provider/come-back-soon", {
        countryId: selectedCountry && selectedCountry.id,
        email: email,
        provinceId:
          (selectedProvinces.length && selectedProvinces[0].id) || null,
      })
      .then((res) => {
        onSuccess && onSuccess(res);
      })
      .catch((error) => {
        console.error(error);
        onFailure && onFailure(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const register = (onSuccess, onFailure) => {
    clearAllValidations();
    dispatch({ type: "SET_LOADING", value: true });
    let signupData = {
      businessName: businessName,
      businessTypeIds: selectedBisunessType.length
        ? selectedBisunessType.map((ele) => ele.id)
        : null,
      countryId: selectedCountry && selectedCountry.id,
      otherBusinessTypeName: otherBusinessTypeName
        ? otherBusinessTypeName
        : null,
      provinceIds: selectedProvinces && selectedProvinces.map((ele) => ele.id),
      contactNumber: phoneNumber,
      email: email.toLowerCase(),
      firstName: firstname,
      lastName: lastname,
      password: password,
      enable2Factor: enable2Factor,
      productUpdateAlert: productUpdateAlert,
      termsPrivacyPolicy: termsPrivacyPolicy,
      paymentSubscriptionRequest: {
        couponCode: promoCode ? promoCode : null,
        paymentCycle: planDuration,
        primaryPlanId: selectedUserPlan,
        provinceId:
          selectedProvinces && selectedProvinces.map((ele) => ele.id)[0],
      },
      stripeProfileRequest: {
        registration: true,
        tokenId: stripeTokenId,
      },
    };
    apiClient
      .post("/service-provider/signUp", signupData)
      .then((res) => {
        onSuccess && onSuccess(res);
        setRegistrationCardDetails(null);
      })
      .catch((error) => {
        console.error(error);
        onFailure && onFailure(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const generateInvoiceRegistration = (provinceSelected, onResult) => {
    const paymentSubscriptionRequest = {
      couponCode: promoCode ? promoCode : null,
      paymentCycle: planDuration,
      primaryPlanId: selectedUserPlan,
      provinceId:
        selectedProvinces && selectedProvinces.length
          ? selectedProvinces.map((ele) => ele.id)[0]
          : provinceSelected &&
            provinceSelected.length &&
            provinceSelected.map((ele) => ele.id)[0],
    };
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post(
        `/service-provider/subscription/invoice/registration`,
        paymentSubscriptionRequest
      )
      .then(function (response) {
        if (response.data.status === 200) {
          dispatch({
            type: SET_INVOICE_DATA,
            payload: { invoiceData: response.data.result },
          });
        }
        dispatch({ type: "SET_TOASTER", value: true });
        onResult && onResult(response);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    validations,
    addValidations,
    setSelectedCountry,
    setSelectedProvinces,
    selectedBisunessType,
    setSelectedBusinessType,
    otherBusinessTypeName,
    setOtherBusinessTypeName,
    setBusinessName,
    businessName,
    setfirstname,
    setlastname,
    firstname,
    lastname,
    setEmail,
    setPhoneNumber,
    password,
    setpassword,
    selectedCountry,
    selectedProvinces,
    email,
    submitProspect,
    enable2Factor,
    productUpdateAlert,
    termsPrivacyPolicy,
    setEnable2Factor,
    setProductUpdateAlert,
    setTermsPrivacyPolicy,
    register,
    allowSubmit,
    phoneNumber,
    password,
    confirmPassword,
    setconfirmPassword,
    setPlanDuration,
    planDuration,
    defaultSelectedCard,
    setDefaultSelectedCard,
    selectedUserPlan,
    plans,
    setSelectedUserPlan,
    generateInvoiceRegistration,
    setStripeTokenId,
    registrationCardDetails,
    setRegistrationCardDetails,
  };
};
