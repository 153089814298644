import { useState } from "react";
import { color_success } from "../toasterColors";
import { useAuthUser } from "../useLogin";
import { useDispatch } from "react-redux";
import { useApiClient } from "../useApiClient";

export const useSocialMediaSync = () => {
  const userInfo = useAuthUser();
  const dispatch = useDispatch();
  const { apiClient } = useApiClient();

  const sendInstagramCode = (code, redirectUri, onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .post(
        `/social-media/instagramToken/serviceProvider/${userInfo.serviceProviderId}`,
        {
          code: code,
          redirectUri: redirectUri,
        }
      )
      .then((response) => {
        onResult && onResult(response);
      })
      .catch((error) => {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    sendInstagramCode,
  };
};
