import { useSelector } from "react-redux";
import { useCallback, useState, useEffect } from "react";
import { useValidations } from "../../useValidations";
import { cloneDeep } from "lodash";

import {
  ADD_C_PAYMENT,
  REMOVE_C_PAYMENT,
  UPDATE_C_PAYMENT,
  UPDATE_CREDIT_CARD_INFO,
  UPDATE_PAYPAL_INFO,
  UPDATE_WIRE_TRANSFER_INFO,
  SET_CUSTOMER_PAYMENTS,
  UPDATE_PAYMENTS_METHODS,
  UPDATE_E_TRANSFER_INFO,
  UPDATE_CHEQUE_INFO,
  RESET_INFO,
  UPDATE_OTHER_INFO,
  RESET_OTHER_INFO,
  ADD_OTHER_PAYMENT_METHOD,
  DELETE_OTHER_PAYMENT_METHOD,
  SAVE_INFO,
  CLICK_EDIT,
  CLICK_DELETE,
  SAVE_OTHER_PAYMENT_METHOD,
} from "../../../souqh-redux/reducers/actionConstants";
import { useActions } from "../../useActions";
import { emailValidationMeta } from "../../validationMetas";

const actionCreators = {
  addCustomerPayment: (payload) => ({
    type: ADD_C_PAYMENT,
    payload,
  }),
  removeCustomerPayment: (payload) => ({
    type: REMOVE_C_PAYMENT,
    payload,
  }),
  updateCustomerPayment: (payload) => ({
    type: UPDATE_C_PAYMENT,
    payload,
  }),
  updateCreditCardInfo: (payload) => ({
    type: UPDATE_CREDIT_CARD_INFO,
    payload,
  }),
  updatePaypalInfo: (payload) => ({
    type: UPDATE_PAYPAL_INFO,
    payload,
  }),
  updateWireTransferInfo: (payload) => ({
    type: UPDATE_WIRE_TRANSFER_INFO,
    payload,
  }),
  updateETransferInfo: (payload) => ({
    type: UPDATE_E_TRANSFER_INFO,
    payload,
  }),
  setCustomerPayments: (payload) => ({
    type: SET_CUSTOMER_PAYMENTS,
    payload,
  }),
  updatePaymentMethods: (payload) => ({
    type: UPDATE_PAYMENTS_METHODS,
    payload,
  }),
  saveInfo: (payload) => ({
    type: SAVE_INFO,
    payload,
  }),
  clickEdit: (payload) => ({
    type: CLICK_EDIT,
    payload,
  }),
  updateChequeInfo: (payload) => ({
    type: UPDATE_CHEQUE_INFO,
    payload,
  }),
  resetInfo: (payload) => ({
    type: RESET_INFO,
    payload,
  }),
  updateOtherInfo: (payload) => ({
    type: UPDATE_OTHER_INFO,
    payload,
  }),
  resetOtherInfo: (payload) => ({
    type: RESET_OTHER_INFO,
    payload,
  }),
  addOtherPaymentMethod: (payload) => ({
    type: ADD_OTHER_PAYMENT_METHOD,
    payload,
  }),
  deletetOtherPaymentMethod: (payload) => ({
    type: DELETE_OTHER_PAYMENT_METHOD,
    payload,
  }),
  clickDelete: (payload) => ({
    type: CLICK_DELETE,
    payload,
  }),
  saveOtherInfo: (payload) => ({
    type: SAVE_OTHER_PAYMENT_METHOD,
    payload,
  }),
};

export const useCustomerPayments = () => {
  const customerPayments = useSelector(
    (state) => state.storefront.customerPayments
  );
  const paymentsVisible = useSelector(
    (state) => state.storefront.paymentsVisible
  );
  const actions = useActions(actionCreators);

  return { customerPayments, actions, paymentsVisible };
};

const validationMeta = [emailValidationMeta];
export const useCustomerPayment = () => {
  const customerPayments = useSelector(
    (state) => state.storefront.customerPayments
  );
  const {
    ccPaymentOptions,
    paymentOptions,
    creditCardInfo,
    paypalInfo,
    otherInfo,
    paymentMethods,
  } = customerPayments;

  const [email, setEmail] = useState("");
  const actions = useActions(actionCreators);

  const getFormValues = useCallback(() => {
    return { email };
  }, [email]);

  const getServerKeysMap = useCallback(() => {
    return {};
  }, []);
  const { validations, addValidations, clearAllValidations } = useValidations(
    validationMeta,
    getFormValues,
    getServerKeysMap
  );

  return {
    customerPayments,
    creditCardInfo,
    paypalInfo,
    otherInfo,
    actions,
    validations,
    setEmail,
    ccPaymentOptions,
    paymentOptions,
    paymentMethods,
  };
};

export const useWireTransferInfo = () => {
  const customerPayments = useSelector(
    (state) => state.storefront.customerPayments
  );
  const { wireTransferInfo } = customerPayments;
  const [allowWireTransferAdd, setAllowWireTransferAdd] = useState(false);

  //Wire transfer validation
  useEffect(() => {
    if (
      wireTransferInfo &&
      wireTransferInfo.config &&
      wireTransferInfo.config.editMode
    ) {
      const validData = Object.values(wireTransferInfo.config).some(
        (value) => !value
      );
      setAllowWireTransferAdd(validData);
    }
  }, [wireTransferInfo]);

  return {
    wireTransferInfo,
    allowWireTransferAdd,
  };
};
export const useChequeInfo = () => {
  const customerPayments = useSelector(
    (state) => state.storefront.customerPayments
  );
  const { chequeInfo } = customerPayments;
  const [allowChequeAdd, setAllowChequeAdd] = useState(false);

  useEffect(() => {
    if (chequeInfo && chequeInfo.config && chequeInfo.config.editMode) {
      const validData = Object.values(chequeInfo.config).some(
        (value) => !value
      );
      setAllowChequeAdd(validData);
    } else {
      setAllowChequeAdd(false);
    }
  }, [chequeInfo]);

  return {
    chequeInfo,
    allowChequeAdd,
  };
};

export const useOtherPaymentInfo = () => {
  const [allowOtherAdd, setAllowOtherAdd] = useState(false);
  const actions = useActions(actionCreators);

  const customerPayments = useSelector(
    (state) => state.storefront.customerPayments
  );
  const { otherInfo } = customerPayments;

  useEffect(() => {
    if (otherInfo && otherInfo.config) {
      const validData = Object.values(otherInfo.config).some((value) => !value);
      setAllowOtherAdd(validData);
    } else {
      setAllowOtherAdd(false);
    }
  }, [otherInfo]);

  return {
    otherInfo,
    allowOtherAdd,
    actions,
  };
};

export const useETransferInfo = () => {
  const eTransferInfo = useSelector(
    (state) => state.storefront.customerPayments.eTransferInfo
  );
  const [allowETransferAdd, setAllowETransferAdd] = useState(false);
  const { email } = eTransferInfo.config;

  const getFormValues = useCallback(() => {
    return { email };
  }, [email]);

  const getServerKeysMap = useCallback(() => {
    return {};
  }, []);
  const { validations, addValidations, clearAllValidations } = useValidations(
    validationMeta,
    getFormValues,
    getServerKeysMap
  );

  useEffect(() => {
    if (eTransferInfo && eTransferInfo.config) {
      const validData =
        !!eTransferInfo.config.email &&
        !!eTransferInfo.config.preferredPassword &&
        !!eTransferInfo.config.maxAmount &&
        !!eTransferInfo.config.mobileNumber &&
        !Object.keys(validations).length;
      setAllowETransferAdd(validData);
    }
  }, [eTransferInfo, validations]);

  return {
    email,
    validations,
    eTransferInfo,
    allowETransferAdd,
  };
};
