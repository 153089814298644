import { useState, useEffect } from "react";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { IconButton, TableCell, TableRow, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import Styles from "../../../Styles.module.css";
import { useDuplicateDocument } from "souqh-react-redux-hooks/homeBuyer/useDuplicateDocument";
import ViewDocumentDialog from "../../DocumentManagementDialogs/ViewDocumentDialog";
import SharedWithPopup from "../../DocumentManagementDialogs/SharedWithPopup";
import DocTableCell from "./DocTableCell";
import SignedWithDialog from "../../DocumentManagementDialogs/SignedWithDialog";

const useStyles = makeStyles((theme) => ({
  paddingRight10: {
    paddingRight: 10,
  },
  tableHeading: {
    fontWeight: "bold",
  },
  cursorPointer: {
    cursor: "pointer",
  },
}));

export default function DocumentsRow(props) {
  const classes = useStyles();
  const {
    category,
    categoryId,
    firstCategory,
    documents,
    order,
    orderBy,
    downLoadDocument,
    setOpenDeleteDocumentDialog,
    setOpenVoidDocumentDialog,
    setOpenMoveDocumentDialog,
    setOpenArchiveDocumentDialog,
    setOpenRenameDocumentDialog,
    getSharedWithTooltip,
    setOpenShareDocumentDialog,
    handleCheckboxChange,
    downloadMultipleDocument,
    selectedData,
    setSelectedData,
    getTabWiseCategoriesList,
    documentTabName,
    setOpenDocumentHistoryDialog,
    setCurrentDocument,
    isMyTemplatesTab,
    setTemplateForUse,
    setOpenEditTemplate,
    setOpenSignDocumentDialog,
    setSignDocumentItem,
    setDocumentDataForEdit,
  } = props;
  const [open, setOpen] = useState(false);
  const [openInnerMenu, setOpenInnerMenu] = useState(false);
  const [innerAnchorRef, setInnerAnchorRef] = useState(null);
  const [selelctedRow, setSelectedRow] = useState(null);
  const { duplicateDocument } = useDuplicateDocument(
    getTabWiseCategoriesList,
    documentTabName
  );

  const [openDocumetDialog, setOpenDocumetDialog] = useState(false);
  const [documentItem, setDocumentItem] = useState(null);

  const [openSharedWithPopup, setOpenSharedWithPopup] = useState({
    open: false,
    sharedWithList: [],
  });

  const [openSignedWithPopup, setOpenSignedWithPopup] = useState({
    open: false,
    signedWithList: [],
  });

  useEffect(() => {
    if (category === firstCategory) {
      setOpen(true);
    }
  }, [category]);

  const handleInnerToggle = () => {
    setOpenInnerMenu((prevOpen) => !prevOpen);
  };

  const handleInnerClose = () => {
    setOpenInnerMenu(false);
  };

  function handleInnerListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenInnerMenu(false);
    }
  }

  const getUploadedDocuments = (docDetails) => {
    if (!docDetails) {
      return [];
    }

    const fileName =
      decodeURIComponent(docDetails.docName.replace(/^.*[\\\/]/, "")) || "";
    let docObj = {
      name: fileName,
      path: docDetails.documentPreviewUrl,
      type: fileName.split(".").pop(),
      isReferenceDocument: docDetails.isReferenceDocument,
      lastChange: docDetails.lastChange,
      documentId: docDetails.docId,
      signNowDocumentId: docDetails.signNowDocumentId,
    };
    return docObj;
  };

  return (
    <>
      <ViewDocumentDialog
        openDocumetDialog={openDocumetDialog}
        setOpenDocumetDialog={setOpenDocumetDialog}
        documentItem={documentItem}
        setDocumentItem={setDocumentItem}
      ></ViewDocumentDialog>
      <SharedWithPopup
        openSharedWithPopup={openSharedWithPopup.open}
        setOpenSharedWithPopup={setOpenSharedWithPopup}
        sharedWithList={openSharedWithPopup.sharedWithList}
      />
      <SignedWithDialog
        openSignedWithPopup={openSignedWithPopup.open}
        setOpenSignedWithPopup={setOpenSignedWithPopup}
        signedWithList={openSignedWithPopup.signedWithList}
      />
      <TableRow onClick={() => setOpen(!open)}>
        <TableCell
          className={clsx(classes.tableHeading, Styles.navyBlueColorForce)}
          style={{ fontSize: 12 }}
        >
          {category} ({documents && documents.length})
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell align="right"></TableCell>
        <TableCell align="right" className={classes.paddingRight10}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            className={Styles.navyBlueColor}
          >
            {open ? (
              <KeyboardArrowUpIcon className={Styles.navyBlueColorForce} />
            ) : (
              <KeyboardArrowDownIcon className={Styles.navyBlueColorForce} />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      {documents && documents.length ? (
        <TableRow>
          <DocTableCell
            categoryId={categoryId}
            documents={documents}
            order={order}
            orderBy={orderBy}
            downLoadDocument={downLoadDocument}
            setOpenDeleteDocumentDialog={setOpenDeleteDocumentDialog}
            setOpenVoidDocumentDialog={setOpenVoidDocumentDialog}
            setOpenMoveDocumentDialog={setOpenMoveDocumentDialog}
            setOpenArchiveDocumentDialog={setOpenArchiveDocumentDialog}
            setOpenRenameDocumentDialog={setOpenRenameDocumentDialog}
            getSharedWithTooltip={getSharedWithTooltip}
            setOpenShareDocumentDialog={setOpenShareDocumentDialog}
            handleCheckboxChange={handleCheckboxChange}
            downloadMultipleDocument={downloadMultipleDocument}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            setSelectedRow={setSelectedRow}
            selelctedRow={selelctedRow}
            setOpenDocumentHistoryDialog={setOpenDocumentHistoryDialog}
            setCurrentDocument={setCurrentDocument}
            handleInnerClose={handleInnerClose}
            openInnerMenu={openInnerMenu}
            duplicateDocument={duplicateDocument}
            handleInnerListKeyDown={handleInnerListKeyDown}
            setDocumentItem={setDocumentItem}
            setOpenDocumetDialog={setOpenDocumetDialog}
            getUploadedDocuments={getUploadedDocuments}
            setOpenSharedWithPopup={setOpenSharedWithPopup}
            innerAnchorRef={innerAnchorRef}
            setInnerAnchorRef={setInnerAnchorRef}
            handleInnerToggle={handleInnerToggle}
            open={open}
            isMyTemplatesTab={isMyTemplatesTab}
            setTemplateForUse={setTemplateForUse}
            setOpenEditTemplate={setOpenEditTemplate}
            setOpenSignDocumentDialog={setOpenSignDocumentDialog}
            setSignDocumentItem={setSignDocumentItem}
            setOpenSignedWithPopup={setOpenSignedWithPopup}
            documentTabName={documentTabName}
            setDocumentDataForEdit={setDocumentDataForEdit}
          />
        </TableRow>
      ) : null}
    </>
  );
}
