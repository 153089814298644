import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  makeStyles,
  TextField,
  Chip,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useParams } from "react-router";
import AddIcon from "@material-ui/icons/Add";
import AddNewSP from "../../ReferralManagement/AddNewSP";
import { useRecommendSP } from "souqh-react-redux-hooks/common/useRecommendSP";
import { useRecommmendSpToClient } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useRecommendSPToClient";
import { useSQQuery } from "souqh-react-redux-hooks/common/useSQQuery";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 620,
    minWidth: !isMobileDevice() && !isTabletDevice() && 800,
  },
}));

function RecommendSPDialog({
  openRecommendSPDialog,
  setOpenRecommendSPDialog,
  userId,
}) {
  const { query } = useSQQuery();
  let serviceRequestId = query.get("serviceRequestId");
  let { userId: clientId } = useParams();

  const classes = useStyles();
  const { serviceProviderList, getSPList } = useRecommendSP();
  const {
    recommmendSpToClient,
    recommendedSPsToClient,
    addLeadsForRecommendedSPs,
    actions: { saveRecommendSPToClientData, clearData },
  } = useRecommmendSpToClient(userId, serviceRequestId);

  const [openAddNewSP, setOpenAddNewSP] = useState(false);

  useEffect(() => {
    getSPList();
  }, []);

  return (
    <>
      <AddNewSP openAddNewSP={openAddNewSP} setOpenAddNewSP={setOpenAddNewSP} />
      <Dialog
        fullWidth
        disableBackdropClick
        open={openRecommendSPDialog}
        onClose={() => {
          setOpenRecommendSPDialog(false);
        }}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DialogTitle className={Styles.navyBlueColor}>
          <Typography variant="subtitle1">
            Recommend Service Provider
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid item xs={12} style={{ paddingBottom: 16, paddingTop: 16 }}>
            <Autocomplete
              multiple
              filterSelectedOptions
              fullWidth
              noOptionsText={"Search and select your service provider"}
              options={serviceProviderList || []}
              getOptionLabel={(option) => {
                return `${option.firstName} ${option.lastName} ${option.email}`;
              }}
              renderOption={(option) => (
                <React.Fragment>
                  <Grid item>
                    <Grid item>
                      <Typography variant="body1">
                        {option.firstName} {option.lastName}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">{option.email}</Typography>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
              getOptionSelected={(option, value) => {
                return option.email === value.email;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  multiline
                  rows={isMobileDevice() && 2}
                  label="Search and select your service provider"
                  variant="outlined"
                  autoComplete="new-password"
                />
              )}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={`${option.firstName} ${option.lastName}`}
                    {...getTagProps({ index })}
                    variant="outlined"
                    className={Styles.navyBlueColorForce}
                  />
                ))
              }
              onChange={(event, value) => {
                saveRecommendSPToClientData(value);
              }}
            />
          </Grid>
          {/* <Grid item xs={4} style={{ paddingBottom: 16 }}>
            <Button
              variant="outlined"
              startIcon={<AddIcon className={Styles.navyBlueColorForce} />}
              className={Styles.navyBlueColorForce}
              style={{ fontSize: 14, textTransform: "capitalize" }}
              onClick={() => {
                setOpenAddNewSP(true);
              }}
            >
              Add New Service Provider
            </Button>
          </Grid> */}
        </DialogContent>
        <Divider />
        <DialogActions style={{ padding: "16px 24px" }}>
          <Button
            variant="outlined"
            style={{
              padding: "6px 16px",
              marginRight: 12,
              textTransform: "capitalize",
            }}
            className={Styles.navyBlueColorForce}
            onClick={() => {
              setOpenRecommendSPDialog(false);
              clearData();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={Styles.primaryBtnAccent}
            style={{ textTransform: "capitalize", padding: "6px 32px" }}
            onClick={() => {
              recommmendSpToClient((res) => {
                if (res && res.data.status === 200) {
                  addLeadsForRecommendedSPs(
                    recommendedSPsToClient,
                    (response) => {
                      if (response && response.status === 200) {
                        setOpenRecommendSPDialog(false);
                        clearData();
                      }
                    },
                    clientId
                  );
                }
              });
            }}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default RecommendSPDialog;
