import { ThemeProvider } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useLocale } from "souqh-react-redux-hooks/locale";
import "./App.css";
import { getSouqhtheme } from "./SouqhTheme";
import useLocalStorage from "react-use-localstorage";
import { useDispatch } from "react-redux";
import { IntercomProvider } from "react-use-intercom";
import SQIntercomWrapper from "./SQIntercomWrapper";
import { useMessaging } from "souqh-react-redux-hooks/messaging/useMessaging";
import { useTagSomeone } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useTagSomeone";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import * as FullStory from "@fullstory/browser";
import { TEAM_MEMBER_USER } from "./components/ServiceProvider/serviceProviderConstants";

const App = () => {
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useLocalStorage("userInfo");
  const user = useAuthUser();

  const [signUpInfo, setSignUpInfo] = useLocalStorage("signUpInfo");
  const [theme, setTheme] = useState(null);
  const { locale } = useLocale(window.navigator.language || "en-US");
  const {
    sessionStarted,
    socketMessage,
    messagesLeftBarProcessor,
    subscribeToMessageUpdate,
    socketProcessor,
  } = useMessaging(true);
  const { getAllSouqhMembers } = useTagSomeone();
  const envName = process.env.REACT_APP_ENV_NAME;
  const INTERCOM_APP_ID = envName === "PROD" ? "v50av2d9" : "buto214u";

  // Comment below code for dev and qa env
  // Add user information in fullstory
  // if (user) {
  //   const userName =
  //     user.userType === TEAM_MEMBER_USER
  //       ? user.fullName
  //       : `${user.firstName} ${user.lastName}`;

  //   FullStory.identify(userName, {
  //     displayName: userName,
  //     name: userName,
  //     email: user.email,
  //   });
  // }

  useEffect(() => {
    if (userInfo) {
      dispatch({ type: "SAVE_USER", payload: JSON.parse(userInfo) });
    } else {
      dispatch({ type: "CLEAR_EXPLORE_SEARCH_RESULT", payload: null });
    }
  }, [userInfo]);

  useEffect(() => {
    if (signUpInfo) {
      dispatch({
        type: "SAVE_HB_SINGUP_INFO",
        payload: JSON.parse(signUpInfo),
      });
    }
  }, [signUpInfo]);

  useEffect(() => {
    const getTheme = async () => {
      const t = await getSouqhtheme(
        locale,
        window.navigator.language || "en-US"
      );
      setTheme(t);
    };
    if (!theme && locale) {
      getTheme();
    }
  }, [theme, locale]);

  useEffect(() => {
    if (sessionStarted) {
      subscribeToMessageUpdate(socketProcessor);
    }
  }, [sessionStarted]);

  useEffect(() => {
    if (socketMessage) {
      messagesLeftBarProcessor(true);
    }
  }, [socketMessage]);

  // Get all users
  useEffect(() => {
    if (user) {
      getAllSouqhMembers();
    } else {
      dispatch({ type: "RESET_ALL_USERS_DATA" });
    }
  }, [user]);

  if (!theme) {
    return <div>loading</div>;
  }

  return (
    <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={true}>
      <ThemeProvider theme={theme}>
        <SQIntercomWrapper />
      </ThemeProvider>
    </IntercomProvider>
  );
};

export default App;
