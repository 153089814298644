export const AGE_RANGE = ["18-25", "26-35", "36-55", "55 and above"];
export const MARITAL_STATUS = [
  { key: "Single", value: "SINGLE" },
  { key: "Married", value: "MARRIED" },
  { key: "Common-law", value: "COMMON_LAW" },
  { key: "Other", value: "OTHER" },
];
export const PROPERTY_TYPE = [
  "Detached",
  "Semi-detached",
  "Townhouse",
  "Condo/Apartment",
  "Other",
];
export const EMPLOYMENT_TYPE = ["Employed", "Self Employed"];

export const IMAGE_FILE_FORMATS = [
  "tif",
  "tiff",
  "bmp",
  "jpeg",
  "jpg",
  "gif",
  "svg",
  "png",
  "eps",
  "raw",
];

export const OFFICE_FILE_FORMATS = [
  "doc",
  "docx",
  "xls",
  "xlsx",
  "ppt",
  "pptx",
];
