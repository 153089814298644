import { Divider, Grid, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Styles from "../../../Styles.module.css";
import PersonIcon from "@material-ui/icons/Person";
import StoreMallDirectoryIcon from "@material-ui/icons/StoreMallDirectory";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import TimelineIcon from "@material-ui/icons/Timeline";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import TimerIcon from "@material-ui/icons/Timer";
import { useSPKeyStatisticsWidget } from "souqh-react-redux-hooks/serviceProvider/useSPKeyStatisticsWidget";
import {
  isMobileDevice,
  isTabletDevice,
  abbreviateNumber,
  isMarketplacePlan,
  isOnboardingInComplete,
} from "../../../utils/AppUtils";
import { useHistory } from "react-router-dom";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import PlanUpgradeMsgDialog from "../../common/PlanUpgradeMsgDialog";

export default function SPKeyStatisticsWidget({
  xlUp,
  openPlanUpgradeMsgDialog,
  setOpenPlanUpgradeMsgDialog,
}) {
  const keyStatsData = [
    {
      image: <AssignmentIndIcon className={Styles.primaryTxtAccent} />,
      text: "Lead Received",
      id: "keyStatiticsLeadDto",
    },
    {
      image: <PersonIcon className={Styles.primaryTxtAccent} />,
      text: "Active Clients",
      id: "keyStatiticsActiveClientDto",
    },
    {
      image: <PersonIcon className={Styles.primaryTxtAccent} />,
      text: "Completed Clients",
      id: "keyStatiticsCompletedDto",
    },
    {
      image: <TimerIcon className={Styles.primaryTxtAccent} />,
      text: "Average Response Time",
      id: "keyStatisticsResponseTime",
    },
    {
      image: <StoreMallDirectoryIcon className={Styles.primaryTxtAccent} />,
      text: "Storefront Visits",
      id: "storeFrontVisit",
    },
    {
      image: <InsertDriveFileIcon className={Styles.primaryTxtAccent} />,
      text: "Documents Shared",
      id: "keyStatiticsEsignDocumentDto",
    },
    {
      image: <CardGiftcardIcon className={Styles.primaryTxtAccent} />,
      text: "Referrals Sent",
      id: "keyStatiticsReferralsDto",
    },
  ];
  //   const [keyStatsData, setKeyStatsData] = useState(data);
  const { getSPkeyStatisticsData, SPKeyStatisticsData } =
    useSPKeyStatisticsWidget();
  let history = useHistory();
  const userInfo = useAuthUser();

  useEffect(() => {
    getSPkeyStatisticsData();
  }, []);

  const getCount = (data) => {
    if (data.id === "keyStatisticsResponseTime") {
      let value =
        SPKeyStatisticsData &&
        SPKeyStatisticsData[data.id] &&
        SPKeyStatisticsData[data.id].totalResponseTime < 1
          ? "< 1"
          : SPKeyStatisticsData &&
            SPKeyStatisticsData[data.id] &&
            SPKeyStatisticsData[data.id].totalResponseTime;

      return (
        <React.Fragment>
          <Grid container justifyContent="center">
            <div style={{ display: "flex" }}>
              <Typography variant={xlUp ? "h5" : "h6"} noWrap>
                {value}
              </Typography>
              <Typography
                style={{ fontSize: 11, fontWeight: 600, marginTop: 15 }}
                noWrap
              >
                days
              </Typography>
            </div>
            {/* <Grid item>
            <Typography variant={xlUp ? "h5" : "h6"}>{value}</Typography>
          </Grid>
          <Grid item>
            <Typography style={{ fontSize: 11, fontWeight: 600 }}>
              days
            </Typography>
          </Grid> */}
          </Grid>
        </React.Fragment>
      );
    } else if (data.id === "storeFrontVisit") {
      return (SPKeyStatisticsData && SPKeyStatisticsData[data.id]) || 0;
    } else {
      return (
        (SPKeyStatisticsData &&
          SPKeyStatisticsData[data.id] &&
          abbreviateNumber(SPKeyStatisticsData[data.id].totalCount)) ||
        0
      );
    }
  };

  return (
    <React.Fragment>
      <Grid
        container
        style={
          isMobileDevice() || isTabletDevice()
            ? {
                display: "flex",
                flexWrap: "nowrap",
                overflowX: "auto",
              }
            : { padding: "30px 0px 20px 0px", height: "100%" }
        }
      >
        {keyStatsData.map((data, index) => (
          <React.Fragment key={index}>
            <Grid
              container
              item
              xs={1}
              alignItems="center"
              alignContent="center"
              style={{
                minWidth: isMobileDevice()
                  ? "38%"
                  : isTabletDevice()
                  ? "22%"
                  : "14%",
                padding: "0px 10px",
                height: xlUp ? "18.5rem" : 230,
                cursor:
                  data.text === "Average Response Time" ||
                  isOnboardingInComplete(userInfo)
                    ? "default"
                    : "pointer",
              }}
              onClick={() => {
                if (!isOnboardingInComplete(userInfo)) {
                  if (isMarketplacePlan(userInfo)) {
                    setOpenPlanUpgradeMsgDialog(true);
                  } else {
                    switch (data.text) {
                      case "Lead Received":
                        history.push("/serviceprovider/clients?tabIndex=3");
                        break;
                      case "Active Clients":
                        history.push("/serviceprovider/clients?tabIndex=0");
                        break;
                      case "Completed Clients":
                        history.push("/serviceprovider/clients?tabIndex=2");
                        break;
                      case "Storefront Visits":
                        history.push("/serviceprovider/storefront?tabIndex=1");
                        break;
                      case "Documents Shared":
                        history.push("/serviceprovider/documents");
                        break;
                      case "Referrals Sent":
                        history.push("/serviceprovider/referralmanagement");
                        break;
                    }
                  }
                }
              }}
            >
              <Grid
                item
                xs={12}
                style={{
                  textAlign: "center",
                  height: "15%",
                }}
              >
                {data.image}
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  textAlign: "center",
                  height: isMobileDevice() || isTabletDevice() ? "20%" : "25%",
                }}
              >
                <Typography
                  className={Styles.navyBlueColor}
                  style={{ fontSize: xlUp ? 16 : 12, fontWeight: 500 }}
                >
                  {data.text}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  textAlign: "center",
                  height: isMobileDevice() || isTabletDevice() ? "20%" : "25%",
                  marginTop: 15,
                }}
              >
                <Typography
                  className={Styles.navyBlueColor}
                  variant={xlUp ? "h5" : "h6"}
                >
                  {getCount(data)}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  textAlign: "center",
                  height: "25%",
                  marginTop: 10,
                }}
              >
                <Grid container alignItems="center">
                  <Grid item xs={6}>
                    <Typography
                      style={{
                        fontSize: 10,
                        color: "#0A4C25",
                        fontWeight: 600,
                      }}
                    >
                      0%
                    </Typography>
                  </Grid>
                  <Grid container item xs={6} justifyContent="flex-start">
                    <TimelineIcon style={{ color: "#0A4C25" }} />
                  </Grid>
                </Grid>
                <Typography style={{ fontSize: 9, color: "#868DA6" }}>
                  This Month{" "}
                  {data.id === "keyStatisticsResponseTime"
                    ? (SPKeyStatisticsData &&
                        SPKeyStatisticsData[data.id] &&
                        SPKeyStatisticsData[
                          data.id
                        ].currentMonthResponseTime.toFixed(2)) ||
                      0
                    : (SPKeyStatisticsData &&
                        SPKeyStatisticsData[data.id] &&
                        SPKeyStatisticsData[data.id].currentMonthCount) ||
                      0}
                </Typography>
                <Typography style={{ fontSize: 9, color: "#868DA6" }}>
                  Last Month{" "}
                  {data.id === "keyStatisticsResponseTime"
                    ? (SPKeyStatisticsData &&
                        SPKeyStatisticsData[data.id] &&
                        SPKeyStatisticsData[
                          data.id
                        ].previousMonthReponseTime.toFixed(2)) ||
                      0
                    : (SPKeyStatisticsData &&
                        SPKeyStatisticsData[data.id] &&
                        SPKeyStatisticsData[data.id].previousMonthCount) ||
                      0}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              {index !== 6 ? (
                <Divider
                  orientation="vertical"
                  style={{
                    backgroundColor: "#0E1C4E1F",
                    height: "100%",
                    width: 2,
                  }}
                />
              ) : null}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </React.Fragment>
  );
}
