import { Chip, Grid, makeStyles, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { find } from "lodash-es";
import React, { useState } from "react";
import { useAreaOfSpec } from "souqh-react-redux-hooks/useAreaOfSpec";
import Styles from "../../../Styles.module.css";
import { matchSorter } from "match-sorter";

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: 20,
  },
}));

export default function AreaOfSpecialization({
  areaOfSpec,
  selectedAreaOfSpec,
  deleteItem,
  addItem,
  setCustomAreaOfSpec,
  customAreaOfSpec,
  validation,
  setValidation,
  maxLimitValidation,
  setSelectedAreaOfSpec,
  checkDuplicate,
}) {
  const classes = useStyles();

  return (
    <Grid container item xs={12} className={classes.marginTop}>
      <Autocomplete
        multiple
        filterSelectedOptions
        fullWidth
        style={{ display: "inline-flex" }}
        variant="outlined"
        value={selectedAreaOfSpec}
        noOptionsText={
          "Please enter your areas of specialization and press enter to add"
        }
        options={areaOfSpec || []}
        getOptionLabel={(option) => option.name}
        onChange={(event, value) => {
          setSelectedAreaOfSpec(value);
        }}
        getOptionSelected={(option, value) => {
          return option.name === value.name;
        }}
        renderInput={(params) => (
          <TextField
            required
            variant="outlined"
            {...params}
            label={"Area of Specialization"}
            onChange={(event) => {
              setCustomAreaOfSpec(event.target.value);
              checkDuplicate(event);
            }}
            onKeyPress={(event) => addItem(event, customAreaOfSpec)}
            error={maxLimitValidation}
            helperText={
              maxLimitValidation &&
              "Max limit is 20 specializations. You can't add more"
            }
          />
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.name}
              {...getTagProps({ index })}
              onDelete={deleteItem(option)}
              variant="outlined"
              className={Styles.navyBlueColorForce}
            />
          ))
        }
        inputProps={{
          className: Styles.navyBlueColorForce,
        }}
        filterOptions={(options, { inputValue }) => {
          if (inputValue.length >= 1) {
            return matchSorter(options, inputValue, {
              keys: ["name"],
            });
          } else {
            return [];
          }
        }}
      />
    </Grid>
  );
}
