import { useDispatch } from "react-redux";
import { useAuthUser } from "../useLogin";
import { useState } from "react";
import { useApiClient } from "../useApiClient";

export const useRenameDocument = (
  documentId,
  categoryId,
  setOpenRenameDocumentDialog,
  getTabWiseCategoriesList,
  documentTabName,
  documentName,
  documentItem
) => {
  const { apiClient } = useApiClient();
  const [renamedDocument, setRenamedDocument] = useState("");
  const [renameErrorMsg, setRenameErrorMsg] = useState("");
  const userInfo = useAuthUser();
  const dispatch = useDispatch();
  const fileExtension = documentName && documentName.split(".").pop();
  const renameData = {
    categoryId: categoryId,
    documentId: documentId,
    documentNewName: `${renamedDocument}.${fileExtension}`,
    userId: userInfo.userId,
  };

  const handleRenameKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.code === "Enter") {
      renameHBDocument((response) => {
        if (response && response.data.status === 200) {
          setOpenRenameDocumentDialog(false);
        }
      });
    }
  };

  const renameHBDocument = (onResult) => {
    if (documentItem && documentItem.signNowDocumentId) {
      renameEsignedDocument(onResult);
    } else {
      renameRefDocument(onResult);
    }
  };

  const renameEsignedDocument = (onResult) => {
    const url = `/signnow/rename/document/${documentItem.signNowDocumentId}/${userInfo.userId}/${renamedDocument}.${fileExtension}`;
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .put(url, {})
      .then((res) => {
        if (res.data.status === 200) {
          documentTabName &&
            getTabWiseCategoriesList &&
            getTabWiseCategoriesList(documentTabName);
        } else if (res.data.status === 409) {
          setRenameErrorMsg(res.data.message);
        }
        onResult && onResult(res);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const renameRefDocument = (onResult) => {
    dispatch({ type: "SET_LOADING", value: true });

    return apiClient
      .post("/documents/rename", renameData)
      .then((res) => {
        if (res.data.status === 200) {
          documentTabName &&
            getTabWiseCategoriesList &&
            getTabWiseCategoriesList(documentTabName);
        } else if (res.data.status === 409) {
          setRenameErrorMsg(res.data.message);
        }
        onResult && onResult(res);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    setRenamedDocument,
    renamedDocument,
    renameHBDocument,
    handleRenameKeypress,
    renameErrorMsg,
  };
};
