import { Checkbox, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";

const styles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined": {
      top: -6,
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px",
    },
  },
}));
export default function AssocSPRow({ rowData, index, handleAssocSPSelect }) {
  const classes = styles();

  return (
    <Grid
      item
      container
      xs={12}
      style={{ padding: "4px 0px" }}
      className={classes.root}
      alignItems="center"
    >
      <Grid item xs={1}>
        <Checkbox
          checked={rowData.selected || false}
          onChange={(event) => {
            handleAssocSPSelect(event.target.checked, index);
          }}
          color={Styles.navyBlueColorForce}
        />
      </Grid>
      <Grid item xs={4}>
        <Typography
          variant="body1"
          className={clsx(Styles.navyBlueColor, Styles.sqEllipsis)}
        >
          {rowData.role}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography
          variant="body1"
          className={clsx(Styles.navyBlueColor, Styles.sqEllipsis)}
        >
          {rowData.businessType}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography
          variant="body1"
          className={clsx(Styles.navyBlueColor, Styles.sqEllipsis)}
        >
          {rowData.email}
        </Typography>
      </Grid>
    </Grid>
  );
}
