import React from "react";
import { useTheme, useMediaQuery } from "@material-ui/core";

export default function ClientStatus({ isDashboardWidget, clientStatus }) {
  const theme = useTheme();
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  let status = (clientStatus || "").toLowerCase().trim();
  let color = "#FA7E61";
  let backgroundColor = "#FA7E6140";

  if (status) {
    switch (status) {
      case "find_your_home":
        status = "FIND YOUR HOME";
        break;
      case "fund_your_home":
        color = "#66D7D1";
        backgroundColor = "#66D7D140";
        status = "FUND YOUR HOME";
        break;
      case "close_your_home":
        color = "#C86FC9";
        backgroundColor = "#C86FC940";
        status = "CLOSE YOUR HOME";
        break;
      case "move_into_your_home":
        color = "#D90368";
        backgroundColor = "#D9036840";
        status = "MOVE INTO YOUR HOME";
        break;
      case "new":
        color = "#2842D0";
        backgroundColor = "#C9CFF3";
        status = "NEW";
        break;
      case "viewed":
        color = "#868DA6";
        backgroundColor = "#F5F6F8";
        status = "VIEWD";
        break;
      case "accepted":
        color = "#0A4C25";
        backgroundColor = "#C3DECE";
        status = "ACCEPTED";
        break;
      case "in_progress":
        status = "IN PROGRESS";
        break;
      case "requested":
        color = "#987400";
        backgroundColor = "#FFEFBF";
        status = "REQUESTED";
        break;
      case "rejected":
        color = "#700000";
        backgroundColor = "#EEBFBF";
        status = "REJECTED";
        break;
      case "pending_invite":
        color = "#700000";
        backgroundColor = "#EEBFBF";
        status = "PENDING INVITE";
        break;
      case "joined_souqh":
        color = "#0a4c25";
        backgroundColor = "#aceb98";
        status = "JOINED SOUQH";
        break;
      case "active":
        color = "#0a4c25";
        backgroundColor = "#aceb98";
        status = "ACTIVE";
        break;
      case "fulfilled":
        color = "#987400";
        backgroundColor = "#FFEFBF";
        status = "FULFILLED";
        break;
      default:
        break;
    }
  }
  return (
    <span
      style={{
        backgroundColor,
        borderRadius: 4,
        textAlign: "center",
        padding: "4px 10px",
        fontSize: isDashboardWidget && !xlUp ? 11 : 14,
        color,
      }}
    >
      {status || "-"}
    </span>
  );
}
