import { cloneDeep } from "lodash";
import {
  SET_PLAN_DURATION,
  SET_PROMO_CODE,
  SET_HAVE_PROMO_CODE,
  SET_TEAM_PLAN_ID,
  SET_USER_PLAN_ID,
  SET_PREV_USER_PLAN_ID,
  SET_PREV_PLAN_DURATION,
} from "./actionConstants";

const initialState = {
  planDuration: "YEARLY",
  promoCode: "",
  havePromoCode: false,
  selectedTeamPlan: null,
  selectedUserPlan: 3, // it is hardcoded to 3 i.e premium plan as it is by default 30 days free premium plan for user, and it can be only changed after 30 dauys from admin module
  selectedPrevUserPlan: null,
  selectedPrevPlanDuration: null,
};

const subscriptionPlan = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_PLAN_DURATION:
      newState = cloneDeep(state);
      newState.planDuration = action.payload;
      return newState;
    case SET_PROMO_CODE:
      newState = cloneDeep(state);
      newState.promoCode = action.payload;
      return newState;
    case SET_HAVE_PROMO_CODE:
      newState = cloneDeep(state);
      newState.havePromoCode = action.payload;
      return newState;
    case SET_TEAM_PLAN_ID:
      newState = cloneDeep(state);
      newState.selectedTeamPlan = action.payload;
      return newState;
    case SET_USER_PLAN_ID:
      newState = cloneDeep(state);
      newState.selectedUserPlan = action.payload;
      return newState;
    case SET_PREV_USER_PLAN_ID:
      newState = cloneDeep(state);
      newState.selectedPrevUserPlan = action.payload;
      return newState;
    case SET_PREV_PLAN_DURATION:
      newState = cloneDeep(state);
      newState.selectedPrevPlanDuration = action.payload;
      return newState;
    default:
      return state;
  }
};

export default subscriptionPlan;
