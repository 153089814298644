import { useAuthUser } from "../../useLogin";
import { useSelector, useDispatch } from "react-redux";
import { useApiClient } from "../../useApiClient";
import { SET_ACTIVE_CLIENT_DATA } from "souqh-redux/reducers/actionConstants";
import { useActions } from "../../useActions";
import { color_success } from "../../toasterColors";

const actionCreators = {
  clearActiveClientsData: (payload) => ({
    type: SET_ACTIVE_CLIENT_DATA,
    payload: { response: [] },
  }),
};

export const useAssignClientDialog = () => {
  const { apiClient } = useApiClient();
  const userInfo = useAuthUser();
  const dispatch = useDispatch();
  const actions = useActions(actionCreators);

  const { active } = useSelector((state) => state.clientManagement);

  const assignClient = (
    serviceRequestId,
    finalAssignData,
    assignToValue,
    onResult
  ) => {
    let finalUserIds = finalAssignData.map((item) => item.userId);
    let serviceRequestAssignmentDTO = {
      serviceReqIds: serviceRequestId,
      userId: assignToValue === "MYSELF" ? [userInfo.userId] : finalUserIds,
    };
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post(`/service-provider/client/assign`, serviceRequestAssignmentDTO)
      .then(function (response) {
        if (response && response.data.status === 200) {
          onResult && onResult(response);
          dispatch({
            type: "SET_TOASTER",
            payload: {
              message: "Clients assigned successfully",
              bgColor: color_success,
              open: true,
            },
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    assignClient,
  };
};
