import { Divider, Grid, Typography } from "@material-ui/core";
import React from "react";
import ActiveClientInfoCard from "./ActiveClientInfoCard";
import CompletedClientInfoCard from "./CompletedClientInfoCard";
import InActiveClientInfoCard from "./InActiveClientInfoCard";
import LeadsClientInfoCard from "./LeadsClientInfoCard";
import Styles from "../../../Styles.module.css";
import Pagination from "@material-ui/lab/Pagination";
import { isMobileDevice } from "../../../utils/AppUtils";

export default function ClientInfoGridView({
  tabValue,
  displayClientsData,
  handlePageChange,
  getClientManagementData,
}) {
  const renderSwitch = (tabValue, data) => {
    switch (tabValue) {
      case 0:
        return <ActiveClientInfoCard data={data} />;
      case 1:
        return <InActiveClientInfoCard data={data} />;
      case 2:
        return <CompletedClientInfoCard data={data} />;
      case 3:
        return (
          <LeadsClientInfoCard
            data={data}
            getClientManagementData={getClientManagementData}
          />
        );
    }
  };

  return (
    <Grid container spacing={2}>
      {displayClientsData &&
      displayClientsData.response &&
      displayClientsData.response.length
        ? displayClientsData.response.map((data, index) => (
            <React.Fragment>
              <Grid key={index} item xs={12} md={6} lg={4} xl={3}>
                {renderSwitch(tabValue, data)}
              </Grid>
              {isMobileDevice() && (
                <Divider
                  orientation="horizontal"
                  variant="fullWidth"
                  style={{ width: "calc(100% + 32px)", marginLeft: -20 }}
                />
              )}
            </React.Fragment>
          ))
        : null}
      {displayClientsData &&
      displayClientsData.response &&
      displayClientsData.response.length &&
      tabValue !== 0 ? (
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          style={{ margin: "16px 0" }}
        >
          <Pagination
            count={displayClientsData && displayClientsData.totalPages}
            shape="rounded"
            variant="outlined"
            onChange={handlePageChange}
          />
        </Grid>
      ) : null}
    </Grid>
  );
}
