import { Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import { useCompeteText } from "souqh-react-redux-hooks/serviceProvider/storefront/useCompeteText";
import { useTheme } from "souqh-react-redux-hooks/serviceProvider/storefront/useTheme";
import Styles from "../../../Styles.module.css";

function CompeteLine() {
  const { competeText, actions } = useCompeteText();
  const { primaryColor } = useTheme();
  return (
    <Grid container style={{ marginBottom: 50 }}>
      <Typography variant="h6" className={Styles.navyBlueColor}>
        {competeText || "What separates you from the competition?"}
      </Typography>
      {/* <TextField
        label="What separates you from the competition?"
        required
        variant="outlined"
        fullWidth
        value={competeText}
        onChange={(e) => {
          actions.setCompeteText({ competeText: e.target.value });
        }}
      ></TextField> */}
    </Grid>
  );
}

export default CompeteLine;
