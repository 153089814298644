import { useDispatch, useSelector } from "react-redux";
import { useAuthUser } from "../useLogin";
import { useActions } from "../useActions";
import { SET_SELECTED_CATEGORY } from "../../souqh-redux/reducers/actionConstants";
import { useState } from "react";
import { useApiClient } from "../useApiClient";
import { color_success } from "../toasterColors";

const actionCreator = {
  setSelectedCategory: (payload) => ({ type: SET_SELECTED_CATEGORY, payload }),
};

export const useDeleteCategory = (
  getTabWiseCategoriesList,
  documentTabName
) => {
  const { apiClient } = useApiClient();

  const userInfo = useAuthUser();
  const actions = useActions(actionCreator);
  const selectedCategory = useSelector(
    (state) => state.documents.selectedCategory
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const dispatch = useDispatch();
  const deleteCategory = (onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .delete(
        `/category?categoryId=${selectedCategory}&userId=${userInfo.userId}`
      )
      .then((res) => {
        if (res.data.status === 200) {
          getTabWiseCategoriesList(documentTabName);
          dispatch({
            type: "SET_TOASTER",
            payload: {
              message: `You have deleted a category - ${selectedCategoryName}`,
              bgColor: color_success,
              open: true,
            },
          });
        } else if (res.data.status === 424) {
          setErrorMessage(res.data.message);
        }
        onResult && onResult(res);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    deleteCategory,
    actions,
    errorMessage,
    setErrorMessage,
    selectedCategoryName,
    setSelectedCategoryName,
  };
};
