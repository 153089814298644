import { Grid } from "@material-ui/core";
import React from "react";
import SettingsLeftPanel from "./SettingsLeftPanel";
import SettingsRightPanel from "./SettingsRightPanel";

export default function Settings() {
  return (
    <Grid
      item
      container
      xs={12}
      style={{ height: window.innerHeight, marginLeft: 80 }}
    >
      <Grid item container xs={12} style={{ height: "100%" }}>
        <div
          style={{
            width: 300,
            backgroundColor: "#F5F6F8",
          }}
        >
          <SettingsLeftPanel />
        </div>
        <div
          style={{
            width: "calc(100% - 300px)",
            backgroundColor: "#FFFFFF",
          }}
        >
          <SettingsRightPanel />
        </div>
      </Grid>
    </Grid>
  );
}
