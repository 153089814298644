import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import * as moment from "moment";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import {
  HOME_BUYER_USER,
  SERVICE_PROVIDER_USER,
} from "../../ServiceProvider/serviceProviderConstants";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 400,
  },
}));

function ViewTagDialog({
  openViewTagDialog,
  setOpenViewTagDialog,
  viewTagDialogData,
}) {
  const classes = useStyles();
  const userInfo = useAuthUser();

  let requestorEmail;
  if (userInfo.userType === HOME_BUYER_USER) {
    requestorEmail =
      viewTagDialogData &&
      viewTagDialogData.requestorSPDetails &&
      viewTagDialogData.requestorSPDetails.email;
  } else {
    requestorEmail =
      viewTagDialogData && viewTagDialogData.requestorHBDetails
        ? viewTagDialogData.requestorHBDetails.email
        : viewTagDialogData &&
          viewTagDialogData.requestorSPDetails &&
          viewTagDialogData.requestorSPDetails.email;
  }

  return (
    <Dialog
      fullWidth
      open={openViewTagDialog}
      onClose={() => {
        setOpenViewTagDialog(false);
      }}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle
        className={Styles.navyBlueColor}
        style={{ padding: "12px 24px" }}
      >
        <Typography variant="subtitle1">View Tag</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ marginTop: 8 }}>
        <Grid container item xs={12}>
          <Grid item xs={3}>
            <Typography
              variant="body1"
              className={Styles.navyBlueColor}
              style={{ fontWeight: 600 }}
            >
              From
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography
              variant="body1"
              className={Styles.navyBlueColor}
              style={{ fontWeight: 600 }}
            >
              {requestorEmail}
            </Typography>
          </Grid>
          <Divider
            orientation="horizontal"
            variant="fullWidth"
            style={{ width: "100%", margin: "16px 0" }}
          />
          <Grid item xs={3}>
            <Typography
              variant="body1"
              className={Styles.navyBlueColor}
              style={{ fontWeight: 600 }}
            >
              Comment
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="body1" className={Styles.navyBlueColor}>
              {viewTagDialogData && viewTagDialogData.tagComments}
            </Typography>
          </Grid>
          <Divider
            orientation="horizontal"
            variant="fullWidth"
            style={{ width: "100%", margin: "16px 0" }}
          />
          <Grid item xs={3}>
            <Typography
              variant="body1"
              className={Styles.navyBlueColor}
              style={{ fontWeight: 600 }}
            >
              Deadline
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="body1" className={Styles.navyBlueColor}>
              {viewTagDialogData && viewTagDialogData.dueDate
                ? moment(viewTagDialogData.dueDate).format("MMM DD, YYYY")
                : "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions style={{ padding: "16px 24px" }}>
        <Button
          variant="outlined"
          style={{
            padding: "6px 16px",
            textTransform: "capitalize",
          }}
          className={Styles.navyBlueColorForce}
          onClick={() => {
            setOpenViewTagDialog(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ViewTagDialog;
