import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Tabs,
  Tab,
  makeStyles,
  Divider,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";

import { useDocumentPreview } from "souqh-react-redux-hooks/homeBuyer/useDocumentPreview";
import clsx from "clsx";
import { useViewDocument } from "souqh-react-redux-hooks/common/useViewDocument";
import AddIcon from "@material-ui/icons/Add";
import AddSummaryDialog from "../ServiceProvider/UploadDocuments/AddSummaryDialog";
import CustomAccordian from "./CustomAccordian";
import DocumentPreviewRenderer from "./DocumentPreviewRenderer";
import { isMobileDevice, isTabletDevice } from "../../utils/AppUtils";

const useStyles = makeStyles((theme) => ({
  root: {},
  tabs: {
    minWidth: "16%",
    fontWeight: "bold",
    textTransform: "capitalize",
    opacity: "100%",
  },
  dialogPaper: {
    height: isMobileDevice() ? 680 : 733,
    minWidth: !isMobileDevice() && !isTabletDevice() && 1140,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function ViewDocumentDialog({
  openDocumetDialog,
  setOpenDocumetDialog,
  documentItem,
  setDocumentItem,
}) {
  const classes = useStyles();
  const { eleRef, fileNotLoaded, setRefreshTimer, clearAll, iframeLoaded } =
    useDocumentPreview();
  const [viewDocumentTab, setViewDocumentTab] = useState(0);
  const {
    documentSummaryList,
    setDocumentSummaryList,
    loadDocumentSummary,
    openAddSummaryDialog,
    setOpenAddSummaryDialog,
    summaryTobeEdited,
    setSummaryTobeEdited,
    checkAndAddSummary,
    deleteSummary,
    handleClose,
    canEdit,
  } = useViewDocument(documentItem);

  useEffect(() => {
    setRefreshTimer(documentItem);
  }, []);

  useEffect(() => {
    if (documentItem) {
      loadDocumentSummary(documentItem.documentId);
    } else {
      setDocumentSummaryList([]);
    }
  }, [documentItem]);

  if (!documentItem) {
    return null;
  }

  const documentType = (documentItem.type || "").toLowerCase();

  const VIEW_DOCUMENT_TABS = ["Summary", "Full Document"];

  const previewSection = () => {
    return (
      <Grid container>
        <Grid item>
          {fileNotLoaded && (
            <Typography variant="caption" style={{ color: "red" }}>
              Failed to load preview, please download file
            </Typography>
          )}
        </Grid>
        <Grid item container xs={12} style={{ justifyContent: "center" }}>
          <DocumentPreviewRenderer
            documentItem={documentItem}
            iframeLoaded={iframeLoaded}
            eleRef={eleRef}
          />
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      <AddSummaryDialog
        openAddSummaryDialog={openAddSummaryDialog}
        setOpenAddSummaryDialog={setOpenAddSummaryDialog}
        checkAndAddSummary={checkAndAddSummary}
        summaryList={documentSummaryList}
        summaryTobeEdited={summaryTobeEdited}
        setSummaryTobeEdited={setSummaryTobeEdited}
        deleteSummary={deleteSummary}
      />
      <Dialog
        fullWidth
        maxWidth={"lg"}
        open={openDocumetDialog}
        classes={{
          paper: classes.dialogPaper,
          root: classes.root,
        }}
        onClose={handleClose}
      >
        <DialogTitle
          className={Styles.navyBlueColor}
          style={
            !documentItem.isReferenceDocument
              ? { paddingBottom: 0, paddingTop: 0 }
              : { paddingBottom: 0 }
          }
        >
          {!documentItem.isReferenceDocument ? (
            <Grid item container xs={12}>
              <Tabs
                value={viewDocumentTab}
                onChange={(event, newValue) => {
                  setViewDocumentTab(newValue);
                }}
                aria-label="simple tabs example"
                classes={{ indicator: Styles.indicator }}
              >
                {VIEW_DOCUMENT_TABS.map((tab, index) => (
                  <Tab
                    key={index}
                    label={tab}
                    className={clsx(classes.tabs, {
                      [Styles.primaryTxtAccentForce]: index === viewDocumentTab,
                      [Styles.navyBlueColorForce]: index !== viewDocumentTab,
                    })}
                  />
                ))}
              </Tabs>
            </Grid>
          ) : (
            <>
              <Grid item container xs={12}>
                <Typography
                  variant="subtitle1"
                  className={clsx(Styles.sqEllipsis, Styles.navyBlueColor)}
                >
                  {documentItem.name}
                </Typography>
              </Grid>
              <Grid item container xs={12}>
                <Typography variant="body1" className={Styles.navyBlueColor}>
                  {documentItem.lastChange}
                </Typography>
              </Grid>
            </>
          )}
        </DialogTitle>
        <DialogContent
          style={{
            paddingTop: !isMobileDevice() && 10,
            backgroundColor: "#0E1C4E0A",
            padding: isMobileDevice() && "10px 0px 0px 0px",
          }}
        >
          {!documentItem.isReferenceDocument ? (
            <>
              <Grid item container xs={12}>
                <Grid item container xs={8}>
                  <Grid item container xs={12}>
                    <Typography
                      variant="subtitle1"
                      className={clsx(Styles.sqEllipsis, Styles.navyBlueColor)}
                    >
                      {documentItem.name}
                    </Typography>
                  </Grid>
                  <Grid item container xs={12} style={{ marginBottom: 16 }}>
                    <Typography
                      variant="body1"
                      className={Styles.navyBlueColor}
                    >
                      {documentItem.lastChange}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={4}
                  style={{ justifyContent: "flex-end" }}
                >
                  {canEdit ? (
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon style={{ fill: "#0e1c4e" }} />}
                      style={{ textTransform: "capitalize", height: 36 }}
                      className={clsx(Styles.navyBlueColorForce)}
                      onClick={() => {
                        setOpenAddSummaryDialog(true);
                      }}
                    >
                      Add Summary Section
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
              <TabPanel
                value={viewDocumentTab}
                index={0}
                style={{ height: "calc(100% - 70px)", overflow: "auto" }}
              >
                {documentSummaryList.map((item, index) => {
                  return (
                    <CustomAccordian
                      key={item.id}
                      data={item}
                      index={index}
                      setSummaryTobeEdited={setSummaryTobeEdited}
                      deleteSummary={deleteSummary}
                      canEdit={canEdit}
                    />
                  );
                })}
                {documentSummaryList.length < 1 ? (
                  <Typography
                    variant="h6"
                    style={{
                      color: "0e1c4e",
                      opacity: 0.5,
                      fontStyle: "italic",
                      fontWeight: 300,
                      textAlign: "center",
                      paddingTop: 40,
                    }}
                  >
                    No Summary added
                  </Typography>
                ) : null}
              </TabPanel>

              <TabPanel value={viewDocumentTab} index={1}>
                {previewSection()}
              </TabPanel>
            </>
          ) : (
            previewSection()
          )}
        </DialogContent>
        <Divider
          orientation="horizontal"
          variant="fullWidth"
          style={{ width: "100%" }}
        />
        <DialogActions style={{ padding: 0 }}>
          <Button
            variant="outlined"
            className={Styles.primaryBtnAccent}
            style={{
              textTransform: "capitalize",
              margin: "8px 40px",
            }}
            onClick={() => {
              clearAll(setDocumentItem, setOpenDocumetDialog);
              setViewDocumentTab(0);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ViewDocumentDialog;
