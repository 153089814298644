import { makeStyles } from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import GetAppIcon from "@material-ui/icons/GetApp";
import ShareIcon from "@material-ui/icons/Share";
import EditIcon from "@material-ui/icons/Edit";
import clsx from "clsx";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import {
  ARCHIVED,
  COMPLETED,
  HOME_BUYER_USER,
} from "../serviceProviderConstants";
import { useSPeSignWizard } from "souqh-react-redux-hooks/serviceProvider/documents/useSPeSignWizard";
import { cloneDeep } from "lodash-es";

const useStyles = makeStyles((theme) => ({
  editIcon: {
    height: 16,
    width: 16,
    marginRight: 16,
  },
  shareIcon: {
    height: 14,
    width: 16,
    marginRight: 16,
  },
  downloadIcon: {
    height: 18,
    width: 17,
    marginBottom: -3,
  },
  cursorPointer: {
    cursor: "pointer",
  },
}));

export default function DocQuickActions(props) {
  const classes = useStyles();
  const userInfo = useAuthUser();
  const {
    actions: { setOpenEditEsignDocumentDialog, setOpenEditWarningDialog },
    showDialogFlag,
  } = useSPeSignWizard();
  const {
    categoryId,
    row,
    setSelectedData,
    setOpenShareDocumentDialog,
    downloadMultipleDocument,
    downLoadDocument,
    selectedData,
    documentTabName,
    setDocumentDataForEdit,
  } = props;

  return (
    <>
      {userInfo && userInfo.userType !== HOME_BUYER_USER ? (
        <EditIcon
          className={clsx(classes.editIcon, Styles.navyBlueColorForce)}
          style={{
            opacity:
              !row.signNowDocumentId || !row.allowVoid || row.isTemplate
                ? 0.4
                : 1,
            cursor:
              !row.signNowDocumentId || !row.allowVoid || row.isTemplate
                ? "default"
                : "pointer",
          }}
          onClick={() => {
            if (!row.signNowDocumentId || !row.allowVoid || row.isTemplate) {
              return;
            }
            setDocumentDataForEdit(cloneDeep(row));
            if (showDialogFlag) {
              setOpenEditWarningDialog(true);
            } else {
              setOpenEditEsignDocumentDialog(true);
            }
          }}
        />
      ) : null}

      <ShareIcon
        className={clsx(
          classes.shareIcon,
          classes.cursorPointer,
          Styles.navyBlueColorForce
        )}
        style={{
          opacity: userInfo.signNowAccountCreated ? 1 : 0.5,
          cursor: userInfo.signNowAccountCreated ? "pointer" : "default",
        }}
        onClick={() => {
          if (userInfo.signNowAccountCreated) {
            if (!selectedData.length) {
              setSelectedData([row]);
            }
            setOpenShareDocumentDialog(true);
          }
        }}
      />
      <GetAppIcon
        className={clsx(
          classes.downloadIcon,
          classes.cursorPointer,
          Styles.navyBlueColorForce
        )}
        style={{
          opacity: userInfo.signNowAccountCreated ? 1 : 0.5,
          cursor: userInfo.signNowAccountCreated ? "pointer" : "default",
        }}
        onClick={() => {
          if (userInfo.signNowAccountCreated) {
            if (selectedData.length > 1) {
              downloadMultipleDocument();
            } else {
              downLoadDocument(categoryId, row);
            }
          }
        }}
      />
    </>
  );
}
