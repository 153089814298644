import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  IconButton,
  TextField,
  Divider,
  Button,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useOtherPaymentInfo } from "souqh-react-redux-hooks/serviceProvider/storefront/useCustomerPayments";
export default function OtherPaymentMethod({
  newOtherPaymentMethod,
  setNewOtherPaymentMethod,
}) {
  const {
    otherInfo,
    allowOtherAdd,
    actions: {
      updateOtherInfo,
      resetOtherInfo,
      saveOtherInfo,
      addOtherPaymentMethod,
      deletetOtherPaymentMethod,
    },
  } = useOtherPaymentInfo();
  const [update, setUpdate] = useState({});
  useEffect(() => {
    if (update.edit) {
      setNewOtherPaymentMethod(update.edit);
    } else {
      setNewOtherPaymentMethod(false);
    }
  }, [update]);
  return (
    <>
      <OtherPaymentListItem
        otherInfo={otherInfo}
        deletetOtherPaymentMethod={deletetOtherPaymentMethod}
        setUpdate={setUpdate}
      />
      {(!otherInfo.config.length || newOtherPaymentMethod) && (
        <OtherPaymentComponent
          index={update && update.index}
          otherInfo={update && otherInfo.config[update.index]}
          allowOtherAdd={allowOtherAdd}
          updateOtherInfo={updateOtherInfo}
          resetOtherInfo={resetOtherInfo}
          saveOtherInfo={saveOtherInfo}
          addOtherPaymentMethod={addOtherPaymentMethod}
          setNewOtherPaymentMethod={setNewOtherPaymentMethod}
          setUpdate={setUpdate}
        />
      )}
    </>
  );
}

function OtherPaymentListItem({
  otherInfo,
  deletetOtherPaymentMethod,
  setUpdate,
}) {
  return (
    <>
      {otherInfo.config &&
        otherInfo.config.map(
          (otherPayment, index) =>
            otherPayment && (
              <Grid container key={index}>
                <Grid item container xs={12} justifyContent="flex-end">
                  <IconButton
                    color="default"
                    component="span"
                    onClick={() => {
                      setUpdate({ edit: true, index });
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="default"
                    component="span"
                    onClick={() => {
                      deletetOtherPaymentMethod(index);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
                <Grid item container direction="column">
                  <Grid item container>
                    <Typography variant="subtitle1" style={{ marginRight: 10 }}>
                      Payment Method:
                    </Typography>
                    <Typography variant="body1">
                      {otherPayment && otherPayment.methodName}
                    </Typography>
                  </Grid>
                  <Grid item container>
                    <Typography variant="subtitle1" style={{ marginRight: 15 }}>
                      Details:
                    </Typography>
                    <Typography variant="body1">
                      {otherPayment && otherPayment.methodDetails}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider
                  orientation="horizontal"
                  variant="fullWidth"
                  style={{ width: "100%", marginTop: 10, marginBottom: 10 }}
                />
              </Grid>
            )
        )}
    </>
  );
}
function OtherPaymentComponent({
  otherInfo,
  allowOtherAdd,
  addOtherPaymentMethod,
  updateOtherInfo,
  index,
  setNewOtherPaymentMethod,
  setUpdate,
}) {
  const [methodName, setMethodName] = useState("");
  const [methodDetails, setMethodDetails] = useState("");
  useEffect(() => {
    if (otherInfo) {
      setMethodName(otherInfo.methodName);
      setMethodDetails(otherInfo.methodDetails);
    }
  }, [otherInfo]);
  return (
    <>
      <TextField
        fullWidth
        variant="outlined"
        required
        label="Payment Method Name"
        value={methodName}
        onChange={(event) => {
          setMethodName(event.target.value);
        }}
        multiline
        inputProps={{
          maxLength: 256,
        }}
      />
      <TextField
        fullWidth
        variant="outlined"
        required
        label="Payment Method Details"
        value={methodDetails}
        multiline
        onChange={(event) => {
          setMethodDetails(event.target.value);
        }}
        inputProps={{
          maxLength: 500,
        }}
      />
      <Grid item container xs={12} justifyContent="flex-end">
        <Button
          variant="outlined"
          color="primary"
          style={{ textTransform: "capitalize", marginRight: 20 }}
          onClick={() => {
            setMethodName("");
            setMethodDetails("");
          }}
        >
          Reset
        </Button>
        <Button
          disabled={allowOtherAdd}
          variant="contained"
          className={Styles.primaryBtnAccent}
          onClick={() => {
            if (!otherInfo) {
              addOtherPaymentMethod({
                methodName,
                methodDetails,
              });
              setMethodName("");
              setMethodDetails("");
              setNewOtherPaymentMethod(false);
            } else {
              updateOtherInfo({
                index,
                methodName,
                methodDetails,
              });
              setUpdate({ edit: false });
            }
          }}
        >
          Save
        </Button>
      </Grid>
    </>
  );
}
