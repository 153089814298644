import { useSelector } from "react-redux";

import { SET_DISPLAY_PICTURE } from "../../../souqh-redux/reducers/actionConstants";
import { useActions } from "../../useActions";

const actionCreators = {
  setDisplayPicture: (payload) => ({
    type: SET_DISPLAY_PICTURE,
    payload,
  }),
};

export const useDisplayPicture = () => {
  const displayPicture = useSelector(
    (state) => state.storefront.displayPicture
  );
  const actions = useActions(actionCreators);

  return { displayPicture, actions };
};
