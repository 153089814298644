import { Auth } from "@aws-amplify/auth";
import { awsChimeConfigs } from "../../web/src/chimeConfig";

const Chime = require("aws-sdk/clients/chime");

export const useChimeApi = () => {
  const createMemberArn = (userId) =>
    `${awsChimeConfigs.appInstanceArn}/user/${userId}`;
  const appInstanceUserArnHeader = "x-amz-chime-bearer";

  const chimeClient = async () => {
    const creds = await Auth.currentCredentials();
    const chime = new Chime({
      region: "us-east-1",
      credentials: await Auth.essentialCredentials(creds),
    });
    return chime;
  };

  const getMessagingSessionEndpoint = async () => {
    const request = (await chimeClient()).getMessagingSessionEndpoint();
    const response = await request.promise();
    return response;
  };

  const listChannels = async (appInstanceArn, userId) => {
    const params = {
      AppInstanceArn: appInstanceArn,
    };
    const request = (await chimeClient()).listChannels(params);
    request.on("build", () => {
      request.httpRequest.headers[appInstanceUserArnHeader] =
        createMemberArn(userId);
    });
    const response = await request.promise();
    const channels = response.Channels;
    return channels;
  };

  const listChannelMembershipsForAppInstanceUser = async (userId) => {
    const request = (
      await chimeClient()
    ).listChannelMembershipsForAppInstanceUser();
    request.on("build", () => {
      request.httpRequest.headers[appInstanceUserArnHeader] =
        createMemberArn(userId);
    });
    const response = await request.promise();
    const channels = response.ChannelMemberships;
    return channels;
  };

  const listChannelMessages = async (channelArn, userId, nextToken = null) => {
    const params = {
      ChannelArn: channelArn,
      NextToken: nextToken,
    };

    const request = (await chimeClient()).listChannelMessages(params);

    request.on("build", () => {
      request.httpRequest.headers[appInstanceUserArnHeader] =
        createMemberArn(userId);
    });
    const response = await request.promise();
    const messageList = response.ChannelMessages;
    messageList.sort((a, b) => {
      return a.CreatedTimestamp < b.CreatedTimestamp
        ? -1
        : a.CreatedTimestamp > b.CreatedTimestamp
        ? 1
        : 0;
    });

    const messages = [];
    let i = 0;
    for (i = 0; i < messageList.length; i++) {
      const message = messageList[i];
      messages.push(message);
    }
    return { Messages: messages, NextToken: response.NextToken };
  };

  const describeChannel = async (channelArn, userId) => {
    const params = {
      ChannelArn: channelArn,
    };
    const request = (await chimeClient()).describeChannel(params);
    request.on("build", () => {
      request.httpRequest.headers[appInstanceUserArnHeader] =
        createMemberArn(userId);
    });
    const response = await request.promise();
    return response.Channel;
  };

  const createChannel = async (appInstanceArn, name, mode, privacy, userId) => {
    const params = {
      AppInstanceArn: appInstanceArn,
      Name: name,
      Mode: mode,
      Privacy: privacy,
    };

    const request = (await chimeClient()).createChannel(params);
    request.on("build", () => {
      request.httpRequest.headers[appInstanceUserArnHeader] =
        createMemberArn(userId);
    });
    const response = await request.promise();
    return response.ChannelArn;
  };

  const createChannelMembership = async (channelArn, memberArn, userId) => {
    const params = {
      ChannelArn: channelArn,
      MemberArn: memberArn,
      Type: "DEFAULT", // OPTIONS ARE: DEFAULT and HIDDEN
    };

    const request = (await chimeClient()).createChannelMembership(params);
    request.on("build", () => {
      request.httpRequest.headers[appInstanceUserArnHeader] =
        createMemberArn(userId);
    });
    const response = await request.promise();
    return response.Member;
  };

  const listChannelMemberships = async (channelArn, userId) => {
    const params = {
      ChannelArn: channelArn,
    };

    const request = (await chimeClient()).listChannelMemberships(params);
    request.on("build", () => {
      request.httpRequest.headers[appInstanceUserArnHeader] =
        createMemberArn(userId);
    });
    const response = await request.promise();
    return response.ChannelMemberships;
  };

  const sendChannelMessage = async (
    channelArn,
    messageContent,
    member,
    options = null
  ) => {
    const params = {
      ChannelArn: channelArn,
      Content: messageContent,
      Persistence: "PERSISTENT", // Allowed types are PERSISTENT and NON_PERSISTENT
      Type: "STANDARD", // Allowed types are STANDARD and CONTROL
    };
    if (options && options.Metadata) {
      params.Metadata = options.Metadata;
    }

    const request = (await chimeClient()).sendChannelMessage(params);
    request.on("build", () => {
      request.httpRequest.headers[appInstanceUserArnHeader] = createMemberArn(
        member.userId
      );
    });
    const response = await request.promise();
    const sentMessage = {
      response,
      CreatedTimestamp: new Date(),
      Sender: { Arn: createMemberArn(member.userId), Name: member.username },
    };
    return sentMessage;
  };

  const deleteChannel = async (channelArn, userId) => {
    const params = {
      ChannelArn: channelArn,
    };
    const request = (await chimeClient()).deleteChannel(params);
    request.on("build", () => {
      request.httpRequest.headers[appInstanceUserArnHeader] =
        createMemberArn(userId);
    });
    await request.promise();
  };

  return {
    createMemberArn,
    chimeClient,
    getMessagingSessionEndpoint,
    listChannels,
    listChannelMessages,
    describeChannel,
    createChannel,
    createChannelMembership,
    listChannelMemberships,
    sendChannelMessage,
    deleteChannel,
    listChannelMembershipsForAppInstanceUser,
  };
};
