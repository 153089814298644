import { cloneDeep, forEach, find, findIndex, uniqBy } from "lodash";
import {
  ADD_ADDRESS,
  DELETE_ADDRESS,
  SAVE_ADDRESS_FIELD,
  SAVE_CURRENT_AND_NEW_ADDRESS_FIELD,
  RESET_CURRENT_AND_NEW_ADDRESS_FIELD,
  SET_PRIMARY,
  SET_BILLING_ADDRESS_SAME_AS_OA,
  SAVE_BILLING_ADDRESS_FIELD,
  ADD_LOCATION_SERVED,
  DELETE_LOCATION_SERVED_CITY,
  SET_ADDITIONAL_USER_SUPPORT,
  ADD_ADDITIONAL_USER,
  DELETE_ADDITIONAL_USER,
  BULK_ADD_ADDITIONAL_USERS,
  REMOVE_ALL_ADDITIONAL_USERS,
  SET_STORE_FRONT_TYPE,
  SAVE_SUB_BUSINESS_TYPE,
  SET_AREA_SPEC,
  SET_LANGUAGE_SUPPORT,
  SET_YEARS_IN_BUSINESS,
  SET_NUM_OF_EMPLOYEES,
  SET_LICENSED_PRACTITIONER,
  SET_LICENSE_BODY_NAME,
  SET_LICENSE_NUMBER,
  SET_PRE_PROFILE_DATA,
  SET_PREFERRED_SP_SUPPORT,
  REMOVE_ALL_PREFERRED_SP,
  ADD_PREFERRED_SP,
  SAVE_CURRENT_TEAM_MEMBER,
  DELETE_PREFERRED_SP,
  SAVE_PREFERRED_SP,
  SET_PROFILE_STATUS,
  SET_MEMBER_MOBILE_NUMBER,
  CLEAR_LOCATION_SERVED,
} from "./actionConstants";

const addressDefaultProps = {
  title: "",
  streetNumber: "",
  streetName: "",
  province: null,
  city: null,
  county: "Canada",
  postalCode: "",
  isPrimary: false,
};

const defaultPrimary = {
  ...addressDefaultProps,
  isPrimary: true,
  title: "Main Address",
};

const currentAndHomeDefault={
  currentAddress: { ...addressDefaultProps, fieldType: "currentAddress" },
  newAddress: { ...addressDefaultProps, fieldType: "newAddress" },
}

const initialState = {
  maxAdditionalAddresses: 5,
  provinces: [],
  cities: [],
  addresses: [defaultPrimary],
  billingAddress: defaultPrimary,
  billingAddressSameAsOA: true,
  currentAndNewAddress: currentAndHomeDefault,
  locationsServed: [],
  team: {
    additionalUsersSupport: false,
    additionalUsers: [],
    storeFrontType: "One Storefront",
    licensedPractitioner: false,
    licenseName: "",
    licenseNumber: "",
  },
  preferredSPSupport: false,
  preferredSPs: [],
  businessTypeSubCategories: [],
  areaOfSpecializations: [],
  languageSupport: [{ id: 1, name: "English" }],
  yearsInBusiness: "",
  numberOfEmployees: "",
  stripeCustomerId: null,
  spProfileStatus: {
    profileSetupStatus: false,
    isPaymentPlanSelected: false,
    isPaymentCardAdded: false,
  },
  deletedCities: [],
  phoneNumber: "",
  isRandomContactNo: false,
};

const spSetup = (state = initialState, action) => {
  let newState;
  let newAddresses;
  let newCities;
  switch (action.type) {
    case SET_PRE_PROFILE_DATA:
      newState = cloneDeep(state);
      newState = action.payload;
      const bAddress = action.payload.addresses.find((a) => a.billing);
      if (bAddress) {
        newState.billingAddress = bAddress;
      }
      if (!action.payload.addresses.length) {
        newState.addresses = [defaultPrimary];
      }
      return newState;
    case SAVE_ADDRESS_FIELD:
      newState = cloneDeep(state);
      newAddresses = newState.addresses;
      const { index, field, value } = action.payload;
      newAddresses[index][field] = value;
      // on every province value chnage reset city
      if (field === "province") {
        newAddresses[index]["city"] = null;
      }
      return newState;

    case SAVE_CURRENT_AND_NEW_ADDRESS_FIELD:
      newState = cloneDeep(state);
      newAddresses = newState.currentAndNewAddress;
      const { field: fieldName, value: fieldValue, fieldType } = action.payload;
      newAddresses[fieldType][fieldName] = fieldValue;
      // on every province value change reset city
      if (fieldName === "province") {
        newAddresses[fieldType]["city"] = null;
      }
      return newState;

    case RESET_CURRENT_AND_NEW_ADDRESS_FIELD:
      newState = cloneDeep(state);
      newState.currentAndNewAddress={...currentAndHomeDefault};
      return newState;

    case SET_PRIMARY:
      newState = cloneDeep(state);
      newAddresses = newState.addresses;
      forEach(newAddresses, (a) => {
        a.isPrimary = false;
      });
      newAddresses[action.index]["isPrimary"] = true;
      return newState;

    case ADD_ADDRESS:
      newState = cloneDeep(state);
      newAddresses = newState.addresses;
      newAddresses.push({ ...addressDefaultProps });
      return newState;

    case DELETE_ADDRESS:
      newState = cloneDeep(state);
      newAddresses = newState.addresses;
      newAddresses.splice(action.index, 1);
      return newState;

    case SET_BILLING_ADDRESS_SAME_AS_OA:
      newState = cloneDeep(state);
      const { check, title } = action.payload;
      newState.billingAddressSameAsOA = check;
      if (!check) {
        newState.billingAddress = { ...addressDefaultProps };
      } else {
        if (!title) {
          // set primary address as default
          const pa = find(newState.addresses, (a) => a.isPrimary);
          newState.billingAddress = pa;
        } else {
          const ta = find(newState.addresses, (a) => a.title === title);
          newState.billingAddress = ta;
        }
      }
      return newState;

    case SAVE_BILLING_ADDRESS_FIELD:
      newState = cloneDeep(state);
      const newBillingAddress = newState.billingAddress;
      const { field: baField, value: baValue } = action.payload;
      newBillingAddress[baField] = baValue;
      // on every province value chnage reset city
      if (baField === "province") {
        newBillingAddress["city"] = null;
      }
      return newState;
    case ADD_LOCATION_SERVED:
      newState = cloneDeep(state);
      let locationsServed = newState.locationsServed;
      if (locationsServed.length) {
        const location = find(
          locationsServed,
          (location) => location.province.id === action.payload.province.id
        );
        if (location) {
          if (
            action.payload.cities[0].name === "All Cities" ||
            location.cities[0].name === "All Cities"
          ) {
            location.cities = [];
            location.cities.push(...action.payload.cities);
          } else {
            // Avoid duplicate cities
            location.cities.push(...action.payload.cities);
            location.cities = uniqBy(location.cities, "id");
          }
        } else {
          locationsServed.push({ ...action.payload });
        }
      } else {
        locationsServed.push({ ...action.payload });
      }

      return newState;

    case DELETE_LOCATION_SERVED_CITY:
      newState = cloneDeep(state);
      newState.deletedCities.push(action.payload.city.id);
      newCities = newState.locationsServed[action.payload.index].cities;
      let cityIndex = findIndex(newCities, (o) => {
        return o.id == action.payload.city.id;
      });
      newCities.splice(cityIndex, 1);
      if (!newCities.length) {
        newState.locationsServed.splice(action.payload.index, 1);
      }
      return newState;

    case CLEAR_LOCATION_SERVED:
      newState = cloneDeep(state);
      newState.locationsServed = initialState.locationsServed;
      return newState;

    // ==================== Team Section ====================

    case SET_ADDITIONAL_USER_SUPPORT:
      newState = cloneDeep(state);
      newState.team.additionalUsersSupport = action.payload.check;
      return newState;

    case SET_LICENSED_PRACTITIONER:
      newState = cloneDeep(state);
      if (action.payload.check) {
        newState.team.licensedPractitioner = action.payload.check;
      } else {
        // unset values if licensedPractitioner is false
        newState.team.licensedPractitioner = action.payload.check;
        newState.team.licenseName = "";
        newState.team.licenseNumber = "";
      }
      return newState;

    case SET_LICENSE_BODY_NAME:
      newState = cloneDeep(state);
      newState.team.licenseName = action.payload;
      return newState;

    case SET_LICENSE_NUMBER:
      newState = cloneDeep(state);
      newState.team.licenseNumber = action.payload;
      return newState;

    case ADD_ADDITIONAL_USER:
      newState = cloneDeep(state);
      // if action payload has index then set aUser at that index else push at array end
      if (action.payload.index) {
        newState.team.additionalUsers[action.payload.index] = {
          ...action.payload.aUser,
        };
      } else {
        newState.team.additionalUsers.push({ ...action.payload.aUser });
      }
      return newState;

    case DELETE_ADDITIONAL_USER:
      newState = cloneDeep(state);
      newState.team.additionalUsers.splice(action.payload.index, 1);
      return newState;

    case BULK_ADD_ADDITIONAL_USERS:
      newState = cloneDeep(state);
      newState.team.additionalUsers = newState.team.additionalUsers.concat(
        action.payload.aUsers
      );
      return newState;

    case REMOVE_ALL_ADDITIONAL_USERS:
      newState = cloneDeep(state);
      newState.team.additionalUsers = [];
      return newState;

    case SAVE_CURRENT_TEAM_MEMBER:
      newState = cloneDeep(state);
      const additionalUsers = newState.team.additionalUsers;
      const {
        index: teamIndex,
        field: teamField,
        value: teamValue,
      } = action.payload;
      additionalUsers[teamIndex][teamField] = teamValue;

      return newState;

    case SET_STORE_FRONT_TYPE:
      newState = cloneDeep(state);
      newState.team.storeFrontType = action.payload.storeFrontType;
      return newState;

    case SAVE_SUB_BUSINESS_TYPE:
      newState = cloneDeep(state);
      const found = find(action.payload, (c) => c.name === "All of Above");
      if (found && action.payload.length > 1) {
        newState.businessTypeSubCategories = [found];
      } else {
        newState.businessTypeSubCategories = action.payload;
      }
      return newState;

    case SET_AREA_SPEC:
      newState = cloneDeep(state);
      newState.areaOfSpecializations = action.payload;
      return newState;
    case SET_LANGUAGE_SUPPORT:
      newState = cloneDeep(state);
      newState.languageSupport = action.payload;
      return newState;

    case SET_YEARS_IN_BUSINESS:
      newState = cloneDeep(state);
      newState.yearsInBusiness = action.payload;
      return newState;

    case SET_NUM_OF_EMPLOYEES:
      newState = cloneDeep(state);
      newState.numberOfEmployees = action.payload;
      return newState;

    // ==================== Preferred Service Provider Section ====================
    case SET_PREFERRED_SP_SUPPORT:
      newState = cloneDeep(state);
      newState.preferredSPSupport = action.payload.check;
      return newState;

    case ADD_PREFERRED_SP:
      newState = cloneDeep(state);
      newState.preferredSPs.push({ ...action.payload.prefSP });
      return newState;

    case REMOVE_ALL_PREFERRED_SP:
      newState = cloneDeep(state);
      newState.preferredSPs = [];
      return newState;

    case DELETE_PREFERRED_SP:
      newState = cloneDeep(state);
      newState.preferredSPs.splice(action.payload.index, 1);
      return newState;

    case SAVE_PREFERRED_SP:
      newState = cloneDeep(state);
      const preferredSPs = newState.preferredSPs;
      const { index: spIndex, field: spField, value: spValue } = action.payload;
      preferredSPs[spIndex][spField] = spValue;
      return newState;

    case SET_PROFILE_STATUS:
      newState = cloneDeep(state);
      newState.spProfileStatus[action.payload.data.key] =
        action.payload.data.value;
      return newState;

    case SET_MEMBER_MOBILE_NUMBER:
      newState = cloneDeep(state);
      newState.phoneNumber = action.payload;
      return newState;

    default:
      return state;
  }
};

export default spSetup;
