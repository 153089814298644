import { cloneDeep, findIndex } from "lodash";
import { ACTION_REQUIRED } from "../../web/src/components/ServiceProvider/serviceProviderConstants";
import {
  SET_CATEGORIES,
  SET_SELECTED_CATEGORY,
  SET_CATEGORY,
  UPDATE_CATEGORIES,
  RENAME_CATEGORY,
  SET_DOCUMENT_TAB,
  SET_DOCUMENT_TAB_NAME,
} from "./actionConstants";

const initialState = {
  categories: [],
  documentTab: 0,
  documentTabName: "",
  selectedCategory: "",
  categoryName: "",
};

const documents = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_CATEGORIES:
      newState = cloneDeep(state);
      newState.categories = action.payload.categories;
      return newState;
    case UPDATE_CATEGORIES:
      newState = cloneDeep(state);
      newState.categories.push(action.payload.category);
      return newState;
    case SET_SELECTED_CATEGORY:
      newState = cloneDeep(state);
      newState.selectedCategory = action.payload;
      return newState;
    case SET_CATEGORY:
      newState = cloneDeep(state);
      newState.categoryName = action.payload;
      return newState;
    case RENAME_CATEGORY:
      newState = cloneDeep(state);
      const myIndex = findIndex(newState.categories, ["id", action.payload.id]);
      newState.categories[myIndex].name = action.payload.name;
      return newState;
    case SET_DOCUMENT_TAB:
      newState = cloneDeep(state);
      newState.documentTab = action.payload;
      return newState;
    case SET_DOCUMENT_TAB_NAME:
      newState = cloneDeep(state);
      newState.documentTabName = action.payload;
      return newState;
    default:
      return state;
  }
};

export default documents;
