import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
export const useLocationServedReview = () => {
  const locationsServed = useSelector((state) => state.spSetup.locationsServed);
  const [lastIndex, setLastIndex] = useState(0);
  const [displayArray, setDisplayArray] = useState([]);
  useEffect(() => {
    const tempLocation = cloneDeep(locationsServed);
    if (tempLocation.length) {
      setDisplayArray(tempLocation.splice(0, 2));
    }
  }, [locationsServed]);
  const handleNext = () => {
    const index = cloneDeep(lastIndex);
    const tempLocation = cloneDeep(locationsServed);

    if (lastIndex < locationsServed.length - 1) {
      setLastIndex((prevActiveStep) => prevActiveStep + 2);
      setDisplayArray(tempLocation.splice(index + 2, 2));
    }
  };
  const handleBack = () => {
    const index = cloneDeep(lastIndex);
    const tempLocation = cloneDeep(locationsServed);
    if (lastIndex < locationsServed.length) {
      setLastIndex((prevActiveStep) => prevActiveStep - 2);
      setDisplayArray(tempLocation.splice(index - 2, 2));
    }
  };
  return {
    locationsServed,
    lastIndex,
    handleNext,
    handleBack,
    displayArray,
  };
};
