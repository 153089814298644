import React from "react";
import {
  Typography,
  Grid,
  Chip,
  makeStyles,
  ButtonBase,
  Divider,
  Avatar,
} from "@material-ui/core/";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    color: "#0E1C4E",
    fontSize: "1rem",
    fontWeight: "bold",
  },
  tableContent: {
    color: "#0E1C4E",
    fontSize: "1rem",
  },
  button: {
    color: "#0E1C4E",
    textTransform: "capitalize",
  },
}));

export default function ReviewAndSendDocuments({
  documentList,
  deleteDocument,
  handleCustomerSelect,
  selectedActiveCustomers,
  selectedContacts,
  removeFromOtherContacts,
}) {
  const classes = useStyles();

  const selectedDocuments = documentList.map((item, index) => {
    return (
      <Grid key={index}>
        <Divider />
        <Grid container item xs={12} style={{ padding: "10px 0px" }}>
          <Grid item xs={11}>
            <Typography
              variant="body1"
              style={{ paddingLeft: "8px" }}
              className={clsx(classes.tableContent, Styles.sqEllipsis)}
            >
              {item.file.name}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <ButtonBase>
              <CancelOutlinedIcon
                style={{ marginTop: 10 }}
                style={{ fill: "#0e1c4e" }}
                onClick={() => {
                  deleteDocument(index);
                }}
              />
            </ButtonBase>
          </Grid>
        </Grid>
      </Grid>
    );
  });

  const showSelectedCustomers = selectedActiveCustomers.map((item, index) => {
    return (
      <Chip
        key={index}
        variant="outlined"
        label={item.firstName + " " + item.lastName}
        avatar={
          item.profilePic ? (
            <Avatar src={item.profilePic} />
          ) : (
            <Avatar>
              <AccountCircleOutlinedIcon
                style={{ fontSize: 32, fill: "#0e1c4e" }}
              />
            </Avatar>
          )
        }
        className={Styles.navyBlueColorForce}
        onDelete={() => {
          handleCustomerSelect(false, item);
        }}
        style={{ marginRight: 8, fontWeight: 600 }}
      />
    );
  });

  const showOtherContacts = selectedContacts.map((item, index) => {
    return (
      <Chip
        key={index}
        icon={<PermIdentityIcon />}
        variant="outlined"
        label={item.email}
        className={Styles.navyBlueColorForce}
        onDelete={() => {
          removeFromOtherContacts(item);
        }}
        style={{ marginRight: 8, marginBottom: 4, fontWeight: 600 }}
      />
    );
  });

  return (
    <Grid item xs={12} style={{ padding: "0px 16px", height: "100%" }}>
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          style={{ margin: "12px 0px" }}
          className={clsx(classes.tableContent, Styles.navyBlueColor)}
        >
          Selected Documents
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        style={{ maxHeight: "calc(50% - 55px)", overflow: "auto" }}
      >
        {selectedDocuments}
        <Divider />
      </Grid>

      {!selectedDocuments.length ? (
        <Grid item xs={12} style={{ margin: "12px 0px" }}>
          <Typography
            variant="body1"
            style={{
              margin: "32px 0px",
              textAlign: "center",
              opacity: 0.5,
              fontStyle: "italic",
              fontWeight: 300,
            }}
            className={Styles.navyBlueColor}
          >
            No document uploaded
          </Typography>
        </Grid>
      ) : null}

      <Grid item xs={12} style={{ marginBottom: "20px" }}>
        <Typography
          variant="subtitle1"
          style={{ margin: "12px 0px" }}
          className={clsx(classes.tableContent, Styles.navyBlueColor)}
        >
          Selected Contacts
        </Typography>
        <Divider />
      </Grid>
      <Grid
        item
        xs={12}
        style={{ maxHeight: "calc(50% - 55px)", overflow: "auto" }}
      >
        <Grid item xs={12} style={{ margin: "12px 0px" }}>
          {showSelectedCustomers}
        </Grid>
        <Grid item xs={12} style={{ margin: "12px 0px" }}>
          {showOtherContacts}
        </Grid>

        {!showSelectedCustomers.length && !showOtherContacts.length ? (
          <Grid item xs={12} style={{ margin: "12px 0px" }}>
            <Typography
              variant="body1"
              style={{
                margin: "32px 0px",
                textAlign: "center",
                opacity: 0.5,
                fontStyle: "italic",
                fontWeight: 300,
              }}
              className={Styles.navyBlueColor}
            >
              No Contacts Selected
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
}
