import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  makeStyles,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  ButtonBase,
  Paper,
  TableRow,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import CancelIcon from "@material-ui/icons/Cancel";
import { useSendInvoiceDialog } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useSendInvoiceDialog";
import { useDispatch } from "react-redux";
import { color_error } from "souqh-react-redux-hooks/toasterColors";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 620,
  },
}));

function SendInvoiceDialog({
  openSendInvoiceDialog,
  setOpenSendInvoiceDialog,
  primaryApplicant,
  serviceRequestId,
  connectedAccountDetails,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    comments,
    setAmount,
    setComments,
    sendInvoiceDcouments,
    sendInvoice,
    amount,
    actions: { addFiles, deleteFile },
  } = useSendInvoiceDialog(primaryApplicant, serviceRequestId);

  return (
    <Dialog
      fullWidth
      disableBackdropClick
      open={openSendInvoiceDialog}
      onClose={() => {
        setOpenSendInvoiceDialog(false);
      }}
      classes={{
        paper:
          connectedAccountDetails &&
          connectedAccountDetails.chargeEnabled &&
          connectedAccountDetails.detailsSubmitted &&
          connectedAccountDetails.enableEcommerce &&
          classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Send Invoice</Typography>
      </DialogTitle>
      <Divider />
      {connectedAccountDetails &&
      connectedAccountDetails.chargeEnabled &&
      connectedAccountDetails.detailsSubmitted &&
      connectedAccountDetails.enableEcommerce ? (
        <React.Fragment>
          <DialogContent>
            <Grid item xs={12} style={{ paddingBottom: 16, paddingTop: 16 }}>
              <TextField
                multiline
                placeholder="Client name"
                variant="outlined"
                fullWidth
                inputProps={{
                  className: Styles.navyBlueColor,
                }}
                value={primaryApplicant.fullName}
                disabled
              />
            </Grid>
            <Grid item xs={12} style={{ paddingBottom: 16 }}>
              <TextField
                placeholder="Amount"
                variant="outlined"
                fullWidth
                inputProps={{
                  className: Styles.navyBlueColor,
                }}
                type="number"
                onChange={(event) => {
                  setAmount(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ paddingBottom: 10 }}>
              <TextField
                multiline
                placeholder="Add comments"
                required
                rows={5}
                rowsMax={9}
                variant="outlined"
                fullWidth
                inputProps={{
                  className: Styles.navyBlueColor,
                  maxLength: 500,
                }}
                onChange={(event) => {
                  setComments(event.target.value.trim());
                }}
              />
              <Grid container item justifyContent="flex-end">
                <Typography variant="body2" style={{ color: "#17174C99" }}>
                  {comments.length}/500
                </Typography>
              </Grid>
            </Grid>
            {sendInvoiceDcouments.length ? (
              <Grid item>
                <TableContainer component={Paper} style={{ marginBottom: 20 }}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>File Name</TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sendInvoiceDcouments.map((document, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {document.name}
                          </TableCell>
                          <TableCell align="right">
                            <ButtonBase>
                              <CancelIcon
                                onClick={() => {
                                  deleteFile(index);
                                }}
                              />
                            </ButtonBase>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            ) : null}
            <Grid
              container
              justifyContent="center"
              style={{ marginBottom: 16 }}
            >
              <input
                accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .png, .jpeg, .bmp, .gif, .CSV"
                style={{ display: "none" }}
                id="contained-button-file1"
                multiple
                type="file"
                onChange={(event) => {
                  addFiles(event.target.files);
                }}
              />
              <label
                htmlFor="contained-button-file1"
                style={{ marginBottom: 16 }}
              >
                <Button
                  variant="outlined"
                  color="default"
                  component="span"
                  startIcon={
                    <img src="/images/Icon material-file-upload.png" />
                  }
                  style={{ textTransform: "capitalize" }}
                  className={Styles.navyBlueColorForce}
                >
                  Upload Invoice
                </Button>
              </label>
              <Typography
                variant="caption"
                align="center"
                className={Styles.navyBlueColor}
              >
                You will be able to upload .doc, .docx, .xls, .xlsx, .ppt,
                .pptx, .pdf, .jpeg, .bmp, .png, .jpg files with maximum
                supported size of 20 MB per file
              </Typography>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions style={{ padding: "16px 24px" }}>
            <Button
              variant="outlined"
              style={{
                padding: "6px 16px",
                marginRight: 8,
                textTransform: "capitalize",
              }}
              className={Styles.navyBlueColorForce}
              onClick={() => {
                setOpenSendInvoiceDialog(false);
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={!amount}
              variant="contained"
              className={Styles.primaryBtnAccent}
              style={{ textTransform: "capitalize", padding: "6px 32px" }}
              onClick={() => {
                sendInvoice();
                setOpenSendInvoiceDialog(false);
              }}
            >
              Send
            </Button>
          </DialogActions>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography style={{ padding: 40 }}>
            Looks like you haven't configured your stripe connected account.{" "}
            <Link
              style={{ marginRight: 5 }}
              className={Styles.navyBlueColor}
              to={{
                pathname: "/serviceprovider/settings/ecommerce",
              }}
            >
              Click here
            </Link>
            to configure your stripe connected account.
          </Typography>
          <DialogActions style={{ padding: "16px 24px" }}>
            <Button
              variant="outlined"
              style={{
                padding: "6px 16px",
                marginRight: 8,
                textTransform: "capitalize",
              }}
              className={Styles.navyBlueColorForce}
              onClick={() => {
                setOpenSendInvoiceDialog(false);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
    </Dialog>
  );
}

export default SendInvoiceDialog;
