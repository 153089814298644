const metaDataReducer = (
  state = { languages: [], verifyPromoCode: null, showInvoice: false },
  action
) => {
  const newState = { ...state };
  switch (action.type) {
    case "SET_CONTRIES":
      newState.countries = action.countries;
      return newState;
    case "SET_PROVINCES":
      newState.provinces = action.provinces;
      return newState;
    case "SET_BUSINESS_TYPE":
      newState.businessTypes = action.businessTypes;
      return newState;
    case "SET_CITIES":
      newState.cities = action.cities;
      return newState;
    case "SET_AREA_OF_SPEC":
      newState.areaOfSpec = action.areaOfSpec;
      return newState;
    case "SET_LANGUAGES":
      newState.languages = action.languages;
      return newState;
    case "SET_SUBSCRIPTION_PLANS":
      newState.plans = action.plans;
      return newState;
    case "SET_VERFIY_PROMOCODE":
      newState.verifyPromoCode = action.verifyPromoCode;
      return newState;
    case "SHOW_INVOICE":
      newState.showInvoice = action.payload;
      return newState;
    default:
      return state;
  }
};

export default metaDataReducer;
