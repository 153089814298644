import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useServices } from "souqh-react-redux-hooks/serviceProvider/storefront/useServices";
import ServiceListItem from "./ServiceListItem";
import Styles from "../../../Styles.module.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    boxShadow: "3px 3px 10px #00000000",
  },
}));

export const PaperLikeGrid = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <Grid {...rest} container className={classes.paper}>
      {children}
    </Grid>
  );
};

function ServiceList({
  setexistingUpdate,
  existingUpdate,
  setShowNewForm,
  sourcePage,
}) {
  const classes = useStyles();
  const {
    onServiceDragEnd,
    reorder,
    services,
    actions: { reOrderService },
  } = useServices();

  if (services && services.length) {
    services.map((item) => {
      if (!item.timeStampId) {
        item.timeStampId = Date.now() + Math.random() + "";
      }
    });
  }

  const draggableList = (
    <DragDropContext onDragEnd={onServiceDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <Grid
            xs={12}
            item
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {services && services.length
              ? services.map((s, index) => {
                  return (
                    <ServiceListItem
                      key={s.timeStampId}
                      index={index}
                      setexistingUpdate={setexistingUpdate}
                      setShowNewForm={setShowNewForm}
                      sourcePage={sourcePage}
                      services={services}
                    ></ServiceListItem>
                  );
                })
              : null}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
  return (
    <Grid container>
      <PaperLikeGrid style={{ padding: "8px 24px", marginBottom: 5 }}>
        <Typography variant="subtitle1" className={Styles.navyBlueColor}>
          Services
        </Typography>
      </PaperLikeGrid>
      {services && services.length < 1 && !existingUpdate ? (
        <Typography
          variant="body1"
          style={{ margin: "20px 0px", width: "100%", textAlign: "center" }}
        >
          No services added
        </Typography>
      ) : null}

      {draggableList}
    </Grid>
  );
}

export default ServiceList;
