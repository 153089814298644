import {
  Grid,
  Typography,
  Paper,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stepper,
  Step,
  StepButton,
  makeStyles,
  ButtonBase,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Styles from "../../../Styles.module.css";
import ChangeStatusConfirmDialog from "./ChangeStatusConfirmDialog";
import QuickActions from "./QuickActions";
import { useHomeBuyingJourney } from "souqh-react-redux-hooks/common/useHomeBuyingJourney";
import { useParams } from "react-router-dom";
import {
  covertTimeStampToDateTimeSeconds,
  isMobileDevice,
  isTabletDevice,
} from "../../../utils/AppUtils";
import { useClientsHubTab } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useClientsHubTab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IndividualSPTimeline from "../../HomeBuyer/HomeBuyingJourney/IndividualSPTimeline";
import { useHomeOwnershipTimeline } from "souqh-react-redux-hooks/common/useHomeOwnershipTimeline";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { Pagination } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiStepConnector-horizontal": {
      top: 17,
      left: "calc(-50% + 17px)",
      right: "calc(50% + 17px)",
      position: "absolute",
    },
    "& .MuiStepConnector-line": {
      borderTopWidth: 5,
      borderColor: "#0E1C4E1F",
    },
  },
}));

export default function ClientsHubTab({
  clientFirstName,
  primaryApplicant,
  serviceRequestId,
}) {
  const classes = useStyles();
  let { userId } = useParams();
  const userInfo = useAuthUser();

  const [activeStep, setActiveStep] = useState(0);
  const [hbJourneyView, setHbJourneyView] = useState(true);

  const stages = [
    {
      name: "Find Your Home",
      image: "/images/Find-your-home.svg",
    },
    {
      name: "Fund Your Home",
      image: "/images/Fund-your-home.svg",
    },
    {
      name: "Close Your Home",
      image: "/images/Close-Your-Home.svg",
    },
    {
      name: "Move Into Your Home",
      image: "/images/Move-Into-Your-Home.svg",
    },
  ];

  const [openChangeStatusConfirmDialog, setOpenChangeStatusConfirmDialog] =
    useState(false);

  const { getJourneyStatus, journeyStatus, updateJourneyStatus } =
    useHomeBuyingJourney(userId);

  const { getClientHistory, clientHistory, getDescription, handlePageChange } =
    useClientsHubTab(userId, serviceRequestId);

  const { getIndividualSPsTimelineData, individualSPTimelineData } =
    useHomeOwnershipTimeline();

  useEffect(() => {
    getJourneyStatus();
    getClientHistory();
    getIndividualSPsTimelineData(userInfo.serviceProviderId, userId);
  }, []);

  // for recent records on top
  let orderedClientHistory =
    clientHistory &&
    clientHistory.response &&
    clientHistory.response.length &&
    clientHistory.response.sort(function (a, b) {
      return new Date(b.historyDateTime) - new Date(a.historyDateTime);
    });

  const formattedJourneyStatus = journeyStatus.split("_").join(" ");
  return (
    <Paper elevation={0} style={{ padding: 12 }}>
      <ChangeStatusConfirmDialog
        openChangeStatusConfirmDialog={openChangeStatusConfirmDialog}
        setOpenChangeStatusConfirmDialog={setOpenChangeStatusConfirmDialog}
        updateJourneyStatus={updateJourneyStatus}
        activeStep={activeStep}
      />
      <Grid container>
        {!isMobileDevice() && (
          <React.Fragment>
            <Grid item xs={12} style={{ display: "flex" }}>
              <ButtonBase
                disableRipple
                onClick={() => setHbJourneyView(!hbJourneyView)}
              >
                <Typography variant="h6" className={Styles.navyBlueColor}>
                  {hbJourneyView ? "Client Journey" : "Home Ownership Timeline"}
                </Typography>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "Center",
                    // alignItems: "Center",
                    marginLeft: "5px",
                  }}
                >
                  <KeyboardArrowUpIcon fontSize="small" />
                  <KeyboardArrowDownIcon fontSize="small" />
                </span>
              </ButtonBase>
            </Grid>

            {hbJourneyView && (
              <Grid container item xs={12} justifyContent="space-between">
                {stages.map((stage, index) => (
                  <Grid
                    key={index}
                    container
                    item
                    xs={3}
                    xl={2}
                    justifyContent="center"
                    style={{
                      borderRadius: 8,
                      backgroundColor:
                        stage.name.toUpperCase() === formattedJourneyStatus &&
                        "#FA7E61",
                      paddingTop:
                        stage.name.toUpperCase() === formattedJourneyStatus &&
                        15,
                      height: 203,
                    }}
                  >
                    <Grid
                      item
                      xs={10}
                      container
                      style={{
                        border: "1px solid ##0000001F",
                        borderRadius: 4,
                        backgroundColor: "#FFFFFF",
                      }}
                      justifyContent="center"
                      alignContent="center"
                    >
                      <img
                        src={stage.image}
                        style={{ height: 122, width: 156 }}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        variant="body1"
                        style={{
                          color:
                            stage.name.toUpperCase() === formattedJourneyStatus
                              ? "#FFFFFF"
                              : "#0E1C4E",
                          fontWeight: 500,
                        }}
                      >
                        {stage.name}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}

            {hbJourneyView && (
              <Grid item xs={12} style={{ marginTop: 10 }}>
                <Stepper
                  activeStep={activeStep}
                  alternativeLabel
                  className={classes.root}
                >
                  {stages.map((stage, index) => (
                    <Step
                      key={stage.name}
                      onClick={() => {
                        setActiveStep(index);
                        setOpenChangeStatusConfirmDialog(true);
                      }}
                    >
                      <StepButton
                        icon={
                          <div
                            style={{
                              borderRadius: "50%",
                              backgroundColor:
                                stage.name.toUpperCase() ===
                                formattedJourneyStatus
                                  ? "#FA7E61"
                                  : "#FFFFFF",
                              border:
                                stage.name.toUpperCase() !==
                                formattedJourneyStatus
                                  ? "2px solid #0E1C4E14"
                                  : "none",
                              height: 36,
                              width: 36,
                            }}
                          ></div>
                        }
                      >
                        {stage.name.toUpperCase() ===
                          formattedJourneyStatus && (
                          <Typography
                            className={Styles.navyBlueColorForce}
                            style={{ fontWeight: 600 }}
                          >
                            {clientFirstName} is here
                          </Typography>
                        )}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
            )}

            {!hbJourneyView && (
              <IndividualSPTimeline
                individualSPTimelineData={individualSPTimelineData}
                isClientsHubTab={true}
              />
            )}

            <Divider
              orientation="horizontal"
              variant="fullWidth"
              style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
            />
          </React.Fragment>
        )}
        <QuickActions primaryApplicant={primaryApplicant} userId={userId} />

        <Divider
          orientation="horizontal"
          variant="fullWidth"
          style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
        />

        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <Typography variant="h6" className={Styles.navyBlueColor}>
            Interaction History
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            marginBottom: isMobileDevice() || isTabletDevice() ? 80 : 20,
          }}
        >
          <TableContainer
            style={{ border: "1px solid #0000001F", borderRadius: 4 }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    className={Styles.navyBlueColorForce}
                    style={{ fontWeight: 600 }}
                  >
                    Date/Time
                  </TableCell>
                  <TableCell
                    className={Styles.navyBlueColorForce}
                    style={{ fontWeight: 600, padding: "16px 8px" }}
                  >
                    Event Description
                  </TableCell>
                  <TableCell
                    className={Styles.navyBlueColorForce}
                    style={{ fontWeight: 600 }}
                  >
                    Event Owner
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderedClientHistory && orderedClientHistory.length
                  ? orderedClientHistory.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell
                          style={{ borderBottom: "none", width: 158 }}
                          className={Styles.navyBlueColorForce}
                        >
                          {covertTimeStampToDateTimeSeconds(
                            row.historyDateTime
                          )}
                        </TableCell>
                        <TableCell
                          style={{ borderBottom: "none", padding: "16px 8px" }}
                          className={Styles.navyBlueColorForce}
                        >
                          <Typography style={{ fontSize: 12 }}>
                            {getDescription(
                              row.description,
                              row.dueDate,
                              row.eventFromDateTime
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell
                          style={{ borderBottom: "none", width: 150 }}
                          className={Styles.navyBlueColorForce}
                        >
                          {row.eventOwner}
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          style={{ margin: "16px 0" }}
        >
          <Pagination
            count={clientHistory && clientHistory.totalPages}
            shape="rounded"
            variant="outlined"
            onChange={handlePageChange}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
