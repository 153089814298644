import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
  Grid,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import { UPCOMING_EVENTS_HEADERS } from "./pendingActionConstants";
import {
  getComparator,
  isMobileDevice,
  isProPlan,
  isTabletDevice,
  stableSort,
} from "../../../utils/AppUtils";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import clsx from "clsx";
import AttendeesCell from "./AttendeesCell";
import ConfirmCancelMeetingDialog from "../SQCalender/ConfirmCancelMeetingDialog";
import CreateMeetingDialog from "../SQCalender/CreateMeetingDialog";
import { MEETING_READ_MODE } from "../../ServiceProvider/serviceProviderConstants";
import ConfirmCancelEventDialog from "./ConfirmCancelEventDialog";

function EventsTableHeader({
  eventsOrder,
  eventsOrderBy,
  handleEventsRequestSort,
}) {
  const createSortHandler = (property) => (event) => {
    handleEventsRequestSort(event, property);
  };

  return (
    <>
      {UPCOMING_EVENTS_HEADERS.map((headCell) => (
        <TableCell
          className={Styles.navyBlueColorForce}
          key={headCell.id}
          align="left"
          sortDirection={eventsOrderBy === headCell.id ? eventsOrder : false}
          style={
            isMobileDevice() || isTabletDevice()
              ? { minWidth: headCell.width }
              : { width: headCell.width }
          }
        >
          <TableSortLabel
            active={eventsOrderBy === headCell.id}
            direction={eventsOrderBy === headCell.id ? eventsOrder : "asc"}
            onClick={createSortHandler(headCell.id)}
            disabled={headCell.id === "id" || headCell.id === "attendees"}
          >
            <Typography className={Styles.navyBlueColor} variant="subtitle1">
              {headCell.label}
            </Typography>
          </TableSortLabel>
        </TableCell>
      ))}
    </>
  );
}

export default function UpcomingEvents({
  eventsOrder,
  eventsOrderBy,
  handleEventsRequestSort,
  upcomingEventsList,
  getAttendeesTooltip,
  userInfo,
  cancelEvent,
  openConfirmCanelMeetingDialog,
  setOpenConfirmCanelMeetingDialog,
  handleEventDialogClose,
  eventSelected,
  setEventSelected,
  getFileretedUpcomingEvents,
  eventAndActionsearchText,
  setOpenCreateMeetingDialog,
  openCreateMeetingDialog,
  meetingMode,
  selectedCalendarEvent,
  cancelMeeting,
  title,
  setTitle,
  meetingDate,
  setMeetingDate,
  setMeetingMode,
  meetingLink,
  meetingPhoneNumber,
  meetingLocation,
  setMeetingLocation,
  meetingAttachments,
  description,
  setDescription,
  uploadRef,
  textAreaRef,
  contactInputRef,
  handleDialogClose,
  processAttachment,
  removeAttachment,
  copyToClipboard,
  meetingStartTime,
  setMeetingStartTime,
  meetingEndTime,
  setMeetingEndTime,
  addCustomItem,
  selectedContacts,
  customContact,
  setCustomContact,
  allContacts,
  removeSelectedContact,
  addItemOnSelect,
  getAllContacts,
  invalidTimes,
  setInvalidTimes,
  setMeetingData,
  removeMeetingLink,
  addMeetingLink,
  setAddMeetingLink,
  setupEmptyData,
  saveMeeting,
  validateTimeSlot,
  isFullDayEvent,
  setIsFullDayEvent,
  getCalendarEvents,
  getCalendarEventDetails,
  eventUserId,
  hasFilledMandatoryData,
  updateMeetingStatus,
  downlodAttachment,
  filteredContacts,
  downloadCalendarAttachment,
}) {
  const [openConfirmCancelEventDialog, setOpenConfirmCancelEventDialog] =
    useState(false);

  if (!upcomingEventsList) {
    return null;
  }
  return (
    <React.Fragment>
      <ConfirmCancelEventDialog
        openConfirmCancelEventDialog={openConfirmCancelEventDialog}
        setOpenConfirmCancelEventDialog={setOpenConfirmCancelEventDialog}
        cancelEvent={cancelEvent}
      />
      <CreateMeetingDialog
        openCreateMeetingDialog={openCreateMeetingDialog}
        setOpenCreateMeetingDialog={setOpenCreateMeetingDialog}
        meetingMode={meetingMode}
        setMeetingMode={setMeetingMode}
        openConfirmCanelMeetingDialog={openConfirmCanelMeetingDialog}
        setOpenConfirmCanelMeetingDialog={setOpenConfirmCanelMeetingDialog}
        cancelMeeting={cancelMeeting}
        selectedCalendarEvent={selectedCalendarEvent}
        title={title}
        setTitle={setTitle}
        meetingDate={meetingDate}
        setMeetingDate={setMeetingDate}
        meetingLink={meetingLink}
        meetingPhoneNumber={meetingPhoneNumber}
        meetingLocation={meetingLocation}
        setMeetingLocation={setMeetingLocation}
        meetingAttachments={meetingAttachments}
        description={description}
        setDescription={setDescription}
        uploadRef={uploadRef}
        textAreaRef={textAreaRef}
        contactInputRef={contactInputRef}
        meetingStartTime={meetingStartTime}
        setMeetingStartTime={setMeetingStartTime}
        meetingEndTime={meetingEndTime}
        setMeetingEndTime={setMeetingEndTime}
        handleDialogClose={handleDialogClose}
        processAttachment={processAttachment}
        removeAttachment={removeAttachment}
        copyToClipboard={copyToClipboard}
        addCustomItem={addCustomItem}
        selectedContacts={selectedContacts}
        customContact={customContact}
        setCustomContact={setCustomContact}
        allContacts={allContacts}
        removeSelectedContact={removeSelectedContact}
        addItemOnSelect={addItemOnSelect}
        invalidTimes={invalidTimes}
        setInvalidTimes={setInvalidTimes}
        setMeetingData={setMeetingData}
        removeMeetingLink={removeMeetingLink}
        addMeetingLink={addMeetingLink}
        setAddMeetingLink={setAddMeetingLink}
        setupEmptyData={setupEmptyData}
        saveMeeting={saveMeeting}
        validateTimeSlot={validateTimeSlot}
        isFullDayEvent={isFullDayEvent}
        setIsFullDayEvent={setIsFullDayEvent}
        eventUserId={eventUserId}
        userInfo={userInfo}
        hasFilledMandatoryData={hasFilledMandatoryData}
        updateMeetingStatus={updateMeetingStatus}
        downlodAttachment={downlodAttachment}
        filteredContacts={filteredContacts}
        downloadCalendarAttachment={downloadCalendarAttachment}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <EventsTableHeader
                eventsOrder={eventsOrder}
                eventsOrderBy={eventsOrderBy}
                handleEventsRequestSort={handleEventsRequestSort}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {upcomingEventsList && upcomingEventsList.length
              ? stableSort(
                  getFileretedUpcomingEvents(
                    upcomingEventsList,
                    eventAndActionsearchText
                  ),
                  getComparator(eventsOrder, eventsOrderBy)
                ).map((row, index) => {
                  let isAttendee = row.attendees.find(
                    (o) => o.email === userInfo.email
                  );
                  return (
                    <TableRow
                      key={index}
                      style={{
                        borderBottom: "1px solid rgba(14, 28, 78, 0.12)",
                      }}
                    >
                      <TableCell
                        align="left"
                        component="th"
                        scope="row"
                        style={{ borderBottom: "none" }}
                      >
                        <Typography
                          variant="body1"
                          className={Styles.navyBlueColor}
                        >
                          {index + 1}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" style={{ borderBottom: "none" }}>
                        <Typography
                          variant="body1"
                          className={Styles.navyBlueColor}
                        >
                          {row.displayFromDate}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" style={{ borderBottom: "none" }}>
                        <Grid item xs={12}>
                          <AttendeesCell
                            row={row}
                            getAttendeesTooltip={getAttendeesTooltip}
                          />
                        </Grid>
                      </TableCell>
                      <TableCell align="left" style={{ borderBottom: "none" }}>
                        <Typography
                          variant="body1"
                          title={row.title}
                          style={{
                            textDecoration: "underline",
                            cursor: !isProPlan(userInfo) && "pointer",
                          }}
                          className={clsx(
                            Styles.sqEllipsisOneLine,
                            Styles.primaryTxtAccentForce
                          )}
                          onClick={() => {
                            if (!isProPlan(userInfo)) {
                              // getCalendarEventDetails(row.id, (fullData) => {
                              //   setMeetingData(fullData);
                              //   setMeetingMode(MEETING_READ_MODE);
                              //   setOpenCreateMeetingDialog(true);
                              // });
                              setMeetingData(row);
                              setMeetingMode(MEETING_READ_MODE);
                              setOpenCreateMeetingDialog(true);
                            }
                          }}
                        >
                          {row.title}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ borderBottom: "none", paddingRight: 0 }}
                      >
                        {!isAttendee ? (
                          <CancelOutlinedIcon
                            className={Styles.navyBlueColor}
                            style={{ fontSize: 24, cursor: "pointer" }}
                            onClick={() => {
                              setEventSelected(row);
                              setOpenConfirmCancelEventDialog(true);
                            }}
                          />
                        ) : null}
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}
