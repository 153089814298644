import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ButtonBase,
  Grid,
  AppBar,
  Avatar,
  useTheme,
  useMediaQuery,
  Link as MaterialLink,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Styles from "../../../Styles.module.css";
import ClientStatus from "../ClientManagement/ClientStatus";
import MessageIcon from "@material-ui/icons/Message";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { useHistory } from "react-router-dom";
import { useNavigateToMessage } from "souqh-react-redux-hooks/common/useNavigateToMessage";
import { useLeadsClientInfoCard } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useLeadsClientInfoCard";
import * as moment from "moment";
import DocumentPreviewDialog from "../../HomeBuyer/Explore/StorefrontPage/DocumentPreviewDialog";
import Attachments from "../../HomeBuyer/SPManagement/Attachments";
import MoreOptionsOnClientInfo from "../ClientManagement/MoreOptionsOnClientInfo";
import clsx from "clsx";
import {
  getMetadataInfo,
  getUploadedDocuments,
  isMarketplacePlan,
  isMobileDevice,
  isOnboardingInComplete,
  isProPlan,
  isTabletDevice,
} from "../../../utils/AppUtils";
import MoreTrafficDialog from "./MoreTrafficDialog";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import UpgradePlanIconSection from "../../common/UpgradePlanIconSection";

export default function LeadsWidget({
  storeFrontRouteName,
  storeFrontId,
  openPlanUpgradeMsgDialog,
  setOpenPlanUpgradeMsgDialog,
  storeFrontDetails,
}) {
  const [openDocumetDialog, setOpenDocumetDialog] = useState(false);
  const [documentItem, setDocumentItem] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hbUserId, setHBUserId] = useState(null);
  const [serviceRequestId, setServiceRequestId] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [openMoreTrafficDialog, setopenMoreTrafficDialog] = useState(false);
  const [docCategoryId, setDocCategoryId] = useState(null);
  const [docNameForDownload, setDocNameForDownload] = useState(null);
  const [docUserId, setDocUserId] = useState(null);

  const theme = useTheme();
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  let history = useHistory();
  const { navigateMessage } = useNavigateToMessage();
  const userInfo = useAuthUser();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const { getLeadsClientData, leads, actions } = useLeadsClientInfoCard(5); //page size

  useEffect(() => {
    getLeadsClientData();
    return () => {
      actions.clearLeadsData();
    };
  }, []);

  return (
    <React.Fragment>
      <MoreTrafficDialog
        open={openMoreTrafficDialog}
        setopenMoreTrafficDialog={setopenMoreTrafficDialog}
        storeFrontRouteName={storeFrontRouteName}
        storeFrontId={storeFrontId}
        data={getMetadataInfo(storeFrontDetails)}
      />
      <DocumentPreviewDialog
        openDocumetDialog={openDocumetDialog}
        setOpenDocumetDialog={setOpenDocumetDialog}
        documentItem={documentItem}
        setDocumentItem={setDocumentItem}
        downloadUrl={downloadUrl}
        source="leads-page"
        docCategoryId={docCategoryId}
        docNameForDownload={docNameForDownload}
        docUserId={docUserId}
      />
      <MoreOptionsOnClientInfo
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        showTagMemberOption={true}
        toUserId={hbUserId}
        serviceRequestId={serviceRequestId}
        isDashboardWidget={true}
      />
      {leads && leads.response && leads.response.length ? (
        <TableContainer
          component={Paper}
          elevation={0}
          className="hideScroll"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Table
            aria-label="collapsible table"
            className="hideScroll"
            stickyHeader
            style={{
              paddingBottom: 142,
              overflowX: "auto",
              display: "inline-block",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    width: "22%",
                    fontWeight: 600,
                    fontSize: xlUp ? 16 : 12,
                    backgroundColor: "#FFFFFF",
                    lineHeight: "0.5rem",
                    minWidth: (isMobileDevice() || isTabletDevice()) && "13em",
                  }}
                  className={Styles.navyBlueColorForce}
                >
                  Applicant
                </TableCell>
                <TableCell
                  style={{
                    width: "21%",
                    fontWeight: 600,
                    fontSize: xlUp ? 16 : 12,
                    backgroundColor: "#FFFFFF",
                    lineHeight: "0.5rem",
                    minWidth: (isMobileDevice() || isTabletDevice()) && "13em",
                  }}
                  className={Styles.navyBlueColorForce}
                >
                  Status
                </TableCell>
                <TableCell
                  style={{
                    width: "14%",
                    fontWeight: 600,
                    fontSize: xlUp ? 16 : 12,
                    backgroundColor: "#FFFFFF",
                    lineHeight: "0.5rem",
                    minWidth: (isMobileDevice() || isTabletDevice()) && "12em",
                  }}
                  className={Styles.navyBlueColorForce}
                >
                  Request Date
                </TableCell>
                <TableCell
                  style={{
                    width: "26%",
                    fontWeight: 600,
                    fontSize: xlUp ? 16 : 12,
                    backgroundColor: "#FFFFFF",
                    lineHeight: "0.5rem",
                    minWidth: (isMobileDevice() || isTabletDevice()) && "9em",
                  }}
                  className={Styles.navyBlueColorForce}
                >
                  Applicant Notes
                </TableCell>
                <TableCell
                  style={{
                    width: "14%",
                    fontWeight: 600,
                    fontSize: xlUp ? 16 : 12,
                    backgroundColor: "#FFFFFF",
                    lineHeight: "0.5rem",
                    minWidth: (isMobileDevice() || isTabletDevice()) && "7em",
                  }}
                  className={Styles.navyBlueColorForce}
                >
                  Quick Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leads.response.map((data, index) => (
                <TableRow key={index}>
                  <TableCell container>
                    <Grid container>
                      <Grid item xs={2}>
                        <Avatar
                          style={{ height: 24, width: 24 }}
                          src={data.hbDetails.avatarUrl}
                        >
                          {(data.hbDetails.name || "").charAt(0)}
                        </Avatar>
                      </Grid>
                      <Grid container item xs={10} style={{ paddingLeft: 10 }}>
                        <Typography
                          onClick={() => {
                            if (isMarketplacePlan(userInfo)) {
                              setOpenPlanUpgradeMsgDialog(true);
                            } else {
                              history.push(
                                "/serviceprovider/clients?tabIndex=3"
                              );
                            }
                          }}
                          className={Styles.navyBlueColor}
                          style={{
                            fontWeight: 600,
                            fontSize: isMobileDevice() ? 12 : xlUp ? 16 : 11,
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          {data.hbDetails.name}
                        </Typography>
                        {!isMarketplacePlan(userInfo) && (
                          <Typography
                            style={{
                              fontSize: isMobileDevice() ? 10 : xlUp ? 14 : 8,
                            }}
                            className={clsx(
                              Styles.navyBlueColor,
                              Styles.sqEllipsis
                            )}
                          >
                            {data.hbDetails.email}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell style={{ textTransform: "uppercase" }}>
                    <ClientStatus
                      clientStatus={data.hbJourneyStatus}
                      isDashboardWidget={true}
                    />
                  </TableCell>
                  <TableCell className={Styles.navyBlueColor}>
                    <Typography
                      className={Styles.navyBlueColor}
                      style={{
                        fontSize: isMobileDevice() ? 12 : xlUp ? 16 : 11,
                      }}
                    >
                      {moment(data.requestedDate).format(
                        "MMM DD, YYYY - hh:mm A"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          className={clsx(
                            Styles.navyBlueColor,
                            Styles.sqEllipsisTwoLines
                          )}
                          style={{
                            fontSize: isMobileDevice() ? 12 : xlUp ? 14 : 11,
                          }}
                        >
                          {data.notes}
                        </Typography>
                      </Grid>
                      <Grid container item xs={12}>
                        {/* <Grid item xs={3}>
                          <AttachmentIcon
                            className={Styles.navyBlueColorForce}
                          />
                        </Grid> */}
                        <Grid container item xs={12} alignContent="center">
                          {data.attachments && data.attachments.length ? (
                            <Attachments
                              attachments={data.attachments}
                              limit={1}
                              setDocumentItem={setDocumentItem}
                              getUploadedDocuments={getUploadedDocuments}
                              setOpenDocumetDialog={setOpenDocumetDialog}
                              isDashboardWidget={true}
                              setDownloadUrl={setDownloadUrl}
                              setDocCategoryId={setDocCategoryId}
                              setDocNameForDownload={setDocNameForDownload}
                              setDocUserId={setDocUserId}
                              userInfo={userInfo}
                              setOpenPlanUpgradeMsgDialog={
                                setOpenPlanUpgradeMsgDialog
                              }
                            />
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid container justifyContent="space-between">
                      <Grid item xs={3}>
                        <ButtonBase>
                          <MessageIcon
                            style={{ fontSize: 14 }}
                            className={Styles.navyBlueColorForce}
                            onClick={(event) => {
                              if (isMarketplacePlan(userInfo)) {
                                setOpenPlanUpgradeMsgDialog(true);
                              } else {
                                navigateMessage(event, data.hbDetails.email);
                              }
                            }}
                          />
                        </ButtonBase>
                      </Grid>
                      <Grid item xs={3}>
                        {/* <ButtonBase> */}
                        <div style={{ position: "relative", top: 2 }}>
                          <DateRangeIcon
                            style={{
                              fontSize: 14,
                              color: isProPlan(userInfo) && "grey",
                              pointerEvents: isProPlan(userInfo) && "none",
                              cursor: isProPlan(userInfo)
                                ? "default"
                                : "pointer",
                            }}
                            className={Styles.navyBlueColorForce}
                            onClick={() => {
                              if (isMarketplacePlan(userInfo)) {
                                setOpenPlanUpgradeMsgDialog(true);
                              } else {
                                history.push(
                                  "/serviceprovider/calendar?scheduleMeeting=true"
                                );
                              }
                            }}
                          />
                          {isProPlan(userInfo) && (
                            <UpgradePlanIconSection
                              iconBtnStyle={{
                                position: "absolute",
                                fontSize: 16,
                                left: 6,
                                top: -10,
                                fill: "#fa7e61",
                              }}
                              iconStyle={{
                                position: "absolute",
                                fontSize: 16,
                                top: 5,
                                fill: "#fa7e61",
                              }}
                            />
                          )}
                        </div>
                        {/* </ButtonBase> */}
                      </Grid>
                      <Grid item xs={3}>
                        <ButtonBase>
                          <MoreHorizIcon
                            className={Styles.navyBlueColorForce}
                            onClick={(event) => {
                              if (isMarketplacePlan(userInfo)) {
                                setOpenPlanUpgradeMsgDialog(true);
                              } else {
                                handleClick(event);
                                setHBUserId(data.hbDetails.userId);
                                setServiceRequestId(data.serviceRequestId);
                              }
                            }}
                          />
                        </ButtonBase>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div
            style={{
              position: "sticky",
              bottom: 0,
              width: "100%",
              height: 48,
              display: "inline-block",
            }}
          >
            <AppBar
              position="sticky"
              style={{
                top: "auto",
                bottom: 0,
                backgroundColor: "#FFFFFF",
                color: "#FA7E61",
                height: 48,
              }}
            >
              <Grid
                container
                item
                justifyContent="flex-end"
                alignContent="center"
                style={{ paddingRight: "2%", height: "100%" }}
              >
                <ButtonBase
                  onClick={() => {
                    if (isMarketplacePlan(userInfo)) {
                      setOpenPlanUpgradeMsgDialog(true);
                    } else {
                      history.push("/serviceprovider/clients?tabIndex=3");
                    }
                  }}
                >
                  <Typography style={{ fontSize: xlUp ? 14 : 12 }}>
                    View All
                  </Typography>
                </ButtonBase>
              </Grid>
            </AppBar>
          </div>
        </TableContainer>
      ) : (
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            style={{ textAlign: isMobileDevice() && "center" }}
          >
            <img
              src="/images/no-result-found-blue.svg"
              style={{
                height: isMobileDevice() && 121,
                width: isMobileDevice() && 124,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
            <Typography
              style={{
                color: "#868DA6",
                fontSize: 11,
                padding: isMobileDevice() && "0px 24px",
                marginTop: isMobileDevice() && -60,
              }}
            >
              Looks like you do not have any leads at this time. Click{" "}
              <MaterialLink
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (!isOnboardingInComplete(userInfo)) {
                    if (isMarketplacePlan(userInfo)) {
                      setOpenPlanUpgradeMsgDialog(true);
                    } else {
                      setopenMoreTrafficDialog(true);
                    }
                  }
                }}
              >
                here
              </MaterialLink>{" "}
              to drive more traffic by sharing your storefront
            </Typography>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}
