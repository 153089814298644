import React from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import { useLeadsClientInfoCard } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useLeadsClientInfoCard";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minWidth: 606,
  },
}));

function MoveSPToArchiveDialog({
  openMoveSPToArchiveDialog,
  setOpenMoveSPToArchiveDialog,
  serviceRequestId,
  storefrontName,
}) {
  const classes = useStyles();
  const { declineServiceRequest } = useLeadsClientInfoCard();
  return (
    <Dialog
      disableBackdropClick
      open={openMoveSPToArchiveDialog}
      onClose={() => {
        setOpenMoveSPToArchiveDialog(false);
      }}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Move to Archive</Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: 200 }}>
        <DialogContentText>
          Are you sure you want to move {storefrontName} to archive tab?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <Checkbox></Checkbox>
            <Typography
              variant="body1"
              className={Styles.navyBlueColor}
              style={{ display: "inline-flex" }}
            >
              Don't show this again
            </Typography>
          </Grid>
          <Grid container item xs={6} justifyContent="flex-end">
            <Button
              variant="outlined"
              className={Styles.navyBlueColorForce}
              style={{ textTransform: "capitalize" }}
              onClick={() => setOpenMoveSPToArchiveDialog(false)}
            >
              Cancel
            </Button>
            <Button
              className={Styles.primaryBtnAccent}
              style={{
                textTransform: "capitalize",
                padding: "6px 32px",
                margin: "0px 16px",
              }}
              onClick={() => {
                declineServiceRequest(
                  serviceRequestId,
                  `Request Archived ${storefrontName}`,
                  (res) => {
                    if (res && res.status === 200) {
                      setOpenMoveSPToArchiveDialog(false);
                    }
                  }
                );
              }}
            >
              Yes
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default MoveSPToArchiveDialog;
