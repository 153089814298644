import { Grid, Typography, Tooltip } from "@material-ui/core";
import React from "react";
import { useRepresentativeMock2 } from "souqh-react-redux-hooks/serviceProvider/storefront/useRepresentativeMock2";
import { useTheme } from "souqh-react-redux-hooks/serviceProvider/storefront/useTheme";
import Styles from "../../../Styles.module.css";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";

function RepresentativeMock2(props) {
  const { primaryColor, secondaryColor } = useTheme();
  const { numberOfEmployees, yearsInBusiness } = useRepresentativeMock2();
  return (
    <Grid container justifyContent="flex-end">
      <Grid
        container
        item
        xs={12}
        lg={4}
        style={{
          backgroundColor: primaryColor,
          borderRadius: isMobileDevice() || isTabletDevice() ? 0 : 8,
          height: isMobileDevice() || isTabletDevice() ? 100 : 300,
          width: isMobileDevice() ? 375 : 140,
          maxWidth: "unset",
          flexBasis: isMobileDevice() || isTabletDevice() ? "none " : "unset",
          padding: 5,
        }}
      >
        <Grid
          item
          lg={12}
          xs={4}
          container
          justifyContent="center"
          alignContent="center"
          direction="column"
        >
          <Typography
            variant="h6"
            style={{ color: secondaryColor }}
            align="center"
          >
            TBC
          </Typography>
          <Tooltip title="Calculated by Souqh">
            <Typography
              variant="body1"
              style={{ color: secondaryColor }}
              align="center"
            >
              Average Response Time
            </Typography>
          </Tooltip>
        </Grid>
        <Grid
          item
          lg={12}
          xs={4}
          container
          justifyContent="center"
          alignContent="center"
          direction="column"
        >
          <Typography
            variant="h6"
            style={{ color: secondaryColor }}
            align="center"
          >
            {yearsInBusiness || props.yearsInBusiness || "XX"}
          </Typography>
          <Typography
            variant="body1"
            style={{ color: secondaryColor }}
            align="center"
          >
            Years in business
          </Typography>
        </Grid>
        <Grid
          item
          lg={12}
          xs={4}
          container
          justifyContent="center"
          alignContent="center"
          direction="column"
        >
          <Typography
            variant="h6"
            style={{ color: secondaryColor }}
            align="center"
          >
            {numberOfEmployees || props.numberOfEmployees || "XX"}
          </Typography>
          <Typography
            variant="body1"
            style={{ color: secondaryColor }}
            align="center"
          >
            Number of Employees
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default RepresentativeMock2;
