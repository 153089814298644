import { useAuthUser } from "../../useLogin";
import { useSelector, useDispatch } from "react-redux";
import { useApiClient } from "../../useApiClient";
import { useState, useEffect } from "react";
import { AGE_RANGE } from "../../../web/src/components/HBRegistration/HomeBuyerConstants";
import { useNewHomeProfile } from "../../homeBuyer/hbProfile/useNewHomeProfile";
import { useDemographicData } from "../../homeBuyer/hbProfile/useDemographicData";

export const usePeoplesAnalytics = (clients, days) => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();
  const { propertyTypes } = useNewHomeProfile();
  const { industryTypes, householdIncomeRange } = useDemographicData();
  const [finalEmploymentData, setFinalEmploymentData] = useState([]);
  const [finalMaritalData, setFinalMaritalData] = useState([]);
  const [finalAgeRangeData, setFinalAgeRangeData] = useState([]);
  const [finalHouseholdIncomeRangeData, setFinalHouseholdIncomeRangeData] =
    useState([]);
  const [finalPropertyTypeData, setFinalPropertyTypeData] = useState([]);
  const [finalIndustryData, setFinalIndustryData] = useState([]);

  const [peopleAnalyticsData, setPeopleAnalyticsData] = useState([]);
  const [householdIncomeLabels, setHouseholdIncomeLabels] = useState([]);
  const [propertyLabels, setPropertyLabels] = useState([]);
  const [industryLabels, setIndustryLabels] = useState([]);

  useEffect(() => {
    let _propertyLabels = [];
    if (propertyTypes) {
      propertyTypes &&
        propertyTypes.length &&
        propertyTypes.map((item) => _propertyLabels.push(item.name));
      setPropertyLabels(_propertyLabels);
    }
  }, [propertyTypes]);

  useEffect(() => {
    if (propertyLabels && propertyLabels.length) {
      let propertyData = [];
      propertyLabels.map((item) => {
        let index = -1;
        if (
          peopleAnalyticsData.propertyType &&
          peopleAnalyticsData.propertyType.length
        ) {
          index = peopleAnalyticsData.propertyType.findIndex(
            (item2) => item2.name === item
          );
        }
        if (index >= 0) {
          propertyData.push({
            name: getCapitalizedString(item),
            value: peopleAnalyticsData.propertyType[index].percentage,
            color: getMaritalDataColors(item),
          });
        } else {
          propertyData.push({
            name: getCapitalizedString(item),
            value: 0,
            color: getMaritalDataColors(item),
          });
        }
      });
      setFinalPropertyTypeData(propertyData);
    }
  }, [peopleAnalyticsData, propertyLabels]);

  useEffect(() => {
    let _householdIncomeLabels = [];
    if (householdIncomeRange) {
      householdIncomeRange &&
        householdIncomeRange.length &&
        householdIncomeRange.forEach((item) =>
          _householdIncomeLabels.push(item.name)
        );
      setHouseholdIncomeLabels(_householdIncomeLabels);
    }
  }, [householdIncomeRange]);

  useEffect(() => {
    let householdIncomeData = [];
    if (householdIncomeLabels && householdIncomeLabels.length) {
      householdIncomeLabels.forEach((item) => {
        let index = -1;
        if (
          peopleAnalyticsData.householdIncomeRange &&
          peopleAnalyticsData.householdIncomeRange.length
        ) {
          index = peopleAnalyticsData.householdIncomeRange.findIndex(
            (item2) => item2.name === item
          );
        }
        if (index >= 0) {
          householdIncomeData.push({
            name: getCapitalizedString(item),
            value: peopleAnalyticsData.householdIncomeRange[index].percentage,
            color: getMaritalDataColors(item),
          });
        } else {
          householdIncomeData.push({
            name: getCapitalizedString(item),
            value: 0,
            color: getMaritalDataColors(item),
          });
        }
      });
      setFinalHouseholdIncomeRangeData(householdIncomeData);
    }
  }, [peopleAnalyticsData, householdIncomeLabels]);

  useEffect(() => {
    let _industryLabels = [];
    if (industryTypes) {
      industryTypes &&
        industryTypes.length &&
        industryTypes.map((item) => _industryLabels.push(item.name));
      setIndustryLabels(_industryLabels);
    }
  }, [industryTypes]);

  useEffect(() => {
    if (industryLabels && industryLabels.length) {
      let indusData = [];
      industryLabels.map((item) => {
        let index = -1;
        if (
          peopleAnalyticsData.industry &&
          peopleAnalyticsData.industry.length
        ) {
          index =
            peopleAnalyticsData.industry.length &&
            peopleAnalyticsData.industry.findIndex(
              (item2) => item2.name === item
            );
        }
        if (index >= 0) {
          indusData.push({
            name: getCapitalizedString(item),
            value: peopleAnalyticsData.industry[index].percentage,
            color: getMaritalDataColors(item),
          });
        } else {
          indusData.push({
            name: getCapitalizedString(item),
            value: 0,
            color: getMaritalDataColors(item),
          });
        }
      });
      setFinalIndustryData(indusData);
    }
  }, [peopleAnalyticsData, industryLabels]);

  const getPeoplesAnalyticsData = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(
        `/service-provider/storefront/analytics/people?clients=${clients}&days=${days}`
      )
      .then(function (response) {
        if (response.data && response.status === 200) {
          setPeopleAnalyticsData(response.data);
          let ageData = [];
          AGE_RANGE.map((item) => {
            let index = -1;
            if (response.data.ageRange.length) {
              index = response.data.ageRange.findIndex(
                (item2) => item2.ageGroup === item
              );
            }
            if (index >= 0) {
              ageData.push({
                name: getCapitalizedString(item),
                value: response.data.ageRange[index].percentage,
                color: getMaritalDataColors(item),
              });
            } else {
              ageData.push({
                name: getCapitalizedString(item),
                value: 0,
                color: getMaritalDataColors(item),
              });
            }
          });
          setFinalAgeRangeData(ageData);

          let empData = [];
          ["EMPLOYED", "SELF_EMPLOYED"].map((item) => {
            let index = -1;
            if (response.data.employment.length) {
              index = response.data.employment.findIndex(
                (item2) => item2.employment === item
              );
            }
            if (index >= 0) {
              empData.push({
                name: getCapitalizedString(item),
                value: response.data.employment[index].percentage,
                color: item === "EMPLOYED" ? "#FA7E61" : "#FDD2C8",
              });
            } else {
              empData.push({
                name: getCapitalizedString(item),
                value: 0,
                color: item === "EMPLOYED" ? "#FA7E61" : "#FDD2C8",
              });
            }
          });
          setFinalEmploymentData(empData);

          let maritalData = [];
          ["MARRIED", "SINGLE", "COMMON_LAW", "OTHER"].map((item) => {
            let index = -1;
            if (response.data.maritalStatus.length) {
              index = response.data.maritalStatus.findIndex(
                (item2) => item2.maritalStatus === item
              );
            }
            if (index >= 0) {
              maritalData.push({
                name: getCapitalizedString(item),
                value: response.data.maritalStatus[index].percentage,
                color: getMaritalDataColors(item),
              });
            } else {
              maritalData.push({
                name: getCapitalizedString(item),
                value: 0,
                color: getMaritalDataColors(item),
              });
            }
          });
          setFinalMaritalData(maritalData);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getCapitalizedString = (item) => {
    return item
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const getMaritalDataColors = (item) => {
    switch (item) {
      //marital data
      case "MARRIED":
        return "#66D7D1";
      case "SINGLE":
        return "#C9F1EF";
      case "COMMON_LAW":
        return "#d691b2";
      case "OTHER":
        return "#b2b2b2";
      //age range data
      case "18-25":
        return "#C86FC9";
      case "26-35":
        return "#DBA1DC";
      case "36-55":
        return "#ECCDEC";
      case "55 and above":
        return "#D90568";
      //household income data
      case "$5,000 to $39,999":
        return "#D90568";
      case "$40,000 to $49,999":
        return "#E65B9D";
      case "$50,000 to $59,999":
        return "#F2A7CA";
      case "$60,000 to $79,999":
        return "#2842D0";
      case "$80,000 to $99,999":
        return "#7384E0";
      case "$100,000 to $149,999":
        return "#B4BDEF";
      case "$150,000 to $199,999":
        return "#FA7E61";
      case "$200,000 and over":
        return "#FCAB98";
      // property type data
      case "Detached":
        return "#2842D0";
      case "Semi-detached":
        return "#7384E0";
      case "Townhouse":
        return "#B4BDEF";
      case "Condo/Apartment":
        return "#FA7E61";
      case "Other":
        return "#FCAB98";
      // industry type data
      case "Accommodation and food services":
        return "#FA7E61";
      case "Agriculture":
        return "#FA7E61A6";
      case "Information Technology":
        return "#FA7E6159";
      case "Construction":
        return "#66D7D1";
      case "Educational services":
        return "#66D7D1A6";
      case "Finance and insurance":
        return "#66D7D159";
      case "Forestry, fishing, mining, oil and gas":
        return "#C86FC9";
      case "Health care and social assistance":
        return "#C86FC9A6";
      case "Recreation":
        return "#C86FC959";
      case "Manufacturing":
        return "#D90368";
      case "Other services":
        return "#D90368A6";
      case "Professional, scientific and technical services":
        return "#D9036859";
      case "Public administration":
        return "#2842D0";
      case "Trade":
        return "#7384E0";
      case "Transportation and warehousing":
        return "#B4BDEF";
      case "Utilities":
        return "#FDC000";
      case "Real estate and leasing":
        return "#FFEFBF";
    }
  };

  return {
    getPeoplesAnalyticsData,
    finalEmploymentData,
    finalMaritalData,
    finalAgeRangeData,
    finalHouseholdIncomeRangeData,
    finalPropertyTypeData,
    finalIndustryData,
  };
};
