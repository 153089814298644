import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useApiClient } from "../../useApiClient";
import * as moment from "moment";
import { getDatesRange } from "../../../web/src/utils/AppUtils";

export const useTrafficAnalytics = (days) => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();
  const [trafficAnalyticsData, setTrafficAnalyticsData] = useState();
  const [previousDaysCount, setPreviousDaysCount] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [selectiveArray, setSelectiveArray] = useState([]);
  const [allArray, setAllArray] = useState([]);
  const [finalTimeTrafficData, setFinalTimeTrafficData] = useState([]);
  const [trafficByLocationData, setTrafficByLocationData] = useState([]);
  const [finalLocationTrafficData, setFinalLocationTrafficData] = useState([]);
  const [trafficByLocationPreference, setTrafficByLocationPreference] =
    useState("City");

  useEffect(() => {
    if (days === "THIRTY_DAYS") {
      setPreviousDaysCount(30);
    } else if (days === "NINETY_DAYS") {
      setPreviousDaysCount(90);
    }
  }, [days]);

  useEffect(() => {
    if (
      days === "ALL_DAYS" &&
      trafficAnalyticsData &&
      trafficAnalyticsData.sfVisitsTimelineAnalyticsData &&
      trafficAnalyticsData.sfVisitsTimelineAnalyticsData.length
    ) {
      setStartDate(
        new Date(trafficAnalyticsData.sfVisitsTimelineAnalyticsData[0].date)
      );
    } else {
      const date = new Date();
      setStartDate(new Date().setDate(date.getDate() - previousDaysCount));
    }
  }, [trafficAnalyticsData, days, previousDaysCount]);

  useEffect(() => {
    const date = new Date();
    getDatesRange(
      startDate,
      date,
      1,
      setAllArray,
      setSelectiveArray,
      true //for isAllArray
    );
    if (days === "THIRTY_DAYS") {
      getDatesRange(startDate, date, 3, setAllArray, setSelectiveArray, false);
    } else if (days === "NINETY_DAYS") {
      getDatesRange(startDate, date, 7, setAllArray, setSelectiveArray, false);
    } else {
      let end = moment();
      let start = moment(startDate, "DD-MM-YYYY");
      let diff = end.diff(start, "days");
      let step = Math.round(diff / 10);
      let stepSize = step || 1;

      if (diff > 0) {
        getDatesRange(
          startDate,
          date,
          stepSize,
          setAllArray,
          setSelectiveArray,
          false
        );
      }
    }
  }, [startDate]);

  useEffect(() => {
    if (allArray && allArray.length) {
      let finalData = [];
      allArray.map((item) => {
        let index = -1;
        if (
          trafficAnalyticsData &&
          trafficAnalyticsData.sfVisitsTimelineAnalyticsData &&
          trafficAnalyticsData.sfVisitsTimelineAnalyticsData.length
        ) {
          index =
            trafficAnalyticsData.sfVisitsTimelineAnalyticsData.length &&
            trafficAnalyticsData.sfVisitsTimelineAnalyticsData.findIndex(
              (item2) =>
                moment(item2.date).format("MMM DD, YYYY").split(",")[0] === item
            );
        }

        // { year: 2000, value: 10, xValue: 2000 },
        if (index >= 0) {
          finalData.push({
            year: selectiveArray.includes(item) ? item : "",
            value:
              trafficAnalyticsData.sfVisitsTimelineAnalyticsData[index].count,
            xValue: item,
          });
        } else {
          finalData.push({
            year: selectiveArray.includes(item) ? item : "",
            value: 0,
            xValue: item,
          });
        }
        setFinalTimeTrafficData(finalData);
      });
    }
  }, [trafficAnalyticsData, allArray]);

  useEffect(() => {
    if (trafficByLocationData) {
      let displayData =
        trafficByLocationPreference === "City"
          ? trafficByLocationData.cityTraffic
          : trafficByLocationData.provinceTraffic;
      let finalData = [];
      displayData &&
        Object.keys(displayData).map((key) => {
          finalData.push({
            name: key,
            value: displayData[key],
            xValue: key,
          });
        });
      setFinalLocationTrafficData(finalData);
    }
  }, [trafficByLocationData, trafficByLocationPreference]);

  const getTrafficAnalyticsData = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/service-provider/storefront/analytics/traffic?days=${days}`)
      .then(function (response) {
        setTrafficAnalyticsData(response.data);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getTrafficByLocationData = () => {
    // trafficByLocationPreference will give whether user selected province or city
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/service-provider/storefront/analytics/ga-location?days=ALL_DAYS`)
      .then(function (response) {
        setTrafficByLocationData(response.data);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    getTrafficAnalyticsData,
    trafficAnalyticsData,
    finalTimeTrafficData,
    getTrafficByLocationData,
    finalLocationTrafficData,
    trafficByLocationPreference,
    setTrafficByLocationPreference,
  };
};
