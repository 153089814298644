import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Typography,
  Divider,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useAddMember } from "souqh-react-redux-hooks/settings/useAddMember";
import {
  PLUS,
  PRO,
  SERVICE_PROVIDER_USER,
  TEAM_MEMBER_ROLES,
  TEAM_MEMBER_USER,
  BILLING_CYCLE,
} from "../ServiceProvider/serviceProviderConstants";
import { useGetSubscriptionPlanList } from "souqh-react-redux-hooks/common/useGetSubscriptionPlanList";
import Promocode from "../SPSetup/PaymentAndInvoice/Promocode";
import { useDispatch } from "react-redux";
import { SET_PROMO_CODE } from "souqh-redux/reducers/actionConstants";

function AddMemberDialog({ openAddMemberDialog, setOpenAddMemberDialog }) {
  const dispatch = useDispatch();
  const {
    setTitle,
    setName,
    setEmail,
    setPlan,
    setRole,
    addTeamMember,
    plan,
    role,
    allowAdd,
    validations,
    clearData,
    addValidations,
    error,
    setError,
    billing,
    setBilling,
  } = useAddMember();

  const { getSubscriptionPlanList, plans, userInfo } =
    useGetSubscriptionPlanList();

  useEffect(() => {
    if (
      userInfo.userType === SERVICE_PROVIDER_USER ||
      userInfo.userType === TEAM_MEMBER_USER
    ) {
      getSubscriptionPlanList();
    }
    return () => {
      setError("");
    };
  }, []);

  useEffect(() => {
    if (!openAddMemberDialog) {
      dispatch({
        type: SET_PROMO_CODE,
        payload: "",
      });
      dispatch({
        type: "SET_VERFIY_PROMOCODE",
        payload: "",
      });
    }
  }, [openAddMemberDialog]);

  return (
    <Dialog
      fullWidth
      open={openAddMemberDialog}
      disableBackdropClick
      onClose={() => {
        setError("");
        setOpenAddMemberDialog(false);
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Add New Member</Typography>
      </DialogTitle>
      <Divider style={{ marginBottom: 24 }} />
      <DialogContent>
        <Grid container className={Styles.navyBlueColor}>
          <Grid item xs={12} style={{ marginBottom: 8 }}>
            <TextField
              id="title"
              placeholder="Title"
              name="title"
              variant="outlined"
              fullWidth
              required
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 8 }}>
            <TextField
              error={!!validations.name}
              helperText={validations.name}
              id="fullName"
              placeholder="Full Name"
              name="fullName"
              variant="outlined"
              fullWidth
              required
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 8 }}>
            <TextField
              error={!!validations.email}
              helperText={validations.email}
              id="email"
              placeholder="Email Address"
              name="email"
              variant="outlined"
              fullWidth
              required
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 8 }}>
            <Autocomplete
              fullWidth
              closeIcon=""
              options={
                (plans &&
                  plans.length &&
                  plans.filter((ele) => ele.id !== 4 && ele.id !== 1)) ||
                []
              }
              // value={meetingStartTime}
              getOptionLabel={(option) => option.planName}
              getOptionSelected={(option, value) => {
                return option.planName === value.planName;
              }}
              onChange={(e, value) => {
                setPlan(value);
                setRole("");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Plan"
                />
              )}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  {option.planName !== "Enterprise Plan" &&
                    option.planName !== "Marketplace Plan" &&
                    option.planName}
                </React.Fragment>
              )}
            />
          </Grid>
          {plan.planName === PRO || plan.planName === PLUS ? (
            <Grid item xs={12} style={{ marginBottom: 8 }}>
              <Autocomplete
                fullWidth
                closeIcon=""
                value={role}
                options={TEAM_MEMBER_ROLES || []}
                onChange={(e, value) => {
                  setRole(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Role"
                  />
                )}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} style={{ marginBottom: 8 }}>
            <Autocomplete
              fullWidth
              closeIcon=""
              value={billing}
              options={BILLING_CYCLE || []}
              onChange={(e, value) => {
                setBilling(value);
              }}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Billing Cycle"
                />
              )}
            />
          </Grid>
          <Grid container item xs={12} style={{ marginBottom: 8 }}>
            <Promocode page="teamMember" />
          </Grid>
        </Grid>
        {error && <Typography color="error">{error}</Typography>}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          className={Styles.navyBlueColorForce}
          style={{ textTransform: "capitalize", marginRight: 8 }}
          onClick={() => {
            setError("");
            setOpenAddMemberDialog(false);
            clearData();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!allowAdd}
          className={Styles.primaryBtnAccent}
          style={{
            textTransform: "capitalize",
            marginRight: 16,
            padding: "6px 24px",
          }}
          onClick={() => {
            addTeamMember((res) => {
              if (res && res.data) {
                if (res.data.status === 200) {
                  setOpenAddMemberDialog(false);
                  clearData();
                } else if (res.data.status === 400) {
                  addValidations({ email: res.data.message });
                } else if (res.data.status === 409) {
                  setError(res.data.message);
                }
              }
            });
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddMemberDialog;
