import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  makeStyles,
  Chip,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import CloseIcon from "@material-ui/icons/Close";
import CircleIcon from "@mui/icons-material/Circle";
import { useExplore } from "souqh-react-redux-hooks/homeBuyer/useExplore";
import { useHistory } from "react-router";
import { useNewHomeProfile } from "souqh-react-redux-hooks/homeBuyer/hbProfile/useNewHomeProfile";
import useFeaturedStoreFront from "souqh-react-redux-hooks/homeBuyer/useFeaturedStoreFront";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";
import FeaturedStoreFront from "../Explore/FeaturedStoreFront";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 720,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  svgIcon: {
    color: "white",
    fill: "white",
    stroke: "#0e1c4e",
    width: "0.75rem !important",
    "&.selected": {
      fill: "#0e1c4e",
    },
  },
  svgIconButton: {
    padding: 6,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  content: {
    marginLeft: "4.5rem",
    padding: "1.5rem",
  },
  chip: {
    marginTop: "2rem",
  },
  heading: {
    marginBottom: "1.5rem",
  },
}));

function MoveIntoHomeDialog({
  openMoveIntoHomeDialog,
  setOpenMoveIntoHomeDialog,
  newHomeProfile,
}) {
  const classes = useStyles();
  let history = useHistory();

  const serviceProviders = [
    {
      id: 6,
      name: "Mover",
      logoUrl: "Mover.svg",
      isBusinessType: true,
      storefrontPathName: "mover",
    },
    {
      id: 37,
      isBusinessType: false,
      logoUrl: "Painters&decorators.svg",
      name: "Painters & Decorators",
      storefrontPathName: "painters",
    },
    {
      id: 39,
      isBusinessType: false,
      logoUrl: "Plumbing.svg",
      name: "Plumbing",
      storefrontPathName: "plumbing-services",
    },
    {
      id: 27,
      isBusinessType: false,
      logoUrl: "Electricandlighting.svg",
      name: "Electrical and Lighting",
      storefrontPathName: "electrical-and-lighting",
    },
    // FIX ME: add Other General Contractors
  ];

  const userInfo = useAuthUser();

  const theme = useTheme();
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  const numberOfCards = isMobileDevice()
    ? 1
    : isTabletDevice()
    ? 2
    : xlUp
    ? 6
    : 4;

  const [featuredSFs, setFeaturedSFs] = useState([]);

  const { formSaveCategoryObjAndCallApi } = useExplore();
  const { shuffledItems, activeIndex, setActiveIndex } = useFeaturedStoreFront(
    featuredSFs,
    numberOfCards,
    openMoveIntoHomeDialog
  );

  useEffect(() => {
    let final = [];
    userInfo.journeyStoreFront &&
      userInfo.journeyStoreFront.length &&
      userInfo.journeyStoreFront.map((item) => final.push(item));
    setFeaturedSFs(final);
  }, [userInfo]);

  return (
    <Dialog
      fullWidth
      disableBackdropClick
      open={openMoveIntoHomeDialog}
      onClose={() => {
        setOpenMoveIntoHomeDialog(false);
      }}
      classes={{
        paper: classes.dialogPaper,
      }}
      maxWidth="xl"
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Move Into Your Home</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => {
            setOpenMoveIntoHomeDialog(false);
          }}
        >
          <CloseIcon className={Styles.navyBlueColor} />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent classes={{ root: classes.content }}>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          {/* <Grid item xs={6}>
            <img
              src="/images/Move-Into-Your-Home.svg"
              style={{ height: 305, width: 460 }}
            />
          </Grid> */}
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Typography
                classes={{ root: classes.heading }}
                variant="h1"
                className={Styles.navyBlueColor}
              >
                Hurray!
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                style={{ fontSize: 24, fontWeight: 600 }}
                className={Styles.navyBlueColor}
              >
                It’s finally time to move in to your home! Let’s get you in
                touch with service providers to help you with the final stage of
                your journey.
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <Grid item container xs={12}>
                <FeaturedStoreFront
                  bgColor="#66D7D140"
                  storeFrontList={shuffledItems}
                />
                {featuredSFs.length &&
                featuredSFs.length / numberOfCards > 1 ? (
                  <Grid
                    style={{ justifyContent: "center", marginTop: 10 }}
                    container
                    item
                    xs={12}
                  >
                    {Array(Math.ceil(featuredSFs.length / numberOfCards))
                      .fill(1)
                      .map((item, index) => (
                        <IconButton
                          classes={{ root: classes.svgIconButton }}
                          onClick={() => setActiveIndex(index)}
                        >
                          <CircleIcon
                            classes={{ root: classes.svgIcon }}
                            className={activeIndex === index ? "selected" : ""}
                          />
                        </IconButton>
                      ))}
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={12} classes={{ root: classes.chip }}>
              {serviceProviders.map((provider, index) => (
                <Chip
                  key={index}
                  variant="outlined"
                  label={provider.name}
                  className={Styles.navyBlueColorForce}
                  style={{ marginRight: 8 }}
                  onClick={() => {
                    formSaveCategoryObjAndCallApi(provider, userInfo);
                    history.push({
                      pathname: `/homebuyer/explore/${provider.storefrontPathName}/${newHomeProfile?.city?.shortName}`,
                      state: {
                        searchedService: provider.name,
                      },
                    });
                    // history.push(
                    //   `/homebuyer/explore/${provider.storefrontPathName}/${newHomeProfile?.city?.name}`
                    // );
                  }}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default MoveIntoHomeDialog;
