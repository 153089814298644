import { useDispatch, useSelector } from "react-redux";
import { TEAM_MEMBER_USER } from "../web/src/components/ServiceProvider/serviceProviderConstants";
import { useApiClient } from "./useApiClient";
import { useAuthUser } from "./useLogin";

export const useError = () => {
  const dispatch = useDispatch();
  const { errorNotification } = useSelector((state) => state.ui);
  const userInfo = useAuthUser();
  const { apiClient } = useApiClient();

  const handleClose = () => {
    dispatch({ type: "SET_ERROR_NOTIFICATION", error: null });
  };

  const sendFeedback = (errorPage) => {
    dispatch({ type: "SET_LOADING", value: true });
    const userName =
      userInfo.userType === TEAM_MEMBER_USER
        ? userInfo.fullName
        : `${userInfo.firstName} ${userInfo.lastName}`;
    apiClient
      .post("/error-feedback/", {
        userName: userName,
        errorPage,
        errorMessage: errorNotification,
        errorTime: new Date().toISOString(),
      })
      .then((res) => {
        onSuccess && onSuccess(res);
      })
      .catch((error) => {
        dispatch({ type: "SET_SERVER_ERROR", error: error });
        // onFailure && onFailure(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return { errorNotification, handleClose, sendFeedback };
};
