import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  makeStyles,
  Grid,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";
import { useDeleteCategory } from "souqh-react-redux-hooks/homeBuyer/useDeleteCategory";
import DeleteCategoryConfirmationDialog from "./DeleteCategoryConfirmationDialog";
import Toaster from "../../utils/Toaster";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 350,
  },
}));

function DeleteCategoryDialog({
  openDeleteCategoryDialog,
  setOpenDeleteCategoryDialog,
  categories,
  getTabWiseCategoriesList,
  documentTabName,
}) {
  const {
    deleteCategory,
    errorMessage,
    setErrorMessage,
    selectedCategoryName,
    setSelectedCategoryName,
    actions: { setSelectedCategory },
  } = useDeleteCategory(getTabWiseCategoriesList, documentTabName);

  const [
    openDeleteCategoryConfirmationDialog,
    setOpenDeleteCategoryConfirmationDialog,
  ] = useState(false);

  const classes = useStyles();
  const [categoryName, setCategoryName] = useState("");
  return (
    <React.Fragment key="deleteDialog">
      <Dialog
        fullWidth
        open={openDeleteCategoryDialog}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DeleteCategoryConfirmationDialog
          openDeleteCategoryConfirmationDialog={
            openDeleteCategoryConfirmationDialog
          }
          setOpenDeleteCategoryConfirmationDialog={
            setOpenDeleteCategoryConfirmationDialog
          }
          categoryName={selectedCategoryName}
          setSelectedCategoryName={setSelectedCategoryName}
          setSelectedCategory={setSelectedCategory}
          deleteCategory={deleteCategory}
          setOpenDeleteCategoryDialog={setOpenDeleteCategoryDialog}
        />
        <DialogTitle className={Styles.navyBlueColor}>
          <Typography variant="subtitle1">Delete Category</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label">Select Category</InputLabel>
              <Select
                labelId="label"
                id="select-category-id"
                label="Select Category"
                onClick={(e) => setSelectedCategory(e.target.value)}
              >
                {categories &&
                  categories.length &&
                  categories
                    .filter((item) => !item.isDefault)
                    .map((category, index) => (
                      <MenuItem
                        data-name={category.name}
                        key={index}
                        value={category.id}
                        onClick={(event) => {
                          setCategoryName(category.name);
                          setSelectedCategoryName(
                            event.currentTarget.getAttribute("data-name")
                          );
                        }}
                      >
                        {category.name}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
            {errorMessage && (
              <Typography variant="body2" color="error">
                {errorMessage}
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            className={Styles.navyBlueColorForce}
            style={{ textTransform: "capitalize" }}
            onClick={() => {
              setOpenDeleteCategoryDialog(false);
              setErrorMessage("");
              setSelectedCategoryName("");
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!selectedCategoryName}
            className={Styles.primaryBtnAccent}
            style={{
              textTransform: "capitalize",
              padding: "6px 24px",
              marginRight: 16,
            }}
            onClick={() => setOpenDeleteCategoryConfirmationDialog(true)}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default DeleteCategoryDialog;
