import React, { useEffect } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Documents from "./UploadDocuments/Documents";
import SPDashboard from "./Dashboard";
import ReferralManagement from "../ReferralManagement/ReferralManagement";
import LeftNavBar from "../LeftNavBar";
import ClientManagement from "./ClientManagement";
import ClientDetails from "./ClientManagement/ClientDetails";
import DetailReviewsPage from "./ClientManagement/DetailReviewsPage";
import ViewWrapper from "../common/ViewWrapper";
import Messaging from "../Messaging/Messaging";
import Settings from "../Settings/Settings";
import ManageStorefront from "./ManageStorefront";
import StorefrontPage from "../HomeBuyer/Explore/StorefrontPage/StorefrontPage";
import SQCalender from "../common/SQCalender/SQCalender";
import UpcomingEeventsPendingActions from "../common/UpcomingEeventsPendingActions";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { useRouteCheck } from "souqh-react-redux-hooks/common/useRouteCheck";
import Marketing from "../ServiceProvider/MarketingCampaign/Marketing";
import Apps from "./Apps/index";
import FeedbackPage from "../FeedbackPage";
import Explore from "../HomeBuyer/Explore/Explore";
import { isMobileDevice, isTabletDevice } from "../../utils/AppUtils";
import MobileStorefrontPage from "../../mobileComponents/HomeBuyer/StorfrontDetails/MobileStorefrontPage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    backgroundColor: "#F5F5F8",
    minHeight: window.innerHeight,
  },
}));
const ServiceProvider = () => {
  let { path } = useRouteMatch();
  const classes = useStyles();
  const userInfo = useAuthUser();
  const { checkSPRoute } = useRouteCheck();

  useEffect(() => {
    checkSPRoute();
  }, [userInfo]);

  if (!userInfo) {
    return null;
  }

  return (
    <div className={classes.root}>
      <LeftNavBar></LeftNavBar>
      <main className={classes.content}>
        <div style={{ width: "calc(100%)" }}>
          <Grid
            item
            container
            xs={12}
            style={{
              alignItems: "flex-start",
              justifyContent: "center",
              maxWidth: window.screen.width,
            }}
          >
            <Switch>
              <Route exact path={`${path}/dashboard`}>
                <ViewWrapper>
                  <SPDashboard />
                </ViewWrapper>
              </Route>
              <Route exact path={`${path}`}>
                <ViewWrapper>
                  <SPDashboard />
                </ViewWrapper>
              </Route>
              <Route exact path={`${path}/calendar`}>
                <SQCalender />
              </Route>
              <Route exact path={`${path}/storefront`}>
                <ViewWrapper
                  showWhiteBackgroundForTabletAndMobile={
                    isTabletDevice() || isMobileDevice() ? true : false
                  }
                >
                  <ManageStorefront />
                </ViewWrapper>
              </Route>
              <Route exact path={`${path}/clients`}>
                <ViewWrapper showWhiteBackground={true}>
                  <ClientManagement />
                </ViewWrapper>
              </Route>
              <Route exact path={`${path}/clients/reviews`}>
                <ViewWrapper>
                  <DetailReviewsPage />
                </ViewWrapper>
              </Route>
              <Route exact path={`${path}/clients/details/:userId`}>
                <ViewWrapper
                  showWhiteBackgroundForTabletAndMobile={
                    isTabletDevice() || isMobileDevice() ? true : false
                  }
                >
                  <ClientDetails />
                </ViewWrapper>
              </Route>
              <Route exact path={`${path}/documents`}>
                <ViewWrapper
                  showWhiteBackgroundForTabletAndMobile={
                    isTabletDevice() || isMobileDevice() ? true : false
                  }
                >
                  <Documents />
                </ViewWrapper>
              </Route>
              <Route exact path={`${path}/marketing`}>
                <ViewWrapper
                  showWhiteBackgroundForTabletAndMobile={
                    isTabletDevice() || isMobileDevice() ? true : false
                  }
                >
                  <Marketing />
                </ViewWrapper>
              </Route>
              <Route
                exact
                path={`${path}/explore/:appSearchedService?/:appSearchedCityId?`}
              >
                {!isMobileDevice() && !isTabletDevice() ? (
                  <ViewWrapper>
                    <Explore />
                  </ViewWrapper>
                ) : (
                  <Explore />
                )}
              </Route>
              <Route exact path={`${path}/referralmanagement`}>
                <ViewWrapper>
                  <ReferralManagement source="serviceProvider" />
                </ViewWrapper>
              </Route>
              <Route exact path={`${path}/events`}>
                <ViewWrapper>
                  <UpcomingEeventsPendingActions />
                </ViewWrapper>
              </Route>
              <Route exact path={`${path}/storefrontview/:businessName`}>
                {isMobileDevice() ? (
                  <MobileStorefrontPage />
                ) : isTabletDevice() ? (
                  <StorefrontPage sourcePage="storefrontWidget" />
                ) : (
                  <ViewWrapper>
                    <StorefrontPage sourcePage="storefrontWidget" />
                  </ViewWrapper>
                )}
              </Route>
              <Route exact path={`${path}/messaging`}>
                <Messaging />
              </Route>
              <Route path={`${path}/settings`}>
                <Settings />
              </Route>
              <Route path={`${path}/apps`}>
                <Apps />
              </Route>
              <Route path={`${path}/feedback`}>
                <FeedbackPage />
              </Route>
            </Switch>
          </Grid>
        </div>
      </main>
    </div>
  );
};
export default ServiceProvider;
