import {
  Grid,
  Typography,
  Paper,
  Tabs,
  makeStyles,
  Tab,
  ButtonBase,
  Tooltip,
  Button,
  Divider,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  Avatar,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Styles from "../../../Styles.module.css";
import { CLIENT_DETAILS_TAB_NAMES } from "./clientManagementConstants";
import clsx from "clsx";
import PersonalDetailsTab from "./PersonalDetailsTab";
import MoreOptionsOnClientInfo from "./MoreOptionsOnClientInfo";
import DemographicsDataTab from "./DemographicsDataTab";
import ClientsHubTab from "./ClientsHubTab";
import DocumentsTab from "./DocumentsTab";
import { useHistory } from "react-router";
import { Link, useParams } from "react-router-dom";
import * as moment from "moment";
import { useSQQuery } from "souqh-react-redux-hooks/common/useSQQuery";
import { useSPeSignWizard } from "souqh-react-redux-hooks/serviceProvider/documents/useSPeSignWizard";
import SPeSignWizard from "../UploadDocuments/SPeSignWizard";
import { useDocuments } from "souqh-react-redux-hooks/serviceProvider/documents/useDocuments";
import { useSPUploadWizard } from "souqh-react-redux-hooks/serviceProvider/documents/useSPUploadWizard";
import SPUploadWizard from "../../common/UploadWizard/SPUploadWizard";
import {
  formatContactNumber,
  isMobileDevice,
  isTabletDevice,
} from "../../../utils/AppUtils";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { usePersonalOverview } from "souqh-react-redux-hooks/homeBuyer/hbProfile/usePersonalOverview";
import AssignClientDialog from "./AssignClientDialog";
import { SERVICE_PROVIDER_USER } from "../serviceProviderConstants";
import { useSelector } from "react-redux";
import { useRoleManagement } from "souqh-react-redux-hooks/common/useRoleManagement";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import MessageIcon from "@material-ui/icons/Message";
import CallIcon from "@material-ui/icons/Call";
import { useNavigateToMessage } from "souqh-react-redux-hooks/common/useNavigateToMessage";
import { useTagSomeone } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useTagSomeone";

const useStyles = makeStyles((theme) => ({
  marginTop20: {
    marginTop: 20,
  },
  tabs: {
    minWidth: "20%",
    fontWeight: "bold",
    textTransform: "capitalize",
    opacity: "100%",
    fontSize: 16,
  },
  marginTop10: {
    marginTop: 10,
  },
  modal: {
    zIndex: "1400 !important",
  },
  paper: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export default function ClientDetails() {
  const { query } = useSQQuery();
  const history = useHistory();
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tabDrawer, setTabDrawer] = useState(false);
  const [quickActionDrawer, setQuickActionDrawer] = useState(false);
  const [webPresenceDrawer, setWebPresenceDrawer] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Personal");
  const [openAssignClientDialog, setOpenAssignClientDialog] = useState(false);
  const { getTagMembers } = useTagSomeone();

  const { tagMembers } = useSelector((state) => state.clientManagement);
  const { isAdmin } = useRoleManagement();
  const userInfo = useAuthUser();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  let nonPremiumTeamMembersCheck =
    tagMembers &&
    tagMembers.nonPremiumTeamMembersList &&
    tagMembers.nonPremiumTeamMembersList.length;

  const assignClientDetails = history.location.state;

  const {
    getPersonalData,
    clientProfileImage,
    clientFirstName,
    coApplicant,
    currentAddress,
    primaryApplicant,
    coApplicantSupport,
    onSouqhSince,
    activeSps,
  } = usePersonalOverview(false);

  const {
    updateIntercomStatus,
    getTabWiseCategoriesList,
    documentTabName,
    categories,
    actions: { setDocumentTabName, setDocumentTab },
  } = useDocuments();

  const {
    onUploadEsignFileChange,
    openEsignWizard,
    actions: { setOpenEsignWizard },
  } = useSPeSignWizard();

  const {
    onUploadFileChange,
    openUploadWizard,
    actions: { setOpenUploadWizard },
  } = useSPUploadWizard();

  const { navigateMessage } = useNavigateToMessage();

  let { userId } = useParams();
  let serviceRequestId = query.get("serviceRequestId");

  useEffect(() => {
    // window.scrollTo(0, 0);
    // temporary fix for scroll as window.scrollTo(0, 0) is not working here
    window.scrollTo({
      top: 10,
      left: 10,
      behavior: "smooth",
    });
    getPersonalData(userId);
    getTagMembers();
  }, []);

  useEffect(() => {
    const tabIndex = query.get("tabIndex");
    if (tabIndex) {
      setTabValue(parseInt(tabIndex));
    } else {
      setTabValue(0);
    }
  }, []);

  const moreActionsList = () => (
    <div
      className={classes.fullList}
      role="presentation"
      onClick={() => {
        setTabDrawer(false);
      }}
      onKeyDown={() => {
        setTabDrawer(false);
      }}
    >
      <Grid container item xs={12} style={{ padding: 16 }}>
        <Grid item xs={10}>
          <Typography variant="subtitle1" className={Styles.navyBlueColorForce}>
            More Information
          </Typography>
        </Grid>
        <Grid container item xs={2} justifyContent="flex-end">
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              setTabDrawer(false);
            }}
          />
        </Grid>
      </Grid>
      <Divider />
      <List>
        {["Personal", "Documents", "Demographic", "Client Hub"].map(
          (text, index) => (
            <ListItem key={index} onClick={() => setSelectedTab(text)}>
              <ListItemText
                primary={text}
                className={Styles.navyBlueColorForce}
                style={{ fontSize: 14, cursor: "pointer" }}
              />
            </ListItem>
          )
        )}
      </List>
    </div>
  );
  const moreQuickActionsList = () => (
    <div
      className={classes.fullList}
      role="presentation"
      onClick={() => {
        setTabDrawer(false);
      }}
      onKeyDown={() => {
        setTabDrawer(false);
      }}
    >
      <Grid container item xs={12} style={{ padding: 16 }}>
        <Grid item xs={10}>
          <Typography variant="subtitle1" className={Styles.navyBlueColorForce}>
            Quick Actions
          </Typography>
        </Grid>
        <Grid container item xs={2} justifyContent="flex-end">
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              setTabDrawer(false);
            }}
          />
        </Grid>
      </Grid>
      <Divider />
      <List>
        {[
          "Request Profile Update",
          "Schedule Appointment",
          "Send Document",
          "Request Document",
          "Send Message",
          "Live Meeting",
        ].map((text, index) => (
          <ListItem key={index} onClick={(event) => console.log(text)}>
            <ListItemText
              primary={text}
              className={Styles.navyBlueColorForce}
              style={{ fontSize: 14, cursor: "pointer" }}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const renderTabView = () => {
    let selectedTabView;

    switch (selectedTab) {
      case "Personal":
        selectedTabView = (
          <Grid item container xs={12}>
            <PersonalDetailsTab
              getPersonalData={getPersonalData}
              coApplicant={coApplicant}
              currentAddress={currentAddress}
              primaryApplicant={primaryApplicant}
              coApplicantSupport={coApplicantSupport}
            />
          </Grid>
        );
        return selectedTabView;
      case "Documents":
        selectedTabView = (
          <Grid item container xs={12}>
            <DocumentsTab userId={userId} />
          </Grid>
        );
        return selectedTabView;
      case "Demographic":
        selectedTabView = (
          <Grid item container xs={12}>
            <DemographicsDataTab />
          </Grid>
        );
        return selectedTabView;
      case "Client Hub":
        selectedTabView = (
          <Grid item container xs={12}>
            <ClientsHubTab
              clientFirstName={clientFirstName}
              primaryApplicant={primaryApplicant}
            />
          </Grid>
        );
        return selectedTabView;
    }
  };

  return (
    <Grid container>
      <SwipeableDrawer
        classes={{ modal: classes.modal, paper: classes.paper }}
        anchor="bottom"
        open={tabDrawer || quickActionDrawer}
        onClose={() => {
          setTabDrawer(false);
          // setQuickActionDrawer(false);
        }}
        onOpen={() => {
          setTabDrawer(true);
          // setQuickActionDrawer(true);
        }}
      >
        {tabDrawer && moreActionsList()}
        {/* {quickActionDrawer && moreQuickActionsList()} */}
      </SwipeableDrawer>
      <SPeSignWizard
        openEsignWizard={openEsignWizard}
        setOpenEsignWizard={setOpenEsignWizard}
        updateIntercomStatus={updateIntercomStatus}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
        setDocumentTabName={setDocumentTabName}
        documentTabName={documentTabName}
      />
      <SPUploadWizard
        openUploadWizard={openUploadWizard}
        setOpenUploadWizard={setOpenUploadWizard}
        categories={categories}
        updateIntercomStatus={updateIntercomStatus}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
        setDocumentTabName={setDocumentTabName}
        documentTabName={documentTabName}
        setDocumentTab={setDocumentTab}
      />
      <MoreOptionsOnClientInfo
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        showProfileUpdateOption={true}
        showScheduleAppointmentOption={true}
        showSendMessageOption={true}
        showLiveMeetingOption={true}
        email={primaryApplicant.email}
        // fromServiceProviderId={data.storefrontDetails.serviceProviderId}
        toUserId={userId}
        serviceRequestId={serviceRequestId}
        isClientDetailsPage={true}
        setOpenAssignClientDialog={setOpenAssignClientDialog}
        assignClientDetails={assignClientDetails}
        nonPremiumTeamMembersCheck={nonPremiumTeamMembersCheck}
        isAdmin={isAdmin}
      />
      <AssignClientDialog
        openAssignClientDialog={openAssignClientDialog}
        setOpenAssignClientDialog={setOpenAssignClientDialog}
        assignClientDetails={assignClientDetails}
        tagMembers={tagMembers}
        serviceRequestId={serviceRequestId}
      />
      {!isMobileDevice() && (
        <Grid item xs={12}>
          <ButtonBase
            onClick={() => {
              history.push("/serviceprovider/clients");
            }}
          >
            <Typography variant="body1" className={Styles.navyBlueColor}>
              Clients/{primaryApplicant && primaryApplicant.fullName}
            </Typography>
          </ButtonBase>
        </Grid>
      )}
      <Grid container item xs={12} md={12}>
        {!isMobileDevice() && (
          <React.Fragment>
            <Grid item xs={12} md={6} lg={4}>
              <Typography variant="h4" className={Styles.navyBlueColor}>
                {primaryApplicant && primaryApplicant.fullName}
              </Typography>
            </Grid>

            <Grid item container xs={6} lg={8} justifyContent="flex-end">
              {nonPremiumTeamMembersCheck &&
              assignClientDetails?.assignClient &&
              (userInfo.userType === SERVICE_PROVIDER_USER || isAdmin()) ? (
                <Button
                  fullWidth={isTabletDevice() ? true : false}
                  variant="outlined"
                  style={{
                    textTransform: "none",
                    paddingLeft: isTabletDevice() ? 32 : 24,
                    paddingRight: 24,
                    marginRight: 16,
                  }}
                  onClick={() => setOpenAssignClientDialog(true)}
                >
                  Assign Client
                </Button>
              ) : null}
              <input
                style={{ display: "none" }}
                id="contained-button-file"
                multiple
                type="file"
                onChange={onUploadFileChange}
              />
              <label htmlFor="contained-button-file">
                <Button
                  fullWidth={isTabletDevice() ? true : false}
                  variant="outlined"
                  style={{
                    textTransform: "none",
                    paddingLeft: isTabletDevice() ? 32 : 24,
                    paddingRight: 24,
                    marginRight: 16,
                  }}
                  component="span"
                >
                  Upload Documents
                </Button>
              </label>
              <input
                style={{ display: "none" }}
                id="contained-button-file-esign"
                multiple
                type="file"
                onChange={onUploadEsignFileChange}
              />
              <label htmlFor="contained-button-file-esign">
                <Button
                  fullWidth={isTabletDevice() ? true : false}
                  style={{
                    textTransform: "none",
                    paddingLeft: 24,
                    paddingRight: 24,
                    marginTop: isTabletDevice() && 10,
                  }}
                  className={Styles.primaryBtnAccent}
                  component="span"
                >
                  Send eSign Documents
                </Button>
              </label>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
      <Grid
        container
        item
        xs={12}
        style={{ marginTop: !isMobileDevice() && 20 }}
      >
        <Grid
          container={isMobileDevice() || isTabletDevice() ? true : false}
          item
          xs={12}
          lg={3}
          justifyContent={
            (isMobileDevice() || isTabletDevice()) && "space-between"
          }
        >
          <Grid item xs={3} md={1}>
            <Avatar
              style={{
                height: isMobileDevice() || isTabletDevice() ? 80 : 170,
                width: isMobileDevice() || isTabletDevice() ? 80 : 170,
                marginTop: isMobileDevice() && 20,
              }}
              variant="square"
              src={clientProfileImage}
            >
              {(primaryApplicant.fullName || "").charAt(0)}
            </Avatar>
          </Grid>
          <Grid
            item
            xs={8}
            md={10}
            lg={12}
            container={isMobileDevice() || isTabletDevice() ? true : false}
          >
            {isMobileDevice() && (
              <Grid item container xs={12} alignItems="center">
                <Grid item xs={10}>
                  <Typography
                    variant="subtitle1"
                    className={Styles.navyBlueColor}
                  >
                    {primaryApplicant && primaryApplicant.fullName}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <ButtonBase>
                    <MoreHorizIcon
                      onClick={handleClick}
                      style={{ padding: "12px 16px 12px 12px" }}
                      className={Styles.navyBlueColor}
                    />
                  </ButtonBase>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography className={Styles.navyBlueColor} variant="body1">
                On Souqh since
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={Styles.navyBlueColor} variant="body1">
                {moment(onSouqhSince).format("MMM DD, YYYY")}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.marginTop20}>
            <Typography className={Styles.navyBlueColor} variant="subtitle1">
              Service Providers
            </Typography>
          </Grid>

          <Grid container item xs={12} className={classes.marginTop10}>
            {activeSps && activeSps.length ? (
              activeSps.map((activeSp, index) => {
                const storeFrontContent = JSON.parse(
                  activeSp.storeFrontContent
                );
                return (
                  <Grid
                    key={index}
                    container
                    item
                    xs={2}
                    md={1}
                    lg={12}
                    style={{
                      marginTop: 10,
                      flexWrap:
                        !isMobileDevice() && !isTabletDevice() && "nowrap",
                    }}
                  >
                    <Grid item xs={12} md={2} lg={2}>
                      <img
                        alt=""
                        src={
                          storeFrontContent.displayPicture || "/images/logo.svg"
                        }
                        style={{ height: 32, width: 32 }}
                      />
                    </Grid>
                    {!isMobileDevice() && !isTabletDevice() && (
                      <React.Fragment>
                        <Grid item lg={7}>
                          <Tooltip title={activeSp.storeFrontName}>
                            <Link
                              key={index}
                              to={{
                                pathname:
                                  window.location.origin +
                                  `/storefront/${activeSp.storeFrontRouteName}`,
                              }}
                              target="_blank"
                              className={clsx(
                                Styles.sqEllipsisOneLine,
                                Styles.navyBlueColor
                              )}
                            >
                              {activeSp.storeFrontName}
                            </Link>
                          </Tooltip>
                          <Typography
                            className={Styles.navyBlueColor}
                            style={{ fontSize: 10 }}
                          >
                            {activeSp.businessTypes &&
                            activeSp.businessTypes.length
                              ? activeSp.businessTypes[0].name === "Other"
                                ? activeSp.businessTypes[0].otherBusinessName
                                : activeSp.businessTypes[0].shortName
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid item lg={1} style={{ marginLeft: 8 }}>
                          <Tooltip
                            title={
                              formatContactNumber(activeSp.contactNumber) || ""
                            }
                          >
                            <CallIcon
                              style={{ fontSize: 16 }}
                              className={Styles.navyBlueColor}
                              onClick={(event) => {
                                event.stopPropagation();
                                if (isMobileDevice()) {
                                  activeSp.contactNumber &&
                                    window.open(
                                      `tel:${activeSp.contactNumber}`,
                                      "_self"
                                    );
                                }
                              }}
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item lg={1} style={{ marginLeft: 4 }}>
                          <MessageIcon
                            style={{ cursor: "pointer", fontSize: 16 }}
                            className={Styles.navyBlueColor}
                            onClick={(event) => {
                              event.stopPropagation();
                              navigateMessage(event, activeSp.email);
                            }}
                          />
                        </Grid>
                      </React.Fragment>
                    )}
                  </Grid>
                );
              })
            ) : (
              <Typography className={Styles.navyBlueColor} variant="body1">
                No data available
              </Typography>
            )}
          </Grid>
        </Grid>
        {!isMobileDevice() && (
          <Grid item xs={9} sm={9} lg={9} md={12}>
            <Paper
              elevation={0}
              style={{ display: "flex", marginTop: isTabletDevice() && 10 }}
            >
              <Tabs
                value={tabValue}
                onChange={(event, newValue) => {
                  setTabValue(newValue);
                }}
                aria-label="client-details-tabs"
                style={{ width: "95%" }}
                classes={{ indicator: Styles.indicator }}
              >
                {CLIENT_DETAILS_TAB_NAMES.map((tab, index) => (
                  <Tab
                    key={index}
                    label={`${tab}`}
                    className={clsx(classes.tabs, {
                      [Styles.primaryTxtAccentForce]: index === tabValue,
                      [Styles.navyBlueColorForce]: index !== tabValue,
                    })}
                  />
                ))}
              </Tabs>
              <ButtonBase>
                <MoreHorizIcon
                  onClick={handleClick}
                  style={{ padding: "12px 16px 12px 12px" }}
                  className={Styles.navyBlueColor}
                />
              </ButtonBase>
            </Paper>
            <TabPanel
              value={tabValue}
              index={0}
              className={classes.marginTop10}
            >
              <PersonalDetailsTab
                getPersonalData={getPersonalData}
                coApplicant={coApplicant}
                currentAddress={currentAddress}
                primaryApplicant={primaryApplicant}
                coApplicantSupport={coApplicantSupport}
              />
            </TabPanel>

            <TabPanel
              value={tabValue}
              index={1}
              className={classes.marginTop10}
            >
              <DocumentsTab userId={userId} />
            </TabPanel>

            <TabPanel
              value={tabValue}
              index={2}
              className={classes.marginTop10}
            >
              <DemographicsDataTab />
            </TabPanel>

            <TabPanel
              value={tabValue}
              index={3}
              className={classes.marginTop10}
            >
              <ClientsHubTab
                clientFirstName={clientFirstName}
                primaryApplicant={primaryApplicant}
                serviceRequestId={serviceRequestId}
              />
            </TabPanel>
          </Grid>
        )}

        {isMobileDevice() && (
          <Grid item container xs={12}>
            <Grid item container xs={12}>
              <Button
                fullWidth
                variant="outlined"
                className={Styles.navyBlueColorForce}
                style={{ textTransform: "capitalize", marginTop: 12 }}
                onClick={() => setTabDrawer(true)}
                endIcon={<ExpandMoreIcon />}
              >
                {selectedTab}
              </Button>
            </Grid>
            <Grid item container xs={12} style={{ marginTop: 16 }}>
              {renderTabView()}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
