import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useLocationServed } from "souqh-react-redux-hooks/useLocationServed";
import NewCampaignStep3 from "./NewCampaignStep3";
import Styles from "../../../Styles.module.css";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 900,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    padding: "0 1.5rem 0.5rem",
  },
}));

const DialogTitle = (props) => {
  const { children, classes, setMCampDetailsDialog, handleClose, ...other } =
    props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
      style={{ backgroundColor: "transparent" }}
    >
      <Typography variant="subtitle1">{children}</Typography>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={() => {
          setMCampDetailsDialog(false);
          handleClose();
        }}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
};

function MCampDetails({
  getMarketingCampaignData,
  selelctedRow,
  openMCampDetailsDialog,
  setMCampDetailsDialog,
  handleClose,
  getMarketingCampaignInvoiceData,
}) {
  const { campaignData } = useSelector((state) => state.marketingCampaign);
  const { locationsServed } = useLocationServed();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (openMCampDetailsDialog && campaignData.name)
  //     getMarketingCampaignInvoiceData();
  // }, [campaignData.name, openMCampDetailsDialog]);

  useEffect(() => {
    dispatch({ type: "SET_LOADING", value: true });
    if (campaignData) {
      dispatch({ type: "SET_LOADING", value: false });
    }
  }, [campaignData]);
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      open={openMCampDetailsDialog}
      classes={{ paper: classes.dialogPaper }}
      maxWidth={"lg"}
    >
      <DialogTitle
        classes={classes}
        className={Styles.navyBlueColorForce}
        setMCampDetailsDialog={setMCampDetailsDialog}
        handleClose={handleClose}
      >
        Campaign Details
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <NewCampaignStep3
          section={3}
          style={{ marginTop: "-50px" }}
          campaignData={campaignData}
          locationsServed={locationsServed}
        />
      </DialogContent>
    </Dialog>
  );
}

export default MCampDetails;
