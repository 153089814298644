import {
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import { filter } from "lodash";
import { useTheme } from "souqh-react-redux-hooks/serviceProvider/storefront/useTheme";
import Styles from "../../../Styles.module.css";
import StarsIcon from "@material-ui/icons/Stars";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "12px 0px",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
}));

function Address({
  disabled,
  index,
  title,
  streetNumber,
  streetName,
  province,
  city,
  county,
  postalCode,
  isPrimary,
  saveAddressField,
  setPrimary,
  deleteAddress,
  provinces,
  cities,
}) {
  const classes = useStyles();
  const { primaryColor } = useTheme();

  return (
    <Grid item className={classes.root}>
      {setPrimary && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ padding: "0px 26px 10px 23px" }}
        >
          <Grid container item xs={6}>
            <TextField
              variant="outlined"
              onChange={(e) => {
                saveAddressField({
                  field: "title",
                  value: e.target.value,
                  index,
                });
              }}
              value={title}
              fullWidth
              aria-label="Address Title Input"
              id={"address-title-for-" + index}
              label="Address Title"
              required
              inputProps={{
                maxLength: 100,
              }}
            />
          </Grid>
          {!isPrimary && (
            <Grid>
              <IconButton
                disabled={isPrimary}
                color="default"
                style={{ color: primaryColor }}
                aria-label="Delete Address"
                component="span"
                onClick={() => {
                  deleteAddress(index);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      )}
      <Divider />
      <Grid container item xs={12} style={{ padding: "10px 26px 10px 23px" }}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <TextField
              disabled={!!disabled}
              variant="outlined"
              onChange={(e) => {
                saveAddressField({
                  field: "streetNumber",
                  value: e.target.value,
                  index,
                });
              }}
              value={streetNumber}
              fullWidth
              required
              aria-label="Stree number Input"
              id={"stree-number-for-" + index}
              label="Address Line 1"
              inputProps={{
                maxLength: 100,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled={!!disabled}
              variant="outlined"
              onChange={(e) => {
                saveAddressField({
                  field: "streetName",
                  value: e.target.value,
                  index,
                });
              }}
              value={streetName}
              fullWidth
              aria-label="Street Name Input"
              id={"stree-name-for-" + index}
              label="Address Line 2 (optional)"
              // required
              inputProps={{
                maxLength: 100,
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <Autocomplete
              disabled={!!disabled}
              value={province || ""}
              fullWidth
              onChange={(e, value) => {
                saveAddressField({
                  field: "province",
                  value: value,
                  index,
                });
              }}
              id={"province-for-" + index}
              options={provinces || []}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => {
                return option.name === value.name;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Province"
                  required
                  variant="outlined"
                />
              )}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              disabled={!!disabled}
              value={city}
              fullWidth
              onChange={(e, value) => {
                saveAddressField({
                  field: "city",
                  value: value,
                  index,
                });
              }}
              noOptionsText={"Please select your official municipality"}
              id={"city-for-" + index}
              options={
                province && province.cityDTO
                  ? filter(
                      // Remove All cities option as it is single select.
                      province.cityDTO,
                      (city) => city.name !== "All Cities"
                    )
                  : []
              }
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => {
                return option.name === value.name;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="City"
                  required
                  variant="outlined"
                />
              )}
              required
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <TextField
              disabled={!!disabled}
              variant="outlined"
              onChange={(e) => {
                saveAddressField({
                  field: "county",
                  value: e.target.value,
                  index,
                  index,
                });
              }}
              value={county}
              fullWidth
              aria-label="County Input"
              id={"county-for-" + index}
              label="Country"
              inputProps={{
                maxLength: 256,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled={!!disabled}
              variant="outlined"
              onChange={(e) => {
                saveAddressField({
                  field: "postalCode",
                  value: e.target.value,
                  index,
                });
              }}
              value={postalCode}
              fullWidth
              aria-label="Postal Code Input"
              id={"postal-code-for-" + index}
              label="Postal Code"
              required
              inputProps={{
                maxLength: 15,
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent="flex-end">
          {setPrimary &&
            (isPrimary ? (
              <Typography
                variant="caption"
                className={Styles.navyBlueColor}
                style={{ paddingRight: 8 }}
              >
                <StarsIcon
                  style={{
                    fontSize: 16,
                    marginBottom: -4,
                    marginRight: 4,
                  }}
                  className={Styles.navyBlueColor}
                />
                <span>This is your Primary Address</span>
              </Typography>
            ) : (
              <FormControlLabel
                style={{ paddingRight: 16 }}
                value={isPrimary}
                control={
                  <Switch
                    size="small"
                    className={"sqGraySwitch"}
                    disabled={isPrimary}
                    checked={isPrimary}
                    onChange={(e) => {
                      setPrimary(index, e.target.checked);
                    }}
                  />
                }
                label={
                  <Typography
                    variant="caption"
                    className={Styles.navyBlueColor}
                  >
                    Primary Address
                  </Typography>
                }
                labelPlacement="start"
              />
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Address;
