import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import Styles from "../../Styles.module.css";
import RemoveMemberDialog from "./RemoveMemberDialog";
import EditableTeamMemberRow from "./EditableTeamMemberRow";
import NonEditableTeamMemberRow from "./NonEditableTeamMemberRow";

export default function CommonStorefront({
  editTeamMember,
  memberList,
  tab,
  resendInviteMail,
  removeTeamMember,
  getTagMembers,
  updateTeamMember,
}) {
  const [openRemoveMemberDialog, setOpenRemoveMemberDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editRow, setEditRow] = useState("");

  return (
    <React.Fragment>
      <RemoveMemberDialog
        openRemoveMemberDialog={openRemoveMemberDialog}
        setOpenRemoveMemberDialog={setOpenRemoveMemberDialog}
        removeTeamMember={removeTeamMember}
        selectedUser={selectedUser}
        getTagMembers={getTagMembers}
      />
      {memberList && memberList.length ? (
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ width: "20%" }}
                  className={Styles.navyBlueColorForce}
                >
                  <Typography style={{ fontWeight: 600 }}>Members</Typography>
                </TableCell>
                <TableCell
                  style={{ width: tab === "pendingMembers" ? "21%" : "15%" }}
                  className={Styles.navyBlueColorForce}
                >
                  <Typography style={{ fontWeight: 600 }}>Role</Typography>
                </TableCell>
                <TableCell
                  style={{ width: "8%" }}
                  className={Styles.navyBlueColorForce}
                >
                  <Typography style={{ fontWeight: 600 }}>Plan</Typography>
                </TableCell>
                <TableCell
                  style={{ width: "15%" }}
                  className={Styles.navyBlueColorForce}
                >
                  <Typography style={{ fontWeight: 600 }}>
                    Billing Cycle
                  </Typography>
                </TableCell>
                {tab !== "pendingMembers" ? (
                  <TableCell
                    style={{ width: "8%" }}
                    className={Styles.navyBlueColorForce}
                  >
                    <Typography style={{ fontWeight: 600 }}>2FA</Typography>
                  </TableCell>
                ) : (
                  <TableCell />
                )}
                {tab !== "pendingMembers" ? (
                  <TableCell
                    style={{ width: "20%" }}
                    className={Styles.navyBlueColorForce}
                  >
                    <Typography style={{ fontWeight: 600 }}>
                      Last Login
                    </Typography>
                  </TableCell>
                ) : (
                  <TableCell />
                )}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {memberList.map((data, index) =>
                data.userId === editRow ? (
                  <EditableTeamMemberRow
                    data={data}
                    // updateTeamMember={(data) => {
                    //   updateTeamMember(data, () => {
                    //     getTagMembers();
                    //   });
                    //   setEditRow("");
                    // }}
                    updateTeamMember={updateTeamMember}
                    setEditRow={setEditRow}
                    getTagMembers={getTagMembers}
                    key={index}
                  />
                ) : (
                  <NonEditableTeamMemberRow
                    data={data}
                    editTeamMember={(id) =>
                      setEditRow(tab === "individualSF" ? "" : id)
                    }
                    setOpenRemoveMemberDialog={setOpenRemoveMemberDialog}
                    key={index}
                    tab={tab}
                    resendInviteMail={resendInviteMail}
                    setSelectedUser={setSelectedUser}
                  />
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography className={Styles.navyBlueColor}>
          No data available
        </Typography>
      )}
    </React.Fragment>
  );
}
