import { useDispatch, useSelector } from "react-redux";
import {
  ADD_BROADCAST_SERVICE_REQUEST_DOCUMENTS,
  DELETE_BROADCAST_SERVICE_REQUEST_DOCUMENT,
} from "souqh-redux/reducers/actionConstants";
import { color_error, color_success } from "../toasterColors";
import { useActions } from "../useActions";
import { useApiClient } from "../useApiClient";
import { useAuthUser } from "../useLogin";
import { useValidations } from "../useValidations";
import { useState, useCallback, useEffect } from "react";
import {
  emailValidationMeta,
  mobileNumberValidationMeta,
  TMFullnameValidationMeta,
} from "../validationMetas";

const actionCreators = {
  addFiles: (payload) => ({
    type: ADD_BROADCAST_SERVICE_REQUEST_DOCUMENTS,
    payload,
  }),
  deleteFile: (payload) => ({
    type: DELETE_BROADCAST_SERVICE_REQUEST_DOCUMENT,
    payload,
  }),
};

const validationMeta = [
  TMFullnameValidationMeta,
  emailValidationMeta,
  mobileNumberValidationMeta,
];

export const useBroadcastServiceRequest = () => {
  const { apiClient } = useApiClient();
  const userInfo = useAuthUser();
  const dispatch = useDispatch();

  const [matchingSPCount, setMatchingSPCount] = useState(null);
  const [matchingSPEmails, setMatchingSPEmails] = useState([]);

  const actions = useActions(actionCreators);

  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");

  const { broadcastServiceRequestDocuments } = useSelector(
    (state) => state.homeBuyer
  );

  const getFormValues = useCallback(() => {
    return { name, email, phoneNumber };
  }, [name, email, phoneNumber]);

  const getServerKeysMap = useCallback(() => {
    return {
      contactNumber: "phoneNumber",
      name: "name",
    };
  }, []);
  const { validations, addValidations, clearAllValidations } = useValidations(
    validationMeta,
    getFormValues,
    getServerKeysMap
  );

  const createBroadcastService = (
    searchedCategory,
    searchedCity,
    timelineOption,
    description,
    rentalForNewComersDetails,
    onResult
  ) => {
    const formData = new FormData();
    let data = {
      businessType: searchedCategory.name,
      city: searchedCity.cityDTO[0].name,
      description: description,
      homeBuyerId: userInfo.homeBuyerId,
      // "id": 0, -> No need to pass this
      provinceId: searchedCity.id,
      // "serviceProviderId": 0, No need pass this
      // "status": "ACTIVE" -> No need to pass , by default ACTIVE
      timeLine: timelineOption.value,
      rentalForNewComersDetails: rentalForNewComersDetails,
    };
    formData.append(
      "broadcastServiceRequest",
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      })
    );

    broadcastServiceRequestDocuments.forEach((file) => {
      formData.append("attachments", file, file.name);
    });
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post(`/broadcast-service-request`, formData)
      .then((res) => {
        if (res.data.status === 200) {
          dispatch({
            type: "SET_TOASTER",
            payload: {
              message: res.data.message,
              bgColor: color_success,
              open: true,
            },
          });
        } else if (res.data.status === 400) {
          dispatch({
            type: "SET_TOASTER",
            payload: {
              message: res.data.message,
              bgColor: color_error,
              open: true,
            },
          });
        }
        onResult && onResult(res);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const updateBroadcastServiceRequestStatus = (requestId, status, onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .put(
        `/broadcast-service-request/request-id/${requestId}/status/${status}`
      )
      .then((res) => {
        if (res.data.status === 200) {
          dispatch({
            type: "SET_TOASTER",
            payload: {
              message: res.data.message,
              bgColor: color_success,
              open: true,
            },
          });
        }
        onResult && onResult(res);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getAcceptedSPsForBroadcastRequest = (
    broadcastServiceRequestId,
    homeBuyerId,
    onResult
  ) => {
    if (broadcastServiceRequestId && homeBuyerId) {
      dispatch({ type: "SET_LOADING", value: true });
      return apiClient
        .get(
          `/broadcast-service-request/${broadcastServiceRequestId}/home-buyer/${homeBuyerId}`
        )
        .then((res) => {
          if (res.data.status === 200) {
            console.log(res.data);
          }
          onResult && onResult(res);
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const getMatchingServiceProviderDetails = (
    searchedCategory,
    searchedCity,
    onResult
  ) => {
    dispatch({ type: "SET_LOADING", value: true });
    if (searchedCategory && searchedCity) {
      return apiClient
        .get(
          `broadcast-service-request/matching-service-requests?businessType=${searchedCategory.name}&cityName=${searchedCity.cityDTO[0].name}&provinceId=${searchedCity.id}`
        )
        .then((res) => {
          if (res.data.status === 200) {
            setMatchingSPCount(res.data.result.matchedSPCount);
            setMatchingSPEmails(res.data.result.matchedSpEmails);
          }
          onResult && onResult(res);
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  return {
    createBroadcastService,
    actions,
    broadcastServiceRequestDocuments,
    updateBroadcastServiceRequestStatus,
    getAcceptedSPsForBroadcastRequest,
    validations,
    phoneNumber,
    setPhoneNumber,
    email,
    setEmail,
    name,
    setName,
    getMatchingServiceProviderDetails,
    matchingSPCount,
    matchingSPEmails,
    setMatchingSPEmails
  };
};
