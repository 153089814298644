import { Grid, Button, Typography, Link } from "@material-ui/core";
import React from "react";
import Styles from "../../Styles.module.css";
import { isMarketplacePlan } from "../../utils/AppUtils";

export default function HelpCenterWidget({
  openPlanUpgradeMsgDialog,
  setOpenPlanUpgradeMsgDialog,
  userInfo,
}) {
  return (
    <Grid container item xs={12} alignItems="center">
      <Grid container item xs={12} justifyContent="center">
        <img
          src="/images/Help-Center.svg"
          style={{ height: 175, width: 175 }}
        />
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Typography
          style={{ fontWeight: 600 }}
          className={Styles.navyBlueColor}
        >
          We are here for you!
        </Typography>
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Link
          // href="http://help.souqh.ca/"
          onClick={() => {
            if (isMarketplacePlan(userInfo)) {
              setOpenPlanUpgradeMsgDialog(true);
            } else {
              window.open("http://help.souqh.ca/", "_blank");
            }
          }}
          underline="none"
          target="_blank"
          rel="noreferrer"
          style={{ color: "#0e1c4e" }}
        >
          <Button
            variant="contained"
            className={Styles.primaryBtnAccent}
            style={{
              textTransform: "capitalize",
            }}
          >
            Help Center
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
}
