import { cloneDeep } from "lodash";
import {
  SET_CO_APPLICANT,
  SAVE_CO_APPLICANT,
  UPDATE_CURRENT_ADDRESS,
  SAVE_CURRENT_ADDRESS,
  EDIT_CURRENT_ADDRESS,
  RESET_CURRENT_ADDRESS,
  UPDATE_PRIMARY_APPLICANT,
  UPDATE_PROFILE_PHOTO,
  UPDATE_NEW_HOME_PROFILE,
  SAVE_NEW_HOME_PROFILE,
  EDIT_NEW_HOME_PROFILE,
  RESET_NEW_HOME_PROFILE,
  UPDATE_DEMOGRAPHIC_DATA,
  SET_HB_PERSONAL_INFO,
  SET_NEW_HOME_ADDRESS,
  SET_DEMOGRAPHIC_DATA,
  UPDATE_DOCUMENT_LIST,
  SET_DOCUMENTS_DATA,
  SET_MAX_FILE_SIZE,
  DELETE_PROFILE_DOC,
} from "../../souqh-redux/reducers/actionConstants";

const defaultAddress = {
  streetName: "",
  streetNumber: "",
  province: null,
  county: "Canada",
  postalCode: "",
};
const initialState = {
  profilePhoto: "",
  primaryApplicant: {
    fullName: "",
    email: "",
    phoneNumber: "",
    maritalStatus: null,
  },
  coApplicantSupport: false,
  coApplicant: {
    fullName: "",
    email: null,
  },
  currentAddress: {
    ...defaultAddress,
  },
  newHomeProfile: {
    newHomeAddress: { ...defaultAddress },
    ...defaultAddress,
    propertyType: null,
    closingDate: "",
    firstTimeHomeBuyer: false,
  },
  demographicData: {
    employment: null,
    industry: null,
    ageGroup: null,
    maritalStatus: "",
    householdIncomeRange: "",
    propertyValue: "",
  },
  documentList: {
    DRIVERS_LICENSE: [],
    OTHER_GOVERNMENT_ISSUED_PHOTO_ID: [],
    LETTER_OF_EMPLOYMENT: [],
    PAY_SLIPS: [],
    T4_FORM: [],
    NOTICE_OF_ASSESSMENT_FROM_TAX_RETURN: [],
    OFFICIAL_BANK_STATEMENT_RRSP: [],
    SELF_EMPLOYED_CORPORATE_TAX: [],
  },
};

const hbSetup = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case "SET_PROPERTY_VALUES":
      newState = cloneDeep(state);
      newState.propertyValues = action.payload;
      return newState;
    case "SET_PROPERTY_TYPES":
      newState = cloneDeep(state);
      newState.propertyTypes = action.payload;
      return newState;
    case "SET_INDUSTRY_TYPES":
      newState = cloneDeep(state);
      newState.industryTypes = action.payload;
      return newState;
    case "SET_HOUSEHOLD_INCOME_RANGE":
      newState = cloneDeep(state);
      newState.householdIncomeRange = action.payload;
      return newState;
    case UPDATE_PROFILE_PHOTO:
      newState = cloneDeep(state);
      newState.profilePhoto = action.payload;
      return newState;
    case UPDATE_PRIMARY_APPLICANT:
      newState = cloneDeep(state);
      newState.primaryApplicant = {
        ...newState.primaryApplicant,
        ...action.payload,
      };
      return newState;
    case SET_CO_APPLICANT:
      newState = cloneDeep(state);
      newState.coApplicantSupport = action.payload;
      return newState;
    case SAVE_CO_APPLICANT:
      newState = cloneDeep(state);
      newState.coApplicant = { ...newState.coApplicant, ...action.payload };
      return newState;
    case UPDATE_CURRENT_ADDRESS:
      newState = cloneDeep(state);
      newState.currentAddress = {
        ...newState.currentAddress,
        ...action.payload,
      };
      return newState;
    case SAVE_CURRENT_ADDRESS:
      newState = cloneDeep(state);
      newState.currentAddress.editMode = true;
      return newState;
    case EDIT_CURRENT_ADDRESS:
      newState = cloneDeep(state);
      newState.currentAddress.editMode = false;
      return newState;
    case RESET_CURRENT_ADDRESS:
      newState = cloneDeep(state);
      Object.keys(newState.currentAddress).map(
        (key) => (newState.currentAddress[key] = "")
      );
      return newState;
    case UPDATE_NEW_HOME_PROFILE:
      newState = cloneDeep(state);
      newState.newHomeProfile = {
        ...newState.newHomeProfile,
        ...action.payload,
      };
      return newState;
    case SAVE_NEW_HOME_PROFILE:
      newState = cloneDeep(state);
      newState.newHomeProfile.editMode = true;
      return newState;
    case EDIT_NEW_HOME_PROFILE:
      newState = cloneDeep(state);
      newState.newHomeProfile.editMode = false;
      return newState;
    case RESET_NEW_HOME_PROFILE:
      newState = cloneDeep(state);
      Object.keys(newState.newHomeProfile).map(
        (key) => (newState.newHomeProfile[key] = "")
      );
      return newState;
    case UPDATE_DEMOGRAPHIC_DATA:
      newState = cloneDeep(state);
      newState.demographicData = {
        ...newState.demographicData,
        ...action.payload,
      };
      return newState;
    case SET_HB_PERSONAL_INFO:
      newState = cloneDeep(state);
      newState = {
        ...newState,
        ...action.payload,
      };
      return newState;
    case SET_NEW_HOME_ADDRESS:
      newState = cloneDeep(state);
      newState.newHomeProfile = {
        ...newState.newHomeProfile,
        ...action.payload,
      };
      return newState;
    case SET_DEMOGRAPHIC_DATA:
      newState = cloneDeep(state);
      newState.demographicData = {
        ...action.payload,
      };
      return newState;
    case UPDATE_DOCUMENT_LIST:
      newState = cloneDeep(state);
      const { field, value, limit } = action.payload;
      const dList = newState.documentList;
      Object.keys(value).map((key) => {
        if ((value[key].size / 1024 / 1024).toFixed(2) > 15) {
          newState.maxFileSize = true;
        } else {
          dList[field].push(value[key]);
        }
      });
      return newState;
    case SET_DOCUMENTS_DATA:
      newState = cloneDeep(state);
      newState.documentList = {
        ...newState.documentList,
        ...action.payload.documentChecklist,
      };
      return newState;
    case SET_MAX_FILE_SIZE:
      newState = cloneDeep(state);
      newState.maxFileSize = action.payload;
      return newState;
    case DELETE_PROFILE_DOC:
      newState = cloneDeep(state);
      const { field: dField, index } = action.payload;
      const docList = newState.documentList;
      docList[dField].map((fileObj, i) => {
        if (i === index) {
          docList[dField].splice(index, 1);
        }
      });
      return newState;
    default:
      return state;
  }
};
export default hbSetup;
