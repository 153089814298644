const chimeConfigs = {
  DEV: {
    cognitoUserPoolId: "us-east-1_1NfI0XCNI",
    cognitoAppClientId: "4omicoggraslnij2o32jle7u04",
    cognitoIdentityPoolId: "us-east-1:1fdd2bd8-9fe5-466f-9d78-3703bc7d8507",
    appInstanceArn:
      "arn:aws:chime:us-east-1:283624750762:app-instance/2aaf4177-668f-4120-a94f-fa1b7ad62f9e",
    region: "us-east-1",
    attachments_s3_bucket_name:
      "souqh-dev-chime-app-chatattachmentsbucket-1xbhclphm3va6",
  },
  QA: {
    cognitoUserPoolId: "us-east-1_j4qTm2S9f",
    cognitoAppClientId: "52oc39qblku7phoeiti8dqrooq",
    cognitoIdentityPoolId: "us-east-1:a3fd71a1-ca61-4505-a0de-ef69578b13e8",
    appInstanceArn:
      "arn:aws:chime:us-east-1:626396063337:app-instance/76ed2285-2f10-4970-bf89-d7a30fb40c3f",
    region: "us-east-1",
    attachments_s3_bucket_name:
      "south-demo-chime-chatattachmentsbucket-1316fk0fkws5n",
  },
  PROD: {
    cognitoUserPoolId: "us-east-1_ebx0IV4c7",
    cognitoAppClientId: "5bpstbpbb17lrkbd12osh15nab",
    cognitoIdentityPoolId: "us-east-1:5e5dbdd1-6c29-44ab-86c3-a5c39c4278d0",
    appInstanceArn:
      "arn:aws:chime:us-east-1:203404015345:app-instance/2a8ea475-0eba-4d58-9e34-f14ba2dcfc05",
    region: "us-east-1",
    attachments_s3_bucket_name:
      "souqh-chime-app-chatattachmentsbucket-1l75hmfou5swk",
  },
};
const envName = process.env.REACT_APP_ENV_NAME;
export const awsChimeConfigs = chimeConfigs[envName]
  ? chimeConfigs[envName]
  : chimeConfigs["DEV"];
