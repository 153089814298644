import React from "react";
import { Grid, Tooltip } from "@material-ui/core";

import ImageCropper from "./../../ImageCropper";
import { useDisplayPicture } from "souqh-react-redux-hooks/serviceProvider/storefront/useDisplayPicture";

function DisplayPicture() {
  const {
    displayPicture,
    actions: { setDisplayPicture },
  } = useDisplayPicture();
  return (
    <Tooltip title="Add a logo or profile image">
      <Grid container>
        <ImageCropper
          helperText1={"Please add your logo or profile image"}
          type="logo"
          image={displayPicture}
          cropWidth={320}
          cropHeight={320}
          placeholderImage="/images/logo.svg"
          onImageAvailalbe={(image) => {
            setDisplayPicture({ displayPicture: image });
          }}
        ></ImageCropper>
      </Grid>
    </Tooltip>
  );
}

export default DisplayPicture;
