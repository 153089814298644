import React, { useEffect } from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import {
  Typography,
  IconButton,
  Dialog,
  Grid,
  makeStyles,
  Button,
} from "@material-ui/core/";
import Styles from "../../../../Styles.module.css";
import clsx from "clsx";
import { useDocumentHistory } from "souqh-react-redux-hooks/serviceProvider/documents/useDocumentHistory";
import HistoryHeader from "./HistoryHeader";
import HistoryTable from "./HistoryTable";
import GetAppIcon from "@material-ui/icons/GetApp";

const styles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    padding: "16px 24px",
  },
  dialogPaper: {
    height: window.innerHeight - 20,
    borderRadius: 4,
  },
  diaogFooter: {
    padding: "12px 36px",
  },
  dialogRoot: {
    padding: 16,
  },
}));

const DialogTitle = (props) => {
  const {
    children,
    classes,
    setOpenDocumentHistoryDialog,
    setCurrentDocument,
    ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="subtitle1">{children}</Typography>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={() => {
          setCurrentDocument(null);
          setOpenDocumentHistoryDialog(false);
        }}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
};

export default function DocumentHistoryDialog({
  openDocumentHistoryDialog,
  setOpenDocumentHistoryDialog,
  updateIntercomStatus,
  currentDocument,
  setCurrentDocument,
}) {
  const classes = styles();
  const {
    historyData,
    setHistoryData,
    loadDocumentHistory,
    downloadDocumentHistory,
  } = useDocumentHistory();

  useEffect(() => {
    updateIntercomStatus && updateIntercomStatus(openDocumentHistoryDialog);
  }, [openDocumentHistoryDialog]);

  useEffect(() => {
    if (currentDocument) {
      loadDocumentHistory(currentDocument.signNowDocumentId);
    }
  }, [currentDocument]);

  const handleClose = () => {
    setCurrentDocument(null);
    setHistoryData(null);
  };

  return (
    <>
      <div>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          fullScreen
          maxWidth={"lg"}
          onClose={handleClose}
          open={openDocumentHistoryDialog}
          maxWidth="lg"
          classes={{ paper: classes.dialogPaper, root: classes.dialogRoot }}
        >
          <DialogTitle
            classes={classes}
            id="customized-dialog-title"
            className={Styles.navyBlueColor}
            setOpenDocumentHistoryDialog={setOpenDocumentHistoryDialog}
            setCurrentDocument={setCurrentDocument}
          >
            History
          </DialogTitle>
          <MuiDialogContent dividers className={classes.dialogContent}>
            {historyData ? (
              <Grid container style={{ height: "100%" }}>
                <Grid item xs={12} style={{ height: 80 }}>
                  <HistoryHeader historyData={historyData} />
                </Grid>
                <Grid item xs={12} style={{ height: "calc(100% - 70px)" }}>
                  <HistoryTable
                    documentHistoryList={historyData.documentHistoryList}
                  />
                </Grid>
              </Grid>
            ) : null}
          </MuiDialogContent>
          <MuiDialogActions className={classes.diaogFooter}>
            <Grid item container xs={12} style={{ justifyContent: "flex-end" }}>
              <Button
                variant="outlined"
                style={{
                  textTransform: "capitalize",
                  borderRadius: 4,
                  padding: "6px 16px",
                  marginRight: 16,
                }}
                className={clsx(Styles.navyBlueColorForce)}
                onClick={() => {
                  setCurrentDocument(null);
                  setOpenDocumentHistoryDialog(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                startIcon={<GetAppIcon style={{ fill: "#0e1c4e" }} />}
                style={{ textTransform: "capitalize", padding: "6px 20px" }}
                className={clsx(
                  Styles.navyBlueColorForce,
                  Styles.primaryBtnAccent
                )}
                onClick={() =>
                  downloadDocumentHistory(
                    currentDocument.signNowDocumentId,
                    () => {
                      setCurrentDocument(null);
                      setOpenDocumentHistoryDialog(false);
                    }
                  )
                }
              >
                Download document with history
              </Button>
            </Grid>
          </MuiDialogActions>
        </Dialog>
      </div>
    </>
  );
}
