import React from "react";
import { Grid, TextField, Paper } from "@material-ui/core";

export default function CoApplicant({
  coApplicant,
  saveCoApplicant,
  validations,
}) {
  return (
    <Grid item xs={12}>
      <Grid item xs={12}>
        <Paper elevation={0} style={{ padding: "16px 10px" }}>
          <Grid container item xs={12}>
            <TextField
              autoComplete="new-password"
              variant="outlined"
              fullWidth
              aria-label="Full Name Input"
              label="Full Name"
              required
              value={coApplicant.fullName}
              inputProps={{
                maxLength: 256,
              }}
              onChange={(event) => {
                saveCoApplicant({ fullName: event.target.value });
              }}
            />
          </Grid>
          <Grid container item xs={12}>
            <TextField
              autoComplete="new-password"
              error={!!validations.email}
              helperText={validations.email}
              variant="outlined"
              fullWidth
              aria-label="Email ID Input"
              label="Email ID"
              required
              value={coApplicant.email}
              inputProps={{
                maxLength: 256,
              }}
              onChange={(event) => {
                saveCoApplicant({ email: event.target.value });
              }}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
