import React, { useEffect } from "react";
import { Popover, makeStyles } from "@material-ui/core";
import { useNotifications } from "souqh-react-redux-hooks/notifications/useNotifications";
import ViewInvoiceDialog from "../common/UpcomingEeventsPendingActions/ViewInvoiceDialog";
import AddReviewDialog from "../HomeBuyer/SPManagement/AddReviewDialog";
import ProfileAccessRequestDialog from "../common/UpcomingEeventsPendingActions/ProfileAccessRequestDialog";
import ViewOtherActionDialog from "../common/UpcomingEeventsPendingActions/ViewOtherActionDialog";
import DocumentAccessRequestDialog from "../common/UpcomingEeventsPendingActions/DocumentAccessRequestDialog";
import SPUploadWizard from "../common/UploadWizard/SPUploadWizard";
import { useUpcomingEeventsPendingActions } from "souqh-react-redux-hooks/serviceProvider/upcomingEeventsPendingActions/useUpcomingEeventsPendingActions";
import ViewDocumentDialog from "../DocumentManagementDialogs/ViewDocumentDialog";
import SignDocumentDialog from "../ServiceProvider/UploadDocuments/SignDocumentDialog";
import NotificationContent from "./NotificationContent";
import ViewTagDialog from "../common/UpcomingEeventsPendingActions/ViewTagDialog";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "10px 10px 10px 16px",
    width: 440,
    position: "absolute",
    left: "60px !important",
    maxHeight: 507,
    marginTop: 32,
  },
}));

export default function Notifications({
  notificationsAnchorEl,
  handleNotificationsClose,
}) {
  const open = Boolean(notificationsAnchorEl);
  const id = open ? "simple-popover" : undefined;
  const classes = useStyles();

  const {
    getNotifications,
    todayNotifications,
    olderNotifications,
    getDescription,
    getAvatarUrl,
    handleNotification,
    actions: { setShowOnlyUnreadNotifications },
    showOnlyUnreadNotifications,
    markAllNotificationsRead,
    openViewInvoiceDialog,
    setOpenViewInvoiceDialog,
    viewInvoiceDialogData,
    userInfo,
    openAddReviewDialog,
    setOpenAddReviewDialog,
    openProfileAccessRequestDialog,
    setOpenProfileAccessRequestDialog,
    profileAccessData,
    acceptRejectProfileAccessRequest,
    openOtherActionDialog,
    setOpenOtherActionDialog,
    otherActionData,
    handelScroll,
    scroller,
    openDocumentAccessRequestDialog,
    setOpenDocumentAccessRequestDialog,
    requestedDocAccessName,
    documentAccessRequestorSP,
    setOpenUploadWizard,
    openUploadWizard,
    docAccessRequestedData,
    openDocumetDialog,
    setOpenDocumetDialog,
    documentItem,
    setDocumentItem,
    openSignDocumentDialog,
    setOpenSignDocumentDialog,
    signDocumentItem,
    setSignDocumentItem,
    deleteNotification,
    openViewTagDialog,
    setOpenViewTagDialog,
    viewTagDialogData,
  } = useNotifications();
  const { acceptDocumentAccessRequest } = useUpcomingEeventsPendingActions();

  useEffect(() => {
    getNotifications(0);
  }, []);

  const noNotifications =
    (todayNotifications && todayNotifications.length) ||
    (olderNotifications && olderNotifications.length);

  return (
    <React.Fragment>
      <SPUploadWizard
        openUploadWizard={openUploadWizard}
        setOpenUploadWizard={setOpenUploadWizard}
      />
      <DocumentAccessRequestDialog
        openDocumentAccessRequestDialog={openDocumentAccessRequestDialog}
        setOpenDocumentAccessRequestDialog={setOpenDocumentAccessRequestDialog}
        requestedDocAccessName={requestedDocAccessName}
        documentAccessRequestorSP={documentAccessRequestorSP}
        acceptDocumentAccessRequest={acceptDocumentAccessRequest}
        docAccessRequestedData={docAccessRequestedData}
      />
      <ViewOtherActionDialog
        openOtherActionDialog={openOtherActionDialog}
        setOpenOtherActionDialog={setOpenOtherActionDialog}
        otherActionData={otherActionData}
        source="notifications"
      />
      <ProfileAccessRequestDialog
        openProfileAccessRequestDialog={openProfileAccessRequestDialog}
        setOpenProfileAccessRequestDialog={setOpenProfileAccessRequestDialog}
        profileAccessData={profileAccessData}
        acceptRejectProfileAccessRequest={acceptRejectProfileAccessRequest}
      />
      <ViewInvoiceDialog
        openViewInvoiceDialog={openViewInvoiceDialog}
        setOpenViewInvoiceDialog={setOpenViewInvoiceDialog}
        viewInvoiceDialogData={viewInvoiceDialogData}
        userInfo={userInfo}
      />
      <AddReviewDialog
        openAddReviewDialog={openAddReviewDialog}
        setOpenAddReviewDialog={setOpenAddReviewDialog}
      />
      <ViewDocumentDialog
        openDocumetDialog={openDocumetDialog}
        setOpenDocumetDialog={setOpenDocumetDialog}
        documentItem={documentItem}
        setDocumentItem={setDocumentItem}
      />
      <SignDocumentDialog
        openSignDocumentDialog={openSignDocumentDialog}
        setOpenSignDocumentDialog={setOpenSignDocumentDialog}
        signDocumentItem={signDocumentItem}
        setSignDocumentItem={setSignDocumentItem}
      />
      <ViewTagDialog
        openViewTagDialog={openViewTagDialog}
        setOpenViewTagDialog={setOpenViewTagDialog}
        viewTagDialogData={viewTagDialogData}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={notificationsAnchorEl}
        onClose={() => {
          handleNotificationsClose();
          setShowOnlyUnreadNotifications(false);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        classes={{
          paper: classes.paper,
        }}
      >
        <NotificationContent
          handleNotificationsClose={handleNotificationsClose}
          noNotifications={noNotifications}
          handelScroll={handelScroll}
          scroller={scroller}
          todayNotifications={todayNotifications}
          olderNotifications={olderNotifications}
          getDescription={getDescription}
          getAvatarUrl={getAvatarUrl}
          handleNotification={handleNotification}
          setShowOnlyUnreadNotifications={setShowOnlyUnreadNotifications}
          showOnlyUnreadNotifications={showOnlyUnreadNotifications}
          markAllNotificationsRead={markAllNotificationsRead}
          deleteNotification={deleteNotification}
        />
      </Popover>
    </React.Fragment>
  );
}
