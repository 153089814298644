import { useSelector } from "react-redux";

import { SET_STORE_FRONT_NAME } from "../../../souqh-redux/reducers/actionConstants";
import { useActions } from "../../useActions";
import { useCallback } from "react";
import { useValidations } from "../../useValidations";

const actionCreators = {
  setStorefrontName: (payload) => ({
    type: SET_STORE_FRONT_NAME,
    payload,
  }),
};

const validationMeta = [
  {
    name: "storefrontName",
    patterns: [
      {
        match: (val) => /(?!^ +$)^[A-Za-z0-9 ,$:|*&^;#+'()._-]*$/i.test(val),
        msg: "Please enter a valid storefront name",
      },
    ],
  },
];

export const useStoreFrontName = () => {
  const storefrontName = useSelector(
    (state) => state.storefront.storefrontName
  );

  const actions = useActions(actionCreators);

  const getFormValues = useCallback(() => {
    return { storefrontName };
  }, [storefrontName]);
  const getServerKeysMap = useCallback(() => {
    return {
      storeFrontName: "storefrontName",
    };
  }, []);

  const { validations, addValidations, clearAllValidations } = useValidations(
    validationMeta,
    getFormValues,
    getServerKeysMap
  );

  return { storefrontName, actions, validations, addValidations };
};
