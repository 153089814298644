import { useDispatch, useSelector } from "react-redux";

export const useServerErrors = () => {
  const dispatch = useDispatch();
  const serverError = useSelector((state) => state.ui.serverError);
  const handleClose = () => {
    dispatch({ type: "SET_SERVER_ERROR", error: null });
  };
  return { serverError, handleClose };
};
