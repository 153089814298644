import { useState } from "react";
import { useAuthUser } from "../../useLogin";
import { useDispatch } from "react-redux";
import * as moment from "moment";
import * as momentTimeZone from "moment-timezone";
import { useApiClient } from "../../useApiClient";
import { processDownloadFileResponse } from "../../../web/src/utils/AppUtils";

export const useDocumentHistory = () => {
  const { apiClient } = useApiClient();
  const userInfo = useAuthUser();
  const dispatch = useDispatch();

  const [openDocumentHistoryDialog, setOpenDocumentHistoryDialog] =
    useState(false);
  const [currentDocument, setCurrentDocument] = useState(null);
  const [historyData, setHistoryData] = useState(null);

  const getFormattedTimeStamp = (dateValue) => {
    if (dateValue) {
      try {
        const dateObj = new Date(dateValue);
        const date = moment(dateObj);
        const timeZone = momentTimeZone.tz.guess();
        const timeZoneOffset = new Date().getTimezoneOffset();
        const timeZoneName = momentTimeZone.tz
          .zone(timeZone)
          .abbr(timeZoneOffset);
        const dateString = date.format("MMM DD, yyyy");
        const timeString = date.format("hh:mm:ss A ");
        return dateString + " - " + timeString + " " + timeZoneName;
      } catch (e) {
        console.error(e);
      }
    }
    return "";
  };

  const loadDocumentHistory = (documentId) => {
    if (documentId) {
      setHistoryData(null);
      const url = `signnow/document/history/${documentId}/${userInfo.userId}`;
      dispatch({ type: "SET_LOADING", value: true });
      apiClient
        .get(url)
        .then((response) => {
          if (response.data.status === 200) {
            setHistoryData(response.data.result);
          } else {
            setHistoryData(null);
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const downloadDocumentHistory = (documentId, callBack) => {
    if (documentId) {
      const url = `signnow/download/document/${documentId}/${userInfo.userId}/true`;
      dispatch({ type: "SET_LOADING", value: true });
      apiClient
        .get(url, { responseType: "blob" })
        .then((response) => {
          processDownloadFileResponse(response);
          callBack && callBack();
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  return {
    openDocumentHistoryDialog,
    setOpenDocumentHistoryDialog,
    currentDocument,
    setCurrentDocument,
    loadDocumentHistory,
    historyData,
    setHistoryData,
    getFormattedTimeStamp,
    downloadDocumentHistory,
  };
};
