import { Grid, Paper, TextField } from "@material-ui/core";
import React from "react";
import Styles from "../../../Styles.module.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MaskedMobileInput from "../../MaskedMobileInput";
import { MARITAL_STATUS } from "../../../components/HBRegistration/HomeBuyerConstants";

const PrimaryApplicant = ({
  user,
  updatePrimaryApplicant,
  primaryApplicant,
  validations,
}) => {
  return (
    <Grid item xs={12}>
      <Grid item xs={12}>
        <Paper elevation={0} style={{ padding: "16px 10px" }}>
          <Grid container item xs={12}>
            <TextField
              autoComplete="new-password"
              variant="outlined"
              fullWidth
              disabled
              aria-label="Full Name Input"
              label="Full Name"
              required
              value={primaryApplicant.fullName}
              inputProps={{
                maxLength: 256,
              }}
            />
          </Grid>
          <Grid container item xs={12}>
            <TextField
              autoComplete="new-password"
              variant="outlined"
              fullWidth
              aria-label="Email ID Input"
              label="Email ID"
              required
              disabled
              value={primaryApplicant.email}
              inputProps={{
                maxLength: 256,
              }}
            />
          </Grid>

          <Grid container item xs={12}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={6}>
                <MaskedMobileInput
                  error={!!validations.phoneNumber}
                  helperText={
                    validations.phoneNumber && validations.phoneNumber.join(" ")
                  }
                  autoComplete="new-password"
                  id="phoneNumber"
                  label="Mobile Number"
                  name="phoneNumber"
                  variant="outlined"
                  required
                  type="tel"
                  fullWidth
                  value={primaryApplicant && primaryApplicant.phoneNumber}
                  onChange={(event) => {
                    updatePrimaryApplicant({
                      phoneNumber: event.target.value.replaceAll("-", ""),
                    });
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  options={MARITAL_STATUS}
                  value={
                    (primaryApplicant && primaryApplicant.maritalStatus) || null
                  }
                  required
                  onChange={(event, value) => {
                    updatePrimaryApplicant({ maritalStatus: value });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Marital Status"
                      required
                      variant="outlined"
                      autoComplete="new-password"
                    />
                  )}
                  getOptionLabel={(option) => option.key}
                  getOptionSelected={(option, value) => {
                    return option.key === value.key;
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PrimaryApplicant;
