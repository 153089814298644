import {
  UPDATE_NEW_HOME_PROFILE,
  SAVE_NEW_HOME_PROFILE,
  EDIT_NEW_HOME_PROFILE,
  RESET_NEW_HOME_PROFILE,
  SET_NEW_HOME_ADDRESS,
} from "souqh-redux/reducers/actionConstants";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useActions } from "../../useActions";
import { useProvinces } from "../../useProvinces";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import * as moment from "moment";
import { useApiClient } from "../../useApiClient";

const actionCreators = {
  updateNewHomeProfile: (payload) => ({
    type: UPDATE_NEW_HOME_PROFILE,
    payload,
  }),
  saveNewHomeProfile: () => ({ type: SAVE_NEW_HOME_PROFILE }),
  clickEdit: () => ({ type: EDIT_NEW_HOME_PROFILE }),
  resetNewHomeProfile: () => ({ type: RESET_NEW_HOME_PROFILE }),
};
const getPropertTypesActionCreator = (apiClient) => (dispatch) => {
  dispatch({ type: "SET_LOADING", value: true });
  return apiClient
    .get(`/home-buyer/demographic/property-type`)
    .then(function (response) {
      if (!response.data.result.length) {
        // we need response.data.content with values in every condition
        throw Error("Server Error : Property types not found");
      } else {
        dispatch({
          type: "SET_PROPERTY_TYPES",
          payload: response.data.result,
        });
      }
    })
    .catch(function (error) {
      console.error(error);
    })
    .then(function () {
      dispatch({ type: "SET_LOADING", value: false });
    });
};

export const useNewHomeProfile = () => {
  const { apiClient } = useApiClient();

  const dispatch = useDispatch();
  const [allowSave, setAllowSave] = useState(false);
  const [allowReset, setAllowReset] = useState(false);
  const { provinces } = useProvinces(1); // FIXME get country id from logged in user
  const { newHomeProfile } = useSelector((state) => state.hbSetup);
  const propertyTypes = useSelector((state) => state.hbSetup.propertyTypes);
  const actions = useActions(actionCreators);
  const user = useAuthUser();

  useEffect(() => {
    if (!propertyTypes) {
      dispatch(getPropertTypesActionCreator(apiClient));
    }
  }, [propertyTypes]);

  useEffect(() => {
    let validData =
      newHomeProfile &&
      !!newHomeProfile.streetNumber.trim() &&
      !!newHomeProfile.city &&
      newHomeProfile.province &&
      !!newHomeProfile.province.name &&
      !!newHomeProfile.postalCode.trim() &&
      !!newHomeProfile.propertyType;
    setAllowSave(validData);
    validData = Object.keys(newHomeProfile).some((key) => newHomeProfile[key]);
    setAllowReset(validData);
  }, [newHomeProfile]);
  const data = {
    closingDate: newHomeProfile.closingDate
      ? moment(newHomeProfile.closingDate).format("yyyy-MM-DD")
      : null,
    firstTimeHomeBuyer: newHomeProfile.firstTimeHomeBuyer,
    id: newHomeProfile.id || null,
    propertyTypeId:
      newHomeProfile.propertyType && newHomeProfile.propertyType.id,
    hbNewHomeAddress: {
      cityId: newHomeProfile.city && newHomeProfile.city.id,
      provinceId: newHomeProfile.province && newHomeProfile.province.id,
      county: newHomeProfile.county || "Canada",
      id: newHomeProfile.newHomeAddress
        ? newHomeProfile.newHomeAddress.id
        : null,
      otherCity: newHomeProfile.otherCity || null,
      postalCode: newHomeProfile.postalCode,
      streetName: newHomeProfile.streetName,
      streetNumber: newHomeProfile.streetNumber,
    },
  };
  const saveNewAddress = (onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .put(`/home-buyer/profile/new-home-profile/${user.userId}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        onResult && onResult(res);
      })
      .catch((error) => {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getNewProfileData = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/home-buyer/profile/new-home-profile/${user.userId}`)
      .then(async (response) => {
        let data;
        if (response.data.result) {
          data = mapKeysToReducer(response.data.result);
        }
        dispatch({
          type: SET_NEW_HOME_ADDRESS,
          payload: data,
        });
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };
  const mapKeysToReducer = (data) => {
    return {
      newHomeAddress: { ...data.newHomeAddress },
      ...data.newHomeAddress,
      closingDate: data.closingDate || null,
      propertyType: data.propertyType || null,
      firstTimeHomeBuyer: data.firstTimeHomeBuyer || false,
      editMode: data.newHomeAddress.id ? true : false,
      id: data.id
    };
  };
  return {
    newHomeProfile,
    actions,
    provinces,
    allowSave,
    allowReset,
    propertyTypes,
    saveNewAddress,
    getNewProfileData,
  };
};
