import React from "react";

function InviteCheck({ validations }) {
  const { hyperlinkUrl } = validations;
  if (!validations) {
    return null;
  }

  const result = Object.keys(validations).map((e) => {
    if (/Click here/i.test(validations[e][0])) {
      return validations[e][0].replaceAll(
        "Click here",
        `<a href="${hyperlinkUrl}" target="_blank">Click here</a>`
      );
    }
    return validations[e][0];
  });
  return <div dangerouslySetInnerHTML={{ __html: result[0] }}></div>;
}

export default InviteCheck;
