import {
  Fab,
  Grid,
  IconButton,
  Popover,
  TextField,
  Typography,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";

import AddIcon from "@material-ui/icons/Add";

import React, { useCallback, useEffect, useState } from "react";

import { SocialIcon } from "react-social-icons";
import { cloneDeep, map } from "lodash";
import WebAddress from "./WebAddress";

import { useCompanyWebsite } from "souqh-react-redux-hooks/serviceProvider/storefront/useCompanyWebsite";
import { useSocialLinks } from "souqh-react-redux-hooks/serviceProvider/storefront/useSocialLinks";

function SocialIcons(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [id, setId] = React.useState(null);
  const [currentPopLink, setCurrentPopLink] = React.useState("");
  const {
    socialLinks,
    actions: { addSocialLink, removeSocialLink, updateSocialLink },
  } = useSocialLinks();

  const {
    companyWebsite,
    actions: { setCompanyWebsite },
  } = useCompanyWebsite();

  const { data, onChange, isEditMode, isPreviewMode, readOnly, focused } =
    props;
  useEffect(() => {
    if (id !== null && id !== "web-address") {
      setCurrentPopLink(socialLinks[id]);
    }
  }, [id]);

  const handleClick = (event, i) => {
    setAnchorEl(event.currentTarget);
    setId(i);
    if (i === "web-address") {
      setCurrentPopLink(companyWebsite);
    } else {
      setCurrentPopLink(socialLinks[i]);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const displayOnly = isPreviewMode || readOnly;

  const addButton = (
    <IconButton
      size="small"
      color="primary"
      aria-label="crop picture"
      component="span"
      onClick={() => {
        addSocialLink({ link: "" });
      }}
    >
      <AddIcon />
    </IconButton>
  );
  let symbs = null;
  const webAddressIcon = (
    <WebAddress
      style={{ height: 25, width: 25, margin: 2 }}
      id={"web-address"}
      onClick={(e) => {
        if (!displayOnly) {
          e.preventDefault();
          handleClick(e, "web-address");
        }
      }}
    ></WebAddress>
  );
  if (socialLinks) {
    symbs = map(socialLinks, (l, i) => {
      return (
        <SocialIcon
          style={{ height: 25, width: 25, margin: 2 }}
          key={i}
          id={i}
          url={l}
          network={l.includes("www") ? l.split(".")[1] : "sharethis"}
          onClick={(e) => {
            if (!displayOnly) {
              e.preventDefault();
              handleClick(e, i);
            }
          }}
        />
      );
    });
  }

  return (
    <Grid container>
      {webAddressIcon}
      {symbs}
      {addButton}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Grid container style={{ padding: 10 }}>
          <TextField
            value={currentPopLink}
            onChange={(e) => {
              setCurrentPopLink(e.target.value);
            }}
          ></TextField>

          <IconButton
            size="small"
            color="primary"
            aria-label="crop picture"
            component="span"
            onClick={() => {
              if (id === "web-address") {
                setCompanyWebsite({ companyWebsite: currentPopLink });
              } else {
                updateSocialLink({ index: id, link: currentPopLink });
              }

              handleClose();
              setCurrentPopLink("");
            }}
          >
            <DoneIcon />
          </IconButton>
          {id !== "web-address" && (
            <IconButton
              size="small"
              color="primary"
              aria-label="crop picture"
              component="span"
              onClick={() => {
                setCurrentPopLink("");
                handleClose();
                removeSocialLink({ index: id });
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Grid>
      </Popover>
    </Grid>
  );
}

export default SocialIcons;
