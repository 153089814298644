import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";
import SearchIcon from "@material-ui/icons/Search";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import clsx from "clsx";
import { cloneDeep } from "lodash-es";

const styles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined": {
      top: -8,
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px",
    },
  },
  dialogPaper: {
    height: window.innerHeight,
    borderRadius: 0,
  },
}));
export default function CreateNewGroupChatDialog({
  openCreateNewGroupChat,
  setOpenCreateNewGroupChat,
  channelList,
  createNewUserGroup,
}) {
  const classes = styles();

  const [searchText, setSearchText] = useState("");
  const [groupName, setGroupName] = useState("");
  const [userListToCreateGroup, setUserListToCreateGroup] = useState([]);
  useEffect(() => {
    if (openCreateNewGroupChat) {
      setUserListToCreateGroup(
        cloneDeep(channelList).filter(
          (item) => item.Username && item.cognitoProfileId
        )
      );
    } else {
      setUserListToCreateGroup([]);
    }
  }, [openCreateNewGroupChat]);

  const handleDialogClose = () => {
    setSearchText("");
    setGroupName("");
    setOpenCreateNewGroupChat(false);
  };

  const handleSelectionChange = (value, item) => {
    const tempList = cloneDeep(userListToCreateGroup);
    const findItem = tempList.find(
      (elem) => elem.Username && elem.Username === item.Username
    );
    if (findItem) {
      findItem.selected = value;
    }
    setUserListToCreateGroup(tempList);
  };
  const txt = (searchText || "").toLowerCase().trim();
  const allUsersList = userListToCreateGroup
    ? userListToCreateGroup
        .filter((elem) => (elem.fullName || "").toLowerCase().indexOf(txt) >= 0)
        .map((item, index) => {
          return (
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              style={{ padding: "4px 0px" }}
              key={index}
            >
              <Grid item xs={1}>
                <Checkbox
                  checked={item.selected || false}
                  onChange={(event) => {
                    handleSelectionChange(event.target.checked, item);
                  }}
                  size="small"
                  value={item.selected}
                  name="radio-button-demo"
                  className={"sqBlueRadio"}
                  color={Styles.navyBlueColorForce}
                />
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  padding: "0px 12px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {item.profilePhoto ? (
                  <Avatar
                    src={item.profilePhoto}
                    style={{ width: 32, height: 32, borderRadius: 4 }}
                  />
                ) : (
                  <AccountBoxIcon
                    style={{ fontSize: 40, fill: "#0e1c4e", opacity: 0.3 }}
                  />
                )}
              </Grid>
              <Grid item xs={9}>
                <Typography
                  variant="body1"
                  title={item.fullName || item.Username}
                  style={{ width: "95%" }}
                  className={clsx(Styles.navyBlueColor, Styles.sqEllipsis)}
                >
                  {item.fullName || item.Username}
                </Typography>
              </Grid>
            </Grid>
          );
        })
    : null;

  const getSelectedUsers = () => {
    return userListToCreateGroup.filter((item) => item.selected);
  };
  return (
    <Dialog
      fullWidth
      disableBackdropClick
      maxWidth={"xs"}
      open={openCreateNewGroupChat}
      onClose={() => {
        handleDialogClose();
      }}
      classes={{ paper: classes.dialogPaper, root: classes.root }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <div
          style={{ width: "95%", fontSize: 16 }}
          className={clsx(Styles.navyBlueColor)}
        >
          New Group Chat
        </div>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ paddingTop: 16, overflow: "hidden" }}>
        <Grid item xs={12} style={{ paddingBottom: 16 }}>
          <TextField
            fullWidth
            label="Group Name"
            variant="outlined"
            value={groupName}
            onChange={(e) => {
              setGroupName(e.target.value);
            }}
            inputProps={{
              maxLength: 100,
            }}
          />
        </Grid>
        <Typography
          variant="subtitle1"
          className={Styles.navyBlueColor}
          style={{ marginBottom: 8 }}
        >
          Add contacts to group
        </Typography>
        <Grid item xs={12} style={{ position: "relative", marginBottom: 8 }}>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Search Contacts"
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
            }}
          />
          <SearchIcon
            style={{
              marginTop: 10,
              paddingRight: 10,
              width: 24,
              height: 24,
              position: "absolute",
              right: 0,
              fill: "#0e1c4e",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ height: "calc(100% - 130px)", overflow: "auto" }}
        >
          {allUsersList}
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: "16px 24px" }}>
        <Button
          variant="outlined"
          style={{
            padding: "6px 16px",
            marginRight: 12,
            textTransform: "capitalize",
          }}
          className={Styles.navyBlueColorForce}
          onClick={() => {
            handleDialogClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={
            (groupName || "").trim().length < 3 || getSelectedUsers().length < 2
          }
          onClick={() => {
            if (
              getSelectedUsers().length < 2 ||
              (groupName || "").trim().length > 100
            ) {
              return;
            }
            createNewUserGroup(
              (groupName || "").trim(),
              getSelectedUsers(),
              () => {
                handleDialogClose();
              }
            );
          }}
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize", padding: "6px 32px" }}
        >
          Create Group
        </Button>
      </DialogActions>
    </Dialog>
  );
}
