import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles,
  Card,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { useRoleManagement } from "souqh-react-redux-hooks/common/useRoleManagement";
import {
  isMobileDevice,
  isOnboardingInComplete,
  isProPlan,
} from "../utils/AppUtils";
import UpgradePlanIconSection from "./common/UpgradePlanIconSection";

const useStyles = makeStyles((theme) => ({
  column1: {
    flexBasis: "90%",
  },
  column2: {
    flexBasis: "30%",
  },
}));

export default function DashboardCard({
  title,
  imageSrc,
  children,
  redirectTo,
  onAddClick,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const userInfo = useAuthUser();
  const { isTeamMember } = useRoleManagement();

  return (
    <Card
      raised
      style={{ marginRight: !isMobileDevice() && 20, borderRadius: 8 }}
    >
      <Accordion defaultExpanded style={{ color: "white" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#0E1C4E", minHeight: 0, height: 55 }}
        >
          <div className={classes.column1} style={{ alignSelf: "center" }}>
            <Typography
              style={{
                fontWeight: 700,
                fontSize: xlUp ? "1rem" : "0.875rem", // 16px / 14 px
                color: "white",
              }}
              onClick={() => {
                if (!isOnboardingInComplete(userInfo)) {
                  redirectTo && redirectTo();
                }
              }}
            >
              {title}
            </Typography>
          </div>
          {(title === "Calendar" ||
            (title === "Marketing Campaigns"&& userInfo.roles?.length && !isTeamMember()) ||
            title === "Active Clients" ||
            title === "Upcoming Events") && (
            <div className={classes.column2}>
              <AddIcon
                style={{
                  float: "right",
                  fill:
                    (title === "Upcoming Events" || title === "Calendar") &&
                    isProPlan(userInfo)
                      ? "grey"
                      : "white",
                  position: "relative",
                }}
                onClick={(event) => {
                  if (!isOnboardingInComplete(userInfo)) {
                    event && event.stopPropagation();
                    onAddClick && onAddClick(event);
                  }
                }}
              />
              {(title === "Upcoming Events" || title === "Calendar") &&
                isProPlan(userInfo) && (
                  <UpgradePlanIconSection
                    iconBtnStyle={{
                      position: "absolute",
                      fontSize: 16,
                      left: 184,
                      top: 0,
                      fill: "#fa7e61",
                    }}
                    iconStyle={{
                      position: "absolute",
                      fontSize: 16,
                      top: 12,
                      fill: "#fa7e61",
                    }}
                  />
                )}
            </div>
          )}
        </AccordionSummary>
        <AccordionDetails
          style={{
            height: xlUp ? "23rem" : "18.5rem",
            padding: 0,
            display: "grid",
          }}
        >
          {children}
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}
