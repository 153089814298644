import { Link, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Styles from "../Styles.module.css";
import "./TermsAndPolicy.css";

export default function PrivacyPolicy() {
  return (
    <Typography className="body">
      <Typography className={Styles.navyBlueColor}>
        <Typography variant="subtitle1" className="heading">
          Privacy Policy
        </Typography>
        <Typography className="container" align="justify">
          <Typography variant="body1" className="para">
            Last modified: May 9, 2021
          </Typography>
          <Typography className="subHeading">
            <u>INTRODUCTION</u>
          </Typography>
          <Typography className="li">
            Souqh Technologies Inc. (<span className="subHeading">"Souqh"</span>{" "}
            or 
            <span className="subHeading">"we"</span>) respect your privacy and
            are committed to protecting it by complying with this policy.
          </Typography>
          <Typography className="para">This policy describes:</Typography>
          <Typography>
            <ul>
              <li>
                How we collect, use, disclose, and protect the personal
                information of our customers and website users{" "}
                <span className="subHeading">("you")</span>.
              </li>
              <li>
                Describes the types of information we may collect from you or
                that you may provide when you visit the website www.souqh.ca
                (our
                <span className="subHeading">"Website"</span>).
              </li>
              <li>
                Our practices for collecting, using, maintaining, protecting,
                and disclosing that information.
              </li>
            </ul>
          </Typography>
          <div className="para">
            We will only use your personal information in accordance with this
            policy unless otherwise required by applicable law. We take steps to
            ensure that the personal information that we collect about you is
            adequate, relevant, not excessive, and used for limited purposes.
          </div>
          <div className="para">
            Privacy laws in Canada generally define "personal information" as
            any information about an identifiable individual, which includes
            information that can be used on its own or with other information to
            identify, contact, or locate a single person. Personal information
            does not include business contact information, including your
            business name, title, or contact information.
          </div>
          <div className="para">
            This policy applies to information we collect, use, or disclose
            about you:
          </div>
          <div className="para">
            <ul>
              <li>On this Website.</li>
              <li>
                In email, text, and other electronic messages between you and
                this Website.
              </li>
              <li>
                Through mobile and desktop applications you download from this
                Website, which provide dedicated non-browser-based interaction
                between you and this Website.
              </li>
              <li>
                When you interact with our advertising, third-party marketing
                campaigns on the Website and applications on third-party
                websites and services.
              </li>
            </ul>
          </div>
          <div className="para">
            The Website may include links to third-party websites, plug-ins,
            services, social networks, or applications. Clicking on those links
            or enabling those connections may allow the third party to collect
            or share data about you. If you follow a link to a third-party
            website or engage a third-party plugin, please note that these third
            parties have their own privacy policies and we do not accept any
            responsibility or liability for these policies. We do not control
            these third-party websites, and we encourage you to read the privacy
            policy of every website you visit.
          </div>
          <div className="para">
            This policy DOES NOT apply to information that:
          </div>
          <div>
            <ul>
              <li>
                We collect offline or through any other means, including on any
                other Company or third-party website.
              </li>
              <li>
                You provide to or is collected by any third party, through any
                application or content (including advertising) that may link to
                or be accessible from or on the Website.
              </li>
            </ul>
          </div>
          <div>
            Please read this policy carefully to understand our policies and
            practices for collecting, processing, and storing your information.
            If you do not agree with our policies and practices, you must not to
            use our Website. By accessing or using this Website, you indicate
            that you understand, accept, and consent to the practices described
            in this policy. This policy may change from time to time. Your
            continued use of this Website after we make changes indicates that
            you accept and consent to those changes, so please check the policy
            periodically for updates. We will notify you in advance of any
            material changes to this policy and obtain your consent to any new
            ways that we collect, use, and disclose your personal information.
          </div>
          <div className="para">
            <b>
              <u>INFORMATION WE COLLECT ABOUT YOU</u>
            </b>
          </div>
          <div className="para">
            We collect and use several types of information from and about you,
            including:
          </div>
          <div className="para">
            <ul>
              <li className="li">
                <div className="para">
                  <span className="subHeading">Personal information</span>, that
                  we can reasonably use to directly or indirectly identify you,
                  such as your name, mailing address, e-mail address, telephone
                  number, Internet protocol (IP) address used to connect your
                  computer to the Internet, user name or other similar
                  identifier, billing and account information, language you
                  speak, area of expertise, license information, occupation, age
                  range, income range, marital status, home ownership status,
                  and any other identifier we may use to contact you{" "}
                  <span className="subHeading">("personal information")</span>.
                </div>
                <div className="li">
                  We provide an opportunity for any user to unsubscribe from our
                  service or opt-out of contact for marketing purposes on an
                  ongoing basis by accessing our Website, using the unsubscribe
                  mechanism at the bottom of our emails, or e-mailing to{" "}
                  <Link underline="none"> support@souqh.ca </Link>
                </div>
              </li>
              <li className="li">
                <span className="subHeading">Non-personal information</span>,
                that does not directly or indirectly reveal your identity or
                directly relate to an identifiable individual, such as
                demographic information, or statistical or aggregated
                information. Statistical or aggregated data does not directly
                identify a specific person, but we may derive non-personal
                statistical or aggregated data from personal information. For
                example, we may aggregate personal information to calculate the
                percentage of users accessing a specific Website feature such as
                marketing campaigns and key statistics.
              </li>
              <li className="li">
                <span className="subHeading">Technical information</span>,
                including your login information, browser type and version, time
                zone setting, browser plug-in types and versions, operating
                system and platform, or information about your internet
                connection, the equipment you use to access our Website, and
                usage details.
              </li>
              <li className="li">
                <span className="subHeading">
                  Non-personal details about your Website interactions
                </span>
                , including the full Uniform Resource Locators (URLs),
                clickstream to, through and from our Website (including date and
                time), products you viewed or searched for, page response times,
                download errors, length of visits to certain pages, page
                interaction information (such as scrolling, clicks, and
                mouse-overs), methods used to browse away from the page, or any
                phone number used to call our customer service number.
              </li>
            </ul>
          </div>
          <div className="para">
            <b>
              <u>HOW WE COLLECT INFORMATION ABOUT YOU</u>
            </b>
          </div>
          <div className="para">
            We use different methods to collect your information, including
            through:
          </div>
          <div className="para">
            <ul>
              <li className="li">
                <span className="subHeading">
                  Direct interactions with you when you provide it to us
                </span>
                , for example, by filling in forms or corresponding with us by
                phone, email, or otherwise.
              </li>
              <li className="li">
                <span className="subHeading">User submissions</span>. You may
                also provide information for us to publish or display on public
                Website areas or transmit to other Website users or third
                parties.
              </li>
              <li className="li">
                <span className="subHeading">
                  Automated technologies or interactions
                </span>
                , as you navigate through our Website. Information collected
                automatically may include usage details, response to certain
                marketing campaigns, IP addresses, and information collected
                through cookies, web beacons, and other tracking technologies.
              </li>
              <li className="li">
                <span className="subHeading">
                  Third parties or publicly available sources
                </span>
                , for example, our business partners.
              </li>
            </ul>
          </div>
          <div className="para">
            <b>
              <u>Information You Provide to Us </u>{" "}
            </b>
          </div>
          <div className="para">
            The information we collect directly from you on or through our
            Website may include:
          </div>
          <div className="para">
            <ul>
              <li className="li">
                Information that you provide by filling in forms on our Website.
                This includes information provided at the time of registering to
                use our Website, subscribing to our service, posting material,
                uploading documents, or requesting further services. We may also
                ask you for information when you enter a promotion sponsored by
                us, and when you report a problem with our Website.
              </li>
              <li className="li">
                Records and copies of your correspondence (including email
                addresses), if you contact us.
              </li>
              <li className="li">
                Your responses to surveys that we might ask you to complete for
                research purposes.
              </li>
              <li className="li">
                Details of transactions you carry out through our Website and of
                the fulfillment of your orders. You may be required to provide
                financial information before placing an order through our
                Website.
              </li>
              <li className="li">Your search queries on the Website.</li>
              <li className="li">
                Your visits to certain storefronts and profiles of other users.
              </li>
            </ul>
          </div>
          <div className="para">
            You may also provide information to be published or displayed
            (hereinafter, <span className="subHeading">"posted"</span>) on
            public areas of the Website or transmitted to other users of the
            Website or third parties (collectively,{" "}
            <span className="subHeading">"User Submissions"</span>). Your User
            Submissions are posted on and transmitted to others at your own
            risk. Although we limit access to certain pages/you may set certain
            privacy settings for such information by logging into your account
            profile, please be aware that no security measures are perfect.
            Additionally, we cannot control the actions of other users of the
            Website with whom you may choose to share your User Submissions.
            Therefore, we cannot and do not guarantee that unauthorized persons
            will not view your User Submissions.
          </div>
          <div className="para">
            <b>
              Information We Collect Through Cookies and Other Automatic Data
              Collection Technologies
            </b>
          </div>
          <div className="para">
            As you navigate through and interact with our Website, we may use
            cookies or other automatic data collection technologies to collect
            certain information about your equipment, browsing actions, and
            patterns, including:
          </div>
          <div className="para">
            <ul>
              <li className="li">
                Details of your visits to our Website, including traffic data,
                location data, logs, and other communication data and the
                resources that you access and use on the Website.
              </li>
              <li className="li">
                Information about your computer and internet connection,
                including your IP address, operating system, and browser type.
              </li>
            </ul>
          </div>
          <div className="para">
            We may also use these technologies to collect information about your
            online activities over time and across third-party websites or other
            online services (behavioral tracking). To learn more or to opt-out
            of tailored advertising please visit https://youradchoices.ca/ for
            information on how you can opt out of behavioral tracking on this
            Website and how we respond to web browser signals and other
            mechanisms that enable consumers to exercise choice about behavioral
            tracking.
          </div>
          <div className="para">
            The information we collect automatically is statistical data is
            statistical information and may include personal information, and we
            may maintain it or associate it with personal information we collect
            in other ways, or receive from third parties. It helps us to improve
            our Website and to deliver a better and more personalized service,
            including by enabling us to:
          </div>
          <div className="para">
            <ul>
              <li className="li">Estimate our user base and usage patterns.</li>
              <li className="li">
                Store information about your preferences, allowing us to
                customize our Website according to your individual interests.
              </li>
              <li className="li">Speed up your searches.</li>
              <li className="li">Connect you with adequate parties.</li>
              <li className="li">
                Recognize you when you return to our Website.
              </li>
            </ul>
          </div>
          <div className="para">
            The technologies we use for this automatic data collection may
            include:
          </div>
          <div className="para">
            <ul>
              <li className="li">
                <span className="subHeading">Cookies (or browser cookies)</span>
                . A cookie is a small file placed on the hard drive of your
                computer. You may refuse to accept browser cookies by activating
                the appropriate setting on your browser. However, if you select
                this setting you may be unable to access certain parts of our
                Website. Unless you have adjusted your browser setting so that
                it will refuse cookies, our system will issue cookies when you
                direct your browser to our Website.
              </li>
              <li className="li">
                <span className="subHeading">Web Beacons and Badges</span>.
                Pages of our Website and our e-mails may contain small
                electronic files known as web beacons and/or badges (also
                referred to as clear gifs, pixel tags, and single-pixel gifs)
                that permit the Company, for example, to count users who have
                visited those pages or opened an email, to enhance search engine
                optimization for Souqh storefronts, and for other Website
                statistics (for example, recording the popularity of certain
                website content and verifying system and server integrity).
              </li>
            </ul>
          </div>
          <div className="para">
            <b>Third Party Use of Cookies and Other Tracking Technologies</b>
          </div>
          <div className="para">
            Some content or applications on the Website, including
            advertisements, are served by third parties, including advertisers,
            ad networks and servers, content providers, and application
            providers. These third parties may use cookies alone or in
            conjunction with web beacons or other tracking technologies to
            collect information about you when you use our Website. The
            information they collect may be associated with your personal
            information or they may collect information, including personal
            information, about your online activities over time and across
            different websites and other online services. They may use this
            information to provide you with interest-based (behavioural)
            advertising or other targeted content.
          </div>
          <div className="para">
            ou can opt-out of several third-party ad servers' and networks'
            cookies simultaneously by using an opt-out tool created by the
            Digital Advertising Alliance of Canada (https://youradchoices.ca).
            You can also access these websites to learn more about online
            behavioural advertising and how to stop websites from placing
            cookies on your device. Opting out of a network does not mean you
            will no longer receive online advertising. It does mean that the
            network from which you opted out will no longer deliver ads tailored
            to your web preferences and usage patterns.
          </div>
          <div className="para">
            We do not control these third parties' tracking technologies or how
            they are used. If you have any questions about an advertisement or
            other targeted content, you should contact the responsible provider
            directly.
          </div>
          <div className="para">
            <b>
              <u>HOW WE USE YOUR INFORMATION</u>
            </b>
          </div>
          <div className="para">
            We use information that we collect about you or that you provide to
            us, including any personal information:
          </div>
          <div className="para">
            <ul>
              <li className="li">
                To present our Website and its contents to you.
              </li>
              <li className="li">
                To provide you with information, products, or services that you
                request from us.
              </li>
              <li className="li">
                To fulfill the purposes for which you provided the information
                or that were described when it was collected, or any other
                purpose for which you provide it.
              </li>
              <li className="li">
                To provide you with notices about your account/subscription,
                including expiration and renewal notices.
              </li>
              <li className="li">
                To carry out our obligations and enforce our rights arising from
                any contracts with you, including for billing and collection or
                to comply with legal requirements.
              </li>
              <li className="li">
                To notify you about changes to our Website or any products or
                services we offer or provide though it.
              </li>
              <li className="li">
                To improve our Website, products or services, marketing, or
                customer relationships and experiences.
              </li>
              <li className="li">
                To allow you to participate in interactive features, social
                media, or similar features on our Website.
              </li>
              <li className="li">
                To measure or understand the effectiveness of the advertising we
                serve to you and other marketing campaigns run by users, and to
                deliver relevant advertising to you.
              </li>
              <li className="li">
                To facilitate your communication with other users of this
                Website.
              </li>
              <li className="li">
                In any other way we may describe when you provide the
                information.
              </li>
              <li className="li">For any other purpose with your consent.</li>
            </ul>
          </div>
          <div className="para">
            We may use the information we have collected from you to enable us
            to display advertisements to our advertisers' target audiences. Even
            though we do not disclose your personal information for these
            purposes without your consent, if you click on or otherwise interact
            with an advertisement, the advertiser may assume that you meet its
            target criteria.
          </div>
          <div className="para">
            <b>DISCLOSURE OF YOUR INFORMATION</b>
          </div>
          <div className="para">
            We may disclose aggregated information about our users, and
            information that does not identify any individual, without
            restriction.
          </div>
          <div className="para">
            We may disclose personal information that we collect or you provide
            as described in this privacy policy:
          </div>
          <div className="para">
            <ul>
              <li className="li">
                To our subsidiaries and affiliates, if applicable.
              </li>
              <li className="li">
                In accordance with applicable law, to a buyer or other successor
                in the event of a merger, divestiture, restructuring,
                reorganization, dissolution, or other sale or transfer of some
                or all of Souqh's assets, whether as a going concern or as part
                of bankruptcy, liquidation, or similar proceeding, in which
                personal information held by Souqh about our customers and users
                is among the assets transferred.
              </li>
              <li className="li">
                To advertisers and advertising networks that require the
                information to select and serve relevant advertisements to you
                and others. We do not disclose data about identifiable
                individuals to our advertisers, but we may provide them with
                aggregate information about our users (for example, we may
                inform them that 400 women between 30 and 45 have clicked on
                their advertisement on a specific day). We may also use such
                aggregate information to help advertisers target a specific
                audience (for example, men in a specific location). We may make
                use of the personal information we have collected from you to
                enable us to display our advertisers' advertisement to that
                target audience.
              </li>
              <li className="li">
                To third parties to market their products or services to you if
                you have consented to/not opted out of these disclosures. We
                contractually require these third parties to keep personal
                information confidential and use it only for the purposes for
                which we disclose it to them.
              </li>
              <li className="li">
                To contractors, service providers, and other third parties we
                use to support our business (such as analytics and search engine
                providers that assist us with Website improvement and
                optimization) and who are contractually obligated to keep
                personal information confidential, use it only for the purposes
                for which we disclose it to them, and to process the personal
                information with the same standards set out in this policy.
              </li>
              <li className="li">
                To fulfill the purpose for which you provide it. For example, if
                you give us an email address to use the "invite a friend"
                feature of our Website, we will transmit the contents of that
                email and your email address to the recipients.
              </li>
              <li className="li">
                For any other purpose disclosed by us when you provide the
                information.
              </li>
              <li className="li">With your consent.</li>
            </ul>
          </div>
          <div className="para">
            We may also disclose your personal information:
          </div>
          <div className="para">
            <ul>
              <li className="li">
                To comply with any court order, law, or legal process, including
                to respond to any government or regulatory request, in
                accordance with applicable law.
              </li>
              <li className="li">
                To enforce or apply our terms and conditions of use{" "}
                <Link
                  href="/userterms"
                  underline="always"
                  target="_blank"
                  rel="noreferrer"
                >
                  link
                </Link>{" "}
                and other agreements, including for billing and collection
                purposes.
              </li>
              <li className="li">
                If we believe disclosure is necessary or appropriate to protect
                the rights, property, or safety of Souqh, our users, or others.
                This includes exchanging information with other companies and
                organizations for the purposes of fraud protection and credit
                risk reduction.
              </li>
            </ul>
          </div>
          <div className="para">
            <b>
              <u>TRANSFERRING YOUR PERSONAL INFORMATION</u>
            </b>
          </div>
          <div className="para">
            We may transfer personal information that we collect or that you
            provide as described in this policy to contractors, service
            providers, and other third parties we use to support our business
            (such as analytics and search engine providers that assist us with
            Website improvement and optimization) and who are contractually
            obligated to keep personal information confidential, use it only for
            the purposes for which we disclose it to them, and to process the
            personal information with the same standards set out in this policy.
          </div>
          <div className="para">
            We may process, store, and transfer your personal information in and
            to a foreign country, with different privacy laws that may or may
            not be as comprehensive as Canadian law. In these circumstances, the
            governments, courts, law enforcement, or regulatory agencies of that
            country may be able to obtain access to your personal information
            through the laws of the foreign country. Whenever we engage a
            service provider, we require that its privacy and security standards
            adhere to this policy and applicable Canadian privacy legislation.
          </div>
          <div className="para">
            You are welcome to contact us to obtain further information about
            Company policies regarding service providers outside of Canada.
          </div>
          <div className="para">
            By submitting your personal information or engaging with the
            Website, you consent to this transfer, storage, or processing.
          </div>
          <div className="para">
            <b>
              <u>CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR INFORMATION</u>
            </b>
          </div>
          <div className="para">
            We strive to provide you with choices regarding the personal
            information you provide to us. We have created mechanisms to provide
            you with the following control over your information:
          </div>
          <div className="para">
            <ul>
              <li className="li">
                <span className="subHeading">
                  Tracking Technologies and Advertising.
                </span>{" "}
                You can set your browser to refuse all or some browser cookies,
                or to alert you when cookies are being sent. If you disable or
                refuse cookies, please note that some parts of this Website may
                not be accessible or may not function properly.
              </li>
              <li className="li">
                <span className="subHeading">Third-Party Advertising.</span> If
                you do not want us to share your personal information with
                unaffiliated or non-agent third parties for promotional
                purposes, you can opt-out by emailing
                <Link underline="none"> support@souqh.ca</Link>
              </li>
              <li className="li">
                <span className="subHeading">
                  Promotional Offers from Souqh.
                </span>{" "}
                If you have opted in to receive certain emails from us but no
                longer wish to have your email address/contact information used
                by us to promote our own or third parties' products or services,
                you can opt-out by accessing our Website, using the unsubscribe
                mechanism at the bottom of our emails, or by sending us an email
                stating your request to
                <Link underline="none"> support@souqh.ca</Link> If we have sent
                you a promotional email, you may unsubscribe by clicking the
                unsubscribe link we have included in the email. This opt-out
                does not apply to information provided to the Company as part of
                a product purchase, warranty registration, product service
                experience, or other transactions.
              </li>
              <li className="li">
                <span className="subHeading">Targeted Advertising.</span> If you
                do not want us to use information that we collect or that you
                provide to us to deliver advertisements according to our
                advertisers' target-audience preferences, you can opt out by
                emailing <Link underline="none"> support@souqh.ca</Link>
                <div className="li">
                  We do not control third parties' collection or use of your
                  information to serve interest-based advertising. However,
                  these third parties may provide you with ways to choose not to
                  have your information collected or used in this way. You can
                  opt out of several third party ad servers' and networks'
                  cookies simultaneously by using an opt-out tool created by the
                  Digital Advertising Alliance of Canada. You can also access
                  these websites to learn more about online behavioural
                  advertising and how to stop websites from placing cookies on
                  your device. Opting out of a network does not mean you will no
                  longer receive online advertising. It does mean that the
                  network from which you opted out will no longer deliver ads
                  tailored to your web preferences and usage patterns.
                </div>
              </li>
            </ul>
          </div>
          <div className="para">
            <b>
              <u>DATA SECURITY</u>
            </b>
          </div>
          <div className="para">
            The security of your personal information is very important to us.
            We use physical, electronic, and administrative measures designed to
            secure your personal information from accidental loss and from
            unauthorized access, use, alteration, and disclosure. We store all
            information you provide to us behind firewalls on our secure
            servers. Any payment transactions will be encrypted using TLS (SSL)
            technology serviced by Stripe, Inc. Please visit
            https://stripe.com/en-ca/privacy for the privacy policy of Stripe,
            Inc., for further information on the security of your transactional
            information and how your payment information will be collected,
            stored and disclosed. Your use or continued use of our Website and
            its payment functions will constitute your agreement to be bound by
            the terms and conditions and privacy policies of Stripe, Inc.
          </div>
          <div className="para">
            The safety and security of your information also depends on you.
            Where we have given you (or where you have chosen) a password for
            access to certain parts of our Website, you are responsible for
            keeping this password confidential. We ask you not to share your
            password with anyone. We urge you to be careful about giving out
            information in public areas of the Website like message boards,
            which any Website visitor can view.
          </div>
          <div className="para">
            Unfortunately, the transmission of information via the Internet is
            not completely secure. Although we do our best to protect your
            personal information, we cannot guarantee the security of your
            personal information transmitted to our Website. Any transmission of
            personal information is at your own risk. We are not responsible for
            circumvention of any privacy settings or security measures contained
            on the Website.
          </div>
          <div className="para">
            <b>
              <u>THIRD PARTY POLICIES</u>
            </b>
          </div>
          <div className="para">
            To support us in delivering our Services, we engage third party
            service providers to assist us with our services including data
            processing activities and as provided in our terms and conditions of
            use{" "}
            <Link
              href="/userterms"
              underline="always"
              target="_blank"
              rel="noreferrer"
            >
              link
            </Link>
            . These service providers may have access to or process personal
            data on our behalf, only to perform their tasks on our behalf and
            are obligated not to collect, use or disclose your personal
            information for any other purpose. Please review the respective
            privacy policies of our service providers, which may be modified or
            updated from time to time, to become familiar with how they collect,
            use and disclose your personal information:
          </div>
          <div className="para">
            <ul>
              <li className="li">
                <b>Stripe</b> (Click
                <Link
                  href="https://stripe.com/en-ca/privacy"
                  underline="always"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  here{" "}
                </Link>
                for the privacy policy)
              </li>
              <li className="li">
                <b>SignNow</b> (Click
                <Link
                  href="https://www.signnow.com/privacy_policy"
                  underline="always"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  here{" "}
                </Link>
                for the privacy policy)
              </li>
              <li className="li">
                <b>Intercom</b> (Click
                <Link
                  href="https://www.intercom.com/legal/privacy"
                  underline="always"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  here{" "}
                </Link>
                for the privacy policy)
              </li>
              <li className="li">
                <b>Upscope</b> (Click
                <Link
                  href="https://upscope.com/privacy-policy/"
                  underline="always"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  here{" "}
                </Link>{" "}
                for the privacy policy)
              </li>
              <li className="li">
                <b>AWS</b> (Click
                <Link
                  href="https://aws.amazon.com/privacy/"
                  underline="always"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  here{" "}
                </Link>{" "}
                for the privacy policy)
              </li>
              <li className="li">
                <b>Calendly</b> (Click
                <Link
                  href="https://calendly.com/pages/privacy"
                  underline="always"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  here{" "}
                </Link>{" "}
                for the privacy policy)
              </li>
            </ul>
          </div>
          <div className="para">
            You must not use our Website if you do not agree to our service
            providers’ respective privacy policies.
          </div>
          <div className="para">
            <b>
              <u>DATA RETENTION</u>
            </b>
          </div>
          <div className="para">
            Except as otherwise permitted or required by applicable law or
            regulation, we will only retain your personal information for as
            long as necessary to fulfill the purposes we collected it for,
            including for the purposes of satisfying any legal, accounting, or
            reporting requirements. Under some circumstances we may anonymize
            your personal information so that it can no longer be associated
            with you. We reserve the right to use such anonymous and
            de-identified data for any legitimate business purpose without
            further notice to you or your consent.
          </div>
          <div className="para">
            <b>
              <u>CHILDREN UNDER THE AGE OF 13</u>
            </b>
          </div>
          <div className="para">
            Our Website is not intended for children under 13 years of age. No
            one under age 13 may provide any information to or on the Website.
            We do not knowingly collect personal information from children under
            13. If you are under 13, do not use or provide any information on
            this Website or on or through any of its features/register on the
            Website, make any purchases through the Website, use any of the
            interactive or public comment features of this Website, or provide
            any information about yourself to us, including your name, address,
            telephone number, email address, or any screen name or username you
            may use. If we learn we have collected or received personal
            information from a child under 13 without verification of parental
            consent, we will delete that information. If you believe we might
            have any information from or about a child under 13, please contact
            us at <Link underline="none"> support@souqh.ca</Link>
          </div>
          <div className="para">
            <b>
              <u>ACCESSING AND CORRECTING YOUR PERSONAL INFORMATION</u>
            </b>
          </div>
          <div className="para">
            It is important that the personal information we hold about you is
            accurate and current. Please keep us informed if your personal
            information changes. By law you have the right to request access to
            and to correct the personal information that we hold about you.
          </div>
          <div className="para">
            You can review and change your personal information by logging into
            the Website and visiting your account profile page.
          </div>
          <div className="para">
            If you want to review, verify, correct, or withdraw consent to the
            use of your personal information you may also send us an email at{" "}
            <Link underline="none">support@souqh.ca</Link> to request access to,
            correct, or delete any personal information that you have provided
            to us. We cannot delete your personal information except by also
            deleting your user account. We may not accommodate a request to
            change information if we believe the change would violate any law or
            legal requirement or cause the information to be incorrect.
          </div>
          <div className="para">
            We may request specific information from you to help us confirm your
            identity and your right to access, and to provide you with the
            personal information that we hold about you or make your requested
            changes. Applicable law may allow or require us to refuse to provide
            you with access to some or all of the personal information that we
            hold about you, or we may have destroyed, erased, or made your
            personal information anonymous in accordance with our record
            retention obligations and practices. If we cannot provide you with
            access to your personal information, we will inform you of the
            reasons why, subject to any legal or regulatory restrictions.
          </div>
          <div className="para">
            We will provide access to your personal information, subject to
            exceptions set out in applicable privacy legislation. Examples of
            such exceptions include:
          </div>
          <div className="para">
            <ul>
              <li className="li">
                Information protected by solicitor-client privilege.
              </li>
              <li className="li">
                Information that is part of a formal dispute resolution process.
              </li>
              <li className="li">
                Information that is about another individual that would reveal
                their personal information or confidential commercial
                information.
              </li>
            </ul>
          </div>
          <div className="para">
            If you are concerned about our response or would like to correct the
            information provided, you may contact our Privacy Officer at{" "}
            <Link underline="none">support@souqh.ca</Link>
          </div>
          <div className="para">
            If you delete your User Submissions from the Website, copies of your
            User Submissions may remain viewable in cached and archived pages or
            might have been copied or stored by other Website users. Proper
            access and use of information provided on the Website, including
            User Submissions, is governed by our terms of conditions of use{" "}
            <Link
              href="/userterms"
              underline="always"
              target="_blank"
              rel="noreferrer"
            >
              link
            </Link>
            .
          </div>
          <div className="para">
            <b>
              <u>WITHDRAWING YOUR CONSENT</u>
            </b>
          </div>
          <div className="para">
            Where you have provided your consent to the collection, use, and
            transfer of your personal information, you may have the legal right
            to withdraw your consent under certain circumstances. To withdraw
            your consent, if applicable, contact us at{" "}
            <Link underline="none">support@souqh.ca</Link> Please note that if
            you withdraw your consent we may not be able to provide you with a
            particular product or service. We will explain the impact to you at
            the time to help you with your decision.{" "}
          </div>
          <div className="para">
            <b>
              <u>CHANGES TO OUR PRIVACY POLICY</u>
            </b>
          </div>
          <div className="para">
            It is our policy to post any changes we make to our privacy policy
            on this page with a notice that the privacy policy has been updated
            on the Website home page. If we make material changes to how we
            treat our users' personal information, we will notify you by email
            to the email address specified in your account or through a notice
            on the Website home page.{" "}
          </div>
          <div className="para">
            We include the date the privacy policy was last revised at the top
            of the page. You are responsible for ensuring we have an up-to-date,
            active, and deliverable email address for you, and for periodically
            visiting our Website and this privacy policy to check for any
            changes.{" "}
          </div>
          <div className="para">
            <b>
              <u>CONTACT INFORMATION AND CHALLENGING COMPLIANCE</u>
            </b>
          </div>
          <div className="para">
            We welcome your questions, comments, and requests regarding this
            privacy policy and our privacy practices. Please contact our Privacy
            Officer at <Link underline="none">support@souqh.ca</Link> or mail to
            37A Green Belt Drive, Toronto, ON M3C 1L8.{" "}
          </div>
          <div className="para">
            We have procedures in place to receive and respond to complaints or
            inquiries about our handling of personal information, our compliance
            with this policy, and with applicable privacy laws. To discuss our
            compliance with this policy please contact our Privacy Officer using
            the contact information listed above.
          </div>
        </Typography>
      </Typography>
    </Typography>
  );
}
