import {
  Divider,
  Grid,
  makeStyles,
  Typography,
  Card,
  Button,
} from "@material-ui/core";
import React, { useEffect } from "react";
import EditIcon from "@material-ui/icons/Edit";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { usePaymentPlanReview } from "souqh-react-redux-hooks/review/usePaymentPlanReview";
import { useHistory } from "react-router-dom";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { APPROVED } from "../../ServiceProvider/serviceProviderConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    padding: "16px 22px",
    color: "#0e1c4e",
  },
  marginTop: {
    marginTop: 20,
  },
  marginBottom20: {
    marginBottom: 20,
  },
  marginBottom8: {
    marginBottom: 8,
  },
  marginRight: {
    marginRight: 10,
  },
  spacingControl: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
  },
  cursorPointer: {
    cursor: "pointer",
  },
}));

export default function PaymentPlanReview() {
  const classes = useStyles();
  let history = useHistory();
  const userInfo = useAuthUser();

  const { planDetails, getSubscriptionPlanDetails } = usePaymentPlanReview();

  useEffect(() => {
    getSubscriptionPlanDetails();
  }, []);

  const validity = () => {
    return (
      <Typography className={classes.spacingControl} variant="body1">
        /{planDetails && planDetails.paymentCycle === "MONTHLY" ? "mo" : "yr"}
        /user
      </Typography>
    );
  };

  const currency = () => {
    return (
      <Typography className={classes.spacingControl} variant="body1">
        $
      </Typography>
    );
  };

  return (
    <Grid container className={`${classes.root} ${classes.marginTop}`}>
      <Grid
        item
        xs={12}
        className={clsx(classes.marginTop, classes.marginBottom8)}
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h4">Subscription Plan</Typography>
          </Grid>
          {userInfo.spApprovalStatus !== APPROVED ? (
            <Grid item>
              <Grid container className={classes.cursorPointer}>
                <Button
                  style={{ color: "#f38282", textTransform: "capitalize" }}
                  onClick={() => {
                    history.push("/setup/subscription");
                  }}
                  startIcon={<EditIcon />}
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
          ) : null}
        </Grid>

        <Divider className={classes.marginTop} />

        <Grid
          container
          item
          className={clsx(classes.marginTop, classes.marginBottom20)}
        >
          <Typography variant="h6">Select Payment Plan</Typography>
        </Grid>
        {planDetails && (
          <>
            <Grid container item>
              <Typography variant="subtitle1">Payment type: </Typography>
              <Grid
                style={{
                  textTransform: "capitalize",
                  marginLeft: 10,
                  fontSize: "16px",
                  fontWeight: 700,
                }}
              >
                {planDetails.paymentCycle.toLowerCase()}
              </Grid>
            </Grid>
            <Grid container item>
              <Typography
                variant="body1"
                style={{ textTransform: "capitalize" }}
              >
                Primary User
              </Typography>
            </Grid>
          </>
        )}
        {planDetails && (
          <Card
            style={{
              color: "#17174C",
              border: "1px solid #E2E2E9",
            }}
          >
            <Grid container item sm={12} style={{ padding: 20 }}>
              <Grid item sm={1}></Grid>
              <Grid container item sm={6}>
                <Grid
                  container
                  item
                  sm={12}
                  className={classes.spacingControl}
                  justifyContent="flex-start"
                >
                  <Typography
                    className={clsx(classes.spacingControl, classes.title)}
                    variant="h5"
                  >
                    {planDetails.primarySubscriptionPlan.planName} Plan
                  </Typography>
                </Grid>

                <Grid
                  container
                  item
                  sm={12}
                  className={classes.spacingControl}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {currency()}
                  <Typography
                    className={clsx(classes.spacingControl, classes.title)}
                    variant="h4"
                  >
                    {planDetails.paymentCycle === "MONTHLY"
                      ? planDetails.primarySubscriptionPlan.monthlyPrice
                      : planDetails.primarySubscriptionPlan.annualPrice}
                  </Typography>
                  {validity()}
                </Grid>

                {planDetails.primarySubscriptionPlan.subscriptionPlanFeatures &&
                  planDetails.primarySubscriptionPlan.subscriptionPlanFeatures.map(
                    (feature, index) => (
                      <Grid
                        container
                        item
                        sm={12}
                        justifyContent="flex-start"
                        key={index}
                      >
                        <Grid item sm={2} justifyContent="flex-start">
                          {feature.status === true ? (
                            <CheckCircleIcon />
                          ) : (
                            <CancelOutlinedIcon />
                          )}
                        </Grid>

                        <Grid item sm={10} justifyContent="flex-start">
                          <Typography variant="body1">
                            {feature.featureText}
                          </Typography>
                        </Grid>
                      </Grid>
                    )
                  )}
                {planDetails.enterprisePlanPromoCodes && (
                  <Grid
                    item
                    className={clsx(classes.spacingControl, classes.marginTop)}
                  >
                    <Typography variant="body1">
                      Promo Code:{" "}
                      {planDetails.enterprisePlanPromoCodes.promoCode}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid container item sm={5}>
                <img src="/images/Treasure-amico.svg"></img>
              </Grid>
            </Grid>
          </Card>
        )}

        {planDetails && planDetails.teamSubscriptionPlan && (
          <Grid container item className={classes.marginTop}>
            <Typography variant="body1" style={{ textTransform: "capitalize" }}>
              Team Members
            </Typography>
          </Grid>
        )}
        {planDetails && planDetails.teamSubscriptionPlan && (
          <Card
            style={{
              color: "#17174C",
              border: "1px solid #E2E2E9",
            }}
          >
            <Grid container item sm={12} style={{ padding: 20 }}>
              <Grid item sm={1}></Grid>
              <Grid container item sm={6}>
                <Grid
                  container
                  item
                  sm={12}
                  className={classes.spacingControl}
                  justifyContent="flex-start"
                >
                  <Typography
                    className={clsx(classes.spacingControl, classes.title)}
                    variant="h5"
                  >
                    {planDetails.teamSubscriptionPlan.planName} Plan
                  </Typography>
                </Grid>

                <Grid
                  container
                  item
                  sm={12}
                  className={classes.spacingControl}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {currency()}
                  <Typography
                    className={clsx(classes.spacingControl, classes.title)}
                    variant="h4"
                  >
                    {planDetails.paymentCycle === "MONTHLY"
                      ? planDetails.teamSubscriptionPlan.monthlyPrice
                      : planDetails.teamSubscriptionPlan.annualPrice}
                  </Typography>
                  {validity()}
                </Grid>

                {planDetails.teamSubscriptionPlan.subscriptionPlanFeatures &&
                  planDetails.teamSubscriptionPlan.subscriptionPlanFeatures.map(
                    (feature, index) => (
                      <Grid
                        container
                        item
                        sm={12}
                        justifyContent="flex-start"
                        key={index}
                      >
                        <Grid item sm={2} justifyContent="flex-start">
                          {feature.status === true ? (
                            <CheckCircleIcon />
                          ) : (
                            <CancelOutlinedIcon />
                          )}
                        </Grid>

                        <Grid item sm={10} justifyContent="flex-start">
                          <Typography variant="body1">
                            {feature.featureText}
                          </Typography>
                        </Grid>
                      </Grid>
                    )
                  )}
                {planDetails.enterprisePlanPromoCodes && (
                  <Grid
                    item
                    className={clsx(classes.spacingControl, classes.marginTop)}
                  >
                    <Typography variant="body1">
                      Promo Code:{" "}
                      {planDetails.enterprisePlanPromoCodes.promoCode}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid container item sm={5}>
                <img src="/images/Treasure-amico.svg"></img>
              </Grid>
            </Grid>
          </Card>
        )}
      </Grid>
    </Grid>
  );
}
