import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 400,
  },
}));

function DocumentAccessRequestDialog({
  openDocumentAccessRequestDialog,
  setOpenDocumentAccessRequestDialog,
  requestedDocAccessName,
  documentAccessRequestorSP,
  acceptDocumentAccessRequest,
  docAccessRequestedData,
}) {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      open={openDocumentAccessRequestDialog}
      onClose={() => {
        setOpenDocumentAccessRequestDialog(false);
      }}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Document Access Request</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ marginTop: 8 }}>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            className={Styles.navyBlueColor}
            style={{ fontWeight: 600 }}
          >
            {documentAccessRequestorSP} is requesting access to{" "}
            {requestedDocAccessName}
          </Typography>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions style={{ padding: "16px 36px" }}>
        <Button
          variant="outlined"
          style={{
            padding: "6px 16px",
            marginRight: 12,
            textTransform: "capitalize",
          }}
          className={Styles.navyBlueColorForce}
          onClick={() => {
            acceptDocumentAccessRequest
              ? acceptDocumentAccessRequest(
                  docAccessRequestedData.requestorUserId,
                  docAccessRequestedData.referenceDocumentId,
                  docAccessRequestedData.signnowDocumentId,
                  false, // isAppproved value false fpr reject and true for accept
                  (response) => {
                    if (response && response.data.status === 200) {
                      setOpenDocumentAccessRequestDialog(false);
                      docAccessRequestedData.isDocumentAccessApproved = false;
                    }
                  }
                )
              : setOpenDocumentAccessRequestDialog(false);
          }}
        >
          Reject
        </Button>
        <Button
          variant="contained"
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize", padding: "6px 32px" }}
          onClick={() => {
            acceptDocumentAccessRequest
              ? acceptDocumentAccessRequest(
                  docAccessRequestedData.requestorUserId,
                  docAccessRequestedData.referenceDocumentId,
                  docAccessRequestedData.signnowDocumentId,
                  true,
                  (response) => {
                    if (response && response.data.status === 200) {
                      setOpenDocumentAccessRequestDialog(false);
                      docAccessRequestedData.isDocumentAccessApproved = true;
                    }
                  }
                )
              : setOpenDocumentAccessRequestDialog(false);
          }}
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DocumentAccessRequestDialog;
