import { cloneDeep } from "lodash";
import {
  SAVE_INVITE_SP_DATA,
  SAVE_PRIMARY_APPLICANT_DATA,
  SAVE_CO_APPLICANT_DATA,
  CLEAR_INIVITE_CUSTOMER_DATA,
  CLEAR_INIVITE_SP_DATA,
  SAVE_RECOMMEND_SP_DATA,
  SHOW_CO_APPLICANT,
  SHOW_RECOMMENDED_SP,
  SAVE_NEW_SP,
  SET_SP_REFERRAL_LIST,
  ADD_NEW_SP,
  DELETE_NEW_SP,
  CLEAR_NEW_SP_DATA,
  SET_SERVICE_PROVIDER_LIST,
  SET_DOCUMENT_LIST,
  SET_TEMPLATE_LIST,
} from "../../souqh-redux/reducers/actionConstants";
import {
  BUSINESS_TYPE_OTHER,
  REFERRAL_INVITE_STATUS,
} from "../../web/src/components/ServiceProvider/serviceProviderConstants";
import { remove_AndFormValidString } from "../../web/src/utils/AppUtils";

const defaultSate = {
  fullName: "",
  email: "",
};
const initialState = {
  showCoApplicantdetails: false,
  showRecommendedSPDetails: false,
  primaryApplicant: {
    ...defaultSate,
    phoneNumber: "",
  },
  coApplicant: {
    ...defaultSate,
  },
  recommendSP: {
    selectedPreferredSP: [],
    newAddedSP: [],
  },
  iniviteSP: {
    ...defaultSate,
    phoneNumber: "",
    serviceProviderType: null,
    website: "",
  },
  newSP: {
    ...defaultSate,
    phoneNumber: null,
    serviceProviderType: null,
  },
  spReferralList: [],
  serviceProviderList: [],
  documentChecklist: [],
  templateList: [],
};

const referralManagement = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SAVE_INVITE_SP_DATA:
      newState = cloneDeep(state);
      newState.iniviteSP = { ...newState.iniviteSP, ...action.payload };
      return newState;
    case SAVE_PRIMARY_APPLICANT_DATA:
      newState = cloneDeep(state);
      newState.primaryApplicant = {
        ...newState.primaryApplicant,
        ...action.payload,
      };
      return newState;
    case SAVE_CO_APPLICANT_DATA:
      newState = cloneDeep(state);
      newState.coApplicant = {
        ...newState.coApplicant,
        ...action.payload,
      };
      return newState;
    case SAVE_RECOMMEND_SP_DATA:
      newState = cloneDeep(state);
      newState.recommendSP = {
        ...newState.recommendSP,
        ...action.payload,
      };
      return newState;
    case CLEAR_INIVITE_CUSTOMER_DATA:
      newState = cloneDeep(state);
      newState.primaryApplicant = {
        ...initialState.primaryApplicant,
      };
      newState.coApplicant = {
        ...initialState.coApplicant,
      };
      newState.recommendSP = {
        ...initialState.recommendSP,
      };
      return newState;
    case CLEAR_INIVITE_SP_DATA:
      newState = cloneDeep(state);
      newState.iniviteSP = {
        ...initialState.iniviteSP,
      };
      return newState;
    case SHOW_CO_APPLICANT:
      newState = cloneDeep(state);
      newState.showCoApplicantdetails = action.payload;
      if (!action.payload) {
        newState.coApplicant.fullName = "";
        newState.coApplicant.email = "";
      }
      return newState;
    case SHOW_RECOMMENDED_SP:
      newState = cloneDeep(state);
      newState.showRecommendedSPDetails = action.payload;
      if (!action.payload) {
        newState.recommendSP.selectedPreferredSP = [];
        newState.recommendSP.newAddedSP = [];
      }
      return newState;
    case SAVE_NEW_SP:
      newState = cloneDeep(state);
      newState.newSP = {
        ...newState.newSP,
        ...action.payload,
      };
      return newState;
    case ADD_NEW_SP:
      newState = cloneDeep(state);
      newState.recommendSP.newAddedSP.push(action.payload);
      return newState;
    case DELETE_NEW_SP:
      newState = cloneDeep(state);
      newState.recommendSP.newAddedSP.splice(action.index, 1);
      return newState;
    case CLEAR_NEW_SP_DATA:
      newState = cloneDeep(state);
      newState.newSP = {
        ...initialState.newSP,
      };
      return newState;
    case SET_SP_REFERRAL_LIST:
      newState = cloneDeep(state);
      const data = [];
      action.payload.forEach((element) => {
        data.push({
          ...element,
          referralInviteStatus: REFERRAL_INVITE_STATUS.filter(
            (mData) => mData.key === element.referralInviteStatus
          )[0].value,
        });
      });
      newState.spReferralList = [...newState.spReferralList, ...data];
      return newState;
    case SET_SERVICE_PROVIDER_LIST:
      newState = cloneDeep(state);
      newState.serviceProviderList = action.serviceProviderList;
      return newState;
    case SET_DOCUMENT_LIST:
      newState = cloneDeep(state);
      // newState.documentChecklist = action.payload.map((ele) =>
      //   remove_AndFormValidString(ele)
      // );
      // newState.documentChecklist.push(BUSINESS_TYPE_OTHER);
      newState.documentChecklist = action.payload;
      return newState;
    case SET_TEMPLATE_LIST:
      newState = cloneDeep(state);
      let list = [];
      action.payload.forEach((ele) =>
        ele.documents.forEach((item) => {
          list.push({
            name: item.docName,
            signNowDocumentId: item.signNowDocumentId,
          });
        })
      );
      newState.templateList = list;
      return newState;
    default:
      return state;
  }
};

export default referralManagement;
