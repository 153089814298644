import {
  Grid,
  Typography,
  TextField,
  Chip,
  Avatar,
  Checkbox,
} from "@material-ui/core";
import React, { useEffect } from "react";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import { HOME_BUYER_USER } from "../../ServiceProvider/serviceProviderConstants";
import { cloneDeep } from "lodash";

export default function ActiveCustomersTab({
  userType,
  searchText,
  setSearchText,
  handleCustomerSelect,
  selectedActiveCustomers,
  selectAllCustomers,
  setSelectAllCustomers,
  getFilteredCustomers,
  activeCustomers,
}) {
  const contactsList = getFilteredCustomers().map((el, index) => {
    return (
      <Grid
        item
        container
        xs={12}
        key={index}
        style={{
          padding: "8px 0px",
          alignItems: "center",
        }}
      >
        <Grid
          item
          container
          xs={5}
          md={2}
          lg={1}
          style={{ justifyContent: "space-around" }}
        >
          <Checkbox
            checked={el.selected || false}
            onChange={(event) => {
              handleCustomerSelect(event.target.checked, el);
            }}
            color={Styles.navyBlueColorForce}
          />
          {el.profilePic ? (
            <Avatar src={el.profilePic} />
          ) : (
            <Avatar>
              <AccountCircleOutlinedIcon
                style={{ fontSize: 32, fill: "#0e1c4e" }}
              />
            </Avatar>
          )}
        </Grid>

        <Grid item xs={7} md={8} style={{ paddingLeft: 16 }}>
          <Typography
            variant="subtitle1"
            className={clsx(Styles.sqEllipsis, Styles.navyBlueColor)}
          >
            {el.firstName} {el.lastName}
          </Typography>
          <Typography
            variant="body1"
            className={clsx(Styles.sqEllipsis, Styles.navyBlueColor)}
          >
            {el.email}
          </Typography>
        </Grid>
      </Grid>
    );
  });

  const showSelectedCustomers = selectedActiveCustomers.map((item, index) => {
    return (
      <Chip
        key={index}
        variant="outlined"
        label={item.firstName + " " + item.lastName}
        avatar={
          item.profilePic ? (
            <Avatar src={item.profilePic} />
          ) : (
            <Avatar>
              <AccountCircleOutlinedIcon
                style={{ fontSize: 32, fill: "#0e1c4e" }}
              />
            </Avatar>
          )
        }
        onDelete={() => {
          handleCustomerSelect(false, item);
        }}
        className={Styles.navyBlueColorForce}
        style={{ marginRight: 8, marginBottom: 4, fontWeight: 600 }}
      />
    );
  });
  return (
    <>
      {activeCustomers && activeCustomers.length ? (
        <Grid item xs={11} style={{ margin: "12px 0px" }}>
          <TextField
            fullWidth
            label="Search your active customers by name"
            variant="outlined"
            value={searchText}
            style={{ backgroundColor: "#ffffff" }}
            onInput={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </Grid>
      ) : null}
      <Grid item xs={12} style={{ padding: "8px 0px" }}>
        {showSelectedCustomers}
      </Grid>
      {
        <Grid
          item
          container
          xs={12}
          style={{
            alignItems: "flex-start",
          }}
        >
          {contactsList && contactsList.length ? (
            <Grid
              item
              container
              xs={12}
              style={{ alignItems: "center", paddingLeft: 6 }}
            >
              <Checkbox
                checked={selectAllCustomers || false}
                onChange={(event) => {
                  setSelectAllCustomers(event.target.checked);
                }}
                color={Styles.navyBlueColorForce}
              />
              <Typography
                variant="body1"
                className={Styles.navyBlueColor}
                style={{ fontWeight: 600 }}
              >
                Select All
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      }
      <Grid item xs={12}>
        {contactsList}
      </Grid>
      {!contactsList || !contactsList.length ? (
        <Grid>
          <Typography
            variant="body1"
            className={Styles.navyBlueColor}
            style={{ textAlign: "center", margin: "32px 0px" }}
          >
            No active
            {userType === HOME_BUYER_USER ? " service providers " : " clients "}
            available
          </Typography>
        </Grid>
      ) : null}
    </>
  );
}
