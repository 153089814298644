import {
  Checkbox,
  Chip,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import React from "react";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { useAboutYourBusiness } from "souqh-react-redux-hooks/serviceProvider/useSetUp";
import Styles from "../../../Styles.module.css";
import {
  BUSINESS_TYPE_HOME_DEPOSIT,
  BUSINESS_TYPE_OTHER,
} from "../../ServiceProvider/serviceProviderConstants";

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: 20,
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AboutYourBusiness = () => {
  const {
    businessTypeSubCategories,
    actions: { saveSubBusinessType },
  } = useAboutYourBusiness();
  const user = useAuthUser();
  const classes = useStyles();

  return (
    <Grid container item xs={12} direction="column">
      <Grid
        container
        item
        xs={12}
        id="about_business"
        className={classes.marginTop}
      >
        <Grid container item alignItems="center">
          <Typography
            style={{ margin: "0px 10px" }}
            className={Styles.navyBlueColorForce}
            variant="subtitle1"
          >
            Business Name:
          </Typography>
          <Typography className={Styles.navyBlueColorForce}>
            {user.businessName}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.marginTop}>
        <Grid container item alignItems="center">
          <Typography
            style={{ margin: "0px 10px" }}
            className={Styles.navyBlueColorForce}
            variant="subtitle1"
          >
            Business Type:
          </Typography>
          <Typography className={Styles.navyBlueColorForce}>
            {user.businessTypes &&
            user.businessTypes.length &&
            user.businessTypes[0].name === BUSINESS_TYPE_OTHER
              ? user.businessTypes[0].otherBusinessName
              : user.businessTypes[0].name}
          </Typography>
        </Grid>
        <Grid item>
          {user.businessTypes &&
            user.businessTypes.length &&
            user.businessTypes[0].name !== BUSINESS_TYPE_OTHER &&
            user.businessTypes[0].name !== BUSINESS_TYPE_HOME_DEPOSIT && (
              <Autocomplete
                multiple
                fullWidth
                style={{ display: "inline-flex" }}
                variant="outlined"
                id="business-type-options"
                options={
                  user.businessTypes.length &&
                  user.businessTypes[0].subBusinessTypes
                }
                // disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                inputProps={{
                  className: Styles.navyBlueColorForce,
                }}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      color={Styles.navyBlueColorForce}
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </React.Fragment>
                )}
                value={businessTypeSubCategories}
                onChange={(e, v) => {
                  saveSubBusinessType(v);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    // label={user.businessTypes[0].name}
                    placeholder="Please select the types of services you provide*"
                  />
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      label={option.name}
                      {...getTagProps({ index })}
                      variant="outlined"
                      className={Styles.navyBlueColorForce}
                    />
                  ))
                }
                getOptionSelected={(option, value) => {
                  return option.name === value.name;
                }}
              />
            )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AboutYourBusiness;
