import { cloneDeep } from "lodash";
import {
  GET_SERVICE_PROVIDERS_DATA,
  SET_SERVICE_PROVIDERS_TAB,
  SET_SP_MGMT_DATA,
  SET_ACTIVE_SP_DATA,
  SET_REQUESTS_SP_DATA,
  SET_SP_LABEL,
  SET_ARCHIVE_SP_DATA,
  SET_COMPLETE_SP_DATA,
} from "./actionConstants";

const initialState = {
  active: { response: [], count: 0 },
  requests: { response: [], count: 0 },
  completed: { response: [], count: 0 },
  archive: { response: [], count: 0 },
  clientsTab: 0,
  serviceProvidersTab: 0,
};

const spManagement = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case GET_SERVICE_PROVIDERS_DATA:
      return newState;
    case SET_SERVICE_PROVIDERS_TAB:
      newState = cloneDeep(state);
      newState.serviceProvidersTab = action.payload;
      return newState;
    case SET_SP_MGMT_DATA:
      newState = cloneDeep(state);
      newState.active = action.payload.Active;
      newState.active.count = action.payload.Active.totalItems;
      newState.completed = action.payload.Completed;
      newState.completed.count = newState.completed.totalItems;
      newState.requests = action.payload.Requests;
      newState.requests.count = newState.requests.totalItems;
      newState.archive = action.payload.Archive;
      newState.archive.count = newState.archive.totalItems;
      return newState;
    case SET_ACTIVE_SP_DATA:
      newState = cloneDeep(state);
      newState.active.response = action.payload.response;
      newState.active.count = action.payload.totalItems;
      return newState;
    case SET_REQUESTS_SP_DATA:
      newState = cloneDeep(state);
      newState.requests.response = action.payload.response;
      newState.requests.count = action.payload.totalItems;
      return newState;
    case SET_ARCHIVE_SP_DATA:
      newState = cloneDeep(state);
      newState.archive.response = action.payload.response;
      newState.archive.count = action.payload.totalItems;
      return newState;
    case SET_COMPLETE_SP_DATA:
      newState = cloneDeep(state);
      newState.completed.response = action.payload.response;
      newState.completed.count = action.payload.totalItems;
      return newState;
    case SET_SP_LABEL:
      newState = cloneDeep(state);
      let activeResponse = newState.active.response;
      const index = activeResponse.findIndex(
        (item) => item.serviceRequestId === action.payload.serviceRequestId
      );
      if (index > -1) {
        newState.active.response[index].label = action.payload.label;
      }
      return newState;
    default:
      return state;
  }
};

export default spManagement;
