import React from "react";

export default function DocumentStatus({ docStatus }) {
  const status = (docStatus || "").toLowerCase().trim();
  let color = "#868DA6";
  let backgroundColor = "#F5F6F8";
  if (status) {
    switch (status) {
      case "viewed":
      case "sent":
        break;
      case "submitted":
      case "waiting for sign":
      case "new":
        color = "#2842D0";
        backgroundColor = "#C9CFF3";
        break;
      case "expired":
      case "void":
        color = "#700000";
        backgroundColor = "#EEBFBF";
        break;
      case "expiring soon":
      case "signed":
        color = "#987400";
        backgroundColor = "#FFEFBF";
        break;
      case "void":
        color = "#700000";
        backgroundColor = "#EEBFBF";
        break;
    }
  }
  return (
    <span
      style={{
        backgroundColor,
        borderRadius: 4,
        textAlign: "center",
        padding: "4px 10px",
        fontSize: 14,
        color,
      }}
    >
      {docStatus || ""}
    </span>
  );
}
