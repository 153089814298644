import { Grid, Typography, Paper, Avatar, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";
import { useDemographicData } from "souqh-react-redux-hooks/homeBuyer/hbProfile/useDemographicData";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paddingLeft15: {
    paddingLeft: 15,
  },
  italicFont: {
    fontStyle: "italic",
  },
}));

export default function DemographicsDataTab() {
  const classes = useStyles();
  const { demographicData, getDemographicData } = useDemographicData(true);
  let { userId } = useParams();

  useEffect(() => {
    window.scroll(0, 0);
    getDemographicData(userId);
  }, []);

  return (
    <Paper elevation={0} style={{ padding: 12, height: 479 }}>
      <Grid container>
        <Grid item xs={12} md={5}>
          <Grid item xs={12}>
            <Typography variant="h6" className={Styles.navyBlueColor}>
              Primary Applicant
            </Typography>
          </Grid>
          <Grid container item xs={12} style={{ marginTop: 20 }}>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                className={Styles.navyBlueColor}
                style={{ fontWeight: 500 }}
              >
                Employment
              </Typography>
            </Grid>
            <Grid item xs={8} className={classes.paddingLeft15}>
              <Typography
                variant="body1"
                className={clsx(classes.italicFont, Styles.navyBlueColor)}
              >
                {demographicData.employment || "-"}
              </Typography>
            </Grid>
            <Grid container item xs={12} style={{ marginTop: 8 }}>
              <Grid item xs={4}>
                <Typography
                  variant="body1"
                  className={Styles.navyBlueColor}
                  style={{ fontWeight: 500 }}
                >
                  Industry
                </Typography>
              </Grid>
              <Grid item xs={8} className={classes.paddingLeft15}>
                <Typography
                  variant="body1"
                  className={clsx(classes.italicFont, Styles.navyBlueColor)}
                >
                  {(demographicData.industry &&
                    demographicData.industry.name) ||
                    "-"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} style={{ marginTop: 8 }}>
              <Grid item xs={4}>
                <Typography
                  variant="body1"
                  className={Styles.navyBlueColor}
                  style={{ fontWeight: 500 }}
                >
                  Age Range
                </Typography>
              </Grid>
              <Grid item xs={8} className={classes.paddingLeft15}>
                <Typography
                  variant="body1"
                  className={clsx(classes.italicFont, Styles.navyBlueColor)}
                >
                  {demographicData.ageGroup || "-"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} style={{ marginTop: 8 }}>
              <Grid item xs={4}>
                <Typography
                  variant="body1"
                  className={Styles.navyBlueColor}
                  style={{ fontWeight: 500 }}
                >
                  Status
                </Typography>
              </Grid>
              <Grid item xs={8} className={classes.paddingLeft15}>
                <Typography
                  variant="body1"
                  className={clsx(classes.italicFont, Styles.navyBlueColor)}
                >
                  {(demographicData.maritalStatus &&
                    demographicData.maritalStatus.key) ||
                    "-"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} style={{ marginTop: 8 }}>
              <Grid item xs={4}>
                <Typography
                  variant="body1"
                  className={Styles.navyBlueColor}
                  style={{ fontWeight: 500 }}
                >
                  Income Range
                </Typography>
              </Grid>
              <Grid item xs={8} className={classes.paddingLeft15}>
                <Typography
                  variant="body1"
                  className={clsx(classes.italicFont, Styles.navyBlueColor)}
                >
                  {(demographicData.householdIncomeRange &&
                    demographicData.householdIncomeRange.name) ||
                    "-"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} style={{ marginTop: 8 }}>
              <Grid item xs={4}>
                <Typography
                  variant="body1"
                  className={Styles.navyBlueColor}
                  style={{ fontWeight: 500 }}
                >
                  Property Value
                </Typography>
              </Grid>
              <Grid item xs={8} className={classes.paddingLeft15}>
                <Typography
                  variant="body1"
                  className={clsx(classes.italicFont, Styles.navyBlueColor)}
                >
                  {(demographicData.propertyValue &&
                    demographicData.propertyValue.name) ||
                    "-"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={1}></Grid>
      </Grid>
    </Paper>
  );
}
