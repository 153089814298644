import { useSelector } from "react-redux";

import { SET_COMPANY_WEBSITE } from "../../../souqh-redux/reducers/actionConstants";
import { useActions } from "../../useActions";

const actionCreators = {
  setCompanyWebsite: (payload) => ({
    type: SET_COMPANY_WEBSITE,
    payload,
  }),
};

export const useCompanyWebsite = () => {
  const companyWebsite = useSelector(
    (state) => state.storefront.webPresence.companyWebsite
  );
  const actions = useActions(actionCreators);

  return { companyWebsite, actions };
};
