import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { MEETING_READ_MODE } from "../../ServiceProvider/serviceProviderConstants";
import {
  getTimeFromDate,
  isMobileDevice,
  isProPlan,
} from "../../../utils/AppUtils";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";

const localizer = momentLocalizer(moment);

const useStyles = makeStyles((theme) => ({
  root: {
    "& .rbc-day-slot .rbc-event-label": {
      display: "none",
    },
    "& .rbc-current-time-indicator": {
      height: 2,
      backgroundColor: "#ea4335",
    },
    "& .rbc-event-content": {
      whiteSpace: "break-spaces",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-webkit-line-clamp": 2,
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      wordBreak: "break-all",
    },
    "& .rbc-day-slot": {
      height: 2300,
    },
    "& .rbc-time-gutter ": {
      height: 2300,
    },
  },
}));

function CustomToolbar(props) {
  const { label } = props;

  useEffect(() => {
    isMobileDevice() ? setNewView("day") : setNewView("week");
  }, []);

  const navigate = (action) => {
    props.onNavigate(action);
  };

  const setNewView = (view) => {
    props.onView(view);
  };

  if (isMobileDevice()) return null;
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        flexBasis: "unset",
        alignItems: "center",
        marginBottom: 12,
      }}
    >
      <Grid item md={6} xs={12}>
        <Typography variant={isMobileDevice() ? "subtitle1" : "h6"}>
          {label}
        </Typography>
      </Grid>
      <Grid
        item
        container
        md={6}
        xs={12}
        style={{ justifyContent: isMobileDevice() ? "center" : "flex-end" }}
      >
        <Button
          variant="outlined"
          style={{
            color: "#0E1C4E",
            textTransform: "capitalize",
            backgroundColor: props.view === "day" ? "#ffffff" : "transparent",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderRight: "transparent",
          }}
          onClick={() => {
            setNewView("day");
          }}
        >
          Day
        </Button>
        <Button
          variant="outlined"
          style={{
            color: "#0E1C4E",
            textTransform: "capitalize",
            backgroundColor: props.view === "week" ? "#ffffff" : "transparent",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderRight: "transparent",
          }}
          onClick={() => {
            setNewView("week");
          }}
        >
          Week
        </Button>
        <Button
          variant="outlined"
          style={{
            color: "#0E1C4E",
            textTransform: "capitalize",
            backgroundColor: props.view === "month" ? "#ffffff" : "transparent",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
          onClick={() => {
            setNewView("month");
          }}
        >
          Month
        </Button>
      </Grid>
    </Grid>
  );
}

function weekHeader(props) {
  const { label } = props;
  return (
    <div>
      <div
        style={{
          fontSize: 12,
        }}
      >
        {label.split(" ")[1]}
      </div>
      <div
        className="sq-header-date"
        style={{
          display: "inline-block",
          borderRadius: "100%",
          padding: "3px 6px",
          fontSize: 14,
        }}
      >
        {label.split(" ")[0]}{" "}
      </div>
    </div>
  );
}

function MyCustomEvent(props) {
  const eventData = props.event;
  if (!eventData) {
    return null;
  }
  const timeLabel =
    getTimeFromDate(eventData.start) + " - " + getTimeFromDate(eventData.end);
  return (
    <div style={{}}>
      <div style={{ marginBottom: 4, fontSize: 14 }}>{props.title}</div>
      {timeLabel && !eventData.allDay ? (
        <div style={{ fontSize: 12 }}>{timeLabel}</div>
      ) : null}
    </div>
  );
}

export default function CalendarRightPanel({
  currentCalendarDate,
  setCurrentCalendarDate,
  calendarEvents,
  mainCalendarView,
  setMainCalendarView,
  setOpenCreateMeetingDialog,
  setMeetingMode,
  setMeetingData,
  getCalendarEventDetails,
  findEventInList,
}) {
  const classes = useStyles();
  const userInfo = useAuthUser();

  const components = {
    toolbar: CustomToolbar,
    week: {
      event: MyCustomEvent,
    },
    month: {
      event: MyCustomEvent,
    },
    day: {
      event: MyCustomEvent,
    },
  };
  return (
    <Grid
      item
      xs={12}
      className={classes.root}
      style={{
        height: "100%",
        width: "100%",
        padding: isMobileDevice() ? "20px 20px 500px 20px" : "60px 40px",
      }}
    >
      <Calendar
        localizer={localizer}
        events={calendarEvents || []}
        startAccessor="start"
        endAccessor="end"
        toolbar={true}
        date={new Date(moment(currentCalendarDate).format())}
        components={components}
        onNavigate={(day) => {
          setCurrentCalendarDate(day);
        }}
        onSelectEvent={(calendarEvent, event) => {
          if (!isProPlan(userInfo)) {
            findEventInList(calendarEvent);
            // getCalendarEventDetails(
            //   calendarEvent.resource.eventId,
            //   (fullData) => {
            //     setMeetingData(fullData);
            //     setMeetingMode(MEETING_READ_MODE);
            //     setOpenCreateMeetingDialog(true);
            //   }
            // );
          }
        }}
        onView={(view) => {
          setMainCalendarView(view);
        }}
        eventPropGetter={(event, start, end, isSelected) => ({
          event,
          start,
          end,
          isSelected,
          style: { backgroundColor: !event.resource.isSouqhEvent && "green" },
        })}
      />
    </Grid>
  );
}
