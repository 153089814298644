import { useAuthUser } from "../../useLogin";
import { useSelector, useDispatch } from "react-redux";
import { useApiClient } from "../../useApiClient";
import { SET_CLIENT_DOCUMENTS } from "../../../souqh-redux/reducers/actionConstants";

export const useClientDocuments = (userId, selectedDocId, serviceRequestId) => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();

  const { clientDocuments } = useSelector((state) => state.clientManagement);

  const getClientDocuments = () => {
    if (userId) {
      dispatch({ type: "SET_LOADING", value: true });
      return apiClient
        .get(`/service-provider/client/documents/${userId}`)
        .then(function (response) {
          if (response.data.result && response.data.result.length) {
            dispatch({
              type: SET_CLIENT_DOCUMENTS,
              payload: response.data.result,
            });
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const requestDocumentAccess = (selectedSignNowDocId, onResult) => {
    if (userId && selectedDocId && serviceRequestId) {
      const url = selectedSignNowDocId
        ? `/service-provider/client/documents/request-access/sign-now/${selectedDocId}/${userId}/${serviceRequestId}`
        : `/service-provider/client/documents/request-access/${selectedDocId}/${userId}/${serviceRequestId}`;

      dispatch({ type: "SET_LOADING", value: true });
      return apiClient
        .post(url)
        .then(function (response) {
          if (response.data.status === 200) {
            onResult && onResult(response);
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  return {
    getClientDocuments,
    clientDocuments,
    requestDocumentAccess,
  };
};
