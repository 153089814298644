import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  Chip,
  Tooltip,
  Switch,
} from "@material-ui/core";
import React, { useEffect } from "react";
import ImageCropper from "../../ImageCropper";
import { PaperLikeGrid } from "./ServiceList";
import {
  useServices,
  useService,
} from "souqh-react-redux-hooks/serviceProvider/storefront/useServices";
import Styles from "../../../Styles.module.css";
import BlobManager from "../../../utils/BlobManager";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import clsx from "clsx";

function ServiceForm({
  operation = "create",
  index,
  onUpdate,
  edit = false,
  setShowNewForm,
  sourcePage,
}) {
  // operation would be either create or update

  const {
    actions: { addService, updateService, setExternalLinksSupport },
  } = useServices();

  const {
    service,
    uploadDocUrl,
    setUploadDocUrl,
    handleOnChange,
    name,
    description,
    label,
    link,
    image: imageUrl,
    setname,
    setdescription,
    setLabel,
    setLink,
    setimage,
    maxFileSize,
    externalLinksSupport,
    getFormattedFileName,
    updateExistingService,
    error,
  } = useService(index);

  useEffect(() => {
    if (service) {
      setname(service.name);
      setdescription(service.description);
      setLink(service.link);
      setLabel(service.label);
      // const imageUrl = BlobManager.getBlobURLByFileName(image);
      // setimage(BlobManager.getBlobURLByFileName(service.image));
      setimage(service.imageUrl);
      const tempDoc = service.uploadDocUrl ? service.uploadDocUrl : "";
      if (tempDoc) {
        setUploadDocUrl(tempDoc);
      } else {
        setUploadDocUrl(null);
      }
      // service && service.image && !service.imageURL
      //   ? setimage(service.image)
      //   : setimage(service.imageURL);
    }
  }, [service]);

  useEffect(() => {
    if (!externalLinksSupport) {
      setLink("");
      setLabel("");
      setUploadDocUrl(null);
    }
  }, [externalLinksSupport]);

  return (
    <PaperLikeGrid style={{ padding: 24 }}>
      <Grid container>
        <Tooltip title="Please add service/product image">
          <Grid container item xs={5}>
            <ImageCropper
              type={`service-`}
              cropWidth={250}
              cropHeight={250}
              placeholderImage="/images/logo.svg"
              image={imageUrl}
              onImageAvailalbe={(image) => {
                setimage(image);
              }}
              edit={edit}
              serviceImageHeight={true}
            ></ImageCropper>
          </Grid>
        </Tooltip>
        <Grid
          container
          item
          xs={7}
          style={{ paddingLeft: 10 }}
          direction="column"
          justifyContent="space-between"
        >
          <TextField
            error={error}
            helperText={error}
            placeholder="Please enter each service name"
            variant="outlined"
            name="name"
            fullWidth
            required
            style={{ marginTop: 0 }}
            value={name}
            onChange={(e) => {
              setname(e.target.value);
            }}
            inputProps={{
              maxLength: 256,
            }}
          />
          <TextField
            placeholder="Please enter each service description and click + Add"
            variant="outlined"
            name="description"
            fullWidth
            multiline
            rows={sourcePage === "manageStoreFront" ? 12 : 8}
            rowsMax={sourcePage === "manageStoreFront" ? 12 : 8}
            required
            style={{ marginBottom: 0, marginTop: 0 }}
            value={description}
            onChange={(e) => {
              setdescription(e.target.value);
            }}
            inputProps={{
              maxLength: 2000,
              className: Styles.navyBlueColor,
            }}
          />
        </Grid>
        <Grid container item justifyContent="flex-end">
          <Typography variant="body2" style={{ color: "#17174C99" }}>
            {description.length}/2000
          </Typography>
        </Grid>
      </Grid>
      {uploadDocUrl ? (
        <Chip
          label={getFormattedFileName(uploadDocUrl)}
          variant="outlined"
          style={{ marginLeft: 10, marginBottom: 10 }}
          icon={<InsertDriveFileIcon />}
        />
      ) : (
        ""
      )}
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        style={{ width: "100%", marginTop: 30, marginBottom: 15 }}
      ></Divider>
      <Grid item container>
        <Grid item container xs={10}>
          <Typography
            variant="subtitle1"
            className={Styles.navyBlueColor}
            style={{ marginBottom: 10 }}
          >
            Would you like to add supporting material for this service?
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={2}
          justifyContent="flex-end"
          style={{ marginTop: "-5px" }}
        >
          <Switch
            className={"sqGraySwitch"}
            checked={externalLinksSupport}
            onChange={(e) => {
              setExternalLinksSupport({ check: e.target.checked });
            }}
          ></Switch>
        </Grid>
      </Grid>

      {externalLinksSupport && (
        <Grid>
          <TextField
            placeholder="Add label"
            variant="outlined"
            fullWidth
            inputProps={{
              className: Styles.navyBlueColor,
              maxLength: 100,
            }}
            value={label}
            onChange={(e) => {
              setLabel(e.target.value);
            }}
          ></TextField>
          <TextField
            placeholder="Add hyperlink"
            variant="outlined"
            fullWidth
            style={{ marginBottom: 16 }}
            value={link}
            inputProps={{
              className: Styles.navyBlueColor,
            }}
            onChange={(e) => {
              setLink(e.target.value);
            }}
          ></TextField>
          <Grid container justifyContent="center" style={{ marginBottom: 16 }}>
            <Typography className={Styles.navyBlueColor}>or</Typography>
          </Grid>
          <Grid container justifyContent="center" style={{ marginBottom: 16 }}>
            <input
              accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .png, .jpeg, .bmp, .gif, .CSV"
              style={{ display: "none" }}
              id="contained-button-file"
              multiple
              type="file"
              onChange={handleOnChange}
            />
            <label htmlFor="contained-button-file" style={{ marginBottom: 16 }}>
              <Button
                variant="outlined"
                color="default"
                component="span"
                startIcon={<img src="/images/Icon material-file-upload.png" />}
                style={{ textTransform: "capitalize" }}
                className={Styles.navyBlueColorForce}
              >
                Upload
              </Button>
            </label>
            <Typography
              variant="caption"
              align="center"
              className={Styles.navyBlueColor}
            >
              You will be able to upload .doc, .docx, .xls, .xlsx, .ppt, .pptx,
              .pdf, .jpeg, .bmp, .png, .jpg files with maximum supported size of
              50 MB per file
            </Typography>
          </Grid>
          {maxFileSize && (
            <Typography
              variant="body2"
              style={{ marginBottom: 10, color: "red" }}
            >
              File size is restricted to 50 MB only.
            </Typography>
          )}
        </Grid>
      )}
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        style={{ width: "100%", marginTop: 30 }}
      ></Divider>

      <Grid container style={{ padding: "10px 0px" }} justifyContent="flex-end">
        <Button
          variant="outlined"
          style={{ textTransform: "capitalize", marginRight: "10px" }}
          className={Styles.navyBlueColorForce}
          onClick={() => {
            onUpdate && onUpdate();
            setShowNewForm && setShowNewForm(false);
          }}
        >
          Cancel
        </Button>

        <Button
          disabled={!(name.trim() || description.trim() || link.trim())}
          variant="outlined"
          style={{ textTransform: "capitalize", marginRight: "10px" }}
          className={Styles.navyBlueColorForce}
          onClick={() => {
            setname("");
            setdescription("");
            setimage("");
            setLabel("");
            setLink("");
            setUploadDocUrl(null);
          }}
        >
          Reset
        </Button>
        <Button
          disabled={!(name.trim() && description.trim())}
          variant="outlined"
          style={{ textTransform: "capitalize" }}
          className={clsx(Styles.navyBlueColorForce, Styles.primaryBtnAccent)}
          onClick={() => {
            if (operation === "create") {
              const timeStampId = Date.now() + Math.random() + "";
              addService({
                service: {
                  id: null,
                  name,
                  imageUrl,
                  label,
                  link,
                  description,
                  uploadDocUrl,
                  timeStampId,
                },
              });
              setname("");
              setdescription("");
              setimage("");
              setLabel("");
              setLink("");
              setUploadDocUrl(null);
              setShowNewForm && setShowNewForm(false);
            }
            if (operation === "update") {
              let serviceData = {
                index,
                service: {
                  id: service.id,
                  name,
                  imageUrl,
                  description,
                  uploadDocUrl,
                  label,
                  link,
                  timeStampId: service.timeStampId,
                },
              };
              if (service.id) {
                //if service already present update service api call
                updateExistingService(serviceData);
              } else {
                //if new service update store and service will get saved on save or save and continue button
                updateService(serviceData);
              }
              onUpdate && onUpdate();
              return;
            }
          }}
        >
          {operation === "create" ? "Save" : "update"}
        </Button>
      </Grid>
    </PaperLikeGrid>
  );
}

export default ServiceForm;
