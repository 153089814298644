import React, { useState, useEffect } from "react";
import { CSVDownloader, CSVReader } from "react-papaparse";
import {
  Divider,
  Grid,
  Paper,
  Typography,
  makeStyles,
  Switch,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Checkbox,
  TableCell,
  TableBody,
  Tabs,
  Tab,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Link,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import {
  usePreferredSP,
  useSPMember,
} from "souqh-react-redux-hooks/serviceProvider/usePreferredSP";
import { filter, map } from "lodash";
import AddIcon from "@material-ui/icons/Add";
import { useBusinessType } from "souqh-react-redux-hooks/useBusinessType";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmationDialog from "../../ConfirmationDialog";
import Styles from "../../../Styles.module.css";
import { useTheme } from "souqh-react-redux-hooks/serviceProvider/storefront/useTheme";
import { APPROVED } from "../../ServiceProvider/serviceProviderConstants";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import MaskedMobileInput from "../../MaskedMobileInput";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "20px 0px",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    "& .MuiTypography-root": {
      margin: theme.spacing(1),
    },
  },
  buttons: {
    textTransform: "capitalize",
    color: "#0E1C4E",
    lineHeight: 1.3,
  },
}));

function PreferredSP() {
  const classes = useStyles();
  const {
    preferredSPSupport,
    preferredSPs,
    allowAction,
    actions: {
      setPreferredSpSupport,
      addPreferredSP,
      deletePreferredSP,
      savePreferrefSP,
    },
  } = usePreferredSP();
  const {
    validations: preferredSPValidations,
    spType,
    setSPType,
    businessTypeName,
    setBusinessTypeName,
    spFullName,
    setSPFullName,
    email: spEmail,
    setSPEmail,
    allowAddSP,
    phoneNumber,
    setPhoneNumber,
    website,
    setWebsite
  } = useSPMember();

  let { businessTypes } = useBusinessType();
  // Remove other option
  businessTypes = businessTypes.filter((type) => {
    return (type.name = type.id === 7 ? "General Contractor" : type.name);
  });

  const [openCreditPolicy, setOpenCreditPloicy] = useState(false);
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
    useState(false);
  const [index, setindex] = useState();
  const userInfo = useAuthUser();

  return (
    <Grid
      item
      className={classes.root}
      id="your_team"
      style={{ paddinBottom: 40 }}
    >
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={() => setOpenCreditPloicy(false)}
        aria-labelledby="simple-dialog-title"
        open={openCreditPolicy}
      >
        <DialogContent className={Styles.navyBlueColor}>
          Coming Soon
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setOpenCreditPloicy(false)}
            className={Styles.navyBlueColorForce}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={openDeleteConfirmationDialog}
        handleYes={() => {
          deletePreferredSP({ index: index });
          setOpenDeleteConfirmationDialog(false);
        }}
        handleNo={() => {
          setOpenDeleteConfirmationDialog(false);
        }}
        sectionTitle={"Do you want to delete this Service Provider?"}
      />

      {userInfo.spApprovalStatus !== APPROVED ? (
        <React.Fragment>
          <Grid style={{ marginTop: 20 }}>
            <Paper elevation={0}>
              <Grid container style={{ padding: "10px 26px 10px 23px" }}>
                <Typography
                  variant="subtitle1"
                  className={Styles.navyBlueColor}
                >
                  Refer your network and earn bonus credits
                </Typography>
              </Grid>
              <Divider />
              <Grid item container style={{ padding: "10px 26px 10px 23px" }}>
                <Grid item container xs={10}>
                  <Typography variant="body1" className={Styles.navyBlueColor}>
                    Would you like to invite other service providers in your
                    network?
                  </Typography>
                  <Link
                    component="button"
                    style={{ textTransform: "capitalize", color: "#FA7E61" }}
                    onClick={() =>
                      window.open(
                        "https://help.souqh.ca/en/articles/5331204-referring-other-service-providers-to-souqh",
                        "_blank"
                      )
                    }
                  >
                    Learn More
                  </Link>
                </Grid>
                <Grid item container xs={2} justifyContent="flex-end">
                  <Switch
                    className={"sqGraySwitch"}
                    checked={preferredSPSupport}
                    onChange={(e) => {
                      setPreferredSpSupport({ check: e.target.checked });
                    }}
                  ></Switch>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {preferredSPSupport && (
            <>
              {preferredSPs.length ? (
                <Grid style={{ marginTop: 20 }}>
                  <Paper elevation={0}>
                    <Grid item>
                      <TableContainer>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Checkbox color="primary" />
                              </TableCell>
                              <TableCell>Service Provider Type</TableCell>
                              <TableCell>Full Name</TableCell>
                              <TableCell>Email Address</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {preferredSPs.map((a, index) => (
                              <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                  <Checkbox color={Styles.navyBlueColorForce} />
                                </TableCell>
                                <TableCell>
                                  <Select
                                    value={a.spType}
                                    onChange={(e) => {
                                      savePreferrefSP({
                                        field: "spType",
                                        value: e.target.value,
                                        index,
                                      });
                                    }}
                                    label="Service Provider Type"
                                  >
                                    {businessTypes.map(
                                      (businessType, index) => (
                                        <MenuItem
                                          value={businessType.id}
                                          key={index}
                                        >
                                          {businessType.name}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </TableCell>

                                <TableCell>
                                  <TextField
                                    required
                                    value={a.spFullName}
                                    onChange={(e) => {
                                      savePreferrefSP({
                                        field: "spFullName",
                                        value: e.target.value,
                                        index,
                                      });
                                    }}
                                  ></TextField>
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    required
                                    value={a.spEmail}
                                    onChange={(e) => {
                                      savePreferrefSP({
                                        field: "spEmail",
                                        value: e.target.value,
                                        index,
                                      });
                                    }}
                                  ></TextField>
                                </TableCell>
                                <TableCell>
                                  <DeleteIcon
                                    onClick={() => {
                                      setOpenDeleteConfirmationDialog(true);
                                      setindex(index);
                                    }}
                                  ></DeleteIcon>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                        {!allowAction && (
                          <Typography variant="body2" style={{ color: "red" }}>
                            Service Provider type ,name and email id should not
                            blank.
                          </Typography>
                        )}
                      </TableContainer>
                    </Grid>
                  </Paper>
                </Grid>
              ) : (
                ""
              )}

              <Grid style={{ marginTop: 20 }}>
                <Paper elevation={0}>
                  <Grid container style={{ padding: 10 }}>
                    <Typography
                      variant="subtitle1"
                      className={Styles.navyBlueColor}
                    >
                      Invite Service Providers
                    </Typography>
                  </Grid>
                  <Divider />
                  <Grid container style={{ padding: 10 }}>
                    <FormControl
                      variant="outlined"
                      className="MuiFormControl-fullWidth"
                      style={{ paddingLeft: 8, paddingRight: 8 }}
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        style={{ paddingLeft: 8 }}
                      >
                        Service Provider Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={spType}
                        onChange={(e) => {
                          setSPType(e.target.value);
                          setBusinessTypeName(e.target.name);
                        }}
                        label="Servie Provider Type"
                      >
                        {businessTypes.map((businessType, index) => (
                          <MenuItem
                            value={businessType.id}
                            name={businessType.name}
                            key={index}
                          >
                            {businessType.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      required
                      label="Full Name"
                      fullWidth
                      value={spFullName}
                      onChange={(e) => {
                        setSPFullName(e.target.value);
                      }}
                      variant="outlined"
                      inputProps={{
                        maxLength: 256,
                      }}
                    ></TextField>
                    <TextField
                      error={!!preferredSPValidations.email}
                      helperText={preferredSPValidations.email}
                      required
                      label="Email Address"
                      fullWidth
                      value={spEmail}
                      onChange={(e) => {
                        setSPEmail(e.target.value);
                      }}
                      variant="outlined"
                      inputProps={{
                        maxLength: 256,
                      }}
                    ></TextField>
                    <MaskedMobileInput
                      value={phoneNumber}
                      error={!!preferredSPValidations.phoneNumber}
                      helperText={
                        preferredSPValidations.phoneNumber &&
                        preferredSPValidations.phoneNumber.join(" ")
                      }
                      id="phoneNumber"
                      label="Phone Number"
                      name="phoneNumber"
                      variant="outlined"
                      type="tel"
                      fullWidth
                      onChange={(e) => {
                        setPhoneNumber(e.target.value.replaceAll("-", ""));
                      }}
                    ></MaskedMobileInput>
                    <TextField
                      id="website"
                      placeholder="Website"
                      label="Website"
                      name="website"
                      variant="outlined"
                      fullWidth
                      value={website}
                      onChange={(event) => {
                        setWebsite(event.target.value);
                      }}
                      inputProps={{
                        maxLength: 100,
                      }}
                    />
                  </Grid>
                  <Divider />
                  <Grid
                    container
                    style={{ padding: 10 }}
                    justifyContent="flex-end"
                  >
                    <Button
                      disabled={
                        !(spType || businessTypeName || spFullName || spEmail)
                      }
                      variant="outlined"
                      className={classes.buttons}
                      style={{ textTransform: "capitalize", marginRight: 20 }}
                      onClick={() => {
                        setSPType("");
                        setBusinessTypeName("");
                        setSPFullName("");
                        setSPEmail("");
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      disabled={!allowAddSP}
                      variant="outlined"
                      className={Styles.primaryBtnAccent}
                      startIcon={<AddIcon />}
                      style={{ textTransform: "capitalize" }}
                      onClick={() => {
                        addPreferredSP({
                          prefSP: {
                            spType: spType,
                            spFullName: spFullName,
                            spEmail: spEmail,
                            businessTypeName: businessTypeName,
                            phoneNumber: phoneNumber,
                            website: website
                          },
                        });
                        setSPType("");
                        setBusinessTypeName("");
                        setSPFullName("");
                        setSPEmail("");
                        setPhoneNumber("");
                        setWebsite("")
                      }}
                    >
                      Add New
                    </Button>
                  </Grid>
                </Paper>
              </Grid>
            </>
          )}
        </React.Fragment>
      ) : null}
    </Grid>
  );
}

export default PreferredSP;
