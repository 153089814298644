import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  makeStyles,
  TextField,
  Avatar,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import Rating from "@material-ui/lab/Rating";
import useMoveSPToCompleted from "souqh-react-redux-hooks/homeBuyer/spManagement/useMoveSPToCompleted";
import ContactNumber from "./ContactNumber";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minWidth: !isTabletDevice() && !isMobileDevice() && 800,
  },
}));

function MoveSPToCompletedDialog({
  openMoveSPToCompletedDialog,
  setOpenMoveSPToCompletedDialog,
  serviceRequestId,
  storefrontId,
  displayPicture,
  storefrontName,
  serviceName,
  email,
  contactNumber,
  getSpManagementData,
}) {
  const classes = useStyles();

  const {
    moveRequestToCompleted,
    ratingValue,
    setRatingValue,
    reviewComment,
    setReviewComment,
  } = useMoveSPToCompleted(serviceRequestId, storefrontId);
  return (
    <Dialog
      fullWidth
      disableBackdropClick
      open={openMoveSPToCompletedDialog}
      onClose={() => {
        setOpenMoveSPToCompletedDialog(false);
      }}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Move to Completed</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container>
          <Grid item xs={12} style={{ marginBottom: 20 }}>
            <Typography className={Styles.navyBlueColor} variant="body1">
              You are about to move{" "}
              <Typography variant="subtitle1" style={{ display: "inline" }}>
                {storefrontName}
              </Typography>{" "}
              to the Completed section. Please add a review for your service
              provider to let them and others know how they did!
            </Typography>
          </Grid>
          <Grid item xs={3} md={1}>
            <Avatar
              style={{ height: 56, width: 56, borderRadius: 4 }}
              variant="square"
              src={displayPicture}
            >
              N
            </Avatar>
          </Grid>
          <Grid container item xs={9} md={11} style={{ paddingLeft: 10 }}>
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <Typography
                  className={Styles.navyBlueColor}
                  variant="body1"
                  style={{ fontWeight: 500 }}
                >
                  {storefrontName}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid
                item
                xs={2}
                md={1}
                style={{
                  marginRight: !isTabletDevice() && !isMobileDevice() && -20,
                }}
              >
                <EmailIcon
                  className={Styles.navyBlueColor}
                  style={{ marginRight: 8 }}
                />
              </Grid>
              <Grid item xs={10} md={11}>
                <Typography
                  variant="body1"
                  className={clsx(Styles.navyBlueColor, Styles.sqEllipsis)}
                >
                  {email}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <CallIcon
                className={Styles.navyBlueColor}
                style={{ marginRight: 8 }}
              />
              <Typography variant="body1" className={Styles.navyBlueColor}>
                <ContactNumber contactNumber={contactNumber} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ margin: "10px 0px" }} />
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              className={Styles.navyBlueColor}
              style={{ fontWeight: 600 }}
            >
              Overall Rating
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Rating
              name="rating"
              value={ratingValue}
              className={Styles.navyBlueColorForce}
              onChange={(event, newValue) => {
                setRatingValue(newValue);
              }}
            />
          </Grid>
        </Grid>
        <Divider style={{ margin: "10px 0px" }} />
        <Grid item xs={12}>
          <Typography
            variant="body1"
            className={Styles.navyBlueColor}
            style={{ fontWeight: 600 }}
          >
            {serviceName}
          </Typography>
        </Grid>
        <Divider style={{ margin: "15px 0px" }} />
        <Grid item xs={12}>
          <Typography
            variant="body1"
            className={Styles.navyBlueColor}
            style={{ fontWeight: 600 }}
          >
            Add a written review
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 16 }}>
          <TextField
            multiline
            placeholder="Add comments"
            required
            rows={isMobileDevice() ? 5 : 9}
            rowsMax={isMobileDevice() ? 5 : 9}
            variant="outlined"
            fullWidth
            inputProps={{
              className: Styles.navyBlueColor,
              maxLength: 500,
            }}
            onChange={(event) => {
              setReviewComment(event.target.value);
            }}
          />
          <Grid container item justifyContent="flex-end">
            <Typography variant="body2" style={{ color: "#17174C99" }}>
              {reviewComment.length}/500
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions style={{ padding: "16px 37px" }}>
        <Button
          variant="outlined"
          style={{
            padding: "6px 16px",
            marginRight: 12,
            textTransform: "capitalize",
          }}
          className={Styles.navyBlueColorForce}
          onClick={() => {
            setOpenMoveSPToCompletedDialog(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!(ratingValue && reviewComment)}
          variant="contained"
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize", padding: "6px 32px" }}
          onClick={() => {
            moveRequestToCompleted((res) => {
              if (res && res.data.status === 200) {
                getSpManagementData();
                setOpenMoveSPToCompletedDialog(false);
              }
            });
          }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MoveSPToCompletedDialog;
