import { useState } from "react";
import { useDispatch } from "react-redux";
import { useApiClient } from "../../useApiClient";
import { useAuthUser } from "../../useLogin";
import useLocalStorage from "react-use-localstorage";
import { color_error } from "../../toasterColors";
import { map } from "lodash";

export const usePersonalInfo = (businessTypeSubCategories) => {
  const [userInfo, setUserInfo] = useLocalStorage("userInfo");
  const user = useAuthUser();
  const [email, setEmail] = useState(user.email);
  const [mobileNumber, setMobileNumber] = useState(user.contactNumber);
  const [enable2Fa, setEnable2Fa] = useState(user.enable2Factor);
  const [businessName, setBusinessName] = useState(user.businessName);
  const [businessType, setBusinessType] = useState("");
  const [otherBusinessTypeName, setOtherBusinessTypeName] = useState("");
  const [selectedBusinessType, setSelectedBusinessType] = useState([]);

  const { apiClient } = useApiClient();
  const dispatch = useDispatch();

  const savePersonalDetails = (onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .put(`/user/settings/personal-information`, {
        businessName: businessName !== user.businessName ? businessName : "",
        businessTypeId:
          businessType.id !== user.businessTypes[0].id ? businessType.id : "",
        contactNumber: mobileNumber,
        is2FAEnabled: enable2Fa,
        otherBusinessName: otherBusinessTypeName ? otherBusinessTypeName : null,
        subBusinessTypeIds: map(businessTypeSubCategories, "id"),
        userId: user.userId,
      })
      .then((res) => {
        if (res && res.data.status === 200) {
          user.contactNumber = mobileNumber;
          user.enable2Factor = enable2Fa;
          setUserInfo(JSON.stringify(user));
        }
        onResult && onResult(res);
      })
      .catch((error) => {
        dispatch({
          type: "SET_TOASTER",
          payload: {
            message: `Couldn't update data`,
            bgColor: color_error,
            open: true,
          },
        });
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };
  return {
    email,
    mobileNumber,
    enable2Fa,
    setMobileNumber,
    setEnable2Fa,
    savePersonalDetails,
    user,
    businessName,
    setBusinessName,
    businessType,
    setBusinessType,
    otherBusinessTypeName,
    setOtherBusinessTypeName,
    selectedBusinessType,
    setSelectedBusinessType,
  };
};
