import { useDispatch } from "react-redux";
import { useApiClient } from "../../useApiClient";

export const useUserEngagement = () => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();

  const setUserEngagementData = (storefrontId, label) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .put(
        `/service-provider/storefront/analytics/user-engagement/${storefrontId}?label=${label}`
      )
      .then(function (response) {
        if (response) {
          console.log(response);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };
  const increaseLeadCount = (storefrontId, label) => {
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .post(`/service-provider/storefront/lead/${storefrontId}`, null, {
        params: { label: label },
      })
      .then((response) => {
        if (response) {
          console.log(response);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };
  return {
    setUserEngagementData,
    increaseLeadCount,
  };
};
