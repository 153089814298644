import { Box, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { SketchPicker } from "react-color";
import { useTheme } from "souqh-react-redux-hooks/serviceProvider/storefront/useTheme";
import Styles from "../../../Styles.module.css";

function BrandTheme() {
  const {
    primaryColor: rPrimaryColor,
    secondaryColor: rSecondaryColor,
    actions,
  } = useTheme();

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [xPosition, setXPosition] = useState();
  const [yPosition, setYPosition] = useState();
  const [primaryColor, setPrimaryColor] = useState({
    color: { r: "23", g: "23", b: "76", a: "1" },
  });
  const [secondaryColor, setSecondaryColor] = useState({
    color: { r: "255", g: "141", b: "142", a: "1" },
  });
  const [selectedColor, setSelectedColor] = useState(null);
  const openColorPicker = (event, target) => {
    setXPosition(event.target.offsetLeft);
    setYPosition(event.target.offsetTop);
    setDisplayColorPicker({ displayColorPicker: !displayColorPicker });
    setSelectedColor(target);
  };
  const closeColorPicker = () => {
    setDisplayColorPicker(false);
  };
  const changeColor = (color) => {
    if (selectedColor === "primaryColor") {
      setPrimaryColor({ color: color.rgb });
      actions.setPrimaryColor({ primaryColor: color.hex });
    } else {
      setSecondaryColor({ color: color.rgb });
      actions.setSecondaryColor({ secondaryColor: color.hex });
    }
  };
  const popover = {
    position: "absolute",
    zIndex: "2",
    top: "17%",
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };
  const commonBorder = {
    border: "1px solid #17174C1F",
    borderRadius: "4px",
  };
  const boxBorder = {
    borderRadius: "4px",
  };
  return (
    <Grid container item xs={9} style={{ marginTop: 30 }}>
      <Grid item>
        <Typography variant="body1" className={Styles.navyBlueColor}>
          Colour Theme
        </Typography>
      </Grid>
      <Grid item container spacing={1} xs={12} style={{ marginTop: 10 }}>
        <Grid item md={6} xs={12}>
          <Box
            id="primaryColor"
            style={{ cursor: "pointer" }}
            borderRadius={3}
            bgcolor={rPrimaryColor}
            color="primary.contrastText"
            p={2}
            onClick={(event) => {
              openColorPicker(event, "primaryColor");
            }}
          >
            <Typography align="center">Primary color</Typography>
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box
            id="secondaryColor"
            style={{ cursor: "pointer" }}
            borderRadius={3}
            bgcolor={rSecondaryColor}
            color="primary.contrastText"
            p={2}
            onClick={(event) => {
              openColorPicker(event, "secondaryColor");
            }}
          >
            <Typography align="center">Secondary Color</Typography>
          </Box>
        </Grid>
      </Grid>

      {/* Commented because current design dow not have Five default color
      <Grid item container spacing={1}>
        <Grid xs={1} item container style={commonBorder}>
          <Grid item xs={6}>
            <Box
              bgcolor="primary.main"
              color="primary.contrastText"
              py={4}
              xs={6}
              style={boxBorder}
            ></Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              bgcolor="lightpink"
              color="primary.contrastText"
              py={4}
              style={boxBorder}
            ></Box>
          </Grid>
        </Grid>
        <Grid xs={1} item container style={commonBorder}>
          <Grid item xs={6}>
            <Box
              style={boxBorder}
              bgcolor="black"
              color="primary.contrastText"
              py={4}
            ></Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              bgcolor="white"
              color="primary.contrastText"
              style={{ borderRadius: "4px", border: "1px solid #000" }}
              py={4}
            ></Box>
          </Grid>
        </Grid>
        <Grid xs={1} item container style={commonBorder}>
          <Grid item xs={6}>
            <Box
              bgcolor="blue"
              color="primary.contrastText"
              py={4}
              style={boxBorder}
            ></Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              bgcolor="yellow"
              color="primary.contrastText"
              py={4}
              style={boxBorder}
            ></Box>
          </Grid>
        </Grid>
        <Grid xs={1} item container style={commonBorder}>
          <Grid item xs={6}>
            <Box
              bgcolor="cyan"
              color="primary.contrastText"
              py={4}
              style={boxBorder}
            ></Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              bgcolor="gray"
              color="primary.contrastText"
              py={4}
              style={boxBorder}
            ></Box>
          </Grid>
        </Grid>
        <Grid xs={1} item container style={commonBorder}>
          <Grid item xs={6}>
            <Box
              bgcolor="orange"
              color="primary.contrastText"
              py={4}
              style={boxBorder}
            ></Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              bgcolor="lightpink"
              color="primary.contrastText"
              py={4}
              style={boxBorder}
            ></Box>
          </Grid>
        </Grid>
      </Grid> */}
      {displayColorPicker ? (
        <div
          style={{
            position: "absolute",
            zIndex: "2",
            top: yPosition + 55,
            left: xPosition,
          }}
        >
          <div
            style={cover}
            onClick={() => {
              closeColorPicker();
            }}
          />
          <SketchPicker
            disableAlpha
            color={
              selectedColor === "primaryColor" ? rPrimaryColor : rSecondaryColor
            }
            onChange={(color) => {
              changeColor(color);
            }}
            onChangeComplete={(color) => {
              changeColor(color);
            }}
          />
        </div>
      ) : null}
    </Grid>
  );
}
export default BrandTheme;
