import { cloneDeep } from "lodash";
import { SET_CLIENT_HISTORY } from "./actionConstants";

const initialState = {
  clientHistory: [],
};

const clientHub = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_CLIENT_HISTORY:
      newState = cloneDeep(state);
      newState.clientHistory = action.payload;
      return newState;
    default:
      return state;
  }
};

export default clientHub;
