import { Grid, Typography, Divider, Container } from "@material-ui/core";
import { VideoAdaptiveProbePolicy } from "amazon-chime-sdk-js";
import moment from "moment";
import React from "react";
import Styles from "../../../Styles.module.css";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";
import ForecastedResult from "./ForecastedResult";
import TimeBudget, { AddOn } from "./TimeBudget";

function NewCampaignStep3({ section, campaignData, locationsServed }) {
  if (campaignData) {
    var {
      name,
      campaignUserType,
      campaignStage,
      ageRange,
      maritalStatus,
      employmentStatus,
      houseHoldIncomeRange,
      propertyType,
      industry,
      addOnHomePage,
      campaignPlanId,
      campaignStartDate,
    } = campaignData;
  }

  return (
    <React.Fragment>
      <Grid
        container
        item
        xs={12}
        className="hideScroll"
        style={{
          marginTop: isMobileDevice() || isTabletDevice() ? 20 : 0,
          minHeight: (isMobileDevice() || isTabletDevice()) && "40rem",
          overflow: (isMobileDevice() || isTabletDevice()) && "hidden",
        }}
      >
        <Grid
          container
          item
          xs={12}
          lg={8}
          style={{
            height: (isMobileDevice() || isTabletDevice()) && 430,
            overflow: (isMobileDevice() || isTabletDevice()) && "scroll",
          }}
        >
          <Container maxWidth="md">
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <Typography
                  style={{
                    fontSize: "0.625rem",
                    color: "#868DA6",
                    marginTop: !isMobileDevice() && !isTabletDevice() && 16,
                  }}
                >
                  GENERAL
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle1"
                  className={Styles.navyBlueColor}
                  style={{ marginTop: 12 }}
                >
                  Campaign Name
                </Typography>
                <Typography
                  variant="body2"
                  className={Styles.navyBlueColor}
                  style={{ marginTop: 4 }}
                >
                  {name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle1"
                  className={Styles.navyBlueColor}
                  style={{ marginTop: 12 }}
                >
                  Region
                </Typography>
                {locationsServed
                  ? locationsServed.map((location, i) => (
                      <Grid key={i}>
                        <Typography
                          variant="subtitle1"
                          className={Styles.navyBlueColor}
                        >
                          {location.province.name}
                        </Typography>
                        <Grid item>
                          {location.cities
                            ? location.cities.map((city, i) => (
                                <Typography
                                  variant="body2"
                                  key={i}
                                  className={Styles.navyBlueColor}
                                >
                                  {city.name}
                                </Typography>
                              ))
                            : null}
                        </Grid>
                      </Grid>
                    ))
                  : null}
              </Grid>
              {campaignUserType && campaignUserType.length ? (
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle1"
                    className={Styles.navyBlueColor}
                    style={{ marginTop: 20 }}
                  >
                    User Type
                  </Typography>
                  {campaignUserType.map((data, i) => (
                    <Typography
                      key={i}
                      variant="body2"
                      className={Styles.navyBlueColor}
                      style={{ marginTop: 4 }}
                    >
                      {data.name}
                    </Typography>
                  ))}
                </Grid>
              ) : null}
              {campaignStage && campaignStage.length ? (
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle1"
                    className={Styles.navyBlueColor}
                    style={{ marginTop: 20 }}
                  >
                    Stage
                  </Typography>
                  {campaignStage.map((data, i) => (
                    <Typography
                      key={i}
                      variant="body2"
                      className={Styles.navyBlueColor}
                      style={{ marginTop: 4 }}
                    >
                      {data.value}
                    </Typography>
                  ))}
                </Grid>
              ) : null}
              {(ageRange && ageRange.length) ||
              (maritalStatus && maritalStatus.length) ? (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        fontSize: "0.625rem",
                        marginTop: 50,
                        color: "#868DA6",
                      }}
                    >
                      DEMOGRAPHIC
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </React.Fragment>
              ) : null}
              {ageRange && ageRange.length ? (
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle1"
                    className={Styles.navyBlueColor}
                    style={{ marginTop: 12 }}
                  >
                    Age Range
                  </Typography>
                  {ageRange.map((data, i) => (
                    <Typography
                      key={i}
                      variant="body2"
                      className={Styles.navyBlueColor}
                      style={{ marginTop: 4 }}
                    >
                      {data.value}
                    </Typography>
                  ))}
                </Grid>
              ) : null}
              {maritalStatus && maritalStatus.length ? (
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle1"
                    className={Styles.navyBlueColor}
                    style={{ marginTop: 12 }}
                  >
                    Marital Status
                  </Typography>
                  {maritalStatus.map((data, i) => (
                    <Typography
                      key={i}
                      variant="body2"
                      className={Styles.navyBlueColor}
                      style={{ marginTop: 4 }}
                    >
                      {data.name}
                    </Typography>
                  ))}
                </Grid>
              ) : null}

              <Grid container item xs={12}>
                {(employmentStatus && employmentStatus.length) ||
                (houseHoldIncomeRange && houseHoldIncomeRange.length) ||
                (propertyType && propertyType.length) ||
                (industry && industry.length) ? (
                  <React.Fragment>
                    <Grid item xs={12}>
                      <Typography
                        style={{
                          fontSize: "0.625rem",
                          marginTop: 50,
                          color: "#868DA6",
                        }}
                      >
                        FINANCIAL
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </React.Fragment>
                ) : null}
                {employmentStatus && employmentStatus.length ? (
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle1"
                      className={Styles.navyBlueColor}
                      style={{ marginTop: 20 }}
                    >
                      Employment
                    </Typography>
                    {employmentStatus.map((data, i) => (
                      <Typography
                        key={i}
                        variant="body2"
                        className={Styles.navyBlueColor}
                        style={{ marginTop: 4 }}
                      >
                        {data.name}
                      </Typography>
                    ))}
                  </Grid>
                ) : null}
                {houseHoldIncomeRange && houseHoldIncomeRange.length ? (
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle1"
                      className={Styles.navyBlueColor}
                      style={{ marginTop: 20 }}
                    >
                      Household Income Range
                    </Typography>
                    {houseHoldIncomeRange.map((data, i) => (
                      <Typography
                        key={i}
                        variant="body2"
                        className={Styles.navyBlueColor}
                        style={{ marginTop: 4 }}
                      >
                        {data.value}
                      </Typography>
                    ))}
                  </Grid>
                ) : null}
                {propertyType && propertyType.length ? (
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle1"
                      className={Styles.navyBlueColor}
                      style={{ marginTop: 24 }}
                    >
                      Property Type
                    </Typography>
                    {propertyType.map((data, i) => (
                      <Typography
                        key={i}
                        variant="body2"
                        className={Styles.navyBlueColor}
                        style={{ marginTop: 4 }}
                      >
                        {data.value}
                      </Typography>
                    ))}
                  </Grid>
                ) : null}
                {industry && industry.length ? (
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle1"
                      className={Styles.navyBlueColor}
                      style={{ marginTop: 24 }}
                    >
                      Industry
                    </Typography>
                    {industry.map((data, i) => (
                      <Typography
                        key={i}
                        variant="body2"
                        className={Styles.navyBlueColor}
                        style={{ marginTop: 4 }}
                      >
                        {data.name}
                      </Typography>
                    ))}
                  </Grid>
                ) : null}
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 20 }}>
                <Divider style={{ margin: "30px 0" }} />
                <Typography
                  variant="subtitle1"
                  className={Styles.navyBlueColor}
                  style={{ marginTop: 4 }}
                >
                  Select Time period & Budget
                </Typography>
                <Grid item xs={isMobileDevice() ? 11 : 7}>
                  {name ? (
                    <TimeBudget data={campaignPlanId} step={"step3"} />
                  ) : null}
                </Grid>
                <Typography
                  variant="subtitle1"
                  className={Styles.navyBlueColor}
                  style={{ marginTop: 12 }}
                >
                  Start Date
                </Typography>
                <Typography
                  variant="body2"
                  className={Styles.navyBlueColor}
                  style={{ marginTop: 4, marginLeft: "0.25rem" }}
                >
                  {name && campaignStartDate
                    ? moment(new Date(campaignStartDate)).format("MMM DD, YYYY")
                    : ""}
                </Typography>
                {addOnHomePage ? (
                  <Grid item xs={12} md={7}>
                    <AddOn section={section} campaignData={campaignData} />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <ForecastedResult section={section} campaignData={campaignData} />
      </Grid>
    </React.Fragment>
  );
}

export default NewCampaignStep3;
