import { useDispatch } from "react-redux";
import { useAuthUser } from "../useLogin";
import { useApiClient } from "../useApiClient";

export const useShareDocuments = (
  selectedData,
  selectedContacts,
  getTabWiseCategoriesList,
  documentTabName,
  selectedActiveCustomers
) => {
  const { apiClient } = useApiClient();

  const dispatch = useDispatch();
  const userInfo = useAuthUser();

  let documentIdList = [];
  selectedData &&
    selectedData.length &&
    selectedData.map((item) => {
      documentIdList.push(item.docId);
    });

  let otherEmailList = [];
  selectedContacts &&
    selectedContacts.length &&
    selectedContacts.map((item) => {
      otherEmailList.push(item.email);
    });

  let sharedWithActiveUserList = [];
  selectedActiveCustomers &&
    selectedActiveCustomers.length &&
    selectedActiveCustomers.map((item) => {
      sharedWithActiveUserList.push(item.email);
    });

  let shareData = {
    activeUserList: sharedWithActiveUserList,
    documentIdList: documentIdList,
    otherEmailList: otherEmailList,
    userId: userInfo.userId,
  };

  let sharedDocumentsList = [];
  selectedData &&
    selectedData.length &&
    selectedData.map((item) => {
      sharedDocumentsList.push({
        docDescription: item.docDescription,
        docName: item.docName,
        isReferenceDocument: true,
      });
    });

  let signNowDocIds = [];
  selectedData &&
    selectedData.length &&
    selectedData.map((item) => {
      signNowDocIds.push(item.signNowDocumentId);
    });

  let shareSignNowData = {
    documentIds: signNowDocIds,
    uploadDocumentRequest: {
      sharedDocuments: [
        {
          documents: sharedDocumentsList,
        },
      ],
      sharedWithActiveUser: sharedWithActiveUserList,
      sharedWithOthers: otherEmailList,
    },
  };

  const shareDocuments = (onResult) => {
    let signedFlag = selectedData.some((item) => item.signNowDocumentId);
    let nonSignedFlag = selectedData.some((item) => !item.signNowDocumentId);
    if (signedFlag && nonSignedFlag) {
      dispatch({
        type: "SET_SERVER_ERROR",
        error: {
          message:
            "You can not share Reference documents and eSign documents together",
        },
      });
      return;
    }

    if (selectedData.every((item) => item.signNowDocumentId)) {
      shareSignNowDocuments(onResult);
    } else {
      shareReferenceDocuments(onResult);
    }
  };

  const shareReferenceDocuments = (onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post("/documents/share", shareData)
      .then((res) => {
        if (res.data.status === 200) {
          getTabWiseCategoriesList(documentTabName);
        }
        onResult && onResult(res);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const shareSignNowDocuments = (onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post(
        `/documents/share/sign-now/document/${userInfo.userId}`,
        shareSignNowData
      )
      .then((res) => {
        if (res.data.status === 200) {
          getTabWiseCategoriesList(documentTabName);
        }
        onResult && onResult(res);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return { shareDocuments };
};
