import { useState } from "react";
import { useAuthUser } from "../../useLogin";
import * as Stomp from "stompjs";
import * as SockJS from "sockjs-client";
import { useApiClient } from "../../useApiClient";
import { useDispatch, useSelector } from "react-redux";
import { processDownloadFileResponse } from "../../../web/src/utils/AppUtils";

export const useSignDocument = (reloadPendingActionData) => {
  const userInfo = useAuthUser();
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();
  const [openSignDocumentDialog, setOpenSignDocumentDialog] = useState(false);
  const [openUploadCancelDialog, setOpenUploadCancelDialog] = useState(false);
  const [signDocumentItem, setSignDocumentItem] = useState(null);

  const [webSocket, setWebSocket] = useState(null);
  const [stompClient, setStompClient] = useState(null);
  const [eventType, setEventType] = useState(null);
  const [urlForSign, setUrlForSign] = useState(null);
  const closeAndFinishSignDialog = () => {
    setSignDocumentItem(null);
    setEventType(null);
  };

  const connectToSocket = () => {
    const authToken = userInfo.access_token;
    if (authToken) {
      try {
        const url =
          process.env.REACT_APP_SOUQH_API_SERVER_BASE_URL + "/ws-notifications";
        const tempWebSocket = new SockJS(url);
        setWebSocket(tempWebSocket);
        let tempClient = Stomp.over(tempWebSocket);
        tempClient.debug = null;
        setStompClient(tempClient);
        tempClient.connect({ Authorization: authToken }, (frame) => {
          tempClient.subscribe("/queue/private", (response) => {
            setEventType(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  const loadSignnowUrl = (signNowDocumentId, userId = null) => {
    if (!signNowDocumentId) {
      return;
    }
    let _userId;
    if (userId) {
      _userId = userId;
    } else {
      _userId = userInfo.userId;
    }
    dispatch({ type: "SET_LOADING", value: true });
    const url = `signnow/view/document/${signNowDocumentId}/${_userId}`;
    apiClient
      .get(url)
      .then((response) => {
        if (response.data.status === 200) {
          setUrlForSign(response.data.result);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const disconnectFromSocket = () => {
    if (stompClient !== null) {
      stompClient.disconnect();
      setStompClient(null);
      setWebSocket(null);
    }
  };

  const processListener = (signDocumentItem, closeCurrentDialog) => {
    if (signDocumentItem && eventType && eventType.body) {
      const body = JSON.parse(eventType.body);
      if (body && body.documentId === signDocumentItem.signNowDocumentId) {
        if (body.event === "signed" || body.event === "role_updated") {
          setEventType(null);
          closeCurrentDialog && closeCurrentDialog();
          disconnectFromSocket();
          reloadPendingActionData && reloadPendingActionData();
        }
      }
    }
  };

  const downloadSignedDocument = (signNowDocumentId) => {
    if (signNowDocumentId) {
      const url = `signnow/download/document/${signNowDocumentId}/${userInfo.userId}/false`;
      dispatch({ type: "SET_LOADING", value: true });
      apiClient
        .get(url, { responseType: "blob" })
        .then((response) => {
          processDownloadFileResponse(response);
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  return {
    openSignDocumentDialog,
    setOpenSignDocumentDialog,
    openUploadCancelDialog,
    setOpenUploadCancelDialog,
    closeAndFinishSignDialog,
    signDocumentItem,
    setSignDocumentItem,
    eventType,
    setEventType,
    processListener,
    connectToSocket,
    loadSignnowUrl,
    urlForSign,
    setUrlForSign,
    downloadSignedDocument,
  };
};
