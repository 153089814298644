import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import { useInactiveReview } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useInactiveReview";

function InactiveReviewDialog({
  openInactiveReviewDialog,
  setOpenInactiveReviewDialog,
  reviewId,
}) {
  const { flagReview, flaggedReason, setFlaggedReason } =
    useInactiveReview(reviewId);

  return (
    <Dialog
      fullWidth
      disableBackdropClick
      maxWidth={"sm"}
      open={openInactiveReviewDialog}
      onClose={() => {
        setOpenInactiveReviewDialog(false);
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Reason for In-Active</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid item xs={12} style={{ paddingBottom: 16, paddingTop: 20 }}>
          <TextField
            multiline
            placeholder="Add comments"
            required
            rows={9}
            rowsMax={9}
            variant="outlined"
            fullWidth
            inputProps={{
              className: Styles.navyBlueColor,
              maxLength: 500,
            }}
            value={flaggedReason}
            onChange={(e) => {
              setFlaggedReason(e.target.value);
            }}
          />
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-end"
            style={{ marginTop: 20 }}
          >
            <Typography variant="body2" style={{ color: "#17174C99" }}>
              {flaggedReason.length}/500
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions style={{ padding: "16px 24px" }}>
        <Grid container item xs={12} justifyContent="flex-end">
          <Button
            variant="outlined"
            style={{
              padding: "6px 16px",
              marginRight: 12,
              textTransform: "capitalize",
            }}
            className={Styles.navyBlueColorForce}
            onClick={() => {
              setOpenInactiveReviewDialog(false);
              setFlaggedReason("");
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={Styles.primaryBtnAccent}
            style={{ textTransform: "capitalize", padding: "6px 32px" }}
            onClick={() => {
              flagReview((response) => {
                if (response && response.data.status === 200) {
                  setOpenInactiveReviewDialog(false);
                  setFlaggedReason("");
                }
              });
            }}
          >
            Send
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default InactiveReviewDialog;
