import { useTheme } from "@emotion/react";
import { Avatar, Grid, Tooltip, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useNavigateToMessage } from "souqh-react-redux-hooks/common/useNavigateToMessage";
import Styles from "../../../../Styles.module.css";
import {
  formatContactNumber,
  isMarketplacePlan,
  isMobileDevice,
  isTabletDevice,
} from "../../../../utils/AppUtils";
import RepresentativeMock2 from "../../../SPSetup/StoreFront/RepresentativeMock2.js";
import MessageIcon from "@material-ui/icons/Message";
import DateRangeIcon from "@material-ui/icons/DateRange";
import CallIcon from "@material-ui/icons/Call";
import useLocalStorage from "react-use-localstorage";
import { HOME_BUYER_USER } from "../../../ServiceProvider/serviceProviderConstants";
import { SocialIcon } from "react-social-icons";
import PlanUpgradeMsgDialog from "../../../common/PlanUpgradeMsgDialog";

export default function TeamExperts(props) {
  const {
    teamMembers,
    yearsInBusiness,
    numberOfEmployees,
    user,
    setUserEngagementData,
    increaseLeadCount,
    storeFrontId,
  } = props;
  const { primaryColor } = useTheme();
  const { navigateMessage } = useNavigateToMessage();
  let history = useHistory();
  const [loggedInUser, setLoggedInUser] = useLocalStorage("nonloggedInUser");
  const [openPlanUpgradeMsgDialog, setOpenPlanUpgradeMsgDialog] =
    useState(false);

  const experts = teamMembers.map((el, index) => {
    return (
      <Grid
        item
        container
        xs={12}
        key={index}
        style={{
          padding: "12px 0px",
          alignItems: "flex-start",
          marginBottom: 16,
        }}
      >
        <Grid item xs={2} md={1} lg={1}>
          <Avatar
            src={
              el.avatarUrl
                ? el.avatarUrl
                : (el.title || "").toLowerCase() === "miss"
                ? "/images/expert_female.svg"
                : "/images/expert_male.svg"
            }
            style={{ padding: isMobileDevice() && "0 16px" }}
          />
        </Grid>
        <Grid item xs={10} md={4} lg={4} style={{ paddingLeft: 16 }}>
          <Tooltip title={el.name}>
            <Typography variant="subtitle1" className={Styles.sqEllipsis}>
              {el.name}
            </Typography>
          </Tooltip>
          <Typography variant="body1" className={Styles.sqEllipsis}>
            {el.title}
          </Typography>
        </Grid>
        {(isMobileDevice() && JSON.parse(el.socialLinks).length) ||
        !isMobileDevice() ? (
          <React.Fragment>
            {isMobileDevice() && <Grid item xs={2} />}
            <Grid
              item
              xs={10}
              md={4}
              lg={5}
              style={{
                overflow: "auto",
                whiteSpace: "nowrap",
                paddingLeft: isMobileDevice() && 16,
                marginTop: isMobileDevice() && 8,
              }}
              className="hideScroll"
            >
              {el.socialLinks && JSON.parse(el.socialLinks).length
                ? JSON.parse(el.socialLinks).map((sl, index) => {
                    return (
                      <SocialIcon
                        key={index}
                        url={sl}
                        network={
                          sl.includes("www") ? sl.split(".")[1] : "sharethis"
                        }
                        style={{
                          width: 24,
                          height: 24,
                          marginRight: isMobileDevice() ? 24 : 8,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          let url = sl.indexOf("//") < 0 ? "http://" + sl : sl; //check if link is in valid format or not
                          window.open(url, "_blank");
                          // window.open(l, "_blank");
                          setUserEngagementData(storeFrontId, "Social_icon");
                        }}
                      />
                    );
                  })
                : null}
            </Grid>
          </React.Fragment>
        ) : null}
        {isMobileDevice() && <Grid item xs={2} />}
        <Grid
          container
          item
          xs={10}
          md={3}
          lg={2}
          style={{ paddingLeft: 16, marginTop: isMobileDevice() && 16 }}
        >
          <Grid item xs={2} md={4}>
            <MessageIcon
              style={{
                fill: primaryColor,
                cursor: "pointer",
              }}
              onClick={(event) => {
                setUserEngagementData(storeFrontId, "Message");
                increaseLeadCount(storeFrontId, "Message");
                if (user) {
                  if (isMarketplacePlan(user)) {
                    setOpenPlanUpgradeMsgDialog(true);
                  } else {
                    navigateMessage(event, el.email);
                  }
                } else {
                  history.push("/userlogin");
                  setLoggedInUser(
                    JSON.stringify({
                      redirectUrl: `/homebuyer/messaging?sendMessage=${el.email}`,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={2} md={4}>
            <Tooltip title={formatContactNumber(el.contactNumber) || ""}>
              <CallIcon
                style={{
                  fill: primaryColor,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setUserEngagementData(storeFrontId, "Call");
                  increaseLeadCount(storeFrontId, "Call");
                  if (isMobileDevice()) {
                    el.contactNumber &&
                      window.open(`tel:${el.contactNumber}`, "_self");
                  }
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={2} md={4}>
            <DateRangeIcon
              className={Styles.navyBlueColorForce}
              onClick={() => {
                setUserEngagementData(storeFrontId, "Calendar");
                increaseLeadCount(storeFrontId, "Calendar");
                if (user) {
                  if (user.userType === HOME_BUYER_USER) {
                    history.push("/homebuyer/calendar?scheduleMeeting=true");
                  } else {
                    if (isMarketplacePlan(user)) {
                      setOpenPlanUpgradeMsgDialog(true);
                    } else {
                      history.push(
                        "/serviceprovider/calendar?scheduleMeeting=true"
                      );
                    }
                  }
                } else {
                  history.push("/userlogin");
                }
              }}
            />
          </Grid>
        </Grid>
        {isMobileDevice() && <Grid item xs={3}></Grid>}
        {/* <Grid
          item
          xs={9}
          md={7}
          style={{ paddingLeft: isMobileDevice() && 16 }}
        >
          <Typography variant="body1" className={"sqTwoLineClamp"}>
            Meet {el.name} our subject matter expert.
          </Typography>
        </Grid> */}
      </Grid>
    );
  });

  return (
    <Grid item container xs={12}>
      <PlanUpgradeMsgDialog
        openPlanUpgradeMsgDialog={openPlanUpgradeMsgDialog}
        setOpenPlanUpgradeMsgDialog={setOpenPlanUpgradeMsgDialog}
      />
      <Grid
        item
        container
        xs={12}
        style={{ paddingTop: 24, paddingBottom: isTabletDevice() && 64 }}
      >
        <Grid item xs={12}>
          {!isMobileDevice() && (
            <Grid container item xs={12}>
              <Grid item xs={2} md={1} lg={1} />
              <Grid item xs={10} md={4} lg={4} style={{ paddingLeft: 16 }}>
                <Typography variant="subtitle1">Team Members</Typography>
              </Grid>
              <Grid item xs={10} md={4} lg={5}>
                <Typography variant="subtitle1">Social Links</Typography>
              </Grid>
              <Grid
                container
                item
                xs={10}
                md={3}
                lg={2}
                style={{ paddingLeft: 16 }}
              >
                <Typography variant="subtitle1">Quick Actions</Typography>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            {experts}
          </Grid>
        </Grid>
        {/* {!isMobileDevice() && (
          <Grid item xs={12} lg={2}>
            <RepresentativeMock2
              yearsInBusiness={yearsInBusiness}
              numberOfEmployees={numberOfEmployees}
            ></RepresentativeMock2>
          </Grid>
        )} */}
      </Grid>
    </Grid>
  );
}
