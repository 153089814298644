import { Grid } from "@material-ui/core";
import React from "react";
import AppsRightPanel from "./AppsRightPanel";
import AppsLeftPanel from "./AppsLeftPanel";

export default function Apps() {
  return (
    <Grid
      item
      container
      xs={12}
      style={{ height: window.innerHeight, marginLeft: 80 }}
    >
      <Grid item container xs={12} style={{ height: "100%" }}>
        <div
          style={{
            width: 300,
            backgroundColor: "#F5F6F8",
          }}
        >
          <AppsLeftPanel />
        </div>
        <div
          style={{
            width: "calc(100% - 300px)",
            backgroundColor: "#FFFFFF",
          }}
        >
          <AppsRightPanel />
        </div>
      </Grid>
    </Grid>
  );
}
