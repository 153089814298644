import React, { useEffect, useRef, useState } from "react";

export default function Documentiframe({ documentTagUrl }) {
  const iframeRef = useRef(null);
  const [showIframe, setShowIframe] = useState(true);
  useEffect(() => {
    if (documentTagUrl) {
      setShowIframe(false);
      setShowIframe(true);
    }
  });
  if (!documentTagUrl || !showIframe) {
    return null;
  }
  return (
    <iframe ref={iframeRef} src={documentTagUrl} width="100%" height="100%">
      iframe is not supported
    </iframe>
  );
}
