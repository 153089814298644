import React, { useCallback } from "react";
import {
  Typography,
  Button,
  Grid,
  makeStyles,
  ButtonBase,
  FormControl,
  TextField,
  Divider,
} from "@material-ui/core/";
import AddIcon from "@material-ui/icons/Add";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import clsx from "clsx";
import Styles from "../../../Styles.module.css";
import { useDropzone } from "react-dropzone";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined": {
      top: -8,
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px",
    },
    "& .file-drop": {
      height: "100%",
    },
  },
  tableHeader: {
    color: "#0E1C4E",
    fontSize: "1rem",
    fontWeight: "bold",
  },
  tableContent: {
    color: "#0E1C4E",
    fontSize: "1rem",
  },
  button: {
    color: "#0E1C4E",
    textTransform: "capitalize",
  },
  formControl: {
    minWidth: 120,
    width: "80%",
  },
}));

export default function SPUploadStep1({
  documentList,
  deleteDocument,
  updateDescription,
  onUploadFileChange,
  processUploadedFiles,
}) {
  const classes = useStyles();

  const cellContent = documentList.length
    ? documentList.map((fileData, index) => {
        return (
          <Grid item container xs={12} key={index}>
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              style={{ padding: "10px 0px" }}
            >
              <Grid item container xs={4} className={classes.tableContent}>
                <Typography
                  variant="body1"
                  style={{ width: "95%" }}
                  className={clsx(Styles.sqEllipsis, Styles.navyBlueColor)}
                >
                  {fileData.file.name}
                </Typography>
              </Grid>
              <Grid item container xs={7} className={classes.tableContent}>
                <FormControl className={classes.formControl}>
                  <TextField
                    error={
                      !fileData.description && fileData.hasUpdatedDescription
                    }
                    fullWidth
                    placeholder={"Please add a brief document description*"}
                    variant="outlined"
                    value={fileData.description}
                    style={{ backgroundColor: "#ffffff" }}
                    required
                    onChange={(e) => {
                      updateDescription(index, e.target.value);
                    }}
                    helperText={
                      !fileData.description && fileData.hasUpdatedDescription
                        ? "Please enter valid document description"
                        : ""
                    }
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                container
                xs={1}
                className={classes.tableContent}
                style={{ justifyContent: "flex-end", paddingRight: 48 }}
              >
                <ButtonBase>
                  <CancelOutlinedIcon
                    style={{ marginTop: 10 }}
                    onClick={() => {
                      deleteDocument(index);
                    }}
                  />
                </ButtonBase>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        );
      })
    : null;

  const tableHeader = (
    <Grid item container xs={12} style={{ padding: "16px 0px" }}>
      <Grid item container xs={4} className={classes.tableHeader}>
        <Typography variant="subtitle1" className={Styles.navyBlueColor}>
          Document Name
        </Typography>
      </Grid>
      <Grid item container xs={7} className={classes.tableHeader}>
        <Grid container>
          <Grid item>
            <Typography variant="subtitle1" className={Styles.navyBlueColor}>
              Description
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: 10, marginTop: 3 }}>
            <InfoOutlinedIcon fontSize="small" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={1} className={classes.tableHeader}></Grid>
    </Grid>
  );

  const onDrop = (acceptedFiles) => {
    processUploadedFiles(acceptedFiles, null);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Grid
      container
      style={{ padding: "12px 20px", height: "100%" }}
      className={classes.root}
    >
      <Grid
        item
        xs={12}
        container
        justifyContent="flex-end"
        style={{ margin: "4px 0px", paddingRight: 48 }}
      >
        <input
          style={{ display: "none" }}
          id="contained-button-file-upload"
          multiple
          type="file"
          onChange={onUploadFileChange}
        />
        <label htmlFor="contained-button-file-upload">
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            className={classes.button}
            component="span"
          >
            Add
          </Button>
        </label>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ height: "calc(100% - 80px)", overflow: "auto" }}
      >
        <div
          style={{ height: "100%" }}
          {...getRootProps()}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <input {...getInputProps()} />
          <Grid item container xs={12}>
            {tableHeader}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {cellContent}
            {!documentList.length ? (
              <Grid item xs={12} style={{ margin: "12px 0px" }}>
                <Typography
                  variant="body1"
                  style={{
                    margin: "64px 0px",
                    textAlign: "center",
                    opacity: 0.5,
                    fontStyle: "italic",
                    fontWeight: 300,
                  }}
                  className={Styles.navyBlueColor}
                >
                  No document added, Please click on add button
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}
