import {
  Grid,
  Typography,
  makeStyles,
  Card,
  useTheme,
  useMediaQuery,
  Link,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import clsx from "clsx";
import Styles from "../../../Styles.module.css";
import StoreIcon from "@material-ui/icons/Store";
import {
  ADVANCED,
  HELP_CENTER_LINK,
  PLUS,
  PREMIUM,
  PRO,
} from "../../ServiceProvider/serviceProviderConstants";
import ChangeSubscriptionDialog from "../../Settings/ChangeSubscriptionDialog";

const useStyles = makeStyles((theme) => ({
  spacingControl: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
  },
}));

function SubscriptionPlan({
  title,
  amount,
  subscriptionPlanFeatures,
  planId,
  planCase,
  defaultSelectedCard,
  setDefaultSelectedCard,
  setSelectedTeamPlan,
  type,
  planDuration,
  srcPage,
  setSelectedUserPlan,
  savePlanData,
  selectedTeamPlan,
  selectedUserPlan,
  selectedPrevUserPlan,
  setPrevSelectedUserPlan,
  plansList,
  changeSubscription,
  openChangeSubscriptionDialog,
  setOpenChangeSubscriptionDialog,
  selectedPrevPlanDuration,
  setPlanDuration,
}) {
  const classes = useStyles();
  const [selectedPlan, setSelectedPlan] = useState(selectedTeamPlan || 2);
  const theme = useTheme();
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  const proText =
    "All Pro features with a common storefront for all team members";
  const plusText =
    "All Plus features with a common storefront for all team members";
  const premiumText =
    "All Premium features with a individual storefronts for each team member";
  const advancedText =
    "All Advanced features with a individual storefronts for each team member";

  useEffect(() => {
    if (type === "teamPaymentPlan") {
      if (planCase === 1 && !selectedPlan) {
        setSelectedTeamPlan(null);
      } else {
        setSelectedTeamPlan(selectedPlan);
      }
    }
  }, [selectedPlan, planCase]);

  const changePlan = () => {
    setDefaultSelectedCard(title);
    if (type === "teamPaymentPlan") {
      setSelectedPlan(planId);
      setSelectedTeamPlan(selectedPlan);
    } else {
      setSelectedUserPlan(planId);
    }
  };

  const openConfirmationDialog = () => {
    setPrevSelectedUserPlan(selectedUserPlan);
    setOpenChangeSubscriptionDialog(true);
    setDefaultSelectedCard(title);
    setSelectedUserPlan(planId);
  };

  return (
    <React.Fragment>
      <ChangeSubscriptionDialog
        openChangeSubscriptionDialog={openChangeSubscriptionDialog}
        setOpenChangeSubscriptionDialog={setOpenChangeSubscriptionDialog}
        defaultSelectedCard={defaultSelectedCard}
        title={title}
        planDuration={planDuration}
        amount={amount}
        savePlanData={savePlanData}
        selectedPrevUserPlan={selectedPrevUserPlan}
        selectedUserPlan={selectedUserPlan}
        setSelectedUserPlan={setSelectedUserPlan}
        setDefaultSelectedCard={setDefaultSelectedCard}
        plansList={plansList}
        changeSubscription={changeSubscription}
        selectedPrevPlanDuration={selectedPrevPlanDuration}
        setPlanDuration={setPlanDuration}
      />

      <Card
        style={{
          color: "#17174C",
          border:
            defaultSelectedCard === title
              ? "4px solid #17174c"
              : "2px solid #0E1C4E1F",
          width: "100%",
          height: "100%",
        }}
        elevation={0}
      >
        <Grid
          disabled={
            type === "userPaymentPlan" ? (planCase === 1 ? true : false) : false
          }
          style={{
            cursor: "pointer",
            opacity:
              // planCase === 1 &&
              // srcPage !== "settingsPage" &&
              // type === "userPaymentPlan"
              //   ? 0.4
              // :
              1,
            height: "100%",
            pointerEvents:
              // planCase === 1 &&
              // (title === PRO || title === PLUS) &&
              // type === "userPaymentPlan" &&
              // srcPage !== "settingsPage"
              //   ? "none"
              // :
              "auto",
            backgroundColor:
              // type === "userPaymentPlan"
              //   ? planCase === 1 &&
              //     (title === PRO || title === PLUS) &&
              //     srcPage !== "settingsPage"
              //     ? "#0E1C4E1F"
              //     : "white"
              // :
              "white",
          }}
          onClick={() =>
            srcPage !== "settingsPage" ? changePlan() : openConfirmationDialog()
          }
        >
          <Grid container item sm={12} style={{ padding: 20 }}>
            {(title === PLUS && type === "teamPaymentPlan") || (title === PREMIUM && type !== "teamPaymentPlan") ? (
              <Grid
                container
                item
                sm={12}
                className={classes.spacingControl}
                justifyContent="flex-end"
                style={{ height: 24, position: "relative" }}
              >
                <img
                  src="/images/Most-Popular.svg"
                  style={{ position: "absolute", right: -20 }}
                />
              </Grid>
            ) : (
              <Grid
                container
                item
                sm={12}
                className={classes.spacingControl}
                justifyContent="flex-start"
                style={{ height: 24 }}
              ></Grid>
            )}
            <Grid
              container
              item
              sm={12}
              className={classes.spacingControl}
              justifyContent="center"
            >
              <img
                style={{ height: 200, width: 200 }}
                src={`/images/${title}.svg`}
              ></img>
            </Grid>
            <Grid container item sm={12} justifyContent="center">
              <Typography
                className={clsx(
                  classes.spacingControl,
                  classes.title,
                  Styles.navyBlueColor
                )}
                variant="h5"
                style={{ marginTop: 20 }}
              >
                {title}
              </Typography>
            </Grid>

            <Grid
              container
              item
              sm={12}
              className={classes.spacingControl}
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                container
                xs={12}
                style={{
                  alignItems: "center",
                  position: "relative",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    fontSize: 20,
                    fontWeight: "600",
                    alignItems: "flex-start",
                    justifyContent: "flex-end",
                    display: "flex",
                    paddingBottom: 40,
                    height: "100%",
                  }}
                >
                  $
                </div>
                <div
                  style={{
                    fontSize: 63,
                    lineHeight: 0,
                    fontWeight: 700,
                    display: "flex",
                  }}
                >
                  {amount.toString().split(".")[0]}
                </div>
                <div
                  style={{
                    fontWeight: "500",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      fontSize: 24,
                      lineHeight: "43px",
                      alignItems: "flex-end",
                      display: "flex",
                      height: "50%",
                      fontWeight: "600",
                    }}
                  >
                    {planDuration === "YEARLY" ? (
                      <span>{amount.toString().split(".")[1]}</span>
                    ) : (
                      <span style={{ visibility: "hidden" }}>.</span>
                    )}
                  </div>
                  {/* <div
                    style={{
                      fontSize: 22,
                      lineHeight: "26px",
                      marginBottom: 12,
                      fontWeight: "500",
                      alignItems: "flex-end",
                      display: "flex",
                      paddingBottom: 16,
                      height: "50%",
                      visibility: "hidden",
                    }}
                  >
                    .
                  </div> */}
                </div>
              </Grid>
              <Grid container item xs={12} justifyContent="center">
                <Typography style={{ display: "inline", fontSize: 14 }}>
                  {planDuration === "MONTHLY" ? "/mo/user" : "/year/user"}
                </Typography>
              </Grid>
              {/* <Grid
                style={{
                  backgroundColor: "#c9cff3",
                  color: "#2842D0",
                  textTransform: "uppercase",
                  // marginLeft: 10,
                  padding: "6px 5px 5px 5px",
                  borderRadius: 4,
                  marginTop: 20,
                }}
                item
              >
                <Typography className={Styles.font14}>
                  UPTO{" "}
                  {title === PRO ? "$100" : title === "Plus" ? "$160" : "$200"}{" "}
                  SAVINGS
                </Typography>
              </Grid> */}
            </Grid>
            {type === "teamPaymentPlan" ? (
              <Grid container justifyContent="center">
                <Grid item>
                  <StoreIcon style={{ color: "#66D7D1" }}></StoreIcon>
                  {title === PREMIUM || title === ADVANCED ? (
                    <>
                      <StoreIcon style={{ color: "#66D7D1" }}></StoreIcon>
                      <StoreIcon style={{ color: "#66D7D1" }}></StoreIcon>
                    </>
                  ) : null}
                </Grid>
              </Grid>
            ) : null}
            {subscriptionPlanFeatures.length ? (
              subscriptionPlanFeatures.map((feature, index) => (
                <Grid
                  container
                  item
                  sm={12}
                  justifyContent="flex-start"
                  key={index}
                  style={{ marginTop: 5 }}
                >
                  <Grid item xs={1}>
                    {feature.status === true ? (
                      <CheckCircleIcon
                        style={{ color: "#107E3E", marginTop: 3 }}
                      />
                    ) : (
                      <CancelOutlinedIcon
                        style={{ color: "#EEBFBF", marginTop: 3 }}
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    container
                    xs={11}
                    justifyContent="flex-start"
                    style={{
                      fontSize: 14,
                      marginTop: 4,
                      paddingLeft: 16,
                    }}
                    className={Styles.navyBlueColor}
                  >
                    {planDuration === "YEARLY" && feature.annualFeatureText
                      ? feature.annualFeatureText
                      : feature.featureText}
                  </Grid>
                </Grid>
              ))
            ) : (
              <Grid>
                  <Typography style={{
                    textAlign: "center", fontSize: 14, height: 84
                  }}>
                  {title === PRO
                    ? `${proText}`
                    : title === PLUS
                    ? `${plusText}`
                        : title === PREMIUM
                          ? `${premiumText}`
                          : `${advancedText}`}
                </Typography>
              </Grid>
            )}
            <Grid
              container
              item
              sm={12}
              style={{
                fontSize: 14,
                marginTop: 10,
                paddingLeft: 10,
              }}
              justifyContent="center"
              alignItems="flex-end"
            >
              <Link
                underline="always"
                href={HELP_CENTER_LINK}
                target="_blank"
                style={{ color: "#FA7E61", fontSzie: 14 }}
              >
                <Typography style={{ fontSize: 14 }}>Learn more</Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
}

export default function OneStoreFrontPlans({
  plansList,
  planDuration,
  setSelectedTeamPlan,
  defaultSelectedCard,
  setDefaultSelectedCard,
  type,
  srcPage,
  setSelectedUserPlan,
  savePlanData,
  selectedTeamPlan,
  selectedUserPlan,
  selectedPrevUserPlan,
  setPrevSelectedUserPlan,
  changeSubscription,
  openChangeSubscriptionDialog,
  setOpenChangeSubscriptionDialog,
  selectedPrevPlanDuration,
  setSelectedPrevPlanDuration,
  setPlanDuration,
}) {
  useEffect(() => {
    if (!selectedPrevPlanDuration) {
      setSelectedPrevPlanDuration(planDuration);
    }
    if (!selectedPrevUserPlan) {
      setPrevSelectedUserPlan(selectedUserPlan);
    }
  }, []);
  return (
    <Grid
      container
      style={{
        marginTop: srcPage !== "settingsPage" ? 20 : 8,
        // paddingBottom: srcPage !== "settingsPage" ? 80 : 28,
        paddingBottom: 28,
      }}
      spacing={2}
    >
      {plansList.length &&
        plansList.map((plan, index) => (
          <Grid item xs={12} md={4} key={index}>
            <SubscriptionPlan
              title={plan.planName}
              amount={
                planDuration === "MONTHLY"
                  ? plan.monthlyPrice
                  : plan.annualPrice
              }
              subscriptionPlanFeatures={
                type === "userPaymentPlan" ? plan.subscriptionPlanFeatures : []
              }
              planId={plan.id}
              planCase={plansList.case}
              defaultSelectedCard={defaultSelectedCard}
              setDefaultSelectedCard={setDefaultSelectedCard}
              setSelectedTeamPlan={setSelectedTeamPlan}
              type={type}
              planDuration={planDuration}
              srcPage={srcPage}
              setSelectedUserPlan={setSelectedUserPlan}
              savePlanData={savePlanData}
              selectedTeamPlan={selectedTeamPlan}
              selectedUserPlan={selectedUserPlan}
              selectedPrevUserPlan={selectedPrevUserPlan}
              setPrevSelectedUserPlan={setPrevSelectedUserPlan}
              plansList={plansList}
              changeSubscription={changeSubscription}
              openChangeSubscriptionDialog={openChangeSubscriptionDialog}
              setOpenChangeSubscriptionDialog={setOpenChangeSubscriptionDialog}
              selectedPrevPlanDuration={selectedPrevPlanDuration}
              setSelectedPrevPlanDuration={setSelectedPrevPlanDuration}
              setPlanDuration={setPlanDuration}
            />
          </Grid>
        ))}
    </Grid>
  );
}
