import { useOpenApiClient } from "./useOpenApiClient";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  ADVANCED,
  PLUS,
  PREMIUM,
  PRO,
  TEAM_MEMBER_USER,
} from "../web/src/components/ServiceProvider/serviceProviderConstants";
import useLocalStorage from "react-use-localstorage";
import * as moment from "moment";

export const usePersonateUser = (linkKey) => {
  const dispatch = useDispatch();
  const { openApiClient } = useOpenApiClient();
  const history = useHistory();
  const [userInfo, setUserInfo] = useLocalStorage("userInfo");

  const getUserData = () => {
    if (!linkKey) {
      return;
    }
    dispatch({ type: "SET_LOADING", value: true });
    return openApiClient
      .get(`/service-provider/impersonate/${linkKey}`)
      .then(function (res) {
        if (res.data.status === 200) {
          res.data.result.access_token = res.headers.access_token;
          res.data.result.refresh_token = res.headers.refresh_token;
          let userData = res.data.result;
          if (res.data.result.userType === TEAM_MEMBER_USER) {
            if (res.data.result.subscriptionPlanName === PREMIUM || res.data.result.subscriptionPlanName === ADVANCED) {
              userData = {
                ...userData,
                ...res.data.result.premiumMemberDetails,
              };
              delete userData.premiumMemberDetails;
            }
            if (
              res.data.result.subscriptionPlanName === PLUS ||
              res.data.result.subscriptionPlanName === PRO
            ) {
              userData = {
                ...userData,
                ...res.data.result.nonPremiumTeamMemberDetails,
              };
              delete userData.nonPremiumTeamMemberDetails;
            }
          }
          dispatch({ type: "SAVE_USER", payload: userData });
          setUserInfo(JSON.stringify(userData));
          dispatch({
            type: "SET_TOASTER",
            payload: {
              message: `You have logged in to Souqh at ${moment(
                currentDate
              ).format("MMM DD, YYYY hh:mm")}`,
              bgColor: success,
              open: true,
            },
          });
          deleteUserData();
          history.push("/serviceprovider/dashboard");
        } else {
          // if error  bring back to login page
          history.push("/serviceprovider/login");
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const deleteUserData = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return openApiClient
      .delete(`/service-provider/impersonate/${linkKey}`)
      .then(function (response) {
        if (response.data.status === 200) {
          // user Deleted sucsess.
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return { getUserData };
};
