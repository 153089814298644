import { cloneDeep } from "lodash";
import {
  RESET_ALL_MESSAGING_DATA,
  RESET_LOADED_MESSAGE_DATA,
  SET_All_USER_LIST,
  SET_CHANNEL_MEMBERSHIP_LIST,
  SET_CHANNEL_MESSAGE_TOKEN,
  SET_CHIME_AUTHENTICATION_DATA,
  SET_CHIME_CHANNEL_LIST,
  SET_CHIME_SESSION,
  SET_CHIME_SESSION_STARTED,
  SET_SELECTED_CHANNEL_DATA,
  SET_CURRENT_CHANNEL_MESSAGES,
  SET_LOADED_CHANNEL_DATA,
  SET_MESSAGE_ATTACHMENT,
  SET_SIGN_IN_PROGRESS,
  SET_SOCKET_MESSAGE,
  SET_UNREAD_CHANNELS,
  SET_UNREAD_MESSAGES_FROM_USERS,
  SET_CURRENT_UI_PATH,
} from "./actionConstants";

const initialState = {
  isAuthenticated: false,
  authenticationFailed: false,
  awsCredentials: null,
  sessionId: null,
  session: null,
  member: null,
  endpoint: null,
  logger: null,
  messageUpdateCallbacks: [],
  signInProgress: false,
  unReadMessagesFromUsers: {},
  sessionStarted: false,
  unreadChannels: [],
  channelMembershipList: [],
  allUsersList: [],
  channelMessageToken: null,
  messageAttachment: null,
  currentChannelMessages: [],
  selectedChannelData: null,
  channelList: [],
  loadedChannelData: false,
  currentUIPath: "",
};

const messaging = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_CHIME_AUTHENTICATION_DATA:
      newState = cloneDeep(state);
      newState.isAuthenticated = action.payload.isAuthenticated;
      newState.authenticationFailed = action.payload.authenticationFailed;
      newState.awsCredentials = action.payload.awsCredentials;
      newState.member = action.payload.member;
      newState.signInProgress = false;
      return newState;
    case SET_CHIME_SESSION_STARTED:
      newState = cloneDeep(state);
      newState.sessionStarted = action.payload.sessionStarted;
      return newState;
    case SET_SIGN_IN_PROGRESS:
      newState = cloneDeep(state);
      newState.signInProgress = action.payload.signInProgress;
      return newState;
    case SET_CURRENT_CHANNEL_MESSAGES:
      newState = cloneDeep(state);
      newState.currentChannelMessages = action.payload.currentChannelMessages;
      return newState;
    case SET_SELECTED_CHANNEL_DATA:
      newState = cloneDeep(state);
      newState.selectedChannelData = action.payload.selectedChannelData;
      return newState;
    case SET_CHIME_CHANNEL_LIST:
      newState = cloneDeep(state);
      newState.channelList = action.payload.channelList;
      return newState;
    case RESET_ALL_MESSAGING_DATA:
      newState = cloneDeep(initialState);
      return newState;
    case SET_UNREAD_CHANNELS:
      newState = cloneDeep(state);
      newState.unreadChannels = action.payload.unreadChannels;
      return newState;
    case SET_CHANNEL_MEMBERSHIP_LIST:
      newState = cloneDeep(state);
      newState.channelMembershipList = action.payload.channelMembershipList;
      return newState;
    case SET_All_USER_LIST:
      newState = cloneDeep(state);
      newState.allUsersList = action.payload.allUsersList;
      return newState;
    case SET_UNREAD_MESSAGES_FROM_USERS:
      newState = cloneDeep(state);
      newState.unReadMessagesFromUsers = action.payload.unReadMessagesFromUsers;
      return newState;
    case SET_CHANNEL_MESSAGE_TOKEN:
      newState = cloneDeep(state);
      newState.channelMessageToken = action.payload.channelMessageToken;
      return newState;
    case SET_MESSAGE_ATTACHMENT:
      newState = cloneDeep(state);
      newState.messageAttachment = action.payload.messageAttachment;
      return newState;
    case RESET_LOADED_MESSAGE_DATA:
      newState = cloneDeep(state);
      newState.messageAttachment = initialState.messageAttachment;
      newState.channelMembershipList = initialState.channelMembershipList;
      newState.allUsersList = initialState.allUsersList;
      newState.channelMessageToken = initialState.channelMessageToken;
      newState.selectedChannelData = initialState.selectedChannelData;
      newState.channelList = initialState.channelList;
      newState.currentChannelMessages = initialState.currentChannelMessages;
      newState.loadedChannelData = initialState.loadedChannelData;
      return newState;
    case SET_LOADED_CHANNEL_DATA:
      newState = cloneDeep(state);
      newState.loadedChannelData = action.payload.loadedChannelData;
      return newState;
    case SET_CHIME_SESSION:
      newState = cloneDeep(state);
      newState.session = action.payload.session;
      return newState;
    case SET_CURRENT_UI_PATH:
      newState = cloneDeep(state);
      newState.currentUIPath = action.payload.currentUIPath;
      return newState;
    default:
      return state;
  }
};

export default messaging;
