import { useDispatch, useSelector } from "react-redux";
import { useApiClient } from "../useApiClient";
import { useAuthUser } from "../useLogin";

export const usePlanUpgradeDowngrade = () => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();
  const user = useAuthUser();

  const data = useSelector((state) => state.subscriptionPlan);

  const changeSubscription = (onResult) => {
    let changeSubscriptionRequest = {
      couponCode: "",
      paymentCycle: data.planDuration,
      planId: data.selectedUserPlan,
      userId: user.userId,
    };

    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .put(
        `/service-provider/subscription/upgrade-downgrade`,
        changeSubscriptionRequest
      )
      .then(function (response) {
        if (response.data.status === 200) {
          onResult && onResult(response);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    changeSubscription,
  };
};
