import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";
import { useAddCategory } from "souqh-react-redux-hooks/homeBuyer/useAddCategory";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 276,
  },
}));

function AddCategoryDialog({
  openAddCategoryDialog,
  setOpenAddCategoryDialog,
}) {
  const {
    categoryName,
    actions: { setCategory },
    addCategory,
    handleKeypress,
    addErrorMsg,
    setAddErrorMsg,
    emptyAddErrorMsg,
    setEmptyAddErrorMsg,
  } = useAddCategory(setOpenAddCategoryDialog);
  const classes = useStyles();
  return (
    <React.Fragment key="addDialog">
      <Dialog
        fullWidth
        open={openAddCategoryDialog}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DialogTitle className={Styles.navyBlueColor}>
          <Typography variant="subtitle1">Add Category</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container className={Styles.navyBlueColor}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="categoryName"
                placeholder="Category Name"
                label="Category Name"
                name="categoryName"
                variant="outlined"
                value={categoryName}
                required
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
                onKeyPress={handleKeypress}
              />
            </Grid>
            <Grid item xs={12}>
              {addErrorMsg && (
                <Typography variant="body2" color="error">
                  Category already exists. Please enter the unique category name
                </Typography>
              )}
              {emptyAddErrorMsg && (
                <Typography variant="body2" color="error">
                  Category name should not be blank
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            className={Styles.navyBlueColorForce}
            style={{ textTransform: "capitalize" }}
            onClick={() => {
              setOpenAddCategoryDialog(false);
              setAddErrorMsg("");
              setCategory("");
              setEmptyAddErrorMsg("");
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!categoryName}
            className={Styles.primaryBtnAccent}
            style={{
              textTransform: "capitalize",
              marginRight: 16,
              padding: "6px 24px",
            }}
            onClick={() => {
              addCategory((response) => {
                if (response && response.data.status === 200) {
                  setOpenAddCategoryDialog(false);
                  setCategory("");
                  setAddErrorMsg("");
                  setEmptyAddErrorMsg("");
                }
              });
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default AddCategoryDialog;
