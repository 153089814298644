import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import {
  HOME_BUYER_USER,
  SERVICE_PROVIDER_USER,
  TEAM_MEMBER_USER,
} from "./ServiceProvider/serviceProviderConstants";

function OpenRoute({ children, allowVisit = false, ...rest }) {
  const user = useAuthUser();
  let route =
    (user && user.userType === SERVICE_PROVIDER_USER) ||
    (user && user.userType === TEAM_MEMBER_USER)
      ? "/serviceprovider/dashboard"
      : user && user.userType === HOME_BUYER_USER
      ? "/homebuyer/explore"
      : "/";

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !user || allowVisit ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: route,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default OpenRoute;
