import { useSelector } from "react-redux";

import { SET_COMPANY_OVERVIEW } from "../../../souqh-redux/reducers/actionConstants";
import { useActions } from "../../useActions";

const actionCreators = {
  setCompanyOverview: (payload) => ({
    type: SET_COMPANY_OVERVIEW,
    payload,
  }),
};

export const useCompanyOverview = () => {
  const companyOverview = useSelector(
    (state) => state.storefront.companyOverview
  );
  const actions = useActions(actionCreators);

  return { companyOverview, actions };
};
