import { useState, useEffect } from "react";
import { SHOW_INVOICE } from "../souqh-redux/reducers/actionConstants";
import { useActions } from "./useActions";
import { useSelector } from "react-redux";

const actionCreator = {
  showInvoiceComponent: (payload) => ({
    type: SHOW_INVOICE,
    payload,
  }),
};

export const useSetupDrawer = () => {
  const [activeSection, setActiveSection] = useState("profile");
  const [selectedItem, setSelectedItem] = useState(1);
  const [selectedSubItem, setSelectedSubItem] = useState(1);

  const showInvoice = useSelector((state) => state.metaData.showInvoice);
  const spProfileStatus = useSelector((state) => state.spSetup.spProfileStatus);

  const actions = useActions(actionCreator);

  useEffect(() => {
    if (!showInvoice) {
      setSelectedSubItem(1);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (showInvoice) {
      setSelectedSubItem(2);
    } else {
      setSelectedSubItem(1);
    }
  }, [showInvoice]);

  return {
    activeSection,
    selectedItem,
    selectedSubItem,
    setActiveSection,
    setSelectedItem,
    setSelectedSubItem,
    actions,
    showInvoice,
    spProfileStatus,
  };
};
