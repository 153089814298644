import React from "react";
import {
  Typography,
  Button,
  makeStyles,
  Divider,
  Grid,
} from "@material-ui/core/";
import clsx from "clsx";
import Styles from "../../../../Styles.module.css";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined": {
      top: -8,
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px",
    },
  },
}));

export default function EditTemplateActionBtns({
  activeSection,
  setActiveSection,
  duplicateFileName,
  setOpenUploadCancelDialog,
  uploadFilesForTemplateTag,
  uploadTaggedDcouments,
  setOpenEditTemplate,
  isAllEmailsFilled,
  inviteAndSend,
  setOpenCustomEmailMessageDialog,
  validateTaggedDocument,
  templateDocumentList,
  showDialogFlag,
  setConfirmGoBackDialog,
  resetStep1,
  setSelectedContact,
}) {
  const classes = useStyles();

  return (
    <>
      <Divider />
      <Grid item container xs={12} alignItems="center" className={classes.root}>
        <Grid item container xs={2}></Grid>
        <Grid
          item
          container
          xs={10}
          style={{
            justifyContent: "flex-end",
            alignItems: "center",
            paddingRight: 0,
          }}
        >
          {duplicateFileName && activeSection === 1 && (
            <Typography
              variant="body2"
              style={{ color: "red", marginRight: 8 }}
            >
              ** File Name already exist, please rename the file and try again.
            </Typography>
          )}
          {activeSection !== 1 && (
            <Button
              startIcon={<ArrowBackIosIcon />}
              style={{
                textTransform: "capitalize",
                marginRight: 20,
              }}
              className={Styles.navyBlueColorForce}
              onClick={() => {
                if (activeSection === 2) {
                  if (showDialogFlag) {
                    setConfirmGoBackDialog(true);
                  } else {
                    resetStep1();
                  }
                } else {
                  if (activeSection === 4) {
                    setSelectedContact(null);
                  }
                  setActiveSection(activeSection - 1);
                }
              }}
            >
              Go Back
            </Button>
          )}
          {activeSection === 1 ? (
            <Button
              variant="outlined"
              style={{
                textTransform: "capitalize",
                padding: "6px 16px",
                marginRight: 12,
              }}
              className={clsx(Styles.navyBlueColorForce)}
              onClick={() => {
                setOpenUploadCancelDialog(true);
              }}
            >
              Cancel
            </Button>
          ) : null}
          {activeSection === 3 ? (
            <Button
              variant="outlined"
              style={{
                textTransform: "capitalize",
                padding: "6px 16px",
                marginRight: 12,
              }}
              className={clsx(Styles.navyBlueColorForce)}
              onClick={() => {
                uploadTaggedDcouments(false, () => {
                  setOpenEditTemplate(false);
                });
              }}
            >
              Upload
            </Button>
          ) : null}
          {activeSection === 3 ? (
            <Button
              style={{
                textTransform: "capitalize",
                borderRadius: 4,
                padding: "6px 32px",
              }}
              className={clsx(
                Styles.navyBlueColorForce,
                Styles.primaryBtnAccent
              )}
              onClick={() => {
                uploadTaggedDcouments(true);
              }}
            >
              Upload and use
            </Button>
          ) : null}

          {activeSection < 3 ? (
            <Button
              disabled={templateDocumentList.length < 1}
              style={{
                textTransform: "capitalize",
                borderRadius: 4,
                padding: "6px 32px",
              }}
              className={clsx(
                Styles.navyBlueColorForce,
                Styles.primaryBtnAccent
              )}
              onClick={() => {
                if (activeSection === 1) {
                  uploadFilesForTemplateTag();
                } else if (activeSection === 2) {
                  validateTaggedDocument();
                } else {
                  setActiveSection(activeSection + 1);
                }
              }}
            >
              Next
            </Button>
          ) : null}
          {activeSection === 4 ? (
            <Button
              variant="outlined"
              style={{
                textTransform: "capitalize",
                padding: "6px 16px",
                marginRight: 12,
              }}
              className={clsx(Styles.navyBlueColorForce)}
              onClick={() => {
                setOpenCustomEmailMessageDialog(true);
              }}
            >
              Customize Email
            </Button>
          ) : null}
          {activeSection === 4 ? (
            <Button
              disabled={!isAllEmailsFilled()}
              style={{
                textTransform: "capitalize",
                padding: "6px 32px",
              }}
              className={Styles.primaryBtnAccent}
              onClick={() => {
                inviteAndSend(() => {
                  setOpenEditTemplate(false);
                });
              }}
            >
              Invite &#38; Send
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}
