import { useAuthUser } from "../../useLogin";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useApiClient } from "../../useApiClient";
import { UPDATE_CLIENT_REVIEW_FLAG } from "../../../souqh-redux/reducers/actionConstants";

export const useInactiveReview = (reviewId) => {
  const { apiClient } = useApiClient();
  const userInfo = useAuthUser();
  const dispatch = useDispatch();
  const [flaggedReason, setFlaggedReason] = useState("");

  const flagReview = (onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post(
        `/storefront/service-review/flag?review-id=${reviewId}&sp-id=${userInfo.serviceProviderId}`,
        {
          flaggedReason,
        }
      )
      .then((res) => {
        if (res && res.data.status === 200) {
          dispatch({
            type: UPDATE_CLIENT_REVIEW_FLAG,
            payload: {
              id: reviewId,
              flagged: true,
            },
          });
        }
        onResult && onResult(res);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    flagReview,
    flaggedReason,
    setFlaggedReason,
  };
};
