import { useState } from "react";
import { color_success } from "../toasterColors";
import { useAuthUser } from "../useLogin";
import { useDispatch } from "react-redux";
import { useApiClient } from "../useApiClient";
import axios from "axios";
import * as Stomp from "stompjs";
import * as SockJS from "sockjs-client";

export const useCalendarSync = () => {
  const [accountDetails, setAccountDetails] = useState(null);
  const [scheduledPages, setScheduledPages] = useState([]);

  const [webSocket, setWebSocket] = useState(null);
  const [stompClient, setStompClient] = useState(null);
  const [eventType, setEventType] = useState(null);

  const user = useAuthUser();
  const dispatch = useDispatch();
  const { apiClient } = useApiClient();

  const sendAuthToken = (authCode) => {
    const nylasAuthCodeRequest = { authCode: authCode };
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .post(`/nylas/oauth/token/${user.userId}`, nylasAuthCodeRequest)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getAccountDetails = () => {
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .get(`nylas/account-details`, {
        headers: {
          Authorization: user.access_token,
        },
      })
      .then((res) => {
        setAccountDetails(res.data.result);
      })
      .catch((error) => {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getSchedulingPages = (calSyncAccessToken) => {
    if (calSyncAccessToken) {
      dispatch({ type: "SET_LOADING", value: true });
      axios
        .get(`https://api.schedule.nylas.com/manage/pages`, {
          headers: {
            Authorization: `Bearer ${calSyncAccessToken}`,
          },
        })
        .then((res) => {
          setScheduledPages(res.data);
        })
        .catch((error) => {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const connectToSocket = () => {
    const authToken = user.access_token;
    if (authToken) {
      try {
        const url =
          process.env.REACT_APP_SOUQH_API_SERVER_BASE_URL + "/ws-notifications";
        const tempWebSocket = new SockJS(url);
        setWebSocket(tempWebSocket);
        let tempClient = Stomp.over(tempWebSocket);
        tempClient.debug = null;
        setStompClient(tempClient);
        tempClient.connect({ Authorization: authToken }, (frame) => {
          tempClient.subscribe("/queue/private", (response) => {
            if (response && response.body) {
              const body = JSON.parse(response.body);
              if (
                body &&
                body.account_id === accountDetails?.accountId &&
                (body.trigger === "event.created" ||
                  body.trigger === "event.updated" ||
                  body.trigger === "event.deleted")
              ) {
                setEventType(body);
                // window.location.reload();
              }
            }
          });
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  const disconnectFromSocket = () => {
    if (stompClient !== null) {
      stompClient.disconnect();
      setStompClient(null);
      setWebSocket(null);
    }
  };

  return {
    sendAuthToken,
    getAccountDetails,
    accountDetails,
    getSchedulingPages,
    scheduledPages,
    connectToSocket,
    disconnectFromSocket,
    eventType,
  };
};
