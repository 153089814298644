import React, { useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
  Grid,
  ButtonBase,
  DialogTitle,
  Button,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";

export default function CopyQRCode({
  open,
  setOpenQRCodeDialog,
  qrCodeImageSrc,
  copyImageToClipBoard,
  copySuccess,
}) {
  return (
    <Dialog open={open} maxWidth="sm">
      <DialogContent>
        <DialogTitle style={{ padding: "0px 0px 10px 0px" }}>
          <Grid container item xs={12}>
            <Grid item xs={10}>
              <Typography variant="subtitle1" className={Styles.navyBlueColor}>
                QR Code
              </Typography>
            </Grid>
            <Grid item container xs={2} justifyContent="flex-end">
              <ButtonBase>
                <CloseIcon
                  onClick={() => {
                    setOpenQRCodeDialog(false);
                  }}
                />
              </ButtonBase>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContentText>
          <Grid container item>
            <img src={qrCodeImageSrc || ""} alt="QR code img"></img>
          </Grid>
          {copySuccess && (
            <MuiAlert
              style={{
                marginTop: 10,
                position: "absolute",
                bottom: 12,
                left: 100,
              }}
              severity="success"
              variant="filled"
              elevation={10}
            >
              Copied
            </MuiAlert>
          )}
          <Grid item container justifyContent="flex-end">
            <Button
              variant="outlined"
              style={{
                padding: "6px 10px",
                textTransform: "capitalize",
              }}
              className={Styles.navyBlueColorForce}
              onClick={copyImageToClipBoard}
            >
              Copy
            </Button>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
