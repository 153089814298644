import React from "react";
import { CircularProgress, Dialog } from "@material-ui/core";
import { useSelector } from "react-redux";
function LoadingMask() {
  const loading = useSelector((state) => state.ui.loading);

  return (
    <Dialog open={!!loading}>
      <CircularProgress style={{ margin: 20 }} color="primary" />
    </Dialog>
  );
}

export default LoadingMask;
