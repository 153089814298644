import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useApiClient } from "../../useApiClient";
import {
  SAVE_RECOMMEND_SP_TO_CLIENT_DATA,
  CLEAR_RECOMMEND_SP_TO_CLIENT_DATA,
} from "../../../souqh-redux/reducers/actionConstants";
import { useActions } from "../../useActions";

const recommendSPToClientActions = {
  saveRecommendSPToClientData: (payload) => ({
    type: SAVE_RECOMMEND_SP_TO_CLIENT_DATA,
    payload,
  }),
  clearData: (payload) => ({
    type: CLEAR_RECOMMEND_SP_TO_CLIENT_DATA,
    payload,
  }),
};

export const useRecommmendSpToClient = (
  userId,
  serviceRequestId,
  clientId = null
) => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();
  const actions = useActions(recommendSPToClientActions);

  const { recommendedSPsToClient } = useSelector(
    (state) => state.clientManagement
  );

  const recommmendSpToClient = (onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post(
        `/service-provider/client/recommend-sp/${userId}/${serviceRequestId}`,
        recommendedSPsToClient
      )
      .then(function (response) {
        if (response.data.status === 200) {
          onResult && onResult(response);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const addLeadsForRecommendedSPs = (
    recommendedSP,
    onResult,
    clientId = null
  ) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post(`/service-provider/add/leads/recommendedSP`, recommendedSP, {
        params: {
          clientId,
        },
      })
      .then(async (response) => {
        if (response) {
          onResult && onResult(response);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    recommmendSpToClient,
    actions,
    addLeadsForRecommendedSPs,
    recommendedSPsToClient,
  };
};
