import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import { MAX_SUMMARY_ITEMS } from "../serviceProviderConstants";

function AddSummaryDialog({
  setOpenAddSummaryDialog,
  openAddSummaryDialog,
  checkAndAddSummary,
  summaryList,
  summaryTobeEdited,
  setSummaryTobeEdited,
}) {
  useEffect(() => {
    if (summaryTobeEdited) {
      setOpenAddSummaryDialog(true);
      setTitle(summaryTobeEdited.title);
      setSubtitle(summaryTobeEdited.subtitle);
      setDescription(summaryTobeEdited.description);
      setId(summaryTobeEdited.id || null);
      setEditIndex(summaryTobeEdited.index);
    }
  }, [summaryTobeEdited]);

  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [description, setDescription] = useState("");
  const [duplicateTitle, setDuplicateTitle] = useState(false);
  const [id, setId] = useState(null);
  const [editIndex, setEditIndex] = useState(-1);
  const handleDialogClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    setTitle("");
    setSubtitle("");
    setDescription("");
    setDuplicateTitle(false);
    setOpenAddSummaryDialog(false);
    setSummaryTobeEdited(null);
    setEditIndex(-1);
    setId(null);
  };
  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      open={openAddSummaryDialog}
      onClose={handleDialogClose}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Add Summary</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ paddingTop: 32 }}>
        <Grid item xs={12} style={{ paddingBottom: 16 }}>
          <TextField
            fullWidth
            label="Title"
            variant="outlined"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              setDuplicateTitle(false);
            }}
            required
            inputProps={{
              maxLength: 256,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 16 }}>
          <TextField
            fullWidth
            label="Subtitle"
            variant="outlined"
            value={subtitle}
            multiline
            rows={2}
            onChange={(e) => {
              setSubtitle(e.target.value);
            }}
            inputProps={{
              maxLength: 2000,
            }}
          ></TextField>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 16 }}>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Description"
            variant="outlined"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            inputProps={{
              maxLength: 5000,
            }}
          ></TextField>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: "16px 24px" }}>
        {duplicateTitle && (
          <Typography variant="body2" style={{ color: "red" }}>
            **Summary with given title already exists.
          </Typography>
        )}
        <Button
          variant="outlined"
          style={{
            padding: "6px 16px",
            marginRight: 12,
            textTransform: "capitalize",
          }}
          className={Styles.navyBlueColorForce}
          onClick={() => {
            handleDialogClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={!(title || "").trim()}
          onClick={() => {
            if (summaryList.length < MAX_SUMMARY_ITEMS) {
              const summary = {
                title: (title || "").trim(),
                subtitle,
                description,
                id: id || null,
                index: editIndex,
              };
              checkAndAddSummary(summary, !!summaryTobeEdited, (flag) => {
                if (flag) {
                  setDuplicateTitle(true);
                } else {
                  handleDialogClose();
                }
              });
            }
          }}
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize", padding: "6px 32px" }}
        >
          {summaryTobeEdited ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddSummaryDialog;
