import {
  Button,
  Container,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useFetch } from "souqh-react-redux-hooks/serviceProvider/storefront/useFetch";
import { useSave } from "souqh-react-redux-hooks/serviceProvider/storefront/useSave";
import BrandAndThemeSection from "./BrandAndThemeSection";
import CustomerPayments from "./CustomerPayments";
import ExistingReviews from "./ExistingReviews";
import ServiceDetails from "./ServiceDetails";
import SetupFooter from "../SetupFooter";
import { useHistory } from "react-router-dom";
import { useSetupDrawer } from "souqh-react-redux-hooks/useSetupDrawer";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";
import MarketingBrochure from "./MarketingBrochure";
import PreviewStorefrontDialog from "./PreviewStorefrontDialog";
import { useSelector, useDispatch } from "react-redux";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { useDocuments } from "souqh-react-redux-hooks/serviceProvider/documents/useDocuments";
import { useStoreFrontName } from "souqh-react-redux-hooks/serviceProvider/storefront/useStoreFrontName";
import { useApiClient } from "souqh-react-redux-hooks/useApiClient";
import BlobManager from "../../../utils/BlobManager";
import { APPROVED } from "../../ServiceProvider/serviceProviderConstants";
import { color_success } from "souqh-react-redux-hooks/toasterColors";
import SyncSocialMediaFeed from "./SyncSocialMediaFeed";
import { useSQQuery } from "souqh-react-redux-hooks/common/useSQQuery";
import { useSocialMediaSync } from "souqh-react-redux-hooks/socialMediaSync/useSocialMediaSync";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    paddingBottom: 70,
    marginTop: 20,
  },
  buttons: {
    backgroundColor: "#FA7E61",
    padding: "0px 30px",
    textTransform: "capitalize",
    color: "#0E1C4E",
  },
}));

function StoreFront({ sourcePage = "", setOpenConfirmationDialog }) {
  const userInfo = useAuthUser();
  const classes = useStyles();
  let history = useHistory();
  const theme = useTheme();
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();
  const { query } = useSQQuery();

  const [instagramCode, setInstagramCode] = useState(null);
  const [redirectUri, setRedirectUri] = useState(null);
  const { sendInstagramCode } = useSocialMediaSync();

  let code = query.get("code");

  const {
    actions: { showInvoiceComponent },
  } = useSetupDrawer();

  const {
    actions: { save },
  } = useSave();

  const storeFrontId = useSelector((state) => state.storefront.storeFrontId);
  const services = useSelector((state) => state.storefront.services);
  const instagramToken = useSelector(
    (state) => state.storefront.instagramToken
  );
  const {
    actions: { fetch },
  } = useFetch();

  const getRedirectURI = () => {
    const envName = process.env.REACT_APP_ENV_NAME;
    const windowPathName = window.location.pathname;
    let redirectLink;
    if (windowPathName.includes("setup")) {
      redirectLink =
        envName === "PROD"
          ? "https://souqh.ca/app/setup/storefront"
          : envName === "QA"
          ? window.location.origin.includes("preprod.souqh.co")
            ? "https://preprod.souqh.co/app/setup/storefront"
            : "https://demo.souqh.co/setup/storefront"
          : "https://development.souqh.co/setup/storefront";
    } else {
      redirectLink =
        envName === "PROD"
          ? "https://souqh.ca/app/serviceprovider/storefront"
          : envName === "QA"
          ? window.location.origin.includes("preprod.souqh.co")
            ? "https://preprod.souqh.co/app/serviceprovider/storefront"
            : "https://demo.souqh.co/serviceprovider/storefront"
          : "https://development.souqh.co/serviceprovider/storefront";
    }
    setRedirectUri(redirectLink);
  };

  useEffect(() => {
    fetch(apiClient);
    window.scrollTo(0, 0);
    getRedirectURI();
    return () => {
      BlobManager.clearBlob();
    };
  }, []);

  useEffect(() => {
    const envName = process.env.REACT_APP_ENV_NAME;
    const windowPathName = window.location.pathname;
    let redirectLink;
    if (windowPathName.includes("setup")) {
      redirectLink =
        envName === "PROD"
          ? "https://souqh.ca/app/setup/storefront"
          : envName === "QA"
          ? window.location.origin.includes("preprod.souqh.co")
            ? "https://preprod.souqh.co/app/setup/storefront"
            : "https://demo.souqh.co/setup/storefront"
          : "https://development.souqh.co/setup/storefront";
    } else {
      redirectLink =
        envName === "PROD"
          ? "https://souqh.ca/app/serviceprovider/storefront"
          : envName === "QA"
          ? window.location.origin.includes("preprod.souqh.co")
            ? "https://preprod.souqh.co/app/serviceprovider/storefront"
            : "https://demo.souqh.co/serviceprovider/storefront"
          : "https://development.souqh.co/serviceprovider/storefront";
    }
    if (code && redirectLink) {
      sendInstagramCode(code, redirectLink, (res) => {
        if (res && res.data && res.data.status === 200) {
          setInstagramCode(code);
          fetch(apiClient);
          dispatch({
            type: "SET_TOASTER",
            payload: {
              message: "Instagram login successful",
              bgColor: color_success,
              open: true,
            },
          });
        }
      });
    }
  }, [code]);

  const [openPreviewSFDialog, setOpenPreviewSFDialog] = useState(false);

  const {
    addValidations,
    storefrontName,
    validations,
    actions: { setStorefrontName },
  } = useStoreFrontName();
  const { updateIntercomStatus } = useDocuments();
  const companyOverview = useSelector(
    (state) => state.storefront.companyOverview
  );

  return (
    <Grid container item className={classes.root}>
      <Container
        maxWidth={xlUp ? "lg" : "md"}
        style={{
          paddingBottom: 40,
          marginLeft: sourcePage === "manageStoreFront" ? "unset" : "auto",
        }}
      >
        <PreviewStorefrontDialog
          openPreviewSFDialog={openPreviewSFDialog}
          setOpenPreviewSFDialog={setOpenPreviewSFDialog}
          previewStorefrontId={storeFrontId}
          fetch={fetch}
          updateIntercomStatus={updateIntercomStatus}
        />
        <BrandAndThemeSection
          storefrontName={storefrontName}
          setStorefrontName={setStorefrontName}
          validations={validations}
          sourcePage={sourcePage}
          companyOverview={companyOverview}
          services={services}
        ></BrandAndThemeSection>
        <ServiceDetails sourcePage={sourcePage}></ServiceDetails>
        <SyncSocialMediaFeed
          save={save}
          apiClient={apiClient}
          instagramToken={instagramToken}
          instagramCode={instagramCode}
        />
        <MarketingBrochure />
        <ExistingReviews></ExistingReviews>
        <CustomerPayments></CustomerPayments>
        {sourcePage === "manageStoreFront" ? (
          <SetupFooter
            manageStoreFront={true}
            isSaveAsDraft={false}
            disablePreview={!storeFrontId}
            onPreview={() => {
              save(
                (res) => {
                  if (res && res.data.status === 200) {
                    setOpenPreviewSFDialog(true);
                  }
                  if (res && res.data && res.data.status === 412) {
                    // handle server response for validations
                    if (res.data.result) {
                      addValidations({ storefrontName: res.data.result });
                    }
                  }
                },
                apiClient,
                true
              );
            }}
          >
            <Button
              style={{ marginRight: 20 }}
              variant="outlined"
              className={clsx(Styles.navyBlueColor)}
              onClick={() => {
                setOpenConfirmationDialog(true);
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={
                !storefrontName ||
                !companyOverview ||
                !!validations.storefrontName ||
                (services && !services.length)
              }
              variant="contained"
              className={clsx(classes.buttons, Styles.primaryBtnAccent)}
              onClick={() => {
                save(
                  (res) => {
                    if (res && res.data && res.data.status === 412) {
                      // handle server response for validations
                      if (res.data.result) {
                        addValidations({ storefrontName: res.data.result });
                      }
                    }
                    // else if (res && res.data && res.data.status === 200) {
                    //   sendInstagramCode(instagramCode, redirectUri, (res) => {
                    //     if (res && res.data && res.data.status === 200) {
                    //       fetch(apiClient);
                    //     }
                    //   });
                    // }
                  },
                  apiClient,
                  true
                );
              }}
            >
              Save
            </Button>
          </SetupFooter>
        ) : (
          <SetupFooter
            storefront={true}
            disablePreview={!storeFrontId}
            onSaveAndDraft={() => {
              save(() => {}, apiClient, true);
            }}
            onPreview={() => {
              save((res) => {
                if (res && res.data.status === 200) {
                  setOpenPreviewSFDialog(true);
                }
                if (res && res.data && res.data.status === 412) {
                  // handle server response for validations
                  if (res.data.result) {
                    addValidations({ storefrontName: res.data.result });
                  }
                }
              }, apiClient);
            }}
            isSaveAsDraft={false}
            setupPage="profile"
          >
            <Button
              style={{ marginRight: 10, textTransform: "capitalize" }}
              disabled={
                !storefrontName ||
                !companyOverview ||
                !!validations.storefrontName ||
                (services && !services.length)
              }
              variant="outlined"
              className={Styles.navyBlueColorForce}
              onClick={() => {
                save(
                  (res) => {
                    if (res && res.data && res.data.status === 412) {
                      // handle server response for validations
                      if (res.data.result) {
                        addValidations({ storefrontName: res.data.result });
                      }
                    }
                    //  else if (res && res.data && res.data.status === 200) {
                    //   sendInstagramCode(instagramCode, redirectUri, (res) => {
                    //     if (res && res.data && res.data.status === 200) {
                    //       fetch(apiClient);
                    //     }
                    //   });
                    // }
                  },
                  apiClient,
                  true
                );
              }}
            >
              Save
            </Button>

            <Button
              disabled={
                !storefrontName ||
                !companyOverview ||
                !!validations.storefrontName ||
                (services && !services.length)
              }
              variant="contained"
              className={clsx(classes.buttons, Styles.primaryBtnAccent)}
              onClick={() => {
                save((res) => {
                  if (res && res.data.status === 200) {
                    // sendInstagramCode(instagramCode, redirectUri);
                    if (userInfo.spApprovalStatus === APPROVED) {
                      history.push("/setup/review");
                    } else {
                      history.push("/setup/subscription");
                    }
                    showInvoiceComponent(false);
                  }
                  if (res && res.data && res.data.status === 412) {
                    // handle server response for validations
                    if (res.data.result) {
                      addValidations({ storefrontName: res.data.result });
                    }
                  }
                }, apiClient);
              }}
            >
              Save And Continue
            </Button>
          </SetupFooter>
        )}
      </Container>
    </Grid>
  );
}

export default StoreFront;
