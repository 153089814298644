import {
  Button,
  Grid,
  makeStyles,
  Switch,
  TextField,
  Typography,
  Chip,
  Box,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { useRecommendSP } from "souqh-react-redux-hooks/common/useInviteClientsDialog";
import clsx from "clsx";
import Styles from "../../Styles.module.css";
import AddNewSP from "./AddNewSP";
import { isMobileDevice } from "../../utils/AppUtils";

const useStyles = makeStyles((theme) => ({
  grids: {
    marginBottom: 16,
  },
  marginTop20: {
    marginTop: 20,
  },
  semiBold16: {
    fontSize: "1rem",
    fontWeight: 600,
  },
}));

export default function RecommendedSP({
  showRecommendedSPDetails,
  setshowRecommendedSPDetails,
  recommendSP,
  saveRecommendSPData,
  serviceProviderList,
  setAddSPSucessMsg,
  newAddedSP,
  deleteNewSP,
  getSPList,
}) {
  const classes = useStyles();

  const [openAddNewSP, setOpenAddNewSP] = useState(false);

  useEffect(() => {
    getSPList();
  }, []);

  return (
    <React.Fragment>
      <AddNewSP
        openAddNewSP={openAddNewSP}
        setOpenAddNewSP={setOpenAddNewSP}
        setAddSPSucessMsg={setAddSPSucessMsg}
      />
      {isMobileDevice() ? (
        <Grid
          container
          item
          xs={12}
          className={clsx(classes.grids, classes.marginTop20)}
        >
          <Grid item xs={10}>
            <Typography className={classes.semiBold16}>Recommend</Typography>
            <Typography className={classes.semiBold16}>
              Service Providers
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              size="small"
              className={"sqGraySwitch"}
              checked={showRecommendedSPDetails}
              onChange={(event) => {
                setshowRecommendedSPDetails(event.target.checked);
              }}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          item
          xs={12}
          md={6}
          className={clsx(classes.grids, classes.marginTop20)}
        >
          <Grid item xs={10}>
            <Typography className={classes.semiBold16}>
              Recommend Service Providers
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              size="small"
              className={"sqGraySwitch"}
              checked={showRecommendedSPDetails}
              onChange={(event) => {
                setshowRecommendedSPDetails(event.target.checked);
              }}
            />
          </Grid>
        </Grid>
      )}
      {showRecommendedSPDetails && (
        <Grid container>
          <Grid item xs={12} md={6}>
            <Grid item xs={12} md={11}>
              <Grid item xs={12} className={classes.grids}>
                <Autocomplete
                  multiple
                  filterSelectedOptions
                  fullWidth
                  style={{ display: "inline-flex" }}
                  variant="outlined"
                  value={recommendSP.selectedPreferredSP}
                  noOptionsText={"No Options"}
                  options={serviceProviderList || []}
                  getOptionLabel={(option) => {
                    return `${option.firstName} ${option.lastName} ${option.email}`;
                  }}
                  onChange={(event, value) => {
                    saveRecommendSPData({ selectedPreferredSP: value });
                  }}
                  renderOption={(option) => (
                    <React.Fragment>
                      <Grid item>
                        <Grid item>
                          <Typography variant="body1">
                            {option.firstName} {option.lastName}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2">
                            {option.email}
                          </Typography>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                  getOptionSelected={(option, value) => {
                    return option.email === value.email;
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      {...params}
                      label={"Select Preferred Service Provider"}
                    />
                  )}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        label={`${option.firstName} ${option.lastName}`}
                        {...getTagProps({ index })}
                        variant="outlined"
                        className={Styles.navyBlueColorForce}
                      />
                    ))
                  }
                  inputProps={{
                    className: Styles.navyBlueColorForce,
                  }}
                />
              </Grid>
              {newAddedSP.length ? (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Typography varinat="body1" className={classes.grids}>
                      New Service Providers:
                    </Typography>
                  </Grid>
                  <Grid
                    xs={12}
                    item
                    className={classes.grids}
                    style={{
                      padding: 10,
                      border: "1px solid #c4c4c4",
                      borderRadius: 4,
                    }}
                  >
                    {newAddedSP.map((data, index) => (
                      <Chip
                        key={index}
                        variant="outlined"
                        label={data.fullName}
                        onDelete={() => {
                          deleteNewSP(index);
                        }}
                      ></Chip>
                    ))}
                  </Grid>
                </React.Fragment>
              ) : null}
              <Grid item xs={12}>
                <Button
                  className={clsx(
                    Styles.navButtonTxt,
                    Styles.navyBlueColorForce
                  )}
                  variant="outlined"
                  onClick={() => {
                    setOpenAddNewSP(true);
                  }}
                >
                  + Add New Service Provider
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}
