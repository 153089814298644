import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useServerErrors } from "souqh-react-redux-hooks/useServerErrors";
import Styles from "../Styles.module.css";

function ServerErrorDialog() {
  const { serverError, handleClose } = useServerErrors();
  let msg =
    serverError &&
    serverError.response &&
    serverError.response.data &&
    serverError.response.data.message;
  if (!msg) {
    msg = serverError && serverError.message;
  }
  return (
    <Dialog open={!!serverError}>
      <DialogTitle className={Styles.navyBlueColor}>
        {(serverError && serverError.title) || "Server Error"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{msg || "Error"}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ServerErrorDialog;
