import { Typography } from "@material-ui/core";
import React from "react";
import Styles from "../../../Styles.module.css";

export default function ContactNumber({ contactNumber }) {
  const formattedNumber = contactNumber
    ? `${contactNumber && contactNumber.substring(0, 3)}-${
        contactNumber && contactNumber.substring(3, 6)
      }-${contactNumber && contactNumber.substring(6, contactNumber.length)}`
    : "-";

  return (
    <Typography variant="body1" className={Styles.navyBlueColor}>
      {formattedNumber}
    </Typography>
  );
}
