import React, { useState } from "react";
import {
  Avatar,
  Card,
  CardHeader,
  Grid,
  Typography,
  CardContent,
  Divider,
  ButtonBase,
  IconButton,
  Tooltip,
  Button,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import MessageIcon from "@material-ui/icons/Message";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";
import * as moment from "moment";
import MoreOptionsOnClientInfo from "./MoreOptionsOnClientInfo";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { useNavigateToMessage } from "souqh-react-redux-hooks/common/useNavigateToMessage";
import {
  isMobileDevice,
  isProPlan,
  viewClientDetails,
} from "../../../utils/AppUtils";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import UpgradePlanIconSection from "../../common/UpgradePlanIconSection";

export default function InActiveClientInfoCard({ data }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  let history = useHistory();
  let { path } = useRouteMatch();
  const { navigateMessage } = useNavigateToMessage();
  const userInfo = useAuthUser();

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const showMoreInfoCondition = isMobileDevice() && showMoreInfo;

  return (
    <Card
      elevation={0}
      style={{ border: !isMobileDevice() && "1px solid #0000001F" }}
    >
      <MoreOptionsOnClientInfo
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        showLiveMeetingOption={true}
        showRequestAccessOption={true}
        toUserId={data.hbDetails.userId}
        serviceRequestId={data.serviceRequestId}
        isInactiveTab={true}
      />
      <CardHeader
        style={{
          cursor: data.profileAccessRequestGranted && "pointer",
          padding: isMobileDevice() && "16px 8px 16px 0px",
        }}
        onClick={() => {
          data.profileAccessRequestGranted &&
            viewClientDetails(history, path, data);
        }}
        avatar={
          <Avatar
            style={{ height: 76, width: 76, borderRadius: 4 }}
            variant="square"
            src={data.avatarUrl}
          >
            {(data.name || "").charAt(0)}
          </Avatar>
        }
        action={
          <ButtonBase>
            <MoreHorizIcon
              className={Styles.navyBlueColor}
              onClick={handleClick}
            />
          </ButtonBase>
        }
        title={
          <Typography
            className={Styles.navyBlueColor}
            variant="body1"
            style={{
              fontWeight: 500,
              marginLeft: 10,
              color: !data.profileAccessRequestGranted && "#868DA6",
            }}
          >
            {data.name}
          </Typography>
        }
        subheader={
          <>
            <Grid container>
              <Grid item xs={3}>
                <IconButton>
                  <MessageIcon
                    className={Styles.navyBlueColor}
                    onClick={(event) => {
                      navigateMessage(event, data.email);
                    }}
                  />
                </IconButton>
              </Grid>
              <Grid item xs={3}>
                {/* <IconButton> */}
                <div style={{ position: "relative", top: 9 }}>
                  <DateRangeIcon
                    style={{
                      color: isProPlan(userInfo) && "grey",
                      cursor: isProPlan(userInfo) ? "default" : "pointer",
                    }}
                    className={Styles.navyBlueColor}
                    onClick={(event) => {
                      event.stopPropagation();
                      if (!isProPlan(userInfo)) {
                        history.push(
                          "/serviceprovider/calendar?scheduleMeeting=true"
                        );
                      }
                    }}
                  />
                  {isProPlan(userInfo) && (
                    <UpgradePlanIconSection
                      iconBtnStyle={{
                        position: "absolute",
                        fontSize: 16,
                        left: 12,
                        top: -10,
                        fill: "#fa7e61",
                      }}
                      iconStyle={{
                        position: "absolute",
                        fontSize: 16,
                        top: 5,
                        fill: "#fa7e61",
                      }}
                    />
                  )}
                </div>
                {/* </IconButton> */}
              </Grid>
            </Grid>
          </>
        }
      />
      {(!isMobileDevice() || showMoreInfoCondition) && (
        <React.Fragment>
          <Divider />
          <CardContent>
            <Grid container>
              <Grid item xs={6}>
                <Grid item>
                  <Typography
                    className={Styles.navyBlueColor}
                    variant="body1"
                    style={{ fontWeight: 500 }}
                  >
                    Request Date
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    className={clsx(Styles.navyBlueColor, Styles.font14)}
                  >
                    {moment(data.requestedDate).format("MMM DD, YYYY")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid item>
                  <Typography
                    className={Styles.navyBlueColor}
                    variant="body1"
                    style={{ fontWeight: 500 }}
                  >
                    In-Active Date
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    className={clsx(Styles.navyBlueColor, Styles.font14)}
                  >
                    {moment(data.archiveInActiveDate).format("MMM DD, YYYY")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Divider style={{ margin: "15px 0px" }} />
            <Grid item>
              <Typography
                className={Styles.navyBlueColor}
                variant="body1"
                style={{ fontWeight: 500 }}
              >
                Service Details
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title={data.notes}>
                <Typography
                  className={clsx(
                    Styles.navyBlueColor,
                    Styles.font14,
                    Styles.sqEllipsisTwoLines
                  )}
                  style={{ minHeight: "3em" }}
                >
                  {data.notes || "No details available"}
                </Typography>
              </Tooltip>
            </Grid>
            <Divider style={{ margin: "15px 0px" }} />
            <Grid item>
              <Typography
                className={Styles.navyBlueColor}
                variant="body1"
                style={{ fontWeight: 500 }}
              >
                Reason for In-active
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title={data.archiveInActiveReason}>
                <Typography
                  className={clsx(
                    Styles.navyBlueColor,
                    Styles.font14,
                    Styles.sqEllipsisTwoLines
                  )}
                  style={{ minHeight: "3em" }}
                >
                  {data.archiveInActiveReason || "No details available"}
                </Typography>
              </Tooltip>
            </Grid>
            {/* <Divider style={{ margin: "15px 0px" }} />
        <Grid item>
          <Typography
            className={Styles.navyBlueColor}
            variant="body1"
            style={{ fontWeight: 500 }}
          >
            Attachments
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            className={clsx(Styles.navyBlueColor, Styles.font14)}
            style={{ fontStyle: "italic" }}
          >
            No Attachments
          </Typography>
        </Grid> */}
          </CardContent>
        </React.Fragment>
      )}
      {isMobileDevice() && (
        <Grid container item xs={12} justifyContent="flex-end">
          <Button
            variant="text"
            className={Styles.primaryTxtAccentForce}
            style={{
              textTransform: "capitalize",
              marginTop: showMoreInfo ? -16 : -32,
            }}
            onClick={(event) => {
              event.stopPropagation();
              setShowMoreInfo(!showMoreInfo);
            }}
          >
            {showMoreInfo ? "Show less" : "Show more"}
          </Button>
        </Grid>
      )}
    </Card>
  );
}
