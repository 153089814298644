import { useSelector, useDispatch } from "react-redux";
import { useAuthUser } from "../useLogin";
import { SET_SUBSCRIPTION_PLAN_DETAILS } from "../../souqh-redux/reducers/actionConstants";
import { useApiClient } from "../useApiClient";
import {
  ADVANCED,
  PREMIUM,
  SERVICE_PROVIDER_USER,
  TEAM_MEMBER_USER,
} from "../../web/src/components/ServiceProvider/serviceProviderConstants";

export const usePaymentPlanReview = () => {
  const { apiClient } = useApiClient();
  const user = useAuthUser();
  const dispatch = useDispatch();

  const planDetails = useSelector(
    (state) => state.paymentAndInvoice.planDetails
  );

  let url =
    user.userType === SERVICE_PROVIDER_USER ||
    (user.userType === TEAM_MEMBER_USER &&
      (user.subscriptionPlanName === PREMIUM || user.subscriptionPlanName === ADVANCED))
      ? `/service-provider/subscription/${user.serviceProviderId}`
      : `/service-provider/subscription/${user.parentServiceProviderId}`;

  const getSubscriptionPlanDetails = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(url)
      .then(function (response) {
        dispatch({
          type: SET_SUBSCRIPTION_PLAN_DETAILS,
          payload: { planDetails: response.data.result },
        });
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    planDetails,
    getSubscriptionPlanDetails,
  };
};
