import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import React from "react";
import Styles from "../../../Styles.module.css";
import MeetingLinkInfo from "./MeetingLinkInfo";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";

export default function InstantMeetingDialog({
  openInstantMeetingDailog,
  setOpenInstantMeetingDailog,
  meetingLink,
  copyToClipboard,
  textAreaRef,
  moderatorTextAreaRef,
  moderatorMeetingLink,
  setModeratorMeetingLink,
}) {
  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={openInstantMeetingDailog}
      onClose={() => {
        setOpenInstantMeetingDailog(false);
      }}
      // classes={{
      //   paper: classes.dialogPaper,
      //   root: classes.root,
      // }}
    >
      <DialogTitle>
        {/* <Typography variant="subtitle1" className={Styles.navyBlueColor}>
          Instant Meeting
        </Typography> */}
        <Grid item container xs={12} alignItems="center">
          <Grid item xs={11}>
            <div
              style={{ width: "95%", fontSize: 16 }}
              className={clsx(Styles.navyBlueColor)}
            >
              Instant Meeting
            </div>
          </Grid>
          <Grid item container xs={1} justifyContent="flex-end">
            <IconButton
              aria-label="close"
              //   className={classes.closeButton}
              style={{ position: "relative", top: -10, right: -16 }}
              onClick={() => {
                setOpenInstantMeetingDailog(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" className={Styles.navyBlueColor}>
          <MeetingLinkInfo
            meetingLink={meetingLink}
            copyToClipboard={copyToClipboard}
            textAreaRef={textAreaRef}
            moderatorMeetingLink={moderatorMeetingLink}
            setModeratorMeetingLink={setModeratorMeetingLink}
            moderatorTextAreaRef={moderatorTextAreaRef}
            //   removeMeetingLink={removeMeetingLink}
            //   isReadMode={isReadMode}
          />
        </Typography>
        <textarea
          readOnly
          style={{ width: 0, height: 0, opacity: 0 }}
          ref={textAreaRef}
          rows="9"
          value={meetingLink}
        />
        <textarea
          readOnly
          style={{ width: 0, height: 0, opacity: 0 }}
          ref={moderatorTextAreaRef}
          rows="9"
          value={moderatorMeetingLink}
        />
      </DialogContent>
    </Dialog>
  );
}
