import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
  TableSortLabel,
  Checkbox,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";
import clsx from "clsx";
import InviteSPDialog from "../ReferralManagement/InviteSPdialog";
import InviteClientsDialog from "../ReferralManagement/InviteClientsDialog";
import * as moment from "moment";
import { useReferralManagement } from "souqh-react-redux-hooks/common/useReferralManagement";
import { REFERRAL_MGMT_HEADERS } from "./referralMgmtConstants";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { getComparator, stableSort } from "../../utils/AppUtils";
import { HOME_BUYER_USER, SERVICE_PROVIDER_USER } from "../ServiceProvider/serviceProviderConstants";
import ConfirmationDialog from "../ConfirmationDialog";
const useStyles = makeStyles({
  root: {
    "& .MuiPaper-root": {
      boxShadow: "none",
    },
  },
  table: {
    minWidth: 650,
  },
  marginTop15: {
    marginTop: 15,
  },
});

function EnhancedTableHead({ classes, order, orderBy, onRequestSort, userInfo }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  let tableHeaders = userInfo && userInfo.userType === SERVICE_PROVIDER_USER ? REFERRAL_MGMT_HEADERS : REFERRAL_MGMT_HEADERS.filter((el) => el.label !== "Preferred Partner")

  return (
    <>
      {tableHeaders.map((headCell) => (
        <TableCell
          className={clsx(classes.tableHeading, Styles.navyBlueColorForce)}
          key={headCell.id}
          align="left"
          sortDirection={orderBy === headCell.id ? order : false}
          style={{ width: headCell.width, maxWidth: headCell.maxWidth }}
        >
          <TableSortLabel
            active={orderBy === headCell.id}
            direction={orderBy === headCell.id ? order : "asc"}
            onClick={createSortHandler(headCell.id)}
          >
            <Typography className={Styles.navyBlueColor} variant="subtitle1">
              {headCell.label}
            </Typography>
          </TableSortLabel>
        </TableCell>
      ))}
    </>
  );
}

export default function ReferralManagement({ source }) {
  const classes = useStyles();
  const [openInviteSPDialog, setOpenInviteSPDialog] = useState(false);
  const [openInviteClientsDialog, setOpenInviteClientsDialog] = useState(false);
  const [openCloseConfirmationDialog, setOpenCloseConfirmationDialog] =
    useState(false);
  const [toBeDeletedPartner, setToBeDeletedPartner] =
    useState(null);
  const {
    spReferralList,
    order,
    orderBy,
    handleRequestSort,
    searchVal,
    referralTableData,
    setPreferredCheckedValue
  } = useReferralManagement(true);
  const userInfo = useAuthUser();

  return (
    <>
      <ConfirmationDialog
        open={openCloseConfirmationDialog}
        handleYes={() => {
          if (openCloseConfirmationDialog) {
            setPreferredCheckedValue(false, toBeDeletedPartner, (res) => {
              if (res && res.status === 200) {
                setOpenCloseConfirmationDialog(false)
                setToBeDeletedPartner(null)
              }
            })
          }
        }}
        handleNo={() => {
          if (openCloseConfirmationDialog) {
            setOpenCloseConfirmationDialog(false)
            setToBeDeletedPartner(null)
          }
        }}
        title="Confirmation"
        sectionTitle="Do you want to remove this Service Provider from your Preferred Partner list?" />
      <InviteSPDialog
        openInviteSPDialog={openInviteSPDialog}
        setOpenInviteSPDialog={setOpenInviteSPDialog}
      />
      <InviteClientsDialog
        openInviteClientsDialog={openInviteClientsDialog}
        setOpenInviteClientsDialog={setOpenInviteClientsDialog}
        srcPage={source}
      />
      <Grid container style={{ marginTop: 50 }}>
        <Grid item xs={12}>
          <Typography variant="h4" className={Styles.navyBlueColor}>
            {userInfo && userInfo.userType === SERVICE_PROVIDER_USER ? "Preferred Partner Management" : "Referral Management"}
          </Typography>
        </Grid>
        <Grid container item xs={12} className={classes.marginTop8}>
          <Grid item xs={8}>
            <Grid item xs={9}>
              <Grid container>
                <Grid item xs={11} style={{ marginTop: 10 }}>
                  <TextField
                    className={classes.textInput}
                    fullWidth
                    variant="outlined"
                    label="Search your referrals here"
                    onChange={(event) => searchVal(event.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={4}
            justifyContent="flex-end"
            style={{ paddingTop: 10, paddingBottom: 10 }}
          >
            <Button
              className={clsx(Styles.navButtonTxt, Styles.navyBlueColorForce)}
              variant="outlined"
              style={{ marginRight: 10 }}
              onClick={() => setOpenInviteClientsDialog(true)}
            >
              {userInfo.userType === HOME_BUYER_USER
                ? "Invite Friends"
                : "Invite Clients"}
            </Button>
            <Button
              style={{ textTransform: "none" }}
              className={clsx(Styles.primaryBtnAccent)}
              variant="outlined"
              onClick={() => setOpenInviteSPDialog(true)}
            >
              Invite Service Providers
            </Button>
          </Grid>
        </Grid>
        <Grid container className={classes.root}>
          <TableContainer component={Paper} style={{ marginTop: 25 }}>
            <Table className={classes.table} aria-label="simple table">
              {!spReferralList.length ? (
                <caption>No Data available!</caption>
              ) : null}
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <Typography
                      className={Styles.navyBlueColor}
                      variant="subtitle1"
                    >
                      #
                    </Typography>
                  </TableCell>
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    userInfo={userInfo}
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                {referralTableData.length
                  ? stableSort(
                      referralTableData,
                      getComparator(order, orderBy)
                    ).map((row, index) => (
                      <TableRow key={index}>
                        <TableCell
                          align="left"
                          component="th"
                          scope="row"
                          style={{ borderBottom: "none" }}
                        >
                          <Typography
                            variant="body1"
                            className={Styles.navyBlueColor}
                          >
                            {index + 1}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ borderBottom: "none" }}
                        >
                          <Typography
                            variant="body1"
                            className={Styles.navyBlueColor}
                          >
                            {row.businessType.name}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ borderBottom: "none" }}
                        >
                          <Typography
                            variant="body1"
                            className={Styles.navyBlueColor}
                          >
                            {row.fullName}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ borderBottom: "none" }}
                        >
                          <Typography
                            variant="body1"
                            className={Styles.navyBlueColor}
                          >
                            {row.email}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ borderBottom: "none" }}
                        >
                          <Typography
                            variant="body1"
                            className={Styles.navyBlueColor}
                          >
                            {row.referralInviteStatus}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ borderBottom: "none" }}
                        >
                          <Typography
                            variant="body1"
                            className={Styles.navyBlueColor}
                          >
                            {row.inviteDate
                              ? moment(row.inviteDate).format("MMM DD, YYYY")
                              : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ borderBottom: "none" }}
                        >
                          <Typography
                            variant="body1"
                            className={Styles.navyBlueColor}
                          >
                            {row.acceptedDate
                              ? moment(row.acceptedDate).format("MMM DD, YYYY")
                              : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ borderBottom: "none" }}
                        >
                          <Typography
                            variant="body1"
                            className={Styles.navyBlueColor}
                          >
                            {row.completionDate
                              ? moment(row.completionDate).format(
                                  "MMM DD, YYYY"
                                )
                              : "-"}
                          </Typography>
                        </TableCell>
                        {userInfo && userInfo.userType === SERVICE_PROVIDER_USER && <TableCell
                          align="left"
                          style={{ borderBottom: "none" }}
                        >
                          <Checkbox
                            checked={row.preferred}
                            onChange={(event) => {
                              console.log(event.target.checked)
                              if (event.target.checked) {
                                setPreferredCheckedValue(event.target.checked, row.email)
                              } else {
                                setToBeDeletedPartner(row.email)
                                setOpenCloseConfirmationDialog(true)
                              }
                            }}
                            color={Styles.navyBlueColorForce}
                          />
                        </TableCell>}
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}
