import React, { useEffect } from "react";
import {
  Grid,
  Button,
  makeStyles,
  TextField,
  Paper,
  Container,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";
import SetupFooter from "../../SPSetup/SetupFooter";
import { useDemographicData } from "souqh-react-redux-hooks/homeBuyer/hbProfile/useDemographicData";
import {
  EMPLOYMENT_TYPE,
  AGE_RANGE,
  MARITAL_STATUS,
} from "../../../components/HBRegistration/HomeBuyerConstants";
import { useHistory } from "react-router";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  padding: {
    padding: 10,
  },
  marginTop: {
    marginTop: 20,
  },
  buttons: {
    padding: "5px 30px",
    textTransform: "capitalize",
  },
}));
const DemographicData = () => {
  let history = useHistory();
  const user = useAuthUser();

  const classes = useStyles();
  const {
    propertyValues,
    industryTypes,
    householdIncomeRange,
    demographicData,
    actions: { updateDemographiData },
    saveDemographicData,
    getDemographicData,
  } = useDemographicData();
  useEffect(() => {
    window.scroll(0, 0);
    getDemographicData(user.userId);
  }, []);
  return (
    <>
      <Grid container className={clsx(classes.root, classes.marginTop)}>
        <Container maxWidth="md" style={{ paddingBottom: 70 }}>
          <Grid style={{ marginBottom: 48, marginTop: 64 }}>
            <Typography variant="h4" className={Styles.navyBlueColor}>
              Demographic Data
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={0} style={{ padding: "16px 10px" }}>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={6}>
                  <Autocomplete
                    options={EMPLOYMENT_TYPE}
                    value={
                      (demographicData && demographicData.employment) || null
                    }
                    onChange={(event, value) => {
                      updateDemographiData({ employment: value });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Employment Type"
                        variant="outlined"
                        autoComplete="new-password"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    options={industryTypes || []}
                    value={
                      (demographicData && demographicData.industry) || null
                    }
                    onChange={(event, value) => {
                      updateDemographiData({ industry: value });
                    }}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => {
                      return option.name === value.name;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Industry"
                        variant="outlined"
                        autoComplete="new-password"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={6}>
                  <Autocomplete
                    options={AGE_RANGE}
                    value={demographicData && demographicData.ageGroup}
                    onChange={(event, value) => {
                      updateDemographiData({ ageGroup: value });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Age Range"
                        variant="outlined"
                        autoComplete="new-password"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    disabled
                    options={MARITAL_STATUS}
                    value={
                      (demographicData && demographicData.maritalStatus) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Marital Status"
                        variant="outlined"
                        autoComplete="new-password"
                      />
                    )}
                    getOptionLabel={(option) => option.key}
                    getOptionSelected={(option, value) => {
                      return option.key === value.key;
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={6}>
                  <Autocomplete
                    value={
                      (demographicData &&
                        demographicData.householdIncomeRange) ||
                      null
                    }
                    options={householdIncomeRange || []}
                    onChange={(event, value) => {
                      updateDemographiData({ householdIncomeRange: value });
                    }}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => {
                      return option.name === value.name;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Household Income Range"
                        variant="outlined"
                        autoComplete="new-password"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    options={propertyValues || []}
                    onChange={(event, value) => {
                      updateDemographiData({ propertyValue: value });
                    }}
                    value={
                      (demographicData && demographicData.propertyValue) || null
                    }
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => {
                      return option.name === value.name;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Property Value"
                        variant="outlined"
                        autoComplete="new-password"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Container>
        <SetupFooter isSaveAsDraft={false} page="newhomeprofile">
          <Button
            variant="contained"
            onClick={() => {
              saveDemographicData((res) => {
                if (res && res.status === 200) {
                  history.push("/usersetup/documentchecklist");
                }
              });
            }}
            className={clsx(Styles.primaryBtnAccent, classes.buttons)}
          >
            Save and continue
          </Button>
        </SetupFooter>
      </Grid>
    </>
  );
};

export default DemographicData;
