const arnParser = (arn) => {
  const arnMap = [
    "arn",
    "aws",
    "service",
    "region",
    "namespace",
    "relativeId",
    "relativeValue",
  ];
  return arn.split(":").reduce(function (aggregator, piece, index) {
    aggregator[arnMap[index]] = piece;
    return aggregator;
  }, {});
};

export default arnParser;
