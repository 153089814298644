import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoadingMask from "./components/LoadingMask";
import OpenRoute from "./components/OpenRoute";
// import ReactPageDemo from "./components/Storefront/deprecated/ReactPageDemo";
import SecuredRoute from "./components/SecuredRoute";

import ServerErrorDialog from "./components/ServerErrorDialog";
import SPSetup from "./components/SPSetup";
// import UnlayerDemo from "./components/UnlayerDemo";
import ServiceProvider from "./components/ServiceProvider";

import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";
import HomeBuyer from "./components/HomeBuyer";
import HBSetup from "./components/HBSetup";
import SPTermsOfUse from "./TermsAndPolicy/SPTermsOfUse";
import PrivacyPolicy from "./TermsAndPolicy/PrivacyPolicy";
import HBTermsOfUse from "./TermsAndPolicy/HBTermsOfUse";
import SecurityPolicy from "./TermsAndPolicy/SecurityPolicy";
import StorefrontPage from "./components/HomeBuyer/Explore/StorefrontPage/StorefrontPage";
import CommonTermsOfUse from "./TermsAndPolicy/CommonTermsOfUse";
import Toaster from "./utils/Toaster";
import PersonateUser from "./components/PersonateUser";
import { isMobileDevice, isTabletDevice } from "./utils/AppUtils";
import MobileStorefrontPage from "./mobileComponents/HomeBuyer/StorfrontDetails/MobileStorefrontPage";
import ViewWrapper from "./components/common/ViewWrapper";
import RedirectToNotifications from "./components/RedirectToNotifications";
import ErrorDialog from "./components/common/ErrorDialog";
import RedirectToDeleteTMSettings from "./RedirectToDeleteTMSettings";
import NylasRedirection from "./components/common/SQCalender/NylasRedirection";

const HomePage = lazy(() => import("./components/HomePage"));
const SPRegistration = lazy(() => import("./components/SPRegistration"));
const SignUpSuccessFeedback = lazy(() =>
  import("./components/SPRegistration/SignUpSuccessFeedback")
);
const EmailConfirmation = lazy(() =>
  import("./components/SPRegistration/EmailConfirmation")
);
const OnboardingOverview = lazy(() =>
  import("./components/SPRegistration/OnboardingOverview")
);
const ResetPassword = lazy(() => import("./components/Password/ResetPassword"));
const LoginPage = lazy(() => import("./components/LoginPage"));
const ForgotPassword = lazy(() =>
  import("./components/Password/ForgotPassword")
);

const HomeBuyerLogin = lazy(() => import("./components/HBRegistration/Login"));

const HomeBuyerSignUp = lazy(() =>
  import("./components/HBRegistration/SignUp")
);

const HomeBuyerGetStarted = lazy(() =>
  import("./components/HBRegistration/GetStarted")
);

const HomeBuyerDetails = lazy(() =>
  import("./components/HBRegistration/HomeBuyerDetails")
);

const SetupSuccess = lazy(() => import("./components/SPSetup/SetupSuccess"));
const TeamOnboardingStep1 = lazy(() =>
  import("./components/ServiceProvider/TeamOnboarding/TeamOnboardingStep1")
);
const TeamOnboardingStep2 = lazy(() =>
  import("./components/ServiceProvider/TeamOnboarding/TeamOnboardingStep2")
);
const TeamOnboardingMsgScreen = lazy(() =>
  import("./components/ServiceProvider/TeamOnboarding/TeamOnboardingMsgScreen")
);
const Explore = lazy(() => import("./components/HomeBuyer/Explore/Explore"));

const InstantMeeting = lazy(() =>
  import("./components/common/SQCalender/InstantMeeting")
);

const OrganisationalRedirect = lazy(() =>
  import("./components/OrganisationalRedirect")
);

const AppRouter = () => {
  return (
    <>
      <ServerErrorDialog></ServerErrorDialog>
      <ErrorDialog />
      <LoadingMask></LoadingMask>
      <Toaster />

      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <OpenRoute allowVisit path="/reset-password">
              <ForgotPassword />
            </OpenRoute>
            <Route path="/requestpasswordreset">
              <ResetPassword />
            </Route>
            <Route path="/userlogin">
              <HomeBuyerLogin />
            </Route>
            <Route path="/homebuyerterms">
              <HBTermsOfUse />
            </Route>
            <Route path="/serviceproviderterms">
              <SPTermsOfUse />
            </Route>
            <Route path="/termsAndPolicy">
              <CommonTermsOfUse />
            </Route>
            <Route path="/privacypolicy">
              <PrivacyPolicy />
            </Route>
            <Route path="/securitypolicy">
              <SecurityPolicy />
            </Route>
            <Route path="/homebuyer-getStarted">
              <HomeBuyerGetStarted />
            </Route>
            <OpenRoute allowVisit path="/homebuyerdetails">
              <HomeBuyerDetails />
            </OpenRoute>
            <Route path="/homebuyersignup">
              <HomeBuyerSignUp />
            </Route>
            <Route path="/verify-email">
              <EmailConfirmation />
            </Route>
            <Route path="/new/success">
              <SignUpSuccessFeedback />
            </Route>
            <Route path="/new/onboarding">
              <OnboardingOverview />
            </Route>
            <Route path={`/nylasAuth`}>
              <NylasRedirection />
            </Route>
            <OpenRoute allowVisit path="/team/onboarding">
              <TeamOnboardingStep1 />
            </OpenRoute>
            <OpenRoute allowVisit path="/team/updateDetails">
              <TeamOnboardingStep2 />
            </OpenRoute>
            <OpenRoute allowVisit path="/team/verify">
              <TeamOnboardingMsgScreen />
            </OpenRoute>
            <OpenRoute allowVisit path="/login">
              <LoginPage />
            </OpenRoute>
            <OpenRoute allowVisit path="/new">
              <SPRegistration />
            </OpenRoute>
            <SecuredRoute path="/setup">
              <SPSetup />
            </SecuredRoute>
            <SecuredRoute path="/homebuyer">
              <HomeBuyer />
            </SecuredRoute>
            <SecuredRoute path="/setup-success">
              <SetupSuccess />
            </SecuredRoute>
            <SecuredRoute path="/serviceprovider">
              <ServiceProvider />
            </SecuredRoute>
            <SecuredRoute path="/instantMeeting">
              <InstantMeeting />
            </SecuredRoute>
            <SecuredRoute path="/usersetup">
              <HBSetup></HBSetup>
            </SecuredRoute>
            <OpenRoute exact allowVisit path={`/storefront/:storeFrontName`}>
              {isMobileDevice() ? (
                <MobileStorefrontPage />
              ) : isTabletDevice() ? (
                <StorefrontPage />
              ) : (
                <ViewWrapper>
                  <StorefrontPage />
                </ViewWrapper>
              )}
            </OpenRoute>
            <OpenRoute
              exact
              allowVisit
              path={`/marketplace/:appSearchedService?/:appSearchedCityId?/:showAllCategories?`}
            >
              {!isMobileDevice() && !isTabletDevice() ? (
                <ViewWrapper exploreStorefronts={true}>
                  <Explore />
                </ViewWrapper>
              ) : (
                <Explore />
              )}
            </OpenRoute>
            <Route path="/organisationalRedirect">
              <OrganisationalRedirect />
            </Route>
            <OpenRoute path={"/clone"}>
              <PersonateUser />
            </OpenRoute>
            <OpenRoute path={"/email-notifications"}>
              <RedirectToNotifications />
            </OpenRoute>
            <OpenRoute allowVisit path="/team/remove/:flag">
              <RedirectToDeleteTMSettings />
            </OpenRoute>
            <OpenRoute allowVisit path="/prepare-for-canada/login">
              <HomeBuyerLogin />
            </OpenRoute>
            <OpenRoute allowVisit path="/prepare-for-canada/signup">
              <HomeBuyerSignUp />
            </OpenRoute>
            <OpenRoute
              allowVisit
              path="/prepare-for-canada/marketplace/:appSearchedService?/:appSearchedCityId?/:showAllCategories?"
            >
              {!isMobileDevice() && !isTabletDevice() ? (
                <ViewWrapper exploreStorefronts={true}>
                  <Explore />
                </ViewWrapper>
              ) : (
                <Explore />
              )}
            </OpenRoute>
            {/* <OpenRoute allowVisit path="/unlayerDemo">
              <UnlayerDemo />
            </OpenRoute> */}
            {/* <OpenRoute allowVisit path="/react-page-demo">
              <ReactPageDemo />
            </OpenRoute> */}
            <OpenRoute allowVisit path="/">
              <ReactKeycloakProvider authClient={keycloak}>
                <HomePage />
              </ReactKeycloakProvider>
            </OpenRoute>
          </Switch>
        </Suspense>
      </Router>
    </>
  );
};

export default AppRouter;
