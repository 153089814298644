import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { filter } from "lodash";
import { useApiClient } from "./useApiClient";

const getRemoteProvincesActionCreator =
  (counytryId, getCities, apiClient) => (dispatch) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/province/${counytryId}/${getCities}`)
      .then(function (response) {
        if (!response.data.result.length) {
          // we need response.data.content with values in every condition
          throw Error("Server Error : Provinces not found");
        } else {
          dispatch({ type: "SET_PROVINCES", provinces: response.data.result });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

const getProvinces = createSelector(
  (state) => state.metaData.provinces,
  (provinces) => {
    if (!provinces) return [];
    return provinces.map((v) => ({
      id: v.id,
      name: v.name,
      serviceable: v.functional,
      selected: v.selected || false,
      cityDTO: v.cityDTO,
    }));
  }
);

export const useProvinces = (counytryId, getCities = true) => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();
  const provinces = useSelector(getProvinces);
  useEffect(() => {
    if (counytryId || !provinces.length) {
      dispatch(
        getRemoteProvincesActionCreator(counytryId, getCities, apiClient)
      );
    }
  }, [counytryId]);

  return { provinces };
};

export const useNotAvailable = (provinces) => {
  const [notAvailable, setNotAvailable] = useState([]);

  useEffect(() => {
    if (provinces && provinces.length) {
      setNotAvailable(filter(provinces, (sp) => !sp.serviceable));
    }
  }, [provinces]);
  return { notAvailable, count: notAvailable && notAvailable.length };
};
