import { Button, Grid, Link, Typography } from "@material-ui/core";
import React from "react";
import Styles from "../../../../Styles.module.css";
import RepresentativeMock2 from "../../../SPSetup/StoreFront/RepresentativeMock2.js";
import GetAppIcon from "@material-ui/icons/GetApp";
import DocumentPreviewDialog from "./DocumentPreviewDialog";
import { isMobileDevice, isTabletDevice } from "../../../../utils/AppUtils";

export default function StorefrontDocuments(props) {
  const {
    storeFrontDetails,
    openDocumetDialog,
    setOpenDocumetDialog,
    documentItem,
    setDocumentItem,
    yearsInBusiness,
    numberOfEmployees,
    getUploadedDocuments,
    setUserEngagementData,
    storeFrontId,
  } = props;

  const documents = getUploadedDocuments(storeFrontDetails).map((el, index) => {
    return (
      <Grid
        item
        container
        xs={12}
        key={index}
        style={{
          padding: "12px 0px",
          alignItems: "center",
          borderBottom: "2px solid rgb(245, 245, 248)",
        }}
      >
        <Grid item xs={10} md={9}>
          <Typography
            variant="body1"
            className={Styles.sqEllipsis}
            style={{ cursor: "pointer", paddingLeft: isMobileDevice() && 16 }}
            onClick={(event) => {
              event.stopPropagation();
              setUserEngagementData(storeFrontId, "Document_preview");
              setDocumentItem(el);
              setOpenDocumetDialog(true);
            }}
          >
            {el.name}
          </Typography>
        </Grid>
        <Grid item xs={2} md={3}>
          <Link
            href={el.path}
            target={"_blank"}
            download
            onClick={(event) => {
              event.stopPropagation();
              setUserEngagementData(storeFrontId, "Document_download");
            }}
          >
            {isMobileDevice() ? (
              <GetAppIcon style={{ fill: "#0e1c4e" }} />
            ) : (
              <Button
                variant="outlined"
                startIcon={<GetAppIcon style={{ fill: "#0e1c4e" }} />}
                className={Styles.navyBlueColorForce}
              >
                Download
              </Button>
            )}
          </Link>
        </Grid>
      </Grid>
    );
  });

  return (
    <>
      <Grid container item xs={12}>
        <Grid
          item
          container
          xs={12}
          style={{ paddingTop: 24, paddingBottom: isTabletDevice() && 48 }}
        >
          <Grid item xs={12} md={9}>
            <Grid item xs={12}>
              {documents && documents.length ? (
                documents
              ) : (
                <Typography varinat="caption">No documents added</Typography>
              )}
            </Grid>
          </Grid>
          {!isMobileDevice() && (
            <Grid item xs={12} lg={3}>
              <RepresentativeMock2
                yearsInBusiness={yearsInBusiness}
                numberOfEmployees={numberOfEmployees}
              ></RepresentativeMock2>
            </Grid>
          )}
        </Grid>
      </Grid>
      {openDocumetDialog && (
        <DocumentPreviewDialog
          openDocumetDialog={openDocumetDialog}
          setOpenDocumetDialog={setOpenDocumetDialog}
          documentItem={documentItem}
          setDocumentItem={setDocumentItem}
        ></DocumentPreviewDialog>
      )}
    </>
  );
}
