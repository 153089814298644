import {
  Button,
  Grid,
  TextField,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState, useEffect } from "react";
import Styles from "../../Styles.module.css";
import AddMemberDialog from "./AddMemberDialog";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CommonStorefront from "./CommonStorefront";
import SearchIcon from "@material-ui/icons/Search";
import { useTagSomeone } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useTagSomeone";
import { useTeamManagement } from "souqh-react-redux-hooks/settings/useTeamManagement";
import { TEAM_MEMBER_ROLES } from "../ServiceProvider/serviceProviderConstants";
import { useRoleManagement } from "souqh-react-redux-hooks/common/useRoleManagement";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { isProPlan, isPlusPlan } from "../../utils/AppUtils";
import clsx from "clsx";
import UpgradePlanIconSection from "../common/UpgradePlanIconSection";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#0e1c4e",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0e1c4e",
      },
    },
  },
}));

export default function TeamManagement() {
  const classes = useStyles();
  const [openAddMemberDialog, setOpenAddMemberDialog] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const userInfo = useAuthUser();
  const {
    getTagMembers,
    actions: { editTeamMember },
  } = useTagSomeone();
  const { isTeamMember, getAdminNames } = useRoleManagement();

  const {
    displayTeamMembersData,
    requestTeamMembersSearch,
    searchTeamMemberText,
    searchedRole,
    setSearchedRole,
    displayPremiumMembersData,
    displayPendingMembersData,
    resendInviteMail,
    requestTeamMembersSearchByRole,
    removeTeamMember,
    updateTeamMember,
  } = useTeamManagement();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    getTagMembers();
  }, []);

  useEffect(() => {
    if (
      displayTeamMembersData &&
      displayPremiumMembersData &&
      displayPendingMembersData
    ) {
      const temp = [
        displayTeamMembersData.length,
        displayPremiumMembersData.length,
        displayPendingMembersData.length,
      ];

      let foundIndex = temp.indexOf(Math.max(...temp));
      foundIndex === 0
        ? setExpanded("panel1")
        : foundIndex === 1
        ? setExpanded("panel2")
        : setExpanded("panel3");
    }
  }, [
    displayTeamMembersData,
    displayPremiumMembersData,
    displayPendingMembersData,
    searchTeamMemberText,
  ]);

  return (
    <Grid
      container
      item
      xs={12}
      style={{ height: "100%", overflow: "hidden", padding: "0px 24px" }}
    >
      {!isTeamMember() ? (
        <React.Fragment>
          <AddMemberDialog
            openAddMemberDialog={openAddMemberDialog}
            setOpenAddMemberDialog={setOpenAddMemberDialog}
          />
          <Grid item xs={12} style={{ marginBottom: 16 }}>
            <Typography variant="h4" className={Styles.navyBlueColor}>
              Team Management
            </Typography>
          </Grid>
          <Grid container item xs={12} style={{ marginBottom: 16 }}>
            <Grid container item xs={8} spacing={2}>
              <Grid item xs={6}>
                <TextField
                  value={searchTeamMemberText}
                  variant="outlined"
                  fullWidth
                  placeholder="Search team members"
                  style={{ backgroundColor: "#FFFFFF" }}
                  onChange={(event) => {
                    requestTeamMembersSearch(event.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon className={Styles.navyBlueColor} />
                      </InputAdornment>
                    ),
                  }}
                  classes={{ root: classes.root }}
                ></TextField>
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  fullWidth
                  value={searchedRole || null}
                  options={TEAM_MEMBER_ROLES || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select role"
                      variant="outlined"
                      autoComplete="new-password"
                    />
                  )}
                  required
                  onChange={(event, value) => {
                    setSearchedRole(value);
                    requestTeamMembersSearchByRole(value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid justifyContent="space-between" container item xs={4}>
              <Grid
                style={{
                  position: "relative",
                  maxWidth: "46.666667%",
                  flexBasis: "46.666667%",
                }}
                item
                xs={5}
              >
                <Button
                  variant="outlined"
                  className={clsx({
                    [Styles.navyBlueColorForce]:
                      !isProPlan(userInfo) && !isPlusPlan(userInfo),
                  })}
                  style={{
                    textTransform: "capitalize",
                    width: "100%",
                  }}
                  disabled={isProPlan(userInfo) || isPlusPlan(userInfo)}
                >
                  Import Team
                </Button>
                {(isProPlan(userInfo) || isPlusPlan(userInfo)) && (
                  <UpgradePlanIconSection
                    iconBtnStyle={{
                      fontSize: 16,
                      right: -10,
                      top: -13,
                      fill: "#fa7e61",
                      position: "absolute",
                    }}
                    iconStyle={{
                      position: "absolute",
                      fontSize: 16,
                      top: 4,
                      fill: "#fa7e61",
                    }}
                  />
                )}
              </Grid>
              <Grid
                style={{
                  position: "relative",
                }}
                item
                xs={6}
              >
                <Button
                  className={clsx({
                    [Styles.primaryBtnAccent]:
                      !isProPlan(userInfo) && !isPlusPlan(userInfo),
                  })}
                  style={{
                    textTransform: "capitalize",
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                  onClick={() => setOpenAddMemberDialog(true)}
                  disabled={isProPlan(userInfo) || isPlusPlan(userInfo)}
                >
                  Add New Member
                </Button>
                {(isProPlan(userInfo) || isPlusPlan(userInfo)) && (
                  <UpgradePlanIconSection
                    iconBtnStyle={{
                      fontSize: 16,
                      fill: "#fa7e61",
                      right: -10,
                      top: -13,
                      position: "absolute",
                    }}
                    iconStyle={{
                      position: "absolute",
                      fontSize: 16,
                      top: 4,
                      fill: "#fa7e61",
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={Styles.navyBlueColor} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ marginTop: 10 }}
              >
                <Typography
                  variant="subtitle1"
                  className={Styles.navyBlueColor}
                >
                  Common Storefront
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  backgroundColor: "#F5F6F8",
                  padding: 0,
                  width: "100%",
                }}
              >
                <CommonStorefront
                  editTeamMember={editTeamMember}
                  memberList={displayTeamMembersData}
                  removeTeamMember={removeTeamMember}
                  getTagMembers={getTagMembers}
                  updateTeamMember={updateTeamMember}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={Styles.navyBlueColor} />}
                style={{ marginTop: 10 }}
              >
                <Typography
                  variant="subtitle1"
                  className={Styles.navyBlueColor}
                >
                  Individual Storefront
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{ backgroundColor: "#F5F6F8", padding: 0 }}
              >
                <CommonStorefront
                  editTeamMember={editTeamMember}
                  memberList={displayPremiumMembersData}
                  tab="individualSF"
                  removeTeamMember={removeTeamMember}
                  getTagMembers={getTagMembers}
                  updateTeamMember={updateTeamMember}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={Styles.navyBlueColor} />}
                style={{ marginTop: 10 }}
              >
                <Typography
                  variant="subtitle1"
                  className={Styles.navyBlueColor}
                >
                  Pending Members
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{ backgroundColor: "#F5F6F8", padding: 0 }}
              >
                <CommonStorefront
                  editTeamMember={editTeamMember}
                  memberList={displayPendingMembersData}
                  tab="pendingMembers"
                  resendInviteMail={resendInviteMail}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </React.Fragment>
      ) : (
        <Grid>
          <Typography className={Styles.navyBlueColor}>
            Please contact {getAdminNames()} for access
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
