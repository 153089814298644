import {
  UPDATE_DOCUMENT_LIST,
  SET_DOCUMENTS_DATA,
  SET_MAX_FILE_SIZE,
  DELETE_PROFILE_DOC,
} from "souqh-redux/reducers/actionConstants";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useActions } from "../../useActions";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { useHistory } from "react-router";
import { isString, filter } from "lodash";
import { useApiClient } from "../../useApiClient";

const actionCreators = {
  updateDocumentList: (payload) => ({
    type: UPDATE_DOCUMENT_LIST,
    payload,
  }),
  setMaxFileSize: (payload) => ({
    type: SET_MAX_FILE_SIZE,
    payload,
  }),
  deleteDoc: (payload) => ({
    type: DELETE_PROFILE_DOC,
    payload,
  }),
};

export const useDocumentList = () => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();
  const user = useAuthUser();
  const actions = useActions(actionCreators);
  const { documentList, maxFileSize } = useSelector((state) => state.hbSetup);
  const history = useHistory();
  const [openDeleteDocumentDialog, setOpenDeleteDocumentDialog] = useState({
    open: false,
    docDetails: {},
    categoryId: "",
    isDeleteSuccessful: false,
  });

  const handleOnChange = ({ event, field, limit }) => {
    actions.updateDocumentList({ field, value: event.target.files, limit });
    event.target.value = "";
  };

  const saveDocumentList = (onResult) => {
    let docData;
    Object.keys(documentList).map((key) => {
      docData = {
        ...docData,
        [key]: filter(
          documentList[key].map((file) => {
            if (!isString(file)) {
              return file.name;
            }
            return;
          }),
          (value) => value
        ),
      };
    });
    docData = { ...docData };

    let fileList = [];
    Object.keys(documentList).map((key) => {
      fileList = [...fileList, ...documentList[key]];
    });

    const formData = new FormData();
    fileList.forEach((file) => {
      file instanceof File && formData.append("documents", file, file.name);
    });

    formData.append(
      "uploadDocumentChecklist",
      new Blob([JSON.stringify(docData)], {
        type: "application/json",
      })
    );

    if (formData.getAll("documents").length) {
      dispatch({ type: "SET_LOADING", value: true });
      apiClient
        .put(`/home-buyer/profile/document-checklist`, formData, {
          params: {
            userId: user.userId,
          },
        })
        .then((res) => {
          onResult && onResult(res);
        })
        .catch((error) => {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    } else {
      history.push("/homebuyer/dashboard");
    }
  };
  const getDocumentsData = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/home-buyer/profile/document-checklist/${user.userId}`)
      .then(async (response) => {
        if (response.data.result) {
          dispatch({
            type: SET_DOCUMENTS_DATA,
            payload: response.data.result,
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };
  return {
    actions,
    handleOnChange,
    documentList,
    saveDocumentList,
    getDocumentsData,
    maxFileSize,
    openDeleteDocumentDialog,
    setOpenDeleteDocumentDialog,
  };
};
