import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  makeStyles,
  TextField,
  Snackbar,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import { useLeadsClientInfoCard } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useLeadsClientInfoCard";
import Alert from "@material-ui/lab/Alert";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { isTabletDevice, isMobileDevice } from "../../../utils/AppUtils";
import { HOME_BUYER_USER } from "../serviceProviderConstants";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 620,
    minWidth: !isTabletDevice() && !isMobileDevice() && 800,
  },
}));

function RejectionReasonDialog({
  openRejectionReasonDialog,
  setOpenRejectionReasonDialog,
  getClientManagementData,
  getSpManagementData,
  title = "",
  message = "",
  actionType = "",
}) {
  const [declineRequest, setDeclineRequest] = useState(false);
  const {
    declineServiceRequest,
    archiveInActiveReason,
    setArchiveInActiveReason,
  } = useLeadsClientInfoCard();
  const classes = useStyles();
  const userInfo = useAuthUser();
  return (
    <Dialog
      fullWidth
      disableBackdropClick
      open={openRejectionReasonDialog.open}
      onClose={() => {
        setOpenRejectionReasonDialog({ open: false, id: null });
      }}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">
          {title || "Reason for In-Active"}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container item xs={12} style={{ paddingBottom: 16 }}>
          <Grid item xs={12} style={{ marginTop: 16 }}>
            <TextField
              multiline
              placeholder="Add comments"
              required
              rows={13}
              maxRows={13}
              variant="outlined"
              fullWidth
              inputProps={{
                className: Styles.navyBlueColor,
                maxLength: 200,
              }}
              onChange={(event) => {
                setArchiveInActiveReason(event.target.value.trim());
              }}
            />
          </Grid>
          <Grid
            container
            item
            justifyContent="flex-end"
            style={{ marginTop: 10 }}
          >
            <Typography variant="body2" style={{ color: "#17174C99" }}>
              {archiveInActiveReason.length}/200
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions style={{ padding: "16px 24px" }}>
        <Button
          variant="outlined"
          style={{
            padding: "6px 16px",
            marginRight: 12,
            textTransform: "capitalize",
          }}
          className={Styles.navyBlueColorForce}
          onClick={() => {
            setOpenRejectionReasonDialog({ open: false, id: null });
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!archiveInActiveReason}
          variant="contained"
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize", padding: "6px 32px" }}
          onClick={() => {
            declineServiceRequest(
              openRejectionReasonDialog.id,
              `${message} ${archiveInActiveReason}`,
              actionType,
              (res) => {
                if (res && res.status === 200) {
                  setDeclineRequest(true);
                  setOpenRejectionReasonDialog({ open: false, id: null });
                  if (userInfo.userType === HOME_BUYER_USER) {
                    getSpManagementData && getSpManagementData();
                  } else {
                    getClientManagementData && getClientManagementData();
                  }
                }
              }
            );
          }}
        >
          Send
        </Button>
      </DialogActions>
      {declineRequest ? (
        <Snackbar
          open={declineRequest}
          autoHideDuration={3000}
          onClose={() => {
            setDeclineRequest(false);
          }}
        >
          <Alert variant="filled" severity="warning">
            You have declined request
          </Alert>
        </Snackbar>
      ) : null}
    </Dialog>
  );
}

export default RejectionReasonDialog;
