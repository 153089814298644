import { Grid, Paper, Typography, Button } from "@material-ui/core";
import React, { useState } from "react";
import ServiceForm from "./ServiceForm";
import ServiceList from "./ServiceList";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";

const Header = () => {
  return (
    <Grid style={{ marginTop: 60, marginBottom: 20 }} container>
      <Grid item xs={12}>
        <Typography
          variant="h4"
          className={Styles.navyBlueColor}
          style={{ marginBottom: 8 }}
        >
          Service Details
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          style={{ marginTop: 10 }}
          className={Styles.navyBlueColor}
        >
          Please add details of services that you want to display on your
          storefront.
        </Typography>
      </Grid>
    </Grid>
  );
};

function ServiceDetails({ sourcePage }) {
  const [existingUpdate, setexistingUpdate] = useState(false);
  const [showNewForm, setShowNewForm] = useState(false);
  return (
    <Grid container item xs={12} id="service_details">
      <Header></Header>
      <ServiceList
        existingUpdate={existingUpdate}
        setexistingUpdate={setexistingUpdate}
        setShowNewForm={setShowNewForm}
        sourcePage={sourcePage}
      ></ServiceList>
      {showNewForm && !existingUpdate && (
        <ServiceForm
          setShowNewForm={setShowNewForm}
          sourcePage={sourcePage}
        ></ServiceForm>
      )}

      <Grid
        container
        item
        xs={12}
        justifyContent="flex-end"
        style={{ marginTop: 10 }}
      >
        <Button
          disabled={existingUpdate || showNewForm}
          variant="outlined"
          className={Styles.navyBlueColorForce}
          onClick={() => {
            setShowNewForm(true);
          }}
        >
          + Add New Service
        </Button>
      </Grid>
    </Grid>
  );
}

export default ServiceDetails;
