import React, { useRef, useState } from "react";
import { Grid, makeStyles, TextField, Chip, Avatar } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ImageIcon from "@material-ui/icons/Image";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import { Picker } from "emoji-mart";
import CircularProgress from "@material-ui/core/CircularProgress";
import { isMobileDevice, isTabletDevice } from "../../utils/AppUtils";

const useStyles = makeStyles((theme) => ({
  actionIcon: {
    fill: "#0e1c4e",
    cursor: "pointer",
    margin: "0px 8px",
  },
  sendMessageBox: {
    width: "calc(100% - 80px)",
  },
}));
export default function ChatPanelFooter({
  messageTextBox,
  setMessageTextBox,
  messageTextBoxRef,
  sendMessage,
  processMessageAttachment,
  messageAttachment,
  removeAttachment,
  sendingMessage,
  showEmojiPicker,
  setShowEmojiPicker,
}) {
  const uploadRef = useRef(null);
  const uploadRefImg = useRef(null);
  const classes = useStyles();

  return (
    <Grid
      item
      xs={12}
      style={{
        width: isMobileDevice() ? "92%" : "100%",
        position: isMobileDevice() ? "fixed" : "relative",
        bottom: (isMobileDevice() || isTabletDevice()) && 80,
        backgroundColor: "#F5F5F8",
      }}
    >
      {messageAttachment ? (
        <Grid
          item
          xs={12}
          style={{
            position: "absolute",
            top: -100,
            width: "98%",
            height: 80,
            display: "flex",
            alignItems: "center",
            paddingLeft: 16,
            backgroundColor: "#ffffff",
            borderRadius: 6,
          }}
        >
          <Chip
            icon={<AttachFileIcon />}
            label={messageAttachment.name}
            onDelete={removeAttachment}
            variant="outlined"
            style={{ marginRight: 4 }}
          />
        </Grid>
      ) : null}

      {sendingMessage ? (
        <Grid
          item
          xs={12}
          style={{
            position: "absolute",
            top: -80,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={40} />{" "}
        </Grid>
      ) : null}

      {showEmojiPicker ? (
        <Grid
          item
          xs={12}
          className="sq-emoji-container"
          style={{
            position: "absolute",
            top: -100,
            width: "98%",
            height: 80,
            display: "flex",
            alignItems: "center",
            paddingLeft: 16,
            backgroundColor: "#ffffff",
            borderRadius: 6,
          }}
        >
          <Picker
            style={{
              width: "100%",
              position: "relative",
              bottom: "100px",
              right: "20px",
              height: "300px",
            }}
            onSelect={(emoji) => {
              if (emoji && emoji.native) {
                const newText = messageTextBox + emoji.native;
                setMessageTextBox(newText);
                if (messageTextBoxRef && messageTextBoxRef.current) {
                  messageTextBoxRef.current.focus();
                }
              }
            }}
          />
        </Grid>
      ) : null}

      <Grid
        item
        xs={12}
        style={{
          width: "100%",
          position: "relative",
        }}
      >
        <TextField
          disabled={sendingMessage}
          variant="outlined"
          inputRef={messageTextBoxRef}
          fullWidth
          placeholder="Type a new message"
          style={{
            backgroundColor: "#ffffff",
          }}
          value={messageTextBox}
          onChange={(event) => {
            setMessageTextBox(event.target.value);
          }}
          onKeyUp={(event) => {
            if (event.key === "Enter") {
              sendMessage();
            }
          }}
          inputProps={{
            className: classes.sendMessageBox,
            maxLength: 4000,
          }}
        />
        <SendIcon
          style={{
            marginTop: 12,
            paddingRight: 10,
            width: 30,
            height: 30,
            position: "absolute",
            right: 0,
            fill: "#0e1c4e",
            cursor: "pointer",
          }}
          onClick={() => {
            sendMessage();
          }}
        />
      </Grid>

      <Grid item xs={12} style={{ margin: "10px 0px" }}>
        <AttachFileIcon
          className={classes.actionIcon}
          onClick={(event) => {
            if (uploadRef && uploadRef.current) {
              uploadRef.current.value = null;
              uploadRef.current.click();
            }
          }}
          style={{
            opacity: messageAttachment || showEmojiPicker ? 0.5 : 1,
            pointerEvents:
              messageAttachment || showEmojiPicker ? "none" : "all",
          }}
        />
        <ImageIcon
          className={classes.actionIcon}
          onClick={(event) => {
            if (uploadRefImg && uploadRefImg.current) {
              uploadRefImg.current.value = null;
              uploadRefImg.current.click();
            }
          }}
          style={{
            opacity: messageAttachment || showEmojiPicker ? 0.5 : 1,
            pointerEvents:
              messageAttachment || showEmojiPicker ? "none" : "all",
          }}
        />
        <SentimentVerySatisfiedIcon
          className={classes.actionIcon}
          onClick={() => {
            setShowEmojiPicker(!showEmojiPicker);
          }}
          style={{
            opacity: messageAttachment ? 0.5 : 1,
            pointerEvents: messageAttachment ? "none" : "all",
          }}
        />
        <input
          accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .csv, .odt, .txt"
          style={{ display: "none" }}
          id="send-msg-attachament"
          multiple
          type="file"
          ref={uploadRef}
          onChange={processMessageAttachment}
        />
        <input
          accept=".tif, .tiff, .bmp, .jpeg, .jpg, .webp, .gif, .svg, .png, .eps."
          style={{ display: "none" }}
          id="send-msg-attachament-img"
          multiple
          type="file"
          ref={uploadRefImg}
          onChange={processMessageAttachment}
        />
      </Grid>
    </Grid>
  );
}
