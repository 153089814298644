import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import Styles from "../../../Styles.module.css";
import CloseIcon from "@material-ui/icons/Close";
import { useCalendarSync } from "souqh-react-redux-hooks/calendarSync/useCalendarSync";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 400,
  },
}));

export default function SyncCalendarDialog({
  openSyncCalendarDialog,
  setOpenSyncCalendarDialog,
}) {
  const classes = useStyles();
  const history = useHistory();

  const { detectProvider, authorizeEmailApi } = useCalendarSync();
  const [emailId, setEmailId] = useState("");

  // const Nylas = require("nylas");

  // Nylas.config({
  //   clientId: "aimf9hv1m0o0e68v97l1wofm4",
  //   clientSecret: "615ilxgkv5es6a55v0it48mkw",
  // });

  // const options = {
  //   redirectURI: "http://localhost:3000/nylasAuth",
  //   loginHint: "kirthi.nair@excellarate.com",
  //   responseType: "code",
  //   scopes: ["calendar"],
  //   // state: 'mycustomstate',
  //   // provider: "gmail",
  // };

  // const authUrl = Nylas.urlForAuthentication(options);

  const authorizeEmail = () => {
    const Nylas = require("nylas");

    Nylas.config({
      clientId: "aimf9hv1m0o0e68v97l1wofm4",
      clientSecret: "615ilxgkv5es6a55v0it48mkw",
    });

    const options = {
      redirectURI: "http://localhost:3000/nylasAuth",
      loginHint: emailId,
      responseType: "code",
      scopes: ["calendar"],
      // state: 'mycustomstate',
      provider: "gmail",
    };

    const authUrl = Nylas.urlForAuthentication(options);
    window.open(authUrl, "_self");
  };

  return (
    <Dialog
      fullWidth
      open={openSyncCalendarDialog}
      onClose={() => {
        setOpenSyncCalendarDialog(false);
      }}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Grid container item>
          <Grid item xs={10}>
            <Typography variant="subtitle1">Calendar Sync</Typography>
          </Grid>
          <Grid container item xs={2} justifyContent="flex-end">
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpenSyncCalendarDialog(false);
                setEmailId("");
              }}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography
          variant="body1"
          className={Styles.navyBlueColor}
          style={{ marginTop: 16 }}
        >
          Please enter the email id to be synced
        </Typography>
        <Grid item xs={12} style={{ marginBottom: 12, marginTop: 12 }}>
          <TextField
            fullWidth
            placeholder="Email Id"
            variant="outlined"
            value={emailId}
            onChange={(e) => {
              setEmailId(e.target.value);
            }}
          />
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="contained"
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize", padding: "6px 32px" }}
          onClick={() => {
            // detectProvider(emailId);
            authorizeEmail();
            // authorizeEmailApi(emailId);
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
