import { useAuthUser } from "../../useLogin";
import { useSelector, useDispatch } from "react-redux";
import { useApiClient } from "../../useApiClient";
import { SET_ACTIVE_CLIENT_DATA } from "../../../souqh-redux/reducers/actionConstants";
import { useActions } from "../../useActions";

const actionCreators = {
  clearActiveClientsData: (payload) => ({
    type: SET_ACTIVE_CLIENT_DATA,
    payload: { response: [] },
  }),
};

export const useActiveClientInfo = () => {
  const { apiClient } = useApiClient();
  const userInfo = useAuthUser();
  const dispatch = useDispatch();
  const actions = useActions(actionCreators);

  const { active } = useSelector((state) => state.clientManagement);

  const getActiveClientData = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/service-provider/client/service-request/active/${userInfo.userId}`)
      .then(function (response) {
        if (response.data.response && response.data.response.length) {
          const data = processResponse(response.data);
          dispatch({
            type: SET_ACTIVE_CLIENT_DATA,
            payload: data,
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const processResponse = (data) => {
    data.response = data.response.length
      ? data.response.map((res) => {
          return { ...res.hbDetails, ...res };
        })
      : [];
    return data;
  };

  return {
    getActiveClientData,
    active,
    actions,
  };
};
