import { Chip, Grid, makeStyles, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { useLanguages } from "souqh-react-redux-hooks/useLanguages";
import Styles from "../../../Styles.module.css";

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: 20,
  },
}));

export default function LanguagesYouSpeak() {
  const { languages, setLanguage, selectedLanguage } = useLanguages();
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.marginTop}>
      <Autocomplete
        multiple
        filterSelectedOptions
        fullWidth
        style={{ display: "inline-flex" }}
        variant="outlined"
        value={selectedLanguage}
        noOptionsText={"Languages you speak"}
        options={languages || []}
        getOptionLabel={(option) => option.name}
        onChange={(event, value) => {
          // English should be there by default and cannot be deleted.
          setLanguage([
            ...languages.filter((lang) => lang.id === 1),
            ...value.filter(
              (option) =>
                languages.filter((lang) => lang.id === 1).indexOf(option) === -1
            ),
          ]);
        }}
        getOptionSelected={(option, value) => {
          return option.name === value.name;
        }}
        renderInput={(params) => (
          <TextField
            variant="outlined"
            {...params}
            label={"Languages you speak"}
            required
          />
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.name}
              {...getTagProps({ index })}
              disabled={option.id === 1} // disable English option
              variant="outlined"
              className={Styles.navyBlueColorForce}
            />
          ))
        }
        inputProps={{
          className: Styles.navyBlueColorForce,
        }}
      />
    </Grid>
  );
}
