import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";
import { useRenameDocument } from "souqh-react-redux-hooks/homeBuyer/useRenameDocument";
import { useDispatch } from "react-redux";
import { color_success } from "souqh-react-redux-hooks/toasterColors";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 350,
  },
}));

function RenameDocumentDialog({
  openRenameDocumentDialog,
  setOpenRenameDocumentDialog,
  documentId,
  categoryId,
  getTabWiseCategoriesList,
  documentTabName,
  documentName,
  documentItem,
  refreshSPDocuments,
}) {
  const {
    renameHBDocument,
    setRenamedDocument,
    renamedDocument,
    handleRenameKeypress,
    renameErrorMsg,
  } = useRenameDocument(
    documentId,
    categoryId,
    setOpenRenameDocumentDialog,
    getTabWiseCategoriesList,
    documentTabName,
    documentName,
    documentItem
  );

  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Dialog
      fullWidth
      open={openRenameDocumentDialog || false}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Rename Document</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container className={Styles.navyBlueColor}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="documentName"
              label="New document Name"
              placeholder="New document Name"
              name="documentName"
              variant="outlined"
              required
              onChange={(e) => {
                setRenamedDocument(e.target.value);
              }}
              onKeyPress={handleRenameKeypress}
            />
          </Grid>
          {renameErrorMsg && (
            <Grid item xs={12}>
              <Typography variant="body2" color="error">
                {renameErrorMsg}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className={Styles.navyBlueColorForce}
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            setOpenRenameDocumentDialog(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!(renamedDocument || "").trim()}
          className={Styles.primaryBtnAccent}
          style={{
            textTransform: "capitalize",
            marginRight: 16,
            padding: "6px 16px",
          }}
          onClick={() =>
            renameHBDocument((res) => {
              if (res.data.status === 200) {
                setOpenRenameDocumentDialog(false);
                dispatch({
                  type: "SET_TOASTER",
                  payload: {
                    message: res.data.message,
                    bgColor: color_success,
                    open: true,
                  },
                });
                refreshSPDocuments && refreshSPDocuments();
              }
            })
          }
        >
          Rename
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RenameDocumentDialog;
