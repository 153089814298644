import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import CloseIcon from "@material-ui/icons/Close";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";

export default function MeetingLinkInfo({
  meetingLink,
  meetingPhoneNumber,
  copyToClipboard,
  removeMeetingLink,
  isReadMode,
  moderatorTextAreaRef,
  moderatorMeetingLink,
}) {
  const copyModeratorToClipboard = (e) => {
    if (moderatorTextAreaRef && moderatorTextAreaRef.current) {
      moderatorTextAreaRef.current.select();
      document.execCommand("copy");
    }
  };

  return (
    <Grid
      item
      xs={12}
      style={{
        padding: "16px",
        backgroundColor: "#0E1C4E0A",
        marginBottom: 20,
      }}
    >
      <Grid item container xs={12}>
        <Grid item xs={10}>
          <Typography
            variant="subtitle1"
            className={Styles.navyBlueColor}
            style={{ fontSize: 14, marginBottom: 6 }}
          >
            Praticipant Meeting ID
          </Typography>
          <Typography
            variant="body1"
            className={clsx(Styles.navyBlueColor, Styles.sqEllipsisTwoLines)}
            style={{ fontSize: 14, marginBottom: 6 }}
          >
            {meetingLink}
          </Typography>
        </Grid>
        <Grid container item xs={2} style={{ justifyContent: "flex-end" }}>
          <FileCopyOutlinedIcon
            onClick={(e) => {
              copyToClipboard(e);
            }}
            style={{
              fill: "#0e1c4e",
              cursor: "pointer",
              margin: "0px 8px",
              fontSize: 20,
            }}
          />
        </Grid>
        {moderatorMeetingLink ? (
          <a
            href={moderatorMeetingLink}
            target="_blank"
            style={{ textDecoration: "none", marginTop: 10 }}
          >
            <Button
              variant="contained"
              style={{
                padding: "10px 30px",
                textTransform: "capitalize",
                color: "#0E1C4E",
                backgroundColor: "#FA7E61",
              }}
            >
              Join Meeting as moderator
            </Button>
          </a>
        ) : null}
      </Grid>
      {/* <Grid item xs={12} style={{ marginBottom: 16 }}>
        <Typography
          variant="subtitle1"
          className={Styles.navyBlueColor}
          style={{ fontSize: 14, marginBottom: 6 }}
        >
          Phone numbers(US)
        </Typography>
        <Typography
          variant="body1"
          className={Styles.navyBlueColor}
          style={{ fontSize: 14, marginBottom: 6 }}
        >
          {meetingPhoneNumber}
        </Typography>
      </Grid> */}
    </Grid>
  );
}
