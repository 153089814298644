import { useState } from "react";
import { useDispatch } from "react-redux";
import { useApiClient } from "../useApiClient";
import { useAuthUser } from "../useLogin";

export const useViewDocument = (documentItem) => {
  const { apiClient } = useApiClient();

  const dispatch = useDispatch();
  const userInfo = useAuthUser();

  const [documentSummaryList, setDocumentSummaryList] = useState([]);
  const [summaryTobeEdited, setSummaryTobeEdited] = useState(null);
  const [openAddSummaryDialog, setOpenAddSummaryDialog] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const loadDocumentSummary = (documentId) => {
    if (documentId) {
      dispatch({ type: "SET_LOADING", value: true });
      const url = `/signnow/document/summary/${documentId}?${
        userInfo.userId || null
      }`;
      apiClient
        .get(url)
        .then((response) => {
          if (response.data.status === 200) {
            setDocumentSummaryList(response.data.result.documentSummary || []);
            setCanEdit(response.data.result.actions || false);
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const checkAndAddSummary = (summary, isEdit, callback) => {
    const currentTitle = (summary.title || "").toLowerCase();
    const duplicateIndex = documentSummaryList.findIndex(
      (item) => (item.title || "").trim().toLowerCase() === currentTitle
    );
    const data = {
      title: summary.title,
      subtitle: summary.subtitle,
      description: summary.description,
      id: summary.id || null,
    };
    if (isEdit) {
      if (duplicateIndex === -1 || duplicateIndex === summary.index) {
        saveSummaryToBackend(data, summary.index, callback);
        return;
      }
    } else {
      if (duplicateIndex === -1) {
        saveSummaryToBackend(data, null, callback);
        return;
      }
    }
    callback(true);
  };

  const saveSummaryToBackend = (data, index, callback) => {
    const body = {
      description: data.description,
      id: data.id,
      subtitle: data.subtitle,
      title: data.title,
    };

    const url = `/signnow/document/summary/${documentItem.documentId}`;
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .post(url, body)
      .then((response) => {
        const responseData = response.data;
        if (responseData.status === 200) {
          const savedData = response.data.result || [];
          setDocumentSummaryList(savedData);
          if (savedData) {
            callback(false);
          }
        } else {
          // To Do
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const deleteSummary = (id) => {
    dispatch({ type: "SET_LOADING", value: true });
    const url = `/signnow/document/summary/${id}`;
    apiClient
      .delete(url)
      .then((response) => {
        if (response.data.status === 200) {
          const savedData = response.data.result || [];
          setDocumentSummaryList(savedData);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };
  const handleClose = () => {
    setOpenAddSummaryDialog(false);
    setDocumentSummaryList([]);
  };

  return {
    documentSummaryList,
    setDocumentSummaryList,
    loadDocumentSummary,
    openAddSummaryDialog,
    setOpenAddSummaryDialog,
    summaryTobeEdited,
    setSummaryTobeEdited,
    checkAndAddSummary,
    deleteSummary,
    handleClose,
    canEdit,
  };
};
