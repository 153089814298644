import { useAuthUser } from "../../useLogin";
import { useDispatch, useSelector } from "react-redux";
import { useApiClient } from "../../useApiClient";
import { SET_ACTIVE_SP_DATA } from "../../../souqh-redux/reducers/actionConstants";

export const useActiveSPInfo = (pageSize = 24) => {
  const { apiClient } = useApiClient();
  const userInfo = useAuthUser();
  const dispatch = useDispatch();

  const { active } = useSelector((state) => state.spManagement);

  const getActiveSPData = (page = 0) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(
        `/home-buyer/sp/service-request/active/${userInfo.userId}?page=${page}&size=${pageSize}`
      )
      .then(function (response) {
        if (response.data.response && response.data.response.length) {
          dispatch({
            type: SET_ACTIVE_SP_DATA,
            payload: response.data,
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    getActiveSPData,
    active,
  };
};
