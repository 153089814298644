export const REFERRAL_MGMT_HEADERS = [
  {
    id: "businessTypeName",
    label: "Service Provider Type",
    width: "21%",
  },
  {
    id: "fullName",
    label: "Full Name",
    width: "17%",
  },
  {
    id: "email",
    label: "Email Address",
    width: "10%",
  },
  {
    id: "referralInviteStatus",
    label: "Status",
    width: "10%",
    maxWidth: "26px",
  },
  {
    id: "inviteDate",
    label: "Invite Date",
    width: "13%",
    maxWidth: "26px",
  },
  {
    id: "acceptedDate",
    label: "Accepted Date",
    width: "13%",
    maxWidth: "26px",
  },
  {
    id: "completionDate",
    label: "Completion Date",
    width: "14%",
    maxWidth: "30px",
  },
  {
    id: "preferred",
    label: "Preferred Partner",
    width: "5%",
  },
];
