import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  makeStyles,
  TextField,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import { useCompleteClientInfo } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useCompleteClientInfo";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minWidth: !isMobileDevice() && !isTabletDevice() && 800,
  },
}));

function CompletionReasonDialog({
  openCompletionReasonDialog,
  setOpenCompletionReasonDialog,
}) {
  const { completeClientRequest, completionReason, setCompletionReason } =
    useCompleteClientInfo();
  const classes = useStyles();
  return (
    <Dialog
      fullWidth
      disableBackdropClick
      open={openCompletionReasonDialog.open}
      onClose={() => {
        setOpenCompletionReasonDialog({ open: false, id: null });
      }}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Mark as Complete</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container item xs={12} style={{ paddingBottom: 16 }}>
          <Grid item xs={12} style={{ marginTop: 16 }}>
            <TextField
              multiline
              placeholder="Add comments"
              required
              rows={12}
              rowsMax={12}
              variant="outlined"
              fullWidth
              inputProps={{
                className: Styles.navyBlueColor,
                maxLength: 500,
              }}
              onChange={(event) => {
                setCompletionReason(event.target.value);
              }}
            />
          </Grid>
          <Grid
            container
            item
            justifyContent="flex-end"
            style={{ marginTop: 37 }}
          >
            <Typography variant="body2" style={{ color: "#17174C99" }}>
              {completionReason.length}/500
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions style={{ padding: "16px 24px" }}>
        <Button
          variant="outlined"
          style={{
            padding: "6px 16px",
            marginRight: 12,
            textTransform: "capitalize",
          }}
          className={Styles.navyBlueColorForce}
          onClick={() => {
            setOpenCompletionReasonDialog({ open: false, id: null });
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!completionReason}
          variant="contained"
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize", padding: "6px 32px" }}
          onClick={() => {
            completeClientRequest(openCompletionReasonDialog.id, (res) => {
              if (res && res.status === 200) {
                setOpenCompletionReasonDialog({ open: false, id: null });
              }
            });
          }}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CompletionReasonDialog;
