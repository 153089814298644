import { useState } from "react";
import { useDispatch } from "react-redux";
import { useApiClient } from "../../useApiClient";
import { useAuthUser } from "../../useLogin";
import * as moment from "moment";

export const useOtherAction = () => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();
  const userInfo = useAuthUser();

  const [otherActionComments, setOtherActionComments] = useState("");
  const [dueDate, setDueDate] = useState(null);
  const [actionName, setActionName] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);
  const [pastDateError, setPastDateError] = useState(false);

  const requestOtherAction = (serviceRequestId, onResult) => {
    var localTime = moment(dueDate).format("YYYY-MM-DD");
    var proposedDate = localTime + "T00:00:00.000Z";

    let otherActionRequest = {
      actionName: actionName,
      clientUserId: selectedMember && selectedMember.userId,
      comments: otherActionComments,
      dueDate: dueDate ? proposedDate : null,
      requestorUserId: userInfo.userId,
      serviceRequestId: serviceRequestId,
    };
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post("/pending-actions/other-action", otherActionRequest)
      .then(function (response) {
        if (response.data.status === 200) {
          onResult && onResult(response);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const clearData = () => {
    setOtherActionComments("");
    setActionName(null);
    setSelectedMember(null);
    setDueDate(null);
  };

  return {
    requestOtherAction,
    actionName,
    setActionName,
    dueDate,
    setDueDate,
    otherActionComments,
    setOtherActionComments,
    selectedMember,
    setSelectedMember,
    clearData,
    pastDateError,
    setPastDateError,
  };
};
