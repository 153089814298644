import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";
import { useVoidDocument } from "souqh-react-redux-hooks/homeBuyer/useVoidDocument";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 350,
  },
}));

function VoidDocumentDialog({
  openVoidDocumentDialog,
  setOpenVoidDocumentDialog,
  documentName,
  docDetails,
  getTabWiseCategoriesList,
  documentTabName,
}) {
  const classes = useStyles();

  const { voidEsignedDocument } = useVoidDocument(
    docDetails,
    getTabWiseCategoriesList,
    documentTabName
  );
  if (!docDetails) {
    return null;
  }
  return (
    <Dialog
      open={openVoidDocumentDialog}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Void Document</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1">
            Are you sure you want to void {documentName} ?
          </Typography>
          {docDetails.isDocInDocumentGroup ? (
            <Typography variant="body1" style={{ marginBottom: 60 }}>
              All documents from this group will be revoked
            </Typography>
          ) : null}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className={Styles.navyBlueColorForce}
          style={{ textTransform: "capitalize" }}
          onClick={() => setOpenVoidDocumentDialog(false)}
        >
          Cancel
        </Button>
        <Button
          className={Styles.primaryBtnAccent}
          style={{
            textTransform: "capitalize",
            marginRight: 16,
            padding: "6px 24px",
          }}
          onClick={() => {
            voidEsignedDocument(() => setOpenVoidDocumentDialog(false));
          }}
        >
          Void
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default VoidDocumentDialog;
