import { useSelector } from "react-redux";

import { SET_COMPETE_TEXT } from "../../../souqh-redux/reducers/actionConstants";
import { useActions } from "../../useActions";

const actionCreators = {
  setCompeteText: (payload) => ({
    type: SET_COMPETE_TEXT,
    payload,
  }),
};

export const useCompeteText = () => {
  const competeText = useSelector((state) => state.storefront.competeText);

  const actions = useActions(actionCreators);

  return { competeText, actions };
};
