import React from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import { setHBJourneyStatus } from "../../../utils/AppUtils";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 350,
  },
}));

function ChangeStatusConfirmDialog({
  openChangeStatusConfirmDialog,
  setOpenChangeStatusConfirmDialog,
  updateJourneyStatus,
  activeStep,
}) {
  const classes = useStyles();
  return (
    <Dialog
      fullWidth
      disableBackdropClick
      maxWidth={"sm"}
      open={openChangeStatusConfirmDialog}
      onClose={() => {
        setOpenChangeStatusConfirmDialog(false);
      }}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Information</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid item xs={12} style={{ paddingBottom: 16 }}>
          <Typography variant="body1" className={Styles.navyBlueColor}>
            You are about to change the status of your client. Are you sure you
            want to go ahead with this action?
          </Typography>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions style={{ padding: "16px 24px" }}>
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <Checkbox></Checkbox>
            <Typography
              variant="body1"
              className={Styles.navyBlueColor}
              style={{ display: "inline-flex" }}
            >
              Don't show this again
            </Typography>
          </Grid>
          <Grid container item xs={6} justifyContent="flex-end">
            <Button
              variant="outlined"
              style={{
                padding: "6px 16px",
                marginRight: 12,
                textTransform: "capitalize",
              }}
              className={Styles.navyBlueColorForce}
              onClick={() => {
                setOpenChangeStatusConfirmDialog(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={Styles.primaryBtnAccent}
              style={{ textTransform: "capitalize", padding: "6px 32px" }}
              onClick={() => {
                updateJourneyStatus(
                  setHBJourneyStatus(activeStep),
                  "clientManagement" //page name
                );
                setOpenChangeStatusConfirmDialog(false);
              }}
            >
              Yes
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default ChangeStatusConfirmDialog;
