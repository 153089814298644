import { useState, useEffect } from "react";
import { EMAIL_PATTERN } from "../../../web/src/components/ServiceProvider/serviceProviderConstants";

export const useAddContact = () => {
  const [fullName, setFullName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [duplicateUser, setDuplicateUser] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [touchedEmail, setTouchedEmail] = useState(false);

  const handleDialogClose = (setCreateContactDialog) => {
    setFullName("");
    setBusinessType("");
    setEmailAddress("");
    setDuplicateUser(false);
    setIsValidEmail(true);
    setCreateContactDialog(false);
    setTouchedEmail(false);
  };

  useEffect(() => {
    validateEmail();
  }, [emailAddress]);
  const validateEmail = () => {
    if (EMAIL_PATTERN.test(emailAddress)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  };

  return {
    fullName,
    setFullName,
    emailAddress,
    setEmailAddress,
    businessType,
    setBusinessType,
    duplicateUser,
    setDuplicateUser,
    handleDialogClose,
    isValidEmail,
    validateEmail,
    touchedEmail,
    setTouchedEmail,
  };
};
