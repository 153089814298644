import { useDispatch } from "react-redux";
import { useApiClient } from "../useApiClient";
import { useAuthUser } from "../useLogin";
import { color_error } from "../toasterColors";

export const useDeleteDocument = (
  docDetails,
  categoryId,
  getTabWiseCategoriesList,
  documentTabName
) => {
  const { apiClient } = useApiClient();

  const userInfo = useAuthUser();
  const dispatch = useDispatch();
  const deleteData = {
    documentList: [
      {
        categoryId: categoryId,
        documentId: docDetails && docDetails.docId,
        fileName: docDetails && docDetails.fileName,
        isReferenceDocument: docDetails && docDetails.isReferenceDocument,
      },
    ],
  };

  const deleteDocument = (onResult) => {
    if (docDetails && docDetails.signNowDocumentId) {
      deleteEsignedDocument(onResult);
    } else {
      deleteRefDocument(onResult);
    }
  };

  const deleteEsignedDocument = (onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    const url = `/signnow/delete/document/${docDetails.signNowDocumentId}/${userInfo.userId}`;

    return apiClient
      .delete(url)
      .then((res) => {
        onResult && onResult(res);
        if (res.data.status === 200) {
          getTabWiseCategoriesList &&
            documentTabName &&
            getTabWiseCategoriesList(documentTabName);
        } else if (res.data.status === 401) {
          dispatch({
            type: "SET_SERVER_ERROR",
            error: {
              message: "You do not have permission to perform this operation",
            },
          });
        } else {
          dispatch({
            type: "SET_SERVER_ERROR",
            error: {
              message: "Something went wrong",
            },
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const deleteRefDocument = (onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .delete(`/documents/delete/user/${userInfo.userId}`, {
        data: deleteData,
      })
      .then((res) => {
        if (res.data.status === 200) {
          getTabWiseCategoriesList &&
            documentTabName &&
            getTabWiseCategoriesList(documentTabName);
        } else if (res.data.status === 409) {
          dispatch({
            type: "SET_TOASTER",
            payload: {
              message: res.data.message,
              bgColor: color_error,
              open: true,
            },
          });
        }
        onResult && onResult(res);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    deleteDocument,
  };
};
