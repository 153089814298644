import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";

import {
  ADD_SERVICE,
  REMOVE_SERVICE,
  REORDER_SERVICE,
  UPDATE_SERVICE,
  SET_EXTERNAL_LINKS_SUPPORT,
} from "../../../souqh-redux/reducers/actionConstants";
import { useActions } from "../../useActions";
import { useApiClient } from "../../useApiClient";
import BlobManager from "../../../web/src/utils/BlobManager";

const actionCreators = {
  addService: (payload) => ({
    type: ADD_SERVICE,
    payload,
  }),
  removeService: (payload) => ({
    type: REMOVE_SERVICE,
    payload,
  }),
  updateService: (payload) => ({
    type: UPDATE_SERVICE,
    payload,
  }),
  reOrderService: (payload) => ({
    type: REORDER_SERVICE,
    payload,
  }),
  setExternalLinksSupport: (payload) => ({
    type: SET_EXTERNAL_LINKS_SUPPORT,
    payload,
  }),
};

export const useServices = () => {
  const services = useSelector((state) => state.storefront.services);
  const actions = useActions(actionCreators);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onServiceDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newServices = reorder(
      services,
      result.source.index,
      result.destination.index
    );

    actions.reOrderService(newServices);
  };

  return { onServiceDragEnd, reorder, services, actions };
};
export const useService = (index) => {
  const service = useSelector((state) => {
    if (state.storefront.services) {
      return state.storefront.services[index];
    }
    return null;
  });

  const services = useSelector((state) => state.storefront.services);
  const { externalLinksSupport, storeFrontId } = useSelector(
    (state) => state.storefront
  );
  const [name, setname] = useState("");
  const [description, setdescription] = useState("");
  const [label, setLabel] = useState("");
  const [link, setLink] = useState("");
  const [image, setimage] = useState("");

  const actions = useActions(actionCreators);
  const [files, setFiles] = useState([]);
  const [uploadDocUrl, setUploadDocUrl] = useState(null);
  const [maxFileSize, setMaxFileSize] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const { apiClient } = useApiClient();

  const handleOnChange = (event) => {
    const tempArray = [];
    let files = event.target.files;
    Object.keys(files).forEach((key) => {
      if ((files[key].size / 1000 / 1000).toFixed(2) > 50) {
        // check file size less than 50mb
        setMaxFileSize(true);
      } else {
        setMaxFileSize(false);
      }
    });

    Object.values(event.target.files).map((file) => {
      tempArray.push(file);
      setFiles(tempArray);
    });
  };

  useEffect(() => {
    if (files && files.length && !maxFileSize) {
      setUploadDocUrl(files);
    }
  }, [files]);

  useEffect(() => {
    let serviceNameList = services.map((ele, i) => {
      if (index !== i) {
        return ele.name.toLowerCase();
      }
    });

    if (services && serviceNameList.includes(name.toLocaleLowerCase())) {
      setError("Please enter unique service name");
    } else {
      setError("");
    }
  }, [name]);

  const getFormattedFileName = (uploadDocUrl) => {
    if (typeof uploadDocUrl === "string") {
      if ((uploadDocUrl || "").startsWith("http")) {
        return decodeURIComponent(uploadDocUrl || "")
          .split("/")
          .pop();
      } else {
        return uploadDocUrl;
      }
    }
    // Fix me: will rrmove this code after proper QA testing , currently kept for object type of input coming
    if (uploadDocUrl[0]) {
      if (uploadDocUrl[0].name) {
        return (uploadDocUrl[0].name || "").split("/").pop();
      } else if (typeof uploadDocUrl[0] === "string") {
        if ((uploadDocUrl[0] || "").startsWith("http")) {
          return decodeURIComponent(uploadDocUrl[0] || "")
            .split("/")
            .pop();
        } else {
          return uploadDocUrl[0];
        }
      }
    }
    return "";
  };

  const deleteService = (index, serviceId) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .delete(
        `/service-provider/storefront/${storeFrontId}/service/${serviceId}`
      )
      .then((res) => {
        if (res.data.status === 200) {
          actions.removeService({ index });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const updateExistingService = (serviceData) => {
    const bodyFormData = new FormData();
    let service = serviceData.service;

    let imageBlob;
    let tempValue;
    // service image upload
    if (service.imageUrl) {
      imageBlob = BlobManager.getByName(service.imageUrl);
      imageBlob &&
        bodyFormData.append("serviceDocs", imageBlob.file, imageBlob.fileName);
    }
    // service documents upload
    const isObject = (data) => {
      return typeof data === "object";
    };
    if (service.uploadDocUrl && isObject(service.uploadDocUrl)) {
      Object.values(service.uploadDocUrl).map((value) => {
        bodyFormData.append("serviceDocs", value, value.name);
        tempValue = value.name;
      });
      service.uploadDocUrl = tempValue;
    }

    bodyFormData.append(
      "service",
      new Blob([JSON.stringify(service)], {
        type: "application/json",
      })
    );

    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post(
        `/service-provider/storefront/service?storeFrontId=${storeFrontId}`,
        bodyFormData
      )
      .then((res) => {
        //service updated
        if (res.data.status === 200) {
          actions.updateService(serviceData);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    service,
    actions,
    files,
    handleOnChange,
    uploadDocUrl,
    setUploadDocUrl,
    name,
    description,
    label,
    link,
    image,
    setname,
    setdescription,
    setLabel,
    setLink,
    setimage,
    maxFileSize,
    externalLinksSupport,
    getFormattedFileName,
    deleteService,
    updateExistingService,
    error,
  };
};
