import React, { useEffect } from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import {
  Typography,
  IconButton,
  Dialog,
  Grid,
  makeStyles,
} from "@material-ui/core/";
import Styles from "../../../Styles.module.css";
import NewCampaignDialogHeader from "./NewCampaignDialogHeader";
import NewCampaignDialogFooter from "./NewCampaignDialogFooter";
import NewCampaignStep1 from "./NewCampaignStep1";
import NewCampaignStep2 from "./NewCampaignStep2";
import NewCampaignStep3 from "./NewCampaignStep3";

const styles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    padding: 0,
  },
  dialogPaper: {
    height: window.innerHeight - 20,
    borderRadius: 8,
  },
  diaogFooter: {
    padding: "12px 36px",
  },
  dialogRoot: {
    padding: "0px 16px",
  },
  selectedIcon: {
    fontWeight: "bold",
    backgroundColor: "#FA7E61",
  },
  nonSelectedIcon: {
    backgroundColor: "white",
  },
  default: {
    marginLeft: 7,
    padding: "1px 12px 1px 12px",
    borderRadius: "4px",
    fontWeight: "bold",
  },
  selectedText: {
    color: "#FA7E61 !important",
  },
  defaultText: {
    color: "white !important",
  },
  reviewContainer: {
    marginTop: 60,
  },
}));

const DialogTitle = (props) => {
  const {
    children,
    classes,
    setOpenNewCampaignDialog,
    setActiveSection,
    handleClose,
    setOpenCloseConfirmationDialog,
    ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="subtitle1">{children}</Typography>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={() => {
          setOpenCloseConfirmationDialog(true);
        }}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
};

export default function NewCampaignDialog({
  openNewCampaignDialog,
  setOpenNewCampaignDialog,
  updateIntercomStatus,
  activeSection,
  setActiveSection,
  campaignData,
  enableStep1,
  enableStep2,
  setStep1Data,
  resetCampaignStartDate,
  locationsServed,
  createMarketingCampaign,
  dropDownValues,
  setDetectForcastedResult,
  displayMarketingData,
  duplicateCampaignName,
  setDuplicateCampaignName,
  handleClose,
  setOpenCloseConfirmationDialog,
  validations,
  selelctedRow,
  getMarketingCampaignData,
  setEnableStep2,
  updateMarketingCampaign,
  setSelectedRow = null,
  getMarketingCampaignInvoiceData,
  checkMarketingCampaignNameExists,
  nameAlreadyExist,
}) {
  const classes = styles();

  useEffect(() => {
    updateIntercomStatus && updateIntercomStatus(openNewCampaignDialog.open);
  }, [openNewCampaignDialog.open]);

  useEffect(() => {
    selelctedRow &&
      openNewCampaignDialog.open &&
      getMarketingCampaignData(selelctedRow.id);
  }, [selelctedRow, openNewCampaignDialog.open]);

  return (
    <React.Fragment>
      <Dialog
        id="newCampaign"
        fullScreen
        maxWidth={"lg"}
        onClose={handleClose}
        open={openNewCampaignDialog.open}
        classes={{ paper: classes.dialogPaper, root: classes.dialogRoot }}
      >
        <DialogTitle
          classes={classes}
          className={Styles.navyBlueColor}
          setOpenNewCampaignDialog={setOpenNewCampaignDialog}
          setActiveSection={setActiveSection}
          handleClose={handleClose}
          setOpenCloseConfirmationDialog={setOpenCloseConfirmationDialog}
        >
          {campaignData && campaignData.id ? "Edit Campaign" : "New Campaign"}
        </DialogTitle>
        <MuiDialogContent dividers className={classes.dialogContent}>
          <Grid container style={{ height: "100%" }}>
            <NewCampaignDialogHeader activeSection={activeSection} />
            <Grid item xs={12}>
              {activeSection === 1 && (
                <NewCampaignStep1
                  section={1}
                  campaignData={campaignData}
                  setStep1Data={setStep1Data}
                  dropDownValues={dropDownValues}
                  setDetectForcastedResult={setDetectForcastedResult}
                  displayMarketingData={displayMarketingData}
                  validations={validations}
                  checkMarketingCampaignNameExists={
                    checkMarketingCampaignNameExists
                  }
                  nameAlreadyExist={nameAlreadyExist}
                />
              )}
              {activeSection === 2 && (
                <NewCampaignStep2
                  section={2}
                  campaignData={campaignData}
                  setStep1Data={setStep1Data}
                  resetCampaignStartDate={resetCampaignStartDate}
                  setDetectForcastedResult={setDetectForcastedResult}
                  setEnableStep2={setEnableStep2}
                  selelctedRow={selelctedRow}
                  dropDownValues={dropDownValues}
                />
              )}
              {activeSection === 3 && (
                <Grid container classes={{ root: classes.reviewContainer }}>
                  <NewCampaignStep3
                    section={3}
                    campaignData={campaignData}
                    locationsServed={locationsServed}
                    setDetectForcastedResult={setDetectForcastedResult}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </MuiDialogContent>
        <MuiDialogActions className={classes.diaogFooter}>
          <NewCampaignDialogFooter
            activeSection={activeSection}
            setActiveSection={setActiveSection}
            enableStep1={enableStep1}
            enableStep2={enableStep2}
            createMarketingCampaign={createMarketingCampaign}
            setOpenNewCampaignDialog={setOpenNewCampaignDialog}
            duplicateCampaignName={duplicateCampaignName}
            campaignId={campaignData.id}
            updateMarketingCampaign={updateMarketingCampaign}
            getMarketingCampaignInvoiceData={getMarketingCampaignInvoiceData}
            handleClose={handleClose}
          />
        </MuiDialogActions>
      </Dialog>
    </React.Fragment>
  );
}
