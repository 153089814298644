import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import Styles from "../../Styles.module.css";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 637,
    width: 800,
  },
}));

export default function UpgradePlanDialog({
  openUpgradePlanDialog,
  setOpenUpgradePlanDialog,
}) {
  let history = useHistory();
  const classes = useStyles();

  const subscriptionPlanFeatures = [
    "Branded Storefront+ Premium badge",
    "Powerful data analytics",
    "Marketing campaigns",
  ];
  return (
    <Dialog
      fullWidth
      open={openUpgradePlanDialog}
      disableBackdropClick
      onClose={() => {
        setOpenUpgradePlanDialog(false);
      }}
      maxWidth={"md"}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Change Subscription</Typography>
      </DialogTitle>
      <Divider style={{ marginBottom: 24 }} />
      <DialogContent>
        <Grid
          container
          spacing={3}
          className={Styles.navyBlueColor}
          style={{ height: "100%" }}
        >
          <Grid item xs={4} style={{ marginBottom: 8 }}>
            <Card
              style={{
                color: "#17174C",
                border: "2px solid lightgray",
                width: "100%",
                height: "100%",
              }}
              elevation={0}
            >
              <Grid
                style={{
                  height: "100%",
                }}
              >
                <Grid container item sm={12} style={{ padding: 20 }}>
                  <Grid container item sm={12} justifyContent="center">
                    <img
                      style={{ height: 158, width: 158 }}
                      src={`/images/Premium.svg`}
                    ></img>
                  </Grid>
                  <Grid container item sm={12} justifyContent="center">
                    <Typography className={Styles.navyBlueColor} variant="h5">
                      Premium
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    sm={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid
                      item
                      container
                      xs={12}
                      style={{
                        alignItems: "center",
                        position: "relative",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 20,
                          fontWeight: "600",
                          alignItems: "flex-start",
                          justifyContent: "flex-end",
                          display: "flex",
                          paddingBottom: 40,
                          height: "100%",
                        }}
                      >
                        $
                      </div>
                      <div
                        style={{
                          fontSize: 72,
                          lineHeight: "95px",
                          fontWeight: "600",
                          display: "flex",
                        }}
                      >
                        99
                      </div>
                    </Grid>
                    <Grid container item xs={12} justifyContent="center">
                      <Typography style={{ display: "inline", fontSize: 14 }}>
                        /mo/user
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={8} style={{ marginBottom: 8 }}>
            <Grid item xs={12} style={{ marginBottom: 24 }}>
              <Typography variant="h3" className={Styles.navyBlueColor}>
                It’s time to upgrade!
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 24 }}>
              <Typography className={Styles.navyBlueColor}>
                We know you loved our Pro plan and our Premium Plan is about to
                change your mind!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {subscriptionPlanFeatures.length
                ? subscriptionPlanFeatures.map((feature, index) => (
                    <Grid
                      container
                      item
                      sm={12}
                      justifyContent="flex-start"
                      key={index}
                      style={{ marginTop: 5 }}
                    >
                      <Grid item xs={1}>
                        <CheckCircleIcon
                          style={{ color: "#107E3E", marginTop: 3 }}
                        />
                      </Grid>
                      <Grid
                        item
                        container
                        xs={11}
                        justifyContent="flex-start"
                        style={{
                          fontSize: 14,
                          marginTop: 4,
                          paddingLeft: 16,
                        }}
                        className={Styles.navyBlueColor}
                      >
                        {feature}
                      </Grid>
                    </Grid>
                  ))
                : null}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          className={Styles.navyBlueColorForce}
          style={{ textTransform: "capitalize", marginRight: 8 }}
          onClick={(event) => {
            event.stopPropagation();
            setOpenUpgradePlanDialog(false);
          }}
        >
          Cancel
        </Button>
        <Button
          className={Styles.primaryBtnAccent}
          style={{
            textTransform: "capitalize",
            marginRight: 16,
            padding: "6px 24px",
          }}
          onClick={(event) => {
            event.stopPropagation();
            history.push("/serviceprovider/settings/subscription?panel=panel1");
            setOpenUpgradePlanDialog(false);
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
