import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Divider,
  makeStyles,
  Card,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";
import { useSubscriptionPlan } from "souqh-react-redux-hooks/serviceProvider/plans/useSubscriptionPlan";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 637,
    width: 800,
  },
}));

function TeamMgmtPlanChangeDialog({
  openTeamMgmtPlanChangeDialog,
  setOpenTeamMgmtPlanChangeDialog,
  editData,
  prevData,
}) {
  const classes = useStyles();

  const { subscriptionPlans } = useSubscriptionPlan();

  const planUpgrade =
    editData && prevData.subscriptionPlan?.id < editData.plan?.id
      ? true
      : false;

  const planDurationUpgrade =
    prevData &&
    prevData.paymentCycle === "MONTHLY" &&
    editData &&
    editData.paymentCycle?.value === "YEARLY"
      ? true
      : false;

  const prevDetails = [
    {
      key: "Current billing plan",
      value:
        prevData &&
        subscriptionPlans &&
        subscriptionPlans.find((el) => el.id === prevData.subscriptionPlan?.id)
          ?.planName,
    },
    { key: "Current billing cycle", value: prevData && prevData.paymentCycle },
    // { key: "Current billing date", value: "" },
    // { key: "Current charges", value: "" },
  ];

  const newDetails = [
    {
      key: "New billing plan",
      value:
        editData &&
        subscriptionPlans &&
        subscriptionPlans.find((el) => el.id === editData.plan?.id)?.planName,
    },
    {
      key: "New billing cycle",
      value: editData && editData.paymentCycle?.value,
    },
    // { key: "Next billing date", value: "" },
    // { key: "New charges", value: "" },
  ];

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        open={openTeamMgmtPlanChangeDialog}
        disableBackdropClick
        onClose={() => {
          setOpenTeamMgmtPlanChangeDialog(false);
        }}
        maxWidth={"md"}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DialogTitle className={Styles.navyBlueColor}>
          <Typography variant="subtitle1">Change Subscription</Typography>
        </DialogTitle>
        <Divider style={{ marginBottom: 24 }} />
        <DialogContent>
          <Grid container spacing={3} className={Styles.navyBlueColor}>
            <Grid item xs={4} style={{ marginBottom: 8 }}>
              <Card
                style={{
                  color: "#17174C",
                  border: "2px solid lightgray",
                  width: "100%",
                  height: "100%",
                }}
                elevation={0}
              >
                <Grid
                  style={{
                    height: "100%",
                  }}
                >
                  <Grid container item sm={12} style={{ padding: 20 }}>
                    <Grid container item sm={12} justifyContent="center">
                      <img
                        style={{ height: 158, width: 158 }}
                        src={`/images/${
                          editData && editData.plan?.planName
                        }.svg`}
                      ></img>
                    </Grid>
                    <Grid container item sm={12} justifyContent="center">
                      <Typography className={Styles.navyBlueColor} variant="h5">
                        {editData && editData.plan?.planName}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      item
                      sm={12}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid
                        item
                        container
                        xs={12}
                        style={{
                          alignItems: "center",
                          position: "relative",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 20,
                            fontWeight: "600",
                            alignItems: "flex-start",
                            justifyContent: "flex-end",
                            display: "flex",
                            paddingBottom: 40,
                            height: "100%",
                          }}
                        >
                          $
                        </div>
                        <div
                          style={{
                            fontSize: 72,
                            lineHeight: "95px",
                            fontWeight: "600",
                            display: "flex",
                          }}
                        >
                          {editData &&
                          editData.plan?.id &&
                          editData.paymentCycle?.value === "MONTHLY"
                            ? subscriptionPlans
                                ?.find((el) => el.id === editData.plan?.id)
                                ?.monthlyPrice.toString()
                                .split(".")[0]
                            : subscriptionPlans
                                ?.find((el) => el.id === editData.plan?.id)
                                ?.annualPrice.toString()
                                .split(".")[0]}
                        </div>
                      </Grid>
                      <Grid container item xs={12} justifyContent="center">
                        <Typography style={{ display: "inline", fontSize: 14 }}>
                          {editData &&
                          editData.paymentCycle?.value === "MONTHLY"
                            ? "/mo/user"
                            : "/year/user"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid container item xs={8} style={{ marginBottom: 8 }}>
              {/* <Grid item xs={12}>
                {!planUpgrade && !planDurationUpgrade ? (
                  <Typography variant="h3" className={Styles.navyBlueColor}>
                    Are you sure?
                  </Typography>
                ) : (
                  <Typography variant="h3" className={Styles.navyBlueColor}>
                    It’s time to upgrade!
                  </Typography>
                )}
              </Grid> */}
              <Grid item xs={12} style={{ marginBottom: 16 }}>
                <Typography className={Styles.navyBlueColor}>
                  You have opted to change your current billing cycle.
                </Typography>
                <Typography className={Styles.navyBlueColor}>
                  This will impact your billing cycle
                </Typography>
              </Grid>
              <Grid container item xs={12} style={{ marginBottom: 16 }}>
                {prevDetails.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Typography className={Styles.navyBlueColor}>
                      {item.key}: {item.value}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Grid container item xs={12} style={{ marginBottom: 16 }}>
                {newDetails && newDetails.length
                  ? newDetails.map((item, index) => (
                      <Grid item xs={12} key={index}>
                        <Typography className={Styles.navyBlueColor}>
                          {item.key}: {item.value}
                        </Typography>
                      </Grid>
                    ))
                  : null}
              </Grid>
              {!planUpgrade && !planDurationUpgrade ? (
                <Grid item xs={12}>
                  <Typography className={Styles.navyBlueColor}>
                    You have opted for plan downgrade. This will deactivate some
                    of the existing features in the Souqh application as per
                    your plan selection.
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Typography className={Styles.navyBlueColor}>
                    You have opted for plan upgrade. Please re-login again to
                    view the features as per your plan
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="outlined"
            className={Styles.navyBlueColorForce}
            style={{ textTransform: "capitalize", marginRight: 8 }}
            onClick={() => {
              setOpenTeamMgmtPlanChangeDialog(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default TeamMgmtPlanChangeDialog;
