import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";

export default function TaggedTemplate({
  taggedDoucment,
  openTagged,
  setStopListen,
}) {
  useEffect(() => {
    setStopListen(false);
  }, []);
  return (
    <Grid style={{ padding: "0px", width: "99%", height: "99%" }}>
      {taggedDoucment ? (
        <iframe
          src={
            openTagged
              ? taggedDoucment.documentTagUrl
              : taggedDoucment.smartFieldUrl
          }
          width="100%"
          height="100%"
        >
          iframe is not supported
        </iframe>
      ) : null}
    </Grid>
  );
}
