import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Grid,
  Button,
  Typography,
  TextField,
  Paper,
  Tabs,
  Tab,
  IconButton,
  SwipeableDrawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  Link,
  Tooltip,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import { SP_TAB_NAMES } from "./spManagementConstants";
import clsx from "clsx";
import { useSPManagement } from "souqh-react-redux-hooks/homeBuyer/spManagement/useSPManagement";
import GridOnIcon from "@material-ui/icons/GridOn";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import SPInfoGridView from "./SPInfoGridView";
import SPInfoTableView from "./SPInfoTableView";
import AddSPDialog from "./AddSPDialog";
import { useSQQuery } from "souqh-react-redux-hooks/common/useSQQuery";
import { useHistory } from "react-router-dom";
import { isMobileDevice } from "../../../utils/AppUtils";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import NoDataAvailable from "../../common/NoDataAvailable";
import SearchNotFound from "../../common/SearchNotFound";

const useStyles = makeStyles((theme) => ({
  marginTop20: {
    marginTop: 20,
  },
  tabs: {
    minWidth: "16%",
    fontWeight: "bold",
    textTransform: "capitalize",
    opacity: "100%",
    fontSize: 14,
  },
  modal: {
    zIndex: "1400 !important",
  },
  paper: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
}));

export default function SPManagement() {
  const classes = useStyles();
  const {
    displaySPData,
    searchSPText,
    requestSPSearch,
    serviceProvidersTab,
    getSpManagementData,
    spManagement,
    handlePageChange,
    actions: { setServiceProvidersTab },
    getDataOnTabChange,
    pageNumber,
    getRequestsSPData,
    getArchiveSPData,
  } = useSPManagement();

  const [tableView, setTableView] = useState(false);
  const [addSpDialog, setAddSpDialog] = useState(false);
  const [tabDrawer, setTabDrawer] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Active");
  const { query } = useSQQuery();
  const history = useHistory();

  useEffect(() => {
    let unlisten = history.listen((location, action) => {
      let tab = location.search.split("=");
      setServiceProvidersTab(parseInt(tab[tab.length - 1]));
    });
    const tabIndex = query.get("tabIndex");
    if (tabIndex) {
      if (
        tabIndex === "0" ||
        tabIndex === "1" ||
        tabIndex === "2" ||
        tabIndex === "3"
      ) {
        setServiceProvidersTab(parseInt(tabIndex));
      } else {
        setServiceProvidersTab(0);
        setSelectedTab("Active");
      }
    } else {
      setServiceProvidersTab(0);
      setSelectedTab("Active");
    }
    getSpManagementData();
    window.scrollTo(0, 0); // scroll at top of page on load
    return () => {
      setServiceProvidersTab(null);
      unlisten();
    };
  }, []);

  useEffect(() => {
    getDataOnTabChange();
  }, [serviceProvidersTab]);

  const moreActionsList = () => (
    <div
      className={classes.fullList}
      role="presentation"
      onClick={() => {
        setTabDrawer(false);
      }}
      onKeyDown={() => {
        setTabDrawer(false);
      }}
    >
      <Grid container item xs={12} style={{ padding: 16 }}>
        <Grid item xs={10}>
          <Typography variant="subtitle1" className={Styles.navyBlueColorForce}>
            More Information
          </Typography>
        </Grid>
        <Grid container item xs={2} justifyContent="flex-end">
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              setTabDrawer(false);
            }}
          />
        </Grid>
      </Grid>
      <Divider />
      <List>
        {SP_TAB_NAMES.map((text, index) => (
          <ListItem
            key={index}
            onClick={() => {
              setSelectedTab(text.value);
              setServiceProvidersTab(index);
            }}
          >
            <ListItemText
              primary={text.value}
              className={Styles.navyBlueColorForce}
              style={{ fontSize: 14 }}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const getNoResultInfo = () => {
    if (serviceProvidersTab === 0) {
      return (
        <Typography style={{ color: "#868DA6" }}>
          Looks like you are not working with any active service providers.
          Click{" "}
          <Link style={{ cursor: "pointer" }} href="/homebuyer/explore">
            here
          </Link>{" "}
          to explore storefronts to request services
        </Typography>
      );
    } else {
      return (
        <Typography style={{ color: "#868DA6" }}>
          Looks like you do not have any active service requests. Click{" "}
          <Link style={{ cursor: "pointer" }} href="/homebuyer/explore">
            here
          </Link>{" "}
          to explore storefronts to request services
        </Typography>
      );
    }
  };

  return (
    <Grid container>
      <SwipeableDrawer
        classes={{ modal: classes.modal, paper: classes.paper }}
        anchor="bottom"
        open={tabDrawer}
        onClose={() => {
          setTabDrawer(false);
        }}
        onOpen={() => {
          setTabDrawer(true);
        }}
      >
        {tabDrawer && moreActionsList()}
      </SwipeableDrawer>
      <AddSPDialog addSpDialog={addSpDialog} setAddSpDialog={setAddSpDialog} />

      <Grid container item xs={12}>
        <Grid item xs={9} md={12}>
          <Typography
            variant={isMobileDevice() ? "h6" : "h4"}
            className={Styles.navyBlueColor}
          >
            Service Providers
          </Typography>
        </Grid>
        {isMobileDevice() && (
          <Grid container item xs={3} justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={() => setAddSpDialog(true)}
              className={Styles.primaryBtnAccent}
              style={{ textTransform: "capitalize", height: 40 }}
            >
              <AddIcon style={{ height: 30, width: 30 }} />
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} className={classes.marginTop20}>
        <Grid item xs={12} md={9}>
          <TextField
            value={searchSPText}
            variant="outlined"
            fullWidth
            placeholder="Search by service provider name, email address, business name or storefront name"
            style={{ backgroundColor: "#FFFFFF" }}
            onChange={(event) => {
              requestSPSearch(event.target.value);
            }}
          ></TextField>
        </Grid>
        {!isMobileDevice() && (
          <Grid
            container
            item
            xs={12}
            md={3}
            justifyContent={isMobileDevice() ? "flex-start" : "flex-end"}
            alignItems="center"
            className={isMobileDevice() && classes.marginTop20}
          >
            <Button
              variant="outlined"
              onClick={() => setAddSpDialog(true)}
              className={Styles.primaryBtnAccent}
              style={{ textTransform: "capitalize", height: 40 }}
            >
              Add Service provider
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} style={{ marginTop: 8 }}>
        {isMobileDevice() ? (
          <Grid item container xs={12}>
            <Button
              fullWidth
              variant="outlined"
              className={Styles.navyBlueColorForce}
              style={{
                textTransform: "capitalize",
                marginTop: 12,
                padding: 12,
              }}
              onClick={() => setTabDrawer(true)}
              endIcon={<ExpandMoreIcon />}
            >
              {selectedTab}
            </Button>
          </Grid>
        ) : (
          <Paper elevation={0}>
            <Grid container item xs={12}>
              <Grid item md={10} lg={11}>
                <Tabs
                  value={serviceProvidersTab}
                  aria-label="sp-management-tabs"
                  classes={{ indicator: Styles.indicator }}
                >
                  {SP_TAB_NAMES.map((tab, index) => (
                    <Tab
                      key={index}
                      label={`${tab.value} (${spManagement[tab.key].count})`}
                      className={clsx(classes.tabs, {
                        [Styles.primaryTxtAccentForce]:
                          index === serviceProvidersTab,
                        [Styles.navyBlueColorForce]:
                          index !== serviceProvidersTab,
                      })}
                      onClick={() => setServiceProvidersTab(index)}
                    />
                  ))}
                </Tabs>
              </Grid>
              <Grid
                item
                md={2}
                lg={1}
                container
                justifyContent="space-around"
                alignItems="center"
              >
                {serviceProvidersTab !== 0 ? (
                  <React.Fragment>
                    <Tooltip title="Coming Soon">
                      <img src="/images/filter.svg" />
                    </Tooltip>
                    <IconButton onClick={() => setTableView(!tableView)}>
                      {tableView ? (
                        <GridOnIcon className={Styles.navyBlueColor} />
                      ) : (
                        <FormatListBulletedIcon
                          className={Styles.navyBlueColor}
                        />
                      )}
                    </IconButton>
                  </React.Fragment>
                ) : null}
              </Grid>
            </Grid>
          </Paper>
        )}
      </Grid>
      {displaySPData &&
      displaySPData.response &&
      displaySPData.response.length ? (
        <Grid item xs={12} className={classes.marginTop20}>
          {tableView && serviceProvidersTab !== 0 ? (
            <SPInfoTableView
              displaySPData={displaySPData}
              tabValue={serviceProvidersTab}
              handlePageChange={handlePageChange}
              getSpManagementData={getSpManagementData}
              getDataOnTabChange={getDataOnTabChange}
              pageNumber={pageNumber}
              getRequestsSPData={getRequestsSPData}
              getArchiveSPData={getArchiveSPData}
            />
          ) : (
            <SPInfoGridView
              displaySPData={displaySPData}
              tabValue={serviceProvidersTab}
              handlePageChange={handlePageChange}
              getSpManagementData={getSpManagementData}
              getDataOnTabChange={getDataOnTabChange}
              pageNumber={pageNumber}
              getRequestsSPData={getRequestsSPData}
              getArchiveSPData={getArchiveSPData}
            />
          )}
        </Grid>
      ) : searchSPText ? (
        <SearchNotFound functionality="service providers" />
      ) : serviceProvidersTab === 0 || serviceProvidersTab === 1 ? (
        <NoDataAvailable isUserMgmtPage={true}>
          {getNoResultInfo()}
        </NoDataAvailable>
      ) : null}
    </Grid>
  );
}
