import { Divider, Grid, Paper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import Styles from "../../../Styles.module.css";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";
import { useSalesAnalytics } from "souqh-react-redux-hooks/serviceProvider/analytics/useSalesAnalytics";
import {
  ResponsiveContainer,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  AreaChart,
  BarChart,
  Bar,
  Cell,
} from "recharts";
import NoDataAvailable from "../../common/NoDataAvailable";

export default function SalesAnalytics({ days }) {
  const {
    getSalesAnalyticsData,
    salesAnalyticsData,
    finalServiceReqData,
    finalServicesData,
  } = useSalesAnalytics(days);

  useEffect(() => {
    getSalesAnalyticsData();
  }, [days]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="tooltip" style={{ backgroundColor: "white" }}>
          <p className="tooltipLabel">{`${payload[0].payload?.xValue}`}</p>
          <p className="tooltipDesc">{`${payload[0]?.payload?.value}`}</p>
        </div>
      );
    }

    return null;
  };

  const tickFormatter = (value, index) => {
    const limit = 7;
    if (value.length <= limit) return value;
    return `${value.substring(0, limit)}...`;
  };

  return (
    <Grid
      container
      spacing={3}
      style={{
        marginTop: 12,
        paddingRight: !isMobileDevice() && !isTabletDevice() && 20,
      }}
    >
      <Grid item xs={12} md={4}>
        <Paper
          elevation={0}
          style={{
            border:
              (isMobileDevice() || isTabletDevice()) && "1px solid #0000001F",
          }}
        >
          <Grid item xs={12} style={{ padding: "16px 0px 8px 0px" }}>
            <Typography
              align="center"
              className={Styles.navyBlueColor}
              style={{ fontWeight: 600 }}
            >
              Number of Service Requests
            </Typography>
          </Grid>
          <Divider />
          <Grid item xs={12} style={{ padding: "24px 0px 48px 0px" }}>
            <Typography
              variant="h1"
              align="center"
              className={Styles.navyBlueColor}
            >
              {(salesAnalyticsData &&
                salesAnalyticsData.serviceRequestsCount) ||
                0}
            </Typography>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper
          elevation={0}
          style={{
            border:
              (isMobileDevice() || isTabletDevice()) && "1px solid #0000001F",
          }}
        >
          <Grid item xs={12} style={{ padding: "16px 0px 8px 0px" }}>
            <Typography
              align="center"
              className={Styles.navyBlueColor}
              style={{ fontWeight: 600, padding: isTabletDevice() && 10 }}
            >
              Number of Clients
            </Typography>
          </Grid>
          <Divider />
          <Grid item xs={12} style={{ padding: "24px 0px 48px 0px" }}>
            <Typography
              variant="h1"
              align="center"
              className={Styles.navyBlueColor}
            >
              {(salesAnalyticsData && salesAnalyticsData.clientsCount) || 0}
            </Typography>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper
          elevation={0}
          style={{
            border:
              (isMobileDevice() || isTabletDevice()) && "1px solid #0000001F",
          }}
        >
          <Grid item xs={12} style={{ padding: "16px 0px 8px 0px" }}>
            <Typography
              align="center"
              className={Styles.navyBlueColor}
              style={{ fontWeight: 600, padding: isTabletDevice() && 10 }}
            >
              Total Referral Credits
            </Typography>
          </Grid>
          <Divider />
          <Grid
            item
            xs={12}
            style={{ padding: "24px 0px 48px 0px", textAlign: "center" }}
          >
            <Typography
              variant="subtitle1"
              align="center"
              className={Styles.navyBlueColor}
              style={{ display: "inline-flex" }}
            >
              $
            </Typography>
            <Typography
              variant="h1"
              align="center"
              className={Styles.navyBlueColor}
              style={{ display: "inline-flex" }}
            >
              {(salesAnalyticsData && salesAnalyticsData.totalReferralCount) ||
                0}
            </Typography>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            border:
              (isMobileDevice() || isTabletDevice()) && "1px solid #0000001F",
          }}
        >
          <Grid item xs={12} style={{ padding: "16px 0px 8px 0px" }}>
            <Typography
              align="center"
              className={Styles.navyBlueColor}
              style={{ fontWeight: 600 }}
            >
              Service Requests Over Time
            </Typography>
          </Grid>
          <Divider />
          <Grid
            item
            xs={12}
            style={{
              padding: "24px 0px",
              textAlign: "center",
              height: "18.5rem",
            }}
          >
            {finalServiceReqData.every((item) => item.value === 0) ? (
              <NoDataAvailable isSFAnalyticsPage={true} />
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  // width={1200}
                  // height={300}
                  data={finalServiceReqData}
                  margin={{ top: 5, right: 20, bottom: 20, left: 0 }}
                  dataKey="value"
                  nameKey="xValue"
                >
                  <Line
                    type="monotone"
                    dataKey="value"
                    stroke="#8884d8"
                    dot={true}
                  />
                  <XAxis
                    dataKey="year"
                    textAnchor="end"
                    sclaeToFit="true"
                    verticalAnchor="start"
                    interval={0}
                    angle="-30"
                  />
                  <YAxis allowDecimals={false} />
                  <Tooltip content={<CustomTooltip />} />
                  <Area
                    type="monotone"
                    dataKey="value"
                    stroke="#8884d8"
                    fill="#C9CFF3"
                  />
                </AreaChart>
              </ResponsiveContainer>
            )}
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            border:
              (isMobileDevice() || isTabletDevice()) && "1px solid #0000001F",
          }}
        >
          <Grid item xs={12} style={{ padding: "16px 0px 8px 0px" }}>
            <Typography
              align="center"
              className={Styles.navyBlueColor}
              style={{ fontWeight: 600 }}
            >
              Top Selling Services
            </Typography>
          </Grid>
          <Divider />
          <Grid
            item
            xs={12}
            style={{ padding: "24px 0px 48px 0px", textAlign: "center" }}
          >
            {!finalServicesData.length ? (
              <NoDataAvailable isSFAnalyticsPage={true} />
            ) : (
              <ResponsiveContainer
                width={"100%"}
                height={50 * finalServicesData.length}
                debounce={50}
              >
                <BarChart
                  data={finalServicesData}
                  layout="vertical"
                  margin={{ left: 30, right: 20 }}
                >
                  <Tooltip cursor={{ fill: "transparent" }} />
                  <XAxis type="number" />
                  <YAxis
                    yAxisId={0}
                    dataKey="name"
                    interval={0}
                    type="category"
                    tickLine={false}
                    tickFormatter={tickFormatter}
                  />

                  <Bar dataKey="value" minPointSize={2} barSize={32}>
                    {finalServicesData.map((d, idx) => {
                      return <Cell fill="#C9CFF3" />;
                    })}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
