import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import MoveIntoHomeDialog from "./MoveIntoHomeDialog";
import CloseYourHomeDialog from "./CloseYourHomeDialog";
import FundYourHomeDialog from "./FundYourHomeDialog";
import FindYourHomeDialog from "./FindYourHomeDialog";
import { isMobileDevice, setHBJourneyStatus } from "../../../utils/AppUtils";
import { useNewHomeProfile } from "souqh-react-redux-hooks/homeBuyer/hbProfile/useNewHomeProfile";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 350,
  },
}));

function NextPhaseDialog({
  openNextPhaseDialog,
  setOpenNextPhaseDialog,
  previousStage,
  phase,
  updateJourneyStatus,
  backwardTransition,
  newStageName,
}) {
  const classes = useStyles();

  const [openMoveIntoHomeDialog, setOpenMoveIntoHomeDialog] = useState(false);
  const [openCloseYourHomeDialog, setOpenCloseYourHomeDialog] = useState(false);
  const [openFundYourHomeDialog, setOpenFundYourHomeDialog] = useState(false);
  const [openFindYourHomeDialog, setOpenFindYourHomeDialog] = useState(false);

  const openFollowUpDialog = () => {
    switch (phase) {
      case 0:
        return setOpenFindYourHomeDialog(true);
      case 1:
        return setOpenFundYourHomeDialog(true);
      case 2:
        return setOpenCloseYourHomeDialog(true);
      case 3:
        return setOpenMoveIntoHomeDialog(true);
      default:
        return;
    }
  };

  const { getNewProfileData, newHomeProfile } = useNewHomeProfile();

  useEffect(() => {
    getNewProfileData();
  }, []);

  return (
    <>
      <MoveIntoHomeDialog
        openMoveIntoHomeDialog={openMoveIntoHomeDialog}
        setOpenMoveIntoHomeDialog={setOpenMoveIntoHomeDialog}
        newHomeProfile={newHomeProfile}
      />
      <CloseYourHomeDialog
        openCloseYourHomeDialog={openCloseYourHomeDialog}
        setOpenCloseYourHomeDialog={setOpenCloseYourHomeDialog}
        newHomeProfile={newHomeProfile}
      />
      <FundYourHomeDialog
        openFundYourHomeDialog={openFundYourHomeDialog}
        setOpenFundYourHomeDialog={setOpenFundYourHomeDialog}
        newHomeProfile={newHomeProfile}
      />
      <FindYourHomeDialog
        openFindYourHomeDialog={openFindYourHomeDialog}
        setOpenFindYourHomeDialog={setOpenFindYourHomeDialog}
        newHomeProfile={newHomeProfile}
      />
      <Dialog
        fullWidth
        disableBackdropClick
        maxWidth={"sm"}
        open={openNextPhaseDialog}
        onClose={() => {
          setOpenNextPhaseDialog(false);
        }}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DialogTitle className={Styles.navyBlueColor}>
          <Typography variant="subtitle1">Information</Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid item xs={12} style={{ paddingBottom: 16 }}>
            {backwardTransition ? (
              <Typography variant="body1" className={Styles.navyBlueColor}>
                Are you sure you want to move to {newStageName}?
              </Typography>
            ) : (
              <Typography variant="body1" className={Styles.navyBlueColor}>
                You are about to begin the next phase in your journey. Please
                confirm you have completed {previousStage}
              </Typography>
            )}
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions style={{ padding: "16px 24px" }}>
          <Grid container item xs={12}>
            <Grid item xs={12} md={6}>
              <Checkbox style={{ marginLeft: isMobileDevice() && -12 }} />
              <Typography
                variant="body1"
                className={Styles.navyBlueColor}
                style={{ display: "inline-flex" }}
              >
                Don't show this again
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={6}
              justifyContent={!isMobileDevice() && "flex-end"}
              style={{ marginTop: isMobileDevice() && 16 }}
            >
              <Button
                variant="outlined"
                style={{
                  padding: "6px 16px",
                  marginRight: 12,
                  textTransform: "capitalize",
                }}
                className={Styles.navyBlueColorForce}
                onClick={() => {
                  setOpenNextPhaseDialog(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className={Styles.primaryBtnAccent}
                style={{ textTransform: "capitalize", padding: "6px 32px" }}
                onClick={() => {
                  setOpenNextPhaseDialog(false);
                  // !backwardTransition &&
                  openFollowUpDialog();
                  updateJourneyStatus(setHBJourneyStatus(phase));
                }}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NextPhaseDialog;
