import {
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Divider,
  ButtonBase,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Styles from "../../Styles.module.css";

export default function CustomAccordian({
  data,
  index,
  setSummaryTobeEdited,
  deleteSummary,
  canEdit,
}) {
  return (
    <Accordion
      disableEscapeKeyDown
      style={{ color: "white", borderRadius: 8, marginBottom: 20 }}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            style={{ color: "white", fontSize: 48, paddingLeft: 0 }}
          />
        }
        aria-controls="panel1a-content"
        id="summary-dialog"
        style={{
          backgroundColor: "#FA7E61",
          minHeight: 0,
          height: 55,
          paddingTop: 10,
          paddingBottom: 10,
          borderRadius: 8,
          overflow: "hidden",
        }}
      >
        <div style={{ width: "100%", overflow: "hidden" }}>
          <Grid item container xs={12} style={{ alignItems: "center" }}>
            <Grid item xs={11}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  style={{
                    color: "white",
                  }}
                  title={data.title}
                  className={Styles.sqEllipsis}
                >
                  {data.title}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "white",
                  }}
                  title={data.subtitle}
                  className={Styles.sqEllipsis}
                >
                  {data.subtitle}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingBottom: 8,
              }}
            >
              {canEdit ? (
                <ButtonBase style={{ marginBottom: 4, marginRight: 12 }}>
                  <EditIcon
                    style={{ marginTop: 10, cusror: "pointer" }}
                    onClick={(event) => {
                      event.stopPropagation();
                      setSummaryTobeEdited({ ...data, index });
                    }}
                  />
                </ButtonBase>
              ) : null}

              {canEdit ? (
                <ButtonBase style={{ marginBottom: 4 }}>
                  <DeleteIcon
                    style={{ marginTop: 10, cusror: "pointer" }}
                    onClick={(event) => {
                      event.stopPropagation();
                      deleteSummary(data.id);
                    }}
                  />
                </ButtonBase>
              ) : null}
            </Grid>
          </Grid>
        </div>
      </AccordionSummary>
      <AccordionDetails className={Styles.navyBlueColor}>
        {data.description}
      </AccordionDetails>
    </Accordion>
  );
}
