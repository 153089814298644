import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuthUser } from "../useLogin";
import { SET_EMAIL_PREFERENCES } from "souqh-redux/reducers/actionConstants";
import { useApiClient } from "../useApiClient";
import { color_success } from "../toasterColors";

const useEmailPreferences = () => {
  const user = useAuthUser();
  const dispatch = useDispatch();
  const { apiClient } = useApiClient();
  const { preferences } = useSelector((state) => state.emailPreferences);
  const [emailPreferences, setEmailPreferences] = useState(preferences);

  useEffect(() => {
    setEmailPreferences(preferences);
  }, [preferences]);

  const getEmailPreferences = () => {
    dispatch({ type: "SET_LOADING", value: true });

    apiClient
      .get(`/email-preferences/email-preference/${user.userId}`)
      .then((res) => {
        if (res.data.status === 200 && res.data.result) {
          dispatch({ type: SET_EMAIL_PREFERENCES, payload: res.data.result });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const saveEmailPreferences = () => {
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .put(
        `/email-preferences/new-email-preference/${
          user.userId
        }?emailPreferenceRequest=${encodeURIComponent(
          JSON.stringify(emailPreferences)
        )}`
      )
      .then((res) => {
        if (res.data.status === 200) {
          dispatch({ type: SET_EMAIL_PREFERENCES, payload: emailPreferences });
          dispatch({
            type: "SET_TOASTER",
            payload: {
              message: "Email preferences updated successfully",
              bgColor: color_success,
              open: true,
            },
          });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const resetPreferences = () => {
    setEmailPreferences(preferences);
  };

  return {
    emailPreferences,
    saveEmailPreferences,
    setEmailPreferences,
    getEmailPreferences,
    resetPreferences,
  };
};

export default useEmailPreferences;
