import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useBusinessType } from "souqh-react-redux-hooks/useBusinessType";
import { useInviteSPDialog } from "souqh-react-redux-hooks/common/useInviteSPDialog";
import { useReferralManagement } from "souqh-react-redux-hooks/common/useReferralManagement";
import InviteCheck from "../InviteCheck";
import ConfirmationDailog from "./ConfirmationDailog";
import { isMobileDevice, isTabletDevice } from "../../utils/AppUtils";
import MaskedMobileInput from "../MaskedMobileInput";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 574,
    minWidth: isTabletDevice() ? 700 : !isMobileDevice() && 1140,
  },
  grids: {
    marginBottom: 16,
  },
}));

function InviteSPDialog({ openInviteSPDialog, setOpenInviteSPDialog }) {
  const classes = useStyles();
  const { businessTypes } = useBusinessType();
  const {
    iniviteSP,
    validations,
    allowAction,
    sendSpInvite,
    addValidations,
    actions: { saveIniviteSpData, clearData },
  } = useInviteSPDialog();

  const {
    actions: { setSpReferralList },
  } = useReferralManagement(false);

  const [openConfirmationDailog, setOpenConfirmationDailog] = useState(false);

  return (
    <>
      <ConfirmationDailog
        openConfirmationDailog={openConfirmationDailog}
        setOpenConfirmationDailog={setOpenConfirmationDailog}
        setOpenInviteSPDialog={setOpenInviteSPDialog}
        clearData={clearData}
        type="inviteSP"
      />
      <Dialog
        open={openInviteSPDialog}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DialogTitle>
          <Typography variant="subtitle1" className={Styles.navyBlueColor}>
            Invite New Service Provider
          </Typography>
        </DialogTitle>
        <Divider
          orientation="horizontal"
          variant="fullWidth"
          style={{ width: "100%" }}
        />
        <DialogContent>
          <DialogContentText>
            <Grid container style={{ marginTop: 25 }}>
              {!isMobileDevice() && (
                <Grid item md={6}>
                  <img
                    src="/images/Invite-SP.svg"
                    style={{ width: isTabletDevice() ? 280 : 461 }}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <Grid item xs={12} className={classes.grids}>
                  <Autocomplete
                    value={iniviteSP.serviceProviderType}
                    fullWidth
                    id="service-provider-type"
                    options={businessTypes}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => {
                      return option.name === value.name;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Provider Type"
                        variant="outlined"
                      />
                    )}
                    onChange={(event, value) => {
                      saveIniviteSpData({
                        serviceProviderType: value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} className={classes.grids}>
                  <TextField
                    id="fullName"
                    placeholder="Full Name"
                    label="Full Name"
                    name="fullName"
                    variant="outlined"
                    fullWidth
                    value={iniviteSP.fullName}
                    onChange={(event) => {
                      saveIniviteSpData({ fullName: event.target.value });
                    }}
                    inputProps={{
                      maxLength: 256,
                    }}
                  />
                </Grid>
                <Grid item xs={12} className={classes.grids}>
                  <TextField
                    error={!!validations.email}
                    helperText={
                      validations.email && (
                        <InviteCheck validations={validations} />
                      )
                    }
                    id="emailAddress"
                    placeholder="Email Address"
                    label="Email Address"
                    name="emailAddress"
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      maxLength: 256,
                    }}
                    onChange={(event) => {
                      saveIniviteSpData({ email: event.target.value });
                    }}
                    value={iniviteSP.email}
                  />
                </Grid>
                <Grid item xs={12} className={classes.grids}>
                  <MaskedMobileInput
                    value={iniviteSP.phoneNumber}
                    error={!!validations.phoneNumber}
                    helperText={
                      validations.phoneNumber &&
                      validations.phoneNumber.join(" ")
                    }
                    id="phoneNumber"
                    label="Phone Number"
                    name="phoneNumber"
                    variant="outlined"
                    type="tel"
                    fullWidth
                    onChange={(e) => {
                      saveIniviteSpData({
                        phoneNumber: e.target.value.replaceAll("-", ""),
                      });
                    }}
                  ></MaskedMobileInput>
                </Grid>
                <Grid item xs={12} className={classes.grids}>
                  <TextField
                    id="website"
                    placeholder="Website"
                    label="Website"
                    name="website"
                    variant="outlined"
                    fullWidth
                    value={iniviteSP.website}
                    onChange={(event) => {
                      saveIniviteSpData({ website: event.target.value });
                    }}
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end">
              <Typography>
                <a
                  className={Styles.navyBlueColor}
                  target="_blank"
                  href="https://help.souqh.ca/en/articles/5342665-invite-service-providers-and-earn-credits"
                >
                  Learn more about referral credits
                </a>
              </Typography>
            </Grid>
          </DialogContentText>
        </DialogContent>

        <Divider
          orientation="horizontal"
          variant="fullWidth"
          style={{ width: "100%" }}
        />
        <DialogActions style={{ paddingRight: 38 }}>
          <Button
            variant="outlined"
            className={Styles.navyBlueColorForce}
            style={{ textTransform: "capitalize", marginRight: 10 }}
            onClick={() => {
              if (
                iniviteSP.fullName ||
                iniviteSP.serviceProviderType ||
                iniviteSP.email
              ) {
                setOpenConfirmationDailog(true);
              } else {
                setOpenInviteSPDialog(false);
                clearData();
              }
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!allowAction}
            className={Styles.primaryBtnAccent}
            style={{ textTransform: "capitalize", width: 141 }}
            onClick={() => {
              sendSpInvite((res) => {
                if (res && res.data.status === 200) {
                  setOpenInviteSPDialog(false);
                  clearData();
                  if (res.data.result) {
                    setSpReferralList([res.data.result]);
                  }
                } else if (res && res.data.status === 400) {
                  addValidations(res.data.result);
                }
              });
            }}
          >
            Send Invite
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default InviteSPDialog;
