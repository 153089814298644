import {
  emailValidationMeta,
  mobileNumberValidationMeta,
} from "../validationMetas";
import { useValidations } from "../useValidations";
import { useActions } from "../useActions";
import {
  SAVE_INVITE_SP_DATA,
  CLEAR_INIVITE_SP_DATA,
} from "../../souqh-redux/reducers/actionConstants";
import { useSelector, useDispatch } from "react-redux";
import { useCallback, useState, useEffect } from "react";
import { useAuthUser } from "../useLogin";
import { useApiClient } from "../useApiClient";

const validationMeta = [emailValidationMeta, mobileNumberValidationMeta];

const actionCreators = {
  saveIniviteSpData: (payload) => ({ type: SAVE_INVITE_SP_DATA, payload }),
  clearData: (payload) => ({ type: CLEAR_INIVITE_SP_DATA, payload }),
};

export const useInviteSPDialog = () => {
  const { apiClient } = useApiClient();

  const actions = useActions(actionCreators);
  const user = useAuthUser();
  const dispatch = useDispatch();
  const { email, phoneNumber } = useSelector(
    (state) => state.referralManagement.iniviteSP
  );
  const { iniviteSP } = useSelector((state) => state.referralManagement);
  const [allowAction, setAllowAction] = useState(false);

  const getFormValues = useCallback(() => {
    return { email, phoneNumber };
  }, [email, phoneNumber]);

  const getServerKeysMap = useCallback(() => {
    return {
      email: "email",
      phoneNumber: "phoneNumber",
    };
  }, []);

  const { validations, addValidations, clearAllValidations } = useValidations(
    validationMeta,
    getFormValues,
    getServerKeysMap
  );

  useEffect(() => {
    setAllowAction(
      !!iniviteSP.email &&
        !!iniviteSP.fullName &&
        !!iniviteSP.serviceProviderType &&
        !!iniviteSP.phoneNumber &&
        !Object.keys(validations).length
    );
  }, [iniviteSP, validations]);

  const sendSpInvite = (onResult) => {
    const data = {
      email: iniviteSP.email,
      fullName: iniviteSP.fullName,
      businessTypeId:
        iniviteSP.serviceProviderType && iniviteSP.serviceProviderType.id,
      senderUserId: user.userId,
      contactNumber: iniviteSP.phoneNumber,
      website: iniviteSP.website,
    };
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .post(`/referral/invite/service-provider`, data)
      .then((res) => {
        onResult && onResult(res);
      })
      .catch((error) => {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    iniviteSP,
    validations,
    actions,
    allowAction,
    addValidations,
    sendSpInvite,
  };
};
