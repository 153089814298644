import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography,
  Chip,
} from "@material-ui/core";
import Carousel from "./../../Layouts/Carousel";
import { useSelector } from "react-redux";
import { useLocationServedReview } from "souqh-react-redux-hooks/review/useLocationServedReview";

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: 20,
  },
  margins: {
    marginRight: 8,
    marginTop: 8,
  },
  textColor: {
    color: "#0e1c4e",
  },
}));
export default function LocationServedReview() {
  const classes = useStyles();

  const { locationsServed, handleNext, handleBack, displayArray, lastIndex } =
    useLocationServedReview();
  return (
    <>
      <Grid
        item
        xs={12}
        className={classes.marginTop}
        style={{ padding: "0px 22px" }}
      >
        <Typography variant="subtitle1">Locations Served</Typography>
        {displayArray.length ? (
          <Carousel
            originalArray={locationsServed}
            handleNext={handleNext}
            handleBack={handleBack}
            displayArray={displayArray}
            lastIndex={lastIndex}
          >
            {displayArray.map((location, i) => (
              <Grid key={i} item md={4}>
                <Grid item md={11}>
                  <Card variant="outlined" className={classes.textColor}>
                    <CardContent>
                      <Typography variant="subtitle1">
                        {location.province.name}
                      </Typography>
                      <Grid item>
                        {location.cities.length &&
                          location.cities.map((city) => (
                            <Chip
                              key={city.id}
                              label={city.name}
                              variant="outlined"
                              className={classes.margins}
                            />
                          ))}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            ))}
          </Carousel>
        ) : (
          <Typography>No data available</Typography>
        )}
      </Grid>
    </>
  );
}
