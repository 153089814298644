import { Avatar, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import Styles from "../../Styles.module.css";
import { CHAT_PANEL_MAX_WIDTH } from "../ServiceProvider/serviceProviderConstants";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import { isTabletDevice } from "../../utils/AppUtils";

const useStyles = makeStyles((theme) => ({
  actionBox: {
    border: "1px solid #0E1C4E1F",
    borderRadius: 4,
    height: 160,
    backgroundColor: "#ffffff",
    display: "flex",
    justifyContent: "center",
    paddingTop: 32,
    cursor: "default",
  },
}));
export default function MessageUserActionsPanel({
  souqhUserInfo,
  setOpenCreateNewGroupChat,
}) {
  const classes = useStyles();
  if (!souqhUserInfo) {
    return null;
  }
  return (
    <Grid
      item
      xs={12}
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
      }}
    >
      <Grid
        item
        xs={12}
        style={{
          height: "100%",
          width: "100%",
          maxWidth: CHAT_PANEL_MAX_WIDTH,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid item xs={10}>
          <Grid item container xs={12} style={{ marginBottom: 32 }}>
            <Grid
              item
              xs={3}
              md={2}
              lg={1}
              style={{ display: "flex", alignItems: "center" }}
            >
              {souqhUserInfo.avatarUrl ? (
                <Avatar
                  variant="square"
                  src={souqhUserInfo.avatarUrl}
                  style={{
                    width: isTabletDevice() ? 60 : 72,
                    height: isTabletDevice() ? 60 : 72,
                    borderRadius: 4,
                  }}
                />
              ) : (
                <AccountBoxIcon
                  style={{ fontSize: 72, fill: "#0e1c4e", opacity: 0.3 }}
                />
              )}
            </Grid>
            <Grid
              item
              xs={9}
              md={10}
              lg={11}
              style={{
                paddingLeft: 16,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h6"
                style={{ fontWeight: 400, lineHeight: "28px" }}
                className={clsx(Styles.sqEllipsis, Styles.navyBlueColor)}
              >
                Welcome!
              </Typography>
              <Typography
                variant="h5"
                style={{ lineHeight: "30px" }}
                className={clsx(Styles.sqEllipsis, Styles.navyBlueColor)}
              >
                {souqhUserInfo.firstName}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} style={{ marginBottom: 16 }}>
            <Grid
              item
              xs={5}
              md={7}
              className={classes.actionBox}
              title={"Coming soon"}
            >
              <Typography
                variant="subtitle1"
                style={{}}
                className={clsx(Styles.sqEllipsis, Styles.navyBlueColor)}
                onClick={() => {
                  setOpenCreateNewGroupChat(true);
                }}
              >
                Create New Group
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid
              title={"Coming soon"}
              style={{ width: "calc(83.33% + 24px" }}
              className={classes.actionBox}
            >
              <Typography
                variant="subtitle1"
                style={{}}
                className={clsx(Styles.sqEllipsis, Styles.navyBlueColor)}
              >
                Easy Meetings
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
