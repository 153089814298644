import { useApiClient } from "../useApiClient";
import { useDispatch } from "react-redux";
import { useAuthUser } from "../useLogin";
import { useState } from "react";

export const useCopyQRCode = (storeFrontId) => {
  const dispatch = useDispatch();
  const { apiClient } = useApiClient();
  const [qrCodeImageSrc, setQrCodeImageSrc] = useState("");
  const [copySuccess, setCopySuccess] = useState(false);

  const getQRCode = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/service-provider/storefront/qrcode/${storeFrontId}`)
      .then(function (response) {
        if (response.data.status === 200) {
          setQrCodeImageSrc(response.data.result);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  async function askWritePermission() {
    try {
      // The clipboard-write permission is granted automatically to pages
      // when they are the active tab. So it's not required, but it's more safe.
      const { state } = await navigator.permissions.query({
        name: "clipboard-write",
      });
      return state === "granted";
    } catch (error) {
      // Browser compatibility / Security error (ONLY HTTPS) ...
      return false;
    }
  }

  const setToClipboard = async (blob) => {
    const data = [new ClipboardItem({ [blob.type]: blob })];
    await navigator.clipboard.write(data);
  };

  const copyImageToClipBoard = async () => {
    if (qrCodeImageSrc) {
      const canWriteToClipboard = await askWritePermission();
      // Copy a PNG image to clipboard
      if (canWriteToClipboard) {
        const response = await fetch(qrCodeImageSrc);
        const blob = await response.blob();
        await setToClipboard(blob);
        setCopySuccess(true);
        setTimeout(() => {
          setCopySuccess(false);
        }, 1000);
      }
    }
  };

  return {
    getQRCode,
    qrCodeImageSrc,
    copyImageToClipBoard,
    copySuccess,
  };
};
