import React, { useRef } from "react";
import {
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { TextField, Grid } from "@material-ui/core";

const StripeInput = ({ component: Component, inputRef, ...other }) => {
  const elementRef = useRef();
  // FIXME: remove later if not needed
  // useImperativeHandle(inputRef, () => ({
  //   focus: () => elementRef.current.focus,
  // }));

  return (
    <Component
      onReady={(element) => (elementRef.current = element)}
      {...other}
    />
  );
};

function CardSection({ cardHolderName, setCardHolderName, validations }) {
  return (
    <>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <TextField
          label="Card Holder Name"
          name="cardName"
          variant="outlined"
          required
          fullWidth
          placeholder="Card Holder Name"
          InputLabelProps={{ shrink: true }}
          inputProps={{
            maxLength: 256,
          }}
          value={cardHolderName}
          onChange={(e) => {
            setCardHolderName(e.target.value);
          }}
          error={!!validations.cardHolderName}
          helperText={validations.cardHolderName}
        />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <TextField
          label="Card Number"
          name="cardNumber"
          variant="outlined"
          required
          fullWidth
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardNumberElement,
            },
          }}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item xs={6} style={{ marginBottom: 20 }}>
          <TextField
            label="Expiry Date"
            name="cardExpiration"
            variant="outlined"
            required
            fullWidth
            InputProps={{
              inputProps: {
                component: CardExpiryElement,
              },
              inputComponent: StripeInput,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            label="CVC"
            name="cvc"
            variant="outlined"
            required
            fullWidth
            InputProps={{
              inputProps: {
                component: CardCvcElement,
              },
              inputComponent: StripeInput,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
    </>
  );
}
export default CardSection;
