import { Divider, Grid } from "@material-ui/core";
import React from "react";
import { transformMsgDate } from "../../utils/CommonUtils";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import Styles from "../../Styles.module.css";
import clsx from "clsx";
import MessageAttachment from "./MessageAttachment";
import { isMobileDevice, isTabletDevice } from "../../utils/AppUtils";

export default function MessageItem({
  messageData,
  downloadAttachment,
  isGroup,
}) {
  if (!messageData) {
    return null;
  }

  let videoCallMsg = (messageData) => {
    return (
      <>
        Please join the meeting using the link below:
        <br />
        <a
          target="_blank"
          href={messageData
            .split("Please join the meeting using the link below:")
            .pop()
            .trim()}
        >
          Join Meeting
        </a>
      </>
    );
  };

  return (
    <Grid
      item
      xs={12}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: messageData.isIncoming ? "flex-start" : "flex-end",
        margin: "16px 0px",
        letterSpacing: 0,
        color: "#0e1c4e",
        marginBottom: isMobileDevice() ? 48 : isTabletDevice() && 72,
      }}
    >
      <div
        style={{
          maxWidth: "70%",
          padding: 16,
          borderRadius: 4,
          overflow: "hidden",
          backgroundColor: messageData.isIncoming ? "#FA7E6140" : "#0E1C4E0A",
        }}
      >
        {isGroup && messageData.isIncoming ? (
          <div
            style={{
              color: "rgb(12 115 220)",
              marginBottom: 12,
              fontSize: 13,
              fontWeight: 600,
            }}
          >
            {messageData.senderName}
          </div>
        ) : null}

        <MessageAttachment
          messageData={messageData}
          downloadAttachment={downloadAttachment}
        />
        <div
          style={{
            width: "100%",
            fontSize: 16,
            textAlign: "left",
            lineHeight: "18px",
            marginBottom: 4,
            overflow: "visible",
            overflowWrap: "break-word",
          }}
          className={Styles.navyBlueColor}
        >
          {messageData.Content.includes(
            "Please join the meeting using the link below:"
          )
            ? videoCallMsg(messageData.Content)
            : messageData.Content}
        </div>
        <div
          style={{
            fontSize: 12,
            lineHeight: "15px",
            color: "#868DA6",
            textAlign: "right",
            marginTop: 8,
          }}
        >
          {transformMsgDate(messageData.CreatedTimestamp)}
        </div>
      </div>
    </Grid>
  );
}
