import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 350,
  },
}));

function ArchiveDocumentDialog({
  openArchiveDocumentDialog,
  setOpenArchiveDocumentDialog,
  docDetails,
  categoryId,
}) {
  const classes = useStyles();
  return (
    <Dialog
      open={openArchiveDocumentDialog}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Archive Document</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to move {docDetails && docDetails.docName} to
          Archived?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className={Styles.navyBlueColorForce}
          style={{ textTransform: "capitalize" }}
          onClick={() => setOpenArchiveDocumentDialog(false)}
        >
          Cancel
        </Button>
        <Button
          className={Styles.primaryBtnAccent}
          style={{
            textTransform: "capitalize",
            marginRight: 16,
            padding: "6px 24px",
          }}
        >
          Archive
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ArchiveDocumentDialog;
