import { MenuList, MenuItem } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import Styles from "../../../Styles.module.css";

export default function CategoryActions(props) {
  const {
    handleListKeyDown,
    handleClose,
    setOpenAddCategoryDialog,
    setOpenRenameCategoryDialog,
    setOpenDeleteCategoryDialog,
  } = props;
  return (
    <MenuList
      id="menu-list-grow"
      onKeyDown={handleListKeyDown}
      className={Styles.navyBlueColor}
    >
      <MenuItem
        onClick={() => {
          setOpenAddCategoryDialog(true);
          handleClose();
        }}
      >
        Add Category
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenRenameCategoryDialog(true);
          handleClose();
        }}
      >
        Rename Category
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenDeleteCategoryDialog(true);
          handleClose();
        }}
      >
        Delete Category
      </MenuItem>
    </MenuList>
  );
}
