import React, { useEffect } from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import {
  Typography,
  IconButton,
  Dialog,
  Grid,
  makeStyles,
} from "@material-ui/core/";
import Styles from "../../../../Styles.module.css";
import UploadCancelDialog from "../../../HomeBuyer/UploadDocuments/UploadCancelDialog";
import { useAddTemplate } from "souqh-react-redux-hooks/serviceProvider/documents/useAddTemplate";
import AddTemplateActionBtns from "./AddTemplateActionBtns";
import AddTemplateHeader from "./AddTemplateHeader";
import AddTemplateStep1 from "./AddTemplateStep1";
import TaggedTemplate from "./TaggedTemplate";
import TemplateSummary from "./TemplateSummary";
import AddSummaryDialog from "../AddSummaryDialog";
import UploadAndUseTemplate from "./UploadAndUseTemplate";
import CreateContactDialog from "../CreateContactDialog";
import AddContactDialog from "../AddContactDialog";
import CustomEmailMessageDialog from "../CustomEmailMessageDialog";
import ConfirmGoBackDialog from "../ConfirmGoBackDialog";
import EditTemplateActionBtns from "./EditTemplateActionBtns";
import { useBusinessType } from "souqh-react-redux-hooks/useBusinessType";

const styles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    padding: 0,
  },
  dialogPaper: {
    height: window.innerHeight,
    borderRadius: 0,
  },
  diaogFooter: {
    padding: "12px 36px",
  },
  dialogRoot: {
    padding: 0,
  },
}));

const DialogTitle = (props) => {
  const { children, classes, setOpenUploadCancelDialog, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="subtitle1">{children}</Typography>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={() => {
          setOpenUploadCancelDialog(true);
        }}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
};

export default function EditTemplate({
  openEditTemplate,
  setOpenEditTemplate,
  updateIntercomStatus,
  getTabWiseCategoriesList,
  setDocumentTabName,
  documentTabName,
}) {
  const classes = styles();
  const {
    openUploadCancelDialog,
    setOpenUploadCancelDialog,
    activeSection,
    setActiveSection,
    duplicateFileName,

    templateDocumentList,
    actions: { deleteDocument },
    uploadFilesForTemplateTag,
    uploadTaggedDcouments,
    taggedDoucment,
    openTagged,
    templateSummaryList,
    openAddSummaryDialog,
    setOpenAddSummaryDialog,
    summaryTobeEdited,
    setSummaryTobeEdited,
    checkAndAddSummary,
    deleteSummary,
    searchText,
    setSearchText,
    selectedContact,
    setSelectedContact,
    filteredContacts,
    setFilteredContacts,
    filterContacts,
    openAddContactDialog,
    setAddContactDialog,
    addContactItem,
    associactedServiceProviders,
    sharedWithSP,
    setSharedWithSP,
    getAssociactedServiceProviders,
    getContacts,
    setAssociactedServiceProviders,
    contacts,
    signRoles,
    signOrders,
    signOrdersData,
    updateSignOrderChange,
    updateEmailTouchedState,
    updateEmailValue,
    isAllEmailsFilled,
    inviteAndSend,
    updatePrimaryAndSecondaryApplicant,
    openCustomEmailMessageDialog,
    setOpenCustomEmailMessageDialog,
    customEmailTitle,
    setCustomEmailTitle,
    customEmailMessage,
    setCustomEmailMessage,
    closeAndFinishAddTemplate,
    SPContacts,
    getSPContacts,
    handleAssocSPSelect,
    validateTaggedDocument,
    // businessTypes,
    setConfirmGoBackDialog,
    openConfirmGoBackDialog,
    showDialogFlag,
    updateShowDialogFlag,
    resetStep1,
    connectToSocket,
    disconnectFromSocket,
    setStopListen,
    primaryApplicantList,
    defaultEmailTitle,
    setDefaultEmailTitle,
    defaultEmailMessage,
    setDefaultEmailMessage,
  } = useAddTemplate(
    getTabWiseCategoriesList,
    setDocumentTabName,
    documentTabName
  );
  const { businessTypes } = useBusinessType();

  useEffect(() => {
    updateIntercomStatus && updateIntercomStatus(openEditTemplate);
    if (openEditTemplate) {
      getContacts();
      getSPContacts();
    }
  }, [openEditTemplate]);

  useEffect(() => {
    setFilteredContacts([...contacts]);
    filterContacts();
  }, [contacts]);

  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    closeAndFinishAddTemplate();
  };
  const handleBackClose = () => {
    resetStep1();
  };

  useEffect(() => {
    if (openEditTemplate) {
      if (taggedDoucment) {
        connectToSocket();
      } else {
        disconnectFromSocket();
      }
    }
  }, [taggedDoucment]);

  return (
    <>
      <UploadCancelDialog
        setOpenUploadCancelDialog={setOpenUploadCancelDialog}
        openUploadCancelDialog={openUploadCancelDialog}
        onClose={handleClose}
      />
      <ConfirmGoBackDialog
        setConfirmGoBackDialog={setConfirmGoBackDialog}
        openConfirmGoBackDialog={openConfirmGoBackDialog}
        showDialogFlag={showDialogFlag}
        updateShowDialogFlag={updateShowDialogFlag}
        onClose={handleBackClose}
      />
      <AddSummaryDialog
        openAddSummaryDialog={openAddSummaryDialog}
        setOpenAddSummaryDialog={setOpenAddSummaryDialog}
        checkAndAddSummary={checkAndAddSummary}
        summaryList={templateSummaryList}
        summaryTobeEdited={summaryTobeEdited}
        setSummaryTobeEdited={setSummaryTobeEdited}
      />
      <AddContactDialog
        openAddContactDialog={openAddContactDialog}
        setAddContactDialog={setAddContactDialog}
        addContactItem={addContactItem}
        businessTypes={businessTypes || []}
      />
      <CustomEmailMessageDialog
        openCustomEmailMessageDialog={openCustomEmailMessageDialog}
        setOpenCustomEmailMessageDialog={setOpenCustomEmailMessageDialog}
        customEmailTitle={customEmailTitle}
        setCustomEmailTitle={setCustomEmailTitle}
        customEmailMessage={customEmailMessage}
        setCustomEmailMessage={setCustomEmailMessage}
        defaultEmailTitle={defaultEmailTitle}
        setDefaultEmailTitle={setDefaultEmailTitle}
        defaultEmailMessage={defaultEmailMessage}
        setDefaultEmailMessage={setDefaultEmailMessage}
      />
      <div>
        <Dialog
          fullScreen
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openEditTemplate}
          maxWidth="lg"
          classes={{ paper: classes.dialogPaper, root: classes.dialogRoot }}
        >
          <DialogTitle
            classes={classes}
            id="customized-dialog-title"
            className={Styles.navyBlueColor}
            setOpenUploadCancelDialog={setOpenUploadCancelDialog}
          >
            Edit Template
          </DialogTitle>
          <MuiDialogContent dividers className={classes.dialogContent}>
            <Grid container style={{ height: "100%" }}>
              <AddTemplateHeader activeSection={activeSection} />
              <Grid item xs={12} style={{ height: "calc(100% - 60px)" }}>
                {activeSection === 2 && (
                  <TaggedTemplate
                    taggedDoucment={taggedDoucment}
                    openTagged={openTagged}
                    setStopListen={setStopListen}
                  />
                )}

                {activeSection === 3 && (
                  <TemplateSummary
                    templateSummaryList={templateSummaryList}
                    openAddSummaryDialog={openAddSummaryDialog}
                    setOpenAddSummaryDialog={setOpenAddSummaryDialog}
                    summaryTobeEdited={summaryTobeEdited}
                    setSummaryTobeEdited={setSummaryTobeEdited}
                    deleteSummary={deleteSummary}
                  />
                )}
                {activeSection === 4 && (
                  <UploadAndUseTemplate
                    searchText={searchText}
                    setSearchText={setSearchText}
                    selectedContact={selectedContact}
                    setSelectedContact={setSelectedContact}
                    filteredContacts={filteredContacts}
                    setFilteredContacts={setFilteredContacts}
                    filterContacts={filterContacts}
                    associactedServiceProviders={associactedServiceProviders}
                    setAssociactedServiceProviders={
                      setAssociactedServiceProviders
                    }
                    updatePrimaryAndSecondaryApplicant={
                      updatePrimaryAndSecondaryApplicant
                    }
                    sharedWithSP={sharedWithSP}
                    setSharedWithSP={setSharedWithSP}
                    openAddContactDialog={openAddContactDialog}
                    setAddContactDialog={setAddContactDialog}
                    getAssociactedServiceProviders={
                      getAssociactedServiceProviders
                    }
                    getContacts={getContacts}
                    signOrders={signOrders}
                    signOrdersData={signOrdersData}
                    updateSignOrderChange={updateSignOrderChange}
                    updateEmailTouchedState={updateEmailTouchedState}
                    updateEmailValue={updateEmailValue}
                    contacts={contacts}
                    SPContacts={SPContacts}
                    handleAssocSPSelect={handleAssocSPSelect}
                    primaryApplicantList={primaryApplicantList}
                  />
                )}
              </Grid>
            </Grid>
          </MuiDialogContent>
          <MuiDialogActions className={classes.diaogFooter}>
            <EditTemplateActionBtns
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              setOpenUploadCancelDialog={setOpenUploadCancelDialog}
              duplicateFileName={duplicateFileName}
              uploadFilesForTemplateTag={uploadFilesForTemplateTag}
              uploadTaggedDcouments={uploadTaggedDcouments}
              setOpenEditTemplate={setOpenEditTemplate}
              isAllEmailsFilled={isAllEmailsFilled}
              inviteAndSend={inviteAndSend}
              setOpenCustomEmailMessageDialog={setOpenCustomEmailMessageDialog}
              validateTaggedDocument={validateTaggedDocument}
              templateDocumentList={templateDocumentList}
              showDialogFlag={showDialogFlag}
              setConfirmGoBackDialog={setConfirmGoBackDialog}
              resetStep1={resetStep1}
              setSelectedContact={setSelectedContact}
            />
          </MuiDialogActions>
        </Dialog>
      </div>
    </>
  );
}
