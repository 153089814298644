import { cloneDeep, forEach } from "lodash";
import { SAVE_USER } from "./actionConstants";

const initialState = {
  userDetails: null,
};

const auth = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SAVE_USER:
      newState = cloneDeep(state);
      if (action.payload) {
        newState.userDetails = { ...newState.userDetails, ...action.payload };
      } else {
        newState.userDetails = null;
      }
      return newState;
    default:
      return state;
  }
};

export default auth;
