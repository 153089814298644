import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SET_CAMPAIGN_ANALYTICS_DATA } from "souqh-redux/reducers/actionConstants";
import { useApiClient } from "../../useApiClient";
import { useAuthUser } from "../../useLogin";

export const useCampaignAnalytics = () => {
  const dispatch = useDispatch();
  const { apiClient } = useApiClient();
  const user = useAuthUser();

  const { analytics } = useSelector((state) => state.marketingCampaign);

  const getCampaignAnalytics = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(
        `/marketing-campaign/analytics?spId=${
          user.serviceProviderId || user.parentServiceProviderId
        }`
      )
      .then((res) => {
        if (res.data.status === 200 && res.data.result)
          dispatch({
            type: SET_CAMPAIGN_ANALYTICS_DATA,
            payload: res.data.result,
          });
      })
      .catch((error) => {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return { analytics, getCampaignAnalytics };
};
