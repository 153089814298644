import { SAVE_TEAM_ONBOARDING_DATA, SAVE_ERROR_MSG } from "./actionConstants";
import { cloneDeep } from "lodash";

const initialState = {
  step1Info: null,
  error: {
    code: null,
    msg: "",
  },
};

const teamOnboarding = (state = initialState, action) => {
  let newState;

  switch (action.type) {
    case SAVE_TEAM_ONBOARDING_DATA:
      newState = cloneDeep(state);
      newState.step1Info = action.payload;
      return newState;
    case SAVE_ERROR_MSG:
      newState = cloneDeep(state);
      newState.error = action.payload;
      return newState;
    default:
      return state;
  }
};

export default teamOnboarding;
