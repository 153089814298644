import { useDispatch } from "react-redux";
import { useApiClient } from "../useApiClient";
import { useAuthUser } from "../useLogin";

export const useSpSetupReview = () => {
  const { apiClient } = useApiClient();
  const userInfo = useAuthUser();

  const dispatch = useDispatch();

  const requestToAdmin = (onSuccess, onFailure) => {
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .put(`/service-provider/profile/complete/${userInfo.serviceProviderId}`)
      .then((res) => {
        if (res.data.status === 200) {
          onSuccess && onSuccess(res);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    requestToAdmin,
  };
};
