import React from "react";
import { Grid, useTheme, useMediaQuery } from "@material-ui/core";
import { VIEW_MAX_WIDTH } from "../ServiceProvider/serviceProviderConstants";
import { isMobileDevice } from "../../utils/AppUtils";

const ViewWrapper = ({
  children,
  showWhiteBackground,
  showWhiteBackgroundForTabletAndMobile,
  exploreStorefronts,
  spDetails,
}) => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        maxWidth: VIEW_MAX_WIDTH,
        paddingLeft: mdUp ? (exploreStorefronts ? 80 : 120) : !spDetails && 20,
        paddingRight: mdUp ? 80 : !spDetails && 20,
        paddingTop: 32,
        backgroundColor:
          (showWhiteBackgroundForTabletAndMobile ||
            (showWhiteBackground && isMobileDevice())) &&
          "#FFFFFF",
      }}
    >
      {children}
    </Grid>
  );
};
export default ViewWrapper;
