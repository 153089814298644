import { useDispatch } from "react-redux";
import { useApiClient } from "../useApiClient";
import { useAuthUser } from "../useLogin";

export const useVoidDocument = (
  docDetails,
  getTabWiseCategoriesList,
  documentTabName
) => {
  const { apiClient } = useApiClient();
  const userInfo = useAuthUser();
  const dispatch = useDispatch();

  const voidEsignedDocument = (onResult) => {
    if (docDetails && docDetails.signNowDocumentId) {
      dispatch({ type: "SET_LOADING", value: true });
      const url = `/signnow/void/document/${docDetails.signNowDocumentId}/${userInfo.userId}`;

      return apiClient
        .put(url, {})
        .then((res) => {
          if (res.data.status === 200) {
            getTabWiseCategoriesList &&
              getTabWiseCategoriesList(documentTabName);
          }
          onResult && onResult(res);
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  return {
    voidEsignedDocument,
  };
};
