import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import CalendarLeftPanel from "./CalendarLeftPanel";
import CalendarRightPanel from "./CalendarRightPanel";
import { useSQCalendar } from "souqh-react-redux-hooks/common/useSQCalendar";
import CreateMeetingDialog from "./CreateMeetingDialog";
import { MEETING_CREATE_MODE } from "../../ServiceProvider/serviceProviderConstants";
import InstantMeetingDialog from "./InstantMeetingDialog";
import { isMobileDevice } from "../../../utils/AppUtils";
import moment from "moment";
import { useCalendarSync } from "souqh-react-redux-hooks/calendarSync/useCalendarSync";

export default function SQCalender() {
  const {
    actions: {
      setOpenCreateMeetingDialog,
      setMeetingMode,
      setOpenConfirmCanelMeetingDialog,
    },
    currentCalendarDate,
    setCurrentCalendarDate,
    calendarEvents,
    mainCalendarView,
    setMainCalendarView,
    openCreateMeetingDialog,
    userInfo,
    createInstantMeeting,
    meetingMode,
    selectedCalendarEvent,
    openConfirmCanelMeetingDialog,
    cancelMeeting,
    title,
    setTitle,
    meetingDate,
    setMeetingDate,
    meetingLink,
    setMeetingLink,
    meetingPhoneNumber,
    meetingLocation,
    setMeetingLocation,
    meetingAttachments,
    description,
    setDescription,
    uploadRef,
    textAreaRef,
    contactInputRef,
    handleDialogClose,
    processAttachment,
    removeAttachment,
    copyToClipboard,
    meetingStartTime,
    setMeetingStartTime,
    meetingEndTime,
    setMeetingEndTime,
    addCustomItem,
    selectedContacts,
    customContact,
    setCustomContact,
    allContacts,
    removeSelectedContact,
    addItemOnSelect,
    getAllContacts,
    invalidTimes,
    setInvalidTimes,
    setMeetingData,
    removeMeetingLink,
    addMeetingLink,
    setAddMeetingLink,
    setupEmptyData,
    saveMeeting,
    validateTimeSlot,
    isFullDayEvent,
    setIsFullDayEvent,
    // getCalendarEvents,
    getCalendarEventDetails,
    eventUserId,
    hasFilledMandatoryData,
    updateMeetingStatus,
    downlodAttachment,
    filteredContacts,
    downloadCalendarAttachment,
    openInstantMeetingDailog,
    setOpenInstantMeetingDailog,
    moderatorTextAreaRef,
    moderatorMeetingLink,
    setModeratorMeetingLink,
    getAllCalendarEvents,
    setStartDateTimestamp,
    setEndDateTimestamp,
    startDateTimestamp,
    endDateTimestamp,
    findEventInList,
  } = useSQCalendar({
    calendarListUpdater: true,
    upcomingEventsUpdater: null,
  });

  const {
    connectToSocket,
    accountDetails,
    getAccountDetails,
    disconnectFromSocket,
    eventType,
  } = useCalendarSync();

  useEffect(() => {
    // getCalendarEvents(true);
    // getAllCalendarEvents(true);
    getAllContacts();
    getAccountDetails();
    return () => {
      setOpenCreateMeetingDialog(false);
      disconnectFromSocket();
    };
  }, []);

  useEffect(() => {
    connectToSocket();
  }, [accountDetails]);

  useEffect(() => {
    setStartAndEndDates();
  }, [currentCalendarDate, mainCalendarView]);

  useEffect(() => {
    getAllCalendarEvents(true);
  }, [startDateTimestamp, endDateTimestamp, eventType]);

  useEffect(() => {
    if (mainCalendarView === "month") {
      getAllCalendarEvents(true);
    }
  }, [mainCalendarView]);

  useEffect(() => {
    if (meetingMode === MEETING_CREATE_MODE) {
      setupEmptyData();
    }
  }, [meetingMode]);

  const setStartAndEndDates = () => {
    // let currentDate = moment();
    let weekStart = moment(currentCalendarDate).clone().startOf("week");
    let weekEnd = moment(currentCalendarDate).clone().endOf("week");

    let monthStart = moment(currentCalendarDate).clone().startOf("month");
    let monthEnd = moment(currentCalendarDate).clone().endOf("month");
    let startDate = mainCalendarView === "month" ? monthStart : weekStart;
    const endDate = mainCalendarView === "month" ? monthEnd : weekEnd;
    const startDateTS = moment(startDate).unix();
    const endDateTS = moment(endDate).unix();
    setStartDateTimestamp(startDateTS);
    setEndDateTimestamp(endDateTS);
    setTimeout(3000);
  };

  if (!userInfo) {
    return null;
  }
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        height: window.innerHeight,
        marginLeft: !isMobileDevice() && 80,
      }}
    >
      <CreateMeetingDialog
        openCreateMeetingDialog={openCreateMeetingDialog}
        setOpenCreateMeetingDialog={setOpenCreateMeetingDialog}
        meetingMode={meetingMode}
        setMeetingMode={setMeetingMode}
        openConfirmCanelMeetingDialog={openConfirmCanelMeetingDialog}
        setOpenConfirmCanelMeetingDialog={setOpenConfirmCanelMeetingDialog}
        cancelMeeting={cancelMeeting}
        selectedCalendarEvent={selectedCalendarEvent}
        title={title}
        setTitle={setTitle}
        meetingDate={meetingDate}
        setMeetingDate={setMeetingDate}
        meetingLink={meetingLink}
        meetingPhoneNumber={meetingPhoneNumber}
        meetingLocation={meetingLocation}
        setMeetingLocation={setMeetingLocation}
        meetingAttachments={meetingAttachments}
        description={description}
        setDescription={setDescription}
        uploadRef={uploadRef}
        textAreaRef={textAreaRef}
        contactInputRef={contactInputRef}
        meetingStartTime={meetingStartTime}
        setMeetingStartTime={setMeetingStartTime}
        meetingEndTime={meetingEndTime}
        setMeetingEndTime={setMeetingEndTime}
        handleDialogClose={handleDialogClose}
        processAttachment={processAttachment}
        removeAttachment={removeAttachment}
        copyToClipboard={copyToClipboard}
        addCustomItem={addCustomItem}
        selectedContacts={selectedContacts}
        customContact={customContact}
        setCustomContact={setCustomContact}
        allContacts={allContacts}
        removeSelectedContact={removeSelectedContact}
        addItemOnSelect={addItemOnSelect}
        invalidTimes={invalidTimes}
        setInvalidTimes={setInvalidTimes}
        setMeetingData={setMeetingData}
        removeMeetingLink={removeMeetingLink}
        addMeetingLink={addMeetingLink}
        setAddMeetingLink={setAddMeetingLink}
        setupEmptyData={setupEmptyData}
        saveMeeting={saveMeeting}
        validateTimeSlot={validateTimeSlot}
        isFullDayEvent={isFullDayEvent}
        setIsFullDayEvent={setIsFullDayEvent}
        eventUserId={eventUserId}
        userInfo={userInfo}
        hasFilledMandatoryData={hasFilledMandatoryData}
        updateMeetingStatus={updateMeetingStatus}
        downlodAttachment={downlodAttachment}
        filteredContacts={filteredContacts}
        downloadCalendarAttachment={downloadCalendarAttachment}
      />
      <InstantMeetingDialog
        openInstantMeetingDailog={openInstantMeetingDailog}
        setOpenInstantMeetingDailog={setOpenInstantMeetingDailog}
        meetingLink={meetingLink}
        copyToClipboard={copyToClipboard}
        textAreaRef={textAreaRef}
        moderatorTextAreaRef={moderatorTextAreaRef}
        moderatorMeetingLink={moderatorMeetingLink}
        setModeratorMeetingLink={setModeratorMeetingLink}
      />
      {isMobileDevice() ? (
        <Grid
          item
          container
          xs={12}
          style={{
            height: "100%",
            // overflow: "hidden",
          }}
        >
          <Grid
            item
            container
            xs={12}
            style={{
              width: 300,
              // height: "100%",
              backgroundColor: "#ffffff",
              overflow: "hidden",
              borderBottom: "1px solid #17174C1F",
            }}
          >
            <CalendarLeftPanel
              currentCalendarDate={currentCalendarDate}
              setCurrentCalendarDate={setCurrentCalendarDate}
              calendarEvents={calendarEvents}
              setOpenCreateMeetingDialog={setOpenCreateMeetingDialog}
              createInstantMeeting={createInstantMeeting}
              setMeetingMode={setMeetingMode}
              openInstantMeetingDailog={openInstantMeetingDailog}
              setOpenInstantMeetingDailog={setOpenInstantMeetingDailog}
              setMeetingLink={setMeetingLink}
              moderatorMeetingLink={moderatorMeetingLink}
              setModeratorMeetingLink={setModeratorMeetingLink}
              userInfo={userInfo}
              accountDetails={accountDetails}
              setMainCalendarView={setMainCalendarView}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            style={{
              width: "calc(100% - 300px)",
              height: "100%",
              backgroundColor: "white",
            }}
          >
            <CalendarRightPanel
              currentCalendarDate={currentCalendarDate}
              setCurrentCalendarDate={setCurrentCalendarDate}
              calendarEvents={calendarEvents}
              mainCalendarView={mainCalendarView}
              setMainCalendarView={setMainCalendarView}
              setOpenCreateMeetingDialog={setOpenCreateMeetingDialog}
              setMeetingMode={setMeetingMode}
              setMeetingData={setMeetingData}
              getCalendarEventDetails={getCalendarEventDetails}
              findEventInList={findEventInList}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid item container xs={12} style={{ height: "100%" }}>
          <div
            style={{
              width: 300,
              height: "100%",
              backgroundColor: "#ffffff",
              overflow: "hidden",
            }}
          >
            <CalendarLeftPanel
              currentCalendarDate={currentCalendarDate}
              setCurrentCalendarDate={setCurrentCalendarDate}
              calendarEvents={calendarEvents}
              setOpenCreateMeetingDialog={setOpenCreateMeetingDialog}
              createInstantMeeting={createInstantMeeting}
              setMeetingMode={setMeetingMode}
              openInstantMeetingDailog={openInstantMeetingDailog}
              setOpenInstantMeetingDailog={setOpenInstantMeetingDailog}
              setMeetingLink={setMeetingLink}
              moderatorMeetingLink={moderatorMeetingLink}
              setModeratorMeetingLink={setModeratorMeetingLink}
              userInfo={userInfo}
              accountDetails={accountDetails}
              setMainCalendarView={setMainCalendarView}
            />
          </div>
          <div
            style={{
              width: "calc(100% - 300px)",
              height: "100%",
              backgroundColor: "transparent",
            }}
          >
            <CalendarRightPanel
              currentCalendarDate={currentCalendarDate}
              setCurrentCalendarDate={setCurrentCalendarDate}
              calendarEvents={calendarEvents}
              mainCalendarView={mainCalendarView}
              setMainCalendarView={setMainCalendarView}
              setOpenCreateMeetingDialog={setOpenCreateMeetingDialog}
              setMeetingMode={setMeetingMode}
              setMeetingData={setMeetingData}
              getCalendarEventDetails={getCalendarEventDetails}
              findEventInList={findEventInList}
            />
          </div>
        </Grid>
      )}
    </Grid>
  );
}
