import React from "react";
import AttachmentProcessor from "./AttachmentProcessor";

export default function MessageAttachment({ messageData, downloadAttachment }) {
  if (!messageData) {
    return null;
  }

  let docItem = null;
  if (messageData.Metadata) {
    const metaData = JSON.parse(messageData.Metadata);
    if (metaData && metaData.attachments) {
      const fileEl = metaData.attachments[0];
      if (fileEl) {
        docItem = (
          <AttachmentProcessor
            fileName={fileEl.name}
            fileKey={fileEl.fileKey}
            senderId={messageData.Sender.Arn}
            downloadAttachment={downloadAttachment}
          />
        );
      }
    }
  }

  return docItem;
}
