import { useState, useCallback, useEffect } from "react";
import { useValidations } from "./useValidations";
import { emailValidationMeta } from "./validationMetas";
import { useDispatch, useSelector } from "react-redux";
import { useActions } from "./useActions";
import useLocalStorage from "react-use-localstorage";
import { useApiClient } from "./useApiClient";
import { useOpenApiClient } from "./useOpenApiClient";
import { INITIAL_STATE_STORE } from "../souqh-redux/reducers/actionConstants";
import {
  ADVANCED,
  PLUS,
  PREMIUM,
  PRO,
  TEAM_MEMBER_USER,
} from "../web/src/components/ServiceProvider/serviceProviderConstants";
import * as moment from "moment";
import { color_success } from "./toasterColors";
const validationMeta = [emailValidationMeta];

const loginActionCreators = {
  saveUser: (payload) => ({ type: SAVE_USER, payload }),
};

// FIXME rename this module to auth.js
export const useLogin = (loginFor) => {
  const { openApiClient } = useOpenApiClient();
  const [userInfo, setUserInfo] = useLocalStorage("userInfo");

  const dispatch = useDispatch();
  const actions = useActions(loginActionCreators);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [enable2Factor, setEnable2Factor] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [currentDate, setCurrentDate] = useState(new Date());

  const getFormValues = useCallback(() => {
    return { email, password };
  }, [email, password]);
  const getServerKeysMap = useCallback(() => {
    return {
      email: "email",
    };
  }, []);

  const { validations, addValidations, clearAllValidations } = useValidations(
    validationMeta,
    getFormValues,
    getServerKeysMap
  );

  useEffect(() => {
    let validForm = !!email && !!password && !Object.keys(validations).length;
    setAllowSubmit(validForm);
    setErrorMsg("");
  }, [email, password, validations]);

  const login = (onSuccess, onFailure) => {
    let url = "";
    if (loginFor === "serviceProvider") {
      url = "/user/service-provider/signin";
    } else if (loginFor === "homeBuyer") {
      url = "/user/home-buyer/signin";
    }

    dispatch({ type: "SET_LOADING", value: true });
    openApiClient
      .post(url, {
        email: email.toLowerCase(),
        password: password,
      })
      .then((res) => {
        if (res.data.status === 200) {
          res.data.result.access_token = res.headers.access_token;
          res.data.result.refresh_token = res.headers.refresh_token;
          let userData = res.data.result;
          if (res.data.result.userType === TEAM_MEMBER_USER) {
            if (res.data.result.subscriptionPlanName === PREMIUM || res.data.result.subscriptionPlanName === ADVANCED) {
              userData = {
                ...userData,
                ...res.data.result.premiumMemberDetails,
              };
              delete userData.premiumMemberDetails;
            }
            if (
              res.data.result.subscriptionPlanName === PLUS ||
              res.data.result.subscriptionPlanName === PRO
            ) {
              userData = {
                ...userData,
                ...res.data.result.nonPremiumTeamMemberDetails,
              };
              delete userData.nonPremiumTeamMemberDetails;
            }
          }
          dispatch({ type: "SAVE_USER", payload: userData });
          setUserInfo(JSON.stringify(userData));
          setEnable2Factor(userData.enable2Factor);
          dispatch({
            type: "SET_TOASTER",
            payload: {
              message: `You have logged in to Souqh at ${moment(
                currentDate
              ).format("MMM DD, YYYY hh:mm")}`,
              bgColor: color_success,
              open: true,
            },
          });
        }
        onSuccess && onSuccess(res);
      })
      .catch((error) => {
        console.error(error);
        onFailure && onFailure(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    setEmail,
    setPassword,
    validations,
    addValidations,
    clearAllValidations,
    password,
    allowSubmit,
    login,
    enable2Factor,
    email,
    errorMsg,
    setErrorMsg,
  };
};

export const useLogout = () => {
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useLocalStorage("userInfo");
  const { apiClient } = useApiClient();

  const logout = (onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .post(`/user/auth/takemehome`, {})
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 401) {
          // FXIME: confirm from backend team for 401 status error.
          dispatch({
            type: "SET_TOASTER",
            payload: {
              message: `You have logged out from Souqh at ${moment(
                new Date()
              ).format("MMM DD, YYYY hh:mm")}`,
              bgColor: color_success,
              open: true,
            },
          });
          dispatch({ type: "SAVE_USER", payload: null });
          setUserInfo("");
          localStorage.removeItem("nonloggedInUser");
          dispatch({ type: INITIAL_STATE_STORE });
          localStorage.removeItem("instantMeetingDetails");
          onResult(res);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return { logout };
};

export const useAuthUser = () => {
  return useSelector((state) => state.auth.userDetails);
};
