import { useDispatch, useSelector } from "react-redux";
import { useApiClient } from "../useApiClient";
import { useAuthUser } from "../useLogin";

export const useGetSubscriptionPlanList = () => {
  const userInfo = useAuthUser();
  const dispatch = useDispatch();
  const { apiClient } = useApiClient();

  const plans = useSelector((state) => state.metaData.plans);

  const getSubscriptionPlanList = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/service-provider/subscription/plans`)
      .then(function (response) {
        if (
          response &&
          response.data &&
          response.data.result &&
          !response.data.result.length
        ) {
          // we need response.data.result with values in every condition
          throw Error("Server Error : Plans not found");
        } else {
          dispatch({
            type: "SET_SUBSCRIPTION_PLANS",
            plans: response.data.result,
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    getSubscriptionPlanList,
    plans,
    userInfo,
  };
};
