import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useActions } from "../../useActions";
import {
  SET_CLIENTS_TAB,
  SET_CLIENT_MGMT_DATA,
} from "../../../souqh-redux/reducers/actionConstants";
import { useAuthUser } from "../../useLogin";
import { useApiClient } from "../../useApiClient";
import { useLeadsClientInfoCard } from "./useLeadsClientInfoCard";
import { useActiveClientInfo } from "./useActiveClientInfo";
import { useInactiveClientInfo } from "./useInactiveClientInfo";
import { useCompleteClientInfo } from "./useCompleteClientInfo";
import { PAGE_SIZE } from "../../../web/src/components/HomeBuyer/SPManagement/spManagementConstants";

const actionCreator = {
  setClientsTab: (payload) => ({ type: SET_CLIENTS_TAB, payload }),
};

export const useClientManagement = () => {
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [displayClientsData, setDisplayClientsData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [openInviteClientsDialog, setOpenInviteClientsDialog] = useState(false);
  const dispatch = useDispatch();
  const { apiClient } = useApiClient();
  const userInfo = useAuthUser();
  const { active, inActive, completed, leads, clientsTab } = useSelector(
    (state) => state.clientManagement
  );
  const clientMgmtData = useSelector((state) => state.clientManagement);
  const actions = useActions(actionCreator);
  const { getLeadsClientData } = useLeadsClientInfoCard(PAGE_SIZE);
  const { getActiveClientData } = useActiveClientInfo(); // We dont have pagination for active tab becoz of add non souqh users
  const { getInactiveClientData } = useInactiveClientInfo(PAGE_SIZE);
  const { getCompletedClientData } = useCompleteClientInfo(PAGE_SIZE);
  const { storeFrontRouteName, storeFrontId } = useSelector(
    (state) => state.storefront
  );

  useEffect(() => {
    setSearchText(""); // empty search text on tab change
    switch (clientsTab) {
      case 0:
        setDisplayClientsData(active);
        setOriginalData(active);
        return;
      case 1:
        setDisplayClientsData(inActive);
        setOriginalData(inActive);
        return;
      case 2:
        setDisplayClientsData(completed);
        setOriginalData(completed);
        return;
      case 3:
        setDisplayClientsData(leads);
        setOriginalData(leads);
        return;
      default:
        return;
    }
  }, [active, clientsTab]);

  const requestClientSearch = (searchedVal) => {
    setSearchText(searchedVal);
    let filteredRows = { response: [], count: 0 };
    originalData &&
      originalData.response &&
      originalData.response.length &&
      originalData.response.map((row) => {
        if (
          row.name.toLowerCase().includes(searchedVal.toLowerCase()) ||
          row.email.toLowerCase().includes(searchedVal.toLowerCase()) ||
          (row.contactNumber &&
            row.contactNumber.toLowerCase().includes(searchedVal.toLowerCase()))
        ) {
          filteredRows.response.push(row);
        }
      });
    filteredRows.count = filteredRows.response.length;
    setDisplayClientsData(filteredRows);
  };

  const getClientManagementData = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(
        `/service-provider/client/service-request/all/${userInfo.userId}?page=0&size=${PAGE_SIZE}`
      )
      .then(async (response) => {
        if (response.status === 200) {
          const data = processResponse(response.data);
          dispatch({
            type: SET_CLIENT_MGMT_DATA,
            payload: data,
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const processResponse = (data) => {
    Object.keys(data).map((key) => {
      data[key].response = data[key].response.length
        ? data[key].response.map((res) => {
            return { ...res.hbDetails, ...res };
          })
        : [];
    });
    return data;
  };

  const handlePageChange = (event, page) => {
    setSearchText("");
    switch (clientsTab) {
      case 0:
        getActiveClientData();
        break;
      case 1:
        getInactiveClientData(page - 1);
      case 2:
        getCompletedClientData(page - 1);
      case 3:
        getLeadsClientData(page - 1);
        break;
    }
  };

  const getDataOnTabChange = () => {
    if (clientsTab !== null) {
      switch (clientsTab) {
        case 0:
          getActiveClientData(0);
          return;
        case 1:
          getInactiveClientData(0);
          return;
        case 2:
          getCompletedClientData(0);
          return;
        case 3:
          getLeadsClientData(0);
          return;
        default:
          return;
      }
    }
  };

  return {
    openInviteClientsDialog,
    setOpenInviteClientsDialog,
    displayClientsData,
    searchText,
    setSearchText,
    requestClientSearch,
    clientsTab,
    actions,
    clientMgmtData,
    active,
    inActive,
    completed,
    leads,
    getClientManagementData,
    handlePageChange,
    getDataOnTabChange,
    getActiveClientData,
    storeFrontRouteName,
    storeFrontId,
  };
};
