import {
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import Styles from "../../../Styles.module.css";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { useExplore } from "souqh-react-redux-hooks/homeBuyer/useExplore";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useBroadcastServiceRequest } from "souqh-react-redux-hooks/homeBuyer/useBroadcastServiceRequest";
import CancelIcon from "@material-ui/icons/Cancel";
import { useHistory, useParams } from "react-router-dom";
import { isMobileDevice } from "../../../utils/AppUtils";
import BroadcastConfirmationDialog from "./BroadcastConfirmationDialog";

const useStyles = makeStyles((theme) => ({
  inputs: {
    marginTop: 0,
    marginBottom: 0,
  },

  textInput: {
    "& .MuiInput-underline:before": {
      // borderBottom: "none",
    },
  },
  marginTop20: {
    marginTop: 20,
  },
}));

export default function BroadcastServiceRequestDialog({
  openBroadcastServiceRequestDialog,
  setOpenBroadcastServiceRequestDialog,
  userInfo,
  defaultSearchedCity,
  defaultSearchedService,
  defaultServiceForBroadcast,
  // services,
  // cities,
  // storefrontPathName,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [searchedCategory, setSearchedCategory] = useState(null);
  const [searchedCity, setSearchedCity] = useState(null);
  const [timelineOption, setTimelineOption] = useState(null);
  const [description, setDescription] = useState("");

  const [openBroadcastConfirmationDialog, setOpenBroadcastConfirmationDialog] =
    useState(false);

  let { appSearchedService, appSearchedCityId } = useParams();

  const { cities, getCitiesList, services, getServicesList } = useExplore();

  const {
    createBroadcastService,
    actions: { addFiles, deleteFile },
    broadcastServiceRequestDocuments,
    getMatchingServiceProviderDetails,
    matchingSPCount,
    matchingSPEmails,
    setMatchingSPEmails
  } = useBroadcastServiceRequest();

  useEffect(() => {
    getServicesList();
    getCitiesList();
  }, []);
  // if (defaultSearchedCity) {
  //   console.log("yooooo");
  //   setSearchedCity(defaultSearchedCity);
  // }

  // if (defaultSearchedService && services.length) {
  //   const filteredService = services.filter(
  //     (service) => service.name === defaultSearchedService
  //   );
  //   filteredService &&
  //     filteredService.length &&
  //     setSearchedCategory(filteredService[0]);
  // } else {
  //   setSearchedCategory(defaultServiceForBroadcast[0]);
  // }

  useEffect(() => {
    if (cities && cities.length && appSearchedCityId) {
      const city = cities.filter(
        (city) => city.cityDTO?.[0]?.shortName === appSearchedCityId
      )[0];
      setSearchedCity(city);
    }

    if (userInfo && !appSearchedCityId) {
      if (userInfo.hbCurrentAddressCity) {
        setSearchedCity(userInfo.hbCurrentAddressCity);
      } else {
        setSearchedCity({
          id: 9,
          name: "Ontario",
          provinceCode: "ON",
          functional: true,
          selected: true,
          localeCode: "en",
          cityDTO: [
            {
              id: 1,
              name: "Toronto",
              shortName: "toronto",
            },
          ],
        });
      }
    }

    if (appSearchedService) {
      if (services.length) {
        const filteredService = services.filter(
          (service) => service.storefrontPathName === appSearchedService
        );
        if (filteredService) {
          setSearchedCategory(filteredService[0]);
        }
      }
    } else {
      if (services.length) {
        const defaultServiceForBroadcast = services.filter(
          (service) => service.name === "Real Estate Broker / Agent"
        );
        setSearchedCategory(defaultServiceForBroadcast[0]);
      }
    }
  }, [services.length, cities.length, userInfo]);

  const timelineOptions = [
    { name: "Urgent", value: "URGENT" },
    { name: "As Soon As Possible", value: "AS_SOON_AS_POSSIBLE" },
    { name: "1-2 weeks", value: "ONE_TO_TWO_WEEKS" },
    { name: "2-4 weeks", value: "TWO_TO_FOUR_WEEKS" },
    { name: "4-8 weeks", value: "FOUR_TO_EIGHT_WEEKS" },
    { name: "8 weeks plus", value: "EIGHT_WEEKS_PLUS" },
  ];

  const clearData = () => {
    // setSearchedCategory(null);
    // setSearchedCity(null);
    setTimelineOption(null);
    setDescription("");
    addFiles({});
  };

  let filteredCities = cities.filter(
    (item) => item.cityDTO[0].name !== "All Cities"
  );
  let filteredCategories = services.filter((item) => item.name !== "Other");

  return (
    <>
      {openBroadcastConfirmationDialog && (
        <BroadcastConfirmationDialog
          openBroadcastConfirmationDialog={openBroadcastConfirmationDialog}
          setOpenBroadcastConfirmationDialog={
            setOpenBroadcastConfirmationDialog
          }
          createBroadcastService={createBroadcastService}
          searchedCategory={searchedCategory}
          searchedCity={searchedCity}
          timelineOption={timelineOption}
          description={description}
          clearData={clearData}
          getMatchingServiceProviderDetails={getMatchingServiceProviderDetails}
          matchingSPCount={matchingSPCount}
          setOpenBroadcastServiceRequestDialog={
            setOpenBroadcastServiceRequestDialog
          }
          matchingSPEmails={matchingSPEmails}
          setMatchingSPEmails={setMatchingSPEmails}
          broadcastServiceRequestDocuments={broadcastServiceRequestDocuments}
        />
      )}
      <Dialog
        fullWidth
        open={openBroadcastServiceRequestDialog}
        maxWidth={"sm"}
      >
        <DialogTitle>
          <Grid item container xs={12}>
            <Grid item xs={11}>
              <Typography
                variant="subtitle1"
                className={Styles.navyBlueColorForce}
              >
                Send Service Request
              </Typography>
            </Grid>
            <Grid item xs={1} container style={{ justifyContent: "flex-end" }}>
              <Button
                style={{ padding: 0, minWidth: 0 }}
                onClick={() => {
                  //     setUserEngagementData &&
                  //       setUserEngagementData(
                  //         storeFrontId,
                  //         "Service_Request_Cancelled"
                  //       );
                  //     setOpenserviceRequestDialog({ open: false, service: null });
                  //     if (nonloggedInUser) {
                  //       localStorage.removeItem("nonloggedInUser");
                  //       history.push("/app/homebuyer/explore");
                  //     }
                  //     setServiceSelected(null);
                  //     clearData();
                  setOpenBroadcastServiceRequestDialog(false);
                  clearData();
                }}
              >
                <CloseIcon className={Styles.navyBlueColorForce} />
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent
          className={Styles.navyBlueColorForce}
          // style={{ minHeight: userInfo ? 350 : 70 }}
        >
          {userInfo ? (
            <>
              <Autocomplete
                options={filteredCategories || []}
                value={searchedCategory}
                getOptionLabel={(option) => option.name || ""}
                id="category"
                debug
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    className={clsx(classes.inputs, classes.textInput)}
                    placeholder="What service are you looking for?"
                    variant="outlined"
                  />
                )}
                onChange={(event, value) => {
                  setSearchedCategory(value);
                }}
                getOptionSelected={(option, value) => {
                  return option?.name === value?.name;
                }}
                style={{ width: !isMobileDevice() && "410px" }}
              />

              <Autocomplete
                options={filteredCities || []}
                value={searchedCity}
                // getOptionLabel={(option) => option.cityDTO[0].name || ""}
                getOptionLabel={(option) => {
                  return `${option.cityDTO[0].shortName} (${option.name})`;
                }}
                id="city"
                debug
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    className={clsx(classes.inputs, classes.textInput)}
                    placeholder="Which city?"
                    variant="outlined"
                  />
                )}
                onChange={(event, value) => {
                  setSearchedCity(value);
                }}
                getOptionSelected={(option, value) => {
                  return option?.cityDTO[0]?.name === value?.cityDTO[0].name;
                }}
                style={{ width: !isMobileDevice() && "410px" }}
                className={classes.marginTop20}
              />

              <Autocomplete
                options={timelineOptions || []}
                value={timelineOption}
                getOptionLabel={(option) => option.name || ""}
                id="timeline"
                debug
                renderInput={(params) => (
                  <Tooltip title="How soon do you want the service to be completed?">
                    <TextField
                      {...params}
                      margin="normal"
                      className={clsx(classes.inputs, classes.textInput)}
                      placeholder="Timeline"
                      variant="outlined"
                    />
                  </Tooltip>
                )}
                onChange={(event, value) => {
                  setTimelineOption(value);
                }}
                getOptionSelected={(option, value) => {
                  return option === value;
                }}
                style={{ width: !isMobileDevice() && "410px" }}
                className={classes.marginTop20}
              />

              <Grid item className={classes.marginTop20}>
                <Typography varinat="body1">Service Details</Typography>
                <TextField
                  fullWidth
                  className={classes.marginTop10}
                  variant="outlined"
                  helperText="Describe 
                  details of your service request so the appropriate support can be provided."
                  label="Description"
                  multiline={true}
                  rows={4}
                  onChange={(event) => {
                    setDescription(event.target.value);
                  }}
                  inputProps={{
                    className: Styles.navyBlueColor,
                    maxLength: 500,
                  }}
                />
                <Grid
                  container
                  item
                  justifyContent="flex-end"
                  style={{ marginTop: 10 }}
                >
                  <Typography variant="body2" style={{ color: "#17174C99" }}>
                    {description.length}/500
                  </Typography>
                </Grid>
              </Grid>

              <Grid container className={classes.marginTop20}>
                <Grid item xs={12} md={4}>
                  <Typography>Add Attachments</Typography>
                </Grid>
                <Grid item container xs={8} justifyContent="flex-end">
                  <input
                    accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .png, .jpeg, .bmp, .gif, .CSV"
                    style={{ display: "none" }}
                    id="upload-docs"
                    multiple
                    type="file"
                    onChange={(event) => {
                      addFiles(event.target.files);
                    }}
                  />
                  <label htmlFor="upload-docs" style={{ marginBottom: 16 }}>
                    <Button
                      variant="outlined"
                      color="default"
                      component="span"
                      startIcon={
                        <img
                          src="/app/images/Icon material-file-upload.png"
                          alt="file upload"
                          title="file upload"
                        />
                      }
                      style={{ textTransform: "capitalize" }}
                      className={Styles.navyBlueColorForce}
                    >
                      Upload
                    </Button>
                  </label>
                </Grid>
              </Grid>
              {broadcastServiceRequestDocuments.length ? (
                <Grid item>
                  <TableContainer
                    component={Paper}
                    style={{ marginBottom: 20 }}
                  >
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>File Name</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {broadcastServiceRequestDocuments.map(
                          (document, index) => (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row">
                                {document.name}
                              </TableCell>
                              <TableCell align="right">
                                <ButtonBase>
                                  <CancelIcon
                                    onClick={(event) => {
                                      // event.stopPropagation();
                                      // event.preventDefault();
                                      deleteFile(index);
                                    }}
                                  />
                                </ButtonBase>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              ) : null}
            </>
          ) : (
            <>
              <Grid container className={classes.marginTop10}>
                <Grid item>
                  <Typography className={classes.font14}>
                    Please log in to interact with service providers. Don't have
                    an account? Sign up for your free Souqh account today -
                    should take less than a minute
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          {userInfo ? (
            <Button
              disabled={!searchedCategory || !searchedCity || !timelineOption || !description}
              style={{
                textTransform: "capitalize",
                marginRight: 10,
                cursor: "pointer",
              }}
              // onClick={() => {
              //   createBroadcastService(
              //     searchedCategory,
              //     searchedCity,
              //     timelineOption,
              //     description,
              //     null, // for rentalForNewComersDetails
              //     (res) => {
              //       if (res && res.data.status === 200) {
              //         setOpenBroadcastServiceRequestDialog(false);
              //         clearData();
              //       }
              //     }
              //   );
              // }}
              onClick={() => {
                getMatchingServiceProviderDetails(
                  searchedCategory,
                  searchedCity,
                  (res) => {
                    if (res.data.status === 200) {
                      setOpenBroadcastConfirmationDialog(true);
                    }
                  }
                );
              }}
              className={clsx(
                Styles.navyBlueColorForce,
                Styles.primaryBtnAccent
              )}
            >
              Submit
            </Button>
          ) : (
            <>
              <Button
                className={Styles.navyBlueColorForce}
                style={{ textTransform: "capitalize", cursor: "pointer" }}
                onClick={() => {
                  history.push("/homebuyersignup");
                }}
              >
                Sign up for free
              </Button>
              <Button
                style={{
                  textTransform: "capitalize",
                  marginRight: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  history.push("/userlogin");
                }}
                className={clsx(
                  Styles.navyBlueColorForce,
                  Styles.primaryBtnAccent
                )}
              >
                Login
              </Button>
            </>
          )}
        </DialogActions>
        {/* {errorMsg ? (
        <Snackbar
          open={errorMsg}
          autoHideDuration={3000}
          onClose={() => {
            setOpenserviceRequestDialog({
              open: false,
              service: null,
            });
            setErrorMsg("");
          }}
        >
          <Alert variant="filled" severity="error">
            {errorMsg}
          </Alert>
        </Snackbar>
      ) : null} */}
      </Dialog>
    </>
  );
}
