import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  makeStyles,
  TextField,
  Avatar,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import Rating from "@material-ui/lab/Rating";
import { useAddReview } from "souqh-react-redux-hooks/homeBuyer/spManagement/useAddReview";
import ContactNumber from "./ContactNumber";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: !isMobileDevice() && 707,
    minWidth: !isTabletDevice() && !isMobileDevice() && 800,
  },
}));

function AddReviewDialog({ openAddReviewDialog, setOpenAddReviewDialog }) {
  const classes = useStyles();
  const {
    addReview,
    ratingValue,
    setRatingValue,
    reviewComment,
    setReviewComment,
  } = useAddReview();

  const handleDialogClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    setOpenAddReviewDialog({ open: false, data: {} });
  };

  return (
    <Dialog
      fullWidth
      open={openAddReviewDialog && openAddReviewDialog.open}
      onClose={handleDialogClose}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Add Review</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container alignItems="center">
          <Grid item xs={2} md={1}>
            <Avatar
              style={{ height: 56, width: 56, borderRadius: 4 }}
              variant="square"
              src={
                openAddReviewDialog &&
                openAddReviewDialog.open &&
                openAddReviewDialog.data.displayPicture
              }
            >
              N
            </Avatar>
          </Grid>
          <Grid
            container
            item
            xs={10}
            md={11}
            style={{
              paddingLeft: isTabletDevice() || isMobileDevice() ? 28 : 10,
            }}
          >
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <Typography
                  className={Styles.navyBlueColor}
                  variant="body1"
                  style={{ fontWeight: 500 }}
                >
                  {openAddReviewDialog &&
                    openAddReviewDialog.open &&
                    openAddReviewDialog.data.storefrontName}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid
                item
                xs={2}
                md={1}
                style={{
                  marginRight: !isTabletDevice() && !isMobileDevice() && -20,
                }}
              >
                <EmailIcon className={Styles.navyBlueColor} />
              </Grid>
              <Grid item xs={10} md={11}>
                <Typography
                  variant="body1"
                  className={clsx(Styles.navyBlueColor, Styles.sqEllipsis)}
                >
                  {openAddReviewDialog &&
                    openAddReviewDialog.open &&
                    openAddReviewDialog.data.email}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid
                item
                xs={2}
                md={1}
                style={{
                  marginRight: !isTabletDevice() && !isMobileDevice() && -20,
                }}
              >
                <CallIcon className={Styles.navyBlueColor} />
              </Grid>
              <Grid item xs={10} md={11}>
                <ContactNumber
                  contactNumber={
                    openAddReviewDialog &&
                    openAddReviewDialog.open &&
                    openAddReviewDialog.data.contactNumber
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ margin: "10px 0px" }} />
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              className={Styles.navyBlueColor}
              style={{ fontWeight: 600 }}
            >
              Overall Rating
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Rating
              name="rating"
              value={ratingValue}
              className={Styles.navyBlueColorForce}
              onChange={(event, newValue) => {
                setRatingValue(newValue);
              }}
            />
          </Grid>
        </Grid>
        <Divider style={{ margin: "10px 0px" }} />
        <Grid item xs={12}>
          <Typography
            variant="body1"
            className={Styles.navyBlueColor}
            style={{ fontWeight: 600 }}
          >
            {openAddReviewDialog &&
              openAddReviewDialog.open &&
              openAddReviewDialog.data.serviceName}
          </Typography>
        </Grid>
        <Divider style={{ margin: "15px 0px" }} />
        <Grid item xs={12}>
          <Typography
            variant="body1"
            className={Styles.navyBlueColor}
            style={{ fontWeight: 600 }}
          >
            Add a written review
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 16 }}>
          <TextField
            multiline
            placeholder="Add comments"
            required
            rows={9}
            rowsMax={9}
            variant="outlined"
            fullWidth
            inputProps={{
              className: Styles.navyBlueColor,
              maxLength: 500,
            }}
            value={reviewComment}
            onChange={(e) => {
              setReviewComment(e.target.value);
            }}
          />
          <Grid container item justifyContent="flex-end">
            <Typography variant="body2" style={{ color: "#17174C99" }}>
              {reviewComment.length}/500
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions style={{ padding: "16px 37px" }}>
        <Button
          variant="outlined"
          style={{
            padding: "6px 16px",
            marginRight: 12,
            textTransform: "capitalize",
          }}
          className={Styles.navyBlueColorForce}
          onClick={() => {
            setOpenAddReviewDialog({ open: false, data: {} });
            setReviewComment("");
            setRatingValue(0);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!ratingValue || !reviewComment}
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize", padding: "6px 32px" }}
          onClick={() => {
            addReview(
              openAddReviewDialog.data.serviceRequestId,
              openAddReviewDialog.data.storefrontId,
              (res) => {
                if (res && res.data.status === 200) {
                  setOpenAddReviewDialog({ open: false, data: {} });
                  setReviewComment("");
                  setRatingValue(0);
                }
              }
            );
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddReviewDialog;
