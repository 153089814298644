import { useSelector } from "react-redux";

import { SET_BANNER_PICTURE } from "../../../souqh-redux/reducers/actionConstants";
import { useActions } from "../../useActions";

const actionCreators = {
  setBannerPicture: (payload) => ({
    type: SET_BANNER_PICTURE,
    payload,
  }),
};

export const useBannerPicture = () => {
  const bannerPicture = useSelector((state) => state.storefront.bannerPicture);
  const actions = useActions(actionCreators);

  return { bannerPicture, actions };
};
