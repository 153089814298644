import { Grid, Typography } from "@material-ui/core";
import { find } from "lodash";
import { passwordValidationMeta } from "souqh-react-redux-hooks/validationMetas";
import Styles from "../Styles.module.css";
import CloseIcon from "@material-ui/icons/Close";

const PasswordChecks = ({ validations }) => {
  let istyle = {
    paddingRight: 5,
    paddingTop: 2,
    height: 15,
    width: 15,
  };

  const checkmark = <img src="/images/checkmark.svg" style={istyle}></img>;
  const crossmark = (
    <CloseIcon
      style={{ ...istyle, fontSize: 16, color: "#FF005C", paddingTop: 4 }}
    />
  );

  return (
    <Grid container>
      {passwordValidationMeta.patterns.map((p, index) => {
        let found = find(validations, (v) => v === p.msg);
        return (
          <Grid container key={index} style={{ flexWrap: "nowrap" }}>
            {found ? crossmark : checkmark}
            <Typography variant="caption" className={Styles.navyBlueColor}>
              {p.msg}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default PasswordChecks;
