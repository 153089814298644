import { Grid, makeStyles, TextField } from "@material-ui/core";

import React from "react";
import { useBusinessYrAndEmp } from "souqh-react-redux-hooks/serviceProvider/useSetUp";
import Styles from "../../../Styles.module.css";

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

export default function BusinessYearsAndEmp() {
  const classes = useStyles();

  const {
    actions: { setBusinessYears, setNumOfEmp },
    yearsInBusiness,
    numberOfEmployees,
    validations,
  } = useBusinessYrAndEmp();

  return (
    <Grid container item xs={12} className={classes.margin} spacing={2}>
      <Grid item xs={6}>
        <TextField
          error={!!yearsInBusiness && !!validations.yearsInBusiness}
          helperText={!!yearsInBusiness && validations.yearsInBusiness}
          fullWidth
          required
          label="Years in Business"
          value={yearsInBusiness}
          variant="outlined"
          type="number"
          inputProps={{
            min: 1,
            max: 999,
            className: Styles.navyBlueColorForce,
          }}
          onChange={(event) => {
            if (
              event.target.value === "" ||
              parseInt(event.target.value) <= 999
            ) {
              setBusinessYears(event.target.value);
            }
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          error={!!numberOfEmployees && !!validations.numberOfEmployees}
          helperText={!!numberOfEmployees && validations.numberOfEmployees}
          fullWidth
          required
          label="Number Of Employees"
          variant="outlined"
          type="number"
          value={numberOfEmployees}
          inputProps={{
            min: 1,
            max: 99999,
            className: Styles.navyBlueColorForce,
          }}
          onChange={(event) => {
            if (
              event.target.value === "" ||
              parseInt(event.target.value) <= 99999
            ) {
              setNumOfEmp(event.target.value);
            }
          }}
        />
      </Grid>
    </Grid>
  );
}
