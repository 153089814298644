import { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  SET_EXPLORE_SERVICES,
  SET_EXPLORE_CITIES,
  SET_FEATURED_STORE_FRONTS,
  SET_SEARCHED_STORE_FRONTS,
  EXPLORE_PAGE_FILTER,
  SET_SEARCHED_CITY,
  SET_SEARCHED_SERVICE,
  CLEAR_EXPLORE_SEARCH_RESULT,
  SET_FEATURED_STORE_FRONTS_FOR_WEBSITE,
  SET_FEATURED_STORE_FRONTS_ADD_ON,
} from "../../souqh-redux/reducers/actionConstants";
import { cloneDeep } from "lodash";
import { createSelector } from "reselect";
import { emailValidationMeta } from "../validationMetas";
import { useValidations } from "../useValidations";
import { useActions } from "../useActions";
import { useApiClient } from "../useApiClient";
import { isTabletDevice } from "../../web/src/utils/AppUtils";
import { DOMAIN_URL } from "../../web/src/components/ServiceProvider/serviceProviderConstants";
import { useAuthUser } from "../useLogin";

const validationMeta = [emailValidationMeta];

const actionCreators = {
  setFilterActive: (payload) => ({ type: EXPLORE_PAGE_FILTER, payload }),
  setSearchedCity: (payload) => ({ type: SET_SEARCHED_CITY, payload }),
  setSearchedService: (payload) => ({ type: SET_SEARCHED_SERVICE, payload }),
  clearSearchResult: () => ({
    type: CLEAR_EXPLORE_SEARCH_RESULT,
    payload: null,
  }),
};

export const useExplore = () => {
  const { apiClient } = useApiClient();
  const elRefs = useRef([]);
  const dispatch = useDispatch();
  const actions = useActions(actionCreators);
  const user = useAuthUser();

  const [carouselLimit, setCarouselLimit] = useState(false);
  const [displayServicesList, setDisplayServicesList] = useState([]);
  const [lastIndexServices, setLastIndexServices] = useState(0);
  const [showEmailSection, setShowEmailSection] = useState(false);
  const [email, setEmail] = useState("");
  const [pageSize, setPageSize] = useState(0);
  const [loadingMsg, setLoadingMsg] = useState("");
  const [scrollBottom, setscrollBottom] = useState(0);
  const [storefrontPathName, setStorefrontPathName] = useState(null);
  const [
    openBroadcastServiceRequestDialog,
    setOpenBroadcastServiceRequestDialog,
  ] = useState(false);
  const [preferredPartners, setPreferredPartners] = useState([])
  const { isFilterActive, searchedService, searchedCity } = useSelector(
    (state) => state.homeBuyer
  );

  const {
    selectedBusinessType,
    selectedAreaOfSpec,
    selectedLanguage,
    rating,
    badge,
  } = useSelector((state) => state.explorePageFilters);

  const { featuredStoreFrontsForWebsite, featuredStoreFrontsAddon } =
    useSelector((state) => state.homeBuyer);

  const tempExampleSearches = [
    {
      id: 2,
      name: "Mortgage Broker",
      logoUrl: "Mortgage-Broker.svg",
      isBusinessType: true,
      storefrontPathName: "mortgage-broker-agent",
    },
    {
      id: 3,
      name: "Real Estate Lawyer",
      logoUrl: "Lawyer.svg",
      isBusinessType: true,
      storefrontPathName: "real-estate-lawyer",
    },
    {
      id: 6,
      name: "Mover",
      logoUrl: "Mover.svg",
      isBusinessType: true,
      storefrontPathName: "mover",
    },
    {
      id: 39,
      name: "Plumbing",
      shortName: "Plumbing",
      logoUrl: "Plumbing.svg",
      isBusinessType: false,
      storefrontPathName: "plumbing-services",
    },
  ];
  const exampleSearches =
    !user ||
    (user && !user.referredSPCatgoryShortName) ||
    (user &&
      user.referredSPCatgoryShortName &&
      !user.referredSPCatgoryShortName.length)
      ? tempExampleSearches
      : tempExampleSearches.filter(
          (service) => !user.referredSPCatgoryShortName.includes(service.name)
        );

  let businessTypeArr = [];
  let areaOfSpecArr = [];
  let langArr = [];
  let ratingArr = []; // will be used later when API is modified

  selectedBusinessType &&
    selectedBusinessType.length &&
    selectedBusinessType.map((type) => businessTypeArr.push(type.id));
  let stringifiedBusinessTypes = businessTypeArr.toString();

  selectedAreaOfSpec &&
    selectedAreaOfSpec.length &&
    selectedAreaOfSpec.map((spec) => areaOfSpecArr.push(spec.id));
  let stringifiedAreas = areaOfSpecArr.toString();

  selectedLanguage &&
    selectedLanguage.length &&
    selectedLanguage.map((lang) => langArr.push(lang.id));
  let stringifiedLangs = langArr.toString();

  let badgeArr = badge && badge.value;

  const getFormValues = useCallback(() => {
    return { email };
  }, [email]);
  const getServerKeysMap = useCallback(() => {}, []);

  const { validations, addValidations, clearAllValidations } = useValidations(
    validationMeta,
    getFormValues,
    getServerKeysMap
  );

  const services = useSelector((state) => state.homeBuyer.services);
  const cities = useSelector((state) => state.homeBuyer.cities);

  const getFeatuedStoreFrontsList = createSelector(
    (state) => state.homeBuyer.featuredStoreFronts,
    (featuredStoreFronts) => {
      if (!featuredStoreFronts.response || !featuredStoreFronts.response.length)
        return [];
      return featuredStoreFronts.response.map((v) => ({
        storeFrontId: v.storeFrontId,
        businessName: v.businessName,
        businessTypeName:
          v.businessTypes[0].name === "Other"
            ? v.businessTypes[0].otherBusinessName
            : v.businessTypes[0].name,
        primarySubscriptionPlanName: v.primarySubscriptionPlanName,
        storeFrontContent: JSON.parse(v.storeFrontContent),
        storeFrontRouteName: v.storeFrontRouteName,
        storeFrontName: v.storeFrontName,
        email: v.email,
        reviewsCount: v.reviewsCount,
        avgStarRatings: v.avgStarRatings || 0,
        contactNumber: v.contactNumber,
        address: v.address,
        calSyncAccessToken: v.calSyncAccessToken,
        sfServices: v.sfServices,
        serviceProviderId: v.serviceProviderId,
        storefrontPathName: v.businessTypes[0].storefrontPathName,
        subBusinessTypes: v.subBusinessTypes,
        businessTypes: v.businessTypes,
        locationsServed: v.locationsServed,
      }));
    }
  );

  const getSearchedStoreFrontsList = createSelector(
    (state) => state.homeBuyer.searchedStoreFronts,
    (searchedStoreFronts) => {
      if (!searchedStoreFronts.response.length) {
        return {
          response: [],
          totalPages: 0,
          totalItems: 0,
        };
      } else {
        let tempArr = [];
        let response = cloneDeep(searchedStoreFronts.response);
        tempArr = response.map((v) => ({
          storeFrontId: v.storeFrontId,
          businessName: v.businessName,
          businessTypeName:
            v.businessTypes[0].name === "Other"
              ? v.businessTypes[0].otherBusinessName
              : v.businessTypes[0].name,
          primarySubscriptionPlanName: v.primarySubscriptionPlanName,
          storeFrontContent: JSON.parse(v.storeFrontContent),
          storeFrontRouteName: v.storeFrontRouteName,
          storeFrontName: v.storeFrontName,
          email: v.email,
          reviewsCount: v.reviewsCount,
          avgStarRatings: v.avgStarRatings || 0,
          contactNumber: v.contactNumber,
          address: v.address,
          calSyncAccessToken: v.calSyncAccessToken,
          sfServices: v.sfServices,
          serviceProviderId: v.serviceProviderId,
          storefrontPathName: v.businessTypes[0].storefrontPathName,
          subBusinessTypes: v.subBusinessTypes,
          businessTypes: v.businessTypes,
          locationsServed: v.locationsServed,
        }));
        return {
          response: tempArr,
          totalPages: searchedStoreFronts.totalPages,
          totalItems: searchedStoreFronts.totalItems,
        };
      }
    }
  );

  const featuredStoreFronts = useSelector(getFeatuedStoreFrontsList);
  const searchedStoreFronts = useSelector(getSearchedStoreFrontsList);
  const [storeFrontList, setStoreFrontList] = useState([]);

  useEffect(() => {
    if (services.length) {
      let tempServices = cloneDeep(services);
      let filteredServices =
        user &&
        user.referredSPCatgoryShortName &&
        tempServices.filter(
          (service) =>
            !user.referredSPCatgoryShortName.includes(service.shortName)
        );
      console.log(filteredServices);
      if (services.length >= 8) {
        let tempLocation;
        if (filteredServices && filteredServices.length) {
          tempLocation = cloneDeep(filteredServices);
        } else {
          tempLocation = cloneDeep(services);
        }
        setCarouselLimit(true);
        if (!displayServicesList.length) {
          if (isTabletDevice()) {
            setDisplayServicesList(tempLocation.splice(0, 6));
          } else {
            setDisplayServicesList(tempLocation.splice(0, 11)); // set 11 elements from array as back its last index and back arrow will be hidden over here
          }
        }
      } else {
        if (filteredServices && filteredServices.length) {
          setDisplayServicesList(filteredServices);
        } else {
          setDisplayServicesList(services);
        }
        setCarouselLimit(false);
      }
    }
  }, [services]);

  useEffect(() => {
    window.onscroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
        setscrollBottom((prevState) => prevState + 1);
      }
    };
    // window.addEventListener("scroll", handelScroll);
    // return () => {
    //   window.removeEventListener("scroll", () => {});
    // };
  }, []);

  const handelScroll = (event) => {
    if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
      setscrollBottom((prevState) => prevState + 1);
    }
  };

  useEffect(() => {
    if (scrollBottom) {
      if (
        searchedStoreFronts.response.length &&
        pageSize + 1 < searchedStoreFronts.totalPages
      ) {
        setPageSize((prevState) => ++prevState);
        if (isFilterActive) {
          getFilteredStoreFronts(pageSize + 1);
        } else {
          getSearchedStoreFronts(
            searchedService,
            searchedCity?.cityDTO?.[0]?.id,
            pageSize + 1
          );
        }
      }
    }
  }, [scrollBottom]);

  const getServicesList = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get("/home-buyer/explore/services")
      .then(function (response) {
        dispatch({
          type: SET_EXPLORE_SERVICES,
          payload: { services: response.data },
        });
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getCitiesList = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get("/home-buyer/explore/cities")
      .then(function (response) {
        dispatch({
          type: SET_EXPLORE_CITIES,
          payload: { cities: response.data },
        });
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getFeaturedStoreFronts = (searchedService, cityId) => {
    let url = `/home-buyer/explore/search/${cityId}/featured`;
    dispatch({ type: "SET_LOADING", value: true });
    if (searchedService && cityId) {
      return apiClient
        .get(url, {
          params: { searchText: searchedService && searchedService.trim() },
        })
        .then(function (response) {
          dispatch({
            type: SET_FEATURED_STORE_FRONTS,
            payload: { featuredStoreFronts: response.data },
          });
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const getSearchedStoreFronts = (searchedService, cityId, page) => {
    if (!page) {
      dispatch({ type: "SET_LOADING", value: true });
    } else {
      setLoadingMsg("Loading more...");
    }
    let url = `/home-buyer/explore/search/${cityId}?page=${page}&size=8`;
    if (searchedService && cityId) {
      return apiClient
        .get(url, {
          params: { searchText: searchedService && searchedService.trim() },
        })
        .then(function (response) {
          if (response.data.response.length) {
            if (!page) {
              // if 1st page reset pageSize
              setPageSize(0);
            }
            setShowEmailSection(false);
            response.data.page = page;
            dispatch({
              type: SET_SEARCHED_STORE_FRONTS,
              payload: { searchedStoreFronts: response.data },
            });
          } else {
            // If no results for search empty the previous result
            dispatch({
              type: SET_SEARCHED_STORE_FRONTS,
              payload: {
                searchedStoreFronts: {
                  response: [],
                  totalPages: 0,
                  totalItems: 0,
                },
              },
            });
            setShowEmailSection(true);
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          if (!page) {
            dispatch({ type: "SET_LOADING", value: false });
          } else {
            setLoadingMsg("");
          }
        });
    }
  };

  const handleNext = () => {
    const index = cloneDeep(lastIndexServices);
    const tempLocation = cloneDeep(services);
    let filteredServices =
      user &&
      user.referredSPCatgoryShortName &&
      tempLocation.filter(
        (service) =>
          !user.referredSPCatgoryShortName.includes(service.shortName)
      );
    if (filteredServices && filteredServices.length) {
      if (lastIndexServices < filteredServices.length - 1) {
        if (isTabletDevice()) {
          setLastIndexServices((prevActiveStep) => prevActiveStep + 5);
          setDisplayServicesList(filteredServices.splice(index + 5, 5));
        } else {
          setLastIndexServices((prevActiveStep) => prevActiveStep + 10);
          setDisplayServicesList(filteredServices.splice(index + 10, 10));
        }
      }
    } else {
      if (lastIndexServices < services.length - 1) {
        if (isTabletDevice()) {
          setLastIndexServices((prevActiveStep) => prevActiveStep + 5);
          setDisplayServicesList(tempLocation.splice(index + 5, 5));
        } else {
          setLastIndexServices((prevActiveStep) => prevActiveStep + 10);
          setDisplayServicesList(tempLocation.splice(index + 10, 10));
        }
      }
    }
  };

  const handleBack = () => {
    const index = cloneDeep(lastIndexServices);
    const tempLocation = cloneDeep(services);
    let filteredServices =
      user &&
      user.referredSPCatgoryShortName &&
      tempLocation.filter(
        (service) =>
          !user.referredSPCatgoryShortName.includes(service.shortName)
      );

    if (filteredServices && filteredServices.length) {
      if (lastIndexServices < filteredServices.length) {
        if (isTabletDevice()) {
          if (lastIndexServices === 5) {
            setLastIndexServices((prevActiveStep) => prevActiveStep - 5);
            setDisplayServicesList(filteredServices.splice(index - 5, 6));
          } else {
            setLastIndexServices((prevActiveStep) => prevActiveStep - 5);
            setDisplayServicesList(filteredServices.splice(index - 5, 5));
          }
        } else {
          if (lastIndexServices === 10) {
            setLastIndexServices((prevActiveStep) => prevActiveStep - 10);
            setDisplayServicesList(filteredServices.splice(index - 10, 11)); // set 11 elements from array as back its last index and back arrow will be hidden over here
          } else {
            setLastIndexServices((prevActiveStep) => prevActiveStep - 10);
            setDisplayServicesList(filteredServices.splice(index - 10, 10));
          }
        }
      }
    } else {
      if (lastIndexServices < services.length) {
        if (isTabletDevice()) {
          if (lastIndexServices === 5) {
            setLastIndexServices((prevActiveStep) => prevActiveStep - 5);
            setDisplayServicesList(tempLocation.splice(index - 5, 6));
          } else {
            setLastIndexServices((prevActiveStep) => prevActiveStep - 5);
            setDisplayServicesList(tempLocation.splice(index - 5, 5));
          }
        } else {
          if (lastIndexServices === 10) {
            setLastIndexServices((prevActiveStep) => prevActiveStep - 10);
            setDisplayServicesList(tempLocation.splice(index - 10, 11));
          } else {
            setLastIndexServices((prevActiveStep) => prevActiveStep - 10);
            setDisplayServicesList(tempLocation.splice(index - 10, 10));
          }
        }
      }
    }
  };

  const getFilteredStoreFronts = (page) => {
    let searchedCityId = searchedCity.cityDTO?.[0]?.id;
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`home-buyer/explore/filter/${searchedCityId}`, {
        params: {
          badgePartnerType: badgeArr,
          languages: stringifiedLangs,
          page: page,
          size: 8,
          specializations: stringifiedAreas,
          subTypes: stringifiedBusinessTypes,
          starRatings: rating && rating.name,
          searchText: searchedService && searchedService.trim(),
        },
      })
      .then(function (response) {
        if (response.data.response.length) {
          if (!page) {
            // if 1st page reset pageSize
            setPageSize(0);
          }
          setShowEmailSection(false);
          response.data.page = page;
          dispatch({
            type: SET_SEARCHED_STORE_FRONTS,
            payload: { searchedStoreFronts: response.data },
          });
        } else {
          // If no results for search empty the previous result
          dispatch({
            type: SET_SEARCHED_STORE_FRONTS,
            payload: {
              searchedStoreFronts: {
                response: [],
                totalPages: 0,
                totalItems: 0,
              },
            },
          });
          setShowEmailSection(true);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getFilteredFeaturedStoreFronts = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(
        `home-buyer/explore/filter/storefronts/featured?badgePartnerType=${badgeArr}&languages=${stringifiedLangs}&page=0&size=8&specializations=${stringifiedAreas}&subTypes=${stringifiedBusinessTypes}&starRatings=${rating.name}`
      )
      .then(function (response) {
        dispatch({
          type: SET_FEATURED_STORE_FRONTS,
          payload: { featuredStoreFronts: response.data },
        });
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  // Find storefront by business name, SF name, business type and business sub categories
  const getAllStorefronts = (searchValue) => {
    if (searchValue) {
      return apiClient
        .get(`/home-buyer/explore/search-criteria`, {
          params: {
            searchText: searchValue.trim(),
          },
        })
        .then(function (response) {
          if (response && response.data && response.data.length) {
            setStoreFrontList(response.data);
          } else {
            setNoSearchResults(true);
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {});
    }
  };

  const getFeaturedStorefrontsForWebsite = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/home-buyer/explore/website/storefronts/featured`)
      .then(function (response) {
        dispatch({
          type: SET_FEATURED_STORE_FRONTS_FOR_WEBSITE,
          payload: { featuredStoreFrontsForWebsite: response.data },
        });
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getFeaturedStorefrontsAddon = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/marketing-campaign/addon`)
      .then(function (response) {
        if (response?.data?.result)
          dispatch({
            type: SET_FEATURED_STORE_FRONTS_ADD_ON,
            payload: { featuredStoreFrontsAddon: response.data.result },
          });
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const addImpression = (url) => {
    return apiClient
      .post(`marketing-campaign/add-impressions/${url}`)
      .catch(function (error) {
        console.error(error);
      });
  };

  const getFeatureSfsByBusinessType = (businessType) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(
        `marketing-campaign/filterByBusinessType?businessType=${businessType}`
      )
      .then((res) => res.data?.result || [])
      .catch((error) => {
        console.error(error);
      })
      .finally(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const formSaveCategoryObjAndCallApi = (
    category,
    isHomeServicesProfessional,
    userInfo
  ) => {
    let obj;
    if (category) {
      if (category.isBusinessType) {
        obj = {
          businessTypeId: category.id,
          label:
            window.location.origin === "https://app.souqh.ca" ||
            window.location.origin === "https://development.souqh.co" ||
            window.location.origin === "https://demo.souqh.co" ||
            window.location.origin === "http://localhost:3000"
              ? "App"
              : "Website",
          subBusinessTypeId: null,
          userId: userInfo ? userInfo.userId : null,
        };
      } else {
        obj = {
          businessTypeId: null,
          label:
            window.location.origin === "https://app.souqh.ca" ||
            window.location.origin === "https://development.souqh.co" ||
            window.location.origin === "https://demo.souqh.co" ||
            window.location.origin === "http://localhost:3000"
              ? "App"
              : "Website",
          subBusinessTypeId: category.id,
          userId: userInfo ? userInfo.userId : null,
        };
      }
    } else {
      if (isHomeServicesProfessional) {
        obj = {
          businessTypeId: 7, // id for Home Services Professional
          label:
            window.location.origin === "https://app.souqh.ca" ||
            window.location.origin === "https://development.souqh.co" ||
            window.location.origin === "https://demo.souqh.co" ||
            window.location.origin === "http://localhost:3000"
              ? "App"
              : "Website",
          subBusinessTypeId: null,
          userId: userInfo ? userInfo.userId : null,
        };
      }
    }
    saveCategoryVisit(obj);
  };

  const saveCategoryVisit = (obj) => {
    let categoryVisitRequest = {
      businessTypeId: obj.businessTypeId,
      label: obj.label,
      subBusinessTypeId: obj.subBusinessTypeId,
      userId: obj.userId,
    };
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post(`/business-category/visit`, categoryVisitRequest)
      .then(function (response) {
        console.log("response", response);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getFeatureSfsByBusinessTypeForNonLoggedInUsers = (
    businessType,
    cityId
  ) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(
        `/marketing-campaign/filterByBusinessTypeForNonLoggedInUsers?businessType=${businessType}`,
        {
          params: {
            cityId: cityId,
          },
        }
      )
      .then((res) => res.data?.result || [])
      .catch((error) => {
        console.error(error);
      })
      .finally(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getAllPreferredPartners = (userInfo) => {
    if (userInfo && userInfo.referredSPName) {
      let url = `/preferred-partner/${userInfo.homeBuyerId}/all`;

      dispatch({ type: "SET_LOADING", value: true });
      return apiClient
        .get(url)
        .then(function (response) {
          setPreferredPartners(response.data.result)
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          // always executed
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  }

  return {
    searchedService,
    searchedCity,
    services,
    getServicesList,
    cities,
    getCitiesList,
    carouselLimit,
    displayServicesList,
    handleNext,
    handleBack,
    lastIndexServices,
    exampleSearches,
    getFeaturedStoreFronts,
    featuredStoreFronts,
    getSearchedStoreFronts,
    searchedStoreFronts,
    showEmailSection,
    setEmail,
    validations,
    elRefs,
    loadingMsg,
    getFilteredStoreFronts,
    getFilteredFeaturedStoreFronts,
    setscrollBottom,
    setPageSize,
    actions,
    getAllStorefronts,
    storeFrontList,
    getFeaturedStorefrontsForWebsite,
    featuredStoreFrontsForWebsite,
    getFeaturedStorefrontsAddon,
    featuredStoreFrontsAddon,
    addImpression,
    getFeatureSfsByBusinessType,
    storefrontPathName,
    setStorefrontPathName,
    formSaveCategoryObjAndCallApi,
    saveCategoryVisit,
    openBroadcastServiceRequestDialog,
    setOpenBroadcastServiceRequestDialog,
    getFeatureSfsByBusinessTypeForNonLoggedInUsers,
    getAllPreferredPartners,
    preferredPartners
  };
};
