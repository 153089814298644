import React from "react";

import {
  Divider,
  Grid,
  Paper,
  Typography,
  Switch,
  TextField,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import {
  useTeam,
  useValidationForLicensePractioner,
} from "souqh-react-redux-hooks/serviceProvider/useSetUp";
export default function LicensedPractioner() {
  const {
    licensedPractitioner,
    licenseName,
    licenseNumber,
    actions: { setLicensedPractitioner, setLicenseName, setLicenseNumber },
  } = useTeam();
  const { addValidations, validations } = useValidationForLicensePractioner();
  return (
    <Paper style={{ marginTop: 20 }} elevation={0}>
      <Grid container style={{ padding: "10px 26px 10px 23px" }}>
        <Typography variant="subtitle1" className={Styles.navyBlueColor}>
          Licensed Practitioner
        </Typography>
      </Grid>
      <Divider />
      <Grid item container style={{ padding: "10px 26px 10px 23px" }}>
        <Grid item xs={10}>
          <Typography variant="body1" className={Styles.navyBlueColor}>
            Are you a licensed practitioner?
          </Typography>
        </Grid>
        <Grid item container xs={2} justifyContent="flex-end">
          <Switch
            className={"sqGraySwitch"}
            checked={licensedPractitioner}
            onChange={(e) => {
              setLicensedPractitioner({ check: e.target.checked });
            }}
          ></Switch>
        </Grid>
      </Grid>
      {licensedPractitioner && (
        <Grid item container style={{ padding: "10px 10px 20px 10px" }}>
          <TextField
            error={!!validations.licenseName}
            helperText={validations.licenseName}
            label="Licensing Body Name"
            fullWidth
            value={licenseName}
            onChange={(e) => {
              setLicenseName(e.target.value);
            }}
            variant="outlined"
            required
            inputProps={{
              maxLength: 256,
              className: Styles.navyBlueColor,
            }}
          ></TextField>
          <TextField
            error={!!validations.licenseNumber}
            helperText={validations.licenseNumber}
            label="License Number"
            fullWidth
            value={licenseNumber}
            onChange={(e) => {
              setLicenseNumber(e.target.value);
            }}
            variant="outlined"
            required
            inputProps={{
              maxLength: 50,
              className: Styles.navyBlueColor,
            }}
          ></TextField>
        </Grid>
      )}
    </Paper>
  );
}
