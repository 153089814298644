import { useAuthUser } from "../../useLogin";
import { useDispatch } from "react-redux";
import { useApiClient } from "../../useApiClient";
import { useState } from "react";
import { SET_COMPLETE_CLIENT_DATA } from "../../../souqh-redux/reducers/actionConstants";

export const useCompleteClientInfo = (pageSize = 24) => {
  const { apiClient } = useApiClient();
  const userInfo = useAuthUser();
  const dispatch = useDispatch();
  const [completionReason, setCompletionReason] = useState("");

  const completeClientRequest = (serviceRequestId, onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .put(
        `/service-provider/client/service-request/completed/${serviceRequestId}`,
        {
          completeRequestReason: completionReason,
        }
      )
      .then((res) => {
        onResult && onResult(res);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getCompletedClientData = (page = 0) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(
        `/service-provider/client/service-request/completed/${userInfo.userId}?page=${page}&size=${pageSize}`
      )
      .then(function (response) {
        if (response.data.response && response.data.response.length) {
          const data = processResponse(response.data);
          dispatch({
            type: SET_COMPLETE_CLIENT_DATA,
            payload: data,
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const processResponse = (data) => {
    data.response = data.response.length
      ? data.response.map((res) => {
          return {
            ...res.hbDetails,
            ...res,
            starRating:
              res.hbServiceReviews &&
              res.hbServiceReviews.length &&
              res.hbServiceReviews[0].starRating,
          };
        })
      : [];
    return data;
  };

  return {
    completionReason,
    completeClientRequest,
    setCompletionReason,
    getCompletedClientData,
  };
};
