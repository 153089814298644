import {
  Dialog,
  DialogContent,
  DialogContentText,
  Button,
  Grid,
  ButtonBase,
  DialogTitle,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useRef, useState } from "react";
import Styles from "../../../Styles.module.css";
import CloseIcon from "@material-ui/icons/Close";
import { DOMAIN_URL } from "../serviceProviderConstants";

export default function ShareEmbedDialog({
  open,
  setopenShareEmbedDialog,
  storeFrontName,
  copyLink,
  data,
}) {
  const [copySuccess, setCopySuccess] = useState(false);
  const textAreaRef = useRef(null);

  const copyToClipboard = (e) => {
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1000);
  };

  return (
    <Dialog open={open} maxWidth="sm">
      <DialogContent>
        <DialogTitle style={{ padding: "0px 0px 10px 0px" }}>
          <Grid item container xs={12} justifyContent="flex-end">
            <ButtonBase>
              <CloseIcon
                onClick={() => {
                  setopenShareEmbedDialog(false);
                }}
              />
            </ButtonBase>
          </Grid>
        </DialogTitle>
        <DialogContentText>
          <Grid item>
            <textarea
              readOnly
              style={{ width: 280 }}
              ref={textAreaRef}
              rows="9"
              value={
                copyLink
                  ? `https://${DOMAIN_URL}/storefront/${storeFrontName}`
                  : `<a href="https://${DOMAIN_URL}/storefront/${storeFrontName}" style="display: 'flex'"><img alt="${
                      data?.metaDataDescription || "logo-img"
                    }" src="https://${DOMAIN_URL}/images/blue_badge.svg" title="${
                      data?.metaDataTitle || "Souqh Premium Partner"
                    }"></img></a>`
              }
            />
            {copySuccess && (
              <MuiAlert
                style={{
                  marginTop: 10,
                  position: "absolute",
                  bottom: 12,
                  left: 100,
                }}
                severity="success"
                variant="filled"
                elevation={10}
              >
                Copied
              </MuiAlert>
            )}
          </Grid>
          <Grid item container justifyContent="flex-end">
            <Button
              variant="outlined"
              style={{
                padding: "6px 10px",
                textTransform: "capitalize",
              }}
              className={Styles.navyBlueColorForce}
              onClick={copyToClipboard}
            >
              Copy
            </Button>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
