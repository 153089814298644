import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
  Link,
} from "@material-ui/core";
import React, { useState } from "react";
import BannerPicture from "./BannerPicture";
import BrandTheme from "./BrandTheme";
import CompeteLine from "./CompeteLine";
import DisplayPicture from "./DisplayPicture";
import Overview from "./Overview";

import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { useCompeteText } from "souqh-react-redux-hooks/serviceProvider/storefront/useCompeteText";
import { useTheme } from "souqh-react-redux-hooks/serviceProvider/storefront/useTheme";
import SocialIcons from "./SocialIcons";
import RepresentativeMock1 from "./RepresentativeMock1";
import RepresentativeMock2 from "./RepresentativeMock2";
import Styles from "../../../Styles.module.css";
import SkipSFCreationDialog from "./SkipSFCreationDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    // boxShadow: "3px 3px 10px #00000000",
  },
  paper: {
    marginBottom: 40,
    borderRadius: 8,
    boxShadow: "3px 3px 10px #00000000",
  },
}));

const Header = ({
  storefrontName,
  validations,
  sourcePage,
  companyOverview,
  competeText,
  services,
}) => {
  const [openSkipSFCreationDialog, setOpenSkipSFCreationDialog] =
    useState(false);
  return (
    <>
      <SkipSFCreationDialog
        openSkipSFCreationDialog={openSkipSFCreationDialog}
        setOpenSkipSFCreationDialog={setOpenSkipSFCreationDialog}
        storefrontName={storefrontName}
        companyOverview={companyOverview}
        services={services}
        competeText={competeText}
      />
      <Grid
        style={{
          marginBottom: 48,
          marginTop: sourcePage === "manageStoreFront" ? 0 : 84,
        }}
        container
        justifyContent="space-between"
      >
        <Typography variant="h4" className={Styles.navyBlueColor}>
          Brand and Theme
        </Typography>
        {sourcePage !== "manageStoreFront" ? (
          <Button
            // disabled={!competeText}
            variant="outlined"
            className={Styles.navyBlueColorForce}
            style={{ textTransform: "capitalize" }}
            onClick={() => setOpenSkipSFCreationDialog(true)}
          >
            Skip This Step
          </Button>
        ) : null}
      </Grid>
    </>
  );
};

function BrandAndThemeSection({
  setStorefrontName,
  storefrontName,
  validations,
  sourcePage,
  companyOverview,
  services,
}) {
  const classes = useStyles();
  const user = useAuthUser();
  const { primaryColor, secondaryColor } = useTheme();
  const {
    competeText,
    actions: { setCompeteText },
  } = useCompeteText();

  return (
    <Grid container item className={classes.root} id="brand_theme">
      <Header
        sourcePage={sourcePage}
        storefrontName={storefrontName}
        validations={validations}
        companyOverview={companyOverview}
        competeText={competeText}
        services={services}
      ></Header>
      <Typography variant="subtitle1" className={Styles.navyBlueColor}>
        Your Storefront
      </Typography>
      <Grid item xs={12}>
        <Typography
          variant="body2"
          style={{
            marginBottom: 24,
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          <Link
            className={Styles.navyBlueColorForce}
            target="_blank"
            href="https://help.souqh.ca/en/articles/5325949-setting-up-your-storefront"
          >
            What is a storefront?
          </Link>
        </Typography>
      </Grid>
      <Paper className={classes.paper}>
        <BannerPicture></BannerPicture>
        <Grid container style={{ padding: "0px 16px", paddingBottom: 24 }}>
          <Grid container item xs={12}>
            <Grid container item xs={3} style={{ position: "relative" }}>
              <Grid
                item
                xs={12}
                style={{
                  position: "absolute",
                  top: -12,
                  left: 0,
                  backgroundColor: "white",
                  borderRadius: 4,
                  width: "100%",
                  height: "100% + 12px",
                }}
              >
                <DisplayPicture></DisplayPicture>
              </Grid>
            </Grid>
            <Grid container item xs={9}>
              <Grid container item style={{ flex: 1 }}>
                <Grid
                  container
                  item
                  xs={12}
                  direction="column"
                  style={{ padding: "0px 0px 0px 8px" }}
                >
                  <TextField
                    helperText={validations.storefrontName}
                    error={!!validations.storefrontName}
                    required
                    variant="outlined"
                    value={storefrontName}
                    placeholder="Storefront Name"
                    onChange={(e) => {
                      setStorefrontName(e.target.value);
                    }}
                    inputProps={{
                      className: Styles.navyBlueColor,
                      maxLength: 100,
                    }}
                  />

                  <TextField
                    style={{ marginTop: 0 }}
                    variant="outlined"
                    value={competeText}
                    placeholder="What separates you from the competition?"
                    onChange={(e) => {
                      setCompeteText({ competeText: e.target.value });
                    }}
                    inputProps={{
                      className: Styles.navyBlueColor,
                      maxLength: 100,
                    }}
                    multiline
                  />
                  <Grid container item justifyContent="flex-end">
                    <Typography variant="body2" style={{ color: "#17174C99" }}>
                      {competeText.length}/100
                    </Typography>
                  </Grid>
                  <SocialIcons></SocialIcons>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <Divider
            orientation="horizontal"
            variant="fullWidth"
            style={{ width: "100%", marginTop: 60 }}
          ></Divider> */}
          <Grid container style={{ paddingBottom: 48, paddingTop: 16 }}>
            <Grid container item xs={12}>
              {/* <CompeteLine></CompeteLine> */}
              <Overview></Overview>
            </Grid>
            {/* <Grid container item xs={6}>
              <RepresentativeMock2></RepresentativeMock2>
            </Grid> */}
          </Grid>
          <Divider
            orientation="horizontal"
            variant="fullWidth"
            style={{ width: "100%" }}
          ></Divider>
          <BrandTheme></BrandTheme>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default BrandAndThemeSection;
