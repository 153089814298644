import {
  AppBar,
  Avatar,
  ButtonBase,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import Styles from "../../Styles.module.css";
import { useHistory } from "react-router-dom";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { HOME_BUYER_USER } from "../ServiceProvider/serviceProviderConstants";
import * as moment from "moment";
import AddReviewDialog from "../HomeBuyer/SPManagement/AddReviewDialog";
import SelectHomeBuyingJourneyDialog from "./UpcomingEeventsPendingActions/SelectHomeBuyingJourneyDialog";
import AddMemberDialog from "../Settings/AddMemberDialog";
import InviteSPDialog from "../ReferralManagement/InviteSPdialog";
import AddTemplate from "../ServiceProvider/UploadDocuments/AddTemplate/AddTemplate";
import SPeSignWizard from "../ServiceProvider/UploadDocuments/SPeSignWizard";
import ViewInvoiceDialog from "./UpcomingEeventsPendingActions/ViewInvoiceDialog";
import ViewTagDialog from "./UpcomingEeventsPendingActions/ViewTagDialog";
import SignDocumentDialog from "../ServiceProvider/UploadDocuments/SignDocumentDialog";
import DocumentAccessRequestDialog from "./UpcomingEeventsPendingActions/DocumentAccessRequestDialog";
import RequestedDocumentDialog from "./UpcomingEeventsPendingActions/RequestedDocumentDialog";
import RecommendedSPDialog from "./UpcomingEeventsPendingActions/RecommendedSPDialog.";
import {
  getClientColumnValue,
  getImgSrc,
  isMarketplacePlan,
  isMobileDevice,
  isOnboardingInComplete,
} from "../../utils/AppUtils";
import DocumentPreviewDialog from "../HomeBuyer/Explore/StorefrontPage/DocumentPreviewDialog";
import { useDispatch } from "react-redux";
import { SP_SIGN_NOW_ACCOUNT_EXIST } from "../ServiceProvider/serviceProviderConstants";
import NoDataAvailable from "./NoDataAvailable";

export default function PendingActionsWidget({
  xlUp,
  setEventsTab,
  getAllPendingActions,
  pendingActionsList,
  userInfo,
  handlePendingAction,
  openAddReviewDialog,
  setOpenAddReviewDialog,
  openHomeBuyingJourneyDialog,
  setOpenHomeBuyingJourneyDialog,
  openAddMemberDialog,
  setOpenAddMemberDialog,
  openInviteSPDialogForPendingAction,
  setOpenInviteSPDialogForPendingAction,
  openAddTemplate,
  setOpenAddTemplate,
  onUploadTemplateFileChange,
  updateIntercomStatus,
  openViewInvoiceDialog,
  setOpenViewInvoiceDialog,
  viewInvoiceDialogData,
  openViewTagDialog,
  setOpenViewTagDialog,
  viewTagDialogData,
  openSignDocumentDialog,
  setOpenSignDocumentDialog,
  signDocumentItem,
  setSignDocumentItem,
  setEventIndex,
  reloadPendingActionData,
  deletePendingAction,
  openRecommendedSPDialog,
  setOpenRecommendedSPDialog,
  recommendedSpData,
  openRequestedDocumentDialog,
  setOpenRequestedDocumentDialog,
  requestedDocumentData,
  openDocumentAccessRequestDialog,
  setOpenDocumentAccessRequestDialog,
  requestedDocAccessName,
  documentAccessRequestorSP,
  acceptDocumentAccessRequest,
  openDocumetDialog,
  setOpenDocumetDialog,
  documentItem,
  setDocumentItem,
  downloadUrl,
  docCategoryId,
  docNameForDownload,
  docUserId,
  docAccessRequestedData,
  openPlanUpgradeMsgDialog,
  setOpenPlanUpgradeMsgDialog,
}) {
  let history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    getAllPendingActions(0, 5); //page size and page number
  }, []);

  const getRedirectionUrl = () => {
    if (!isOnboardingInComplete(userInfo)) {
      if (userInfo.userType === HOME_BUYER_USER) {
        history.push("/homebuyer/events?tabIndex=1");
      } else {
        if (isMarketplacePlan(userInfo)) {
          setOpenPlanUpgradeMsgDialog(true);
        } else {
          history.push("/serviceprovider/events?tabIndex=1");
        }
      }
    }
  };

  return (
    <React.Fragment>
      <DocumentPreviewDialog
        openDocumetDialog={openDocumetDialog}
        setOpenDocumetDialog={setOpenDocumetDialog}
        documentItem={documentItem}
        setDocumentItem={setDocumentItem}
        downloadUrl={downloadUrl}
        source="review-document"
        docCategoryId={docCategoryId}
        docNameForDownload={docNameForDownload}
        docUserId={docUserId}
      />
      <RecommendedSPDialog
        openRecommendedSPDialog={openRecommendedSPDialog}
        setOpenRecommendedSPDialog={setOpenRecommendedSPDialog}
        recommendedSpData={recommendedSpData}
      />
      <DocumentAccessRequestDialog
        openDocumentAccessRequestDialog={openDocumentAccessRequestDialog}
        setOpenDocumentAccessRequestDialog={setOpenDocumentAccessRequestDialog}
        requestedDocAccessName={requestedDocAccessName}
        documentAccessRequestorSP={documentAccessRequestorSP}
        acceptDocumentAccessRequest={acceptDocumentAccessRequest}
        docAccessRequestedData={docAccessRequestedData}
      />
      <RequestedDocumentDialog
        openRequestedDocumentDialog={openRequestedDocumentDialog}
        setOpenRequestedDocumentDialog={setOpenRequestedDocumentDialog}
        requestedDocumentData={requestedDocumentData}
        deletePendingAction={deletePendingAction}
      />
      <AddReviewDialog
        openAddReviewDialog={openAddReviewDialog}
        setOpenAddReviewDialog={setOpenAddReviewDialog}
      />
      <SelectHomeBuyingJourneyDialog
        openHomeBuyingJourneyDialog={openHomeBuyingJourneyDialog}
        setOpenHomeBuyingJourneyDialog={setOpenHomeBuyingJourneyDialog}
      />
      <AddMemberDialog
        openAddMemberDialog={openAddMemberDialog}
        setOpenAddMemberDialog={setOpenAddMemberDialog}
      />
      <InviteSPDialog
        openInviteSPDialog={openInviteSPDialogForPendingAction}
        setOpenInviteSPDialog={setOpenInviteSPDialogForPendingAction}
      />
      <AddTemplate
        openAddTemplate={openAddTemplate}
        setOpenAddTemplate={setOpenAddTemplate}
        updateIntercomStatus={updateIntercomStatus}
      />
      <ViewInvoiceDialog
        openViewInvoiceDialog={openViewInvoiceDialog}
        setOpenViewInvoiceDialog={setOpenViewInvoiceDialog}
        viewInvoiceDialogData={viewInvoiceDialogData}
        userInfo={userInfo}
      />
      <ViewTagDialog
        openViewTagDialog={openViewTagDialog}
        setOpenViewTagDialog={setOpenViewTagDialog}
        viewTagDialogData={viewTagDialogData}
      />
      <SignDocumentDialog
        openSignDocumentDialog={openSignDocumentDialog}
        setOpenSignDocumentDialog={setOpenSignDocumentDialog}
        signDocumentItem={signDocumentItem}
        setSignDocumentItem={setSignDocumentItem}
        reloadPendingActionData={reloadPendingActionData}
      />
      {pendingActionsList && pendingActionsList.length ? (
        <TableContainer
          component={Paper}
          elevation={0}
          className="hideScroll"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Table
            aria-label="collapsible table"
            stickyHeader
            style={{
              padding: "8px 8px 32px 8px",
            }}
          >
            <TableBody>
              {pendingActionsList.map((data, index) => (
                <TableRow key={data.pendingActionId}>
                  <TableCell style={{ padding: "8px 0px" }}>
                    <Grid container>
                      <Grid item xs={2}>
                        <Avatar
                          style={{ height: 30, width: 30 }}
                          src={getImgSrc(data)}
                        >
                          S
                        </Avatar>
                      </Grid>
                      <Grid container item xs={10} style={{ paddingLeft: 10 }}>
                        <Grid item xs={12}>
                          <Typography
                            className={Styles.navyBlueColor}
                            style={{
                              fontWeight: 600,
                              fontSize: isMobileDevice() || xlUp ? 14 : 11,
                            }}
                          >
                            {data.dueDate
                              ? moment(data.dueDate).format("MMM DD,YYYY")
                              : null}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            style={{
                              fontSize: isMobileDevice() || xlUp ? 14 : 11,
                            }}
                            className={Styles.navyBlueColor}
                          >
                            {getClientColumnValue(data)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {data.pendingAction === "UPLOAD_DOCUMENT_TEMPLATE" &&
                          userInfo.signNowAccountCreated ? (
                            <div>
                              <input
                                style={{ display: "none" }}
                                id="contained-button-file-temp"
                                multiple
                                type="file"
                                onChange={(event) =>
                                  onUploadTemplateFileChange(event)
                                }
                              />
                              <label htmlFor="contained-button-file-temp">
                                <Link
                                  underline="always"
                                  className={Styles.navyBlueColorForce}
                                  style={{
                                    fontSize:
                                      isMobileDevice() || xlUp ? 14 : 11,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if (isMarketplacePlan(userInfo)) {
                                      document.getElementById(
                                        "contained-button-file-temp"
                                      ).disabled = true;
                                      setOpenPlanUpgradeMsgDialog(true);
                                    }
                                  }}
                                >
                                  {data.eventDescription}
                                </Link>
                              </label>
                            </div>
                          ) : (
                            <Link
                              underline="always"
                              className={Styles.navyBlueColorForce}
                              style={{
                                fontSize: isMobileDevice() || xlUp ? 14 : 11,
                                cursor: isOnboardingInComplete(userInfo)
                                  ? "default"
                                  : "pointer",
                              }}
                              onClick={() => {
                                if (!isOnboardingInComplete(userInfo)) {
                                  if (isMarketplacePlan(userInfo)) {
                                    setOpenPlanUpgradeMsgDialog(true);
                                  } else {
                                    if (
                                      data.pendingAction === "SIGN_DOCUMENT" ||
                                      data.pendingAction ===
                                        "UPLOAD_DOCUMENT_TEMPLATE"
                                    ) {
                                      if (userInfo.signNowAccountCreated) {
                                        handlePendingAction(data);
                                        setEventIndex(data.pendingActionId);
                                      } else {
                                        dispatch({
                                          type: "SET_SERVER_ERROR",
                                          error: {
                                            message: SP_SIGN_NOW_ACCOUNT_EXIST,
                                            title:
                                              "Existing SignNow Account Error",
                                          },
                                        });
                                      }
                                    } else {
                                      handlePendingAction(data);
                                      setEventIndex(data.pendingActionId);
                                    }
                                  }
                                }
                              }}
                            >
                              {data.eventDescription}
                            </Link>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell
                    style={{
                      padding: xlUp ? 16 : "8px 0px",
                      cursor: isOnboardingInComplete(userInfo)
                        ? "default"
                        : "pointer",
                    }}
                  >
                    <CancelOutlinedIcon
                      className={Styles.navyBlueColor}
                      style={{ fontSize: 16 }}
                      onClick={() => {
                        if (!isOnboardingInComplete(userInfo)) {
                          if (isMarketplacePlan(userInfo)) {
                            setOpenPlanUpgradeMsgDialog(true);
                          } else {
                            deletePendingAction(data.pendingActionId);
                          }
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {pendingActionsList && pendingActionsList.length ? (
            <div
              style={{
                position: "sticky",
                alignItems: "flex-end",
                bottom: 0,
                width: "100%",
              }}
            >
              <AppBar
                position="sticky"
                style={{
                  top: "auto",
                  bottom: 0,
                  backgroundColor: "#FFFFFF",
                  color: "#FA7E61",
                  height: 48,
                }}
              >
                <Grid
                  container
                  item
                  justifyContent="flex-end"
                  alignContent="center"
                  style={{ paddingRight: xlUp ? "7%" : "5%", height: "100%" }}
                >
                  <ButtonBase
                    onClick={() => {
                      if (!isOnboardingInComplete(userInfo)) {
                        if (userInfo.userType === HOME_BUYER_USER) {
                          history.push("/homebuyer/events?tabIndex=1");
                        } else {
                          if (isMarketplacePlan(userInfo)) {
                            setOpenPlanUpgradeMsgDialog(true);
                          } else {
                            history.push("/serviceprovider/events?tabIndex=1");
                          }
                        }
                      }
                    }}
                  >
                    <Typography style={{ fontSize: xlUp ? 14 : 12 }}>
                      View All
                    </Typography>
                  </ButtonBase>
                </Grid>
              </AppBar>
            </div>
          ) : null}
        </TableContainer>
      ) : (
        <NoDataAvailable>
          <Typography
            style={{
              color: "#868DA6",
              fontSize: 11,
              marginTop: -60,
              padding: "0px 24px",
            }}
          >
            Looks like you don’t have any pending actions. Click{" "}
            <Link
              style={{ cursor: "pointer" }}
              onClick={() => getRedirectionUrl()}
            >
              here
            </Link>{" "}
            to view your recent activities
          </Typography>
        </NoDataAvailable>
      )}
    </React.Fragment>
  );
}
