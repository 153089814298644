import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  DELETE_LOCATION_SERVED_CITY,
  ADD_LOCATION_SERVED,
  CLEAR_LOCATION_SERVED,
} from "../souqh-redux/reducers/actionConstants";
import { useProvinces } from "souqh-react-redux-hooks/useProvinces";
import { useActions } from "./useActions";
import { find, sortBy } from "lodash";

const locationServedActionCreators = {
  saveLocationServed: (payload) => ({ type: ADD_LOCATION_SERVED, payload }),
  deleteCity: (payload) => ({ type: DELETE_LOCATION_SERVED_CITY, payload }),
  clearLocationServed: () => ({ type: CLEAR_LOCATION_SERVED, payload: null }),
};

export const useLocationServed = () => {
  const locationsServed = useSelector((state) => state.spSetup.locationsServed);
  const actions = useActions(locationServedActionCreators);
  const { provinces } = useProvinces(1, true); // FIXME get country id from logged in user

  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedCities, setSelectedCities] = useState([]);
  const [displayCities, setDisplayCities] = useState([]);

  useEffect(() => {
    if (selectedProvince) {
      //sort cities to get All Cities option at top
      let cities = sortBy(selectedProvince.cityDTO, (city) =>
        city.name === "All Cities" ? 0 : 1
      );
      setDisplayCities([...cities]);
      setSelectedCities([]);
    } else {
      setSelectedCities([]);
    }
  }, [selectedProvince]);

  useEffect(() => {
    if (selectedCities.length) {
      const found = find(selectedCities, (c) => c.name === "All Cities");
      if (found && selectedCities.length > 1) {
        setSelectedCities([found]);
      }
    }
  }, [selectedCities]);

  const addLocation = () => {
    let location = {
      province: selectedProvince,
      cities: selectedCities,
    };
    actions.saveLocationServed(location);
  };

  return {
    provinces,
    locationsServed,
    selectedProvince,
    selectedCities,
    displayCities,
    setSelectedProvince,
    setSelectedCities,
    setDisplayCities,
    addLocation,
    actions,
  };
};
