import { Grid, Typography, Button, Divider, Switch } from "@material-ui/core";
import React, { useEffect } from "react";
import { useReviews } from "souqh-react-redux-hooks/serviceProvider/storefront/useReviews";
import Review from "./Review";
import AddIcon from "@material-ui/icons/Add";
import { PaperLikeGrid } from "./ServiceList";
import Styles from "../../../Styles.module.css";

function ExistingReviews() {
  const {
    reviewsMaxLimit,
    setReviewsMaxLimit,
    reviews,
    allowAction,
    externalReviewSupport,
    actions: { addReview, removeReview, setExternalReviewSupport },
  } = useReviews();

  return (
    <Grid container item xs={12}>
      <PaperLikeGrid style={{ padding: 24, marginTop: 20 }}>
        <Grid item container xs={10}>
          <Typography variant="subtitle1" className={Styles.navyBlueColor}>
            Do you want to add references to external review sources?
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={2}
          justifyContent="flex-end"
          style={{ marginTop: "-5px" }}
        >
          <Switch
            className={"sqGraySwitch"}
            checked={externalReviewSupport}
            onChange={(e) => {
              setExternalReviewSupport({ check: e.target.checked });
            }}
          ></Switch>
        </Grid>
        {externalReviewSupport && reviews && reviews.length
          ? reviews.map((review, index) => {
              return (
                <Review
                  key={index}
                  {...review}
                  index={index}
                  removeReview={removeReview}
                ></Review>
              );
            })
          : ""}
        {externalReviewSupport && (
          <Grid container>
            <Divider
              orientation="horizontal"
              variant="fullWidth"
              style={{ width: "100%", marginTop: 20, marginBottom: 15 }}
            />
          </Grid>
        )}
        <Grid container>
          <Grid item container>
            {reviews.length === 5 && reviewsMaxLimit && (
              <Typography style={{ color: "red" }}>
                Maximum of 5 reviews sources allowed
              </Typography>
            )}
          </Grid>
          {externalReviewSupport && (
            <Grid item container justifyContent="flex-end">
              <Button
                disabled={!allowAction}
                variant="outlined"
                style={{
                  color: allowAction ? "#0E1C4E" : "lightgray",
                  textTransform: "capitalize",
                }}
                startIcon={<AddIcon />}
                onClick={() => {
                  if (reviews.length === 5) {
                    setReviewsMaxLimit(true);
                  } else {
                    setReviewsMaxLimit(false);
                    addReview();
                  }
                }}
              >
                Add
              </Button>
            </Grid>
          )}
        </Grid>
      </PaperLikeGrid>
    </Grid>
  );
}

export default ExistingReviews;
