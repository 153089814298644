import React, { useEffect, useState } from "react";
import { Grid, Typography, Container, Button } from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import TimeBudget, { AddOn } from "./TimeBudget";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ForecastedResult from "./ForecastedResult";
import moment from "moment";
import Promocode from "../../SPSetup/PaymentAndInvoice/Promocode";

function NewCampaignStep2({
  section,
  campaignData,
  setStep1Data,
  resetCampaignStartDate,
  selelctedRow,
  dropDownValues,
}) {
  const [startDateError, setStartDateError] = useState(null);

  useEffect(() => {
    if (!campaignData.campaignStartDate) {
      setStartDateError("Please select start date");
    } else {
      setStartDateError(null);
    }
  }, [campaignData]);

  return (
    <React.Fragment>
      <Grid
        container
        item
        xs={12}
        className="hideScroll"
        style={{
          marginTop: isMobileDevice() || isTabletDevice() ? 16 : 60,
          minHeight: (isMobileDevice() || isTabletDevice()) && "40rem",
          overflow: (isMobileDevice() || isTabletDevice()) && "hidden",
        }}
      >
        <Grid
          container
          item
          xs={12}
          lg={8}
          style={{
            height: (isMobileDevice() || isTabletDevice()) && 430,
            overflow: (isMobileDevice() || isTabletDevice()) && "scroll",
          }}
        >
          <Container maxWidth="md">
            <Grid container item xs={12}>
              <Typography
                variant="subtitle1"
                className={Styles.navyBlueColor}
                style={{
                  marginTop: !isMobileDevice() && !isTabletDevice() && 16,
                  marginBottom: 24,
                }}
              >
                Select Time period & Budget
              </Typography>
              <Grid container item xs={12} spacing={1}>
                {dropDownValues &&
                  dropDownValues.marketingCampaignPlanList &&
                  dropDownValues.marketingCampaignPlanList.map(
                    (item, index) => (
                      <Grid
                        key={index}
                        container
                        item
                        xs={12}
                        md={6}
                        onClick={() => {
                          item.selected = true;
                          setStep1Data({
                            field: "campaignPlanId",
                            value: item,
                          });
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <TimeBudget data={item} />
                      </Grid>
                    )
                  )}
              </Grid>
              <AddOn setStep1Data={setStep1Data} campaignData={campaignData} />
              <Grid container item xs={12} style={{ marginTop: 20 }}>
                <Promocode page="marketingCampaign" isCampaign={true} />
              </Grid>
              <Grid container item xs={12} style={{ marginTop: 20 }}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    className={Styles.navyBlueColor}
                    style={{ marginBottom: 10 }}
                  >
                    Set Dates*
                  </Typography>
                </Grid>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    error={startDateError}
                    helperText={startDateError}
                    disableToolbar
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    id="date-picker-inline"
                    label="Start Date"
                    required
                    autoOk={true}
                    value={
                      campaignData && campaignData.campaignStartDate
                        ? moment(
                            new Date(campaignData.campaignStartDate)
                          ).format("MMM DD, YYYY")
                        : null
                    }
                    onChange={(event) => {
                      setStep1Data({
                        field: "campaignStartDate",
                        value: moment(event).format(),
                      });
                    }}
                    minDate={moment()}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    keyboardIcon={
                      <DateRangeIcon
                        style={{
                          fontSize: 20,
                          fill: "#1B1E4C",
                        }}
                      />
                    }
                    InputAdornmentProps={{
                      position: "start",
                    }}
                  />
                </MuiPickersUtilsProvider>
                <Button
                  className={Styles.primaryTxtAccentForce}
                  style={{
                    marginBottom: 10,
                    cursor: "pointer",
                    textAlign: "center",
                    margin: 10,
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    resetCampaignStartDate();
                  }}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <ForecastedResult section={section} campaignData={campaignData} />
      </Grid>
    </React.Fragment>
  );
}

export default NewCampaignStep2;
