import { cloneDeep } from "lodash";
import { remove_AndFormValidString } from "../../web/src/utils/AppUtils";
import {
  SET_ALL_MARKETING_CAMPAIGN_DATA,
  SET_CAMPAIGN_STEP1_DATA,
  CLEAR_CAMPAIGN_DATA,
  SET_DROPDOWN_VALUES,
  RESET_CAMPAIGN_START_DATE,
  CLEAR_LOCATION_SERVED,
  SET_UPDATE_MARKETING_CAMPAIGN_DATA,
  SET_CAMPAIGN_INVOCIE_DATA,
  SET_CAMPAIGN_ANALYTICS_DATA,
} from "./actionConstants";
import moment from "moment";

const initialState = {
  allMarketingCampaignData: [],
  dropDownValues: {},

  campaignData: {
    id: null,
    name: "",
    province: [],
    cityIds: [],
    campaignUserType: "",
    campaignStage: [],
    ageRange: null,
    maritalStatus: null,
    employmentStatus: null,
    houseHoldIncomeRange: null,
    propertyType: null,
    industry: null,
    addOnHomePage: false,
    campaignEndDate: "",
    campaignPlanId: {
      id: 3,
      days: 30,
      duration: "1 Month",
      description: "MOST POPULAR",
      amount: 99,
      selected: true,
      addOnAmount: 15,
    },
    campaignStartDate: moment(new Date()),
    addOnValue: 15,
    forcastedResult: 0,
    marketingRegion: [],
  },
  analytics: [],
};

const marketingCampaign = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_ALL_MARKETING_CAMPAIGN_DATA:
      newState = cloneDeep(state);
      newState.allMarketingCampaignData = action.payload;
      return newState;
    case CLEAR_CAMPAIGN_DATA:
      newState = cloneDeep(state);
      if (Object.keys(newState.dropDownValues)) {
        const { userTypes, journeyStatusList, marketingCampaignPlanList } =
          newState.dropDownValues;
        newState.dropDownValues.userTypes =
          userTypes &&
          userTypes.map((ele) => {
            return { ...ele, selected: false };
          });
        newState.dropDownValues.journeyStatusList =
          journeyStatusList &&
          journeyStatusList.map((ele) => {
            return { ...ele, selected: false };
          });
        newState.dropDownValues.marketingCampaignPlanList =
          marketingCampaignPlanList &&
          marketingCampaignPlanList.map((ele) => {
            return {
              ...ele,
              selected:
                ele.id === initialState.campaignData.campaignPlanId.id
                  ? true
                  : false,
            };
          });
      }
      newState.campaignData = initialState.campaignData;
      return newState;
    case SET_CAMPAIGN_STEP1_DATA:
      newState = cloneDeep(state);
      const { field, value } = action.payload;
      if (field === "campaignPlanId") {
        newState.dropDownValues.marketingCampaignPlanList.map(
          (ele) => (ele.selected = false)
        );
        newState.campaignData[field] = value;
        newState.dropDownValues.marketingCampaignPlanList.find(
          (ele) => ele.id === value.id
        ).selected = true;
        const { amount } = newState.campaignData.campaignPlanId;
        newState.campaignData.addOnValue = Math.round(amount * 0.15);
      } else if (field === "campaignStartDate" && value === "Invalid date") {
        newState.campaignData.campaignStartDate = null;
      } else {
        newState.campaignData[field] = value;
      }
      return newState;
    case SET_DROPDOWN_VALUES:
      newState = cloneDeep(state);
      action.payload.journeyStatusList &&
        action.payload.journeyStatusList.length &&
        action.payload.journeyStatusList.map(
          (ele) => (ele.value = remove_AndFormValidString(ele.name))
        );
      newState.dropDownValues = {
        ...action.payload,
        userTypes: action.payload.userTypeList,
      };
      return newState;
    case RESET_CAMPAIGN_START_DATE:
      newState = cloneDeep(state);
      newState.campaignData.campaignStartDate = null;
      return newState;
    case CLEAR_LOCATION_SERVED:
      newState = cloneDeep(state);
      newState.locationsServed = initialState.locationsServed;
      return newState;
    case SET_UPDATE_MARKETING_CAMPAIGN_DATA:
      newState = cloneDeep(state);
      const userTypeIDs = action.payload.campaignUserType.map(
        (item) => item.id
      );

      const updatedJourneyList = newState.dropDownValues.journeyStatusList.map(
        (stage) => ({
          ...stage,
          selected: action.payload.journeyStatusIds.includes(stage.id),
        })
      );

      const updatedUserTypes = newState.dropDownValues.userTypes.map(
        (userType) => ({
          ...userType,
          selected: userTypeIDs.includes(userType.id),
        })
      );
      const testArray =
        newState.dropDownValues.marketingCampaignPlanList &&
        newState.dropDownValues.marketingCampaignPlanList.map((item) => {
          if (action.payload.campaignPlanId === item.id) {
            return { ...item, selected: true };
          } else {
            return { ...item, selected: false };
          }
        });
      newState.campaignData = action.payload;
      const selectedPlan = testArray.filter((ele) => ele.selected);
      newState.campaignData.campaignPlanId = selectedPlan[0];
      newState.campaignData.addOnValue = Math.round(
        selectedPlan[0].amount * 0.15
      );
      newState.dropDownValues.marketingCampaignPlanList = testArray;
      newState.dropDownValues.userTypes = updatedUserTypes;
      newState.dropDownValues.journeyStatusList = updatedJourneyList;
      return newState;
    case SET_CAMPAIGN_INVOCIE_DATA:
      newState = cloneDeep(state);
      newState.campaignData.totalCost = action.payload;
      return newState;
    case SET_CAMPAIGN_ANALYTICS_DATA:
      newState = cloneDeep(state);
      newState.analytics = action.payload;
      return newState;
    default:
      return state;
  }
};

export default marketingCampaign;
