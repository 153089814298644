import { useState, useEffect } from "react";
import { useAuthUser } from "../../useLogin";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_SP_SELECTED_CATEGORY,
  SET_SP_SELECTED_CONTACTS,
  SET_SP_SELECTED_ACTIVE_SP,
  ADD_SP_DOCUMENT_SUMMARY,
  DELETE_SP_DOCUMENT_SUMMARY,
  CLEAR_SP_ESIGN_UPLOAD_FILE_DATA,
  UPDATE_SP_ESIGN_DOCUMENT_DESCRIPTION,
  EDIT_SP_DOCUMENT_SUMMARY,
  ADD_SP_ESIGN_DOCUMENTS,
  SET_SP_DOCUMENT_SUMMARY,
  SET_WARNING_DIALOG_FLAG,
  SET_EDIT_ESIGN_DOCUMENT,
  SET_OPEN_ESIGN_DOCUMENT,
  SET_EDIT_WARNING_DIALOG,
  CLEAN_ESIGN_DATA_AND_CLOSE,
} from "../../../souqh-redux/reducers/actionConstants";
import { useActions } from "../../useActions";
import { cloneDeep } from "lodash";
import {
  EMAIL_PATTERN,
  SERVICE_PROVIDER_USER,
  WAITING_FOR_OTHER,
} from "../../../web/src/components/ServiceProvider/serviceProviderConstants";
import { useApiClient } from "../../useApiClient";
import * as Stomp from "stompjs";
import * as SockJS from "sockjs-client";
import { useBusinessType } from "../../useBusinessType";
import { isMarketplacePlan } from "../../../web/src/utils/AppUtils";

const actionCreator = {
  setSelectedCategory: (payload) => ({
    type: SET_SP_SELECTED_CATEGORY,
    payload,
  }),
  setSelectedContacts: (payload) => ({
    type: SET_SP_SELECTED_CONTACTS,
    payload,
  }),
  setSelectedActiveSPs: (payload) => ({
    type: SET_SP_SELECTED_ACTIVE_SP,
    payload,
  }),
  cleanUploadFileDataOnClose: () => ({
    type: CLEAR_SP_ESIGN_UPLOAD_FILE_DATA,
    payload: [],
  }),
  cleanEsignDataAndClose: () => ({
    type: CLEAN_ESIGN_DATA_AND_CLOSE,
    payload: [],
  }),
  updateDescription: (index, value) => ({
    type: UPDATE_SP_ESIGN_DOCUMENT_DESCRIPTION,
    payload: { index, value },
  }),
  addDocumentSummary: (summary) => ({
    type: ADD_SP_DOCUMENT_SUMMARY,
    payload: { summary },
  }),
  deleteDocumentSummary: (index) => ({
    type: DELETE_SP_DOCUMENT_SUMMARY,
    payload: { index },
  }),
  editDocumentSummary: (data, index) => ({
    type: EDIT_SP_DOCUMENT_SUMMARY,
    payload: { data, index },
  }),
  setDocumentSummaryList: (summaryList) => ({
    type: SET_SP_DOCUMENT_SUMMARY,
    payload: summaryList,
  }),
  setOpenEditEsignDocumentDialog: (openEditEsignDocumentDialog) => ({
    type: SET_EDIT_ESIGN_DOCUMENT,
    payload: { openEditEsignDocumentDialog },
  }),
  setOpenEsignWizard: (openEsignWizard) => ({
    type: SET_OPEN_ESIGN_DOCUMENT,
    payload: { openEsignWizard },
  }),
  setOpenEditWarningDialog: (openEditWarningDialog) => ({
    type: SET_EDIT_WARNING_DIALOG,
    payload: { openEditWarningDialog },
  }),
};

export const useSPeSignWizard = (
  getTabWiseCategoriesList,
  setDocumentTabName,
  documentTabName
) => {
  const { apiClient } = useApiClient();
  // const { businessTypes } = useBusinessType();

  const [activeEsignSection, setActiveEsignSection] = useState(1);
  const [openUploadCancelDialog, setOpenUploadCancelDialog] = useState(false);
  const userInfo = useAuthUser();
  const dispatch = useDispatch();
  const actions = useActions(actionCreator);
  const [openAddSummaryDialog, setOpenAddSummaryDialog] = useState(false);
  const [openAddContactDialog, setAddContactDialog] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedContact, setSelectedContact] = useState(null);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [sharedWithSP, setSharedWithSP] = useState(false);
  const [summaryTobeEdited, setSummaryTobeEdited] = useState(null);
  const [documentsTagged, setDocumentsTagged] = useState([]);
  const [selectedTaggedDoucment, setSelectedTaggedDoucment] = useState("");
  const [selectedDocumentForSummary, setSelectedDocumentForSummary] =
    useState("");
  const [contacts, setContacts] = useState([]);
  const [SPContacts, setSPContacts] = useState([]);

  const [associactedServiceProviders, setAssociactedServiceProviders] =
    useState([]);

  const [openCustomEmailMessageDialog, setOpenCustomEmailMessageDialog] =
    useState(false);
  const [customEmailTitle, setCustomEmailTitle] = useState("");
  const [customEmailMessage, setCustomEmailMessage] = useState("");
  const [defaultEmailTitle, setDefaultEmailTitle] = useState("");
  const [defaultEmailMessage, setDefaultEmailMessage] = useState("");
  const [signRoles, setSignRoles] = useState([]);
  const [signOrders, setSignOrders] = useState([]);
  const [signOrdersData, setSignOrdersData] = useState([]);
  const [dataForInvite, setDataForInvite] = useState(null);
  const [disableSkipBtn, setDisableSkipBtn] = useState(false);
  const [openConfirmGoBackDialog, setConfirmGoBackDialog] = useState(false);
  const [primaryApplicantList, setPrimaryApplicantList] = useState([]);
  const showDialogFlag = useSelector(
    (state) => state.spDocument.showBackWarningDialog
  );

  const openEditEsignDocumentDialog = useSelector(
    (state) => state.spDocument.openEditEsignDocumentDialog
  );

  const openEditWarningDialog = useSelector(
    (state) => state.spDocument.openEditWarningDialog
  );

  const openEsignWizard = useSelector(
    (state) => state.spDocument.openEsignWizard
  );

  const summaryList = useSelector((state) => state.spDocument.summaryList);

  const selectedContacts = useSelector(
    (state) => state.spDocument.selectedContacts
  );
  const duplicateFileName = useSelector(
    (state) => state.spDocument.duplicateFileName
  );
  const allReferenceDocuments = useSelector(
    (state) => state.spDocument.allReferenceDocuments
  );

  const eSignDocumentList = useSelector(
    (state) => state.spDocument.eSignDocumentList
  );

  const [webSocket, setWebSocket] = useState(null);
  const [stompClient, setStompClient] = useState(null);
  const [eventType, setEventType] = useState(null);

  const connectToSocket = () => {
    const authToken = userInfo.access_token;
    if (authToken) {
      try {
        const url =
          process.env.REACT_APP_SOUQH_API_SERVER_BASE_URL + "/ws-notifications";
        const tempWebSocket = new SockJS(url);
        setWebSocket(tempWebSocket);
        let tempClient = Stomp.over(tempWebSocket);
        tempClient.debug = null;
        setStompClient(tempClient);
        tempClient.connect({ Authorization: authToken }, (frame) => {
          tempClient.subscribe("/queue/private", (response) => {
            setEventType(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  const processListener = () => {
    if (eventType && eventType.body) {
      const body = JSON.parse(eventType.body);
      if (documentsTagged.length === 1) {
        if (
          body &&
          body.documentId === documentsTagged[0].signNowDocumentId &&
          body.event === "role_updated"
        ) {
          setEventType(null);
          validateTaggedDocument();
        }
      } else if (documentsTagged.length > 1) {
        const tempId = body.documentId;
        if (tempId) {
          const foundIndex = documentsTagged.findIndex(
            (item) => item.signNowDocumentId === tempId
          );
          if (foundIndex > -1) {
            if (foundIndex === documentsTagged.length - 1) {
              validateTaggedDocument();
            } else {
              const newItem = documentsTagged[foundIndex + 1];
              if (newItem) {
                setSelectedTaggedDoucment(newItem);
              }
            }
          }
        }
      }
    }
  };

  const disconnectFromSocket = () => {
    if (stompClient !== null) {
      stompClient.disconnect();
      setStompClient(null);
      setWebSocket(null);
    }
  };

  const getContacts = () => {
    if (userInfo && userInfo.userType === SERVICE_PROVIDER_USER) {
      const url = `service-provider/${userInfo.serviceProviderId}/home-buyer`;
      apiClient
        .get(url)
        .then((response) => {
          if (response.data.status === 200) {
            setContacts(response.data.result || []);
            const mappedData = (response.data.result || []).map(
              (item) => item.primaryApplicantEmail
            );
            setPrimaryApplicantList(mappedData);
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    }
  };

  const updateSignOrderChange = (value, index) => {
    const tempData = cloneDeep(signOrdersData);
    const findItem = tempData[index];
    if (findItem) {
      findItem.order = value ? value : "";
    }
    setSignOrdersData(tempData);
  };

  const handleAssocSPSelect = (value, index) => {
    const tempData = cloneDeep(associactedServiceProviders);
    const findItem = tempData[index];
    if (findItem) {
      findItem.selected = value;
    }
    setAssociactedServiceProviders(tempData);
  };

  const filterContacts = () => {
    const txt = (searchText || "").toLowerCase().trim();
    setFilteredContacts(
      contacts.filter(
        (item) =>
          (item.primaryApplicantEmail || "").toLowerCase().indexOf(txt) >= 0 ||
          (item.name || "").toLowerCase().indexOf(txt) >= 0
      )
    );
  };

  const checkForDocumentDescriptions = () => {
    let flag = false;
    eSignDocumentList.forEach((item, index) => {
      if ((item.description || "").trim().length < 1) {
        flag = true;
        actions.updateDescription(index, "");
      }
    });
    if (!flag) {
      uploadDocumentsForTagging();
    }
  };

  const isAllDataFilled = () => {
    if (signOrdersData.length) {
      const flag = signOrdersData.every(
        (item) =>
          item.email &&
          EMAIL_PATTERN.test(item.email) &&
          item.order &&
          item.role
      );
      if (documentsTagged.length > 1) {
        let duplicateCount = 0;
        signOrdersData.forEach((item) => {
          if (
            signOrdersData.filter((elem) => elem.order === item.order).length >
            1
          ) {
            duplicateCount++;
          }
        });
        if (duplicateCount > 0) {
          return false;
        }
      }

      return flag;
    }
    return false;
  };

  const getSPContacts = () => {
    return apiClient
      .get("/home-buyer/contacts/other-sp")
      .then(function (response) {
        const mappedData = (response.data.result || []).map(
          (item) => item.email
        );
        setSPContacts(mappedData);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const updateEmailTouchedState = (status, index) => {
    const tempData = cloneDeep(signOrdersData);
    const findItem = tempData[index];
    if (findItem) {
      findItem.touchedEmail = status;
      if (EMAIL_PATTERN.test(findItem.email)) {
        findItem.isValidEmail = true;
      } else {
        findItem.isValidEmail = false;
      }
    }
    setSignOrdersData(tempData);
  };

  const updateEmailValue = (value, index) => {
    const tempData = cloneDeep(signOrdersData);
    const findItem = tempData[index];
    if (findItem) {
      findItem.email = value;
      if (EMAIL_PATTERN.test(findItem.email)) {
        findItem.isValidEmail = true;
      } else {
        findItem.isValidEmail = false;
      }
    }
    setSignOrdersData(tempData);
  };

  const onUploadEsignFileChange = (event) => {
    processEsignUploadedFiles(event.target.files, event);
  };

  const processEsignUploadedFiles = (files, event) => {
    let finalFilesarray = [];
    Object.keys(files).forEach((key) => {
      finalFilesarray.push({
        fileName: files[key].name,
        file: files[key],
        isRef: false,
        description: "",
        hasUpdatedDescription: false,
      });
    });
    let compareArray = [...eSignDocumentList, ...finalFilesarray];
    var valueArr = compareArray.map(function (item) {
      return item.fileName;
    });
    var isDuplicate = valueArr.some((item, index) => {
      return valueArr.indexOf(item) != index;
    });
    dispatch({
      type: ADD_SP_ESIGN_DOCUMENTS,
      payload: { files: finalFilesarray, isDuplicate },
    });
    if (event && event.target) {
      event.target.value = "";
    }
    if (!isMarketplacePlan(userInfo)) {
      actions.setOpenEsignWizard(true);
    }
  };

  const checkAndAddSummary = (summary, isEdit, callback) => {
    setDisableSkipBtn(true);
    const currentTitle = (summary.title || "").toLowerCase();
    const duplicateIndex = summaryList.findIndex(
      (item) => (item.title || "").trim().toLowerCase() === currentTitle
    );
    const data = {
      title: summary.title,
      subtitle: summary.subtitle,
      description: summary.description,
      id: summary.id || null,
    };
    if (isEdit) {
      if (duplicateIndex === -1 || duplicateIndex === summary.index) {
        saveSummaryToBackend(data, summary.index, callback);
        return;
      }
    } else {
      if (duplicateIndex === -1) {
        saveSummaryToBackend(data, null, callback);
        return;
      }
    }
    callback(true);
  };

  const deleteSummary = (id) => {
    dispatch({ type: "SET_LOADING", value: true });
    const url = `/signnow/document/summary/${id}`;
    apiClient
      .delete(url)
      .then((response) => {
        if (response.data.status === 200) {
          const savedData = response.data.result || [];
          actions.setDocumentSummaryList(savedData);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const createContactItem = (contact, callback) => {
    const contactEmail = (contact.email || "").trim().toLowerCase();
    const isDuplicate = contacts.some(
      (item) => (item.email || "").trim().toLowerCase() === contactEmail
    );

    if (isDuplicate) {
      callback(true);
    } else {
      const tempList = cloneDeep(contacts);
      tempList.push(contact);
      setContacts(tempList);
      callback(false);
    }
  };

  const addContactItem = (serviceProvider, callback) => {
    const serviceProviderEmail = (serviceProvider.email || "")
      .trim()
      .toLowerCase();
    const isDuplicate = associactedServiceProviders.some(
      (item) => (item.email || "").trim().toLowerCase() === serviceProviderEmail
    );

    if (isDuplicate) {
      callback(true);
    } else {
      const tempList = cloneDeep(associactedServiceProviders);
      tempList.push(serviceProvider);
      setAssociactedServiceProviders(tempList);
      callback(false);
    }
  };

  const updatePrimaryAndSecondaryApplicant = () => {
    const tempData = cloneDeep(signOrdersData);
    tempData.forEach((item) => (item.email = ""));
    const primaryApplicant = tempData[0];
    if (primaryApplicant) {
      primaryApplicant.email = selectedContact.primaryApplicantEmail || "";
    }

    const coApplicant = tempData[1];
    if (coApplicant) {
      coApplicant.email = selectedContact.coApplicantEmail || "";
    }
    setSignOrdersData(tempData);
  };

  const updateShowDialogFlag = (flag) => {
    return apiClient
      .put(`/user/${userInfo.userId}/showdialog/${flag}`)
      .then(function (response) {
        dispatch({
          type: SET_WARNING_DIALOG_FLAG,
          payload: { flag: response.data.result },
        });
      });
  };

  const uploadDocumentsForTagging = () => {
    const bodyFormData = new FormData();
    const fileNameDescriptionMap = {};
    eSignDocumentList.forEach((fileData) => {
      bodyFormData.append("uploadDocs", fileData.file, fileData.file.name);
      fileNameDescriptionMap[fileData.file.name] = "";
    });
    const fileDescription = {
      fileNameDescriptionMap,
      templateName: "",
      templateDescription: "",
    };

    bodyFormData.append("fileDescription", JSON.stringify(fileDescription));
    bodyFormData.append("userId", userInfo.userId);

    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .post("/signnow/upload/documents/", bodyFormData)
      .then((response) => {
        const responseData = response.data;
        if (responseData.status === 200) {
          setActiveEsignSection(activeEsignSection + 1);
          setDocumentsTagged(responseData.result || []);
          connectToSocket();
        } else {
          dispatch({
            type: "SET_SERVER_ERROR",
            error: { message: "Something went wrong" },
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const createDocumentForEdit = (docDetails, onResult) => {
    if (!docDetails) {
      return;
    }
    const documentData = {
      documentId: docDetails.signNowDocumentId,
      userId: userInfo.userId,
    };

    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post("/signnow/edit/document", {}, { params: documentData })
      .then((res) => {
        if (res.data.status === 200) {
          setActiveEsignSection(2);
          setDocumentsTagged(res.data.result || []);
        }
        onResult && onResult(res);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const validateTaggedDocument = () => {
    const url = `/signnow/validate/documents/${userInfo.userId}`;
    dispatch({ type: "SET_LOADING", value: true });
    const body = documentsTagged.map((item) => item.signNowDocumentId);
    apiClient
      .post(url, body)
      .then((response) => {
        const responseData = response.data;
        if (responseData.status === 200) {
          setActiveEsignSection(3);
          setSelectedDocumentForSummary(documentsTagged[0]);
        } else if (responseData.status === 404) {
          dispatch({
            type: "SET_SERVER_ERROR",
            error: { message: responseData.message },
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getDocumentSummaryList = () => {
    dispatch({ type: "SET_LOADING", value: true });
    const url = `/signnow/document/summary/${
      selectedDocumentForSummary.documentId
    }?${userInfo.userId || null}`;
    apiClient
      .get(url)
      .then((response) => {
        if (response.data.status === 200) {
          actions.setDocumentSummaryList(
            response.data.result.documentSummary || []
          );
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const saveSummaryToBackend = (data, index, callback) => {
    const body = {
      description: data.description,
      id: data.id,
      subtitle: data.subtitle,
      title: data.title,
    };

    const url = `/signnow/document/summary/${selectedDocumentForSummary.documentId}`;
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .post(url, body)
      .then((response) => {
        const responseData = response.data;
        if (responseData.status === 200) {
          const savedData = response.data.result || [];
          actions.setDocumentSummaryList(savedData);
          if (savedData) {
            callback(false);
          }
        } else {
          // To Do
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getSignnowInviteData = () => {
    if (documentsTagged.length === 1) {
      getSingleDocDataForSignNow(documentsTagged[0].signNowDocumentId);
    } else if (documentsTagged.length > 1) {
      getDocumentGroupDataForSignNow();
    }
  };

  const getSingleDocDataForSignNow = (signNowDocumentId) => {
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .get("/signnow/document/invite-data", {
        params: {
          documentId: signNowDocumentId,
          userId: userInfo.userId,
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          if (response.data.result.to && response.data.result.to.length) {
            const result = response.data.result;
            setActiveEsignSection(4);
            setDataForInvite(result);
            parseAndSetRoles(result.to || []);
            setDefaultEmailTitle(result.subject || "");
            setDefaultEmailMessage(result.message || "");
            setCustomEmailTitle(result.subject || "");
            setCustomEmailMessage(result.message || "");
          } else {
            dispatch({
              type: "SET_SERVER_ERROR",
              error: { message: "No Signer has been added" },
            });
          }
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getDocumentGroupDataForSignNow = () => {
    const groupId = documentsTagged[0]
      ? documentsTagged[0].documentGroupId
      : null;

    if (groupId) {
      const url = `/signnow/documentGroup/invite/data/${groupId}/${userInfo.userId}`;
      dispatch({ type: "SET_LOADING", value: true });
      apiClient
        .get(url)
        .then((response) => {
          if (response.data.status === 200) {
            const result = response.data.result;
            setActiveEsignSection(4);
            setDataForInvite(result);
            parseAndSetGroupRoles(result.to || []);
            setDefaultEmailTitle(result.subject || "");
            setDefaultEmailMessage(result.message || "");
            setCustomEmailTitle(result.subject || "");
            setCustomEmailMessage(result.message || "");
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const inviteForEsign = (callBack) => {
    if (documentsTagged.length === 1) {
      inviteAndSendSingleDoc(callBack);
    } else if (documentsTagged.length > 1) {
      inviteAndSendMultiDoc(callBack);
    }
  };

  const inviteAndSendSingleDoc = (callBack) => {
    const selectedSP = associactedServiceProviders
      .filter((item) => item.selected === true)
      .map((item) => item.email);

    const signNowInviteBody = {
      cc: selectedSP,
      documentIds: [],
      from: dataForInvite.from,
      message: customEmailMessage,
      redirect_uri: "",
      subject: customEmailTitle,
      to: signOrdersData,
    };
    const signNowDocumentId = documentsTagged[0].signNowDocumentId;
    const url = `/signnow/${userInfo.userId}/send/${signNowDocumentId}/invite`;
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .post(url, signNowInviteBody)
      .then((response) => {
        if (response.data.status === 200) {
          callBack && callBack();
          if (documentTabName !== WAITING_FOR_OTHER) {
            setDocumentTabName && setDocumentTabName(WAITING_FOR_OTHER);
          } else {
            getTabWiseCategoriesList &&
              getTabWiseCategoriesList(WAITING_FOR_OTHER);
          }
          closeAndFinishEsign();
        } else if (response.data.status === 412) {
          if (response.data.result) {
            dispatch({
              type: "SET_SERVER_ERROR",
              error: { message: response.data.result },
            });
          }
        } else {
          dispatch({
            type: "SET_SERVER_ERROR",
            error: { message: "Something went wrong" },
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const inviteAndSendMultiDoc = (callBack) => {
    const selectedSP = associactedServiceProviders.map((item) => item.email);
    const signNowInviteBody = {
      cc: selectedSP,
      documentIds: documentsTagged.map((item) => item.signNowDocumentId),
      from: dataForInvite.from,
      message: customEmailMessage,
      redirect_uri: "",
      subject: customEmailTitle,
      to: signOrdersData,
    };
    const documentGroupId = documentsTagged[0].documentGroupId;
    const url = `/signnow/${userInfo.userId}/send/${documentGroupId}/groupInvite`;
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .post(url, signNowInviteBody)
      .then((response) => {
        if (response.data.status === 200) {
          callBack && callBack();
          if (documentTabName !== WAITING_FOR_OTHER) {
            setDocumentTabName && setDocumentTabName(WAITING_FOR_OTHER);
          } else {
            getTabWiseCategoriesList &&
              getTabWiseCategoriesList(WAITING_FOR_OTHER);
          }
          closeAndFinishEsign();
        } else if (response.data.status === 412) {
          if (response.data.result) {
            dispatch({
              type: "SET_SERVER_ERROR",
              error: { message: response.data.result },
            });
          }
        } else {
          dispatch({
            type: "SET_SERVER_ERROR",
            error: { message: "Something went wrong" },
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const deleteUploadedESignDoc = async () => {
    if (!documentsTagged || !documentsTagged.length) {
      return;
    }
    if (documentsTagged.length === 1) {
      dispatch({ type: "SET_LOADING", value: true });
      try {
        const response = await deleteEsignedDocument(
          documentsTagged[0].signNowDocumentId
        );
        if (response) {
          resetStep1();
        }
      } catch (e) {
      } finally {
        dispatch({ type: "SET_LOADING", value: false });
      }
    } else {
      dispatch({ type: "SET_LOADING", value: true });
      try {
        documentsTagged.forEach(async (item) => {
          try {
            await deleteEsignedDocument(item.signNowDocumentId);
          } catch (e) {}
        });
        resetStep1();
      } catch (e) {
      } finally {
        dispatch({ type: "SET_LOADING", value: false });
      }
    }
  };

  const deleteEsignedDocument = (signNowDocumentId) => {
    if (!signNowDocumentId) {
      return Promise.resolve(false);
    }
    const url = `/signnow/delete/document/${signNowDocumentId}/${userInfo.userId}`;
    return apiClient
      .delete(url)
      .then((res) => {
        if (res.data.status === 200) {
          return Promise.resolve(true);
        }
        return Promise.resolve(false);
      })
      .catch(function (error) {
        return Promise.resolve(false);
      });
  };

  const parseAndSetGroupRoles = (data) => {
    if (data && data.length) {
      const tempRoles = {};
      const orders = [];

      data.forEach((item, index) => {
        const label = (item.role || "").replace(/_/g, " ");
        tempRoles[item.role] = {
          role: item.role,
          label,
        };
        orders.push({
          order: index + 1 + "",
          label: "Sign Order " + (index + 1),
        });
        item.email = item.email || "";
        item.order = item.order || "";
        if (EMAIL_PATTERN.test(item.email)) {
          item.isValidEmail = true;
        } else {
          item.isValidEmail = false;
        }
        item.touchedEmail = false;

        const words = label.split(" ");
        for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }
        item.roleLabel = words.join(" ");
      });
      setSignOrdersData(data);
      const roles = [];
      Object.keys(tempRoles).forEach((item) => {
        roles.push(tempRoles[item]);
      });
      setSignRoles(roles);
      setSignOrders(orders);
    } else {
      setSignOrdersData([]);
      setSignOrders([]);
      setSignRoles([]);
    }
  };

  const parseAndSetRoles = (data) => {
    if (data && data.length) {
      const tempRoles = {};
      const tempOrders = {};
      const tempEmails = [];
      const orders = [];
      data.forEach((item, index) => {
        const label = (item.role || "").replace(/_/g, " ");
        item.email = item.email || "";
        item.order = item.order || "";
        tempRoles[item.role] = {
          role: item.role,
          label,
        };
        tempOrders[item.order] = {
          order: item.order,
          label: "Sign Order " + item.order,
        };
        orders.push({
          order: index + 1 + "",
          label: "Sign Order " + (index + 1),
        });
        tempEmails.push(item.email);
        if (EMAIL_PATTERN.test(item.email)) {
          item.isValidEmail = true;
        } else {
          item.isValidEmail = false;
        }
        item.touchedEmail = false;

        const words = label.split(" ");

        for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }
        item.roleLabel = words.join(" ");
      });
      setSignOrdersData(data);
      const roles = [];
      Object.keys(tempRoles).forEach((item) => {
        roles.push(tempRoles[item]);
      });
      setSignRoles(roles);

      setSignOrders(orders);
    } else {
      setSignOrdersData([]);
      setSignOrders([]);
      setSignRoles([]);
    }
  };

  const closeAndFinishEsign = () => {
    actions.cleanEsignDataAndClose();
    setActiveEsignSection(1);
    setAssociactedServiceProviders([]);
    setContacts([]);
    setSelectedContact("");
    setSharedWithSP(false);
    setSignRoles([]);
    setSignOrders([]);
    setCustomEmailTitle("");
    setCustomEmailMessage("");
    setOpenCustomEmailMessageDialog(false);
    setSPContacts([]);
    setSelectedTaggedDoucment("");
    setSelectedDocumentForSummary("");
    setOpenUploadCancelDialog(false);
    setConfirmGoBackDialog(false);
    setDisableSkipBtn(false);
    setPrimaryApplicantList([]);
    setDefaultEmailTitle("");
    setDefaultEmailMessage("");
    disconnectFromSocket();
  };

  const resetStep1 = () => {
    actions.cleanUploadFileDataOnClose();
    setConfirmGoBackDialog(false);
    setActiveEsignSection(1);
    setDocumentsTagged([]);
    setAssociactedServiceProviders([]);
    setSelectedContact(null);
    setSharedWithSP(false);
    setSignRoles([]);
    setSignOrders([]);
    setSPContacts([]);
    setSelectedTaggedDoucment("");
    setSelectedDocumentForSummary("");
    setDisableSkipBtn(false);
    setPrimaryApplicantList([]);
    setDefaultEmailTitle("");
    setDefaultEmailMessage("");
    setCustomEmailTitle("");
    setCustomEmailMessage("");
    disconnectFromSocket();
  };

  return {
    sharedWithSP,
    setSharedWithSP,
    associactedServiceProviders,
    getContacts,
    contacts,
    searchText,
    setSearchText,
    selectedContact,
    setSelectedContact,
    filteredContacts,
    setFilteredContacts,
    filterContacts,
    openAddContactDialog,
    setAddContactDialog,
    createContactItem,
    addContactItem,
    openAddSummaryDialog,
    setOpenAddSummaryDialog,
    summaryList,
    deleteSummary,
    activeEsignSection,
    setActiveEsignSection,
    actions,
    eSignDocumentList,
    checkForDocumentDescriptions,
    checkAndAddSummary,
    duplicateFileName,
    allReferenceDocuments,
    setOpenUploadCancelDialog,
    openUploadCancelDialog,
    summaryTobeEdited,
    setSummaryTobeEdited,
    documentsTagged,
    setDocumentsTagged,
    selectedTaggedDoucment,
    setSelectedTaggedDoucment,
    onUploadEsignFileChange,
    processEsignUploadedFiles,
    openEsignWizard,
    setAssociactedServiceProviders,
    setContacts,
    openCustomEmailMessageDialog,
    setOpenCustomEmailMessageDialog,
    customEmailTitle,
    setCustomEmailTitle,
    customEmailMessage,
    setCustomEmailMessage,
    selectedDocumentForSummary,
    setSelectedDocumentForSummary,
    getDocumentSummaryList,
    validateTaggedDocument,
    signRoles,
    setSignRoles,
    signOrders,
    setSignOrders,
    signOrdersData,
    setSignOrdersData,
    updatePrimaryAndSecondaryApplicant,
    isAllDataFilled,
    updateSignOrderChange,
    handleAssocSPSelect,
    updateEmailTouchedState,
    updateEmailValue,
    getSPContacts,
    SPContacts,
    closeAndFinishEsign,
    getSignnowInviteData,
    inviteForEsign,
    setConfirmGoBackDialog,
    openConfirmGoBackDialog,
    showDialogFlag,
    resetStep1,
    disableSkipBtn,
    setDisableSkipBtn,
    updateShowDialogFlag,
    primaryApplicantList,
    setPrimaryApplicantList,
    defaultEmailTitle,
    setDefaultEmailTitle,
    defaultEmailMessage,
    setDefaultEmailMessage,
    openEditEsignDocumentDialog,
    createDocumentForEdit,
    connectToSocket,
    eventType,
    processListener,
    deleteUploadedESignDoc,
    openEditWarningDialog,
    // businessTypes,
  };
};
