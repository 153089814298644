import React, { useEffect } from "react";
import AppRouter from "./AppRouter";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { useSQIntercom } from "souqh-react-redux-hooks/intercom/useSQIntercom";
import {
  HOME_BUYER_USER,
  SERVICE_PROVIDER_USER,
  TEAM_MEMBER_USER,
} from "./components/ServiceProvider/serviceProviderConstants";
import { hideItercomForMobileTablet } from "./utils/AppUtils";

function SQIntercomWrapper() {
  const userInfo = useAuthUser();
  const { startIntercom, stopIntercom } = useSQIntercom();
  useEffect(() => {
    if (userInfo) {
      stopIntercom();
      let userType = "";
      if (userInfo.userType === SERVICE_PROVIDER_USER) {
        userType = "Service Provider";
      } else if (userInfo.userType === HOME_BUYER_USER) {
        userType = "Home Buyer";
      } else if (userInfo.userType === TEAM_MEMBER_USER) {
        userType = "Team Member";
      }
      startIntercom({
        email: userInfo.email,
        userId: userInfo.email,
        userHash: userInfo.intercomUserKey,
        type: userType,
        name: "",
        customAttributes: {
          User_Type: userType,
          stripe_id: userInfo.stripeId,
          job_title: userInfo.jobTitle,
          Phone: userInfo.contactNumber,
          Plan: userInfo.plan,
          Company_size: userInfo.companySize,
          Company_website: userInfo.companyWebsite,
          Signed_Up: userInfo.signedUp,
          Company_Created_At: userInfo.companyCreatedAt,
          User_Company_Type: "",
        },
      });
      hideItercomForMobileTablet();
    } else {
      stopIntercom();
      startIntercom();
    }
  }, [userInfo]);
  return <AppRouter />;
}

export default SQIntercomWrapper;
