import {
  Button,
  Checkbox,
  Chip,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect } from "react";
import Styles from "../../../Styles.module.css";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useAreaOfSpec } from "souqh-react-redux-hooks/useAreaOfSpec";
import { useLanguages } from "souqh-react-redux-hooks/useLanguages";
import { useStoreFrontSearchFilters } from "souqh-react-redux-hooks/homeBuyer/useStoreFrontSearchFilters";
import { useExplore } from "souqh-react-redux-hooks/homeBuyer/useExplore";
import { find } from "lodash";
import { isTabletDevice } from "../../../utils/AppUtils";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

//FIX me: Remove hardcoded values try to get it from API
const ratingOptions = [
  { id: 1, name: "1" },
  { id: 2, name: "2" },
  { id: 3, name: "3" },
  { id: 4, name: "4" },
  { id: 5, name: "5" },
];
const badgeOptions = [
  { id: 1, value: "Premium", name: "Premium Partner" },
  { id: 2, value: "Non-Premium", name: "Souqh Verified Partner" },
];

export default function StoreFrontSearchFilters({
  searchedService,
  setscrollBottom,
  setPageSize,
  getFilteredStoreFronts,
  setFilterActive,
}) {
  const { areaOfSpec, getRemoteAreaOfSpec } = useAreaOfSpec();
  const { languages } = useLanguages();
  const {
    selectedBusinessType,
    selectedAreaOfSpec,
    selectedLanguage,
    rating,
    badge,
    getSubBusinessTypes,
    actions: {
      setSelectedBusinessType,
      setSelectedAreaOfSpec,
      setSelectedLanguage,
      setRating,
      setBadge,
      subBusinessTypes,
      resetExploreFilters,
    },
  } = useStoreFrontSearchFilters();

  useEffect(() => {
    getRemoteAreaOfSpec();
    return () => {
      resetExploreFilters();
    };
  }, []);

  useEffect(() => {
    if (searchedService && searchedService.isBusinessType) {
      getSubBusinessTypes(searchedService.id);
    }
  }, [searchedService]);

  return (
    <Grid container item xs={12}>
      <Grid container item xs={12} style={{ marginBottom: 10 }}>
        <Grid item>
          <Typography className={Styles.navyBlueColor}>
            Advanced Filters:{" "}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            className={Styles.primaryBtnAccent}
            style={{ marginLeft: 10, textTransform: "none" }}
            size="small"
            disabled={
              (!selectedBusinessType || !selectedBusinessType.length) &&
              (!selectedAreaOfSpec || !selectedAreaOfSpec.length) &&
              (!selectedLanguage || !selectedLanguage.length) &&
              !rating &&
              !badge
            }
            onClick={() => {
              setscrollBottom(0);
              setPageSize(0);
              setFilterActive(true);
              getFilteredStoreFronts(0);
              // getFilteredFeaturedStoreFronts();
            }}
          >
            Apply Filter
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        className="hideScroll"
        style={
          isTabletDevice()
            ? {
                display: "flex",
                flexWrap: "nowrap",
                overflowX: "auto",
              }
            : {}
        }
      >
        <Grid
          item
          xs={12}
          md={3}
          style={{ minWidth: isTabletDevice() && "38%" }}
        >
          <Autocomplete
            multiple
            id="business-type"
            value={selectedBusinessType}
            options={subBusinessTypes || []}
            disabled={!searchedService || !searchedService.isBusinessType}
            disableCloseOnSelect
            limitTags={1}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => {
              return option.name === value.name;
            }}
            onChange={(e, v) => {
              setSelectedBusinessType(v);
            }}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                  color={Styles.navyBlueColorForce}
                />
                {option.name}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Business Type"
                size="small"
              />
            )}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  style={{ marginTop: 5 }}
                  label={option.name}
                  {...getTagProps({ index })}
                  variant="outlined"
                  className={Styles.navyBlueColorForce}
                  size="small"
                />
              ))
            }
            inputprops={{
              className: Styles.navyBlueColorForce,
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          style={{ minWidth: isTabletDevice() && "40%" }}
        >
          <Autocomplete
            multiple
            value={selectedAreaOfSpec}
            id="area-of-spec"
            options={areaOfSpec || []}
            disableCloseOnSelect
            limitTags={1}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => {
              return option.name === value.name;
            }}
            onChange={(e, v) => {
              setSelectedAreaOfSpec(v);
            }}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Area of Specialization"
                size="small"
              />
            )}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  style={{ marginTop: 5 }}
                  label={option.name}
                  {...getTagProps({ index })}
                  variant="outlined"
                  className={Styles.navyBlueColorForce}
                  size="small"
                />
              ))
            }
            inputprops={{
              className: Styles.navyBlueColorForce,
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          style={{ minWidth: isTabletDevice() && "25%" }}
        >
          <Autocomplete
            multiple
            value={selectedLanguage}
            id="languages"
            options={languages}
            disableCloseOnSelect
            limitTags={1}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => {
              return option.name === value.name;
            }}
            onChange={(e, v) => {
              setSelectedLanguage(v);
            }}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Languages"
                size="small"
              />
            )}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  style={{ marginTop: 5 }}
                  label={option.name}
                  {...getTagProps({ index })}
                  variant="outlined"
                  className={Styles.navyBlueColorForce}
                  size="small"
                />
              ))
            }
            inputprops={{
              className: Styles.navyBlueColorForce,
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={1}
          style={{ minWidth: isTabletDevice() && "17%" }}
        >
          <Autocomplete
            value={rating}
            noOptionsText={"Please select rating"}
            id="rating-id"
            options={ratingOptions}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => {
              return option.name === value.name;
            }}
            onChange={(e, v) => {
              setRating(v);
            }}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                label="Rating"
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={2}
          style={{ minWidth: isTabletDevice() && "18%" }}
        >
          <Autocomplete
            id="badge"
            value={badge}
            options={badgeOptions || []}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => {
              return option.name === value.name;
            }}
            onChange={(e, v) => {
              setBadge(v);
            }}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                variant="outlined"
                label="Badge"
                size="small"
              />
            )}
            inputprops={{
              className: Styles.navyBlueColorForce,
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
