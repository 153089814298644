import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";

function Toaster() {
  const dispatch = useDispatch();
  const { toasterData } = useSelector((state) => state.ui);

  return (
    <React.Fragment>
      {toasterData && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={toasterData.open}
          autoHideDuration={3000}
          onClose={() => {
            dispatch({
              type: "SET_TOASTER",
              payload: null,
            });
          }}
        >
          <Alert
            severity={toasterData.severity || "success"}
            style={{
              backgroundColor: toasterData.bgColor,
              color: "black",
            }}
          >
            {toasterData.message}
          </Alert>
        </Snackbar>
      )}
    </React.Fragment>
  );
}
export default Toaster;
