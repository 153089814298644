import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
  Link,
  Grid,
  Avatar,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import * as moment from "moment";
import { PENDING_ACTION_HEADERS } from "./pendingActionConstants";
import {
  getClientColumnValue,
  getComparator,
  getImgSrc,
  isMobileDevice,
  isTabletDevice,
  stableSort,
} from "../../../utils/AppUtils";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import AddReviewDialog from "../../HomeBuyer/SPManagement/AddReviewDialog";
import SelectHomeBuyingJourneyDialog from "./SelectHomeBuyingJourneyDialog";
import AddMemberDialog from "../../Settings/AddMemberDialog";
import InviteSPDialog from "../../ReferralManagement/InviteSPdialog";
import AddTemplate from "../../ServiceProvider/UploadDocuments/AddTemplate/AddTemplate";
import DocumentAccessRequestDialog from "./DocumentAccessRequestDialog";
import ViewInvoiceDialog from "./ViewInvoiceDialog";
import DocumentPreviewDialog from "../../HomeBuyer/Explore/StorefrontPage/DocumentPreviewDialog";
import SignDocumentDialog from "../../ServiceProvider/UploadDocuments/SignDocumentDialog";
import RequestedDocumentDialog from "./RequestedDocumentDialog";
import { SP_SIGN_NOW_ACCOUNT_EXIST } from "../../ServiceProvider/serviceProviderConstants";
import { useDispatch } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";

function EnhancedTableHead({ order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      {PENDING_ACTION_HEADERS.map((headCell) => (
        <TableCell
          className={Styles.navyBlueColorForce}
          key={headCell.id}
          align="left"
          sortDirection={orderBy === headCell.id ? order : false}
          style={
            isMobileDevice() || isTabletDevice()
              ? { minWidth: headCell.width }
              : { width: headCell.width }
          }
        >
          <TableSortLabel
            active={orderBy === headCell.id}
            direction={orderBy === headCell.id ? order : "asc"}
            onClick={createSortHandler(headCell.id)}
            disabled={
              headCell.id === "eventDescription" ||
              headCell.id === "requestedDate" ||
              headCell.id === "dueDate"
            }
          >
            <Typography className={Styles.navyBlueColor} variant="subtitle1">
              {headCell.label}
            </Typography>
          </TableSortLabel>
        </TableCell>
      ))}
    </>
  );
}

export default function PendingActions({
  order,
  orderBy,
  handleRequestSort,
  pendingActionsList,
  handlePendingAction,
  openAddReviewDialog,
  setOpenAddReviewDialog,
  openHomeBuyingJourneyDialog,
  setOpenHomeBuyingJourneyDialog,
  openAddMemberDialog,
  setOpenAddMemberDialog,
  openInviteSPDialogForPendingAction,
  setOpenInviteSPDialogForPendingAction,
  onUploadTemplateFileChange,
  openAddTemplate,
  setOpenAddTemplate,
  updateIntercomStatus,
  openDocumentAccessRequestDialog,
  setOpenDocumentAccessRequestDialog,
  requestedDocAccessName,
  documentAccessRequestorSP,
  acceptDocumentAccessRequest,
  openViewInvoiceDialog,
  setOpenViewInvoiceDialog,
  viewInvoiceDialogData,
  userInfo,
  openDocumetDialog,
  setOpenDocumetDialog,
  documentItem,
  setDocumentItem,
  setEventIndex,
  openSignDocumentDialog,
  setOpenSignDocumentDialog,
  signDocumentItem,
  setSignDocumentItem,
  reloadPendingActionData,
  openRequestedDocumentDialog,
  setOpenRequestedDocumentDialog,
  deletePendingAction,
  requestedDocumentData,
  downloadUrl,
  docAccessRequestedData,
  docCategoryId,
  docNameForDownload,
  docUserId,
  pendingActions,
  handlePageChange,
  displayPendingActionsData,
  setDisplayPendingActionsData,
}) {
  useEffect(() => {
    setDisplayPendingActionsData(pendingActions);
  }, [pendingActions]);

  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <DocumentPreviewDialog
        openDocumetDialog={openDocumetDialog}
        setOpenDocumetDialog={setOpenDocumetDialog}
        documentItem={documentItem}
        setDocumentItem={setDocumentItem}
        downloadUrl={downloadUrl}
        source="review-document"
        docCategoryId={docCategoryId}
        docNameForDownload={docNameForDownload}
        docUserId={docUserId}
      />
      <DocumentAccessRequestDialog
        openDocumentAccessRequestDialog={openDocumentAccessRequestDialog}
        setOpenDocumentAccessRequestDialog={setOpenDocumentAccessRequestDialog}
        requestedDocAccessName={requestedDocAccessName}
        documentAccessRequestorSP={documentAccessRequestorSP}
        acceptDocumentAccessRequest={acceptDocumentAccessRequest}
        docAccessRequestedData={docAccessRequestedData}
      />
      <RequestedDocumentDialog
        openRequestedDocumentDialog={openRequestedDocumentDialog}
        setOpenRequestedDocumentDialog={setOpenRequestedDocumentDialog}
        requestedDocumentData={requestedDocumentData}
        deletePendingAction={deletePendingAction}
      />
      <AddReviewDialog
        openAddReviewDialog={openAddReviewDialog}
        setOpenAddReviewDialog={setOpenAddReviewDialog}
      />
      <SelectHomeBuyingJourneyDialog
        openHomeBuyingJourneyDialog={openHomeBuyingJourneyDialog}
        setOpenHomeBuyingJourneyDialog={setOpenHomeBuyingJourneyDialog}
      />
      <AddMemberDialog
        openAddMemberDialog={openAddMemberDialog}
        setOpenAddMemberDialog={setOpenAddMemberDialog}
      />
      <InviteSPDialog
        openInviteSPDialog={openInviteSPDialogForPendingAction}
        setOpenInviteSPDialog={setOpenInviteSPDialogForPendingAction}
      />
      <AddTemplate
        openAddTemplate={openAddTemplate}
        setOpenAddTemplate={setOpenAddTemplate}
        updateIntercomStatus={updateIntercomStatus}
      />
      <ViewInvoiceDialog
        openViewInvoiceDialog={openViewInvoiceDialog}
        setOpenViewInvoiceDialog={setOpenViewInvoiceDialog}
        viewInvoiceDialogData={viewInvoiceDialogData}
        userInfo={userInfo}
      />
      <SignDocumentDialog
        openSignDocumentDialog={openSignDocumentDialog}
        setOpenSignDocumentDialog={setOpenSignDocumentDialog}
        signDocumentItem={signDocumentItem}
        setSignDocumentItem={setSignDocumentItem}
        reloadPendingActionData={reloadPendingActionData}
      />
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {displayPendingActionsData &&
            displayPendingActionsData.response &&
            displayPendingActionsData.response.length
              ? stableSort(
                  displayPendingActionsData.response,
                  getComparator(order, orderBy)
                ).map((row, index) => (
                  <TableRow key={row.pendingActionId}>
                    <TableCell
                      align="left"
                      component="th"
                      scope="row"
                      style={{ borderBottom: "none" }}
                    >
                      <Typography
                        variant="body1"
                        className={Styles.navyBlueColor}
                      >
                        {index + 1}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" style={{ borderBottom: "none" }}>
                      <Typography
                        variant="body1"
                        className={Styles.navyBlueColor}
                      >
                        {row.requestDate
                          ? moment(row.requestDate).format("MMM DD,YYYY")
                          : "N/A"}
                      </Typography>
                    </TableCell>
                    {/* <TableCell align="left" style={{ borderBottom: "none" }}>
                      <Typography
                        variant="body1"
                        className={Styles.navyBlueColor}
                      >
                        {row.dueDate
                          ? moment(row.dueDate).format("MMM DD,YYYY")
                          : "N/A"}
                      </Typography>
                    </TableCell> */}
                    <TableCell align="left" style={{ borderBottom: "none" }}>
                      <Grid container>
                        <Grid item xs={2}>
                          <Avatar
                            src={getImgSrc(row)}
                            style={{
                              height: isMobileDevice() ? 25 : 32,
                              width: isMobileDevice() ? 20 : 32,
                            }}
                          />
                        </Grid>
                        <Grid item xs={10} style={{ paddingLeft: 8 }}>
                          <Typography
                            variant="body1"
                            className={Styles.navyBlueColor}
                            style={{ fontWeight: 600 }}
                          >
                            {row.clientName}
                          </Typography>
                        </Grid>
                      </Grid>{" "}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ borderBottom: "none", cursor: "pointer" }}
                    >
                      {row.pendingAction === "UPLOAD_DOCUMENT_TEMPLATE" ? (
                        <div>
                          <input
                            style={{ display: "none" }}
                            id="contained-button-file-temp"
                            multiple
                            type="file"
                            onChange={(event) =>
                              onUploadTemplateFileChange(event)
                            }
                          />
                          <label htmlFor="contained-button-file-temp">
                            <Link
                              underline="always"
                              className={Styles.primaryTxtAccentForce}
                              variant="body1"
                              style={{ cursor: "pointer" }}
                            >
                              {row.eventDescription}
                            </Link>
                          </label>
                        </div>
                      ) : (
                        <Link
                          underline="always"
                          variant="body1"
                          className={Styles.primaryTxtAccentForce}
                          onClick={() => {
                            if (
                              row.pendingAction === "SIGN_DOCUMENT" ||
                              row.pendingAction === "UPLOAD_DOCUMENT_TEMPLATE"
                            ) {
                              if (userInfo.signNowAccountCreated) {
                                handlePendingAction(row);
                                setEventIndex(row.pendingActionId);
                              } else {
                                dispatch({
                                  type: "SET_SERVER_ERROR",
                                  error: {
                                    message: SP_SIGN_NOW_ACCOUNT_EXIST,
                                    title: "Existing SignNow Account Error",
                                  },
                                });
                              }
                            } else {
                              handlePendingAction(row);
                              setEventIndex(row.pendingActionId);
                            }
                          }}
                        >
                          {row.eventDescription}
                        </Link>
                      )}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        borderBottom: "none",
                        paddingRight: !isMobileDevice() && 0,
                        cursor: "pointer",
                      }}
                    >
                      <CancelOutlinedIcon
                        className={Styles.navyBlueColor}
                        style={{ fontSize: 24 }}
                        onClick={() => {
                          deletePendingAction(row.pendingActionId);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        style={{ margin: "16px 0" }}
      >
        <Pagination
          count={
            displayPendingActionsData && displayPendingActionsData.totalPages
          }
          shape="rounded"
          variant="outlined"
          onChange={handlePageChange}
        />
      </Grid>
    </React.Fragment>
  );
}
