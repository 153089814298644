import {
  SET_ALL_TIMELINE_DATA,
  SET_INDIVIDUAL_SP_TIMELINE_DATA,
  CLEAR_INDIVIDUAL_SP_TIMELINE_DATA,
  SET_INDIVIDUAL_SP_DOCUMENTS,
} from "souqh-redux/reducers/actionConstants";
import { useSelector, useDispatch } from "react-redux";
import { useApiClient } from "../useApiClient";
import { useState } from "react";
import { useActions } from "../useActions";

const actionCreators = {
  clearIndividualSPTimelineData: (payload) => ({
    type: CLEAR_INDIVIDUAL_SP_TIMELINE_DATA,
    payload,
  }),
};

export const useHomeOwnershipTimeline = (userId) => {
  const [timelinespType, setTimelinespType] = useState("all");
  const [showIndividualSPData, setShowIndividualSPData] = useState(false);
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();
  const actions = useActions(actionCreators);

  const { allTimelineData, individualSPTimelineData, individualSPDocuments } =
    useSelector((state) => state.homeOwnershipTimeline);

  const getAllSPsTimelineData = () => {
    if (userId) {
      dispatch({ type: "SET_LOADING", value: true });
      return apiClient
        .get(`/client/history/${timelinespType}/home-buyer-timeline/${userId}`)
        .then(function (response) {
          if (response.data.result) {
            dispatch({
              type: SET_ALL_TIMELINE_DATA,
              payload: response.data.result,
            });
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          // always executed
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const getIndividualSPsTimelineData = (spId, hbUserId) => {
    if (spId && hbUserId) {
      dispatch({ type: "SET_LOADING", value: true });
      return apiClient
        .get(
          `/client/history/home-buyer-timeline/sp-history?homeBuyerUserId=${hbUserId}&serviceProviderId=${spId}`
        )
        .then(function (response) {
          if (response.data.result) {
            dispatch({
              type: SET_INDIVIDUAL_SP_TIMELINE_DATA,
              payload: response.data.result,
            });
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const getIndividualSPsDocuments = (spId) => {
    if (spId) {
      dispatch({ type: "SET_LOADING", value: true });
      return apiClient
        .get(
          `/client/history/home-buyer-timeline/documents?serviceProviderId=${spId}`
        )
        .then(function (response) {
          if (response.data.result) {
            dispatch({
              type: SET_INDIVIDUAL_SP_DOCUMENTS,
              payload: response.data.result,
            });
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  return {
    getAllSPsTimelineData,
    allTimelineData,
    getIndividualSPsTimelineData,
    timelinespType,
    setTimelinespType,
    showIndividualSPData,
    setShowIndividualSPData,
    individualSPTimelineData,
    actions,
    getIndividualSPsDocuments,
    individualSPDocuments,
  };
};
