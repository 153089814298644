import { cloneDeep, isEmpty } from "lodash";
import {
  SET_SELECTED_BUSINESS_TYPES,
  SET_SELECTED_AREA_OF_SPEC,
  SET_SELECTED_LANGUAGES,
  SET_SELECTED_RATING,
  SET_SELECTED_BADGE,
  SET_SELECTED_SUB_BUSINESS_TYPES,
  RESET_EXPLORE_FILTERS,
} from "./actionConstants";

const initialState = {
  selectedBusinessType: [],
  selectedAreaOfSpec: [],
  selectedLanguage: [],
  rating: null,
  badge: null,
  subBusinessTypes: [],
};

const explorePageFilters = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_SELECTED_BUSINESS_TYPES:
      newState = cloneDeep(state);
      newState.selectedBusinessType = action.payload;
      return newState;
    case SET_SELECTED_AREA_OF_SPEC:
      newState = cloneDeep(state);
      newState.selectedAreaOfSpec = action.payload;
      return newState;
    case SET_SELECTED_LANGUAGES:
      newState = cloneDeep(state);
      newState.selectedLanguage = action.payload;
      return newState;
    case SET_SELECTED_RATING:
      newState = cloneDeep(state);
      newState.rating = action.payload;
      return newState;
    case SET_SELECTED_BADGE:
      newState = cloneDeep(state);
      newState.badge = action.payload;
      return newState;
    case SET_SELECTED_SUB_BUSINESS_TYPES:
      newState = cloneDeep(state);
      newState.subBusinessTypes = action.payload;
      return newState;
    case RESET_EXPLORE_FILTERS:
      newState = cloneDeep(state);
      newState = initialState;
      return newState;
    default:
      return state;
  }
};

export default explorePageFilters;
