import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";
import { useRenameCategory } from "souqh-react-redux-hooks/homeBuyer/useRenameCategory";
import Toaster from "../../utils/Toaster";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 350,
  },
}));

function RenameCategoryDialog({
  openRenameCategoryDialog,
  setOpenRenameCategoryDialog,
  categories,
}) {
  const {
    renameExistingCategory,
    setRenamedCategory,
    checkDuplicate,
    validation,
    customCategories,
    setSelectedCategory,
    renamedCategory,
    selectedCategory,
  } = useRenameCategory(categories);
  const [data, setData] = useState({});

  const classes = useStyles();
  const dispatch = useDispatch();

  const oldCategory =
    customCategories.find((ele) => ele.id === selectedCategory) &&
    customCategories.find((ele) => ele.id === selectedCategory).name;
  return (
    <React.Fragment key="renameDialog">
      {/* <Toaster
        customStyle={Styles.toasterSuccess}
        message={`You have renamed category -${data.old} from ${data.old} to ${data.new}`}
      /> */}
      <Dialog
        fullWidth
        open={openRenameCategoryDialog}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DialogTitle className={Styles.navyBlueColor}>
          <Typography variant="subtitle1">Rename Category</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container className={Styles.navyBlueColor}>
            <Grid container item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="label">Select Category</InputLabel>
                <Select
                  labelId="label"
                  id="select-category-id"
                  label="Select Category"
                  onClick={(e) => setSelectedCategory(e.target.value)}
                >
                  {categories && categories.length
                    ? categories
                        .filter((item) => !item.isDefault)
                        .map((category, index) => (
                          <MenuItem key={index} value={category.id}>
                            {category.name}
                          </MenuItem>
                        ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid container style={{ marginTop: 20 }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="categoryName"
                  placeholder="New Category Name"
                  name="categoryName"
                  variant="outlined"
                  value={renamedCategory}
                  required
                  onChange={(e) => {
                    setRenamedCategory(e.target.value);
                    checkDuplicate(e);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {validation && (
                <Typography variant="body2" style={{ color: "red" }}>
                  Category already exists. Please enter the unique category name
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            className={Styles.navyBlueColorForce}
            style={{ textTransform: "capitalize" }}
            onClick={() => {
              setOpenRenameCategoryDialog(false);
              setRenamedCategory("");
              setSelectedCategory("");
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!renamedCategory || !selectedCategory}
            className={Styles.primaryBtnAccent}
            style={{
              textTransform: "capitalize",
              marginRight: 16,
              padding: "6px 24px",
            }}
            onClick={() => {
              renameExistingCategory((response) => {
                if (response && response.data.status === 200) {
                  setData({ old: renamedCategory, new: selectedCategory });
                  setOpenRenameCategoryDialog(false);
                  setRenamedCategory("");
                  setSelectedCategory("");
                }
              });
            }}
          >
            Rename
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default RenameCategoryDialog;
