import { SvgIcon } from "@material-ui/core";
import React from "react";
import { useTheme } from "souqh-react-redux-hooks/serviceProvider/storefront/useTheme";

function WebAddress(props) {
  const { primaryColor } = useTheme();
  return (
    <SvgIcon viewBox="0 0 64 64" {...props}>
      <path
        id="Icon_metro-earth"
        data-name="Icon metro-earth"
        d="M34.571,1.928a32,32,0,1,0,32,32,32,32,0,0,0-32-32Zm0,60A27.906,27.906,0,0,1,23.494,59.65L38.066,43.257a2,2,0,0,0,.505-1.329v-6a2,2,0,0,0-2-2c-7.062,0-14.513-7.341-14.586-7.414a2,2,0,0,0-1.414-.586h-8a2,2,0,0,0-2,2v12a2,2,0,0,0,1.106,1.789l6.894,3.447V56.907a28.017,28.017,0,0,1-9.3-34.979h7.3a2,2,0,0,0,1.414-.586l8-8a2,2,0,0,0,.586-1.414V7.09A28.078,28.078,0,0,1,46.838,8.753c-.259.219-.511.448-.753.69A12,12,0,0,0,54.563,29.93q.3,0,.595-.015c.863,3.238,2.422,11.666-.525,23.271a1.994,1.994,0,0,0-.053.326,27.913,27.913,0,0,1-20.009,8.416Z"
        transform="translate(-2.571 -1.928)"
        fill={primaryColor}
      />
    </SvgIcon>
  );
}

export default WebAddress;
