import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import metaDataReducer from "./metaDataReducer";
import spSetup from "./reducers/spSetup";
import uiReducer from "./uiReducer";
import auth from "./reducers/auth";
import storefront from "./reducers/storefront";
import homeBuyer from "./reducers/homeBuyer";
import subscriptionPlan from "./reducers/subscriptionPlan";
import paymentAndInvoice from "./reducers/paymentAndInvoice";
import hbSetup from "./reducers/hbSetup";
import spDocument from "./reducers/spDocument";
import uploadDocument from "./reducers/uploadDocument";
import referralManagement from "./reducers/referralManagement";
import documents from "./reducers/documents";
import teamOnboarding from "./reducers/teamOnboarding";
import clientManagement from "./reducers/clientManagement";
import spManagement from "./reducers/spManagement";
import upcomingEeventsPendingActions from "./reducers/upcomingEeventsPendingActions";
import storefrontClientReview from "./reducers/storefrontClientReview";
import messaging from "./reducers/messaging";
import documentsData from "./reducers/documentsData";
import homeBuyingJourney from "./reducers/homeBuyingJourney";
import sqCalendar from "./reducers/sqCalendar";
import allUsers from "./reducers/allUsers";
import notifications from "./reducers/notifications";
import keyStatistics from "./reducers/keyStatistics";
import explorePageFilters from "./reducers/explorePageFilters";
import clientHub from "./reducers/clientHub";
import marketingCampaign from "./reducers/marketingCampaign";
import homeOwnershipTimeline from "./reducers/homeOwnershipTimeline";
import emailPreferences from "./reducers/emailPreferences";
import paymentHistory from "./reducers/paymentHistory";

const appReducer = combineReducers({
  metaData: metaDataReducer,
  ui: uiReducer,
  spSetup: spSetup,
  auth: auth,
  storefront,
  homeBuyer,
  subscriptionPlan,
  paymentAndInvoice,
  hbSetup: hbSetup,
  spDocument,
  uploadDocument,
  referralManagement,
  documents,
  teamOnboarding,
  clientManagement,
  spManagement,
  upcomingEeventsPendingActions,
  storefrontClientReview,
  messaging,
  documentsData,
  homeBuyingJourney,
  sqCalendar,
  allUsers,
  notifications,
  keyStatistics,
  explorePageFilters,
  clientHub,
  marketingCampaign,
  homeOwnershipTimeline,
  emailPreferences,
  paymentHistory,
});
const store = createStore(appReducer, applyMiddleware(thunk));

export default store;
