import { useState, useCallback, useEffect } from "react";
import {
  emailValidationMeta,
  mobileNumberValidationMeta,
} from "../validationMetas";
import { useValidations } from "../useValidations";
import { useActions } from "../useActions";
import {
  SAVE_PRIMARY_APPLICANT_DATA,
  SAVE_CO_APPLICANT_DATA,
  CLEAR_INIVITE_CUSTOMER_DATA,
  SHOW_CO_APPLICANT,
  SHOW_RECOMMENDED_SP,
  SET_DOCUMENT_LIST,
  SET_TEMPLATE_LIST,
} from "../../souqh-redux/reducers/actionConstants";
import { useAuthUser } from "../useLogin";
import { map } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useApiClient } from "../useApiClient";
import {
  BUSINESS_TYPE_OTHER,
  MY_TEMPLATES,
  SERVICE_PROVIDER_USER,
  TEAM_MEMBER_USER,
} from "../../web/src/components/ServiceProvider/serviceProviderConstants";
import { useCurrentAndNewAddress } from "../serviceProvider/useSetUp";

const validationMeta = [emailValidationMeta, mobileNumberValidationMeta];

const validationMeta2 = [emailValidationMeta];

const actionCreators = {
  clearData: (payload) => ({
    type: CLEAR_INIVITE_CUSTOMER_DATA,
    payload,
  }),
  setShowCoApplicantdetails: (payload) => ({
    type: SHOW_CO_APPLICANT,
    payload,
  }),
  setshowRecommendedSPDetails: (payload) => ({
    type: SHOW_RECOMMENDED_SP,
    payload,
  }),
};

const primaryApplicantActions = {
  savePrimaryApplicantData: (payload) => ({
    type: SAVE_PRIMARY_APPLICANT_DATA,
    payload,
  }),
};

const coApplicantActions = {
  saveCoApplicantData: (payload) => ({
    type: SAVE_CO_APPLICANT_DATA,
    payload,
  }),
};

export const useInviteClientsDialog = (srcPage = "") => {
  const { apiClient } = useApiClient();

  const userInfo = useAuthUser();
  const dispatch = useDispatch();
  const actions = useActions(actionCreators);
  const [addSPSucessMsg, setAddSPSucessMsg] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedDoc, setSelectedDoc] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [otherDoc, setOtherDoc] = useState("");

  const {
    primaryApplicant,
    coApplicant,
    recommendSP,
    showCoApplicantdetails,
    showRecommendedSPDetails,
    documentChecklist,
    templateList,
  } = useSelector((state) => state.referralManagement);

  const {
    currentAndNewAddress,
    actions: { resetCurrentAndNewAddress },
  } = useCurrentAndNewAddress();

  const hbCurrentAddress = {
    "cityId": currentAndNewAddress?.currentAddress?.city?.id,
    "county": currentAndNewAddress?.currentAddress?.county,
    "otherCity": currentAndNewAddress?.currentAddress?.otherCity,
    "postalCode": currentAndNewAddress?.currentAddress?.postalCode,
    "provinceId": currentAndNewAddress?.currentAddress?.province?.id,
    "streetName": currentAndNewAddress?.currentAddress?.streetName,
    "streetNumber": currentAndNewAddress?.currentAddress?.streetNumber,
  }

  const hbNewHomeAddress = {
    "cityId": currentAndNewAddress?.newAddress?.city?.id,
    "county": currentAndNewAddress?.newAddress?.county,
    "otherCity": currentAndNewAddress?.newAddress?.otherCity,
    "postalCode": currentAndNewAddress?.newAddress?.postalCode,
    "provinceId": currentAndNewAddress?.newAddress?.province?.id,
    "streetName": currentAndNewAddress?.newAddress?.streetName,
    "streetNumber": currentAndNewAddress?.newAddress?.streetNumber,
  }

  //send invite
  const sentInviteToClients = (onResult) => {
    const recommendOtherSPs = [];
    if (recommendSP.newAddedSP.length) {
      recommendSP.newAddedSP.forEach((data) =>
        recommendOtherSPs.push({
          businessTypeId: data.serviceProviderType.id,
          contactNumber: data.phoneNumber,
          email: data.email,
          fullName: data.fullName,
          senderUserId: userInfo.userId,
        })
      );
    }
    if (selectedDoc.includes(BUSINESS_TYPE_OTHER)) {
      otherDoc
        ? selectedDoc.splice(
            selectedDoc.indexOf(BUSINESS_TYPE_OTHER),
            1,
            otherDoc
          )
        : selectedDoc.splice(selectedDoc.indexOf(BUSINESS_TYPE_OTHER), 1);
    }
    const data = {
      coApplicantEmail: coApplicant.email || null,
      coApplicantFullName: coApplicant.fullName || null,
      hbCurrentAddress: hbCurrentAddress,
      hbNewHomeAddress: hbNewHomeAddress,
      isCoApplicantAvailable: showCoApplicantdetails,
      primaryContactNumber: primaryApplicant.phoneNumber || null,
      primaryEmail: primaryApplicant.email,
      primaryFullName: primaryApplicant.fullName,
      recommendOnSouqhSPs: map(recommendSP.selectedPreferredSP, "email"),
      recommendOtherSPs: recommendOtherSPs,
      recommendServiceProviders: showRecommendedSPDetails,
      otherDocuments: selectedDoc,
      signNowTemplateIds: selectedTemplate.map((ele) => ele.signNowDocumentId),
    };

    data.storeFrontServiceId =
      (srcPage === "clientManagement" || srcPage === "serviceProvider") &&
      selectedService
        ? selectedService.id
        : null;

    const apiUrl =
      userInfo.userType === SERVICE_PROVIDER_USER ||
      userInfo.userType === TEAM_MEMBER_USER
        ? `/service-provider/client/add`
        : `/referral/invite/home-buyer?userId=${userInfo.userId}`;

    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .post(apiUrl, data)
      .then((res) => {
        onResult && onResult(res);
      })
      .catch((error) => {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
        resetCurrentAndNewAddress();
      });
  };

  const getDocumentChecklist = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/service-provider/client/other/documents/list`)
      .then(async (response) => {
        if (response.data.result) {
          dispatch({
            type: SET_DOCUMENT_LIST,
            payload: response.data.result,
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getUserTemplatelist = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/documents/user/${userInfo.userId}/${MY_TEMPLATES}`)
      .then(async (response) => {
        if (response.data.result) {
          dispatch({
            type: SET_TEMPLATE_LIST,
            payload: response.data.result.documentList,
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    showCoApplicantdetails,
    showRecommendedSPDetails,
    addSPSucessMsg,
    setAddSPSucessMsg,
    sentInviteToClients,
    actions,
    selectedService,
    setSelectedService,
    getDocumentChecklist,
    documentChecklist,
    selectedDoc,
    setSelectedDoc,
    getUserTemplatelist,
    templateList,
    selectedTemplate,
    setSelectedTemplate,
    otherDoc,
    setOtherDoc,
    userInfo,
  };
};

export const usePrimaryApplicant = () => {
  const actions = useActions(primaryApplicantActions);
  const { email } = useSelector(
    (state) => state.referralManagement.primaryApplicant
  );
  const { phoneNumber } = useSelector(
    (state) => state.referralManagement.primaryApplicant
  );
  const { primaryApplicant } = useSelector((state) => state.referralManagement);
  const [allowAction, setAllowAction] = useState(false);

  const getFormValues = useCallback(() => {
    return { email, phoneNumber };
  }, [email, phoneNumber]);

  const getServerKeysMap = useCallback(() => {
    return {
      email: "email",
      phoneNumber: "phoneNumber",
    };
  }, []);

  const { validations, addValidations, clearAllValidations } = useValidations(
    validationMeta,
    getFormValues,
    getServerKeysMap
  );

  useEffect(() => {
    let validation =
      !!primaryApplicant.email &&
      !!primaryApplicant.fullName &&
      !Object.keys(validations).length;
    setAllowAction(validation);
  }, [primaryApplicant, validations]);

  return {
    validations,
    primaryApplicant,
    allowAction,
    addValidations,
    actions,
  };
};

export const useCoApplicant = () => {
  const actions = useActions(coApplicantActions);
  const { email } = useSelector(
    (state) => state.referralManagement.coApplicant
  );
  const { coApplicant } = useSelector((state) => state.referralManagement);
  const showCoApplicantdetails = useSelector(
    (state) => state.referralManagement.showCoApplicantdetails
  );
  const [allowAction, setAllowAction] = useState(false);

  const getFormValues = useCallback(() => {
    return { email };
  }, [email]);

  const getServerKeysMap = useCallback(() => {
    return {
      email: "email",
    };
  }, []);

  const { validations, addValidations, clearAllValidations } = useValidations(
    validationMeta2,
    getFormValues,
    getServerKeysMap
  );

  useEffect(() => {
    if (showCoApplicantdetails) {
      let validation =
        !!coApplicant.email &&
        !!coApplicant.fullName &&
        !Object.keys(validations).length;
      setAllowAction(validation);
    } else {
      setAllowAction(true);
    }
  }, [showCoApplicantdetails, coApplicant, validations]);

  return {
    validations,
    coApplicant,
    allowAction,
    actions,
  };
};
