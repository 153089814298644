import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";

export default function ConfirmGoBackDialog({
  setConfirmGoBackDialog,
  openConfirmGoBackDialog,
  updateShowDialogFlag,
  showDialogFlag,
  onClose,
}) {
  const [checkBoxFlag, setCheckBoxFlag] = useState(false);

  useEffect(() => {
    setCheckBoxFlag(!showDialogFlag);
  }, [showDialogFlag]);
  return (
    <Dialog fullWidth open={openConfirmGoBackDialog || false}>
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Warning</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText
          style={{ marginBottom: 120 }}
          className={Styles.navyBlueColor}
        >
          Going back will require uploading all the documents again. Are you
          sure you want to go back to step 1?
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid item container xs={12}>
          <Grid item container xs={6} style={{ alignItems: "center" }}>
            <Checkbox
              checked={checkBoxFlag}
              onChange={(event) => {
                setCheckBoxFlag(event.target.checked);
              }}
              color={Styles.navyBlueColorForce}
            />
            <Typography variant="body1" className={Styles.navyBlueColor}>
              Don't show this again
            </Typography>
          </Grid>
          <Grid item container xs={6} style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={() => setConfirmGoBackDialog(false)}
              variant="outlined"
              style={{
                textTransform: "capitalize",
                padding: "4px 16px",
                marginRight: 16,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                updateShowDialogFlag(!checkBoxFlag);
                onClose();
              }}
              className={Styles.primaryBtnAccent}
              style={{ textTransform: "capitalize", padding: "4px 32px" }}
            >
              Yes
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
