import {
  Grid,
  makeStyles,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableBody,
  Button,
  ButtonBase,
} from "@material-ui/core";
import React from "react";
import AddIcon from "@material-ui/icons/Add";
import Styles from "../../../../Styles.module.css";
import { MAX_SUMMARY_ITEMS } from "../../serviceProviderConstants";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    color: "#0E1C4E",
    fontSize: "1rem",
    fontWeight: "bold",
  },
  tableContent: {
    color: "#0E1C4E",
    fontSize: "1rem",
  },
  button: {
    color: "#0E1C4E",
    textTransform: "capitalize",
  },
  formControl: {
    minWidth: 120,
    width: "80%",
    "& .MuiOutlinedInput-input": {
      padding: 8,
    },
  },
}));

export default function TemplateSummary({
  templateSummaryList,
  setOpenAddSummaryDialog,
  setSummaryTobeEdited,
  deleteSummary,
}) {
  const classes = useStyles();

  const tableHeader = (
    <TableRow>
      <TableCell className={classes.tableHeader} style={{ width: "20%" }}>
        <Typography variant="subtitle1">Title</Typography>
      </TableCell>
      <TableCell className={classes.tableHeader} style={{ width: "30%" }}>
        <Typography variant="subtitle1">Subtitle</Typography>
      </TableCell>
      <TableCell className={classes.tableHeader} style={{ width: "40%" }}>
        <Typography variant="subtitle1">Description</Typography>
      </TableCell>
      <TableCell className={classes.tableHeader} style={{ width: "10%" }}>
        <Grid container></Grid>
      </TableCell>
    </TableRow>
  );

  const summaryTable = templateSummaryList.length ? (
    <TableContainer>
      <Table style={{ tableLayout: "fixed", width: "100%" }}>
        <TableHead>{tableHeader}</TableHead>
        <TableBody>
          {templateSummaryList.map((summaryData, index) => (
            <TableRow key={summaryData.id}>
              <TableCell
                component="th"
                scope="row"
                className={classes.tableContent}
              >
                <Typography variant="body1" className={Styles.sqEllipsis}>
                  {summaryData.title}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableContent}>
                <Typography variant="body1" className={Styles.sqEllipsis}>
                  {summaryData.subtitle}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableContent}>
                <Typography variant="body1" className={Styles.sqEllipsis}>
                  {summaryData.description}
                </Typography>
              </TableCell>
              <TableCell
                align="right"
                className={classes.tableContent}
                style={{ paddingRight: 48 }}
              >
                <Grid item container>
                  <ButtonBase style={{ marginRight: 12 }}>
                    <EditIcon
                      style={{ marginTop: 10 }}
                      onClick={() => {
                        setSummaryTobeEdited({ ...summaryData, index });
                      }}
                    />
                  </ButtonBase>

                  <ButtonBase>
                    <CancelOutlinedIcon
                      style={{ marginTop: 10 }}
                      onClick={() => {
                        deleteSummary(summaryData.id);
                      }}
                    />
                  </ButtonBase>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <Grid
      item
      container
      xs={12}
      style={{ padding: "64px 48px", height: "100%" }}
      className={Styles.sqFlexBoxCenter}
    >
      <Typography
        variant="body1"
        style={{
          color: "0e1c4e",
          opacity: 0.5,
          fontStyle: "italic",
          fontWeight: 300,
        }}
      >
        Add document summary details.
      </Typography>
    </Grid>
  );

  return (
    <Grid container style={{ padding: "12px 20px", height: "99%" }}>
      <Grid
        item
        xs={12}
        container
        justifyContent="flex-end"
        style={{ paddingRight: 32, height: "40px", paddingTop: "16px" }}
      >
        <Button
          disabled={templateSummaryList.length >= MAX_SUMMARY_ITEMS}
          variant="outlined"
          startIcon={<AddIcon />}
          className={classes.button}
          onClick={() => {
            setOpenAddSummaryDialog(true);
          }}
        >
          Add
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ height: "calc(100% - 50px)", overflow: "auto" }}
      >
        {summaryTable}
      </Grid>
    </Grid>
  );
}
