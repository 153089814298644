import React, { useState, useEffect } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import ActiveClientTableRow from "./ActiveClientTableRow";
import { Grid } from "@material-ui/core";
import { useClientManagementTable } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useClientManagementTable";
import {
  ACTIVE_CLIENT_TABLE_HEADERS,
  INACTIVE_CLIENT_TABLE_HEADERS,
  COMPLETED_CLIENT_TABLE_HEADERS,
  LEADS_CLIENT_TABLE_HEADERS,
} from "./clientManagementConstants";
import InActiveClientTableRow from "./InActiveClientTableRow";
import CompletedClientTableRow from "./CompletedClientTableRow";
import LeadsClientTableRow from "./LeadsClientTableRow";
import Styles from "../../../Styles.module.css";
import {
  getComparator,
  isTabletDevice,
  stableSort,
} from "../../../utils/AppUtils";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={
              isTabletDevice()
                ? { minWidth: headCell.width }
                : {
                    width: headCell.width,
                  }
            }
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className={Styles.navyBlueColorForce}
              style={{ fontSize: 16, fontWeight: 600 }}
              disabled={
                headCell.id === "action" ||
                headCell.id === "activeSps" ||
                headCell.id === "id" ||
                headCell.id === "review"
              }
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function ClientInfoTableView({
  tabValue,
  displayClientsData,
  handlePageChange,
  getClientManagementData,
}) {
  const classes = useStyles();
  const [tableHeader, setTableHeader] = useState(ACTIVE_CLIENT_TABLE_HEADERS);

  const { handleRequestSort, order, orderBy } = useClientManagementTable();

  useEffect(() => {
    switch (tabValue) {
      case 0:
        return setTableHeader(ACTIVE_CLIENT_TABLE_HEADERS);
      case 1:
        return setTableHeader(INACTIVE_CLIENT_TABLE_HEADERS);
      case 2:
        return setTableHeader(COMPLETED_CLIENT_TABLE_HEADERS);
      case 3:
        return setTableHeader(LEADS_CLIENT_TABLE_HEADERS);
    }
  }, [tabValue]);

  const renderSwitch = (tabValue) => {
    switch (tabValue) {
      case 0:
        return stableSort(
          displayClientsData.response,
          getComparator(order, orderBy)
        ).map((data, index) => {
          return <ActiveClientTableRow data={data} index={index} key={index} />;
        });
      case 1:
        return stableSort(
          displayClientsData.response,
          getComparator(order, orderBy)
        ).map((data, index) => {
          return (
            <InActiveClientTableRow data={data} index={index} key={index} />
          );
        });
      case 2:
        return stableSort(
          displayClientsData.response,
          getComparator(order, orderBy)
        ).map((data, index) => {
          return (
            <CompletedClientTableRow data={data} index={index} key={index} />
          );
        });
      case 3:
        return stableSort(
          displayClientsData.response,
          getComparator(order, orderBy)
        ).map((data, index) => {
          return <LeadsClientTableRow data={data} index={index} key={index} />;
        });
    }
  };

  return (
    <React.Fragment>
      {displayClientsData &&
      displayClientsData.response &&
      displayClientsData.response.length ? (
        <Paper className={classes.paper} elevation={0}>
          <Grid container item xs={12} className={classes.root}>
            <TableContainer>
              <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  headCells={tableHeader}
                />
                <TableBody>
                  {displayClientsData &&
                  displayClientsData.response &&
                  displayClientsData.response.length
                    ? renderSwitch(tabValue)
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            {displayClientsData &&
            displayClientsData.response &&
            displayClientsData.response.length &&
            tabValue !== 0 ? (
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                style={{ margin: "16px 0" }}
              >
                <Pagination
                  count={displayClientsData && displayClientsData.totalPages}
                  shape="rounded"
                  variant="outlined"
                  onChange={handlePageChange}
                />
              </Grid>
            ) : null}
          </Grid>
        </Paper>
      ) : (
        <Typography
          className={Styles.navyBlueColor}
          variant="body1"
          style={{ fontWeight: 500, marginLeft: 20 }}
        >
          No data available
        </Typography>
      )}
    </React.Fragment>
  );
}
