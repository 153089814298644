import { cloneDeep } from "lodash";
import {
  SET_SP_KEY_STATS_DATA,
  SET_HB_KEY_STATS_DATA,
} from "./actionConstants";

const initialState = {
  SPKeyStatisticsData: null,
  HBKeyStatisticsData: null,
};

const keyStatistics = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_SP_KEY_STATS_DATA:
      newState = cloneDeep(state);
      newState.SPKeyStatisticsData = action.payload;
      return newState;
    case SET_HB_KEY_STATS_DATA:
      newState = cloneDeep(state);
      newState.HBKeyStatisticsData = action.payload;
      return newState;
    default:
      return state;
  }
};

export default keyStatistics;
