import { useSelector } from "react-redux";

import {
  ADD_SOCIAL_LINK,
  REMOVE_SOCIAL_LINK,
  UPDATE_SOCIAL_LINK,
} from "../../../souqh-redux/reducers/actionConstants";
import { useActions } from "../../useActions";

const actionCreators = {
  addSocialLink: (payload) => ({
    type: ADD_SOCIAL_LINK,
    payload,
  }),
  removeSocialLink: (payload) => ({
    type: REMOVE_SOCIAL_LINK,
    payload,
  }),
  updateSocialLink: (payload) => ({
    type: UPDATE_SOCIAL_LINK,
    payload,
  }),
};

export const useSocialLinks = () => {
  const socialLinks = useSelector(
    (state) => state.storefront.webPresence.socialLinks
  );
  const actions = useActions(actionCreators);

  return { socialLinks, actions };
};

export const useSocialLink = (index) => {
  const socialLink = useSelector(
    (state) => state.storefront.webPresence.socialLinks[index]
  );
  const actions = useActions(actionCreators);

  return { socialLink, actions };
};
