import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { useAddNewSP } from "souqh-react-redux-hooks/common/useAddNewSP";
import { useBusinessType } from "souqh-react-redux-hooks/useBusinessType";
import Styles from "../../Styles.module.css";
import { isMobileDevice } from "../../utils/AppUtils";
import MaskedMobileInput from "../MaskedMobileInput";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 500,
    minWidth: !isMobileDevice() && 600,
  },
  marginTop20: {
    marginTop: 20,
  },
  semiBold16: {
    fontSize: "1rem",
    fontWeight: 600,
  },
}));

export default function AddNewSP({
  openAddNewSP,
  setOpenAddNewSP,
  setAddSPSucessMsg,
}) {
  const classes = useStyles();
  const { businessTypes } = useBusinessType();
  const {
    validations,
    allowAction,
    newSP,
    actions: { saveNewSP, addNewSP, clearData },
  } = useAddNewSP();

  return (
    <Dialog
      open={openAddNewSP}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Add New Service Provider</Typography>
      </DialogTitle>
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        style={{ width: "100%" }}
      />
      <DialogContent>
        <DialogContentText>
          <Grid item className={classes.marginTop20}>
            <Autocomplete
              value={newSP.serviceProviderType}
              fullWidth
              onChange={(event, value) => {
                saveNewSP({
                  serviceProviderType: value,
                });
              }}
              id="service-provider-type"
              options={businessTypes || []}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => {
                return option.name === value.name;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Service Provider Type"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item className={classes.marginTop20}>
            <TextField
              id="newSPfullName"
              placeholder="Full Name"
              label="Full Name"
              name="fullName"
              variant="outlined"
              fullWidth
              inputProps={{
                maxLength: 256,
              }}
              onChange={(event) => {
                saveNewSP({
                  fullName: event.target.value,
                });
              }}
              autoComplete="full-name"
            />
          </Grid>
          <Grid item className={classes.marginTop20}>
            <TextField
              error={!!validations.email}
              helperText={validations.email}
              id="newSPemailAddress"
              label="Email Address"
              placeholder="Email Address"
              name="emailAddress"
              variant="outlined"
              fullWidth
              onChange={(event) => {
                saveNewSP({ email: event.target.value });
              }}
              autoComplete="email-address"
              value={newSP.email}
            />
          </Grid>
          <Grid item className={classes.marginTop20}>
            <MaskedMobileInput
              value={newSP.phoneNumber}
              error={!!validations.mobileNumber}
              helperText={
                validations.mobileNumber && validations.mobileNumber.join(" ")
              }
              id="newSPphoneNumber"
              label="Phone Number"
              name="phoneNumber"
              variant="outlined"
              type="tel"
              fullWidth
              onChange={(e) => {
                saveNewSP({
                  phoneNumber: e.target.value.replaceAll("-", ""),
                });
              }}
            ></MaskedMobileInput>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        style={{ width: "100%" }}
      />
      <DialogActions style={{ paddingRight: 20 }}>
        <Button
          variant="outlined"
          className={Styles.navyBlueColorForce}
          style={{ textTransform: "capitalize", marginRight: 10 }}
          onClick={() => {
            clearData();
            setOpenAddNewSP(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!allowAction}
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            addNewSP(newSP);
            setOpenAddNewSP(false);
            setAddSPSucessMsg(true);
            setTimeout(() => {
              setAddSPSucessMsg(false);
            }, 4000);
            clearData();
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
