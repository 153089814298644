import React, { useState } from "react";
import {
  Avatar,
  Card,
  CardHeader,
  Grid,
  Typography,
  CardContent,
  Divider,
  ButtonBase,
  IconButton,
  CardActions,
  Chip,
  Tooltip,
  Button,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import MessageIcon from "@material-ui/icons/Message";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Styles from "../../../Styles.module.css";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import clsx from "clsx";
import * as moment from "moment";
import DocumentPreviewDialog from "../../HomeBuyer/Explore/StorefrontPage/DocumentPreviewDialog";
import { useLeadsClientInfoCard } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useLeadsClientInfoCard";
import RejectionReasonDialog from "./RejectionReasonDialog";
import { useNavigateToMessage } from "souqh-react-redux-hooks/common/useNavigateToMessage";
import { useHistory, useRouteMatch } from "react-router-dom";
import MoreOptionsOnClientInfo from "./MoreOptionsOnClientInfo";
import Attachments from "../../HomeBuyer/SPManagement/Attachments";
import {
  getUploadedDocuments,
  isMobileDevice,
  isProPlan,
  viewClientDetails,
} from "../../../utils/AppUtils";
import UpgradePlanIconSection from "../../common/UpgradePlanIconSection";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import ClientStatus from "./ClientStatus";
import RentalDetailsPopup from "../../common/RentalDetailsPopup";
import ServiceRequestFulfilledDialog from "./ServiceRequestFulfilledDialog";
import { useMessaging } from "souqh-react-redux-hooks/messaging/useMessaging";
import { cloneDeep } from "lodash";

export default function LeadsClientInfoCard({ data, getClientManagementData }) {
  const { navigateMessage } = useNavigateToMessage();
  let history = useHistory();
  let { path } = useRouteMatch();
  const userInfo = useAuthUser();

  const [openDocumetDialog, setOpenDocumetDialog] = useState(false);
  const [documentItem, setDocumentItem] = useState(null);
  const [openRejectionReasonDialog, setOpenRejectionReasonDialog] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [docCategoryId, setDocCategoryId] = useState(null);
  const [docNameForDownload, setDocNameForDownload] = useState(null);
  const [docUserId, setDocUserId] = useState(null);
  const [
    openServiceRequestFulfilledDialog,
    setOpenServiceRequestFulfilledDialog,
  ] = useState(false);
  const { requestServiceMessageFromBackground } = useMessaging(false);

  const [openRentalDetailsPopup, setOpenRentalDetailsPopup] = useState(false);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const { accpetServiceRequest } = useLeadsClientInfoCard();

  const showMoreInfoCondition = isMobileDevice() && showMoreInfo;

  const timelineOptions = [
    { name: "Urgent", value: "URGENT" },
    { name: "As Soon As Possible", value: "AS_SOON_AS_POSSIBLE" },
    { name: "1-2 weeks", value: "ONE_TO_TWO_WEEKS" },
    { name: "2-4 weeks", value: "TWO_TO_FOUR_WEEKS" },
    { name: "4-8 weeks", value: "FOUR_TO_EIGHT_WEEKS" },
    { name: "8 weeks plus", value: "EIGHT_WEEKS_PLUS" },
  ];

  let formattedTimeline =
    data.broadCastServiceRequestDto &&
    timelineOptions.filter(
      (item) => item.value === data.broadCastServiceRequestDto.timeLine
    )[0];

  return (
    <>
      <RentalDetailsPopup
        openRentalDetailsPopup={openRentalDetailsPopup}
        setOpenRentalDetailsPopup={setOpenRentalDetailsPopup}
        data={data}
      />
      <ServiceRequestFulfilledDialog
        openServiceRequestFulfilledDialog={openServiceRequestFulfilledDialog}
        setOpenServiceRequestFulfilledDialog={
          setOpenServiceRequestFulfilledDialog
        }
      />
      <DocumentPreviewDialog
        openDocumetDialog={openDocumetDialog}
        setOpenDocumetDialog={setOpenDocumetDialog}
        documentItem={documentItem}
        setDocumentItem={setDocumentItem}
        downloadUrl={downloadUrl}
        source="leads-page"
        docCategoryId={docCategoryId}
        docNameForDownload={docNameForDownload}
        docUserId={docUserId}
      />
      <RejectionReasonDialog
        openRejectionReasonDialog={openRejectionReasonDialog}
        setOpenRejectionReasonDialog={setOpenRejectionReasonDialog}
        getClientManagementData={getClientManagementData}
        title={"Decline Request"}
        message={`Lead Rejected - ${data.name}`}
        actionType="REJECT_LEAD_REQUEST"
      />
      <Card
        elevation={0}
        style={{ border: !isMobileDevice() && "1px solid #0000001F" }}
      >
        <MoreOptionsOnClientInfo
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          showTagMemberOption={true}
          showSendMessageOption={true}
          email={data.email}
          notes={data.notes}
          serviceRequestId={data.serviceRequestId}
          toUserId={data.hbDetails.userId}
        />
        <CardHeader
          onClick={(event) => {
            if (!data.broadCastServiceRequestDto) {
              viewClientDetails(history, path, data);
            }
          }}
          style={{
            padding: isMobileDevice() && "16px 8px 16px 0px",
            cursor: !data.broadCastServiceRequestDto && "pointer",
          }}
          avatar={
            <Avatar
              style={{ height: 76, width: 76, borderRadius: 4 }}
              variant="square"
              src={data.avatarUrl}
            >
              {(data.name || "").charAt(0)}
            </Avatar>
          }
          action={
            <ButtonBase>
              <MoreHorizIcon
                className={Styles.navyBlueColor}
                onClick={handleClick}
              />
            </ButtonBase>
          }
          title={
            <Typography
              className={Styles.navyBlueColor}
              variant="body1"
              style={{ fontWeight: 500, marginLeft: 10 }}
            >
              {data.hbDetails.name}
            </Typography>
          }
          subheader={
            <>
              <Grid container>
                <Grid item xs={3}>
                  <IconButton>
                    <MessageIcon
                      className={Styles.navyBlueColor}
                      onClick={(event) => {
                        event.stopPropagation();
                        if (data.notes) {
                          const temp = {
                            email: data.email,
                            value: data.notes,
                          };
                          localStorage.setItem(
                            "messageContent",
                            JSON.stringify(temp)
                          );
                          navigateMessage(event, data.email, true);
                        } else {
                          navigateMessage(event, data.email, false);
                        }
                      }}
                    />
                  </IconButton>
                </Grid>
                <Grid item xs={3}>
                  {/* <IconButton> */}
                  <div style={{ position: "relative", top: 9 }}>
                    <DateRangeIcon
                      style={{
                        color: isProPlan(userInfo) && "grey",
                        cursor: isProPlan(userInfo) ? "default" : "pointer",
                      }}
                      className={Styles.navyBlueColor}
                      onClick={(event) => {
                        event.stopPropagation();
                        if (!isProPlan(userInfo)) {
                          history.push(
                            "/serviceprovider/calendar?scheduleMeeting=true"
                          );
                        }
                      }}
                    />
                    {isProPlan(userInfo) && (
                      <UpgradePlanIconSection
                        iconBtnStyle={{
                          position: "absolute",
                          fontSize: 16,
                          left: 12,
                          top: -10,
                          fill: "#fa7e61",
                        }}
                        iconStyle={{
                          position: "absolute",
                          fontSize: 16,
                          top: 5,
                          fill: "#fa7e61",
                        }}
                      />
                    )}
                  </div>
                  {/* </IconButton> */}
                </Grid>
              </Grid>
            </>
          }
        />
        {(!isMobileDevice() || showMoreInfoCondition) && (
          <React.Fragment>
            <Divider />
            <CardContent>
              <Grid container>
                <Grid item xs={6}>
                  <Grid item>
                    <Typography
                      className={Styles.navyBlueColor}
                      variant="body1"
                      style={{ fontWeight: 500 }}
                    >
                      Request Date
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      className={clsx(Styles.navyBlueColor, Styles.font14)}
                    >
                      {moment(data.requestedDate).format("MMM DD, YYYY")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Divider style={{ margin: "16px 0px" }} />
              {/* <Grid container item>
          <Grid item>
            <Typography
              className={Styles.navyBlueColor}
              variant="body1"
              style={{ fontWeight: 500 }}
            >
              Status
            </Typography>
          </Grid>
          <Grid
            style={{
              backgroundColor: "#c9cff3",
              color: "#5065d9",
              textTransform: "uppercase",
              marginLeft: 10,
              padding: 5,
              borderRadius: 4,
            }}
            item
          >
            <Typography className={clsx(Styles.navyBlueColor, Styles.font14)}>
              {data.status}
            </Typography>
          </Grid>
        </Grid> 
        <Divider style={{ margin: "16px 0px" }} />*/}
              {data.broadCastServiceRequestDto ? (
                <>
                  <Grid container item xs={12}>
                    <Grid item>
                      <Typography
                        className={Styles.navyBlueColor}
                        variant="body1"
                        style={{ fontWeight: 500 }}
                      >
                        Category:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Tooltip
                        title={data.broadCastServiceRequestDto?.businessType}
                      >
                        <Typography
                          className={clsx(
                            Styles.navyBlueColor,
                            Styles.font14,
                            Styles.sqEllipsisTwoLines
                          )}
                          style={{
                            height: 30,
                            marginTop: 2,
                            marginLeft: 8,
                          }}
                        >
                          {data.broadCastServiceRequestDto?.businessType}
                        </Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12}>
                    <Grid item>
                      <Typography
                        className={Styles.navyBlueColor}
                        variant="body1"
                        style={{ fontWeight: 500 }}
                      >
                        City:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Tooltip title={data.broadCastServiceRequestDto?.city}>
                        <Typography
                          className={clsx(
                            Styles.navyBlueColor,
                            Styles.font14,
                            Styles.sqEllipsisTwoLines
                          )}
                          style={{
                            height: 30,
                            marginTop: 2,
                            marginLeft: 8,
                          }}
                        >
                          {data.broadCastServiceRequestDto?.city}
                        </Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item>
                      <Typography
                        className={Styles.navyBlueColor}
                        variant="body1"
                        style={{ fontWeight: 500 }}
                      >
                        Timeline:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Tooltip title={formattedTimeline?.name}>
                        <Typography
                          className={clsx(
                            Styles.navyBlueColor,
                            Styles.font14,
                            Styles.sqEllipsisTwoLines
                          )}
                          style={{
                            height: 30,
                            marginTop: 2,
                            marginLeft: 8,
                          }}
                        >
                          {formattedTimeline?.name}
                        </Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item xs={2}>
                      <Typography
                        className={Styles.navyBlueColor}
                        variant="body1"
                        style={{ fontWeight: 500 }}
                      >
                        Status:
                      </Typography>
                    </Grid>
                    <Grid item xs={10} style={{ paddingLeft: 8 }}>
                      <ClientStatus
                        clientStatus={data.broadCastServiceRequestDto?.status}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Typography
                  className={clsx(
                    Styles.navyBlueColor,
                    Styles.font14,
                    Styles.sqEllipsisTwoLines
                  )}
                  style={{ height: 122 }}
                >
                  No details available
                </Typography>
              )}
              <Divider style={{ margin: "16px 0px" }} />
              <Grid item>
                <Typography
                  className={Styles.navyBlueColor}
                  variant="body1"
                  style={{ fontWeight: 500 }}
                >
                  Service Details
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Tooltip title={data.notes}>
                  <Typography
                    className={clsx(
                      Styles.navyBlueColor,
                      Styles.font14,
                      Styles.sqEllipsisTwoLines
                    )}
                    style={{ height: 40 }}
                  >
                    {data.notes || "No details available"}
                  </Typography>
                </Tooltip>
              </Grid>
              <Divider style={{ margin: "16px 0px" }} />
              <Grid item>
                <Typography
                  className={Styles.navyBlueColor}
                  variant="body1"
                  style={{ fontWeight: 500 }}
                >
                  Attachments
                </Typography>
              </Grid>
              <Grid
                item
                container
                style={{
                  minHeight:
                    (!data.broadCastServiceRequestDto ||
                      !data.broadCastServiceRequestDto
                        .rentalForNewComersDetails) &&
                    110,
                }}
              >
                {data.attachments && data.attachments.length ? (
                  <Attachments
                    attachments={data.attachments}
                    limit={
                      data.broadCastServiceRequestDto &&
                      data.broadCastServiceRequestDto.rentalForNewComersDetails
                        ? 1
                        : 2
                    }
                    setDocumentItem={setDocumentItem}
                    getUploadedDocuments={getUploadedDocuments}
                    setOpenDocumetDialog={setOpenDocumetDialog}
                    setDownloadUrl={setDownloadUrl}
                    setDocCategoryId={setDocCategoryId}
                    setDocNameForDownload={setDocNameForDownload}
                    setDocUserId={setDocUserId}
                  />
                ) : (
                  <Typography
                    className={clsx(Styles.navyBlueColor, Styles.font14)}
                    style={{ fontStyle: "italic", minHeight: "3em" }}
                  >
                    No Attachments
                  </Typography>
                )}
              </Grid>
              {data.broadCastServiceRequestDto &&
                data.broadCastServiceRequestDto.rentalForNewComersDetails && (
                  <Divider style={{ margin: "15px 0px" }} />
                )}
              {data.broadCastServiceRequestDto &&
                data.broadCastServiceRequestDto.rentalForNewComersDetails && (
                  <Grid container item xs={12}>
                    <Button
                      className={Styles.navyBlueColorForce}
                      variant="outlined"
                      onClick={() => setOpenRentalDetailsPopup(true)}
                      style={{ textTransform: "capitalize" }}
                    >
                      Show Rental Details
                    </Button>
                  </Grid>
                )}
            </CardContent>
          </React.Fragment>
        )}
        {(!isMobileDevice() || showMoreInfoCondition) && (
          <React.Fragment>
            <Divider />
            <CardActions>
              <Grid container item alignItems="center">
                <Grid item xs={7} style={{ paddingLeft: 10 }}>
                  <Typography
                    className={clsx(Styles.navyBlueColor, Styles.font14)}
                  >
                    Accept lead to view complete client profile
                  </Typography>
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end">
                  <Tooltip title="Accept">
                    <CheckCircleIcon
                      style={{
                        color:
                          !data.broadCastServiceRequestDto ||
                          (data.broadCastServiceRequestDto &&
                            data.broadCastServiceRequestDto?.status ===
                              "ACTIVE")
                            ? "#5cd39c"
                            : "#868da6",
                        fontSize: 40,
                        cursor:
                          // (!data.broadCastServiceRequestDto ||
                          //   (data.broadCastServiceRequestDto &&
                          //     data.broadCastServiceRequestDto?.status ===
                          //       "ACTIVE")) &&
                          "pointer",
                      }}
                      onClick={(event) => {
                        if (
                          !data.broadCastServiceRequestDto ||
                          (data.broadCastServiceRequestDto &&
                            data.broadCastServiceRequestDto?.status ===
                              "ACTIVE")
                        ) {
                          accpetServiceRequest(data.serviceRequestId, (res) => {
                            if (res && res.data.status === 200) {
                              getClientManagementData();
                              // if (data.broadCastServiceRequestDto) {
                              //   requestServiceMessageFromBackground(
                              //     data.email,
                              //     data.notes,
                              //     []
                              //     // data.attachments
                              //   ).then(() =>
                              //     navigateMessage(event, data.email, true)
                              //   );
                              // } else {
                                // viewClientDetails(history, path, data);
                                navigateMessage(event, data.email, true);
                              // }
                            }
                          });
                        } else {
                          setOpenServiceRequestFulfilledDialog(true);
                        }
                      }}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={2} container justifyContent="flex-end">
                  <Tooltip title="Reject">
                    <CancelIcon
                      style={{
                        color: "#f04f5b",
                        fontSize: 40,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setOpenRejectionReasonDialog({
                          open: true,
                          id: data.serviceRequestId,
                        });
                      }}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            </CardActions>
          </React.Fragment>
        )}
        {isMobileDevice() && (
          <Grid container item xs={12} justifyContent="flex-end">
            <Button
              variant="text"
              className={Styles.primaryTxtAccentForce}
              style={{
                textTransform: "capitalize",
                marginTop: showMoreInfo ? -12 : -36,
              }}
              onClick={(event) => {
                event.stopPropagation();
                setShowMoreInfo(!showMoreInfo);
              }}
            >
              {showMoreInfo ? "Show less" : "Show more"}
            </Button>
          </Grid>
        )}
      </Card>
    </>
  );
}
