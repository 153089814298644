import { Link, makeStyles } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import Styles from "../../../Styles.module.css";
import { useDocuments } from "souqh-react-redux-hooks/serviceProvider/documents/useDocuments";

const useStyles = makeStyles((theme) => ({
  paddingRight10: {
    paddingRight: 10,
  },
  tableHeading: {
    fontWeight: "bold",
  },
  cursorPointer: {
    cursor: "pointer",
  },
}));

export default function SPClientCell({
  row,
  setOpenSharedWithPopup,
  getSharedWithTooltip,
  setOpenSignedWithPopup,
}) {
  const classes = useStyles();
  const { getSignedWithTooltip } = useDocuments();
  if (row.sharedWith.length) {
    return (
      <>
        <Link
          style={{ float: "left" }}
          underline="always"
          className={clsx(
            classes.cursorPointer,
            Styles.navyBlueColorForce,
            Styles.sqEllipsisOneLine
          )}
        >
          {row.sharedWith[0]}
        </Link>
        {getSharedWithTooltip(row.sharedWith) ? (
          <Link
            className={clsx(classes.cursorPointer, Styles.navyBlueColorForce)}
            style={{ paddingLeft: 4 }}
            underline="always"
            onClick={() => {
              setOpenSharedWithPopup({
                open: true,
                sharedWithList: row.sharedWith,
              });
            }}
          >{`+${row.sharedWith.length - 1} more`}</Link>
        ) : null}
      </>
    );
  }

  if (row.signedWith && row.signedWith.length) {
    return (
      <>
        <Link
          style={{ float: "left" }}
          underline="always"
          className={clsx(classes.cursorPointer, Styles.navyBlueColorForce)}
        >
          {row.signedWith[0].email}
        </Link>
        {getSignedWithTooltip(row.signedWith) ? (
          <Link
            className={clsx(classes.cursorPointer, Styles.navyBlueColorForce)}
            style={{ paddingLeft: 4 }}
            underline="always"
            onClick={() => {
              setOpenSignedWithPopup &&
                setOpenSignedWithPopup({
                  open: true,
                  signedWithList: row.signedWith,
                });
            }}
          >{`+${row.signedWith.length - 1} more`}</Link>
        ) : null}
      </>
    );
  }
  return null;
}
