import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useApiClient } from "../../useApiClient";
import * as moment from "moment";
import { getDatesRange } from "../../../web/src/utils/AppUtils";

export const useSalesAnalytics = (days) => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();
  const [salesAnalyticsData, setSalesAnalyticsData] = useState();
  const [previousDaysCount, setPreviousDaysCount] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [selectiveArray, setSelectiveArray] = useState([]);
  const [allArray, setAllArray] = useState([]);
  const [finalServiceReqData, setFinalServiceReqData] = useState([]);
  const [finalServicesData, setFinalServicesData] = useState([]);

  useEffect(() => {
    if (days === "THIRTY_DAYS") {
      setPreviousDaysCount(30);
    } else if (days === "NINETY_DAYS") {
      setPreviousDaysCount(90);
    }
  }, [days]);

  useEffect(() => {
    if (
      days === "ALL_DAYS" &&
      salesAnalyticsData &&
      salesAnalyticsData.srTimelineAnalyticsData &&
      salesAnalyticsData.srTimelineAnalyticsData.length
    ) {
      setStartDate(
        new Date(salesAnalyticsData.srTimelineAnalyticsData[0].date)
      );
    } else {
      const date = new Date();
      setStartDate(new Date().setDate(date.getDate() - previousDaysCount));
    }
  }, [salesAnalyticsData, days, previousDaysCount]);

  useEffect(() => {
    const date = new Date();
    getDatesRange(startDate, date, 1, setAllArray, setSelectiveArray, true);
    if (days === "THIRTY_DAYS") {
      getDatesRange(startDate, date, 3, setAllArray, setSelectiveArray, false);
    } else if (days === "NINETY_DAYS") {
      getDatesRange(startDate, date, 7, setAllArray, setSelectiveArray, false);
    } else {
      let end = moment();
      let start = moment(startDate, "DD-MM-YYYY");
      let diff = end.diff(start, "days");
      let step = Math.round(diff / 10);
      let stepSize = step || 1;

      if (diff > 0) {
        getDatesRange(
          startDate,
          date,
          stepSize,
          setAllArray,
          setSelectiveArray,
          false
        );
      }
    }
  }, [startDate]);

  useEffect(() => {
    let serviceData = [];
    salesAnalyticsData &&
      salesAnalyticsData.topSellingServices &&
      salesAnalyticsData.topSellingServices.length &&
      salesAnalyticsData.topSellingServices.forEach((element) => {
        serviceData.push({ name: element.name, value: element.count });
      });
    setFinalServicesData(serviceData);
  }, [salesAnalyticsData]);

  useEffect(() => {
    if (allArray && allArray.length) {
      let finalData = [];
      allArray.map((item) => {
        let index = -1;
        if (
          salesAnalyticsData &&
          salesAnalyticsData.srTimelineAnalyticsData &&
          salesAnalyticsData.srTimelineAnalyticsData.length
        ) {
          index =
            salesAnalyticsData.srTimelineAnalyticsData.length &&
            salesAnalyticsData.srTimelineAnalyticsData.findIndex(
              (item2) =>
                moment(item2.date).format("MMM DD, YYYY").split(",")[0] === item
            );
        }

        if (index >= 0) {
          finalData.push({
            year: selectiveArray.includes(item) ? item : "",
            value: salesAnalyticsData.srTimelineAnalyticsData[index].count,
            xValue: item,
          });
        } else {
          finalData.push({
            year: selectiveArray.includes(item) ? item : "",
            value: 0,
            xValue: item,
          });
        }
        setFinalServiceReqData(finalData);
      });
    }
  }, [salesAnalyticsData, allArray]);

  const getSalesAnalyticsData = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/service-provider/storefront/analytics/sales?days=${days}`)
      .then(function (response) {
        setSalesAnalyticsData(response.data);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    getSalesAnalyticsData,
    salesAnalyticsData,
    finalServiceReqData,
    finalServicesData,
  };
};
