import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Step,
  StepButton,
  Stepper,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useHomeOwnershipTimeline } from "souqh-react-redux-hooks/common/useHomeOwnershipTimeline";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import Styles from "../../../Styles.module.css";
import { covertTimeStampToDate } from "../../../utils/AppUtils";
import clsx from "clsx";
import NoDataAvailable from "../../common/NoDataAvailable";
import { useHistory } from "react-router-dom";
import IndividualSPTimeline from "./IndividualSPTimeline";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "24px 0px",
    // justifyContent: "center",
    "& .MuiStepConnector-horizontal": {
      top: 17,
      left: "calc(-50% + 15px)",
      right: "calc(50% + 15px)",
      position: "absolute",
    },
    "& .MuiStepConnector-line": {
      borderTopWidth: 5,
      borderColor: "#66D7D1",
    },
    "& .MuiStep-horizontal": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  selectBox: {
    "& .MuiSelect-root": {
      color: "white",
      fontWeight: 700,
      fontSize: "0.875rem", // 14 px
    },
    "& .MuiSelect-icon": {
      color: "white",
    },
    "& .MuiInput-underline::after": {
      borderBottom: "none",
    },
  },
}));

export default function HomeOwnershipTimelineCard({
  xlUp,
  isMobileDevice,
  setHbJourneyView,
  hbJourneyView,
}) {
  const classes = useStyles();
  const userInfo = useAuthUser();
  const history = useHistory();

  const {
    getAllSPsTimelineData,
    allTimelineData,
    getIndividualSPsTimelineData,
    timelinespType,
    setTimelinespType,
    showIndividualSPData,
    setShowIndividualSPData,
    individualSPTimelineData,
    actions,
  } = useHomeOwnershipTimeline(userInfo.userId);

  useEffect(() => {
    getAllSPsTimelineData();
  }, [timelinespType]);

  const documentActions = [
    "E_SIGN_DOC_SHARED",
    "REFERENCE_DOCUMENT_SHARED_WITH_CLIENT",
    "REQUEST_DOCUMENT",
    "REFERENCE_DOCUMENT_SHARED_BY_CLIENT",
  ];

  const allSPTimeline = () => {
    return (
      <React.Fragment>
        {allTimelineData && allTimelineData.length ? (
          <Stepper
            // activeStep={activeStep}
            alternativeLabel
            className={classes.root}
            style={{
              display: "flex",
              flexWrap: "nowrap",
              overflowX: "auto",
              minHeight: "13rem",
            }}
          >
            {allTimelineData && allTimelineData.length
              ? allTimelineData.map((data, index) => {
                  const storeFrontContent = JSON.parse(data.storeFrontContent);
                  return (
                    <Step
                      key={index}
                      onClick={() => {
                        setShowIndividualSPData(true);
                        getIndividualSPsTimelineData(
                          data.serviceProviderId,
                          userInfo.userId
                        );
                      }}
                      style={{
                        minWidth: isMobileDevice() ? "60%" : "30%",
                        cursor: "pointer",
                        maxWidth: 300,
                      }}
                    >
                      <StepButton
                        icon={
                          <div
                            style={{
                              borderRadius: "50%",
                              border: "5px solid #66D7D1",
                              height: 22,
                              width: 22,
                              marginTop: 3,
                            }}
                          ></div>
                        }
                      >
                        <img
                          style={{ height: 38, width: 38 }}
                          src={
                            storeFrontContent.displayPicture ||
                            "/images/logo.svg"
                          }
                        ></img>
                        <Typography
                          className={Styles.navyBlueColorForce}
                          style={{
                            fontWeight: 600,
                            fontSize: 14,
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          {storeFrontContent.storefrontName}
                        </Typography>
                        <Typography
                          className={Styles.navyBlueColorForce}
                          style={{ fontSize: 14 }}
                        >
                          {data.businessTypeDTOList[0].shortName}
                        </Typography>
                        <Typography style={{ color: "#868DA6", fontSize: 11 }}>
                          {covertTimeStampToDate(data.requestedDate)}
                        </Typography>
                      </StepButton>
                    </Step>
                  );
                })
              : null}
          </Stepper>
        ) : (
          noDataSection(
            "Looks like you have not interacted with any service providers. Explore storefronts to request services"
          )
        )}
      </React.Fragment>
    );
  };

  const noDataSection = (msg) => {
    return (
      <NoDataAvailable>
        <Typography
          style={{
            color: "#868DA6",
            fontSize: 11,
            padding: "0px 16px",
          }}
        >
          {msg || "Looks like you don't have any information"}
        </Typography>
      </NoDataAvailable>
    );
  };

  return (
    <Grid
      container
      item
      xs={12}
      style={{ height: "100%", paddingRight: !isMobileDevice() && 20 }}
    >
      <Card
        raised
        style={{
          borderRadius: 8,
          marginRight: !isMobileDevice() && 20,
          height: "100%",
          minWidth: "100%",
        }}
      >
        <CardHeader
          style={{
            color: "white",
            fontWeight: 700,
            fontSize: xlUp ? "1rem" : "0.875rem", // 14 px
            paddingBottom: 6,
          }}
          className={clsx(Styles.primaryBackground, classes.selectBox)}
          title={
            <Grid container xs={12}>
              <Grid
                item
                xs={hbJourneyView === "home_buying" ? 8 : 4}
                md={6}
                lg={8}
              >
                <FormControl>
                  <Select
                    value={hbJourneyView}
                    onChange={(event) => setHbJourneyView(event.target.value)}
                  >
                    <MenuItem value={"home_buying"}>
                      Home Buying Journey
                    </MenuItem>
                    <MenuItem value={"home_ownership"}>
                      {isMobileDevice()
                        ? "Timeline"
                        : "Home Ownership Timeline"}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {!showIndividualSPData && (
                <Grid
                  container
                  item
                  xs={8}
                  md={6}
                  lg={4}
                  justifyContent="flex-end"
                >
                  <FormControl>
                    <Select
                      value={timelinespType}
                      onChange={(event) =>
                        setTimelinespType(event.target.value)
                      }
                    >
                      <MenuItem value={"all"}>All Service Providers</MenuItem>
                      <MenuItem value={"active"}>
                        Active Service Providers
                      </MenuItem>
                      <MenuItem value={"completed"}>
                        Completed Service Providers
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          }
          titleTypographyProps={{ variant: "" }}
        ></CardHeader>
        <CardContent style={{ maxWidth: !xlUp && 800 }}>
          {showIndividualSPData ? (
            <IndividualSPTimeline
              individualSPTimelineData={individualSPTimelineData}
              xlUp={xlUp}
              setShowIndividualSPData={setShowIndividualSPData}
              actions={actions}
              documentActions={documentActions}
              isHomeOwnershipWidget={true}
            />
          ) : (
            allSPTimeline()
          )}
        </CardContent>
      </Card>
    </Grid>
  );
}
