import { useAuthUser } from "../useLogin";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_CATEGORY,
  UPDATE_CATEGORIES,
} from "../../souqh-redux/reducers/actionConstants";
import { useActions } from "../useActions";
import { useState } from "react";
import { useApiClient } from "../useApiClient";
import { color_success } from "../toasterColors";

const actionCreator = {
  setCategory: (payload) => ({ type: SET_CATEGORY, payload }),
};

export const useAddCategory = (setOpenAddCategoryDialog) => {
  const { apiClient } = useApiClient();

  const userInfo = useAuthUser();
  const dispatch = useDispatch();
  const actions = useActions(actionCreator);
  const categoryName = useSelector((state) => state.documents.categoryName);
  const [addErrorMsg, setAddErrorMsg] = useState(false);
  const [emptyAddErrorMsg, setEmptyAddErrorMsg] = useState(false);

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.code === "Enter") {
      addCategory((response) => {
        if (response && response.data.status === 200) {
          setOpenAddCategoryDialog(false);
        }
      });
    }
  };

  const addCategory = (onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post("category/create", { name: categoryName, userId: userInfo.userId })
      .then((res) => {
        if (res && res.data.status === 200) {
          dispatch({
            type: UPDATE_CATEGORIES,
            payload: {
              category: {
                id: res.data.result.id,
                name: res.data.result.name,
                isDefault: res.data.result.isDefault,
                documents: [],
              },
            },
          });
          dispatch({
            type: "SET_TOASTER",
            payload: {
              message: `You have added a new category - ${categoryName}`,
              bgColor: color_success,
              open: true,
            },
          });
        } else if (res && res.status === 409) {
          setAddErrorMsg(true); // error for already exists
        } else if (res && res.status === 400) {
          setEmptyAddErrorMsg(true); // error for blank name
        }
        onResult && onResult(res);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    addCategory,
    actions,
    handleKeypress,
    addErrorMsg,
    setAddErrorMsg,
    emptyAddErrorMsg,
    setEmptyAddErrorMsg,
    categoryName,
  };
};
