import { Divider } from "@material-ui/core";
import React from "react";
import HBTermsOfUse from "./HBTermsOfUse";
import TermsOfUse from "./SPTermsOfUse";

export default function CommonTermsOfUse() {
  return (
    <div>
      <HBTermsOfUse />
      <Divider />
      <TermsOfUse />
    </div>
  );
}
