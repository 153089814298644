import { Divider, Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import Styles from "../../Styles.module.css";
import PersonIcon from "@material-ui/icons/Person";
import StoreMallDirectoryIcon from "@material-ui/icons/StoreMallDirectory";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import { useHBKeyStatisticsWidget } from "souqh-react-redux-hooks/homeBuyer/useHBKeyStatisticsWidget";
import {
  abbreviateNumber,
  isMobileDevice,
  isTabletDevice,
} from "../../utils/AppUtils";

export default function HBKeyStatisticsWidget({ xlUp }) {
  const { getHBkeyStatisticsData, HBKeyStatisticsData } =
    useHBKeyStatisticsWidget();

  useEffect(() => {
    getHBkeyStatisticsData();
  }, []);

  const keyStatsData = [
    {
      image: <PersonIcon className={Styles.primaryTxtAccent} />,
      text: "Active Service Providers On Souqh",
      count: HBKeyStatisticsData
        ? HBKeyStatisticsData.activeServiceProvider
        : 0,
    },
    {
      image: <AssignmentIndOutlinedIcon className={Styles.primaryTxtAccent} />,
      text: "Your Services Requested",
      count: HBKeyStatisticsData
        ? abbreviateNumber(HBKeyStatisticsData.serviceRequested)
        : 0,
    },
    {
      image: <StoreMallDirectoryIcon className={Styles.primaryTxtAccent} />,
      text: "Your Storefront Visits",
      count: HBKeyStatisticsData ? HBKeyStatisticsData.storeFrontVisit : 0,
    },
    {
      image: <InsertDriveFileIcon className={Styles.primaryTxtAccent} />,
      text: "Your Documents Shared",
      count: HBKeyStatisticsData ? HBKeyStatisticsData.documentShared : 0,
    },
    {
      image: <CardGiftcardIcon className={Styles.primaryTxtAccent} />,
      text: "Your Referral Credits",
      count: HBKeyStatisticsData ? HBKeyStatisticsData.referralcredits : 0,
    },
  ];
  return (
    <Grid
      container
      justifyContent={!isMobileDevice() && !isTabletDevice() && "space-evenly"}
      style={
        isMobileDevice() || isTabletDevice()
          ? {
              display: "flex",
              flexWrap: "nowrap",
              overflowX: "auto",
              padding: "50px 10px",
            }
          : { padding: "50px 20px" }
      }
    >
      {keyStatsData.map((data, index) => (
        <React.Fragment>
          <Grid
            key={index}
            container
            item
            xs={2}
            alignItems="center"
            alignContent="center"
            style={{
              minWidth: isMobileDevice()
                ? "44%"
                : isTabletDevice()
                ? "26%"
                : "14%",
              padding: (isMobileDevice() || isTabletDevice()) && "0px 10px",
            }}
          >
            <Grid
              item
              xs={12}
              style={{
                textAlign: "center",
                height: "15%",
              }}
            >
              {data.image}
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                textAlign: "center",
                height: "25%",
              }}
            >
              <Typography
                className={Styles.navyBlueColor}
                style={{ fontSize: xlUp ? 16 : 12, fontWeight: 500 }}
              >
                {data.text}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                textAlign: "center",
                height: "20%",
              }}
            >
              <Typography
                className={Styles.navyBlueColor}
                variant={xlUp ? "h5" : "h6"}
              >
                {data.count}
              </Typography>
            </Grid>
          </Grid>
          {index !== 4 ? <Divider orientation="vertical" /> : null}
        </React.Fragment>
      ))}
    </Grid>
  );
}
