import React from "react";
import { Typography, Button, Divider, Grid } from "@material-ui/core/";
import clsx from "clsx";
import Styles from "../../../Styles.module.css";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

export default function UploadActionBtns({
  documentList,
  duplicateFileName,
  activeSection,
  setActiveSection,
  checkForDocumentDescriptions,
  section,
  uploadFiles,
  handleClose,
  selectedActiveCustomers,
  selectedContacts,
  getTabWiseCategoriesList,
  setOpenUploadCancelDialog,
  setDocumentTab,
  deletePendingAction,
  pendingActionId,
  refreshSPDocuments,
}) {
  return (
    <Grid
      container
      item
      xs={12}
      style={{ paddingRight: 0, justifyContent: "flex-end" }}
    >
      {duplicateFileName && (
        <Typography variant="body2" style={{ color: "red" }}>
          ** File Name already exist, please rename the file and try again.
        </Typography>
      )}
      {activeSection !== 1 && (
        <Button
          startIcon={<ArrowBackIosIcon />}
          style={{
            textTransform: "capitalize",
          }}
          className={Styles.navyBlueColorForce}
          onClick={() => setActiveSection(section - 1)}
        >
          Go Back
        </Button>
      )}
      {activeSection === 1 ? (
        <Button
          variant="outlined"
          style={{
            textTransform: "capitalize",
            borderRadius: 4,
            padding: "6px 16px",
            marginRight: 12,
          }}
          className={clsx(Styles.navyBlueColorForce)}
          onClick={() => {
            setOpenUploadCancelDialog(true);
          }}
        >
          Cancel
        </Button>
      ) : null}
      {activeSection === 2 ? (
        <Button
          disabled={
            selectedActiveCustomers.length || selectedContacts.length
              ? true
              : false
          }
          variant="outlined"
          style={{
            textTransform: "capitalize",
            borderRadius: 4,
            padding: "6px 16px",
            marginRight: 12,
          }}
          className={(clsx(Styles.navyBlueColorForce), "sq-skip-btn")}
          onClick={() => {
            setActiveSection(section + 1);
          }}
        >
          Skip This Step
        </Button>
      ) : null}
      {activeSection !== 3 ? (
        <Button
          disabled={
            activeSection === 1
              ? !documentList.length
              : !selectedActiveCustomers.length && !selectedContacts.length
          }
          style={{
            textTransform: "capitalize",
            borderRadius: 4,
            padding: "6px 32px",
          }}
          className={clsx(Styles.navyBlueColorForce, Styles.primaryBtnAccent)}
          onClick={() => {
            if (section === 1) {
              checkForDocumentDescriptions(() => {
                setActiveSection(section + 1);
              });
            } else {
              setActiveSection(section + 1);
            }
          }}
        >
          Next
        </Button>
      ) : (
        <Button
          disabled={documentList.length ? false : true}
          style={{
            textTransform: "capitalize",
            padding: "6px 32px",
          }}
          className={clsx(Styles.navyBlueColorForce, Styles.primaryBtnAccent)}
          onClick={() => {
            uploadFiles(
              handleClose,
              getTabWiseCategoriesList,
              setDocumentTab,
              deletePendingAction,
              pendingActionId,
              refreshSPDocuments
            );
          }}
        >
          {selectedActiveCustomers.length || selectedContacts.length
            ? "Invite & Send"
            : "Upload"}
        </Button>
      )}
    </Grid>
  );
}
