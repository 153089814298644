import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  makeStyles,
  TextField,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { useOtherAction } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useOtherAction";
import { useTagSomeone } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useTagSomeone";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 620,
    minWidth: !isMobileDevice() && !isTabletDevice() && 800,
  },
}));

function OtherActionDialog({
  openOtherActionDialog,
  setOpenOtherActionDialog,
  serviceRequestId,
}) {
  const classes = useStyles();

  const { allSouqhUsers } = useTagSomeone();

  const {
    requestOtherAction,
    actionName,
    setActionName,
    dueDate,
    setDueDate,
    otherActionComments,
    setOtherActionComments,
    selectedMember,
    setSelectedMember,
    clearData,
    pastDateError,
    setPastDateError,
  } = useOtherAction();

  const todayDate = new Date();
  const dateInPast = (selectedDate) => {
    if (selectedDate.setHours(0, 0, 0, 0) < todayDate.setHours(0, 0, 0, 0)) {
      return true;
    }

    return false;
  };

  return (
    <Dialog
      fullWidth
      disableBackdropClick
      open={openOtherActionDialog}
      onClose={() => {
        setOpenOtherActionDialog(false);
      }}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Other Action</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid item xs={12} style={{ paddingBottom: 16, paddingTop: 16 }}>
          <TextField
            multiline
            placeholder="Specify action name"
            variant="outlined"
            fullWidth
            inputProps={{
              className: Styles.navyBlueColor,
              maxLength: 100,
            }}
            onChange={(e) => setActionName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 16 }}>
          <Autocomplete
            fullWidth
            noOptionsText={"No options"}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search and add email address"
                variant="outlined"
                autoComplete="new-password"
              />
            )}
            value={selectedMember || ""}
            options={allSouqhUsers || []}
            getOptionLabel={(option) => option.email}
            getOptionSelected={(option, value) => {
              return option.email === value.email;
            }}
            onChange={(event, value) => {
              setSelectedMember(value);
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 16 }}>
          <TextField
            multiline
            placeholder="Add comments"
            required
            rows={9}
            rowsMax={9}
            variant="outlined"
            fullWidth
            inputProps={{
              className: Styles.navyBlueColor,
              maxLength: 500,
            }}
            onChange={(event) => {
              setOtherActionComments(event.target.value.trim());
            }}
          />
          <Grid container item justifyContent="flex-end">
            <Typography variant="body2" style={{ color: "#17174C99" }}>
              {otherActionComments.length}/500
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 16 }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              fullWidth
              inputVariant="outlined"
              format="MM/dd/yyyy"
              id="date-picker-inline"
              label="Select deadline"
              value={dueDate}
              minDate={new Date()}
              autoOk={true}
              keyboardIcon={
                <DateRangeIcon
                  style={{
                    fontSize: 20,
                    fill: "#1B1E4C",
                  }}
                />
              }
              onChange={(event) => {
                setDueDate(event);
                dateInPast(event)
                  ? setPastDateError(true)
                  : setPastDateError(false);
              }}
              InputAdornmentProps={{
                position: "start",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        {/* <Grid item xs={12}>
          {dueDate && dateInPast(dueDate) && (
            <Typography style={{ color: "#f44336", fontSize: 12 }}>
              Past date not allowed
            </Typography>
          )}
        </Grid> */}
      </DialogContent>
      <Divider />
      <DialogActions style={{ padding: "16px 24px" }}>
        <Button
          variant="outlined"
          style={{
            padding: "6px 16px",
            marginRight: 12,
            textTransform: "capitalize",
          }}
          className={Styles.navyBlueColorForce}
          onClick={() => {
            setOpenOtherActionDialog(false);
            clearData();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={
            !selectedMember ||
            !actionName ||
            !otherActionComments ||
            pastDateError
          }
          variant="contained"
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize", padding: "6px 32px" }}
          onClick={() => {
            requestOtherAction(serviceRequestId, (res) => {
              if (res && res.data.status === 200) {
                setOpenOtherActionDialog(false);
                clearData();
              }
            });
          }}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OtherActionDialog;
