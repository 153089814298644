import React from "react";
import { Button, Card, Grid } from "@material-ui/core";
import Styles from "../../Styles.module.css";
import clsx from "clsx";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useDispatch } from "react-redux";
import {
  color_error,
  color_success,
} from "souqh-react-redux-hooks/toasterColors";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
export default function CollectCardDetails({
  clientSecret,
  invoiceId,
  updateStatusOfInvoice,
  setOpenViewInvoiceDialog,
}) {
  const elements = useElements();
  const stripe = useStripe();
  const dispatch = useDispatch();
  const userInfo = useAuthUser();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: `${userInfo.firstName} ${userInfo.lastName}`,
        },
      },
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      dispatch({
        type: "SET_TOASTER",
        payload: {
          message: result.error.message,
          bgColor: color_error,
          open: true,
        },
      });
    } else {
      // The payment has been processed!
      updateStatusOfInvoice(invoiceId, result.paymentIntent.status);
      if (result.paymentIntent.status === "succeeded") {
        setOpenViewInvoiceDialog(false);
        dispatch({
          type: "SET_TOASTER",
          payload: {
            message: `Payment succeeded!`,
            bgColor: color_success,
            open: true,
          },
        });
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={10}>
        <Card variant="outlined" style={{ padding: 20 }}>
          <form>
            <CardElement
            // onReady={() => {
            //   console.log("CardElement [ready]");
            // }}
            // onChange={(event) => {
            //   console.log("CardElement [change]", event);
            // }}
            // onBlur={() => {
            //   console.log("CardElement [blur]");
            // }}
            // onFocus={() => {
            //   console.log("CardElement [focus]");
            // }}
            />
          </form>
        </Card>
      </Grid>
      <Grid xs={2} alignItems="center">
        <Button
          disabled={!stripe}
          style={{ marginTop: 20 }}
          variant="contained"
          className={clsx(Styles.primaryBtnAccent)}
          onClick={(e) => {
            handleSubmit(e, (res) => {
              if (res && res.status === 200) {
                console.log("Sucsessful payment!!");
              }
            });
          }}
        >
          Pay
        </Button>
      </Grid>
    </Grid>
  );
}
