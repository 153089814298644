import { useSelector } from "react-redux";
import { useActions } from "../../useActions";
import { useDocuments } from "./useDocuments";
import {
  UPDATE_SP_ESIGN_DOCUMENT_DESCRIPTION,
  DELETE_SP_ESIGN_DOCUMENT_WHILE_UPLOAD,
} from "../../../souqh-redux/reducers/actionConstants";
import { useSPeSignWizard } from "./useSPeSignWizard";

const actionCreator = {
  deleteDocument: (payload) => ({
    type: DELETE_SP_ESIGN_DOCUMENT_WHILE_UPLOAD,
    payload: { index: payload },
  }),
  updateDescription: (index, value) => ({
    type: UPDATE_SP_ESIGN_DOCUMENT_DESCRIPTION,
    payload: { index, value },
  }),
};

export const useEsignStep1 = () => {
  const actions = useActions(actionCreator);

  const eSignDocumentList = useSelector(
    (state) => state.spDocument.eSignDocumentList
  );

  return {
    eSignDocumentList,
    actions,
  };
};
