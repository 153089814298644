import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  makeStyles,
  Card,
  CardContent,
} from "@material-ui/core";
import React, { useEffect } from "react";
import Styles from "../../../Styles.module.css";
import CloseIcon from "@material-ui/icons/Close";
import { useCalendarSync } from "souqh-react-redux-hooks/calendarSync/useCalendarSync";
import { isMobileDevice } from "../../../utils/AppUtils";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 400,
  },
}));

export default function ScheduledPagesDialog({
  openScheduledPagesDialog,
  setOpenScheduledPagesDialog,
  calSyncAccessToken,
}) {
  const classes = useStyles();

  const { getSchedulingPages, scheduledPages } = useCalendarSync();

  useEffect(() => {
    getSchedulingPages(calSyncAccessToken);
  }, [calSyncAccessToken]);

  const getDuration = (duration) => {
    if (duration > 90) {
      let hours = Math.floor(duration / 60);
      return `${hours} hours`;
    } else {
      return `${duration} minutes`;
    }
  };

  return (
    <Dialog
      fullWidth
      open={openScheduledPagesDialog}
      onClose={() => {
        setOpenScheduledPagesDialog(false);
      }}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Grid container item>
          <Grid item xs={10}>
            <Typography variant="subtitle1">Event Types</Typography>
          </Grid>
          <Grid container item xs={2} justifyContent="flex-end">
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpenScheduledPagesDialog(false);
              }}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container>
          {scheduledPages && scheduledPages.length
            ? scheduledPages.map((data, index) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  key={index}
                  style={{
                    padding:
                      index % 2 === 0 || isMobileDevice()
                        ? "8px 8px 8px 0px"
                        : 8,
                  }}
                >
                  <Card
                    variant="outlined"
                    elevation={0}
                    //   sx={{ minWidth: 275 }}
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#F2F2F2",
                      border: "1px solid #E2E2E2",
                    }}
                    onClick={() =>
                      window.open(
                        `https://schedule.nylas.com/${data.slug}`,
                        "_self"
                      )
                    }
                  >
                    <CardContent>
                      <Typography
                        style={{ fontSize: 16, fontWeight: 500 }}
                        gutterBottom
                      >
                        {data.config.event.title}
                      </Typography>
                      <Typography style={{ fontSize: 14 }}>
                        {getDuration(data.config.event.duration)} -{" "}
                        {data.config.event.location}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            : null}
        </Grid>
      </DialogContent>
      <Divider />
    </Dialog>
  );
}
