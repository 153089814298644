import React, { useEffect } from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import {
  Typography,
  IconButton,
  Button,
  Dialog,
  Grid,
  makeStyles,
} from "@material-ui/core/";
import Styles from "../../../../Styles.module.css";
import UploadCancelDialog from "../../../HomeBuyer/UploadDocuments/UploadCancelDialog";
import TagDocument from "../TagDcoument";
import SPDocumentSummary from "../SPDocumentSummary";
import SPInviteAndSend from "../SPInviteAndSend";
import AddSummaryDialog from "../AddSummaryDialog";
import AddContactDialog from "../AddContactDialog";
import { useSPeSignWizard } from "souqh-react-redux-hooks/serviceProvider/documents/useSPeSignWizard";
import EditEsignActinBtns from "./EditEsignActinBtns";
import EditEsignStepsHeader from "./EditEsignStepsHeader";
import CustomEmailMessageDialog from "../CustomEmailMessageDialog";
import { useBusinessType } from "souqh-react-redux-hooks/useBusinessType";

const styles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    padding: 0,
  },
  dialogPaper: {
    height: window.innerHeight,
    borderRadius: 0,
  },
  diaogFooter: {
    padding: "12px 36px",
  },
  dialogRoot: {
    padding: 0,
  },
}));

const DialogTitle = (props) => {
  const { children, classes, setOpenUploadCancelDialog, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="subtitle1">{children}</Typography>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={() => {
          setOpenUploadCancelDialog(true);
        }}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
};

export default function EditEsignDocumentDialog({
  openEditEsignDocumentDialog,
  setOpenEditEsignDocumentDialog,
  updateIntercomStatus,
  getTabWiseCategoriesList,
  setDocumentTabName,
  documentTabName,
  documentDataForEdit,
  setDocumentDataForEdit,
}) {
  const classes = styles();
  const {
    sharedWithSP,
    setSharedWithSP,
    associactedServiceProviders,
    contacts,
    getContacts,
    searchText,
    setSearchText,
    selectedContact,
    setSelectedContact,
    filteredContacts,
    setFilteredContacts,
    filterContacts,
    openAddContactDialog,
    setAddContactDialog,
    openAddSummaryDialog,
    setOpenAddSummaryDialog,
    summaryList,
    deleteSummary,
    eSignDocumentList,
    activeEsignSection,
    setActiveEsignSection,
    checkForDocumentDescriptions,
    checkAndAddSummary,
    duplicateFileName,
    setOpenUploadCancelDialog,
    openUploadCancelDialog,
    summaryTobeEdited,
    setSummaryTobeEdited,
    documentsTagged,
    setDocumentsTagged,
    selectedTaggedDoucment,
    setSelectedTaggedDoucment,
    setAssociactedServiceProviders,
    actions: { setDocumentSummaryList },
    openCustomEmailMessageDialog,
    setOpenCustomEmailMessageDialog,
    customEmailTitle,
    setCustomEmailTitle,
    customEmailMessage,
    setCustomEmailMessage,
    selectedDocumentForSummary,
    setSelectedDocumentForSummary,
    getDocumentSummaryList,
    validateTaggedDocument,
    signOrders,
    signOrdersData,
    updatePrimaryAndSecondaryApplicant,
    updateSignOrderChange,
    updateEmailTouchedState,
    updateEmailValue,
    SPContacts,
    getSPContacts,
    handleAssocSPSelect,
    closeAndFinishEsign,
    getSignnowInviteData,
    inviteForEsign,
    isAllDataFilled,
    resetStep1,
    disableSkipBtn,
    primaryApplicantList,
    defaultEmailTitle,
    setDefaultEmailTitle,
    defaultEmailMessage,
    setDefaultEmailMessage,
    createDocumentForEdit,
    addContactItem,
    connectToSocket,
    processListener,
    eventType,
    // businessTypes,
  } = useSPeSignWizard(
    getTabWiseCategoriesList,
    setDocumentTabName,
    documentTabName
  );

  const { businessTypes } = useBusinessType();

  useEffect(() => {
    updateIntercomStatus && updateIntercomStatus(openEditEsignDocumentDialog);
    if (openEditEsignDocumentDialog && documentDataForEdit) {
      setActiveEsignSection(2);
      createDocumentForEdit(documentDataForEdit);
      connectToSocket();
      getContacts();
      getSPContacts();
    }
  }, [openEditEsignDocumentDialog]);

  useEffect(() => {
    if (eventType) {
      processListener();
    }
  }, [eventType]);

  useEffect(() => {
    setFilteredContacts([...contacts]);
    filterContacts();
  }, [contacts]);

  useEffect(() => {
    if (documentsTagged && documentsTagged.length) {
      setSelectedTaggedDoucment(documentsTagged[0] || "");
    } else {
      setSelectedTaggedDoucment("");
    }
  }, [documentsTagged]);

  useEffect(() => {
    if (selectedDocumentForSummary) {
      getDocumentSummaryList();
    } else {
      setDocumentSummaryList([]);
    }
  }, [selectedDocumentForSummary]);

  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    setOpenEditEsignDocumentDialog(null);
    setDocumentDataForEdit(null);
    closeAndFinishEsign();
  };

  const handleBackClose = () => {
    resetStep1();
  };

  return (
    <>
      <UploadCancelDialog
        setOpenUploadCancelDialog={setOpenUploadCancelDialog}
        openUploadCancelDialog={openUploadCancelDialog}
        onClose={handleClose}
      />
      <AddSummaryDialog
        openAddSummaryDialog={openAddSummaryDialog}
        setOpenAddSummaryDialog={setOpenAddSummaryDialog}
        checkAndAddSummary={checkAndAddSummary}
        summaryList={summaryList}
        summaryTobeEdited={summaryTobeEdited}
        setSummaryTobeEdited={setSummaryTobeEdited}
      />
      <AddContactDialog
        openAddContactDialog={openAddContactDialog}
        setAddContactDialog={setAddContactDialog}
        addContactItem={addContactItem}
        businessTypes={businessTypes || []}
      />
      <CustomEmailMessageDialog
        openCustomEmailMessageDialog={openCustomEmailMessageDialog}
        setOpenCustomEmailMessageDialog={setOpenCustomEmailMessageDialog}
        customEmailTitle={customEmailTitle}
        setCustomEmailTitle={setCustomEmailTitle}
        customEmailMessage={customEmailMessage}
        setCustomEmailMessage={setCustomEmailMessage}
        defaultEmailTitle={defaultEmailTitle}
        setDefaultEmailTitle={setDefaultEmailTitle}
        defaultEmailMessage={defaultEmailMessage}
        setDefaultEmailMessage={setDefaultEmailMessage}
      />
      <div>
        <Dialog
          disableBackdropClick
          fullScreen
          maxWidth={"lg"}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={!!openEditEsignDocumentDialog}
          classes={{ paper: classes.dialogPaper, root: classes.dialogRoot }}
        >
          <DialogTitle
            classes={classes}
            id="customized-dialog-title"
            className={Styles.navyBlueColor}
            setOpenUploadCancelDialog={setOpenUploadCancelDialog}
          >
            {documentDataForEdit ? (
              <span>Edit eSign Document - {documentDataForEdit.docName}</span>
            ) : (
              <span>Edit eSign Document</span>
            )}
          </DialogTitle>
          <MuiDialogContent dividers className={classes.dialogContent}>
            <Grid container style={{ height: "100%" }}>
              <EditEsignStepsHeader activeEsignSection={activeEsignSection} />
              <Grid item xs={12} style={{ height: "calc(100% - 60px)" }}>
                {activeEsignSection === 2 && (
                  <TagDocument
                    selectedTaggedDoucment={selectedTaggedDoucment}
                  />
                )}
                {activeEsignSection === 3 && (
                  <SPDocumentSummary
                    summaryList={summaryList}
                    setOpenAddSummaryDialog={setOpenAddSummaryDialog}
                    deleteSummary={deleteSummary}
                    setSummaryTobeEdited={setSummaryTobeEdited}
                  />
                )}
                {activeEsignSection === 4 && (
                  <SPInviteAndSend
                    searchText={searchText}
                    setSearchText={setSearchText}
                    selectedContact={selectedContact}
                    setSelectedContact={setSelectedContact}
                    filteredContacts={filteredContacts}
                    setFilteredContacts={setFilteredContacts}
                    filterContacts={filterContacts}
                    associactedServiceProviders={associactedServiceProviders}
                    sharedWithSP={sharedWithSP}
                    setSharedWithSP={setSharedWithSP}
                    openAddContactDialog={openAddContactDialog}
                    setAddContactDialog={setAddContactDialog}
                    setAssociactedServiceProviders={
                      setAssociactedServiceProviders
                    }
                    updatePrimaryAndSecondaryApplicant={
                      updatePrimaryAndSecondaryApplicant
                    }
                    getContacts={getContacts}
                    contacts={contacts}
                    signOrders={signOrders}
                    signOrdersData={signOrdersData}
                    updateSignOrderChange={updateSignOrderChange}
                    updateEmailTouchedState={updateEmailTouchedState}
                    updateEmailValue={updateEmailValue}
                    contacts={contacts}
                    SPContacts={SPContacts}
                    handleAssocSPSelect={handleAssocSPSelect}
                    primaryApplicantList={primaryApplicantList}
                  />
                )}
              </Grid>
            </Grid>
          </MuiDialogContent>
          <MuiDialogActions className={classes.diaogFooter}>
            <EditEsignActinBtns
              duplicateFileName={duplicateFileName}
              activeEsignSection={activeEsignSection}
              eSignDocumentList={eSignDocumentList}
              setActiveEsignSection={setActiveEsignSection}
              checkForDocumentDescriptions={checkForDocumentDescriptions}
              setOpenCustomEmailMessageDialog={setOpenCustomEmailMessageDialog}
              setOpenUploadCancelDialog={setOpenUploadCancelDialog}
              documentsTagged={documentsTagged}
              setDocumentsTagged={setDocumentsTagged}
              selectedTaggedDoucment={selectedTaggedDoucment}
              setSelectedTaggedDoucment={setSelectedTaggedDoucment}
              selectedDocumentForSummary={selectedDocumentForSummary}
              setSelectedDocumentForSummary={setSelectedDocumentForSummary}
              validateTaggedDocument={validateTaggedDocument}
              getSignnowInviteData={getSignnowInviteData}
              inviteForEsign={inviteForEsign}
              isAllDataFilled={isAllDataFilled}
              handleClose={handleClose}
              resetStep1={resetStep1}
              setSelectedContact={setSelectedContact}
              disableSkipBtn={disableSkipBtn}
            />
          </MuiDialogActions>
        </Dialog>
      </div>
    </>
  );
}
