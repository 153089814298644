import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
  Grid,
  ButtonBase,
  DialogTitle,
  Hidden,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import ShareEmbedDialog from "./ShareEmbedDialog";
import CloseIcon from "@material-ui/icons/Close";
import CopyQRCode from "./CopyQRCode";
import { useCopyQRCode } from "souqh-react-redux-hooks/common/useCopyQRCode";
import ComingSoonDialog from "../../common/ComingSoonDialog";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { DOMAIN_URL } from "../serviceProviderConstants";

export default function MoreTrafficDialog({
  open,
  setopenMoreTrafficDialog,
  storeFrontRouteName,
  storeFrontId,
  setUserEngagementData,
  data,
}) {
  const [openShareEmbedDialog, setopenShareEmbedDialog] = useState(false);
  const [openQRCodeDialog, setOpenQRCodeDialog] = useState(false);
  const [openComingSoonDialog, setOpenComingSoonDialog] = useState(false);
  const { getQRCode, qrCodeImageSrc, copyImageToClipBoard, copySuccess } =
    useCopyQRCode(storeFrontId);
  const [copyLink, setCopyLink] = useState(false);

  useEffect(() => {
    if (open) {
      getQRCode();
    }
  }, [open]);

  return (
    <React.Fragment>
      <ComingSoonDialog
        openComingSoonDialog={openComingSoonDialog}
        setOpenComingSoonDialog={setOpenComingSoonDialog}
      />
      <ShareEmbedDialog
        open={openShareEmbedDialog}
        setopenShareEmbedDialog={setopenShareEmbedDialog}
        storeFrontName={storeFrontRouteName}
        copyLink={copyLink}
        data={data}
      />
      <CopyQRCode
        open={openQRCodeDialog}
        setOpenQRCodeDialog={setOpenQRCodeDialog}
        qrCodeImageSrc={qrCodeImageSrc}
        copyImageToClipBoard={copyImageToClipBoard}
        copySuccess={copySuccess}
      />
      <Dialog open={open} maxWidth="md">
        <DialogTitle>
          <Grid item container xs={12} justifyContent="flex-end">
            <ButtonBase>
              <CloseIcon
                onClick={() => {
                  setopenMoreTrafficDialog(false);
                }}
              />
            </ButtonBase>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container>
              <Grid item xs={12} md={8}>
                <Typography
                  variant="body1"
                  style={{ fontWeight: 500 }}
                  className={Styles.navyBlueColor}
                >
                  Share and grow!
                </Typography>

                <Grid item>
                  <Typography variant="body1" className={Styles.navyBlueColor}>
                    Share your storefront across social media and other digital
                    platforms to drive more traffic to your storefront.
                  </Typography>
                </Grid>
                <Grid item style={{ marginTop: 70, marginBottom: 10 }}>
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 500 }}
                    className={Styles.navyBlueColor}
                  >
                    Share now
                  </Typography>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item>
                    {/* https://souqh.ca/marketplace/ - is website route , we redirect the user from website to actual storefront (check MarketPlaceRedirection component in website for more details) */}
                    <EmailShareButton
                      url={`https://${DOMAIN_URL}/storefront/${storeFrontRouteName}`}
                      onClick={() =>
                        setUserEngagementData &&
                        setUserEngagementData(storeFrontId, "Email_share")
                      }
                      openShareDialogOnClick
                    >
                      <EmailIcon
                        bgStyle={{ fill: "#0e1c4e" }}
                        size={36}
                        round
                      />
                    </EmailShareButton>
                  </Grid>
                  <Grid item>
                    <TwitterShareButton
                      title="Hey hi, please take a look at my storefront"
                      url={`https://${DOMAIN_URL}/storefront/${storeFrontRouteName}`}
                      onClick={() =>
                        setUserEngagementData &&
                        setUserEngagementData(storeFrontId, "Twitter_share")
                      }
                    >
                      <TwitterIcon size={36} round />
                    </TwitterShareButton>
                  </Grid>
                  <Grid item>
                    <LinkedinShareButton
                      title="Hey hi, please take a look at my storefront"
                      url={`https://${DOMAIN_URL}/storefront/${storeFrontRouteName}`}
                      onClick={() =>
                        setUserEngagementData &&
                        setUserEngagementData(storeFrontId, "Linkedin_share")
                      }
                    >
                      <LinkedinIcon size={36} round />
                    </LinkedinShareButton>
                  </Grid>
                  <Grid item>
                    <FacebookShareButton
                      title="Hey hi, please take a look at my storefront"
                      url={`https://${DOMAIN_URL}/storefront/${storeFrontRouteName}`}
                      className="facebook-share-button"
                      onClick={() =>
                        setUserEngagementData &&
                        setUserEngagementData(storeFrontId, "Facebook_share")
                      }
                    >
                      <FacebookIcon size={36} round />
                    </FacebookShareButton>
                  </Grid>
                  <Grid item>
                    <WhatsappShareButton
                      title="Hey hi, please take a look at my storefront"
                      url={`https://${DOMAIN_URL}/storefront/${storeFrontRouteName}`}
                      className="facebook-share-button"
                      onClick={() =>
                        setUserEngagementData &&
                        setUserEngagementData(storeFrontId, "Whatsapp_share")
                      }
                    >
                      <WhatsappIcon size={36} round />
                    </WhatsappShareButton>
                  </Grid>
                  <Grid item>
                    <ButtonBase
                      onClick={() => {
                        setopenShareEmbedDialog(true);
                        setCopyLink(false);
                        setUserEngagementData &&
                          setUserEngagementData(
                            storeFrontId,
                            "EmbedCode_share"
                          );
                      }}
                    >
                      <img src="/images/Share-Embed-Icon.svg" />
                    </ButtonBase>
                  </Grid>
                  <Grid item>
                    <ButtonBase
                      onClick={() => {
                        setOpenQRCodeDialog(true);
                        setUserEngagementData &&
                          setUserEngagementData(storeFrontId, "QRCode_share");
                      }}
                    >
                      <img src="/images/QR-code.svg" />
                    </ButtonBase>
                  </Grid>
                  <Grid item>
                    <ButtonBase
                      style={{
                        backgroundColor: "#0e1c4e",
                        color: "white",
                        borderRadius: 24,
                        padding: "2px 6px 6px 6px",
                      }}
                      onClick={() => {
                        setopenShareEmbedDialog(true);
                        setCopyLink(true);
                        setUserEngagementData &&
                          setUserEngagementData(storeFrontId, "Copy_and_share");
                      }}
                    >
                      <ContentCopyIcon style={{ marginTop: 5 }} />
                    </ButtonBase>
                  </Grid>
                </Grid>
              </Grid>
              <Hidden smDown>
                <Grid item xs={4}>
                  <img
                    style={{ height: 254, width: "100%" }}
                    src="/images/Share-Illustration.svg"
                  />
                </Grid>
              </Hidden>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
