import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Divider,
  makeStyles,
  TextField,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 637,
    width: 800,
  },
}));

function DeleteAccountDialog({
  openDeleteAccountDialog,
  setOpenDeleteAccountDialog,
  setDeactivateAccount,
}) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        open={openDeleteAccountDialog}
        disableBackdropClick
        onClose={() => {
          setOpenDeleteAccountDialog(false);
        }}
        maxWidth={"md"}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DialogTitle className={Styles.navyBlueColor}>
          <Typography variant="subtitle1">Delete Account</Typography>
        </DialogTitle>
        <Divider style={{ marginBottom: 8 }} />
        <DialogContent>
          <Grid container className={Styles.navyBlueColor}>
            <Grid container item xs={12} justifyContent="center">
              <ReportProblemOutlinedIcon
                style={{ height: 77, width: 90, color: "#FF005C" }}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 16 }}>
              <Typography display="inline">
                Are you sure you want to permanently delete your account? When
                you delete your Souqh account, you won’t be able to retrieve any
                data including:{" "}
              </Typography>
              <Typography variant="subtitle1" display="inline">
                Storefront, Customers, Profile, Documents, Messages.
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 16 }}>
              <Typography display="inline">
                If you would like to retain the data consider{" "}
              </Typography>
              <Typography variant="subtitle1" display="inline">
                deactivating your account{" "}
              </Typography>
              <Typography display="inline">instead.</Typography>
            </Grid>
            <Typography variant="subtitle1" style={{ marginTop: 48 }}>
              To delete, please enter your storefront name
            </Typography>
            <TextField
              placeholder="Storefront Name*"
              required
              variant="outlined"
              fullWidth
              inputProps={{
                className: Styles.navyBlueColor,
                maxLength: 500,
              }}
              // value={reviewComment}
              // onChange={(e) => {
              //   setReviewComment(e.target.value);
              // }}
            />
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="outlined"
            className={Styles.navyBlueColorForce}
            style={{ textTransform: "capitalize", marginRight: 8 }}
            onClick={() => {
              setOpenDeleteAccountDialog(false);
              setDeactivateAccount(false);
            }}
          >
            Cancel
          </Button>
          <Button
            // disabled={!allowAdd}
            className={Styles.primaryBtnAccent}
            style={{
              textTransform: "capitalize",
              marginRight: 16,
              padding: "6px 24px",
            }}
            onClick={() => {
              setOpenDeleteAccountDialog(false);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default DeleteAccountDialog;
