import {
  Button,
  Grid,
  makeStyles,
  Typography,
  TextField,
  Paper,
  Tabs,
  Tab,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Table,
  Popper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  TableSortLabel,
  Divider,
  SwipeableDrawer,
  List,
  ListItemText,
  ListItem,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import SearchIcon from "@material-ui/icons/Search";
import React, { useRef, useEffect, useState } from "react";
import clsx from "clsx";
import { useDocuments } from "souqh-react-redux-hooks/homeBuyer/useDocuments";
import DeleteCategoryDialog from "../DocumentManagementDialogs/DeleteCategoryDialog";
import AddCategoryDialog from "../DocumentManagementDialogs/AddCategoryDialog";
import RenameCategoryDialog from "../DocumentManagementDialogs/RenameCategoryDialog";
import { useDownloadFile } from "souqh-react-redux-hooks/useDownloadFile";
import DeleteDocumentDialog from "../DocumentManagementDialogs/DeleteDocumentDialog";
import MoveDocumentDialog from "../DocumentManagementDialogs/MoveDocumentDialog";
import ArchiveDocumentDialog from "../DocumentManagementDialogs/ArchiveDocumentDialog";
import Styles from "../../Styles.module.css";
import DocumentsRow from "./DocumentsRow";
import RenameDocumentDialog from "../DocumentManagementDialogs/RenameDocumentDialog";
import {
  DOCUMENTS_TABS,
  DOCUMENTS_TABLE_HEADERS,
  ACTION_REQUIRED,
  WAITING_FOR_OTHER,
  EXPIRING_SOON,
  COMPLETED,
  ARCHIVED,
} from "./homeBuyerConstants";
import SPUploadWizard from "../common/UploadWizard/SPUploadWizard";
import ShareDocumentDialog from "../DocumentManagementDialogs/ShareDocumentDialog";
import { useSPUploadWizard } from "souqh-react-redux-hooks/serviceProvider/documents/useSPUploadWizard";
import { useSignDocument } from "souqh-react-redux-hooks/serviceProvider/documents/useSignDocument";
import SignDocumentDialog from "../ServiceProvider/UploadDocuments/SignDocumentDialog";
import {
  getSelectedTabIndex,
  isMobileDevice,
  isTabletDevice,
} from "../../utils/AppUtils";
import { useSQQuery } from "souqh-react-redux-hooks/common/useSQQuery";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import SearchNotFound from "../common/SearchNotFound";
import NoDataAvailable from "../common/NoDataAvailable";

const useStyles = makeStyles((theme) => ({
  tabs: {
    minWidth: "16%",
    fontWeight: "bold",
    textTransform: "capitalize",
    opacity: "100%",
    fontSize: 14,
  },
  marginTop: {
    marginTop: 25,
  },
  title: {
    fontSize: 32,
    fontWeight: "bold",
  },
  tableHeading: {
    fontWeight: "bold",
  },
  editIcon: {
    height: 16,
    width: 16,
    marginRight: 16,
  },
  shareIcon: {
    height: 14,
    width: 16,
    marginRight: 16,
  },
  downloadIcon: {
    height: 18,
    width: 17,
    marginBottom: -3,
  },
  marginTop8: {
    marginTop: 8,
  },
  marginTop23: {
    marginTop: 23,
  },
  paddingRight10: {
    paddingRight: 10,
  },
  cursorPointer: {
    cursor: "pointer",
  },
  textInput: {
    "& .MuiInput-underline:before, & .MuiInput-underline:hover:before": {
      borderBottom: "none",
    },
  },
  modal: {
    zIndex: "1400 !important",
  },
  paper: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function EnhancedTableHead({ classes, order, orderBy, onRequestSort, tab }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      {DOCUMENTS_TABLE_HEADERS.map((headCell) => (
        <TableCell
          className={Styles.navyBlueColorForce}
          key={headCell.id}
          align={headCell.id === "quickActions" ? "right" : "left"}
          sortDirection={orderBy === headCell.id ? order : false}
          style={
            isTabletDevice() || isMobileDevice()
              ? { minWidth: headCell.width }
              : { width: headCell.width }
          }
        >
          <TableSortLabel
            active={
              headCell.id === "quickActions" ? false : orderBy === headCell.id
            }
            disabled={
              headCell.id === "quickActions" || headCell.id === "sharedWith"
            }
            direction={orderBy === headCell.id ? order : "asc"}
            onClick={createSortHandler(headCell.id)}
            style={{ fontSize: 12, fontWeight: 600 }}
          >
            {tab === "Expiring Soon" && headCell.id === "lastChange"
              ? "Expiry Date"
              : headCell.label}
          </TableSortLabel>
        </TableCell>
      ))}
    </>
  );
}

export default function Documents() {
  const [tabDrawer, setTabDrawer] = useState(false);
  const {
    order,
    orderBy,
    handleRequestSort,
    actions: { setDocumentTab, setDocumentTabName, setDocumentsTableData },
    documentTab,
    openDeleteCategoryDialog,
    setOpenDeleteCategoryDialog,
    openAddCategoryDialog,
    setOpenAddCategoryDialog,
    openMenu,
    handleToggle,
    handleClose,
    handleListKeyDown,
    openRenameCategoryDialog,
    setOpenRenameCategoryDialog,
    getSharedWithTooltip,
    openDeleteDocumentDialog,
    setOpenDeleteDocumentDialog,
    openMoveDocumentDialog,
    setOpenMoveDocumentDialog,
    openArchiveDocumentDialog,
    setOpenArchiveDocumentDialog,
    openRenameDocumentDialog,
    setOpenRenameDocumentDialog,
    searchVal,
    documentsTableData,
    categories,
    openShareDocumentDialog,
    setOpenShareDocumentDialog,
    handleCheckboxChange,
    selectedData,
    setSelectedData,
    selectedDataForDownload,
    setSelectedDataForDownload,
    extractSelectedData,
    deselectDocfromSelection,
    updateIntercomStatus,
    documentTabName,
    getTabWiseCategoriesList,
    actionRequiredCount,
    waitingForOtherCount,
    expiringSoonCount,
    completedCount,
    archivedCount,
    referenceDocumentsCount,
    setSearchText,
    searchText,
    userInfo,
  } = useDocuments();

  const { query } = useSQQuery();

  const { downLoadDocument, downloadMultipleDocument } = useDownloadFile(
    selectedDataForDownload
  );

  const {
    onUploadFileChange,
    openUploadWizard,
    actions: { setOpenUploadWizard, cleanUploadFileDataOnClose },
  } = useSPUploadWizard();

  const {
    openSignDocumentDialog,
    setOpenSignDocumentDialog,
    signDocumentItem,
    setSignDocumentItem,
  } = useSignDocument();

  const classes = useStyles();
  const anchorRef = useRef(null);

  useEffect(() => {
    const tabName = query.get("tabName");
    const _documentId = query.get("documentId");
    const _userId = query.get("userId");
    const _openUploadDocWizard = query.get("openUploadDocWizard");

    if (tabName) {
      setDocumentTabName(tabName);
    } else {
      setDocumentTabName(ACTION_REQUIRED);
    }

    if (_documentId && _userId) {
      setOpenSignDocumentDialog(true);
    }
    if (_openUploadDocWizard) {
      setOpenUploadWizard(true);
    }
    return () => {
      setDocumentTabName("");
      setDocumentTab(0);
      setDocumentsTableData([]);
      cleanUploadFileDataOnClose();
    };
  }, []);

  useEffect(() => {
    if (documentTabName) {
      getTabWiseCategoriesList(documentTabName);
    }
    const newIndex = getSelectedTabIndex(userInfo.userType, documentTabName);
    setDocumentTab(newIndex);
  }, [documentTabName]);

  useEffect(() => {
    let temp = [];
    let dataForDownload = [];
    extractSelectedData(temp, dataForDownload);
    setSelectedData(temp);
    setSelectedDataForDownload(dataForDownload);
  }, [documentsTableData]);

  const firstCategory = categories && categories.length && categories[0].name;

  const tabCount = (tab) => {
    if (tab === ACTION_REQUIRED) {
      return actionRequiredCount;
    } else if (tab === WAITING_FOR_OTHER) {
      return waitingForOtherCount;
    } else if (tab === EXPIRING_SOON) {
      return expiringSoonCount;
    } else if (tab === COMPLETED) {
      return completedCount;
    } else if (tab === ARCHIVED) {
      return archivedCount;
    } else {
      return referenceDocumentsCount;
    }
  };

  const moreActionsList = () => (
    <div
      className={classes.fullList}
      role="presentation"
      onClick={() => {
        setTabDrawer(false);
      }}
      onKeyDown={() => {
        setTabDrawer(false);
      }}
    >
      <Grid container item xs={12} style={{ padding: 16 }}>
        <Grid item xs={10}>
          <Typography variant="subtitle1" className={Styles.navyBlueColorForce}>
            More Information
          </Typography>
        </Grid>
        <Grid container item xs={2} justifyContent="flex-end">
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              setTabDrawer(false);
            }}
          />
        </Grid>
      </Grid>
      <Divider />
      <List>
        {DOCUMENTS_TABS.map((tab, index) => (
          <ListItem
            key={index}
            onClick={() => {
              setDocumentTabName(tab);
              setSearchText("");
            }}
          >
            <ListItemText
              primary={tab}
              className={Styles.navyBlueColorForce}
              style={{ fontSize: 14 }}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  let noDocumentsInTab =
    documentsTableData &&
    documentsTableData.every((el) => !el.documents.length);

  return (
    <Grid
      item
      container
      xs={12}
      style={{ padding: isTabletDevice() && "0px 80px" }}
    >
      <SwipeableDrawer
        classes={{ modal: classes.modal, paper: classes.paper }}
        anchor="bottom"
        open={tabDrawer}
        onClose={() => {
          setTabDrawer(false);
        }}
        onOpen={() => {
          setTabDrawer(true);
        }}
      >
        {tabDrawer && moreActionsList()}
      </SwipeableDrawer>
      <SPUploadWizard
        openUploadWizard={openUploadWizard}
        setOpenUploadWizard={setOpenUploadWizard}
        categories={categories}
        updateIntercomStatus={updateIntercomStatus}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
        setDocumentTab={setDocumentTab}
      ></SPUploadWizard>
      <DeleteCategoryDialog
        openDeleteCategoryDialog={openDeleteCategoryDialog}
        setOpenDeleteCategoryDialog={setOpenDeleteCategoryDialog}
        categories={categories}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
        documentTabName={documentTabName}
      />
      <RenameCategoryDialog
        openRenameCategoryDialog={openRenameCategoryDialog}
        setOpenRenameCategoryDialog={setOpenRenameCategoryDialog}
        categories={categories}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
        documentTabName={documentTabName}
      />
      <AddCategoryDialog
        openAddCategoryDialog={openAddCategoryDialog}
        setOpenAddCategoryDialog={setOpenAddCategoryDialog}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
        documentTabName={documentTabName}
      />
      <DeleteDocumentDialog
        openDeleteDocumentDialog={openDeleteDocumentDialog.open}
        setOpenDeleteDocumentDialog={setOpenDeleteDocumentDialog}
        docDetails={openDeleteDocumentDialog.docDetails}
        categoryId={openDeleteDocumentDialog.categoryId}
        documentTabName={documentTabName}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
      />
      <ArchiveDocumentDialog
        openArchiveDocumentDialog={openArchiveDocumentDialog.open}
        setOpenArchiveDocumentDialog={setOpenArchiveDocumentDialog}
        docDetails={openArchiveDocumentDialog.docDetails}
      />
      <MoveDocumentDialog
        openMoveDocumentDialog={openMoveDocumentDialog.open}
        setOpenMoveDocumentDialog={setOpenMoveDocumentDialog}
        documentName={openMoveDocumentDialog.documentName}
        documentItem={openMoveDocumentDialog.docData}
        categories={categories}
        documentId={openMoveDocumentDialog.documentId}
        sourceCategoryId={openMoveDocumentDialog.sourceCategoryId}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
        documentTabName={documentTabName}
      />
      <RenameDocumentDialog
        openRenameDocumentDialog={openRenameDocumentDialog.open}
        setOpenRenameDocumentDialog={setOpenRenameDocumentDialog}
        documentId={openRenameDocumentDialog.documentId}
        categoryId={openRenameDocumentDialog.categoryId}
        documentItem={openRenameDocumentDialog.docData}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
        documentTabName={documentTabName}
        documentName={openRenameDocumentDialog.documentName}
      />
      <ShareDocumentDialog
        openShareDocumentDialog={openShareDocumentDialog}
        setOpenShareDocumentDialog={setOpenShareDocumentDialog}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
        deselectDocfromSelection={deselectDocfromSelection}
        documentTabName={documentTabName}
      />

      <SignDocumentDialog
        openSignDocumentDialog={openSignDocumentDialog}
        setOpenSignDocumentDialog={setOpenSignDocumentDialog}
        updateIntercomStatus={updateIntercomStatus}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
        setDocumentTab={setDocumentTab}
        signDocumentItem={signDocumentItem}
        setSignDocumentItem={setSignDocumentItem}
        documentTabName={documentTabName}
      />

      <Grid item xs={12} style={{ marginTop: 0 }}>
        <Typography className={clsx(classes.title, Styles.navyBlueColor)}>
          Documents
        </Typography>
      </Grid>
      <Grid container item xs={12} className={classes.marginTop8}>
        <Grid item xs={12} md={8}>
          <Grid item xs={12} lg={9}>
            <Paper
              elevation={0}
              style={{
                fontStyle: "italic",
                paddingLeft: 15,
                border:
                  (isTabletDevice() || isMobileDevice()) &&
                  "1px solid  #17174C1A",
              }}
              className={Styles.navyBlueColorForce}
            >
              <Grid container>
                <Grid item xs={11} style={{ marginTop: 10 }}>
                  <TextField
                    value={searchText}
                    className={classes.textInput}
                    fullWidth
                    placeholder="Search by Document Name, Status, Shared with"
                    onChange={(event) => searchVal(event.target.value)}
                  />
                </Grid>
                <Grid container item xs={1} justifyContent="flex-end">
                  <SearchIcon
                    style={{ marginTop: 10, paddingRight: 10 }}
                    className={clsx(classes.primarColor)}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        {!isMobileDevice() && (
          <Grid
            container
            item
            md={4}
            justifyContent="flex-end"
            style={{ paddingTop: 10, paddingBottom: 10 }}
          >
            <input
              style={{ display: "none" }}
              id="contained-button-file"
              multiple
              type="file"
              onChange={onUploadFileChange}
            />
            <label htmlFor="contained-button-file">
              <Button
                style={{
                  borderRadius: 6,
                  textTransform: "capitalize",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                className={Styles.primaryBtnAccent}
                component="span"
              >
                Upload Document
              </Button>
            </label>
          </Grid>
        )}
      </Grid>
      {isTabletDevice() && (
        <Divider
          orientation="horizontal"
          style={{ width: "100%", marginTop: 8 }}
        />
      )}
      <Grid
        item
        xs={12}
        className={clsx(
          "hideScroll",
          !isTabletDevice() && !isMobileDevice() && classes.marginTop23
        )}
        style={{
          marginTop: (isMobileDevice() || isTabletDevice()) && 8,
          display: isTabletDevice() && "flex",
          flexWrap: isTabletDevice() && "nowrap",
          overflowX: isTabletDevice() && "auto",
        }}
      >
        {isMobileDevice() ? (
          <Grid item container xs={12}>
            <Button
              fullWidth
              variant="outlined"
              className={Styles.navyBlueColorForce}
              style={{
                textTransform: "capitalize",
                marginTop: 8,
                padding: 12,
              }}
              onClick={() => setTabDrawer(true)}
              endIcon={<ExpandMoreIcon />}
            >
              {documentTabName}
            </Button>
          </Grid>
        ) : (
          <Paper elevation={0}>
            <Tabs
              value={documentTab}
              variant="scrollable"
              scrollButtons="false"
              onChange={(event, newValue) => {
                setDocumentTab(newValue);
              }}
              aria-label="doc-tabs-container"
              className={!isTabletDevice() && "sqCustomDocAlign"}
              classes={{ indicator: Styles.indicator }}
            >
              {DOCUMENTS_TABS.map((tab, index) => (
                <Tab
                  key={index}
                  label={`${tab} (${tabCount(tab)})`}
                  className={clsx(classes.tabs, {
                    [Styles.primaryTxtAccentForce]: index === documentTab,
                    [Styles.navyBlueColorForce]: index !== documentTab,
                  })}
                  onClick={() => {
                    setDocumentTabName(tab);
                    setSearchText("");
                    getTabWiseCategoriesList(tab);
                  }}
                />
              ))}
            </Tabs>
          </Paper>
        )}
      </Grid>
      <Grid item xs={12} className={classes.marginTop}>
        {/* <Paper elevation={0}> */}
        {DOCUMENTS_TABS.map((tab, index) => (
          <TabPanel value={documentTab} index={index} key={index}>
            {!noDocumentsInTab && (
              <Paper elevation={0}>
                <TableContainer>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <EnhancedTableHead
                          classes={classes}
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          headCells={DOCUMENTS_TABLE_HEADERS}
                          tab={tab}
                        />

                        <TableCell
                          align="right"
                          className={clsx(
                            classes.tableHeading,
                            Styles.navyBlueColorForce
                          )}
                        >
                          <MoreHorizIcon
                            ref={anchorRef}
                            onClick={handleToggle}
                            className={classes.cursorPointer}
                          />
                          <Popper
                            open={openMenu}
                            anchorEl={anchorRef.current}
                            placement="bottom-end"
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                  id="menu-list-grow"
                                  onKeyDown={handleListKeyDown}
                                  className={Styles.navyBlueColor}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      setOpenAddCategoryDialog(true);
                                      handleClose();
                                    }}
                                  >
                                    Add Category
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      setOpenRenameCategoryDialog(true);
                                      handleClose();
                                    }}
                                  >
                                    Rename Category
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      setOpenDeleteCategoryDialog(true);
                                      handleClose();
                                    }}
                                  >
                                    Delete Category
                                  </MenuItem>
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Popper>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {documentsTableData && documentsTableData.length
                        ? documentsTableData.map((category, index) => (
                            <DocumentsRow
                              key={index}
                              category={category.name}
                              categoryId={category.id}
                              isDefault={category.isDefault}
                              documents={category.documents}
                              firstCategory={firstCategory}
                              classes={classes}
                              order={order}
                              orderBy={orderBy}
                              openRenameCategoryDialog={
                                openRenameCategoryDialog
                              }
                              setOpenDeleteDocumentDialog={
                                setOpenDeleteDocumentDialog
                              }
                              setOpenMoveDocumentDialog={
                                setOpenMoveDocumentDialog
                              }
                              setOpenArchiveDocumentDialog={
                                setOpenArchiveDocumentDialog
                              }
                              setOpenRenameDocumentDialog={
                                setOpenRenameDocumentDialog
                              }
                              getSharedWithTooltip={getSharedWithTooltip}
                              downLoadDocument={downLoadDocument}
                              setOpenShareDocumentDialog={
                                setOpenShareDocumentDialog
                              }
                              handleCheckboxChange={handleCheckboxChange}
                              downloadMultipleDocument={
                                downloadMultipleDocument
                              }
                              selectedData={selectedData}
                              setSelectedData={setSelectedData}
                              getTabWiseCategoriesList={
                                getTabWiseCategoriesList
                              }
                              documentTabName={documentTabName}
                              setOpenSignDocumentDialog={
                                setOpenSignDocumentDialog
                              }
                              setSignDocumentItem={setSignDocumentItem}
                            />
                          ))
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            )}
            {noDocumentsInTab && !searchText ? (
              <Grid container item xs={12} justifyContent="center">
                <NoDataAvailable>
                  <Typography className={Styles.navyBlueColor}>
                    Looks like you do not have any documents. Upload a document
                    to get started
                  </Typography>
                </NoDataAvailable>
              </Grid>
            ) : searchText ? (
              <SearchNotFound functionality="documents" />
            ) : null}
          </TabPanel>
        ))}
        {/* </Paper> */}
      </Grid>
    </Grid>
  );
}
