import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  makeStyles,
  TextField,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTagSomeone } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useTagSomeone";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 620,
    minWidth: !isMobileDevice() && !isTabletDevice() && 800,
  },
}));

function TagSomeoneDialog({
  openTagSomeoneDialog,
  setOpenTagSomeoneDialog,
  serviceRequestId,
  page = "",
}) {
  const classes = useStyles();
  const {
    selectedMember,
    setSelectedMember,
    tagMembers,
    tagComments,
    dueDate,
    setTagComments,
    setDueDate,
    tagMember,
    clearData,
    allSouqhUsers,
  } = useTagSomeone(serviceRequestId, page);

  return (
    <Dialog
      fullWidth
      disableBackdropClick
      open={openTagSomeoneDialog}
      onClose={() => {
        setOpenTagSomeoneDialog(false);
      }}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">
          {page === "clientHub" ? "Tag Someone" : "Tag Team Member"}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid item xs={12} style={{ paddingBottom: 16, paddingTop: 16 }}>
          <Autocomplete
            fullWidth
            noOptionsText={"No options"}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search and add"
                variant="outlined"
                autoComplete="new-password"
              />
            )}
            value={selectedMember || ""}
            options={
              page === "clientHub"
                ? allSouqhUsers
                : tagMembers.nonPremiumTeamMembersList
            }
            getOptionLabel={(option) => option.email}
            getOptionSelected={(option, value) => {
              return option.email === value.email;
            }}
            onChange={(event, value) => {
              setSelectedMember(value);
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 16 }}>
          <TextField
            multiline
            placeholder="Add comments"
            required
            rows={9}
            rowsMax={9}
            variant="outlined"
            fullWidth
            inputProps={{
              className: Styles.navyBlueColor,
              maxLength: 500,
            }}
            onChange={(event) => {
              setTagComments(event.target.value.trim());
            }}
          />
          <Grid container item justifyContent="flex-end">
            <Typography variant="body2" style={{ color: "#17174C99" }}>
              {tagComments.length}/500
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 16 }}>
          <Grid item xs={12} md={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                inputVariant="outlined"
                format="MM/dd/yyyy"
                id="date-picker-inline"
                placeholder="Select deadline"
                value={dueDate}
                autoOk={true}
                keyboardIcon={
                  <DateRangeIcon
                    style={{
                      fontSize: 20,
                      fill: "#1B1E4C",
                    }}
                  />
                }
                onChange={(event) => {
                  setDueDate(event);
                }}
                InputAdornmentProps={{
                  position: "start",
                }}
                minDate={new Date()}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions style={{ padding: "16px 24px" }}>
        <Button
          variant="outlined"
          style={{
            padding: "6px 16px",
            marginRight: 12,
            textTransform: "capitalize",
          }}
          className={Styles.navyBlueColorForce}
          onClick={() => {
            clearData();
            setOpenTagSomeoneDialog(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!selectedMember || !tagComments}
          variant="contained"
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize", padding: "6px 32px" }}
          onClick={() => {
            tagMember((response) => {
              if (response && response.data.status === 200) {
                setOpenTagSomeoneDialog(false);
                clearData();
              }
            });
          }}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TagSomeoneDialog;
