import axios from "axios";
import { useState } from "react";

export const useOpenApiClient = () => {
  const openApiClient = axios.create({
    baseURL: process.env.REACT_APP_SOUQH_API_SERVER_BASE_URL,
    // timeout: 5000,
    /**
     * Successful responses (200-299)
     * Redirects (300–399)
     * Client errors (400-499)
     */
    validateStatus: function (status) {
      return status >= 200 && status < 500;
    },
  });

  openApiClient.interceptors.request.use((config) => {
    return config;
  });

  openApiClient.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return {
    openApiClient,
  };
};
