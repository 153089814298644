import { useState } from "react";
import { useDispatch } from "react-redux";
import { useApiClient } from "../../useApiClient";
import { useAuthUser } from "../../useLogin";

export const useAddReview = () => {
  const dispatch = useDispatch();
  const { apiClient } = useApiClient();
  const userInfo = useAuthUser();

  const [ratingValue, setRatingValue] = useState(0);
  const [reviewComment, setReviewComment] = useState("");

  const addReview = (serviceRequestId, storefrontId, onResult) => {
    const data = {
      reviewComments: reviewComment,
      serviceRequestId: serviceRequestId,
      starRating: ratingValue,
      storefrontId: storefrontId,
      userId: userInfo.userId,
    };
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .post(`/storefront/service-review`, data)
      .then((res) => {
        onResult && onResult(res);
      })
      .catch((error) => {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    addReview,
    ratingValue,
    setRatingValue,
    reviewComment,
    setReviewComment,
  };
};
