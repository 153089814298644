import { Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useSQQuery } from "souqh-react-redux-hooks/common/useSQQuery";
import { usePersonateUser } from "souqh-react-redux-hooks/usePersonateUser";

export default function PersonateUser() {
  const { query } = useSQQuery();
  let linkKey = query.get("linkKey");
  const { getUserData } = usePersonateUser(linkKey);

  useEffect(() => {
    getUserData();
  }, []);
  return (
    <Grid>
      <Typography>Cloning...</Typography>
    </Grid>
  );
}
