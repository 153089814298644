import { useState, useEffect, useRef } from "react";
import { forEach, cloneDeep } from "lodash";

export const useValidations = (fields, getValues, getServerKeysMap) => {
  const [validations, setValidations] = useState([]);

  const addValidations = (result) => {
    const finalResult = [];
    const keysMap = getServerKeysMap();
    forEach(Object.keys(result), (key) => {
      let finalKey = key;
      finalKey = keysMap[key] || key;
      finalResult[finalKey] = finalResult[finalKey] || [];
      finalResult[finalKey].push(result[key]);
    });
    setValidations(finalResult);
  };

  const clearAllValidations = () => {
    setValidations([]);
  };

  useEffect(() => {
    if (fields && fields.length) {
      const clonedValidations = cloneDeep(validations);
      const values = getValues && getValues();
      forEach(fields, (field) => {
        clonedValidations[field.name] = [];
        forEach(field.patterns, (pattern) => {
          const valid = pattern.match(values[field.name]);
          if (!valid) {
            clonedValidations[field.name].push(pattern.msg);
          } else {
            delete clonedValidations[field.name][pattern.msg];
          }
        });
        if (!clonedValidations[field.name].length) {
          delete clonedValidations[field.name];
        }
        setValidations(clonedValidations);
      });
    }
  }, [fields, getValues]);

  return { validations, addValidations, clearAllValidations };
};
