import {
  SET_STOREFRONT_DATA,
  SET_STOREFRONT_ID,
  SET_STORE_FRONT_ROUTE_NAME,
} from "../../../souqh-redux/reducers/actionConstants";
import BlobManager from "../../../web/src/utils/BlobManager";
import { useActions } from "../../useActions";
import { isString, cloneDeep } from "lodash";
import { generateBlobs } from "./useFetch";
import {
  ADVANCED,
  PREMIUM,
  SERVICE_PROVIDER_USER,
  TEAM_MEMBER_USER,
} from "../../../web/src/components/ServiceProvider/serviceProviderConstants";
import { color_success } from "../../toasterColors";

const actionCreators = {
  save:
    (onResult, apiClient, isSave = false) =>
    (dispatch, getState) => {
      const {
        storefront,
        auth: {
          userDetails: {
            serviceProviderId,
            userType,
            parentServiceProviderId,
            subscriptionPlanName,
          },
        },
      } = getState();
      const tempStorefront = cloneDeep(storefront);
      const bodyFormData = new FormData();

      // banner blob
      const bannerBlob = BlobManager.getByName(tempStorefront.bannerPicture);
      bannerBlob &&
        bodyFormData.append("uploadDocs", bannerBlob.file, bannerBlob.fileName);

      // logo blob
      const logoBlob = BlobManager.getByName(tempStorefront.displayPicture);
      logoBlob &&
        bodyFormData.append("uploadDocs", logoBlob.file, logoBlob.fileName);

      // Service blob
      if (tempStorefront.services && tempStorefront.services.length) {
        tempStorefront.services.map((service) => {
          let imageBlob;
          let tempValue;
          // service image upload
          if (!service.id && service.imageUrl) {
            imageBlob = BlobManager.getByName(service.imageUrl);
            imageBlob &&
              bodyFormData.append(
                "serviceDocs",
                imageBlob.file,
                imageBlob.fileName
              );
          }
          // service documents upload
          if (!service.id && service.uploadDocUrl) {
            Object.values(service.uploadDocUrl).map((value) => {
              bodyFormData.append("serviceDocs", value, value.name);
              tempValue = value.name;
            });
            service.uploadDocUrl = tempValue;
          }
        });
      }

      //marketing brochure
      let marketingBrochureNames = [];
      if (tempStorefront.marketingRelatedDocs.length) {
        tempStorefront.marketingRelatedDocs.forEach((document, index) => {
          if (!isString(document)) {
            bodyFormData.append("uploadDocs", document, `${document.name}`);
            marketingBrochureNames.push(`${document.name}`);
          }
        });
      }

      tempStorefront.marketingRelatedDocs = [
        ...tempStorefront.marketingRelatedDocs.filter((doc) => isString(doc)), // exclude file objects from array
        ...marketingBrochureNames,
      ];
      delete tempStorefront.maxFileSize;
      delete tempStorefront.maxFileLimit;

      let storefrontClone = cloneDeep(tempStorefront);
      if (storefrontClone.services) {
        bodyFormData.append(
          "services",
          new Blob([JSON.stringify(storefrontClone.services)], {
            type: "application/json",
          })
        );
      }

      delete tempStorefront.services;
      tempStorefront.storefrontName = tempStorefront.storefrontName.trim();
      bodyFormData.append("storeFront", JSON.stringify(tempStorefront));
      bodyFormData.append(
        "storeFrontName",
        new Blob([storefront.storefrontName.trim()])
      );

      let url =
        userType === SERVICE_PROVIDER_USER ||
          (userType === TEAM_MEMBER_USER && (subscriptionPlanName === PREMIUM || subscriptionPlanName === ADVANCED))
          ? `/service-provider/storefront/?serviceProviderId=${serviceProviderId}&storeFrontId=${tempStorefront.storeFrontId}`
          : `/service-provider/storefront/?serviceProviderId=${parentServiceProviderId}&storeFrontId=${tempStorefront.storeFrontId}`;

      dispatch({ type: "SET_LOADING", value: true });
      return apiClient
        .post(url, bodyFormData)
        .then(async function (response) {
          if (
            response &&
            response.data &&
            response.data.result &&
            response.data.result.storeFrontId &&
            response.data.status === 200
          ) {
            const rStoreFront = JSON.parse(
              response.data.result.storeFrontContent
            );
            if (rStoreFront) {
              rStoreFront.storeFrontId = response.data.result.storeFrontId;
              rStoreFront.services = response.data.result.services;
              await generateBlobs(rStoreFront);
              dispatch({
                type: SET_STOREFRONT_DATA,
                payload: { storeFront: rStoreFront },
              });
              dispatch({
                type: SET_STORE_FRONT_ROUTE_NAME,
                payload: response.data.result.storeFrontRouteName,
              });
            }
          }
          if (isSave && response && response.status === 200) {
            dispatch({
              type: "SET_TOASTER",
              payload: {
                message: "Storefront saved as a Draft",
                bgColor: color_success,
                open: true,
              },
            });
          }
          dispatch({ type: "SET_LOADING", value: false });
          onResult && onResult(response);
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    },
};
export const useSave = () => {
  const actions = useActions(actionCreators);
  return { actions };
};
