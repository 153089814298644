import {
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  Tooltip,
} from "@material-ui/core";

import React, { useEffect, useState } from "react";
import OfficeAddress from "./OfficeAddress";
import BillingAddress from "./BillingAddress";
import LocationServed from "./LocationServed";
import AboutYourBusiness from "./AboutYourBusiness";
import TeamSection from "./TeamSection";
import PreferredSP from "./PreferredSP";
import SetupFooter from "../SetupFooter";
import {
  useSaveProfileData,
  useTeam,
  useValidationForLicensePractioner,
  useTeamMobileNumber,
} from "souqh-react-redux-hooks/serviceProvider/useSetUp";
import LanguagesYouSpeak from "./LanguagesYouSpeak";
import AreaOfSpecialization from "./AreaOfSpecialization";
import { useAreaOfSpec } from "souqh-react-redux-hooks/useAreaOfSpec";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import Styles from "../../../Styles.module.css";
import { usePreProfileData } from "souqh-react-redux-hooks/serviceProvider/useSetUp";
import { usePreferredSP } from "souqh-react-redux-hooks/serviceProvider/usePreferredSP";
import BusinessYearsAndEmp from "./BusinessYearsAndEmp";
import LicensedPractioner from "./LicensedPractioner";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { APPROVED } from "../../ServiceProvider/serviceProviderConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  enabledButton: {
    backgroundColor: "#FA7E61",
  },
  padding: {
    padding: "10px 26px 10px 23px",
  },
  marginTop: {
    marginTop: 20,
  },
}));
function Profile() {
  const { saveProfileData, allowAction } = useSaveProfileData();
  const { allowAction: allowLicensePractioner } =
    useValidationForLicensePractioner();
  const {
    maxLimitAreaOfSpec,
    maxLimitValidation,
    areaOfSpec,
    selectedAreaOfSpec,
    deleteItem,
    addItem,
    setCustomAreaOfSpec,
    customAreaOfSpec,
    validation,
    setValidation,
    actions: { setSelectedAreaOfSpec },
    checkDuplicate,
    getRemoteAreaOfSpec,
  } = useAreaOfSpec();

  const { allowAction: validTeamMemberData } = useTeam();
  const { allowAction: validSpData } = usePreferredSP();
  const {
    mobileNumber,
    isRandomContactNo,
    validations: mobileValidations,
    addValidations,
  } = useTeamMobileNumber();

  const { getProfileData } = usePreProfileData();
  const theme = useTheme();
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  let history = useHistory();
  const [error, setError] = useState("");
  const classes = useStyles();
  const userInfo = useAuthUser();

  useEffect(() => {
    getProfileData(() => {});
    getRemoteAreaOfSpec();
  }, []);
  return (
    <Grid
      container
      className={clsx(classes.root, classes.marginTop)}
      id="about_business"
    >
      <Container maxWidth={xlUp ? "lg" : "md"} style={{ paddingBottom: 70 }}>
        <Grid style={{ marginBottom: 48, marginTop: 84 }}>
          <Typography variant="h4" className={Styles.navyBlueColor}>
            Tell us about your business
          </Typography>
        </Grid>
        <Paper
          elevation={0}
          className={clsx(classes.padding, classes.marginTop)}
        >
          <AboutYourBusiness />
          <AreaOfSpecialization
            areaOfSpec={areaOfSpec}
            selectedAreaOfSpec={selectedAreaOfSpec}
            deleteItem={deleteItem}
            addItem={addItem}
            setCustomAreaOfSpec={setCustomAreaOfSpec}
            customAreaOfSpec={customAreaOfSpec}
            validation={validation}
            setValidation={setValidation}
            maxLimitValidation={maxLimitValidation}
            setSelectedAreaOfSpec={setSelectedAreaOfSpec}
            checkDuplicate={checkDuplicate}
          />
          <LanguagesYouSpeak />
          <BusinessYearsAndEmp />
        </Paper>
        <OfficeAddress></OfficeAddress>
        <BillingAddress></BillingAddress>
        <LocationServed></LocationServed>
        <LicensedPractioner />
        {userInfo.spApprovalStatus !== APPROVED ? (
          <TeamSection error={error} setError={setError}></TeamSection>
        ) : null}
        <PreferredSP></PreferredSP>
      </Container>
      <SetupFooter
        profile={true}
        onSaveAndDraft={() => {
          saveProfileData(() => {});
        }}
      >
        <Tooltip
          title={
            !allowAction ||
            !allowLicensePractioner ||
            maxLimitValidation ||
            !validTeamMemberData ||
            !validSpData
              ? "Please enter all information marked with an *"
              : ""
          }
        >
          <div>
            <Button
              disabled={
                !allowAction ||
                !allowLicensePractioner ||
                maxLimitValidation ||
                !validTeamMemberData ||
                !validSpData ||
                (isRandomContactNo && !mobileNumber)
              }
              variant="contained"
              onClick={() => {
                saveProfileData((res) => {
                  if (res && res.status === 200) {
                    history.push("/setup/storefront");
                  } else if (res && res.status === 400) {
                    setError(res.data.message);
                  }
                });
              }}
              className={clsx(Styles.primaryBtnAccent, {
                [classes.enabledButton]:
                  allowAction &&
                  allowLicensePractioner &&
                  !maxLimitValidation &&
                  validTeamMemberData &&
                  !validSpData &&
                  isRandomContactNo &&
                  !mobileNumber,
              })}
              style={{
                textTransform: "capitalize",
              }}
            >
              Save And Continue
            </Button>
          </div>
        </Tooltip>
      </SetupFooter>
    </Grid>
  );
}

export default Profile;
