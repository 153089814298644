import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";
import { useDeleteDocument } from "souqh-react-redux-hooks/homeBuyer/useDeleteDocument";
import { useDispatch } from "react-redux";
import { color_success } from "souqh-react-redux-hooks/toasterColors";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 350,
  },
}));

function DeleteDocumentDialog({
  openDeleteDocumentDialog,
  setOpenDeleteDocumentDialog,
  docDetails,
  categoryId,
  documentTabName,
  getTabWiseCategoriesList,
  refreshSPDocuments,
}) {
  const { deleteDocument } = useDeleteDocument(
    docDetails,
    categoryId,
    getTabWiseCategoriesList,
    documentTabName
  );
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Dialog
      open={openDeleteDocumentDialog}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Delete Document</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete {docDetails && docDetails.docName}{" "}
          permanently from Souqh?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className={Styles.navyBlueColorForce}
          style={{ textTransform: "capitalize" }}
          onClick={() => setOpenDeleteDocumentDialog(false)}
        >
          Cancel
        </Button>
        <Button
          className={Styles.primaryBtnAccent}
          style={{
            textTransform: "capitalize",
            marginRight: 16,
            padding: "6px 24px",
          }}
          onClick={() =>
            deleteDocument((res) => {
              if (res.data.status === 200) {
                setOpenDeleteDocumentDialog({
                  open: false,
                  isDeleteSuccessful: true,
                });
                dispatch({
                  type: "SET_TOASTER",
                  payload: {
                    message: res.data.message,
                    bgColor: color_success,
                    open: true,
                  },
                });
                refreshSPDocuments && refreshSPDocuments();
              }
            })
          }
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDocumentDialog;
