import { findIndex, cloneDeep } from "lodash";
import {
  SET_REVIEWS_LIST,
  SET_TOTAL_REVIEWS,
  SET_REVIEW_STATISTICS,
  UPDATE_CLIENT_REVIEW_FLAG,
  SET_STOREFRONT_SERVICES_LIST,
} from "./actionConstants";

const initialState = {
  reviewsList: [],
  totalReviews: null,
  reviewStatistics: {},
};

const storefrontClientReview = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_REVIEWS_LIST:
      newState = cloneDeep(state);
      newState.reviewsList = action.reviewsList;
      return newState;
    case SET_TOTAL_REVIEWS:
      newState = cloneDeep(state);
      newState.totalReviews = action.totalReviews;
      return newState;
    case SET_REVIEW_STATISTICS:
      newState = cloneDeep(state);
      newState.reviewStatistics = action.reviewStatistics;
      return newState;
    case UPDATE_CLIENT_REVIEW_FLAG:
      newState = cloneDeep(state);
      const myIndex = findIndex(newState.reviewsList, [
        "id",
        action.payload.id,
      ]);
      newState.reviewsList[myIndex].flagged = action.payload.flagged;
      return newState;
    case SET_STOREFRONT_SERVICES_LIST:
      newState = cloneDeep(state);
      newState.storefrontServicesList = action.storefrontServicesList;
      return newState;
    default:
      return state;
  }
};

export default storefrontClientReview;
