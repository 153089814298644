import { Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MessageItem from "./MessageItem";
import { CHAT_PANEL_MAX_WIDTH } from "../ServiceProvider/serviceProviderConstants";
import ChatPanelHeader from "./ChatPanelHeader";
import ChatPanelFooter from "./ChatPanelFooter";
import * as moment from "moment";
import { cloneDeep } from "lodash";
import { getMessageGroupData } from "../../utils/CommonUtils";
import { isMobileDevice, isTabletDevice } from "../../utils/AppUtils";

const useStyles = makeStyles((theme) => ({
  actionIcon: {
    fill: "#0e1c4e",
    cursor: "pointer",
    margin: "0px 8px",
  },
}));
export default function MessagesChatPanel({
  selectedChannelData,
  currentChannelMessages,
  messageTextBoxRef,
  messagePanelRef,
  messageTextBox,
  setMessageTextBox,
  sendMessage,
  onContentScroll,
  processMessageAttachment,
  messageAttachment,
  removeAttachment,
  sendingMessage,
  downloadAttachment,
  showEmojiPicker,
  setShowEmojiPicker,
  selectedChannelMembersList,
  setOpenGroupMembersDialog,
}) {
  const [viewMessages, setViewMessages] = useState({});
  const classes = useStyles();

  useEffect(() => {
    setViewMessages(getMessageGroupData(currentChannelMessages));
  }, [currentChannelMessages]);
  if (!selectedChannelData) {
    return null;
  }

  const getMessagesForGroup = (data) => {
    return data && data.length
      ? data.map((item, index, selfEle) => {
          return (
            <Grid item xs={12} key={index}>
              <MessageItem
                messageData={item}
                downloadAttachment={downloadAttachment}
                isGroup={selectedChannelData.isGroup}
              />
            </Grid>
          );
        })
      : null;
  };

  let messagesItems = null;

  if ((viewMessages, Object.keys(viewMessages).length)) {
    messagesItems = Object.keys(viewMessages).map((groupKey) => {
      return (
        <div key={groupKey}>
          <div
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                backgroundColor: "rgb(201, 207, 243)",
                color: "rgb(80, 101, 217)",
                padding: "2px 8px",
                borderRadius: 4,
              }}
            >
              {groupKey}
            </div>
          </div>
          {getMessagesForGroup(viewMessages[groupKey])}
        </div>
      );
    });
  } else {
    messagesItems = (
      <Typography
        variant="body1"
        style={{
          color: "#0e1c4e",
          opacity: 0.5,
          fontStyle: "italic",
          fontWeight: 300,
          textAlign: "center",
          paddingTop: 80,
        }}
      >
        No chat history available
      </Typography>
    );
  }

  return (
    <Grid
      item
      xs={12}
      style={{
        padding: isMobileDevice()
          ? "0 20px 0 10px"
          : isTabletDevice()
          ? "0 20px 0 40px"
          : "0px 80px 0px 40px",
        overflow: "hidden",
        height: "100%",
      }}
    >
      <Grid item xs={12}>
        <ChatPanelHeader
          selectedChannelData={selectedChannelData}
          isGroup={selectedChannelData.isGroup}
          selectedChannelMembersList={selectedChannelMembersList}
          setOpenGroupMembersDialog={setOpenGroupMembersDialog}
        />
      </Grid>
      <Divider
        orientation="horizontal"
        style={{
          width: "100%",
          height: 1,
          margin: "8px 0px",
        }}
      />
      <Grid
        item
        ref={messagePanelRef}
        xs={12}
        style={{
          width: "100%",
          height: "calc(100% - 180px)",
          paddingRight: 12,
          overflow: "auto",
          display: "flex",
          justifyContent: "center",
        }}
        onScroll={() => {
          onContentScroll();
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            width: "100%",
            maxWidth: CHAT_PANEL_MAX_WIDTH,
            height: "100%",
          }}
        >
          {messagesItems}
          <Grid item xs={12} style={{ height: 16 }} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ChatPanelFooter
          messageTextBox={messageTextBox}
          setMessageTextBox={setMessageTextBox}
          messageTextBoxRef={messageTextBoxRef}
          sendMessage={sendMessage}
          processMessageAttachment={processMessageAttachment}
          messageAttachment={messageAttachment}
          removeAttachment={removeAttachment}
          sendingMessage={sendingMessage}
          showEmojiPicker={showEmojiPicker}
          setShowEmojiPicker={setShowEmojiPicker}
        />
      </Grid>
    </Grid>
  );
}
