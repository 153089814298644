import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Divider,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 300,
  },
}));

function UploadCancelDialog({
  setOpenUploadCancelDialog,
  openUploadCancelDialog,
  onClose,
}) {
  const classes = useStyles();
  return (
    <Dialog
      fullWidth
      open={openUploadCancelDialog}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Confirmation</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          All the uploaded documents will get discarded. Are you sure?
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => setOpenUploadCancelDialog(false)}
          className={Styles.navyBlueColorForce}
          style={{ textTransform: "capitalize" }}
        >
          No
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpenUploadCancelDialog(false);
            onClose();
          }}
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize" }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UploadCancelDialog;
