import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";
import { covertTimeStampToDate } from "../../utils/AppUtils";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 600,
  },
}));

function RemoveMemberDialog({
  openRemoveMemberDialog,
  setOpenRemoveMemberDialog,
  removeTeamMember,
  selectedUser,
  getTagMembers,
}) {
  const classes = useStyles();
  if (selectedUser) {
    var {
      subscriptionPlan,
      fullName,
      email,
      currentInvoiceEndTimestamp,
      storefrontName,
    } = selectedUser;
  }

  let endDate = currentInvoiceEndTimestamp
    ? covertTimeStampToDate(currentInvoiceEndTimestamp)
    : null;

  let infoText =
    subscriptionPlan &&
    (subscriptionPlan.id === 1 ||
      subscriptionPlan.id === 2 ||
      subscriptionPlan.id === 4)
      ? `Even if the team member is deleted, his subscription will remain active till ${endDate}.
      ${fullName} will be able to access the Souqh features till the ${endDate} using ${email}.`
      : `You are trying to delete a team member with a premium plan. Once deleted, ${fullName} will not be able to view and
      access his independent storefront ${storefrontName}. We have sent a confirmation email to ${email}.
      Once confirmed subscription will get terminated on ${endDate}. Till ${endDate} ${fullName} and the team would be able to view 
      and access Souqh application`;

  let isPremium =
    subscriptionPlan &&
    (subscriptionPlan.id === 1 ||
      subscriptionPlan.id === 2 ||
      subscriptionPlan.id === 4)
      ? false
      : true;
  return (
    <Dialog
      fullWidth
      disableBackdropClick
      open={openRemoveMemberDialog}
      onClose={() => {
        setOpenRemoveMemberDialog(false);
      }}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Remove Team Member</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid item xs={12} style={{ paddingBottom: 16 }}>
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            style={{ margin: "50px 0 40px" }}
          >
            <img src="/images/warning.svg" style={{ height: 72, width: 72 }} />
          </Grid>
          <Typography
            variant="h6"
            className={Styles.navyBlueColor}
            style={{ marginBottom: 40 }}
          >
            Are you sure you want to remove this team member from your account?
          </Typography>
          <Typography variant="body1" className={Styles.navyBlueColor}>
            {infoText}
          </Typography>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions style={{ padding: "16px 24px" }}>
        <Grid container item xs={12}>
          <Grid container item xs={12} justifyContent="flex-end">
            <Button
              variant="outlined"
              style={{
                padding: "6px 16px",
                marginRight: 12,
                textTransform: "capitalize",
              }}
              className={Styles.navyBlueColorForce}
              onClick={() => {
                setOpenRemoveMemberDialog(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={Styles.primaryBtnAccent}
              style={{ textTransform: "capitalize", padding: "6px 32px" }}
              onClick={() => {
                removeTeamMember(selectedUser.userId, isPremium, (res) => {
                  if (res && res.data.status === 200) {
                    setOpenRemoveMemberDialog(false);
                    getTagMembers();
                  }
                });
              }}
            >
              Remove Member
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default RemoveMemberDialog;
