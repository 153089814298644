import {
  AppBar,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Styles from "../../Styles.module.css";
import MessageIcon from "@material-ui/icons/Message";
import PersonIcon from "@material-ui/icons/Person";
import DateRangeIcon from "@material-ui/icons/DateRange";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import { HOME_BUYER_USER } from "../ServiceProvider/serviceProviderConstants";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import useEmailPreferences from "souqh-react-redux-hooks/homeBuyer/useEmailPreferences";
import StoreIcon from "@material-ui/icons/Store";

const commonPreferences = [
  "messagesReceived",
  "pendingActions",
  "upcomingEvents",
  "document",
  "referral",
];

const servicePreferences = [...commonPreferences, "marketing", "client"];
const homeBuyerPreferences = [...commonPreferences, "serviceProvider"];

export default function EmailPreferences() {
  const user = useAuthUser();
  const {
    emailPreferences,
    setEmailPreferences,
    saveEmailPreferences,
    getEmailPreferences,
    resetPreferences,
  } = useEmailPreferences();

  const [preferences, setPreferences] = useState(commonPreferences);

  useEffect(() => {
    setPreferences([
      ...(user.userType !== HOME_BUYER_USER
        ? servicePreferences
        : homeBuyerPreferences),
    ]);
  }, [user.userType]);

  useEffect(() => getEmailPreferences(), []);

  const enable = preferences.every(
    (preference) => emailPreferences[preference]
  );

  const handleSwitch = (event) => {
    setEmailPreferences({
      ...emailPreferences,
      [event.target.value]: !emailPreferences[event.target.value],
    });
  };

  const handleEnableAll = () => {
    let updatedPreferences = {};
    preferences.forEach((preference) => {
      updatedPreferences[preference] = !enable;
    });
    updatedPreferences.sfVisitSummary = enable ? "NONE" : "PER_SF_VISIT";
    setEmailPreferences({
      ...emailPreferences,
      ...updatedPreferences,
    });
  };

  return (
    <Grid
      item
      xs={12}
      style={{
        height: "100%",
        overflow: "hidden",
        padding: "0 24px 120px 24px",
      }}
    >
      <Typography variant="h4" className={Styles.navyBlueColor}>
        Email Preferences
      </Typography>
      <Grid
        item
        container
        xs={12}
        style={{
          borderBottom: "1px solid #17174C1F",
          marginTop: 64,
          paddingBottom: 24,
        }}
      >
        <Grid item container xs={10} style={{ alignItems: "center" }}>
          <Typography variant="subtitle1" className={Styles.navyBlueColor}>
            Enable/Disable All
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={2}
          style={{ justifyContent: "flex-end", alignItems: "center" }}
        >
          <Switch
            size="small"
            className={"sqGraySwitch"}
            checked={enable}
            onChange={handleEnableAll}
          />
        </Grid>
      </Grid>
      {user.userType !== HOME_BUYER_USER && (
        <Grid
          item
          container
          xs={12}
          style={{
            borderBottom: "1px solid #17174C1F",
            marginTop: 24,
            paddingBottom: 24,
          }}
        >
          <Grid item container xs={10} style={{ alignItems: "center" }}>
            <Grid item xs={1}>
              <StoreIcon style={{ color: "#0e1c4e", width: "1em" }} />
            </Grid>
            <Grid item xs={11}>
              <Typography variant="subtitle1" className={Styles.navyBlueColor}>
                Storefront Visit Notifications
              </Typography>
              <Typography className={Styles.navyBlueColor}>
                Select notification frequency for storefront visits.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={2}
            style={{ justifyContent: "flex-end", alignItems: "center" }}
          >
            <Switch
              size="small"
              className={"sqGraySwitch"}
              value="sfVisitSummary"
              checked={
                emailPreferences.sfVisitSummary === "NONE" ? false : true
              }
              onChange={(event) => {
                setEmailPreferences({
                  ...emailPreferences,
                  sfVisitSummary:
                    emailPreferences.sfVisitSummary === "NONE"
                      ? "PER_SF_VISIT"
                      : "NONE", // if toggle is off initially and turned it on then set PER_SF_VISIT and if turned off later then set to NONE
                });
              }}
            />
          </Grid>
          <Grid item xs={1} />
          {emailPreferences.sfVisitSummary !== "NONE" && (
            <Grid item xs={10} style={{ marginLeft: -12 }}>
              <RadioGroup
                style={{ flexDirection: "row", alignItems: "center" }}
                value={emailPreferences.sfVisitSummary}
                onChange={(event) =>
                  setEmailPreferences({
                    ...emailPreferences,
                    sfVisitSummary: event.target.value,
                  })
                }
              >
                <FormControlLabel
                  value="PER_SF_VISIT"
                  control={<Radio color={Styles.navyBlueColor} />}
                  label="One email per storefront visit"
                  style={{ width: "100%", color: "#0e1c4e" }}
                />
                <FormControlLabel
                  value="WEEKLY"
                  control={<Radio color={Styles.navyBlueColor} />}
                  label="Weekly Summary"
                  style={{ width: "100%", color: "#0e1c4e" }}
                />
                <FormControlLabel
                  value="MONTHLY"
                  control={<Radio color={Styles.navyBlueColor} />}
                  label="Monthly Summary"
                  style={{ width: "100%", color: "#0e1c4e" }}
                />
              </RadioGroup>
            </Grid>
          )}
        </Grid>
      )}
      <Grid
        item
        container
        xs={12}
        style={{
          borderBottom: "1px solid #17174C1F",
          marginTop: 24,
          paddingBottom: 24,
        }}
      >
        <Grid item container xs={10} style={{ alignItems: "center" }}>
          <Grid item xs={1}>
            <MessageIcon className={Styles.navyBlueColorForce} />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="subtitle1" className={Styles.navyBlueColor}>
              Messages Received
            </Typography>
            <Typography className={Styles.navyBlueColor}>
              Receive an email notification for new messages received.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={2}
          style={{ justifyContent: "flex-end", alignItems: "center" }}
        >
          <Switch
            size="small"
            value="messagesReceived"
            className={"sqGraySwitch"}
            checked={emailPreferences.messagesReceived}
            onChange={handleSwitch}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        style={{
          borderBottom: "1px solid #17174C1F",
          marginTop: 24,
          paddingBottom: 24,
        }}
      >
        <Grid item container xs={10} style={{ alignItems: "center" }}>
          <Grid item xs={1}>
            <TimelapseIcon className={Styles.navyBlueColorForce} />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="subtitle1" className={Styles.navyBlueColor}>
              Pending Actions
            </Typography>
            <Typography className={Styles.navyBlueColor}>
              Receive an email notification for status updates on pending
              actions.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={2}
          style={{ justifyContent: "flex-end", alignItems: "center" }}
        >
          <Switch
            size="small"
            className={"sqGraySwitch"}
            value="pendingActions"
            checked={emailPreferences.pendingActions}
            onChange={handleSwitch}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        style={{
          borderBottom: "1px solid #17174C1F",
          marginTop: 24,
          paddingBottom: 24,
        }}
      >
        <Grid item container xs={10} style={{ alignItems: "center" }}>
          <Grid item xs={1}>
            <DateRangeIcon className={Styles.navyBlueColorForce} />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="subtitle1" className={Styles.navyBlueColor}>
              Upcoming Events
            </Typography>
            <Typography className={Styles.navyBlueColor}>
              Receive reminder email notifications for upcoming events.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={2}
          style={{ justifyContent: "flex-end", alignItems: "center" }}
        >
          <Switch
            size="small"
            className={"sqGraySwitch"}
            value="upcomingEvents"
            checked={emailPreferences.upcomingEvents}
            onChange={handleSwitch}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        style={{
          borderBottom: "1px solid #17174C1F",
          marginTop: 24,
          paddingBottom: 24,
        }}
      >
        <Grid item container xs={10} style={{ alignItems: "center" }}>
          <Grid item xs={1}>
            <PersonIcon className={Styles.navyBlueColorForce} />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="subtitle1" className={Styles.navyBlueColor}>
              {user.userType !== HOME_BUYER_USER
                ? "Clients"
                : "Service Providers"}
            </Typography>
            <Typography className={Styles.navyBlueColor}>
              Receive email notification on{" "}
              {user.userType !== HOME_BUYER_USER
                ? "client status"
                : "service provider"}{" "}
              updates and new{" "}
              {user.userType !== HOME_BUYER_USER ? "leads" : "requests"}.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={2}
          style={{ justifyContent: "flex-end", alignItems: "center" }}
        >
          <Switch
            size="small"
            className={"sqGraySwitch"}
            value={
              user.userType !== HOME_BUYER_USER ? "client" : "serviceProvider"
            }
            checked={
              user.userType !== HOME_BUYER_USER
                ? emailPreferences.client
                : emailPreferences.serviceProvider
            }
            onChange={handleSwitch}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        style={{
          borderBottom: "1px solid #17174C1F",
          marginTop: 24,
          paddingBottom: 24,
        }}
      >
        <Grid item container xs={10} style={{ alignItems: "center" }}>
          <Grid item xs={1}>
            <InsertDriveFileIcon className={Styles.navyBlueColorForce} />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="subtitle1" className={Styles.navyBlueColor}>
              Documents
            </Typography>
            <Typography className={Styles.navyBlueColor}>
              Receive an email notification for status updates on non-eSign
              documents or shared documents.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={2}
          style={{ justifyContent: "flex-end", alignItems: "center" }}
        >
          <Switch
            size="small"
            className={"sqGraySwitch"}
            value="document"
            checked={emailPreferences.document}
            onChange={handleSwitch}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        style={{
          borderBottom: "1px solid #17174C1F",
          marginTop: 24,
          paddingBottom: 24,
        }}
      >
        <Grid item container xs={10} style={{ alignItems: "center" }}>
          <Grid item xs={1}>
            <CardGiftcardIcon className={Styles.navyBlueColorForce} />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="subtitle1" className={Styles.navyBlueColor}>
              Referrals
            </Typography>
            <Typography className={Styles.navyBlueColor}>
              Receive email notifications when your referrals have accepted your
              invite.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={2}
          style={{ justifyContent: "flex-end", alignItems: "center" }}
        >
          <Switch
            size="small"
            className={"sqGraySwitch"}
            value="referral"
            checked={emailPreferences.referral}
            onChange={handleSwitch}
          />
        </Grid>
      </Grid>
      {user.userType !== HOME_BUYER_USER && (
        <Grid
          item
          container
          xs={12}
          style={{
            borderBottom: "1px solid #17174C1F",
            marginTop: 24,
            paddingBottom: 24,
          }}
        >
          <Grid item container xs={10} style={{ alignItems: "center" }}>
            <Grid item xs={1}>
              <img
                src="/images/marketing_blue.svg"
                style={{ width: "1em", paddingLeft: 6 }}
              />
            </Grid>
            <Grid item xs={11}>
              <Typography variant="subtitle1" className={Styles.navyBlueColor}>
                Marketing
              </Typography>
              <Typography className={Styles.navyBlueColor}>
                Receive email notifications on marketing updates, news letters
                and special offers from Souqh.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={2}
            style={{ justifyContent: "flex-end", alignItems: "center" }}
          >
            <Switch
              size="small"
              className={"sqGraySwitch"}
              value="marketing"
              checked={emailPreferences.marketing}
              onChange={handleSwitch}
            />
          </Grid>
        </Grid>
      )}
      <AppBar
        position="fixed"
        style={{
          top: "auto",
          bottom: 0,
          left: 380,
          width: "75%",
          backgroundColor: "#FFFFFF",
          boxShadow: "0px -3px 20px #0000001c",
        }}
      >
        <Toolbar>
          <Grid container>
            <Grid
              container
              item
              xs={12}
              justifyContent="flex-end"
              style={{ marginRight: 108 }}
            >
              <Button
                variant="outlined"
                className={Styles.navyBlueColor}
                style={{ marginRight: 12, textTransform: "capitalize" }}
                onClick={resetPreferences}
              >
                Cancel
              </Button>
              <Button
                style={{
                  marginRight: 12,
                  textTransform: "capitalize",
                  padding: "0px 24px",
                }}
                className={Styles.primaryBtnAccent}
                onClick={saveEmailPreferences}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Grid>
  );
}
