import { cloneDeep } from "lodash";
import { SET_JOURNEY_STATUS } from "./actionConstants";

const initialState = {
  journeyStatus: "",
};

const homeBuyingJourney = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_JOURNEY_STATUS:
      newState = cloneDeep(state);
      newState.journeyStatus = action.journeyStatus;
      return newState;
    default:
      return state;
  }
};

export default homeBuyingJourney;
