import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { Grid } from "@material-ui/core";
import { useSPManagementTable } from "souqh-react-redux-hooks/homeBuyer/spManagement/useSPManagementTable";
import {
  REQUEST_SP_TABLE_HEADERS,
  COMPLETED_SP_TABLE_HEADERS,
  ARCHIVE_SP_TABLE_HEADERS,
} from "./spManagementConstants";
import Styles from "../../../Styles.module.css";
import RequestSPTableRow from "./RequestSPTableRow";
import CompletedSPTableRow from "./CompletedSPTableRow";
import ArchiveSPTableRow from "./ArchiveSPTableRow";
import {
  getComparator,
  isTabletDevice,
  stableSort,
} from "../../../utils/AppUtils";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={
              isTabletDevice()
                ? { minWidth: headCell.width }
                : {
                    width: headCell.width,
                  }
            }
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className={Styles.navyBlueColorForce}
              style={{ fontSize: 16, fontWeight: 600 }}
              disabled={headCell.id === "action" || headCell.id === "id"}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function SPInfoTableView({
  tabValue,
  displaySPData,
  handlePageChange,
  getSpManagementData,
  pageNumber,
  getRequestsSPData,
  getArchiveSPData,
}) {
  const classes = useStyles();
  const [tableHeader, setTableHeader] = useState(REQUEST_SP_TABLE_HEADERS);

  const { handleRequestSort, order, orderBy, setOrderBy } =
    useSPManagementTable();

  useEffect(() => {
    switch (tabValue) {
      case 1:
        setOrderBy("requestedDate");
        return setTableHeader(REQUEST_SP_TABLE_HEADERS);
      case 2:
        setOrderBy("requestedDate");
        return setTableHeader(COMPLETED_SP_TABLE_HEADERS);
      case 3:
        setOrderBy("requestedDate");
        return setTableHeader(ARCHIVE_SP_TABLE_HEADERS);
    }
  }, [tabValue]);

  const renderSwitch = (tabValue) => {
    switch (tabValue) {
      case 1:
        return stableSort(
          displaySPData.response,
          getComparator(order, orderBy)
        ).map((data, index) => {
          return (
            <RequestSPTableRow
              data={data}
              index={index}
              key={index}
              getSpManagementData={getSpManagementData}
              pageNumber={pageNumber}
              getRequestsSPData={getRequestsSPData}
              getArchiveSPData={getArchiveSPData}
            />
          );
        });
      case 2:
        return stableSort(
          displaySPData.response,
          getComparator(order, orderBy)
        ).map((data, index) => {
          return (
            <CompletedSPTableRow
              data={data}
              index={index}
              key={index}
              getSpManagementData={getSpManagementData}
            />
          );
        });
      case 3:
        return stableSort(
          displaySPData.response,
          getComparator(order, orderBy)
        ).map((data, index) => {
          return (
            <ArchiveSPTableRow
              data={data}
              index={index}
              key={index}
              getSpManagementData={getSpManagementData}
            />
          );
        });
    }
  };

  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container item xs={12} className={classes.root}>
        <TableContainer>
          <Table aria-labelledby="tableTitle" aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={tableHeader}
            />
            <TableBody>
              {displaySPData &&
              displaySPData.response &&
              displaySPData.response.length
                ? renderSwitch(tabValue)
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        {displaySPData &&
        displaySPData.response &&
        displaySPData.response.length ? (
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            style={{ margin: "16px 0" }}
          >
            <Pagination
              count={displaySPData && displaySPData.totalPages}
              shape="rounded"
              variant="outlined"
              onChange={handlePageChange}
            />
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );
}
