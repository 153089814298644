import React from "react";
import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Carousel from "./../../Layouts/Carousel";
import StarsIcon from "@material-ui/icons/Stars";
import { useOfficeAddressReview } from "souqh-react-redux-hooks/review/useOfficeAddressReview";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: 20,
  },
  marginRight: {
    marginRight: 10,
  },
  italicFont: {
    fontStyle: "italic",
  },
  textColor: {
    color: "#0e1c4e",
  },
  paperPadding: {
    padding: "16px 22px",
  },
}));
export default function OfficeAddressReview() {
  const classes = useStyles();

  const { addresses, handleNext, handleBack, displayArray, lastIndex } =
    useOfficeAddressReview();

  return (
    <>
      <Grid
        item
        xs={12}
        className={clsx(classes.marginTop, classes.paperPadding)}
      >
        <Typography variant="h6">Office Address</Typography>
      </Grid>
      <Grid item xs={12} className={classes.paperPadding}>
        <Typography variant="subtitle1">Address Details</Typography>
        {displayArray.length ? (
          <Carousel
            originalArray={addresses}
            handleNext={handleNext}
            handleBack={handleBack}
            displayArray={displayArray}
            lastIndex={lastIndex}
          >
            {displayArray.map((address, i) => (
              <Grid key={i} item md={4} className={classes.marginRight}>
                <Grid item md={11}>
                  <Card
                    variant="outlined"
                    className={classes.textColor}
                    style={{ minHeight: "140px" }}
                  >
                    <CardContent>
                      <Grid container>
                        {address.isPrimary && (
                          <StarsIcon className={classes.marginRight} />
                        )}
                        <Typography variant="subtitle1">
                          {address.title}
                        </Typography>
                      </Grid>
                      <Typography variant="body1">
                        {address.streetNumber}, {address.streetName}
                      </Typography>
                      <Typography variant="body1">
                        {address.city && address.city.name},{" "}
                        {address.province && address.province.name},{" "}
                        {address.county && address.county}, {address.postalCode}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            ))}
          </Carousel>
        ) : (
          <Typography>No data available</Typography>
        )}
      </Grid>
    </>
  );
}
