import { cloneDeep } from "lodash";
import {
  SET_ALL_TIMELINE_DATA,
  SET_INDIVIDUAL_SP_TIMELINE_DATA,
  CLEAR_INDIVIDUAL_SP_TIMELINE_DATA,
  SET_INDIVIDUAL_SP_DOCUMENTS,
} from "./actionConstants";

const initialState = {
  allTimelineData: [],
  individualSPTimelineData: [],
  individualSPDocuments: {},
};

const homeOwnershipTimeline = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_ALL_TIMELINE_DATA:
      newState = cloneDeep(state);
      newState.allTimelineData = action.payload;
      return newState;
    case SET_INDIVIDUAL_SP_TIMELINE_DATA:
      newState = cloneDeep(state);
      newState.individualSPTimelineData = action.payload;
      return newState;
    case CLEAR_INDIVIDUAL_SP_TIMELINE_DATA:
      newState = cloneDeep(state);
      newState.individualSPTimelineData = initialState.individualSPTimelineData;
      return newState;
    case SET_INDIVIDUAL_SP_DOCUMENTS:
      newState = cloneDeep(state);
      newState.individualSPDocuments = action.payload;
      return newState;
    default:
      return state;
  }
};

export default homeOwnershipTimeline;
