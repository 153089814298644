import React, { useState } from "react";
import {
  TableCell,
  TableRow,
  Typography,
  Link,
  ButtonBase,
  Popover,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";
import {
  isMobileDevice,
  isTabletDevice,
  covertTimeStampToDate,
  getUploadedDocumentsForClientAndSPDetails,
} from "../../../utils/AppUtils";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import DocumentStatus from "../../common/DocumentStatus";
import ViewDocumentDialog from "../../DocumentManagementDialogs/ViewDocumentDialog";
import SignDocumentDialog from "../../ServiceProvider/UploadDocuments/SignDocumentDialog";
import { useDuplicateDocument } from "souqh-react-redux-hooks/homeBuyer/useDuplicateDocument";
import HBRowMenuOptions from "../HBRowMenuOptions";

export default function SPDetailsDocumentsRow({
  data,
  handleClick,
  setSelectedRow,
  categoryId,
  selectedRow,
  setOpenRenameDocumentDialog,
  openPopover,
  anchorEl,
  handleClose,
  setOpenDeleteDocumentDialog,
  selectedCategoryId,
  setSelectedCategoryId,
  setOpenMoveDocumentDialog,
}) {
  const [openDocumetDialog, setOpenDocumetDialog] = useState(false);
  const [documentItem, setDocumentItem] = useState(null);
  const [openSignDocumentDialog, setOpenSignDocumentDialog] = useState(false);
  const [signDocumentItem, setSignDocumentItem] = useState(null);

  const { duplicateDocument } = useDuplicateDocument(null, null);

  return (
    <React.Fragment>
      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        elevation={1}
      >
        <HBRowMenuOptions
          row={selectedRow}
          selelctedRow={selectedRow}
          categoryId={selectedCategoryId}
          setOpenDeleteDocumentDialog={setOpenDeleteDocumentDialog}
          setOpenMoveDocumentDialog={setOpenMoveDocumentDialog}
          setOpenRenameDocumentDialog={setOpenRenameDocumentDialog}
          handleInnerClose={handleClose}
          openInnerMenu={anchorEl ? true : false}
          handleInnerListKeyDown={null}
          duplicateDocument={duplicateDocument}
        />
      </Popover>
      <ViewDocumentDialog
        openDocumetDialog={openDocumetDialog}
        setOpenDocumetDialog={setOpenDocumetDialog}
        documentItem={documentItem}
        setDocumentItem={setDocumentItem}
      ></ViewDocumentDialog>
      <SignDocumentDialog
        openSignDocumentDialog={openSignDocumentDialog}
        setOpenSignDocumentDialog={setOpenSignDocumentDialog}
        signDocumentItem={signDocumentItem}
        setSignDocumentItem={setSignDocumentItem}
      />
      {data.documents && data.documents.length
        ? data.documents.map((row, index) => {
            const isLinkEnabled = row.signNowDocumentId
              ? row.enableUrl
                ? true
                : false
              : true;
            return (
              <TableRow key={index}>
                <TableCell
                  style={{
                    width:
                      isTabletDevice() || isMobileDevice() ? "18em" : "21%",
                    overflow: "hidden",
                  }}
                >
                  <Link
                    color="inherit"
                    style={{
                      wordBreak: "break-all",
                      fontSize: 16,

                      opacity: isLinkEnabled ? 1 : 0.6,
                      cursor: isLinkEnabled ? "pointer" : "default",
                      textDecoration: isLinkEnabled ? "underline" : "none",
                    }}
                    className={Styles.navyBlueColorForce}
                    onClick={() => {
                      if (!isLinkEnabled) {
                        return;
                      }
                      if (row.enableUrl && row.signNowDocumentId) {
                        setSignDocumentItem(row);
                        setOpenSignDocumentDialog(true);
                      } else {
                        setDocumentItem(
                          getUploadedDocumentsForClientAndSPDetails(row)
                        );
                        setOpenDocumetDialog(true);
                      }
                    }}
                  >
                    {row.docName}
                  </Link>
                </TableCell>
                <TableCell
                  style={{
                    width:
                      isTabletDevice() || isMobileDevice() ? "12em" : "20%",
                  }}
                >
                  <Typography
                    variant="body1"
                    className={clsx(Styles.sqEllipsis, Styles.navyBlueColor)}
                  >
                    {row.docDescription}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    width: isTabletDevice() || isMobileDevice() ? "8em" : "18%",
                    paddingRight: 0,
                  }}
                >
                  <DocumentStatus docStatus={row.docStatus} />
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    width:
                      isTabletDevice() || isMobileDevice() ? "12em" : "18%",
                    paddingLeft: 0,
                  }}
                >
                  <Typography variant="body1" className={Styles.navyBlueColor}>
                    {covertTimeStampToDate(row.lastChange)}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    width: isTabletDevice() || isMobileDevice() ? "2em" : "1%",
                  }}
                >
                  <ButtonBase>
                    <MoreHorizIcon
                      className={Styles.navyBlueColor}
                      onClick={(event) => {
                        setSelectedRow(row);
                        setSelectedCategoryId(categoryId);
                        handleClick(event, row.docId, row.signNowDocumentId);
                      }}
                    />
                  </ButtonBase>
                  {/* FIX ME: will remove after testing */}
                  {/* <Popover
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    elevation={1}
                  >
                    <HBRowMenuOptions
                      row={row}
                      selelctedRow={selectedRow}
                      categoryId={selectedCategoryId}
                      setOpenDeleteDocumentDialog={setOpenDeleteDocumentDialog}
                      setOpenMoveDocumentDialog={setOpenMoveDocumentDialog}
                      setOpenRenameDocumentDialog={setOpenRenameDocumentDialog}
                      handleInnerClose={handleClose}
                      openInnerMenu={anchorEl ? true : false}
                      handleInnerListKeyDown={null}
                      duplicateDocument={duplicateDocument}
                    />
                  </Popover> */}
                </TableCell>
              </TableRow>
            );
          })
        : null}
    </React.Fragment>
  );
}
