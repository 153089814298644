import { useDispatch } from "react-redux";
import { useApiClient } from "../useApiClient";
import { useAuthUser } from "../useLogin";
import { useMessaging } from "./useMessaging";

export const useAudioVideoCalling = (selectedChannelData) => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();
  const userInfo = useAuthUser();

  const { requestServiceMessageFromBackground } = useMessaging(false);

  const getCallLink = (onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post(`/enablex/createOneOnOneMeeting`, {
        moderatorEmail: userInfo.email,
        moderatorName: `${userInfo.firstName} ${userInfo.lastName}`,
        participantEmail:
          (selectedChannelData && selectedChannelData.email) || "",
        participantName:
          (selectedChannelData && selectedChannelData.fullName) || "",
      })
      .then((response) => {
        onResult && onResult(response);
      })
      .catch(function (error) {
        dispatch({ type: "SET_SERVER_ERROR", error: error });
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getInstantMeetingLink = (onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post(`/enablex/createInstantMeeting`, {
        moderatorEmail: userInfo.email,
        moderatorName: `${userInfo.firstName} ${userInfo.lastName}`,
        participantEmail: "",
        participantName: "",
      })
      .then((response) => {
        onResult && onResult(response);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    getCallLink,
    requestServiceMessageFromBackground,
    getInstantMeetingLink,
  };
};
