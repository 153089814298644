import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { useApiClient } from "./useApiClient";

const getRemoteBusinessTypeActionCreator = (apiClient) => (dispatch) => {
  dispatch({ type: "SET_LOADING", value: true });
  return apiClient
    .get("/business/types")
    .then(function (response) {
      if (!response.data.content.length) {
        // we need response.data.content with values in every condition
        throw Error("Server Error : Business type not found");
      } else {
        dispatch({ type: "SET_BUSINESS_TYPE", businessTypes: response.data });
      }
    })
    .catch(function (error) {
      console.error(error);
    })
    .then(function () {
      // always executed
      dispatch({ type: "SET_LOADING", value: false });
    });
};

const getBusinessType = createSelector(
  (state) => state.metaData.businessTypes,
  (businessTypes) => {
    if (!businessTypes) return [];
    return businessTypes.content.map((v) => ({
      id: v.id,
      name: v.name,
      logoUrl: v.logoUrl,
      localeCode: v.localeCode,
      checked: false,
      shortName: v.shortName,
    }));
  }
);

export const useBusinessType = () => {
  const dispatch = useDispatch();
  const businessTypes = useSelector(getBusinessType);
  const { apiClient } = useApiClient();

  useEffect(() => {
    if (!businessTypes.length) {
      dispatch(getRemoteBusinessTypeActionCreator(apiClient));
    }
  }, [businessTypes]);

  return { businessTypes };
};
