import {
  SET_DEMOGRAPHIC_DATA,
  UPDATE_DEMOGRAPHIC_DATA,
} from "souqh-redux/reducers/actionConstants";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useActions } from "../../useActions";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { useApiClient } from "../../useApiClient";
import { MARITAL_STATUS } from "../../../web/src/components/HBRegistration/HomeBuyerConstants";

const actionCreators = {
  updateDemographiData: (payload) => ({
    type: UPDATE_DEMOGRAPHIC_DATA,
    payload,
  }),
};

const getPropertValuesActionCreator = (apiClient) => (dispatch) => {
  dispatch({ type: "SET_LOADING", value: true });
  return apiClient
    .get(`/home-buyer/demographic/property-value`)
    .then(function (response) {
      if (!response.data.result.length) {
        // we need response.data.content with values in every condition
        throw Error("Server Error : Property types not found");
      } else {
        dispatch({
          type: "SET_PROPERTY_VALUES",
          payload: response.data.result,
        });
      }
    })
    .catch(function (error) {
      console.error(error);
    })
    .then(function () {
      dispatch({ type: "SET_LOADING", value: false });
    });
};

const getIndustryActionCreator = (apiClient) => (dispatch) => {
  dispatch({ type: "SET_LOADING", value: true });
  return apiClient
    .get(`/home-buyer/demographic/industry`)
    .then(function (response) {
      if (!response.data.result.length) {
        // we need response.data.content with values in every condition
        throw Error("Server Error : Property types not found");
      } else {
        dispatch({
          type: "SET_INDUSTRY_TYPES",
          payload: response.data.result,
        });
      }
    })
    .catch(function (error) {
      console.error(error);
    })
    .then(function () {
      dispatch({ type: "SET_LOADING", value: false });
    });
};
const getHouseHoldIncomeRange = (apiClient) => (dispatch) => {
  dispatch({ type: "SET_LOADING", value: true });
  return apiClient
    .get(`/home-buyer/demographic/household-income-range`)
    .then(function (response) {
      if (!response.data.result.length) {
        // we need response.data.content with values in every condition
        throw Error("Server Error : Property types not found");
      } else {
        dispatch({
          type: "SET_HOUSEHOLD_INCOME_RANGE",
          payload: response.data.result,
        });
      }
    })
    .catch(function (error) {
      console.error(error);
    })
    .then(function () {
      dispatch({ type: "SET_LOADING", value: false });
    });
};
export const useDemographicData = (demographicsGetcall = false) => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();
  const propertyValues = useSelector((state) => state.hbSetup.propertyValues);
  const industryTypes = useSelector((state) => state.hbSetup.industryTypes);
  const householdIncomeRange = useSelector(
    (state) => state.hbSetup.householdIncomeRange
  );
  const { demographicData } = useSelector((state) => state.hbSetup);
  const actions = useActions(actionCreators);
  const user = useAuthUser();

  useEffect(() => {
    if (!propertyValues && !demographicsGetcall) {
      dispatch(getPropertValuesActionCreator(apiClient));
    }
  }, [propertyValues]);

  useEffect(() => {
    if (!industryTypes && !demographicsGetcall) {
      dispatch(getIndustryActionCreator(apiClient));
    }
  }, [industryTypes]);
  useEffect(() => {
    if (!householdIncomeRange && !demographicsGetcall) {
      dispatch(getHouseHoldIncomeRange(apiClient));
    }
  }, [householdIncomeRange]);
  const data = {
    ageGroup: demographicData.ageGroup,
    employment:
      demographicData.employment && demographicData.employment === "Employed"
        ? "EMPLOYED"
        : "SELF_EMPLOYED",
    householeIncomeRangeId:
      demographicData.householdIncomeRange &&
      demographicData.householdIncomeRange.id,
    id: demographicData.id || null,
    industryId: demographicData.industry && demographicData.industry.id,
    maritalStatus: demographicData.maritalStatus,
    propertyValueId:
      demographicData.propertyValue && demographicData.propertyValue.id,
  };
  const saveDemographicData = (onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .put(`/home-buyer/profile/demographic/${user.userId}`, data)
      .then((res) => {
        onResult && onResult(res);
      })
      .catch((error) => {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };
  const getDemographicData = (userId) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/home-buyer/profile/demographic/${userId}`)
      .then(async (response) => {
        let data;
        if (response.data.result) {
          data = mapKeysToReducer(response.data.result);
        } else {
          data = {
            ageGroup: user.ageGroup,
            maritalStatus:
              (data &&
                data.maritalStatus &&
                MARITAL_STATUS.filter(
                  (mData) => mData.value === data.maritalStatus
                )[0]) ||
              null,
          };
        }
        dispatch({
          type: SET_DEMOGRAPHIC_DATA,
          payload: data,
        });
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };
  const mapKeysToReducer = (data) => {
    return {
      ...data,
      id: data.id,
      employment:
        data.employment &&
        (data.employment === "EMPLOYED" ? "Employed" : "Self Employed"),
      maritalStatus: data.maritalStatus
        ? MARITAL_STATUS.filter(
            (mData) => mData.value === data.maritalStatus
          )[0]
        : null,
    };
  };
  return {
    propertyValues,
    industryTypes,
    householdIncomeRange,
    demographicData,
    actions,
    saveDemographicData,
    getDemographicData,
  };
};
