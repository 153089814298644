import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import { useMessaging } from "souqh-react-redux-hooks/messaging/useMessaging";
import { cloneDeep } from "lodash";

const useStyles = makeStyles((theme) => ({
  font14: {
    fontSize: 14,
  },
  font14Bold: {
    fontSize: 14,
    fontWeight: 700,
  },
  marginTop20: {
    marginTop: 20,
  },
  marginTop10: {
    marginTop: 10,
  },
}));

export default function BroadcastConfirmationDialog({
  openBroadcastConfirmationDialog,
  setOpenBroadcastConfirmationDialog,
  createBroadcastService,
  searchedCategory,
  searchedCity,
  timelineOption,
  description,
  clearData,
  getMatchingServiceProviderDetails,
  matchingSPCount,
  setOpenBroadcastServiceRequestDialog,
  setOpenRentalInquiryFormDialog,
  rentalForNewComersDetails,
  matchingSPEmails,
  setMatchingSPEmails,
  broadcastServiceRequestDocuments
}) {
  const classes = useStyles();
  const { onSendBroadcastMessage, channelList, loadChannelsData } = useMessaging(false)
  const [userListToBroadCast, setUserListToBroadCast] = useState([]);

  // let emailArr = ["apeksha.pawar@synerzip.com", "souqhsp@gmail.com"]

  // useEffect(() => {
  //   getMatchingServiceProviderDetails(searchedCategory, searchedCity);
  // }, [openBroadcastConfirmationDialog]);

  // useEffect(() => {
  //   loadChannelsData(true)
  // }, [])

  // useEffect(() => {
  //   setUserListToBroadCast(
  //     cloneDeep(channelList).filter((item) => item.Username)
  //   );
  // }, channelList.length)

  // useEffect(() => {
  //   setUserListToBroadCast(
  //     cloneDeep(channelList).filter((item) => matchingSPEmails.includes(item.email))
  //   );
  // }, channelList.length)
  // console.log("HBHBHBHBBH", channelList, "-----", userListToBroadCast)
  return (
    <Dialog fullWidth open={openBroadcastConfirmationDialog} maxWidth={"sm"}>
      <DialogTitle>
        <Grid item container xs={12}>
          <Grid item xs={11}>
            <Typography
              variant="subtitle1"
              className={Styles.navyBlueColorForce}
            >
              Confirmation
            </Typography>
          </Grid>
          <Grid item xs={1} container style={{ justifyContent: "flex-end" }}>
            <Button
              style={{ padding: 0, minWidth: 0 }}
              onClick={() => {
                setOpenBroadcastConfirmationDialog(false);
              }}
            >
              <CloseIcon className={Styles.navyBlueColorForce} />
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent
        className={Styles.navyBlueColorForce}
        style={{ minHeight: 70 }}
      >
        <Grid container className={classes.marginTop10}>
          <Grid item>
            <Typography className={classes.font14} style={{ marginBottom: 16 }}>
              Posting your{" "}
              <Typography
                variant="subtitle1"
                className={classes.font14}
                style={{ marginBottom: 16, display: "inline" }}
              >
                {searchedCategory?.name}
              </Typography>{" "}
              service request in{" "}
              <Typography
                variant="subtitle1"
                className={classes.font14}
                style={{ marginBottom: 16, display: "inline" }}
              >
                {searchedCity?.cityDTO[0].shortName} ({searchedCity?.name})
              </Typography>
              , which will be sent out to{" "}
              <Typography
                variant="subtitle1"
                className={classes.font14}
                style={{ marginBottom: 16, display: "inline" }}
              >
                {matchingSPCount}
              </Typography>{" "}
              service providers. Do you want to post this{" "}
              {rentalForNewComersDetails ? "rental" : "service"} request now?
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          className={Styles.grayTextForce}
          style={{ textTransform: "capitalize", cursor: "pointer" }}
          onClick={() => {
            setOpenBroadcastConfirmationDialog(false);
            setMatchingSPEmails([])
          }}
        >
          Go Back
        </Button>

        <Button
          style={{
            textTransform: "capitalize",
            marginRight: 10,
            cursor: "pointer",
          }}
          onClick={() => {
            createBroadcastService(
              searchedCategory,
              searchedCity,
              timelineOption,
              description,
              rentalForNewComersDetails ? rentalForNewComersDetails : null, // for rentalForNewComersDetails
              (res) => {
                if (res && res.data.status === 200) {
                  onSendBroadcastMessage(
                    (description || "").trim(),
                    userListToBroadCast,
                    () => {
                      setOpenBroadcastConfirmationDialog(false);
                      setOpenBroadcastServiceRequestDialog &&
                        setOpenBroadcastServiceRequestDialog(false);
                      setOpenRentalInquiryFormDialog &&
                        setOpenRentalInquiryFormDialog(false);
                      clearData();
                      setMatchingSPEmails([])
                    },
                    true, // isBSR value
                    matchingSPEmails,
                    cloneDeep(broadcastServiceRequestDocuments)
                  );
                  setOpenBroadcastConfirmationDialog(false);
                  setOpenBroadcastServiceRequestDialog &&
                    setOpenBroadcastServiceRequestDialog(false);
                  setOpenRentalInquiryFormDialog &&
                    setOpenRentalInquiryFormDialog(false);
                  clearData();
                  setMatchingSPEmails([])
                }
              }
            );
          }}
          className={clsx(Styles.navyBlueColorForce, Styles.primaryBtnAccent)}
        >
          {rentalForNewComersDetails
            ? "Post Rental Request"
            : "Post Service Request"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
