import {
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Styles from "../../../Styles.module.css";

const styles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined": {
      top: -6,
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px",
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      padding: "0px",
    },
    "& .MuiAutocomplete-endAdornment": {
      display: "none",
    },
  },
}));
export default function SignOrderRow({
  signOrders,
  signData,
  index,
  updateSignOrderChange,
  updateEmailTouchedState,
  updateEmailValue,
  SPContacts,
  primaryApplicantList,
}) {
  const classes = styles();

  const getEmailField = () => {
    const isPrimaryAplicant = index === 0;
    const optionsList = isPrimaryAplicant ? primaryApplicantList : SPContacts;

    return (
      <TextField
        fullWidth
        placeholder="Email Address"
        variant="outlined"
        value={signData.email}
        onChange={(e) => {
          updateEmailValue(e.target.value, index);
        }}
        onBlur={() => {
          updateEmailTouchedState(true, index);
        }}
        required
        error={!signData.isValidEmail && signData.touchedEmail}
        helperText={
          !signData.isValidEmail && signData.touchedEmail
            ? "Please enter an valid email address"
            : ""
        }
        inputProps={{
          maxLength: 500,
        }}
      />
    );
  };
  return (
    <Grid
      item
      container
      xs={12}
      alignItems="center"
      className={classes.root}
      style={{ padding: "8px 0px" }}
    >
      <Grid item xs={3}>
        <Grid item container xs={11}>
          <Typography variant="subtitle1" className={Styles.navyBlueColor}>
            {signData.roleLabel}:
          </Typography>

          {/* <FormControl variant="outlined" fullWidth>
              <Select
                labelId="primary-applicant"
                value={signData.role}
                onChange={(e) => {}}
                required
              >
                {signRoles &&
                  signRoles.length &&
                  signRoles.map((role, index) => (
                    <MenuItem key={index} value={role.role}>
                      {role.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl> */}
        </Grid>
      </Grid>
      <Grid item container xs={9} alignItems="center">
        <Grid item container xs={8} alignItems="center">
          <Grid item container xs={11}>
            {getEmailField()}
          </Grid>
        </Grid>
        <Grid item container xs={4} alignItems="center">
          <Grid item container xs={11} alignItems="center">
            <FormControl variant="outlined" fullWidth>
              <Select
                labelId="primary-applicant"
                value={signData.order}
                onChange={(e) => {
                  updateSignOrderChange(e.target.value, index);
                }}
              >
                {signOrders &&
                  signOrders.length &&
                  signOrders.map((order, index) => (
                    <MenuItem key={index} value={order.order}>
                      {order.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            justifyContent: "flex-end",
            display: "flex",
            paddingRight: 8,
          }}
        ></Grid>
      </Grid>
    </Grid>
  );
}
