import { isTabletDevice } from "../../../utils/AppUtils";

export const SP_TAB_NAMES = [
  { value: "Active", key: "active" },
  { value: "Requests", key: "requests" },
  { value: "Completed", key: "completed" },
  { value: "Archive", key: "archive" },
];

export const REQUEST_SP_TABLE_HEADERS = [
  {
    id: "id",
    label: "#",
    width: "1%",
  },
  {
    id: "storeFrontName",
    label: "Service Provider",
    width: isTabletDevice() ? "14em" : "25%",
  },
  // {
  //   id: "status",
  //   label: "Status",
  //   width: "13%",
  // },
  {
    id: "requestedDate",
    label: "Request Date",
    width: isTabletDevice() ? "12em" : "15%",
  },
  {
    id: "notes",
    label: "Service Notes",
    width: "30%",
  },
  {
    id: "action",
    label: "Action",
    width: isTabletDevice() ? "11em" : "15%",
  },
  {
    id: "menu",
    label: "",
    width: "1%",
  },
];

export const COMPLETED_SP_TABLE_HEADERS = [
  {
    id: "id",
    label: "#",
    width: "1%",
  },
  {
    id: "storeFrontName",
    label: "Service Provider Details",
    width: "32%",
  },
  {
    id: "requestedDate",
    label: "Request Date",
    width: isTabletDevice() ? "12em" : "16%",
  },
  {
    id: "completeDate",
    label: "Complete Date",
    width: isTabletDevice() ? "13em" : "18%",
  },
  {
    id: "review",
    label: "Review",
    width: isTabletDevice() ? "25em" : "32%",
  },
  {
    id: "menu",
    label: "",
    width: "1%",
  },
];

export const ARCHIVE_SP_TABLE_HEADERS = [
  {
    id: "id",
    label: "#",
    width: "1%",
  },
  {
    id: "storeFrontName",
    label: "Service Provider Details",
    width: "34%",
  },
  {
    id: "requestedDate",
    label: "Request Date",
    width: isTabletDevice() ? "12em" : "16%",
  },
  {
    id: "archiveInActiveDate",
    label: "Archive Date",
    width: isTabletDevice() ? "11em" : "16%",
  },
  {
    id: "review",
    label: "Review",
    width: isTabletDevice() ? "25em" : "30%",
  },
  {
    id: "menu",
    label: "",
    width: "3%",
  },
];

export const PAGE_SIZE = 24;
