import { cloneDeep } from "lodash";
import { SET_DOCUMENTS_TABLE_DATA } from "./actionConstants";

const initialState = {
  documentsTableData: [],
};

const documentsData = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_DOCUMENTS_TABLE_DATA:
      newState = cloneDeep(state);
      newState.documentsTableData = action.payload.documentsTableData;
      return newState;
    default:
      return state;
  }
};

export default documentsData;
