import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  SET_PREFERRED_SP_SUPPORT,
  REMOVE_ALL_PREFERRED_SP,
  ADD_PREFERRED_SP,
  DELETE_PREFERRED_SP,
  SAVE_PREFERRED_SP,
} from "souqh-redux/reducers/actionConstants";
import { useActions } from "../useActions";
import { useValidations } from "../useValidations";
import { emailValidationMeta, mobileNumberValidationMeta } from "../validationMetas";
import { find } from "lodash";

const testActionCreators = {
  setPreferredSpSupport: (payload) => ({
    type: SET_PREFERRED_SP_SUPPORT,
    payload,
  }),
  addPreferredSP: (payload) => ({
    type: ADD_PREFERRED_SP,
    payload,
  }),
  deletePreferredSP: (payload) => ({
    type: DELETE_PREFERRED_SP,
    payload,
  }),
  savePreferrefSP: (payload) => ({
    type: SAVE_PREFERRED_SP,
    payload,
  }),
};
export const usePreferredSP = () => {
  const dispatch = useDispatch();
  const { preferredSPSupport, preferredSPs } = useSelector(
    (state) => state.spSetup
  );
  const [allowAction, setAllowAction] = useState(false);

  useEffect(() => {
    if (!preferredSPSupport) {
      if (preferredSPs.length) {
        dispatch({ type: REMOVE_ALL_PREFERRED_SP });
      }
      setAllowAction(true); // if toggle off dont need validation on service providers.
    } else {
      setAllowAction(false); // if toggle ON , check if any of the data in service providers table is empty
    }
  }, [preferredSPSupport]);

  useEffect(() => {
    // check if any of the data in Team Memeber table is empty
    let validData;
    if (preferredSPs.length) {
      validData = preferredSPs.some((sp) => {
        return sp.spType && !!sp.spFullName && !!sp.spEmail;
      });
      setAllowAction(validData);
    } else if (!preferredSPs.length && preferredSPSupport) {
      setAllowAction(false);
    } else {
      setAllowAction(true);
    }
  }, [preferredSPs]);

  const actions = useActions(testActionCreators);
  return {
    preferredSPSupport,
    preferredSPs,
    actions,
    allowAction,
  };
};

const validationMeta = [emailValidationMeta, mobileNumberValidationMeta];
export const useSPMember = () => {
  const [spType, setSPType] = useState("");
  const [businessTypeName, setBusinessTypeName] = useState("");
  const [spFullName, setSPFullName] = useState("");
  const [email, setSPEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [website, setWebsite] = useState("");
  const [allowAddSP, setAllowAddSP] = useState(false);
  const { preferredSPs } = useSelector((state) => state.spSetup);

  const getFormValues = useCallback(() => {
    return { spType, spFullName, email, businessTypeName, phoneNumber };
  }, [spType, spFullName, email, businessTypeName, phoneNumber]);

  const getServerKeysMap = useCallback(() => {
    return {
      contactNumber: "phoneNumber"
    };
  }, []);

  const { validations, addValidations, clearAllValidations } = useValidations(
    validationMeta,
    getFormValues,
    getServerKeysMap
  );

  useEffect(() => {
    let validForm =
      !!email &&
      !!spType &&
      !!spFullName.trim() &&
      !!phoneNumber &&
      !Object.keys(validations).length;
    setAllowAddSP(validForm);
  }, [email, spType, spFullName, validations]);

  useEffect(() => {
    if (email) {
      const found = find(
        preferredSPs,
        (sp) => sp.spEmail.toUpperCase().trim() === email.toUpperCase().trim()
      );
      if (found) {
        setAllowAddSP(false);
        addValidations({
          email: "Service provider with same email already exist",
        });
      }
    }
  }, [preferredSPs, email, spType, spFullName]);

  return {
    validations,
    spType,
    setSPType,
    businessTypeName,
    setBusinessTypeName,
    spFullName,
    setSPFullName,
    email,
    setSPEmail,
    allowAddSP,
    phoneNumber,
    setPhoneNumber,
    website,
    setWebsite
  };
};
