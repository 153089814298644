import { useState } from "react";
import { useSelector } from "react-redux";
import {
  ADD_MARKETING_RELATED_FILES,
  DELETE_MARKETING_RELATED_FILE,
} from "../../../souqh-redux/reducers/actionConstants";
import { useActions } from "../../useActions";

const actionCreators = {
  addFiles: (payload) => ({
    type: ADD_MARKETING_RELATED_FILES,
    payload,
  }),
  deleteFile: (payload) => ({
    type: DELETE_MARKETING_RELATED_FILE,
    payload,
  }),
};

export const useMarketingBrochure = () => {
  const actions = useActions(actionCreators);
  const { maxFileSize, maxFileLimit, marketingRelatedDocs } = useSelector(
    (state) => state.storefront
  );
  const validateFiles = (event) => {
    let maxFileSize = false;
    let maxFileLimit = false;
    let files = event.target.files;

    files &&
      Object.keys(files).forEach((key) => {
        if ((files[key].size / 1000 / 1000).toFixed(2) > 50) {
          // check file size less than 50mb
          maxFileSize = true;
        }
      });
    // Count newly added files + already added files
    if (marketingRelatedDocs.length + files.length > 25) {
      maxFileLimit = true;
    }
    actions.addFiles({ files, maxFileSize, maxFileLimit });
    event.target.value = "";
  };
  return {
    marketingRelatedDocs,
    actions,
    validateFiles,
    maxFileSize,
    maxFileLimit,
  };
};
