import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  makeStyles,
  Divider,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 250,
  },
}));

function DeleteCategoryConfirmationDialog({
  openDeleteCategoryConfirmationDialog,
  setOpenDeleteCategoryConfirmationDialog,
  categoryName,
  deleteCategory,
  setOpenDeleteCategoryDialog,
  setSelectedCategoryName,
  setSelectedCategory,
}) {
  const classes = useStyles();
  return (
    <Dialog
      fullWidth
      open={openDeleteCategoryConfirmationDialog}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Delete Document</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          Are you sure to delete category {categoryName}?
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          className={Styles.navyBlueColorForce}
          style={{ textTransform: "capitalize" }}
          onClick={() => setOpenDeleteCategoryConfirmationDialog(false)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize" }}
          onClick={() =>
            deleteCategory((res) => {
              setOpenDeleteCategoryConfirmationDialog(false);
              if (res.data.status === 200) {
                setOpenDeleteCategoryDialog(false);
                setSelectedCategoryName("");
                setSelectedCategory("");
              }
            })
          }
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteCategoryConfirmationDialog;
