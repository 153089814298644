import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Divider,
  Chip,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";
import SPSelectContactsToUpload from "../common/UploadWizard/SPSelectContactsToUpload";
import { useSPUploadWizard } from "souqh-react-redux-hooks/serviceProvider/documents/useSPUploadWizard";
import { useShareDocuments } from "souqh-react-redux-hooks/common/useShareDocument";
import { HOME_BUYER_USER } from "../ServiceProvider/serviceProviderConstants";
import { isMobileDevice, isTabletDevice } from "../../utils/AppUtils";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: isMobileDevice() ? "79%" : isTabletDevice() ? "83%" : 733,
    minWidth: isTabletDevice() ? 700 : !isMobileDevice() && 1140,
  },
}));

function ShareDocumentDialog({
  openShareDocumentDialog,
  setOpenShareDocumentDialog,
  selectedData,
  getTabWiseCategoriesList,
  deselectDocfromSelection,
  documentTabName,
}) {
  const classes = useStyles();

  const {
    actions: { setSelectedContacts, setActiveCustomers },
    userType,
    searchText,
    setSearchText,
    tabs,
    activeTab,
    setActiveTab,
    getContacts,
    contacts,
    setCustomContacts,
    customContacts,
    addItem,
    handleCustomerSelect,
    selectedActiveCustomers,
    selectedContacts,
    getActiveCustomers,
    selectAllCustomers,
    setSelectAllCustomers,
    getFilteredCustomers,
    setUserType,
    setTabs,
    userInfo,
    hasLoadedList,
    activeCustomers,
    refreshSelectedActiveCustomers,
    removeFromOtherContacts,
  } = useSPUploadWizard();

  useEffect(() => {
    if (userInfo) {
      setUserType(userInfo.userType);
    } else {
      setUserType("");
    }
  }, [userInfo]);

  useEffect(() => {
    if (userType === HOME_BUYER_USER) {
      setTabs(["Active Service Providers", "Other Contacts"]);
    } else {
      setTabs(["Active Clients", "Other Contacts"]);
    }
  }, [userType]);

  useEffect(() => {
    if (openShareDocumentDialog) {
      getContacts();
      getActiveCustomers();
    }
  }, [openShareDocumentDialog]);

  const { shareDocuments } = useShareDocuments(
    selectedData,
    selectedContacts,
    getTabWiseCategoriesList,
    documentTabName,
    selectedActiveCustomers
  );
  return (
    <Dialog
      fullWidth
      open={openShareDocumentDialog}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Share Document</Typography>
      </DialogTitle>
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        style={{ width: "100%" }}
      />
      <DialogContent>
        <Grid container className={Styles.navyBlueColor}>
          <Grid container item xs={12} alignContent="center">
            {selectedData && selectedData.length
              ? selectedData.map((item, index) => (
                  <Chip
                    key={index}
                    label={item.docName}
                    variant="outlined"
                    className={Styles.navyBlueColorForce}
                    onDelete={() => {
                      deselectDocfromSelection(item.docId);
                    }}
                    style={{ marginBottom: 8 }}
                  />
                ))
              : null}
          </Grid>
          <Divider
            orientation="horizontal"
            variant="fullWidth"
            style={{ width: "100%" }}
          />
          <SPSelectContactsToUpload
            searchText={searchText}
            setSearchText={setSearchText}
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            getContacts={getContacts}
            contacts={contacts}
            setCustomContacts={setCustomContacts}
            customContacts={customContacts}
            addItem={addItem}
            handleCustomerSelect={handleCustomerSelect}
            selectedActiveCustomers={selectedActiveCustomers}
            selectedContacts={selectedContacts}
            setSelectedContacts={setSelectedContacts}
            selectAllCustomers={selectAllCustomers}
            setSelectAllCustomers={setSelectAllCustomers}
            getFilteredCustomers={getFilteredCustomers}
            userType={userType}
            activeCustomers={activeCustomers}
            hasLoadedList={hasLoadedList}
            setActiveCustomers={setActiveCustomers}
            refreshSelectedActiveCustomers={refreshSelectedActiveCustomers}
            removeFromOtherContacts={removeFromOtherContacts}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          className={Styles.navyBlueColorForce}
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            setOpenShareDocumentDialog(false);
            setSelectedContacts([]);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!selectedData.length}
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            shareDocuments((response) => {
              if (response && response.data.status === 200) {
                setOpenShareDocumentDialog(false);
                setSelectedContacts([]);
              }
            });
          }}
        >
          Share
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShareDocumentDialog;
