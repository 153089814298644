import { Container, Typography, Paper, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      padding: 10,
    },
  },
  paper: {
    padding: 100,
    marginTop: 20,
    maxWidth: "50%",
    margin: "auto",
  },
  marginTop: {},
}));
function SecurityPolicy() {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant="h6">Security Policy Coming soon...</Typography>
      </Paper>
    </Container>
  );
}

export default SecurityPolicy;
