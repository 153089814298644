import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TextField,
  Typography,
  Paper,
  TableRow,
  Snackbar,
} from "@material-ui/core";
import Styles from "../../../../Styles.module.css";
import clsx from "clsx";
import { useSendSerivceRequestDialog } from "souqh-react-redux-hooks/homeBuyer/useSendSerivceRequestDialog";
import CancelIcon from "@material-ui/icons/Cancel";
import { useHistory, useLocation } from "react-router-dom";
import useLocalStorage from "react-use-localstorage";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  font14: {
    fontSize: 14,
  },
  font14Bold: {
    fontSize: 14,
    fontWeight: 700,
  },
  marginTop20: {
    marginTop: 20,
  },
  marginTop10: {
    marginTop: 10,
  },
}));

export default function SendSerivceRequestDialog({
  openserviceRequestDialog,
  setOpenserviceRequestDialog,
  serviceProviderId,
  businessName,
  email,
  serviceName,
  srcPage,
  sfServiceId,
  setUserEngagementData,
  storeFrontId,
  storeFrontName,
  sfServices,
  isExplorePageCard,
  pageRoute,
}) {
  const classes = useStyles();
  const {
    serviceRequestDocuments,
    submitSerivceRequest,
    setDescription,
    clearData,
    userInfo,
    description,
    actions: { addFiles, deleteFile },
  } = useSendSerivceRequestDialog(
    serviceProviderId,
    openserviceRequestDialog.service,
    email,
    srcPage,
    sfServiceId,
    isExplorePageCard
  );
  const history = useHistory();
  const location = useLocation();
  const [nonloggedInUser, setNonLoggedInUser] =
    useLocalStorage("nonloggedInUser");
  const [localUserInfo, setUserInfo] = useLocalStorage("userInfo");
  const [errorMsg, setErrorMsg] = useState("");
  const [serviceSelected, setServiceSelected] = useState(null);

  const requestedServices = userInfo && userInfo.requestedServices;
  return (
    <Dialog fullWidth open={openserviceRequestDialog.open} maxWidth={"sm"}>
      <DialogTitle>
        <Grid item container xs={12}>
          <Grid item xs={11}>
            <Typography
              variant="subtitle1"
              className={Styles.navyBlueColorForce}
            >
              Send Service Request
            </Typography>
          </Grid>
          <Grid item xs={1} container style={{ justifyContent: "flex-end" }}>
            <Button
              style={{ padding: 0, minWidth: 0 }}
              onClick={() => {
                setUserEngagementData &&
                  setUserEngagementData(
                    storeFrontId,
                    "Service_Request_Cancelled"
                  );
                setOpenserviceRequestDialog({ open: false, service: null });
                if (nonloggedInUser) {
                  localStorage.removeItem("nonloggedInUser");
                  // history.push("/homebuyer/explore");
                }
                setServiceSelected(null);
                clearData();
              }}
            >
              <CloseIcon className={Styles.navyBlueColorForce} />
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent
        className={Styles.navyBlueColorForce}
        style={{ minHeight: userInfo ? 350 : 70 }}
      >
        {userInfo ? (
          <>
            <Grid container className={classes.marginTop10}>
              <Grid item>
                <Typography className={classes.font14}>
                  Service Provider :
                </Typography>
              </Grid>
              <Grid item style={{ marginLeft: 5 }}>
                <Typography className={classes.font14Bold}>
                  {businessName}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.marginTop10}>
              <Grid
                item
                xs={3}
                style={{ alignSelf: "center", minWidth: "27%" }}
              >
                <Typography className={classes.font14}>
                  Service Requested :
                </Typography>
              </Grid>
              <Grid item xs={9} style={{ maxWidth: "73%" }}>
                {!isExplorePageCard ? (
                  <Typography className={classes.font14Bold}>
                    {srcPage === "SPManagement"
                      ? serviceName
                      : openserviceRequestDialog.service &&
                        openserviceRequestDialog.service.name}
                  </Typography>
                ) : (
                  <Autocomplete
                    // style={{ minWidth: 200 }}
                    value={serviceSelected}
                    noOptionsText={"Please select service"}
                    id="sf-services-id"
                    options={sfServices || []}
                    getOptionLabel={(option) => option.name}
                    // getOptionSelected={(option, value) => {
                    //   return option.name === value.name;
                    // }}
                    getOptionDisabled={(option) =>
                      requestedServices && requestedServices.includes(option.id)
                    }
                    onChange={(e, v) => {
                      setServiceSelected(v);
                    }}
                    renderInput={(params) => (
                      <TextField
                        // size="small"
                        {...params}
                        label="Services"
                        variant="outlined"
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item className={classes.marginTop20}>
              <Typography varinat="body1">Service Details</Typography>
              <TextField
                fullWidth
                className={classes.marginTop10}
                variant="outlined"
                label="Description"
                multiline={true}
                rows={4}
                onChange={(event) => {
                  setDescription(event.target.value);
                }}
                inputProps={{
                  className: Styles.navyBlueColor,
                  maxLength: 500,
                }}
              />
              <Grid
                container
                item
                justifyContent="flex-end"
                style={{ marginTop: 10 }}
              >
                <Typography variant="body2" style={{ color: "#17174C99" }}>
                  {description.length}/500
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.marginTop20}>
              <Grid item xs={4}>
                <Typography>Add Attachments</Typography>
              </Grid>
              <Grid item container xs={8} justifyContent="flex-end">
                <input
                  accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .png, .jpeg, .bmp, .gif, .CSV"
                  style={{ display: "none" }}
                  id="upload-docs"
                  multiple
                  type="file"
                  onChange={(event) => {
                    addFiles(event.target.files);
                  }}
                />
                <label htmlFor="upload-docs" style={{ marginBottom: 16 }}>
                  <Button
                    variant="outlined"
                    color="default"
                    component="span"
                    startIcon={
                      <img src="/images/Icon material-file-upload.png" alt="" />
                    }
                    style={{ textTransform: "capitalize" }}
                    onClick={() => {}}
                    className={Styles.navyBlueColorForce}
                  >
                    Upload
                  </Button>
                </label>
              </Grid>
            </Grid>
            {serviceRequestDocuments.length ? (
              <Grid item>
                <TableContainer component={Paper} style={{ marginBottom: 20 }}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>File Name</TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {serviceRequestDocuments.map((document, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {document.name}
                          </TableCell>
                          <TableCell align="right">
                            <ButtonBase>
                              <CancelIcon
                                onClick={() => {
                                  deleteFile(index);
                                }}
                              />
                            </ButtonBase>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            ) : null}
          </>
        ) : (
          <>
            <Grid container className={classes.marginTop10}>
              <Grid item>
                <Typography className={classes.font14}>
                  {`Please log in to interact with ${storeFrontName}. Don't have an
                  account? Sign up for your free Souqh account today - should
                  take less than a minute`}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        {userInfo ? null : (
          <Button
            className={Styles.navyBlueColorForce}
            style={{ textTransform: "capitalize", cursor: "pointer" }}
            onClick={() => {
              if (pageRoute && pageRoute.includes("prepare-for-canada")) {
                history.push("/prepare-for-canada/signup");
              } else {
                if (localStorage.getItem("nonloggedInUser")) {
                  localStorage.removeItem("nonloggedInUser");
                  history.push("/homebuyersignup");
                }
              }
            }}
          >
            Sign up for free
          </Button>
        )}
        {/* <Button
          className={Styles.navyBlueColorForce}
          style={{ textTransform: "capitalize", cursor: "pointer" }}
          onClick={() => {
            setUserEngagementData &&
              setUserEngagementData(storeFrontId, "Service_Request_Cancelled");
            setOpenserviceRequestDialog({ open: false, service: null });
            clearData();
            if (localStorage.getItem("nonloggedInUser")) {
              localStorage.removeItem("nonloggedInUser");
              history.push("/homebuyer/explore");
            }
          }}
        >
          Cancel
        </Button> */}

        {userInfo ? (
          <Button
            disabled={isExplorePageCard && !serviceSelected}
            style={{
              textTransform: "capitalize",
              marginRight: 10,
              cursor: "pointer",
            }}
            onClick={() => {
              submitSerivceRequest(serviceSelected, (res) => {
                if (res && res.data.status === 200) {
                  userInfo.requestedServices &&
                    userInfo.requestedServices.push(
                      res.data.result.sfServiceId
                    );
                  setUserInfo(JSON.stringify(userInfo));
                  setOpenserviceRequestDialog({ open: false, service: null });

                  // In case of non logged in user first time user will goto dashboard
                  if (nonloggedInUser) {
                    localStorage.removeItem("nonloggedInUser");
                    history.push("/homebuyer/explore");
                  }
                  setServiceSelected(null);
                  clearData();
                } else if (res && res.data.status === 400) {
                  setErrorMsg(res.data.message);
                }
              });
              setUserEngagementData &&
                setUserEngagementData(
                  storeFrontId,
                  "Service_Request_Submitted"
                );
            }}
            className={clsx(Styles.navyBlueColorForce, Styles.primaryBtnAccent)}
          >
            Submit
          </Button>
        ) : (
          <Button
            style={{
              textTransform: "capitalize",
              marginRight: 10,
              cursor: "pointer",
            }}
            onClick={() => {
              if (pageRoute && pageRoute.includes("prepare-for-canada")) {
                history.push("/prepare-for-canada/login");
              } else {
                history.push("/userlogin");
                setNonLoggedInUser(
                  JSON.stringify({
                    redirectUrl: location.pathname,
                    openserviceRequestDialog,
                    setOpenserviceRequestDialog,
                    serviceProviderId,
                  })
                );
              }
            }}
            className={clsx(Styles.navyBlueColorForce, Styles.primaryBtnAccent)}
          >
            Login
          </Button>
        )}
      </DialogActions>
      {errorMsg ? (
        <Snackbar
          open={errorMsg}
          autoHideDuration={3000}
          onClose={() => {
            setOpenserviceRequestDialog({
              open: false,
              service: null,
            });
            setErrorMsg("");
          }}
        >
          <Alert variant="filled" severity="error">
            {errorMsg}
          </Alert>
        </Snackbar>
      ) : null}
    </Dialog>
  );
}
