import { useDispatch } from "react-redux";
import { useApiClient } from "../../useApiClient";
import { useState } from "react";

export const useAddSPDialog = () => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();

  const [storeFrontList, setStoreFrontList] = useState([]);
  const [noSearchResults, setNoSearchResults] = useState(false);

  const getSPData = (searchValue) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/home-buyer/sp/search/${searchValue}`)
      .then(function (response) {
        if (
          response &&
          response.data &&
          response.data.result &&
          response.data.result.length
        ) {
          setNoSearchResults(false);
          setStoreFrontList(response.data.result);
        } else {
          setNoSearchResults(true);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    getSPData,
    storeFrontList,
    setStoreFrontList,
    noSearchResults,
    setNoSearchResults,
  };
};
