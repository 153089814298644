import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import Styles from "../../../Styles.module.css";
import { isMobileDevice } from "../../../utils/AppUtils";
import { cloneDeep } from "lodash";
import { useAssignClientDialog } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useAssignClientDialog";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: isMobileDevice() ? "92%" : 574,
    minWidth: isMobileDevice() && "92%",
  },
}));

export default function AssignClientDialog({
  openAssignClientDialog,
  setOpenAssignClientDialog,
  assignClientDetails,
  tagMembers,
  serviceRequestId,
}) {
  const classes = useStyles();
  const [assignData, setAssignData] = useState([]);
  const [assignToValue, setAssignToValue] = useState(null);
  const [teamMembersList, setTeamMembersList] = useState([]);
  const [prevUsers, setPrevUsers] = useState([]);

  const { assignClient } = useAssignClientDialog();

  useEffect(() => {
    if (
      tagMembers &&
      tagMembers.nonPremiumTeamMembersList &&
      tagMembers.premiumTeamMembersList
    ) {
      const membersList = [
        ...tagMembers.nonPremiumTeamMembersList,
        ...tagMembers.premiumTeamMembersList,
      ].filter(
        (member) => !prevUsers.some((user) => user.email === member.email)
      );
      setTeamMembersList(membersList || []);
    }
  }, [tagMembers, prevUsers]);

  useEffect(() => {
    if (assignClientDetails?.assignedUsers) {
      setPrevUsers(assignClientDetails.assignedUsers);
    }
  }, [assignClientDetails?.assignedUsers]);

  const deselectItemFromSelection = (email) => {
    const tempList = cloneDeep(assignData);

    const index = tempList.findIndex((item) => item.email === email);
    tempList.splice(index, 1);
    setAssignData(tempList);
  };

  const deletePrevUser = (email) => {
    const tempList = cloneDeep(prevUsers);

    const index = tempList.findIndex((item) => item.email === email);
    tempList.splice(index, 1);
    setPrevUsers(tempList);
  };

  const handleChange = (event) => {
    setAssignToValue(event.target.value);
  };

  return (
    <Dialog
      fullWidth
      open={openAssignClientDialog}
      onClose={() => {
        setOpenAssignClientDialog(false);
      }}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Assign Client</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container item xs={12} style={{ marginTop: 20 }}>
          {prevUsers.length ? (
            <>
              <Grid item xs={12}>
                <Typography className={Styles.navyBlueColorForce}>
                  This client is already assigned to below users
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 20 }}>
                {prevUsers.map((item, index) => (
                  <Chip
                    key={index}
                    label={item.name || item.fullName}
                    variant="outlined"
                    className={Styles.navyBlueColorForce}
                    onDelete={() => {
                      deletePrevUser(item.email);
                    }}
                    style={{ marginBottom: 8 }}
                  />
                ))}
              </Grid>
              <Divider style={{ width: "100%", margin: "1rem 0" }} />
            </>
          ) : null}
          <Grid item xs={12}>
            <Typography className={Styles.navyBlueColorForce}>
              {`Select a team member to ${
                prevUsers.length ? "reassign this client" : "assign a client"
              }`}
            </Typography>
          </Grid>
          <RadioGroup
            style={{ alignItems: "center" }}
            value={assignToValue}
            onChange={handleChange}
          >
            <FormControlLabel
              value="MYSELF"
              control={
                <Radio
                  color={Styles.navyBlueColor}
                  style={{ marginLeft: -24 }}
                />
              }
              label="Assign it to myself"
              className={Styles.navyBlueColor}
            />
            <FormControlLabel
              value="TEAM_MEMBER"
              control={<Radio color={Styles.navyBlueColor} />}
              label="Select team member"
              className={Styles.navyBlueColor}
            />
          </RadioGroup>
          {assignToValue === "TEAM_MEMBER" && (
            <Grid item xs={12} style={{ paddingBottom: 16, paddingTop: 16 }}>
              <Autocomplete
                multiple
                //   limitTags={2}
                //   filterSelectedOptions
                fullWidth
                noOptionsText={"Select team member"}
                options={teamMembersList || []}
                getOptionLabel={(option) => {
                  return `${option.fullName}`;
                }}
                renderOption={(option) => (
                  <React.Fragment>
                    <Grid item>
                      <Grid item>
                        <Typography variant="body1">
                          {option.fullName}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2">{option.email}</Typography>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
                getOptionSelected={(option, value) => {
                  return option.email === value.email;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    multiline
                    rows={isMobileDevice() && 2}
                    label="Select team member"
                    variant="outlined"
                    autoComplete="new-password"
                  />
                )}
                renderTags={() => null}
                onChange={(event, value) => {
                  setAssignData(value);
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} style={{ marginTop: 20 }}>
            {assignData && assignData.length
              ? assignData.map((item, index) => (
                  <Chip
                    key={index}
                    label={`${item && item.fullName}`}
                    variant="outlined"
                    className={Styles.navyBlueColorForce}
                    onDelete={() => {
                      deselectItemFromSelection(item.email);
                    }}
                    style={{ marginBottom: 8 }}
                  />
                ))
              : null}
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions style={{ padding: "16px 24px" }}>
        <Grid container item xs={12} justifyContent="flex-end">
          <Button
            variant="outlined"
            style={{
              padding: "6px 16px",
              marginRight: 12,
              textTransform: "capitalize",
            }}
            className={Styles.navyBlueColorForce}
            onClick={() => {
              setOpenAssignClientDialog(false);
              setAssignToValue(null);
              setAssignData([]);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={Styles.primaryBtnAccent}
            style={{ textTransform: "capitalize", padding: "6px 32px" }}
            disabled={
              !assignData.length &&
              assignToValue !== "MYSELF" &&
              JSON.stringify(assignClientDetails?.assignedUsers || []) ===
                JSON.stringify(prevUsers)
            }
            onClick={() => {
              assignClient(
                assignClientDetails?.serviceRequestId || serviceRequestId,
                [...prevUsers, ...assignData],
                assignToValue,
                (res) => {
                  if (res && res.data.status === 200) {
                    setOpenAssignClientDialog(false);
                    setAssignToValue(null);
                    setAssignData([]);
                    setPrevUsers([...prevUsers, ...assignData]);
                  }
                }
              );
            }}
          >
            Assign
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
