import {
  makeStyles,
  Grid,
  Typography,
  Paper,
  Tabs,
  Tab,
  Button,
  Divider,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Styles from "../../../Styles.module.css";
import StoreFront from "../../SPSetup/StoreFront";
import ConfirmationDialog from "../../ConfirmationDialog";
import { useHistory } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PreviewStorefrontDialog from "../../SPSetup/StoreFront/PreviewStorefrontDialog";
import { useFetch } from "souqh-react-redux-hooks/serviceProvider/storefront/useFetch";
import { useSelector } from "react-redux";
import { useDocuments } from "souqh-react-redux-hooks/serviceProvider/documents/useDocuments";
import { useApiClient } from "souqh-react-redux-hooks/useApiClient";
import { useSave } from "souqh-react-redux-hooks/serviceProvider/storefront/useSave";
import StorefrontPage from "../../HomeBuyer/Explore/StorefrontPage/StorefrontPage";
import NoAccessDialog from "../../common/NoAccessDialog";
import ShareIcon from "@material-ui/icons/Share";
import MoreTrafficDialog from "../Dashboard/MoreTrafficDialog";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import {
  ADVANCED,
  PREMIUM,
  SERVICE_PROVIDER_USER,
  TEAM_MEMBER_USER,
} from "../serviceProviderConstants";
import { useRoleManagement } from "souqh-react-redux-hooks/common/useRoleManagement";
import StorefrontAnalytics from "./StorefrontAnalytics";
import {
  getMetadataInfo,
  isMarketplacePlan,
  isMobileDevice,
  isOnboardingInComplete,
  isTabletDevice,
} from "../../../utils/AppUtils";
import { useSQQuery } from "souqh-react-redux-hooks/common/useSQQuery";
import PlanUpgradeMsgDialog from "../../common/PlanUpgradeMsgDialog";

const useStyles = makeStyles((theme) => ({
  marginTop20: {
    marginTop: !isMobileDevice() && !isTabletDevice() && 20,
  },
  tabs: {
    minWidth: "16%",
    fontWeight: "bold",
    textTransform: "capitalize",
    opacity: "100%",
    fontSize: 14,
  },
}));

const TAB_NAMES = ["Manage Storefront", "Storefront Analytics"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export default function ManageStorefront() {
  const { apiClient } = useApiClient();
  const { query } = useSQQuery();
  let history = useHistory();
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [openPreviewSFDialog, setOpenPreviewSFDialog] = useState(false);
  const [openNoAccessDialog, setOpenNoAccessDialog] = useState(false);
  const [openMoreTrafficDialog, setopenMoreTrafficDialog] = useState(false);
  const [openPlanUpgradeMsgDialog, setOpenPlanUpgradeMsgDialog] =
    useState(false);

  const [fullSFDetails, setFullSFDetails] = useState(null);

  const { storeFrontId, storeFrontRouteName } = useSelector(
    (state) => state.storefront
  );
  const {
    actions: { fetch },
  } = useFetch();
  const {
    actions: { save },
  } = useSave();
  const { updateIntercomStatus } = useDocuments();
  const userInfo = useAuthUser();
  const { isTeamMember } = useRoleManagement();

  useEffect(() => {
    const tabIndex = query.get("tabIndex");
    fetch(apiClient, (res) => {
      setFullSFDetails(res.data.result[0]);
    });
    if (tabIndex) {
      setActiveTab(parseInt(tabIndex));
    } else {
      setActiveTab(0);
    }
  }, []);

  return (
    <Grid container>
      <PlanUpgradeMsgDialog
        openPlanUpgradeMsgDialog={openPlanUpgradeMsgDialog}
        setOpenPlanUpgradeMsgDialog={setOpenPlanUpgradeMsgDialog}
      />
      <MoreTrafficDialog
        open={openMoreTrafficDialog}
        setopenMoreTrafficDialog={setopenMoreTrafficDialog}
        storeFrontId={storeFrontId}
        storeFrontRouteName={storeFrontRouteName}
        data={getMetadataInfo(fullSFDetails)}
      />
      <PreviewStorefrontDialog
        openPreviewSFDialog={openPreviewSFDialog}
        setOpenPreviewSFDialog={setOpenPreviewSFDialog}
        previewStorefrontId={storeFrontId}
        fetch={fetch}
        updateIntercomStatus={updateIntercomStatus}
        sourcePage="manageStoreFront"
      />
      <ConfirmationDialog
        open={openConfirmationDialog}
        handleYes={() => {
          history.push("/serviceprovider/dashboard");
          setOpenConfirmationDialog(false);
        }}
        handleNo={() => {
          setOpenConfirmationDialog(false);
        }}
        sectionTitle={
          "Are you sure you want to discard changes made to your storefront?"
        }
      />
      <NoAccessDialog
        openNoAccessDialog={openNoAccessDialog}
        setOpenNoAccessDialog={setOpenNoAccessDialog}
      />

      <Grid item xs={12} className={classes.marginTop20}>
        <Paper
          elevation={0}
          style={{ marginRight: !isMobileDevice() && !isTabletDevice() && 20 }}
        >
          {(isMobileDevice() || isTabletDevice()) && (
            <Grid container item xs={12}>
              <Grid item xs={9}>
                <Typography
                  variant={isMobileDevice() ? "h6" : "h4"}
                  className={Styles.navyBlueColor}
                  style={{ marginBottom: 20 }}
                >
                  Storefront
                </Typography>
              </Grid>
              {isMobileDevice() && (
                <Grid item xs={2}>
                  <ShareIcon
                    className={Styles.navyBlueColor}
                    // style={{ paddingRight: 6 }}
                    onClick={() => {
                      setopenMoreTrafficDialog(true);
                    }}
                  />
                </Grid>
              )}
              {isMobileDevice() && (
                <Grid item xs={1}>
                  <VisibilityIcon
                    className={Styles.navyBlueColor}
                    onClick={() => {
                      save((res) => {
                        if (res && res.data.status === 200) {
                          setOpenPreviewSFDialog(true);
                        }
                      }, apiClient);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          )}
          <Grid container item xs={12}>
            <Grid item xs={12} md={6} lg={7}>
              <Tabs
                value={activeTab}
                // onChange={(event, newValue) => {
                //   setDocumentTab(newValue);
                // }}
                aria-label="storefront-management-tabs"
                classes={{ indicator: Styles.indicator }}
              >
                {TAB_NAMES.map((tab, index) => (
                  <Tab
                    key={index}
                    label={`${tab}`}
                    className={clsx(classes.tabs, {
                      [Styles.primaryTxtAccentForce]: index === activeTab,
                      [Styles.navyBlueColorForce]: index !== activeTab,
                    })}
                    onClick={() => {
                      if (index === 1 && isMarketplacePlan(userInfo)) {
                        setOpenPlanUpgradeMsgDialog(true);
                      } else {
                        setActiveTab(index);
                      }
                    }}
                    // disabled={
                    //   index === 1 &&
                    //   (isOnboardingInComplete(userInfo) ||
                    //     isMarketplacePlan(userInfo))
                    // }
                    disabled={index === 1 && isOnboardingInComplete(userInfo)}
                  />
                ))}
              </Tabs>
            </Grid>
            {!isMobileDevice() && (
              <React.Fragment>
                <Grid
                  container
                  item
                  xs={6}
                  md={
                    userInfo.userType === SERVICE_PROVIDER_USER ||
                    (userInfo.userType === TEAM_MEMBER_USER &&
                      (userInfo.subscriptionPlanName === PREMIUM || userInfo.subscriptionPlanName === ADVANCED))
                      ? 3
                      : 5
                  }
                  alignItems="center"
                  justifyContent="flex-end"
                  style={{ paddingRight: 16, cursor: "pointer" }}
                  onClick={() => {
                    if (!isOnboardingInComplete(userInfo)) {
                      // if (isMarketplacePlan(userInfo)) {
                      //   setOpenPlanUpgradeMsgDialog(true);
                      // } else {
                      setopenMoreTrafficDialog(true);
                      // }
                    }
                  }}
                >
                  <ShareIcon
                    className={
                      isOnboardingInComplete(userInfo)
                        ? Styles.grayText
                        : Styles.navyBlueColor
                    }
                    style={{ paddingRight: 6 }}
                  />
                  <Typography
                    className={
                      isOnboardingInComplete(userInfo)
                        ? Styles.grayText
                        : Styles.navyBlueColor
                    }
                  >
                    Share Storefront
                  </Typography>
                </Grid>
                {userInfo.userType === SERVICE_PROVIDER_USER ||
                (userInfo.userType === TEAM_MEMBER_USER &&
                    (userInfo.subscriptionPlanName === PREMIUM || userInfo.subscriptionPlanName === ADVANCED)) ? (
                  <Grid
                    container
                    item
                    xs={6}
                    md={3}
                    lg={2}
                    alignItems="center"
                    justifyContent="flex-end"
                    style={{ paddingRight: 16, cursor: "pointer" }}
                    onClick={() => {
                      if (!isOnboardingInComplete(userInfo)) {
                        save((res) => {
                          if (res && res.data.status === 200) {
                            setOpenPreviewSFDialog(true);
                          }
                        }, apiClient);
                      }
                    }}
                  >
                    <VisibilityIcon
                      className={
                        isOnboardingInComplete(userInfo)
                          ? Styles.grayText
                          : Styles.navyBlueColor
                      }
                      style={{ paddingRight: 6 }}
                    />
                    <Typography
                      className={
                        isOnboardingInComplete(userInfo)
                          ? Styles.grayText
                          : Styles.navyBlueColor
                      }
                    >
                      Visit Storefront
                    </Typography>
                  </Grid>
                ) : null}
              </React.Fragment>
            )}
          </Grid>
          {(isMobileDevice() || isTabletDevice()) && <Divider />}
        </Paper>
        <TabPanel value={activeTab} index={0}>
          {userInfo.userType === TEAM_MEMBER_USER &&
            userInfo.subscriptionPlanName !== PREMIUM && userInfo.subscriptionPlanName !== ADVANCED ? (
            <Grid
              container
              item
              justifyContent="flex-end"
              style={{ marginTop: 10 }}
            >
              <Button
                onClick={() => {
                  setOpenNoAccessDialog(true);
                }}
                variant="outlined"
              >
                Edit storefront
              </Button>
            </Grid>
          ) : null}
          {!isTeamMember() && !isMarketplacePlan(userInfo) ? (
            <StoreFront
              sourcePage="manageStoreFront"
              setOpenConfirmationDialog={setOpenConfirmationDialog}
            />
          ) : (
            <StorefrontPage sourcePage="manageStoreFront" />
          )}
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <StorefrontAnalytics />
        </TabPanel>
      </Grid>
    </Grid>
  );
}
