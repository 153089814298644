import { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useApiClient } from "../../useApiClient";
import {
  SET_ALL_MARKETING_CAMPAIGN_DATA,
  SET_CAMPAIGN_STEP1_DATA,
  CLEAR_CAMPAIGN_DATA,
  SET_DROPDOWN_VALUES,
  RESET_CAMPAIGN_START_DATE,
  SET_UPDATE_MARKETING_CAMPAIGN_DATA,
  SET_CAMPAIGN_INVOCIE_DATA,
  ADD_LOCATION_SERVED,
} from "../../../souqh-redux/reducers/actionConstants";
import { useActions } from "../../useActions";
import { useLocationServed } from "../../useLocationServed";
import { forEach, map } from "lodash";
import { color_success, color_error } from "../../toasterColors";
import { useValidations } from "../../useValidations";
import { useAuthUser } from "../../useLogin";

const marketingCampainActions = {
  setStep1Data: (payload) => ({
    type: SET_CAMPAIGN_STEP1_DATA,
    payload,
  }),
  clearData: (payload) => ({
    type: CLEAR_CAMPAIGN_DATA,
    payload,
  }),
  resetCampaignStartDate: (payload) => ({
    type: RESET_CAMPAIGN_START_DATE,
    payload,
  }),
};
const validationMeta = [
  {
    name: "name",
    patterns: [
      {
        match: (val) => /^[A-Za-z0-9 ,$&:|*^"';#@./!+-_]*$/i.test(val),
        msg: "Entered campaign name has invalid characters. Please enter a valid campaign name.",
      },
    ],
  },
];
export const useMarketingCampaign = () => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();
  const actions = useActions(marketingCampainActions);
  const [enableStep1, setEnableStep1] = useState(true);
  const [enableStep2, setEnableStep2] = useState(true);
  const [displayMarketingData, setDisplayMarketingData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [detectForcastedResult, setDetectForcastedResult] = useState(0);
  const { locationsServed, actions: locationServedActions } =
    useLocationServed();
  const { dropDownValues } = useSelector((state) => state.marketingCampaign);
  const userInfo = useAuthUser();

  const [totalPages, setTotalPages] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [nameAlreadyExist, setNameAlreadyExist] = useState(false);

  const { allMarketingCampaignData, campaignData } = useSelector(
    (state) => state.marketingCampaign
  );

  const { timeList, name } = useSelector(
    (state) => state.marketingCampaign.campaignData
  );
  const { promoCode, havePromoCode } = useSelector(
    (state) => state.subscriptionPlan
  );

  const verifyPromoCodeData = useSelector(
    (state) => state.metaData.verifyPromoCode
  );

  const getFormValues = useCallback(() => {
    return { name };
  }, [name]);

  const getServerKeysMap = useCallback(() => {
    return {};
  }, []);

  const { validations, addValidations, clearAllValidations } = useValidations(
    validationMeta,
    getFormValues,
    getServerKeysMap
  );

  const getAllMarketingCampaignData = (page = 0) => {
    setSelectedPage(page + 1);
    let url = `marketing-campaign/all?page=${page}&size=20&serviceProviderId=${
      userInfo.serviceProviderId || userInfo.parentServiceProviderId
    }`;
    if (searchText)
      url = `marketing-campaign/search/${searchText}?page=${page}&size=20&spId=${
        userInfo.serviceProviderId || userInfo.parentServiceProviderId
      }`;
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(url)
      .then(function (response) {
        if (response.data.response) {
          dispatch({
            type: SET_ALL_MARKETING_CAMPAIGN_DATA,
            payload: response.data.response,
          });
        }
        setTotalPages(response.data.totalPages || 0);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const duplicateMarketingCampaign = (id, onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post(`marketing-campaign/${id}`)
      .then(function (response) {
        if (response && response.status === 200) {
          getAllMarketingCampaignData(selectedPage - 1);
          onResult && onResult(response);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const deleteMarketingCampaign = (id, onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .delete(`/marketing-campaign/${id}`)
      .then(function (response) {
        if (response && response.status === 200) {
          getAllMarketingCampaignData();
          setSelectedPage(1);
          onResult && onResult(response);
          dispatch({
            type: "SET_TOASTER",
            payload: {
              message: `Campaign deleted successfully`,
              bgColor: color_success,
              open: true,
            },
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getDropdownValues = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`marketing-campaign/dropdown/values/`)
      .then(function (response) {
        if (response.data.result) {
          dispatch({
            type: SET_DROPDOWN_VALUES,
            payload: response.data.result,
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const createMarketingCampaign = (onResult) => {
    var creatCapaignData = formCreateUpdateObject();
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post(`marketing-campaign/`, creatCapaignData)
      .then(function (response) {
        if (response && response.status === 200) {
          getAllMarketingCampaignData();
          setSelectedPage(1);
          dispatch({
            type: "SET_TOASTER",
            payload: {
              message: `Campaign created successfully`,
              bgColor: color_success,
              open: true,
            },
          });
          onResult && onResult(response);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const formCreateUpdateObject = () => {
    if (campaignData) {
      campaignData.marketingRegion = locationsServed
        ? map(locationsServed, (location) => ({
            cityIds: location.cities.map((ele) => ele.id),
            provinceId: location.province.id,
          }))
        : [];

      return {
        addOnHomePage: campaignData.addOnHomePage,
        ageRangeIds: map(campaignData.ageRange, "id"),
        campaignStartDate: moment.utc(campaignData.campaignStartDate).format(),
        campaignPlanId: campaignData.campaignPlanId.id,
        campaignUserType:
          (campaignData.campaignUserType &&
            campaignData.campaignUserType.map((ele) => ele.id)) ||
          null,
        marketingRegion: campaignData.marketingRegion || [],
        employmentStatusIds:
          campaignData.employmentStatus &&
          campaignData.employmentStatus.map((e) => e.id),
        houseHoldIncomeRangeIds:
          campaignData.houseHoldIncomeRange &&
          campaignData.houseHoldIncomeRange.map((e) => e.id),
        industryIds:
          campaignData.industry && campaignData.industry.map((e) => e.id),
        journeyStatusIds:
          campaignData.campaignStage &&
          campaignData.campaignStage.map((e) => e.id),
        maritalStatusId:
          campaignData.maritalStatus &&
          campaignData.maritalStatus.map((e) => e.id),
        name: campaignData.name,
        propertyTypeIds:
          campaignData.propertyType &&
          campaignData.propertyType.map((e) => e.id),
        id: campaignData.id,
        couponCode: promoCode,
      };
    }
  };
  const updateMarketingCampaign = (onResult) => {
    var creatCapaignData = formCreateUpdateObject();
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .put(`marketing-campaign/`, creatCapaignData)
      .then(function (response) {
        if (response && response.status === 200) {
          dispatch({
            type: "SET_TOASTER",
            payload: {
              message: `Campaign updated successfully`,
              bgColor: color_success,
              open: true,
            },
          });
          getAllMarketingCampaignData(selectedPage - 1);
          onResult && onResult(response);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  useEffect(() => {
    getDropdownValues();
  }, []);

  useEffect(() => {
    if (locationsServed.length || detectForcastedResult) {
      getForcastedResult();
    }
  }, [detectForcastedResult, JSON.stringify(locationsServed)]);

  useEffect(() => {
    setEnableStep1(
      !!campaignData.name && locationsServed.length && !nameAlreadyExist
        ? false
        : true
    );
    setEnableStep2(
      campaignData.campaignStartDate &&
        (!havePromoCode || (havePromoCode && verifyPromoCodeData?.result))
        ? false
        : true
    );
  }, [
    campaignData,
    locationsServed,
    nameAlreadyExist,
    havePromoCode,
    verifyPromoCodeData,
  ]);

  useEffect(() => {
    if (allMarketingCampaignData) {
      setDisplayMarketingData([...allMarketingCampaignData]);
    }
  }, [allMarketingCampaignData]);

  const getForcastedResult = () => {
    let cityIds = [];
    const provinces = [];
    locationsServed.length &&
      forEach(locationsServed, (location) => {
        cityIds = cityIds.concat(
          location.cities.map((ele) => {
            if (ele.name === "All Cities") provinces.push(location.province.id);
            return ele.name;
          })
        );
      });
    const data = {
      ageRange:
        campaignData &&
        campaignData.ageRange &&
        campaignData.ageRange.map((ele) => ele.value) + "",
      city: cityIds + "" || null,
      clientHomeType:
        (campaignData &&
          campaignData.campaignUserType &&
          campaignData.campaignUserType.map((ele) => ele.value) + "") ||
        null,
      employmentType:
        (campaignData.employmentStatus &&
          campaignData.employmentStatus.map((e) => e.value) + "") ||
        null,
      houseHoldIncomeRange:
        campaignData.houseHoldIncomeRange &&
        campaignData.houseHoldIncomeRange.map((e) => e.id) + "",
      industry:
        (campaignData.industry &&
          campaignData.industry.map((e) => e.id) + "") ||
        null,
      // journeyStatuses:
      //   campaignData.journeyStatusList &&
      //   campaignData.journeyStatusList.map((e) => e.value) + "",
      maritalStatus:
        campaignData.maritalStatus &&
        campaignData.maritalStatus.map((e) => e.value) + "",
      journeyStatuses:
        (campaignData.campaignStage &&
          campaignData.campaignStage.map((e) => e.name) + "") ||
        null,
      propertyValue:
        (campaignData.propertyType &&
          campaignData.propertyType.map((e) => e.id) + "") ||
        null,
      provinceId: provinces.toString(),
    };
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/marketing-campaign/forecast`, {
        params: { ...data },
      })
      .then(function (response) {
        if (response && response.status === 200) {
          actions.setStep1Data({
            field: "forcastedResult",
            value: response.data.result,
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getMarketingCampaignData = (id) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/marketing-campaign/${id}`)
      .then(function (response) {
        if (response.status === 200) {
          const data = mapDataToReducer(response.data.result);
          dispatch({
            type: SET_UPDATE_MARKETING_CAMPAIGN_DATA,
            payload: data,
          });
          data.marketingRegion.forEach((region) => {
            dropDownValues.provinceDTOList.forEach((province) => {
              if (province.id === region.provinceId) {
                dispatch({
                  type: ADD_LOCATION_SERVED,
                  payload: {
                    province,
                    cities: province.cityDTO.filter((city) =>
                      region.cityIds.includes(city.id)
                    ),
                  },
                });
              }
            });
          });
          getMarketingCampaignInvoiceData(
            response.data.result.couponCode,
            response.data.result.campaignPlanId,
            response.data.result.addOnHomePage
          );
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };
  const mapDataToReducer = (data) => {
    const {
      name,
      ageRangeIds,
      industryIds,
      campaignUserType,
      employmentStatusIds,
      houseHoldIncomeRangeIds,
      journeyStatusIds,
      maritalStatusId,
      propertyTypeIds,
    } = data;
    return {
      name,
      ageRange: dropDownValues.ageRangeList.filter((item) =>
        ageRangeIds.includes(item.id)
      ),
      industry: dropDownValues.industryList.filter((item) =>
        industryIds.includes(item.id)
      ),
      campaignUserType:
        (campaignUserType &&
          dropDownValues.userTypes.filter((item) =>
            campaignUserType.includes(item.id)
          )) ||
        null,
      employmentStatus:
        employmentStatusIds &&
        dropDownValues.employmentStatusList.filter((item) =>
          employmentStatusIds.includes(item.id)
        ),
      houseHoldIncomeRange:
        houseHoldIncomeRangeIds &&
        dropDownValues.householdIncomeRangeList.filter((item) =>
          houseHoldIncomeRangeIds.includes(item.id)
        ),
      campaignStage:
        journeyStatusIds &&
        dropDownValues.journeyStatusList
          .filter((item) => journeyStatusIds.includes(item.id))
          .map((ele) => ({ ...ele, selected: true })),
      maritalStatus:
        maritalStatusId &&
        dropDownValues.maritalStatusList.filter((item) =>
          maritalStatusId.includes(item.id)
        ),
      propertyType:
        propertyTypeIds &&
        dropDownValues.propertyTypeList.filter((item) =>
          propertyTypeIds.includes(item.id)
        ),
      campaignPlanId: data.campaignPlanId,
      campaignStartDate: data.campaignStartDate || null,
      addOnHomePage: data.addOnHomePage,
      campaignPlanId: data.campaignPlanId,
      marketingRegion: data.marketingRegion,
      journeyStatusIds,
      id: data.id,
    };
  };

  const getMarketingCampaignInvoiceData = (
    couponCode,
    campaignPlanId,
    addOnHomePage
  ) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/marketing-campaign/invoice`, {
        params: {
          promocode: couponCode || promoCode || null,
          planId: campaignPlanId || campaignData.campaignPlanId.id,
          addOnFlag: addOnHomePage || campaignData.addOnHomePage,
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: SET_CAMPAIGN_INVOCIE_DATA,
            payload: response.data.result.totalAmount,
          });
        } else {
          dispatch({
            type: "SET_TOASTER",
            payload: {
              message:
                "Your Stripe Connected account is not configured. Please navigate to eCommerce page under Settings to setup your account or contact Souqh support for more details",
              bgColor: color_error,
              open: true,
            },
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const checkMarketingCampaignNameExists = (campaignName) => {
    return apiClient
      .get(`/marketing-campaign/nameExists?name=${campaignName}`)
      .then(function (response) {
        if (response.status === 200) {
          setNameAlreadyExist(response.data.result);
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  return {
    getAllMarketingCampaignData,
    allMarketingCampaignData,
    duplicateMarketingCampaign,
    deleteMarketingCampaign,
    actions,
    campaignData,
    enableStep1,
    enableStep2,
    createMarketingCampaign,
    getDropdownValues,
    displayMarketingData,
    searchText,
    locationServedActions,
    timeList,
    locationsServed,
    getForcastedResult,
    setDetectForcastedResult,
    dropDownValues,
    validations,
    getMarketingCampaignData,
    setEnableStep2,
    updateMarketingCampaign,
    getMarketingCampaignInvoiceData,
    totalPages,
    selectedPage,
    checkMarketingCampaignNameExists,
    nameAlreadyExist,
    setNameAlreadyExist,
    setSearchText,
  };
};
