import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Divider,
  makeStyles,
  Card,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";
import LogoutMsgDialog from "./LogoutMsgDialog";
import { useLogout } from "souqh-react-redux-hooks/useLogin";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 637,
    width: 800,
  },
}));

function ChangeSubscriptionDialog({
  openChangeSubscriptionDialog,
  setOpenChangeSubscriptionDialog,
  defaultSelectedCard,
  title,
  planDuration,
  amount,
  savePlanData,
  selectedUserPlan,
  selectedPrevUserPlan,
  setSelectedUserPlan,
  setDefaultSelectedCard,
  plansList,
  changeSubscription,
  selectedPrevPlanDuration,
  setPlanDuration,
}) {
  const classes = useStyles();
  const { logout } = useLogout();
  let history = useHistory();

  const planUpgrade = selectedPrevUserPlan < selectedUserPlan ? true : false;

  const planDurationUpgrade =
    selectedPrevPlanDuration === "MONTHLY" && planDuration === "YEARLY"
      ? true
      : false;

  const prevDetails = [
    {
      key: "Current billing plan",
      value:
        selectedPrevUserPlan &&
        plansList.find((el) => el.id === selectedPrevUserPlan)?.planName,
    },
    { key: "Current billing cycle", value: selectedPrevPlanDuration },
    // { key: "Current billing date", value: "" },
    // { key: "Current charges", value: "" },
  ];

  const newDetails = [
    {
      key: "New billing plan",
      value:
        selectedUserPlan &&
        plansList.find((el) => el.id === selectedUserPlan)?.planName,
    },
    { key: "New billing cycle", value: planDuration },
    // { key: "Next billing date", value: "" },
    // { key: "New charges", value: "" },
  ];

  // console.log(
  //   "Plan prev IDS:",
  //   selectedPrevUserPlan,
  //   "Plan cuurent IDS:",
  //   selectedUserPlan,
  //   plansList
  // ); // FIX mE: remove after complete the functionality

  const [openLogoutMsgDialog, setOpenLogoutMsgDialog] = useState(false);

  return (
    <React.Fragment>
      <LogoutMsgDialog
        openLogoutMsgDialog={openLogoutMsgDialog}
        setOpenLogoutMsgDialog={setOpenLogoutMsgDialog}
      />
      <Dialog
        fullWidth
        open={openChangeSubscriptionDialog}
        disableBackdropClick
        onClose={() => {
          setOpenChangeSubscriptionDialog(false);
        }}
        maxWidth={"md"}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DialogTitle className={Styles.navyBlueColor}>
          <Typography variant="subtitle1">Change Subscription</Typography>
        </DialogTitle>
        <Divider style={{ marginBottom: 24 }} />
        <DialogContent>
          <Grid container spacing={3} className={Styles.navyBlueColor}>
            <Grid item xs={4} style={{ marginBottom: 8 }}>
              <Card
                style={{
                  color: "#17174C",
                  border: "2px solid lightgray",
                  width: "100%",
                  height: "100%",
                }}
                elevation={0}
              >
                <Grid
                  style={{
                    height: "100%",
                  }}
                >
                  <Grid container item sm={12} style={{ padding: 20 }}>
                    <Grid container item sm={12} justifyContent="center">
                      <img
                        style={{ height: 158, width: 158 }}
                        src={`/images/${title}.svg`}
                      ></img>
                    </Grid>
                    <Grid container item sm={12} justifyContent="center">
                      <Typography className={Styles.navyBlueColor} variant="h5">
                        {selectedUserPlan &&
                          plansList.find((el) => el.id === selectedUserPlan)
                            ?.planName}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      item
                      sm={12}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid
                        item
                        container
                        xs={12}
                        style={{
                          alignItems: "center",
                          position: "relative",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 20,
                            fontWeight: "600",
                            alignItems: "flex-start",
                            justifyContent: "flex-end",
                            display: "flex",
                            paddingBottom: 40,
                            height: "100%",
                          }}
                        >
                          $
                        </div>
                        <div
                          style={{
                            fontSize: 72,
                            lineHeight: "95px",
                            fontWeight: "600",
                            display: "flex",
                          }}
                        >
                          {selectedUserPlan && planDuration === "MONTHLY"
                            ? plansList
                                .find((el) => el.id === selectedUserPlan)
                                ?.monthlyPrice.toString()
                                .split(".")[0]
                            : plansList
                                .find((el) => el.id === selectedUserPlan)
                                ?.annualPrice.toString()
                                .split(".")[0]}
                        </div>
                      </Grid>
                      <Grid container item xs={12} justifyContent="center">
                        <Typography style={{ display: "inline", fontSize: 14 }}>
                          {planDuration === "MONTHLY"
                            ? "/mo/user"
                            : "/year/user"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid container item xs={8} style={{ marginBottom: 8 }}>
              <Grid item xs={12}>
                {!planUpgrade && !planDurationUpgrade ? (
                  <Typography variant="h3" className={Styles.navyBlueColor}>
                    Are you sure?
                  </Typography>
                ) : (
                  <Typography variant="h3" className={Styles.navyBlueColor}>
                    It’s time to upgrade!
                  </Typography>
                )}
              </Grid>
              {/* {plansList.length && selectedPrevUserPlan ? (
              <Grid item xs={12}>
                {selectedPrevUserPlan > selectedUserPlan ? (
                  <Typography className={Styles.navyBlueColor}>
                    We know you loved our{" "}
                    {
                      plansList.find((el) => el.id === selectedPrevUserPlan)
                        .planName
                    }{" "}
                    plan, but{" "}
                    {
                      plansList.find((el) => el.id === selectedUserPlan)
                        .planName
                    }{" "}
                    Plan will lose out on these essential features
                  </Typography>
                ) : (
                  <Typography className={Styles.navyBlueColor}>
                    We know you loved our{" "}
                    {
                      plansList.find((el) => el.id === selectedPrevUserPlan)
                        .planName
                    }{" "}
                    plan but{" "}
                    {
                      plansList.find((el) => el.id === selectedUserPlan)
                        .planName
                    }{" "}
                    Plan is about to change your mind!
                  </Typography>
                )}
              </Grid>
            ) : null} */}
              <Grid item xs={12} style={{ marginBottom: 16 }}>
                <Typography className={Styles.navyBlueColor}>
                  You have opted to change your current billing cycle.
                </Typography>
                <Typography className={Styles.navyBlueColor}>
                  This will impact your billing cycle
                </Typography>
              </Grid>
              <Grid container item xs={12} style={{ marginBottom: 16 }}>
                {prevDetails.map((item) => (
                  <Grid item xs={12}>
                    <Typography className={Styles.navyBlueColor}>
                      {item.key}: {item.value}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Grid container item xs={12} style={{ marginBottom: 16 }}>
                {newDetails.map((item) => (
                  <Grid item xs={12}>
                    <Typography className={Styles.navyBlueColor}>
                      {item.key}: {item.value}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              {!planUpgrade && !planDurationUpgrade ? (
                <Grid item xs={12}>
                  <Typography className={Styles.navyBlueColor}>
                    You have opted for plan downgrade. This will deactivate some
                    of the existing features in the Souqh application as per
                    your plan selection. Are you sure you want to continue?
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="outlined"
            className={Styles.navyBlueColorForce}
            style={{ textTransform: "capitalize", marginRight: 8 }}
            onClick={() => {
              setSelectedUserPlan(selectedPrevUserPlan);
              setPlanDuration(selectedPrevPlanDuration);
              setOpenChangeSubscriptionDialog(false);
              setDefaultSelectedCard(
                plansList.find((el) => el.id === selectedPrevUserPlan)?.planName
              );
            }}
          >
            Cancel
          </Button>
          <Button
            className={Styles.primaryBtnAccent}
            style={{
              textTransform: "capitalize",
              marginRight: 16,
              padding: "6px 24px",
            }}
            onClick={() => {
              changeSubscription((res) => {
                if (res && res.status === 200) {
                  setOpenChangeSubscriptionDialog(false);
                  setOpenLogoutMsgDialog(true);
                  setTimeout(() => {
                    logout((res) => {
                      if (res.data.status === 200) {
                        history.push("/login");
                      }
                    });
                  }, 3000);
                }
              });
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default ChangeSubscriptionDialog;
