import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_AREA_SPEC } from "../souqh-redux/reducers/actionConstants";
import { useActions } from "./useActions";
import { cloneDeep, capitalize } from "lodash";
import { useApiClient } from "./useApiClient";

const actionCreator = {
  setSelectedAreaOfSpec: (payload) => ({ type: SET_AREA_SPEC, payload }),
};

export const useAreaOfSpec = () => {
  const { apiClient } = useApiClient();

  const dispatch = useDispatch();
  const areaOfSpec = useSelector((state) => state.metaData.areaOfSpec);
  const selectedAreaOfSpec = useSelector(
    (state) => state.spSetup.areaOfSpecializations
  );
  const [customAreaOfSpec, setCustomAreaOfSpec] = useState("");
  const [validation, setValidation] = useState(null);
  const [maxLimitValidation, setMaxLimitValidation] = useState(false);
  const actions = useActions(actionCreator);

  let duplicate;

  const getRemoteAreaOfSpec = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get("/specialization/all")
      .then(function (response) {
        dispatch({
          type: "SET_AREA_OF_SPEC",
          areaOfSpec: response.data.result,
        });
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  useEffect(() => {
    if (selectedAreaOfSpec.length > 20) {
      setMaxLimitValidation(true);
    } else {
      if (selectedAreaOfSpec.length) {
        setMaxLimitValidation(false);
      }
    }
  }, [selectedAreaOfSpec]);

  const deleteItem = (itemToDelete) => () => {
    actions.setSelectedAreaOfSpec(
      selectedAreaOfSpec.filter((item) => item.name !== itemToDelete.name)
    );
  };

  const addItem = (event, value) => {
    if (event.charCode === 13 && !validation) {
      if (value) {
        let data = cloneDeep(selectedAreaOfSpec);
        data.push({ id: null, name: capitalize(value) });
        actions.setSelectedAreaOfSpec(data);
        setCustomAreaOfSpec("");
      }
    }
  };

  const checkDuplicate = (event) => {
    duplicate =
      (areaOfSpec &&
        areaOfSpec.find(
          (e) =>
            e.name.toUpperCase() === event.target.value.toUpperCase().trim()
        )) ||
      (selectedAreaOfSpec &&
        selectedAreaOfSpec.find(
          (e) =>
            e.name.toUpperCase() === event.target.value.toUpperCase().trim()
        ));
    if (duplicate && event.target.value) {
      setValidation(true);
    } else {
      setValidation(null);
    }
  };

  return {
    areaOfSpec,
    selectedAreaOfSpec,
    customAreaOfSpec,
    setCustomAreaOfSpec,
    validation,
    setValidation,
    deleteItem,
    addItem,
    actions,
    checkDuplicate,
    maxLimitValidation,
    getRemoteAreaOfSpec,
  };
};
