import { usePreProfileData } from "souqh-react-redux-hooks/serviceProvider/useSetUp";
import { IconButton, Grid } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { isEqual } from "lodash";

export default function Carousel({
  originalArray,
  handleNext,
  handleBack,
  displayArray,
  lastIndex,
  children,
}) {
  const limit = originalArray.length >= 3;
  return (
    <Grid container>
      {limit && (
        <Grid container item xs={1} justifyContent="center">
          <IconButton
            disabled={!lastIndex}
            onClick={() => {
              handleBack();
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Grid>
      )}
      {children}
      {limit && (
        <Grid container item xs={1} justifyContent="center">
          <IconButton
            disabled={isEqual(
              originalArray[originalArray.length - 1],
              displayArray[displayArray.length - 1]
            )}
            onClick={() => {
              handleNext();
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}
