import React, { useState } from "react";
import {
  Avatar,
  Card,
  Grid,
  Typography,
  CardContent,
  Divider,
  ButtonBase,
  Tooltip,
  Button,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import Styles from "../../../Styles.module.css";
import Rating from "@material-ui/lab/Rating";
import { Link, useHistory } from "react-router-dom";
import clsx from "clsx";
import MoreOptionsOnSPInfo from "./MoreOptionsOnSPInfo";
import * as moment from "moment";
import ContactNumber from "./ContactNumber";
import { isMobileDevice } from "../../../utils/AppUtils";

export default function CompletedSPInfoCard({
  data,
  storeFrontContent,
  storefrontDetails,
  getSpManagementData,
}) {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  const showMoreInfoCondition = isMobileDevice() && showMoreInfo;

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const review =
    data.hbServiceReviews &&
    data.hbServiceReviews.length &&
    data.hbServiceReviews.filter(
      (ele) => data.serviceRequestId === ele.serviceRequestId
    )[0];
  return (
    <Card
      elevation={0}
      style={{ border: !isMobileDevice() && "1px solid #0000001F" }}
    >
      <MoreOptionsOnSPInfo
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        serviceRequestId={data.serviceRequestId}
        storefrontId={storefrontDetails.storefrontId}
        displayPicture={storeFrontContent.displayPicture}
        storefrontName={storeFrontContent.storefrontName}
        serviceName={data.serviceName}
        showAddReviewOption={true}
        email={storefrontDetails.email}
        contactNumber={storefrontDetails.contactNumber}
        showRequestServiceOption={true}
        showRemoveAndArchiveOption={true}
        storeFrontRouteName={storefrontDetails.storeFrontRouteName}
        showSendMessageOption={true}
        storefrontDetails={storefrontDetails}
        sfServiceId={data.sfServiceId}
        getSpManagementData={getSpManagementData}
        actionType="ARCHIVE_COMPLETED_REQUEST"
      />
      <CardContent
        style={{ padding: isMobileDevice() ? "16px 0px 0px 0px" : "16px 0px" }}
      >
        {/* <Link
          style={{ textDecoration: "none", cursor: "pointer" }}
          to={`/homebuyer/serviceproviders/details/${data.storefrontDetails.serviceProviderId}`}
        > */}
        <Grid
          container
          style={{
            // padding: !isMobileDevice() && "0px 16px",
            cursor: "pointer",
          }}
          onClick={() => {
            history.push({
              pathname: `/homebuyer/serviceproviders/details/${data.storefrontDetails.serviceProviderId}`,
              state: {
                email: storefrontDetails.email,
              },
            });
          }}
        >
          <Grid container style={{ padding: !isMobileDevice() && "0px 16px" }}>
            <Grid item xs={3}>
              <Avatar
                style={{ height: 76, width: 76, borderRadius: 4 }}
                variant="square"
                src={storeFrontContent.displayPicture}
              >
                {(storefrontDetails.storeFrontName || "").charAt(0)}
              </Avatar>
            </Grid>
            <Grid container item xs={8} style={{ paddingLeft: 4 }}>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Typography
                    className={clsx(Styles.navyBlueColor, Styles.sqEllipsis)}
                    variant="body1"
                    style={{ fontWeight: 500 }}
                  >
                    {storefrontDetails.storeFrontName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={1}>
                  <EmailIcon className={Styles.navyBlueColor} />
                </Grid>
                <Grid item xs={11} style={{ paddingLeft: 10 }}>
                  <Typography
                    variant="body1"
                    className={clsx(Styles.navyBlueColor, Styles.sqEllipsis)}
                  >
                    {storefrontDetails.email}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={1}>
                  <CallIcon className={Styles.navyBlueColor} />
                </Grid>
                <Grid item xs={11} style={{ paddingLeft: 10 }}>
                  <ContactNumber
                    contactNumber={storefrontDetails.contactNumber}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <ButtonBase>
                <MoreHorizIcon
                  className={Styles.navyBlueColor}
                  onClick={handleClick}
                />
              </ButtonBase>
            </Grid>
          </Grid>
        </Grid>
        {/* </Link> */}
        {(!isMobileDevice() || showMoreInfoCondition) && (
          <React.Fragment>
            <Divider style={{ margin: "16px 0" }} />
            <Grid container style={{ padding: "0px 16px" }}>
              <Grid item xs={6}>
                <Grid item>
                  <Typography
                    className={Styles.navyBlueColor}
                    variant="body1"
                    style={{ fontWeight: 500 }}
                  >
                    Request Date
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    className={clsx(Styles.navyBlueColor, Styles.font14)}
                  >
                    {moment(data.requestedDate).format("MMM DD, YYYY")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid item>
                  <Typography
                    className={Styles.navyBlueColor}
                    variant="body1"
                    style={{ fontWeight: 500 }}
                  >
                    Complete Date
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    className={clsx(Styles.navyBlueColor, Styles.font14)}
                  >
                    {moment(data.completeDate).format("MMM DD, YYYY")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Divider style={{ margin: 16 }} />
            <Grid container style={{ padding: "0px 16px" }}>
              <Grid item>
                <Typography
                  className={Styles.navyBlueColor}
                  variant="body1"
                  style={{ fontWeight: 500 }}
                >
                  Review
                </Typography>
              </Grid>
              {review ? (
                <Grid item container xs={12}>
                  <Grid item xs={3}>
                    <Rating
                      name="rating"
                      value={review.starRating}
                      className={Styles.navyBlueColorForce}
                      style={{ fontSize: 16 }}
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={9} style={{ paddingLeft: 10 }}>
                    <Typography
                      className={clsx(Styles.navyBlueColor, Styles.font14)}
                    >
                      {review.serviceName}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Tooltip title={review.reviewComments}>
                      <Typography
                        className={clsx(
                          Styles.navyBlueColor,
                          Styles.font14,
                          Styles.sqEllipsisTwoLines
                        )}
                        style={{ minHeight: "3em" }}
                      >
                        {review.reviewComments}
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Typography
                    className={clsx(Styles.navyBlueColor, Styles.font14)}
                    style={{ minHeight: "5em" }}
                  >
                    No details available
                  </Typography>
                </Grid>
              )}
            </Grid>
          </React.Fragment>
        )}
        {isMobileDevice() && (
          <Grid container item xs={12} justifyContent="flex-end">
            <Button
              variant="text"
              className={Styles.primaryTxtAccentForce}
              style={{
                textTransform: "capitalize",
                marginTop: showMoreInfo ? -28 : -8,
              }}
              onClick={(event) => {
                event.stopPropagation();
                setShowMoreInfo(!showMoreInfo);
              }}
            >
              {showMoreInfo ? "Show less" : "Show more"}
            </Button>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}
