// service provider profile setup constants
export const SET_PRE_PROFILE_DATA = "SET_PRE_PROFILE_DATA";
export const SET_PROFILE_STATUS = "SET_PROFILE_STATUS";

// office addresses
export const SAVE_ADDRESS_FIELD = "SAVE_ADDRESS_FIELD";
export const SET_PRIMARY = "SET_PRIMARY";
export const ADD_ADDRESS = "ADD_ADDRESSS";
export const DELETE_ADDRESS = "DELETE_ADDRESS";

//current and new address
export const SAVE_CURRENT_AND_NEW_ADDRESS_FIELD="SAVE_CURRENT_AND_NEW_ADDRESS_FIELD";
export const RESET_CURRENT_AND_NEW_ADDRESS_FIELD="RESET_CURRENT_AND_NEW_ADDRESS_FIELD";

// billing address
export const SET_BILLING_ADDRESS_SAME_AS_OA = "SET_BILLING_ADDRESS_SAME_AS_OA";
export const SAVE_BILLING_ADDRESS_FIELD = "SAVE_BILLING_ADDRESS_FIELD";

// team
export const SET_ADDITIONAL_USER_SUPPORT = "SET_ADDITIONAL_USER_SUPPORT";
export const ADD_ADDITIONAL_USER = "ADD_ADDITIONAL_USER";
export const DELETE_ADDITIONAL_USER = "DELETE_ADDITIONAL_USER";
export const BULK_ADD_ADDITIONAL_USERS = "BULK_ADD_ADDITIONAL_USERS";
export const REMOVE_ALL_ADDITIONAL_USERS = "REMOVE_ALL_ADDITIONAL_USERS";
export const SET_STORE_FRONT_TYPE = "SET_STORE_FRONT_TYPE";
export const SET_LICENSED_PRACTITIONER = "SET_LICENSED_PRACTITIONER";
export const SET_LICENSE_BODY_NAME = "SET_LICENSE_BODY_NAME";
export const SET_LICENSE_NUMBER = "SET_LICENSE_NUMBER";
export const SAVE_CURRENT_TEAM_MEMBER = "SAVE_CURRENT_TEAM_MEMBER";
export const SET_MEMBER_MOBILE_NUMBER = "SET_MEMBER_MOBILE_NUMBER";

// preferred service provider
export const SET_PREFERRED_SP_SUPPORT = "SET_PREFERRED_SP_SUPPORT";
export const REMOVE_ALL_PREFERRED_SP = "REMOVE_ALL_PREFERRED_SP";
export const ADD_PREFERRED_SP = "ADD_PREFERRED_SP";
export const DELETE_PREFERRED_SP = "DELETE_PREFERRED_SP";
export const SAVE_PREFERRED_SP = "SAVE_PREFERRED_SP";

//user Data -> on Login
export const SAVE_USER = "SAVE_USER";

//Location Served
export const SET_CITIES = "SET_CITIES";
export const ADD_LOCATION_SERVED = "ADD_LOCATION_SERVED";
export const DELETE_LOCATION_SERVED_CITY = "DELETE_LOCATION_SERVED_CITY";
export const CLEAR_LOCATION_SERVED = "CLEAR_LOCATION_SERVED";

//business type
export const SAVE_SUB_BUSINESS_TYPE = "SAVE_SUB_BUSINESS_TYPE";

export const SET_AREA_SPEC = "SET_AREA_SPEC";

export const SET_LANGUAGE_SUPPORT = "SET_LANGUAGE_SUPPORT";

export const SET_YEARS_IN_BUSINESS = "SET_YEARS_IN_BUSINESS";

export const SET_NUM_OF_EMPLOYEES = "SET_NUM_OF_EMPLOYEES";

// Storefront
export const SAVE_STOREFRONT = "SAVE_STOREFRONT";
export const SET_DISPLAY_PICTURE = "SET_DISPLAY_PICTURE";
export const SET_BANNER_PICTURE = "SET_BANNER_PICTURE";
export const ADD_SOCIAL_LINK = "ADD_SOCIAL_LINK";
export const REMOVE_SOCIAL_LINK = "REMOVE_SOCIAL_LINK";
export const UPDATE_SOCIAL_LINK = "UPDATE_SOCIAL_LINK";
export const SET_PRIMARY_COLOR = "SET_PRIMARY_COLOR";
export const SET_SECONDARY_COLOR = "SET_SECONDARY_COLOR";
export const SET_COMPETE_TEXT = "SET_COMPETE_TEXT";
export const SET_COMPANY_OVERVIEW = "SET_COMPANY_OVERVIEW";
export const SET_COMPANY_WEBSITE = "SET_COMPANY_WEBSITE";
export const ADD_SERVICE = "ADD_SERVICE";
export const REMOVE_SERVICE = "REMOVE_SERVICE";
export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const REORDER_SERVICE = "REORDER_SERVICE";
export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const REMOVE_DOCUMENT = "REMOVE_DOCUMENT";
export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT";
export const ADD_REVIEW = "ADD_REVIEW";
export const REMOVE_REVIEW = "REMOVE_REVIEW";
export const UPDATE_REVIEW = "UPDATE_REVIEW";
export const ADD_C_PAYMENT = "ADD_C_PAYMENT";
export const REMOVE_C_PAYMENT = "REMOVE_C_PAYMENT";
export const UPDATE_C_PAYMENT = "UPDATE_C_PAYMENT";
export const SET_STOREFRONT_DATA = "SET_STOREFRONT_DATA";
export const SET_STOREFRONT_ID = "SET_STOREFRONT_ID";
export const SET_REVIEW_FIELD = "SET_REVIEW_FIELD";
export const UPDATE_CREDIT_CARD_INFO = "UPDATE_CREDIT_CARD_INFO";
export const UPDATE_PAYPAL_INFO = "UPDATE_PAYPAL_INFO";
export const UPDATE_WIRE_TRANSFER_INFO = "UPDATE_WIRE_TRANSFER_INFO";
export const SET_CUSTOMER_PAYMENTS = "SET_CUSTOMER_PAYMENTS";
export const UPDATE_PAYMENTS_METHODS = "UPDATE_PAYMENTS_METHODS";
export const UPDATE_E_TRANSFER_INFO = "UPDATE_E_TRANSFER_INFO";
export const UPDATE_CHEQUE_INFO = "UPDATE_CHEQUE_INFO";
export const RESET_INFO = "RESET_INFO";
export const UPDATE_OTHER_INFO = "UPDATE_OTHER_INFO";
export const RESET_OTHER_INFO = "RESET_OTHER_INFO";
export const ADD_OTHER_PAYMENT_METHOD = "ADD_OTHER_PAYMENT_METHOD";
export const DELETE_OTHER_PAYMENT_METHOD = "DELETE_OTHER_PAYMENT_METHOD";
export const SAVE_INFO = "SAVE_INFO";
export const CLICK_EDIT = "CLICK_EDIT";
export const CLICK_DELETE = "CLICK_DELETE";
export const SAVE_OTHER_PAYMENT_METHOD = "SAVE_OTHER_PAYMENT_METHOD";
export const SET_NUM_YRS_BUSINESS = "SET_NUM_YRS_BUSINESS";
export const SET_NUM_EMPLOYEES = "SET_NUM_EMPLOYEES";
export const SET_STORE_FRONT_ROUTE_NAME = "SET_STORE_FRONT_ROUTE_NAME";
export const INITIAL_STATE_STORE = "INITIAL_STATE_STORE";
export const SET_STORE_FRONT_NAME = "SET_STORE_FRONT_NAME";
export const SET_EXTERNAL_LINKS_SUPPORT = "SET_EXTERNAL_LINKS_SUPPORT";
export const SET_EXTERNAL_REVIEW_SUPPORT = "SET_EXTERNAL_REVIEW_SUPPORT";
export const SET_INSTAGRAM_TOKEN = "SET_INSTAGRAM_TOKEN";

//HomeBuyer
export const SAVE_HB_SINGUP_INFO = "SAVE_HB_SINGUP_INFO";
export const SAVE_HB_SINGUP_USERINFO = "SAVE_HB_SINGUP_USERINFO";
export const SET_EXPLORE_SERVICES = "SET_EXPLORE_SERVICES";
export const SET_EXPLORE_CITIES = "SET_EXPLORE_CITIES";
export const SET_FEATURED_STORE_FRONTS = "SET_FEATURED_STORE_FRONTS";
export const SET_SEARCHED_STORE_FRONTS = "SET_SEARCHED_STORE_FRONTS";
export const ADD_MARKETING_RELATED_FILES = "ADD_MARKETING_RELATED_FILES";
export const DELETE_MARKETING_RELATED_FILE = "DELETE_MARKETING_RELATED_FILE";
export const SET_FEATURED_STORE_FRONTS_FOR_WEBSITE =
  "SET_FEATURED_STORE_FRONTS_FOR_WEBSITE";
export const SET_FEATURED_STORE_FRONTS_ADD_ON =
  "SET_FEATURED_STORE_FRONTS_ADD_ON";

//Subscription Plan
export const SET_PLAN_DURATION = "SET_PLAN_DURATION";
export const SET_PROMO_CODE = "SET_PROMO_CODE";
export const SET_HAVE_PROMO_CODE = "SET_HAVE_PROMO_CODE";
export const SET_TEAM_PLAN_ID = "SET_TEAM_PLAN_ID";
export const SET_USER_PLAN_ID = "SET_USER_PLAN_ID";
export const SET_PREV_USER_PLAN_ID = "SET_PREV_USER_PLAN_ID";
export const SET_PREV_PLAN_DURATION = "SET_PREV_PLAN_DURATION";

//Payment And Invoice
export const SET_INVOICE_DATA = "SET_INVOICE_DATA";
export const SET_SUBSCRIPTION_PLAN_DETAILS = "SET_SUBSCRIPTION_PLAN_DETAILS";
export const SHOW_INVOICE = "SHOW_INVOICE";

// HB documents
export const SET_DOCUMENT_TAB = "SET_DOCUMENT_TAB";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const UPDATE_CATEGORIES = "UPDATE_CATEGORIES";
export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";
export const SET_CATEGORY = "SET_CATEGORY";
export const RENAME_CATEGORY = "RENAME_CATEGORY";
export const SET_CONTACTS = "SET_CONTACTS";
export const SET_SELECTED_CONTACTS = "SET_SELECTED_CONTACTS";
export const SET_ACTIVE_SERVICE_PROVIDERS = "SET_ACTIVE_SERVICE_PROVIDERS";
export const SET_SELECTED_ACTIVE_SP = "SET_SELECTED_ACTIVE_SP";
export const ADD_DOCUMENTS = "ADD_DOCUMENTS";
export const SET_REFERENCE_DOCUMENT = "SET_REFERENCE_DOCUMENT";
export const SET_DOCUMENT_CATEGORY = "SET_DOCUMENT_CATEGORY";
export const DELETE_DOCUMENT_WHILE_UPLOAD = "DELETE_DOCUMENT_WHILE_UPLOAD";
export const CLEAR_UPLOAD_FILE_DATA = "CLEAR_UPLOAD_FILE_DATA";
export const UPDATE_UPLOADED_DOCS = "UPDATE_UPLOADED_DOCS";
export const DELETE_CONTACTS = "DELETE_CONTACTS";
export const SET_DOCUMENT_TAB_NAME = "SET_DOCUMENT_TAB_NAME";

//explore page
export const EXPLORE_PAGE_FILTER = "EXPLORE_PAGE_FILTER";
export const SET_SEARCHED_CITY = "SET_SEARCHED_CITY";
export const SET_SEARCHED_SERVICE = "SET_SEARCHED_SERVICE";
export const CLEAR_EXPLORE_SEARCH_RESULT = "CLEAR_EXPLORE_SEARCH_RESULT";
// HB Setup
export const SET_CO_APPLICANT = "SET_CO_APPLICANT";
export const SAVE_CO_APPLICANT = "SAVE_CO_APPLICANT";
export const UPDATE_CURRENT_ADDRESS = "UPDATE_CURRENT_ADDRESS";
export const SAVE_CURRENT_ADDRESS = "SAVE_CURRENT_ADDRESS";
export const EDIT_CURRENT_ADDRESS = "EDIT_CURRENT_ADDRESS";
export const RESET_CURRENT_ADDRESS = "RESET_CURRENT_ADDRESS";
export const UPDATE_PRIMARY_APPLICANT = "UPDATE_PRIMARY_APPLICANT";
export const UPDATE_PROFILE_PHOTO = "UPDATE_PROFILE_PHOTO";
export const UPDATE_NEW_HOME_PROFILE = "UPDATE_NEW_HOME_PROFILE";
export const SAVE_NEW_HOME_PROFILE = "SAVE_NEW_HOME_PROFILE";
export const EDIT_NEW_HOME_PROFILE = "EDIT_NEW_HOME_PROFILE";
export const RESET_NEW_HOME_PROFILE = "RESET_NEW_HOME_PROFILE";
export const UPDATE_DEMOGRAPHIC_DATA = "UPDATE_DEMOGRAPHIC_DATA";
export const SET_HB_PERSONAL_INFO = "SET_HB_PERSONAL_INFO";
export const SET_NEW_HOME_ADDRESS = "SET_NEW_HOME_ADDRESS";
export const SET_DEMOGRAPHIC_DATA = "SET_DEMOGRAPHIC_DATA";
export const UPDATE_DOCUMENT_LIST = "UPDATE_DOCUMENT_LIST";
export const SET_DOCUMENTS_DATA = "SET_DOCUMENTS_DATA";
export const SET_MAX_FILE_SIZE = "SET_MAX_FILE_SIZE";
export const DELETE_PROFILE_DOC = "DELETE_PROFILE_DOC";

// SP Document Upload

export const SET_SP_CATEGORIES = "SET_SP_CATEGORIES";
export const UPDATE_SP_CATEGORIES = "UPDATE_SP_CATEGORIES";
export const SET_SP_SELECTED_CATEGORY = "SET_SP_SELECTED_CATEGORY";
export const SET_SP_CATEGORY = "SET_SP_CATEGORY";
export const RENAME_SP_CATEGORY = "RENAME_SP_CATEGORY";
export const SET_SP_CONTACTS_LIST = "SET_SP_CONTACTS_LIST";
export const SET_SP_SELECTED_CONTACTS = "SET_SP_SELECTED_CONTACTS";
export const SET_SP_ACTIVE_SERVICE_PROVIDERS =
  "SET_SP_ACTIVE_SERVICE_PROVIDERS";
export const SET_SP_SELECTED_ACTIVE_SP = "SET_SP_SELECTED_ACTIVE_SP";
export const ADD_SP_DOCUMENTS = "ADD_SP_DOCUMENTS";
export const SET_SP_REFERENCE_DOCUMENT = "SET_SP_REFERENCE_DOCUMENT";
export const SET_SP_DOCUMENT_CATEGORY = "SET_SP_DOCUMENT_CATEGORY";
export const DELETE_SP_DOCUMENT_WHILE_UPLOAD =
  "DELETE_SP_DOCUMENT_WHILE_UPLOAD";
export const CLEAR_SP_UPLOAD_FILE_DATA = "CLEAR_SP_UPLOAD_FILE_DATA";
export const UPDATE_SP_UPLOADED_DOCS = "UPDATE_SP_UPLOADED_DOCS";
export const DELETE_SP_CONTACTS = "DELETE_SP_CONTACTS";
export const UPDATE_SP_DOCUMENT_DESCRIPTION = "UPDATE_SP_DOCUMENT_DESCRIPTION";
export const ADD_SP_DOCUMENT_SUMMARY = "ADD_SP_DOCUMENT_SUMMARY";
export const DELETE_SP_DOCUMENT_SUMMARY = "DELETE_SP_DOCUMENT_SUMMARY";
export const SET_SP_DOCUMENT_SUMMARY = "SET_SP_DOCUMENT_SUMMARY";
export const EDIT_SP_DOCUMENT_SUMMARY = "EDIT_SP_DOCUMENT_SUMMARY";
export const ADD_SP_ACTIVE_CONTACT = "ADD_SP_ACTIVE_CONTACT";
export const SET_SP_ASSOCIACTED_SERVICE_PROVIDERS =
  "SET_SP_ASSOCIACTED_SERVICE_PROVIDERS";
export const ADD_SP_ASSOC_PROVIDER_CONTACT = "ADD_SP_ASSOC_PROVIDER_CONTACT";
export const ADD_SP_ESIGN_DOCUMENTS = "ADD_SP_ESIGN_DOCUMENTS";
export const DELETE_SP_ESIGN_DOCUMENT_WHILE_UPLOAD =
  "DELETE_SP_ESIGN_DOCUMENT_WHILE_UPLOAD";
export const CLEAR_SP_ESIGN_UPLOAD_FILE_DATA =
  "CLEAR_SP_ESIGN_UPLOAD_FILE_DATA";
export const CLEAN_ESIGN_DATA_AND_CLOSE = "CLEAN_ESIGN_DATA_AND_CLOSE";
export const UPDATE_SP_ESIGN_DOCUMENT_DESCRIPTION =
  "UPDATE_SP_ESIGN_DOCUMENT_DESCRIPTION";

export const ADD_UPLOAD_DOCUMENTS = "ADD_UPLOAD_DOCUMENTS";
export const UPDATE_UPLOAD_DOCUMENT_DESCRIPTION =
  "UPDATE_UPLOAD_DOCUMENT_DESCRIPTION";
export const DELETE_UPLOAD_DOCUMENT_WHILE_UPLOAD =
  "DELETE_UPLOAD_DOCUMENT_WHILE_UPLOAD";
export const CLEAN_UPLOAD_FILE_DATA = "CLEAN_UPLOAD_FILE_DATA";

export const SET_UPLOAD_SELECTED_CONTACTS = "SET_UPLOAD_SELECTED_CONTACTS";
export const SET_UPLOAD_ACTIVE_CUSTOMERS = "SET_UPLOAD_ACTIVE_CUSTOMERS";
export const SET_UPLOAD_CONTACTS = "SET_UPLOAD_CONTACTS";
export const SET_EDIT_ESIGN_DOCUMENT = "SET_EDIT_ESIGN_DOCUMENT";
export const SET_OPEN_ESIGN_DOCUMENT = "SET_OPEN_ESIGN_DOCUMENT";
export const SET_EDIT_WARNING_DIALOG = "SET_EDIT_WARNING_DIALOG";
export const SIGN_NOW_USER_VERIFIED = "SIGN_NOW_USER_VERIFIED";
// Referral Management

export const SAVE_INVITE_SP_DATA = "SAVE_INVITE_SP_DATA";
export const SAVE_PRIMARY_APPLICANT_DATA = "SAVE_PRIMARY_APPLICANT_DATA";
export const SAVE_CO_APPLICANT_DATA = "SAVE_CO_APPLICANT_DATA";
export const CLEAR_INIVITE_CUSTOMER_DATA = "CLEAR_INIVITE_CUSTOMER_DATA";
export const CLEAR_INIVITE_SP_DATA = "CLEAR_INIVITE_SP_DATA";
export const SAVE_RECOMMEND_SP_DATA = "SAVE_RECOMMEND_SP_DATA";
export const SHOW_CO_APPLICANT = "SHOW_CO_APPLICANT";
export const SHOW_RECOMMENDED_SP = "SHOW_RECOMMENDED_SP";
export const SET_UPLOAD_CATEGORIES = "SET_UPLOAD_CATEGORIES";
export const SET_OPEN_UPLOAD_WIZARD = "SET_OPEN_UPLOAD_WIZARD";
export const SAVE_NEW_SP = "SAVE_NEW_SP";
export const SET_OPEN_ADD_TEMPLATE = "SET_OPEN_ADD_TEMPLATE";
export const SET_OPEN_EDIT_TEMPLATE = "SET_OPEN_EDIT_TEMPLATE";
export const SET_OPEN_INVITE_AND_SEND_TEMPLATE =
  "SET_OPEN_INVITE_AND_SEND_TEMPLATE";
export const ADD_TEMPLATE_DOCUMENT = "ADD_TEMPLATE_DOCUMENT";
export const CLEAR_TEMPLATE_DOCUMENTS = "CLEAR_TEMPLATE_DOCUMENTS";
export const CLEAR_TEMPLATE_DOCS_AND_CLOSE = "CLEAR_TEMPLATE_DOCS_AND_CLOSE";
export const SET_SP_REFERRAL_LIST = "SET_SP_REFERRAL_LIST";
export const DELETE_TEMPLATE_DOCUMENT_WHILE_UPLOAD =
  "DELETE_TEMPLATE_DOCUMENT_WHILE_UPLOAD";
export const ADD_TEMPLATE_DOCUMENT_SUMMARY = "ADD_TEMPLATE_DOCUMENT_SUMMARY";
export const SET_TEMPLATE_DOCUMENT_SUMMARY = "SET_TEMPLATE_DOCUMENT_SUMMARY";
export const DELETE_TEMPLATE_DOCUMENT_SUMMARY =
  "DELETE_TEMPLATE_DOCUMENT_SUMMARY";
export const EDIT_TEMPLATE_DOCUMENT_SUMMARY = "EDIT_TEMPLATE_DOCUMENT_SUMMARY";
export const ADD_NEW_SP = "ADD_NEW_SP";
export const DELETE_NEW_SP = "DELETE_NEW_SP";
export const CLEAR_NEW_SP_DATA = "CLEAR_NEW_SP_DATA";
export const SET_SERVICE_PROVIDER_LIST = "SET_SERVICE_PROVIDER_LIST";
export const CLEAR_CO_APPLICANT_DETAILS = "CLEAR_CO_APPLICANT_DETAILS";
export const CLEAR_RECOMMENDED_SP = "CLEAR_RECOMMENDED_SP";
export const SET_DOCUMENT_LIST = "SET_DOCUMENT_LIST";
export const SET_TEMPLATE_LIST = "SET_TEMPLATE_LIST";

//service request
export const ADD_SERVICE_REQUEST_DCOUMENTS = "ADD_SERVICE_REQUEST_DCOUMENTS";
export const DELETE_SERVICE_REQUEST_DCOUMENT =
  "DELETE_SERVICE_REQUEST_DCOUMENT";
export const SET_WARNING_DIALOG_FLAG = "SET_WARNING_DIALOG_FLAG";

// Team Onboarding
export const SAVE_TEAM_ONBOARDING_DATA = "SAVE_TEAM_ONBOARDING_DATA";
export const SAVE_ERROR_MSG = "SAVE_ERROR_MSG";

// Client Management
export const GET_CLIENT_DATA = "GET_CLIENT_DATA";
export const SET_CLIENTS_TAB = "SET_CLIENTS_TAB";
export const SET_CLIENT_MGMT_DATA = "SET_CLIENT_MGMT_DATA";
export const DROP_REQUEST = "DROP_REQUEST";
export const SET_ACTIVE_CLIENT_DATA = "SET_ACTIVE_CLIENT_DATA";
export const SET_INACTIVE_CLIENT_DATA = "SET_INACTIVE_CLIENT_DATA";
export const SET_COMPLETE_CLIENT_DATA = "SET_COMPLETE_CLIENT_DATA";
export const SET_LEADS_CLIENT_DATA = "SET_LEADS_CLIENT_DATA";
export const SEND_INVOICE_ADD_FILE = "SEND_INVOICE_ADD_FILE";
export const SEND_INVOICE_REMOVE_FILE = "SEND_INVOICE_REMOVE_FILE";
export const SET_CLIENT_DOCUMENTS = "SET_CLIENT_DOCUMENTS";
export const SET_TAG_MEMBERS = "SET_TAG_MEMBERS";
export const EDIT_TEAM_MEMBER = "EDIT_TEAM_MEMBER";
export const SET_ALL_USERS = "SET_ALL_USERS";
export const RESET_ALL_USERS_DATA = "RESET_ALL_USERS_DATA";
export const SET_LOADING_ALL_USERS_DATA = "SET_LOADING_ALL_USERS_DATA";
export const SET_CLIENT_HUB_DOCUMENTS = "SET_CLIENT_HUB_DOCUMENTS";
export const CLEAR_RECOMMEND_SP_TO_CLIENT_DATA =
  "CLEAR_RECOMMEND_SP_TO_CLIENT_DATA";
export const SAVE_RECOMMEND_SP_TO_CLIENT_DATA =
  "SAVE_RECOMMEND_SP_TO_CLIENT_DATA";

// SP Management
export const GET_SERVICE_PROVIDERS_DATA = "GET_SERVICE_PROVIDERS_DATA";
export const SET_SERVICE_PROVIDERS_TAB = "SET_SERVICE_PROVIDERS_TAB";
export const SET_SP_MGMT_DATA = "SET_SP_MGMT_DATA";
export const SET_ACTIVE_SP_DATA = "SET_ACTIVE_SP_DATA";
export const SET_REQUESTS_SP_DATA = "SET_REQUESTS_SP_DATA";
export const SET_SP_LABEL = "SET_SP_LABEL";
export const SET_ARCHIVE_SP_DATA = "SET_ARCHIVE_SP_DATA";
export const SET_COMPLETE_SP_DATA = "SET_COMPLETE_SP_DATA";

//Upcoming Events, Pending Actions
export const SET_EVENTS_TAB = "SET_EVENTS_TAB";
export const SET_PENDING_ACTIONS = "SET_PENDING_ACTIONS";
export const REMOVE_PENDING_ACTION = "REMOVE_PENDING_ACTION";

// SF Client Reviews
export const SET_REVIEWS_LIST = "SET_REVIEWS_LIST";
export const SET_TOTAL_REVIEWS = "SET_TOTAL_REVIEWS";
export const SET_REVIEW_STATISTICS = "SET_REVIEW_STATISTICS";
export const UPDATE_CLIENT_REVIEW_FLAG = " UPDATE_CLIENT_REVIEW_FLAG";
export const SET_STOREFRONT_SERVICES_LIST = " SET_STOREFRONT_SERVICES_LIST";

// Messaging actions
export const SET_CHIME_AUTHENTICATION_DATA = "SET_CHIME_AUTHENTICATION_DATA";
export const SET_CURRENT_CHANNEL_MESSAGES = "SET_CURRENT_CHANNEL_MESSAGES";
export const SET_SIGN_IN_PROGRESS = "SET_SIGN_IN_PROGRESS";
export const SET_SELECTED_CHANNEL_DATA = "SET_SELECTED_CHANNEL_DATA";
export const SET_CHIME_CHANNEL_LIST = "SET_CHIME_CHANNEL_LIST";
export const RESET_ALL_MESSAGING_DATA = "RESET_ALL_MESSAGING_DATA";
export const SET_CHIME_SESSION_STARTED = "SET_CHIME_SESSION_STARTED";
export const SET_UNREAD_CHANNELS = "SET_UNREAD_CHANNELS";
export const SET_CHANNEL_MEMBERSHIP_LIST = "SET_CHANNEL_MEMBERSHIP_LIST";
export const SET_All_USER_LIST = "SET_All_USER_LIST";
export const SET_UNREAD_MESSAGES_FROM_USERS = "SET_UNREAD_MESSAGES_FROM_USERS";
export const SET_CHANNEL_MESSAGE_TOKEN = "SET_CHANNEL_MESSAGE_TOKEN";
export const SET_MESSAGE_ATTACHMENT = "SET_MESSAGE_ATTACHMENT";
export const RESET_LOADED_MESSAGE_DATA = "RESET_LOADED_MESSAGE_DATA";
export const SET_LOADED_CHANNEL_DATA = "SET_LOADED_CHANNEL_DATA";
export const SET_CHIME_SESSION = "SET_CHIME_SESSION";
export const SET_SOCKET_MESSAGE = "SET_SOCKET_MESSAGE";
export const SET_CURRENT_UI_PATH = "SET_CURRENT_UI_PATH";
// common documents store
export const SET_DOCUMENTS_TABLE_DATA = "SET_DOCUMENTS_TABLE_DATA";

//Home Buying Journey
export const SET_JOURNEY_STATUS = "SET_JOURNEY_STATUS";

// Calendar
export const SET_CALENDAR_EVENTS = "SET_CALENDAR_EVENTS";
export const GET_ALL_CONTACTS_TO_MEETING = "GET_ALL_CONTACTS_TO_MEETING";
export const SET_OPEN_CREATE_MEETING_DIALOG = "SET_OPEN_CREATE_MEETING_DIALOG";
export const SET_MEETING_MODE = "SET_MEETING_MODE";
export const SET_SELECTED_CALENDAR_EVENT = "SET_SELECTED_CALENDAR_EVENT";
export const SET_OPEN_CANCEL_MEETING_DIALOG = "SET_OPEN_CANCEL_MEETING_DIALOG";
export const CLEAR_CALENDAR_DATA = "CLEAR_CALENDAR_DATA";

// Notifications
export const SET_NOTIFICATIONS_LIST = "SET_NOTIFICATIONS_LIST";
export const SET_SHOW_ONLY_UNREAD_NOTIFICATIONS =
  "SET_SHOW_ONLY_UNREAD_NOTIFICATIONS";
export const UPDATE_READ_NOTIFICATION = "UPDATE_READ_NOTIFICATION";
export const UPDATE_ALL_READ_NOTIFICATION = "UPDATE_ALL_READ_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";

//Key Stats
export const SET_SP_KEY_STATS_DATA = "SET_SP_KEY_STATS_DATA";
export const SET_HB_KEY_STATS_DATA = "SET_HB_KEY_STATS_DATA";

//explore page filters
export const SET_SELECTED_BUSINESS_TYPES = "SET_SELECTED_BUSINESS_TYPES";
export const SET_SELECTED_AREA_OF_SPEC = "SET_SELECTED_AREA_OF_SPEC";
export const SET_SELECTED_LANGUAGES = "SET_SELECTED_LANGUAGES";
export const SET_SELECTED_RATING = "SET_SELECTED_RATING";
export const SET_SELECTED_BADGE = "SET_SELECTED_BADGE";
export const SET_SELECTED_SUB_BUSINESS_TYPES =
  "SET_SELECTED_SUB_BUSINESS_TYPES";
export const RESET_EXPLORE_FILTERS = "RESET_EXPLORE_FILTERS";

// client hub tab
export const SET_CLIENT_HISTORY = "SET_CLIENT_HISTORY";

// marketing campaign
export const SET_ALL_MARKETING_CAMPAIGN_DATA =
  "SET_ALL_MARKETING_CAMPAIGN_DATA";
export const SET_CAMPAIGN_STEP1_DATA = "SET_CAMPAIGN_STEP1_DATA";
export const CLEAR_CAMPAIGN_DATA = "CLEAR_CAMPAIGN_DATA";
export const SET_DROPDOWN_VALUES = "SET_DROPDOWN_VALUES";
export const RESET_CAMPAIGN_START_DATE = "RESET_CAMPAIGN_START_DATE";
export const SET_UPDATE_MARKETING_CAMPAIGN_DATA =
  "SET_UPDATE_MARKETING_CAMPAIGN_DATA";
export const SET_CAMPAIGN_INVOCIE_DATA = "SET_CAMPAIGN_INVOCIE_DATA";
export const SET_CAMPAIGN_ANALYTICS_DATA = "SET_CAMPAIGN_ANALYTICS_DATA";

// Home Buyer Timeline data
export const SET_ALL_TIMELINE_DATA = "SET_ALL_TIMELINE_DATA";
export const SET_INDIVIDUAL_SP_TIMELINE_DATA =
  "SET_INDIVIDUAL_SP_TIMELINE_DATA";
export const CLEAR_INDIVIDUAL_SP_TIMELINE_DATA =
  "CLEAR_INDIVIDUAL_SP_TIMELINE_DATA";
export const SET_INDIVIDUAL_SP_DOCUMENTS = "SET_INDIVIDUAL_SP_DOCUMENTS";

// Email preferences
export const SET_EMAIL_PREFERENCES = "SET_EMAIL_PREFERENCES";

// Payment history
export const SET_PAYMENT_HISTORY = "SET_PAYMENT_HISTORY";

//broadcast service request
export const ADD_BROADCAST_SERVICE_REQUEST_DOCUMENTS =
  "ADD_BROADCAST_SERVICE_REQUEST_DOCUMENTS";
export const DELETE_BROADCAST_SERVICE_REQUEST_DOCUMENT =
  "DELETE_BROADCAST_SERVICE_REQUEST_DOCUMENT";
