import {
  Avatar,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
  Link,
} from "@material-ui/core";
import React, { useEffect } from "react";
import clsx from "clsx";
import Styles from "../../Styles.module.css";
import CallIcon from "@material-ui/icons/Call";
import VideocamIcon from "@material-ui/icons/Videocam";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import { useAudioVideoCalling } from "souqh-react-redux-hooks/messaging/useAudioVideoCalling";
import {
  formatContactNumber,
  isMobileDevice,
  isPlusPlan,
  isProPlan,
  isTabletDevice,
} from "../../utils/AppUtils";
import { useSQCalendar } from "souqh-react-redux-hooks/common/useSQCalendar";
import { useUpcomingEvents } from "souqh-react-redux-hooks/serviceProvider/upcomingEeventsPendingActions/useUpcomingEvents";
import CreateMeetingDialog from "../common/SQCalender/CreateMeetingDialog";
import {
  HOME_BUYER_USER,
  MEETING_CREATE_MODE,
} from "../ServiceProvider/serviceProviderConstants";
import StarsIcon from "@mui/icons-material/Stars";
import UpgradePlanIconSection from "../common/UpgradePlanIconSection";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  actionIcon: {
    fill: "#0e1c4e",
    cursor: "default",
    margin: "0px 8px",
    opacity: 0.5,
  },
  activeActionIcon: {
    fill: "#0e1c4e",
    margin: "0px 8px",
    cursor: "pointer",
  },
  subText: {
    fontSize: "12px",
  },
}));
export default function ChatPanelHeader({
  selectedChannelData,
  selectedChannelMembersList,
  setOpenGroupMembersDialog,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { getCallLink, requestServiceMessageFromBackground } =
    useAudioVideoCalling(selectedChannelData);

  const { getUpcomingCalendarEvents } = useUpcomingEvents();

  const {
    actions: {
      setOpenCreateMeetingDialog,
      setMeetingMode,
      setOpenConfirmCanelMeetingDialog,
    },
    currentCalendarDate,
    setCurrentCalendarDate,
    calendarEvents,
    openCreateMeetingDialog,
    userInfo,
    meetingMode,
    selectedCalendarEvent,
    cancelMeeting,
    title,
    setTitle,
    meetingDate,
    setMeetingDate,
    meetingLink,
    meetingPhoneNumber,
    meetingLocation,
    setMeetingLocation,
    meetingAttachments,
    description,
    setDescription,
    uploadRef,
    textAreaRef,
    contactInputRef,
    handleDialogClose,
    processAttachment,
    removeAttachment,
    copyToClipboard,
    meetingStartTime,
    setMeetingStartTime,
    meetingEndTime,
    setMeetingEndTime,
    addCustomItem,
    selectedContacts,
    customContact,
    setCustomContact,
    allContacts,
    removeSelectedContact,
    addItemOnSelect,
    getAllContacts,
    invalidTimes,
    setInvalidTimes,
    setMeetingData,
    removeMeetingLink,
    addMeetingLink,
    setAddMeetingLink,
    setupEmptyData,
    saveMeeting,
    validateTimeSlot,
    isFullDayEvent,
    setIsFullDayEvent,
    getCalendarEvents,
    eventUserId,
    hasFilledMandatoryData,
    updateMeetingStatus,
    downlodAttachment,
    openConfirmCanelMeetingDialog,
    filteredContacts,
    downloadCalendarAttachment,
  } = useSQCalendar({
    calendarListUpdater: true,
    upcomingEventsUpdater: getUpcomingCalendarEvents,
  });

  useEffect(() => {
    getCalendarEvents();
    getUpcomingCalendarEvents(10);
    getAllContacts();
  }, []);

  if (!selectedChannelData) {
    return null;
  }
  return (
    <Grid item xs={12} style={{ width: isMobileDevice() && "92%" }}>
      <CreateMeetingDialog
        openCreateMeetingDialog={openCreateMeetingDialog}
        setOpenCreateMeetingDialog={setOpenCreateMeetingDialog}
        meetingMode={meetingMode}
        setMeetingMode={setMeetingMode}
        openConfirmCanelMeetingDialog={openConfirmCanelMeetingDialog}
        setOpenConfirmCanelMeetingDialog={setOpenConfirmCanelMeetingDialog}
        cancelMeeting={cancelMeeting}
        selectedCalendarEvent={selectedCalendarEvent}
        title={title}
        setTitle={setTitle}
        meetingDate={meetingDate}
        setMeetingDate={setMeetingDate}
        meetingLink={meetingLink}
        meetingPhoneNumber={meetingPhoneNumber}
        meetingLocation={meetingLocation}
        setMeetingLocation={setMeetingLocation}
        meetingAttachments={meetingAttachments}
        description={description}
        setDescription={setDescription}
        uploadRef={uploadRef}
        textAreaRef={textAreaRef}
        contactInputRef={contactInputRef}
        meetingStartTime={meetingStartTime}
        setMeetingStartTime={setMeetingStartTime}
        meetingEndTime={meetingEndTime}
        setMeetingEndTime={setMeetingEndTime}
        handleDialogClose={handleDialogClose}
        processAttachment={processAttachment}
        removeAttachment={removeAttachment}
        copyToClipboard={copyToClipboard}
        addCustomItem={addCustomItem}
        selectedContacts={selectedContacts}
        customContact={customContact}
        setCustomContact={setCustomContact}
        allContacts={allContacts}
        removeSelectedContact={removeSelectedContact}
        addItemOnSelect={addItemOnSelect}
        invalidTimes={invalidTimes}
        setInvalidTimes={setInvalidTimes}
        setMeetingData={setMeetingData}
        removeMeetingLink={removeMeetingLink}
        addMeetingLink={addMeetingLink}
        setAddMeetingLink={setAddMeetingLink}
        setupEmptyData={setupEmptyData}
        saveMeeting={saveMeeting}
        validateTimeSlot={validateTimeSlot}
        isFullDayEvent={isFullDayEvent}
        setIsFullDayEvent={setIsFullDayEvent}
        eventUserId={eventUserId}
        userInfo={userInfo}
        hasFilledMandatoryData={hasFilledMandatoryData}
        updateMeetingStatus={updateMeetingStatus}
        downlodAttachment={downlodAttachment}
        filteredContacts={filteredContacts}
        downloadCalendarAttachment={downloadCalendarAttachment}
      />
      <Grid item container xs={12}>
        <Grid item container xs={10}>
          <Grid
            item
            xs={2}
            lg={1}
            style={{ display: "flex", alignItems: "center" }}
          >
            {selectedChannelData.isGroup ? (
              <Avatar
                src={"/images/message_group.svg"}
                style={{
                  width: isMobileDevice() ? 36 : 56,
                  height: isMobileDevice() ? 36 : 56,
                  borderRadius: 4,
                }}
              />
            ) : selectedChannelData.profilePhoto ? (
              <Avatar src={selectedChannelData.profilePhoto} />
            ) : selectedChannelData.storeFrontContent ? (
              <Avatar
                src={
                  JSON.parse(selectedChannelData.storeFrontContent)
                    .displayPicture
                }
                style={{
                  width: isMobileDevice() ? 40 : 60,
                  height: isMobileDevice() ? 40 : 60,
                  borderRadius: 4,
                }}
              />
            ) : (
              <AccountBoxIcon
                style={{
                  fontSize: isMobileDevice() ? 36 : 56,
                  fill: "#0e1c4e",
                  opacity: 0.3,
                }}
              />
            )}
          </Grid>
          <Grid
            item
            xs={10}
            lg={11}
            style={{ paddingLeft: !isMobileDevice() && 20, overflow: "hidden" }}
          >
            <Typography
              variant={isMobileDevice() ? "subtitle1" : "h6"}
              style={{ textTransform: "capitalize" }}
              className={clsx(Styles.sqEllipsis, Styles.navyBlueColor)}
            >
              <Link
                underline="always"
                style={{ cursor: "pointer" }}
                className={Styles.navyBlueColorForce}
                onClick={() => {
                  if (userInfo && userInfo.userType === HOME_BUYER_USER) {
                    history.push(
                      `/homebuyer/serviceproviders/details/${selectedChannelData.serviceProviderId}`
                    );
                  } else {
                    if (selectedChannelData.serviceProviderId) {
                      let sfId = JSON.parse(selectedChannelData.storeFrontContent).storeFrontId
                      if (sfId) {
                        history.push(
                          `/serviceprovider/storefrontview/${selectedChannelData.businessName}?storeFrontId=${sfId}`
                        );
                      }
                    } else {
                      history.push(
                        `/serviceprovider/clients/details/${selectedChannelData.userId}`
                      );
                    }
                  }
                }}
              >
                {selectedChannelData.fullName || selectedChannelData.Username}
              </Link>
              {selectedChannelData.storeFrontName &&
              selectedChannelData.businessName ? (
                isMobileDevice() ? (
                  <>
                    <Typography
                      className={clsx(classes.subText, Styles.navyBlueColor)}
                    >
                      {selectedChannelData.storeFrontName}
                    </Typography>
                    <Typography
                      className={clsx(classes.subText, Styles.navyBlueColor)}
                    >
                      {selectedChannelData.businessName}
                    </Typography>
                  </>
                ) : (
                  <Typography
                    className={clsx(classes.subText, Styles.navyBlueColor)}
                  >
                    {selectedChannelData.storeFrontName},{" "}
                    {selectedChannelData.businessName}
                  </Typography>
                )
              ) : null}
              {selectedChannelData.categoryName ? (
                <Typography
                  className={clsx(classes.subText, Styles.navyBlueColor)}
                >
                  {selectedChannelData.categoryName}
                </Typography>
              ) : null}
            </Typography>

            <Typography
              variant={isMobileDevice() ? "body2" : "body1"}
              style={{ cursor: "pointer" }}
              className={clsx(Styles.sqEllipsis, Styles.navyBlueColor)}
            >
              {selectedChannelData.isGroup ? (
                <span
                  onClick={() => {
                    setOpenGroupMembersDialog(true);
                  }}
                >
                  {selectedChannelMembersList.length} Participants
                </span>
              ) : null}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tooltip
            title={formatContactNumber(selectedChannelData.contactNumber) || ""}
          >
            <CallIcon
              className={classes.activeActionIcon}
              onClick={(event) => {
                event.stopPropagation();
                if (isMobileDevice()) {
                  selectedChannelData.contactNumber &&
                    window.open(
                      `tel:${selectedChannelData.contactNumber}`,
                      "_self"
                    );
                }
              }}
            />
          </Tooltip>
          <VideocamIcon
            className={clsx({
              [classes.activeActionIcon]:
                !isProPlan(userInfo) && !isPlusPlan(userInfo),
              [classes.actionIcon]: isProPlan(userInfo) || isPlusPlan(userInfo),
            })}
            onClick={() => {
              if (!isProPlan(userInfo) && !isPlusPlan(userInfo)) {
                getCallLink((res) => {
                  if (res && res.data && res.status === 200) {
                    const message = document.createElement("p");
                    message.text =
                      "Please join the meeting using the link below";

                    const link = document.createElement("a");
                    link.href = res.data.result.participantURL;
                    document.body.appendChild(message);
                    document.body.appendChild(link);

                    requestServiceMessageFromBackground(
                      selectedChannelData.email,
                      `Please join the meeting using the link below: ${link}`,
                      ""
                    );
                    // Open link in new window
                    window.open(res.data.result.moderatorURL, "_blank");
                  }
                });
              }
            }}
          />
          {(isProPlan(userInfo) || isPlusPlan(userInfo)) && (
            <UpgradePlanIconSection
              iconBtnStyle={{
                fontSize: 16,
                left: -12,
                top: -6,
                fill: "#fa7e61",
              }}
              iconStyle={{
                position: "absolute",
                fontSize: 16,
                top: 4,
                fill: "#fa7e61",
              }}
            />
          )}
          <DateRangeIcon
            className={clsx({
              [classes.activeActionIcon]: !isProPlan(userInfo),
              [classes.actionIcon]: isProPlan(userInfo),
            })}
            style={{
              marginLeft: (isProPlan(userInfo) || isPlusPlan(userInfo)) && -12,
            }}
            onClick={(e) => {
              if (!isProPlan(userInfo)) {
                if (
                  JSON.parse(selectedChannelData.storeFrontContent)
                    ?.storeFrontId === 104
                ) {
                  window.open(
                    "http://richardscalendar.torontoism.com/",
                    "_blank"
                  );
                } else {
                  // FOR SF name: Silver Burtnick & Associates
                  setMeetingMode(MEETING_CREATE_MODE);
                  setOpenCreateMeetingDialog(true);
                }
              }
            }}
          />
          {isProPlan(userInfo) && (
            <UpgradePlanIconSection
              iconBtnStyle={{
                fontSize: 16,
                left: -17,
                top: -6,
                fill: "#fa7e61",
              }}
              iconStyle={{
                position: "absolute",
                fontSize: 16,
                top: 4,
                fill: "#fa7e61",
              }}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
