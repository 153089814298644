import { TableCell, TableRow, IconButton } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import Styles from "../../Styles.module.css";
import { useDuplicateDocument } from "souqh-react-redux-hooks/homeBuyer/useDuplicateDocument";
import ViewDocumentDialog from "../DocumentManagementDialogs/ViewDocumentDialog";
import SharedWithPopup from "../DocumentManagementDialogs/SharedWithPopup";
import HBDocTableCell from "./HBDocTableCell";

export default function DocumentsRow(props) {
  const {
    category,
    categoryId,
    classes,
    firstCategory,
    documents,
    order,
    orderBy,
    getSharedWithTooltip,
    downLoadDocument,
    setOpenDeleteDocumentDialog,
    setOpenMoveDocumentDialog,
    setOpenArchiveDocumentDialog,
    setOpenRenameDocumentDialog,
    setOpenShareDocumentDialog,
    handleCheckboxChange,
    downloadMultipleDocument,
    selectedData,
    setSelectedData,
    getTabWiseCategoriesList,
    documentTabName,
    setOpenSignDocumentDialog,
    setSignDocumentItem,
  } = props;
  const [open, setOpen] = useState(false);
  const [openInnerMenu, setOpenInnerMenu] = useState(false);
  const [innerAnchorRef, setInnerAnchorRef] = useState(null);
  const [selelctedRow, setSelectedRow] = useState(null);

  const { duplicateDocument } = useDuplicateDocument(
    getTabWiseCategoriesList,
    documentTabName
  );

  const [openDocumetDialog, setOpenDocumetDialog] = useState(false);
  const [documentItem, setDocumentItem] = useState(null);

  const [openSharedWithPopup, setOpenSharedWithPopup] = useState({
    open: false,
    sharedWithList: [],
  });

  useEffect(() => {
    if (category === firstCategory) {
      setOpen(true);
    }
  }, [category]);

  const handleInnerToggle = () => {
    setOpenInnerMenu((prevOpen) => !prevOpen);
  };

  const handleInnerClose = () => {
    setOpenInnerMenu(false);
  };

  function handleInnerListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenInnerMenu(false);
    }
  }
  const getUploadedDocuments = (docDetails) => {
    if (!docDetails) {
      return [];
    }

    const fileName =
      decodeURIComponent(docDetails.docName.replace(/^.*[\\\/]/, "")) || "";
    let docObj = {
      name: fileName,
      path: docDetails.documentPreviewUrl,
      type: fileName.split(".").pop(),
      isReferenceDocument: docDetails.isReferenceDocument,
      lastChange: docDetails.lastChange,
      documentId: docDetails.docId,
      signNowDocumentId: docDetails.signNowDocumentId,
    };
    return docObj;
  };

  return (
    <React.Fragment>
      <ViewDocumentDialog
        openDocumetDialog={openDocumetDialog}
        setOpenDocumetDialog={setOpenDocumetDialog}
        documentItem={documentItem}
        setDocumentItem={setDocumentItem}
      ></ViewDocumentDialog>
      <SharedWithPopup
        openSharedWithPopup={openSharedWithPopup.open}
        setOpenSharedWithPopup={setOpenSharedWithPopup}
        sharedWithList={openSharedWithPopup.sharedWithList}
      />
      <TableRow onClick={() => setOpen(!open)}>
        <TableCell
          className={clsx(classes.tableHeading, Styles.navyBlueColorForce)}
          style={{ fontSize: 12 }}
        >
          {category} ({documents && documents.length})
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell align="right"></TableCell>
        <TableCell align="right" className={classes.paddingRight10}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            className={Styles.navyBlueColor}
          >
            {open ? (
              <KeyboardArrowUpIcon className={Styles.navyBlueColorForce} />
            ) : (
              <KeyboardArrowDownIcon className={Styles.navyBlueColorForce} />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      {documents && documents.length ? (
        <TableRow>
          <HBDocTableCell
            classes={classes}
            categoryId={categoryId}
            documents={documents}
            order={order}
            orderBy={orderBy}
            downLoadDocument={downLoadDocument}
            setOpenDeleteDocumentDialog={setOpenDeleteDocumentDialog}
            setOpenMoveDocumentDialog={setOpenMoveDocumentDialog}
            setOpenArchiveDocumentDialog={setOpenArchiveDocumentDialog}
            setOpenRenameDocumentDialog={setOpenRenameDocumentDialog}
            getSharedWithTooltip={getSharedWithTooltip}
            setOpenShareDocumentDialog={setOpenShareDocumentDialog}
            handleCheckboxChange={handleCheckboxChange}
            downloadMultipleDocument={downloadMultipleDocument}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            setSelectedRow={setSelectedRow}
            selelctedRow={selelctedRow}
            handleInnerClose={handleInnerClose}
            openInnerMenu={openInnerMenu}
            duplicateDocument={duplicateDocument}
            handleInnerListKeyDown={handleInnerListKeyDown}
            setDocumentItem={setDocumentItem}
            setOpenDocumetDialog={setOpenDocumetDialog}
            getUploadedDocuments={getUploadedDocuments}
            setOpenSharedWithPopup={setOpenSharedWithPopup}
            innerAnchorRef={innerAnchorRef}
            setInnerAnchorRef={setInnerAnchorRef}
            handleInnerToggle={handleInnerToggle}
            open={open}
            setOpenSignDocumentDialog={setOpenSignDocumentDialog}
            setSignDocumentItem={setSignDocumentItem}
            documentTabName={documentTabName}
          />
        </TableRow>
      ) : null}
    </React.Fragment>
  );
}
