import {
  Avatar,
  Card,
  Grid,
  Typography,
  CardContent,
  Divider,
  CardActions,
  ButtonBase,
  Button,
} from "@material-ui/core";
import React, { useState } from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import Styles from "../../../Styles.module.css";
import MoreOptionsOnClientInfo from "./MoreOptionsOnClientInfo";
import clsx from "clsx";
import { useHistory } from "react-router";
import { useRouteMatch, Link } from "react-router-dom";
import * as moment from "moment";
import ClientStatus from "./ClientStatus";
import ContactNumber from "../../HomeBuyer/SPManagement/ContactNumber";
import { isMobileDevice, viewClientDetails } from "../../../utils/AppUtils";
import RentalDetailsPopup from "../../common/RentalDetailsPopup";

export default function ActiveClientInfoCard({ data }) {
  const history = useHistory();
  let { path } = useRouteMatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [openRentalDetailsPopup, setOpenRentalDetailsPopup] = useState(false);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const showMoreInfoCondition = isMobileDevice() && showMoreInfo;

  return (
    <Card
      elevation={0}
      style={{ border: !isMobileDevice() && "1px solid #0000001F" }}
    >
      <RentalDetailsPopup
        openRentalDetailsPopup={openRentalDetailsPopup}
        setOpenRentalDetailsPopup={setOpenRentalDetailsPopup}
        data={data}
      />
      <MoreOptionsOnClientInfo
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        showProfileUpdateOption={true}
        showScheduleAppointmentOption={true}
        showSendMessageOption={true}
        showLiveMeetingOption={true}
        email={data.hbDetails.email}
        toUserId={data.hbDetails.userId}
        serviceRequestId={data.serviceRequestId}
      />
      <CardContent style={{ padding: "16px 0px" }}>
        <Grid
          container
          style={{
            padding: !isMobileDevice() && "0px 16px",
            cursor: data.hbDetails.userId ? "pointer" : "default",
          }}
          onClick={() => {
            // viewClientDetails(history, path, data);
            if (data.userId) {
              history.push({
                pathname: `${path}/details/${data.userId}`,
                search: `?serviceRequestId=${data.serviceRequestId}`,
                state: {
                  assignClient: data.assignClient,
                  assignedUsers: data.assignedUsers,
                  serviceRequestId: data.serviceRequestId,
                },
              });
            }
          }}
        >
          <Grid item xs={3}>
            <Avatar
              style={{ height: 76, width: 76, borderRadius: 4 }}
              variant="square"
              src={data.avatarUrl}
            >
              {(data.name || "").charAt(0)}
            </Avatar>
          </Grid>
          <Grid container item xs={9} style={{ paddingLeft: 4 }}>
            <Grid container item xs={12}>
              <Grid item xs={11}>
                <Typography
                  className={Styles.navyBlueColor}
                  variant="body1"
                  style={{ fontWeight: 500 }}
                >
                  {data.name}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <ButtonBase disabled={!data.hbDetails.userId}>
                  <MoreHorizIcon
                    className={Styles.navyBlueColor}
                    onClick={handleClick}
                  />
                </ButtonBase>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={1}>
                <EmailIcon className={Styles.navyBlueColor} />
              </Grid>
              <Grid item xs={11} style={{ paddingLeft: 10 }}>
                <Typography
                  variant="body1"
                  className={clsx(Styles.navyBlueColor, Styles.sqEllipsis)}
                >
                  {data.email}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={1}>
                <CallIcon className={Styles.navyBlueColor} />
              </Grid>
              <Grid item xs={11} style={{ paddingLeft: 10 }}>
                <ContactNumber contactNumber={data.contactNumber} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {(!isMobileDevice() || showMoreInfoCondition) && (
          <React.Fragment>
            <Divider style={{ margin: "16px 0" }} />
            <Grid container style={{ padding: "0px 16px" }}>
              <Grid container item>
                <Grid item xs={2}>
                  <Typography
                    className={Styles.navyBlueColor}
                    variant="body1"
                    style={{ fontWeight: 500 }}
                  >
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <ClientStatus clientStatus={data.hbJourneyStatus} />
                </Grid>
              </Grid>
              <Grid container item style={{ marginTop: 10 }}>
                <Grid item>
                  <Typography
                    className={Styles.navyBlueColor}
                    variant="body1"
                    style={{ fontWeight: 500 }}
                  >
                    Closing Date:
                  </Typography>
                </Grid>
                <Grid item style={{ marginLeft: 5, marginTop: 2 }}>
                  <Typography
                    className={clsx(Styles.navyBlueColor, Styles.font14)}
                  >
                    {data.closingDate
                      ? moment(data.closingDate).format("MMM DD, YYYY")
                      : "N/A"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Divider style={{ margin: 16 }} />
            <Grid container item xs={12} style={{ padding: "0px 16px" }}>
              <Grid item xs={12}>
                <Typography
                  className={Styles.navyBlueColor}
                  variant="body1"
                  style={{ fontWeight: 500 }}
                >
                  Active Service Providers
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                spacing={1}
                style={{
                  marginTop: 5,
                  flexWrap: "nowrap",
                  overflowX: "auto",
                  minHeight:
                    (!data.broadCastServiceRequestDto ||
                      !data.broadCastServiceRequestDto
                        .rentalForNewComersDetails) &&
                    141,
                }}
              >
                {data.activeServiceProviders &&
                data.activeServiceProviders.length ? (
                  data.activeServiceProviders.map((activeSp, index) => {
                    const storeFrontContent = JSON.parse(
                      activeSp.storeFrontContent
                    );
                    return (
                      <Grid
                        item
                        xs={2}
                        key={index}
                        style={{
                          height: data.activeServiceProviders.length < 6 && 82,
                        }}
                      >
                        <React.Fragment>
                          <Link
                            to={{
                              pathname:
                                window.location.origin +
                                `/storefront/${activeSp.storeFrontRouteName}`,
                            }}
                            target="_blank"
                          >
                            <img
                              alt={storeFrontContent.storefrontName}
                              src={
                                storeFrontContent.displayPicture ||
                                "/images/souqh_logo.svg"
                              }
                              style={{
                                height: 50,
                                border: "1px solid #0E1C4E1F",
                                borderRadius: 4,
                                cursor: "pointer",
                              }}
                            />
                          </Link>
                        </React.Fragment>
                      </Grid>
                    );
                  })
                ) : (
                  //For constant card size: Show empty space if active providers are not available
                  <Grid item xs={12} style={{ height: 82, marginLeft: 10 }}>
                    <Typography
                      className={Styles.navyBlueColor}
                      variant="body1"
                    >
                      No data available
                    </Typography>
                  </Grid>
                )}
              </Grid>
              {data.broadCastServiceRequestDto &&
                data.broadCastServiceRequestDto.rentalForNewComersDetails && (
                  // <Divider fullWidth style={{ margin: "15px 0px" }} />
                  <Divider
                    style={{ width: "100%", marginTop: 16, marginBottom: 16 }}
                  />
                )}
              {data.broadCastServiceRequestDto &&
                data.broadCastServiceRequestDto.rentalForNewComersDetails && (
                  <Grid container item xs={12}>
                    <Button
                      className={Styles.navyBlueColorForce}
                      variant="outlined"
                      onClick={() => setOpenRentalDetailsPopup(true)}
                      style={{ textTransform: "capitalize" }}
                    >
                      Show Rental Details
                    </Button>
                  </Grid>
                )}
            </Grid>
          </React.Fragment>
        )}
      </CardContent>
      {!isMobileDevice() && (
        <React.Fragment>
          <Divider
            style={{
              margin:
                data.broadCastServiceRequestDto &&
                data.broadCastServiceRequestDto.rentalForNewComersDetails
                  ? "4px 0px 5px 0px"
                  : "15px 0px 5px 0px",
            }}
          />
          <CardActions disableSpacing>
            <Grid container item xs={12} justifyContent="flex-end">
              <ButtonBase
                onClick={() => {
                  // viewClientDetails(history, path, data);
                  if (data.userId) {
                    history.push({
                      pathname: `${path}/details/${data.userId}`,
                      search: `?serviceRequestId=${data.serviceRequestId}`,
                      state: {
                        assignClient: data.assignClient,
                        assignedUsers: data.assignedUsers,
                        serviceRequestId: data.serviceRequestId,
                      },
                    });
                  }
                }}
              >
                <Typography
                  style={{ paddingRight: 10 }}
                  className={clsx(Styles.navyBlueColor, Styles.font14)}
                >
                  View Details
                </Typography>
              </ButtonBase>
            </Grid>
          </CardActions>
        </React.Fragment>
      )}
      {isMobileDevice() && (
        <Grid container item xs={12} justifyContent="flex-end">
          <Button
            variant="text"
            className={Styles.primaryTxtAccentForce}
            style={{ textTransform: "capitalize", marginTop: -28 }}
            onClick={(event) => {
              event.stopPropagation();
              setShowMoreInfo(!showMoreInfo);
            }}
          >
            {showMoreInfo ? "Show less" : "Show more"}
          </Button>
        </Grid>
      )}
    </Card>
  );
}
