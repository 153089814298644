import { useState, useEffect, useRef } from "react";
import { cloneDeep } from "lodash";
import { useAuthUser } from "../useLogin";
import { useDispatch, useSelector } from "react-redux";
import { useActions } from "../useActions";
import {
  EMAIL_PATTERN,
  MESSAGE_GROUP_NAME_PREFIX,
} from "../../web/src/components/ServiceProvider/serviceProviderConstants";
import { Auth } from "@aws-amplify/auth";
import * as AWS from "aws-sdk";
import {
  RESET_ALL_MESSAGING_DATA,
  RESET_LOADED_MESSAGE_DATA,
  SET_All_USER_LIST,
  SET_CHANNEL_MEMBERSHIP_LIST,
  SET_CHANNEL_MESSAGE_TOKEN,
  SET_CHIME_AUTHENTICATION_DATA,
  SET_CHIME_CHANNEL_LIST,
  SET_CHIME_SESSION,
  SET_CHIME_SESSION_STARTED,
  SET_SELECTED_CHANNEL_DATA,
  SET_CURRENT_CHANNEL_MESSAGES,
  SET_LOADED_CHANNEL_DATA,
  SET_MESSAGE_ATTACHMENT,
  SET_SIGN_IN_PROGRESS,
  SET_UNREAD_CHANNELS,
  SET_UNREAD_MESSAGES_FROM_USERS,
  SET_CURRENT_UI_PATH,
} from "../../souqh-redux/reducers/actionConstants";
import { awsChimeConfigs } from "../../web/src/chimeConfig";
import { useChimeApi } from "./useChimeApi";
import { v4 as uuid4 } from "uuid";
import {
  ConsoleLogger,
  DefaultMessagingSession,
  LogLevel,
  MessagingSessionConfiguration,
} from "amazon-chime-sdk-js";
import ChimeSingletone from "./ChimeSingletone";
import {
  deriveLastMessageTimestamp,
  getChannelNameRegx,
  getDisplayGroupName,
  isGroupChannel,
  mergeArrayOfObjects,
  sortArrayNumberColumn,
  userChannelMap,
} from "../../web/src/utils/CommonUtils";
import { useAttachmentService } from "./useAttachmentService";
import { useApiClient } from "../useApiClient";
import { color_error } from "../toasterColors";

const actionCreator = {
  setChimeAuthenticationData: (payload) => ({
    type: SET_CHIME_AUTHENTICATION_DATA,
    payload,
  }),
  setSessionStarted: (flag) => ({
    type: SET_CHIME_SESSION_STARTED,
    payload: { sessionStarted: flag },
  }),
  setSignInProgress: (flag) => ({
    type: SET_SIGN_IN_PROGRESS,
    payload: { signInProgress: flag },
  }),
  setSelectedChannelData: (selectedChannelData) => ({
    type: SET_SELECTED_CHANNEL_DATA,
    payload: { selectedChannelData },
  }),
  setCurrentChannelMessages: (currentChannelMessages) => ({
    type: SET_CURRENT_CHANNEL_MESSAGES,
    payload: { currentChannelMessages },
  }),
  setChannelList: (channelList) => ({
    type: SET_CHIME_CHANNEL_LIST,
    payload: { channelList },
  }),
  resetAllMessagingData: () => ({
    type: RESET_ALL_MESSAGING_DATA,
    payload: {},
  }),
  setUnreadChannels: (unreadChannels) => ({
    type: SET_UNREAD_CHANNELS,
    payload: { unreadChannels },
  }),
  setChannelMembershipList: (channelMembershipList) => ({
    type: SET_CHANNEL_MEMBERSHIP_LIST,
    payload: { channelMembershipList },
  }),
  setAllUserList: (allUsersList) => ({
    type: SET_All_USER_LIST,
    payload: { allUsersList },
  }),
  setUnReadMessagesFromUsers: (unReadMessagesFromUsers) => ({
    type: SET_UNREAD_MESSAGES_FROM_USERS,
    payload: { unReadMessagesFromUsers },
  }),
  setChannelMessageToken: (channelMessageToken) => ({
    type: SET_CHANNEL_MESSAGE_TOKEN,
    payload: { channelMessageToken },
  }),
  setMessageAttachment: (messageAttachment) => ({
    type: SET_MESSAGE_ATTACHMENT,
    payload: { messageAttachment },
  }),
  resetLoadedMessageData: () => ({
    type: RESET_LOADED_MESSAGE_DATA,
    payload: {},
  }),
  setLoadedChannelData: (loadedChannelData) => ({
    type: SET_LOADED_CHANNEL_DATA,
    payload: { loadedChannelData },
  }),
  setChimeSession: (session) => ({
    type: SET_CHIME_SESSION,
    payload: { session },
  }),
  setCurrentUIPath: (currentUIPath) => ({
    type: SET_CURRENT_UI_PATH,
    payload: { currentUIPath },
  }),
};
export const useMessaging = (originFlag) => {
  const actions = useActions(actionCreator);
  const souqhUserInfo = useAuthUser();
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();

  useEffect(() => {
    // autheniticate user to chime server
    if (originFlag) {
      if (souqhUserInfo) {
        const code = (souqhUserInfo.email || "").trim();
        const pass = code.split("").reverse().join("") + "S0";
        signInUserToChime(souqhUserInfo.email, pass);
      } else {
        signOutUserFromChime();
      }
    }
  }, [souqhUserInfo]);

  const {
    getMessagingSessionEndpoint,
    createMemberArn,
    describeChannel,
    deleteChannel,
    listChannelMessages,
    createChannelMembership,
    createChannel,
    sendChannelMessage,
    describeChannelMembership,
    listChannelMemberships,
    listChannelMembershipsForAppInstanceUser,
  } = useChimeApi();

  const { uploadAttachment, downloadAttachment, deleteAttachment } =
    useAttachmentService();
  const messageUpdateCallbacks = ChimeSingletone.messageUpdateCallbacks;
  const leftPanelRef = useRef(null);
  const messagePanelRef = useRef(null);
  const messageTextBoxRef = useRef(null);
  const resetMessageUpdateCallbacks =
    ChimeSingletone.resetMessageUpdateCallbacks;
  const [socketMessage, setSocketMessage] = useState(null);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const addToUpdateCallBack = ChimeSingletone.addToUpdateCallBack;
  const messagingSession = ChimeSingletone.messagingSession;
  const removeFromUpdateCallBack = ChimeSingletone.removeFromUpdateCallBack;
  const [searchUserText, setSearchUserText] = useState("");
  const [openCreateNewGroupChat, setOpenCreateNewGroupChat] = useState(false);
  const [openBroadCastMessageDialog, setOpenBroadCastMessageDialog] =
    useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [broadcastMessage, setBroadcastMessage] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [queryEmailAddress, setQueryEmailAddress] = useState("");
  const [hasMessageContent, setHasMessageContent] = useState(false);
  let itemRefs = [];
  const [openChannelManually, setOpenChannelManually] = useState(null);
  const [messageTextBox, setMessageTextBox] = useState("");
  const [selectedChannelMembersList, setSelectedChannelMembersList] = useState(
    []
  );
  const [openGroupMembersDialog, setOpenGroupMembersDialog] = useState(false);
  const [addedWaitForUnread, setAddedWaitForUnread] = useState(false);
  const [waitedUnreadData, setWaitedUnreadData] = useState(null);
  const signInProgress = useSelector((state) => state.messaging.signInProgress);
  const member = useSelector((state) => state.messaging.member);
  const isAuthenticated = useSelector(
    (state) => state.messaging.isAuthenticated
  );
  const currentUIPath = useSelector((state) => state.messaging.currentUIPath);

  const unReadMessagesFromUsers = useSelector(
    (state) => state.messaging.unReadMessagesFromUsers
  );
  const authenticationFailed = useSelector(
    (state) => state.messaging.authenticationFailed
  );
  const channelList = useSelector((state) => {
    return state.messaging.channelList;
  });
  const selectedChannelData = useSelector(
    (state) => state.messaging.selectedChannelData
  );
  const currentChannelMessages = useSelector(
    (state) => state.messaging.currentChannelMessages
  );
  const sessionStarted = useSelector((state) => state.messaging.sessionStarted);
  const unreadChannels = useSelector((state) => state.messaging.unreadChannels);
  const channelMembershipList = useSelector(
    (state) => state.messaging.channelMembershipList
  );
  const allUsersList = useSelector((state) => state.messaging.allUsersList);

  const channelMessageToken = useSelector(
    (state) => state.messaging.channelMessageToken
  );
  const messageAttachment = useSelector(
    (state) => state.messaging.messageAttachment
  );

  const loadedChannelData = useSelector(
    (state) => state.messaging.loadedChannelData
  );

  const session = useSelector((state) => state.messaging.session);

  const signInUserToChime = async (username, password) => {
    // send login request to chime server using logged in user details
    if (signInProgress || isAuthenticated) {
      return;
    }
    actions.setSessionStarted(false);
    if (username && password) {
      actions.setSignInProgress(true);
      try {
        Auth.signIn({ username, password })
          .then(async () => {
            const data = await Auth.currentUserInfo();
            setAuthenticatedUser(data);
          })
          .catch((err) => {
            dispatch({ type: "SET_LOADING", value: false });
            dispatch({
              type: "SET_SERVER_ERROR",
              error: { message: "Error while chat authentication" },
            });
            actions.setChimeAuthenticationData({
              isAuthenticated: false,
              authenticationFailed: true,
              awsCredentials: null,
              member: null,
              signInProgress: false,
            });
          });
      } catch (e) {
        dispatch({ type: "SET_LOADING", value: false });
        // dispatch({
        //   type: "SET_SERVER_ERROR",
        //   error: { message: "Error while chat authentication" },
        // });
        actions.setChimeAuthenticationData({
          isAuthenticated: false,
          authenticationFailed: true,
          awsCredentials: null,
          member: null,
          signInProgress: false,
        });
      }
    } else {
      dispatch({ type: "SET_LOADING", value: false });
    }
  };

  const setAuthenticatedUser = async (currUser) => {
    // Update hook state using data from logged in users data
    if (currUser) {
      const creds = await Auth.currentCredentials();
      const essentialCreds = await Auth.essentialCredentials(creds);
      const awsCredentials = essentialCreds;
      const member = {
        Username: currUser.username,
        userId: currUser.id,
      };

      actions.setChimeAuthenticationData({
        isAuthenticated: true,
        authenticationFailed: false,
        awsCredentials,
        member,
        signInProgress: false,
      });
      connectToChime(awsCredentials, member);
    } else {
      dispatch({ type: "SET_LOADING", value: false });
      dispatch({
        type: "SET_SERVER_ERROR",
        error: { message: "Failed to load user data" },
      });
      actions.setChimeAuthenticationData({
        isAuthenticated: false,
        authenticationFailed: true,
        awsCredentials: null,
        member: null,
        signInProgress: false,
      });
    }
  };

  const connectToChime = (awsCredentials, member) => {
    // configure parameters to messaging connection
    AWS.config.region = awsChimeConfigs.region;
    AWS.config.credentials = awsCredentials;
    AWS.config.apiVersions = {
      chime: "2018-05-01",
    };
    setMeessaginEndpoint(member);
  };

  const setMeessaginEndpoint = (member) => {
    // create messsaging endpoint which creates messaging session
    getMessagingSessionEndpoint()
      .then((response) => {
        const endpoint = response.Endpoint.Url;
        const sessionConfig = new MessagingSessionConfiguration(
          createMemberArn(member.userId),
          uuid4(),
          endpoint,
          new AWS.Chime(),
          AWS
        );
        const tempSession = new DefaultMessagingSession(
          sessionConfig,
          new ConsoleLogger("MN Chat", LogLevel.OFF)
        );
        actions.setChimeSession(tempSession);
        tempSession.addObserver(ChimeSingletone.messageObserver);
        tempSession.start();
        actions.setSessionStarted(true);
      })
      .catch((err) => {
        if (typeof err === "AccessDeniedException") {
          console.log("got error match");
        }
        dispatch({
          type: "SET_SERVER_ERROR",
          error: { message: "Error while creating endpoint" },
        });
      })
      .finally(() => {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const subscribeToMessageUpdate = (callback) => {
    // add listner callback to socket update
    addToUpdateCallBack && addToUpdateCallBack(callback);
  };

  const unSubscribeToMessageUpdate = (callback) => {
    // remove listner callback to socket update
    removeFromUpdateCallBack(callback);
  };

  const loadChannelsData = async (flag) => {
    // load users and channels membership data from Souqh backend
    dispatch({ type: "SET_LOADING", value: true });
    if (flag) {
      actions.setLoadedChannelData(false);
    }
    const url = `messaging/members-channels/${souqhUserInfo.email}`;

    try {
      const response = await apiClient.get(url);

      if (response && response.data.status === 200) {
        filterVerifiedChannels(response.data.result);
        dispatch({ type: "SET_LOADING", value: false });
        return Promise.resolve(true);
      } else {
        dispatch({
          type: "SET_SERVER_ERROR",
          error: { message: "Error while getting chat user list" },
        });
        dispatch({ type: "SET_LOADING", value: false });
        return Promise.resolve(false);
      }
    } catch (e) {
      dispatch({
        type: "SET_SERVER_ERROR",
        error: { message: "Error while getting user list" },
      });
      dispatch({ type: "SET_LOADING", value: false });
      return Promise.resolve(false);
    }
  };

  const filterVerifiedChannels = (result) => {
    // filter users who has no email or congnitoProfileId
    if (result) {
      const userList = result.chimeUsers
        .map((user) => {
          if (user.cognitoProfileId) {
            return user;
          }
          return false;
        })
        .filter(
          (item) =>
            item &&
            item.cognitoProfileId &&
            item.email &&
            item.email !== souqhUserInfo.email
        );
      actions.setAllUserList(userList);
      parseChannelsAndUsers(
        result.channelMembership.ChannelMemberships,
        userList
      );
    }
  };

  const parseChannelsAndUsers = async (userChannelMemberships, userList) => {
    //  separate out users and membership list
    const tempChannelList = userChannelMemberships.map(
      (channelMembership) => channelMembership.ChannelSummary
    );
    // call method - when we want to delete channels - udeleteUserChannels and pass tempChannelList to it
    const membershipList = mergeArrayOfObjects(
      tempChannelList,
      [],
      "ChannelArn"
    );
    actions.setChannelMembershipList(membershipList);
    setChannelAndUsersData(userList, membershipList);
    actions.setLoadedChannelData(true);
  };

  const setChannelAndUsersData = (userList, membershipList) => {
    // merge users and channel membership data to show on left panel
    const data = userChannelMap(userList, membershipList, member);
    if (data) {
      sortAndSetChannelList(data);
    }
  };

  const fetchChannelMembersList = async (channelArn) => {
    // fetch users added in created channel. (userful for group channel to show participients)
    if (channelArn) {
      try {
        const channels = await listChannelMemberships(
          channelArn,
          member.userId
        );
        mapChannelMembers(channels);
      } catch (e) {
        setSelectedChannelMembersList([]);
      }
    }
  };

  const mapChannelMembers = (members) => {
    // map user names and profile pic
    if (members) {
      const mappedMembers = members.map((item) => {
        const temp = {
          email: item.Member.Name,
        };
        if (temp.email === souqhUserInfo.email) {
          temp.fullName = "You";
        } else {
          temp.fullName = getUserFullName(temp.email);
        }
        temp.profilePhoto = getUserProfilePic(temp.email);

        return temp;
      });
      setSelectedChannelMembersList(mappedMembers);
    } else {
      setSelectedChannelMembersList([]);
    }
  };

  const sortAndSetChannelList = (tempChannelList) => {
    // sort channel list as per LastMessageTimestamp
    const sortedList = sortArrayNumberColumn(
      tempChannelList,
      "LastMessageTimestamp",
      false
    );
    actions.setChannelList(sortedList);
  };

  const udeleteUserChannels = (userChannels) => {
    // delete channels only for dev purpose
    userChannels
      .filter((el) => !isGroupChannel(el.Name))
      .forEach((item) => {
        deleteChannel(item.ChannelArn, member.userId).then((response) => {});
      });
  };

  const deleteGroupChannels = (allChannels) => {
    // delete group channels only for dev purpose
    allChannels
      .filter((el) => isGroupChannel(el.Name))
      .forEach((item) => {
        deleteChannel(item.ChannelArn, member.userId).then((response) => {});
      });
  };

  const markChannelAsread = (channelArn) => {
    // mark channel as read when opened. (remove unread message and red blink in left panel )
    if (channelArn) {
      const tempUnreadChannelList = cloneDeep(unreadChannels);
      const index = unreadChannels.findIndex((item) => item === channelArn);
      if (index > -1) {
        tempUnreadChannelList.splice(index, 1);
        actions.setUnreadChannels(tempUnreadChannelList);
      }
    }
  };

  const requestServiceMessageFromBackground = async (
    receiverEmail,
    messageText,
    documents
  ) => {
    // send message and documents from service request popup
    if (souqhUserInfo && member && receiverEmail) {
      try {
        const url = `messaging/members-channels/${souqhUserInfo.email}`;
        const response = await apiClient.get(url);
        if (response && response.data.result) {
          const users = response.data.result.chimeUsers;
          const tempChannelList =
            response.data.result.channelMembership.ChannelMemberships.map(
              (channelMembership) => channelMembership.ChannelSummary
            );
          const foundItem = users.find((item) => item.email === receiverEmail);

          if (foundItem) {
            const re1 = getChannelNameRegx(member.Username, foundItem.email);
            const re2 = getChannelNameRegx(foundItem.email, member.Username);

            const latestChannelList = await flattenChannelMemberList();
            const matchedValues = latestChannelList.filter((el) => {
              if (re1.test(el.Name) || re2.test(el.Name)) {
                return el;
              }
            });
            if (matchedValues.length === 0) {
              createChannelFromBackground(foundItem, messageText, documents);
            } else {
              const matchedItem = matchedValues[0];
              if (matchedItem && matchedItem.ChannelArn) {
                sendMessageFromBackground(
                  matchedItem.ChannelArn,
                  messageText,
                  documents
                );
              }
            }
          } else {
            const newMember = await addNewContact(receiverEmail);
            if (newMember && newMember.email) {
              const existingChannels = await findInChannelMembershipList(
                newMember
              );
              if (existingChannels && existingChannels.length) {
                const tempChannel = existingChannels[0];
                if (tempChannel && tempChannel.ChannelArn) {
                  sendMessageFromBackground(
                    tempChannel.ChannelArn,
                    messageText,
                    documents
                  );
                }
              } else {
                createChannelFromBackground(newMember, messageText, documents);
              }
            }
          }
        }
      } catch (e) {
        console.error(e);
        dispatch({
          type: "SET_SERVER_ERROR",
          error: { message: "Error while sending message list" },
        });
      }
    }
  };

  const findInChannelMembershipList = async (newItem) => {
    const re1 = getChannelNameRegx(member.Username, newItem.email);
    const re2 = getChannelNameRegx(newItem.email, member.Username);

    const latestChannelList = await flattenChannelMemberList();
    const matchedValues = latestChannelList.filter((el) => {
      if (re1.test(el.Name) || re2.test(el.Name)) {
        return el;
      }
    });
    return Promise.resolve(matchedValues);
  };

  const addNewContact = async (email) => {
    // add user to contact list at Souqh backend
    try {
      const body = [
        {
          email,
          userId: souqhUserInfo.userId,
        },
      ];
      const url = "/contacts/add-contact";
      const response = await apiClient.post(url, body);

      if (response && response.data.status === 200 && response.data.result) {
        dispatch({ type: "SET_LOADING", value: false });
        const findItem = (response.data.result || []).find(
          (item) =>
            (item.email || "").toLowerCase() === (email || "").toLowerCase()
        );
        if (findItem) {
          return Promise.resolve(findItem);
        } else {
          return Promise.resolve(false);
        }
      } else {
        dispatch({
          type: "SET_SERVER_ERROR",
          error: { message: "Error while adding contact list" },
        });
        dispatch({ type: "SET_LOADING", value: false });
        return Promise.resolve(false);
      }
    } catch (e) {
      dispatch({
        type: "SET_SERVER_ERROR",
        error: { message: "Error while adding contact list" },
      });
      dispatch({ type: "SET_LOADING", value: false });
      return Promise.resolve(false);
    }
  };

  const addChannelToMembershipList = (newChannel) => {
    // add newly created channel to channel membership list
    if (newChannel && newChannel.ChannelArn) {
      const findItem = channelMembershipList.find(
        (item) => item.ChannelArn === newChannel.ChannelArn
      );
      if (!findItem) {
        const tempMemberShipList = cloneDeep(channelMembershipList);
        actions.setChannelMembershipList(tempMemberShipList);
      }
    }
  };

  const createChannelFromBackground = async (
    foundItem,
    messageText,
    documents
  ) => {
    // create channel when user is on another screen.
    // (specially useful in case of service request popup )
    const newName = member.Username + "-" + foundItem.email;
    const channelArn = await createChannel(
      awsChimeConfigs.appInstanceArn,
      newName,
      "RESTRICTED",
      "PRIVATE",
      member.userId
    );
    if (channelArn) {
      addChannelToMembershipList({
        ChannelArn: channelArn,
        Name: newName,
        LastMessageTimestamp: null,
        Metadata: null,
        Mode: "RESTRICTED",
        Privacy: "PRIVATE",
      });
      const membership = await createChannelMembership(
        channelArn,
        `${awsChimeConfigs.appInstanceArn}/user/${foundItem.cognitoProfileId}`,
        member.userId
      );
      if (membership) {
        sendMessageFromBackground(channelArn, messageText, documents);
      }
    }
  };

  const sendMessageFromBackground = async (
    channelArn,
    messageText,
    documents
  ) => {
    // send message from another screen
    if (!channelArn) {
      return;
    }
    try {
      if (messageText) {
        if ((messageText || "").trim().length < 4000) {
          await sendChannelMessage(channelArn, messageText, member);
        }
      }
      if (documents && documents.length) {
        documents.forEach(async (document) => {
          const response = await uploadAttachment(document);
          const options = {};
          options.Metadata = JSON.stringify({
            attachments: [
              {
                fileKey: response.key,
                name: document.name,
                size: document.size,
                type: document.type,
              },
            ],
          });
          await sendChannelMessage(channelArn, " ", member, options);
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const sendMessage = () => {
    // check message length send message
    setShowEmojiPicker(false);
    const value = messageTextBox ? messageTextBox.trim() : "";
    if (new TextEncoder().encode(value).length > 4000) {
      return;
    }
    if (value || messageAttachment) {
      processMessageForSend(value, selectedChannelData.ChannelArn);
    }
  };

  const processMessageForSend = async (value, channelArn, isFromBroadCast) => {
    // send message
    try {
      if (messageAttachment && !isFromBroadCast) {
        try {
          // attachment message
          setSendingMessage(true);
          const response = await uploadAttachment(messageAttachment.file);
          const options = {};
          const newFileObj = {
            key: response.key,
            ...messageAttachment,
          };
          actions.setMessageAttachment(newFileObj);

          options.Metadata = JSON.stringify({
            attachments: [
              {
                fileKey: response.key,
                name: messageAttachment.name,
                size: messageAttachment.file.size,
                type: messageAttachment.file.type,
              },
            ],
          });
          await sendChannelMessage(channelArn, value || " ", member, options);
          if (!isFromBroadCast) {
            setSendingMessage(false);
            actions.setMessageAttachment(null);
            setMessageTextBox("");
            scrollLeftPanelToTop();
          }
          Promise.resolve(true);
        } catch (err) {
          setSendingMessage(false);
          dispatch({
            type: "SET_SERVER_ERROR",
            error: { message: "Error while uploading attachment" },
          });
          Promise.resolve(false);
          throw new Error(`Failed uploading... ${err}`);
        }
      } else {
        // text message
        setSendingMessage(true);
        await sendChannelMessage(channelArn, value, member);
        setSendingMessage(false);
        if (!isFromBroadCast) {
          setMessageTextBox("");
          actions.setMessageAttachment(null);
          scrollLeftPanelToTop();
        }
        Promise.resolve(true);
      }
    } catch (e) {
      console.error(e);
      setSendingMessage(false);
      Promise.resolve(false);
    }
  };

  const onChannelClick = async (clickedChannel, messageContent) => {
    // action to be performed when clicked on left panel list item for sending message
    setShowEmojiPicker(false);
    actions.setMessageAttachment(null);
    setOpenGroupMembersDialog(false);
    setSendingMessage(false);
    const tempChannelList = cloneDeep(channelList);
    let channelItem = null;
    if (clickedChannel.Username) {
      channelItem = tempChannelList.find(
        (item) => item.Username === clickedChannel.Username
      );
    } else {
      channelItem = tempChannelList.find(
        (item) => item.Name === clickedChannel.Name
      );
    }
    if (channelItem && channelItem.ChannelArn) {
      if (
        selectedChannelData &&
        selectedChannelData.ChannelArn === channelItem.ChannelArn
      ) {
        return;
      }
      channelItem.unreadContent = null;
      actions.setSelectedChannelData(channelItem);
      fetchChannelMembersList(channelItem.ChannelArn);
      sortAndSetChannelList(tempChannelList);
      loadChannelMessages(channelItem);
      setMessageTextBox(messageContent || "");
    } else {
      const user = tempChannelList.find(
        (item) => item.Username === clickedChannel.Username
      );
      if (user) {
        createNewChannel(user, "RESTRICTED", "PRIVATE", messageContent);
      }
    }
  };

  const onSendBroadcastMessage = async (message, selectedUsers, callback, isBSR, matchingSPEmails, documents) => {
    if (isBSR) {
      console.log("FOUND==")
      const url = `messaging/members-channels/${souqhUserInfo.email}`;
      const response = await apiClient.get(url);
      if (response && response.data.result) {
        const users = response.data.result.chimeUsers;
        const tempChannelList =
          response.data.result.channelMembership.ChannelMemberships.map(
            (channelMembership) => channelMembership.ChannelSummary
          );

        if (matchingSPEmails && matchingSPEmails.length) {
          for (i = 0; i < matchingSPEmails.length; i++) {
            const foundItem = users.find((item) => item.email === matchingSPEmails[i]);

            if (foundItem) {
              console.log("FOUND", foundItem, "---", i)
              const re1 = getChannelNameRegx(member.Username, foundItem.email);
              const re2 = getChannelNameRegx(foundItem.email, member.Username);

              const latestChannelList = await flattenChannelMemberList();
              console.log("FOUND 2", latestChannelList, "---", i)
              const matchedValues = latestChannelList.filter((el) => {
                if (re1.test(el.Name) || re2.test(el.Name)) {
                  return el;
                }
              });
              console.log("FOUND 3", matchedValues, "---", i)
              if (matchedValues.length === 0) {
                await createChannelFromBackground(foundItem, message,
                  documents
                  // []
                );
              } else {
                const matchedItem = matchedValues[0];
                if (matchedItem && matchedItem.ChannelArn) {
                  await sendMessageFromBackground(
                    matchedItem.ChannelArn,
                    message,
                    documents
                    // []
                  );
                }
              }
            } else {
              console.log(" NOT FOUND", i)
              const newMember = await addNewContact(matchingSPEmails[i]);
              console.log(" NOT FOUND 2", newMember)
              if (newMember && newMember.email) {
                const existingChannels = await findInChannelMembershipList(
                  newMember
                );
                if (existingChannels && existingChannels.length) {
                  const tempChannel = existingChannels[0];
                  if (tempChannel && tempChannel.ChannelArn) {
                    await sendMessageFromBackground(
                      tempChannel.ChannelArn,
                      message,
                      documents
              // []

                    );
                  }
                } else {
                  await createChannelFromBackground(newMember, message,
                    documents
                    // []
                  );
                }
              }
            }
            callback && callback();
          }
        }
      }
    } else {
    // broadcast message to multiple users
    if ((message, selectedUsers.length > 1)) {
      const existingChannelUsers = selectedUsers.filter(
        (item) => item.ChannelArn
      );
      const newChannelUsers = selectedUsers.filter((item) => !item.ChannelArn);
      let i = 0;
      if (existingChannelUsers.length) {
        dispatch({ type: "SET_LOADING", value: true });
        for (i = 0; i < existingChannelUsers.length; i++) {
          const channel = existingChannelUsers[i];
          processMessageForSend(message, channel.ChannelArn);
        }
        if (!newChannelUsers.length) {
          callback && callback();
          dispatch({ type: "SET_LOADING", value: false });
        }
      }
      if (newChannelUsers.length) {
        const promiseArray = [];
        callback && callback();
        setBroadcastMessage(false);
        if (!existingChannelUsers.length) {
          dispatch({ type: "SET_LOADING", value: true });
        }
        const channelsArray = [];
        for (i = 0; i < newChannelUsers.length; i++) {
          const channel = newChannelUsers[i];
          const channelData = await createChannelForBroadcast(
            channel,
            "RESTRICTED",
            "PRIVATE"
          );
          if (channelData && channelData.ChannelArn) {
            channelsArray.push(channelData.ChannelArn);
          }
        }
        for (let j = 0; j < channelsArray.length; j++) {
          await processMessageForSend(message, channelsArray[j]);
        }
        setBroadcastMessage(true);
        dispatch({ type: "SET_LOADING", value: false });
      }
    }
    }
  };

  const createChannelForBroadcast = async (user, mode, privacy) => {
    // create channel in broadcast if channel is not created with that user
    const newName = member.Username + "-" + user.Username;
    const re1 = getChannelNameRegx(member.Username, user.Username);
    const re2 = getChannelNameRegx(user.Username, member.Username);

    const latestChannelList = await flattenChannelMemberList();
    const matchedValues = latestChannelList.filter((el) => {
      if (re1.test(el.Name) || re2.test(el.Name)) {
        return el;
      }
    });

    if (matchedValues.length === 0) {
      if (!newName) {
        return Promise.resolve(false);
      } else {
        let channelArn = null;
        try {
          channelArn = await createChannel(
            awsChimeConfigs.appInstanceArn,
            newName,
            mode,
            privacy,
            member.userId
          );
        } catch (e) {
          dispatch({ type: "SET_LOADING", value: false });
          return Promise.resolve(false);
        }

        if (channelArn) {
          addChannelToMembershipList({
            ChannelArn: channelArn,
            Name: newName,
            LastMessageTimestamp: null,
            Metadata: null,
            Mode: "RESTRICTED",
            Privacy: "PRIVATE",
          });
          let channel = {};
          channel.ChannelArn = channelArn;
          channel.Name = newName;
          channel.fullName = user.fullName || "";
          channel.profilePhoto = user.profilePhoto;
          channel.isGroup = false;
          channel.LastMessageTimestamp = user.LastMessageTimestamp;
          channel.Username = user.Username;
          channel.cognitoProfileId = user.cognitoProfileId;
          channel.Value = user.cognitoProfileId;
          channel.LastMessageTimestamp = user.LastMessageTimestamp;
          return await addMemberForBroadcast(user, channel);
        } else {
          dispatch({ type: "SET_LOADING", value: false });
          return Promise.resolve(false);
        }
      }
    } else {
      return Promise.resolve(false);
    }
  };

  const addMemberForBroadcast = async (newUser, channel) => {
    // add member to channel in broadcast flow
    try {
      const membership = await createChannelMembership(
        channel.ChannelArn,
        `${awsChimeConfigs.appInstanceArn}/user/${newUser.Value}`,
        member.userId
      );
      if (membership) {
        const tempChannelList = cloneDeep(channelList);
        const index = tempChannelList.findIndex(
          (item) => item.Username === channel.Username
        );
        if (index > -1) {
          const tempChannel = tempChannelList[index];
          tempChannel.ChannelArn = channel.ChannelArn;
          tempChannel.Name = channel.Name;
          tempChannel.Username = channel.Username;
          tempChannel.Value = channel.Value;
          tempChannel.cognitoProfileId = channel.cognitoProfileId;
          tempChannel.profilePhoto = channel.profilePhoto;
          tempChannel.isGroup = false;
          tempChannel.fullName = channel.fullName || "";
          tempChannel.LastMessageTimestamp = channel.LastMessageTimestamp;
        }
        sortAndSetChannelList(tempChannelList);
        return Promise.resolve(channel);
      } else {
        return Promise.resolve(false);
      }
    } catch (e) {
      dispatch({ type: "SET_LOADING", value: false });
      return Promise.resolve(false);
    }
  };

  const updateListAfterBroadcast = async () => {
    // update left panel channel list when broadcast message flow is completed
    loadChannelsData(false);
    dispatch({ type: "SET_LOADING", value: false });
  };

  const loadChannelMessages = (channelItem) => {
    // load messages for the selected/opened channel
    dispatch({ type: "SET_LOADING", value: true });
    channelIdChangeHandler(channelItem.ChannelArn)
      .then((response) => {
        actions.setChannelMessageToken(response.NextToken);
        if (response && response.Messages) {
          actions.setUnreadChannels(
            cloneDeep(
              unreadChannels.filter((c) => c !== channelItem.ChannelArn)
            )
          );
          processAndSetChannelMessages(response.Messages);
          setScrollToChatPanelBottom();
        }
        removeunreadMessagesFromUsers(channelItem.ChannelArn);
        setTimeout(() => {
          setSrollToActiveChannel();
        });
      })
      .finally(() => {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const processAndSetChannelMessages = (messages) => {
    // set new message data
    if (messages) {
      const mappedChannelMessages = mapChannelMessages(messages);
      actions.setCurrentChannelMessages(mappedChannelMessages);
    }
  };

  const setUnreadMessagesLocal = (record) => {
    // set unread messages object, used to show message notification
    if (record && record.ChannelArn) {
      const tempUnreadMessages = cloneDeep(unReadMessagesFromUsers);
      tempUnreadMessages[record.ChannelArn] = record;
      actions.setUnReadMessagesFromUsers(tempUnreadMessages);
    }
  };

  const removeunreadMessagesFromUsers = (channelArn) => {
    // remove message record from unread messages object, when channel item clicked
    if (channelArn) {
      const tempUnreadMessages = cloneDeep(unReadMessagesFromUsers);
      delete tempUnreadMessages[channelArn];
      actions.setUnReadMessagesFromUsers(tempUnreadMessages);
    }
  };

  const createNewChannel = async (user, mode, privacy, messageContent) => {
    // create new channel for the user when clicked in left panel
    const newName = member.Username + "-" + user.Username;
    const re1 = getChannelNameRegx(member.Username, user.Username);
    const re2 = getChannelNameRegx(user.Username, member.Username);

    const latestChannelList = await flattenChannelMemberList();
    const matchedValues = latestChannelList.filter((el) => {
      if (re1.test(el.Name) || re2.test(el.Name)) {
        return el;
      }
    });

    if (matchedValues.length === 0) {
      if (!newName) {
        return Promise.resolve(false);
      } else {
        let channelArn = null;
        try {
          dispatch({ type: "SET_LOADING", value: true });
          channelArn = await createChannel(
            awsChimeConfigs.appInstanceArn,
            newName,
            mode,
            privacy,
            member.userId
          );
        } catch (e) {
          dispatch({ type: "SET_LOADING", value: false });
          return Promise.resolve(false);
        }

        if (channelArn) {
          addChannelToMembershipList({
            ChannelArn: channelArn,
            Name: newName,
            LastMessageTimestamp: null,
            Metadata: null,
            Mode: "RESTRICTED",
            Privacy: "PRIVATE",
          });
          let channel = {};
          channel.ChannelArn = channelArn;
          channel.Name = newName;
          channel.fullName = user.fullName || "";
          channel.profilePhoto = user.profilePhoto;
          channel.isGroup = false;
          channel.LastMessageTimestamp = user.LastMessageTimestamp;
          channel.Username = user.Username;
          channel.cognitoProfileId = user.cognitoProfileId;
          channel.Value = user.cognitoProfileId;
          channel.LastMessageTimestamp = user.LastMessageTimestamp;
          return await addMember(user, channel, messageContent);
        } else {
          dispatch({ type: "SET_LOADING", value: false });
          return Promise.resolve(false);
        }
      }
    } else {
      return Promise.resolve(false);
    }
  };

  const addMember = async (newUser, channel, messageContent) => {
    // add user to created channel
    try {
      const membership = await createChannelMembership(
        channel.ChannelArn,
        `${awsChimeConfigs.appInstanceArn}/user/${newUser.Value}`,
        member.userId
      );
      if (membership) {
        const tempChannelList = cloneDeep(channelList);
        const index = tempChannelList.findIndex(
          (item) => item.Username === channel.Username
        );
        if (index > -1) {
          const tempChannel = tempChannelList[index];
          tempChannel.ChannelArn = channel.ChannelArn;
          tempChannel.Name = channel.Name;
          tempChannel.Username = channel.Username;
          tempChannel.Value = channel.Value;
          tempChannel.cognitoProfileId = channel.cognitoProfileId;
          tempChannel.profilePhoto = channel.profilePhoto;
          tempChannel.isGroup = false;
          tempChannel.fullName = channel.fullName || "";
          tempChannel.LastMessageTimestamp = channel.LastMessageTimestamp;
        }
        sortAndSetChannelList(tempChannelList);
        actions.setSelectedChannelData(channel);
        loadChannelMessages(channel);
        setTimeout(() => {
          setSrollToActiveChannel();
        });
        setMessageTextBox(messageContent || "");
        dispatch({ type: "SET_LOADING", value: false });
        return Promise.resolve(true);
      } else {
        return Promise.resolve(false);
      }
    } catch (e) {
      dispatch({ type: "SET_LOADING", value: false });
      return Promise.resolve(false);
    }
  };

  const setSrollToActiveChannel = () => {
    // move scroll to selected channel position in left panel
    if (itemRefs && itemRefs.length) {
      if (selectedChannelData && selectedChannelData.email) {
        let temp = null;
        itemRefs.forEach((item) => {
          if (
            item &&
            item.getAttribute("data-userid") === selectedChannelData.email
          ) {
            temp = item;
          }
        });
        if (temp) {
          temp.scrollIntoView();
        }
      }
    }
  };

  const channelIdChangeHandler = async (channelArn) => {
    // chime api call to get messages
    const newMessages = await listChannelMessages(channelArn, member.userId);
    return newMessages;
  };

  const mapChannelMessages = (messages) => {
    // mark flag to messages as per sender & receiver of mesage
    try {
      if (messages && messages.length) {
        return messages.map((item) => {
          item.isIncoming = createMemberArn(member.userId) !== item.Sender.Arn;
          item.senderName = getUserFullName(item.Sender.Name);
          return item;
        });
      }
    } catch (e) {
      console.error(e);
    }
    return [];
  };

  const setUnreadChannlesData = () => {
    // update left panel list if unread message is present
    const tempHash = unReadMessagesFromUsers;
    if (tempHash) {
      Object.keys(tempHash).forEach((key) => {
        if (tempHash[key]) {
          setUnreadChannelMessage(tempHash[key], false);
        }
      });
    }
  };

  const setScrollToChatPanelBottom = () => {
    // set scroll to bottom of right panel, where messages are shown
    setTimeout(() => {
      if (messagePanelRef && messagePanelRef.current) {
        messagePanelRef.current.scrollTop =
          messagePanelRef.current.scrollHeight;
      }
      if (messageTextBoxRef && messageTextBoxRef.current) {
        messageTextBoxRef.current.focus();
      }
    }, 100);
  };

  const processChannelMessage = async (message) => {
    // process message received add update right side panel
    const promise = Promise.resolve(message);
    const newMessage = await promise.then((m) => m);

    let isDuplicate = false;

    currentChannelMessages.forEach((m, i, self) => {
      if ((m.MessageId || m.MessageId) === newMessage.MessageId) {
        isDuplicate = true;
        self[i] = newMessage;
      }
    });

    let newMessages = [...currentChannelMessages];

    if (!isDuplicate) {
      newMessages = [...newMessages, newMessage];
    }

    processAndSetChannelMessages(newMessages);
    // sort channel list as per new message and move left side scroll to top
    if (selectedChannelData) {
      const date = new Date(message.CreatedTimestamp);
      selectedChannelData.LastMessageTimestamp = date.getTime();
      const tempChannelList = cloneDeep(channelList);
      const foundChannel = tempChannelList.find(
        (item) => item.ChannelArn === selectedChannelData.ChannelArn
      );
      if (foundChannel) {
        foundChannel.LastMessageTimestamp = date.getTime();
        sortAndSetChannelList(tempChannelList);
      }

      if (newMessages.length) {
        scrollLeftPanelToTop();
      }
    }
    setScrollToChatPanelBottom();
  };

  const scrollLeftPanelToTop = () => {
    // set scroll to top
    if (leftPanelRef && leftPanelRef.current) {
      leftPanelRef.current.scrollTop = 0;
    }
  };

  const onContentScroll = async (event) => {
    // get new messages when scroll on top of right side panel
    if (messagePanelRef && messagePanelRef.current) {
      if (messagePanelRef.current.scrollTop === 0) {
        if (!channelMessageToken) {
          return;
        }
        const oldMessages = await listChannelMessages(
          selectedChannelData.ChannelArn,
          member.userId,
          channelMessageToken
        );
        const newMessages = [
          ...oldMessages.Messages,
          ...currentChannelMessages,
        ];
        processAndSetChannelMessages(newMessages);
        actions.setChannelMessageToken(oldMessages.NextToken);
      }
    }
  };

  const setUnreadChannelMessage = (record, fromSocket) => {
    // setunread channel message for left side panel
    const tempChannelList = cloneDeep(channelList);
    const tempUnreadChannel = tempChannelList.find(
      (item) => item.ChannelArn === record.ChannelArn
    );
    if (tempUnreadChannel) {
      tempUnreadChannel.unreadContent = record;
      const date = new Date(record.CreatedTimestamp);
      tempUnreadChannel.LastMessageTimestamp = date.getTime();

      sortAndSetChannelList(tempChannelList);
    } else {
      if (fromSocket) {
        setWaitedUnreadData(record);
      }
    }
  };

  const processUnreadWait = () => {
    // update left side panel when broadcast message lsi
    if (waitedUnreadData && addedWaitForUnread) {
      const record = waitedUnreadData;
      const tempChannelList = cloneDeep(channelList);
      const tempUnreadChannel = tempChannelList.find(
        (item) => item.ChannelArn === record.ChannelArn
      );
      if (tempUnreadChannel) {
        tempUnreadChannel.unreadContent = record;
        const date = new Date(record.CreatedTimestamp);
        tempUnreadChannel.LastMessageTimestamp = date.getTime();

        sortAndSetChannelList(tempChannelList);
      }
    }
    setAddedWaitForUnread(false);
    setWaitedUnreadData(null);
  };

  const createNewUserGroup = async (groupName, selectedUsers, callback) => {
    // create new  group chat
    callback && callback();
    return;
    if (groupName && selectedUsers.length) {
      try {
        if (isGroupChannel(groupName)) {
          return;
        }
        const newGroupName =
          MESSAGE_GROUP_NAME_PREFIX + groupName.toLowerCase();
        dispatch({ type: "SET_LOADING", value: true });
        const channelArn = await createChannel(
          awsChimeConfigs.appInstanceArn,
          newGroupName,
          "RESTRICTED",
          "PRIVATE",
          member.userId
        );
        if (channelArn) {
          addChannelToMembershipList({
            ChannelArn: channelArn,
            Name: newGroupName,
            LastMessageTimestamp: null,
            Metadata: null,
            Mode: "RESTRICTED",
            Privacy: "PRIVATE",
          });
          selectedUsers.forEach(async (item) => {
            try {
              const membership = await createChannelMembership(
                channelArn,
                `${awsChimeConfigs.appInstanceArn}/user/${item.Value}`,
                member.userId
              );
            } catch (e) {
              console.error("Error while adding user");
            } finally {
              callback && callback();
              addCreatedGroupChannel(channelArn, newGroupName);
              dispatch({ type: "SET_LOADING", value: false });
            }
          });
        } else {
          dispatch({ type: "SET_LOADING", value: false });
        }
      } catch (e) {
        dispatch({ type: "SET_LOADING", value: false });
      }
    }
  };

  const addCreatedGroupChannel = (channelArn, name) => {
    // add group channel to left side list
    const tempChannel = {};
    tempChannel.ChannelArn = channelArn;
    tempChannel.Name = name;
    tempChannel.Username = name;
    tempChannel.fullName = getDisplayGroupName(name);
    tempChannel.isGroup = true;
    tempChannel.profilePhoto = null;
    tempChannel.cognitoProfileId = null;
    tempChannel.Value = null;
    tempChannel.LastMessageTimestamp = Date.now();
    const tempChannelList = cloneDeep(channelList);
    tempChannelList.unshift(tempChannel);
    sortAndSetChannelList(tempChannelList);
    setOpenChannelManually(tempChannel);
  };

  const openChannelForMessage = async () => {
    // open message window(right side panel) without click.
    // this is required when we click on "Send Message" from another page
    if (queryEmailAddress) {
      const messageContent = getMessageContentFromLocalStorage();
      const foundItem = channelList.find(
        (item) =>
          (item.Username || "").toLowerCase() ===
          (queryEmailAddress || "").toLowerCase()
      );
      if (foundItem) {
        onChannelClick(foundItem, messageContent);
      } else {
        const newMember = await addNewContact(queryEmailAddress);
        if (newMember && newMember.email) {
          const tempChannel = {};
          const existingChannels = await findInChannelMembershipList(newMember);
          if (existingChannels && existingChannels.length) {
            if (existingChannels[0] && existingChannels[0].ChannelArn) {
              tempChannel.ChannelArn = existingChannels[0].ChannelArn;
            }
          }
          if (tempChannel.ChannelArn === undefined) {
            tempChannel.ChannelArn = null;
          }
          tempChannel.fullName = newMember.fullName || "";
          tempChannel.profilePhoto = newMember.profilePhoto;
          tempChannel.isGroup = false;
          tempChannel.LastMessageTimestamp = null;
          tempChannel.Username = newMember.email;
          tempChannel.Value = newMember.cognitoProfileId;
          tempChannel.cognitoProfileId = newMember.cognitoProfileId;
          tempChannel.unreadContent = null;
          const tempChannelList = cloneDeep(channelList);
          tempChannelList.unshift(tempChannel);
          sortAndSetChannelList(tempChannelList);
          setOpenChannelManually(tempChannel);
        }
      }
      setQueryEmailAddress("");
    }
  };

  const getMessageContentFromLocalStorage = () => {
    // get text message content from local storage to send to user
    // this is required when we pass click on "Send message" from Leads Tab
    // along with user we have to pass text content here
    let messageContent = "";
    if (hasMessageContent) {
      const temp = localStorage.getItem("messageContent");
      localStorage.removeItem("messageContent");
      if (temp) {
        const obj = JSON.parse(temp);
        if (obj && obj.email === queryEmailAddress) {
          messageContent = obj.value;
        }
      }
    }
    return messageContent;
  };

  const addNewMessageMemberToList = async (record) => {
    // add new channel/user to left side list
    const channel = await describeChannel(record.ChannelArn, member.userId);

    if (channel) {
      const response = getMember({ email: record.Sender.Name });

      const user = response.data.users;
      channel.fullName = user.fullName || "";
      channel.profilePhoto = user.profilePhoto;
      channel.isGroup = false;
      channel.LastMessageTimestamp = user.LastMessageTimestamp;
      channel.Username = user.email;
      if (user.cognitoProfileId) {
        channel.Value = user.cognitoProfileId;
        channel.cognitoProfileId = user.cognitoProfileId;
      }
      channel.unreadContent = record;
      const date = new Date(record.CreatedTimestamp);
      channel.LastMessageTimestamp = date.getTime();
      const tempChannelList = cloneDeep(channelList);
      tempChannelList.unshift(channel);
      sortAndSetChannelList(tempChannelList);

      const membershipList = mergeArrayOfObjects(
        channelMembershipList,
        [channel],
        "ChannelArn"
      );
      actions.setChannelMembershipList(membershipList);
    }
  };
  const socketProcessor = (message) => {
    setSocketMessage(message);
  };

  const messagesProcessor = async () => {
    // process event received over messaging socket
    if (!socketMessage) {
      return;
    }
    const message = socketMessage;
    const messageType = message.headers["x-amz-chime-event-type"];
    const record = JSON.parse(message.payload);

    switch (messageType) {
      case "CREATE_CHANNEL_MESSAGE":
      case "REDACT_CHANNEL_MESSAGE":
      case "UPDATE_CHANNEL_MESSAGE":
      case "DELETE_CHANNEL_MESSAGE":
        // Process ChannelMessage
        if (
          selectedChannelData &&
          selectedChannelData.ChannelArn === record.ChannelArn
        ) {
          processChannelMessage(record);
          markChannelAsread(record.ChannelArn);
        } else {
          if (
            !channelList.find((item) => item.Username === record.Sender.Name)
          ) {
            if (record.Sender.Name !== souqhUserInfo.email) {
              // To do
              // addNewMessageMemberToList(record);
            }
          }
          if (record.Sender.Name === souqhUserInfo.email) {
            return;
          }
          const findMatch = unreadChannels.find(
            (chArn) => chArn === record.ChannelArn
          );
          setUnreadChannelMessage(record, true);
          if (findMatch) {
            return;
          }
          const newUnreads = cloneDeep([...unreadChannels, record.ChannelArn]);
          actions.setUnreadChannels(newUnreads);
        }
        break;
      // Channels actions
      case "CREATE_CHANNEL":
      case "UPDATE_CHANNEL":
        return;
        // const newChannelArn = record.ChannelArn;
        // const updatedChannelList = channelMembershipList.map((c) => {
        //   if (c.ChannelArn !== newChannelArn) {
        //     return c;
        //   }
        //   return record;
        // });
        //  to do
        break;
      case "DELETE_CHANNEL": {
        // To do with delete
        break;
      }
      // Channel Memberships
      case "CREATE_CHANNEL_MEMBERSHIP":
        if (record.InvitedBy.Name === souqhUserInfo.email) {
          return;
        }
        const createdChannel = await describeChannel(
          record.ChannelArn,
          member.userId
        );

        const tempChannelList = cloneDeep(channelList);

        if (isGroupChannel(createdChannel.Name)) {
          if (record.Member.Name !== souqhUserInfo.email) {
            return;
          }
          const isDuplicate = channelList.find(
            (item) => item.ChannelArn === createdChannel.ChannelArn
          );
          if (isDuplicate) {
            return;
          } else {
            const tempChannel = {};
            tempChannel.ChannelArn = createdChannel.ChannelArn;
            tempChannel.Name = createdChannel.Name;
            tempChannel.Username = createdChannel.Name;
            tempChannel.fullName = getDisplayGroupName(createdChannel.Name);
            tempChannel.isGroup = true;
            tempChannel.profilePhoto = null;
            tempChannel.Value = null;
            tempChannel.LastMessageTimestamp =
              deriveLastMessageTimestamp(createdChannel);

            tempChannelList.push(tempChannel);
            addChannelToMembershipList({
              ChannelArn: createdChannel.ChannelArn,
              Name: createdChannel.Name,
              LastMessageTimestamp: createdChannel.LastMessageTimestamp,
              Metadata: createdChannel.Metadata,
              Mode: createdChannel.Mode,
              Privacy: createdChannel.Privacy,
            });
            sortAndSetChannelList(tempChannelList);
          }
        } else {
          const Username = createdChannel.CreatedBy.Name;
          if (Username) {
            const foundItem = tempChannelList.find(
              (item) => item.Username === Username
            );
            if (foundItem) {
              foundItem.ChannelArn = createdChannel.ChannelArn;
              foundItem.Name = createdChannel.Name;
              addChannelToMembershipList({
                ChannelArn: createdChannel.ChannelArn,
                Name: createdChannel.Name,
                LastMessageTimestamp: createdChannel.LastMessageTimestamp,
                Metadata: createdChannel.Metadata,
                Mode: createdChannel.Mode,
                Privacy: createdChannel.Privacy,
              });
              sortAndSetChannelList(tempChannelList);
              setAddedWaitForUnread(true);
            } else {
              try {
                const response = await apiClient.get(
                  `/contacts/${souqhUserInfo.userId}`
                );
                const allContacts = response.data.result || [];
                const contactItem = allContacts.find(
                  (item) => item.email === Username
                );
                if (contactItem) {
                  contactItem.ChannelArn = createdChannel.ChannelArn;
                  contactItem.Name = createdChannel.Name;

                  const tempChannel = {};
                  tempChannel.ChannelArn = createdChannel.ChannelArn;
                  tempChannel.Name = createdChannel.Name;
                  tempChannel.Username = contactItem.email;
                  tempChannel.Value = contactItem.cognitoProfileId;
                  tempChannel.cognitoProfileId = contactItem.cognitoProfileId;
                  tempChannel.profilePhoto = contactItem.profilePhoto;
                  tempChannel.isGroup = false;
                  tempChannel.fullName = contactItem.fullName || "";
                  tempChannel.LastMessageTimestamp =
                    createdChannel.LastMessageTimestamp;
                  tempChannelList.unshift(tempChannel);
                  addChannelToMembershipList({
                    ChannelArn: createdChannel.ChannelArn,
                    Name: createdChannel.Name,
                    LastMessageTimestamp: createdChannel.LastMessageTimestamp,
                    Metadata: createdChannel.Metadata,
                    Mode: createdChannel.Mode,
                    Privacy: createdChannel.Privacy,
                  });
                  sortAndSetChannelList(tempChannelList);
                  setAddedWaitForUnread(true);
                }
              } catch (e) {
                console.error(e);
              }
            }
          }
        }

        break;
      case "UPDATE_CHANNEL_MEMBERSHIP":
        return;
        // if (
        //   `${awsChimeConfigs.appInstanceArn}/user/${member.userId}` !==
        //   record.InvitedBy.Arn
        // ) {
        //   const channel = await describeChannel(
        //     record.ChannelArn,
        //     member.userId
        //   );
        //   const newChannelList = mergeArrayOfObjects(
        //     [channel],
        //     channelMembershipList,
        //     "ChannelArn"
        //   );
        //   actions.setChannelMembershipList(cloneDeep(newChannelList));
        // }
        break;
      case "DELETE_CHANNEL_MEMBERSHIP":
        // You are removed
        // return;
        // if (record.Member.Arn.includes(member.userId)) {
        //   this.setChannelMembershipList(
        //     this.channelMembershipList.filter(
        //       (chRef) => chRef.ChannelArn !== record.ChannelArn
        //     )
        //   );
        //   if (this.activeChannel.ChannelArn === record.ChannelArn) {
        //     this.setActiveChannel({});
        //     this.onCloseSelectPanel();
        //     this.isChannelSelected = false;
        //   }
        // } else {
        //   // Someone else is removed
        //   const updatedMemberships = this.activeChannelMemberships.filter(
        //     (m) => m.Member.Arn !== record.Member.Arn
        //   );
        //   this.setActiveChannelMemberships(updatedMemberships);
        // }
        break;
      default:
      // console.log(`Unexpected message type! ${messageType}`);
    }
  };

  const messagesLeftBarProcessor = async () => {
    // process event from messaging socket
    // this is used in left nav bar show amber notification
    if (!socketMessage) {
      return;
    }
    const message = socketMessage;
    const messageType = message.headers["x-amz-chime-event-type"];
    const record = JSON.parse(message.payload);

    switch (messageType) {
      case "CREATE_CHANNEL_MESSAGE":
      case "REDACT_CHANNEL_MESSAGE":
      case "UPDATE_CHANNEL_MESSAGE":
      case "DELETE_CHANNEL_MESSAGE":
        if (record.Sender.Name === souqhUserInfo.email) {
          return;
        }
        if ((currentUIPath || "").indexOf("messaging") > 0) {
          return;
        }
        if (record.Sender.Name !== souqhUserInfo.email) {
          setUnreadMessagesLocal(record);
        }
        const findMatch = unreadChannels.find(
          (chArn) => chArn === record.ChannelArn
        );
        if (findMatch) {
          return;
        }
        const newUnreads = cloneDeep([...unreadChannels, record.ChannelArn]);
        actions.setUnreadChannels(newUnreads);
        break;
    }
  };

  const signOutUserFromChime = async () => {
    // logout user from chime when, user logs out of souqh application
    try {
      const response = await Auth.signOut();
    } catch (error) {
      console.log(`error siging out ${error}`);
    } finally {
      clearMessageDataOnDestroy();
      actions.resetAllMessagingData();
      try {
        if (session) {
          session.stop();
        }
        resetMessageUpdateCallbacks && resetMessageUpdateCallbacks();
      } catch (err) {
        console.error("Failed to stop Messaging Session.", err);
      }
    }
  };

  const getMember = (email) => {
    return {};
  };

  const processMessageAttachment = (event) => {
    // set file/attachment data in state variable
    const file = event.currentTarget.files[0];
    if (!file) return;

    if (file.size / 1024 / 1024 < 15) {
      actions.setMessageAttachment({
        file: file,
        name: file.name,
      });
      if (messageTextBoxRef && messageTextBoxRef.current) {
        messageTextBoxRef.current.focus();
      }
    } else {
      dispatch({
        type: "SET_TOASTER",
        payload: {
          message: `Maximum size 15 MB file is allowed`,
          bgColor: color_error,
          open: true,
          severity: "error",
        },
      });
    }
  };

  const removeAttachment = () => {
    // unset attachment data
    actions.setMessageAttachment(null);
  };

  const getUserFullName = (email) => {
    if (email) {
      const foundItem = allUsersList.find((item) => item.email === email);
      if (foundItem) {
        return foundItem.fullName || "";
      }
      return email;
    }
    return "";
  };

  const getUserProfilePic = (email) => {
    if (email) {
      const foundItem = allUsersList.find((item) => item.email === email);
      if (foundItem) {
        return foundItem.profilePhoto;
      }
    }
    return "";
  };

  const handleSnackBarClose = (event, reason) => {
    setOpenSnackBar(false);
  };

  const clearMessageDataOnDestroy = () => {
    setSocketMessage(null);
    setSearchUserText("");
    setOpenCreateNewGroupChat(false);
    setOpenBroadCastMessageDialog(false);
    setSendingMessage(false);
    setMessageTextBox("");
    unSubscribeToMessageUpdate(messagesProcessor);
    actions.resetLoadedMessageData();
    setBroadcastMessage(false);
    setShowEmojiPicker(false);
    actions.setMessageAttachment(null);
    setSelectedChannelMembersList([]);
    setOpenGroupMembersDialog(false);
    setOpenChannelManually(null);
    setQueryEmailAddress(null);
    setAddedWaitForUnread(false);
    setWaitedUnreadData(null);
    setHasMessageContent(false);
  };

  const showLoader = () => {};

  const flattenChannelMemberList = async () => {
    if (!member) {
      return Promise.resolve([]);
    }
    let latestChannelList = [];
    try {
      latestChannelList = await listChannelMembershipsForAppInstanceUser(
        member.userId
      );
    } catch (e) {
      console.error(e);
    }

    if (!latestChannelList || !latestChannelList.length) {
      return Promise.resolve([]);
    }
    return Promise.resolve(
      latestChannelList.map(
        (channelMembership) => channelMembership.ChannelSummary
      )
    );
  };

  return {
    actions,
    searchUserText,
    setSearchUserText,
    channelList,
    openCreateNewGroupChat,
    setOpenCreateNewGroupChat,
    openBroadCastMessageDialog,
    setOpenBroadCastMessageDialog,
    souqhUserInfo,
    setAuthenticatedUser,
    messageUpdateCallbacks,
    selectedChannelData,
    currentChannelMessages,
    sessionStarted,
    loadChannelsData,
    leftPanelRef,
    itemRefs,
    setSrollToActiveChannel,
    messageTextBoxRef,
    messagePanelRef,
    messageTextBox,
    setMessageTextBox,
    sendMessage,
    onContentScroll,
    processMessageAttachment,
    messageAttachment,
    removeAttachment,
    signInProgress,
    onChannelClick,
    subscribeToMessageUpdate,
    socketProcessor,
    loadedChannelData,
    socketMessage,
    messagesProcessor,
    clearMessageDataOnDestroy,
    unreadChannels,
    sendingMessage,
    setSendingMessage,
    showLoader,
    downloadAttachment,
    messagesLeftBarProcessor,
    setUnreadChannlesData,
    openSnackBar,
    handleSnackBarClose,
    createNewUserGroup,
    broadcastMessage,
    onSendBroadcastMessage,
    updateListAfterBroadcast,
    showEmojiPicker,
    setShowEmojiPicker,
    allUsersList,
    selectedChannelMembersList,
    setSelectedChannelMembersList,
    openGroupMembersDialog,
    setOpenGroupMembersDialog,
    openChannelManually,
    setOpenChannelManually,
    queryEmailAddress,
    setQueryEmailAddress,
    openChannelForMessage,
    requestServiceMessageFromBackground,
    addedWaitForUnread,
    setAddedWaitForUnread,
    processUnreadWait,
    waitedUnreadData,
    setWaitedUnreadData,
    hasMessageContent,
    setHasMessageContent,
    getMessageContentFromLocalStorage,
  };
};
