import {
  Container,
  Drawer,
  Fab,
  Grid,
  IconButton,
  makeStyles,
  StylesProvider,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Profile from "./Profile";
import Review from "./Review";
import SetupDrawer from "./SetupDrawer";
import StoreFront from "./StoreFront";
import Subscription from "./Subscription";
import Invoice from "./PaymentAndInvoice/Invoice";
import {
  usePreProfileData,
  useSaveProfileData,
} from "souqh-react-redux-hooks/serviceProvider/useSetUp";
import { useSetupDrawer } from "souqh-react-redux-hooks/useSetupDrawer";
import clsx from "clsx";
import { useAuthUser, useLogout } from "souqh-react-redux-hooks/useLogin";
import { useHistory, useLocation } from "react-router-dom";
import Styles from "../../Styles.module.css";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { useSave } from "souqh-react-redux-hooks/serviceProvider/storefront/useSave";
import { useApiClient } from "souqh-react-redux-hooks/useApiClient";
import { useRouteCheck } from "souqh-react-redux-hooks/common/useRouteCheck";
import {
  APPROVED,
  SERVICE_PROVIDER_USER,
  TEAM_MEMBER_USER,
} from "../ServiceProvider/serviceProviderConstants";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const drawerWidth = 365;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#17174c",
  },
  content: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.default,
    backgroundColor: "#F5F5F8",
    minHeight: window.innerHeight,
    // width: "73%",
  },
  bodyBackgroundImg: {
    backgroundImage:
      "url('/images/blue_bg_shape.svg'), url('/images/square_triangle_shapes.svg'), url('/images/orange_shape.svg')",
    backgroundRepeat: "no-repeat, no-repeat, no-repeat",
    backgroundPosition: "0% 2%, 80% 15%, 100% 98%",
    backgroundSize: "10%, auto, auto",
    position: "sticky",
    top: 0,
    left: 365,
    minHeight: "calc(100vh - 0px)",
    width: "calc(100% - 365px)",
  },
  mobileBackground: {
    backgroundColor: "rgba(14, 28, 78, 0.04)",
    minHeight: "calc(100vh - 24px)",
  },
}));
const SPSetup = () => {
  let { path } = useRouteMatch();
  const classes = useStyles();
  const { getProfileData, user } = usePreProfileData();
  const { showInvoice } = useSetupDrawer();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  const { logout } = useLogout();
  let history = useHistory();
  const userInfo = useAuthUser();
  const { saveProfileData } = useSaveProfileData();
  const { apiClient } = useApiClient();

  const {
    actions: { save },
  } = useSave();
  let location = useLocation();
  let locationParam = location.pathname.split("/"); // read URL and split it on "/"
  let activeSectionFromURL = locationParam[locationParam.length - 1]; // take last section from URL

  useEffect(() => {
    window.scrollTo(0, 0);
    getProfileData(() => {});
  }, []);

  const { checkSPRoute } = useRouteCheck();

  useEffect(() => {
    checkSPRoute();
  }, [userInfo]);

  if (!userInfo) {
    return null;
  }
  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <SetupDrawer></SetupDrawer>
      </Drawer>
      <main
        className={clsx(
          smUp ? classes.bodyBackgroundImg : classes.mobileBackground,
          classes.content
        )}
      >
        <Grid container>
          <Grid item xs={xlUp ? 1 : 1}></Grid>
          <Grid item xs={xlUp ? 7 : 11}>
            <Container
              maxWidth={xlUp ? "lg" : "md"}
              style={{ paddingLeft: xlUp ? 84 : 14 }}
            >
              {/* TODO: Remove the code after proper testing of the flow */}
              {/* {user.profileSetupStatus &&
              user.isPaymentPlanSelected &&
              user.isPaymentCardAdded &&
              user.spApprovalStatus === APPROVED ? ( */}
              <Grid container direction="column">
                <Tooltip title="Go to Dashboard">
                  <Fab
                    aria-label="home"
                    style={{ position: "fixed", top: 50, right: 40 }}
                  >
                    <IconButton
                      onClick={() => {
                        if (
                          activeSectionFromURL === "setup" ||
                          activeSectionFromURL === "profile"
                        ) {
                          if (user.spApprovalStatus === APPROVED) {
                            saveProfileData((res) => {
                              if (res && res.data.status === 200) {
                                history.push("/serviceprovider/dashboard");
                              }
                            });
                          } else {
                            history.push("/serviceprovider/dashboard");
                          }
                        } else if (activeSectionFromURL === "storefront") {
                          history.push("/serviceprovider/dashboard");
                          // Multiple fields are mandatory to save SF
                          // save((res) => {
                          //   if (res && res.data.status === 200) {
                          //     history.push("/serviceprovider/dashboard");
                          //   }
                          // }, apiClient);
                        } else {
                          history.push("/serviceprovider/dashboard");
                        }
                      }}
                    >
                      <DashboardIcon className={Styles.navyBlueColor} />
                    </IconButton>
                  </Fab>
                </Tooltip>
              </Grid>
              {/* ) : (
                <Tooltip title="Logout">
                  <Fab
                    aria-label="home"
                    style={{ position: "fixed", top: 50, right: 40 }}
                  >
                    <IconButton
                      color="inherit"
                      className={classes.paddingLeft0}
                      onClick={() => {
                        const usertype = user.userType;
                        logout((res) => {
                          if (
                            usertype === SERVICE_PROVIDER_USER ||
                            usertype === TEAM_MEMBER_USER
                          ) {
                            history.push("/login");
                          } else {
                            history.push("/userlogin");
                          }
                        });
                      }}
                    >
                      <ExitToAppIcon />
                    </IconButton>
                  </Fab>
                </Tooltip>
              )} */}
              <Grid container item md={12} xs={12} justifyContent="flex-start">
                <Grid container item md={11} xs={12}>
                  <Switch>
                    <Route exact path={`${path}/profile`}>
                      <Profile />
                    </Route>
                    <Route exact path={`${path}`}>
                      <Profile />
                    </Route>
                    <Route exact path={`${path}/storefront`}>
                      <StoreFront />
                    </Route>
                    <Route exact path={`${path}/subscription`}>
                      {!showInvoice ? <Subscription /> : <Invoice />}
                    </Route>
                    <Route exact path={`${path}/review`}>
                      <Review />
                    </Route>
                  </Switch>
                </Grid>
              </Grid>
            </Container>
          </Grid>
          <Grid item xs={xlUp ? 4 : 0}></Grid>
        </Grid>
      </main>
    </div>
  );
};

export default SPSetup;
