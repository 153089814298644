import React from "react";
import {
  Button,
  makeStyles,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core/";
import clsx from "clsx";
import Styles from "../../../../Styles.module.css";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useDispatch } from "react-redux";
import { MANDATORY_DETAILS_MESSAGE } from "../../serviceProviderConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined": {
      top: -8,
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px",
    },
  },
}));
export default function EditEsignActinBtns({
  activeEsignSection,
  setActiveEsignSection,
  setOpenCustomEmailMessageDialog,
  setOpenUploadCancelDialog,
  documentsTagged,
  validateTaggedDocument,
  getSignnowInviteData,
  inviteForEsign,
  isAllDataFilled,
  handleClose,
  setSelectedContact,
  disableSkipBtn,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <>
      <Divider />

      <Grid item xs={12} alignItems="center" className={classes.root}>
        <Grid
          item
          xs={12}
          style={{
            alignItems: "center",
            display: "flex",
            paddingRight: 0,
          }}
        >
          <Grid item xs={6}>
            {activeEsignSection === 2 ? (
              <Typography
                variant="caption"
                style={{ color: "#ff0000", marginRight: 8 }}
              >
                Please click 'Save and Close', before clicking 'Next'.
              </Typography>
            ) : null}
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              justifyContent: "flex-end",
              alignItems: "center",
              display: "flex",
              paddingRight: 0,
            }}
          >
            {activeEsignSection > 2 && (
              <Button
                startIcon={<ArrowBackIosIcon />}
                style={{
                  textTransform: "capitalize",
                  marginRight: 20,
                }}
                className={Styles.navyBlueColorForce}
                onClick={() => {
                  if (activeEsignSection === 4) {
                    setSelectedContact(null);
                  }
                  setActiveEsignSection(activeEsignSection - 1);
                }}
              >
                Go Back
              </Button>
            )}
            {activeEsignSection === 2 ? (
              <Button
                variant="outlined"
                style={{
                  textTransform: "capitalize",
                  borderRadius: 4,
                  padding: "6px 16px",
                  marginRight: 12,
                }}
                className={clsx(Styles.navyBlueColor)}
                onClick={() => {
                  setOpenUploadCancelDialog(true);
                }}
              >
                Cancel
              </Button>
            ) : null}
            {activeEsignSection === 3 ? (
              <Button
                disabled={disableSkipBtn}
                variant="outlined"
                style={{
                  textTransform: "capitalize",
                  borderRadius: 4,
                  padding: "6px 16px",
                  marginRight: 12,
                }}
                className={clsx(Styles.navyBlueColor)}
                onClick={() => {
                  getSignnowInviteData();
                }}
              >
                Skip This Step
              </Button>
            ) : null}

            {activeEsignSection === 4 ? (
              <Button
                variant="outlined"
                style={{
                  textTransform: "capitalize",
                  padding: "6px 16px",
                  marginRight: 12,
                }}
                className={clsx(Styles.navyBlueColorForce)}
                onClick={() => {
                  setOpenCustomEmailMessageDialog(true);
                }}
              >
                Customize Email
              </Button>
            ) : null}
            {activeEsignSection !== 4 ? (
              <Button
                disabled={!documentsTagged.length}
                style={{
                  textTransform: "capitalize",
                  borderRadius: 4,
                  padding: "6px 32px",
                }}
                className={clsx(
                  Styles.navyBlueColorForce,
                  Styles.primaryBtnAccent
                )}
                onClick={() => {
                  if (activeEsignSection === 2) {
                    validateTaggedDocument();
                  } else if (activeEsignSection === 3) {
                    getSignnowInviteData();
                  }
                }}
              >
                Next
              </Button>
            ) : (
              <Button
                style={{
                  textTransform: "capitalize",
                  padding: "6px 32px",
                }}
                className={Styles.primaryBtnAccent}
                onClick={() => {
                  if (!isAllDataFilled()) {
                    dispatch({
                      type: "SET_SERVER_ERROR",
                      error: { message: MANDATORY_DETAILS_MESSAGE },
                    });
                  } else {
                    inviteForEsign(() => {
                      handleClose();
                    });
                  }
                }}
              >
                Invite &#38; Send
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
