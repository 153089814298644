import { useState, useCallback, useEffect } from "react";
import { useAuthUser } from "../useLogin";
import { useDispatch, useSelector } from "react-redux";
import { useApiClient } from "../useApiClient";
import {
  emailValidationMeta,
  TMFullnameValidationMeta,
} from "../validationMetas";
import { useValidations } from "../useValidations";
import { useTagSomeone } from "../serviceProvider/clientManagement/useTagSomeone";
import { ADVANCED, PREMIUM } from "../../web/src/components/ServiceProvider/serviceProviderConstants";

const validationMeta = [TMFullnameValidationMeta, emailValidationMeta];

const billingMap = {
  Monthly: 'MONTHLY',
  Annually: 'YEARLY'
}

export const useAddMember = () => {
  const { apiClient } = useApiClient();
  const userInfo = useAuthUser();
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [plan, setPlan] = useState("");
  const [role, setRole] = useState("");
  const [error, setError] = useState("");
  const [billing, setBilling] = useState("");

  const { promoCode } = useSelector((state) => state.subscriptionPlan);

  const verifyPromoCodeData = useSelector(
    (state) => state.metaData.verifyPromoCode
  );

  const { getTagMembers } = useTagSomeone();

  const [allowAdd, setAllowAdd] = useState(false);

  const getFormValues = useCallback(() => {
    return { name, email };
  }, [name, email]);
  const getServerKeysMap = useCallback(() => {
    return {};
  }, []);

  const { validations, addValidations, clearAllValidations } = useValidations(
    validationMeta,
    getFormValues,
    getServerKeysMap
  );
  useEffect(() => {
    let validForm =
      !!title &&
      !!name &&
      !!email &&
      !!plan &&
      !!billing &&
      !Object.keys(validations).length;

    let finalValidForm =
      validForm &&
        (plan.planName === PREMIUM || plan.planName === ADVANCED || role) &&
      (!promoCode || (promoCode && verifyPromoCodeData?.result))
        ? true
        : false;

    setAllowAdd(finalValidForm);
  }, [
    title,
    name,
    email,
    plan,
    role,
    validations,
    verifyPromoCodeData,
    promoCode,
    billing,
  ]);
  const addTeamMember = (onResult) => {
    let roleValue = role === "Admin" ? "SERVICE_PROVIDER" : "TEAM_MEMBER";
    let data = {
      email: email,
      name: name,
      parentServiceProviderId: userInfo.serviceProviderId,
      planId: plan.id,
      role: (plan.planName !== PREMIUM && plan.planName !== ADVANCED) ? roleValue : "SERVICE_PROVIDER",
      title: title,
      paymentCycle: billing.value,
      promoCode: promoCode || null
    };
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .post("/service-provider/team-member/add", data)
      .then((res) => {
        if (res.data.status === 200) {
          getTagMembers();
        }
        onResult && onResult(res);
      })
      .catch((error) => {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const clearData = () => {
    setTitle("");
    setName("");
    setEmail("");
    setPlan("");
    setRole("");
    setBilling("");
  };
  return {
    setTitle,
    setName,
    setEmail,
    setPlan,
    setRole,
    addTeamMember,
    plan,
    allowAdd,
    validations,
    clearData,
    role,
    addValidations,
    error,
    setError,
    billing,
    setBilling,
  };
};
