import { isTabletDevice } from "../../../utils/AppUtils";

export const TAB_NAMES = [
  { value: "Active", key: "active" },
  { value: "In-Active", key: "inActive" },
  { value: "Completed", key: "completed" },
  { value: "Leads", key: "leads" },
];

export const CLIENT_DETAILS_TAB_NAMES = [
  "Personal Details",
  "Documents",
  "Demographics Data",
  "Client Hub",
];

export const REVIEW_TYPES = {
  Positive: "#0A4C25",
  Negative: "#700000",
  Neutral: "#868DA6",
  Count: "#2842D0",
};

export const ACTIVE_CLIENT_TABLE_HEADERS = [
  {
    id: "id",
    label: "#",
    width: "1%",
  },
  {
    id: "name",
    label: "Client Details",
    width: "28%",
  },
  {
    id: "hbJourneyStatus",
    label: "Status",
    width: isTabletDevice() ? "12rem" : "19%",
  },
  {
    id: "closingDate",
    label: "Closing Date",
    width: isTabletDevice() ? "9rem" : "15%",
  },
  {
    id: "activeSps",
    label: "Active Service Providers",
    width: "23%",
  },
  {
    id: "menu",
    label: "",
    width: "3%",
  },
];

export const INACTIVE_CLIENT_TABLE_HEADERS = [
  {
    id: "id",
    label: "#",
    width: "1%",
  },
  {
    id: "name",
    label: "Client Details",
    width: isTabletDevice() ? "12rem" : "17%",
  },
  {
    id: "requestedDate",
    label: "Request Date",
    width: isTabletDevice() ? "9rem" : "14%",
  },
  {
    id: "archiveInActiveDate",
    label: "In-Active Date",
    width: isTabletDevice() ? "9rem" : "14%",
  },
  {
    id: "notes",
    label: "Service Details",
    width: isTabletDevice() ? "10rem" : "30%",
  },
  {
    id: "menu",
    label: "",
    width: "3%",
  },
];

export const COMPLETED_CLIENT_TABLE_HEADERS = [
  {
    id: "id",
    label: "#",
    width: "1%",
  },
  {
    id: "name",
    label: "Client Details",
    width: isTabletDevice() ? "12rem" : "17%",
  },
  {
    id: "requestedDate",
    label: "Request Date",
    width: isTabletDevice() ? "9rem" : "14%",
  },
  {
    id: "completeDate",
    label: "Completed Date",
    width: isTabletDevice() ? "10rem" : "16%",
  },
  {
    id: "starRating",
    label: "Review",
    width: isTabletDevice() ? "12rem" : "30%",
  },
  {
    id: "menu",
    label: "",
    width: "3%",
  },
];

export const LEADS_CLIENT_TABLE_HEADERS = [
  {
    id: "id",
    label: "#",
    width: "1%",
  },
  {
    id: "name",
    label: "Client Details",
    width: isTabletDevice() ? "12rem" : "25%",
  },
  // {
  //   id: "status",
  //   label: "Status",
  //   width: "19%",
  // },
  {
    id: "requestedDate",
    label: "Request Date",
    width: isTabletDevice() ? "9rem" : "15%",
  },
  {
    id: "notes",
    label: "Service Details",
    width: isTabletDevice() ? "10rem" : "35%",
  },
  {
    id: "action",
    label: "Action",
    width: "6%",
  },
  {
    id: "menu",
    label: "",
    width: "3%",
  },
];
