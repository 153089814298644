import { cloneDeep } from "lodash";
import { MEETING_READ_MODE } from "../../web/src/components/ServiceProvider/serviceProviderConstants";
import {
  CLEAR_CALENDAR_DATA,
  GET_ALL_CONTACTS_TO_MEETING,
  SET_CALENDAR_EVENTS,
  SET_MEETING_MODE,
  SET_OPEN_CANCEL_MEETING_DIALOG,
  SET_OPEN_CREATE_MEETING_DIALOG,
  SET_SELECTED_CALENDAR_EVENT,
} from "./actionConstants";

const initialState = {
  openCreateMeetingDialog: false,
  meetingMode: MEETING_READ_MODE,
  calendarEvents: [],
  allContacts: [],
  selectedCalendarEvent: null,
  openConfirmCanelMeetingDialog: false,
};
const sqCalendar = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_CALENDAR_EVENTS:
      newState = cloneDeep(state);
      newState.calendarEvents = action.payload.calendarEvents;
      return newState;
    case GET_ALL_CONTACTS_TO_MEETING:
      newState = cloneDeep(state);
      newState.allContacts = action.payload.allContacts || [];
      return newState;
    case SET_OPEN_CREATE_MEETING_DIALOG:
      newState = cloneDeep(state);
      newState.openCreateMeetingDialog = action.payload.openCreateMeetingDialog;
      return newState;
    case SET_MEETING_MODE:
      newState = cloneDeep(state);
      newState.meetingMode = action.payload.meetingMode;
      return newState;
    case SET_SELECTED_CALENDAR_EVENT:
      newState = cloneDeep(state);
      newState.selectedCalendarEvent = action.payload.selectedCalendarEvent;
      return newState;
    case SET_OPEN_CANCEL_MEETING_DIALOG:
      newState = cloneDeep(state);
      newState.openConfirmCanelMeetingDialog =
        action.payload.openConfirmCanelMeetingDialog;
      return newState;
    case CLEAR_CALENDAR_DATA:
      newState = cloneDeep(state);
      newState.openConfirmCanelMeetingDialog =
        initialState.openConfirmCanelMeetingDialog;
      newState.selectedCalendarEvent = initialState.selectedCalendarEvent;
      newState.openCreateMeetingDialog = initialState.openCreateMeetingDialog;
      newState.allContacts = initialState.allContacts;
      newState.calendarEvents = initialState.calendarEvents;
      return newState;
    default:
      return state;
  }
};

export default sqCalendar;
