import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import * as moment from "moment";
import { useSPUploadWizard } from "souqh-react-redux-hooks/serviceProvider/documents/useSPUploadWizard";
import SPUploadWizard from "../UploadWizard/SPUploadWizard";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 400,
    minWidth: 800,
  },
}));

function RequestedDocumentDialog({
  openRequestedDocumentDialog,
  setOpenRequestedDocumentDialog,
  requestedDocumentData,
  deletePendingAction,
}) {
  const classes = useStyles();

  const {
    onUploadFileChange,
    openUploadWizard,
    actions: { setOpenUploadWizard, cleanUploadFileDataOnClose },
  } = useSPUploadWizard();

  const documentName = requestedDocumentData
    ? requestedDocumentData.documentDetailDTO.documentName ||
      requestedDocumentData.eventDescription.split("-")[1]
    : "-";
  return (
    <React.Fragment>
      <SPUploadWizard
        openUploadWizard={openUploadWizard}
        setOpenUploadWizard={setOpenUploadWizard}
        deletePendingAction={deletePendingAction}
        pendingActionId={
          requestedDocumentData && requestedDocumentData.pendingActionId
        }
      />

      <Dialog
        fullWidth
        open={openRequestedDocumentDialog}
        onClose={() => {
          setOpenRequestedDocumentDialog(false);
          cleanUploadFileDataOnClose();
        }}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DialogTitle
          className={Styles.navyBlueColor}
          style={{ padding: "12px 24px" }}
        >
          <Typography variant="subtitle1">Requested Document</Typography>
        </DialogTitle>
        <Divider />
        <DialogContent style={{ marginTop: 8 }}>
          <Grid container item xs={12}>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                className={Styles.navyBlueColor}
                style={{ fontWeight: 600 }}
              >
                Document Name
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" className={Styles.navyBlueColor}>
                {documentName}
              </Typography>
            </Grid>
            <Divider
              orientation="horizontal"
              variant="fullWidth"
              style={{ width: "100%", margin: "16px 0" }}
            />
            <Grid item xs={4}>
              <Typography
                variant="body1"
                className={Styles.navyBlueColor}
                style={{ fontWeight: 600 }}
              >
                Comment
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" className={Styles.navyBlueColor}>
                {requestedDocumentData && requestedDocumentData.tagComments
                  ? requestedDocumentData.tagComments
                  : "-"}
              </Typography>
            </Grid>
            <Divider
              orientation="horizontal"
              variant="fullWidth"
              style={{ width: "100%", margin: "16px 0" }}
            />
            <Grid item xs={4}>
              <Typography
                variant="body1"
                className={Styles.navyBlueColor}
                style={{ fontWeight: 600 }}
              >
                Deadline
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" className={Styles.navyBlueColor}>
                {requestedDocumentData && requestedDocumentData.dueDate
                  ? moment(requestedDocumentData.dueDate).format("MMM DD, YYYY")
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions style={{ padding: "16px 24px" }}>
          <Button
            variant="outlined"
            style={{
              padding: "6px 16px",
              textTransform: "capitalize",
              marginRight: 8,
            }}
            className={Styles.navyBlueColorForce}
            onClick={() => {
              setOpenRequestedDocumentDialog(false);
            }}
          >
            Cancel
          </Button>
          <input
            style={{ display: "none" }}
            id="contained-button-file"
            multiple
            type="file"
            onChange={(event) => {
              onUploadFileChange(event);
              setOpenRequestedDocumentDialog(false);
            }}
          />
          <label htmlFor="contained-button-file">
            <Button
              component="span"
              variant="contained"
              className={Styles.primaryBtnAccent}
              style={{ textTransform: "capitalize", padding: "6px 32px" }}
            >
              Upload
            </Button>
          </label>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default RequestedDocumentDialog;
