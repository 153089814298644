import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_LANGUAGE_SUPPORT } from "../souqh-redux/reducers/actionConstants";
import { uniqBy } from "lodash";
import { useActions } from "./useActions";
import { useApiClient } from "./useApiClient";

const getRemoteLanguagesActionCreator = (apiClient) => (dispatch) => {
  dispatch({ type: "SET_LOADING", value: true });
  return apiClient
    .get(`/language-support`)
    .then(function (response) {
      if (!response.data.result.length) {
        // we need response.data.result with values in every condition
        throw Error("Server Error : Languages not found");
      } else {
        dispatch({
          type: "SET_LANGUAGES",
          languages: response.data.result,
        });
      }
    })
    .catch(function (error) {
      console.error(error);
    })
    .then(function () {
      // always executed
      dispatch({ type: "SET_LOADING", value: false });
    });
};

const actionCreator = {
  setSelectedLanguage: (payload) => ({ type: SET_LANGUAGE_SUPPORT, payload }),
};

export const useLanguages = () => {
  const { apiClient } = useApiClient();

  const dispatch = useDispatch();
  const languages = useSelector((state) => state.metaData.languages);
  const [language, setLanguage] = useState([]);
  const actions = useActions(actionCreator);
  const selectedLanguage = useSelector(
    (state) => state.spSetup.languageSupport
  );
  useEffect(() => {
    if (languages && !languages.length) {
      dispatch(getRemoteLanguagesActionCreator(apiClient));
    }
  }, [languages]);

  useEffect(() => {
    if (language.length) {
      actions.setSelectedLanguage(uniqBy(language, "id")); // update reducer
    }
  }, [language]);

  return { languages, actions, language, setLanguage, selectedLanguage };
};
