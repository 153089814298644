import { Grid, Typography } from "@material-ui/core";
import React from "react";

export default function NoDataAvailable({
  isSFAnalyticsPage,
  children,
  isUserMgmtPage,
  isMarketingCampaignPage,
  addMarginTop
}) {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ marginTop: (isUserMgmtPage || isMarketingCampaignPage || addMarginTop) && 40 }}
    >
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <img src="/images/no-result-found-orange.svg" />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          textAlign: "center",
          marginTop: isSFAnalyticsPage ? 80 : isUserMgmtPage && 40,
        }}
      >
        {isSFAnalyticsPage ? (
          <Typography
            style={{
              color: "#868DA6",
              fontSize: 11,
              marginTop: -60,
              padding: "0px 16px",
            }}
          >
            Looks like you don't have any information for selected filters
          </Typography>
        ) : (
          children
        )}
      </Grid>
    </Grid>
  );
}
