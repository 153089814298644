import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ButtonBase,
  Grid,
  AppBar,
  Link as MaterialLink,
  Avatar,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Styles from "../../Styles.module.css";
import MessageIcon from "@material-ui/icons/Message";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { useHistory, Link } from "react-router-dom";
import { useSPManagement } from "souqh-react-redux-hooks/homeBuyer/spManagement/useSPManagement";
import MoreOptionsOnSPInfo from "../HomeBuyer/SPManagement/MoreOptionsOnSPInfo";
import { useNavigateToMessage } from "souqh-react-redux-hooks/common/useNavigateToMessage";
import { useActiveSPInfo } from "souqh-react-redux-hooks/homeBuyer/spManagement/useActiveSPInfo";
import { isMobileDevice } from "../../utils/AppUtils";

export default function ServiceProvidersWidget({ xlUp }) {
  let history = useHistory();
  const { navigateMessage } = useNavigateToMessage();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const { getActiveSPData, active } = useActiveSPInfo(5);

  useEffect(() => {
    getActiveSPData();
  }, []);
  return (
    <React.Fragment>
      <MoreOptionsOnSPInfo anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      {active && active.response && active.response.length ? (
        <TableContainer
          component={Paper}
          elevation={0}
          className="hideScroll"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Table
            aria-label="collapsible table"
            stickyHeader
            style={{ padding: "8px 8px 32px 8px" }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    width: "66%",
                    fontWeight: 600,
                    fontSize: xlUp ? 16 : 12,
                    backgroundColor: "#FFFFFF",
                    lineHeight: "0.5rem",
                    padding: "12px 0px",
                  }}
                  className={Styles.navyBlueColorForce}
                >
                  Service Providers
                </TableCell>
                <TableCell
                  style={{
                    width: "34%",
                    fontWeight: 600,
                    fontSize: xlUp ? 16 : 12,
                    backgroundColor: "#FFFFFF",
                    lineHeight: "0.5rem",
                    padding: "12px 0px",
                  }}
                  className={Styles.navyBlueColorForce}
                >
                  Quick Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {active.response.map((data, index) => {
                const storeFrontContent = JSON.parse(
                  data.storefrontDetails.storeFrontContent
                );
                return (
                  <TableRow key={index}>
                    <TableCell container style={{ padding: "12px 0px" }}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={2}>
                          <Link
                            key={index}
                            to={{
                              pathname:
                                window.location.origin +
                                `/storefront/${data.storefrontDetails.storeFrontRouteName}`,
                            }}
                            target="_blank"
                          >
                            <Avatar
                              style={{ height: 24, width: 24 }}
                              src={storeFrontContent.displayPicture}
                            >
                              {(
                                data.storefrontDetails.storeFrontName || ""
                              ).charAt(0)}
                            </Avatar>
                          </Link>
                        </Grid>
                        <Grid container item xs={10}>
                          <Typography
                            className={Styles.navyBlueColor}
                            style={{
                              fontWeight: 600,
                              fontSize: isMobileDevice() ? 12 : xlUp ? 16 : 11,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              history.push(
                                "/homebuyer/serviceproviders?tabIndex=0"
                              );
                            }}
                          >
                            {data.storefrontDetails.storeFrontName}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell style={{ padding: "12px 8px" }}>
                      <Grid container justifyContent="space-between">
                        <Grid item xs={4}>
                          <ButtonBase>
                            <MessageIcon
                              style={{ fontSize: 12 }}
                              className={Styles.navyBlueColorForce}
                              onClick={(event) => {
                                navigateMessage(
                                  event,
                                  data.storefrontDetails.email
                                );
                              }}
                            />
                          </ButtonBase>
                        </Grid>
                        <Grid item xs={4}>
                          <ButtonBase>
                            <DateRangeIcon
                              style={{ fontSize: 12 }}
                              className={Styles.navyBlueColorForce}
                              onClick={() => {
                                history.push(
                                  "/homebuyer/calendar?scheduleMeeting=true"
                                );
                              }}
                            />
                          </ButtonBase>
                        </Grid>
                        <Grid item xs={4}>
                          <ButtonBase>
                            <MoreHorizIcon
                              className={Styles.navyBlueColorForce}
                              onClick={handleClick}
                            />
                          </ButtonBase>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <div
            style={{
              position: "sticky",
              alignItems: "flex-end",
              bottom: 0,
              width: "100%",
            }}
          >
            <AppBar
              position="sticky"
              style={{
                top: "auto",
                bottom: 0,
                backgroundColor: "#FFFFFF",
                color: "#FA7E61",
                height: 48,
              }}
            >
              <Grid
                container
                item
                justifyContent="flex-end"
                alignContent="center"
                style={{ paddingRight: "5%", height: "100%" }}
              >
                <ButtonBase
                  onClick={() => {
                    history.push("/homebuyer/serviceproviders?tabIndex=0");
                  }}
                >
                  <Typography style={{ fontSize: xlUp ? 14 : 12 }}>
                    View All
                  </Typography>
                </ButtonBase>
              </Grid>
            </AppBar>
          </div>
        </TableContainer>
      ) : (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <img
              src="/images/no-result-found-blue.svg"
              style={{ height: 121, width: 124 }}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography
              style={{
                color: "#868DA6",
                fontSize: 11,
                marginTop: -60,
                padding: "0px 24px",
              }}
            >
              Looks like you are not working with any active service providers.
              Click{" "}
              <MaterialLink
                style={{ cursor: "pointer" }}
                href="/homebuyer/explore"
              >
                here
              </MaterialLink>{" "}
              to explore storefronts to request services
            </Typography>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}
