import {
  Button,
  Grid,
  Tabs,
  TextField,
  Typography,
  Tab,
  makeStyles,
  Paper,
  Tooltip,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import React, { useEffect, useState } from "react";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { useUpcomingEeventsPendingActions } from "souqh-react-redux-hooks/serviceProvider/upcomingEeventsPendingActions/useUpcomingEeventsPendingActions";
import clsx from "clsx";
import PendingActions from "./PendingActions";
import ProfileAccessRequestDialog from "./ProfileAccessRequestDialog";
import ViewTagDialog from "./ViewTagDialog";
import RequestedDocumentDialog from "./RequestedDocumentDialog";
import ViewOtherActionDialog from "./ViewOtherActionDialog";
import RecommendedSPDialog from "./RecommendedSPDialog.";
import { useAddTemplate } from "souqh-react-redux-hooks/serviceProvider/documents/useAddTemplate";
import { useDocuments } from "souqh-react-redux-hooks/serviceProvider/documents/useDocuments";
import UpcomingEvents from "./UpcomingEvents";
import { useUpcomingEvents } from "souqh-react-redux-hooks/serviceProvider/upcomingEeventsPendingActions/useUpcomingEvents";
import { useSQCalendar } from "souqh-react-redux-hooks/common/useSQCalendar";
import { useSQQuery } from "souqh-react-redux-hooks/common/useSQQuery";
import { isMobileDevice } from "../../../utils/AppUtils";
import MovingChecklist from "./MovingChecklist";
import { HOME_BUYER_USER } from "../../ServiceProvider/serviceProviderConstants";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  marginTop20: {
    marginTop: 20,
  },
  tabs: {
    minWidth: "22%",
    fontWeight: "bold",
    textTransform: "capitalize",
    opacity: "100%",
    fontSize: 14,
  },
}));

let TAB_NAMES = ["Upcoming Events", "Pending Actions"];
let TAB_NAMES_HB = ["Upcoming Events", "Pending Actions", "Moving Checklist"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Grid item> {children}</Grid>}
    </div>
  );
}

export default function UpcomingEeventsPendingActions() {
  const classes = useStyles();

  const [tabs, setTabs] = useState(TAB_NAMES_HB)
  const {
    order,
    orderBy,
    handleRequestSort,
    getAllPendingActions,
    pendingActionsList,
    eventsTab,
    handlePendingAction,
    openDocumentAccessRequestDialog,
    setOpenDocumentAccessRequestDialog,
    openProfileAccessRequestDialog,
    setOpenProfileAccessRequestDialog,
    openViewTagDialog,
    setOpenViewTagDialog,
    openRequestedDocumentDialog,
    setOpenRequestedDocumentDialog,
    openAddReviewDialog,
    setOpenAddReviewDialog,
    eventAndActionsearchText,
    openHomeBuyingJourneyDialog,
    setOpenHomeBuyingJourneyDialog,
    openOtherActionDialog,
    setOpenOtherActionDialog,
    openRecommendedSPDialog,
    setOpenRecommendedSPDialog,
    openAddMemberDialog,
    setOpenAddMemberDialog,
    openInviteSPDialogForPendingAction,
    setOpenInviteSPDialogForPendingAction,
    searchEventsAndActions,
    requestedDocAccessName,
    documentAccessRequestorSP,
    acceptDocumentAccessRequest,
    openViewInvoiceDialog,
    setOpenViewInvoiceDialog,
    viewInvoiceDialogData,
    userInfo,
    viewTagDialogData,
    openDocumetDialog,
    setOpenDocumetDialog,
    documentItem,
    setDocumentItem,
    setEventIndex,
    openSignDocumentDialog,
    setOpenSignDocumentDialog,
    signDocumentItem,
    setSignDocumentItem,
    reloadPendingActionData,
    deletePendingAction,
    requestedDocumentData,
    recommendedSpData,
    otherActionData,
    profileAccessData,
    acceptRejectProfileAccessRequest,
    downloadUrl,
    docAccessRequestedData,
    actions: { setEventsTab },
    docCategoryId,
    docNameForDownload,
    pendingActions,
    handlePageChange,
    displayPendingActionsData,
    setDisplayPendingActionsData,
    handleMovingChecklist,
    getAllMovingChecklist,
    movingChecklist,
    openMovingChecklistPopup,
    setOpenMovingChecklistPopup,
    setMovingChecklistCheckedValue,
    openMovingChecklistAddressPopup,
    setOpenMovingChecklistAddressPopup,
  } = useUpcomingEeventsPendingActions();

  const {
    onUploadTemplateFileChange,
    openAddTemplate,
    closeAndFinishAddTemplate,
    actions: { setOpenAddTemplate },
  } = useAddTemplate();
  const { updateIntercomStatus } = useDocuments();

  const {
    handleEventsRequestSort,
    eventsOrder,
    eventsOrderBy,
    upcomingEventsList,
    getUpcomingCalendarEvents,
    getAttendeesTooltip,
    handleEventDialogClose,
    cancelEvent,
    eventSelected,
    setEventSelected,
    clearUpcomingEventsDataDestroy,
    getFileretedUpcomingEvents,
  } = useUpcomingEvents();

  const {
    actions: {
      setOpenCreateMeetingDialog,
      setMeetingMode,
      setOpenConfirmCanelMeetingDialog,
    },
    openCreateMeetingDialog,
    meetingMode,
    selectedCalendarEvent,
    openConfirmCanelMeetingDialog,
    cancelMeeting,
    title,
    setTitle,
    meetingDate,
    setMeetingDate,
    meetingLink,
    meetingPhoneNumber,
    meetingLocation,
    setMeetingLocation,
    meetingAttachments,
    description,
    setDescription,
    uploadRef,
    textAreaRef,
    contactInputRef,
    handleDialogClose,
    processAttachment,
    removeAttachment,
    copyToClipboard,
    meetingStartTime,
    setMeetingStartTime,
    meetingEndTime,
    setMeetingEndTime,
    addCustomItem,
    selectedContacts,
    customContact,
    setCustomContact,
    allContacts,
    removeSelectedContact,
    addItemOnSelect,
    getAllContacts,
    invalidTimes,
    setInvalidTimes,
    setMeetingData,
    removeMeetingLink,
    addMeetingLink,
    setupEmptyData,
    saveMeeting,
    validateTimeSlot,
    isFullDayEvent,
    setIsFullDayEvent,
    getCalendarEventDetails,
    eventUserId,
    hasFilledMandatoryData,
    updateMeetingStatus,
    downlodAttachment,
    filteredContacts,
    downloadCalendarAttachment,
  } = useSQCalendar({
    calendarListUpdater: null,
    upcomingEventsUpdater: getUpcomingCalendarEvents,
  });

  const { query } = useSQQuery();
  const history = useHistory();


  useEffect(() => {
    let unlisten = history.listen((location, action) => {
      let tab = location.search.split("=");
      setEventsTab(parseInt(tab[tab.length - 1]));
    });
    const tabIndex = query.get("tabIndex");
    if (tabIndex) {
      if (tabIndex === "0" || tabIndex === "1" || tabIndex === "2") {
        setEventsTab(parseInt(tabIndex));
      } else {
        setEventsTab(0);
      }
    } else {
      setEventsTab(0);
    }
    getAllPendingActions();
    getUpcomingCalendarEvents(0);
    if (userInfo.userType === HOME_BUYER_USER) {
      getAllMovingChecklist()
    }
    getAllContacts();
    return () => {
      closeAndFinishAddTemplate();
      setEventsTab(null);
      unlisten()
    };
  }, []);

  useEffect(() => {
    if (!userInfo) {
      clearUpcomingEventsDataDestroy();
    } else {
      if (userInfo.userType === HOME_BUYER_USER) {
        setTabs(TAB_NAMES_HB)
      } else {
        setTabs(TAB_NAMES)
      }
    }
  }, [userInfo]);

  return (
    <Grid container xs={12} style={{ marginBottom: 30 }}>
      <ProfileAccessRequestDialog
        openProfileAccessRequestDialog={openProfileAccessRequestDialog}
        setOpenProfileAccessRequestDialog={setOpenProfileAccessRequestDialog}
        profileAccessData={profileAccessData}
        acceptRejectProfileAccessRequest={acceptRejectProfileAccessRequest}
      />
      <ViewTagDialog
        openViewTagDialog={openViewTagDialog}
        setOpenViewTagDialog={setOpenViewTagDialog}
        viewTagDialogData={viewTagDialogData}
      />
      <ViewOtherActionDialog
        openOtherActionDialog={openOtherActionDialog}
        setOpenOtherActionDialog={setOpenOtherActionDialog}
        otherActionData={otherActionData}
      />
      <RecommendedSPDialog
        openRecommendedSPDialog={openRecommendedSPDialog}
        setOpenRecommendedSPDialog={setOpenRecommendedSPDialog}
        recommendedSpData={recommendedSpData}
      />
      <Typography variant="h4" className={Styles.navyBlueColor}>
        Events and Actions
      </Typography>

      <Grid container xs={12} style={{ marginTop: 16 }} alignItems="center">
        <Grid item xs={12} md={9}>
          <TextField
            fullWidth
            label={
              eventsTab === 0
                ? "Search upcoming events here"
                : "Search your actions here"
            }
            variant="outlined"
            value={eventAndActionsearchText}
            style={{ backgroundColor: "#ffffff" }}
            onChange={(event) => searchEventsAndActions(event.target.value)}
          />
        </Grid>
        {/* <Grid container item xs={3} justifyContent="flex-end"></Grid> */}
      </Grid>
      <Grid item container xs={12} style={{ marginTop: 8 }}>
        <Paper
          elevation={0}
          style={{
            width: "100%",
            padding: isMobileDevice() ? "0px 8px" : "0px 20px",
          }}
        >
          <Grid container item xs={12}>
            <Grid item xs={12} md={11}>
              <Tabs
                value={eventsTab}
                aria-label="client-management-tabs"
                classes={{ indicator: Styles.indicator }}
              >
                {tabs.map((tab, index) => (
                  <Tab
                    key={index}
                    label={`${tab} (${
                      index === 1
                        ? pendingActions
                          ? pendingActions.totalItems
                          : 0
                      : index === 2
                        ? movingChecklist
                          ? movingChecklist.length
                          : 0
                        : upcomingEventsList
                        ? upcomingEventsList.length
                        : 0
                    })`}
                    className={clsx(classes.tabs, {
                      [Styles.primaryTxtAccentForce]: index === eventsTab,
                      [Styles.navyBlueColorForce]: index !== eventsTab,
                    })}
                    onClick={() => {
                      setEventsTab(index)
                      if (userInfo.userType === HOME_BUYER_USER) {
                        history.push(`/homebuyer/events?tabIndex=${index}`);
                      } else {
                        history.push(`/serviceprovider/events?tabIndex=${index}`);
                      }
                    }}
                  />
                ))}
              </Tabs>
            </Grid>
            {!isMobileDevice() && (
              <Grid
                item
                xs={1}
                container
                justifyContent="flex-end"
                alignItems="center"
              >
                <Tooltip title="Coming Soon">
                  <img src="/images/filter.svg" />
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
      <Grid item container xs={12} style={{ marginTop: 8 }}>
        <Paper
          elevation={0}
          style={{ width: "100%", padding: !isMobileDevice() && "0px 20px" }}
        >
          <TabPanel value={eventsTab} index={0}>
            <UpcomingEvents
              handleEventsRequestSort={handleEventsRequestSort}
              eventsOrder={eventsOrder}
              eventsOrderBy={eventsOrderBy}
              upcomingEventsList={upcomingEventsList}
              getAttendeesTooltip={getAttendeesTooltip}
              userInfo={userInfo}
              openConfirmCanelMeetingDialog={openConfirmCanelMeetingDialog}
              setOpenConfirmCanelMeetingDialog={
                setOpenConfirmCanelMeetingDialog
              }
              handleEventDialogClose={handleEventDialogClose}
              cancelEvent={cancelEvent}
              eventSelected={eventSelected}
              setEventSelected={setEventSelected}
              getFileretedUpcomingEvents={getFileretedUpcomingEvents}
              eventAndActionsearchText={eventAndActionsearchText}
              getCalendarEventDetails={getCalendarEventDetails}
              setMeetingData={setMeetingData}
              setMeetingMode={setMeetingMode}
              setOpenCreateMeetingDialog={setOpenCreateMeetingDialog}
              openCreateMeetingDialog={openCreateMeetingDialog}
              meetingMode={meetingMode}
              cancelMeeting={cancelMeeting}
              selectedCalendarEvent={selectedCalendarEvent}
              title={title}
              setTitle={setTitle}
              meetingDate={meetingDate}
              setMeetingDate={setMeetingDate}
              meetingLink={meetingLink}
              meetingPhoneNumber={meetingPhoneNumber}
              meetingLocation={meetingLocation}
              setMeetingLocation={setMeetingLocation}
              meetingAttachments={meetingAttachments}
              description={description}
              setDescription={setDescription}
              uploadRef={uploadRef}
              textAreaRef={textAreaRef}
              contactInputRef={contactInputRef}
              meetingStartTime={meetingStartTime}
              setMeetingStartTime={setMeetingStartTime}
              meetingEndTime={meetingEndTime}
              setMeetingEndTime={setMeetingEndTime}
              handleDialogClose={handleDialogClose}
              processAttachment={processAttachment}
              removeAttachment={removeAttachment}
              copyToClipboard={copyToClipboard}
              addCustomItem={addCustomItem}
              selectedContacts={selectedContacts}
              customContact={customContact}
              setCustomContact={setCustomContact}
              allContacts={allContacts}
              removeSelectedContact={removeSelectedContact}
              addItemOnSelect={addItemOnSelect}
              invalidTimes={invalidTimes}
              setInvalidTimes={setInvalidTimes}
              removeMeetingLink={removeMeetingLink}
              addMeetingLink={addMeetingLink}
              setupEmptyData={setupEmptyData}
              saveMeeting={saveMeeting}
              validateTimeSlot={validateTimeSlot}
              isFullDayEvent={isFullDayEvent}
              setIsFullDayEvent={setIsFullDayEvent}
              eventUserId={eventUserId}
              hasFilledMandatoryData={hasFilledMandatoryData}
              updateMeetingStatus={updateMeetingStatus}
              downlodAttachment={downlodAttachment}
              filteredContacts={filteredContacts}
              downloadCalendarAttachment={downloadCalendarAttachment}
            />
          </TabPanel>
          <TabPanel value={eventsTab} index={1}>
            <PendingActions
              order={order}
              orderBy={orderBy}
              handleRequestSort={handleRequestSort}
              getAllPendingActions={getAllPendingActions}
              pendingActionsList={pendingActionsList}
              handlePendingAction={handlePendingAction}
              openAddReviewDialog={openAddReviewDialog}
              setOpenAddReviewDialog={setOpenAddReviewDialog}
              openHomeBuyingJourneyDialog={openHomeBuyingJourneyDialog}
              setOpenHomeBuyingJourneyDialog={setOpenHomeBuyingJourneyDialog}
              openAddMemberDialog={openAddMemberDialog}
              setOpenAddMemberDialog={setOpenAddMemberDialog}
              openInviteSPDialogForPendingAction={
                openInviteSPDialogForPendingAction
              }
              setOpenInviteSPDialogForPendingAction={
                setOpenInviteSPDialogForPendingAction
              }
              openAddTemplate={openAddTemplate}
              setOpenAddTemplate={setOpenAddTemplate}
              onUploadTemplateFileChange={onUploadTemplateFileChange}
              updateIntercomStatus={updateIntercomStatus}
              openDocumentAccessRequestDialog={openDocumentAccessRequestDialog}
              setOpenDocumentAccessRequestDialog={
                setOpenDocumentAccessRequestDialog
              }
              requestedDocAccessName={requestedDocAccessName}
              documentAccessRequestorSP={documentAccessRequestorSP}
              acceptDocumentAccessRequest={acceptDocumentAccessRequest}
              openViewInvoiceDialog={openViewInvoiceDialog}
              setOpenViewInvoiceDialog={setOpenViewInvoiceDialog}
              viewInvoiceDialogData={viewInvoiceDialogData}
              userInfo={userInfo}
              openDocumetDialog={openDocumetDialog}
              setOpenDocumetDialog={setOpenDocumetDialog}
              documentItem={documentItem}
              setDocumentItem={setDocumentItem}
              setEventIndex={setEventIndex}
              openSignDocumentDialog={openSignDocumentDialog}
              setOpenSignDocumentDialog={setOpenSignDocumentDialog}
              signDocumentItem={signDocumentItem}
              setSignDocumentItem={setSignDocumentItem}
              reloadPendingActionData={reloadPendingActionData}
              openRequestedDocumentDialog={openRequestedDocumentDialog}
              setOpenRequestedDocumentDialog={setOpenRequestedDocumentDialog}
              deletePendingAction={deletePendingAction}
              requestedDocumentData={requestedDocumentData}
              closeAndFinishAddTemplate={closeAndFinishAddTemplate}
              downloadUrl={downloadUrl}
              docAccessRequestedData={docAccessRequestedData}
              docCategoryId={docCategoryId}
              docNameForDownload={docNameForDownload}
              pendingActions={pendingActions}
              handlePageChange={handlePageChange}
              displayPendingActionsData={displayPendingActionsData}
              setDisplayPendingActionsData={setDisplayPendingActionsData}
            />
          </TabPanel>
          <TabPanel value={eventsTab} index={2}>
            <MovingChecklist
              order={order}
              orderBy={orderBy}
              handleRequestSort={handleRequestSort}
              getAllPendingActions={getAllPendingActions}
              pendingActionsList={pendingActionsList}
              handlePendingAction={handlePendingAction}
              userInfo={userInfo}
              setEventIndex={setEventIndex}
              reloadPendingActionData={reloadPendingActionData}
              deletePendingAction={deletePendingAction}
              pendingActions={pendingActions}
              handlePageChange={handlePageChange}
              displayPendingActionsData={displayPendingActionsData}
              setDisplayPendingActionsData={setDisplayPendingActionsData}
              handleMovingChecklist={handleMovingChecklist}
              movingChecklist={movingChecklist}
              openMovingChecklistPopup={openMovingChecklistPopup}
              setOpenMovingChecklistPopup={setOpenMovingChecklistPopup}
              setMovingChecklistCheckedValue={setMovingChecklistCheckedValue}
              openMovingChecklistAddressPopup={openMovingChecklistAddressPopup}
              setOpenMovingChecklistAddressPopup={setOpenMovingChecklistAddressPopup}
            />
          </TabPanel>
        </Paper>
      </Grid>
    </Grid>
  );
}
