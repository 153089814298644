import { Button, Grid, Switch, TextField, Typography } from "@material-ui/core";
import React from "react";
import { useVerifyPromocode } from "souqh-react-redux-hooks/serviceProvider/plans/useSubscriptionPlan";
import Styles from "../../../Styles.module.css";

export default function Promocode({
  page = "",
  isCampaign = false,
  selectedProvinces,
}) {
  const {
    havePromoCode,
    promoCode,
    verifyPromoCodeData,
    verifyPromoCode,
    actions: { setPromoCode, setHavePromoCode, resetPromoCode },
  } = useVerifyPromocode(isCampaign, page, selectedProvinces);
  return (
    <Grid
      item
      style={{
        backgroundColor: "#ffffff",
        borderRadius: 8,
        padding: page !== "marketingCampaign" && page !== "teamMember" && 20,
        marginTop: 10,
        border: page === "registration" && "1px solid #17174c1f",
      }}
    >
      {page === "teamMember" ? null : (
        <Grid
          container
          item
          sm={12}
          style={{ marginBottom: 10 }}
          alignItems="center"
        >
          <Typography variant="body1" className={Styles.navyBlueColor}>
            Have a promo code?
          </Typography>
          <Switch
            className={"sqGraySwitch"}
            checked={havePromoCode}
            onChange={(e) => {
              setHavePromoCode(e.target.checked);
            }}
          ></Switch>
        </Grid>
      )}
      {(havePromoCode || page === "teamMember") && (
        <>
          <Grid alignItems="center" container spacing={2}>
            <Grid item xs={9}>
              <TextField
                size="small"
                id="addPromoCode"
                label="Add promo code"
                name="addPromoCode"
                onChange={(e) => {
                  resetPromoCode();
                  setPromoCode(e.target.value);
                }}
                value={promoCode}
                variant="outlined"
              />
            </Grid>
            <Grid container item xs={3} justifyContent="flex-end">
              <Button
                variant="outlined"
                color="primary"
                disabled={!promoCode}
                onClick={() => {
                  verifyPromoCode((res) => {});
                }}
              >
                Verify
              </Button>
            </Grid>
          </Grid>
          {verifyPromoCodeData && (
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <Typography
                variant="body2"
                style={{
                  color: verifyPromoCodeData.result ? "green" : "red",
                }}
              >
                {verifyPromoCodeData.message}.
              </Typography>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}
