import React from "react";
import {
  Grid,
  TextField,
  makeStyles,
  IconButton,
  Divider,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useReview } from "souqh-react-redux-hooks/serviceProvider/storefront/useReviews";
import Styles from "../../../Styles.module.css";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
}));
function Review({ index, removeReview }) {
  const {
    review,
    validations,
    actions: { setReview },
  } = useReview(index);
  const classes = useStyles();
  return (
    <Grid container direction="column">
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        style={{ width: "100%", marginTop: 10, marginBottom: 15 }}
      />
      <Grid item container justifyContent="flex-end">
        <IconButton
          aria-label="Delete Address"
          component="span"
          style={{ color: "#0e1c4e" }}
          onClick={() => {
            removeReview({ index: index });
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          placeholder="Review Source Name"
          variant="outlined"
          required
          className={classes.textField}
          value={review.title}
          inputProps={{
            maxLength: 100,
          }}
          onChange={(event) => {
            setReview({
              index,
              field: "title",
              value: event.target.value,
            });
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          error={!!validations.link}
          helperText={validations.link}
          placeholder="Website Link"
          variant="outlined"
          fullWidth
          value={review.link}
          inputProps={{
            maxLength: 200,
          }}
          onChange={(event) => {
            setReview({
              index,
              field: "link",
              value: event.target.value,
            });
          }}
        />
      </Grid>
    </Grid>
  );
}

export default Review;
