import { Grid } from "@material-ui/core";
import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AccountPreferences from "./AccountPreferences";
import ECommerceSettings from "./ECommerceSettings";
import EmailPreferences from "./EmailPreferences.";
import PaymentHistory from "./PaymentHistory";
import SubscriptionAndPaymentSettings from "./SubscriptionAndPaymentSettings";
import TeamManagement from "./TeamManagement";

export default function SettingsRightPanel() {
  let { path } = useRouteMatch();

  return (
    <Grid
      item
      xs={12}
      style={{
        height: "100%",
        overflow: "hidden",
        paddingTop: 32,
        marginRight: 50,
      }}
    >
      <Switch>
        <Route exact path={`${path}/account`}>
          <AccountPreferences />
        </Route>
        <Route exact path={`${path}`}>
          <AccountPreferences />
        </Route>
        <Route exact path={`${path}/email`}>
          <EmailPreferences />
          {/* Email Preferences */}
        </Route>
        <Route exact path={`${path}/team`}>
          <TeamManagement />
        </Route>
        <Route exact path={`${path}/subscription`}>
          <SubscriptionAndPaymentSettings />
        </Route>
        <Route exact path={`${path}/ecommerce`}>
          <ECommerceSettings />
        </Route>
        <Route exact path={`${path}/payment`}>
          <PaymentHistory />
        </Route>
      </Switch>
    </Grid>
  );
}
