import React, { useState, useEffect } from "react";
import { CSVDownloader, CSVReader } from "react-papaparse";
import {
  Divider,
  Grid,
  Paper,
  Typography,
  makeStyles,
  Switch,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Checkbox,
  TableCell,
  TableBody,
  IconButton,
  Tabs,
  Tab,
  TextField,
  Button,
  Snackbar,
} from "@material-ui/core";
import {
  useTeam,
  useValidationForLicensePractioner,
  useMember,
  useTeamMobileNumber,
} from "souqh-react-redux-hooks/serviceProvider/useSetUp";
import { filter, map, some } from "lodash";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmationDialog from "../../ConfirmationDialog";
import AddIcon from "@material-ui/icons/Add";
import GetAppIcon from "@material-ui/icons/GetApp";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import Alert from "@material-ui/lab/Alert";
import Styles from "../../../Styles.module.css";
import StarsIcon from "@material-ui/icons/Stars";
import { useTheme } from "souqh-react-redux-hooks/serviceProvider/storefront/useTheme";
import MaskedMobileInput from "../../MaskedMobileInput";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "20px 0px",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    "& .MuiTypography-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    color: "#0e1c4e",
    textTransform: "capitalize",
    marginRight: 20,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function TeamSection({ error, setError }) {
  const classes = useStyles();
  const { primaryColor, secondaryColor } = useTheme();

  const {
    user,
    additionalUsersSupport,
    additionalUsers,
    allowAction,
    actions: {
      setAdditionalUserSupport,
      addAdditionalUser,
      deleteAdditionalUser,
      bulkAddAdditionalUsers,
      saveCurrentTeamMember,
    },
  } = useTeam();
  const {
    validations: teamValidations,
    auTitle,
    setAuTitle,
    auFullName,
    setAuFullName,
    email,
    setEmail,
    allowAdd,
  } = useMember();

  const {
    isRandomContactNo,
    phoneNumber,
    actions: { setMemberMobileNumber },
    validations: profileValidations,
    addValidations: mobileAddValidations,
  } = useTeamMobileNumber();
  const { addValidations, validations } = useValidationForLicensePractioner();
  const [memberTab, setmemberTab] = useState(0);
  const [cvsRawData, setcvsRawData] = useState(null);
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
    useState(false);
  const [index, setindex] = useState();
  const [csvError, setCsvError] = useState();
  const [csvSuccessToast, setcsvSuccessToast] = useState(false);

  useEffect(() => {
    if (cvsRawData) {
      const data = map(
        filter(cvsRawData, (d, i) => i !== 0),

        (d) => ({
          title: d.data[0],
          fullName: d.data[1],
          email: d.data[2],
        })
      );

      bulkAddAdditionalUsers({ aUsers: data });
    } else {
    }
  }, [cvsRawData]);

  return (
    <Grid item className={classes.root} id="your_team">
      <ConfirmationDialog
        open={openDeleteConfirmationDialog}
        handleYes={() => {
          deleteAdditionalUser({ index: index });
          setOpenDeleteConfirmationDialog(false);
          setError("");
        }}
        handleNo={() => {
          setOpenDeleteConfirmationDialog(false);
        }}
        sectionTitle={"Do you want to delete this Team member?"}
      ></ConfirmationDialog>
      <Typography
        variant="h4"
        className={Styles.navyBlueColor}
        style={{ marginBottom: 8, marginTop: 36 }}
      >
        Your Team
      </Typography>

      <Typography
        variant="body1"
        className={Styles.navyBlueColor}
        style={{ marginBottom: 36 }}
      >
        Add your team members and other service providers that you work with
      </Typography>

      <Grid>
        <Paper elevation={0}>
          <Grid container style={{ padding: "10px 26px 10px 23px" }}>
            <Typography variant="subtitle1" className={Styles.navyBlueColor}>
              Primary Contact
            </Typography>
          </Grid>
          <Divider />
          <Grid item style={{ padding: "10px 26px 10px 23px" }}>
            <Typography
              variant="subtitle1"
              className={Styles.navyBlueColor}
              style={{ fontStyle: "italic" }}
            >
              {user.firstName + " " + user.lastName}
            </Typography>
            <Typography className={Styles.navyBlueColor}>
              {user.email}
            </Typography>
            {!isRandomContactNo ? (
              <Typography className={Styles.navyBlueColor}>
                {`(${phoneNumber && phoneNumber.substring(0, 3)})-${
                  phoneNumber && phoneNumber.substring(3, 6)
                }-${
                  phoneNumber && phoneNumber.substring(6, phoneNumber.length)
                }`}
              </Typography>
            ) : (
              <MaskedMobileInput
                value={phoneNumber}
                error={!!profileValidations.phoneNumber}
                helperText={
                  profileValidations.phoneNumber &&
                  profileValidations.phoneNumber.join(" ")
                }
                id="phoneNumber"
                label="Mobile Number"
                name="phoneNumber"
                variant="outlined"
                required
                type="tel"
                fullWidth
                className={classes.textField}
                onChange={(e) => {
                  setMemberMobileNumber(e.target.value.replaceAll("-", ""));
                }}
              />
            )}
          </Grid>
        </Paper>
      </Grid>
      <Paper style={{ marginTop: 20 }} elevation={0}>
        <Grid item container style={{ padding: "10px 26px 10px 23px" }}>
          <Grid item xs={10}>
            <Typography variant="subtitle1" className={Styles.navyBlueColor}>
              Would you like to add additional users for this storefront?
            </Typography>
          </Grid>
          <Grid item container xs={2} justifyContent="flex-end">
            <Switch
              className={"sqGraySwitch"}
              checked={additionalUsersSupport}
              onChange={(e) => {
                setAdditionalUserSupport({ check: e.target.checked });
              }}
            ></Switch>
          </Grid>
        </Grid>
      </Paper>
      {additionalUsersSupport && (
        <>
          {additionalUsers.length ? (
            <Grid style={{ marginTop: 20 }}>
              <Paper elevation={0}>
                <Grid container style={{ padding: "10px 26px 10px 23px" }}>
                  <Typography
                    variant="subtitle1"
                    className={Styles.navyBlueColor}
                  >
                    Current Team ({additionalUsers.length})
                  </Typography>
                </Grid>
                <Divider />
                <Grid item>
                  <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow className={"navyBlueTableRow"}>
                          <TableCell>
                            <Checkbox color={Styles.navyBlueColorForce} />
                          </TableCell>
                          <TableCell style={{ fontSize: 16 }}>Title</TableCell>
                          <TableCell style={{ fontSize: 16 }}>
                            Full Name
                          </TableCell>
                          <TableCell style={{ fontSize: 16 }}>
                            Email Address
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {additionalUsers.length &&
                          additionalUsers.map((a, index) => (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row">
                                <Checkbox color={Styles.navyBlueColorForce} />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  required
                                  value={a.title}
                                  onChange={(e) => {
                                    saveCurrentTeamMember({
                                      field: "title",
                                      value: e.target.value,
                                      index,
                                    });
                                  }}
                                  inputProps={{
                                    maxLength: 100,
                                    className: Styles.navyBlueColor,
                                  }}
                                ></TextField>
                              </TableCell>
                              <TableCell>
                                <TextField
                                  required
                                  value={a.fullName}
                                  onChange={(e) => {
                                    saveCurrentTeamMember({
                                      field: "fullName",
                                      value: e.target.value,
                                      index,
                                    });
                                  }}
                                  inputProps={{
                                    maxLength: 256,
                                    className: Styles.navyBlueColor,
                                  }}
                                ></TextField>
                              </TableCell>
                              <TableCell>
                                <TextField
                                  required
                                  value={a.email}
                                  onChange={(e) => {
                                    saveCurrentTeamMember({
                                      field: "email",
                                      value: e.target.value,
                                      index,
                                    });
                                    setError("");
                                  }}
                                  inputProps={{
                                    maxLength: 256,
                                    className: Styles.navyBlueColor,
                                  }}
                                ></TextField>
                              </TableCell>
                              <TableCell>
                                <DeleteIcon
                                  className={Styles.navyBlueColor}
                                  onClick={() => {
                                    setOpenDeleteConfirmationDialog(true);
                                    setindex(index);
                                  }}
                                ></DeleteIcon>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                    {!allowAction && (
                      <Typography variant="body2" style={{ color: "red" }}>
                        Please enter a title,name and email_id should not blank.
                      </Typography>
                    )}
                  </TableContainer>
                </Grid>
                {error && <Typography color="error">{error}</Typography>}
              </Paper>
            </Grid>
          ) : (
            ""
          )}

          <Grid style={{ marginTop: 20 }}>
            <Paper elevation={0}>
              <Grid container style={{ padding: "10px 26px 10px 23px" }}>
                <Typography
                  variant="subtitle1"
                  className={Styles.navyBlueColor}
                >
                  Invite Team Members
                </Typography>
              </Grid>
              <Divider />
              <Tabs
                value={memberTab}
                onChange={(event, newValue) => {
                  setmemberTab(newValue);
                }}
                aria-label="simple tabs example"
                classes={{ indicator: Styles.indicator }}
              >
                <Tab
                  label="Add Manually"
                  style={{
                    color: "#0E1C4E",
                    fontWeight: "500",
                    textTransform: "capitalize",
                  }}
                />
                <Tab
                  label="Upload a list"
                  style={{
                    color: "#0E1C4E",
                    fontWeight: "500",
                    textTransform: "capitalize",
                  }}
                />
              </Tabs>
            </Paper>
          </Grid>
          <Grid style={{ marginTop: 10 }}>
            {csvSuccessToast && (
              <Snackbar
                open={csvSuccessToast}
                autoHideDuration={6000}
                onClose={() => {
                  setcsvSuccessToast(false);
                }}
              >
                <Alert variant="filled" severity="success">
                  You have successfully imported your team
                </Alert>
              </Snackbar>
            )}
            <Paper elevation={0}>
              <TabPanel value={memberTab} index={0}>
                <Grid container style={{ padding: 10 }}>
                  <TextField
                    error={!isNaN(parseInt(auTitle))}
                    helperText={
                      !isNaN(parseInt(auTitle)) && "Enter valid title"
                    }
                    required
                    label="Job Title"
                    fullWidth
                    value={auTitle}
                    onChange={(e) => {
                      setAuTitle(e.target.value);
                    }}
                    variant="outlined"
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                  <TextField
                    required
                    label="Full Name"
                    error={!isNaN(parseInt(auFullName))}
                    helperText={
                      !isNaN(parseInt(auFullName)) && "Enter valid Full name"
                    }
                    fullWidth
                    value={auFullName}
                    onChange={(e) => {
                      setAuFullName(e.target.value);
                    }}
                    variant="outlined"
                    inputProps={{
                      maxLength: 256,
                      className: Styles.navyBlueColor,
                    }}
                  />
                  <TextField
                    error={!!teamValidations.email}
                    helperText={teamValidations.email}
                    required
                    label="Email Address"
                    fullWidth
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    variant="outlined"
                    inputProps={{
                      maxLength: 256,
                      className: Styles.navyBlueColor,
                    }}
                  />
                </Grid>
                <Divider />
                <Grid
                  container
                  style={{ padding: 10 }}
                  justifyContent="flex-end"
                >
                  <Button
                    disabled={!(auTitle || auFullName || email)}
                    variant="outlined"
                    color="primary"
                    style={{ textTransform: "capitalize", marginRight: 20 }}
                    onClick={() => {
                      setAuTitle("");
                      setAuFullName("");
                      setEmail("");
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    disabled={
                      !allowAdd ||
                      !isNaN(parseInt(auFullName)) ||
                      !isNaN(parseInt(auTitle))
                    }
                    variant="contained"
                    className={Styles.primaryBtnAccent}
                    startIcon={<AddIcon />}
                    onClick={() => {
                      addAdditionalUser({
                        aUser: {
                          title: auTitle,
                          fullName: auFullName,
                          email: email,
                        },
                      });
                      setAuTitle("");
                      setAuFullName("");
                      setEmail("");
                      setError("");
                    }}
                  >
                    Add
                  </Button>
                </Grid>
              </TabPanel>
              <TabPanel value={memberTab} index={1}>
                <Grid container style={{ padding: 10 }}>
                  <Typography variant="body1" className={Styles.navyBlueColor}>
                    Download this CSV template to import multiple contacts
                  </Typography>
                </Grid>
                <Grid
                  container
                  style={{ padding: 10 }}
                  alignItems="center"
                  // justifyContent="space-evenly"
                >
                  <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    style={{ padding: 10 }}
                  >
                    <Grid item xs={7}>
                      Step 1: Download blank template
                    </Grid>
                    <Grid item xs={5}>
                      <CSVDownloader
                        data={[
                          {
                            Title: "",
                            "Full Name": "",
                            "Email Address": "",
                          },
                        ]}
                        filename={"Souqh_Contacts_Import_Template"}
                        bom={true}
                      >
                        <Button
                          variant="outlined"
                          startIcon={<GetAppIcon />}
                          classes={{ root: classes.button }}
                        >
                          Download
                        </Button>
                      </CSVDownloader>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    style={{ padding: 10 }}
                  >
                    <Grid item xs={7}>
                      Step 2: Upload completed template
                    </Grid>
                    <Grid item xs={5} className={"sqCVSReader"}>
                      <CSVReader
                        id="dumb"
                        onDrop={(data) => {
                          let list = [];
                          data.forEach((item) => {
                            list.push(...item.data);
                          });
                          // check if there is any empty row added
                          if (data[data.length - 1]["data"].length <= 1) {
                            data.splice(data.length - 1, 1);
                            list.splice(list.length - 1, 1);
                          }
                          if (list.some((cell) => !cell)) {
                            setCsvError(true);
                          } else {
                            setcvsRawData(data);
                            setCsvError(false);
                            setcsvSuccessToast(true);
                          }
                        }}
                        onError={(err, file, inputElem, reason) => {
                          console.log(
                            "err, file, inputElem, reason",
                            err,
                            file,
                            inputElem,
                            reason
                          );
                        }}
                        // addRemoveButton
                        onRemoveFile={(data) => {
                          setcvsRawData(data);
                        }}
                      >
                        <Button
                          variant="outlined"
                          startIcon={<ImportContactsIcon />}
                          classes={{ root: classes.button }}
                          style={{ width: 128, marginRight: 0 }}
                        >
                          Import
                        </Button>
                      </CSVReader>
                      {csvError && (
                        <Typography style={{ color: "red" }}>
                          This file has invalid details. Please upload a file
                          with valid details.
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
            </Paper>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default TeamSection;
