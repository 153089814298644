import { useState } from "react";
import {
  SEND_INVOICE_ADD_FILE,
  SEND_INVOICE_REMOVE_FILE,
} from "souqh-redux/reducers/actionConstants";
import { useSelector, useDispatch } from "react-redux";
import { useAuthUser } from "../../useLogin";
import { useApiClient } from "../../useApiClient";
import { cloneDeep } from "lodash";
import { useActions } from "../../useActions";

const actionCreators = {
  addFiles: (payload) => ({
    type: SEND_INVOICE_ADD_FILE,
    payload,
  }),
  deleteFile: (payload) => ({
    type: SEND_INVOICE_REMOVE_FILE,
    payload,
  }),
};

export const useSendInvoiceDialog = (primaryApplicant, serviceRequestId) => {
  const userInfo = useAuthUser();
  const dispatch = useDispatch();

  const { apiClient } = useApiClient();
  const actions = useActions(actionCreators);
  const { sendInvoiceDcouments } = useSelector(
    (state) => state.clientManagement
  );
  const [amount, setAmount] = useState(0);
  const [comments, setComments] = useState("");

  const sendInvoice = () => {
    const formData = new FormData();
    const data = {
      clientEmailId: primaryApplicant.email,
      userId: userInfo.userId, //SP userId
      comments: comments,
      amount: amount,
      serviceRequestId: serviceRequestId,
    };
    formData.append(
      "invoiceContent",
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      })
    );
    sendInvoiceDcouments.forEach((file) => {
      formData.append("invoiceDocs", file, file.name);
    });
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .post(`/service-provider/client/send-invoice`, formData, {})
      .then((res) => {
        // handle success
      })
      .catch((error) => {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    comments,
    setAmount,
    setComments,
    sendInvoice,
    sendInvoiceDcouments,
    amount,
    actions,
  };
};
