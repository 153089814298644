import {
  Grid,
  TableRow,
  TableCell,
  Typography,
  ButtonBase,
  Tooltip,
} from "@material-ui/core";
import React, { useState } from "react";
import Styles from "../../../Styles.module.css";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import Rating from "@material-ui/lab/Rating";
import clsx from "clsx";
import MoreOptionsOnSPInfo from "./MoreOptionsOnSPInfo";
import * as moment from "moment";
import { Link, useHistory } from "react-router-dom";
import ContactNumber from "./ContactNumber";

export default function CompletedSPTableRow({
  data,
  index,
  getSpManagementData,
}) {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const sfDetails = data.storefrontDetails;
  const sfContents = JSON.parse(sfDetails.storeFrontContent);
  const review =
    data.hbServiceReviews &&
    data.hbServiceReviews.length &&
    data.hbServiceReviews.filter(
      (ele) => data.serviceRequestId === ele.serviceRequestId
    )[0];

  return (
    <React.Fragment>
      <MoreOptionsOnSPInfo
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        serviceRequestId={data.serviceRequestId}
        storefrontId={sfDetails.storefrontId}
        displayPicture={sfContents.displayPicture}
        storefrontName={sfContents.storefrontName}
        serviceName={data.serviceName}
        showAddReviewOption={true}
        email={sfDetails.email}
        contactNumber={sfDetails.contactNumber}
        showRequestServiceOption={true}
        showRemoveAndArchiveOption={true}
        storeFrontRouteName={sfDetails.storeFrontRouteName}
        showSendMessageOption={true}
        storefrontDetails={data.storefrontDetails}
        sfServiceId={data.sfServiceId}
        getSpManagementData={getSpManagementData}
        actionType="ARCHIVE_COMPLETED_REQUEST"
      />
      <TableRow>
        <TableCell>
          <Typography className={Styles.navyBlueColor}>{index + 1}</Typography>
        </TableCell>
        <TableCell container>
          {/* <Link
            style={{ textDecoration: "none", cursor: "pointer" }}
            to={`/homebuyer/serviceproviders/details/${data.storefrontDetails.serviceProviderId}`}
          > */}
          <Grid
            container
            style={{
              // padding: !isMobileDevice() && "0px 16px",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push({
                pathname: `/homebuyer/serviceproviders/details/${data.storefrontDetails.serviceProviderId}`,
                state: {
                  email: data.storefrontDetails.email,
                },
              });
            }}
          >
            <Grid container>
              <Grid item xs={3}>
                <img
                  src={sfContents.displayPicture}
                  alt="display picture"
                  title="display picture"
                  style={{
                    height: 50,
                    width: 50,
                    border: "1px solid #0E1C4E1F",
                    borderRadius: 4,
                    marginRight: 10,
                  }}
                />
              </Grid>
              <Grid container item xs={9} alignContent="center">
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    className={Styles.navyBlueColor}
                    style={{ fontWeight: 600 }}
                  >
                    {sfDetails.storeFrontName}
                  </Typography>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={2}>
                    <EmailIcon className={Styles.navyBlueColor} />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography
                      variant="body1"
                      className={clsx(Styles.sqEllipsis, Styles.navyBlueColor)}
                    >
                      {sfDetails.email}
                    </Typography>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={2}>
                      <CallIcon className={Styles.navyBlueColor} />
                    </Grid>
                    <Grid item xs={10}>
                      <ContactNumber contactNumber={sfDetails.contactNumber} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* </Link> */}
        </TableCell>
        <TableCell>
          <Typography className={Styles.navyBlueColor}>
            {moment(data.requestedDate).format("MMM DD, YYYY")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography className={Styles.navyBlueColor}>
            {moment(data.completeDate).format("MMM DD, YYYY")}
          </Typography>
        </TableCell>
        <TableCell>
          {review ? (
            <Grid container>
              <Grid container item xs={12}>
                <Grid item xs={3} lg={4}>
                  <Rating
                    name="rating"
                    value={review.starRating}
                    className={Styles.navyBlueColorForce}
                    style={{ fontSize: 16 }}
                    readOnly
                  />
                </Grid>
                <Grid item xs={9} lg={8} style={{ paddingLeft: 10 }}>
                  <Typography
                    className={clsx(Styles.navyBlueColor, Styles.font14)}
                  >
                    {review.serviceName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Tooltip title={review.reviewComments}>
                  <Typography
                    className={clsx(
                      Styles.navyBlueColor,
                      Styles.sqEllipsisTwoLines
                    )}
                  >
                    {review.reviewComments}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography className={clsx(Styles.navyBlueColor, Styles.font14)}>
                No details available
              </Typography>
            </Grid>
          )}
        </TableCell>
        <TableCell>
          <ButtonBase>
            <MoreHorizIcon
              onClick={handleClick}
              className={Styles.navyBlueColor}
            />
          </ButtonBase>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
