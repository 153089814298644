import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";

export default function ConfirmCancelMeetingDialog({
  openConfirmCanelMeetingDialog,
  setOpenConfirmCanelMeetingDialog,
  onClose,
  cancelMeeting,
  cancelEvent,
}) {
  return (
    <Dialog fullWidth open={openConfirmCanelMeetingDialog}>
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">
          Cancel {cancelMeeting ? " Meeting" : " Event"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: 120 }}>
          Are you sure to cancel this {cancelMeeting ? " meeting" : " event"}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => setOpenConfirmCanelMeetingDialog(false)}
          className={Styles.navyBlueColorForce}
          style={{ textTransform: "capitalize", marginRight: 16 }}
        >
          No
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            if (cancelEvent) {
              cancelEvent(() => {
                onClose();
              });
            } else if (cancelMeeting) {
              cancelMeeting(() => {
                onClose();
              });
            }
          }}
          className={clsx(Styles.navyBlueColorForce, Styles.primaryBtnAccent)}
          style={{ textTransform: "capitalize" }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
