import { cloneDeep } from "lodash";
import * as moment from "moment";
import { MESSAGE_GROUP_NAME_PREFIX } from "../components/ServiceProvider/serviceProviderConstants";

export const sortArrayNumberColumn = (list, key, asc) => {
  if (list && key) {
    const fullList = cloneDeep(list);
    const trueList = fullList.filter((item) => item[key]);
    const falseList = fullList.filter((item) => !item[key]);

    trueList.sort((a, b) => {
      if (a[key] > b[key]) {
        return asc ? 1 : -1;
      }
      if (a[key] < b[key]) {
        return asc ? -1 : 1;
      }
      return 0;
    });

    return trueList.concat(falseList);
  }
  return list;
};

export const mergeArrayOfObjects = (original, newdata, uniqueSelector = "") => {
  newdata.forEach((dat) => {
    const foundIndex = original.findIndex(
      (ori) => ori[uniqueSelector] === dat[uniqueSelector]
    );
    if (foundIndex >= 0) {
      original.splice(foundIndex, 1, dat);
    } else {
      original.push(dat);
    }
  });
  return original;
};

export const escapeRegExp = (str) => {
  if (str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  } else {
    return str;
  }
};

export const transformMsgDate = (value) => {
  let date = null;
  let dateString = "";
  if (value) {
    date = new Date(value);
    if (date.toString() === "Invalid Date") {
      return "";
    }
    if (date && date.getMonth) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];
      let minutes = date.getMinutes();
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      let hours = date.getHours();
      let noonString = "am";
      if (hours === 12) {
        hours = hours;
        noonString = "pm";
      }
      if (hours === 0) {
        hours = 12;
        noonString = "am";
      }
      if (hours > 12) {
        hours = hours - 12;
        noonString = "pm";
      }
      dateString = hours + ":" + minutes + " " + noonString;
    }
  }
  return dateString;
};

export const userChannelMap = (usersList, membershipList, member) => {
  const userChannObj = {};
  const newUserList = cloneDeep(usersList);
  if (newUserList && membershipList && member) {
    const singleChannels = membershipList.filter(
      (item) => !isGroupChannel(item.Name)
    );
    newUserList
      .filter((user) => member.Username !== user.email)
      .forEach((user) => {
        const re1 = getChannelNameRegx(member.Username, user.email);
        const re2 = getChannelNameRegx(user.email, member.Username);

        const filteredChannel = singleChannels.find((channel) => {
          if (re1.test(channel.Name) || re2.test(channel.Name)) {
            return channel;
          }
        });

        if (filteredChannel) {
          user.ChannelArn = filteredChannel.ChannelArn;
          user.Name = filteredChannel.Name;
        } else {
          user.ChannelArn = null;
          user.Name = null;
        }
        user.LastMessageTimestamp = deriveLastMessageTimestamp(filteredChannel);
        user.Username = user.email;
        user.fullName = (user.fullName || "").trim();
        user.profilePhoto = user.profilePhoto;
        user.Value = user.cognitoProfileId;
        user.isGroup = false;
        userChannObj[user.email] = user;
      });
  }

  const userChannelList = [];
  Object.keys(userChannObj).forEach((item) => {
    userChannelList.push(userChannObj[item]);
  });

  const groupChannels = membershipList.filter((item) =>
    isGroupChannel(item.Name)
  );
  groupChannels.forEach((item) => {
    if (!userChannelList.find((el) => el.Name === item.Name)) {
      const tempChannel = {};
      tempChannel.ChannelArn = item.ChannelArn;
      tempChannel.Name = item.Name;
      tempChannel.Username = item.Name;
      tempChannel.fullName = getDisplayGroupName(item.Name);
      tempChannel.isGroup = true;
      tempChannel.profilePhoto = null;
      tempChannel.Value = null;
      tempChannel.LastMessageTimestamp = deriveLastMessageTimestamp(item);
      userChannelList.push(tempChannel);
    }
  });

  return userChannelList;
};

export const getChannelNameRegx = (firstUser, secondUser) => {
  return new RegExp(
    `(?=.*${escapeRegExp(firstUser)})(?=.*${escapeRegExp(secondUser)})`
  );
};

export const deriveLastMessageTimestamp = (data) => {
  if (data && data.LastMessageTimestamp) {
    const seconds = data.LastMessageTimestamp.Seconds || null;
    if (seconds) {
      return new Date(seconds * 1000).getTime();
    }
  }
  return null;
};

export const isGroupChannel = (channelName) => {
  return (channelName || "").startsWith(MESSAGE_GROUP_NAME_PREFIX);
};

export const getDisplayGroupName = (name) => {
  return (name || "").replace(MESSAGE_GROUP_NAME_PREFIX, "");
};

export const getMessageGroupData = (currentChannelMessages) => {
  if (currentChannelMessages) {
    const tempMessages = cloneDeep(currentChannelMessages);
    tempMessages.map((item) => {
      const msgDate = moment(item.CreatedTimestamp);
      const todayDate = moment(Date.now());
      if (msgDate.isSame(Date.now(), "day")) {
        item.msgDateGroup = "Today";
      } else {
        item.msgDateGroup = msgDate.format("MMM DD, YYYY");
      }
    });

    const group = tempMessages.reduce((r, a) => {
      r[a.msgDateGroup] = [...(r[a.msgDateGroup] || []), a];
      return r;
    }, {});
    return group;
  }
  return {};
};

export const removeDuplicates = (arr, field) => {
  return [...new Map(arr.map((item) => [item[field], item])).values()];
};
