import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
export const useOfficeAddressReview = () => {
  const addresses = useSelector((state) => state.spSetup.addresses);
  const [lastIndex, setLastIndex] = useState(0);
  const [displayArray, setDisplayArray] = useState([]);
  useEffect(() => {
    const tempAddress = cloneDeep(addresses);
    if (tempAddress.length) {
      setDisplayArray(tempAddress.splice(0, 2));
    }
  }, [addresses]);
  const handleNext = () => {
    const index = cloneDeep(lastIndex);
    const tempAddress = cloneDeep(addresses);

    if (lastIndex < addresses.length - 1) {
      setLastIndex((prevActiveStep) => prevActiveStep + 2);
      setDisplayArray(tempAddress.splice(index + 2, 2));
    }
  };
  const handleBack = () => {
    const index = cloneDeep(lastIndex);
    const tempAddress = cloneDeep(addresses);
    if (lastIndex < addresses.length) {
      setLastIndex((prevActiveStep) => prevActiveStep - 2);
      setDisplayArray(tempAddress.splice(index - 2, 2));
    }
  };
  return {
    addresses,
    lastIndex,
    handleNext,
    handleBack,
    displayArray,
  };
};
