import { Link, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Styles from "../Styles.module.css";
import "./TermsAndPolicy.css";

export default function TermsOfUse() {
  return (
    <Typography className="body" className={Styles.navyBlueColor}>
      <Typography variant="subtitle1" className="heading">
        Service Provider Terms and Conditions of Use
      </Typography>
      <Typography align="justify" className="container">
        <Typography className="subHeading">
          <u>ACCEPTANCE OF THE WEBSITE TERMS AND CONDITIONS OF USE</u>
        </Typography>
        <div className="para">
          These website terms and conditions of use for{" "}
          <Link underline="none">www.souqh.ca</Link>, constitute a legal
          agreement and are entered into by and between you and Souqh
          Technologies Inc.{" "}
          <span className="subHeading">("Souqh," "we," "us," "our")</span>. The
          following terms and conditions, together with any documents and/or
          additional terms they expressly incorporate by reference
          (collectively, these
          <span className="subHeading">"Terms and Conditions"),</span> govern
          your access to and use, including any content, functionality, and
          services offered on or through{" "}
          <Link underline="none">www.souqh.ca</Link> (
          <span className="subHeading">"Website"</span> or{" "}
          <span className="subHeading">"Service"</span>).
        </div>
        <div className="para subHeading">
          BY USING THE WEBSITE OR BY CLICKING TO ACCEPT THE TERMS AND
          CONDITIONS, YOU ACCEPT AND AGREE TO BE BOUND AND COMPLY WITH THESE
          TERMS AND CONDITIONS AND OUR PRIVACY POLICY, FOUND{" "}
          <Link
            href="/privacypolicy"
            underline="always"
            target="_blank"
            rel="noreferrer"
          >
            HERE
          </Link>
          , INCORPORATED HEREIN BY REFERENCE. IF YOU DO NOT AGREE TO THESE TERMS
          AND CONDITIONS OR THE PRIVACY POLICY, YOU MUST NOT ACCESS OR USE THE
          SERVICE.
        </div>
        <div className="para">
          By using this Service, you represent and warrant that:
          <div className="para">
            <ol type="a">
              <li>
                you are the legal age of majority under applicable law to form a
                binding contract with Souqh.
              </li>
              <li>
                you have not previously been suspended or removed from using the
                Service.
              </li>
              <li>
                you have full power and authority to enter into these Terms and
                Conditions and in doing so will not violate any other agreement
                to which you are a party
              </li>
              <li>
                if you are agreeing to these Terms and Conditions on behalf of a
                corporate entity, such corporate entity is duly organized and
                validly existing under the applicable laws of the jurisdiction
                of its organization and you have the legal authority to bind
                such corporate entity to these Terms and Conditions (in which
                case the terms “you” or “users” shall refer to such corporate
                entity).
              </li>
              <li>
                if you are associated with or a member or licensee of a
                governing organization or entity (e.g. Canadian Real Estate
                Association, Ontario Real Estate Association, Canadian Mortgage
                Brokers Association, the Law Society of Ontario, etc.), you are
                in good standing with such a governing organization or entity;
                and
              </li>
              <li>
                monies used in connection with the Service do not represent
                proceeds of crime for the purposes of the{" "}
                <i>
                  Proceeds of Crime (Money Laundering) and Terrorist Financing
                  Act
                </i>
                (Canada) <span className="subHeading">(“PCMLTFA”)</span> and you
                acknowledge that we may in the future be required by law to
                disclose your information in our database on a confidential
                basis, pursuant to the PCMLTFA. To the best of your knowledge
                (i) none of the funds to be provided by you have been or will be
                derived from or related to any activity that is deemed criminal
                under the law of Canada, the United States of America, or any
                other jurisdiction, or are being tendered on behalf of a person
                or entity who has not been identified to you; and (ii) if you
                discover that any of such representations cease to be true, you
                shall promptly notify us and provide us with appropriate
                information in connection therewith.
              </li>
            </ol>
          </div>
        </div>
        <div className="para subHeading">
          <u>MODIFICATIONS TO THE TERMS AND CONDITIONS AND TO THE SERVICE</u>
        </div>
        <div className="para">
          We reserve the right in our sole discretion to revise and update these
          Terms and Conditions from time to time. Any and all such modifications
          are effective immediately upon posting and apply to all access to and
          continued use of the Service. You agree to periodically review the
          terms and conditions in order to be aware of any such modifications
          and your continued use shall be your acceptance of these.
        </div>
        <div className="li">
          The information and material on this Website, and the Service, may be
          changed, withdrawn, or terminated at any time in our sole discretion
          without notice. We will not be liable if, for any reason, all or any
          part of the Service is restricted to users or unavailable at any time
          or for any period.
        </div>
        <div className="para subHeading">
          <u>Your Use of the Service and Account Set-Up and Security</u>
        </div>
        <div className="li">
          The security of your personal information is very important to us. We
          use physical, electronic, and administrative measures designed to
          secure your personal information from accidental loss and from
          unauthorized access, use, alteration, and disclosure.
        </div>
        <div className="li">
          The safety and security of your information also depends on you. Users
          are responsible for obtaining their own access to the Service. Users
          are required to ensure that all persons who access the Service through
          a user's internet connection are aware of these Terms and Conditions
          and comply with them. The Service, including content or areas of the
          Website, may require user registration. It is a condition of your use
          of the Service that all the information you provide on the Website is
          correct, current, and complete.
        </div>
        <div className="li">
          Unfortunately, the transmission of information via the Internet is not
          completely secure. Although we do our best to protect your personal
          information, we cannot guarantee the security of your personal
          information transmitted to our Website. Any transmission of personal
          information is at your own risk. We are not responsible for
          circumvention of any privacy settings or security measures contained
          on the Website.
        </div>
        <div className="li">
          Your provision of registration information and any submissions you
          make to the Website through any functionality such as applications,
          dashboards, storefronts, e-mail, personal, or interest group web
          pages, profiles, forums, bulletin boards, account creation,
          onboarding, profile creation, document management portal, support
          chats and digital marketing campaigns (collectively,{" "}
          <span className="subHeading">"Interactive Functions"</span>)
          constitutes your consent to all actions we take with respect to such
          information consistent with our Privacy Policy, found at{" "}
          <Link
            href="/privacypolicy"
            underline="always"
            target="_blank"
            rel="noreferrer"
          >
            link
          </Link>
          .
        </div>
        <div className="para">
          Any username, password, or any other piece of information chosen by
          you, or provided to you as part of our security procedures, must be
          treated as confidential, and you must not disclose it to any other
          person or entity. You must exercise caution when accessing your
          account from a public or shared computer so that others are not able
          to view or record your password or other personal information. You
          understand and agree that should you be provided an account, your
          account is personal to you and you agree not to provide any other
          person with access to this Service or portions of it using your
          username, password, or other security information. You agree to notify
          us immediately of any unauthorized access to or use of your username
          or password or any other breach of security. You also agree to ensure
          that you logout from your account at the end of each session. You are
          responsible for any password misuse or any unauthorized access.
        </div>
        <div className="para">
          All account registrations and your use of the Service are subject to
          our review and approval. We reserve the right to deny or disapprove
          your registration and may restrict your use of the Service at our sole
          and unfettered discretion.
        </div>
        <div className="para">
          We reserve the right at any time and from time to time, to disable or
          terminate your account, any username, password, or other identifier,
          whether chosen by you or provided by us, in our sole discretion for
          any or no reason, including any violation of any provision of these
          Terms and Conditions. Without limiting the generality of the
          foregoing, we strictly reserve our right to disable or terminate your
          account if you constantly fall below our minimum rating threshold,
          rated by a verified user with whom you have interacted with, of three
          (3) out of five (5) stars across a total of five (5) consecutive
          reviews for more than three (3) months.
        </div>
        <div className="para">
          You are prohibited from attempting to circumvent and from violating
          the security of this Website, including, without limitation: (a)
          accessing content and data that is not intended for you; (b)
          attempting to breach or breaching the security and/or authentication
          measures which are not authorized; (c) restricting, disrupting or
          disabling service to users, hosts, servers, or networks; (d) illicitly
          reproducing TCP/IP packet header; (e) disrupting network services and
          otherwise disrupting Website owner's ability to monitor the Website;
          (f) using any robot, spider, or other automatic device, process, or
          means to access the Website for any purpose, including monitoring or
          copying any of the material on the Website; (g) introducing any
          viruses, trojan horses, worms, logic bombs, or other material that is
          malicious or technologically harmful; (h) attacking the Website via a
          denial-of-service attack, distributed denial-of-service attack,
          flooding, mailbombing, or crashing; and (i) otherwise attempting to
          interfere with the proper working of the Website.
        </div>
        <div className="para subHeading">
          <u>SERVICE</u>
        </div>
        <div className="para">
          You understand that the Service constitutes an online platform that
          enables individual home buyers and sellers (collectively,
          <span className="subHeading">“Customers”</span>) to connect with
          independent third-party service providers such as real estate
          brokerage, mortgage brokers, real estate lawyers, property appraisers,
          etc. (those service providers,
          <span className="subHeading">“Independent Service Providers”</span>;
          those services provided by Independent Service Providers,{" "}
          <span className="subHeading">“Specialized Services”</span>).
        </div>
        <div className="para">
          HOME BUYERS ACKNOWLEDGE THAT SOUQH DOES NOT IN ANY WAY PROVIDE OR HOLD
          ITSELF OUT AS PROVIDING SPECIALIZED SERVICES AS PART OF THE SERVICE,
          THAT INDEPENDENT SERVICE PROVIDERS ARE NOT EMPLOYED BY SOUQH OR ANY OF
          ITS AFFILIATES, AND THAT SOUQH SHALL NOT BE LIABLE OR RESPONSIBLE FOR
          ANY ACTIONS, OMISSIONS, REPRESENTATIONS, OR WARRANTIES BY INDEPENDENT
          SERVICE PROVIDERS THROUGH THEIR PROVISION OF SPECIALIZED SERVICES
          THROUGH THE WEBSITE.
        </div>
        <div className="para">
          INDEPENDENT SERVICE PROVIDERS ACKNOWLEDGE THAT NO EMPLOYMENT
          RELATIONSHIP IS CREATED BY THESE TERMS AND CONDITIONS OR BY THE
          PROVISION OF THE SERVICE, THAT INDEPENDENT SERVICE PROVIDERS ARE
          STRICTLY CONTRACTORS AND NOTHING IN THESE TERMS AND CONDITIONS WILL
          RENDER THEM AGENTS, PARTNERS OR EMPLOYEES OF SOUQH, AND THAT
          INDEPENDENT SERVICE PROVIDERS WILL NOT HOLD ITSELF OUT AS SUCH.
          NEITHER PARTY WILL HAVE THE RIGHT OR AUTHORITY TO ASSUME, CREATE OR
          INCUR ANY LIABILITY OR ANY OBLIGATION, EXPRESS OR IMPLIED, ON BEHALF
          OF THE OTHER, UNLESS EXPRESSLY STATED IN THESE TERMS AND CONDITIONS.
        </div>
        <div className="para subHeading">
          <u>SUBSCRIPTION AND PAYMENT</u>
        </div>
        <div className="para">
          The prices, features, and options of the Service depend on the
          subscription plan selected as well as any changes instigated by users.
          For example, the capacity of documents allotted will vary across
          different classes of subscription plan, and users can purchase the
          option to run marketing campaigns on the Website to increase exposures
          of their storefronts for select number of days (collectively,
          <span className="subHeading">“Subscription Features”</span>). We do
          not represent or warrant that a particular Subscription Feature will
          be offered indefinitely and reserve the right to change the prices for
          or alter the features and options of our Subscription Features,
          including subscription plans, without prior notice. We, at our sole
          discretion and at any time, may modify the fees associated with any
          and all Subscription Features{" "}
          <span className="subHeading">(“Service Fees”)</span>. Any change to
          the Service Fees will become effective at the end of the then-current
          subscription period or feature-specific period, as applicable. We will
          provide you with reasonable prior notice of any change in Service Fees
          to give you an opportunity to terminate your subscription before such
          change becomes effective. Your continued use of the Service after the
          Service Fee change comes into effect constitutes your agreement to pay
          the modified Service Fee amount.
        </div>
        <div className="para">
          <span>
            You will be billed in arrears or in advance on a recurring and
            periodic basis (such as monthly or annually for subscription plans),
            depending on the type of Subscription Features you select. At the
            end of each period, certain Subscription Features may automatically
            renew under the exact same conditions unless you cancel it or we
            cancel it. Charges for per-use purchases will be billed in arrears.
          </span>
          <span>
            BY COMPLETING REGISTRATION FOR A SUBSCRIPTION FEATURE, YOU AUTHORIZE
            US OR OUR AGENT TO CHARGE YOUR PAYMENT METHOD ON A RECURRING (E.G.
            MONTHLY OR ANNUALLY) BASIS FOR: (A) THE APPLICABLE SUBSCRIPTION
            FEATURE CHARGES; (B) ANY AND ALL APPLICABLE TAXES; AND (C) ANY OTHER
            CHARGES INCURRED IN CONNECTION WITH YOUR USE OF THE SERVICE.
          </span>
        </div>
        <div className="para">
          When you purchase a Subscription Feature, you must provide accurate
          and complete information for a valid payment method that you are
          authorized to use. You will be billed for your Subscription Features
          either through the payment method you provide, such as a credit card,
          or through an intermediary provider such as Google Play, iTunes or a
          similar app store. You must promptly notify us of any change in its
          invoicing address and must update your account with any changes
          related to its payment method. Should automatic billing fail to occur
          for any reason, we will issue an electronic invoice indicating that
          you must proceed manually, within a certain deadline date, with the
          full payment corresponding to the billing period as indicated on the
          invoice.
        </div>
        <div className="para">
          All payments required by this Service are stated exclusive of all
          taxes, duties, levies, imposts, fines, or similar governmental
          assessments, including sales and use taxes, value-added taxes (VAT),
          goods and services taxes/harmonized sales tax (GST/HST), excise,
          business, service, and similar transactional taxes imposed by any
          jurisdiction and the interest and penalties thereon, excluding taxes
          based on Souqh’s net income (collectively,{" "}
          <span className="subHeading">“Taxes”</span>). You shall be responsible
          for and bear Taxes associated with your purchase of, payment for,
          access to or use of the Service. Where the responsibility to remit
          Taxes falls upon us, the Taxes will be added to the payment and
          payable to us at the same time as the payment. Taxes shall not be
          deducted from the payments to us, except as required by law, in which
          case you shall increase the amount payable as necessary so that after
          making all required deductions and withholdings, we receive and retain
          (free from any Tax liability) an amount equal to the amount we would
          have received had no such deductions or withholdings been made. You
          hereby confirm that we can rely on the name and address set forth in
          its registration for a subscription plan as being the place of supply
          for Tax purposes. Souqh’s and your Tax obligations shall survive the
          termination of the Service and these Terms and Conditions.
        </div>
        <div className="para subHeading">
          <u>REFUNDS AND CANCELLATION OF SUBSCRIPTION</u>
        </div>
        <div className="para">
          <span>
            EXCEPT WHEN REQUIRED BY LAW, PAID SERVICE FEES AND ANY OTHER TYPES
            OF UPFRONT PAYMENTS ARE NON-REFUNDABLE.
          </span>
          <span>
            Certain refund requests for subscriptions may be considered by us on
            a case-by-case basis and granted at our sole discretion.
          </span>
        </div>
        <div className="">
          Subject to the terms of your subscription plan, you may cancel your
          subscription renewal either through your account settings page or by
          contacting Souqh. You will not receive a refund for the fees you
          already paid for your current subscription period and you will be able
          to access the Service until the end of your current subscription
          period.
        </div>
        <div className="para subHeading">
          <u>INTELLECTUAL PROPERTY RIGHTS AND OWNERSHIP</u>
        </div>
        <div className="para">
          You understand and agree that the Service and its entire contents,
          features, and functionality, including, but not limited to, all
          information, software, code, text, displays, graphics, photographs,
          video, audio, design, presentation, selection, and arrangement, are
          owned by Souqh, its licensors, or other providers of such material and
          are protected in all forms by intellectual property laws including
          without limitation, copyright, trademark, patent, trade secret, and
          any other proprietary rights.
        </div>
        <div className="para">
          Our name, Souqh, and all related names, logos, product and service
          names, designs, images, and slogans are trademarks of Souqh or its
          affiliates or licensors. You must not use such marks without our prior
          written permission. Other names, logos, product and service names,
          designs, images, and slogans mentioned, or which appear on this
          Website are the trademarks of their respective owners. Use of any such
          property, except as expressly authorized, shall constitute an
          infringement or violation of the rights of the property owner and may
          be a violation of federal or other laws and could subject the
          infringer to legal action. Users are not permitted to modify copies of
          any materials from this Website nor delete or alter any copyright,
          trademark, or other proprietary rights notices from copies of
          materials from this site.
        </div>
        <div className="para">
          If you print off, copy or download any part of our Website in breach
          of these Terms and Conditions, your right to use the Service will
          cease immediately and you must, at our option, return or destroy any
          copies of the materials you have made. You have no right, title, or
          interest in or to the Service or to any content on the Website, and
          all rights not expressly granted are reserved by Souqh. Any use of the
          Service not expressly permitted by these Terms and Conditions is a
          breach of these Terms and Conditions and may infringe or violate
          copyright, trademark, and other intellectual property or other
          proprietary laws.
        </div>
        <div className="para subHeading">
          <u>
            CONDITIONS OF USE AND USER SUBMISSIONS AND SITE CONTENT STANDARDS
          </u>
        </div>
        <div className="para">
          As a condition of your access and use, you agree that you may use the
          Service only for lawful purposes and in accordance with these Terms
          and Conditions.
        </div>
        <div className="para">
          The following content standards apply to any and all content,
          material, and information a user submits, posts, publishes, displays,
          or transmits (collectively, <b>"submit"</b>) to the website, to other
          users or other persons (collectively, <b>"User Submissions"</b>) and
          any and all Interactive Functions. Any and all User Submissions must
          comply with all applicable federal, provincial, local, and
          international laws, regulations, and terms of service.
        </div>
        <div className="para">
          Without limiting the foregoing, you warrant and agree that your use of
          the Service and any User Submissions shall not:
        </div>
        <div className="para">
          <ol type="a">
            <li>
              In any manner violate any applicable federal, provincial, local,
              or international law or regulation including, without limitation,
              any laws regarding the export of data or software, patent,
              trademark, trade secret, copyright, or other intellectual
              property, legal rights (including the rights of publicity and
              privacy of others) or contain any material that could give rise to
              any civil or criminal liability under applicable laws or
              regulations or that otherwise may be in conflict with these Terms
              and Conditions and our Privacy Policy found{" "}
              <Link
                href="/privacypolicy"
                underline="always"
                target="_blank"
                rel="noreferrer"
              >
                here
              </Link>
            </li>
            <li>
              any manner violate the terms of use of any third-party website
              that is linked to the Website, including but not limited to, any
              third-party social media website.
            </li>
            <li>
              Include or contain any material that is exploitive, obscene,
              harmful, threatening, abusive, harassing, hateful, defamatory,
              sexually explicit or pornographic, violent, inflammatory, or
              discriminatory based on race, sex, religion, nationality,
              disability, sexual orientation, or age or other such legally
              prohibited ground or be otherwise objectionable, such
              determination to be made in our sole discretion.
            </li>
            <li>
              Involve stalking, attempting to exploit or harm any individual
              (including minors) in any way by exposing them to inappropriate
              content or otherwise or ask for personal information as prohibited
              under applicable laws, regulations, or code.
            </li>
            <li>
              Involve, provide, or contribute any false, inaccurate, or
              misleading information, including but not limited to
              misrepresenting the business type, service details or custom
              company overview on the storefront.
            </li>
            <li>
              Include sending, knowingly receiving, uploading, downloading,
              using, or reusing any material that does not comply with the User
              Submissions.
            </li>
            <li>
              Impersonate or attempt to impersonate Souqh, a Souqh employee,
              another user, or any other person or entity (including, without
              limitation, by using email addresses, or screen names associated
              with any of the foregoing).
            </li>
            <li>
              Transmit, or procure the sending of, any advertisements or
              promotions without our prior written consent, sales, or encourage
              any other commercial activities, including, without limitation,
              any "spam", "junk mail", "chain letter", contests, sweepstakes and
              other sales promotions, barter, or advertising or any other
              similar solicitation.
            </li>
            <li>
              Misappropriate, use or disclose any personal information of other
              users gathered while using the Service.
            </li>
            <li>
              Encourage any other conduct that restricts or inhibits anyone's
              use or enjoyment of the Service, or which, as determined by us,
              may harm Souqh or users of the Service or expose them to
              liability.
            </li>
            <li>
              Cause annoyance, inconvenience, or needless anxiety or be likely
              to upset, embarrass, or alarm any other person.
            </li>
            <li>
              Promote any illegal activity, or advocate, promote, or assist any
              unlawful act.
            </li>
            <li>
              Give the impression that they originate from or are endorsed by us
              or any other person or entity, if this is not the case.
            </li>
            <li>
              Submit, publish, or otherwise circulate dishonest or ingenuine
              reviews or hearsays of other users or participants of this
              Website.
            </li>
          </ol>
        </div>
        <div className="para subHeading">
          <u>USER SUBMISSIONS: GRANT OF LICENSE</u>
        </div>
        <div className="para">
          The Website may contain Interactive Functions allowing User
          Submissions on or through the Website.
        </div>
        <div className="para">
          By submitting the User Submissions, you declare and warrant that you
          own or have the necessary rights to submit the User Submissions. You
          represent and warrant that all User Submissions comply with applicable
          laws and regulations and the User Submissions set out in these Terms
          and Conditions. You understand and agree that you, not Souqh nor its
          parent, subsidiaries, affiliates nor their respective directors,
          officers, employees, agents, service providers, contractors,
          licensors, suppliers or successors, are fully responsible for any User
          Submissions you submit or contribute, and you are fully responsible
          and legally liable, including to any third party, for such content and
          its accuracy. We are not responsible or legally liable to any third
          party for the content or accuracy of any User Submissions submitted by
          you or any other user of the Service.
        </div>
        <div className="para subHeading">
          <u>SITE MONITORING AND ENFORCEMENT, SUSPENSION, AND TERMINATION</u>
        </div>
        <div className="para">
          We have the right, without provision of notice to:
        </div>
        <div className="para">
          <ul>
            <li className="li">
              Remove or refuse to post on the Website any User Submissions for
              any or no reason in our sole discretion.
            </li>
            <li className="li">
              At all times, take such actions with respect to any User
              Submission deemed necessary or appropriate in our sole discretion,
              including, without limitation, for violating the Website and User
              Submissions and/or Terms and Conditions.
            </li>
            <li className="li">
              Take appropriate legal action, including, without limitation,
              referral to law enforcement or regulatory authority, or notifying
              the harmed party of any illegal or unauthorized use of the
              Service. Without limiting the foregoing, we have the right to
              fully cooperate with any law enforcement authorities or court
              order requesting or directing us to disclose the identity or other
              information of anyone posting any materials on or through the
              Website.
            </li>
            <li className="li">
              Terminate or suspend your access to all or part of the Service for
              any or no reason, including, without limitation, any violation of
              these Terms and Conditions.
            </li>
          </ul>
        </div>
        <div className="para">
          YOU WAIVE AND HOLD HARMLESS SOUQH AND ITS PARENT, SUBSIDIARIES,
          AFFILIATES, AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES,
          AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES,
          SUPPLIERS, AND SUCCESSORS FROM ANY AND ALL CLAIMS RESULTING FROM ANY
          ACTION TAKEN BY SOUQH AND ANY OF THE FOREGOING PARTIES RELATING TO
          ANY, INVESTIGATIONS BY EITHER SOUQH OR SUCH PARTIES OR BY LAW
          ENFORCEMENT AUTHORITIES.
        </div>
        <div className="para">
          We have no obligation, nor any responsibility to any party to monitor
          the Website or its use, and do not and cannot undertake to review
          material that you or other users submit to the Website. We cannot
          ensure prompt removal of objectionable material after it has been
          posted and we have no liability for any action or inaction regarding
          transmissions, communications, or content provided by any user or
          third party, subject to applicable laws.
        </div>
        <div className="para subHeading">
          <u>NO RELIANCE </u>
        </div>
        <div className="para">
          The content on our Website is provided for general information
          purposes only. It is not intended to amount to advice on which you
          should rely. You must obtain more specific or professional advice
          before taking, or refraining from, any action or inaction on the basis
          of the content on our site.
        </div>
        <div className="para">
          Although we make reasonable efforts to update the information on our
          Website, we make no representations, warranties, or guarantees,
          whether express or implied, that the content on our Website is
          accurate, complete, or up to date. Your use of the Service is at your
          own risk and neither Souqh nor its parent, subsidiaries, affiliates,
          and their respective directors, officers, employees, agents, service
          providers, contractors, licensors, licensees, suppliers, or successors
          have any responsibility or liability whatsoever for your use of this
          Service.
        </div>
        <div className="para">
          This Website includes content provided by third parties, including
          from other users and third-party licensors. All statements and/or
          opinions expressed in any such third-party content, other than the
          content provided by Souqh, are solely the opinions and the
          responsibility of the person or entity providing those materials. Such
          materials do not necessarily reflect the opinion of Souqh. Neither
          Souqh nor its parent, subsidiaries, affiliates, and their respective
          directors, officers, employees, agents, service providers,
          contractors, licensors, licensees, suppliers, or successors have any
          responsibility or liability whatsoever to you, or any third party, for
          the content or accuracy of any third-party materials.
        </div>
        <div className="para subHeading">
          <u>PRIVACY </u>
        </div>
        <div className="para">
          By submitting your personal information and using our Website, you
          consent to the collection, use, reproduction, hosting, transmission,
          and disclosure of any such user content submissions in compliance with
          our Privacy Policy, found{" "}
          <Link
            href="/privacypolicy"
            underline="always"
            target="_blank"
            rel="noreferrer"
          >
            here
          </Link>
          , as we deem necessary for use of the Website and provision of
          services.
        </div>
        <div className="para">
          By using this Website you are consenting to the use of cookies which
          allow a server to recall previous requests or registration and/or IP
          addresses to analyze website use patterns. You can set your browser to
          notify you before you receive a cookie, giving you the chance to
          decide whether to accept it. You can also set your browser to turn off
          cookies. If you do, however, some areas of the Website may not
          function adequately.
        </div>
        <div className="para">
          <b>
            <u>THIRD-PARTY WEBSITES </u>
          </b>
        </div>
        <div className="para">
          For your convenience, this Website may provide links or pointers to
          third-party sites. We make no representations about any other websites
          that may be accessed from this Website. If you choose to access any
          such sites, you do so at your own risk. We have no control over the
          contents of any such third-party sites and accept no responsibility
          for such sites or for any loss or damage that may arise from your use
          of them. You are subject to any terms and conditions of such
          third-party sites.
        </div>
        <div className="para">
          You may link to our homepage, provided you do so in a way that is fair
          and legal and does not damage our reputation or take advantage of it.
          You must not establish a link in such a way as to suggest any form of
          association, approval, or endorsement on our part where none exists.
          We reserve the right to withdraw linking permission without notice.
          The website in which you are linking must comply in all respects with
          these Terms and Conditions and User Submissions. You agree to
          cooperate with us in causing any unauthorized framing or linking to
          immediately stop.
        </div>
        <div className="para subHeading">
          <u>THIRD-PARTY API</u>
        </div>
        <div className="para">
          Our Website contains services and contents offered by various service
          providers of certain third-party Application Programming Interfaces
          (APIs)
          <span className="subHeading">(“Third-Party APIs”)</span> to facilitate
          our Service. We make no representations about any of the Third-Party
          APIs contained or used in our Website, and you understand that by
          using our Service, you use our Third-Party APIs at your own risk. By
          agreeing to use our Service, you accept and agree to be bound by the
          respective terms of service of the following service providers, which
          may be modified or updated from time to time:
        </div>
        <div className="para">
          <ul>
            <li className="li">
              <b>Stripe</b> (Click{" "}
              <Link
                href="https://stripe.com/en-ca/legal"
                underline="always"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                here{" "}
              </Link>{" "}
              for the terms of service)
            </li>
            <li className="li">
              <b>SignNow</b> (Click{" "}
              <Link
                href="https://www.signnow.com/terms"
                underline="always"
                target="_blank"
                rel="noreferrer"
              >
                here{" "}
              </Link>{" "}
              for the terms of service)
            </li>
            <li className="li">
              <b>Intercom</b> (Click{" "}
              <Link
                href="https://www.intercom.com/legal/terms-and-policies?on_pageview_event=terms_footer"
                underline="always"
                target="_blank"
                rel="noreferrer"
              >
                here{" "}
              </Link>
              for the terms of service)
            </li>
            <li className="li">
              <b>Upscope</b> (Click{" "}
              <Link
                href="https://upscope.com/legal/"
                underline="always"
                target="_blank"
                rel="noreferrer"
              >
                here{" "}
              </Link>
              for the terms of service)
            </li>
            <li className="li">
              <b>AWS</b> (Click{" "}
              <Link
                href="https://aws.amazon.com/service-terms/"
                underline="always"
                target="_blank"
                rel="noreferrer"
              >
                here{" "}
              </Link>
              for the terms of service)
            </li>
            <li className="li">
              <b>Calendly</b> (Click{" "}
              <Link
                href="https://calendly.com/pages/terms"
                underline="always"
                target="_blank"
                rel="noreferrer"
              >
                here{" "}
              </Link>
              for the terms of service)
            </li>
          </ul>
        </div>
        <div className="para subHeading">
          <u>GEOGRAPHIC RESTRICTIONS</u>
        </div>
        <div className="para">
          Souqh is based in the Province of Ontario in Canada. We provide this
          Service for use only by persons located in Canada for the time being.
          This Service is not intended for use in any jurisdiction where its use
          is not permitted. If you access the Service from outside Canada, you
          do so at your own risk and you are responsible for compliance with
          local laws of your jurisdiction.
        </div>
        <div className="para subHeading">
          <u>COPYRIGHT</u>
        </div>
        <div className="para">
          We take claims of copyright infringement seriously. We will respond to
          notices of alleged copyright infringement. If you believe any
          materials accessible on or from this Website infringe your copyright,
          you may request that we send a notice to the alleged infringer by
          submitting written notification to{" "}
          <Link underline="none">support@souqh.ca</Link> The written notice (the
          <span className="subHeading">"Notice"</span>) must include the
          following:
        </div>
        <div className="para">
          <ul>
            <li className="li">Your name and address.</li>
            <li className="li">
              Identification of the copyrighted work you believe to have been
              infringed or, if the claim involves multiple works on the Website,
              a representative list of such works.
            </li>
            <li className="li">
              A statement describing your interest or right with respect to the
              copyright in the work or other subject matter.
            </li>
            <li className="li">
              Identification of the location of material you believe to be
              infringing in a sufficiently precise manner to allow us to locate
              that material (e.g., a link to the infringing material).
            </li>
            <li className="li">
              Description of the infringement that is claimed.
            </li>
            <li className="li">
              A statement specifying the date and time of the alleged
              infringement.
            </li>
            <li className="li">
              Any other information that may be prescribed by regulation that we
              may advise you of from time-to-time.
            </li>
          </ul>
        </div>
        <div className="para">
          The Notice may <b>not</b> contain any of the following:
        </div>
        <div className="para">
          <ul>
            <li className="li">An offer to settle the claimed infringement.</li>
            <li className="li">
              A request or demand relating to the claimed infringement for
              payment or for personal information.
            </li>
            <li className="li">
              A reference, including by way of hyperlink, to such an offer,
              request, or demand.
            </li>
            <li className="li">
              Any other information that may be prescribed by regulation that we
              may advise you of from time-to-time.
            </li>
          </ul>
        </div>
        <div className="li">
          (the <span className="subHeading">"Prohibited Content"</span>).
        </div>
        <div className="para">
          A Notice containing Prohibited Content will not be considered a valid
          Notice under this Policy.Please be aware that if you knowingly
          materially misrepresent that material or activity on the Website is
          infringing your copyright, you may be held liable for damages
          (including costs and all legal fees, disbursements, and charges).
        </div>
        <div className="para">
          If we receive a Notice in the prescribed form, we will, as soon as
          feasible, forward the Notice electronically to the person to whom the
          electronic location identified by the location data in the Notice
          belongs and inform you of our doing so. If, for some reason, it is not
          possible for us to forward the Notice to such person, we will confirm
          with you the reasons therefor. We will retain records of Notices in
          compliance with our obligations under applicable laws and regulations.
        </div>
        <div className="para">
          Without limiting any other rights or remedies we may be entitled to
          under this Terms and Conditions or otherwise at law, we reserve the
          right to remove any material or disable or terminate our users'
          accounts or take such other actions deemed necessary or appropriate in
          the circumstances in our sole discretion.
        </div>
        <div className="para subHeading">
          <u>DISCLAIMER OF WARRANTIES</u>
        </div>
        <div className="para">
          YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE WEBSITE, ITS CONTENT,
          AND ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE IS AT
          YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS
          FOUND OR ATTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS" AND
          "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OR CONDITIONS OF ANY
          KIND, EITHER EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, THE
          IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, OR NON-INFRINGEMENT. THE FOREGOING DOES NOT AFFECT ANY
          WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
        </div>
        <div className="para">
          NEITHER SOUQH NOR ITS PARENT, SUBSIDIARIES, AFFILIATES, OR THEIR
          RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS,
          CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS MAKE ANY
          WARRANTY, REPRESENTATION, OR ENDORSEMENT WITH RESPECT TO THE
          COMPLETENESS, SECURITY, RELIABILITY, SUITABILITY, ACCURACY, CURRENCY,
          OR AVAILABILITY OF THE WEBSITE OR ITS CONTENTS. WITHOUT LIMITING THE
          FOREGOING, NEITHER SOUQH NOR ITS PARENT, SUBSIDIARIES, AFFILIATES OR
          THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE
          PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS
          REPRESENT OR WARRANT THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR
          ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE WILL BE ACCURATE,
          RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE
          CORRECTED, THAT OUR WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE ARE
          FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
        </div>
        <div className="para">
          WE CANNOT AND DO NOT GUARANTEE OR WARRANT THAT FILES OR DATA AVAILABLE
          FOR DOWNLOADING FROM THE INTERNET OR THE WEBSITE WILL BE FREE OF
          VIRUSES OR OTHER DESTRUCTIVE CODE. YOU ARE SOLELY AND ENTIRELY
          RESPONSIBLE FOR YOUR USE OF THE WEBSITE AND YOUR COMPUTER, INTERNET,
          AND DATA SECURITY. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT
          BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY DENIAL-OF-SERVICE ATTACK,
          DISTRIBUTED DENIAL-OF-SERVICE ATTACK, OVERLOADING, FLOODING,
          MAILBOMBING, OR CRASHING, VIRUSES, TROJAN HORSES, WORMS, LOGIC BOMBS,
          OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR
          COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY
          MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS FOUND
          OR ATTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL
          POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
        </div>
        <div className="para subHeading">
          <u>LIMITATION ON LIABILITY</u>
        </div>
        <div className="para">
          EXCEPT WHERE SUCH EXCLUSIONS ARE PROHIBITED BY LAW, UNDER NO
          CIRCUMSTANCE WILL SOUQH NOR ITS PARENT, SUBSIDIARIES, AFFILIATES OR
          THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE
          PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS
          BE LIABLE FOR NEGLIGENCE, GROSS NEGLIGENCE, NEGLIGENT
          MISREPRESENTATION, FUNDAMENTAL BREACH, DAMAGES OF ANY KIND, UNDER ANY
          LEGAL THEORY, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
          CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO,
          PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF
          REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS,
          LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY
          TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, BREACH OF PRIVACY, OR
          OTHERWISE, EVEN IF THE PARTY WAS ALLEGEDLY ADVISED OR HAD REASON TO
          KNOW, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO
          USE, OR RELIANCE ON, THE WEBSITE, ANY LINKED WEBSITES OR SUCH OTHER
          THIRD-PARTY WEBSITES, NOR ANY WEBSITE CONTENT, MATERIALS, POSTING, OR
          INFORMATION THEREON EVEN IF THE PARTY WAS ALLEGEDLY ADVISED OR HAD
          REASON TO KNOW.
        </div>
        <div className="para subHeading">
          <u>INDEMNIFICATION</u>
        </div>
        <div className="para">
          To the maximum extent permitted by applicable law, you agree to
          defend, indemnify, and hold harmless Souqh, its parent, subsidiaries,
          affiliates, and their respective directors, officers, employees,
          agents, service providers, contractors, licensors, suppliers,
          successors, and assigns from and against any claims, liabilities,
          damages, judgments, awards, losses, costs, expenses, or fees
          (including reasonable attorneys' fees) arising out of or relating to
          your breach of these Terms and Conditions or your use of the Website,
          including, but not limited to, your User Submissions, third-party
          sites, any use of the Website's content, Specialized Services,
          dealings with other users of the Website, and products other than as
          expressly authorized in these Terms and Conditions.
        </div>
        <div className="para subHeading">
          <u>GOVERNING LAW AND CHOICE OF FORUM</u>
        </div>
        <div className="para">
          The Service and these Terms and Conditions will be governed by and
          construed in accordance with the laws of the Province of Ontario and
          the federal laws of Canada applicable therein, without giving effect
          to any choice or conflict of law provision, principle, or rule
          (whether of the laws of the Province of Ontario or any other
          jurisdiction) and notwithstanding your domicile, residence, or
          physical location.
        </div>
        <div className="para">
          Any action or proceeding arising out of or relating to this Service
          and under these Terms and Conditions will be instituted in the courts
          of the City of Toronto in the Province of Ontario and/or the Federal
          Court of Canada, and each party irrevocably submits to the exclusive
          jurisdiction of such courts in any such action or proceeding. You
          waive any and all objections to the exercise of jurisdiction over you
          by such courts and to the venue of such courts.
        </div>
        <div className="para subHeading">
          <u>WAIVER</u>
        </div>
        <div className="para">
          No failure to exercise, or delay in exercising, any right, remedy,
          power, or privilege arising from these Terms and Conditions operates,
          or may be construed, as a waiver thereof. No single or partial
          exercise of any right, remedy, power, or privilege hereunder precludes
          any other or further exercise thereof or the exercise of any other
          right, remedy, power, or privilege.
        </div>
        <div className="para subHeading">
          <u>SEVERABILITY</u>
        </div>
        <div className="para">
          If any term or provision of these Terms and Conditions is invalid,
          illegal, or unenforceable in any jurisdiction, such invalidity,
          illegality, or unenforceability shall not affect any other term or
          provision of these Terms and Conditions or invalidate or render
          unenforceable such term or provision in any other jurisdiction.
        </div>
        <div className="para subHeading">
          <u>ENTIRE AGREEMENT</u>
        </div>
        <div className="para">
          The Terms and Conditions and our Privacy Policy constitute the sole
          and entire agreement between you and Souqh regarding the Service and
          supersedes all prior and contemporaneous understandings, agreements,
          representations and warranties, both written and oral, regarding such
          subject matter.
        </div>
        <div className="para subHeading">
          <u>REPORTING AND CONTACT</u>
        </div>
        <div className="para">
          This Website is operated by Souqh Technologies Inc. located at 37A
          Green Belt Drive, Toronto, ON M3C 1L8.
        </div>
        <div className="para">
          Should you become aware of misuse of the Website including libelous or
          defamatory conduct, you must report it to Souqh at{" "}
          <Link underline="none">support@souqh.ca</Link>
        </div>
        <div className="para">
          All other feedback, comments, requests for technical support, and
          other communications relating to the Service should be directed to{" "}
          <Link underline="none">support@souqh.ca</Link>
        </div>
      </Typography>
    </Typography>
  );
}
