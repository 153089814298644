import React from "react";

import { Typography, Grid } from "@material-ui/core/";
import Styles from "../../../../Styles.module.css";
import clsx from "clsx";
import { useDocumentHistory } from "souqh-react-redux-hooks/serviceProvider/documents/useDocumentHistory";

export default function HistoryTable({ documentHistoryList }) {
  const { getFormattedTimeStamp } = useDocumentHistory();

  const tableRows = (documentHistoryList || []).map((item, index) => {
    return (
      <Grid
        item
        container
        xs={12}
        key={index}
        style={{
          padding: "12px 0px",
          alignItems: "center",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Grid item xs={4}>
          <Typography variant="body1" className={Styles.navyBlueColor}>
            {item.event}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body1" className={Styles.navyBlueColor}>
            {item.email}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body1" className={Styles.navyBlueColor}>
            {getFormattedTimeStamp(item.createdTime)}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1" className={Styles.navyBlueColor}>
            {item.ip_address}
          </Typography>
        </Grid>
      </Grid>
    );
  });

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        height: "100%",
        alignItems: "flex-start",
        alignContent: "flex-start",
        overflow: "auto",
      }}
    >
      <Grid
        item
        container
        xs={12}
        style={{
          height: 60,
          marginTop: 12,
          padding: "12px 0px 12px 0px",
          alignItems: "center",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Grid item xs={4}>
          <Typography variant="subtitle1" className={Styles.navyBlueColor}>
            Event
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1" className={Styles.navyBlueColor}>
            By
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1" className={Styles.navyBlueColor}>
            Time
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1" className={Styles.navyBlueColor}>
            IP address
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        style={{
          alignContent: "flex-start",
        }}
      >
        {tableRows}
      </Grid>
    </Grid>
  );
}
