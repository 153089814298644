import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useActions } from "../../useActions";
import {
  SET_SERVICE_PROVIDERS_TAB,
  SET_SP_MGMT_DATA,
} from "../../../souqh-redux/reducers/actionConstants";
import { useAuthUser } from "../../useLogin";
import { useApiClient } from "../../useApiClient";
import { useActiveSPInfo } from "./useActiveSPInfo";
import { useRequestSPInfo } from "./useRequestSPInfo";
import { useCompletedSPInfo } from "./useCompletedSPInfo";
import { useArchiveSPInfo } from "./useArchiveSPInfo";
import { PAGE_SIZE } from "../../../web/src/components/HomeBuyer/SPManagement/spManagementConstants";

const actionCreator = {
  setServiceProvidersTab: (payload) => ({
    type: SET_SERVICE_PROVIDERS_TAB,
    payload,
  }),
};

export const useSPManagement = () => {
  const [searchSPText, setSearchSPText] = useState("");
  const [displaySPData, setDisplaySPData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const userInfo = useAuthUser();
  const dispatch = useDispatch();
  const { apiClient } = useApiClient();
  const actions = useActions(actionCreator);
  const { active, archive, requests, completed, serviceProvidersTab } =
    useSelector((state) => state.spManagement);
  const spManagement = useSelector((state) => state.spManagement);
  const { getActiveSPData } = useActiveSPInfo(PAGE_SIZE);
  const { getRequestsSPData } = useRequestSPInfo(PAGE_SIZE);
  const { getCompletedSPData } = useCompletedSPInfo(PAGE_SIZE);
  const { getArchiveSPData } = useArchiveSPInfo(PAGE_SIZE);

  const [pageNumber, setPageNumber] = useState(0);
  useEffect(() => {
    setSearchSPText(""); // empty search text on tab change
    switch (serviceProvidersTab) {
      case 0:
        setDisplaySPData(active);
        setOriginalData(active);
        return;
      case 1:
        setDisplaySPData(requests);
        setOriginalData(requests);
        return;
      case 2:
        setDisplaySPData(completed);
        setOriginalData(completed);
        return;
      case 3:
        setDisplaySPData(archive);
        setOriginalData(archive);
        return;
      default:
        return;
    }
  }, [active, serviceProvidersTab]);

  const requestSPSearch = (searchedVal) => {
    setSearchSPText(searchedVal);
    let filteredRows = { response: [], count: 0 };
    originalData &&
      originalData.response &&
      originalData.response.length &&
      originalData.response.map((row) => {
        if (
          //FIX ME: replace name with SP name and add condition for business name

          // row.name.toLowerCase().includes(searchedVal.toLowerCase()) ||
          row.storefrontDetails.email
            .toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row.storefrontDetails.storeFrontName
            .toLowerCase()
            .includes(searchedVal.toLowerCase())
        ) {
          filteredRows.response.push(row);
        }
      });
    filteredRows.count = filteredRows.response.length;
    setDisplaySPData(filteredRows);
  };

  const getSpManagementData = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(
        `/home-buyer/sp/service-request/all/${userInfo.userId}?page=0&size=${PAGE_SIZE}`
      )
      .then(async (response) => {
        if (response.status === 200) {
          dispatch({
            type: SET_SP_MGMT_DATA,
            payload: response.data,
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };
  const handlePageChange = (event, page) => {
    setSearchSPText("");
    setPageNumber(page - 1);
    switch (serviceProvidersTab) {
      case 0:
        getActiveSPData(page - 1);
        break;
      case 1:
        getRequestsSPData(page - 1);
        break;
      case 2:
        getCompletedSPData(page - 1);
        break;
      case 3:
        getArchiveSPData(page - 1);
        break;
    }
  };

  const getDataOnTabChange = () => {
    switch (serviceProvidersTab) {
      case 0:
        getActiveSPData(0);
        return;
      case 1:
        getRequestsSPData(0);
        return;
      case 2:
        getCompletedSPData(0);
        return;
      case 3:
        getArchiveSPData(0);
        return;
      default:
        return;
    }
  };

  return {
    displaySPData,
    searchSPText,
    requestSPSearch,
    serviceProvidersTab,
    actions,
    getSpManagementData,
    spManagement,
    handlePageChange,
    getDataOnTabChange,
    pageNumber,
    getRequestsSPData,
    getArchiveSPData,
  };
};
