import {
  Grid,
  Typography,
  makeStyles,
  withStyles,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Subscription from "../../components/SPSetup/Subscription";
import { useRoleManagement } from "souqh-react-redux-hooks/common/useRoleManagement";
import Styles from "../../Styles.module.css";
import { useConnectedAccount } from "souqh-react-redux-hooks/common/useConnectedAccount";
import { useSQQuery } from "souqh-react-redux-hooks/common/useSQQuery";
import { usePlanUpgradeDowngrade } from "souqh-react-redux-hooks/common/usePlanUpgradeDowngrade";

const Accordion = withStyles({
  root: {
    color: "#2b3350",
  },
  expanded: {},
})(MuiAccordion);

const AccordionDetails = withStyles({
  root: {
    backgroundColor: "#F5F6F8",
  },
  expanded: {},
})(MuiAccordionDetails);

const useStyles = makeStyles(() => ({
  root: {
    color: "#2b3350 !important",
  },
}));

export default function SubscriptionAndPaymentSettings() {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const { isTeamMember } = useRoleManagement();
  const { getExpressAccountUrl, getStripeAccountUrl } = useConnectedAccount();
  const { changeSubscription } = usePlanUpgradeDowngrade();

  const { query } = useSQQuery();

  useEffect(() => {
    const panel = query.get("panel");
    if (panel) {
      setExpanded(panel.toString());
    } else {
      setExpanded(false);
    }
  }, []);

  return (
    <Grid
      item
      xs={12}
      style={{ height: "100%", overflow: "hidden", padding: "0 24px" }}
      className={classes.root}
    >
      <Typography variant="h4">Subscription & Payments</Typography>
      {!isTeamMember() ? (
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ marginTop: 10 }}
          >
            <Typography variant="subtitle1">Change Subscription</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundColor: "#F5F6F8", padding: 0 }}>
            <Typography>
              <Subscription srcPage="settingsPage" />
              {/* Coming Soon */}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ) : null}
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{ marginTop: 10 }}
        >
          <Typography variant="subtitle1">Top-up eSign Envelopes</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Coming soon...</Typography>
        </AccordionDetails>
      </Accordion>
      {!isTeamMember() ? (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            style={{ marginTop: 10 }}
          >
            <Typography variant="subtitle1">
              Manage Payment Method and View Billing History
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Button
              variant="contained"
              onClick={() => {
                getStripeAccountUrl();
              }}
              className={Styles.primaryBtnAccent}
              style={{ textTransform: "inherit" }}
            >
              View billing account on Stripe
            </Button>
          </AccordionDetails>
        </Accordion>
      ) : null}
    </Grid>
  );
}
