import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import Styles from "../../../../Styles.module.css";

import GetAppIcon from "@material-ui/icons/GetApp";
import CloseIcon from "@material-ui/icons/Close";
import { useDocumentPreview } from "souqh-react-redux-hooks/homeBuyer/useDocumentPreview";
import DocumentPreviewRenderer from "../../../DocumentManagementDialogs/DocumentPreviewRenderer";
import { useDownloadFile } from "souqh-react-redux-hooks/useDownloadFile";

function DocumentPreviewDialog({
  openDocumetDialog,
  setOpenDocumetDialog,
  documentItem,
  setDocumentItem,
  source,
  downloadUrl,
  docCategoryId,
  docNameForDownload,
  docUserId,
}) {
  const { eleRef, fileNotLoaded, setRefreshTimer, clearAll, iframeLoaded } =
    useDocumentPreview();
  const { downloadRefDocument } = useDownloadFile();

  useEffect(() => {
    setRefreshTimer(documentItem);
  }, []);

  if (!documentItem) {
    return null;
  }

  return (
    <Dialog fullWidth maxWidth={"lg"} open={openDocumetDialog}>
      <DialogTitle className={Styles.navyBlueColor}>
        <Grid item container xs={12}>
          <Grid item xs={11}>
            <Typography variant="subtitle1">
              File Preview -{" "}
              <span style={{ fontSize: 12 }}>{documentItem.name}</span>
            </Typography>
          </Grid>
          <Grid item xs={1} container style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={() => {
                clearAll(setDocumentItem, setOpenDocumetDialog);
              }}
            >
              <CloseIcon />
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid>
          {fileNotLoaded && (
            <Typography variant="caption" style={{ color: "red" }}>
              Failed to load preview, please download file
            </Typography>
          )}
        </Grid>
        <Grid item container xs={12} style={{ justifyContent: "center" }}>
          <DocumentPreviewRenderer
            documentItem={documentItem}
            iframeLoaded={iframeLoaded}
            eleRef={eleRef}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        {source === "invoice-dailog" ||
        source === "leads-page" ||
        source === "review-document" ||
        source === "documentCheckList" ||
        source === "sp-mgmt" ? (
          // <Link href={downloadUrl} target={"_blank"} download>
          <Button
            variant="outlined"
            startIcon={<GetAppIcon style={{ fill: "#0e1c4e" }} />}
            className={Styles.primaryBtnAccent}
            onClick={() =>
              downloadRefDocument(docCategoryId, docNameForDownload, docUserId)
            }
          >
            Download
          </Button>
        ) : (
          <Link href={documentItem.path} target={"_blank"} download>
            <Button
              variant="outlined"
              startIcon={<GetAppIcon style={{ fill: "#0e1c4e" }} />}
              className={Styles.primaryBtnAccent}
            >
              Download
            </Button>
          </Link>
        )}
        <Button
          variant="outlined"
          className={Styles.primaryBtnAccent}
          style={{
            textTransform: "capitalize",
            margin: "12px 8px 12px 24px",
            paddingRight: 20,
          }}
          onClick={() => {
            clearAll(setDocumentItem, setOpenDocumetDialog);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DocumentPreviewDialog;
