import { cloneDeep } from "lodash";
import {
  ADD_SP_ACTIVE_CONTACT,
  ADD_SP_ASSOC_PROVIDER_CONTACT,
  ADD_SP_DOCUMENTS,
  ADD_SP_DOCUMENT_SUMMARY,
  CLEAR_SP_UPLOAD_FILE_DATA,
  DELETE_SP_DOCUMENT_SUMMARY,
  DELETE_SP_DOCUMENT_WHILE_UPLOAD,
  SET_SP_ASSOCIACTED_SERVICE_PROVIDERS,
  SET_SP_CONTACTS_LIST,
  UPDATE_SP_DOCUMENT_DESCRIPTION,
  ADD_SP_ESIGN_DOCUMENTS,
  UPDATE_SP_ESIGN_DOCUMENT_DESCRIPTION,
  DELETE_SP_ESIGN_DOCUMENT_WHILE_UPLOAD,
  CLEAR_SP_ESIGN_UPLOAD_FILE_DATA,
  EDIT_SP_DOCUMENT_SUMMARY,
  ADD_TEMPLATE_DOCUMENT,
  CLEAR_TEMPLATE_DOCUMENTS,
  DELETE_TEMPLATE_DOCUMENT_WHILE_UPLOAD,
  EDIT_TEMPLATE_DOCUMENT_SUMMARY,
  DELETE_TEMPLATE_DOCUMENT_SUMMARY,
  ADD_TEMPLATE_DOCUMENT_SUMMARY,
  SET_SP_DOCUMENT_SUMMARY,
  SET_TEMPLATE_DOCUMENT_SUMMARY,
  SET_WARNING_DIALOG_FLAG,
  SET_EDIT_ESIGN_DOCUMENT,
  SET_OPEN_ESIGN_DOCUMENT,
  SET_OPEN_ADD_TEMPLATE,
  SET_EDIT_WARNING_DIALOG,
  CLEAR_TEMPLATE_DOCS_AND_CLOSE,
  SET_OPEN_EDIT_TEMPLATE,
  SET_OPEN_INVITE_AND_SEND_TEMPLATE,
  CLEAN_ESIGN_DATA_AND_CLOSE,
  SIGN_NOW_USER_VERIFIED,
} from "./actionConstants";

const initialState = {
  templateSummaryList: [],
  templateDocumentList: [],
  eSignDocumentList: [],
  documentList: [],
  duplicateFileName: false,
  selectedContacts: [],
  activeServiceProviders: [],
  selectedActiveSPs: [],
  contacts: [],
  summaryList: [],
  associactedServiceProviders: [],
  showBackWarningDialog: false,
  openEditEsignDocumentDialog: false,
  openEsignWizard: false,
  openAddTemplate: false,
  openEditWarningDialog: false,
  openInviteAndSendTemplate: false,
  openEditTemplate: false,
  isUserVerifiedForSignNow: true,
};

const spDocument = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case ADD_SP_DOCUMENTS:
      newState = cloneDeep(state);
      if (!action.payload.isDuplicate) {
        newState.documentList = [
          ...newState.documentList,
          ...action.payload.files,
        ];
        newState.duplicateFileName = false;
      } else {
        newState.duplicateFileName = true;
      }
      return newState;
    case UPDATE_SP_DOCUMENT_DESCRIPTION:
      newState = cloneDeep(state);
      const itemTobeModified = newState.documentList[action.payload.index];
      if (itemTobeModified) {
        newState.documentList[action.payload.index].description =
          action.payload.value;
        newState.documentList[
          action.payload.index
        ].hasUpdatedDescription = true;
      }
      return newState;
    case DELETE_SP_DOCUMENT_WHILE_UPLOAD:
      newState = cloneDeep(state);
      newState.documentList.splice(action.payload.index, 1);
      return newState;
    case CLEAR_SP_UPLOAD_FILE_DATA:
      newState = cloneDeep(state);
      newState.documentList = [];
      newState.summaryList = [];
      return newState;
    case ADD_SP_DOCUMENT_SUMMARY:
      newState = cloneDeep(state);
      newState.summaryList.push(action.payload.summary);
    case SET_SP_DOCUMENT_SUMMARY:
      newState = cloneDeep(state);
      newState.summaryList = action.payload;
      return newState;
    case DELETE_SP_DOCUMENT_SUMMARY:
      newState = cloneDeep(state);
      newState.summaryList.splice(action.payload.index, 1);
      return newState;
    case EDIT_SP_DOCUMENT_SUMMARY:
      newState = cloneDeep(state);
      let findItem = newState.summaryList[action.payload.index];
      if (findItem) {
        const newData = action.payload.data;
        findItem.title = newData.title;
        findItem.subtitle = newData.subtitle;
        findItem.description = newData.description;
        findItem.id = newData.id;
      }
      return newState;
    case ADD_SP_ACTIVE_CONTACT:
      newState = cloneDeep(state);
      newState.contacts.push(action.payload.contact);
      return newState;
    case ADD_SP_ASSOC_PROVIDER_CONTACT:
      newState = cloneDeep(state);
      newState.associactedServiceProviders.push(action.payload.contact);
      return newState;
    case SET_SP_CONTACTS_LIST:
      newState = cloneDeep(state);
      newState.contacts = action.payload.contacts;
      return newState;
    case SET_SP_ASSOCIACTED_SERVICE_PROVIDERS:
      newState = cloneDeep(state);
      newState.associactedServiceProviders =
        action.payload.associactedServiceProviders;
      return newState;

    // Cases for update while eSign
    case ADD_SP_ESIGN_DOCUMENTS:
      newState = cloneDeep(state);
      if (!action.payload.isDuplicate) {
        newState.eSignDocumentList = [
          ...newState.eSignDocumentList,
          ...action.payload.files,
        ];
        newState.duplicateFileName = false;
      } else {
        newState.duplicateFileName = true;
      }
      return newState;
    case UPDATE_SP_ESIGN_DOCUMENT_DESCRIPTION:
      newState = cloneDeep(state);
      const eSignItem = newState.eSignDocumentList[action.payload.index];
      if (eSignItem) {
        newState.eSignDocumentList[action.payload.index].description =
          action.payload.value;
        newState.eSignDocumentList[
          action.payload.index
        ].hasUpdatedDescription = true;
      }
      return newState;
    case DELETE_SP_ESIGN_DOCUMENT_WHILE_UPLOAD:
      newState = cloneDeep(state);
      newState.eSignDocumentList.splice(action.payload.index, 1);
      return newState;
    case CLEAR_SP_ESIGN_UPLOAD_FILE_DATA:
      newState = cloneDeep(state);
      newState.eSignDocumentList = [];
      newState.summaryList = [];
      newState.openEditWarningDialog = false;
      return newState;
    case CLEAN_ESIGN_DATA_AND_CLOSE:
      newState = cloneDeep(state);
      newState.eSignDocumentList = [];
      newState.summaryList = [];
      newState.openEsignWizard = false;
      newState.openEditWarningDialog = false;
      newState.openEditEsignDocumentDialog = false;
      return newState;
    case SET_OPEN_ADD_TEMPLATE:
      newState = cloneDeep(state);
      newState.openAddTemplate = action.payload.openAddTemplate;
      return newState;
    case SET_OPEN_EDIT_TEMPLATE:
      newState = cloneDeep(state);
      newState.openEditTemplate = action.payload.openEditTemplate;
      return newState;
    case SET_OPEN_INVITE_AND_SEND_TEMPLATE:
      newState = cloneDeep(state);
      newState.openInviteAndSendTemplate =
        action.payload.openInviteAndSendTemplate;
      return newState;
    case ADD_TEMPLATE_DOCUMENT:
      newState = cloneDeep(state);
      if (!action.payload.isDuplicate) {
        newState.templateDocumentList = [
          ...newState.templateDocumentList,
          ...action.payload.files,
        ];
        newState.duplicateFileName = false;
      } else {
        newState.duplicateFileName = true;
      }
      return newState;
    case CLEAR_TEMPLATE_DOCUMENTS:
      newState = cloneDeep(state);
      newState.templateDocumentList = [];
      newState.templateSummaryList = [];
      return newState;
    case CLEAR_TEMPLATE_DOCS_AND_CLOSE:
      newState = cloneDeep(state);
      newState.templateDocumentList = [];
      newState.templateSummaryList = [];
      newState.openAddTemplate = false;
      newState.openEditTemplate = false;
      newState.openInviteAndSendTemplate = false;
      return newState;

    case DELETE_TEMPLATE_DOCUMENT_WHILE_UPLOAD:
      newState = cloneDeep(state);
      newState.templateDocumentList.splice(action.payload.index, 1);
      return newState;
    case ADD_TEMPLATE_DOCUMENT_SUMMARY:
      newState = cloneDeep(state);
      newState.templateSummaryList.push(action.payload.summary);
      return newState;
    case SET_TEMPLATE_DOCUMENT_SUMMARY:
      newState = cloneDeep(state);
      newState.templateSummaryList = action.payload;
      return newState;
    case DELETE_TEMPLATE_DOCUMENT_SUMMARY:
      newState = cloneDeep(state);
      newState.templateSummaryList.splice(action.payload.index, 1);
      return newState;
    case EDIT_TEMPLATE_DOCUMENT_SUMMARY:
      newState = cloneDeep(state);
      const foundItem = newState.templateSummaryList[action.payload.index];
      if (foundItem) {
        const newData = action.payload.data;
        foundItem.title = newData.title;
        foundItem.subtitle = newData.subtitle;
        foundItem.description = newData.description;
        foundItem.id = newData.id;
      }
      return newState;

    case SET_WARNING_DIALOG_FLAG:
      newState = cloneDeep(state);
      newState.showBackWarningDialog = action.payload.flag;
      return newState;
    case SET_EDIT_ESIGN_DOCUMENT:
      newState = cloneDeep(state);
      newState.openEditEsignDocumentDialog =
        action.payload.openEditEsignDocumentDialog;
      return newState;
    case SET_OPEN_ESIGN_DOCUMENT:
      newState = cloneDeep(state);
      newState.openEsignWizard = action.payload.openEsignWizard;
      return newState;
    case SET_EDIT_WARNING_DIALOG:
      newState = cloneDeep(state);
      newState.openEditWarningDialog = action.payload.openEditWarningDialog;
      return newState;
    case SIGN_NOW_USER_VERIFIED:
      newState = cloneDeep(state);
      // Sign Now returns "true" and "false as string"
      newState.isUserVerifiedForSignNow =
        action.payload === "true" ? true : false;
      return newState;
    default:
      return state;
  }
};

export default spDocument;
