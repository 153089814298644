import { Grid, Typography, Link, Divider } from "@material-ui/core";
import React from "react";
import ActiveSPInfoCard from "./ActiveSPInfoCard";
import ArchiveSPInfoCard from "./ArchiveSPInfoCard";
import CompletedSPInfoCard from "./CompletedSPInfoCard";
import RequestSPInfoCard from "./RequestSPInfoCard";
import Styles from "../../../Styles.module.css";
import Pagination from "@material-ui/lab/Pagination";
import { isMobileDevice } from "../../../utils/AppUtils";

export default function SPInfoGridView({
  tabValue,
  displaySPData,
  handlePageChange,
  getSpManagementData,
  getDataOnTabChange,
  pageNumber,
  getRequestsSPData,
  getArchiveSPData,
}) {
  const renderSwitch = (tabValue, data) => {
    const { storefrontDetails } = data;
    const storeFrontContent =
      data.storefrontDetails &&
      data.storefrontDetails.storeFrontContent &&
      JSON.parse(data.storefrontDetails.storeFrontContent);

    switch (tabValue) {
      case 0:
        return (
          <ActiveSPInfoCard
            data={data}
            storeFrontContent={storeFrontContent}
            storefrontDetails={storefrontDetails}
            getSpManagementData={getSpManagementData}
          />
        );
      case 1:
        return (
          <RequestSPInfoCard
            data={data}
            storeFrontContent={storeFrontContent}
            storefrontDetails={storefrontDetails}
            getSpManagementData={getSpManagementData}
            getDataOnTabChange={getDataOnTabChange}
            pageNumber={pageNumber}
            getRequestsSPData={getRequestsSPData}
            getArchiveSPData={getArchiveSPData}
          />
        );
      case 2:
        return (
          <CompletedSPInfoCard
            data={data}
            storeFrontContent={storeFrontContent}
            storefrontDetails={storefrontDetails}
            getSpManagementData={getSpManagementData}
          />
        );
      case 3:
        return (
          <ArchiveSPInfoCard
            data={data}
            storeFrontContent={storeFrontContent}
            storefrontDetails={storefrontDetails}
            getSpManagementData={getSpManagementData}
          />
        );
    }
  };

  return (
    <Grid container spacing={2}>
      {displaySPData &&
      displaySPData.response &&
      displaySPData.response.length ? (
        displaySPData.response.map((data, index) => (
          <React.Fragment>
            <Grid key={index} item xs={12} md={6} lg={4} xl={3}>
              {renderSwitch(tabValue, data)}
            </Grid>
            {isMobileDevice() && (
              <Divider
                orientation="horizontal"
                variant="fullWidth"
                style={{ width: "calc(100% + 32px)", marginLeft: -20 }}
              />
            )}
          </React.Fragment>
        ))
      ) : tabValue === 1 ? (
        <Typography className={Styles.navyBlueColor}>
          You don’t have any pending service request. Click{" "}
          <Link href="/homebuyer/explore">here</Link> to view service providers
        </Typography>
      ) : (
        <Typography
          className={Styles.navyBlueColor}
          variant="body1"
          style={{ fontWeight: 500, marginLeft: 20 }}
        >
          No data available
        </Typography>
      )}
      {displaySPData &&
      displaySPData.response &&
      displaySPData.response.length ? (
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          style={{ margin: "16px 0" }}
        >
          <Pagination
            count={displaySPData && displaySPData.totalPages}
            shape="rounded"
            variant="outlined"
            onChange={handlePageChange}
          />
        </Grid>
      ) : null}
    </Grid>
  );
}
