import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Typography,
  TextField,
  Paper,
  InputAdornment,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Popper,
  MenuList,
  MenuItem,
  Drawer,
  makeStyles,
  Tooltip,
  ClickAwayListener,
} from "@material-ui/core";
import { Pagination } from "@mui/material";
import Styles from "../../../Styles.module.css";
import SearchIcon from "@material-ui/icons/Search";
import { useTheme, useMediaQuery } from "@material-ui/core";
import NewCampaignDialog from "./NewCampaignDialog";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import clsx from "clsx";
import { cloneDeep } from "lodash";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";
import CloseIcon from "@material-ui/icons/Close";
import NewCampaignStep1 from "./NewCampaignStep1";
import NewCampaignDialogFooter from "./NewCampaignDialogFooter";
import NewCampaignStep2 from "./NewCampaignStep2";
import NewCampaignStep3 from "./NewCampaignStep3";
import { useMarketingCampaign } from "souqh-react-redux-hooks/serviceProvider/marketingCampaign/useMarketingCampaign";
import SearchNotFound from "../../common/SearchNotFound";
import NoDataAvailable from "../../common/NoDataAvailable";
import ConfirmationDialog from "../../ConfirmationDialog";
import { useDispatch } from "react-redux";
import * as moment from "moment";
import MCampDetails from "./MCampDetails";
import { useRoleManagement } from "souqh-react-redux-hooks/common/useRoleManagement";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { Link } from "react-router-dom";
import {
  SET_HAVE_PROMO_CODE,
  SET_PROMO_CODE,
} from "souqh-redux/reducers/actionConstants";

const isMobile = isMobileDevice();

const useStyles = makeStyles((theme) => ({
  fullList: {
    width: "auto",
  },
  paper: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    maxHeight: "90%",
    minHeight: "90%",
  },
}));

function CampaignStatus({ campaignStatus }) {
  let color = "#FA7E61";
  let backgroundColor = "#FA7E6140";

  switch (campaignStatus) {
    case "DRAFT":
      color = "#868DA6";
      backgroundColor = "#c9cff3";
      break;
    case "ACTIVE":
      color = "#2842D0";
      backgroundColor = "#868DA6";
      break;
    case "COMPLETED":
      color = "#0A4C25";
      backgroundColor = "#c3dece";
      break;
    case "CANCELLED":
      backgroundColor = "#700000";
      break;
    default:
      break;
  }
  return (
    <span
      style={{
        backgroundColor,
        borderRadius: 4,
        textAlign: "center",
        padding: "4px 10px",
        color,
      }}
    >
      {campaignStatus || "-"}
    </span>
  );
}

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export default function Marketing() {
  const classes = useStyles();
  const theme = useTheme();
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const { isTeamMember } = useRoleManagement();
  const userInfo = useAuthUser();
  const dispatch = useDispatch();

  const {
    getAllMarketingCampaignData,
    allMarketingCampaignData,
    duplicateMarketingCampaign,
    deleteMarketingCampaign,
    campaignData,
    enableStep1,
    enableStep2,
    requestSearch,
    displayMarketingData,
    searchText,
    locationsServed,
    createMarketingCampaign,
    setDetectForcastedResult,
    dropDownValues,
    validations,
    getMarketingCampaignData,
    setEnableStep2,
    updateMarketingCampaign,
    getMarketingCampaignInvoiceData,
    locationServedActions: { clearLocationServed },
    actions: { setStep1Data, clearData, resetCampaignStartDate },
    totalPages,
    selectedPage,
    checkMarketingCampaignNameExists,
    nameAlreadyExist,
    setNameAlreadyExist,
    setSearchText,
    duplicateCampaignName,
  } = useMarketingCampaign();

  const [innerAnchorRef, setInnerAnchorRef] = useState(null);
  const [selelctedRow, setSelectedRow] = useState(null);
  const [openInnerMenu, setOpenInnerMenu] = useState(false);
  const [rows, setRows] = useState([]);
  const [tabDrawer, setTabDrawer] = useState(false);
  const [activeSection, setActiveSection] = useState(1);

  useEffect(() => {
    getAllMarketingCampaignData();
  }, [searchText]);

  useEffect(() => {
    setRows(displayMarketingData);
  }, [displayMarketingData]);

  useEffect(() => {
    if (tabDrawer && selelctedRow?.id) {
      getMarketingCampaignData(selelctedRow.id);
    }
  }, [tabDrawer, selelctedRow?.id]);

  const handleInnerToggle = () => {
    setOpenInnerMenu((prevOpen) => !prevOpen);
  };

  const debounceHandle = debounce(setSearchText);

  const [openNewCampaignDialog, setOpenNewCampaignDialog] = useState({
    open: false,
    data: null,
  });
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
    useState(false);
  const [openCloseConfirmationDialog, setOpenCloseConfirmationDialog] =
    useState(false);
  const [openMCampDetailsDialog, setMCampDetailsDialog] = useState(false);

  const handleInnerClose = () => {
    setOpenInnerMenu(false);
  };
  const handleCheckboxChange = (checked, id) => {
    const tempList = cloneDeep(displayMarketingData);
    tempList.forEach((item) => {
      if (item.id === id) {
        item.selected = checked;
      }
    });
    setRows(tempList);
  };
  const columns = [
    { field: "campaignName", headerName: "Campaign Name", width: 100 },
    { field: "date", headerName: "Date", width: 150 },
    { field: "status", headerName: "Status", width: 150 },
    { field: "amountSpend", headerName: "Amount Spent\n(CAD)", width: 150 },
    { field: "activeClients", headerName: "User \nEngagement", width: 150 },
    { field: "clicks", headerName: "Impressions", width: 150 },
    { field: "leads", headerName: `Conversions /\nLeads`, width: 150 },
    { field: "accReached", headerName: "Accounts \nReached", width: 150 },
    { field: "cpl", headerName: "Total Cost /\nConversion", width: 150 },
    { field: "actions", headerName: "", width: 150 },
  ];

  const moreActionsList = () => (
    <div className={classes.fullList} role="presentation">
      <Grid
        container
        item
        xs={12}
        style={{
          padding: 16,
          position: "sticky",
          height: 60,
          top: 0,
          zIndex: 1500,
          overflowY: "hidden",
          borderBottom: "1px solid #0E1C4E1F",
          backgroundColor: "white",
        }}
      >
        <Grid item xs={10}>
          <Typography variant="subtitle1" className={Styles.navyBlueColorForce}>
            {`${campaignData.id ? "Edit" : "New"} Campaign ${
              isMobile ? `(${activeSection}/3)` : ""
            }`}
          </Typography>
        </Grid>
        <Grid container item xs={2} justifyContent="flex-end">
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              setOpenCloseConfirmationDialog(true);
              setTabDrawer(false);
              setNameAlreadyExist(false);
            }}
          />
        </Grid>
      </Grid>
      {activeSection === 1 && (
        <NewCampaignStep1
          section={1}
          campaignData={campaignData}
          setStep1Data={setStep1Data}
          dropDownValues={dropDownValues}
          setDetectForcastedResult={setDetectForcastedResult}
          displayMarketingData={displayMarketingData}
          validations={validations}
          checkMarketingCampaignNameExists={checkMarketingCampaignNameExists}
          nameAlreadyExist={nameAlreadyExist}
        />
      )}
      {activeSection === 2 && (
        <NewCampaignStep2
          section={2}
          campaignData={campaignData}
          setStep1Data={setStep1Data}
          resetCampaignStartDate={resetCampaignStartDate}
          setDetectForcastedResult={setDetectForcastedResult}
          dropDownValues={dropDownValues}
        />
      )}
      {activeSection === 3 && (
        <NewCampaignStep3
          section={3}
          campaignData={campaignData}
          locationsServed={locationsServed}
          setDetectForcastedResult={setDetectForcastedResult}
        />
      )}
      <NewCampaignDialogFooter
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        enableStep1={enableStep1}
        enableStep2={enableStep2}
        createMarketingCampaign={createMarketingCampaign}
        setOpenNewCampaignDialog={setOpenNewCampaignDialog}
        campaignId={campaignData.id}
        updateMarketingCampaign={updateMarketingCampaign}
        getMarketingCampaignInvoiceData={getMarketingCampaignInvoiceData}
        handleClose={handleClose}
        duplicateCampaignName={duplicateCampaignName}
      />
    </div>
  );

  const newCampaignBtnGrid = () => {
    return (
      <Grid container item xs={12} md={3} lg={2} justifyContent="flex-end">
        <Button
          fullWidth={isMobileDevice() ? true : false}
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize", padding: "10px 15px" }}
          onClick={() => {
            setSelectedRow && setSelectedRow(null);
            handleInnerClose();
            isMobileDevice() || isTabletDevice()
              ? setTabDrawer(true)
              : setOpenNewCampaignDialog({ open: true });
          }}
        >
          New Campaign
        </Button>
      </Grid>
    );
  };

  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    clearLocationServed && clearLocationServed();
    setActiveSection(1);
    clearData && clearData();
    setOpenNewCampaignDialog({ open: false });
    setTabDrawer(false);
    setSelectedRow && setSelectedRow(null);
    setNameAlreadyExist(false);
    dispatch({
      type: SET_PROMO_CODE,
      payload: "",
    });
    dispatch({
      type: SET_HAVE_PROMO_CODE,
      payload: false,
    });
    dispatch({
      type: "SET_VERFIY_PROMOCODE",
      payload: "",
    });
  };

  const MyPopper = ({ openInnerMenu, handleInnerClose }) => (
    <ClickAwayListener
      onClickAway={() => {
        handleInnerClose();
        handleClose();
      }}
    >
      <Popper
        open={openInnerMenu}
        anchorEl={innerAnchorRef}
        placement="bottom-end"
      >
        <Paper>
          <Paper>
            <MenuList
              autoFocusItem={openInnerMenu}
              id="menu-list-grow"
              className={Styles.navyBlueColor}
            >
              <MenuItem
                disabled={
                  selelctedRow &&
                  (selelctedRow.status === "ACTIVE" ||
                    selelctedRow.status === "COMPLETED")
                }
                onClick={() => {
                  if (selelctedRow.status !== "ACTIVE") {
                    handleInnerClose();
                    isMobileDevice()
                      ? setTabDrawer(true)
                      : setOpenNewCampaignDialog({
                          open: true,
                          data: selelctedRow,
                        });
                  }
                }}
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={() =>
                  duplicateMarketingCampaign(selelctedRow.id, (res) => {
                    if (res && res.status === 200) {
                      handleInnerClose();
                    }
                  })
                }
              >
                Duplicate
              </MenuItem>
              <MenuItem
                disabled={selelctedRow && selelctedRow.status === "ACTIVE"}
                onClick={() => {
                  if (selelctedRow.status !== "ACTIVE") {
                    handleInnerClose();
                    setOpenDeleteConfirmationDialog(true);
                  }
                }}
              >
                Delete
              </MenuItem>
            </MenuList>
          </Paper>
        </Paper>
      </Popper>
    </ClickAwayListener>
  );

  return (
    <React.Fragment>
      <ConfirmationDialog
        open={openDeleteConfirmationDialog || openCloseConfirmationDialog}
        handleYes={() => {
          if (openDeleteConfirmationDialog) {
            deleteMarketingCampaign(selelctedRow.id, (res) => {
              if (res && res.status === 200) {
                handleInnerClose();
              }
            });
            setOpenDeleteConfirmationDialog(false);
          } else if (openCloseConfirmationDialog) {
            setOpenCloseConfirmationDialog(false);
            handleClose();
            setTabDrawer(false);
          }
        }}
        handleNo={() => {
          if (openDeleteConfirmationDialog) {
            setOpenDeleteConfirmationDialog(false);
          } else if (openCloseConfirmationDialog) {
            setOpenCloseConfirmationDialog(false);
            isMobileDevice() && setTabDrawer(true);
          }
        }}
        sectionTitle={
          openDeleteConfirmationDialog
            ? "Are you sure you want to delete this Campaign?"
            : "All the entered data will be lost. Are you sure?"
        }
        title={openCloseConfirmationDialog && "Confirmation"}
      />
      <Drawer
        classes={{ paper: classes.paper }}
        anchor="bottom"
        open={tabDrawer}
        onClose={() => {
          setTabDrawer(false);
        }}
        onOpen={() => {
          setTabDrawer(true);
        }}
      >
        {tabDrawer && moreActionsList()}
      </Drawer>
      <NewCampaignDialog
        openNewCampaignDialog={openNewCampaignDialog}
        setOpenNewCampaignDialog={setOpenNewCampaignDialog}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        campaignData={campaignData}
        enableStep1={enableStep1}
        enableStep2={enableStep2}
        setStep1Data={setStep1Data}
        clearData={clearData}
        clearLocationServed={clearLocationServed}
        resetCampaignStartDate={resetCampaignStartDate}
        locationsServed={locationsServed}
        createMarketingCampaign={createMarketingCampaign}
        dropDownValues={dropDownValues}
        setDetectForcastedResult={setDetectForcastedResult}
        displayMarketingData={displayMarketingData}
        handleClose={handleClose}
        setOpenCloseConfirmationDialog={setOpenCloseConfirmationDialog}
        validations={validations}
        selelctedRow={selelctedRow}
        getMarketingCampaignData={getMarketingCampaignData}
        setEnableStep2={setEnableStep2}
        updateMarketingCampaign={updateMarketingCampaign}
        setSelectedRow={setSelectedRow}
        getMarketingCampaignInvoiceData={getMarketingCampaignInvoiceData}
        checkMarketingCampaignNameExists={checkMarketingCampaignNameExists}
        nameAlreadyExist={nameAlreadyExist}
      />
      <MCampDetails
        getMarketingCampaignData={getMarketingCampaignData}
        openMCampDetailsDialog={openMCampDetailsDialog}
        setMCampDetailsDialog={setMCampDetailsDialog}
        selelctedRow={selelctedRow}
        handleClose={handleClose}
        getMarketingCampaignInvoiceData={getMarketingCampaignInvoiceData}
      />
      <Grid
        container
        item
        xs={12}
        style={{ marginBottom: (isMobileDevice() || isTabletDevice()) && 72 }}
      >
        <Grid item xs={12}>
          <Typography
            variant={isMobileDevice() ? "h6" : "h4"}
            className={Styles.navyBlueColor}
          >
            {isMobileDevice() ? "Marketing" : "Marketing Campaign"}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{
            marginTop: xlUp ? "5%" : 20,
          }}
          alignItems="center"
          justifyContent="space-between"
        >
          {isMobileDevice() && userInfo.roles?.length && !isTeamMember()
            ? newCampaignBtnGrid()
            : null}
          <Grid item xs={12} md={9} lg={10}>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Search your Campaign"
              style={{
                backgroundColor: "#FFFFFF",
                padding: "15px 14px !important",
                marginTop: isMobileDevice() && 16,
              }}
              inputProps={{
                classes: { input: { padding: "15px 14px !important" } },
                endadornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(event) => debounceHandle(event.target.value)}
            />
          </Grid>
          {!isMobileDevice() && userInfo.roles?.length && !isTeamMember()
            ? newCampaignBtnGrid()
            : null}
          <Grid container item xs={12}>
            {rows && rows.length ? (
              <React.Fragment>
                <TableContainer
                  component={Paper}
                  elevation={0}
                  className="hideScroll"
                  style={{ marginTop: 20 }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((headCell, index) => (
                          <TableCell
                            key={index}
                            style={{
                              paddingLeft:
                                headCell.headerName === "Campaign Name"
                                  ? 20
                                  : headCell.headerName === "Status" && 0,
                              minWidth: headCell.headerName === "Date" && "7em",
                              whiteSpace: "pre",
                            }}
                            className={clsx(
                              Styles.navyBlueColorForce,
                              Styles.sqEllipsis
                            )}
                          >
                            {headCell.headerName}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ paddingLeft: 20 }}>
                            <Tooltip title={row.name}>
                              <Typography
                                className={clsx(
                                  Styles.navyBlueColor,
                                  Styles.sqEllipsisOneLine
                                )}
                                style={{
                                  fontSize: 12,
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  getMarketingCampaignData(row.id);
                                  setMCampDetailsDialog(true);
                                }}
                              >
                                {row.name}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            style={{ paddingLeft: 0, textAlign: "center" }}
                            className={clsx(Styles.navyBlueColorForce)}
                          >
                            {row.campaignStartDate
                              ? moment(new Date(row.campaignStartDate)).format(
                                  "MMM DD, YYYY"
                                )
                              : "-"}
                          </TableCell>
                          <TableCell style={{ paddingLeft: 0 }}>
                            <CampaignStatus campaignStatus={row.status} />
                          </TableCell>
                          <TableCell
                            style={{ textAlign: "center" }}
                            className={clsx(Styles.navyBlueColorForce)}
                          >
                            {row.status === "DRAFT" && !row.amountSpend
                              ? "-"
                              : row.amountSpend}
                          </TableCell>
                          <TableCell
                            style={{ textAlign: "center" }}
                            className={clsx(Styles.navyBlueColorForce)}
                          >
                            {row.clickThroughRate}
                          </TableCell>
                          <TableCell
                            style={{ textAlign: "center" }}
                            className={clsx(Styles.navyBlueColorForce)}
                          >
                            {row.impressions}
                          </TableCell>
                          <TableCell
                            style={{ textAlign: "center" }}
                            className={clsx(Styles.navyBlueColorForce)}
                          >
                            {row.leads}
                          </TableCell>
                          <TableCell
                            style={{ textAlign: "center" }}
                            className={clsx(Styles.navyBlueColorForce)}
                          >
                            {row.accReached}
                          </TableCell>
                          <TableCell
                            style={{ textAlign: "center" }}
                            className={clsx(Styles.navyBlueColorForce)}
                          >
                            {row.costPerImpression}
                          </TableCell>

                          <TableCell align="right" style={{ width: "3%" }}>
                            {userInfo.roles?.length && !isTeamMember() ? (
                              <MoreHorizIcon
                                ref={innerAnchorRef}
                                onClick={(event) => {
                                  setSelectedRow(row);
                                  setInnerAnchorRef(event.currentTarget);
                                  handleInnerToggle();
                                }}
                                className={clsx(Styles.navyBlueColorForce)}
                              />
                            ) : null}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="center"
                  style={{ margin: "16px 0" }}
                >
                  <Pagination
                    count={totalPages}
                    shape="rounded"
                    variant="outlined"
                    onChange={(e, page) =>
                      getAllMarketingCampaignData(page - 1)
                    }
                    page={selectedPage}
                  />
                </Grid>
                {openInnerMenu && (
                  <MyPopper {...{ handleInnerClose, openInnerMenu }} />
                )}
              </React.Fragment>
            ) : searchText ? (
              <SearchNotFound functionality="campaigns" />
            ) : (
              <NoDataAvailable isMarketingCampaignPage={true}>
                <Typography className={Styles.navyBlueColor}>
                  Looks like you do not have any campaigns. Create a{" "}
                  <Link
                    onClick={() => {
                      isMobileDevice() || isTabletDevice()
                        ? setTabDrawer(true)
                        : setOpenNewCampaignDialog({ open: true });
                    }}
                  >
                    new campaign
                  </Link>{" "}
                  to get started
                </Typography>
              </NoDataAvailable>
            )}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
