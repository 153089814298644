import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 300,
  },
}));

function ConfirmationDailog({
  openConfirmationDailog,
  setOpenConfirmationDailog,
  setOpenInviteClientsDialog,
  setShowCoApplicantdetails,
  setshowRecommendedSPDetails,
  clearData,
  resetCurrentAndNewAddress,
  setOpenInviteSPDialog,
  type,
  setSelectedService = null,
  setSelectedDoc = null,
  setSelectedTemplate = null,
}) {
  const classes = useStyles();
  return (
    <Dialog
      fullWidth
      open={openConfirmationDailog}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Confirmation</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          Are you sure that you want to leave this page ?
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          className={Styles.navyBlueColorForce}
          style={{ textTransform: "capitalize" }}
          onClick={() => setOpenConfirmationDailog(false)}
        >
          No
        </Button>
        <Button
          variant="contained"
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            if (type === "inviteClient") {
              setOpenInviteClientsDialog(false);
              setShowCoApplicantdetails(false);
              setshowRecommendedSPDetails(false);
              setOpenConfirmationDailog(false);
              setSelectedService && setSelectedService(null);
              setSelectedDoc && setSelectedDoc([]);
              setSelectedTemplate && setSelectedTemplate([]);
              clearData();
              resetCurrentAndNewAddress();
            } else if (type === "inviteSP") {
              setOpenInviteSPDialog(false);
              clearData();
              setOpenConfirmationDailog(false);
            }
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDailog;
