import { useState } from "react";
import { useAuthUser } from "../../useLogin";
import { useDispatch, useSelector } from "react-redux";
import { useActions } from "../../useActions";
import {
  ADD_TEMPLATE_DOCUMENT,
  ADD_TEMPLATE_DOCUMENT_SUMMARY,
  CLEAR_TEMPLATE_DOCS_AND_CLOSE,
  CLEAR_TEMPLATE_DOCUMENTS,
  DELETE_TEMPLATE_DOCUMENT_SUMMARY,
  DELETE_TEMPLATE_DOCUMENT_WHILE_UPLOAD,
  EDIT_TEMPLATE_DOCUMENT_SUMMARY,
  SET_OPEN_ADD_TEMPLATE,
  SET_OPEN_EDIT_TEMPLATE,
  SET_OPEN_INVITE_AND_SEND_TEMPLATE,
  SET_TEMPLATE_DOCUMENT_SUMMARY,
  SET_WARNING_DIALOG_FLAG,
} from "../../../souqh-redux/reducers/actionConstants";
import { cloneDeep } from "lodash";
import {
  EMAIL_PATTERN,
  MY_TEMPLATES,
  SERVICE_PROVIDER_USER,
  WAITING_FOR_OTHER,
} from "../../../web/src/components/ServiceProvider/serviceProviderConstants";
import { useBusinessType } from "../../useBusinessType";
import * as Stomp from "stompjs";
import * as SockJS from "sockjs-client";
import { useApiClient } from "../../useApiClient";
import { isMarketplacePlan } from "../../../web/src/utils/AppUtils";

const actionCreator = {
  deleteDocument: (payload) => ({
    type: DELETE_TEMPLATE_DOCUMENT_WHILE_UPLOAD,
    payload: { index: payload },
  }),
  cleanTemplateFilesDataOnClose: () => ({
    type: CLEAR_TEMPLATE_DOCUMENTS,
    payload: [],
  }),
  cleanTemplateAndCloseDialog: () => ({
    type: CLEAR_TEMPLATE_DOCS_AND_CLOSE,
    payload: [],
  }),
  addTemplateSummary: (summary) => ({
    type: ADD_TEMPLATE_DOCUMENT_SUMMARY,
    payload: { summary },
  }),
  deleteTemplateSummary: (index) => ({
    type: DELETE_TEMPLATE_DOCUMENT_SUMMARY,
    payload: { index },
  }),
  editTemplateSummary: (index, data) => ({
    type: EDIT_TEMPLATE_DOCUMENT_SUMMARY,
    payload: { index, data },
  }),
  setTemplateSummaryList: (summaryList) => ({
    type: SET_TEMPLATE_DOCUMENT_SUMMARY,
    payload: summaryList,
  }),
  addToContact: (contact) => ({
    type: ADD_SP_ASSOC_PROVIDER_CONTACT,
    payload: { contact },
  }),
  createContact: (contact) => ({
    type: ADD_SP_ACTIVE_CONTACT,
    payload: { contact },
  }),
  setOpenAddTemplate: (openAddTemplate) => ({
    type: SET_OPEN_ADD_TEMPLATE,
    payload: { openAddTemplate },
  }),
  setOpenEditTemplate: (openEditTemplate) => ({
    type: SET_OPEN_EDIT_TEMPLATE,
    payload: { openEditTemplate },
  }),
  setOpenInviteAndSendTemplate: (openInviteAndSendTemplate) => ({
    type: SET_OPEN_INVITE_AND_SEND_TEMPLATE,
    payload: { openInviteAndSendTemplate },
  }),
};

export const useAddTemplate = (
  getTabWiseCategoriesList,
  setDocumentTabName,
  documentTabName
) => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();
  const actions = useActions(actionCreator);
  const userInfo = useAuthUser();

  const [activeSection, setActiveSection] = useState(1);

  const [openUploadCancelDialog, setOpenUploadCancelDialog] = useState(false);
  const [templateDescription, setTemplateDescription] = useState("");
  const [hasUpdatedName, setHasUpdatedName] = useState("");
  const [hasUpdatedDescription, setHasUpdatedDescription] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [taggedDoucment, setTaggedDoucment] = useState(null);
  const [openTagged, setOpenTagged] = useState(false);
  const [openAddSummaryDialog, setOpenAddSummaryDialog] = useState(false);
  const [summaryTobeEdited, setSummaryTobeEdited] = useState(null);

  const [openAddContactDialog, setAddContactDialog] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedContact, setSelectedContact] = useState(null);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [sharedWithSP, setSharedWithSP] = useState(false);
  const [signRoles, setSignRoles] = useState([]);
  const [signOrders, setSignOrders] = useState([]);
  const [signOrdersData, setSignOrdersData] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [SPContacts, setSPContacts] = useState([]);
  const [associactedServiceProviders, setAssociactedServiceProviders] =
    useState([]);
  const [openCustomEmailMessageDialog, setOpenCustomEmailMessageDialog] =
    useState(false);
  const [customEmailTitle, setCustomEmailTitle] = useState("");
  const [customEmailMessage, setCustomEmailMessage] = useState("");
  const [defaultEmailTitle, setDefaultEmailTitle] = useState("");
  const [defaultEmailMessage, setDefaultEmailMessage] = useState("");
  const [copiedTemplate, setCopiedTemplate] = useState(null);
  const [dataForInvite, setDataForInvite] = useState(null);
  const [openConfirmGoBackDialog, setConfirmGoBackDialog] = useState(false);
  const [webSocket, setWebSocket] = useState(null);
  const [stompClient, setStompClient] = useState(null);
  const [stopListen, setStopListen] = useState(false);
  const [primaryApplicantList, setPrimaryApplicantList] = useState([]);
  const duplicateFileName = useSelector(
    (state) => state.spDocument.duplicateFileName
  );
  // const { businessTypes } = useBusinessType();
  const openInviteAndSendTemplate = useSelector(
    (state) => state.spDocument.openInviteAndSendTemplate
  );
  const templateDocumentList = useSelector(
    (state) => state.spDocument.templateDocumentList
  );
  const openEditTemplate = useSelector(
    (state) => state.spDocument.openEditTemplate
  );
  const openAddTemplate = useSelector(
    (state) => state.spDocument.openAddTemplate
  );

  const templateSummaryList = useSelector(
    (state) => state.spDocument.templateSummaryList
  );

  const showDialogFlag = useSelector(
    (state) => state.spDocument.showBackWarningDialog
  );

  const connectToSocket = () => {
    const authToken = userInfo.access_token;
    if (authToken) {
      try {
        const url =
          process.env.REACT_APP_SOUQH_API_SERVER_BASE_URL + "/ws-notifications";
        const tempWebSocket = new SockJS(url);
        setWebSocket(tempWebSocket);
        let tempClient = Stomp.over(tempWebSocket);
        tempClient.debug = null;
        setStompClient(tempClient);
        tempClient.connect({ Authorization: authToken }, (frame) => {
          tempClient.subscribe("/queue/private", (response) => {
            processListener(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  const disconnectFromSocket = () => {
    if (stompClient !== null) {
      stompClient.disconnect();
      setStompClient(null);
      setWebSocket(null);
    }
  };

  const processListener = (response) => {
    if (!stopListen) {
      if (response && response.body) {
        const body = JSON.parse(response.body);
        if (
          body &&
          body.documentId === taggedDoucment.signNowDocumentId &&
          body.event === "role_updated"
        ) {
          setStopListen(true);
          validateTaggedDocument();
        }
      }
    }
  };

  const onUploadTemplateFileChange = (event) => {
    processTemplateUploadedFiles(event.target.files, event);
  };

  const processTemplateUploadedFiles = (files, event) => {
    let finalFilesarray = [];
    Object.keys(files).forEach((key) => {
      finalFilesarray.push({
        fileName: files[key].name,
        file: files[key],
        isRef: false,
        description: "",
        hasUpdatedDescription: false,
      });
    });
    let compareArray = [...templateDocumentList, ...finalFilesarray];
    var valueArr = compareArray.map(function (item) {
      return item.fileName;
    });
    var isDuplicate = valueArr.some((item, index) => {
      return valueArr.indexOf(item) != index;
    });
    dispatch({
      type: ADD_TEMPLATE_DOCUMENT,
      payload: { files: finalFilesarray, isDuplicate },
    });
    if (event && event.target) {
      event.target.value = "";
    }
    if (!isMarketplacePlan(userInfo)) {
      actions.setOpenAddTemplate(true);
    }
  };

  const filterContacts = () => {
    const txt = (searchText || "").toLowerCase().trim();
    setFilteredContacts(
      contacts.filter(
        (item) =>
          (item.primaryApplicantEmail || "").toLowerCase().indexOf(txt) >= 0 ||
          (item.name || "").toLowerCase().indexOf(txt) >= 0
      )
    );
  };

  const createContactItem = (contact, callback) => {
    const contactEmail = (contact.email || "").trim().toLowerCase();
    const isDuplicate = contacts.some(
      (item) => (item.email || "").trim().toLowerCase() === contactEmail
    );

    if (isDuplicate) {
      callback(true);
    } else {
      const tempList = cloneDeep(contacts);
      tempList.push(contact);
      setContacts(tempList);
      callback(false);
    }
  };

  const updateShowDialogFlag = (flag) => {
    return apiClient
      .put(`/user/${userInfo.userId}/showdialog/${flag}`)
      .then(function (response) {
        dispatch({
          type: SET_WARNING_DIALOG_FLAG,
          payload: { flag: response.data.result },
        });
      });
  };

  const loadTemplateSummary = (callback) => {
    dispatch({ type: "SET_LOADING", value: true });
    const url = `/signnow/document/summary/${taggedDoucment.documentId}?${
      userInfo.userId || null
    }`;
    apiClient
      .get(url)
      .then((response) => {
        if (response.data.status === 200) {
          actions.setTemplateSummaryList(
            response.data.result.documentSummary || []
          );
          callback && callback();
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const addContactItem = (serviceProvider, callback) => {
    const serviceProviderEmail = (serviceProvider.email || "")
      .trim()
      .toLowerCase();
    const isDuplicate = associactedServiceProviders.some(
      (item) => (item.email || "").trim().toLowerCase() === serviceProviderEmail
    );

    if (isDuplicate) {
      callback(true);
    } else {
      const tempList = cloneDeep(associactedServiceProviders);
      tempList.push(serviceProvider);
      setAssociactedServiceProviders(tempList);
      callback(false);
    }
  };

  const checkAndAddSummary = (summary, isEdit, callback) => {
    const currentTitle = (summary.title || "").toLowerCase();
    const duplicateIndex = templateSummaryList.findIndex(
      (item) => (item.title || "").trim().toLowerCase() === currentTitle
    );
    const data = {
      title: summary.title,
      subtitle: summary.subtitle,
      description: summary.description,
      id: summary.id || null,
    };
    if (isEdit) {
      if (duplicateIndex === -1 || duplicateIndex === summary.index) {
        saveSummaryToBackend(data, summary.index, callback);
        return;
      }
    } else {
      if (duplicateIndex === -1) {
        saveSummaryToBackend(data, null, callback);
        return;
      }
    }
    callback(true);
  };

  const deleteSummary = (id) => {
    dispatch({ type: "SET_LOADING", value: true });
    const url = `/signnow/document/summary/${id}`;
    apiClient
      .delete(url)
      .then((response) => {
        if (response.data.status === 200) {
          const savedData = response.data.result || [];
          actions.setTemplateSummaryList(savedData);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getContacts = () => {
    if (userInfo && userInfo.userType === SERVICE_PROVIDER_USER) {
      const url = `service-provider/${userInfo.serviceProviderId}/home-buyer`;
      apiClient
        .get(url)
        .then((response) => {
          if (response.data.status === 200) {
            setContacts(response.data.result || []);
            const mappedData = (response.data.result || []).map(
              (item) => item.primaryApplicantEmail
            );
            setPrimaryApplicantList(mappedData);
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    }
  };

  const updateSignOrderChange = (value, index) => {
    const tempData = cloneDeep(signOrdersData);
    const findItem = tempData[index];
    if (findItem) {
      findItem.order = value ? value : "";
    }
    setSignOrdersData(tempData);
  };

  const handleAssocSPSelect = (value, index) => {
    const tempData = cloneDeep(associactedServiceProviders);
    const findItem = tempData[index];
    if (findItem) {
      findItem.selected = value;
    }
    setAssociactedServiceProviders(tempData);
  };

  const updatePrimaryAndSecondaryApplicant = () => {
    const tempData = cloneDeep(signOrdersData);
    tempData.forEach((item) => (item.email = ""));
    const primaryApplicant = tempData[0];
    if (primaryApplicant) {
      primaryApplicant.email = selectedContact.primaryApplicantEmail || "";
    }

    const coApplicant = tempData[1];
    if (coApplicant) {
      coApplicant.email = selectedContact.coApplicantEmail || "";
    }
    setSignOrdersData(tempData);
  };

  const updateEmailTouchedState = (status, index) => {
    const tempData = cloneDeep(signOrdersData);
    const findItem = tempData[index];
    if (findItem) {
      findItem.touchedEmail = status;
      if (EMAIL_PATTERN.test(findItem.email)) {
        findItem.isValidEmail = true;
      } else {
        findItem.isValidEmail = false;
      }
    }
    setSignOrdersData(tempData);
  };

  const updateEmailValue = (value, index) => {
    const tempData = cloneDeep(signOrdersData);
    const findItem = tempData[index];
    if (findItem) {
      findItem.email = value;
      if (EMAIL_PATTERN.test(findItem.email)) {
        findItem.isValidEmail = true;
      } else {
        findItem.isValidEmail = false;
      }
    }
    setSignOrdersData(tempData);
  };

  const isAllEmailsFilled = () => {
    if (signOrdersData.length) {
      const flag = signOrdersData.every(
        (item) =>
          item.email &&
          EMAIL_PATTERN.test(item.email) &&
          item.order &&
          item.role
      );
      return flag;
    }
    return false;
  };

  const getSPContacts = () => {
    return apiClient
      .get("/home-buyer/contacts/other-sp")
      .then(function (response) {
        const mappedData = (response.data.result || []).map(
          (item) => item.email
        );
        setSPContacts(mappedData);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const uploadFilesForTemplateTag = () => {
    if (!(templateName || "").trim() || !(templateDescription || "").trim()) {
      setHasUpdatedDescription(true);
      setHasUpdatedName(true);
    } else {
      const bodyFormData = new FormData();
      const fileNameDescriptionMap = {};
      templateDocumentList.forEach((template) => {
        bodyFormData.append("uploadDocs", template.file, template.file.name);
        fileNameDescriptionMap[template.file.name] = "";
      });
      const fileDescription = {
        fileNameDescriptionMap,
        templateName,
        templateDescription,
      };

      bodyFormData.append("fileDescription", JSON.stringify(fileDescription));
      bodyFormData.append("userId", userInfo.userId);
      dispatch({ type: "SET_LOADING", value: true });
      apiClient
        .post("/signnow/upload/template", bodyFormData)
        .then((response) => {
          const responseData = response.data;
          if (responseData.status === 200) {
            setTaggedDoucment(responseData.result);
            setActiveSection(2);
          } else {
            if (responseData.status === 404) {
              let errorMessage = "Something went wrong";
              const respMessage = responseData.message || "";
              if (respMessage.indexOf("Invalid file type") > 0) {
                errorMessage = "Invalid file type";
              } else if (
                respMessage.indexOf("Template with the given name exists") > -1
              ) {
                errorMessage = "Template Name already exists.";
              } else if (
                respMessage.indexOf("Unable to convert file to pdf") > -1
              ) {
                errorMessage = "Unable to process file";
              }
              dispatch({
                type: "SET_SERVER_ERROR",
                error: { message: errorMessage },
              });
            }
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const validateTaggedDocument = () => {
    const url = `/signnow/validate/documents/${userInfo.userId}`;
    dispatch({ type: "SET_LOADING", value: true });
    const body = [taggedDoucment.signNowDocumentId];
    apiClient
      .post(url, body)
      .then((response) => {
        const responseData = response.data;
        if (responseData.status === 200) {
          setActiveSection(3);
          loadTemplateSummary();
        } else if (responseData.status === 404) {
          dispatch({
            type: "SET_SERVER_ERROR",
            error: { message: responseData.message },
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const uploadTaggedDcouments = (nextStep, callback) => {
    if (nextStep) {
      uploadAndUse(true, callback);
    } else {
      callback && callback();
      closeAndFinishAddTemplate();
      if (documentTabName !== MY_TEMPLATES) {
        setDocumentTabName && setDocumentTabName(MY_TEMPLATES);
      } else {
        getTabWiseCategoriesList && getTabWiseCategoriesList(MY_TEMPLATES);
      }
    }
  };

  const uploadAndUse = (loaderFlag, callback) => {
    const params = {
      templateId: taggedDoucment.signNowDocumentId,
      userId: userInfo.userId,
    };
    if (loaderFlag) {
      dispatch({ type: "SET_LOADING", value: true });
    }
    apiClient
      .post(
        "/signnow/use/template",
        {},
        {
          params: params,
        }
      )
      .then((response) => {
        const responseData = response.data;
        if (responseData.status === 200) {
          setCopiedTemplate(response.data.result);
          getInviteDataForSignNow(response.data.result, callback);
        }
      })
      .catch(function (error) {
        console.error(error);
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getInviteDataForSignNow = (copiedTemplate, callback) => {
    apiClient
      .get("/signnow/document/invite-data", {
        params: {
          documentId: copiedTemplate.id,
          userId: userInfo.userId,
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          if (response.data.result.to && response.data.result.to.length) {
            const result = response.data.result;
            setActiveSection(4);
            setDataForInvite(result);
            parseAndSetRoles(result.to || []);
            setDefaultEmailTitle(result.subject || "");
            setDefaultEmailMessage(result.message || "");
            setCustomEmailTitle(result.subject || "");
            setCustomEmailMessage(result.message || "");
          } else {
            dispatch({
              type: "SET_SERVER_ERROR",
              error: { message: "No Signer has been added" },
            });
            callback && callback();
          }
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const saveSummaryToBackend = (data, index, callback) => {
    const body = {
      description: data.description,
      id: data.id,
      subtitle: data.subtitle,
      title: data.title,
    };

    const url = `/signnow/document/summary/${taggedDoucment.documentId}`;
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .post(url, body)
      .then((response) => {
        const responseData = response.data;
        if (responseData.status === 200) {
          const savedData = response.data.result || [];
          actions.setTemplateSummaryList(savedData);
          if (savedData) {
            callback(false);
          }
        } else {
          // To Do
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const inviteAndSend = (callBack) => {
    const selectedSP = associactedServiceProviders
      .filter((item) => item.selected === true)
      .map((item) => item.email);
    const signNowInviteBody = {
      cc: selectedSP,
      documentIds: [],
      from: dataForInvite.from,
      message: customEmailMessage,
      redirect_uri: "",
      subject: customEmailTitle,
      to: signOrdersData,
    };

    const url = `/signnow/${userInfo.userId}/send/${copiedTemplate.id}/invite`;
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .post(url, signNowInviteBody)
      .then((response) => {
        if (response.data.status === 200) {
          callBack && callBack();
          if (documentTabName !== WAITING_FOR_OTHER) {
            setDocumentTabName && setDocumentTabName(WAITING_FOR_OTHER);
          } else {
            getTabWiseCategoriesList &&
              getTabWiseCategoriesList(WAITING_FOR_OTHER);
          }
          closeAndFinishAddTemplate();
        } else if (response.data.status === 412) {
          if (response.data.result) {
            dispatch({
              type: "SET_SERVER_ERROR",
              error: { message: response.data.result },
            });
          }
        } else {
          dispatch({
            type: "SET_SERVER_ERROR",
            error: { message: "Something went wrong" },
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const deleteUploadedTemplate = () => {
    if (!taggedDoucment) {
      return;
    }
    dispatch({ type: "SET_LOADING", value: true });
    const url = `/signnow/delete/document/${taggedDoucment.signNowDocumentId}/${userInfo.userId}`;

    return apiClient
      .delete(url)
      .then((res) => {
        if (res.data.status === 200) {
          resetStep1();
        } else if (res.data.status === 401) {
          dispatch({
            type: "SET_SERVER_ERROR",
            error: {
              message: "You do not have permission to perform this operation",
            },
          });
        } else {
          dispatch({
            type: "SET_SERVER_ERROR",
            error: {
              message: "Something went wrong",
            },
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const deleteCopyOfTemplate = () => {
    dispatch({ type: "SET_LOADING", value: true });
    const url = `/signnow/delete/document/${copiedTemplate.id}/${userInfo.userId}`;

    return apiClient
      .delete(url)
      .then((res) => {
        if (res.data.status === 200) {
          setCopiedTemplate(null);
        } else if (res.data.status === 401) {
          dispatch({
            type: "SET_SERVER_ERROR",
            error: {
              message: "You do not have permission to perform this operation",
            },
          });
        } else {
          dispatch({
            type: "SET_SERVER_ERROR",
            error: {
              message: "Something went wrong",
            },
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const parseAndSetRoles = (data) => {
    if (data && data.length) {
      const tempRoles = {};
      const tempOrders = {};
      const tempEmails = [];

      data.forEach((item) => {
        const label = (item.role || "").replace(/_/g, " ");
        tempRoles[item.role] = {
          role: item.role,
          label,
        };
        tempOrders[item.order] = {
          order: item.order,
          label: "Sign Order " + item.order,
        };
        tempEmails.push(item.email);
        if (EMAIL_PATTERN.test(item.email)) {
          item.isValidEmail = true;
        } else {
          item.isValidEmail = false;
        }
        item.touchedEmail = false;

        const words = label.split(" ");

        for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }
        item.roleLabel = words.join(" ");
      });
      setSignOrdersData(data);
      const roles = [];
      Object.keys(tempRoles).forEach((item) => {
        roles.push(tempRoles[item]);
      });
      setSignRoles(roles);

      const orders = [];
      Object.keys(tempOrders).forEach((item) => {
        orders.push(tempOrders[item]);
      });
      setSignOrders(orders);
    } else {
      setSignOrdersData([]);
      setSignOrders([]);
      setSignRoles([]);
    }
  };

  const closeAndFinishAddTemplate = () => {
    actions.cleanTemplateAndCloseDialog();
    setActiveSection(1);
    setTemplateName("");
    setTemplateDescription("");
    setHasUpdatedDescription(false);
    setHasUpdatedName(false);
    setTaggedDoucment(null);
    setOpenTagged(false);
    setAssociactedServiceProviders([]);
    setContacts([]);
    setSelectedContact(null);
    setSharedWithSP(false);
    setSignRoles([]);
    setSignOrders([]);
    setCustomEmailTitle("");
    setCustomEmailMessage("");
    setDefaultEmailTitle("");
    setDefaultEmailMessage("");
    setOpenCustomEmailMessageDialog(false);
    setSPContacts([]);
    setOpenUploadCancelDialog(false);
    setConfirmGoBackDialog(false);
    disconnectFromSocket();
    setStopListen(false);
    setPrimaryApplicantList([]);
  };

  const resetStep1 = () => {
    actions.cleanTemplateFilesDataOnClose();
    setConfirmGoBackDialog(false);
    setActiveSection(1);
    setTaggedDoucment(null);
    setOpenTagged(false);
    setAssociactedServiceProviders([]);
    setSelectedContact(null);
    setSharedWithSP(false);
    setPrimaryApplicantList([]);
    setSignRoles([]);
    setSignOrders([]);
    setSPContacts([]);
    disconnectFromSocket();
    setStopListen(false);
    setCustomEmailTitle("");
    setCustomEmailMessage("");
    setDefaultEmailTitle("");
    setDefaultEmailMessage("");
  };

  return {
    activeSection,
    setActiveSection,
    openAddTemplate,
    openEditTemplate,
    openUploadCancelDialog,
    setOpenUploadCancelDialog,
    onUploadTemplateFileChange,
    processTemplateUploadedFiles,
    duplicateFileName,
    templateDocumentList,
    templateDescription,
    setTemplateDescription,
    templateName,
    setTemplateName,
    hasUpdatedName,
    setHasUpdatedName,
    hasUpdatedDescription,
    setHasUpdatedDescription,
    actions,
    uploadFilesForTemplateTag,
    uploadTaggedDcouments,
    taggedDoucment,
    setTaggedDoucment,
    openTagged,
    setOpenTagged,
    templateSummaryList,
    openAddSummaryDialog,
    setOpenAddSummaryDialog,
    summaryTobeEdited,
    setSummaryTobeEdited,
    checkAndAddSummary,
    deleteSummary,
    searchText,
    setSearchText,
    selectedContact,
    setSelectedContact,
    filteredContacts,
    setFilteredContacts,
    filterContacts,
    openAddContactDialog,
    setAddContactDialog,
    createContactItem,
    addContactItem,
    associactedServiceProviders,
    sharedWithSP,
    setSharedWithSP,
    getContacts,
    contacts,
    setAssociactedServiceProviders,
    setContacts,
    signRoles,
    setSignRoles,
    signOrders,
    setSignOrders,
    signOrdersData,
    setSignOrdersData,
    updateSignOrderChange,
    updateEmailTouchedState,
    updateEmailValue,
    isAllEmailsFilled,
    inviteAndSend,
    updatePrimaryAndSecondaryApplicant,
    openCustomEmailMessageDialog,
    setOpenCustomEmailMessageDialog,
    customEmailTitle,
    setCustomEmailTitle,
    customEmailMessage,
    setCustomEmailMessage,
    closeAndFinishAddTemplate,
    SPContacts,
    setSPContacts,
    getSPContacts,
    handleAssocSPSelect,
    loadTemplateSummary,
    validateTaggedDocument,
    // businessTypes,
    setConfirmGoBackDialog,
    openConfirmGoBackDialog,
    showDialogFlag,
    resetStep1,
    connectToSocket,
    disconnectFromSocket,
    updateShowDialogFlag,
    setStopListen,
    openInviteAndSendTemplate,
    primaryApplicantList,
    setPrimaryApplicantList,
    defaultEmailTitle,
    setDefaultEmailTitle,
    defaultEmailMessage,
    setDefaultEmailMessage,
    deleteUploadedTemplate,
    deleteCopyOfTemplate,
  };
};
