import { useState } from "react";
import { color_success } from "../toasterColors";
import { useAuthUser } from "../useLogin";
import { useDispatch } from "react-redux";
import { useApiClient } from "../useApiClient";

export const useFeedback = () => {
  const [rating, setRating] = useState(null);
  const [description, setDescription] = useState("");
  const user = useAuthUser();
  const dispatch = useDispatch();
  const { apiClient } = useApiClient();

  const clearData = () => {
    setRating(null);
    setDescription("");
  };

  const submitFeedback = (onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .post("/feedback", null, {
        params: {
          rating: rating.label,
          description,
          userId: user.userId,
        },
      })
      .then((res) => {
        if (res && res.status === 200) {
          dispatch({
            type: "SET_TOASTER",
            payload: {
              message: `Feedback added successfully`,
              bgColor: color_success,
              open: true,
            },
          });
          onResult && onResult(res);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };
  return {
    setRating,
    rating,
    description,
    setDescription,
    submitFeedback,
    clearData,
  };
};
