import {
  ButtonBase,
  Grid,
  Switch,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Styles from "../../Styles.module.css";
import CheckIcon from "@material-ui/icons/Check";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  BILLING_CYCLE,
  TEAM_MEMBER_ROLES,
} from "../ServiceProvider/serviceProviderConstants";
import * as moment from "moment";
import TeamMgmtPlanChangeDialog from "./TeamMgmtPlanChangeDialog";

const rolesMap = {
  Admin: { payload: "SERVICE_PROVIDER", value: "service-provider" },
  "Team Member": { payload: "TEAM_MEMBER", value: "team-member" },
};

export default function EditableTeamMemberRow({
  data,
  updateTeamMember,
  setEditRow,
  getTagMembers,
}) {
  const [openTeamMgmtPlanChangeDialog, setOpenTeamMgmtPlanChangeDialog] =
    useState(false);
  const planOptions = [
    { id: 1, planName: "Pro" },
    { id: 2, planName: "Plus" },
  ];

  const [editData, setEditData] = useState();

  const billingCycle = BILLING_CYCLE.find(
    (billing) => billing.value === data.paymentCycle
  );

  useEffect(() => {
    const selectedRole = Object.keys(rolesMap).find(
      (role) => rolesMap[role].value === data.roles?.[0]
    );
    const formData = {
      fullName: data.fullName,
      roles: selectedRole,
      enable2Factor: data.enable2Factor,
      plan: {
        id: data.subscriptionPlan.id,
        planName: data.subscriptionPlan.planName,
      },
      paymentCycle: BILLING_CYCLE.find(
        (billing) => billing.value === data.paymentCycle
      ),
    };
    setEditData(formData);
  }, [data]);

  const getRole = (obj) => {
    let role = obj?.roles;
    return rolesMap[role]?.value;
  };

  const planChanged = () => {
    let planIsChanged =
      data.subscriptionPlan.id !== editData?.plan.id ? true : false;
    return planIsChanged;
  };

  const cycleChanged = () => {
    let cycleIsChanged =
      data.paymentCycle !== editData.paymentCycle?.value ? true : false;
    return cycleIsChanged;
  };

  return (
    <React.Fragment>
      <TeamMgmtPlanChangeDialog
        openTeamMgmtPlanChangeDialog={openTeamMgmtPlanChangeDialog}
        setOpenTeamMgmtPlanChangeDialog={setOpenTeamMgmtPlanChangeDialog}
        editData={editData}
        prevData={data}
      />
      <TableRow>
        <TableCell
          className={Styles.navyBlueColorForce}
          style={{ width: "20%" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <TextField
                value={editData?.fullName || ""}
                selectedPrevUserPlan
                onChange={(e) =>
                  setEditData((prev) => ({ ...prev, fullName: e.target.value }))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>{data.email} </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell
          className={Styles.navyBlueColorForce}
          style={{ width: "15%" }}
        >
          <Autocomplete
            fullWidth
            value={editData?.roles || null}
            options={TEAM_MEMBER_ROLES || []}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select role"
                variant="outlined"
                autoComplete="new-password"
              />
            )}
            required
            onChange={(event, value) => {
              setEditData((prev) => ({ ...prev, roles: value }));
            }}
          />
        </TableCell>
        <TableCell
          className={Styles.navyBlueColorForce}
          style={{ width: "8%" }}
        >
          <Autocomplete
            fullWidth
            value={editData?.plan || null}
            options={planOptions || []}
            getOptionLabel={(option) => option.planName}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select plan"
                variant="outlined"
                // autoComplete="new-password"
              />
            )}
            required
            onChange={(event, value) =>
              setEditData((prev) => ({
                ...prev,
                plan: value,
              }))
            }
          />
        </TableCell>
        <TableCell className={Styles.navyBlueColorForce}>
          <Autocomplete
            fullWidth
            value={editData?.paymentCycle || null}
            options={BILLING_CYCLE || []}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select billing cycle"
                variant="outlined"
                // autoComplete="new-password"
              />
            )}
            required
            onChange={(event, value) =>
              setEditData((prev) => ({
                ...prev,
                paymentCycle: value,
              }))
            }
          />
        </TableCell>
        <TableCell
          className={Styles.navyBlueColorForce}
          style={{ width: "8%" }}
        >
          <Switch
            size="small"
            className={"sqGraySwitch"}
            checked={editData?.enable2Factor || false}
            onChange={() =>
              setEditData((prev) => ({
                ...prev,
                enable2Factor: !prev.enable2Factor,
              }))
            }
          />
        </TableCell>
        <TableCell
          className={Styles.navyBlueColorForce}
          style={{ width: "20%" }}
        >
          <Typography>
            {data.lastLogin
              ? moment(data.lastLogin).format("MMM DD, YYYY - hh:mm A")
              : null}
          </Typography>
        </TableCell>
        <TableCell align="right" style={{ width: "1%" }}>
          <ButtonBase>
            <CheckIcon
              className={Styles.navyBlueColor}
              onClick={() => {
                if (
                  data.subscriptionPlan.id !== editData.plan.id ||
                  (data.paymentCycle &&
                    data.paymentCycle !== editData.paymentCycle.value)
                ) {
                  setOpenTeamMgmtPlanChangeDialog(true);
                }
                setTimeout(() => {
                  updateTeamMember(
                    {
                      //arg can change to editData once api supports all fields
                      fullName:
                        editData.fullName !== data.fullName
                          ? editData.fullName
                          : null,
                      enable2Factor:
                        editData.enable2Factor !== data.enable2Factor
                          ? editData.enable2Factor
                          : null,
                      userId: data.userId,
                      role:
                        getRole(editData) !== data.roles[0]
                          ? rolesMap[editData.roles]?.payload
                          : null,
                      newPlanId:
                        planChanged() || cycleChanged()
                          ? editData.plan.id
                          : null,
                      paymentCycle:
                        planChanged() || cycleChanged()
                          ? editData.paymentCycle?.value
                          : null,
                      couponCode: null,
                    },
                    () => {
                      getTagMembers();
                      setEditRow("");
                    }
                  );
                }, 5000);
              }}
            />
          </ButtonBase>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
