import {
  Button,
  Grid,
  makeStyles,
  Switch,
  Typography,
} from "@material-ui/core";
import React from "react";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";
import AssocSPRow from "./AssocSPRow";
import AddIcon from "@material-ui/icons/Add";

const styles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined": {
      top: -6,
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px",
    },
  },
}));
export default function ShareWithSPSection({
  sharedWithSP,
  setSharedWithSP,
  setAddContactDialog,
  associactedServiceProviders,
  handleAssocSPSelect,
}) {
  const classes = styles();

  return (
    <>
      <Grid
        item
        container
        xs={12}
        alignItems="center"
        style={{ padding: "8px 0px" }}
      >
        <Grid item xs={7}>
          <Typography
            variant="subtitle1"
            className={clsx(Styles.navyBlueColor)}
          >
            Share documents with associated Service Providers?
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Switch
            className={"sqGraySwitch"}
            checked={sharedWithSP}
            onChange={(e) => {
              setSharedWithSP(e.target.checked);
            }}
          ></Switch>
        </Grid>
        {sharedWithSP ? (
          <Grid item xs={3} style={{ justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              style={{
                color: "#0E1C4E",
                textTransform: "capitalize",
              }}
              onClick={() => {
                setAddContactDialog(true);
              }}
            >
              Add Contact
            </Button>{" "}
          </Grid>
        ) : null}
      </Grid>
      {sharedWithSP ? (
        <Grid
          item
          xs={12}
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            height: "calc(100% - 40px)",
          }}
        >
          {associactedServiceProviders.length ? (
            associactedServiceProviders.map((item, index) => {
              return (
                <AssocSPRow
                  key={index}
                  handleAssocSPSelect={handleAssocSPSelect}
                  rowData={item}
                  index={index}
                />
              );
            })
          ) : (
            <Typography
              variant="body1"
              style={{
                color: "0e1c4e",
                opacity: 0.5,
                fontStyle: "italic",
                fontWeight: 300,
              }}
            >
              No data available.
            </Typography>
          )}
        </Grid>
      ) : null}
    </>
  );
}
