import {
  Divider,
  Grid,
  makeStyles,
  Switch,
  Typography,
} from "@material-ui/core";
import React from "react";
import { isMobileDevice, isTabletDevice } from "../../utils/AppUtils";
import NotificationItem from "./NotificationItem";
import Styles from "../../Styles.module.css";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  scrollPortion: {
    maxHeight: isMobileDevice() ? 600 : isTabletDevice() ? 800 : 400,
    overflowY: "auto",
  },
}));

export default function NotificationContent({
  handleNotificationsClose,
  noNotifications,
  handelScroll,
  scroller,
  todayNotifications,
  olderNotifications,
  getDescription,
  getAvatarUrl,
  handleNotification,
  setShowOnlyUnreadNotifications,
  showOnlyUnreadNotifications,
  markAllNotificationsRead,
  deleteNotification,
}) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid
        container
        style={{
          padding: isMobileDevice() || isTabletDevice() ? 16 : "16px 0px",
        }}
      >
        <Grid item xs={7} md={9} lg={8}>
          <Typography variant="h6" className={Styles.navyBlueColor}>
            Notifications
          </Typography>
        </Grid>
        {todayNotifications.length || olderNotifications.length ? (
          <Grid
            container
            item
            xs={5}
            md={3}
            lg={4}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid item xs={9}>
              <Typography style={{ fontSize: 11, color: "#17174C61" }}>
                Only show unread
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Switch
                size="small"
                className={"sqGraySwitch"}
                checked={showOnlyUnreadNotifications}
                onChange={(event) => {
                  setShowOnlyUnreadNotifications(event.target.checked);
                }}
              />
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      <Divider />
      <Grid
        ref={scroller}
        className={clsx(classes.scrollPortion, "hideScroll")}
        onScroll={() => {
          handelScroll();
        }}
      >
        {!noNotifications ? (
          <Grid
            container
            style={{ padding: 12 }}
            direction="column"
            justifyContent="center"
          >
            <Grid container style={{ padding: 12 }} justifyContent="center">
              <Typography style={{ color: "#868DA6", fontSize: 11 }}>
                Looks like you you have no notifications
              </Typography>
            </Grid>
            <Grid container justifyContent="center">
              <img src="/images/no-result-found-orange.svg" />
            </Grid>
          </Grid>
        ) : null}

        <Grid container style={{ marginTop: 16 }}>
          {todayNotifications.length ? (
            <Grid item xs={8} md={9}>
              <Typography
                style={{
                  color: "#17174C61",
                  fontSize: 12,
                  paddingLeft: (isMobileDevice() || isTabletDevice()) && 16,
                }}
              >
                TODAY
              </Typography>
            </Grid>
          ) : null}
          {todayNotifications.length || olderNotifications.length ? (
            <Grid
              container
              item
              xs={todayNotifications.length ? 4 : 12}
              md={todayNotifications.length ? 3 : 12}
              justifyContent="flex-end"
            >
              <Typography
                style={{
                  color: "#17174C61",
                  fontSize: 12,
                  cursor: "pointer",
                  paddingRight: (isMobileDevice() || isTabletDevice()) && 16,
                }}
                onClick={() => markAllNotificationsRead()}
              >
                Mark all as read
              </Typography>
            </Grid>
          ) : null}
        </Grid>

        {todayNotifications.length ? (
          <NotificationItem
            notifications={todayNotifications}
            getDescription={getDescription}
            getAvatarUrl={getAvatarUrl}
            handleNotification={handleNotification}
            handleNotificationsClose={handleNotificationsClose}
            deleteNotification={deleteNotification}
          />
        ) : null}

        {olderNotifications && olderNotifications.length ? (
          <React.Fragment>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  style={{
                    color: "#17174C61",
                    fontSize: 12,
                    paddingLeft: (isMobileDevice() || isTabletDevice()) && 16,
                  }}
                >
                  OLDER
                </Typography>
              </Grid>
            </Grid>
            <NotificationItem
              notifications={olderNotifications}
              getDescription={getDescription}
              getAvatarUrl={getAvatarUrl}
              handleNotification={handleNotification}
              handleNotificationsClose={handleNotificationsClose}
              deleteNotification={deleteNotification}
            />
            <Typography
              className={Styles.navyBlueColor}
              style={{ fontSize: 12, textAlign: "center" }}
            >
              You are now all caught up!
            </Typography>
          </React.Fragment>
        ) : null}
      </Grid>
    </React.Fragment>
  );
}
