import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";

function CustomEmailMessageDialog({
  openCustomEmailMessageDialog,
  setOpenCustomEmailMessageDialog,
  customEmailTitle,
  setCustomEmailTitle,
  customEmailMessage,
  setCustomEmailMessage,
  defaultEmailTitle,
  setDefaultEmailTitle,
  defaultEmailMessage,
  setDefaultEmailMessage,
}) {
  useEffect(() => {
    if (openCustomEmailMessageDialog) {
      setCustomEmailTitle(defaultEmailTitle || "");
      setCustomEmailMessage(defaultEmailMessage || "");
    }
  }, [openCustomEmailMessageDialog]);
  const handleDialogClose = (flag) => {
    if (flag) {
      setDefaultEmailTitle(customEmailTitle);
      setDefaultEmailMessage(customEmailMessage);
    }
    setOpenCustomEmailMessageDialog(false);
  };
  return (
    <Dialog
      fullWidth
      disableBackdropClick
      maxWidth={"md"}
      open={openCustomEmailMessageDialog || false}
      onClose={() => {
        handleDialogClose();
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Custom Email Message</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ paddingTop: 16 }}>
        <Grid item xs={12} style={{ paddingBottom: 32 }}>
          <Typography variant="body1" className={Styles.navyBlueColor}>
            Do you want to send a custom title and message with your email?
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 16 }}>
          <TextField
            fullWidth
            label={"Title"}
            variant="outlined"
            value={customEmailTitle}
            onChange={(e) => {
              setCustomEmailTitle(e.target.value);
            }}
            inputProps={{
              maxLength: 500,
            }}
          ></TextField>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 8 }}>
          <TextField
            fullWidth
            multiline
            rows={6}
            label="Message"
            variant="outlined"
            value={customEmailMessage}
            onChange={(e) => {
              setCustomEmailMessage(e.target.value);
            }}
            inputProps={{
              maxLength: 5000,
            }}
          ></TextField>
        </Grid>
        <Grid
          item
          container
          xs={12}
          style={{ paddingBottom: 16, justifyContent: "flex-end" }}
        >
          <Typography variant="caption" className={Styles.navyBlueColor}>
            {(customEmailMessage || "").length} / 5000
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: "16px 24px" }}>
        <Button
          variant="outlined"
          style={{
            padding: "6px 16px",
            marginRight: 12,
            textTransform: "capitalize",
          }}
          className={Styles.navyBlueColorForce}
          onClick={() => {
            setCustomEmailTitle(defaultEmailTitle || "");
            setCustomEmailMessage(defaultEmailMessage || "");
            handleDialogClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            handleDialogClose(true);
          }}
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize", padding: "6px 32px" }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomEmailMessageDialog;
