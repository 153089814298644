import {
  SET_REVIEWS_LIST,
  SET_TOTAL_REVIEWS,
  SET_REVIEW_STATISTICS,
  SET_STOREFRONT_SERVICES_LIST,
} from "../../souqh-redux/reducers/actionConstants";
import { useSelector, useDispatch } from "react-redux";
import { useApiClient } from "../useApiClient";
import { useState, useEffect } from "react";
import { useAuthUser } from "../useLogin";
import { useActions } from "../useActions";

const actionCreators = {
  clearReviewList: (payload) => ({
    type: SET_REVIEWS_LIST,
    payload: { reviewsList: [] },
  }),
};

export const useStorefrontClientReviews = (pageSize, url, storeFrontId) => {
  const { apiClient } = useApiClient();
  const user = useAuthUser();
  const actions = useActions(actionCreators);

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [searchedService, setSearchedService] = useState(null);
  const [allReviews, setAllReviews] = useState(null);

  const {
    reviewsList,
    totalReviews,
    reviewStatistics,
    storefrontServicesList,
  } = useSelector((state) => state.storefrontClientReview);
  const { storeFrontRouteName } = useSelector((state) => state.storefront);

  useEffect(() => {
    if (page) {
      getReviews(page, url, storeFrontId);
    }
  }, [page]);

  useEffect(() => {
    setAllReviews(reviewsList);
  }, []);

  //Get Reviews list
  const getReviews = (page = 0, url, storeFrontId) => {
    if (storeFrontId) {
      dispatch({ type: "SET_LOADING", value: true });
      return apiClient
        .get(
          `${url}?page=${
            page - 1
          }&size=${pageSize}&storefrontId=${storeFrontId}`
        )
        .then(function (response) {
          if (response.data.response.length) {
            dispatch({
              type: SET_REVIEWS_LIST,
              reviewsList: response.data.response,
            });
            dispatch({
              type: SET_TOTAL_REVIEWS,
              totalReviews: {
                totalItems: response.data.totalItems,
                totalPages: response.data.totalPages,
              },
            });
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          // always executed
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const getReviewStatistics = (reviewStatsUrl, storeFrontId) => {
    if (storeFrontId) {
      dispatch({ type: "SET_LOADING", value: true });
      return apiClient
        .get(`${reviewStatsUrl}?storefront-id=${storeFrontId}`)
        .then(function (response) {
          if (response.data.result) {
            dispatch({
              type: SET_REVIEW_STATISTICS,
              reviewStatistics: response.data.result,
            });
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          // always executed
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const getStorefrontServices = () => {
    if (storeFrontId) {
      dispatch({ type: "SET_LOADING", value: true });
      return apiClient
        .get(
          `/service-provider/storefront?serviceProviderId=
            ${user.serviceProviderId || user.parentServiceProviderId}`
        )
        .then(function (response) {
          if (response.data.result) {
            const { services, storeFrontContent } = response.data.result[0];
            let rStoreFront = JSON.parse(storeFrontContent);
            rStoreFront.services = services;
            rStoreFront.storeFrontId = response.data.result[0].storeFrontId;
            rStoreFront.storeFrontRouteName =
              response.data.result[0].storeFrontRouteName;
            if (rStoreFront) {
              dispatch({
                type: SET_STOREFRONT_DATA,
                payload: { storeFront: rStoreFront },
              });
            }
            dispatch({
              type: SET_STOREFRONT_SERVICES_LIST,
              storefrontServicesList: services,
            });
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          // always executed
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const searchReviews = (searchUrl, service) => {
    if (storeFrontId) {
      if (service) {
        dispatch({ type: "SET_LOADING", value: true });
        return apiClient
          .get(
            `${searchUrl}?page=${page - 1}&sfServiceId=${
              service.id
            }&size=${pageSize}&storefront-id=${storeFrontId}`
          )
          .then(function (response) {
            if (response.data.response) {
              dispatch({
                type: SET_REVIEWS_LIST,
                reviewsList: response.data.response,
              });
            }
          })
          .catch(function (error) {
            console.error(error);
          })
          .then(function () {
            dispatch({ type: "SET_LOADING", value: false });
          });
      } else {
        dispatch({
          type: SET_REVIEWS_LIST,
          reviewsList: allReviews,
        });
      }
    }
  };
  return {
    getReviews,
    handlePageChange,
    reviewsList,
    totalReviews,
    getReviewStatistics,
    reviewStatistics,
    searchedService,
    setSearchedService,
    searchReviews,
    storefrontServicesList,
    getStorefrontServices,
    storeFrontRouteName,
    actions,
  };
};
