import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  RESET_EXPLORE_FILTERS,
  SET_SELECTED_AREA_OF_SPEC,
  SET_SELECTED_BADGE,
  SET_SELECTED_BUSINESS_TYPES,
  SET_SELECTED_LANGUAGES,
  SET_SELECTED_RATING,
  SET_SELECTED_SUB_BUSINESS_TYPES,
} from "../../souqh-redux/reducers/actionConstants";
import { useActions } from "../useActions";
import { useApiClient } from "../useApiClient";

export const useStoreFrontSearchFilters = () => {
  const { apiClient } = useApiClient();

  const actionCreators = {
    setSelectedBusinessTypes: (payload) => ({
      type: SET_SELECTED_BUSINESS_TYPES,
      payload,
    }),
    setSelectedAreaOfSpec: (payload) => ({
      type: SET_SELECTED_AREA_OF_SPEC,
      payload,
    }),
    setSelectedLanguage: (payload) => ({
      type: SET_SELECTED_LANGUAGES,
      payload,
    }),
    setRating: (payload) => ({
      type: SET_SELECTED_RATING,
      payload,
    }),
    setBadge: (payload) => ({
      type: SET_SELECTED_BADGE,
      payload,
    }),
    setSubBusinessTypes: (payload) => ({
      type: SET_SELECTED_SUB_BUSINESS_TYPES,
      payload,
    }),
    resetExploreFilters: (payload) => ({
      type: RESET_EXPLORE_FILTERS,
      payload,
    }),
  };
  const dispatch = useDispatch();
  const actions = useActions(actionCreators);
  const {
    selectedBusinessType,
    selectedAreaOfSpec,
    selectedLanguage,
    rating,
    badge,
  } = useSelector((state) => state.explorePageFilters);

  const getSubBusinessTypes = (businessTypeId) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/business/types/subcategory/${businessTypeId}`)
      .then(function (response) {
        if (!response.data.content.length) {
          // we need response.data.content with values in every condition
          throw Error("Server Error : Business type not found");
        } else {
          actions.setSubBusinessTypes(response.data.content);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    selectedBusinessType,
    selectedAreaOfSpec,
    selectedLanguage,
    rating,
    badge,
    getSubBusinessTypes,
    actions,
  };
};
