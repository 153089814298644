import React from "react";
import {
  MenuList,
  MenuItem,
  Grid,
  Avatar,
  Typography,
  Badge,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";
import CloseIcon from "@material-ui/icons/Close";
import NotificationStatus from "./NotificationStatus";
import * as moment from "moment";
import { isMobileDevice } from "../../utils/AppUtils";

const useStyles = makeStyles((theme) => ({
  customBadge: {
    backgroundColor: "#17174C",
  },
}));

export default function NotificationItem({
  notifications,
  getDescription,
  getAvatarUrl,
  handleNotification,
  handleNotificationsClose,
  deleteNotification,
}) {
  const classes = useStyles();

  let notificationArray = [
    "CLIENT_ASSIGNED",
    "DELETE_MEETING",
    "DELETE_MEETING_TO_SP",
    "NEW_MEETING",
    "NEW_MEETING_TO_SP",
    "UPDATE_MEETING_TO_SP",
  ];
  return (
    <MenuList id="menu-list-grow" className={Styles.navyBlueColor}>
      {notifications && notifications.length
        ? notifications.map((data) => (
            <MenuItem
              key={data.notificationId}
              style={{
                backgroundColor: !data.isRead && "#0E1C4E0A",
                marginBottom: 8,
                padding: "10px 0px",
                borderRadius: 4,
              }}
              onClick={() => {
                handleNotification(data);
                handleNotificationsClose();
              }}
            >
              <Grid container alignItems="center">
                <Grid item xs={1} style={{ paddingLeft: 16 }}>
                  {!data.isRead && (
                    <Badge
                      variant="dot"
                      classes={{ badge: classes.customBadge }}
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Avatar
                    style={{ height: 56, width: 56, borderRadius: 4 }}
                    variant="square"
                    src={
                      data.userAvatarUrl
                        ? data.userAvatarUrl
                        : data.storefrontAvatarUrl
                        ? getAvatarUrl(data.storefrontAvatarUrl)
                        : "/broken-image.jpg"
                    }
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  style={{
                    paddingRight: 16,
                    paddingLeft: isMobileDevice() && 10,
                  }}
                >
                  <Grid item xs={12}>
                    <Typography
                      style={{ fontSize: 12, fontWeight: 600 }}
                      className={Styles.sqEllipsis}
                    >
                      {data.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Tooltip
                      title={getDescription(
                        data.description,
                        notificationArray.includes(data.notification)
                          ? data.createdAt
                          : data.eventFromDateTime
                      )}
                    >
                      <Typography
                        style={{ fontSize: 12 }}
                        className={Styles.sqEllipsis}
                      >
                        {getDescription(
                          data.description,
                          notificationArray.includes(data.notification)
                            ? data.createdAt
                            : data.eventFromDateTime
                        )}
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      style={{ fontSize: 12, color: "#17174C61" }}
                      className={Styles.sqEllipsis}
                    >
                      {moment(new Date(data.createdAt)).fromNow()}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={2} justifyContent="flex-end">
                  <NotificationStatus notificationStatus={data.status} />
                </Grid>
                <Grid item xs={1} style={{ paddingLeft: 12 }}>
                  <CloseIcon
                    style={{ fontSize: 14 }}
                    onClick={(event) => {
                      event.stopPropagation();
                      deleteNotification(data.notificationId);
                    }}
                  />
                </Grid>
              </Grid>
            </MenuItem>
          ))
        : null}
    </MenuList>
  );
}
