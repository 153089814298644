import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuthUser } from "../useLogin";
import { SET_PAYMENT_HISTORY } from "souqh-redux/reducers/actionConstants";
import { useApiClient } from "../useApiClient";

const usePaymentHistory = () => {
  const user = useAuthUser();
  const dispatch = useDispatch();
  const { apiClient } = useApiClient();
  const { payments } = useSelector((state) => state.paymentHistory);

  const getPaymentHistory = () => {
    dispatch({ type: "SET_LOADING", value: true });

    apiClient
      .get(`/client/history/home-buyer/payment-history?userId=${user.userId}`)
      .then((res) => {
        if (res.data.status === 200 && res.data.result) {
          dispatch({ type: SET_PAYMENT_HISTORY, payload: res.data.result });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    getPaymentHistory,
    payments,
  };
};

export default usePaymentHistory;
