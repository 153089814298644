import { Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import { useCompanyOverview } from "souqh-react-redux-hooks/serviceProvider/storefront/useCompanyOverview";
import { useTheme } from "souqh-react-redux-hooks/serviceProvider/storefront/useTheme";
import Styles from "../../../Styles.module.css";

function Overview() {
  const { primaryColor } = useTheme();
  const { companyOverview, actions } = useCompanyOverview();
  return (
    <Grid container>
      <TextField
        multiline
        placeholder="Company overview on storefront"
        required
        rows={9}
        rowsMax={9}
        variant="outlined"
        fullWidth
        value={companyOverview}
        inputProps={{ style: { color: "#0e1c4e" } }}
        onChange={(e) => {
          actions.setCompanyOverview({ companyOverview: e.target.value });
        }}
        inputProps={{
          className: Styles.navyBlueColor,
          maxLength: 600,
        }}
      />
      <Grid container item justifyContent="flex-end">
        <Typography variant="body2" style={{ color: "#17174C99" }}>
          {companyOverview.length}/600
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Overview;
