import React from "react";
import { Typography, Grid } from "@material-ui/core/";
import Styles from "../../../../Styles.module.css";
import clsx from "clsx";
import { useDocumentHistory } from "souqh-react-redux-hooks/serviceProvider/documents/useDocumentHistory";

export default function HistoryHeader({ historyData }) {
  const { getFormattedTimeStamp } = useDocumentHistory();

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        height: "100%",
        alignItems: "center",
        padding: 12,
        backgroundColor: "#0E1C4E0A",
      }}
    >
      <Grid item xs={5}>
        <Typography variant="subtitle1" className={Styles.navyBlueColor}>
          Document Name
        </Typography>
        <Typography
          variant="body1"
          className={clsx(Styles.sqEllipsis, Styles.navyBlueColor)}
        >
          {historyData.documentName}
        </Typography>
      </Grid>

      <Grid item xs={5}>
        <Typography variant="subtitle1" className={Styles.navyBlueColor}>
          Document Created
        </Typography>
        <Typography
          variant="body1"
          className={clsx(Styles.sqEllipsis, Styles.navyBlueColor)}
        >
          {getFormattedTimeStamp(historyData.created)}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="subtitle1" className={Styles.navyBlueColor}>
          Document Pages
        </Typography>
        <Typography
          variant="body1"
          className={clsx(Styles.sqEllipsis, Styles.navyBlueColor)}
        >
          {historyData.pageNo}
        </Typography>
      </Grid>
    </Grid>
  );
}
