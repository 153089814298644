import {
  Button,
  Grid,
  makeStyles,
  Typography,
  TextField,
  Paper,
  Tabs,
  Tab,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Table,
  Popper,
  ClickAwayListener,
  TableSortLabel,
  useMediaQuery,
  useTheme,
  Divider,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import SearchIcon from "@material-ui/icons/Search";
import React, { useRef, useEffect, useState } from "react";
import clsx from "clsx";
import Styles from "../../../Styles.module.css";
import DocumentsRow from "./DocumentsRow";
import { useDocuments } from "souqh-react-redux-hooks/serviceProvider/documents/useDocuments";
import DeleteCategoryDialog from "../../DocumentManagementDialogs/DeleteCategoryDialog";
import RenameCategoryDialog from "../../DocumentManagementDialogs/RenameCategoryDialog";
import AddCategoryDialog from "../../DocumentManagementDialogs/AddCategoryDialog";
import DeleteDocumentDialog from "../../DocumentManagementDialogs/DeleteDocumentDialog";
import ArchiveDocumentDialog from "../../DocumentManagementDialogs/ArchiveDocumentDialog";
import VoidDocumentDialog from "../../DocumentManagementDialogs/VoidDocumentDialog";
import MoveDocumentDialog from "../../DocumentManagementDialogs/MoveDocumentDialog";
import { useDownloadFile } from "souqh-react-redux-hooks/useDownloadFile";
import RenameDocumentDialog from "../../DocumentManagementDialogs/RenameDocumentDialog";
import {
  DOCUMENTS_TABS,
  DOCUMENTS_TABLE_HEADERS,
  ACTION_REQUIRED,
  WAITING_FOR_OTHER,
  EXPIRING_SOON,
  COMPLETED,
  ARCHIVED,
  OTHER_DOCUMENTS,
  MY_TEMPLATES,
  TEMPLATE_DOCUMENTS_TABLE_HEADERS,
  SP_SIGN_NOW_ACCOUNT_EXIST,
} from "../serviceProviderConstants";
import SPeSignWizard from "./SPeSignWizard";
import SPUploadWizard from "../../common/UploadWizard/SPUploadWizard";
import ShareDocumentDialog from "../../DocumentManagementDialogs/ShareDocumentDialog";
import { useSPUploadWizard } from "souqh-react-redux-hooks/serviceProvider/documents/useSPUploadWizard";
import AddTemplate from "./AddTemplate/AddTemplate";
import { useAddTemplate } from "souqh-react-redux-hooks/serviceProvider/documents/useAddTemplate";
import { useSPeSignWizard } from "souqh-react-redux-hooks/serviceProvider/documents/useSPeSignWizard";
import DocumentHistoryDialog from "./DocumentHistory/DocumentHistoryDialog";
import { useDocumentHistory } from "souqh-react-redux-hooks/serviceProvider/documents/useDocumentHistory";
import CategoryActions from "./CategoryActions";
import InviteAndUseTemplate from "./AddTemplate/InviteAndUseTemplate";
import EditTemplate from "./AddTemplate/EditTemplate";
import SignDocumentDialog from "./SignDocumentDialog";
import { useSignDocument } from "souqh-react-redux-hooks/serviceProvider/documents/useSignDocument";
import {
  getSelectedTabIndex,
  isMobileDevice,
  isTabletDevice,
} from "../../../utils/AppUtils";
import { useSQQuery } from "souqh-react-redux-hooks/common/useSQQuery";
import EditEsignDocumentDialog from "./EditEsignDocument/EditEsignDocumentDialog";
import EditDocWarningDialog from "./EditDocWarningDialog";
import { useDispatch } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import SearchNotFound from "../../common/SearchNotFound";
import NoDataAvailable from "../../common/NoDataAvailable";

const useStyles = makeStyles((theme) => ({
  tabs: {
    minWidth: "10%",
    fontWeight: "bold",
    textTransform: "capitalize",
    opacity: "100%",
    fontSize: 14,
  },
  tabMinwidth: {
    // minWidth: "15%",
  },
  tabMinwidthSmall: {
    // minWidth: "12%",
  },
  marginTop: {
    marginTop: 25,
  },
  title: {
    fontSize: 32,
    fontWeight: "bold",
  },
  tableHeading: {
    fontWeight: "bold",
  },
  marginTop8: {
    marginTop: 8,
  },
  marginTop23: {
    marginTop: 23,
  },
  cursorPointer: {
    cursor: "pointer",
  },
  textInput: {
    "& .MuiInput-underline:before, & .MuiInput-underline:hover:before": {
      borderBottom: "none",
    },
  },
  root: {
    "&.MuiTabScrollButton-root": {
      width: "0px",
    },
  },
  modal: {
    zIndex: "1400 !important",
  },
  paper: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function EnhancedTableHead({
  classes,
  order,
  orderBy,
  onRequestSort,
  isMyTemplatesTab,
  tab,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const tableMap = isMyTemplatesTab
    ? TEMPLATE_DOCUMENTS_TABLE_HEADERS
    : DOCUMENTS_TABLE_HEADERS;
  return (
    <>
      {tableMap.map((headCell) => (
        <TableCell
          className={Styles.navyBlueColorForce}
          key={headCell.id}
          align={headCell.id === "quickActions" ? "right" : "left"}
          sortDirection={orderBy === headCell.id ? order : false}
          style={
            isTabletDevice() || isMobileDevice()
              ? { minWidth: headCell.width }
              : { width: headCell.width }
          }
        >
          <TableSortLabel
            active={
              headCell.id === "quickActions" ? false : orderBy === headCell.id
            }
            disabled={
              headCell.id === "quickActions" ||
              headCell.id === "sharedWith" ||
              headCell.id === "quickActions2"
            }
            direction={orderBy === headCell.id ? order : "asc"}
            onClick={createSortHandler(headCell.id)}
            style={{ fontSize: 14, fontWeight: 600 }}
          >
            {/* table header name as Contact Name intead of Client Name only for Other Documents Tab */}
            {headCell.id === "sharedWith" && tab === "Other Documents"
              ? "Contact Name"
              : headCell.label}
          </TableSortLabel>
        </TableCell>
      ))}
    </>
  );
}

export default function Documents() {
  const theme = useTheme();
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const [tabDrawer, setTabDrawer] = useState(false);
  const {
    order,
    orderBy,
    handleRequestSort,
    actions: { setDocumentTab, setDocumentTabName, setDocumentsTableData },
    documentTab,
    openDeleteCategoryDialog,
    setOpenDeleteCategoryDialog,
    openAddCategoryDialog,
    setOpenAddCategoryDialog,
    openMenu,
    handleToggle,
    handleClose,
    handleListKeyDown,
    openRenameCategoryDialog,
    setOpenRenameCategoryDialog,
    getSharedWithTooltip,
    openDeleteDocumentDialog,
    setOpenDeleteDocumentDialog,
    openVoidDocumentDialog,
    setOpenVoidDocumentDialog,
    openMoveDocumentDialog,
    setOpenMoveDocumentDialog,
    openArchiveDocumentDialog,
    setOpenArchiveDocumentDialog,
    openRenameDocumentDialog,
    setOpenRenameDocumentDialog,
    categories,
    searchVal,
    documentsTableData,
    openShareDocumentDialog,
    setOpenShareDocumentDialog,
    selectedData,
    setSelectedData,
    handleCheckboxChange,
    selectedDataForDownload,
    setSelectedDataForDownload,
    extractSelectedData,
    deselectDocfromSelection,
    updateIntercomStatus,
    documentTabName,
    getTabWiseCategoriesList,
    actionRequiredCount,
    waitingForOtherCount,
    expiringSoonCount,
    completedCount,
    archivedCount,
    otherDocumentsCount,
    myTemplatesCount,
    getShowDialogFlag,
    templateForUse,
    setTemplateForUse,
    setSearchText,
    searchText,
    userInfo,
    documentDataForEdit,
    setDocumentDataForEdit,
    isSignNowUserVerified,
    isUserVerifiedForSignNow,
    showSignNowVerificationToaster,
  } = useDocuments();

  const { query } = useSQQuery();

  const {
    onUploadEsignFileChange,
    openEsignWizard,
    actions: {
      setOpenEsignWizard,
      setOpenEditEsignDocumentDialog,
      setOpenEditWarningDialog,
    },
    openEditEsignDocumentDialog,
    closeAndFinishEsign,
    openEditWarningDialog,
    updateShowDialogFlag,
    showDialogFlag,
  } = useSPeSignWizard();

  useEffect(() => {
    const tabName = query.get("tabName");
    const _documentId = query.get("documentId");
    const _userId = query.get("userId");
    if (_documentId && _userId) {
      setOpenSignDocumentDialog(true);
    }
    if (tabName) {
      setDocumentTabName(tabName);
    } else {
      setDocumentTabName(ACTION_REQUIRED);
    }
    getShowDialogFlag();
    isSignNowUserVerified();

    return () => {
      setDocumentTabName("");
      setDocumentTab(0);
      setDocumentsTableData([]);
      closeAndFinishAddTemplate();
      closeAndFinishEsign();
      cleanUploadFileDataOnClose();
    };
  }, []);

  useEffect(() => {
    if (documentTabName) {
      getTabWiseCategoriesList(documentTabName);
    }
    const newIndex = getSelectedTabIndex(userInfo.userType, documentTabName);
    setDocumentTab(newIndex);
  }, [documentTabName]);

  useEffect(() => {
    let temp = [];
    let dataForDownload = [];
    extractSelectedData(temp, dataForDownload);
    setSelectedData(temp);
    setSelectedDataForDownload(dataForDownload);
  }, [documentsTableData]);

  const {
    onUploadFileChange,
    openUploadWizard,
    actions: { setOpenUploadWizard, cleanUploadFileDataOnClose },
  } = useSPUploadWizard();

  const {
    onUploadTemplateFileChange,
    openAddTemplate,
    actions: {
      setOpenAddTemplate,
      setOpenEditTemplate,
      setOpenInviteAndSendTemplate,
    },
    openInviteAndSendTemplate,
    openEditTemplate,
    closeAndFinishAddTemplate,
  } = useAddTemplate(getTabWiseCategoriesList, setDocumentTabName);

  const { downLoadDocument, downloadMultipleDocument } = useDownloadFile(
    selectedDataForDownload
  );

  const {
    openDocumentHistoryDialog,
    setOpenDocumentHistoryDialog,
    currentDocument,
    setCurrentDocument,
  } = useDocumentHistory();

  const {
    openSignDocumentDialog,
    setOpenSignDocumentDialog,
    signDocumentItem,
    setSignDocumentItem,
  } = useSignDocument();

  const classes = useStyles();
  const anchorRef = useRef(null);
  const dispatch = useDispatch();

  const firstCategory = categories && categories.length && categories[0].name;

  const tabCount = (tab) => {
    if (tab === ACTION_REQUIRED) {
      return actionRequiredCount;
    } else if (tab === WAITING_FOR_OTHER) {
      return waitingForOtherCount;
    } else if (tab === EXPIRING_SOON) {
      return expiringSoonCount;
    } else if (tab === COMPLETED) {
      return completedCount;
    } else if (tab === ARCHIVED) {
      return archivedCount;
    } else if (tab === OTHER_DOCUMENTS) {
      return otherDocumentsCount;
    } else {
      return myTemplatesCount;
    }
  };

  const isMyTemplatesTab = documentTabName === MY_TEMPLATES;

  const moreActionsList = () => (
    <div
      className={classes.fullList}
      role="presentation"
      onClick={() => {
        setTabDrawer(false);
      }}
      onKeyDown={() => {
        setTabDrawer(false);
      }}
    >
      <Grid container item xs={12} style={{ padding: 16 }}>
        <Grid item xs={10}>
          <Typography variant="subtitle1" className={Styles.navyBlueColorForce}>
            More Information
          </Typography>
        </Grid>
        <Grid container item xs={2} justifyContent="flex-end">
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              setTabDrawer(false);
            }}
          />
        </Grid>
      </Grid>
      <Divider />
      <List>
        {DOCUMENTS_TABS.map((tab, index) => (
          <ListItem
            key={index}
            onClick={() => {
              setDocumentTabName(tab);
              setSearchText("");
            }}
          >
            <ListItemText
              primary={tab}
              className={Styles.navyBlueColorForce}
              style={{ fontSize: 14 }}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  let noDocumentsInTab =
    documentsTableData &&
    documentsTableData.every((el) => !el.documents.length);

  return (
    <Grid
      item
      container
      xs={12}
      style={{ padding: isTabletDevice() && "0px 80px" }}
    >
      <SwipeableDrawer
        classes={{ modal: classes.modal, paper: classes.paper }}
        anchor="bottom"
        open={tabDrawer}
        onClose={() => {
          setTabDrawer(false);
        }}
        onOpen={() => {
          setTabDrawer(true);
        }}
      >
        {tabDrawer && moreActionsList()}
      </SwipeableDrawer>
      <DeleteCategoryDialog
        openDeleteCategoryDialog={openDeleteCategoryDialog}
        setOpenDeleteCategoryDialog={setOpenDeleteCategoryDialog}
        categories={categories}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
        documentTabName={documentTabName}
      />
      <RenameCategoryDialog
        openRenameCategoryDialog={openRenameCategoryDialog}
        setOpenRenameCategoryDialog={setOpenRenameCategoryDialog}
        categories={categories}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
        documentTabName={documentTabName}
      />
      <AddCategoryDialog
        openAddCategoryDialog={openAddCategoryDialog}
        setOpenAddCategoryDialog={setOpenAddCategoryDialog}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
        documentTabName={documentTabName}
      />
      <DeleteDocumentDialog
        openDeleteDocumentDialog={openDeleteDocumentDialog.open}
        setOpenDeleteDocumentDialog={setOpenDeleteDocumentDialog}
        docDetails={openDeleteDocumentDialog.docDetails}
        categoryId={openDeleteDocumentDialog.categoryId}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
        documentTabName={documentTabName}
      />
      <ArchiveDocumentDialog
        openArchiveDocumentDialog={openArchiveDocumentDialog.open}
        setOpenArchiveDocumentDialog={setOpenArchiveDocumentDialog}
        docDetails={openArchiveDocumentDialog.docDetails}
      />
      <VoidDocumentDialog
        openVoidDocumentDialog={openVoidDocumentDialog.open}
        setOpenVoidDocumentDialog={setOpenVoidDocumentDialog}
        documentName={openVoidDocumentDialog.documentName}
        docDetails={openVoidDocumentDialog.docDetails}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
        documentTabName={documentTabName}
      />
      <MoveDocumentDialog
        openMoveDocumentDialog={openMoveDocumentDialog.open}
        setOpenMoveDocumentDialog={setOpenMoveDocumentDialog}
        documentName={openMoveDocumentDialog.documentName}
        documentItem={openMoveDocumentDialog.docData}
        categories={categories}
        documentId={openMoveDocumentDialog.documentId}
        sourceCategoryId={openMoveDocumentDialog.sourceCategoryId}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
        documentTabName={documentTabName}
      />
      <RenameDocumentDialog
        openRenameDocumentDialog={openRenameDocumentDialog.open}
        setOpenRenameDocumentDialog={setOpenRenameDocumentDialog}
        documentId={openRenameDocumentDialog.documentId}
        categoryId={openRenameDocumentDialog.categoryId}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
        documentTabName={documentTabName}
        documentName={openRenameDocumentDialog.documentName}
        documentItem={openRenameDocumentDialog.docData}
      />
      <ShareDocumentDialog
        openShareDocumentDialog={openShareDocumentDialog}
        setOpenShareDocumentDialog={setOpenShareDocumentDialog}
        selectedData={selectedData}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
        documentTabName={documentTabName}
        deselectDocfromSelection={deselectDocfromSelection}
      />
      <AddTemplate
        openAddTemplate={openAddTemplate}
        setOpenAddTemplate={setOpenAddTemplate}
        updateIntercomStatus={updateIntercomStatus}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
        setDocumentTabName={setDocumentTabName}
        documentTabName={documentTabName}
      />
      <EditTemplate
        openEditTemplate={openEditTemplate}
        setOpenAddTemplate={setOpenEditTemplate}
        updateIntercomStatus={updateIntercomStatus}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
        setDocumentTabName={setDocumentTabName}
        documentTabName={documentTabName}
      />
      <InviteAndUseTemplate
        openInviteAndSendTemplate={openInviteAndSendTemplate}
        setOpenInviteAndSendTemplate={setOpenInviteAndSendTemplate}
        updateIntercomStatus={updateIntercomStatus}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
        setDocumentTabName={setDocumentTabName}
        templateForUse={templateForUse}
        setTemplateForUse={setTemplateForUse}
        documentTabName={documentTabName}
      />

      <SPUploadWizard
        openUploadWizard={openUploadWizard}
        setOpenUploadWizard={setOpenUploadWizard}
        categories={categories}
        updateIntercomStatus={updateIntercomStatus}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
        setDocumentTabName={setDocumentTabName}
        documentTabName={documentTabName}
        setDocumentTab={setDocumentTab}
      />
      <SPeSignWizard
        openEsignWizard={openEsignWizard}
        setOpenEsignWizard={setOpenEsignWizard}
        updateIntercomStatus={updateIntercomStatus}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
        setDocumentTabName={setDocumentTabName}
        documentTabName={documentTabName}
      />
      <EditEsignDocumentDialog
        openEditEsignDocumentDialog={openEditEsignDocumentDialog}
        setOpenEditEsignDocumentDialog={setOpenEditEsignDocumentDialog}
        updateIntercomStatus={updateIntercomStatus}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
        setDocumentTabName={setDocumentTabName}
        documentTabName={documentTabName}
        documentDataForEdit={documentDataForEdit}
        setDocumentDataForEdit={setDocumentDataForEdit}
      />
      <DocumentHistoryDialog
        openDocumentHistoryDialog={openDocumentHistoryDialog}
        setOpenDocumentHistoryDialog={setOpenDocumentHistoryDialog}
        updateIntercomStatus={updateIntercomStatus}
        currentDocument={currentDocument}
        setCurrentDocument={setCurrentDocument}
      />

      <SignDocumentDialog
        openSignDocumentDialog={openSignDocumentDialog}
        setOpenSignDocumentDialog={setOpenSignDocumentDialog}
        updateIntercomStatus={updateIntercomStatus}
        getTabWiseCategoriesList={getTabWiseCategoriesList}
        setDocumentTabName={setDocumentTabName}
        signDocumentItem={signDocumentItem}
        setSignDocumentItem={setSignDocumentItem}
        documentTabName={documentTabName}
      />

      <EditDocWarningDialog
        openEditWarningDialog={openEditWarningDialog}
        setOpenEditWarningDialog={setOpenEditWarningDialog}
        updateShowDialogFlag={updateShowDialogFlag}
        showDialogFlag={showDialogFlag}
        setOpenEditEsignDocumentDialog={setOpenEditEsignDocumentDialog}
      />

      <Grid item xs={12} style={{ marginTop: 0 }}>
        <Typography className={clsx(classes.title, Styles.navyBlueColor)}>
          Documents
        </Typography>
      </Grid>
      <Grid container item xs={12} className={classes.marginTop8}>
        <Grid item xs={12} lg={5}>
          <Grid item xs={12}>
            <Paper
              elevation={0}
              style={{
                fontStyle: "italic",
                paddingLeft: 15,
                border:
                  (isTabletDevice() || isMobileDevice()) &&
                  "1px solid  #17174C1A",
              }}
              className={Styles.navyBlueColorForce}
            >
              <Grid container>
                <Grid item xs={11} style={{ marginTop: 10 }}>
                  <TextField
                    value={searchText}
                    className={classes.textInput}
                    fullWidth
                    placeholder="Search by Document Name, Status, Client name"
                    onChange={(event) => searchVal(event.target.value)}
                  />
                </Grid>
                <Grid container item xs={1} justifyContent="flex-end">
                  <SearchIcon
                    style={{ marginTop: 10, paddingRight: 10 }}
                    className={clsx(classes.primarColor)}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        {!isMobileDevice() && (
          <Grid
            container
            item
            xs={12}
            lg={7}
            justifyContent={isTabletDevice() ? "flex-start" : "flex-end"}
            style={{ paddingTop: 10, paddingBottom: 10 }}
          >
            <input
              style={{ display: "none" }}
              id="contained-button-file-ref-template"
              multiple
              type="file"
              onChange={onUploadTemplateFileChange}
            />
            {isUserVerifiedForSignNow && userInfo.signNowAccountCreated ? (
              <label htmlFor="contained-button-file-ref-template">
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: 6,
                    textTransform: "capitalize",
                    marginRight: 16,
                  }}
                  component="span"
                >
                  Add Template
                </Button>
              </label>
            ) : (
              <Button
                variant="outlined"
                style={{
                  borderRadius: 6,
                  textTransform: "capitalize",
                  marginRight: 16,
                }}
                onClick={() => {
                  if (userInfo.signNowAccountCreated) {
                    showSignNowVerificationToaster();
                  } else {
                    dispatch({
                      type: "SET_SERVER_ERROR",
                      error: {
                        message: SP_SIGN_NOW_ACCOUNT_EXIST,
                        title: "Existing SignNow Account Error",
                      },
                    });
                  }
                }}
              >
                Add Template
              </Button>
            )}

            <input
              style={{ display: "none" }}
              id="contained-button-file"
              multiple
              type="file"
              onChange={onUploadFileChange}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="outlined"
                style={{
                  textTransform: "none",
                  paddingLeft: 24,
                  paddingRight: 24,
                  marginRight: 16,
                }}
                component="span"
              >
                Upload Documents
              </Button>
            </label>

            <input
              style={{ display: "none" }}
              id="contained-button-file-esign"
              multiple
              type="file"
              onChange={onUploadEsignFileChange}
            />
            {isUserVerifiedForSignNow && userInfo.signNowAccountCreated ? (
              <label htmlFor="contained-button-file-esign">
                <Button
                  style={{
                    textTransform: "none",
                    paddingLeft: 24,
                    paddingRight: 24,
                  }}
                  className={Styles.primaryBtnAccent}
                  component="span"
                >
                  Send eSign Documents
                </Button>
              </label>
            ) : (
              <Button
                style={{
                  textTransform: "none",
                  paddingLeft: 24,
                  paddingRight: 24,
                }}
                className={Styles.primaryBtnAccent}
                onClick={() => {
                  if (userInfo.signNowAccountCreated) {
                    showSignNowVerificationToaster();
                  } else {
                    dispatch({
                      type: "SET_SERVER_ERROR",
                      error: {
                        message: SP_SIGN_NOW_ACCOUNT_EXIST,
                        title: "Existing SignNow Account Error",
                      },
                    });
                  }
                }}
              >
                Send eSign Documents
              </Button>
            )}
          </Grid>
        )}
      </Grid>
      {isTabletDevice() && (
        <Divider
          orientation="horizontal"
          style={{ width: "100%", marginTop: 8 }}
        />
      )}
      <Grid
        item
        xs={12}
        className={clsx(
          "hideScroll",
          !isTabletDevice() && !isMobileDevice() && classes.marginTop23
        )}
        style={{
          marginTop: (isMobileDevice() || isTabletDevice()) && 8,
          display: isTabletDevice() && "flex",
          flexWrap: isTabletDevice() && "nowrap",
          overflowX: isTabletDevice() && "auto",
        }}
      >
        {isMobileDevice() ? (
          <Grid item container xs={12}>
            <Button
              fullWidth
              variant="outlined"
              className={Styles.navyBlueColorForce}
              style={{
                textTransform: "capitalize",
                marginTop: 8,
                padding: 12,
              }}
              onClick={() => setTabDrawer(true)}
              endIcon={<ExpandMoreIcon />}
            >
              {documentTabName}
            </Button>
          </Grid>
        ) : (
          <Paper elevation={0}>
            <Tabs
              value={documentTab}
              variant="scrollable"
              scrollButtons="false"
              onChange={(event, newValue) => {
                setDocumentTab(newValue);
              }}
              aria-label="doc-tabs-container"
              className={!isTabletDevice() && "sqCustomDocAlign"}
              classes={{ indicator: Styles.indicator }}
            >
              {DOCUMENTS_TABS.map((tab, index) => (
                <Tab
                  key={index}
                  label={`${tab} (${tabCount(tab)})`}
                  className={clsx(
                    classes.tabs,
                    {
                      [Styles.primaryTxtAccentForce]: index === documentTab,
                      [Styles.navyBlueColorForce]: index !== documentTab,
                    },
                    xlUp ? classes.tabMinwidth : classes.tabMinwidthSmall
                  )}
                  onClick={() => {
                    setDocumentTabName(tab);
                    setSearchText("");
                  }}
                />
              ))}
            </Tabs>
          </Paper>
        )}
      </Grid>
      <Grid item xs={12} className={classes.marginTop}>
        {/* <Paper elevation={0}> */}
        {DOCUMENTS_TABS.map((tab, index) => (
          <TabPanel value={documentTab} index={index} key={index}>
            {!noDocumentsInTab && (
              <Paper elevation={0}>
                <TableContainer>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <EnhancedTableHead
                          classes={classes}
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          headCells={DOCUMENTS_TABLE_HEADERS}
                          isMyTemplatesTab={isMyTemplatesTab}
                          tab={tab}
                        />

                        <TableCell
                          align="right"
                          className={clsx(
                            classes.tableHeading,
                            Styles.navyBlueColorForce
                          )}
                        >
                          <MoreHorizIcon
                            ref={anchorRef}
                            onClick={handleToggle}
                            className={classes.cursorPointer}
                          />
                          <Popper
                            open={openMenu}
                            anchorEl={anchorRef.current}
                            placement="bottom-end"
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <CategoryActions
                                  handleListKeyDown={handleListKeyDown}
                                  handleClose={handleClose}
                                  setOpenAddCategoryDialog={
                                    setOpenAddCategoryDialog
                                  }
                                  setOpenRenameCategoryDialog={
                                    setOpenRenameCategoryDialog
                                  }
                                  setOpenDeleteCategoryDialog={
                                    setOpenDeleteCategoryDialog
                                  }
                                />
                              </ClickAwayListener>
                            </Paper>
                          </Popper>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {documentsTableData && documentsTableData.length
                        ? documentsTableData.map((category, index) => (
                            <DocumentsRow
                              key={index}
                              category={category.name}
                              categoryId={category.id}
                              documents={category.documents}
                              firstCategory={firstCategory}
                              classes={classes}
                              setOpenUploadWizard={setOpenUploadWizard}
                              order={order}
                              orderBy={orderBy}
                              openRenameCategoryDialog={
                                openRenameCategoryDialog
                              }
                              setOpenDeleteDocumentDialog={
                                setOpenDeleteDocumentDialog
                              }
                              setOpenVoidDocumentDialog={
                                setOpenVoidDocumentDialog
                              }
                              setOpenMoveDocumentDialog={
                                setOpenMoveDocumentDialog
                              }
                              setOpenArchiveDocumentDialog={
                                setOpenArchiveDocumentDialog
                              }
                              setOpenRenameDocumentDialog={
                                setOpenRenameDocumentDialog
                              }
                              getSharedWithTooltip={getSharedWithTooltip}
                              downLoadDocument={downLoadDocument}
                              setOpenShareDocumentDialog={
                                setOpenShareDocumentDialog
                              }
                              handleCheckboxChange={handleCheckboxChange}
                              downloadMultipleDocument={
                                downloadMultipleDocument
                              }
                              selectedData={selectedData}
                              setSelectedData={setSelectedData}
                              getTabWiseCategoriesList={
                                getTabWiseCategoriesList
                              }
                              documentTabName={documentTabName}
                              setOpenDocumentHistoryDialog={
                                setOpenDocumentHistoryDialog
                              }
                              setCurrentDocument={setCurrentDocument}
                              isMyTemplatesTab={isMyTemplatesTab}
                              setTemplateForUse={setTemplateForUse}
                              setOpenEditTemplate={setOpenEditTemplate}
                              setOpenSignDocumentDialog={
                                setOpenSignDocumentDialog
                              }
                              setSignDocumentItem={setSignDocumentItem}
                              setDocumentDataForEdit={setDocumentDataForEdit}
                            />
                          ))
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            )}
            {noDocumentsInTab && !searchText ? (
              <Grid container item xs={12} justifyContent="center">
                <NoDataAvailable>
                  <Typography className={Styles.navyBlueColor}>
                    Looks like you do not have any documents. Upload a document
                    to get started
                  </Typography>
                </NoDataAvailable>
              </Grid>
            ) : searchText ? (
              <SearchNotFound functionality="documents" />
            ) : null}
          </TabPanel>
        ))}
        {/* </Paper> */}
      </Grid>
    </Grid>
  );
}
