import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useRoleManagement } from "souqh-react-redux-hooks/common/useRoleManagement";
import Styles from "../../Styles.module.css";

export default function NoAccessDialog({
  openNoAccessDialog,
  setOpenNoAccessDialog,
}) {
  const { getAdminNames } = useRoleManagement();

  return (
    <Dialog fullWidth maxWidth={"sm"} open={openNoAccessDialog}>
      <DialogContent>
        <Typography className={Styles.navyBlueColor}>
          Please contact {getAdminNames()} for access
        </Typography>
      </DialogContent>
      <DialogActions>
        <Grid container item justifyContent="flex-end">
          <Button
            variant="outlined"
            onClick={() => {
              setOpenNoAccessDialog(false);
            }}
            className={Styles.primaryBtnAccent}
          >
            Close
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
