import { useDispatch } from "react-redux";
import { useAuthUser } from "../useLogin";
import { useApiClient } from "../useApiClient";

export const useDuplicateDocument = (
  getTabWiseCategoriesList,
  documentTabName
) => {
  const { apiClient } = useApiClient();

  const userInfo = useAuthUser();

  const dispatch = useDispatch();
  const duplicateDocument = (categoryId, docDetails, onResult) => {
    if (docDetails && docDetails.signNowDocumentId) {
      duplicateEsignedDoc(docDetails, onResult);
    } else {
      duplicateRefDoc(categoryId, docDetails, onResult);
    }
  };

  const duplicateEsignedDoc = (docDetails, onResult) => {
    const duplicateData = {
      documentId: docDetails.signNowDocumentId,
      isTemplate: docDetails.isTemplate,
      userId: userInfo.userId,
    };

    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post("/signnow/create/document", {}, { params: duplicateData })
      .then((res) => {
        if (res.data.status === 200) {
          getTabWiseCategoriesList &&
            documentTabName &&
            getTabWiseCategoriesList(documentTabName);
        }
        onResult && onResult(res);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const duplicateRefDoc = (categoryId, docDetails, onResult) => {
    const duplicateData = {
      categoryId,
      documentId: docDetails.docId,
      documentName: docDetails.docName,
      fileName: docDetails.fileName,
      userId: userInfo.userId,
    };
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post("/documents/duplicate", duplicateData)
      .then((res) => {
        if (res.data.status === 200) {
          getTabWiseCategoriesList &&
            documentTabName &&
            getTabWiseCategoriesList(documentTabName);
        }
        onResult && onResult(res);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    duplicateDocument,
  };
};
