import axios from "axios";
import useLocalStorage from "react-use-localstorage";
import { useDispatch } from "react-redux";
import { useAuthUser } from "./useLogin";
import { INITIAL_STATE_STORE } from "../souqh-redux/reducers/actionConstants";

export const useApiClient = () => {
  const [userInfo, setUserInfo] = useLocalStorage("userInfo");
  const dispatch = useDispatch();
  const userData = useAuthUser();

  const apiClient = axios.create({
    baseURL: process.env.REACT_APP_SOUQH_API_SERVER_BASE_URL,
    // timeout: 5000,
    /**
     * Successful responses (200-299)
     * Redirects (300–399)
     * Client errors (400-499)
     */
    validateStatus: function (status) {
      return status >= 200 && status < 500;
    },
  });

  apiClient.interceptors.request.use((config) => {
    if (userData) {
      config.headers.Authorization = userData.access_token;
      config.headers.refresh_token = userData.refresh_token;
      config["Access-Control-Allow-Origin value"] = "*";
    }
    return config;
  });

  apiClient.interceptors.response.use(
    (response) => {
      if (response.status === 401) {
        dispatch({ type: "SAVE_USER", payload: null });
        dispatch({ type: INITIAL_STATE_STORE });
        setUserInfo("");
        return Promise.reject(response);
      }
      return response;
    },
    (error) => {
      if (error && error.toString().includes("500")) {
        dispatch({ type: "SET_ERROR_NOTIFICATION", error: error });
      }
      return Promise.reject(error);
    }
  );

  return {
    apiClient,
  };
};
