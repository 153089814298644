import { SET_EMAIL_PREFERENCES } from "./actionConstants";
import { cloneDeep } from "lodash";

const initialState = {
  preferences: {
    messagesReceived: true,
    pendingActions: true,
    upcomingEvents: true,
    client: true,
    serviceProvider: true,
    document: true,
    referral: true,
    marketing: true,
    sfVisitSummary: "PER_SF_VISIT",
  },
};

const emailPreferences = (state = initialState, action) => {
  switch (action.type) {
    case SET_EMAIL_PREFERENCES:
      const newState = cloneDeep(state);
      newState.preferences = action.payload;
      return newState;
    default:
      return state;
  }
};

export default emailPreferences;
