import React from "react";
import MaskedInput from "react-text-mask";
import TextField from "@material-ui/core/TextField";

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
    />
  );
}

export default function MaskedMobileInput(props) {
  return (
    <TextField {...props} InputProps={{ inputComponent: TextMaskCustom }} />
  );
}
