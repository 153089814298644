import React from "react";
import {
  Typography,
  Button,
  makeStyles,
  Divider,
  Grid,
  Select,
  MenuItem,
} from "@material-ui/core/";
import clsx from "clsx";
import Styles from "../../../Styles.module.css";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useDispatch } from "react-redux";
import { MANDATORY_DETAILS_MESSAGE } from "../serviceProviderConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined": {
      top: -8,
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px",
    },
  },
}));
export default function EsignActionBtns({
  eSignDocumentList,
  duplicateFileName,
  activeEsignSection,
  setActiveEsignSection,
  checkForDocumentDescriptions,
  setOpenCustomEmailMessageDialog,
  setOpenUploadCancelDialog,
  documentsTagged,
  selectedTaggedDoucment,
  setSelectedTaggedDoucment,
  selectedDocumentForSummary,
  setSelectedDocumentForSummary,
  validateTaggedDocument,
  getSignnowInviteData,
  inviteForEsign,
  isAllDataFilled,
  setOpenEsignWizard,
  showDialogFlag,
  setConfirmGoBackDialog,
  resetStep1,
  setSelectedContact,
  disableSkipBtn,
  deleteUploadedESignDoc,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const documentTaggingDropdown =
    activeEsignSection == 2 && documentsTagged && documentsTagged.length > 1 ? (
      <Select
        fullWidth
        variant="outlined"
        value={selectedTaggedDoucment}
        onChange={(event) => {
          setSelectedTaggedDoucment(event.target.value);
        }}
      >
        {documentsTagged.map((item, index) => {
          return (
            <MenuItem key={index} value={item}>
              {item.documentName}
            </MenuItem>
          );
        })}
      </Select>
    ) : null;

  const summaryAdditionDropdown =
    activeEsignSection == 3 && documentsTagged && documentsTagged.length > 1 ? (
      <Select
        fullWidth
        variant="outlined"
        value={selectedDocumentForSummary}
        onChange={(event) => {
          setSelectedDocumentForSummary(event.target.value);
        }}
      >
        {documentsTagged.map((item, index) => {
          return (
            <MenuItem key={index} value={item}>
              {item.documentName}
            </MenuItem>
          );
        })}
      </Select>
    ) : null;

  return (
    <>
      <Divider />

      <Grid item container xs={12} alignItems="center" className={classes.root}>
        <Grid item container xs={3}>
          {documentTaggingDropdown}
          {summaryAdditionDropdown}
        </Grid>
        <Grid
          item
          container
          xs={9}
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: 0,
          }}
        >
          <Grid>
            {documentsTagged.length > 1 && activeEsignSection === 2 && (
              <Typography
                variant="caption"
                style={{ color: "#ff0000", marginRight: 8, paddingLeft: 8 }}
              >
                Please click 'Save and Close', before clicking 'Next'.
              </Typography>
            )}
            {duplicateFileName && activeEsignSection === 1 && (
              <Typography
                variant="body2"
                style={{ color: "red", marginRight: 8 }}
              >
                ** File Name already exist, please rename the file and try
                again.
              </Typography>
            )}
          </Grid>
          <Grid>
            {activeEsignSection !== 1 && (
              <Button
                startIcon={<ArrowBackIosIcon />}
                style={{
                  textTransform: "capitalize",
                  marginRight: 20,
                }}
                className={Styles.navyBlueColorForce}
                onClick={() => {
                  if (activeEsignSection === 2) {
                    if (showDialogFlag) {
                      setConfirmGoBackDialog(true);
                    } else {
                      deleteUploadedESignDoc();
                    }
                  } else {
                    if (activeEsignSection === 4) {
                      setSelectedContact(null);
                    }
                    setActiveEsignSection(activeEsignSection - 1);
                  }
                }}
              >
                Go Back
              </Button>
            )}
            {activeEsignSection === 1 ? (
              <Button
                variant="outlined"
                style={{
                  textTransform: "capitalize",
                  borderRadius: 4,
                  padding: "6px 16px",
                  marginRight: 12,
                }}
                className={clsx(Styles.navyBlueColor)}
                onClick={() => {
                  setOpenUploadCancelDialog(true);
                }}
              >
                Cancel
              </Button>
            ) : null}
            {activeEsignSection === 3 ? (
              <Button
                disabled={disableSkipBtn}
                variant="outlined"
                style={{
                  textTransform: "capitalize",
                  borderRadius: 4,
                  padding: "6px 16px",
                  marginRight: 12,
                }}
                className={clsx(Styles.navyBlueColor)}
                onClick={() => {
                  getSignnowInviteData();
                }}
              >
                Skip This Step
              </Button>
            ) : null}

            {activeEsignSection === 4 ? (
              <Button
                variant="outlined"
                style={{
                  textTransform: "capitalize",
                  padding: "6px 16px",
                  marginRight: 12,
                }}
                className={clsx(Styles.navyBlueColorForce)}
                onClick={() => {
                  setOpenCustomEmailMessageDialog(true);
                }}
              >
                Customize Email
              </Button>
            ) : null}
            {activeEsignSection !== 4 ? (
              <Button
                disabled={!eSignDocumentList.length}
                style={{
                  textTransform: "capitalize",
                  borderRadius: 4,
                  padding: "6px 32px",
                }}
                className={clsx(
                  Styles.navyBlueColorForce,
                  Styles.primaryBtnAccent
                )}
                onClick={() => {
                  if (activeEsignSection === 1) {
                    checkForDocumentDescriptions();
                  } else if (activeEsignSection === 2) {
                    validateTaggedDocument();
                  } else if (activeEsignSection === 3) {
                    getSignnowInviteData();
                  }
                }}
              >
                Next
              </Button>
            ) : (
              <Button
                style={{
                  textTransform: "capitalize",
                  padding: "6px 32px",
                }}
                className={Styles.primaryBtnAccent}
                onClick={() => {
                  if (!isAllDataFilled()) {
                    dispatch({
                      type: "SET_SERVER_ERROR",
                      error: { message: MANDATORY_DETAILS_MESSAGE },
                    });
                  } else {
                    inviteForEsign(() => {
                      setOpenEsignWizard(false);
                    });
                  }
                }}
              >
                Invite &#38; Send
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
