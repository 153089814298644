import React from "react";

export default function NotificationStatus({ notificationStatus }) {
  let status = (notificationStatus || "").toLowerCase().trim();
  let color = "#2842D0";
  let backgroundColor = "#C9CFF3";

  //FIX ME: add condition for canceled and success statuses

  if (status) {
    switch (status) {
      case "new":
        status = "NEW";
        break;
      case "completed":
        color = "#0A4C25";
        backgroundColor = "#C3DECE";
        status = "COMPLETED";
        break;
      case "accepted":
        color = "#0A4C25";
        backgroundColor = "#C3DECE";
        status = "ACCEPTED";
        break;
      case "rejected":
        color = "#700000";
        backgroundColor = "#EEBFBF";
        status = "REJECTED";
        break;
      case "access_req":
        color = "#987400";
        backgroundColor = "#FFEFBF";
        status = "ACCESS REQ";
        break;
      case "todo":
        color = "#868DA6";
        backgroundColor = "#F5F6F8";
        status = "TO DO";
        break;
      default:
        break;
    }
  }
  return (
    <span
      style={{
        backgroundColor,
        borderRadius: 4,
        textAlign: "center",
        padding: "1px 4px",
        fontSize: 12,
        color,
      }}
    >
      {status || "-"}
    </span>
  );
}
