import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  makeStyles,
  TextField,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { useRequestDocument } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useRequestDocument";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 620,
    minWidth: !isMobileDevice() && !isTabletDevice() && 800,
  },
}));

function RequestDocumentDialog({
  openRequestDocumentDialog,
  setOpenRequestDocumentDialog,
  userId,
  serviceRequestId,
}) {
  const classes = useStyles();
  const {
    getClientHubDocumentsList,
    clientHubDocuments,
    requestDocument,
    requestDocComments,
    setRequestDocComments,
    dueDate,
    setDueDate,
    setSelectedDocId,
    setSelectedDocName,
    selectedDocId,
    selectedDocName,
    clearData,
  } = useRequestDocument(userId, serviceRequestId);

  useEffect(() => {
    if (openRequestDocumentDialog) {
      getClientHubDocumentsList();
    }
  }, [openRequestDocumentDialog]);

  return (
    <Dialog
      fullWidth
      disableBackdropClick
      open={openRequestDocumentDialog}
      onClose={() => {
        setOpenRequestDocumentDialog(false);
      }}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Request Document</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid item xs={12} style={{ paddingBottom: 16, paddingTop: 16 }}>
          <Autocomplete
            freeSolo
            fullWidth
            noOptionsText={"Select document"}
            options={clientHubDocuments || []}
            getOptionLabel={(option) => option.docName || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select document"
                variant="outlined"
                autoComplete="new-password"
                onChange={(event, value) => {
                  setSelectedDocName(event.target.value);
                }}
              />
            )}
            onChange={(event, value) => {
              value ? setSelectedDocId(value.docId) : setSelectedDocId(null);
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 16 }}>
          <TextField
            multiline
            placeholder="Add comments"
            required
            rows={9}
            rowsMax={9}
            variant="outlined"
            fullWidth
            inputProps={{
              className: Styles.navyBlueColor,
              maxLength: 500,
            }}
            onChange={(event) => {
              setRequestDocComments(event.target.value.trim());
            }}
          />
          <Grid container item justifyContent="flex-end">
            <Typography variant="body2" style={{ color: "#17174C99" }}>
              {requestDocComments.length}/500
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 16 }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              fullWidth
              inputVariant="outlined"
              format="MM/dd/yyyy"
              id="date-picker-inline"
              label="Select deadline"
              value={dueDate}
              minDate={new Date()}
              autoOk={true}
              keyboardIcon={
                <DateRangeIcon
                  style={{
                    fontSize: 20,
                    fill: "#1B1E4C",
                  }}
                />
              }
              onChange={(event) => {
                setDueDate(event);
              }}
              InputAdornmentProps={{
                position: "start",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions style={{ padding: "16px 24px" }}>
        <Button
          variant="outlined"
          style={{
            padding: "6px 16px",
            marginRight: 12,
            textTransform: "capitalize",
          }}
          className={Styles.navyBlueColorForce}
          onClick={() => {
            setOpenRequestDocumentDialog(false);
            clearData();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!selectedDocName && !selectedDocId}
          variant="contained"
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize", padding: "6px 32px" }}
          onClick={() => {
            requestDocument((res) => {
              if (res && res.data.status === 200) {
                setOpenRequestDocumentDialog(false);
                clearData();
              }
            });
          }}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RequestDocumentDialog;
