import { useSelector } from "react-redux";

export const useRepresentativeMock2 = () => {
  const { numberOfEmployees, yearsInBusiness } = useSelector(
    (state) => state.storefront
  );

  return {
    numberOfEmployees,
    yearsInBusiness,
  };
};
