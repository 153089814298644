import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Divider,
  makeStyles,
  Chip,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";

const styles = makeStyles((theme) => ({
  dialogPaper: {
    height: 402,
    width: 606,
  },
}));

function SeeMoreDoc({
  openSeeMoreDocPopup,
  setOpenSeeMoreDocPopup,
  docList,
  setDocumentItem,
  getUploadedDocuments,
  setOpenDocumetDialog,
  setDownloadUrl,
  setDocCategoryId,
  setDocNameForDownload,
  setDocUserId,
}) {
  const classes = styles();
  return (
    <Dialog
      fullWidth
      open={openSeeMoreDocPopup}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Attachments</Typography>
      </DialogTitle>
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        style={{ width: "100%" }}
      />
      <DialogContent>
        {docList && docList.length
          ? docList.map((doc, index) => (
              <Chip
                item
                key={index}
                style={{
                  marginTop: 10,
                  marginRight: 5,
                  textOverflow: "ellipsis",
                  marginLeft: 5,
                  // width: "90%", //To add elipsis to chip
                }}
                label={doc.documentName}
                onClick={() => {
                  setDocumentItem(getUploadedDocuments(doc));
                  setDownloadUrl(doc.documentUrl);
                  setDocCategoryId && setDocCategoryId(doc.categoryId);
                  setDocNameForDownload && setDocNameForDownload(doc.fileName);
                  setOpenDocumetDialog(true);
                  setDocUserId && setDocUserId(doc && doc.userId);
                }}
              />
            ))
          : null}
      </DialogContent>
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        style={{ width: "100%" }}
      />
      <DialogActions>
        <Button
          variant="outlined"
          className={Styles.navyBlueColorForce}
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            setOpenSeeMoreDocPopup(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SeeMoreDoc;
