const uiReducer = (
  state = {
    loading: 0,
    toasterData: { bgColor: "#c3dece", message: "", open: false },
  },
  action
) => {
  const newState = { ...state };
  switch (action.type) {
    case "SET_SERVER_ERROR":
      newState.serverError = action.error;
      return newState;
    case "SET_ERROR_NOTIFICATION":
      newState.errorNotification = action.error;
      return newState;
    case "SET_LOADING":
      if (action.value) {
        newState.loading = newState.loading + 1;
      } else {
        newState.loading = newState.loading - 1;
        // never go below zero
        if (newState.loading < 0) {
          newState.loading = 0;
        }
      }
      return newState;
    case "SET_TOASTER":
      newState.toasterData = action.payload;
      return newState;
    default:
      return state;
  }
};

export default uiReducer;
