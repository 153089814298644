import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Divider,
  Avatar,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import clsx from "clsx";

const styles = makeStyles((theme) => ({
  dialogPaper: {
    height: 602,
    width: 606,
  },
}));

function GroupMembersDialog({
  openGroupMembersDialog,
  setOpenGroupMembersDialog,
  selectedChannelMembersList,
  souqhUserInfo,
}) {
  const classes = styles();
  if (!selectedChannelMembersList) {
    return;
  }
  return (
    <Dialog
      fullWidth
      open={openGroupMembersDialog}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <div
          style={{ width: "95%", fontSize: 16 }}
          className={clsx(Styles.navyBlueColor)}
        >
          Participants
        </div>
      </DialogTitle>
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        style={{ width: "100%" }}
      />
      <DialogContent>
        {selectedChannelMembersList.length
          ? selectedChannelMembersList.map((user, index) => (
              <Grid container key={index} style={{ marginBottom: 12 }}>
                <Grid container item xs={1} alignContent="center">
                  {user.profilePhoto ? (
                    <Avatar
                      src={user.profilePhoto}
                      style={{ width: 40, height: 40, borderRadius: 4 }}
                    />
                  ) : (
                    <AccountBoxIcon
                      style={{ fontSize: 40, fill: "#0e1c4e", opacity: 0.3 }}
                    />
                  )}
                </Grid>
                <Grid container item xs={11} alignContent="center">
                  <Typography
                    variant="body1"
                    className={clsx(Styles.sqEllipsis, Styles.navyBlueColor)}
                  >
                    {user.fullName ? user.fullName : user.email}
                  </Typography>
                </Grid>
              </Grid>
            ))
          : null}
      </DialogContent>
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        style={{ width: "100%" }}
      />
      <DialogActions>
        <Button
          variant="outlined"
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            setOpenGroupMembersDialog(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default GroupMembersDialog;
