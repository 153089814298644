import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Divider,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import StorefrontPage from "../../HomeBuyer/Explore/StorefrontPage/StorefrontPage";
import { useHistory } from "react-router";
import { useApiClient } from "souqh-react-redux-hooks/useApiClient";
import CloseIcon from "@material-ui/icons/Close";
import ViewWrapper from "../../common/ViewWrapper";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: window.innerHeight - 20,
    borderRadius: 4,
  },
  dialogRoot: {
    padding: 16,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function PreviewStorefrontDialog({
  openPreviewSFDialog,
  setOpenPreviewSFDialog,
  previewStorefrontId,
  fetch,
  updateIntercomStatus,
  sourcePage,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { apiClient } = useApiClient();

  useEffect(() => {
    updateIntercomStatus && updateIntercomStatus(openPreviewSFDialog);
  }, [openPreviewSFDialog]);
  return (
    <Dialog
      open={openPreviewSFDialog}
      fullScreen
      maxWidth="lg"
      classes={{ paper: classes.dialogPaper, root: classes.dialogRoot }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Preview</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => {
            setOpenPreviewSFDialog(false);
            fetch(apiClient);
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        style={{ width: "100%" }}
      />
      <DialogContent>
        <ViewWrapper>
          <StorefrontPage previewStorefrontId={previewStorefrontId} />
        </ViewWrapper>
      </DialogContent>
      <DialogActions style={{ paddingRight: 20 }}>
        <Button
          className={Styles.navyBlueColorForce}
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            setOpenPreviewSFDialog(false);
            fetch(apiClient);
          }}
          variant="outlined"
        >
          Continue Editing
        </Button>
        {sourcePage !== "manageStoreFront" ? (
          <Button
            className={Styles.primaryBtnAccent}
            style={{ textTransform: "capitalize" }}
            onClick={() => {
              setOpenPreviewSFDialog(false);
              history.push("/setup/subscription");
            }}
          >
            Save And Continue
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}

export default PreviewStorefrontDialog;
