import { cloneDeep, findIndex } from "lodash";
import {
  SET_NOTIFICATIONS_LIST,
  SET_SHOW_ONLY_UNREAD_NOTIFICATIONS,
  UPDATE_READ_NOTIFICATION,
  UPDATE_ALL_READ_NOTIFICATION,
  DELETE_NOTIFICATION,
} from "./actionConstants";

const initialState = {
  notificationsList: { response: [] },
  showOnlyUnreadNotifications: false,
};
const notifications = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_NOTIFICATIONS_LIST:
      newState = cloneDeep(state);

      if (action.payload.currentPage) {
        newState.notificationsList.response =
          newState.notificationsList.response.concat(action.payload.response);
      } else {
        newState.notificationsList.response = action.payload.response;
      }

      newState.notificationsList.totalItems = action.payload.totalItems;
      newState.notificationsList.totalPages = action.payload.totalPages;

      return newState;
    case SET_SHOW_ONLY_UNREAD_NOTIFICATIONS:
      newState = cloneDeep(state);
      newState.showOnlyUnreadNotifications = action.payload;
      return newState;
    case UPDATE_READ_NOTIFICATION:
      newState = cloneDeep(state);
      const myIndex = findIndex(newState.notificationsList.response, [
        "notificationId",
        action.payload,
      ]);
      newState.notificationsList.response[myIndex].isRead = true;
      return newState;
    case UPDATE_ALL_READ_NOTIFICATION:
      newState = cloneDeep(state);
      newState.notificationsList.response.map((item) => {
        item.isRead = true;
      });
      return newState;
    case DELETE_NOTIFICATION:
      newState = cloneDeep(state);
      let notificationIndex = findIndex(
        newState.notificationsList.response,
        (item) => {
          return item.notificationId == action.payload;
        }
      );
      newState.notificationsList.response.splice(notificationIndex, 1);
      return newState;
    default:
      return state;
  }
};

export default notifications;
