import { SET_HB_KEY_STATS_DATA } from "souqh-redux/reducers/actionConstants";
import { useAuthUser } from "../useLogin";
import { useDispatch, useSelector } from "react-redux";
import { useApiClient } from "../useApiClient";

export const useHBKeyStatisticsWidget = () => {
  const userInfo = useAuthUser();
  const dispatch = useDispatch();
  const { apiClient } = useApiClient();
  const { HBKeyStatisticsData } = useSelector((state) => state.keyStatistics);

  const getHBkeyStatisticsData = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/keystatitics/home-buyer/keystatitics?userId=${userInfo.userId}`)
      .then(function (response) {
        if (response.data.status === 200) {
          dispatch({
            type: SET_HB_KEY_STATS_DATA,
            payload: response.data.result,
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    getHBkeyStatisticsData,
    HBKeyStatisticsData,
  };
};
