import React, { useEffect, useState } from "react";
import {
  Avatar,
  Card,
  CardHeader,
  Grid,
  Typography,
  CardContent,
  Divider,
  IconButton,
  CardActions,
  Button,
  Tooltip,
} from "@material-ui/core";
import MessageIcon from "@material-ui/icons/Message";
import DateRangeIcon from "@material-ui/icons/DateRange";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";
import * as moment from "moment";
import DocumentPreviewDialog from "../../HomeBuyer/Explore/StorefrontPage/DocumentPreviewDialog";
import { Link } from "react-router-dom";
import RejectionReasonDialog from "../../ServiceProvider/ClientManagement/RejectionReasonDialog";
import Attachments from "./Attachments";
import { useNavigateToMessage } from "souqh-react-redux-hooks/common/useNavigateToMessage";
import { getUploadedDocuments, isMobileDevice } from "../../../utils/AppUtils";
import SPUploadWizard from "../../common/UploadWizard/SPUploadWizard";
import { useSPUploadWizard } from "souqh-react-redux-hooks/serviceProvider/documents/useSPUploadWizard";
import { useBroadcastServiceRequest } from "souqh-react-redux-hooks/homeBuyer/useBroadcastServiceRequest";
import { useHistory } from "react-router";
import RentalDetailsPopup from "../../common/RentalDetailsPopup";

export default function RequestSPInfoCard({
  data,
  storeFrontContent,
  storefrontDetails,
  getSpManagementData,
  pageNumber,
  getRequestsSPData,
  getArchiveSPData,
}) {
  const [openDocumetDialog, setOpenDocumetDialog] = useState(false);
  const [documentItem, setDocumentItem] = useState(null);
  const [openRejectionReasonDialog, setOpenRejectionReasonDialog] =
    useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [docCategoryId, setDocCategoryId] = useState(null);
  const [docNameForDownload, setDocNameForDownload] = useState(null);
  const [docUserId, setDocUserId] = useState(null);
  const [activeSPs, setActiveSPs] = useState([]);
  const [openRentalDetailsPopup, setOpenRentalDetailsPopup] = useState(false);

  const showMoreInfoCondition = isMobileDevice() && showMoreInfo;
  const history = useHistory();
  const { navigateMessage } = useNavigateToMessage();

  const {
    onUploadFileChange,
    openUploadWizard,
    actions: { setOpenUploadWizard },
  } = useSPUploadWizard();

  const {
    updateBroadcastServiceRequestStatus,
    getAcceptedSPsForBroadcastRequest,
  } = useBroadcastServiceRequest();

  // useEffect(() => {
  //   if (data.broadCastServiceRequestDto && data.broadCastServiceRequestDto.id) {
  //     getAcceptedSPsForBroadcastRequest(
  //       data.broadCastServiceRequestDto?.id,
  //       data.hbDetails.homeBuyerId,
  //       (res) => {
  //         if (res && res.data.status === 200) {
  //           setActiveSPs(res.data.result);
  //         }
  //       }
  //     );
  //   }
  // }, [data]);

  const timelineOptions = [
    { name: "Urgent", value: "URGENT" },
    { name: "As Soon As Possible", value: "AS_SOON_AS_POSSIBLE" },
    { name: "1-2 weeks", value: "ONE_TO_TWO_WEEKS" },
    { name: "2-4 weeks", value: "TWO_TO_FOUR_WEEKS" },
    { name: "4-8 weeks", value: "FOUR_TO_EIGHT_WEEKS" },
    { name: "8 weeks plus", value: "EIGHT_WEEKS_PLUS" },
  ];

  let formattedTimeline =
    data.broadCastServiceRequestDto &&
    timelineOptions.filter(
      (item) => item.value === data.broadCastServiceRequestDto.timeLine
    )[0];

  return (
    <React.Fragment>
      <RentalDetailsPopup
        openRentalDetailsPopup={openRentalDetailsPopup}
        setOpenRentalDetailsPopup={setOpenRentalDetailsPopup}
        data={data}
      />
      <SPUploadWizard
        openUploadWizard={openUploadWizard}
        setOpenUploadWizard={setOpenUploadWizard}
      />
      <RejectionReasonDialog
        openRejectionReasonDialog={openRejectionReasonDialog}
        setOpenRejectionReasonDialog={setOpenRejectionReasonDialog}
        getSpManagementData={getSpManagementData}
        title={"Cancel Request"}
        message={`Request Cancelled - ${storefrontDetails.storeFrontName}`}
        actionType="CANCEL_REQUEST"
      />
      <DocumentPreviewDialog
        openDocumetDialog={openDocumetDialog}
        setOpenDocumetDialog={setOpenDocumetDialog}
        documentItem={documentItem}
        setDocumentItem={setDocumentItem}
        downloadUrl={downloadUrl}
        source="sp-mgmt"
        docCategoryId={docCategoryId}
        docNameForDownload={docNameForDownload}
        docUserId={docUserId}
      />
      <Card
        elevation={0}
        style={{ border: !isMobileDevice() && "1px solid #0000001F" }}
      >
        {!data.broadCastServiceRequestDto && (
          <CardHeader
            style={{
              padding: isMobileDevice() && "16px 8px 16px 0px",
              height: 93,
            }}
            avatar={
              // <Link
              //   style={{ textDecoration: "none", cursor: "pointer" }}
              //   to={`/homebuyer/serviceproviders/details/${data.storefrontDetails.serviceProviderId}`}
              // >
              <Grid
                container
                style={{
                  // padding: !isMobileDevice() && "0px 16px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  history.push({
                    pathname: `/homebuyer/serviceproviders/details/${data.storefrontDetails.serviceProviderId}`,
                    state: {
                      email: storefrontDetails.email,
                    },
                  });
                }}
              >
                <Avatar
                  style={{ height: 76, width: 76, borderRadius: 4 }}
                  variant="square"
                  src={storeFrontContent.displayPicture}
                >
                  {(storefrontDetails.storeFrontName || "").charAt(0)}
                </Avatar>
                {/* </Link> */}
              </Grid>
            }
            title={
              <React.Fragment>
                <Typography
                  className={clsx(
                    Styles.navyBlueColor,
                    Styles.sqEllipsisOneLine
                  )}
                  variant="body1"
                  style={{ fontWeight: 500, marginLeft: 10 }}
                >
                  {storefrontDetails.storeFrontName}
                </Typography>
                <Tooltip title={data.serviceName}>
                  <Typography
                    className={clsx(
                      Styles.navyBlueColor,
                      Styles.sqEllipsisOneLine
                    )}
                    variant="body1"
                    style={{ marginLeft: 10, height: 20 }}
                  >
                    {data.serviceName}
                  </Typography>
                </Tooltip>
              </React.Fragment>
            }
            subheader={
              <Grid container>
                <Grid item xs={2}>
                  <IconButton>
                    <MessageIcon
                      className={Styles.navyBlueColor}
                      style={{ fontSize: 16 }}
                      onClick={(event) => {
                        event.preventDefault();
                        navigateMessage(event, storefrontDetails.email);
                      }}
                    />
                  </IconButton>
                </Grid>
                <Grid item xs={2}>
                  <IconButton>
                    <DateRangeIcon
                      className={Styles.navyBlueColor}
                      style={{ fontSize: 16 }}
                    />
                  </IconButton>
                </Grid>
                <Grid item xs={2}>
                  <input
                    style={{ display: "none" }}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(event) => {
                      onUploadFileChange(event);
                    }}
                  />
                  <label htmlFor="contained-button-file">
                    <IconButton component="span">
                      <InsertDriveFileIcon
                        className={Styles.navyBlueColor}
                        style={{ fontSize: 16 }}
                      />
                    </IconButton>
                  </label>
                </Grid>
              </Grid>
            }
          />
        )}
        {data.broadCastServiceRequestDto && isMobileDevice() && (
          <>
            <Grid container item xs={12}>
              <Grid item>
                <Typography
                  className={Styles.navyBlueColor}
                  variant="body1"
                  style={{ fontWeight: 500 }}
                >
                  Category:
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip title={data.broadCastServiceRequestDto?.businessType}>
                  <Typography
                    className={clsx(
                      Styles.navyBlueColor,
                      Styles.font14,
                      Styles.sqEllipsisTwoLines
                    )}
                    style={{
                      height: 30,
                      marginTop: 2,
                      marginLeft: 8,
                    }}
                  >
                    {data.broadCastServiceRequestDto?.businessType}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid container item xs={12} style={{ marginTop: -5 }}>
              <Grid item>
                <Typography
                  className={Styles.navyBlueColor}
                  variant="body1"
                  style={{ fontWeight: 500 }}
                >
                  City:
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip title={data.broadCastServiceRequestDto?.city}>
                  <Typography
                    className={clsx(
                      Styles.navyBlueColor,
                      Styles.font14,
                      Styles.sqEllipsisTwoLines
                    )}
                    style={{
                      height: 30,
                      marginTop: 2,
                      marginLeft: 8,
                    }}
                  >
                    {data.broadCastServiceRequestDto?.city}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid container item xs={12} style={{ marginTop: -5 }}>
              <Grid item>
                <Typography
                  className={Styles.navyBlueColor}
                  variant="body1"
                  style={{ fontWeight: 500 }}
                >
                  Timeline:
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip title={formattedTimeline?.name}>
                  <Typography
                    className={clsx(
                      Styles.navyBlueColor,
                      Styles.font14,
                      Styles.sqEllipsisTwoLines
                    )}
                    style={{
                      height: 30,
                      marginTop: 2,
                      marginLeft: 8,
                    }}
                  >
                    {formattedTimeline?.name}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              style={{ marginTop: -5, marginBottom: -5 }}
            >
              <Grid item>
                <Typography
                  className={Styles.navyBlueColor}
                  variant="body1"
                  style={{ fontWeight: 500 }}
                >
                  Matching Service Providers:
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className={clsx(
                    Styles.navyBlueColor,
                    Styles.font14,
                    Styles.sqEllipsisTwoLines
                  )}
                  style={{
                    height: 30,
                    marginTop: 2,
                    marginLeft: 8,
                    marginBottom: 12,
                  }}
                >
                  {data.broadCastServiceRequestDto?.matchedSPCount || "0"}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
        {(!isMobileDevice() || showMoreInfoCondition) && (
          <React.Fragment>
            <Divider />
            <CardContent>
              <Grid container>
                {data.broadCastServiceRequestDto && !isMobileDevice() && (
                  <>
                    <Grid container item xs={12} style={{ marginTop: -5 }}>
                      <Grid item>
                        <Typography
                          className={Styles.navyBlueColor}
                          variant="body1"
                          style={{ fontWeight: 500 }}
                        >
                          Category:
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Tooltip
                          title={data.broadCastServiceRequestDto?.businessType}
                        >
                          <Typography
                            className={clsx(
                              Styles.navyBlueColor,
                              Styles.font14,
                              Styles.sqEllipsisTwoLines
                            )}
                            style={{
                              height: 30,
                              marginTop: 2,
                              marginLeft: 8,
                            }}
                          >
                            {data.broadCastServiceRequestDto?.businessType}
                          </Typography>
                        </Tooltip>
                      </Grid>
                    </Grid>

                    <Grid container item xs={12} style={{ marginTop: -5 }}>
                      <Grid item>
                        <Typography
                          className={Styles.navyBlueColor}
                          variant="body1"
                          style={{ fontWeight: 500 }}
                        >
                          City:
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Tooltip title={data.broadCastServiceRequestDto?.city}>
                          <Typography
                            className={clsx(
                              Styles.navyBlueColor,
                              Styles.font14,
                              Styles.sqEllipsisTwoLines
                            )}
                            style={{
                              height: 30,
                              marginTop: 2,
                              marginLeft: 8,
                            }}
                          >
                            {data.broadCastServiceRequestDto?.city}
                          </Typography>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} style={{ marginTop: -5 }}>
                      <Grid item>
                        <Typography
                          className={Styles.navyBlueColor}
                          variant="body1"
                          style={{ fontWeight: 500 }}
                        >
                          Timeline:
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Tooltip title={formattedTimeline?.name}>
                          <Typography
                            className={clsx(
                              Styles.navyBlueColor,
                              Styles.font14,
                              Styles.sqEllipsisTwoLines
                            )}
                            style={{
                              height: 30,
                              marginTop: 2,
                              marginLeft: 8,
                            }}
                          >
                            {formattedTimeline?.name}
                          </Typography>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      style={{ marginTop: -5, marginBottom: -5 }}
                    >
                      <Grid item>
                        <Typography
                          className={Styles.navyBlueColor}
                          variant="body1"
                          style={{ fontWeight: 500 }}
                        >
                          Matching Service Providers:
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          className={clsx(
                            Styles.navyBlueColor,
                            Styles.font14,
                            Styles.sqEllipsisTwoLines
                          )}
                          style={{
                            height: 30,
                            marginTop: 2,
                            marginLeft: 8,
                          }}
                        >
                          {data.broadCastServiceRequestDto?.matchedSPCount ||
                            "0"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider
                      orientation="horizontal"
                      style={{
                        width: "120%",
                        marginLeft: -16,
                        marginTop: 4,
                        marginBottom: 15,
                      }}
                    />
                  </>
                )}
                <Grid item xs={12}>
                  <Grid item>
                    <Typography
                      className={Styles.navyBlueColor}
                      variant="body1"
                      style={{ fontWeight: 500 }}
                    >
                      Request Date
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      className={clsx(Styles.navyBlueColor, Styles.font14)}
                    >
                      {moment(data.requestedDate).format("MMM DD, YYYY")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Divider style={{ margin: "15px 0px" }} />
          <Grid container item xs={12}>
            <Grid item xs={2}>
              <Typography
                className={Styles.navyBlueColor}
                variant="body1"
                style={{ fontWeight: 500 }}
              >
                Status
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography style={{ textTransform: "uppercase" }}>
                <ClientStatus clientStatus={data.hbJourneyStatus} />
              </Typography>
            </Grid>
          </Grid> */}

              <Divider style={{ margin: "15px 0px" }} />
              <Grid item>
                <Typography
                  className={Styles.navyBlueColor}
                  variant="body1"
                  style={{ fontWeight: 500 }}
                >
                  Service Notes
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip title={data.notes}>
                  <Typography
                    className={clsx(
                      Styles.navyBlueColor,
                      Styles.font14,
                      Styles.sqEllipsisTwoLines
                    )}
                    style={{ minHeight: "3em" }}
                  >
                    {data.notes || "No details available"}
                  </Typography>
                </Tooltip>
              </Grid>
              <Divider style={{ margin: "15px 0px" }} />
              <Grid item>
                <Typography
                  className={Styles.navyBlueColor}
                  variant="body1"
                  style={{ fontWeight: 500 }}
                >
                  Attachments
                </Typography>
              </Grid>
              <Grid
                item
                container
                style={{
                  minHeight: !data.broadCastServiceRequestDto
                    ? 245
                    : !data.broadCastServiceRequestDto
                        .rentalForNewComersDetails && 110,
                }}
              >
                {data.attachments && data.attachments.length ? (
                  <Attachments
                    attachments={data.attachments}
                    limit={data.broadCastServiceRequestDto ? 1 : 5}
                    setDocumentItem={setDocumentItem}
                    getUploadedDocuments={getUploadedDocuments}
                    setOpenDocumetDialog={setOpenDocumetDialog}
                    setDownloadUrl={setDownloadUrl}
                    setDocCategoryId={setDocCategoryId}
                    setDocNameForDownload={setDocNameForDownload}
                    setDocUserId={setDocUserId}
                  />
                ) : (
                  <Typography
                    className={clsx(Styles.navyBlueColor, Styles.font14)}
                    style={{ fontStyle: "italic", minHeight: "3em" }}
                  >
                    No Attachments
                  </Typography>
                )}
              </Grid>
              {data.broadCastServiceRequestDto && (
                <Divider style={{ margin: "15px 0px" }} />
              )}
              {data.broadCastServiceRequestDto && (
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <Typography
                      className={Styles.navyBlueColor}
                      variant="body1"
                      style={{ fontWeight: 500 }}
                    >
                      Service Providers Who Responded
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={1}
                    style={{
                      marginTop: 5,
                      flexWrap: "nowrap",
                      overflowX: "auto",
                    }}
                  >
                    {data.broadCastServiceRequestDto &&
                    data.broadCastServiceRequestDto.acceptedSPs &&
                    data.broadCastServiceRequestDto.acceptedSPs.length ? (
                      data.broadCastServiceRequestDto.acceptedSPs.map(
                        (activeSp, index) => {
                          const storeFrontContentAcceptedSP = JSON.parse(
                            activeSp.storeFrontContent
                          );

                          return (
                            <Grid
                              item
                              xs={2}
                              key={index}
                              style={{
                                height:
                                  data.broadCastServiceRequestDto.acceptedSPs
                                    .length < 6 && 82,
                              }}
                            >
                              <React.Fragment>
                                <Grid
                                  container
                                  style={{
                                    // padding: !isMobileDevice() && "0px 16px",
                                    cursor: "pointer",
                                  }}
                                  // onClick={() => {
                                  //   history.push({
                                  //     pathname: `/homebuyer/serviceproviders/details/${activeSp.serviceProviderId}`,
                                  //     state: {
                                  //       email: activeSp.email,
                                  //     },
                                  //   });
                                  // }}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    navigateMessage(event, activeSp.email);
                                  }}
                                >
                                  <img
                                    alt={
                                      storeFrontContentAcceptedSP.storefrontName
                                    }
                                    src={
                                      storeFrontContentAcceptedSP.displayPicture ||
                                      "/images/souqh_logo.svg"
                                    }
                                    style={{
                                      height: 50,
                                      border: "1px solid #0E1C4E1F",
                                      borderRadius: 4,
                                      cursor: "pointer",
                                    }}
                                  />
                                </Grid>
                              </React.Fragment>
                            </Grid>
                          );
                        }
                      )
                    ) : (
                      //For constant card size: Show empty space if active providers are not available
                      <Grid item xs={12} style={{ height: 82, marginLeft: 10 }}>
                        <Typography
                          className={Styles.navyBlueColor}
                          variant="body1"
                        >
                          Waiting for quotes
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
              {data.broadCastServiceRequestDto &&
                data.broadCastServiceRequestDto.rentalForNewComersDetails && (
                  <Divider style={{ margin: "15px 0px" }} />
                )}
              {data.broadCastServiceRequestDto &&
                data.broadCastServiceRequestDto.rentalForNewComersDetails && (
                  <Grid container item xs={12}>
                    <Button
                      className={Styles.navyBlueColorForce}
                      variant="outlined"
                      onClick={() => setOpenRentalDetailsPopup(true)}
                      style={{ textTransform: "capitalize" }}
                    >
                      Show Rental Details
                    </Button>
                  </Grid>
                )}
            </CardContent>

            <Divider />
            <CardActions>
              <Grid container xs={12} item>
                <Grid item xs={12} container justifyContent="flex-end">
                  <Button
                    variant="outlined"
                    className={Styles.navyBlueColorForce}
                    style={{
                      fontSize: 14,
                      textTransform: "capitalize",
                      padding: 8,
                    }}
                    onClick={() => {
                      if (
                        data.broadCastServiceRequestDto &&
                        data.broadCastServiceRequestDto.id
                      ) {
                        updateBroadcastServiceRequestStatus(
                          data.broadCastServiceRequestDto.id,
                          "FULFILLED",
                          (res) => {
                            if (res && res.data.status === 200) {
                              // getDataOnTabChange && getDataOnTabChange();
                              getRequestsSPData &&
                                getRequestsSPData(pageNumber);
                              getSpManagementData && getSpManagementData();
                              getArchiveSPData && getArchiveSPData(0);
                            }
                          }
                        );
                      } else {
                        setOpenRejectionReasonDialog({
                          open: true,
                          id: data.serviceRequestId,
                        });
                      }
                    }}
                  >
                    Cancel request
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </React.Fragment>
        )}
        {isMobileDevice() && (
          <Grid container item xs={12} justifyContent="flex-end">
            <Button
              variant="text"
              className={Styles.primaryTxtAccentForce}
              style={{
                textTransform: "capitalize",
                marginTop: !showMoreInfo && -28,
              }}
              onClick={(event) => {
                event.stopPropagation();
                setShowMoreInfo(!showMoreInfo);
              }}
            >
              {showMoreInfo ? "Show less" : "Show more"}
            </Button>
          </Grid>
        )}
      </Card>
    </React.Fragment>
  );
}
