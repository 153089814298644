import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import { useAddContact } from "souqh-react-redux-hooks/serviceProvider/documents/useAddContact";
import { ALPHA_NUMERIC_PATTERN } from "../serviceProviderConstants";

function AddContactDialog({
  setAddContactDialog,
  openAddContactDialog,
  addContactItem,
  businessTypes,
}) {
  const {
    fullName,
    setFullName,
    emailAddress,
    setEmailAddress,
    businessType,
    setBusinessType,
    duplicateUser,
    setDuplicateUser,
    handleDialogClose,
    isValidEmail,
    touchedEmail,
    setTouchedEmail,
  } = useAddContact();

  return (
    <Dialog
      fullWidth
      disableBackdropClick
      maxWidth={"sm"}
      open={openAddContactDialog}
      onClose={() => {
        handleDialogClose(setAddContactDialog);
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Add Contact</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ paddingTop: 32 }}>
        <Grid item xs={12} style={{ paddingBottom: 16 }}>
          <TextField
            fullWidth
            label="Full Name"
            variant="outlined"
            value={fullName}
            onChange={(e) => {
              const re = ALPHA_NUMERIC_PATTERN;
              if (e.target.value === "" || re.test(e.target.value)) {
                setFullName(e.target.value);
              }
            }}
            inputProps={{
              maxLength: 500,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 16 }}>
          <TextField
            fullWidth
            label="Email Address"
            variant="outlined"
            value={emailAddress}
            onChange={(e) => {
              setEmailAddress(e.target.value);
              setDuplicateUser(false);
            }}
            onBlur={() => {
              setTouchedEmail(true);
            }}
            required
            error={!isValidEmail && touchedEmail}
            helperText={
              !isValidEmail && touchedEmail
                ? "Please enter an valid email id"
                : ""
            }
            inputProps={{
              maxLength: 500,
            }}
          />
          {duplicateUser && (
            <Typography
              variant="body2"
              style={{ color: "red", margin: "2px 0px" }}
            >
              ** User with given email id already exists.
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 16 }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="label">Select business type</InputLabel>
            <Select
              value={businessType}
              onChange={(e) => setBusinessType(e.target.value)}
              label={"Select business type"}
            >
              {businessTypes &&
                businessTypes.length &&
                businessTypes.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: "16px 24px" }}>
        <Button
          variant="outlined"
          style={{
            padding: "6px 16px",
            marginRight: 12,
            textTransform: "capitalize",
          }}
          className={Styles.navyBlueColorForce}
          onClick={() => {
            handleDialogClose(setAddContactDialog);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={!(emailAddress || "").trim() || !isValidEmail}
          onClick={() => {
            const contact = {
              fullName: (fullName || "").trim(),
              email: (emailAddress || "").trim(),
              businessType: businessType.name,
            };
            addContactItem(contact, (flag) => {
              if (flag) {
                setDuplicateUser(true);
              } else {
                handleDialogClose(setAddContactDialog);
              }
            });
          }}
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize", padding: "6px 32px" }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddContactDialog;
