import { useState } from "react";
import {
  DELETE_SERVICE_REQUEST_DCOUMENT,
  ADD_SERVICE_REQUEST_DCOUMENTS,
} from "souqh-redux/reducers/actionConstants";
import { useActions } from "../useActions";
import { useSelector, useDispatch } from "react-redux";
import { useAuthUser } from "../useLogin";
import { useApiClient } from "../useApiClient";
import { useMessaging } from "../messaging/useMessaging";
import { cloneDeep } from "lodash";

const actionCreators = {
  addFiles: (payload) => ({
    type: ADD_SERVICE_REQUEST_DCOUMENTS,
    payload,
  }),
  deleteFile: (payload) => ({
    type: DELETE_SERVICE_REQUEST_DCOUMENT,
    payload,
  }),
};

export const useSendSerivceRequestDialog = (
  serviceProviderId,
  service,
  email,
  srcPage,
  sfServiceId,
  isExplorePageCard
) => {
  const actions = useActions(actionCreators);
  const [description, setDescription] = useState("");
  const { serviceRequestDocuments } = useSelector((state) => state.homeBuyer);
  const userInfo = useAuthUser();
  const dispatch = useDispatch();
  const { apiClient } = useApiClient();
  const { requestServiceMessageFromBackground } = useMessaging(false);
  const submitSerivceRequest = (serviceSelected, onResult) => {
    const formData = new FormData();
    const data = {
      notes: description,
      homeBuyerId: userInfo.homeBuyerId,
      serviceProviderId: serviceProviderId,
      sfServiceId: isExplorePageCard
        ? serviceSelected.id
        : srcPage === "SPManagement"
        ? sfServiceId
        : service.id,
    };
    formData.append(
      "sfServiceRequest",
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      })
    );
    serviceRequestDocuments.forEach((file) => {
      formData.append("attachments", file, file.name);
    });

    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .post(`/home-buyer/sp/service-request`, formData)
      .then((res) => {
        requestServiceMessageFromBackground(
          email,
          description,
          cloneDeep(serviceRequestDocuments)
        );
        onResult && onResult(res);
      })
      .catch((error) => {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const clearData = () => {
    setDescription("");
    actions.addFiles({});
  };

  return {
    description,
    serviceRequestDocuments,
    setDescription,
    submitSerivceRequest,
    clearData,
    actions,
    userInfo,
  };
};
