import React, { useEffect } from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import {
  Typography,
  IconButton,
  Dialog,
  Grid,
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@material-ui/core/";
import Styles from "../../../../Styles.module.css";
import UploadCancelDialog from "../../../HomeBuyer/UploadDocuments/UploadCancelDialog";
import { useAddTemplate } from "souqh-react-redux-hooks/serviceProvider/documents/useAddTemplate";
import UploadAndUseTemplate from "./UploadAndUseTemplate";
import AddContactDialog from "../AddContactDialog";
import CustomEmailMessageDialog from "../CustomEmailMessageDialog";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { MANDATORY_DETAILS_MESSAGE } from "../../serviceProviderConstants";
import { useBusinessType } from "souqh-react-redux-hooks/useBusinessType";

const styles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    padding: 0,
  },
  dialogPaper: {
    height: window.innerHeight,
    borderRadius: 0,
  },
  diaogFooter: {
    padding: "12px 36px",
  },
  dialogRoot: {
    padding: 0,
  },
  selectedIcon: {
    fontWeight: "bold",
    backgroundColor: "#FA7E61",
  },
  nonSelectedIcon: {
    backgroundColor: "white",
  },
  default: {
    marginLeft: 7,
    padding: "1px 12px 1px 12px",
    borderRadius: "4px",
    fontWeight: "bold",
  },
  selectedText: {
    color: "#FA7E61",
  },
}));

const DialogTitle = (props) => {
  const { children, classes, setOpenUploadCancelDialog, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="subtitle1">{children}</Typography>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={() => {
          setOpenUploadCancelDialog(true);
        }}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
};

export default function InviteAndUseTemplate({
  openInviteAndSendTemplate,
  setOpenInviteAndSendTemplate,
  updateIntercomStatus,
  getTabWiseCategoriesList,
  setDocumentTabName,
  templateForUse,
  setTemplateForUse,
  documentTabName,
}) {
  const classes = styles();
  const dispatch = useDispatch();

  const {
    openUploadCancelDialog,
    setOpenUploadCancelDialog,
    taggedDoucment,
    setTaggedDoucment,
    searchText,
    setSearchText,
    selectedContact,
    setSelectedContact,
    filteredContacts,
    setFilteredContacts,
    filterContacts,
    openAddContactDialog,
    setAddContactDialog,
    addContactItem,
    associactedServiceProviders,
    sharedWithSP,
    setSharedWithSP,
    getAssociactedServiceProviders,
    getContacts,
    setAssociactedServiceProviders,
    contacts,
    signOrders,
    signOrdersData,
    updateSignOrderChange,
    updateEmailTouchedState,
    updateEmailValue,
    isAllEmailsFilled,
    inviteAndSend,
    updatePrimaryAndSecondaryApplicant,
    openCustomEmailMessageDialog,
    setOpenCustomEmailMessageDialog,
    customEmailTitle,
    setCustomEmailTitle,
    customEmailMessage,
    setCustomEmailMessage,
    closeAndFinishAddTemplate,
    SPContacts,
    getSPContacts,
    handleAssocSPSelect,
    // businessTypes,
    uploadTaggedDcouments,
    primaryApplicantList,
    defaultEmailTitle,
    setDefaultEmailTitle,
    defaultEmailMessage,
    setDefaultEmailMessage,
    deleteCopyOfTemplate,
  } = useAddTemplate(
    getTabWiseCategoriesList,
    setDocumentTabName,
    documentTabName
  );

  const { businessTypes } = useBusinessType();

  useEffect(() => {
    updateIntercomStatus && updateIntercomStatus(openInviteAndSendTemplate);
    if (openInviteAndSendTemplate) {
      getContacts();
      getSPContacts();
    }
  }, [openInviteAndSendTemplate]);

  useEffect(() => {
    setFilteredContacts([...contacts]);
    filterContacts();
  }, [contacts]);

  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    setOpenInviteAndSendTemplate(false);
    closeAndFinishAddTemplate();
    // deleteCopyOfTemplate();
    setTemplateForUse(null);
  };

  useEffect(() => {
    if (templateForUse) {
      setOpenInviteAndSendTemplate(true);
      setTaggedDoucment(templateForUse);
    } else {
      setOpenInviteAndSendTemplate(false);
      setTaggedDoucment(null);
    }
  }, [templateForUse]);
  useEffect(() => {
    if (taggedDoucment) {
      uploadTaggedDcouments(true, () => {
        setOpenInviteAndSendTemplate(false);
        setTaggedDoucment(null);
      });
    }
  }, [taggedDoucment]);

  return (
    <>
      <UploadCancelDialog
        setOpenUploadCancelDialog={setOpenUploadCancelDialog}
        openUploadCancelDialog={openUploadCancelDialog}
        onClose={handleClose}
      />

      <AddContactDialog
        openAddContactDialog={openAddContactDialog}
        setAddContactDialog={setAddContactDialog}
        addContactItem={addContactItem}
        businessTypes={businessTypes || []}
      />
      <CustomEmailMessageDialog
        openCustomEmailMessageDialog={openCustomEmailMessageDialog}
        setOpenCustomEmailMessageDialog={setOpenCustomEmailMessageDialog}
        customEmailTitle={customEmailTitle}
        setCustomEmailTitle={setCustomEmailTitle}
        customEmailMessage={customEmailMessage}
        setCustomEmailMessage={setCustomEmailMessage}
        defaultEmailTitle={defaultEmailTitle}
        setDefaultEmailTitle={setDefaultEmailTitle}
        defaultEmailMessage={defaultEmailMessage}
        setDefaultEmailMessage={setDefaultEmailMessage}
      />
      <div>
        <Dialog
          fullScreen
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openInviteAndSendTemplate}
          maxWidth="lg"
          classes={{ paper: classes.dialogPaper, root: classes.dialogRoot }}
        >
          <DialogTitle
            classes={classes}
            id="customized-dialog-title"
            className={Styles.navyBlueColor}
            setOpenUploadCancelDialog={setOpenUploadCancelDialog}
          >
            Use Template -
            <span style={{ fontWeight: 400 }}>
              &nbsp;
              {taggedDoucment ? taggedDoucment.docName : ""}
            </span>
          </DialogTitle>
          <MuiDialogContent dividers className={classes.dialogContent}>
            <Grid container style={{ height: "100%" }}>
              <Grid
                item
                xs={12}
                className={Styles.primaryBackground}
                style={{ height: 60, display: "flex", alignItems: "center" }}
              >
                <List style={{ width: "100%" }}>
                  <Grid container style={{ padding: "2px 84px" }}>
                    <ListItem
                      button
                      style={{
                        cursor: "default",
                        color: "#FA7E61",
                      }}
                    >
                      <ListItemText
                        primary="Invite &#38; Send"
                        style={{ fontWeight: 600, textAlign: "center" }}
                        primaryTypographyProps={{
                          variant: "inherit",
                        }}
                      />
                    </ListItem>
                  </Grid>
                </List>
              </Grid>
              <Grid item xs={12} style={{ height: "calc(100% - 60px)" }}>
                <UploadAndUseTemplate
                  searchText={searchText}
                  setSearchText={setSearchText}
                  selectedContact={selectedContact}
                  setSelectedContact={setSelectedContact}
                  filteredContacts={filteredContacts}
                  setFilteredContacts={setFilteredContacts}
                  filterContacts={filterContacts}
                  associactedServiceProviders={associactedServiceProviders}
                  setAssociactedServiceProviders={
                    setAssociactedServiceProviders
                  }
                  updatePrimaryAndSecondaryApplicant={
                    updatePrimaryAndSecondaryApplicant
                  }
                  sharedWithSP={sharedWithSP}
                  setSharedWithSP={setSharedWithSP}
                  openAddContactDialog={openAddContactDialog}
                  setAddContactDialog={setAddContactDialog}
                  getAssociactedServiceProviders={
                    getAssociactedServiceProviders
                  }
                  getContacts={getContacts}
                  signOrders={signOrders}
                  signOrdersData={signOrdersData}
                  updateSignOrderChange={updateSignOrderChange}
                  updateEmailTouchedState={updateEmailTouchedState}
                  updateEmailValue={updateEmailValue}
                  contacts={contacts}
                  SPContacts={SPContacts}
                  handleAssocSPSelect={handleAssocSPSelect}
                  primaryApplicantList={primaryApplicantList}
                />
              </Grid>
            </Grid>
          </MuiDialogContent>
          <MuiDialogActions className={classes.diaogFooter}>
            <Button
              variant="outlined"
              style={{
                textTransform: "capitalize",
                padding: "6px 16px",
                marginRight: 12,
              }}
              className={clsx(Styles.navyBlueColorForce)}
              onClick={() => {
                setOpenUploadCancelDialog(true);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              style={{
                textTransform: "capitalize",
                padding: "6px 16px",
                marginRight: 12,
              }}
              className={clsx(Styles.navyBlueColorForce)}
              onClick={() => {
                setOpenCustomEmailMessageDialog(true);
              }}
            >
              Customize Email
            </Button>
            <Button
              style={{
                textTransform: "capitalize",
                padding: "6px 32px",
              }}
              className={Styles.primaryBtnAccent}
              onClick={() => {
                if (!isAllEmailsFilled()) {
                  dispatch({
                    type: "SET_SERVER_ERROR",
                    error: { message: MANDATORY_DETAILS_MESSAGE },
                  });
                } else {
                  inviteAndSend(() => {
                    setOpenInviteAndSendTemplate(false);
                    handleClose();
                  });
                }
              }}
            >
              Invite &#38; Send
            </Button>
          </MuiDialogActions>
        </Dialog>
      </div>
    </>
  );
}
