import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  IconButton,
  Switch,
  Checkbox,
  TextField,
  Divider,
  Chip,
  useTheme,
  Button,
  makeStyles,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import { PaperLikeGrid } from "./ServiceList";
import {
  useCustomerPayment,
  useChequeInfo,
  useWireTransferInfo,
  useETransferInfo,
} from "souqh-react-redux-hooks/serviceProvider/storefront/useCustomerPayments";
import Styles from "../../../Styles.module.css";
import AddIcon from "@material-ui/icons/Add";
import MaskedMobileInput from "../../MaskedMobileInput";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import OtherPaymentMethod from "./OtherPaymentMethod";

const ccPaymentOptions = [
  { name: "Visa", id: 1, icon: "/images/visa-credit-card.svg" },
  { name: "Master Card", id: 2, icon: "/images/mastercard.svg" },
  { name: "American Express", id: 3, icon: "/images/american-express.svg" },
  { name: "Discover", id: 4, icon: "/images/discover.svg" },
];
const paymentOptions = [
  {
    name: "Wire Transfer",
    id: 1,
    selected: false,
    formName: "wireTransferInfo",
  },
  { name: "E Transfer", id: 2, selected: false, formName: "eTransferInfo" },
  { name: "Cheque", id: 3, selected: false, formName: "chequeInfo" },
  { name: "Other", id: 4, selected: false, formName: "otherInfo" },
];
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    padding: 16,
    color: "#0e1c4e",
  },
  marginTop: {
    marginTop: 20,
  },
  marginRight: {
    marginRight: 10,
  },
  marginLeft: {
    marginLeft: 10,
  },
  cursorPointer: {
    cursor: "pointer",
  },
  marginBottom: {
    marginBottom: 10,
  },
  webPresenceBox: {
    border: "1px solid #17174C1F",
    borderRadius: 4,
    marginBottom: 4,
  },
  heading: {
    fontWeight: 600,
  },
  dataText: {
    marginRight: 10,
  },
}));
function PaymentMethod() {
  const classes = useStyles();

  const {
    creditCardInfo,
    paypalInfo,
    otherInfo,
    validations,
    paymentMethods,
    setEmail,
    actions: {
      updateCreditCardInfo,
      updatePaypalInfo,
      updateWireTransferInfo,
      updatePaymentMethods,
      updateETransferInfo,
      updateChequeInfo,
      resetInfo,
      saveInfo,
      clickEdit,
      clickDelete,
    },
  } = useCustomerPayment();
  const { chequeInfo, allowChequeAdd } = useChequeInfo();
  const { wireTransferInfo, allowWireTransferAdd } = useWireTransferInfo();
  const [newOtherPaymentMethod, setNewOtherPaymentMethod] = useState(false);
  const { primaryColor, secondaryColor } = useTheme();

  const {
    eTransferInfo,
    allowETransferAdd,
    validations: eTransferValidations,
  } = useETransferInfo();
  return (
    <Grid container>
      <PaperLikeGrid style={{ padding: 10, marginTop: 40 }}>
        <Typography variant="subtitle1" className={Styles.navyBlueColor}>
          Payment Method
        </Typography>
      </PaperLikeGrid>
      <PaperLikeGrid
        style={{ padding: 10, marginTop: 8 }}
        justifyContent="space-between"
      >
        {/* Credit/Debit card */}
        <Grid container>
          <Grid container item xs={6} alignItems="center">
            <Grid item xs={2}>
              <CreditCardIcon
                className={Styles.navyBlueColorForce}
                style={{ padding: 10 }}
              />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body1" className={Styles.navyBlueColor}>
                Credit/Debit Card
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <Typography
              variant="body1"
              className={Styles.navyBlueColor}
              style={{ marginTop: 8 }}
            >
              Make visible
            </Typography>
            <Switch
              className={"sqGraySwitch"}
              checked={creditCardInfo && creditCardInfo.visibility}
              onChange={(event) => {
                updateCreditCardInfo({
                  field: "visibility",
                  value: event.target.checked,
                });
              }}
            />
          </Grid>
          {creditCardInfo.visibility && (
            <>
              <Divider
                orientation="horizontal"
                variant="fullWidth"
                style={{ width: "100%", marginTop: 10, marginBottom: 10 }}
              />
              <Autocomplete
                multiple
                fullWidth
                options={ccPaymentOptions}
                noOptionsText={"Select Payment methods"}
                disableCloseOnSelect
                style={{ padding: "0px 12px" }}
                value={
                  creditCardInfo &&
                  creditCardInfo.config &&
                  Object.keys(creditCardInfo.config).length !== 0
                    ? creditCardInfo.config
                    : []
                }
                getOptionLabel={(option) => option.name}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      color={Styles.navyBlueColorForce}
                      checked={selected}
                    />
                    <img
                      src={option.icon}
                      style={{ width: 20, height: 20, marginRight: 8 }}
                    ></img>
                    {option.name}
                  </React.Fragment>
                )}
                onChange={(event, values) => {
                  updateCreditCardInfo({
                    field: "config",
                    value: values,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={ccPaymentOptions.name}
                  />
                )}
                getOptionSelected={(option, value) => {
                  return option.name === value.name;
                }}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      label={option.name}
                      {...getTagProps({ index })}
                      variant="outlined"
                      className={Styles.navyBlueColorForce}
                    />
                  ))
                }
                inputProps={{
                  className: Styles.navyBlueColorForce,
                }}
              />
            </>
          )}
        </Grid>
      </PaperLikeGrid>
      <PaperLikeGrid
        style={{ padding: 10, marginTop: 8 }}
        justifyContent="space-between"
      >
        {/* Paypal */}
        <Grid container>
          <Grid container item xs={6} alignItems="center">
            <Grid container item xs={2} justifyContent="center">
              <img src="/images/paypal.svg" style={{ width: 20, height: 20 }} />
            </Grid>
            <Grid item xs={10}>
              <Typography className={Styles.navyBlueColor}>Paypal</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <Typography
              className={Styles.navyBlueColor}
              style={{ marginTop: 8 }}
            >
              Make visible
            </Typography>
            <Switch
              className={"sqGraySwitch"}
              checked={paypalInfo && paypalInfo.visibility}
              onChange={(event) => {
                updatePaypalInfo({
                  field: "visibility",
                  value: event.target.checked,
                });
              }}
            />
          </Grid>
          {paypalInfo.visibility && (
            <>
              <Divider
                orientation="horizontal"
                variant="fullWidth"
                style={{ width: "100%", marginTop: 10, marginBottom: 10 }}
              />
              <TextField
                error={!!validations.email}
                helperText={validations.email}
                fullWidth
                variant="outlined"
                label="Email"
                value={paypalInfo && paypalInfo.config.email}
                onChange={(event) => {
                  setEmail(event.target.value);
                  updatePaypalInfo({
                    field: "config",
                    value: { email: event.target.value },
                  });
                }}
                style={{ margin: "10px 12px" }}
              />
            </>
          )}
        </Grid>
      </PaperLikeGrid>
      {wireTransferInfo && wireTransferInfo.selected && (
        <PaperLikeGrid
          style={{ padding: "10px 22px", marginTop: 8 }}
          justifyContent="space-between"
        >
          {/* Wire transfer */}
          <Grid container>
            <Grid item xs={6}>
              <Typography className={Styles.navyBlueColor}>
                <IconButton>
                  <SyncAltIcon />
                </IconButton>
                Wire Transfer
              </Typography>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              {wireTransferInfo.config && wireTransferInfo.config.editMode && (
                <IconButton
                  color="default"
                  style={{ color: primaryColor }}
                  component="span"
                  onClick={() => {
                    clickEdit(paymentOptions[0]);
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
              <IconButton
                color="default"
                style={{ color: primaryColor }}
                component="span"
                onClick={() => {
                  clickDelete(paymentOptions[0]);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
            <Divider
              orientation="horizontal"
              variant="fullWidth"
              style={{ width: "100%", marginTop: 10, marginBottom: 10 }}
            />
            {wireTransferInfo.config && wireTransferInfo.config.editMode ? (
              <>
                <Grid
                  item
                  container
                  className={classes.marginLeft}
                  alignItems="center"
                >
                  <Typography variant="subtitle1" className={classes.dataText}>
                    Payable to:
                  </Typography>
                  <Typography variant="body1" className={classes.dataText}>
                    {wireTransferInfo.config.payableTo}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  className={classes.marginLeft}
                  alignItems="center"
                >
                  <Typography variant="subtitle1" className={classes.dataText}>
                    Firm Address:
                  </Typography>
                  <Typography variant="body1" className={classes.dataText}>
                    {wireTransferInfo.config.firmAddress}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  className={classes.marginLeft}
                  alignItems="center"
                >
                  <Typography variant="subtitle1" className={classes.dataText}>
                    Institution:
                  </Typography>
                  <Typography variant="body1" className={classes.dataText}>
                    {wireTransferInfo.config.institution}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  className={classes.marginLeft}
                  alignItems="center"
                >
                  <Typography variant="subtitle1" className={classes.dataText}>
                    Institution Number:
                  </Typography>
                  <Typography variant="body1" className={classes.dataText}>
                    {wireTransferInfo.config.institutionNumber}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  className={classes.marginLeft}
                  alignItems="center"
                >
                  <Typography variant="subtitle1" className={classes.dataText}>
                    Branch Number:
                  </Typography>
                  <Typography variant="body1" className={classes.dataText}>
                    {wireTransferInfo.config.branchNumber}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  className={classes.marginLeft}
                  alignItems="center"
                >
                  <Typography variant="subtitle1" className={classes.dataText}>
                    Swift Code:
                  </Typography>
                  <Typography variant="body1" className={classes.dataText}>
                    {wireTransferInfo.config.swiftCode}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  className={classes.marginLeft}
                  alignItems="center"
                >
                  <Typography variant="subtitle1" className={classes.dataText}>
                    Canadian Clearing Code:
                  </Typography>
                  <Typography variant="body1" className={classes.dataText}>
                    {wireTransferInfo.config.canadianClearingCode}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  className={classes.marginLeft}
                  alignItems="center"
                >
                  <Typography variant="subtitle1" className={classes.dataText}>
                    Account Number:
                  </Typography>
                  <Typography variant="body1" className={classes.dataText}>
                    {wireTransferInfo.config.accountNumber}
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Payable to"
                  value={wireTransferInfo && wireTransferInfo.config.payableTo}
                  required
                  onChange={(event) => {
                    updateWireTransferInfo({
                      field: "config",
                      value: { payableTo: event.target.value },
                    });
                  }}
                  inputProps={{
                    maxLength: 256,
                  }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Firm Address"
                  required
                  value={
                    wireTransferInfo && wireTransferInfo.config.firmAddress
                  }
                  onChange={(event) => {
                    updateWireTransferInfo({
                      field: "config",
                      value: { firmAddress: event.target.value },
                    });
                  }}
                  inputProps={{
                    maxLength: 500,
                  }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Institution"
                  value={
                    wireTransferInfo &&
                    wireTransferInfo.config &&
                    wireTransferInfo.config.institution
                  }
                  required
                  onChange={(event) => {
                    updateWireTransferInfo({
                      field: "config",
                      value: { institution: event.target.value },
                    });
                  }}
                />
                <Grid container justifyContent="space-between" spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Institution Number"
                      required
                      value={
                        wireTransferInfo &&
                        wireTransferInfo.config.institutionNumber
                      }
                      onChange={(event) => {
                        updateWireTransferInfo({
                          field: "config",
                          value: { institutionNumber: event.target.value },
                        });
                      }}
                      multiline
                      inputProps={{
                        maxLength: 20,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Branch Number"
                      required
                      value={
                        wireTransferInfo && wireTransferInfo.config.branchNumber
                      }
                      onChange={(event) => {
                        updateWireTransferInfo({
                          field: "config",
                          value: { branchNumber: event.target.value },
                        });
                      }}
                      multiline
                      inputProps={{
                        maxLength: 20,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Swift Code"
                      required
                      value={
                        wireTransferInfo && wireTransferInfo.config.swiftCode
                      }
                      onChange={(event) => {
                        updateWireTransferInfo({
                          field: "config",
                          value: { swiftCode: event.target.value },
                        });
                      }}
                      multiline
                      inputProps={{
                        maxLength: 20,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container justifyContent="space-between" spacing={2}>
                  <Grid item md={5} xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Canadian Clearing Code"
                      required
                      value={
                        wireTransferInfo &&
                        wireTransferInfo.config.canadianClearingCode
                      }
                      onChange={(event) => {
                        updateWireTransferInfo({
                          field: "config",
                          value: { canadianClearingCode: event.target.value },
                        });
                      }}
                      multiline
                      inputProps={{
                        maxLength: 20,
                      }}
                    />
                  </Grid>
                  <Grid item md={7} xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Account Number"
                      required
                      value={
                        wireTransferInfo &&
                        wireTransferInfo.config.accountNumber
                      }
                      onChange={(event) => {
                        updateWireTransferInfo({
                          field: "config",
                          value: { accountNumber: event.target.value },
                        });
                      }}
                      multiline
                      inputProps={{
                        maxLength: 20,
                      }}
                    />
                  </Grid>
                  <Grid item container xs={12} justifyContent="flex-end">
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ textTransform: "capitalize", marginRight: 20 }}
                      onClick={() => {
                        resetInfo(paymentOptions[0]);
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      disabled={allowWireTransferAdd}
                      variant="contained"
                      className={Styles.primaryBtnAccent}
                      onClick={() => {
                        saveInfo(paymentOptions[0]);
                      }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </PaperLikeGrid>
      )}

      {eTransferInfo && eTransferInfo.selected && (
        <PaperLikeGrid
          style={{ padding: "10px 22px", marginTop: 8 }}
          justifyContent="space-between"
        >
          {/* E transfer */}
          <Grid container>
            <Grid item xs={6}>
              <Typography className={Styles.navyBlueColor}>
                <IconButton>
                  <SyncAltIcon />
                </IconButton>
                E-Transfer
              </Typography>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              {eTransferInfo.config && eTransferInfo.config.editMode && (
                <IconButton
                  color="default"
                  style={{ color: primaryColor }}
                  component="span"
                  onClick={() => {
                    clickEdit(paymentOptions[1]);
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
              <IconButton
                color="default"
                style={{ color: primaryColor }}
                component="span"
                onClick={() => {
                  clickDelete(paymentOptions[1]);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
            <Divider
              orientation="horizontal"
              variant="fullWidth"
              style={{ width: "100%", marginTop: 10, marginBottom: 10 }}
            />
            {eTransferInfo.config && eTransferInfo.config.editMode ? (
              <>
                <Grid
                  item
                  container
                  className={classes.marginLeft}
                  alignItems="center"
                >
                  <Typography variant="subtitle1" className={classes.dataText}>
                    Email:
                  </Typography>
                  <Typography variant="body1" className={classes.dataText}>
                    {eTransferInfo.config.email}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  className={classes.marginLeft}
                  alignItems="center"
                >
                  <Typography variant="subtitle1" className={classes.dataText}>
                    Preferred Password:
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.dataText}
                    type="password"
                  >
                    {eTransferInfo.config.preferredPassword}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  className={classes.marginLeft}
                  alignItems="center"
                >
                  <Typography variant="subtitle1" className={classes.dataText}>
                    Max Amount:
                  </Typography>
                  <Typography variant="body1" className={classes.dataText}>
                    {eTransferInfo.config.maxAmount}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  className={classes.marginLeft}
                  alignItems="center"
                >
                  <Typography variant="subtitle1" className={classes.dataText}>
                    Mobile Number:
                  </Typography>
                  <Typography variant="body1" className={classes.dataText}>
                    {eTransferInfo.config.mobileNumber}
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                <TextField
                  error={!!eTransferValidations.email}
                  helperText={eTransferValidations.email}
                  fullWidth
                  variant="outlined"
                  required
                  label="Email"
                  autoComplete="off"
                  value={eTransferInfo && eTransferInfo.config.email}
                  onChange={(event) => {
                    updateETransferInfo({ email: event.target.value });
                  }}
                  inputProps={{
                    maxLength: 256,
                  }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  required
                  label="Preferred Password"
                  type="password"
                  autoComplete="new-password"
                  value={
                    eTransferInfo && eTransferInfo.config.preferredPassword
                  }
                  onChange={(event) => {
                    updateETransferInfo({
                      preferredPassword: event.target.value,
                    });
                  }}
                  inputProps={{
                    maxLength: 25,
                    minLength: 8,
                  }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  required
                  label="Max Amount"
                  autoComplete="new-password"
                  value={eTransferInfo && eTransferInfo.config.maxAmount}
                  onChange={(event) => {
                    updateETransferInfo({ maxAmount: event.target.value });
                  }}
                  type="number"
                  inputProps={{
                    maxLength: 15,
                  }}
                />
                <MaskedMobileInput
                  id="mobileNumber"
                  label="Mobile Number"
                  name="mobileNumber"
                  variant="outlined"
                  required
                  type="tel"
                  fullWidth
                  autoComplete="new-password"
                  value={eTransferInfo && eTransferInfo.config.mobileNumber}
                  onChange={(event) => {
                    updateETransferInfo({ mobileNumber: event.target.value });
                  }}
                ></MaskedMobileInput>
                <Grid item container xs={12} justifyContent="flex-end">
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ textTransform: "capitalize", marginRight: 20 }}
                    onClick={() => {
                      resetInfo(paymentOptions[1]);
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    disabled={!allowETransferAdd}
                    variant="contained"
                    className={Styles.primaryBtnAccent}
                    onClick={() => {
                      saveInfo(paymentOptions[1]);
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </PaperLikeGrid>
      )}
      {chequeInfo && chequeInfo.selected && (
        <PaperLikeGrid
          style={{ padding: "10px 22px", marginTop: 8 }}
          justifyContent="space-between"
        >
          {/* Cheque */}
          <Grid container>
            <Grid item xs={6}>
              <Typography className={Styles.navyBlueColor}>
                <IconButton>
                  <SyncAltIcon />
                </IconButton>
                Cheques
              </Typography>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              {chequeInfo.config && chequeInfo.config.editMode && (
                <IconButton
                  color="default"
                  style={{ color: primaryColor }}
                  component="span"
                  onClick={() => {
                    clickEdit(paymentOptions[2]);
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
              <IconButton
                color="default"
                style={{ color: primaryColor }}
                component="span"
                onClick={() => {
                  clickDelete(paymentOptions[2]);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
            <Divider
              orientation="horizontal"
              variant="fullWidth"
              style={{ width: "100%", marginTop: 10, marginBottom: 10 }}
            />
            {chequeInfo.config.editMode ? (
              <Grid item container direction="column">
                <Grid item container alignItems="center">
                  <Typography variant="subtitle1" style={{ marginRight: 10 }}>
                    Name:
                  </Typography>
                  <Typography variant="body1">
                    {chequeInfo && chequeInfo.config && chequeInfo.config.name}
                  </Typography>
                </Grid>
                <Grid item container alignItems="center">
                  <Typography variant="subtitle1" style={{ marginRight: 15 }}>
                    Address:
                  </Typography>
                  <Typography variant="body1">
                    {chequeInfo &&
                      chequeInfo.config &&
                      chequeInfo.config.address}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <>
                <TextField
                  fullWidth
                  variant="outlined"
                  required
                  label="Name"
                  autoComplete="false"
                  value={
                    chequeInfo && chequeInfo.config && chequeInfo.config.name
                  }
                  onChange={(event) => {
                    updateChequeInfo({
                      name: event.target.value,
                    });
                  }}
                  inputProps={{
                    maxLength: 256,
                  }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  required
                  label="Address"
                  multiline
                  autoComplete="new-password"
                  value={
                    chequeInfo && chequeInfo.config && chequeInfo.config.address
                  }
                  autoComplete="false"
                  onChange={(event) => {
                    updateChequeInfo({
                      address: event.target.value,
                    });
                  }}
                  inputProps={{
                    maxLength: 500,
                  }}
                />
                <Grid item container xs={12} justifyContent="flex-end">
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ textTransform: "capitalize", marginRight: 20 }}
                    onClick={() => {
                      resetInfo(paymentOptions[2]);
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    disabled={allowChequeAdd}
                    variant="contained"
                    className={Styles.primaryBtnAccent}
                    onClick={() => {
                      saveInfo(paymentOptions[2]);
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </PaperLikeGrid>
      )}

      {otherInfo && otherInfo.selected && (
        <PaperLikeGrid
          style={{ padding: "10px 22px", marginTop: 8 }}
          justifyContent="space-between"
        >
          {/* Other */}
          <Grid container>
            <Grid item xs={6}>
              <Typography className={Styles.navyBlueColor}>
                <IconButton>
                  <SyncAltIcon />
                </IconButton>
                Other
              </Typography>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <Button
                color="primary"
                style={{ textTransform: "capitalize", marginRight: 20 }}
                onClick={() => {
                  setNewOtherPaymentMethod(true);
                }}
                startIcon={<AddIcon />}
              >
                Add New
              </Button>
              <IconButton
                color="default"
                style={{ color: primaryColor }}
                component="span"
                onClick={() => {
                  clickDelete(paymentOptions[3]);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
            <Divider
              orientation="horizontal"
              variant="fullWidth"
              style={{ width: "100%", marginTop: 10, marginBottom: 10 }}
            />
            <OtherPaymentMethod
              newOtherPaymentMethod={newOtherPaymentMethod}
              setNewOtherPaymentMethod={setNewOtherPaymentMethod}
            />
          </Grid>
        </PaperLikeGrid>
      )}
      <PaperLikeGrid
        style={{ padding: "10px 22px", marginTop: 8 }}
        justifyContent="space-between"
      >
        <Autocomplete
          multiple
          fullWidth
          filterSelectedOptions
          options={paymentOptions}
          noOptionsText={"Add Payment methods"}
          value={paymentMethods || []}
          getOptionLabel={(option) => option.name}
          onChange={(event, values) => {
            updatePaymentMethods(values);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Add Payment Methods"
            />
          )}
          getOptionSelected={(option, value) => {
            return option.name === value.name;
          }}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={option.name}
                {...getTagProps({ index })}
                variant="outlined"
                className={Styles.navyBlueColorForce}
              />
            ))
          }
          inputProps={{
            className: Styles.navyBlueColorForce,
          }}
        />
      </PaperLikeGrid>
    </Grid>
  );
}

export default PaymentMethod;
