import { SET_JOURNEY_STATUS } from "../../souqh-redux/reducers/actionConstants";
import { useSelector, useDispatch } from "react-redux";
import { useApiClient } from "../useApiClient";
import { useState, useEffect } from "react";

export const useHomeBuyingJourney = (userId) => {
  const { apiClient } = useApiClient();

  const dispatch = useDispatch();

  const { journeyStatus } = useSelector((state) => state.homeBuyingJourney);
  const [disableHBjouneryCards, setDisableHBjouneryCards] = useState(false);

  const getJourneyStatus = () => {
    if (userId) {
      dispatch({ type: "SET_LOADING", value: true });
      return apiClient
        .get(`/home-buyer/journey?userId=${userId}`)
        .then(function (response) {
          if (response.data.result) {
            dispatch({
              type: SET_JOURNEY_STATUS,
              journeyStatus: response.data.result,
            });
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          // always executed
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const updateJourneyStatus = (status, page) => {
    let url =
      page === "clientManagement"
        ? "service-provider/client/journey"
        : "/home-buyer/journey";
    if (userId) {
      setDisableHBjouneryCards(true);
      dispatch({ type: "SET_LOADING", value: true });
      return apiClient
        .post(`${url}?status=${status}&userId=${userId}`)
        .then(function (response) {
          if (response.data.status === 200) {
            dispatch({
              type: SET_JOURNEY_STATUS,
              journeyStatus: status,
            });
          }
        })
        .catch(function (error) {
          setDisableHBjouneryCards(false);
          console.error(error);
        })
        .then(function () {
          setDisableHBjouneryCards(false);
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  return {
    getJourneyStatus,
    journeyStatus,
    updateJourneyStatus,
    disableHBjouneryCards,
  };
};
