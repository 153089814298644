import { Button, Grid } from "@material-ui/core";
import React from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";

export default function NewCampaignDialogFooter({
  activeSection,
  setActiveSection,
  enableStep1,
  enableStep2,
  createMarketingCampaign,
  setOpenNewCampaignDialog,
  campaignId = "",
  updateMarketingCampaign,
  getMarketingCampaignInvoiceData,
  handleClose,
}) {
  return (
    <Grid
      container
      item
      xs={12}
      style={{
        paddingRight: 0,
        justifyContent: isMobileDevice() ? "space-between" : "flex-end",
        zIndex: 2100,
        backgroundColor: (isMobileDevice() || isTabletDevice()) && "white",
        position: (isMobileDevice() || isTabletDevice()) && "sticky",
        bottom: (isMobileDevice() || isTabletDevice()) && 0,
        padding: (isMobileDevice() || isTabletDevice()) && 16,
      }}
    >
      {activeSection !== 1 ? (
        <Button
          startIcon={!isMobileDevice() && <ArrowBackIosIcon />}
          style={{
            textTransform: "capitalize",
          }}
          className={Styles.navyBlueColorForce}
          onClick={() => setActiveSection(activeSection - 1)}
          variant={isMobileDevice() ? "outlined" : "text"}
        >
          Go Back
        </Button>
      ) : (
        <div />
      )}
      {activeSection !== 3 ? (
        <Button
          disabled={
            (activeSection === 1 && enableStep1) ||
            (activeSection === 2 && enableStep2)
          }
          style={{
            textTransform: "capitalize",
            borderRadius: 4,
            padding: "6px 32px",
          }}
          className={clsx(Styles.navyBlueColorForce, Styles.primaryBtnAccent)}
          onClick={() => {
            setActiveSection(activeSection + 1);
            if (activeSection === 2) {
              getMarketingCampaignInvoiceData();
            }
          }}
        >
          Next
        </Button>
      ) : (
        <Button
          style={{
            textTransform: "capitalize",
            padding: "6px 32px",
          }}
          className={clsx(Styles.navyBlueColorForce, Styles.primaryBtnAccent)}
          onClick={() => {
            campaignId
              ? updateMarketingCampaign((res) => {
                  setOpenNewCampaignDialog({ open: false });
                  handleClose();
                })
              : createMarketingCampaign((res) => {
                  setOpenNewCampaignDialog({ open: false });
                  handleClose();
                });
          }}
        >
          {campaignId ? "Edit Campaign" : "Create Campaign"}
        </Button>
      )}
    </Grid>
  );
}
