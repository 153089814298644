import { useDispatch } from "react-redux";
import { processDownloadFileResponse } from "../web/src/utils/AppUtils";
import { useApiClient } from "./useApiClient";
import { useAuthUser } from "./useLogin";

export const useDownloadFile = (selectedDataForDownload) => {
  const dispatch = useDispatch();
  const userInfo = useAuthUser();
  const { apiClient } = useApiClient();

  const multipleDownloadData = {
    downloadDocuments: selectedDataForDownload,
    userId: userInfo && userInfo.userId,
  };
  const downLoadDocument = (categoryId, documentRecord) => {
    if (documentRecord) {
      dispatch({ type: "SET_LOADING", value: true });
      if (documentRecord.signNowDocumentId) {
        downloadSignedDocument(documentRecord.signNowDocumentId);
      } else {
        downloadRefDocument(categoryId, documentRecord.fileName);
      }
    }
  };

  const downloadRefDocument = (categoryId, fileName, docUserId) => {
    const downloadDocUserId = docUserId ? docUserId : userInfo.userId;
    const url = `/documents/download?categoryId=${categoryId}&documentName=${fileName}&userId=${downloadDocUserId}`;

    apiClient
      .get(url, { responseType: "blob" })
      .then((response) => {
        processDownloadFileResponse(response);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const downloadSignedDocument = (documentId) => {
    const url = `signnow/download/document/${documentId}/${userInfo.userId}/false`;
    apiClient
      .get(url, { responseType: "blob" })
      .then((response) => {
        processDownloadFileResponse(response);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const downloadMultipleDocument = () => {
    let signedFlag = multipleDownloadData.downloadDocuments.some(
      (item) => item.isSignedDocument
    );
    let nonSignedFlag = multipleDownloadData.downloadDocuments.some(
      (item) => !item.isSignedDocument
    );
    if (signedFlag && nonSignedFlag) {
      dispatch({
        type: "SET_SERVER_ERROR",
        error: {
          message:
            "You can not download Reference documents and eSign documents together",
        },
      });
      return;
    }
    if (
      multipleDownloadData.downloadDocuments.every(
        (item) => item.isSignedDocument
      )
    ) {
      dispatch({
        type: "SET_SERVER_ERROR",
        error: {
          message: "You can not download multiplle eSign documents together",
        },
      });
      return;
    }
    dispatch({ type: "SET_LOADING", value: true });

    return apiClient
      .post("/documents/download/multiple", multipleDownloadData, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        //download file
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/octet-stream" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Souqh-documents.zip"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return { downLoadDocument, downloadMultipleDocument, downloadRefDocument };
};
