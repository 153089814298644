import { Grid } from "@material-ui/core";
import React from "react";
import Documentiframe from "./Documentiframe";

export default function TagDocument({ selectedTaggedDoucment }) {
  return (
    <Grid style={{ padding: "0px", width: "99%", height: "99%" }}>
      {selectedTaggedDoucment ? (
        <Documentiframe
          documentTagUrl={selectedTaggedDoucment.documentTagUrl}
        />
      ) : null}
    </Grid>
  );
}
