import { useState, useEffect } from "react";

export const useHBSetupDrawer = () => {
  const [activeSection, setActiveSection] = useState("personalOverview");
  const [selectedItem, setSelectedItem] = useState(1);
  const [selectedSubItem, setSelectedSubItem] = useState(1);

  useEffect(() => {
    setSelectedSubItem(1);
  }, [selectedItem]);
  return {
    activeSection,
    selectedItem,
    setActiveSection,
    setSelectedItem,
    selectedSubItem,
    setSelectedSubItem,
  };
};
