import React, { useEffect } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { isMobileDevice, parseFileName } from "../../../utils/AppUtils";
import MeetingLinkInfo from "./MeetingLinkInfo";
import {
  MEETING_CREATE_MODE,
  MEETING_EDIT_MODE,
  MEETING_READ_MODE,
  SQ_TIME_SLOTS,
} from "../../ServiceProvider/serviceProviderConstants";
import ConfirmCancelMeetingDialog from "./ConfirmCancelMeetingDialog";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import CancelIcon from "@material-ui/icons/Cancel";
import GetAppIcon from "@material-ui/icons/GetApp";

const styles = makeStyles((theme) => ({
  root: {
    // "& .MuiOutlinedInput-adornedStart": {
    //    paddingLeft: 0,
    // },
    // "& .MuiAutocomplete-root .MuiOutlinedInput-adornedStart": {
    //   paddingLeft: 12,
    // },
    // "& #date-picker-inline": {
    //    paddingLeft: 0,
    // },
    "& .start-time-picker .MuiOutlinedInput-notchedOutline": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderRight: "transparent",
    },
    "& .end-time-picker .MuiOutlinedInput-notchedOutline": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    "& .sqreadOnly .MuiFormControl-root.MuiTextField-root": {
      backgroundColor: "#f6f6f8",
    },
    "& .MuiAutocomplete-root .MuiInputBase-root.Mui-disabled": {
      backgroundColor: "#f6f6f8",
    },
    "& .MuiChip-root.Mui-disabled": {
      opacity: 0.7,
    },
  },
  dialogPaper: {
    height: isMobileDevice() ? 670 : window.innerHeight,
    marginBottom: isMobileDevice() && 90,
    minWidth: isMobileDevice() && "93%",
  },
  actionIcon: {
    fill: "#0e1c4e",
    cursor: "pointer",
    margin: "0px 8px",
    fontSize: 24,
  },
  readOnly: {},
  writeMode: {},
}));

export default function CreateMeetingDialog({
  openCreateMeetingDialog,
  meetingMode,
  setMeetingMode,
  openConfirmCanelMeetingDialog,
  setOpenConfirmCanelMeetingDialog,
  cancelMeeting,
  title,
  setTitle,
  meetingDate,
  setMeetingDate,
  meetingLink,
  meetingPhoneNumber,
  meetingLocation,
  setMeetingLocation,
  meetingAttachments,
  description,
  setDescription,
  uploadRef,
  textAreaRef,
  contactInputRef,
  meetingStartTime,
  setMeetingStartTime,
  meetingEndTime,
  setMeetingEndTime,
  handleDialogClose,
  processAttachment,
  removeAttachment,
  copyToClipboard,
  addCustomItem,
  selectedContacts,
  customContact,
  setCustomContact,
  allContacts,
  removeSelectedContact,
  addItemOnSelect,
  invalidTimes,
  removeMeetingLink,
  addMeetingLink,
  setAddMeetingLink,
  saveMeeting,
  validateTimeSlot,
  isFullDayEvent,
  setIsFullDayEvent,
  eventUserId,
  userInfo,
  hasFilledMandatoryData,
  updateMeetingStatus,
  downlodAttachment,
  filteredContacts,
  downloadCalendarAttachment,
}) {
  const classes = styles();

  const isReadMode = meetingMode === MEETING_READ_MODE ? true : false;
  const isCreateMode = meetingMode === MEETING_CREATE_MODE ? true : false;
  const isEditMode = meetingMode === MEETING_EDIT_MODE ? true : false;

  useEffect(() => {
    if (isFullDayEvent) {
      setMeetingStartTime("12:00 AM");
      setMeetingEndTime("11:30 PM");
    }
  }, [isFullDayEvent]);

  useEffect(() => {
    if (meetingStartTime && meetingEndTime) {
      validateTimeSlot();
    }
  }, [meetingStartTime, meetingEndTime]);

  let dialogTitle = "Create Meeting";
  if (isReadMode) {
    dialogTitle = "Meeting Details";
  } else if (isEditMode) {
    dialogTitle = "Modify Meeting";
  }
  if (!userInfo) {
    return null;
  }

  const isOwner = eventUserId === userInfo.userId ? true : false;
  let isAttendee = selectedContacts.find((o) => o.email === userInfo.email);

  return (
    <>
      <ConfirmCancelMeetingDialog
        openConfirmCanelMeetingDialog={openConfirmCanelMeetingDialog}
        setOpenConfirmCanelMeetingDialog={setOpenConfirmCanelMeetingDialog}
        cancelMeeting={cancelMeeting}
        onClose={handleDialogClose}
      />
      <Dialog
        fullWidth
        maxWidth={"sm"}
        open={openCreateMeetingDialog}
        onClose={handleDialogClose}
        classes={{
          paper: classes.dialogPaper,
          root: classes.root,
        }}
      >
        <DialogTitle
          className={Styles.navyBlueColor}
          style={{ padding: isMobileDevice() && "0px 24px" }}
        >
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={11}>
              <div
                style={{ width: "95%", fontSize: 16 }}
                className={clsx(Styles.navyBlueColor)}
              >
                {dialogTitle}
              </div>
            </Grid>
            <Grid item container xs={1} justifyContent="flex-end">
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                style={{
                  position: "relative",
                  top: !isMobileDevice() && -10,
                  right: -16,
                }}
                onClick={() => {
                  handleDialogClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent
          style={{ paddingTop: 16 }}
          className={isReadMode ? "sqreadOnly" : "sqwriteMode"}
        >
          <Grid item xs={12} style={{ marginBottom: 12 }}>
            <TextField
              disabled={isReadMode}
              fullWidth
              placeholder="Title"
              variant="outlined"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              inputProps={{
                maxLength: 100,
              }}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            style={{ alignItems: "center", marginBottom: 4 }}
          >
            <Checkbox
              disabled={isReadMode}
              checked={isFullDayEvent}
              onChange={(event) => {
                setIsFullDayEvent(event.target.checked);
                if (!event.target.checked) {
                  setMeetingStartTime("");
                  setMeetingEndTime("");
                }
              }}
              color={Styles.navyBlueColorForce}
            />
            <Typography
              variant="body1"
              className={Styles.navyBlueColor}
              style={{
                opacity: isReadMode ? 0.6 : 1,
              }}
            >
              Full Day Event
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            style={{ paddingBottom: isMobileDevice() ? 12 : 32 }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={12} md={5}>
                <Grid item xs={12} md={10}>
                  <KeyboardDatePicker
                    disabled={isReadMode}
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    id="date-picker-inline"
                    placeholder="Meeting Date"
                    minDate={moment()}
                    minDateMessage={
                      isReadMode ? "" : "Date should not be before today"
                    }
                    value={meetingDate}
                    autoOk={true}
                    keyboardIcon={
                      <DateRangeIcon
                        style={{
                          fontSize: 20,
                          fill: "#1B1E4C",
                          opacity: isReadMode ? 0.6 : 1,
                        }}
                      />
                    }
                    onChange={(event) => {
                      setMeetingDate(event);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                md={7}
                style={{ marginTop: isMobileDevice() && 16 }}
              >
                <Grid item container xs={12}>
                  <Grid item xs={6}>
                    <Grid item xs={12} className="start-time-picker">
                      <Autocomplete
                        disabled={isReadMode || isFullDayEvent}
                        fullWidth
                        closeIcon=""
                        options={SQ_TIME_SLOTS}
                        value={meetingStartTime}
                        popupIcon={
                          <AccessTimeIcon
                            style={{
                              fontSize: 20,
                              fill: "#1B1E4C",
                              opacity: isReadMode ? 0.6 : 1,
                            }}
                          />
                        }
                        onChange={(e, value) => {
                          setMeetingStartTime(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="From"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Grid item xs={12} className="end-time-picker">
                      <Autocomplete
                        disabled={isReadMode || isFullDayEvent}
                        fullWidth
                        closeIcon=""
                        popupIcon={
                          <AccessTimeIcon
                            style={{
                              fontSize: 20,
                              fill: "#1B1E4C",
                              opacity: isReadMode ? 0.6 : 1,
                            }}
                          />
                        }
                        options={SQ_TIME_SLOTS}
                        value={meetingEndTime}
                        onChange={(e, value) => {
                          setMeetingEndTime(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="To"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {invalidTimes && !isReadMode ? (
                  <Typography
                    variant="caption"
                    style={{
                      color: "#f44336",
                    }}
                  >
                    From time should be lesser than To time
                  </Typography>
                ) : null}
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Divider style={{ marginBottom: 12 }} />
          <Grid item xs={12} style={{ position: "relative", marginBottom: 12 }}>
            <Grid>
              <Autocomplete
                disabled={isReadMode}
                filterSelectedOptions
                fullWidth
                style={{ display: "inline-flex" }}
                variant="outlined"
                noOptionsText={""}
                options={filteredContacts || []}
                getOptionLabel={(option) => option.email}
                onChange={(event, value) => {
                  addItemOnSelect(value);
                }}
                renderOption={(option) => (
                  <React.Fragment>
                    <div>
                      <div style={{ fontSize: 16 }}>
                        {option.fullName || ""}
                      </div>
                      <div style={{ fontSize: 14 }}> {option.email}</div>
                    </div>
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    {...params}
                    value={customContact}
                    inputRef={contactInputRef}
                    label={"Search and add recipients"}
                    onChange={(event) => {
                      setCustomContact(event.target.value);
                    }}
                    onKeyPress={(event) =>
                      addCustomItem(event, contactInputRef)
                    }
                  />
                )}
                inputProps={{
                  className: Styles.navyBlueColorForce,
                }}
              />
            </Grid>
            {selectedContacts.length < 1 && !isReadMode ? (
              <Grid item xs={12}>
                <Typography
                  variant="caption"
                  style={{
                    color: "#f44336",
                  }}
                >
                  Please select atleast one attendee
                </Typography>
              </Grid>
            ) : null}
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 8 }}>
            {selectedContacts &&
              selectedContacts.map((item, index) => {
                const invitationAccepted =
                  item.inviteStatus === "true" ? true : false;
                const invitationRejected =
                  item.inviteStatus === "false" ? true : false;
                return (
                  <Chip
                    disabled={isReadMode || (!isOwner && !isCreateMode)}
                    key={index}
                    label={item.email}
                    avatar={<Avatar />}
                    variant="outlined"
                    deleteIcon={
                      isCreateMode || (isEditMode && !isAttendee) ? (
                        <CancelIcon />
                      ) : invitationAccepted ? (
                        <DoneIcon style={{ fill: "#188038" }} />
                      ) : invitationRejected ? (
                        <CloseIcon style={{ fill: "rgb(252, 9, 9)" }} />
                      ) : (
                        <div />
                      )
                    }
                    onDelete={() => {
                      // if (!isCreateMode || (isEditMode && isOwner)) {
                      //   return;
                      // }
                      if (isAttendee) {
                        return;
                      }
                      removeSelectedContact(item);
                    }}
                    style={{ margin: "0px 4px 6px 0px" }}
                    className={Styles.navyBlueColorForce}
                  />
                );
              })}
          </Grid>
          <Divider style={{ marginBottom: 32 }} />
          {isReadMode ? null : (
            <Grid container item xs={12} style={{ marginBottom: 10 }}>
              <Grid item xs={10} md={5}>
                <Typography className={Styles.navyBlueColorForce}>
                  Add video conferencing
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                md={7}
                style={{ marginLeft: !isMobileDevice() && -20 }}
              >
                <Switch
                  size="small"
                  className={"sqGraySwitch"}
                  checked={addMeetingLink}
                  onChange={(event) => {
                    setAddMeetingLink(event.target.checked);
                  }}
                />
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} style={{ paddingBottom: 20 }}>
            <TextField
              disabled={isReadMode}
              fullWidth
              placeholder="Add location"
              variant="outlined"
              value={meetingLocation}
              onChange={(e) => {
                setMeetingLocation(e.target.value);
              }}
              inputProps={{
                maxLength: 100,
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: !isMobileDevice() && 16 }}>
            <TextField
              disabled={isReadMode}
              fullWidth
              placeholder="Add meeting description"
              variant="outlined"
              multiline={true}
              rows={4}
              value={description}
              className="multline-txt"
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              inputProps={{
                maxLength: 500,
              }}
            />
            {isCreateMode && (
              <Typography
                variant="body1"
                className={Styles.navyBlueColor}
                style={{ fontSize: 12, textAlign: "right", marginTop: 4 }}
              >
                {(description || "").trim().length} / 500
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} style={{ marginBottom: !isMobileDevice() && 4 }}>
            {/*TO DO:  will remove once design is finalized */}
            {/* {meetingLink ? (
              <MeetingLinkInfo
                meetingLink={meetingLink}
                meetingPhoneNumber={meetingPhoneNumber}
                copyToClipboard={copyToClipboard}
                removeMeetingLink={removeMeetingLink}
                isReadMode={isReadMode}
              />
            ) : null} */}
            {isReadMode ? null : (
              <Button
                disabled={isReadMode}
                startIcon={<AttachFileIcon />}
                style={{
                  color: "#f37c61",
                  textTransform: "capitalize",
                  textDecoration: "underline",
                  marginRight: 16,
                }}
                onClick={(e) => {
                  if (uploadRef && uploadRef.current) {
                    uploadRef.current.value = null;
                    uploadRef.current.click();
                  }
                }}
              >
                Add Attachment
              </Button>
            )}
          </Grid>

          <Grid item xs={12}>
            {meetingAttachments && meetingAttachments.length
              ? meetingAttachments.map((item, index) => {
                  return (
                    <Chip
                      key={index}
                      icon={<InsertDriveFileIcon />}
                      label={parseFileName(item.fileName)}
                      deleteIcon={
                        (isOwner || isCreateMode) && !isReadMode ? (
                          <CancelIcon
                            style={{
                              opacity: isReadMode ? 0.5 : 1,
                              cursor: isReadMode ? "default" : "pointer",
                            }}
                          />
                        ) : (
                          <GetAppIcon />
                        )
                      }
                      onDelete={(event) => {
                        // if (isReadMode) {
                        //   downlodAttachment(item);
                        // }
                        if ((isOwner && !isReadMode) || isCreateMode) {
                          event.preventDefault();
                          removeAttachment(item, index);
                        }
                      }}
                      variant="outlined"
                      style={{ margin: "0px 4px 6px 0px", cursor: "pointer" }}
                      onClick={() => {
                        if (isReadMode) {
                          downloadCalendarAttachment(item.linkKey);
                        }
                      }}
                    />
                  );
                })
              : null}
          </Grid>
          <input
            style={{ display: "none" }}
            id="send-attachament"
            multiple
            type="file"
            ref={uploadRef}
            onChange={processAttachment}
          />
          <textarea
            readOnly
            style={{ width: 0, height: 0, opacity: 0 }}
            ref={textAreaRef}
            rows="9"
            value={meetingLink}
          />
        </DialogContent>
        <DialogActions
          style={{
            padding: isMobileDevice() ? "8px 24px 16px 24px" : "16px 24px",
          }}
        >
          {isReadMode && !isOwner && eventUserId && !isCreateMode ? (
            <>
              <Button
                variant="outlined"
                style={{
                  padding: "6px 16px",
                  marginRight: 12,
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  updateMeetingStatus("false");
                }}
              >
                Reject
              </Button>
              <Button
                variant="outlined"
                className={Styles.primaryBtnAccent}
                style={{ textTransform: "capitalize", padding: "6px 32px" }}
                onClick={(e) => {
                  updateMeetingStatus("true");
                }}
              >
                Accept
              </Button>
              {meetingLink ? (
                <a
                  target="_blank"
                  href={meetingLink}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="outlined"
                    className={Styles.primaryBtnAccent}
                    style={{ textTransform: "capitalize", padding: "6px 32px" }}
                  >
                    {isMobileDevice() ? "Join" : "Join Meeting"}
                  </Button>
                </a>
              ) : null}
            </>
          ) : null}
          {isReadMode && isOwner ? (
            <>
              {!isAttendee && (
                <Button
                  variant="outlined"
                  style={{
                    padding: "6px 16px",
                    marginRight: 12,
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    setMeetingMode(MEETING_EDIT_MODE);
                  }}
                >
                  {isMobileDevice() ? "Modify" : "Modify Meeting"}
                </Button>
              )}
              <Button
                disabled={isAttendee}
                variant="outlined"
                style={{ textTransform: "capitalize", padding: "6px 32px" }}
                onClick={(e) => {
                  setOpenConfirmCanelMeetingDialog(true);
                }}
              >
                {isMobileDevice() ? "Cancel" : "Cancel Meeting"}
              </Button>
              {meetingLink ? (
                <a
                  target="_blank"
                  href={meetingLink}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="outlined"
                    className={Styles.primaryBtnAccent}
                    style={{ textTransform: "capitalize", padding: "6px 32px" }}
                  >
                    {isMobileDevice() ? "Join" : "Join Meeting"}
                  </Button>
                </a>
              ) : null}
            </>
          ) : null}

          {isCreateMode || (eventUserId && isOwner && !isReadMode) ? (
            <>
              <Button
                variant="outlined"
                style={{
                  padding: "6px 16px",
                  marginRight: 12,
                  textTransform: "capitalize",
                }}
                className={Styles.navyBlueColorForce}
                onClick={handleDialogClose}
              >
                Cancel
              </Button>
              <Button
                disabled={!hasFilledMandatoryData()}
                variant="outlined"
                onClick={() => {
                  saveMeeting();
                }}
                className={clsx(
                  Styles.navyBlueColorForce,
                  Styles.primaryBtnAccent
                )}
                style={{ textTransform: "capitalize", padding: "6px 32px" }}
              >
                {isCreateMode ? "Create" : "Update"}
              </Button>
            </>
          ) : null}
        </DialogActions>
      </Dialog>
    </>
  );
}
