import {
  Typography,
  Grid,
  Switch,
  Container,
  Paper,
  Button,
  makeStyles,
  Link,
  // Fab,
  Card,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import OneStoreFrontPlans from "./Plans/OneStoreFrontPlans";
import MultipleStoreFrontPlans from "./Plans/MultipleStoreFrontPlans";
import {
  useSubscriptionPlan,
  useSavePlanData,
  useVerifyPromocode,
  useMarketplacePlan,
} from "souqh-react-redux-hooks/serviceProvider/plans/useSubscriptionPlan";
import SetupFooter from "./SetupFooter";
import { useSetupDrawer } from "souqh-react-redux-hooks/useSetupDrawer";
import Styles from "../../Styles.module.css";
import clsx from "clsx";
import { usePreProfileData } from "souqh-react-redux-hooks/serviceProvider/useSetUp";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import { usePlanUpgradeDowngrade } from "souqh-react-redux-hooks/common/usePlanUpgradeDowngrade";
import ConfirmationDialog from "../ConfirmationDialog";
import { useHistory } from "react-router-dom";
import { isMarketplacePlan } from "../../utils/AppUtils";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles((theme) => ({
  buttons: {
    textTransform: "capitalize",
    padding: "0px 30px",
  },
  mainBoxStyle: {
    borderRadius: "4px",
    color: "white",
    padding: "20px",
    backgroundColor: "#66d7d1",
  },
}));

function Subscription({ srcPage = "" }) {
  const classes = useStyles();
  const [openChangeSubscriptionDialog, setOpenChangeSubscriptionDialog] =
    useState(false);
  const history = useHistory();
  const userInfo = useAuthUser();

  const {
    subscriptionPlans,
    teamSubscriptionPlans,
    actions: { setPlanDuration },
    team,
    planDuration,
    defaultSelectedCard,
    setDefaultSelectedCard,
    defaultSelectedTeamPlan,
    setDefaultSelectedTeamPlan,
    selectedTeamPlan,
    selectedUserPlan,
    selectedPrevUserPlan,
    selectedPrevPlanDuration,
  } = useSubscriptionPlan();

  const {
    savePlanData,
    actions: {
      setSelectedTeamPlan,
      setSelectedUserPlan,
      setPrevSelectedUserPlan,
      setSelectedPrevPlanDuration,
    },
  } = useSavePlanData();

  const { generateInvoiceMarketplace } = useMarketplacePlan();

  const { havePromoCode, verifyPromoCodeData } = useVerifyPromocode();
  const { changeSubscription } = usePlanUpgradeDowngrade();

  const [
    openMarketplacePlanConfirmDialog,
    setOpenMarketplacePlanConfirmDialog,
  ] = useState(false);

  const {
    actions: { showInvoiceComponent },
  } = useSetupDrawer();

  const { getProfileData } = usePreProfileData();

  const changePlanDuration = (e) => {
    e.target.checked ? setPlanDuration("YEARLY") : setPlanDuration("MONTHLY");
  };

  const openConfirmationDialog = () => {
    setSelectedPrevPlanDuration(planDuration);
    setOpenChangeSubscriptionDialog(true);
    planDuration === "MONTHLY"
      ? setPlanDuration("YEARLY")
      : setPlanDuration("MONTHLY");
  };

  useEffect(() => {
    getProfileData(() => {});
    window.scrollTo(0, 0); // scroll at top of page on load
  }, []);

  const marketplacePlanFeatues = [
    "One Stop Dashboard",
    "Basic Storefront",
    "Social Media Sharing",
  ];

  const MarketplacePlanCard = () => {
    return (
      <Card
        style={{
          color: "#17174C",
          border: "2px solid #0E1C4E1F",
          // height: "100%",
          marginBottom: srcPage !== "settingsPage" ? 80 : 28,
          width: "100%",
        }}
        elevation={0}
      >
        <Grid
          container
          item
          sm={12}
          style={{ padding: 20, cursor: "pointer" }}
          onClick={() => setOpenMarketplacePlanConfirmDialog(true)}
        >
          <Grid container item sm={7}>
            <Grid
              container
              item
              sm={12}
              className={classes.spacingControl}
              justifyContent="flex-start"
            >
              <Typography variant="h5">Starter Plan</Typography>
            </Grid>
            <Grid
              container
              item
              sm={12}
              className={classes.spacingControl}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
                No Credit Card Required
              </Typography>
            </Grid>
            <Grid
              container
              item
              sm={12}
              className={classes.spacingControl}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
                Free
              </Typography>
            </Grid>
            {marketplacePlanFeatues.map((feature, index) => (
              <Grid
                container
                item
                sm={12}
                justifyContent="flex-start"
                key={index}
                style={{ marginTop: 5 }}
              >
                <CheckCircleIcon />
                <Typography variant="body1">{feature}</Typography>
              </Grid>
            ))}
          </Grid>
          <Grid container item sm={5}>
            <img
              style={{ height: 250, width: 250 }}
              src="/images/Premium.svg"
            ></img>{" "}
          </Grid>
          {/* <Grid container item sm={12} style={{ marginTop: 10 }}>
            <Link underline="always" href={HELP_CENTER_LINK} target="_blank">
              Learn more
            </Link>
          </Grid> */}
        </Grid>
      </Card>
    );
  };

  const durationSwitch = () => {
    return (
      <Grid
        item
        container
        style={{ marginTop: srcPage !== "settingsPage" && 20 }}
        alignItems="center"
        justifyContent="flex-end"
      >
        <Typography
          variant={planDuration === "MONTHLY" ? "subtitle1" : "body1"}
          className={Styles.navyBlueColor}
        >
          Monthly
        </Typography>
        <Switch
          className={"sqGraySwitch"}
          checked={planDuration === "YEARLY" ? true : false}
          onChange={(e) => {
            srcPage !== "settingsPage"
              ? changePlanDuration(e)
              : openConfirmationDialog();
          }}
        ></Switch>
        <Typography
          variant={planDuration === "YEARLY" ? "subtitle1" : "body1"}
          className={Styles.navyBlueColor}
        >
          Annually
        </Typography>
      </Grid>
    );
  };
  return (
    <Grid container style={{ marginTop: 20 }} id="plan">
      <ConfirmationDialog
        open={openMarketplacePlanConfirmDialog}
        sectionTitle={
          "You have selected Marketplace plan. Please click on Confirm button to proceed"
        }
        handleNo={() => {
          setOpenMarketplacePlanConfirmDialog(false);
        }}
        handleYes={() => {
          setTimeout(() => {
            savePlanData((res) => {
              if (res && res.status === 200) {
                history.push("/setup/review");
              }
            }, true); // here true is for isMarketplaceplan
          }, 5000);
        }}
        title={"Comfirmation"}
        handleNoTitle={"Cancel"}
        handleYesTitle={"Confirm"}
      />
      <Container
        style={{
          paddingBottom: srcPage !== "settingsPage" ? 80 : 0,
          paddingLeft: srcPage === "settingsPage" && 16,
        }}
      >
        {srcPage !== "settingsPage" && (
          <Grid item style={{ marginBottom: 48, marginTop: 84 }}>
            <Typography variant="h4" className={Styles.navyBlueColor}>
              {subscriptionPlans.case === 1
                ? "Select your Payment Plan"
                : subscriptionPlans.case === 2
                ? "Team Payment Plan"
                : "Payment Plan"}
            </Typography>
          </Grid>
        )}
        {srcPage === "settingsPage" && (
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="body" className={Styles.navyBlueColor}>
                Current Subscription:{" "}
              </Typography>
              <Typography
                variant="body"
                className={Styles.navyBlueColor}
                style={{ fontWeight: 600 }}
              >
                {defaultSelectedCard} Plan
              </Typography>
            </Grid>
            {/* <Grid item xs={6}>
              {durationSwitch()}
            </Grid> */}
          </Grid>
        )}

        {/* {team.additionalUsers.length ? (
          <Grid item container style={{ marginTop: 20 }}>
            <Typography variant="body1" className={Styles.navyBlueColor}>
              Select the Payment Plan best suited for your team
            </Typography>
            <Typography variant="body1" className={Styles.navyBlueColor}>
              The primary user of this account will be billed at $99. You are
              currently selecting plan for your team.
            </Typography>
          </Grid>
        ) : null} */}
        {srcPage !== "settingsPage" && (
          <Grid item container style={{ marginTop: 20 }}>
            <Grid item xs={7} className={classes.mainBoxStyle}>
              <Typography variant="h6">Enterprise Plans</Typography>
              <Typography
                variant="body1"
                style={{ marginTop: 20, lineHeight: "21px" }}
              >
                Have a team of more than 10 users? Reach out to us to for
                enterprise plans and special discounts available for your team.
              </Typography>
            </Grid>
            <Grid item xs={5} style={{ borderRadius: "4px" }}>
              <Paper style={{ padding: 20 }}>
                <img
                  style={{ width: "100%", display: "flex" }}
                  src="/images/premium_features.svg"
                ></img>
              </Paper>
            </Grid>
          </Grid>
        )}

        {team.additionalUsers.length > 10 ? (
          <Paper
            elevation={0}
            style={{ padding: "20px 20px 0px 20px", marginTop: 20 }}
          >
            <Grid container alignItems="center">
              <Grid item xs={8}>
                <Grid item style={{ marginTop: 10 }}>
                  <Typography variant="h4" className={Styles.navyBlueColor}>
                    Enterprise Perks
                  </Typography>
                </Grid>
                <Grid item style={{ marginTop: 10 }}>
                  <Typography variant="body1" className={Styles.navyBlueColor}>
                    As you have more than 10 team member you are eligible for
                    the enterprise plan. Please connect with us to get a promo
                    code for additional discount.
                  </Typography>
                </Grid>
                <Grid container style={{ marginTop: 20 }}>
                  <Grid item>
                    <CallIcon className={Styles.navyBlueColor} />
                  </Grid>
                  <Grid item style={{ paddingLeft: 10 }}>
                    <Typography
                      variant="body1"
                      className={Styles.navyBlueColor}
                    >
                      support@souqh.ca
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item>
                    <EmailIcon className={Styles.navyBlueColor} />
                  </Grid>
                  <Grid item style={{ paddingLeft: 10 }}>
                    <Typography
                      variant="body1"
                      className={Styles.navyBlueColor}
                    >
                      314 - 112 - 3245
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <img
                  style={{ height: 250, width: 200 }}
                  src="/images/Enterprise-Plan-Illustration.svg"
                ></img>
              </Grid>
            </Grid>
          </Paper>
        ) : null}

        {srcPage !== "settingsPage" && (
          <Grid container alignItems="center" alignContent="center">
            <Grid item xs={6} style={{ marginTop: 20 }}>
              <Typography
                variant="body"
                className={Styles.navyBlueColor}
                style={{ fontWeight: 600 }}
              >
                Primary User Plan
              </Typography>
            </Grid>
            {/* <Grid item xs={6}>
              {durationSwitch()}
            </Grid> */}
          </Grid>
        )}

        {subscriptionPlans.case === 1 || subscriptionPlans.case === 2 ? (
          <React.Fragment>
            <OneStoreFrontPlans
              plansList={subscriptionPlans}
              planDuration={planDuration}
              setSelectedTeamPlan={setSelectedTeamPlan}
              defaultSelectedCard={defaultSelectedCard}
              setDefaultSelectedCard={setDefaultSelectedCard}
              type="userPaymentPlan"
              srcPage={srcPage}
              setSelectedUserPlan={setSelectedUserPlan}
              savePlanData={savePlanData}
              selectedTeamPlan={selectedTeamPlan}
              selectedUserPlan={selectedUserPlan}
              selectedPrevUserPlan={selectedPrevUserPlan}
              setPrevSelectedUserPlan={setPrevSelectedUserPlan}
              changeSubscription={changeSubscription}
              openChangeSubscriptionDialog={openChangeSubscriptionDialog}
              setOpenChangeSubscriptionDialog={setOpenChangeSubscriptionDialog}
              selectedPrevPlanDuration={selectedPrevPlanDuration}
              setSelectedPrevPlanDuration={setSelectedPrevPlanDuration}
              setPlanDuration={setPlanDuration}
            />
            {srcPage !== "settingsPage" && MarketplacePlanCard()}
          </React.Fragment>
        ) : (
          <MultipleStoreFrontPlans
            plansList={subscriptionPlans}
            planDuration={planDuration}
            setSelectedTeamPlan={setSelectedTeamPlan}
          ></MultipleStoreFrontPlans>
        )}

        {srcPage !== "settingsPage" &&
        team.additionalUsers.length &&
        selectedUserPlan !== 5 ? (
          <Grid>
            <Typography variant="h6" className={Styles.navyBlueColor}>
              Team Plan Selection
            </Typography>
            <OneStoreFrontPlans
              plansList={teamSubscriptionPlans}
              planDuration={planDuration}
              setSelectedTeamPlan={setSelectedTeamPlan}
              selectedTeamPlan={selectedTeamPlan}
              defaultSelectedCard={defaultSelectedTeamPlan}
              setDefaultSelectedCard={setDefaultSelectedTeamPlan}
              type="teamPaymentPlan"
              srcPage={srcPage}
              setSelectedUserPlan={setSelectedUserPlan}
              savePlanData={savePlanData}
              selectedUserPlan={selectedUserPlan}
              selectedPrevUserPlan={selectedPrevUserPlan}
              setPrevSelectedUserPlan={setPrevSelectedUserPlan}
              changeSubscription={changeSubscription}
              openChangeSubscriptionDialog={openChangeSubscriptionDialog}
              setOpenChangeSubscriptionDialog={setOpenChangeSubscriptionDialog}
              selectedPrevPlanDuration={selectedPrevPlanDuration}
              setSelectedPrevPlanDuration={setSelectedPrevPlanDuration}
              setPlanDuration={setPlanDuration}
            ></OneStoreFrontPlans>
          </Grid>
        ) : null}
        {/* <Grid item xs={12}>
          <Fab
            aria-label="home"
            style={{
              // position: "fixed",
              backgroundColor: "#F5F5F8",
              boxShadow: "none",
            }}
            onClick={() => {
              setOpenMarketplacePlanConfirmDialog(true);
            }}
          />
        </Grid> */}
      </Container>
      {!(srcPage === "settingsPage") ? (
        <SetupFooter setupPage="storefront">
          <Button
            disabled={
              isMarketplacePlan(userInfo) &&
              selectedPrevUserPlan === selectedUserPlan &&
              selectedPrevUserPlan === 5 &&
              selectedUserPlan === 5
            }
            variant="contained"
            className={clsx(Styles.primaryBtnAccent, classes.buttons)}
            onClick={() => {
              if (isMarketplacePlan(userInfo)) {
                generateInvoiceMarketplace((res) => {
                  if (res && res.status === 200) {
                    showInvoiceComponent(true);
                  }
                });
              } else {
                savePlanData((res) => {
                  if (res && res.status === 200) {
                    showInvoiceComponent(true);
                  }
                });
              }
            }}
          >
            Save And Continue
          </Button>
        </SetupFooter>
      ) : null}
    </Grid>
  );
}

export default Subscription;
