import { useIntercom } from "react-use-intercom";

export const useSQIntercom = () => {
  const { boot, shutdown, show, hide, update } = useIntercom();

  return {
    startIntercom: boot,
    stopIntercom: shutdown,
    showIntercom: show,
    hideIntercom: hide,
    updateIntercom: update,
  };
};
