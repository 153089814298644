import { cloneDeep, findIndex } from "lodash";
import {
  SET_EVENTS_TAB,
  SET_PENDING_ACTIONS,
  REMOVE_PENDING_ACTION,
} from "./actionConstants";

const initialState = {
  eventsTab: 0,
  pendingActions: { pendingActionsList: [], totalItems: 0 },
};

const upcomingEeventsPendingActions = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_EVENTS_TAB:
      newState = cloneDeep(state);
      newState.eventsTab = action.payload;
      return newState;
    case SET_PENDING_ACTIONS:
      newState = cloneDeep(state);
      newState.pendingActions = action.payload;
      newState.pendingActions.pendingActionsList = action.payload.response;
      newState.pendingActions.totalItems = action.payload.totalItems;
      return newState;
    case REMOVE_PENDING_ACTION:
      newState = cloneDeep(state);
      let index = findIndex(
        newState.pendingActions.pendingActionsList,
        function (o) {
          return o.pendingActionId == action.payload;
        }
      );
      if (index >= 0) {
        newState.pendingActions.pendingActionsList.splice(index, 1);
      }
      --newState.pendingActions.totalItems;
      return newState;
    default:
      return state;
  }
};

export default upcomingEeventsPendingActions;
