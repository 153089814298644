import { cloneDeep, forEach, isEmpty } from "lodash";
import {
  GET_CLIENT_DATA,
  SET_CLIENTS_TAB,
  SET_CLIENT_MGMT_DATA,
  DROP_REQUEST,
  SET_ACTIVE_CLIENT_DATA,
  SET_LEADS_CLIENT_DATA,
  SEND_INVOICE_ADD_FILE,
  SEND_INVOICE_REMOVE_FILE,
  SET_INACTIVE_CLIENT_DATA,
  SET_COMPLETE_CLIENT_DATA,
  SET_CLIENT_DOCUMENTS,
  SET_TAG_MEMBERS,
  EDIT_TEAM_MEMBER,
  SET_ALL_USERS,
  SET_CLIENT_HUB_DOCUMENTS,
  SAVE_RECOMMEND_SP_TO_CLIENT_DATA,
  CLEAR_RECOMMEND_SP_TO_CLIENT_DATA,
} from "./actionConstants";

const initialState = {
  active: { response: [], count: 0 },
  inActive: { response: [], count: 0 },
  completed: { response: [], count: 0 },
  leads: { response: [], count: 0 },
  clientsTab: 0,
  sendInvoiceDcouments: [],
  clientDocuments: [],
  tagMembers: [],
  clientHubDocuments: [],
  recommendedSPsToClient: [],
};

const clientManagement = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case GET_CLIENT_DATA:
      return newState;
    case SET_CLIENTS_TAB:
      newState = cloneDeep(state);
      newState.clientsTab = action.payload;
      return newState;
    case SET_CLIENT_MGMT_DATA:
      newState = cloneDeep(state);
      newState.active = action.payload.Active;
      newState.active.count = action.payload.Active.totalItems;
      newState.inActive = action.payload["In-Active"];
      newState.inActive.count = action.payload["In-Active"].totalItems;
      newState.completed = action.payload.Completed;
      newState.completed.count = action.payload.Completed.totalItems;
      newState.leads = action.payload.Leads;
      newState.leads.count = action.payload.Leads.totalItems;
      return newState;
    case DROP_REQUEST:
      newState = cloneDeep(state);
      const { serviceRequestId, tabName } = action.payload;
      const temp = newState[tabName].response;
      temp.splice(
        temp.findIndex((item) => item.serviceRequestId === serviceRequestId),
        1
      );
      return newState;
    case SET_ACTIVE_CLIENT_DATA:
      newState = cloneDeep(state);
      newState.active.response = action.payload.response;
      newState.active.count = newState.active.response.length;
      return newState;
    case SET_INACTIVE_CLIENT_DATA:
      newState = cloneDeep(state);
      newState.inActive.response = action.payload.response;
      // newState.inActive.count = newState.inActive.response.length;
      return newState;
    case SET_LEADS_CLIENT_DATA:
      newState = cloneDeep(state);
      newState.leads.response = action.payload.response;
      // newState.leads.count = newState.leads.response.length;
      return newState;
    case SET_COMPLETE_CLIENT_DATA:
      newState = cloneDeep(state);
      newState.completed.response = action.payload.response;
      // newState.completed.count = newState.inActive.response.length;
      return newState;
    case SEND_INVOICE_ADD_FILE:
      newState = cloneDeep(state);
      const files = action.payload;
      if (!isEmpty(files)) {
        Object.keys(files).map((key) => {
          newState.sendInvoiceDcouments.push(files[key]);
        });
      } else {
        newState.sendInvoiceDcouments = [];
      }
      return newState;
    case SEND_INVOICE_REMOVE_FILE:
      newState = cloneDeep(state);
      newState.sendInvoiceDcouments.splice(action.payload, 1);
      return newState;
    case SET_CLIENT_DOCUMENTS:
      newState = cloneDeep(state);
      newState.clientDocuments = action.payload;
      return newState;
    case SET_TAG_MEMBERS:
      newState = cloneDeep(state);
      newState.tagMembers = action.payload;
      return newState;
    case EDIT_TEAM_MEMBER:
      newState = cloneDeep(state);
      const teamEditData = newState.tagMembers.find(
        (el) => el.id === action.payload.id
      );
      teamEditData.edit = action.payload.edit;
      return newState;
    case SET_CLIENT_HUB_DOCUMENTS:
      newState = cloneDeep(state);
      newState.clientHubDocuments = action.payload;
      return newState;
    case SAVE_RECOMMEND_SP_TO_CLIENT_DATA:
      newState = cloneDeep(state);
      let spIds = [];
      action.payload.map((item) => {
        spIds.push(item.serviceProviderId);
      });
      newState.recommendedSPsToClient = spIds;
      return newState;
    case CLEAR_RECOMMEND_SP_TO_CLIENT_DATA:
      newState = cloneDeep(state);
      newState.recommendedSPsToClient = initialState.recommendedSPsToClient;
      return newState;
    default:
      return state;
  }
};

export default clientManagement;
