import { useState, useEffect } from "react";
import {
  SET_DOCUMENT_TAB,
  SET_DOCUMENT_TAB_NAME,
  SET_CATEGORIES,
  SET_WARNING_DIALOG_FLAG,
  SET_DOCUMENTS_TABLE_DATA,
  SIGN_NOW_USER_VERIFIED,
} from "../../../souqh-redux/reducers/actionConstants";
import { useActions } from "../../useActions";
import { useSelector, useDispatch } from "react-redux";
import { useAuthUser } from "../../useLogin";
import { findIndex, cloneDeep } from "lodash";
import { useSQIntercom } from "../../intercom/useSQIntercom";
import { useApiClient } from "../../useApiClient";
import { color_error } from "../../toasterColors";

const actionCreator = {
  setDocumentTab: (payload) => ({ type: SET_DOCUMENT_TAB, payload }),
  setDocumentTabName: (payload) => ({ type: SET_DOCUMENT_TAB_NAME, payload }),
  setDocumentsTableData: (documentsTableData) => ({
    type: SET_DOCUMENTS_TABLE_DATA,
    payload: { documentsTableData },
  }),
};

export const useDocuments = () => {
  const { apiClient } = useApiClient();

  const dispatch = useDispatch();
  const actions = useActions(actionCreator);
  const { updateIntercom, showIntercom, hideIntercom } = useSQIntercom();

  const getSharedWithTooltip = (sharedWithArray) => {
    const filteredSharedWithArray = sharedWithArray.slice(0, 1);
    const remainingArray = sharedWithArray.filter(
      (el) => !filteredSharedWithArray.includes(el)
    );
    return remainingArray.join();
  };

  const getSignedWithTooltip = (signedWithList) => {
    const filteredSignedWith = signedWithList.slice(0, 1);
    const remainingList = signedWithList.filter(
      (el) => !filteredSignedWith.includes(el)
    );
    return remainingList.map((item) => item.email).join();
  };

  const [searchText, setSearchText] = useState("");
  const [openShareDocumentDialog, setOpenShareDocumentDialog] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedDataForDownload, setSelectedDataForDownload] = useState([]);

  const [templateName, setTemplateName] = useState("");
  const [templateCategory, setTemplateCategory] = useState("");
  const [templateCategoryList, setTemplateCategoryList] = useState([]);

  const [openConfirmTemplateDialog, setOpenConfirmTemplateDialog] =
    useState(false);

  const [openDeleteCategoryDialog, setOpenDeleteCategoryDialog] =
    useState(false);
  const [openAddCategoryDialog, setOpenAddCategoryDialog] = useState(false);
  const [openRenameCategoryDialog, setOpenRenameCategoryDialog] =
    useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("docName");

  const [actionRequiredCount, setActionRequiredCount] = useState(0);
  const [waitingForOtherCount, setWaitingForOtherCount] = useState(0);
  const [expiringSoonCount, setExpiringSoonCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [archivedCount, setArchivedCount] = useState(0);
  const [otherDocumentsCount, setOtherDocumentsCount] = useState(0);
  const [myTemplatesCount, setMyTemplatesCount] = useState(0);
  const [templateForUse, setTemplateForUse] = useState(null);
  const userInfo = useAuthUser();
  const [documentDataForEdit, setDocumentDataForEdit] = useState(null);
  const [openDeleteDocumentDialog, setOpenDeleteDocumentDialog] = useState({
    open: false,
    docDetails: {},
    categoryId: "",
  });

  const [openVoidDocumentDialog, setOpenVoidDocumentDialog] = useState({
    open: false,
    documentName: "",
  });

  const [openMoveDocumentDialog, setOpenMoveDocumentDialog] = useState({
    open: false,
    documentName: "",
    documentId: "",
    sourceCategoryId: "",
  });

  const [openArchiveDocumentDialog, setOpenArchiveDocumentDialog] = useState({
    open: false,
    docDetails: {},
  });

  const [openRenameDocumentDialog, setOpenRenameDocumentDialog] = useState({
    open: false,
    documentId: "",
    categoryId: "",
    documentName: "",
  });

  const documentsTableData = useSelector(
    (state) => state.documentsData.documentsTableData
  );
  const categories = useSelector((state) => state.documents.categories);

  const documentList = useSelector(
    (state) => state.uploadDocument.documentList
  );

  const documentTab = useSelector((state) => state.documents.documentTab);
  const documentTabName = useSelector(
    (state) => state.documents.documentTabName
  );
  const isUserVerifiedForSignNow = useSelector(
    (state) => state.spDocument.isUserVerifiedForSignNow
  );

  useEffect(() => {
    actions.setDocumentsTableData(categories);
  }, [categories]);

  const updateIntercomStatus = (flag) => {
    if (flag) {
      hideIntercom();
      updateIntercom({
        hideDefaultLauncher: true,
      });
    } else {
      // showIntercom();
      updateIntercom({
        hideDefaultLauncher: false,
      });
    }
  };

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    setOpenMenu(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenMenu(false);
    }
  }

  const handleDeleteCategoryDialog = () => {
    setOpenDeleteCategoryDialog(true);
    handleClose();
  };

  const handleDeleteDocumentDialog = (docDetails, categoryId) => {
    setOpenDeleteDocumentDialog({
      open: true,
      docDetails,
      categoryId,
    });
  };

  const handleVoidDocumentDialog = (documentName) => {
    setOpenVoidDocumentDialog({ open: true, documentName });
  };

  const handleMoveDocumentDialog = (documentName) => {
    setOpenMoveDocumentDialog({ open: true, documentName });
  };

  const handleAddCategoryDialog = () => {
    setOpenAddCategoryDialog(true);
    handleClose();
  };

  const handleRenameCategoryDialog = (categoryId) => {
    setOpenRenameCategoryDialog({ open: true, categoryId });
    handleClose();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getTabWiseCategoriesList = (documentTabName) => {
    if (documentTabName) {
      dispatch({ type: "SET_LOADING", value: true });
      return apiClient
        .get(`/documents/user/${userInfo.userId}/${documentTabName}`)
        .then(function (response) {
          dispatch({
            type: SET_CATEGORIES,
            payload: { categories: response.data.result.documentList },
          });
          setActionRequiredCount(response.data.result.actionRequiredCount);
          setWaitingForOtherCount(response.data.result.waitingForOtherCount);
          setExpiringSoonCount(response.data.result.expiringSoonCount);
          setCompletedCount(response.data.result.completedCount);
          setArchivedCount(response.data.result.archivedCount);
          setOtherDocumentsCount(response.data.result.otherDocumentsCount);
          setMyTemplatesCount(response.data.result.myTemplatesCount);
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const getShowDialogFlag = () => {
    return apiClient
      .get(`/user/${userInfo.userId}/showdialog`)
      .then(function (response) {
        dispatch({
          type: SET_WARNING_DIALOG_FLAG,
          payload: { flag: response.data.result },
        });
      });
  };

  const searchVal = (val) => {
    setSearchText(val);
    requestSearch(val);
  };

  const isPresentInSignedWith = (searchedVal, row) => {
    let found =
      row.signedWith &&
      row.signedWith.length &&
      row.signedWith.find((o) =>
        o.email.toLowerCase().includes(searchedVal.toLowerCase())
      );
    let returnVal = found ? true : false;
    return returnVal;
  };

  const requestSearch = (searchedVal) => {
    setSearchText(searchedVal);
    let filteredRows = [];
    categories.map((category) => {
      category.documents.filter((row) => {
        if (
          row.docName.toLowerCase().includes(searchedVal.toLowerCase()) ||
          row.docStatus.toLowerCase().includes(searchedVal.toLowerCase()) ||
          row.sharedWith
            .toString()
            .toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row.sentBy.toLowerCase().includes(searchedVal.toLowerCase()) ||
          isPresentInSignedWith(searchedVal, row)
        ) {
          const index = findIndex(
            filteredRows,
            (filteredRow) => filteredRow.name === category.name
          );
          if (index >= 0) {
            filteredRows[index].documents.push(row);
          } else {
            filteredRows.push({
              id: category.id,
              isDefault: category.isDefault,
              name: category.name,
              documents: [row],
            });
          }
        }
      });
    });
    searchedVal
      ? actions.setDocumentsTableData(filteredRows)
      : actions.setDocumentsTableData(categories);
  };

  const handleCheckboxChange = (checked, docId) => {
    const tempList = cloneDeep(documentsTableData);
    tempList.forEach((item) => {
      item.documents.forEach((el) => {
        if (el.docId === docId) {
          el.selected = checked;
        }
      });
    });
    actions.setDocumentsTableData(tempList);
  };

  const deselectDocfromSelection = (docId) => {
    const tempList = cloneDeep(documentsTableData);
    let findItem = null;
    tempList.forEach((item) => {
      !findItem
        ? (findItem = item.documents.find((el) => el.docId === docId))
        : null;
    });
    if (findItem) {
      findItem.selected = false;
      actions.setDocumentsTableData(tempList);
    }
  };

  const extractSelectedData = (temp, dataForDownload) => {
    documentsTableData &&
      documentsTableData.length &&
      documentsTableData.forEach((item) => {
        item.documents.forEach((el) => {
          if (el.selected) {
            temp.push(el);
            dataForDownload.push({
              categoryId: item.id,
              categoryName: item.name,
              documentName: el.docName,
              fileName: el.fileName,
              isSignedDocument: !!el.signNowDocumentId,
            });
          }
        });
      });
  };

  const isSignNowUserVerified = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post(`signnow/user/${userInfo.userId}/verified`)
      .then(function (response) {
        if (response.data.status === 200) {
          dispatch({
            type: SIGN_NOW_USER_VERIFIED,
            payload: response.data.result.verified,
          });
        }
        if (response.data.status === 400) {
          dispatch({
            type: "SET_SERVER_ERROR",
            error: { message: response.data.message },
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const showSignNowVerificationToaster = () => {
    dispatch({
      type: "SET_TOASTER",
      payload: {
        message: "Please verify your Sign Now account!",
        bgColor: color_error,
        open: true,
      },
    });
  };

  return {
    templateName,
    setTemplateName,
    templateCategory,
    setTemplateCategory,
    templateCategoryList,
    setTemplateCategoryList,
    documentList,
    openConfirmTemplateDialog,
    setOpenConfirmTemplateDialog,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    handleRequestSort,
    actions,
    documentTab,
    handleDeleteCategoryDialog,
    openDeleteCategoryDialog,
    setOpenDeleteCategoryDialog,
    handleAddCategoryDialog,
    openAddCategoryDialog,
    setOpenAddCategoryDialog,
    handleRenameCategoryDialog,
    openRenameCategoryDialog,
    setOpenRenameCategoryDialog,
    openMenu,
    handleToggle,
    handleClose,
    handleListKeyDown,
    getSharedWithTooltip,
    openDeleteDocumentDialog,
    setOpenDeleteDocumentDialog,
    handleDeleteDocumentDialog,
    openVoidDocumentDialog,
    setOpenVoidDocumentDialog,
    handleVoidDocumentDialog,
    openMoveDocumentDialog,
    setOpenMoveDocumentDialog,
    handleMoveDocumentDialog,
    openArchiveDocumentDialog,
    setOpenArchiveDocumentDialog,
    openRenameDocumentDialog,
    setOpenRenameDocumentDialog,
    categories,
    searchVal,
    documentsTableData,
    openShareDocumentDialog,
    setOpenShareDocumentDialog,
    selectedData,
    setSelectedData,
    handleCheckboxChange,
    selectedDataForDownload,
    setSelectedDataForDownload,
    extractSelectedData,
    deselectDocfromSelection,
    updateIntercomStatus,
    documentTabName,
    getTabWiseCategoriesList,
    actionRequiredCount,
    waitingForOtherCount,
    expiringSoonCount,
    completedCount,
    archivedCount,
    otherDocumentsCount,
    myTemplatesCount,
    getShowDialogFlag,
    templateForUse,
    setTemplateForUse,
    setSearchText,
    searchText,
    getSignedWithTooltip,
    userInfo,
    documentDataForEdit,
    setDocumentDataForEdit,
    isSignNowUserVerified,
    isUserVerifiedForSignNow,
    showSignNowVerificationToaster,
  };
};
