import { useState, useEffect } from "react";
import { useAuthUser } from "../../useLogin";
import { useDispatch, useSelector } from "react-redux";
import {
  CLEAN_UPLOAD_FILE_DATA,
  UPDATE_UPLOAD_DOCUMENT_DESCRIPTION,
  SET_UPLOAD_ACTIVE_CUSTOMERS,
  SET_UPLOAD_SELECTED_CONTACTS,
  DELETE_UPLOAD_DOCUMENT_WHILE_UPLOAD,
  SET_UPLOAD_CONTACTS,
  ADD_UPLOAD_DOCUMENTS,
  SET_OPEN_UPLOAD_WIZARD,
} from "../../../souqh-redux/reducers/actionConstants";
import { useActions } from "../../useActions";
import { cloneDeep, map } from "lodash";
import {
  HOME_BUYER_USER,
  OTHER_DOCUMENTS,
  WAITING_FOR_OTHER,
  SERVICE_PROVIDER_USER,
  TEAM_MEMBER_USER,
  PREMIUM,
  ADVANCED,
} from "../../../web/src/components/ServiceProvider/serviceProviderConstants";
import { REFERENCE_DOCUMENTS } from "../../../web/src/components/HomeBuyer/homeBuyerConstants";
import { useApiClient } from "../../useApiClient";

const actionCreator = {
  cleanUploadFileDataOnClose: () => ({
    type: CLEAN_UPLOAD_FILE_DATA,
    payload: [],
  }),
  updateDescription: (index, value) => ({
    type: UPDATE_UPLOAD_DOCUMENT_DESCRIPTION,
    payload: { index, value },
  }),
  setSelectedContacts: (payload) => ({
    type: SET_UPLOAD_SELECTED_CONTACTS,
    payload,
  }),
  setActiveCustomers: (activeCustomers) => ({
    type: SET_UPLOAD_ACTIVE_CUSTOMERS,
    payload: { activeCustomers },
  }),
  deleteDocument: (payload) => ({
    type: DELETE_UPLOAD_DOCUMENT_WHILE_UPLOAD,
    payload: { index: payload },
  }),
  updateDescription: (index, value) => ({
    type: UPDATE_UPLOAD_DOCUMENT_DESCRIPTION,
    payload: { index, value },
  }),
  setContacts: (contacts) => ({
    type: SET_UPLOAD_CONTACTS,
    payload: contacts,
  }),
  setOpenUploadWizard: (openUploadWizard) => ({
    type: SET_OPEN_UPLOAD_WIZARD,
    payload: { openUploadWizard },
  }),
};

export const useSPUploadWizard = () => {
  const { apiClient } = useApiClient();
  const [activeSection, setActiveSection] = useState(1);
  const [openUploadCancelDialog, setOpenUploadCancelDialog] = useState(false);
  const userInfo = useAuthUser();
  const dispatch = useDispatch();
  const actions = useActions(actionCreator);

  const [activeTab, setActiveTab] = useState(0);
  const [hasLoadedList, setHasLoadedList] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [message, setMessage] = useState("");
  const [defaultCategory, setDefaultCategory] = useState({});
  const [searchText, setSearchText] = useState("");
  const [selectedActiveCustomers, setSelectedActiveCustomers] = useState([]);
  const [customContacts, setCustomContacts] = useState("");
  const [selectAllCustomers, setSelectAllCustomers] = useState(false);
  const [userType, setUserType] = useState("");

  const documentList = useSelector(
    (state) => state.uploadDocument.documentList
  );
  const openUploadWizard = useSelector(
    (state) => state.uploadDocument.openUploadWizard
  );
  const contacts = useSelector((state) => state.uploadDocument.contacts);

  const selectedContacts = useSelector(
    (state) => state.uploadDocument.selectedContacts
  );

  const activeCustomers = useSelector(
    (state) => state.uploadDocument.activeCustomers
  );

  const duplicateFileName = useSelector(
    (state) => state.uploadDocument.duplicateFileName
  );

  const getFilteredCustomers = () => {
    return activeCustomers.filter((item) => {
      const txt = (searchText || "").toLowerCase().trim();
      return (
        (item.email || "").toLowerCase().indexOf(txt) >= 0 ||
        (item.firstName || "").toLowerCase().indexOf(txt) >= 0 ||
        (item.lastName || "").toLowerCase().indexOf(txt) >= 0
      );
    });
  };

  const handleCustomerSelect = (checked, contact) => {
    const tempList = cloneDeep(activeCustomers);
    const findItem = tempList.find((item) => item.email === contact.email);
    if (findItem) {
      findItem.selected = checked;
    }
    actions.setActiveCustomers(tempList);
    setSelectedActiveCustomers(tempList.filter((item) => item.selected));
  };

  const refreshSelectedActiveCustomers = () => {
    setSelectedActiveCustomers(activeCustomers.filter((item) => item.selected));
  };

  const onUploadFileChange = (event) => {
    processUploadedFiles(event.target.files, event);
  };

  const processUploadedFiles = (files, event) => {
    let finalFilesarray = [];
    Object.keys(files).forEach((key) => {
      finalFilesarray.push({
        fileName: files[key].name,
        file: files[key],
        isRef: false,
        description: "",
        hasUpdatedDescription: false,
      });
    });
    // check for duplicate file name
    let compareArray = [...documentList, ...finalFilesarray];
    var valueArr = compareArray.map(function (item) {
      return item.fileName;
    });
    var isDuplicate = valueArr.some((item, index) => {
      return valueArr.indexOf(item) != index;
    });
    dispatch({
      type: ADD_UPLOAD_DOCUMENTS,
      payload: { files: finalFilesarray, isDuplicate },
    });
    if (event && event.target) {
      event.target.value = "";
    }
    actions.setOpenUploadWizard(true);
  };

  const addItem = (event, value) => {
    if (event.charCode === 13) {
      const valid =
        /(^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)|(^$)/i.test(
          value
        );
      if (value && valid) {
        let data = cloneDeep(selectedContacts);
        data.push({ email: value });
        actions.setSelectedContacts(data);
        setCustomContacts("");
      }
    }
  };

  const removeFromOtherContacts = (contact) => {
    let data = cloneDeep(selectedContacts);
    actions.setSelectedContacts(
      data.filter((item) => item.email !== contact.email)
    );
  };

  const getContacts = () => {
    if (userInfo && userInfo.userType === HOME_BUYER_USER) {
      dispatch({ type: "SET_LOADING", value: true });
      return apiClient
        .get("/home-buyer/contacts/other-sp")
        .then(function (response) {
          actions.setContacts(response.data.result);
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    } else {
      // TO DO for SP
      dispatch({ type: "SET_LOADING", value: true });
      return apiClient
        .get("/home-buyer/contacts/other-sp")
        .then(function (response) {
          actions.setContacts(response.data.result);
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const getActiveCustomers = () => {
    setHasLoadedList(true);
    // actions.setActiveCustomers(JSON.parse(localStorage.getItem("contacts")));
    if (userInfo && userInfo.userType === HOME_BUYER_USER) {
      dispatch({ type: "SET_LOADING", value: true });
      return apiClient
        .get(`/home-buyer/contacts/active-sp/${userInfo.userId}`)
        .then(function (response) {
          if (response.data.result.length) {
            actions.setActiveCustomers(mapSPData(response.data.result));
          } else {
            setMessage(response.data.message);
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    } else {
      let url =
        userInfo.userType === SERVICE_PROVIDER_USER ||
        (userInfo.userType === TEAM_MEMBER_USER &&
          (userInfo.subscriptionPlanName === PREMIUM || userInfo.subscriptionPlanName === ADVANCED))
          ? `/service-provider/${userInfo.serviceProviderId}/home-buyer`
          : `/service-provider/${userInfo.parentServiceProviderId}/home-buyer`;

      dispatch({ type: "SET_LOADING", value: true });
      return apiClient
        .get(url)
        .then(function (response) {
          if (response.data.result.length) {
            actions.setActiveCustomers(mapHBData(response.data.result));
          } else {
            setMessage(response.data.message);
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const mapHBData = (data) => {
    const temp = [];
    if (data && data.length) {
      data.forEach((item) => {
        if (item && item.primaryApplicantEmail) {
          temp.push({
            email: item.primaryApplicantEmail,
            firstName: item.name || "",
            lastName: "",
            profilePic: "",
          });
        }
      });
    }
    return temp;
  };

  const mapSPData = (data) => {
    const temp = [];
    if (data && data.length) {
      data.forEach((item) => {
        if (item && item.email) {
          let profilePic = "";
          if (
            item.storeFrontContent &&
            typeof item.storeFrontContent === "string"
          ) {
            const data = JSON.parse(item.storeFrontContent);
            if (data && data.displayPicture) {
              profilePic = data.displayPicture;
            }
          }
          temp.push({
            email: item.email,
            firstName: item.firstName || "",
            lastName: item.lastName || "",
            profilePic,
          });
        }
      });
    }
    return temp;
  };

  const checkForDocumentDescriptions = (callback) => {
    let flag = false;
    documentList.forEach((item, index) => {
      if ((item.description || "").trim().length < 1) {
        flag = true;
        actions.updateDescription(index, "");
      }
    });
    if (!flag) {
      callback();
    }
  };

  const uploadFiles = (
    handleClose,
    getTabWiseCategoriesList,
    setDocumentTab,
    deletePendingAction,
    pendingActionId,
    refreshSPDocuments
  ) => {
    const bodyFormData = new FormData();

    let sharedDocuments = [];
    let documents = [];

    const uploadDocumentRequest = {
      sharedDocuments,
      sharedWithActiveUser: map(selectedActiveCustomers, "email"),
      sharedWithOthers: map(selectedContacts, "email"),
    };

    documentList.forEach((data) => {
      bodyFormData.append("documents", data.file, data.file.name);
      documents.push({
        docName: data.file.name,
        isReferenceDocument: true,
        docDescription: data.description,
      });
    });
    sharedDocuments.push({
      documents,
    });

    bodyFormData.append(
      "uploadDocumentRequest",
      JSON.stringify(uploadDocumentRequest)
    );
    bodyFormData.append("userId", userInfo.userId);
    uploadDocuments(
      bodyFormData,
      handleClose,
      uploadDocumentRequest,
      getTabWiseCategoriesList,
      setDocumentTab,
      deletePendingAction,
      pendingActionId,
      refreshSPDocuments
    );
  };

  const uploadDocuments = (
    bodyFormData,
    handleClose,
    uploadDocumentRequest,
    getTabWiseCategoriesList,
    setDocumentTab,
    deletePendingAction,
    pendingActionId,
    refreshSPDocuments
  ) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post(`/documents/upload`, bodyFormData)
      .then(function (response) {
        if (response.data.status === 200) {
          handleClose && handleClose();
          if (getTabWiseCategoriesList && setDocumentTab) {
            if (userInfo && userInfo.userType === HOME_BUYER_USER) {
              if (uploadDocumentRequest.sharedWithActiveUser.length) {
                getTabWiseCategoriesList(WAITING_FOR_OTHER);
                setDocumentTab(1);
              } else {
                getTabWiseCategoriesList(REFERENCE_DOCUMENTS);
                setDocumentTab(5);
              }
            } else {
              if (uploadDocumentRequest.sharedWithActiveUser.length) {
                getTabWiseCategoriesList(WAITING_FOR_OTHER);
                setDocumentTab(1);
              } else {
                getTabWiseCategoriesList(OTHER_DOCUMENTS);
                setDocumentTab(5);
              }
            }
          }
          refreshSPDocuments && refreshSPDocuments();
          pendingActionId && deletePendingAction(pendingActionId);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    userInfo,
    userType,
    activeSection,
    setActiveSection,
    actions,
    documentList,
    uploadFiles,
    checkForDocumentDescriptions,
    duplicateFileName,
    setOpenUploadCancelDialog,
    openUploadCancelDialog,
    tabs,
    activeTab,
    setActiveTab,
    getContacts,
    contacts,
    message,
    selectedContacts,
    actions,
    setCustomContacts,
    customContacts,
    addItem,
    activeCustomers,
    searchText,
    setSearchText,
    selectedActiveCustomers,
    setSelectedActiveCustomers,
    handleCustomerSelect,
    getActiveCustomers,
    selectAllCustomers,
    setSelectAllCustomers,
    getFilteredCustomers,
    removeFromOtherContacts,
    removeFromOtherContacts,
    setUserType,
    setTabs,
    refreshSelectedActiveCustomers,
    onUploadFileChange,
    processUploadedFiles,
    openUploadWizard,
    hasLoadedList,
  };
};
