import React, { useEffect, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import CardSection from "./CardSection";
import {
  Button,
  Divider,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import OrderSummaryList from "./OrderSummaryList";
import { usePaymentAndInvoice } from "souqh-react-redux-hooks/serviceProvider/paymentAndInvoice/usePaymentAndInvoice";
import SetupFooter from "../SetupFooter";
import { useHistory } from "react-router-dom";
import Styles from "../../../Styles.module.css";
import Promocode from "./Promocode";
import CardDetails from "./CardDetails";
import { isMarketplacePlan } from "../../../utils/AppUtils";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { useMarketplacePlan } from "souqh-react-redux-hooks/serviceProvider/plans/useSubscriptionPlan";
import LogoutMsgDialog from "../../Settings/LogoutMsgDialog";
import PlanUpgradedMsgDialog from "./PlanUpgradedMsgDialog";

const useStyles = makeStyles((theme) => ({
  divider: {
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
  },
  buttons: {
    textTransform: "capitalize",
    color: "#0E1C4E",
    backgroundColor: "#FA7E61",
    padding: "0px 50px",
  },
}));

export default function CheckoutForm() {
  const classes = useStyles();
  const stripe = useStripe();
  let history = useHistory();
  const userInfo = useAuthUser();

  const [openLogoutMsgDialog, setOpenLogoutMsgDialog] = useState(false);
  const [openPlanUpgradedMsgDialog, setOpenPlanUpgradedMsgDialog] =
    useState(false);

  const {
    getInvoiceData,
    invoiceData,
    handleSubmit,
    errorMessage,
    showCardDetails,
    paymentCardDetails,
    team,
    cardHolderName,
    setCardHolderName,
    allowSubmit,
    validations,
  } = usePaymentAndInvoice();

  const { upgradeMarketplacePlan } = useMarketplacePlan();

  useEffect(() => {
    if (!isMarketplacePlan(userInfo)) {
      getInvoiceData();
    }
  }, []);

  // Currency formatter. FIXME: move to common file if required
  let currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <>
      <PlanUpgradedMsgDialog
        openPlanUpgradedMsgDialog={openPlanUpgradedMsgDialog}
        setOpenPlanUpgradedMsgDialog={setOpenPlanUpgradedMsgDialog}
      />
      <LogoutMsgDialog
        openLogoutMsgDialog={openLogoutMsgDialog}
        setOpenLogoutMsgDialog={setOpenLogoutMsgDialog}
        isInvoicePage={true}
      />
      <Grid
        container
        xs={12}
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        style={{ marginTop: 20, color: "#0E1C4E" }}
      >
        <Grid container xs={12}>
          <Grid item xs={12} style={{ marginBottom: 48, marginTop: 84 }}>
            <Typography variant="h4" className={Styles.navyBlueColor}>
              Payment and Invoicing
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            style={{ marginTop: 30, paddingBottom: 80 }}
            justifyContent="space-around"
            spacing={3}
          >
            <Grid item xs={6}>
              {!showCardDetails ? (
                <Grid
                  item
                  style={{
                    backgroundColor: "#ffffff",
                    borderRadius: 8,
                    height: 300,
                    padding: "30px 20px 35px 20px",
                  }}
                >
                  <form onSubmit={handleSubmit}>
                    <CardSection
                      cardHolderName={cardHolderName}
                      setCardHolderName={setCardHolderName}
                      validations={validations}
                    />
                  </form>
                  {errorMessage && (
                    <Typography variant="body2" color="error">
                      {errorMessage}
                    </Typography>
                  )}
                </Grid>
              ) : (
                <CardDetails paymentCardDetails={paymentCardDetails} />
              )}
              <Promocode />
            </Grid>
            <Grid item xs={6}>
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: 8,
                  padding: "10px 0px 10px 0px",
                  marginBottom: 20,
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    marginBottom: 10,
                  }}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    style={{
                      marginTop: 10,
                      backgroundColor: "#F8F8F8",
                      padding: 10,
                      borderRadius: 4,
                    }}
                  >
                    <Grid container>
                      <Grid container item xs={6} justifyContent="flex-start">
                        <Typography
                          variant="body2"
                          className={Styles.navyBlueColor}
                        >
                          Order Summary
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid container item xs={6} justifyContent="flex-start">
                        <Typography
                          variant="subtitle1"
                          className={Styles.navyBlueColor}
                        >
                          Total Cost
                        </Typography>
                      </Grid>
                      <Grid container item xs={6} justifyContent="flex-end">
                        <Typography
                          variant="subtitle1"
                          className={Styles.navyBlueColor}
                        >
                          {invoiceData &&
                            currencyFormatter.format(invoiceData.totalAmount)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <OrderSummaryList
                  title={
                    invoiceData &&
                    `${invoiceData.primaryUserPlanDTO.planName} Plan`
                  }
                  subtitle="Primary User"
                  imageSrc={`/images/${
                    invoiceData &&
                    invoiceData.primaryUserPlanDTO &&
                    invoiceData.primaryUserPlanDTO.planName
                  }.svg`}
                  finalCost={
                    invoiceData &&
                    currencyFormatter.format(invoiceData.primaryUserCost)
                  }
                  perMonthOrYear={invoiceData && invoiceData.paymentCycle}
                  perMonthOrYearCost={
                    invoiceData &&
                    (invoiceData.paymentCycle === "MONTHLY"
                      ? invoiceData.primaryUserPlanDTO &&
                        invoiceData.primaryUserPlanDTO.monthlyPrice
                      : invoiceData.paymentCycle === "YEARLY"
                      ? invoiceData.primaryUserPlanDTO &&
                        invoiceData.primaryUserPlanDTO.annualPrice
                      : "")
                  }
                />
                {invoiceData &&
                  invoiceData.teamMembersPlanDTO &&
                  !invoiceData.storeFrontsCost && (
                    <>
                      <Divider
                        orientation="horizontal"
                        variant="fullWidth"
                        className={classes.divider}
                      />
                      <OrderSummaryList
                        title={
                          invoiceData &&
                          `${invoiceData.teamMembersPlanDTO.planName} Plan`
                        }
                        subtitle={`Team Members: ${
                          invoiceData && invoiceData.numberOfTeamMembers
                        }`}
                        imageSrc={`/images/${
                          invoiceData &&
                          invoiceData.teamMembersPlanDTO &&
                          invoiceData.teamMembersPlanDTO.planName
                        }.svg`}
                        finalCost={
                          invoiceData &&
                          currencyFormatter.format(invoiceData.teamMembersCost)
                        }
                        perMonthOrYear={invoiceData && invoiceData.paymentCycle}
                        perMonthOrYearCost={
                          invoiceData &&
                          (invoiceData.paymentCycle === "MONTHLY"
                            ? invoiceData.teamMembersPlanDTO.monthlyPrice
                            : invoiceData.paymentCycle === "YEARLY"
                            ? invoiceData.teamMembersPlanDTO.annualPrice
                            : "")
                        }
                      />
                    </>
                  )}
                <Divider
                  orientation="horizontal"
                  variant="fullWidth"
                  className={classes.divider}
                />
                {invoiceData && invoiceData.usedPromoCode && (
                  <>
                    <OrderSummaryList
                      title="Promo Code"
                      imageSrc="/images/promo-code.svg"
                      subtitle={invoiceData && invoiceData.usedPromoCode}
                      finalCost={
                        invoiceData &&
                        currencyFormatter.format(invoiceData.discountAmount)
                      }
                    />
                    <Divider
                      orientation="horizontal"
                      variant="fullWidth"
                      className={classes.divider}
                    />
                  </>
                )}
                <Grid
                  container
                  item
                  xs={12}
                  style={{ padding: "10px 20px 10px 0px" }}
                >
                  <Grid item xs={3}></Grid>
                  <Grid item xs={7} style={{ paddingLeft: 12 }}>
                    <Typography
                      variant="body1"
                      className={Styles.navyBlueColor}
                    >
                      Subtotal
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="flex-end" item xs={2}>
                    <Typography
                      variant="body1"
                      className={Styles.navyBlueColor}
                    >
                      {invoiceData &&
                        currencyFormatter.format(invoiceData.subtotalCost)}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider
                  orientation="horizontal"
                  variant="fullWidth"
                  className={classes.divider}
                />
                <OrderSummaryList
                  title="Taxes"
                  imageSrc="/images/taxes.svg"
                  taxes={invoiceData && invoiceData.taxes}
                  finalCost={
                    invoiceData &&
                    currencyFormatter.format(invoiceData.totalTaxAmount)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SetupFooter setupPage="storefront" isSaveAsDraft={false}>
        {!showCardDetails ? (
          !isMarketplacePlan(userInfo) ? (
            <Button
              variant="contained"
              className={classes.buttons}
              disabled={!stripe || !allowSubmit}
              onClick={(e) => {
                handleSubmit(e, (res) => {
                  if (res && res.status === 200) {
                    history.push("/setup/review");
                  }
                });
              }}
            >
              Save And Continue
            </Button>
          ) : (
            <Button
              variant="contained"
              className={classes.buttons}
              disabled={!stripe || !allowSubmit}
              onClick={(e) => {
                handleSubmit(e, (res) => {
                  if (res && res.status === 200) {
                    upgradeMarketplacePlan((res) => {
                      if (res && res.status === 200) {
                        // setOpenLogoutMsgDialog(true);
                        setOpenPlanUpgradedMsgDialog(true);
                      }
                    });
                  }
                });
              }}
            >
              Submit
            </Button>
          )
        ) : isMarketplacePlan(userInfo) ? (
          <Button
            variant="contained"
            className={classes.buttons}
            disabled={!stripe}
            onClick={(e) => {
              upgradeMarketplacePlan((res) => {
                if (res && res.status === 200) {
                  // history.push("/setup/review");
                  // setOpenLogoutMsgDialog(true);
                  setOpenPlanUpgradedMsgDialog(true);
                }
              });
            }}
          >
            Submit
          </Button>
        ) : (
          <Button
            variant="contained"
            className={classes.buttons}
            disabled={!stripe}
            onClick={(e) => {
              history.push("/setup/review");
            }}
          >
            Save And Continue
          </Button>
        )}
      </SetupFooter>
    </>
  );
}
