import { useState } from "react";

export const useSPManagementTable = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("storeFrontName");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return {
    order,
    orderBy,
    handleRequestSort,
    setOrderBy,
  };
};
