import React from "react";
import { Grid, Typography } from "@material-ui/core";
import Styles from "../../../Styles.module.css";

function OrderSummaryList({
  planName,
  title,
  subtitle,
  imageSrc,
  finalCost,
  perMonthOrYear,
  perMonthOrYearCost,
  taxes,
  page = "",
}) {
  return (
    <Grid container xs={12} style={{ marginBottom: 10, padding: "10px 20px" }}>
      <Grid
        container
        item
        xs={3}
        justifyContent="flex-start"
        alignContent="center"
      >
        <Grid
          container
          item
          xs={10}
          justifyContent="center"
          alignContent="center"
        >
          <img
            src={imageSrc}
            style={{
              height:
                title === "Promo Code" ||
                title === "Taxes" ||
                page == "registrationMobile"
                  ? 50
                  : 70,
              width:
                title === "Promo Code" ||
                title === "Taxes" ||
                page === "registrationMobile"
                  ? 50
                  : 70,
            }}
          ></img>
        </Grid>
      </Grid>
      <Grid container item xs={7} direction="column" justifyContent="center">
        <Typography
          className={Styles.navyBlueColor}
          style={{
            fontWeight: "500",
            lineHeight: 1.2,
            marginBottom: 5,
          }}
          variant="body1"
        >
          {title}
        </Typography>
        <Typography
          className={Styles.navyBlueColor}
          style={{
            fontWeight: "500",
            lineHeight: 1.2,
          }}
          variant="body2"
        >
          {subtitle}
        </Typography>

        {title !== "Promo Code" && title !== "Taxes" && (
          <Typography className={Styles.navyBlueColor} style={{ fontSize: 12 }}>
            ${perMonthOrYearCost}/user/
            {perMonthOrYear === "MONTHLY" ? "mo" : "yr"}
          </Typography>
        )}
        {title === "Taxes" && (
          <Grid item>
            {taxes &&
              Object.keys(taxes).map((key) => (
                <Typography
                  key={key}
                  style={{
                    fontSize: 12,
                  }}
                >
                  {key}@ {taxes[key]} %
                </Typography>
              ))}
          </Grid>
        )}
      </Grid>
      <Grid container item xs={2} justifyContent="flex-end">
        <Typography
          className={Styles.navyBlueColor}
          style={{ fontWeight: "600", marginTop: title === "Taxes" ? 0 : 6 }}
        >
          {title === "Promo Code" ? "-" : ""}
          {finalCost}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default OrderSummaryList;
