import Storage from "@aws-amplify/storage";

export const useAttachmentService = () => {
  const userLevel = "protected";

  const userUploadDir = "uploadfiles";

  const uploadAttachment = async (fileObj) => {
    try {
      const time = Date.now();
      const response = await Storage.put(
        `${userUploadDir}/${time}/${fileObj.name}`,
        fileObj,
        {
          contentType: fileObj.type,
          level: userLevel,
        }
      );

      return response;
    } catch (err) {
      throw new Error(`Failed to upload file! with error: ${err}`);
    }
  };

  const downloadAttachment = async (fileKey, userId) => {
    return Storage.get(fileKey, {
      level: userLevel,
      identityId: userId,
    });
  };

  const deleteAttachment = async (fileKey) => {
    return Storage.remove(fileKey, { level: userLevel });
  };

  return {
    uploadAttachment,
    downloadAttachment,
    deleteAttachment,
  };
};
