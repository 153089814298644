import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useApiClient } from "../../useApiClient";
import { SET_CLIENT_HUB_DOCUMENTS } from "../../../souqh-redux/reducers/actionConstants";

export const useRequestDocument = (userId, serviceRequestId) => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();

  const [requestDocComments, setRequestDocComments] = useState("");
  const [dueDate, setDueDate] = useState(null);
  const [selectedDocId, setSelectedDocId] = useState(null);
  const [selectedDocName, setSelectedDocName] = useState(null);
  const { clientHubDocuments } = useSelector((state) => state.clientManagement);

  const getClientHubDocumentsList = () => {
    if (userId) {
      dispatch({ type: "SET_LOADING", value: true });
      return apiClient
        .get(`/service-provider/client/documents/client-hub/${userId}`)
        .then(function (response) {
          if (response && response.data.result) {
            let allDocs = response.data.result;
            let filteredDocs = [];
            allDocs &&
              allDocs.length &&
              allDocs.forEach((item) => {
                if (item.signNowDocumentId === null) {
                  filteredDocs.push(item);
                }
              });
            dispatch({
              type: SET_CLIENT_HUB_DOCUMENTS,
              payload: filteredDocs, // only reference documents filtered out
            });
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const requestDocument = (onResult) => {
    let clientHubDocumentRequestDocumentDTO = {
      clientUserId: userId,
      comment: requestDocComments,
      documentId: selectedDocId || null,
      documentName: selectedDocName,
      dueDate: dueDate ? dueDate.toISOString() : null,
      serviceRequestId: serviceRequestId,
    };
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post(
        "/service-provider/client/documents/request-document",
        clientHubDocumentRequestDocumentDTO
      )
      .then(function (response) {
        if (response.data.status === 200) {
          onResult && onResult(response);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const clearData = () => {
    setRequestDocComments("");
    setSelectedDocId(null);
    setSelectedDocName(null);
    setDueDate(null);
  };

  return {
    getClientHubDocumentsList,
    clientHubDocuments,
    requestDocument,
    requestDocComments,
    setRequestDocComments,
    dueDate,
    setDueDate,
    setSelectedDocId,
    setSelectedDocName,
    selectedDocId,
    selectedDocName,
    clearData,
  };
};
