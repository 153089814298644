import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import HomeBuyingJourneyCard from "../../HomeBuyer/HomeBuyingJourney/HomeBuyingJourneyCard";
import { isMobileDevice } from "../../../utils/AppUtils";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minWidth: 860,
  },
}));

export default function SelectHomeBuyingJourneyDialog({
  openHomeBuyingJourneyDialog,
  setOpenHomeBuyingJourneyDialog,
}) {
  const theme = useTheme();
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      disableBackdropClick
      open={openHomeBuyingJourneyDialog}
      onClose={() => {
        setOpenHomeBuyingJourneyDialog(false);
      }}
      // maxWidth={"md"}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">
          Select Home Buying Journey Stage
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ marginTop: 8 }}>
        <Grid item xs={12}>
          <HomeBuyingJourneyCard xlUp={xlUp} isMobileDevice={isMobileDevice} />
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions style={{ padding: "16px 36px" }}>
        <Button
          variant="outlined"
          style={{
            padding: "6px 16px",
            marginRight: 12,
            textTransform: "capitalize",
          }}
          className={Styles.navyBlueColorForce}
          onClick={() => {
            setOpenHomeBuyingJourneyDialog(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
