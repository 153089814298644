import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  Chip,
  Card,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import { getUploadedDocuments } from "../../../utils/AppUtils";
import DocumentPreviewDialog from "../../HomeBuyer/Explore/StorefrontPage/DocumentPreviewDialog";
import clsx from "clsx";
import CollectCardDetails from "../../Settings/CollectCardDetails";
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_KEY } from "../../ServiceProvider/serviceProviderConstants";
import { useConnectedAccount } from "souqh-react-redux-hooks/common/useConnectedAccount";

export default function ViewInvoiceDialog({
  openViewInvoiceDialog,
  setOpenViewInvoiceDialog,
  viewInvoiceDialogData,
  userInfo,
}) {
  const [openDocumetDialog, setOpenDocumetDialog] = useState(false);
  const [documentItem, setDocumentItem] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [showCardPaymentDetails, setShowCardPaymentDetails] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const { updateStatusOfInvoice } = useConnectedAccount();
  const [docCategoryId, setDocCategoryId] = useState(null);
  const [docNameForDownload, setDocNameForDownload] = useState(null);
  const [docUserId, setDocUserId] = useState(null);

  useEffect(() => {
    if (viewInvoiceDialogData && viewInvoiceDialogData.stripeConnectId) {
      let stripe = loadStripe(STRIPE_KEY, {
        stripeAccount: viewInvoiceDialogData.stripeConnectId,
      });
      setStripePromise(stripe);
    }
  }, [viewInvoiceDialogData]);

  return (
    <React.Fragment>
      <DocumentPreviewDialog
        openDocumetDialog={openDocumetDialog}
        setOpenDocumetDialog={setOpenDocumetDialog}
        documentItem={documentItem}
        downloadUrl={downloadUrl}
        setDocumentItem={setDocumentItem}
        source="invoice-dailog"
        docCategoryId={docCategoryId}
        docNameForDownload={docNameForDownload}
        docUserId={docUserId}
      />
      <Elements stripe={stripePromise}>
        <Dialog
          fullWidth
          open={openViewInvoiceDialog}
          onClose={() => {
            setOpenViewInvoiceDialog(false);
          }}
        >
          <DialogTitle
            className={Styles.navyBlueColor}
            style={{ padding: "12px 24px" }}
          >
            <Typography variant="subtitle1">Invoice</Typography>
          </DialogTitle>
          <Divider />
          <DialogContent style={{ marginTop: 8 }}>
            <Grid
              container
              style={{
                backgroundColor: "#0E1C4E0A",
                padding: "20px 10px 70px 10px",
                borderRadius: 4,
              }}
            >
              <Grid item container xs={12}>
                <Grid item xs={8}>
                  <Typography className={clsx(Styles.grayText, Styles.font14)}>
                    Invoice For
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={clsx(Styles.grayText, Styles.font14)}>
                    Total Amount Due
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} style={{ marginTop: 20 }}>
                <Grid item xs={8}>
                  <Typography variant="h4" className={Styles.navyBlueColor}>
                    {userInfo.firstName} {userInfo.lastName}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h4" className={Styles.navyBlueColor}>
                    ${viewInvoiceDialogData && viewInvoiceDialogData.amount}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <Typography className={clsx(Styles.grayText, Styles.font14)}>
                Comments
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={Styles.navyBlueColor}>
                {viewInvoiceDialogData && viewInvoiceDialogData.comments}
              </Typography>
            </Grid>
            {viewInvoiceDialogData &&
            viewInvoiceDialogData.invoiceDocumentUrls.length ? (
              <React.Fragment>
                <Grid container>
                  <Grid item xs={12} style={{ marginTop: 20 }}>
                    <Typography
                      className={clsx(Styles.grayText, Styles.font14)}
                    >
                      Attachments
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {viewInvoiceDialogData &&
                    viewInvoiceDialogData.invoiceDocumentUrls.length &&
                    viewInvoiceDialogData.invoiceDocumentUrls.map(
                      (doc, index) => (
                        <Chip
                          item
                          key={index}
                          style={{
                            fontSize: 14,
                            marginTop: 10,
                            marginRight: 5,
                            textOverflow: "ellipsis",
                            marginLeft: 5,
                          }}
                          label={doc.documentName}
                          onClick={() => {
                            setDocumentItem(getUploadedDocuments(doc));
                            setDownloadUrl(doc.documentUrl);
                            setOpenDocumetDialog(true);
                            setDocCategoryId(doc.categoryId);
                            setDocNameForDownload(doc.fileName);
                            setDocUserId(doc.userId);
                          }}
                        />
                      )
                    )}
                </Grid>
              </React.Fragment>
            ) : null}
            {showCardPaymentDetails ? (
              <Grid container style={{ marginTop: 20 }}>
                <CollectCardDetails
                  clientSecret={
                    viewInvoiceDialogData && viewInvoiceDialogData.clientSecret
                  }
                  updateStatusOfInvoice={updateStatusOfInvoice}
                  invoiceId={viewInvoiceDialogData && viewInvoiceDialogData.id}
                  setOpenViewInvoiceDialog={setOpenViewInvoiceDialog}
                />
              </Grid>
            ) : null}
          </DialogContent>
          <Divider />
          <DialogActions style={{ padding: "16px 24px" }}>
            <Button
              variant="outlined"
              style={{
                padding: "6px 16px",
                textTransform: "capitalize",
              }}
              className={Styles.navyBlueColorForce}
              onClick={() => {
                setOpenViewInvoiceDialog(false);
                setShowCardPaymentDetails(false);
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={showCardPaymentDetails}
              variant="contained"
              className={Styles.primaryBtnAccent}
              style={{ textTransform: "capitalize", padding: "6px 32px" }}
              onClick={() => {
                // setOpenViewInvoiceDialog(false);
                if (viewInvoiceDialogData && viewInvoiceDialogData.clientSecret)
                  setShowCardPaymentDetails(true);
              }}
            >
              Pay Invoice
            </Button>
          </DialogActions>
        </Dialog>
      </Elements>
    </React.Fragment>
  );
}
