import React, { useEffect } from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import {
  Typography,
  IconButton,
  Dialog,
  Grid,
  makeStyles,
} from "@material-ui/core/";
import Styles from "../../../Styles.module.css";
import { useSPUploadWizard } from "souqh-react-redux-hooks/serviceProvider/documents/useSPUploadWizard";
import SPUploadStepsHeader from "./SPUploadStepsHeader";
import UploadCancelDialog from "../../HomeBuyer/UploadDocuments/UploadCancelDialog";
import SPUploadStep1 from "./SPUploadStep1";
import UploadActionBtns from "./UploadActionBtns";
import SPSelectContactsToUpload from "./SPSelectContactsToUpload";
import ReviewAndSendDocuments from "./ReviewAndSendDocuments";
import { HOME_BUYER_USER } from "../../ServiceProvider/serviceProviderConstants";

const styles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    padding: 0,
  },
  dialogPaper: {
    height: window.innerHeight,
    borderRadius: 0,
  },
  diaogFooter: {
    padding: "12px 36px",
  },
  dialogRoot: {
    padding: 0,
  },
}));

const DialogTitle = (props) => {
  const { children, classes, setOpenUploadCancelDialog, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="subtitle1">{children}</Typography>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={() => {
          setOpenUploadCancelDialog(true);
        }}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
};

export default function SPUploadWizard({
  openUploadWizard,
  setOpenUploadWizard,
  categories,
  updateIntercomStatus,
  getTabWiseCategoriesList,
  setDocumentTab,
  deletePendingAction,
  pendingActionId,
  refreshSPDocuments,
}) {
  const classes = styles();
  const {
    actions: {
      cleanUploadFileDataOnClose,
      setSelectedContacts,
      deleteDocument,
      updateDescription,
      setActiveCustomers,
    },
    userType,
    searchText,
    setSearchText,
    tabs,
    activeTab,
    setActiveTab,
    getContacts,
    contacts,
    setCustomContacts,
    customContacts,
    addItem,
    handleCustomerSelect,
    selectedActiveCustomers,
    selectedContacts,
    getActiveCustomers,
    documentList,
    activeSection,
    setActiveSection,
    uploadFiles,
    checkForDocumentDescriptions,
    duplicateFileName,
    setOpenUploadCancelDialog,
    openUploadCancelDialog,
    selectAllCustomers,
    setSelectAllCustomers,
    activeCustomers,
    getFilteredCustomers,
    setSelectedActiveCustomers,
    removeFromOtherContacts,
    setUserType,
    setTabs,
    refreshSelectedActiveCustomers,
    userInfo,
    onUploadFileChange,
    processUploadedFiles,
    hasLoadedList,
  } = useSPUploadWizard();

  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }

    cleanUploadFileDataOnClose();
    setActiveSection(1);
    setSearchText("");
    setCustomContacts("");
    setSelectAllCustomers(false);
    setSelectedActiveCustomers([]);
    setActiveTab(0);
  };

  useEffect(() => {
    updateIntercomStatus && updateIntercomStatus(openUploadWizard);
  }, [openUploadWizard]);

  useEffect(() => {
    if (userInfo) {
      setUserType(userInfo.userType);
    } else {
      setUserType("");
    }
  }, [userInfo]);

  useEffect(() => {
    if (userType === HOME_BUYER_USER) {
      setTabs(["Active Service Providers", "Other Contacts"]);
    } else {
      setTabs(["Active Clients", "Other Contacts"]);
    }
  }, [userType]);

  useEffect(() => {
    if (openUploadWizard) {
      getContacts();
      getActiveCustomers();
    }
  }, [openUploadWizard]);

  let section = activeSection;
  return (
    <React.Fragment>
      <UploadCancelDialog
        setOpenUploadCancelDialog={setOpenUploadCancelDialog}
        openUploadCancelDialog={openUploadCancelDialog}
        onClose={handleClose}
      />
      <Dialog
        fullScreen
        maxWidth={"lg"}
        onClose={handleClose}
        open={openUploadWizard}
        classes={{ paper: classes.dialogPaper, root: classes.dialogRoot }}
      >
        <DialogTitle
          classes={classes}
          className={Styles.navyBlueColor}
          setOpenUploadCancelDialog={setOpenUploadCancelDialog}
        >
          Upload Document
        </DialogTitle>
        <MuiDialogContent dividers className={classes.dialogContent}>
          <Grid container style={{ height: "100%" }}>
            <SPUploadStepsHeader activeSection={activeSection} />
            <Grid
              item
              xs={12}
              style={{ height: "calc(100% - 60px)", overflow: "hidden" }}
            >
              {activeSection === 1 && (
                <SPUploadStep1
                  documentList={documentList}
                  deleteDocument={deleteDocument}
                  updateDescription={updateDescription}
                  onUploadFileChange={onUploadFileChange}
                  processUploadedFiles={processUploadedFiles}
                />
              )}
              {activeSection === 2 && (
                <SPSelectContactsToUpload
                  searchText={searchText}
                  setSearchText={setSearchText}
                  tabs={tabs}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  getContacts={getContacts}
                  contacts={contacts}
                  setCustomContacts={setCustomContacts}
                  customContacts={customContacts}
                  addItem={addItem}
                  handleCustomerSelect={handleCustomerSelect}
                  selectedActiveCustomers={selectedActiveCustomers}
                  selectedContacts={selectedContacts}
                  setSelectedContacts={setSelectedContacts}
                  selectAllCustomers={selectAllCustomers}
                  setSelectAllCustomers={setSelectAllCustomers}
                  getFilteredCustomers={getFilteredCustomers}
                  userType={userType}
                  activeCustomers={activeCustomers}
                  hasLoadedList={hasLoadedList}
                  setActiveCustomers={setActiveCustomers}
                  refreshSelectedActiveCustomers={
                    refreshSelectedActiveCustomers
                  }
                  removeFromOtherContacts={removeFromOtherContacts}
                />
              )}
              {activeSection === 3 && (
                <ReviewAndSendDocuments
                  documentList={documentList}
                  deleteDocument={deleteDocument}
                  selectedActiveCustomers={selectedActiveCustomers}
                  handleCustomerSelect={handleCustomerSelect}
                  selectedContacts={selectedContacts}
                  removeFromOtherContacts={removeFromOtherContacts}
                />
              )}
            </Grid>
          </Grid>
        </MuiDialogContent>
        <MuiDialogActions className={classes.diaogFooter}>
          <UploadActionBtns
            duplicateFileName={duplicateFileName}
            activeSection={activeSection}
            documentList={documentList}
            setActiveSection={setActiveSection}
            setOpenUploadWizard={setOpenUploadWizard}
            checkForDocumentDescriptions={checkForDocumentDescriptions}
            section={section}
            uploadFiles={uploadFiles}
            handleClose={handleClose}
            selectedActiveCustomers={selectedActiveCustomers}
            selectedContacts={selectedContacts}
            setOpenUploadCancelDialog={setOpenUploadCancelDialog}
            getTabWiseCategoriesList={getTabWiseCategoriesList}
            setDocumentTab={setDocumentTab}
            deletePendingAction={deletePendingAction}
            pendingActionId={pendingActionId}
            refreshSPDocuments={refreshSPDocuments}
          ></UploadActionBtns>
        </MuiDialogActions>
      </Dialog>
    </React.Fragment>
  );
}
