import { useState, useRef } from "react";
import { useAuthUser } from "../useLogin";
import { useDispatch, useSelector } from "react-redux";
import { useActions } from "../useActions";
import moment from "moment";
import { useApiClient } from "../useApiClient";
import { cloneDeep } from "lodash";
import {
  CLEAR_CALENDAR_DATA,
  GET_ALL_CONTACTS_TO_MEETING,
  SET_CALENDAR_EVENTS,
  SET_MEETING_MODE,
  SET_OPEN_CANCEL_MEETING_DIALOG,
  SET_OPEN_CREATE_MEETING_DIALOG,
  SET_SELECTED_CALENDAR_EVENT,
} from "../../souqh-redux/reducers/actionConstants";
import {
  MEETING_CREATE_MODE,
  MEETING_READ_MODE,
} from "../../web/src/components/ServiceProvider/serviceProviderConstants";
import {
  getTimeAndHourFromSlot,
  getTimeSlotFromDate,
  processDownloadFileResponse,
} from "../../web/src/utils/AppUtils";
import { useSQQuery } from "./useSQQuery";
import { color_error } from "../toasterColors";

const actionCreator = {
  setCalendarEvents: (calendarEvents) => ({
    type: SET_CALENDAR_EVENTS,
    payload: { calendarEvents },
  }),
  setOpenCreateMeetingDialog: (openCreateMeetingDialog) => ({
    type: SET_OPEN_CREATE_MEETING_DIALOG,
    payload: { openCreateMeetingDialog },
  }),
  setMeetingMode: (meetingMode) => ({
    type: SET_MEETING_MODE,
    payload: { meetingMode },
  }),
  setSelectedCalendarEvent: (selectedCalendarEvent) => ({
    type: SET_SELECTED_CALENDAR_EVENT,
    payload: { selectedCalendarEvent },
  }),
  setAllContacts: (allContacts) => ({
    type: GET_ALL_CONTACTS_TO_MEETING,
    payload: { allContacts },
  }),
  setOpenConfirmCanelMeetingDialog: (openConfirmCanelMeetingDialog) => ({
    type: SET_OPEN_CANCEL_MEETING_DIALOG,
    payload: { openConfirmCanelMeetingDialog },
  }),
  clearCalendarData: () => ({
    type: CLEAR_CALENDAR_DATA,
    payload: {},
  }),
};

export const useSQCalendar = ({
  calendarListUpdater,
  upcomingEventsUpdater,
}) => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();
  const actions = useActions(actionCreator);
  const userInfo = useAuthUser();
  const { query } = useSQQuery();

  // let startDate = moment(new Date()).date(0).toDate();
  // let startDateTS = moment(startDate).unix();
  // let endDate = moment(new Date()).add(1, "M").startOf("month").toDate();
  // let endDateTS = moment(endDate).unix();

  const [currentCalendarDate, setCurrentCalendarDate] = useState(new Date());
  const [mainCalendarView, setMainCalendarView] = useState("week");
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [startDateTimestamp, setStartDateTimestamp] = useState(null);
  const [endDateTimestamp, setEndDateTimestamp] = useState(null);
  const [allCalendarEvents, setAllCalendarEvents] = useState([]);

  const [openSendMeetingInviteDialog, setOpenSendMeetingInviteDialog] =
    useState(false);
  const calendarEvents = useSelector(
    (state) => state.sqCalendar.calendarEvents
  );
  const selectedCalendarEvent = useSelector(
    (state) => state.sqCalendar.selectedCalendarEvent
  );

  const openConfirmCanelMeetingDialog = useSelector(
    (state) => state.sqCalendar.openConfirmCanelMeetingDialog
  );

  const meetingMode = useSelector((state) => state.sqCalendar.meetingMode);

  const [title, setTitle] = useState("");
  const [meetingDate, setMeetingDate] = useState(null);
  const [meetingStartTime, setMeetingStartTime] = useState("");
  const [meetingEndTime, setMeetingEndTime] = useState("");
  const [meetingLink, setMeetingLink] = useState("");
  const [meetingPhoneNumber, setMeetingPhoneNumber] = useState("");
  const [meetingLocation, setMeetingLocation] = useState("");
  const [description, setDescription] = useState("");
  const [meetingAttachments, setMeetingAttachments] = useState([]);
  const [invalidTimes, setInvalidTimes] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [nylasEventId, setNylasEventId] = useState(null);
  const [eventUserId, setEventUserId] = useState(null);
  const [eventType, setEventType] = useState(null);
  const [isFullDayEvent, setIsFullDayEvent] = useState(false);
  const [openInstantMeetingDailog, setOpenInstantMeetingDailog] =
    useState(false);
  const [moderatorMeetingLink, setModeratorMeetingLink] = useState("");

  const [addMeetingLink, setAddMeetingLink] = useState(false);
  const uploadRef = useRef(null);
  const textAreaRef = useRef(null);
  const moderatorTextAreaRef = useRef(null);

  const contactInputRef = useRef(null);

  const [customContact, setCustomContact] = useState("");

  const [selectedContacts, setSelectedContacts] = useState([]);

  const allContacts = useSelector((state) => state.sqCalendar.allContacts);
  const openCreateMeetingDialog = useSelector(
    (state) => state.sqCalendar.openCreateMeetingDialog
  );

  const [filesDeleted, setFilesDeleted] = useState([]);

  const processAttachment = (event) => {
    const files = event.target.files;
    const tempAttachments = cloneDeep(meetingAttachments);
    if (files) {
      Object.keys(files).forEach((key) => {
        if (files[key].size / 1024 / 1024 < 50) {
          tempAttachments.push({
            fileName: files[key].name,
            file: files[key],
          });
        }
      });
    }
    setMeetingAttachments(tempAttachments);
    // let newFilesArray = [];
    // Object.keys(files).forEach((key) => {
    //   if (files[key].size / 1024 / 1024 < 50) {
    //     newFilesArray.push({
    //       fileName: files[key].name,
    //       file: files[key],
    //     });
    //   }
    // });
    // const finalArray = [...meetingAttachments, ...newFilesArray];
    // const valueArr = finalArray.map(function (item) {
    //   return item.fileName;
    // });
    // const isDuplicate = valueArr.some((item, index) => {
    //   return valueArr.indexOf(item) != index;
    // });
    // if (!isDuplicate) {
    //   setMeetingAttachments(finalArray);
    // }
  };

  const removeAttachment = (item, index) => {
    const tempList = cloneDeep(meetingAttachments);
    const tempFilesDeleted = cloneDeep(filesDeleted);
    if (item.id) {
      tempFilesDeleted.push({ id: item.id });
      setFilesDeleted(tempFilesDeleted);
    }
    tempList.splice(index, 1);
    setMeetingAttachments(tempList);
  };

  const copyToClipboard = (e) => {
    if (textAreaRef && textAreaRef.current) {
      textAreaRef.current.select();
      document.execCommand("copy");
    }
  };

  const addCustomItem = (event, contactInputRef) => {
    const value = event.target.value;
    if (event.charCode === 13) {
      setCustomContact("");
      const valid =
        /(^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)|(^$)/i.test(
          value
        );
      if (value && valid) {
        let data = cloneDeep(selectedContacts);
        data.push({ email: value, fullName: "" });
        setSelectedContacts(data);
      }
      if (contactInputRef && contactInputRef.current) {
        contactInputRef.current.blur();
        contactInputRef.current.focus();
      }
    }
  };

  const addItemOnSelect = (value) => {
    if (value) {
      if (!selectedContacts.find((item) => item.email === value.email)) {
        let data = cloneDeep(selectedContacts);
        data.push(cloneDeep(value));
        setSelectedContacts(data);
      }
      setCustomContact("");
    }
  };

  const removeSelectedContact = (contact) => {
    let data = cloneDeep(selectedContacts);
    setSelectedContacts(data.filter((item) => item.email !== contact.email));
  };

  const validateTimeSlot = () => {
    const startTime = getTimeAndHourFromSlot(meetingStartTime);
    const endTime = getTimeAndHourFromSlot(meetingEndTime);
    const startDate = new Date();
    startDate.setHours(startTime.hours);
    startDate.setMinutes(startTime.minutes);
    const endDate = new Date();
    endDate.setHours(endTime.hours);
    endDate.setMinutes(endTime.minutes);
    const flag = startDate >= endDate;
    setInvalidTimes(flag);
    return flag;
  };

  const hasFilledMandatoryData = () => {
    if (!(title || "").trim() || !meetingDate || !selectedContacts.length) {
      return false;
    }
    if (!isFullDayEvent) {
      if (!meetingStartTime || !meetingEndTime) {
        return false;
      }
    }
    if (validateTimeSlot()) {
      return false;
    }
    return true;
  };

  const removeMeetingLink = () => {
    setMeetingLink("");
    setMeetingPhoneNumber("");
  };

  const getAllContacts = () => {
    apiClient
      .get(`/contacts/${userInfo.userId}`)
      .then((response) => {
        actions.setAllContacts(response.data.result || []);
        response &&
          response.data &&
          response.data.result &&
          setFilteredContacts(
            response.data.result.filter((item) => item.email !== userInfo.email)
          );
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const setupEmptyData = () => {
    setEventId(null);
    setNylasEventId(null);
    setEventType(null);
    setTitle("");
    setMeetingDate(null);
    setMeetingStartTime("");
    setMeetingEndTime("");
    setMeetingLink("");
    setMeetingPhoneNumber("");
    setMeetingLocation("");
    setDescription("");
    setSelectedContacts([]);
    setMeetingAttachments([]);
    setInvalidTimes(false);
    setIsFullDayEvent(false);
    setFilesDeleted([]);
  };

  const handleDialogClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    actions.setMeetingMode(MEETING_READ_MODE);
    actions.setOpenConfirmCanelMeetingDialog(false);
    actions.setSelectedCalendarEvent(null);
    setupEmptyData();
    actions.setOpenCreateMeetingDialog(false);
  };

  const getFullCalendarEvents = () => {
    const url = `/calendar/event/user/${userInfo.userId}`;
    apiClient
      .get(url)
      .then((response) => {
        if (response.data.status === 200) {
          actions.setCalendarEvents(
            mapEventsDataForCalendar(response.data.result || [])
          );
        } else {
          dispatch({
            type: "SET_SERVER_ERROR",
            error: { message: "Something went wrong" },
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getCalendarEvents = (commandFlag) => {
    const url = `/calendar/dashboard-events/user/${userInfo.userId}`;
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .get(url)
      .then((response) => {
        if (response.data.status === 200) {
          let dummyEvents = [
            {
              id: null,
              nylasEventId: "6mdqvhug317qbto3cc12mir54",
              userId: 1,
              title: "Test Nylas",
              fromDate: "2022-04-18 10:00:00 UTC",
              toDate: "2022-04-18 11:00:00 UTC",
              attendees: [],
              meetingInfo: {
                meetingId: null,
                meetingUrl: null,
                meetingRegion: null,
                phoneNo: null,
                pin: null,
              },
              location: null,
              description: null,
              eventType: null,
              eventAttachments: [],
              isFullDayEvent: null,
              moderatorURL: null,
              participantURL: null,
              isSouqhEvent: false,
            },
            {
              id: null,
              nylasEventId: "b3qh4ajbguybcs0he9cc48182",
              userId: 1,
              title: "Souqh Session",
              fromDate: "2022-05-04 16:00:00 UTC",
              toDate: "2022-05-04 17:00:00 UTC",
              attendees: [
                {
                  fullName: "Snehal Tembare",
                  email: "punamg075@gmail.com",
                  inviteStatus: null,
                },
                {
                  fullName: "Member One",
                  email: "memberone.souqh@gmail.com",
                  inviteStatus: null,
                },
              ],
              meetingInfo: {
                meetingId: null,
                meetingUrl: null,
                meetingRegion: null,
                phoneNo: null,
                pin: null,
              },
              location: "Pune",
              description: "souqh session",
              eventType: null,
              eventAttachments: [],
              isFullDayEvent: null,
              moderatorURL: null,
              participantURL: null,
              isSouqhEvent: true,
            },
            {
              id: null,
              nylasEventId: "151dqzsqrbhs96vlxzfycs2f9",
              userId: 1,
              title: "Souqh Conference",
              fromDate: "2022-05-04 12:00:00 UTC",
              toDate: "2022-05-04 13:00:00 UTC",
              attendees: [
                {
                  fullName: "Snehal Tembare",
                  email: "punamg075@gmail.com",
                  inviteStatus: null,
                },
                {
                  fullName: "Member One",
                  email: "memberone.souqh@gmail.com",
                  inviteStatus: null,
                },
              ],
              meetingInfo: {
                meetingId: null,
                meetingUrl: null,
                meetingRegion: null,
                phoneNo: null,
                pin: null,
              },
              location: "conference",
              description:
                "souqh conference\nhttps://souqh.yourvideo.live/host/NjI3MjVhNzMwMDliNGIxYWJmNTc5MjkxLTYxOTY0NDc3MDJkZjMwMTgyNzYxYjdjNQ==?name=punam.gaikwad702@gmail.com&user_ref=punam.gaikwad702@gmail.com&video=true&audio=true&screenshare=true&group_chat=false&username=yes&whiteboard=yes&pvt_chat=false&landing=no",
              eventType: null,
              eventAttachments: [],
              isFullDayEvent: null,
              moderatorURL:
                "https://souqh.yourvideo.live/host/NjI3MjVhNzMwMDliNGIxYWJmNTc5MjkxLTYxOTY0NDc3MDJkZjMwMTgyNzYxYjdjNQ==?name=punam.gaikwad702@gmail.com&user_ref=punam.gaikwad702@gmail.com&video=true&audio=true&screenshare=true&group_chat=false&username=yes&whiteboard=yes&pvt_chat=false&landing=no",
              participantURL:
                "https://souqh.yourvideo.live/62725a73009b4b1abf579291",
              isSouqhEvent: true,
            },
          ];
          actions.setCalendarEvents(
            // mapEventsDataForCalendar(dummyEvents || [])
            mapEventsDataForCalendar(response.data.result || [])
          );
          if (commandFlag) {
            const scheduleMeeting = query.get("scheduleMeeting");
            if (scheduleMeeting) {
              actions.setMeetingMode(MEETING_CREATE_MODE);
              actions.setOpenCreateMeetingDialog(true);
            }
            const openDate = query.get("openDate");
            if (openDate) {
              setCurrentCalendarDate(new Date(openDate));
            }
          }
        } else {
          dispatch({
            type: "SET_SERVER_ERROR",
            error: { message: "Something went wrong" },
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getAllCalendarEvents = (commandFlag) => {
    if (startDateTimestamp && endDateTimestamp) {
      const url = `/calendar/event/all`;
      dispatch({ type: "SET_LOADING", value: true });
      apiClient
        .get(url, {
          params: {
            endBeforeTimestamp: endDateTimestamp,
            startAfterTimestamp: startDateTimestamp,
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            setAllCalendarEvents(response.data.result);
            actions.setCalendarEvents(
              mapEventsDataForCalendar(response.data.result || [])
            );
            if (commandFlag) {
              const scheduleMeeting = query.get("scheduleMeeting");
              if (scheduleMeeting) {
                actions.setMeetingMode(MEETING_CREATE_MODE);
                actions.setOpenCreateMeetingDialog(true);
              }
              const openDate = query.get("openDate");
              if (openDate) {
                setCurrentCalendarDate(new Date(openDate));
              }
            }
          } else {
            dispatch({
              type: "SET_SERVER_ERROR",
              error: { message: "Something went wrong" },
            });
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const findEventInList = (calEvent) => {
    if (allCalendarEvents && allCalendarEvents.length) {
      let foundItem;
      if (calEvent.resource.id) {
        foundItem = allCalendarEvents.find(
          (o) => o.id === calEvent.resource.id
        );
      } else if (calEvent.resource.nylasEventId) {
        foundItem = allCalendarEvents.find(
          (o) => o.nylasEventId === calEvent.resource.nylasEventId
        );
      }
      if (foundItem) {
        setMeetingData(foundItem);
        actions.setMeetingMode(MEETING_READ_MODE);
        actions.setOpenCreateMeetingDialog(true);
      }
    }
  };

  const mapEventsDataForCalendar = (data) => {
    if (data && data.length) {
      const mappedData = [];
      data.forEach((item) => {
        const newItem = {};
        newItem.start = item.isFullDayEvent
          ? moment(new Date(item.eventDate || "")).toDate()
          : moment(new Date(item.startDateTime || "")).toDate();
        newItem.end = item.isFullDayEvent
          ? moment(new Date(item.eventDate || "")).toDate()
          : moment(new Date(item.endDateTime || "")).toDate();
        newItem.title = item.title;
        newItem.allDay = item.isFullDayEvent || false;
        newItem.resource = item;
        mappedData.push(newItem);
      });
      return mappedData;
    }
    return [];
  };

  const downlodAttachment = (item) => {
    if (item && item.link) {
      const link = document.createElement("a");
      link.href = item.link;
      link.target = "_blank";
      link.setAttribute("download", item.fileName);
      document.body.appendChild(link);
      link.click();
    }
  };

  const getCalendarEventDetails = (eventId, callBack) => {
    if (eventId) {
      const url = `/calendar/event/${eventId}`;
      dispatch({ type: "SET_LOADING", value: true });
      apiClient
        .get(url)
        .then((response) => {
          if (response.data.status === 200) {
            callBack && callBack(response.data.result);
          } else {
            dispatch({
              type: "SET_SERVER_ERROR",
              error: { message: "Something went wrong" },
            });
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function (error) {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const downloadCalendarAttachment = (attachmentLinkKey) => {
    const url = `/calendar/attachment/download/${attachmentLinkKey}`;

    apiClient
      .get(url, { responseType: "blob" })
      .then((response) => {
        processDownloadFileResponse(response);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const setMeetingData = (data) => {
    if (data) {
      setEventUserId(data.userId);
      setEventId(data.id);
      setNylasEventId(data.nylasEventId);
      setEventType(data.type);
      setTitle(data.title || "");
      let date = data.isFullDayEvent ? data.eventDate : data.startDateTime;
      const tempDate = moment(new Date(date || "")).toDate();
      setMeetingDate(tempDate);
      const startTime = getTimeSlotFromDate(tempDate);
      const endTime = getTimeSlotFromDate(
        moment(new Date(data.endDateTime || "")).toDate()
      );
      setIsFullDayEvent(data.isFullDayEvent);
      setMeetingStartTime(startTime);
      setMeetingEndTime(endTime);
      setMeetingLocation(data.location || "");
      setDescription(data.description || "");
      setSelectedContacts(data.attendees || []);
      setMeetingAttachments(data.eventAttachments || []);
      // const isOwner = data.userId === userInfo.userId ? true : false;
      let isAttendee = data.attendees.find((o) => o.email === userInfo.email);
      setMeetingLink(isAttendee ? data.participantURL : data.moderatorURL);
      // setMeetingLink(isOwner ? data.moderatorURL : data.participantURL);
    }
  };

  const saveMeeting = () => {
    if (!hasFilledMandatoryData()) {
      return;
    }
    const payload = {};
    payload.userId = userInfo.userId;
    if (eventId || nylasEventId) {
      payload.souqhAppEventId = eventId || null;
      payload.nylasEventId = nylasEventId || null;
    }
    payload.attendees = selectedContacts.map((item) => {
      return {
        email: item.email,
        fullName: item.fullName,
        inviteStatus: item.inviteStatus || null,
      };
    });
    payload.title = title;
    if (meetingDate) {
      const startTime = getTimeAndHourFromSlot(meetingStartTime);
      const endTime = getTimeAndHourFromSlot(meetingEndTime);
      const startDate = new Date(meetingDate.toGMTString());
      startDate.setHours(startTime.hours);
      startDate.setMinutes(startTime.minutes);
      const endDate = new Date(meetingDate.toGMTString());
      endDate.setHours(endTime.hours);
      endDate.setMinutes(endTime.minutes);

      // payload.fromDate = startDate.toGMTString();
      // payload.toDate = endDate.toGMTString();
      payload.fromDate = isFullDayEvent
        ? meetingDate.toGMTString()
        : startDate.toGMTString();
      payload.toDate = isFullDayEvent
        ? meetingDate.toGMTString()
        : endDate.toGMTString();
    } else {
      payload.fromDate = "";
      payload.toDate = "";
    }
    payload.isFullDayEvent = isFullDayEvent;
    payload.location = meetingLocation || "";
    payload.description = description || "";
    payload.meetingInfo = {
      meetingId: "",
      meetingRegion: "",
      meetingUrl: "",
      phoneNo: "",
      pin: "",
    };
    if (!eventId && !nylasEventId) {
      payload.attachments = meetingAttachments.map((item) => {
        return {
          fileName: item.fileName,
        };
      });
    }
    payload.isConference = addMeetingLink;
    const pastTime = new Date(payload.fromDate).getTime() < Date.now();

    const formData = new FormData();
    meetingAttachments.forEach((item) => {
      if (item && item.file) {
        formData.append("attachments", item.file, item.fileName);
      }
    });
    if (eventId || nylasEventId) {
      const filesAdded = [];
      meetingAttachments.forEach((item) => {
        if (item && item.file) {
          filesAdded.push({
            fileName: item.fileName,
          });
        }
      });
      payload.filesAdded = filesAdded;
      payload.filesDeleted = filesDeleted;
    }

    formData.append("event", JSON.stringify(payload));
    formData.append("userId", userInfo.userId);
    if (pastTime) {
      dispatch({
        type: "SET_TOASTER",
        payload: {
          message: `Start time should be greater than current time`,
          bgColor: color_error,
          open: true,
          severity: "error",
        },
      });
    } else {
      dispatch({ type: "SET_LOADING", value: true });
      if (payload.souqhAppEventId || payload.nylasEventId) {
        modifyMeeting(formData);
      } else {
        createMeeting(formData);
      }
    }
  };

  const createMeeting = (payload) => {
    if (payload) {
      apiClient
        .post("/calendar/event", payload)
        .then((response) => {
          if (response.data.status === 200) {
            if (calendarListUpdater) {
              // getCalendarEvents();
              getAllCalendarEvents();
            }
            upcomingEventsUpdater && upcomingEventsUpdater();
            handleDialogClose();
          } else {
            dispatch({
              type: "SET_SERVER_ERROR",
              error: { message: "Something went wrong" },
            });
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function (error) {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const modifyMeeting = (payload) => {
    if (payload) {
      apiClient
        .put(`/calendar/event`, payload)
        .then((response) => {
          if (response.data.status === 200) {
            if (calendarListUpdater) {
              // getCalendarEvents();
              getAllCalendarEvents();
            }
            upcomingEventsUpdater && upcomingEventsUpdater();
            handleDialogClose();
          } else {
            dispatch({
              type: "SET_SERVER_ERROR",
              error: { message: "Something went wrong" },
            });
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function (error) {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const sendMeetingInvite = (callback) => {
    callback && callback();
  };

  const cancelMeeting = (callback) => {
    if (eventId || nylasEventId) {
      // const url = `/calendar/event/${eventId}/user/${userInfo.userId}`;
      const url = `/calendar/event/user/${userInfo.userId}`;
      dispatch({ type: "SET_LOADING", value: true });
      apiClient
        .delete(url, {
          params: { appEventId: eventId, nylasEventId: nylasEventId },
        })
        .then((response) => {
          if (response.data.status === 200) {
            if (calendarListUpdater) {
              // getCalendarEvents();
              getAllCalendarEvents();
            }
            upcomingEventsUpdater && upcomingEventsUpdater();
            handleDialogClose();
          } else {
            dispatch({
              type: "SET_SERVER_ERROR",
              error: { message: "Something went wrong" },
            });
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function (error) {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const updateMeetingStatus = (status) => {
    if (eventId) {
      const payload = {
        attendeeEmail: userInfo.email,
        eventId: eventId,
        status,
      };
      const url = `/calendar/event/update/invite-status`;
      dispatch({ type: "SET_LOADING", value: true });
      apiClient
        .put(url, payload)
        .then((response) => {
          if (response.data.status === 200) {
            handleDialogClose();
            if (status === "false") {
              if (calendarListUpdater) {
                // getCalendarEvents();
                getAllCalendarEvents();
              }
              upcomingEventsUpdater && upcomingEventsUpdater();
              handleDialogClose();
            }
          } else {
            dispatch({
              type: "SET_SERVER_ERROR",
              error: { message: "Something went wrong" },
            });
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function (error) {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const createInstantMeeting = (callback) => {
    callback && callback();
  };

  const clearCalendarDataDestroy = () => {
    setCurrentCalendarDate(new Date());
    setMainCalendarView("week");
    setFilteredContacts([]);
    actions.clearCalendarData();
    setOpenSendMeetingInviteDialog(false);
  };

  return {
    actions,
    currentCalendarDate,
    setCurrentCalendarDate,
    calendarEvents,
    mainCalendarView,
    setMainCalendarView,
    openCreateMeetingDialog,
    userInfo,
    getFullCalendarEvents,
    createInstantMeeting,
    meetingMode,
    selectedCalendarEvent,
    openConfirmCanelMeetingDialog,
    cancelMeeting,
    title,
    setTitle,
    meetingDate,
    setMeetingDate,
    meetingLink,
    setMeetingLink,
    meetingPhoneNumber,
    setMeetingPhoneNumber,
    meetingLocation,
    setMeetingLocation,
    meetingAttachments,
    setMeetingAttachments,
    description,
    setDescription,
    uploadRef,
    textAreaRef,
    contactInputRef,
    handleDialogClose,
    processAttachment,
    removeAttachment,
    copyToClipboard,
    meetingStartTime,
    setMeetingStartTime,
    meetingEndTime,
    setMeetingEndTime,
    addCustomItem,
    selectedContacts,
    customContact,
    setCustomContact,
    allContacts,
    removeSelectedContact,
    addItemOnSelect,
    getAllContacts,
    invalidTimes,
    setInvalidTimes,
    setMeetingData,
    removeMeetingLink,
    addMeetingLink,
    setAddMeetingLink,
    setupEmptyData,
    saveMeeting,
    validateTimeSlot,
    openSendMeetingInviteDialog,
    setOpenSendMeetingInviteDialog,
    sendMeetingInvite,
    setSelectedContacts,
    eventId,
    setEventId,
    eventType,
    setEventType,
    isFullDayEvent,
    setIsFullDayEvent,
    getCalendarEvents,
    getCalendarEventDetails,
    eventUserId,
    setEventUserId,
    hasFilledMandatoryData,
    updateMeetingStatus,
    downlodAttachment,
    clearCalendarDataDestroy,
    filteredContacts,
    downloadCalendarAttachment,
    openInstantMeetingDailog,
    setOpenInstantMeetingDailog,
    moderatorTextAreaRef,
    moderatorMeetingLink,
    setModeratorMeetingLink,
    getAllCalendarEvents,
    setStartDateTimestamp,
    setEndDateTimestamp,
    startDateTimestamp,
    endDateTimestamp,
    allCalendarEvents,
    findEventInList,
  };
};
