import { Grid, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import MessageIcon from "@material-ui/icons/Message";
import DateRangeIcon from "@material-ui/icons/DateRange";
import CallIcon from "@material-ui/icons/Call";
import RoomIcon from "@material-ui/icons/Room";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { useTheme } from "souqh-react-redux-hooks/serviceProvider/storefront/useTheme";
import AddressDialog from "./AddressDialog";
import { useNavigateToMessage } from "souqh-react-redux-hooks/common/useNavigateToMessage";
import { useHistory } from "react-router-dom";
import { HOME_BUYER_USER } from "../../../ServiceProvider/serviceProviderConstants";
import ShareIcon from "@material-ui/icons/Share";
import MoreTrafficDialog from "../../../ServiceProvider/Dashboard/MoreTrafficDialog";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {
  formatContactNumber,
  isMarketplacePlan,
  isMobileDevice,
} from "../../../../utils/AppUtils";
import useLocalStorage from "react-use-localstorage";
import Styles from "../../../../Styles.module.css";
import PlanUpgradeMsgDialog from "../../../common/PlanUpgradeMsgDialog";
import ScheduledPagesDialog from "../../../common/SQCalender/ScheduledPagesDialog";
import SignupForFreePopup from "../SignupForFreePopup";

function StorefrontIcons(props) {
  let {
    showLimitedView,
    email,
    user,
    setWebPresenceDrawer,
    storeFrontRouteName,
    storeFrontId,
    setUserEngagementData,
    increaseLeadCount,
    contactNumber,
    calSyncAccessToken,
    pageRoute,
    storeFrontName,
  } = props;
  const { primaryColor } = useTheme();
  const { navigateMessage } = useNavigateToMessage();
  let history = useHistory();

  const [openAddressDialog, setOpenAddressDialog] = useState(false);
  const [openMoreTrafficDialog, setopenMoreTrafficDialog] = useState(false);

  const [openSignupForFreePopup, setOpenSignupForFreePopup] = useState(false);

  const [loggedInUser, setLoggedInUser] = useLocalStorage("nonloggedInUser");
  const [openPlanUpgradeMsgDialog, setOpenPlanUpgradeMsgDialog] =
    useState(false);
  const [openScheduledPagesDialog, setOpenScheduledPagesDialog] =
    useState(false);

  return (
    <Grid
      container
      item
      // justifyContent={!showLimitedView ? "space-between" : "flex-start"}
      justifyContent={"space-between"}
      style={{ marginTop: 10 }}
    >
      <SignupForFreePopup
        openSignupForFreePopup={openSignupForFreePopup}
        setOpenSignupForFreePopup={setOpenSignupForFreePopup}
        storeFrontName={storeFrontName}
      />
      <ScheduledPagesDialog
        openScheduledPagesDialog={openScheduledPagesDialog}
        setOpenScheduledPagesDialog={setOpenScheduledPagesDialog}
        calSyncAccessToken={calSyncAccessToken}
      />
      <PlanUpgradeMsgDialog
        openPlanUpgradeMsgDialog={openPlanUpgradeMsgDialog}
        setOpenPlanUpgradeMsgDialog={setOpenPlanUpgradeMsgDialog}
      />
      <MoreTrafficDialog
        open={openMoreTrafficDialog}
        setopenMoreTrafficDialog={setopenMoreTrafficDialog}
        storeFrontId={storeFrontId}
        storeFrontRouteName={storeFrontRouteName}
        setUserEngagementData={setUserEngagementData}
      />
      <AddressDialog
        openAddressDialog={openAddressDialog}
        setOpenAddressDialog={setOpenAddressDialog}
        addresses={props.addresses}
      />
      {/* {!showLimitedView ? ( */}
      <Grid item>
        <MessageIcon
          style={{
            fill: primaryColor,
            cursor: "pointer",
          }}
          onClick={(event) => {
            setUserEngagementData(storeFrontId, "Message");
            increaseLeadCount(storeFrontId, "Message");
            if (user) {
              if (isMarketplacePlan(user)) {
                setOpenPlanUpgradeMsgDialog(true);
              } else {
                navigateMessage(event, email);
              }
            } else {
              if (pageRoute && pageRoute.includes("prepare-for-canada")) {
                setOpenSignupForFreePopup(true);
              } else {
                history.push("/userlogin");
                setLoggedInUser(
                  JSON.stringify({
                    redirectUrl: `/homebuyer/messaging?sendMessage=${email}`,
                  })
                );
              }
            }
          }}
        />
      </Grid>
      {/* ) : null} */}
      {/* {!showLimitedView ? ( */}
      <Grid item>
        <Tooltip title={formatContactNumber(contactNumber) || ""}>
          <CallIcon
            style={{
              fill: primaryColor,
              cursor: "pointer",
            }}
            onClick={(event) => {
              // event.stopPropagation();
              setUserEngagementData(storeFrontId, "Call");
              increaseLeadCount(storeFrontId, "Call");
              if (pageRoute && pageRoute.includes("prepare-for-canada")) {
                setOpenSignupForFreePopup(true);
              } else {
                if (user && isMarketplacePlan(user)) {
                  setOpenPlanUpgradeMsgDialog(true);
                } else {
                  if (isMobileDevice()) {
                    contactNumber &&
                      window.open(`tel:${contactNumber}`, "_self");
                  }
                }
              }
            }}
          />
        </Tooltip>
      </Grid>
      {/* ) : null} */}
      <Grid item>
        {showLimitedView ? (
          <RoomIcon
            onClick={() => {
              if (pageRoute && pageRoute.includes("prepare-for-canada")) {
                setOpenSignupForFreePopup(true);
              } else {
                if (user && isMarketplacePlan(user)) {
                  setOpenPlanUpgradeMsgDialog(true);
                } else {
                  setOpenAddressDialog(true);
                }
              }
              setUserEngagementData(storeFrontId, "Location");
            }}
            style={{ fill: primaryColor, cursor: "pointer" }}
          />
        ) : (
          <DateRangeIcon
            style={{
              fill: primaryColor,
              cursor: "pointer",
            }}
            onClick={() => {
              setUserEngagementData(storeFrontId, "Calendar");
              increaseLeadCount(storeFrontId, "Calendar");
              if (pageRoute && pageRoute.includes("prepare-for-canada")) {
                setOpenSignupForFreePopup(true);
              } else {
                if (storeFrontId === 104) {
                  window.open(
                    "http://richardscalendar.torontoism.com/",
                    "_blank"
                  );
                } else {
                  // setOpenScheduledPagesDialog(true);
                  if (user) {
                    if (user.userType === HOME_BUYER_USER) {
                      if (calSyncAccessToken) {
                        setOpenScheduledPagesDialog(true);
                      } else {
                        history.push(
                          "/homebuyer/calendar?scheduleMeeting=true"
                        );
                      }
                    } else {
                      if (isMarketplacePlan(user)) {
                        setOpenPlanUpgradeMsgDialog(true);
                      } else {
                        if (calSyncAccessToken) {
                          setOpenScheduledPagesDialog(true);
                        } else {
                          history.push(
                            "/serviceprovider/calendar?scheduleMeeting=true"
                          );
                        }
                      }
                    }
                  } else {
                    history.push("/userlogin");
                  }
                }
              }
            }}
          />
        )}
      </Grid>
      <Grid item>
        <ShareIcon
          style={{ fill: primaryColor, cursor: "pointer" }}
          onClick={() => {
            setUserEngagementData(storeFrontId, "Share");
            // if (user && isMarketplacePlan(user)) {
            //   setOpenPlanUpgradeMsgDialog(true);
            // } else {
            setopenMoreTrafficDialog(true);
            // }
          }}
        />
      </Grid>
      {isMobileDevice() && (
        <Grid item>
          <MoreHorizIcon
            style={{ fill: primaryColor, cursor: "pointer" }}
            onClick={() => {
              setWebPresenceDrawer(true);
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}
export default StorefrontIcons;
