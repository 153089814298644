import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";
import { useMoveDocument } from "souqh-react-redux-hooks/homeBuyer/useMoveDocument";
import {
  DOCUMENTS_TABS,
  HOME_BUYER_USER,
} from "../ServiceProvider/serviceProviderConstants";
import { DOCUMENTS_TABS as HOME_BUYER_TABS } from "../HomeBuyer/homeBuyerConstants";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { color_success } from "souqh-react-redux-hooks/toasterColors";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 360,
  },
}));

function MoveDocumentDialog({
  openMoveDocumentDialog,
  setOpenMoveDocumentDialog,
  documentName,
  categories,
  documentId,
  sourceCategoryId,
  getTabWiseCategoriesList,
  documentTabName,
  documentItem,
}) {
  const {
    moveHBDocument,
    setDestinationCategoryId,
    setDestinationDocumentTab,
    destinationDocumentTab,
    destinationCategoryId,
    handleClose,
  } = useMoveDocument(
    documentId,
    sourceCategoryId,
    getTabWiseCategoriesList,
    documentTabName,
    documentItem
  );
  const classes = useStyles();
  const user = useAuthUser();
  const dispatch = useDispatch();

  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (user.userType === HOME_BUYER_USER) {
      setTabs(HOME_BUYER_TABS);
    } else {
      setTabs(DOCUMENTS_TABS);
    }
  }, []);

  return (
    <Dialog
      fullWidth
      open={openMoveDocumentDialog}
      classes={{
        paper: classes.dialogPaper,
      }}
      onClose={handleClose}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Move Document</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container className={Styles.navyBlueColor}>
          <Grid container item xs={12} alignContent="center">
            <Typography style={{ marginBottom: 20 }}>
              Are you sure you want to move {documentName} ?
            </Typography>
          </Grid>
          <Grid container item xs={12} alignContent="center">
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label">Select Tab</InputLabel>
              <Select
                labelId="label"
                id="select-tab-id"
                label="Select Tab"
                value={destinationDocumentTab || ""}
                onClick={(e) => setDestinationDocumentTab(e.target.value)}
              >
                {tabs.map((tab, index) => (
                  <MenuItem value={tab} key={index}>
                    {tab}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 8 }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label">Select Category</InputLabel>
              <Select
                labelId="label"
                id="select-category-id"
                label="Select Category"
                value={destinationCategoryId || ""}
                onClick={(e) => setDestinationCategoryId(e.target.value)}
              >
                {categories &&
                  categories.length &&
                  categories.map((category, index) => (
                    <MenuItem key={index} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className={Styles.navyBlueColorForce}
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            setOpenMoveDocumentDialog(false);
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!destinationDocumentTab || !destinationCategoryId}
          className={Styles.primaryBtnAccent}
          style={{
            textTransform: "capitalize",
            marginRight: 16,
            padding: "6px 24px",
          }}
          onClick={() =>
            moveHBDocument((res) => {
              if (res.data.status === 200) {
                setOpenMoveDocumentDialog(false);
                handleClose();
                dispatch({
                  type: "SET_TOASTER",
                  payload: {
                    message: res.data.message,
                    bgColor: color_success,
                    open: true,
                  },
                });
              }
            })
          }
        >
          Move
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MoveDocumentDialog;
