import { Divider, Grid, Paper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import Styles from "../../../Styles.module.css";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";
import { usePeoplesAnalytics } from "souqh-react-redux-hooks/serviceProvider/analytics/usePeoplesAnalytics";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from "recharts";
import NoDataAvailable from "../../common/NoDataAvailable";

export default function PeopleAnalytics({ clients, days }) {
  const {
    getPeoplesAnalyticsData,
    finalEmploymentData,
    finalMaritalData,
    finalAgeRangeData,
    finalHouseholdIncomeRangeData,
    finalPropertyTypeData,
    finalIndustryData,
  } = usePeoplesAnalytics(clients, days);

  useEffect(() => {
    getPeoplesAnalyticsData();
  }, [clients, days]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="tooltip" style={{ backgroundColor: "white" }}>
          <p className="tooltipLabel">{`${payload[0].payload?.name}`}</p>
          <p className="tooltipDesc">{`${payload[0]?.payload?.value}%`}</p>
        </div>
      );
    }

    return null;
  };

  const renderLegend = (props) => {
    const { payload } = props;
    let payloadList1 = payload.slice(0, 9);
    let payloadList2 = payload.slice(9, 17);

    return (
      <Grid container>
        <Grid item xs={12} md={6}>
          <ul style={{ listStyleType: "none", textAlign: "left" }}>
            {payloadList1.map((entry, index) => renderLegendList(entry, index))}
          </ul>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{ marginTop: isMobileDevice() && -28 }}
        >
          <ul style={{ listStyleType: "none", textAlign: "left" }}>
            {payloadList2.map((entry, index) => renderLegendList(entry, index))}
          </ul>
        </Grid>
      </Grid>
    );
  };

  const renderLegendList = (entry, index) => {
    return (
      <li key={`item-${index}`} class="recharts-legend-item legend-item-0">
        <svg
          class="recharts-surface"
          width="14"
          height="14"
          viewBox="0 0 32 32"
          version="1.1"
          style={{
            display: "inline-block",
            verticalAlign: "middle",
            marginRight: 4,
          }}
        >
          <path
            stroke="none"
            fill={entry.color}
            d="M0,4h32v24h-32z"
            class="recharts-legend-icon"
          ></path>
        </svg>
        {entry.value}
      </li>
    );
  };

  return (
    <Grid
      container
      spacing={3}
      style={{
        marginTop: 12,
        paddingRight: !isMobileDevice() && !isTabletDevice() && 20,
      }}
    >
      <Grid item xs={12} md={6} lg={3}>
        <Paper
          elevation={0}
          style={{
            border:
              (isMobileDevice() || isTabletDevice()) && "1px solid #0000001F",
          }}
        >
          <Grid item xs={12} style={{ padding: "16px 0px 8px 0px" }}>
            <Typography
              align="center"
              className={Styles.navyBlueColor}
              style={{ fontWeight: 600 }}
            >
              Employment
            </Typography>
          </Grid>
          <Divider />
          <Grid
            container
            item
            xs={12}
            style={{
              padding: "24px 0px 10px 0px",
              textAlign: "center",
              height: "20.5rem",
            }}
          >
            {finalEmploymentData.every((item) => item.value === 0) ? (
              <NoDataAvailable isSFAnalyticsPage={true} />
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <PieChart
                //  width={400} height={300}
                // margin={{ top: 0, right: 40, bottom: 10, left: 0 }}
                >
                  <Legend
                    layout={isMobileDevice() ? "horizontal" : "vertical"}
                    verticalAlign="bottom"
                    align="center"
                    wrapperStyle={{ marginLeft: 20 }}
                    className="recharts-legend-item-text"
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Pie
                    data={finalEmploymentData}
                    cx={
                      isMobileDevice()
                        ? window.innerWidth / 2 - 20
                        : isTabletDevice()
                        ? 170
                        : 130
                    }
                    cy={90}
                    innerRadius={70}
                    outerRadius={90}
                    // fill="#8884d8"
                    // paddingAngle={0}
                    dataKey="value"
                    nameKey="name"
                  >
                    {finalEmploymentData.map((entry, index) => (
                      <Cell fill={finalEmploymentData[index].color} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            )}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Paper
          elevation={0}
          style={{
            border:
              (isMobileDevice() || isTabletDevice()) && "1px solid #0000001F",
          }}
        >
          <Grid item xs={12} style={{ padding: "16px 0px 8px 0px" }}>
            <Typography
              align="center"
              className={Styles.navyBlueColor}
              style={{ fontWeight: 600 }}
            >
              Marital Status
            </Typography>
          </Grid>
          <Divider />
          <Grid
            container
            item
            xs={12}
            style={{
              padding: "24px 0px 10px 0px",
              textAlign: "center",
              height: "20.5rem",
            }}
          >
            {finalMaritalData.every((item) => item.value === 0) ? (
              <NoDataAvailable isSFAnalyticsPage={true} />
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Legend
                    layout={isMobileDevice() ? "horizontal" : "vertical"}
                    verticalAlign="bottom"
                    align="center"
                    style={{ marginLeft: 20 }}
                    wrapperStyle={{ marginLeft: 20 }}
                    className="recharts-legend-item-text"
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Pie
                    data={finalMaritalData}
                    cx={
                      isMobileDevice()
                        ? window.innerWidth / 2 - 20
                        : isTabletDevice()
                        ? 170
                        : 130
                    }
                    cy={90}
                    innerRadius={70}
                    outerRadius={90}
                    dataKey="value"
                    nameKey="name"
                  >
                    {finalMaritalData.map((entry, index) => (
                      <Cell fill={finalMaritalData[index].color} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            )}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Paper
          elevation={0}
          style={{
            border:
              (isMobileDevice() || isTabletDevice()) && "1px solid #0000001F",
          }}
        >
          <Grid item xs={12} style={{ padding: "16px 0px 8px 0px" }}>
            <Typography
              align="center"
              className={Styles.navyBlueColor}
              style={{ fontWeight: 600 }}
            >
              Age Range
            </Typography>
          </Grid>
          <Divider />
          <Grid
            container
            item
            xs={12}
            style={{
              padding: "24px 0px 10px 0px",
              textAlign: "center",
              height: "20.5rem",
            }}
          >
            {finalAgeRangeData.every((item) => item.value === 0) ? (
              <NoDataAvailable isSFAnalyticsPage={true} />
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Legend
                    layout={isMobileDevice() ? "horizontal" : "vertical"}
                    verticalAlign={isMobileDevice() ? "bottom" : "center"}
                    align={isMobileDevice() ? "center" : "right"}
                    wrapperStyle={{
                      marginRight: !isMobileDevice() && 80,
                      marginLeft: isMobileDevice() && 24,
                      marginTop: 20,
                    }}
                    className="recharts-legend-item-text"
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Pie
                    data={finalAgeRangeData}
                    cx={
                      isMobileDevice()
                        ? window.innerWidth / 2 - 20
                        : isTabletDevice()
                        ? 200
                        : 130
                    }
                    cy={90}
                    innerRadius={70}
                    outerRadius={90}
                    dataKey="value"
                    nameKey="name"
                  >
                    {finalAgeRangeData.map((entry, index) => (
                      <Cell fill={finalAgeRangeData[index].color} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            )}
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} lg={6}>
        <Paper
          elevation={0}
          style={{
            border:
              (isMobileDevice() || isTabletDevice()) && "1px solid #0000001F",
          }}
        >
          <Grid item xs={12} style={{ padding: "16px 0px 8px 0px" }}>
            <Typography
              align="center"
              className={Styles.navyBlueColor}
              style={{ fontWeight: 600 }}
            >
              Household Income Range
            </Typography>
          </Grid>
          <Divider />
          <Grid
            container
            item
            xs={12}
            style={{
              padding: "24px 0px 10px 0px",
              textAlign: "center",
              height: isMobileDevice() ? "28rem" : "18.5rem",
            }}
          >
            {finalHouseholdIncomeRangeData.every((item) => item.value === 0) ? (
              <NoDataAvailable isSFAnalyticsPage={true} />
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Legend
                    layout="vertical"
                    verticalAlign={isMobileDevice() ? "bottom" : "center"}
                    align={isMobileDevice() ? "center" : "right"}
                    wrapperStyle={{
                      marginRight: !isMobileDevice() && 60,
                      marginTop: isMobileDevice() && 40,
                      marginLeft: isMobileDevice() && 16,
                    }}
                    className="recharts-legend-item-text"
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Pie
                    data={finalHouseholdIncomeRangeData}
                    cx={
                      isMobileDevice()
                        ? window.innerWidth / 2 - 20
                        : isTabletDevice()
                        ? 200
                        : 130
                    }
                    cy={90}
                    innerRadius={70}
                    outerRadius={90}
                    dataKey="value"
                    nameKey="name"
                  >
                    {finalHouseholdIncomeRangeData.map((entry, index) => (
                      <Cell fill={finalHouseholdIncomeRangeData[index].color} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            )}
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} lg={6}>
        <Paper
          elevation={0}
          style={{
            border:
              (isMobileDevice() || isTabletDevice()) && "1px solid #0000001F",
          }}
        >
          <Grid item xs={12} style={{ padding: "16px 0px 8px 0px" }}>
            <Typography
              align="center"
              className={Styles.navyBlueColor}
              style={{ fontWeight: 600 }}
            >
              Property Type
            </Typography>
          </Grid>
          <Divider />
          <Grid
            container
            item
            xs={12}
            style={{
              padding: "24px 0px 10px 0px",
              textAlign: "center",
              height: isMobileDevice() ? "20rem" : "18.5rem",
            }}
          >
            {finalPropertyTypeData.every((item) => item.value === 0) ? (
              <NoDataAvailable isSFAnalyticsPage={true} />
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Legend
                    layout={isMobileDevice() ? "horizontal" : "vertical"}
                    verticalAlign={isMobileDevice() ? "bottom" : "center"}
                    align={isMobileDevice() ? "center" : "right"}
                    wrapperStyle={{
                      marginRight: !isMobileDevice() && 60,
                      marginTop: 20,
                      marginLeft: isMobileDevice() && 20,
                    }}
                    className="recharts-legend-item-text"
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Pie
                    data={finalPropertyTypeData}
                    cx={
                      isMobileDevice()
                        ? window.innerWidth / 2 - 20
                        : isTabletDevice()
                        ? 200
                        : 130
                    }
                    cy={90}
                    innerRadius={70}
                    outerRadius={90}
                    dataKey="value"
                    nameKey="name"
                  >
                    {finalPropertyTypeData.map((entry, index) => (
                      <Cell fill={finalPropertyTypeData[index].color} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            )}
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            border:
              (isMobileDevice() || isTabletDevice()) && "1px solid #0000001F",
          }}
        >
          <Grid item xs={12} style={{ padding: "16px 0px 8px 0px" }}>
            <Typography
              align="center"
              className={Styles.navyBlueColor}
              style={{ fontWeight: 600 }}
            >
              Industry
            </Typography>
          </Grid>
          <Divider />
          <Grid
            container
            item
            xs={12}
            style={{
              padding: "24px 0px 10px 0px",
              // textAlign: "left",
              height: isMobileDevice()
                ? "48rem"
                : isTabletDevice()
                ? "36rem"
                : "18.5rem",
            }}
          >
            {finalIndustryData.every((item) => item.value === 0) ? (
              <NoDataAvailable isSFAnalyticsPage={true} />
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Legend
                    layout="vertical"
                    verticalAlign={
                      isMobileDevice() || isTabletDevice() ? "bottom" : "center"
                    }
                    align={isMobileDevice() ? "center" : "right"}
                    wrapperStyle={{
                      marginRight: !isMobileDevice() && 60,
                      marginTop: !isMobileDevice() && 20,
                      // marginLeft: isMobileDevice() && 20,
                    }}
                    content={renderLegend}
                    className="recharts-legend-item-text"
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Pie
                    data={finalIndustryData}
                    cx={
                      isMobileDevice() || isTabletDevice()
                        ? window.innerWidth / 2 - 20
                        : 130
                    }
                    cy={isMobileDevice() || isTabletDevice() ? 90 : 130}
                    innerRadius={70}
                    outerRadius={90}
                    dataKey="value"
                    nameKey="name"
                  >
                    {finalIndustryData.map((entry, index) => (
                      <Cell fill={finalIndustryData[index].color} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
