import { Grid, Typography } from "@material-ui/core";
import React from "react";
import Styles from "../../Styles.module.css";

export default function SearchNotFound({ functionality }) {
  return (
    <Grid item xs={12} style={{ textAlign: "center", marginTop: 40 }}>
      <Typography
        className={Styles.navyBlueColor}
        style={{ textAlign: "center" }}
      >
        Looks like you don't have any {functionality} with above search criteria
      </Typography>
    </Grid>
  );
}
