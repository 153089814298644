import {
  Container,
  Grid,
  Typography,
  makeStyles,
  Button,
  Switch,
  Paper,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";
import PrimaryApplicant from "./PrimaryApplicant";
import CoApplicant from "./CoApplicant";
import CurrentAddress from "./CurrentAddress";
import SetupFooter from "../../SPSetup/SetupFooter";
import ImageCropper from "../../ImageCropper";
import { includes } from "lodash";
import useLocalStorage from "react-use-localstorage";
import {
  usePersonalOverview,
  useSavePersonalInfo,
} from "souqh-react-redux-hooks/homeBuyer/hbProfile/usePersonalOverview";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  padding: {
    padding: 10,
  },
  marginTop: {
    marginTop: 20,
  },
  buttons: {
    padding: "5px 30px",
    textTransform: "capitalize",
  },
}));
const PersonalOverview = () => {
  let history = useHistory();
  const classes = useStyles();
  const {
    user,
    coApplicantSupport,
    primaryApplicant,
    coApplicant,
    provinces,
    currentAddress,
    allowReset,
    validations,
    profilePhoto,
    allowSaveAndContinue,
    getPersonalData,
    addValidations,
    validCoApplicantData,
    actions: {
      setCoApplicant,
      saveCoApplicant,
      updateCurrentAddress,
      saveCurrentAddress,
      resetCurrentAddress,
      clickEdit,
      updatePrimaryApplicant,
      updateProfilePhoto,
    },
  } = usePersonalOverview(true);
  const { savePersonalInfo } = useSavePersonalInfo(user);
  const [localUserInfo, setUserInfo] = useLocalStorage("userInfo");
  const [error, setError] = useState({});

  useEffect(() => {
    window.scroll(0, 0);
    getPersonalData(user.userId);
  }, []);
  return (
    <Grid container className={clsx(classes.root, classes.marginTop)}>
      <Container maxWidth="md" style={{ paddingBottom: 70 }}>
        <Grid
          style={{ marginBottom: 48, marginTop: 64 }}
          id="contact_information"
        >
          <Typography variant="h4" className={Styles.navyBlueColor}>
            Contact Information
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 24 }}>
          <Typography
            variant="subtitle1"
            style={{ marginBottom: 8 }}
            className={Styles.navyBlueColor}
          >
            Profile Photo
          </Typography>
          <Grid container>
            <Grid container item xs={3}>
              <ImageCropper
                type={"hb-profile"}
                cropWidth={150}
                cropHeight={170}
                image={profilePhoto}
                placeholderImage="/images/logo.svg"
                setError={setError}
                validateImage={true}
                onImageAvailalbe={(image) => {
                  updateProfilePhoto(image);
                }}
              />
            </Grid>
            <Grid container item xs={12}>
              {error && error.sizeExceed && (
                <Typography style={{ color: "red" }}>
                  File size is restricted to 15 MB only.
                </Typography>
              )}
              {error && error.invalidFile && (
                <Typography style={{ color: "red" }}>
                  Invalid file format.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            style={{ marginBottom: 8 }}
            className={Styles.navyBlueColor}
          >
            Primary Applicant <span className={Styles.sqErrorColor}>*</span>
          </Typography>
        </Grid>
        <PrimaryApplicant
          user={user}
          updatePrimaryApplicant={updatePrimaryApplicant}
          primaryApplicant={primaryApplicant}
          validations={validations}
        />
        <Grid item container xs={12} style={{ margin: "24px 0px 16px 0px" }}>
          <Grid item container xs={8}>
            <Typography
              variant="body1"
              className={Styles.navyBlueColor}
              style={{ marginTop: 8 }}
            >
              Do you want to add Co-Applicant details?
            </Typography>
          </Grid>
          <Grid item container xs={4} justifyContent="flex-end">
            <Switch
              className={"sqGraySwitch"}
              checked={coApplicantSupport}
              onChange={(event) => {
                setCoApplicant(event.target.checked);
              }}
            />
          </Grid>
        </Grid>
        {coApplicantSupport && (
          <CoApplicant
            coApplicant={coApplicant}
            saveCoApplicant={saveCoApplicant}
            validations={validations}
          />
        )}
        <Grid style={{ marginBottom: 36, marginTop: 36 }} id="current_address">
          <Typography variant="h4" className={Styles.navyBlueColor}>
            Current Address
          </Typography>
        </Grid>
        <Grid>
          <Typography
            variant="subtitle1"
            style={{ marginBottom: 8 }}
            className={Styles.navyBlueColor}
          >
            Your Address <span className={Styles.sqErrorColor}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} style={{ padding: "16px 10px" }}>
            <CurrentAddress
              updateAddress={updateCurrentAddress}
              provinces={provinces}
              address={currentAddress}
              saveAddress={saveCurrentAddress}
              clickEdit={clickEdit}
              allowReset={allowReset}
              resetAddress={resetCurrentAddress}
            />
          </Paper>
        </Grid>
      </Container>
      <SetupFooter profile={true} isSaveAsDraft={false}>
        <Grid item container xs={12} justifyContent="flex-end">
          <Button
            disabled={!allowSaveAndContinue || !validCoApplicantData}
            variant="contained"
            onClick={() => {
              savePersonalInfo((res) => {
                if (res && res.status === 200) {
                  if (user.hbCurrentAddressCity) {
                    user.hbCurrentAddressCity =
                      res.data.result.hbCurrentAddress;
                  }
                  setUserInfo(JSON.stringify(user));
                  history.push("/usersetup/newhomeprofile");
                }
                if (res && res.status === 400 && res.data.result) {
                  addValidations({
                    // email: res.data.result["coApplicant.email"],
                    contactNumber:
                      res.data.result["primaryApplicant.contactNumber"],
                  });
                }
              });
            }}
            className={clsx(Styles.primaryBtnAccent, classes.buttons)}
          >
            Save and continue
          </Button>
        </Grid>
      </SetupFooter>
    </Grid>
  );
};

export default PersonalOverview;
